import React from "react";
import FooterIcons from "./FooterIcons";
import LogoSVG from "../../assets/footers/footerLogoWithTextAbove.svg";
import Facebook from "../../assets/footers/facebook.svg";
import Twitter from "../../assets/footers/twitter.svg";
import Instagram from "../../assets/footers/instagram.svg";
import Github from "../../assets/footers/github.svg";
import LinkedIn from "../../assets/footers/linkedin.svg";
import { Wrapper, Main, MenuMain, CopyRightMain } from "./styles";
import textlogo from "../../assets/Logo/Group 1583.png";
import { Link } from "react-router-dom";

const DistributionLink = [
  { title: "Home", url: "/" },
  { title: "Who We Are", url: "#" },
  { title: "Demo", url: "#" },
  { title: "Blog", url: "#" },
];

const Footer = () => {
  const imgs = ["linkedin.png", "twitter.png", "instagram.png", "youtube.png"];
  const imgUrls = [
    "https://www.linkedin.com/company/unitedmarket",
    "https://twitter.com/unitedmarketapp?s=11&t=BeQLHqOtLHY__VpauHyFEw",
    "https://instagram.com/unitedmarket?utm_medium",
    "https://www.youtube.com/channel/UCuWxIf970SF9k-2KrAVxcOQ?app=desktop",
  ];
  return (
    <>
      <Wrapper>
        <Main>
          <MenuMain>
            <div className="left__side">
              <Link to="/">
                <img src={textlogo} art="logo" />
              </Link>
            </div>
            <div className="right__side">
              <ul style={{ padding: 0 }}>
                {DistributionLink.map((value) => {
                  return (
                    <>
                      <li key={value.title}>
                        <Link to={value.url}>{value.title}</Link>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
          </MenuMain>
          <CopyRightMain>
            <div className="left__side">
              <p>© 2023 United Market. All rights reserved.</p>
            </div>
            <div className="right__side">
              <ul style={{ padding: 0 }}>
                <li>
                  <Link to="#">
                    <img src={Facebook} alt="Facebook" />
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/unitedmarketapp?s=11&t=BeQLHqOtLHY__VpauHyFEw">
                    <img src={Twitter} alt="Twitter" />
                  </Link>
                </li>
                <li>
                  <Link to="https://instagram.com/unitedmarket?utm_medium">
                    <img src={Instagram} alt="Instagram" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src={Github} alt="Github" />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/company/unitedmarket">
                    <img src={LinkedIn} alt="LinkedIn" />
                  </Link>
                </li>
              </ul>
            </div>
          </CopyRightMain>
        </Main>
      </Wrapper>
    </>
  );
};

export default Footer;
