import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import Opportunities from "../../components/OpportunitiesComponents/Opportunities/Opportunities";

const OpportunitiesPage = () => {
  return (
    <Grid>
      <Sidebar childComponent={<Opportunities />} activeText="Opportunities" />
    </Grid>
  );
};

export default OpportunitiesPage;
