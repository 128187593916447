import { useLazyQuery } from "@apollo/client";
import React, { createContext, useState, useContext } from "react";
import useAppState from "./useAppState";
import { getUser } from "../components/Hooks/Authentication/User/query";
import axios from "axios";
import { Mixpanel } from "./mixpanel/mixPanel";

const LibraryContext = createContext();

export const LibraryProvider = (props) => {
  const defaultData = {
    basic: {
      file: {},
      img_src: "images/no_image.png",
      img_title: "Untitled",
      img_description: "Add a note",
      title: "",
      tags: [],
      scale: "",
      bpm: "",
      key: "",
      email: "",
    },
    beat: {
      ex_price: "",
      non_ex_price: "",
      publisher_name: "",
      publisher_pro: "",
      split: "",
      mood_tone: "",
      is_collaborator: false,
    },
    is_master_sample: false,
    pitch: false,
    pitch_for_sync: {
      wav_file: "",
      instrumental: "",
      clean_version: "",
      is_released: false,
      is_owner: false,
      full_name: "",
      email_address: "",
      phone_number: "",
    },
  };
  const [libraryData, setLibraryData] = React.useState([defaultData]);

  const resetData = () => {
    setLibraryData([defaultData]);
  };

  return (
    <LibraryContext.Provider
      value={{
        libraryData,
        setLibraryData,
        resetData,
      }}
    >
      {props.children}
    </LibraryContext.Provider>
  );
};

export const useLibrary = () => {
  const context = useContext(LibraryContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
