import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./subscription.module.css";
import useAppState from "../../../context/useAppState";
import American from "../../../assets/cardSvg/American";
import UnionPay from "../../../assets/cardSvg/UnionPay";
import DiscoverDiners from "../../../assets/cardSvg/Discover";
import JCB from "../../../assets/cardSvg/JCB";
import MasterCard from "../../../assets/cardSvg/MasterCard";
import Visa from "../../../assets/cardSvg/visa";

export default function SubscriptionSettings(props) {
  const { getPaymentInfo, fetchPaymentPortalLink } = useAppState("general");
  const [paymentInfo, setPaymentInfo] = useState(null);

  const user = props.user;
  const baseURL = window.location.origin;

  console.log(user);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const paymentInformation = await getPaymentInfo(user._id);
        console.log(paymentInformation);
        setPaymentInfo(paymentInformation);
        console.log(paymentInformation);
      } catch (error) {
        console.log("Error fetching payment information: ", error);
      }
    };

    fetchInfo();
  }, []);

  async function handleManageSubscription() {
    try {
      const portalURL = await fetchPaymentPortalLink(
        user._id,
        paymentInfo.user.stripeId,
        `${window.location.origin}/settings`
      );
      console.log("Payment Portal URL:", portalURL);

      window.open(portalURL, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function handleCancelSubscription() {
    let formUrl = "https://form.typeform.com/to/OmOfeqhs";
    window.open(formUrl, "_blank");
  }

  console.log(paymentInfo);
  return (
    <div className={styles.main_layout}>
      {paymentInfo && (
        <>
          <div className={styles.payment_section_button}>
            <div></div>
            <div
              className={styles.manage_subscription_button}
              onClick={handleCancelSubscription}
            >
              Cancel Subscription
            </div>
          </div>
          <div className={styles.payment_section_button}>
            <div className={styles.payment_plan_info}>
              <p className={styles.section_title}></p>
              <div className={styles.subscription_options}>
                {paymentInfo.paymentInfo.currentSubscription ? (
                  <CurrentPlanTile
                    plan={paymentInfo.paymentInfo.currentSubscription.plan}
                    subscriptionRenewDate={
                      paymentInfo.paymentInfo.currentSubscription
                        .current_period_end
                    }
                  />
                ) : (
                  <div></div>
                )}

                {/* <UpgradePlanTile profile={profile} /> */}
              </div>
            </div>
            <div
              className={styles.manage_subscription_button}
              onClick={handleManageSubscription}
            >
              Manage Subscription
            </div>
          </div>
          <div className={styles.payment_section}>
            <p className={styles.section_title}></p>
            <div className={styles.payment_cards}>
              {paymentInfo.paymentInfo.paymentMethods ? (
                paymentInfo.paymentInfo.paymentMethods.map(
                  (paymentMethod, index) => (
                    <CreditCard
                      key={index}
                      paymentMethod={paymentMethod}
                      firstName={user.firstname}
                      lastName={user.lastname}
                    />
                  )
                )
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const CreditCard = ({ paymentMethod, firstName, lastName }) => {
  return (
    <div className={styles.credit_card}>
      <div className={styles.card_top_info}>
        <img
          className={styles.chip_image}
          src="PaymentOptionsImages/chip.png"
          alt="brand"
        ></img>

        <img
          className={styles.card_brand_image}
          src="PaymentOptionsImages/visaImage.png"
          alt="brand"
        ></img>
        {/* <CardBrandImage cardBrand={paymentMethod.card.brand} /> */}
      </div>
      <div className={styles.card_middle}>
        <p className={styles.card_number}>
          •••• •••• •••• {paymentMethod.card.last4}
        </p>
      </div>
      <div className={styles.card_bottom_info}>
        <div className={styles.card_info_left}>
          <p className={styles.card_title}>Name</p>
          <p className={styles.card_value}>
            {firstName} {lastName}
          </p>
        </div>
        <div className={styles.card_info_right}>
          <p className={styles.card_title}>Expires</p>
          <p className={styles.card_value}>
            {paymentMethod.card.exp_month} / {paymentMethod.card.exp_year}
          </p>
        </div>
      </div>
    </div>
  );
};

function CardBrandImage(props) {
  const { cardBrand } = props;

  const renderCardImage = (brand) => {
    switch (brand) {
      case "American Express":
        return (
          <American
            className={styles.card_brand_image}
            alt="American Express"
          />
        );
      case "China UnionPay":
        return (
          <UnionPay className={styles.card_brand_image} alt="China UnionPay" />
        );
      case "Discover & Diners Club":
        return (
          <DiscoverDiners
            className={styles.card_brand_image}
            alt="Discover & Diners Club"
          />
        );
      case "Japan Credit Bureau":
        return (
          <JCB className={styles.card_brand_image} alt="Japan Credit Bureau" />
        );
      case "Mastercard":
        return (
          <MasterCard className={styles.card_brand_image} alt="Mastercard" />
        );
      case "Visa":
        return <Visa className={styles.card_brand_image} alt="Visa" />;
      default:
        return <Visa className={styles.card_brand_image} alt="Card Brand" />;
    }
  };

  return <>{renderCardImage(cardBrand)}</>;
}

function CurrentPlanTile(props) {
  const plan = props.plan;
  const renewDate = props.subscriptionRenewDate;
  return (
    <div className={styles.subscription_option_current}>
      <img src="/check.png" alt="Check" className={styles.check_icon} />
      <div className={styles.subscription_option_top}>
        <p className={styles.plan_type}>Standard</p>
        <p className={styles.price_point}>
          <span className={styles.bolder}>{formatPrice(plan.amount)}</span>/
          {plan.interval}
        </p>
      </div>
      <div className={styles.subscription_option_middle}>
        <p className={styles.plan_renews}>
          Your plan renews on {formatUnixTimestamp(renewDate)}
        </p>
      </div>
      <div className={styles.subscription_option_bottom}>
        <div className={styles.current_plan_box}>Current</div>
        {/* <p className={styles.learn_more}>Learn More</p> */}
      </div>
    </div>
  );
}

function UpgradePlanTile(props) {
  const profile = props.profile;
  return (
    <div className={styles.subscription_option_upgrade}>
      <div className={styles.subscription_option_top}>
        <p className={styles.plan_type}>Premium</p>
        <p className={styles.price_point}>
          <span className={styles.bolder}>70.00</span>/
          {profile.paymentInfo.interval}
        </p>
      </div>
      <div className={styles.subscription_option_middle}>
        <p className={styles.plan_renews}>Upgrade to premium for more!</p>
      </div>
      <div className={styles.subscription_option_bottom}>
        <div className={styles.upgrade_button}>Upgrade</div>
        {/* <p className={styles.learn_more}>Learn More</p> */}
      </div>
    </div>
  );
}

function formatUnixTimestamp(timestamp) {
  const date = new Date(timestamp * 1000);

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

function formatPrice(priceInCents, currency = "USD") {
  const priceInDollars = priceInCents / 100;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(priceInDollars);
}

const profile = {
  uid: "123456",
  firstName: "John",
  lastName: "Doe",
  paymentInfo: {
    stripeId: "stripe_abc123",
    pricePoint: 999,
    interval: "month",
    billingCycle: [1609459200, 1612137600],
    paymentMethods: [
      {
        last4: "4242",
        cardExpryMonth: "12",
        cardExpryYear: "2024",
      },
    ],
  },
};
