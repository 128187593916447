import React, { useState, memo, useEffect } from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  MainTitle,
  BeatEmpty,
  CreateBeatInboxPopup,
  BeatsBox,
  PaginationMain,
} from "../../../components/smartlink";
import { image_placeholder_icon } from "../../../assets/smartlink";
import { Grid, Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GetAllActiveBeatBoxes } from "../../../Graphql_services/beatinbox/Beats";
import BugsPopup from "../../../components/smartlink/producers/producerssubmit/popups/BugsPopup";

// import { Sidebar } from "../../../layout/Sidebar/Sidebar";

// import { Sidebar } from '../../layout/Sidebar/Sidebar.js';

const InboxInLibrary = () => {
  const [currentPage, setcurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [Bugopen, setBugopen] = useState(false);

  const perPage = 9;

  const { loading, error, data, refetch } = useQuery(GetAllActiveBeatBoxes, {
    variables: { page: currentPage, perPage },
    fetchPolicy: "no-cache",
    context: { clientName: "beats" },
  });

  useEffect(() => {
    if (error) {
      setBugopen(true);
    }
  }, [error]);

  const createModelOpen = () => {
    setOpen(!open);
  };
  const beatBoxes = data?.getActiveBeatBoxes?.beatBoxes;

  const totalPages = data?.getActiveBeatBoxes?.totalPages;
  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };
  useEffect(() => {
    refetch();
  }, []);

  const [EditData, setEditData] = useState({});

  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
        <Grid sx={{marginTop: "20px"}}>
          <MainTitle
            title="Beat Inbox"
            hasbutton={true}
            isBeatinbox={true}
            openCreateBeatPopup={createModelOpen}
            refetch={refetch}
            isloading={loading}
          />
          {loading && <p style={{ color: "#fff" }}>Loading...</p>}
          <Box
            sx={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Grid container spacing={2}>
              {!loading && beatBoxes?.length > 0
                ? beatBoxes?.map((beatBox, index) => {
                    return (
                      <>
                        <Grid
                          item
                          sm={12}
                          md={6}
                          lg={4}
                          xl={4}
                          key={`beatboxesgrid-${beatBox?._id}`}
                          sx={{ width: "100%" }}
                        >
                          <BeatsBox
                            data={beatBox}
                            index={index}
                            isOpen={openDropdownIndex === index}
                            toggleDropdown={toggleDropdown}
                            setEditData={setEditData}
                            OpenInboxCreate={setOpen}
                          />
                        </Grid>
                      </>
                    );
                  })
                : !loading && <BeatEmpty />}
            </Grid>

            {!loading && totalPages > 1 && (
              <>
                <Box sx={{ marginBottom: "1rem" }}>
                  <PaginationMain
                    pageCount={totalPages}
                    page={currentPage}
                    handelChange={handlePageChange}
                  />
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <CreateBeatInboxPopup
          open={open}
          setOpen={setOpen}
          image={image_placeholder_icon}
          EditData={EditData}
          setEditData={setEditData}
        />
        <BugsPopup open={Bugopen} setOpen={setBugopen} />
    </>
  );
};

export default memo(InboxInLibrary);
