import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ModelContentBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "& .header-icon-box": {
    textAlign: "right",

    "& .close-icon": {
      width: "30px",
      height: "30px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
  "& h4": {
    color: "#E3E3E3",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "10px",
  },
  "& p": {
    color: "#AFAFAF",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "40px",
  },
  "& .package-datail-box": {
    textAlign: "left",
    "& .plan-box": {
      marginBottom: "18px",
      borderBottom: "1px solid #fff",
      "& h5": {
        color: "#E3E3E3",
        fontFamily: theme.typography.fontFamily,
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        textTransform: "capitalize",
        marginBottom: "5px",
        marginTop: "0px",
      },
      "& h6": {
        color: "#FFF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginBottom: "12px",
        textTransform: "capitalize",
        margin: "0px",
        paddingBottom: "7px",
      },
    },
    "& .transaction-date-box": {
      "& .date, & .hours, & .sub-total, & .discount, & .total": {
        display: "flex",
        justifyContent: "space-between",
        "& h6": {
          color: "#FFF",
          fontFamily: theme.typography.fontFamily,
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          marginBottom: "15px",
          textTransform: "capitalize",
          margin: "0px",
        },
      },
      "& .hours": {
        marginBottom: "18px",
        borderBottom: "1px dashed #fff",
        paddingBottom: "6px",
      },
      "& .discount": {
        borderBottom: "1px solid #fff",
        marginBottom: "18px",
        paddingBottom: "6px",
      },
      "& .total": {
        marginBottom: "40px",
      },
      "& .pay-btn": {
        height: "56px",
        background: "#2EBB55",
        borderRadius: "5px",
        color: "#FFF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        textTransform: "capitalize",
      },
    },
  },
}));
export { ModelContentBox };
