// socket.js
import { io } from "socket.io-client";
const userId = localStorage.getItem("userId");
const socket = io(`wss://api.unitedmarket.com`, {
  // const socket = io(`${process.env.REACT_APP_CHAT_URL}`, {
  path: "/socket.io",
  transports: ["websocket", "polling"],
  withCredentials: true,
  query: { id: userId },
});

socket.on('connect', () => {
  console.log('WebSocket connection established for chats');
});

socket.on("connect_error", (err) => {
  // the reason of the error, for example "xhr poll error"
  console.info(err.message, process.env.REACT_APP_CHAT_URL, "tes");

  // some additional description, for example the status code of the initial HTTP response
  // console.log(err.description, process.env.REACT_APP_CHAT_URL, "test");

  // // some additional context, for example the XMLHttpRequest object
  // console.log(err.context,process.env.REACT_APP_CHAT_URL, "testt");
});

// export const Notificationsocket = io(`${process.env.REACT_APP_NOTIFICATIONS}`, {
export const Notificationsocket = io(`wss://api.unitedmarket.com`, {
  transports: ["websocket", "polling"],
  withCredentials: true,
  query: { id: userId },
   path: "/socket.io/gen",
});

// socket for markingAi
export const summarySocket = io(`ws://api.unitedmarket.com/contract`);
// export const summarySocket = io(`wss://api.staging.unitedmarket.com`,{
//   path: "/socket.io/ai",
//   transports: ["websocket", "polling"],
//   withCredentials: true,
// });

// summarySocket.on('connect', () => {
//   console.log('WebSocket connection established');
// });

// export const summarySocket = io(`wss://api.staging.unitedmarket.com`, {
//   path: "/so ",
//   transports: ["websocket", "polling"],
//   withCredentials: true,
// });
// summarySocket.on("connect", () => {
//   console.log("WebSocket connection established");
// });
// summarySocket.on("connect_error", (error) => {
//   console.error("WebSocket error:", error);
// });
export default socket;
