import React, { useState, useRef, useEffect } from "react";
import * as yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { Box, Button, Modal } from "@mui/material";
import { ModelContentBox } from "./style/CollaboratorInputsModelStyle";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import MembershipInput from "../../inputs/membershipinput/MembershipInput";
import ErrorIcon from "@mui/icons-material/Error";

// import { InputMain } from "./style/MembershipInputStyle";

import Tooltip from "@mui/material/Tooltip";
import { InputMain } from "../style/MembershipInputStyle";
import { Notification } from "../../../../NotifyMsg/Notification";

const validationSchema = yup.object().shape({
  MusicianInfo: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Name is required"),
      email: yup.string().email("Invalid email").required("Email is required"),
      splitPrice: yup
        .number()
        .typeError("Split Price must be a number")
        .required("Split Price is required"),
    })
  ),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "#1C1B1B",
  height: "800px",
  display: "block",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "transparent",
  },
  pt: 2,
  px: 4,
  pb: 3,
};

const CollaboratorInputsModel = ({
  bioOpen = false,
  setBioOpen = () => {},
  SelectedNumber = 1,
  setCollaboratorsData = () => {},
  setOpen = () => {},
  setSelectedInputs = () => {},
  CollaboratorsData = () => {},
}) => {
  const MusicianBioOClose = () => {
    setBioOpen(!bioOpen);
  };

  const HandlerSubmit = (values, { resetForm }) => {
    setCollaboratorsData(values.MusicianInfo);
    // resetForm();
    setBioOpen(false);
  };

  const handelPreviusPopup = () => {
    setOpen(true);
    setBioOpen(false);
    setSelectedInputs(1);
  };

  return (
    <>
      <Modal
        open={bioOpen}
        onClose={MusicianBioOClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="main"
          sx={{
            ...style,
            width: { xs: "98%", sm: "900px" },
            padding: "3rem 3.75rem 3.75rem 3.75rem",
            borderRadius: "20px",
            background:
              "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
            backdropFilter: "blur(200px)",
            "& h5": {
              margin: "0",
            },
            input: {
              color: "#fff",
            },
            height: "auto",
            maxHeight: "80vh",
          }}
        >
          <Box className="header-icon-box">
            <ArrowBackIcon className="back-icon" onClick={handelPreviusPopup} />
            <CloseIcon className="close-icon" onClick={MusicianBioOClose} />
          </Box>
          <h5>Add Musician And Splits</h5>
          <Formik
            // initialValues={{
            //   MusicianInfo: Array.from(
            //     { length: Number(SelectedNumber) || 1 },
            //     () => ({
            //       name: "",
            //       email: "",
            //       splitPrice: "",
            //     })
            //   ),
            // }}
            initialValues={{
              MusicianInfo: CollaboratorsData?.length
                ? CollaboratorsData?.map((collaborator) => ({
                    name: collaborator.name,
                    email: collaborator.email,
                    splitPrice: collaborator.splitPrice,
                  }))
                : Array.from({ length: Number(SelectedNumber) || 1 }, () => ({
                    name: "",
                    email: "",
                    splitPrice: "",
                  })),
            }}
            onSubmit={HandlerSubmit}
            validationSchema={validationSchema}
          >
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <Form onSubmit={handleSubmit}>
                <FieldArray
                  name="MusicianInfo"
                  render={(arrayHelpers) => (
                    <>
                      {values.MusicianInfo.map((musician, index) => (
                        <div key={index} className="inboxes_main">
                          <Box className={`input-main`}>
                            <Box className={`input-field-box`}>
                              <Field
                                name={`MusicianInfo.${index}.name`}
                                as={MembershipInput}
                                label={`Enter collaborator’s  ${
                                  index + 1
                                } Name:`}
                                placeholder="Enter collaborator’s name"
                              />
                              <ErrorMessage
                                className="error"
                                name={`MusicianInfo[${index}].name`}
                                component="div"
                              />
                            </Box>
                            <Box className="input-field-box">
                              <Field
                                name={`MusicianInfo.${index}.email`}
                                as={MembershipInput}
                                label="Enter collaborator’s email address:"
                                placeholder="Enter collaborator’s email address"
                              />
                              <ErrorMessage
                                className="error"
                                name={`MusicianInfo[${index}].email`}
                                component="div"
                              />
                            </Box>
                          </Box>
                          <Box className="third-field-box">
                            <Field
                              name={`MusicianInfo.${index}.splitPrice`}
                              as={MembershipInput}
                              label="Split Percentage:"
                              icon={ErrorIcon}
                              title="The publishing % cannot exceed 40%"
                              placeholder="Split Percentage"
                            />
                            <ErrorMessage
                              className="error"
                              name={`MusicianInfo[${index}].splitPrice`}
                              component="div"
                            />
                          </Box>
                          {values.MusicianInfo.length > 1 && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                              }}
                            >
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                style={{
                                  color: "red",
                                  background: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                  fontWeight: 800,
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          )}

                          {index === values.MusicianInfo.length - 1 && (
                            <Box className="btn-main-box">
                              {values.MusicianInfo.length < 5 && (
                                <span
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      name: "",
                                      email: "",
                                      splitPrice: "",
                                    })
                                  }
                                >
                                  add more
                                </span>
                              )}
                              <Button
                                variant="contained"
                                className="green-btn"
                                type="submit"
                              >
                                done
                              </Button>
                            </Box>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default CollaboratorInputsModel;

const MembershipInput = ({
  label = "",
  icon: IconComponent,
  field,
  title = "",
  adornIcon = "",
  type = "text",
  placeholder = "",
  ...props
}) => {
  const [isIconClicked, setIsIconClicked] = useState(false);
  const iconContentRef = useRef(null);

  const handleIconClick = () => {
    setIsIconClicked(!isIconClicked);
  };

  const handleIconHover = () => {
    setIsIconClicked(!isIconClicked);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        iconContentRef.current &&
        !iconContentRef.current.contains(event.target)
      ) {
        setIsIconClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <InputMain>
        <Box className="content">
          <h5>{label}</h5>
          <Box className="icon-main" sx={{ position: "relative" }}>
            {IconComponent && (
              <IconComponent
                className="escalimation"
                onClick={handleIconClick}
                onMouseEnter={() => handleIconHover()}
                onMouseLeave={() => handleIconHover()}
              />
            )}
            {isIconClicked && (
              <Box className="icon-content" ref={iconContentRef}>
                {title}
              </Box>
            )}
          </Box>
        </Box>
        <input {...field} {...props} type={type} placeholder={placeholder} />
      </InputMain>
    </>
  );
};
