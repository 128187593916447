import { useState, useContext } from "react";
import Dropzone from "react-dropzone";
import axios from "axios";
import { Loader } from "../../../components-old/Loader";
import styles from "./styles.module.css";
import { Box } from "native-base";
import DefaultProfile from "./../../../assets/LoginScreens/default.png";
import camera from "../../../assets/LoginScreens/camera.png";
import { AuthContext } from "../Layout";

const UploadProfile = () => {
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);

  const types = ["image/jpeg", "image/png"];
  const { picture, setPicture } = useContext(AuthContext);

  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;

      if (!types.includes(currentFileType)) {
        console.log("not accepted");
        return false;
      }
      return true;
    }
  };

  const handleOnDropLogoImage1 = async (files, rejectedFiles) => {
    const profileData = new FormData();
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (verifyImageFile(files)) {
        profileData.append("file", files[0]);
        profileData.append("type", "Public");
        setIsLoadingProfile(true);
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_FILE_URL}/file/uploadprofilepicture`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: profileData,
        };
        axios(config)
          .then(function (response) {
            setPicture(response.data.file.url);
            setIsLoadingProfile(false);
          })
          .catch(function (error) {
            console.log(error);
            setIsLoadingProfile(false);
          });
        return;
      } else {
        setIsLoadingProfile(false);
        // setErrMsg("invalid file type");
        return;
      }
    }
  };
  return (
    <Box
      position={"absolute"}
      zIndex={99999}
      bottom={{
        sm: isLoadingProfile ? "-110px" : "-70px",
        base: "-50px",
      }}
      //   left={{ sm: isLoadingProfile ? "20px" : "20px", base: "20px" }}
      // size={{ md: "170px", sm: "140px", base: "100px" }}
      left={"50%"}
      height={"150px"}
      width={"150px"}
      resizeMode="cover"
      alignItems={"center"}
      borderRadius={"50%"}
      justifyContent={"center"}
      textAlign={"center"}
      src={require("../../../assets/Profile/userProfile.png")}
      style={{ transform: "translateX(-50%)" }}
    >
      {isLoadingProfile ? (
        <div className={styles.loaderProfile}>
          <Loader />
        </div>
      ) : (
        <Dropzone
          name="dropzone1"
          accept="image/*"
          onDrop={handleOnDropLogoImage1}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <section className={styles.section}>
              <div className={styles.presentation} {...getRootProps()}>
                <div className={styles.UploadIconContainer}>
                  <img
                    alt=""
                    src={picture ? picture : DefaultProfile}
                    style={{
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                    className=""
                  />
                  <input {...getInputProps()} />
                  {/* <input className="input-zone" {...getInputProps()} /> */}
                  {/* {!profilePicture && (
                <p className="text-capitalize mb-4 mx-auto w-75">
                  drop your image here
                </p>
              )} */}

                  <button
                    style={{
                      //   background: "#2ebb55",
                      background: "none",
                      cursor: "pointer",
                      outline: "none",
                      border: "none",
                      color: "#fff",

                      position: "absolute",
                      top: "70%",
                      marginLeft: "-35px",
                      marginTop: "-5px",
                      left: "100%",
                      width: "50%",
                      padding: "0.5rem 1rem",
                      borderRadius: "5px",
                    }}
                  >
                    <span>
                      <img
                        alt="noimage"
                        width={"25px"}
                        height={"25px"}
                        src={camera}
                        className={"cameraimg"}
                      />
                    </span>
                    {/* {"Upload"} */}
                  </button>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      )}
    </Box>
  );
};

export default UploadProfile;
