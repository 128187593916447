import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import ButtonComponent from "../UniversalComponents/ButtonComponent";
import { GreenMusicIcon } from "../../assets/smartlink";
import CloseIcon from "@mui/icons-material/Close";

export function BeatsCheckoutSuccessModal({ openModal, closeModal }) {
  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "#141515",
          boxShadow: 24,
          p: 4,
          borderRadius: '8px'
        }}
      >
        <IconButton
          aria-label="close"
          style={{ position: 'absolute', top: 1, right: 40, color: 'white',marginTop:"25px"  }}
          onClick={closeModal}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <div style={{textAlign: 'center', marginTop: '30px'}}>
          <img src={GreenMusicIcon} alt="green_music_icon" style={{ width: '60px', height: '60px' }} />
        </div>
        <Typography
          id="modal-title"
          variant="h5"
          component="p"
          sx={{
            color: 'white',
            textAlign: 'center'
          }}
          style={{ marginTop: '10px' }}
        >
          Congratulations
        </Typography>
        <Typography
          id="modal-description"
          variant="h8"
          component="p"
          sx={{
            color: '#AFAFAF',
            textAlign: 'center'
          }}
          style={{ marginTop: '10px' }}
        >
          Congratulation you successfully purchased this beat!
        </Typography>
        <ButtonComponent
            textProperties={{
              color: "#E3E3E3",
              fontWeight: "medium",
              fontSize: { xl: "1.2rem", md: "1rem", base: "1rem" },
              fontFamily: "poppins",
              textTransform: "capitalize",
            }}
            height={{ lg: "40px", md: "35px", base: "48px" }}
            backgroundColor={"#2EBB55"}
            mt={{ md: 5, base: 5 }}
            mb={10}
            flag={false}
            text='Done'
            onpress={closeModal}
          ></ButtonComponent>
      </Box>
    </Modal>
  );
}
