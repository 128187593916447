import { AppBar, Box, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  marginTop: "10rem",
  marginBottom: "4rem",
  marginBottom: "4rem",
  ul: {
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  li: {
    borderRadius: "3px",
    background: "#0A0B0A",
    "&:first-child": { color: "#fff" },
    "&:last-child": { color: "#fff" },
    "& .MuiPaginationItem-ellipsis": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "5px",
    },
    button: {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      borderRadius: "2px",
      "&.Mui-disabled": {
        color: "#AFAFAF",
      },
      "&.Mui-selected": {
        padding: "0px 0px",
        borderRadius: "2px",
        color: "#fff",
        margin: "0px",
        background: "#2EBB55",
        "&:hover": {
          color: "#fff",
          background: "#2EBB55",
        },
      },
    },
  },

  //   [theme.breakpoints.up("md")]: {
  //     width: "50%",
  //   },
  //   [theme.down.up("md")]: {
  //     width: "100%",
  //   },
}));

export { Main };
