import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

const ModelContentBox = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",

  "& .dell-icon": {
    display: "flex",

    flexDirection: "column",

    gap: "1.88rem",

    "& h3": {
      fontStyle: "normal",

      fontWeight: 500,

      lineHeight: "normal",

      color: theme.palette.secondary.darker,

      fontFamily: theme.typography.fontFamily,

      fontSize: theme.typography.h3,
    },
  },

  "& p": {
    width: "280px",

    textAlign: "center",

    color: theme.palette.secondary.dark,

    fontFamily: theme.typography.fontFamily,

    fontSize: theme.typography.body1,

    fontStyle: "normal",

    fontWeight: 400,

    lineHeight: "normal",

    marginTop: "0.5rem",
  },

  "& .btn-box": {
    display: "flex",

    justifyContent: "center",

    width: "100%",

    marginTop: "1rem",

    gap: "2.5rem",

    "& .cancel-btn, & .create-btn": {
      padding: " 0.65625rem 1.875rem",

      borderRadius: "0.3125rem",

      textTransform: "capitalize",

      border: "1px solid #fff",

      fontFamily: theme.typography.fontFamily,

      fontSize: theme.typography.body1,

      color: theme.palette.secondary.light,

      fontStyle: "normal",

      fontWeight: 500,

      lineHeight: "normal",
    },

    "& .create-btn": {
      background: theme.palette.secondary.main,

      border: "none",
    },
  },
}));

export { ModelContentBox };
