import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { TextField, Button, Grid, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { addTag } from '../../../Graphql_services/SoundPackMutation';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import styles from '../styles.module.css';
import { useMutation } from '@apollo/client';

export function AddTags(props) {
  const navigate = useNavigate();
  const { openModal, closeModal, heading, label, handleUpdateTag, type } =
    props;
  const [inputValue, setInputValue] = useState('');

  const schema = yup.object().shape({
    tag: yup.string().required().min(3).max(50),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const darkTheme = createTheme({
    palette: {
      type: 'dark',
    },
  });

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const modalStyle = getModalStyle();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSave = (data) => {
    // Handle saving the input value

    addSoundPackTags({ variables: { value: data.tag, type } });

    closeModal();
  };

  const [addSoundPackTags] = useMutation(addTag, {
    context: { clientName: 'soundpack' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const { addTag } = data;
      if (addTag) {
        const { success, message, isTokenExpired, tag } = addTag;

        if (success === true) {
          handleUpdateTag(tag);
          toast.success(message);
        }
        if (success === false) {
          if (isTokenExpired) {
            localStorage.clear();
            navigate('/signin');
          } else {
            toast.error(message);
          }
        }
      }
    },
    onError(err) {
      console.log('error on getting getMyPaymentMethod', err);
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <Modal open={openModal} onClose={closeModal}>
        <div style={modalStyle} className={styles.modalPaper}>
          <div
            className={styles.modalHeader}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='p' className={styles.heading}>
              Add {heading}
            </Typography>
            <IconButton onClick={closeModal}>
              <Close sx={{ color: '#AFAFAF' }} />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit(handleSave)}>
            <Grid container direction='row' className={styles.modalHeader}>
              <p className={styles.labelContent}>{label} Name:</p>
              <TextField
                {...register('tag')}
                name='tag'
                fullWidth
                className={styles.textField}
              />
              <p className={styles.error}>{errors.tag?.message ?? ' '}</p>
            </Grid>
            <Grid container direction='row-reverse'>
              <br />
              <Button
                variant='contained'
                type='submit'
                className={styles.saveBtn}
              >
                Save
              </Button>
              <Button
                variant='contained'
                onClick={closeModal}
                style={{ marginRight: '10px' }}
                className={styles.cancelBtn}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </div>
      </Modal>
    </ThemeProvider>
  );
}
