import React from "react";
import ProducersLayouts from "./ProducersLayouts";
import ProResetPasswordPopup from "../../../../components/smartlink/producers/auths/ProResetPasswordPopup";

const ProducersResetPassPage = () => {
  return (
    <ProducersLayouts>
      <ProResetPasswordPopup />
    </ProducersLayouts>
  );
};

export default ProducersResetPassPage;
