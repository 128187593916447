import styles from "./assets.module.css";
export default function CrownIcon() {
  return (
    <div className={styles.crown_background}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.002 44H14.002C13.6049 43.9974 13.2249 43.8385 12.9442 43.5578C12.6635 43.277 12.5046 42.897 12.502 42.5C12.5046 42.103 12.6635 41.723 12.9442 41.4422C13.2249 41.1615 13.6049 41.0026 14.002 41H34.002C34.399 41.0026 34.779 41.1615 35.0597 41.4422C35.3405 41.723 35.4993 42.103 35.502 42.5C35.4993 42.897 35.3405 43.277 35.0597 43.5578C34.779 43.8385 34.399 43.9974 34.002 44Z"
          fill="#2EBB55"
        />
        <path
          d="M40.6973 11.0427L32.6973 16.7627C32.4471 16.9393 32.16 17.0565 31.8576 17.1054C31.5553 17.1543 31.2459 17.1336 30.9528 17.0449C30.6596 16.9562 30.3906 16.8018 30.1661 16.5935C29.9417 16.3852 29.7677 16.1284 29.6573 15.8427L25.8773 5.76475C25.7341 5.38398 25.4782 5.05593 25.1438 4.82437C24.8093 4.59281 24.4122 4.46875 24.0053 4.46875C23.5985 4.46875 23.2014 4.59281 22.8669 4.82437C22.5324 5.05593 22.2765 5.38398 22.1333 5.76475L18.3333 15.8228C18.2291 16.1109 18.0592 16.3708 17.8371 16.5818C17.6149 16.7928 17.3467 16.9492 17.0536 17.0385C16.7605 17.1278 16.4506 17.1475 16.1485 17.0962C15.8464 17.0449 15.5605 16.9238 15.3133 16.7428L7.31334 11.0227C6.96372 10.7772 6.54513 10.6491 6.11796 10.6569C5.69079 10.6646 5.27714 10.8079 4.93669 11.066C4.59624 11.3242 4.34659 11.6838 4.22377 12.093C4.10095 12.5022 4.11131 12.9398 4.25334 13.3427L12.5733 36.6427C12.7111 37.0306 12.966 37.366 13.3029 37.6025C13.6397 37.839 14.0418 37.9649 14.4533 37.9628H33.5133C33.924 37.9609 34.3242 37.8334 34.6602 37.5974C34.9963 37.3615 35.2521 37.0283 35.3933 36.6427L43.7133 13.3427C43.8602 12.9446 43.8755 12.5098 43.757 12.1023C43.6385 11.6947 43.3925 11.3359 43.055 11.0786C42.7175 10.8212 42.3064 10.6789 41.8821 10.6724C41.4577 10.666 41.0425 10.7958 40.6973 11.0427ZM28.9973 29.5028H18.9973C18.5995 29.5028 18.218 29.3447 17.9367 29.0634C17.6554 28.7821 17.4973 28.4006 17.4973 28.0028C17.4973 27.6049 17.6554 27.2234 17.9367 26.9421C18.218 26.6608 18.5995 26.5028 18.9973 26.5028H28.9973C29.3952 26.5028 29.7767 26.6608 30.058 26.9421C30.3393 27.2234 30.4973 27.6049 30.4973 28.0028C30.4973 28.4006 30.3393 28.7821 30.058 29.0634C29.7767 29.3447 29.3952 29.5028 28.9973 29.5028Z"
          fill="#2EBB55"
        />
      </svg>
    </div>
  );
}
