import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const TableMain = styled(Box)(({ theme }) => ({
  padding: "25px",
  borderRadius: "5px",
  border: "1px solid #414241",
  background: "#1C1B1B",
  table: {
    width: "100%",
    thead: {
      margin: 0,
      padding: 0,
      th: {
        color: "#AFAFAF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        textTransform: "capitalize",
      },
      tr: {
        display: "block",
        "& :nth-child(1)": {
          width: "120px",
        },
        th: {
          textAlign: "left",
        },
      },
    },
    tbody: {
      "& tr:nth-child(1)": {
        "&::after": {
          content: '""',
          display: "block",
          height: 1,
          width: "100%",
          background: "#414241",
          marginTop: "5px",
        },
      },
      "& tr:nth-child(2)": {
        "&::after": {
          content: '""',
          display: "block",
          height: 1,
          width: "100%",
          background: "#414241",
          marginTop: "5px",
        },
      },
      tr: {
        margin: "12px 0",
        display: "block",
        "& :nth-child(1)": {
          color: "#AFAFAF",
          width: "120px",
        },
        td: {
          textAlign: "left",
          color: "#FFFFFF",
        },
      },
    },
  },
}));

export { TableMain };
