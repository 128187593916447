import React, { useEffect, useRef, useState } from 'react';

import WaveSurfer from 'wavesurfer.js';
import styles from './player.module.css';
import useAppState from '../../context/useAppState';
import axios from 'axios';

const WavePlayer = ({ url, isSelected = false, file, height }) => {
  const formWaveSurferOptions = (ref) => ({
    container: ref,
    waveColor: 'rgb(255 255 255 / 60%)',
    progressColor: 'rgb(46 187 85 / 40%)',
    cursorColor: 'rgb(46 187 85 / 0%)',
    barWidth: 1,
    barRadius: 3,
    responsive: true,
    height: height ? height - 20 : 18,
    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
  });
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [load, setLoad] = useState(true);

  const { currentUrl, updateURL } = useAppState('common');

  // useEffect(() => {
  //   if (url) {
  //     setLoad(true);
  //     async function fetchData() {
  //       setPlay(false);
  //       var peaks = [];

  //       if (file?.waveUrl) {
  //         peaks = await axios.get(file.waveUrl);
  //       }

  //       try {
  //         if (
  //           waveformRef.current?.children &&
  //           waveformRef.current?.children[0]
  //         ) {
  //           waveformRef.current.removeChild(waveformRef.current.children[0]);
  //         }
  //       } catch (error) {
  //         console.info('error while deleting dom => ', error);
  //       }

  //       const options = formWaveSurferOptions(waveformRef.current);
  //       wavesurfer.current = WaveSurfer.create(options);

  //       if (peaks && peaks.data && peaks.data.length) {
  //         wavesurfer.current.load(url, peaks.data);
  //       } else {
  //         wavesurfer.current.load(url);
  //       }
  //       wavesurfer.current.load(url);

  //       wavesurfer.current.on('ready', function () {
  //         // make sure object is still available when file is loaded

  //         if (wavesurfer.current) {
  //           wavesurfer.current.setVolume(1);
  //           setLoad(false);
  //         }
  //       });
  //       wavesurfer.current.on('finish', function () {
  //         setPlay(false);
  //         wavesurfer.current.seekTo(0);
  //       });
  //     }

  //     fetchData();
  //   }
  //   return () => {
  //     updateURL('');
  //     setPlay(false);

  //     wavesurfer.current?.destroy();
  //   };
  // }, [url, file]);

  useEffect(() => {
    setPlay(false);
    var peaks = [];

    if (file?.waveUrl) {
      peaks = axios.get(file.waveUrl);
    }

    try {
      if (waveformRef.current?.children && waveformRef.current?.children[0]) {
        waveformRef.current.removeChild(waveformRef.current.children[0]);
      }
    } catch (error) {
      console.info('error while deleting dom => ', error);
    }
    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);
    if (peaks && peaks.data && peaks.data.length) {
      wavesurfer.current.load(url, peaks.data);
    } else {
      wavesurfer.current.load(url);
    }

    wavesurfer.current.on('ready', function () {
      // make sure object is still available when file is loaded

      if (wavesurfer.current) {
        wavesurfer.current.setVolume(1);
      }
    });
    wavesurfer.current.on('finish', function () {
      setPlay(false);
      wavesurfer.current.seekTo(0);
    });

    return () => wavesurfer.current.destroy();
  }, [url, file]);

  const handlePlayPause = () => {
    if (!isSelected) {
      updateURL(url);
    }
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  return (
    <div
      className={styles.waveform_layout}
      style={{ height: height ? height : 30 }}
    >
      <div onClick={handlePlayPause} className={styles.play_button}>
        {!playing ? (
          <img
            className={styles.play_icon}
            alt='play'
            src='/v2Assets/PlayIcon.png'
          />
        ) : (
          <img
            className={styles.play_icon}
            alt='pause'
            src='/v2Assets/PauseIcon.png'
          />
        )}
      </div>
      <div style={{ width: '180px' }}>
        {/* This sets the container width to 80% of its parent */}
        <div ref={waveformRef} />
      </div>
    </div>
  );
};

export default WavePlayer;
