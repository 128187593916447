import React from "react";
import ReactTimeAgo from "react-time-ago";

const Timeago = ({ date }) => {
  return (
    <div>
      <ReactTimeAgo date={date} locale="en-US" timeStyle="round-minute" />
    </div>
  );
};

export default Timeago;
