/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import gql from "graphql-tag";

export const create_opportunity = gql`
  mutation (
    $isVisible: Boolean
    $title: String
    $description: String
    $userId: ID
    $shouldCreateFirstVariant: Boolean
    $metafields: [MetafieldInput] # $key1: String! # $value1: String! # $key2: String! # $value2: String! # $key3: String! # $value3: String! # $key4: String! # $value4: String! # $key5: String! # $value5: String! # $key6: String! # $value6: String! # $key7: String! # $value7: String! # $key8: String! # $value11: String! # $value8: String! # $description8: String! # $key9: String! # $value9: String! # $key10: String! # $value10: String! # $key11: String!
    $referenceTrack: String # $key12: String! # $value12: String! # $key13: String!
    $tokenCount: Int # $value13: String! # $key14: String! # $value14: String! # $key15: String! # $value15: String!
  ) {
    createProduct(
      input: {
        shopId: "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="
        product: {
          isVisible: $isVisible
          title: $title
          description: $description
          uploadedBy: { userId: $userId }
          upVotes: 0
          totalCarts: 0
          productViews: 0
          metafields: $metafields
          referenceTrack: $referenceTrack
          tokenCount: $tokenCount
        }
        shouldCreateFirstVariant: $shouldCreateFirstVariant
      }
    ) {
      product {
        _id
        title
        description
        isDeleted
        isVisible
        referenceTrack
        tokenCount
        uploadedBy {
          userId
        }
        variants {
          _id
        }
        metafields {
          key
          value
        }
      }
    }
  }
`;

export const update_product = gql`
  #mutaion to add collaborators
  mutation (
    $productId: ID!
    $title: String
    $description: String
    $metafields: [MetafieldInput]
  ) {
    updateProduct(
      input: {
        shopId: "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="
        productId: $productId
        product: {
          title: $title
          description: $description
          metafields: $metafields
        }
      }
    ) {
      product {
        _id
        title
        description
        isDeleted
        isVisible
        uploadedBy {
          userId
        }
        variants {
          _id
        }
        metafields {
          key
          value
        }
      }
    }
  }
`;

export const update_variant = gql`
  mutation ($variantId: ID!) {
    updateProductVariant(
      input: {
        shopId: "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="
        variantId: $variantId
        variant: {
          isVisible: true
          title: "first variant"
          attributeLabel: "created by graphql"
          price: 100.00
        }
      }
    ) {
      variant {
        _id
        isVisible
      }
    }
  }
`;

export const publish_product = gql`
  mutation ($productId: [ID]!) {
    publishProductsToCatalog(productIds: $productId) {
      _id
      createdAt
      shop {
        _id
      }
      product {
        _id
      }
    }
  }
`;

export const get_Catalog_items = gql`
  query catalogItems(
    $flag: String
    $offset: Int
    $pageSize: ConnectionLimitInt
  ) {
    catalogItems(
      shopIds: ["cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="]
      flag: $flag
      sortBy: createdAt
      sortOrder: desc
      first: $pageSize
      offset: $offset
    ) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
        __typename
      }
      edges {
        cursor
        node {
          _id
          ... on CatalogItemProduct {
            product {
              _id
              title
              slug
              description
              vendor
              productId
              isLowQuantity
              isSoldOut
              upVotes
              referenceTrack
              tokenCount
              uploadedBy {
                name
                userId
              }
              isBackorder
              variants {
                variantId
                _id
              }
              metafields {
                description
                key
                namespace
                scope
                value
                valueType
                __typename
              }
              shop {
                currency {
                  code
                  __typename
                }
                __typename
              }
              pricing {
                compareAtPrice {
                  displayAmount
                  __typename
                }
                currency {
                  code
                  __typename
                }
                displayPrice
                minPrice
                maxPrice
                __typename
              }
              primaryImage {
                URLs {
                  thumbnail
                  small
                  medium
                  large
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const place_bid = gql`
  mutation (
    $shopId: ID!
    $productId: ID!
    $variantId: ID!
    $soldby: ID!
    $bidSplit: String!
    $fileId: String!
    $fileUrl: String
    $name: String!
    $bidMetaFieldsInput: [bidMetaFieldsInput]
    $tuneVibe: [String]
    $exclusivePrice: Float
    $pitchingPeriod: String
    $isMasterSample: Boolean
  ) {
    placeBidOnProduct(
      input: {
        shopId: $shopId
        productId: $productId
        variantId: $variantId
        bidSplit: $bidSplit
        offer: { amount: { amount: 90.00, currencyCode: "USD" } }
        isShortList: false
        isFavourite: false
        soldby: $soldby
        fileId: $fileId
        fileUrl: $fileUrl
        name: $name
        bidMetaFieldsInput: $bidMetaFieldsInput
        productPrice: { amount: 100.00, currencyCode: "USD" }
        tuneVibe: $tuneVibe
        exclusivePrice: $exclusivePrice
        pitchingPeriod: $pitchingPeriod
        isMasterSample: $isMasterSample
        offerType: text
      }
    ) {
      bidId
    }
  }
`;

export const get_my_opportunities = gql`
  query {
    viewer {
      firstName
      lastName
      Product {
        _id
        title
        description
        createdAt
        isVisible
        publishedAt
        upVotes
        referenceTrack
        tokenCount
        slug
        metafields {
          key
          value
          description
          valueType
        }
        variants {
          _id
          isVisible
        }
      }
    }
  }
`;

export const get_my_submissions = gql`
  query {
    getBidsbyAccountId(isSeller: false) {
      _id
      productId
      product {
        _id
        title
        attributeLabel
        metafields {
          key
          value
        }
      }
      reactionProductId
      reactionVariantId
      productSlug
      createdAt
      fileId
      fileUrl
      name
      description
      createdByinfo {
        name
      }
      soldByInfo {
        name
      }
    }
  }
`;

export const delete_bid = gql`
  # mutation for remove bid
  mutation ($bidId: String!) {
    removeBid(bidId: $bidId) {
      success
      message
      status
    }
  }
`;

export const delete_opportunity = gql`
  # mutation for remove opportunity
  mutation ($productId: String!) {
    removeProduct(productId: $productId) {
      success
      status
      message
    }
  }
`;

export const add_collaborators = gql`
  #mutaion to add collaborators
  mutation (
    $productId: ID!
    $key1: String!
    $value1: String!
    $key2: String!
    $value2: String!
    $key3: String!
    $value3: String!
    $key4: String!
    $value4: String!
    $key5: String!
    $value5: String!
    $key6: String!
    $value6: String!
    $key7: String!
    $value7: String!
    $key8: String!
    $value8: String!
    $key9: String!
    $value9: String!
    $key10: String!
    $value10: String!
    $key11: String!
    $value11: String!
    $description11: String!
    $valueType11: String!
    $key12: String!
    $value12: String!
    $description12: String!
    $valueType12: String!
    $key13: String!
    $value13: String!
    $description13: String!
    $valueType13: String!
  ) {
    updateProduct(
      input: {
        shopId: "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="
        productId: $productId
        product: {
          upVotes: 0
          metafields: [
            { key: $key1, value: $value1 }
            { key: $key2, value: $value2 }
            { key: $key3, value: $value3 }
            { key: $key4, value: $value4 }
            { key: $key5, value: $value5 }
            { key: $key6, value: $value6 }
            { key: $key7, value: $value7 }
            { key: $key8, value: $value8 }
            { key: $key9, value: $value9 }
            { key: $key10, value: $value10 }
            {
              key: $key11
              value: $value11
              description: $description11
              valueType: $valueType11
            }
            {
              key: $key12
              value: $value12
              description: $description12
              valueType: $valueType12
            }
            {
              key: $key13
              value: $value13
              description: $description13
              valueType: $valueType13
            }
          ]
        }
      }
    ) {
      product {
        _id
        title
        description
        isDeleted
        isVisible
        uploadedBy {
          userId
        }
        variants {
          _id
        }
        metafields {
          key
          value
        }
      }
    }
  }
`;
export const getBidsByAccountId = gql`
  query {
    getBidsbyAccountId(isSeller: true) {
      _id
      productId
      product {
        _id
        title
        attributeLabel
        metafields {
          key
          value
        }
      }
      createdAt
      fileId
      fileUrl
      name
      description
      createdBy
      createdByinfo {
        name
      }
      soldByInfo {
        name
      }
    }
  }
`;

export const get_my_bids_on_product = gql`
  query getMyBidsOnProduct($productId: ID!, $variantId: ID!) {
    getMyBidsOnProduct(
      input: { productId: $productId, variantId: $variantId }
    ) {
      _id
      isShortList
      isFavourite
      fileUrl
      fileId
      bidMetaFields {
        collaboratorEmail
        collaboratorSplit
        collaboratorName
        status
      }
      tuneVibe
      exclusivePrice
      pitchingPeriod
      isMasterSample
      createdAt
    }
  }
`;

export const get_bids_on_my_product = gql`
  query getBidsOnMyProduct($productId: ID!, $variantId: ID!) {
    getBidsOnMyProduct(
      input: { productId: $productId, variantId: $variantId }
    ) {
      _id
      name
      productSlug
      reactionProductId
      reactionVariantId
      createdAt
      soldBy
      isShortList
      isFavourite
      fileUrl
      createdBy
      bidMetaFields {
        collaboratorEmail
        collaboratorSplit
        collaboratorName
        status
      }
      tuneVibe
      exclusivePrice
      pitchingPeriod
      isMasterSample
      fitGuidline
    }
  }
`;

export const update_bid_on_product = gql`
  mutation updateBidonProduct(
    $isShortList: Boolean!
    $isFavourite: Boolean!
    $bidId: String!
  ) {
    updateBidonProduct(
      input: {
        isShortList: $isShortList
        isFavourite: $isFavourite
        bidId: $bidId
      }
    ) {
      status
      success
      message
    }
  }
`;

export const update_bid_flag_on_product = gql`
  mutation updateBidFlagonProduct($fitGuidline: Boolean!, $bidId: String!) {
    updateBidFlagonProduct(
      input: { fitGuidline: $fitGuidline, bidId: $bidId }
    ) {
      status
      success
      message
    }
  }
`;
export const getOpportunities = gql`
  query getOpportunities($input: OpportunitiesInput) {
    Opportunities(input: $input) {
      id
      userId
      title
      description
      artistPhoto
      artistName
      monthlyListeners
      dueDate
      genre
      bpm
      key
      scale
      fileFormat
      terms
      advance
      points
      publishing
      isSpotifyLink
      spotifyLink
      tokenCount
      cover_photo
      upVotes
      soldby
      lookingFor
      referenceTrack
      published
    }
  }
`;
export const getCatalogItems = gql`
  query getCatalogItems(
    $flag: String
    $offset: Int
    $pageSize: ConnectionLimitInt
  ) {
    catalogItems(
      shopIds: ["cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="]
      flag: $flag
      sortBy: upVotes
      sortOrder: desc
      first: $pageSize
      offset: $offset
    ) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
        __typename
      }
      edges {
        cursor
        node {
          _id
          ... on CatalogItemProduct {
            product {
              _id
              title
              slug
              description
              vendor
              productId
              isLowQuantity
              isSoldOut
              upVotes
              referenceTrack
              tokenCount
              uploadedBy {
                name
                userId
              }
              isBackorder
              variants {
                variantId
                _id
              }
              metafields {
                description
                key
                namespace
                scope
                value
                valueType
                __typename
              }
              shop {
                currency {
                  code
                  __typename
                }
                __typename
              }
              pricing {
                compareAtPrice {
                  displayAmount
                  __typename
                }
                currency {
                  code
                  __typename
                }
                displayPrice
                minPrice
                maxPrice
                __typename
              }
              primaryImage {
                URLs {
                  thumbnail
                  small
                  medium
                  large
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const test_collaborators = gql`
  #mutaion to add collaborators
  mutation ($productId: ID!, $metafields: [MetafieldInput]) {
    updateProduct(
      input: {
        shopId: "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="
        productId: $productId
        product: { upVotes: 0, metafields: $metafields }
      }
    ) {
      product {
        _id
        title
        description
        isDeleted
        isVisible
        uploadedBy {
          userId
        }
        variants {
          _id
        }
        metafields {
          key
          value
        }
      }
    }
  }
`;

export const get_bids_count = gql`
  query getProductBids($productId: ID!, $variantId: ID!) {
    getProductBids(input: { productId: $productId, variantId: $variantId }) {
      bids {
        _id
      }
      count
    }
  }
`;

export const get_bids_count2 = `
query getProductBids($productId: ID!, $variantId: ID!) {
  getProductBids(input: { productId: $productId, variantId: $variantId }) {
    bids {
      _id
    }
    count
  }
}
`;

export const getWishListByAccountId = gql`
  query getWishListById($accountId: ID!) {
    wishlistByAccountId(
      accountId: $accountId
      shopId: "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="
    ) {
      _id
      email
      items {
        totalCount
        nodes {
          _id
          title
          productConfiguration {
            productId
            productVariantId
          }
          price {
            displayAmount
          }
        }
      }
    }
  }
`;

export const addToWishListItems = gql`
  mutation addWishlistItems(
    $wishlistId: ID!
    $productId: ID!
    $variantId: ID!
  ) {
    addWishlistItems(
      input: {
        wishlistId: $wishlistId
        items: [
          {
            price: { amount: 100.00, currencyCode: "USD" }
            productConfiguration: {
              productId: $productId
              productVariantId: $variantId
            }
          }
        ]
      }
    ) {
      wishlist {
        _id
        items {
          totalCount
          nodes {
            _id
            title
            price {
              displayAmount
            }
          }
        }
      }
    }
  }
`;

export const removeToWishList = gql`
  mutation removeWishListItems(
    $wishlistId: ID!
    $productId: ID!
    $wishlistItemId: ID!
  ) {
    removeWishlistItems(
      input: {
        wishlistId: $wishlistId
        productId: $productId
        wishlistItemIds: [$wishlistItemId]
      }
    ) {
      wishlist {
        _id
        items {
          totalCount
          nodes {
            _id
            title
          }
        }
      }
    }
  }
`;

export const createWishList = gql`
  mutation createWishlist($productId: ID!, $variantId: ID!) {
    createWishlist(
      input: {
        shopId: "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="
        items: {
          price: { amount: 100.00, currencyCode: "USD" }
          productConfiguration: {
            productId: $productId
            productVariantId: $variantId
          }
        }
      }
    ) {
      wishlist {
        _id
        account {
          name
        }
        items {
          totalCount
          nodes {
            _id
            attributes {
              label
              value
            }
            variantTitle
          }
        }
      }
    }
  }
`;

export const getProductById = gql`
  query product($productId: ID!) {
    product(
      productId: $productId
      shopId: "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="
    ) {
      title
      description
      slug
      _id
      vendor
      upVotes
      referenceTrack
      tokenCount
      uploadedBy {
        name
        userId
      }
      variants {
        _id
      }
      metafields {
        key
        value
        description
        valueType
        scope
      }
    }
  }
`;

export const getAllFilesOnProductQuery = gql`
  query getAllFilesOnMyProduct($productId: ID!, $variantId: ID!) {
    getAllFilesOnMyProduct(
      input: { productId: $productId, variantId: $variantId }
    ) {
      _id
      fileUrl
    }
  }
`;
