import moment from "moment";
import { object, string, number, boolean, date } from "yup";

const today = moment().format("YYYY-MM-DD");
const OpportunitiesSchema = object().shape({
  title: string()
    .required("File Name is required")
    .min(3, "File Name must be greater than 3 characters")
    .max(50, "File Name must be less than or equal to 50 characters"),
  lookingFor: string().required("Looking For is required"),
  expireDate: date()
    .min(today, "Expiration date can’t be before today’s date")
    .required()
    .typeError("Invalid Date selection!"),
  genre: string().required("Genre is required"),
  bpm: string(),
  // .matches(
  //   /^\d+(?:-\d+)?$/,
  //   "BPM value should be valid eg. 10-30"
  // ),
  key: string(),
  scale: string(),
  monthlyListeners: string().required("Monthly Listeners is required"),
  fileFormat: string().required("File Format is required"),
  coverPhoto: object()
    .shape({
      name: string().required(),
      url: string().required(),
    })
    .required("Cover Photo is required"),
  artist: object()
    .shape({
      name: string().required("Name is required"),
      status: string().required("Status is required"),
    })
    .default(undefined)
    .required("Artist is required"),
  hasReferenceTrack: boolean(),
  referenceTrack: object()
    .shape()
    .nullable(true)
    .when("hasReferenceTrack", {
      is: true,
      then: () =>
        object()
          .shape({
            name: string().required(),
            url: string().required(),
          })
          .required("Upload reference track"),
    }),
});

export default OpportunitiesSchema;
