import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ModelContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "80vh",
  overflowY: "scroll",
  "& .error": {
    fontFamily: theme.typography.fontFamily,
    color: "red",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  "& .error-input input": {
    border: "1px solid red",
  },
  flexDirection: "column",

  label: {
    color: "#fff !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "10px",
  },
  "& .description_main": {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    textarea: {
      width: "100%",
      borderRadius: "0.3125rem",
      resize: "none",
      padding: "0.5rem",
      // background: "#414241",
      background:
        "linear-gradient(0deg, #1C1B1B, #1C1B1B),\nlinear-gradient(0deg, #414241, #414241)",
      border: "1px solid rgba(65, 66, 65, 1)",
      color: "#fff",
      fontFamily: theme.typography.fontFamily,
      height: "113px",
      bordeRadius: "5px",
      "&:focus": {
        border: "1px solid #414241",
        outline: "none",
      },
    },
  },
  "& .error-description textarea": {
    border: "1px solid red",
  },
  "& .CreateModelMead": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  "& .heading-box": {
    width: "100%",
    "& h3": {
      color: "#E3E3E3",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.h3,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textAlign: "center",
    },
  },

  "& .main-icon-box": {
    display: "flex",
    flexDirection: "column",
    width: "100px",
    marginTop: "2rem",
    img: {
      width: "90px",
      height: "90px",
      objectFit: "cover",
      cursor: "pointer",
    },
  },

  "& .upload-btn": {
    width: "156px",
    height: "40px",
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    border: "1px solid #AFAFAF",
    borderRadoius: "0.3125rem",
    marginTop: "2rem",
    svg: {
      color: "#fff",
      fill: "#fff",
    },
    "&:disabled": {
      color: "gray",
      border: "1px solid gray",
      cursor: "not-allowed",
    },
  },

  "& .input-box": {
    display: "flex",

    flexDirection: "column",

    width: "100%",

    marginTop: "3rem",

    gap: "1rem",
  },

  "& .Inbox_Type_box": {
    marginTop: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 20,
  },

  "& .btn-box": {
    display: "flex",

    justifyContent: "flex-end",

    width: "100%",

    marginTop: "2rem",

    gap: "1.5rem",

    "& .cancel-btn, & .create-btn": {
      padding: " 0.65625rem 1.875rem",
      borderRadius: "0.3125rem",
      textTransform: "capitalize",
      border: "1px solid #fff",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.body1,
      color: theme.palette.secondary.light,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      width: "125px",
    },
    "& .create-btn": {
      background: theme.palette.secondary.main,
      border: "none",
    },
  },
  "& .error-input input": {
    border: "1px solid red !important",
  },

  "& .inbox__type": {
    h6: {
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "27px",
      letterSpacing: "0em",
      textAlign: "left",
      margin: "0",
      color: "rgba(255, 255, 255, 1)",
      marginBottom: "5px",
    },
    "& .type_items": {
      color: "rgba(175, 175, 175, 1)",
    },
    width: "100%",
    marginTop: "8px",
  },
}));

const Modelstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "background.paper",
  //   boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: "auto"
};

export { ModelContentBox, Modelstyle };
