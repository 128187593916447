import React from "react";

const AddCollaboratro = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 19.5H14.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 21.5V17.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1605 10.8708C12.0508 10.8608 11.9403 10.8608 11.8305 10.8708C10.6694 10.8276 9.57161 10.3304 8.77324 9.48619C7.97487 8.64196 7.5397 7.51814 7.56132 6.35639C7.58293 5.19465 8.05961 4.08779 8.88884 3.27385C9.71807 2.45991 10.8336 2.00391 11.9955 2.00391C13.1575 2.00391 14.273 2.45991 15.1023 3.27385C15.9315 4.08779 16.4082 5.19465 16.4298 6.35639C16.4514 7.51814 16.0162 8.64196 15.2179 9.48619C14.4195 10.3304 13.3217 10.8276 12.1605 10.8708V10.8708Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9891 21.8097C10.2208 21.8436 8.4806 21.3642 6.97906 20.4297C4.55906 18.8097 4.55906 16.1697 6.97906 14.5597C8.49232 13.6565 10.2218 13.1797 11.9841 13.1797C13.7464 13.1797 15.4758 13.6565 16.9891 14.5597"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddCollaboratro;
