import { Box } from "@mui/material";
import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { Loader } from "../components-old/Loader";
import useAuth from "../context/AuthContext";

function SmartLinkAllowedRoutes({ component: Component, ...rest }) {
  const { isAuthenticated, loading, loadUser, user } = useAuth();
  React.useEffect(() => {
    if (isAuthenticated) {
      return <Outlet />;
    } else {
      loadUser();
    }
  }, []);

  //   // Check if the user is authenticated
  //   if (loading) {
  //     return (
  //       <Box
  //         style={{
  //           height: "100vh",
  //           width: "100%",
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //         }}
  //       >
  //         <Loader />
  //       </Box>
  //     );
  //   }

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }
  const isAllowed =
    user?.roleId?.roleName === "Artist" ||
    user?.roleId?.roleName === "Admin" ||
    user?.roleId?.roleName === "Music Producer";

  if (!isAllowed) {
    return <Navigate to="/403" />;
  }

  return <Outlet />;
}

export default SmartLinkAllowedRoutes;
