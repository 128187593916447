import {
  fetchConnectMeUsers,
  sendConnectionRequest,
  sendSubmissionConnection,
  recindConnectionRequest,
  fetchUserConnectionRequests,
  acceptConnectionRequest,
  fetchUserNetwork,
  fetchUser,
} from "../apis/connect-me";

const useConnectMe = () => {
  const loadUser = async (userId) => {
    try {
      const data = await fetchUser(userId);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while loading user = ", error);
      throw error;
    }
  };

  const loadConnectMeUsers = async (userID, page, limit, query) => {
    try {
      const data = await fetchConnectMeUsers(userID, page, limit, query);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while loading ConnectMe users = ", error);
      throw error;
    }
  };

  const sendRequest = async (senderUserId, receiverUserId) => {
    try {
      const message = await sendConnectionRequest(senderUserId, receiverUserId);
      return message;
    } catch (error) {
      console.log("error while sending connection request = ", error);
      throw error;
    }
  };

  const sendSubmissionRequest = async (
    senderUserId,
    receiverUserId,
    submissionId
  ) => {
    try {
      const message = await sendSubmissionConnection(
        senderUserId,
        receiverUserId,
        submissionId
      );
      return message;
    } catch (error) {
      console.log("error while sending connection request = ", error);
      throw error;
    }
  };

  const recindRequest = async (senderUserId, receiverUserId) => {
    try {
      const message = await recindConnectionRequest(
        senderUserId,
        receiverUserId
      );
      return message;
    } catch (error) {
      console.log("error while recinding connection request = ", error);
      throw error;
    }
  };

  const acceptRequest = async (senderUserId, receiverUserId) => {
    try {
      const message = await acceptConnectionRequest(
        senderUserId,
        receiverUserId
      );
      return message;
    } catch (error) {
      console.log("error while accepting connection request = ", error);
      throw error;
    }
  };

  const loadUserConnectionRequests = async (userId, search) => {
    try {
      const connections = await fetchUserConnectionRequests(userId,search);
      return connections;
    } catch (error) {
      console.log("error while loading user connections = ", error);
      throw error;
    }
  };

  const loadUserNetwork = async (userId, search) => {
    try {
      const connections = await fetchUserNetwork(userId, search);
      return connections;
    } catch (error) {
      console.log("error while loading user connections = ", error);
      throw error;
    }
  };

  return {
    loadConnectMeUsers,
    sendRequest,
    sendSubmissionRequest,
    recindRequest,
    acceptRequest,
    loadUserConnectionRequests,
    loadUserNetwork,
    loadUser,
  };
};
export default useConnectMe;
