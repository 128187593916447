import React, { useEffect, useState } from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  MainTitle,
  PageTitle,
} from "../../../components/smartlink";
import Index from "../../../components/smartlink/release";

const ReleasePage = () => {
  const [RoleType, setRoletype] = useState("");

  useEffect(() => {
    let role = localStorage.getItem("RouteType");
    if (role !== "") {
      setRoletype(role);
    }
  }, []);

  return (
    <>
      <SmartLinkLayout>
        <MainTitle title="Smart Link" hasbutton={false} />
        <HorizontalMenu />
        <PageTitle NavigateTitle={RoleType} hasNavigate={true} />
        <Index Role={RoleType} />
      </SmartLinkLayout>
    </>
  );
};

export default ReleasePage;
