import React from "react";
import ProducersLayouts from "./ProducersLayouts";
import OTPPopup from "../../../../components/smartlink/producers/auths/OTPPopup";

const ProducerOTPPage = () => {
  return (
    <ProducersLayouts>
      <OTPPopup />
    </ProducersLayouts>
  );
};

export default ProducerOTPPage;
