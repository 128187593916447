import React, { Fragment, useEffect, useState } from "react";

import styles from "../admin.module.css";
import { useDebounce } from "../../../hooks/useDebounce";
import useAuth from "../../../context/AuthContext";
import { getOpportunities } from "../../../context/apis/admin/opportunity";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Box, Button, Grid, ListItemText, Typography } from "@mui/material";
import Setting from "../../OpportunitiesComponents/AdditionalComponents/utils/Setting";
import allImages from "../../../assets";

import universalStyles from "../../UniversalComponents/universal.module.css";
// import OpportunityCard from "./opportunities/Card";
import AdminOpportunityCard from "./opportunities/AdminOpportunityCard";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../OpportunitiesComponents/AdditionalComponents/StyledMenu";
import { GENRE } from "../../../shared/constants";

const PAGE_LIMIT = 10;

const AdminOpportunities = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [opportunityData, setOpportunityData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);

  const [query, setQuery] = useState("");
  const searchQuery = useDebounce(query.trim(), 500);

  const { user } = useAuth();

  const [hasNextPage, setHasNextPage] = useState(true);
  const [error, setError] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    const queryString = new URLSearchParams({
      id: user._id,
      page: 1,
      limit: PAGE_LIMIT,
      search: searchQuery,
      genre: filter,
    }).toString();

    getOpportunities(queryString)
      .then((response) => {
        if (response) {
          const newRows = response?.data;

          setHasNextPage(newRows.length < response.totalCount);
          setOpportunityData(newRows);
          setPage(1);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchQuery, sortBy, filter, isRefresh]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMoreData = async () => {
    setLoading(true);
    try {
      const newPage = page + 1;

      const queryString = new URLSearchParams({
        page: newPage,
        limit: PAGE_LIMIT,
        search: searchQuery,
        sort: sortBy,
        genre: filter,
      }).toString();
      getOpportunities(queryString)
        .then((response) => {
          const newRows = [...opportunityData, ...response?.data];

          setHasNextPage(newRows.length < response.totalCount);
          setOpportunityData(newRows);
          setPage(newPage);
        })
        .catch((e) => {
          console.info("error while fetch opportunities => ", e);

          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setError(true);
    }
  };

  const fetchMore = () => {
    console.info("fetch more...");

    console.info("loading => ", loading);

    if (loading) return;
    getMoreData();
  };

  useEffect(() => {
    setFilteredData([]);
    setPage(0);
  }, [filter, sortBy]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 0px 0px",
  });

  const onRefresh = () => {
    setIsRefresh((prev) => !prev);
    // scroll top when refresh button hits
    document.getElementById("scrolldiv").scrollTop = 0;
  };

  const applyFilter = (value) => {
    if (value === filter) {
      setFilter("");
      setAnchorEl(null);
    } else {
      setFilter(value);
      setAnchorEl(null);
    }
  };

  // hide the record on delete
  const onDelete = (id) => {
    const data = [...opportunityData];
    const index = data.findIndex(({ _id }) => _id === id);

    if (index >= 0) {
      data.splice(index, 1);
      setOpportunityData(data);
    }
  };

  return (
    <Box
      className={styles.main_layout}
      sx={{ display: "flex", flexDirection: "column" }}
      // style={{
      //   border: '1px solid red',
      //   overflowY: 'auto',
      //   maxHeight: 'calc(100vh - 80px)'
      // }}
    >
      <Box
        className={styles.parent_wrapper}
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "calc(100% - 25px)",
          alignItems: "center",
          paddingLeft: "30px",
        }}
      >
        <Box
          className={styles.titleWrapper}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 19,
          }}
        >
          <Typography
            className={styles.title_head}
            style={{
              color: "#FFF",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "normal",
              fontFamily: "Poppins",
            }}
          >
            Opportunities
          </Typography>
          <Button className={styles.filterBtn2} onClick={handleClick}>
            <img src="/images/setting.png" alt="" />
          </Button>
        </Box>

        <Box className={`${styles.buttonGroup} ${styles.buttonsWrapper}`}>
          <div className={styles.refreshBtn} onClick={onRefresh}>
            <img
              src={allImages.refresh}
              className={`${loading ? styles.loading : ""}`}
              alt="refresh"
            />
          </div>
          <div
            className={`${styles.filterBtn1} ${filter && styles.filterActive}`}
            onClick={handleClick}
          >
            <Setting />
            {filter && <div className={styles.isAppliedFilter}></div>}
          </div>

          <StyledMenu
            id="customized-menu1"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {GENRE.map((genre, index) => (
              <StyledMenuItem
                key={`genreA-${index}`}
                className={filter === genre ? "active" : ""}
              >
                <ListItemText
                  primary={genre}
                  onClick={(e) => applyFilter(genre)}
                  style={{ textAlign: "center" }}
                />
              </StyledMenuItem>
            ))}
          </StyledMenu>
        </Box>
      </Box>

      <Box
        sx={{ padding: "15px 0px 10px 0" }}
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "calc(100% - 25px)",
          alignItems: "center",
          paddingLeft: "30px",
        }}
      >
        <div className={universalStyles.search_box}>
          <img
            className={universalStyles.search_icon}
            src="/v2Assets/connect_assets/Search.png"
            alt="search"
          ></img>
          <input
            className={universalStyles.search_box_input}
            type="text"
            placeholder={"Search Opportunities"}
            onChange={(data) => {
              setQuery(data.target.value);
            }}
          />
        </div>
      </Box>

      <Box
        style={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 80px)",
        }}
      >
        <Box
          className={styles.content}
          id="scrolldiv"
          sx={{ padding: 4 }}
          style={
            {
              // overflowY: 'auto',
              // maxHeight: '670px',
              // border: '1px solid red',
              // overflowY: 'auto',
              // maxHeight: 'calc(100vh - 80px)'
            }
          }
        >
          <Box>
            {opportunityData?.length > 0
              ? opportunityData.map((opportunity, index) => (
                <Fragment key={`opportunityDataFragment-${index}`}>
                  <AdminOpportunityCard
                    removeOpportunity={onDelete}
                    opportunityData={opportunity}
                    isAdmin={true}
                  />
                </Fragment>
              ))
              : !loading && (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#fff",
                      alignItems: "center",
                      marginTop: 80,
                    }}
                  >
                    <Typography>No Data Found</Typography>
                  </Box>
                )}
          </Box>
        </Box>

        {(loading || hasNextPage) && (
          <Grid container justifyContent={"center"} ref={sentryRef}>
            <Grid item>
              <Typography style={{ color: "#2ebb55" }}>Loading..</Typography>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default AdminOpportunities;
