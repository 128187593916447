import React from "react";
import { Input } from "@mui/material";
import { SearchContainer } from "./style";
import { search_icon } from "../../../assets/smartlink";

const SearchMain = () => {
  return (
    <SearchContainer>
      <img src={search_icon} alt="search icon" />
      <Input
        placeholder="Search for artists, tags or domain"
        inputProps={{ "aria-label": "search" }}
      />
    </SearchContainer>
  );
};

export default SearchMain;
