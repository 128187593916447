/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { Box, Divider, HStack, Image } from "native-base";
import React from "react";

import Footer from "../../LandingPage/Footer/Footer";
import GetNotified from "../Getnotified/GetNotified";
import HeaderComp from "../../LandingPage/Header/HeaderComp";
import DistributionBanner from "./DistributionBanner";
import Services from "./Services";
import FundCareer from "./FundCareer";
import JoinUs from "./JoinUs";
import RecentReleases from "./RecentReleases";
import Navbar from "../updatedlandingpage/Navbar";
import ScrollToTop from "../updatedlandingpage/scrollTop";

function Distribution() {
  return (
    <Box w={"100%"} bg={"#000201"}>
      {/* <HeaderComp /> */}
      <Navbar />
      <ScrollToTop />
      <DistributionBanner />
      {/* <Services /> */}

      <FundCareer />
      <JoinUs />
      {/* <RecentReleases /> */}

      {/* <GetNotified /> */}
      <Footer />
    </Box>
  );
}

export default Distribution;
