import React from 'react';

import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Avatar,
} from 'native-base';
import ProgressTags from './ProgressTags';
import cover from '../../../assets/Images/1cover.png';
import useAuth from '../../../context/AuthContext';
import styles from '../styles.module.css';

const UploadSoundKitsShare = (props) => {
  const { uploadData, setUploadData, setStep, edit, editData } = props;
  const { user } = useAuth();
 
  return (
    <Box w={'100%'}  marginTop={(uploadData?.collaborators?.length < 1) ? '-10%' : undefined}>
      <Box w={'100%'} px={'3%'} alignSelf={'center'}>
        <ProgressTags index={5} />
        <Heading
          mt={8}
          my={5}
          textTransform={'capitalize'}
          fontFamily={'Poppins'}
          fontSize={{
            xl: '24px',
            lg: '22px',
            md: '20px',
            base: '20px',
          }}
          fontWeight={'medium'}
          textAlign={'left'}
          color={'#fff'}
        >
          Kit Details
        </Heading>

        <HStack w={'100%'} flexWrap={'wrap'} space={8}>
          <Image
            source={
              uploadData.soundPackCover ? uploadData.soundPackCover : cover
            }
            size={'140px'}
            rounded={'md'}
          />
          <Box alignSelf={'center'}>
            {uploadData.soundPackTitle && ( <Heading
              my={2}
              textTransform={'capitalize'}
              fontFamily={'Poppins'}
              fontSize={{ lg: '18px', base: '14px' }}
              fontWeight={'normal'}
              textAlign={'left'}
              color={'#fff'}
            >
              {uploadData.soundPackTitle}
            </Heading>)}
           {uploadData.soundPackKit && (<Heading
              my={1}
              textTransform={'capitalize'}
              fontFamily={'Poppins'}
              fontSize={{ lg: '18px', base: '14px' }}
              fontWeight={'normal'}
              textAlign={'left'}
              color={'#afafaf'}
            >
              {uploadData.soundPackKit}
            </Heading>)}
            {uploadData.soundPackBPM && ( <Heading
              my={1}
              textTransform={'capitalize'}
              fontFamily={'Poppins'}
              fontSize={{ lg: '18px', base: '14px' }}
              fontWeight={'normal'}
              textAlign={'left'}
              color={'#afafaf'}
            >
              {uploadData.soundPackBPM}
            </Heading>)}
           {uploadData.soundPackKey &&  <Heading
              my={1}
              textTransform={'capitalize'}
              fontFamily={'Poppins'}
              fontSize={{ lg: '18px', base: '14px' }}
              fontWeight={'normal'}
              textAlign={'left'}
              color={'#afafaf'}
            >
              {uploadData.soundPackKey}
            </Heading>}
           {uploadData.soundPackPrice &&  <Heading
              my={1}
              textTransform={'capitalize'}
              fontFamily={'Poppins'}
              fontSize={{ lg: '18px', base: '14px' }}
              fontWeight={'normal'}
              textAlign={'left'}
              color={'#2EBB55'}
            >
              ${uploadData.soundPackPrice}
            </Heading>}
          </Box>
        </HStack>

        <Heading
          my={5}
          textTransform={'capitalize'}
          fontFamily={'Poppins'}
          fontSize={{
            xl: '24px',
            lg: '22px',
            md: '20px',
            base: '20px',
          }}
          fontWeight={'medium'}
          textAlign={'left'}
          color={'#fff'}
        >
          Tags
        </Heading>

        <HStack flexWrap={'wrap'} mb={4}>
          {uploadData.tags.map((item, index) => (
            <Button
              key={index}
              variant={'ghost'}
              _hover={{
                bg: 'transparent',
                borderColor: '#afafaf',
                _text: { color: '#afafaf' },
              }}
              _pressed={{ bg: 'transparent' }}
              minW={{ lg: '90px', base: '70px' }}
              _text={{
                fontFamily: 'Poppins',
                fontWeight: 400,
                fontSize: { lg: '18px', base: '14px' },
                color: '#afafaf',
              }}
              borderWidth={1}
              borderColor={'#afafaf'}
              p={2}
              my={2}
              mr={{ lg: 4, base: 3 }}
              textAlign={'center'}
              rounded={'md'}
            >
              {item.value}
            </Button>
          ))}
        </HStack>

        <Heading
          my={5}
          textTransform={'capitalize'}
          fontFamily={'Poppins'}
          fontSize={{
            xl: '24px',
            lg: '22px',
            md: '20px',
            base: '20px',
          }}
          fontWeight={'medium'}
          textAlign={'left'}
          color={'#fff'}
        >
          Collaborators, Role & their Splits
        </Heading>
        {uploadData?.collaborators?.length > 0 ? (
          uploadData.collaborators.map((item, index) => (
            <HStack
              key={index}
              flexWrap={'wrap'}
              w={{ lg: '75%', base: '100%' }}
              justifyContent={'space-between'}
              my={2}
            >
              <HStack space={{ lg: 8, base: 4 }} w={'40%'} overflow={'hidden'}>
                {item.profilePic ? (
                  <Image
                    source={item.profilePic}
                    size={{ lg: '50px', base: '35px' }}
                    rounded={'full'}
                    alignSelf={'center'}
                  />
                ) : (
                  <Avatar
                    bg='#AFAFAF'
                    size={{ lg: '50px', base: '35px' }}
                    alignSelf={'center'}
                    _text={{
                      textTransform: 'uppercase',
                      fontSize: {
                        xl: '20px',
                        lg: '18px',
                        md: '16px',
                        base: '14px',
                      },
                    }}
                  >
                    {item.musicianName[0]}
                  </Avatar>
                )}
                <Heading
                  alignSelf={'center'}
                  textTransform={'capitalize'}
                  fontFamily={'Poppins'}
                  fontSize={{
                    xl: '16px',
                    lg: '16px',
                    md: '16px',
                    base: '14px',
                  }}
                  fontWeight={'medium'}
                  textAlign={'left'}
                  color={'#afafaf'}
                >
                  {item.email === user.email ? '(ME)' : item.musicianName}
                </Heading>
              </HStack>
              <Heading
                alignSelf={'center'}
                textTransform={'capitalize'}
                fontFamily={'Poppins'}
                fontSize={{
                  xl: '20px',
                  lg: '18px',
                  md: '16px',
                  base: '14px',
                }}
                fontWeight={'medium'}
                textAlign={'left'}
                color={'#afafaf'}
                w={'40%'}
              >
                {item.role}
              </Heading>
              <Heading
                alignSelf={'center'}
                textTransform={'capitalize'}
                fontFamily={'Poppins'}
                fontSize={{
                  xl: '20px',
                  lg: '18px',
                  md: '16px',
                  base: '14px',
                }}
                fontWeight={'medium'}
                textAlign={'left'}
                color={'#afafaf'}
                w={'10%'}
              >
                {item.split + '%'}
              </Heading>
            </HStack>
          ))
        ) : (
          <Heading
            fontFamily={'Poppins'}
            fontSize={{
              xl: '20px',
              lg: '20px',
              md: '18px',
              base: '16px',
            }}
            fontWeight={'medium'}
            textAlign={'left'}
            color={'#afafaf'}
          >
            No collaborators
          </Heading>
        )}
        <div className={styles.share_wrapper}>
          <div className={styles.share_text}>
          https://unitedmarket.com/
          </div>
        <Button
              w={'22%'}
              borderColor='#2EBA55'
              borderWidth={1}
              _pressed={{
                bg: '#2EBB55',
                _text: {
                  color: '#fff',
                },
              }}
              backgroundColor='#2EBA55'
              py={2.5}
              _text={{
                color: '#fff',
                fontSize: { xl: '18px', lg: '18px', md: '16px', base: '14px' },
                fontWeight: 'medium',
                textTransform: 'capitalize',
                fontFamily: 'Poppins',
              }}
              _hover={{
                backgroundColor: 'transparent',
                _text: {
                  color: '#2EBA55',
                },
              }}
              alignSelf={'center'}
              // onPress={() => HandleShare()}
            >
              Share
            </Button>
        </div>
      </Box>
    </Box>
  );
};

export default UploadSoundKitsShare;
