import { object, array, string, number, bool, boolean } from "yup";

const SubmissionSchema = object().shape({
  title: string()
    .required("File Name is required")
    .min(3, "File Name must be greater than 3 characters")
    .max(50, "File Name must be less than 50 characters"),
  file: object()
    .shape({
      name: string("File name is required").required("File name is required"),
      url: string("File URL is required").required("File URL is required"),
    })
    .default(undefined)
    .required("File is required"),
  // lookingFor: string().notRequired(),
  // collaboratorOnLoop: string().when("lookingFor", {
  //   is: "Loops",
  //   then: (schema) => schema.required("This field is required"),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  agreepublishingFee: boolean().required("This field is required"),
  agreePolicy: boolean().required("This field is required"),
  collaboratorOnLoop: string().required("This field is required"),
  collaborators: array()
    .of(
      object()
        .shape({
          name: string()
            .required("Collaborator name is required")
            .min(3, "Collaborator name must be greater than 3 characters")
            .max(50, "Collaborator name must be less than 50 characters"),
          email: string()
            .required("Collaborator email is required")
            .email("Invalid email"),
          publishingPercentage: number("Collaborator split must be number")
            .typeError("Collaborator split must be number")
            .required("Split is required")
            .max(45)
            .default(0),
        })
        .required()
    )
    .when("collaboratorOnLoop", {
      is: "yes",
      then: (schema) =>
        schema
          .required("Collaborators information is required")
          .test("collaborators", (collaborators = [], context) => {
            if (collaborators.length > 0) {
              return true;
            } else {
              return context.createError({
                message: `Please add collaborators details`,
              });
            }
          })
          .test("mySplit", (collaborators = [], context) => {
            const total = collaborators.reduce((total, row) => {
              return total + (parseInt(row.publishingPercentage) || 0);
            }, 0);
            if (total <= 45 - context.parent.mySplit) {
              return true;
            } else {
              if (context.parent.mySplit) {
                if (45 - context.parent.mySplit <= 0) {
                  return context.createError({
                    message: `You do not have enough split % to share with collaborators.`,
                  });
                } else {
                  return context.createError({
                    message: `Sum of all collaborators split must be less than or equal to ${45 - context.parent.mySplit}% of total`,
                  });
                }
              }
              return context.createError({
                message: `Enter Add your publishing split %`,
              });
            }
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
  mySplit: number()
    .typeError("This must be number")
    .required("This field is required")
    .max(45, "Total split should not exceed 45%"),
  // .test("max", "Total split should not exceeds 45%", function (value) {
  //   const { collaborators } = this.parent;
  //   const total = collaborators.reduce((total, row) => {
  //     return total + (parseInt(row.publishingPercentage) || 0);
  //   }, 0);
  //   return total + value <= 45;
  // }),
  tone: array().of(string()).required("Tune/ Vibe is required"),
  pitchingPeriod: string().required("Pitching period is required"),
  exclusivePrice: number()
    .required("Exclusive price is required")
    .min(0, "Exlusive price must be greater than 0")
    .typeError("Beat exclusive price is required"),
  // nonExclusivePrice: number()
  //   .required("Non-Exclusive price is required")
  //   .min(0, "Non-Exlusive price must be greater than 0")
  //   .typeError("Beat non-exclusive price is required"),
  isMasterSample: string().required("This field is required"),
});

export default SubmissionSchema;
