import React from "react";
import styles from "../../auth_pages.module.css";
import { useState } from "react";
import { FormField } from "../FormInput";

const PartOfLabel = ({ onNext, user, completeAccount }) => {
  console.log("🚀 ~ PartOfLabel ~ user:", user);
  const [accountType, setAccountType] = React.useState(null);
  const [labelName, setLabelName] = React.useState(null);
  const accountTypes = ["Yes", "No"];

  const canContinue =
    (accountType === "Yes" && labelName) || accountType === "No";

  const handleTypeSelect = (item) => {
    setAccountType(item);
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
        >
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}>
              <p className={styles.auth_title}>Are you part of a label</p>
            </div>
            <div className={styles.type_list}>
              {accountTypes.map((type, index) => (
                <div
                key={`partofLabel-${index}`}
                  className={
                    accountType === type
                      ? styles.account_type_option_selected
                      : styles.account_type_option
                  }
                  onClick={() => handleTypeSelect(type)}
                >
                  <p className={styles.account_type_option_text}> {type}</p>
                </div>
              ))}
            </div>
            <div className={styles.big_spacer}> </div>
            <div className={styles.input_list}>
              {accountType === "Yes" && (
                <FormField
                  title="Label Name"
                  onValueChange={(value) => setLabelName(value)}
                  dropdown={false}
                />
              )}
            </div>
          </div>

          <div className={styles.buttonControls}>
            {canContinue ? (
              <div
                onClick={() => {
                  let updatedUser = { ...user };
                  let updatedProfile = { ...user.profile };
                  updatedProfile.label = labelName;
                  updatedUser.profile = updatedProfile;
                  onNext(updatedUser);
                }}
                className={styles.signin_box}
              >
                <p className={styles.connect_text}>Continue</p>
              </div>
            ) : (
              <div className={styles.signin_box_inactive}>
                <p className={styles.connect_text}>Continue</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartOfLabel;
