import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";

const Main = styled(Box)(({ theme }) => ({
  width: "200px",
  height: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
  borderRadius: "5px",
  transition: "background-color 0.3s, color 0.3s",
  overflow: "hidden",
  cursor: "pointer",
  background: "rgba(79, 132, 93, 0.30)",
  color: "#2EBB55",
  img: {
    width: "100%",
    objectFit: "cover",
  },
  "& .upload-icon": {
    width: "50px",
    height: "50px",
    transition: "color 0.3s",
    cursor: "pointer",
    color: "#2EBB55",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const DeleteBox = styled(Box)(({ theme }) => ({
  width: "200px",
  height: "auto",
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
  "& span": {
    color: "#F00",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    cursor: "pointer",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export { Main, AddIcon, DeleteBox };
