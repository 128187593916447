import React, { useRef, useState, useEffect } from "react";
import styles from "./universal.module.css";

const AudioPlayer = (props) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);
    return `${minutes}:${String(seconds).padStart(2, "0")}`;
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    audioElement.addEventListener("error", (e) => {
      console.error("Audio error:", e.target.error);
    });

    audioElement.addEventListener("timeupdate", () => {
      setCurrentTime(audioElement.currentTime);
    });

    audioElement.addEventListener("durationchange", () => {
      setDuration(audioElement.duration);
    });

    return () => {
      audioElement.removeEventListener("timeupdate", () => {});
      audioElement.removeEventListener("durationchange", () => {});
    };
  }, []);

  return (
    <div
      className={styles.audio_player}
      style={{ height: "40px", width: `${props.width}px` }}
    >
      <audio
        ref={audioRef}
        src={props.src}
        onChange={(e) => {
          console.log("Range input value:", e.target.value);
          audioRef.current.currentTime = e.target.value;
        }}
      ></audio>

      {/* Play/Pause Button */}
      <button className={styles.play_button} type="button" onClick={togglePlayPause}>
        {isPlaying ? (
          <img
            src="/v2Assets/PauseIcon.png"
            alt="pause"
            className={styles.play_image}
          />
        ) : (
          <img
            src="/v2Assets/PlayIcon.png"
            alt="play"
            className={styles.play_image}
          />
        )}
      </button>

      {/* Buffer (Song position drag) */}
      <input
        type="range"
        min="0"
        className={styles.range_input}
        max={duration}
        value={currentTime}
        onChange={(e) => (audioRef.current.currentTime = e.target.value)}
      />

      {/* Time Display */}
      <span className={styles.time_display}>
        {formatTime(currentTime)}/{formatTime(duration)}
      </span>
    </div>
  );
};

export default AudioPlayer;
