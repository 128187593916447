import React, { useEffect, useRef, useState, memo } from "react";
import { Main, CenterBoxMain, MaincollaboratorsList } from "./style/style";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import AddIcon from "@mui/icons-material/Add";
// import ReactSelect from "../../../components-old/share/react_select/ReactSelect";
// import MusicianSplitModel from "../../../components-old/share/models/musiciansplitmodel/MusicianSplitModel";
// import MusicianOtpModel from "../../../components-old/share/models/otpinputmodel/MusicianOtpModel";
import { beats, musicnote } from "../../../../assets/smartlink";
import MembershipInput from "./MembershipInput";
import RadioButtons from "./RadioButtons";
import MembershipCheckBox from "./MembershipCheckBox";
import ReactSelect from "./ReactSelect";
import MusicianSplitModel from "./popups/MusicianSplitModel";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  ADD_BEAT_MUTATION,
  GET_BEATBOX_BY_ID,
} from "../../../../Graphql_services/beatinbox/Beats";
import { useMutation, useQuery } from "@apollo/client";
import { Notification } from "../../../NotifyMsg/Notification";
import CollaboratorInputsModel from "./popups/CollaboratorInputsModel";

// Progress Bar
import LinearProgress from "@mui/material/LinearProgress";
import styled from "styled-components";
import fileImg from "../../../../assets/smartlink/images/Uploadfile.svg";
import LogoutIcon from "@mui/icons-material/Logout";
// File Upload Notification

import { Notify } from "./Notification/Notify";
import useAuth from "../../../../context/AuthContext";
import CreateBeatStatusModel from "./popups/CreateBeatStatusModel";
import theme from "../../theme";

const toneVibeOptions = [
  { value: "Dark", label: "Dark" },
  { value: "Happy", label: "Happy" },
  { value: "EnergyUptempo", label: "Energy/Up tempo" },
  { value: "Virtual", label: "Virtual" },
  { value: "Atmospheric", label: "Atmospheric" },
  { value: "Pain", label: "Pain" },
  { value: "Emotional", label: "Emotional" },
  { value: "Smooth", label: "Smooth" },
  { value: "Other", label: "Other" },
];

const BeatPitchOptions = [
  { value: "30", label: "30 days" },
  { value: "60", label: "60 days" },
  { value: "90", label: "90 days" },
];

const ProducersSubmit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const Token = localStorage.getItem("token");
  useEffect(() => {
    if (typeof localStorage !== "undefined" && id) {
      try {
        localStorage.setItem("beat_id", id);
      } catch (error) {
        console.error("Error storing data in localStorage:", error);
      }
    }
  }, [id]);

  const [addBeat] = useMutation(ADD_BEAT_MUTATION, {
    context: { clientName: "beats" },
  });

  const [Isloading, setIsLoading] = useState(false);

  const { loading, error, data } = useQuery(GET_BEATBOX_BY_ID, {
    variables: {
      id: id,
    },
    context: { clientName: "beats" },
  });
  const beatBoxes = data?.getBeatBoxById?.beatBox;
  const isLoopBeat = !beatBoxes?.isBeat;

  const [CollaboratorModel, setCollaboratorModel] = useState(false);
  const [SelectedInputs, setSelectedInputs] = useState(1);

  const [CollaboratorsData, setCollaboratorsData] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);

  const [successfullOpen, setSuccessfullOpen] = useState(false);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [MusicianName, setMusicianName] = useState(null);
  const [PhoneNumber, setPhoneNumber] = useState(null);
  const [pubSplit, setPublishSplit] = useState(null);
  const [tone, setTone] = useState(null);

  const [exclusivePrice, setExclusivePrice] = useState(null);
  const [nonExclusivePrice, setNonExclusivePrice] = useState(null);
  const [pitchPeriod, setPitchPeriod] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [filename, setFilename] = useState(null);
  const [collaboratorsSelected, setCollaboratorsSelected] = useState(false);
  const [hascollaborators, sethascollaborators] = useState(null);

  const [musicianNo, setMusicianNo] = useState(null);
  const [masterSample, setMasterSample] = useState(null);

  const [FirstCheck, SetFirstCheck] = useState(false);
  const [SecCheck, SetSecCheck] = useState(false);
  const [StatusPopupOpen, SetStatusPopupOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const dropRef = useRef();

  const MusicianSplitOpen = () => {
    if (!CollaboratorsData) {
      setOpen(!open);
    } else if (CollaboratorsData?.length > 0) {
      setCollaboratorModel(true);
    }
  };
  const MusicianOtpModelOpen = () => {
    setOpen(!open);
  };
  const handleFileChange = (file) => {
    setSelectedFile(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
      UploadFilehandler(file);
    }
  };
  const handleRadioChange = (value) => {
    sethascollaborators(value);
    setCollaboratorsSelected(value === "Yes");
  };

  useEffect(() => {
    if (hascollaborators === "No") {
      setCollaboratorsData(false);
    }
  }, [hascollaborators]);

  const [errors, setErrors] = useState({});
  const [isError, setIsError] = useState(false);
  const validateData = (name, pubSplit, exclusivePrice) => {
    const newErrors = {};

    if (name.trim() === "") {
      newErrors.name = "Name is required.";
    }

    if (pubSplit === 0) {
      newErrors.pubSplit = "Publish Split must be greater than 0.";
    }

    if (exclusivePrice === 0) {
      newErrors.exclusivePrice = "Exclusive Price must be greater than 0.";
    }

    if (nonExclusivePrice === 0) {
      newErrors.exclusivePrice = "Non-Exclusive Price must be greater than 0.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // ================ file upload  ======================
  const allowedAudioTypes = [
    "audio/aac",
    "audio/ogg",
    "audio/x-ogg",
    "audio/flac",
    "audio/x-flac",
    "audio/alac",
    "audio/x-alac",
    "audio/wav",
    "audio/x-wav", // Adjusted MIME type for WAV files in Safari
    "audio/aiff",
    "audio/x-aiff", // Adjusted MIME type for AIFF files in Safari
    "audio/dsd",
    "audio/x-dsd",
    "audio/mp3",
    "audio/mpeg",
    "audio/x-mpeg",
    "audio/m4a",
    "audio/x-m4a", // Adjusted MIME type for M4A files in Safari
    "",
  ];

  const UploadFilehandler = async (audFile) => {
    if (!allowedAudioTypes.includes(audFile.type)) {
      Notification(
        "error",
        "Please upload only AAC, Ogg Vorbis, FLAC, ALAC, WAV, AIFF, DSD, MP3, MPEG, or M4A files."
      );
      return;
    }

    setFileSize(audFile.size);
    setuploadfilename(audFile.name.slice(-20));

    const audioFile = new FormData();
    setIsLoading(true);
    SetProgressbar(true);
    try {
      audioFile.append("file", audFile);
      audioFile.append("type", "Public");

      setFilename(audFile.name);
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_FILE_URL}/file/uploadAudioFile`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Token}`,
        },
        data: audioFile,

        // File Progress Bar Perecentage habdle

        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percentCompleted < 99) {
            setProgressPercent(percentCompleted);
          } else {
            delayedProgress = true;
          }
        },
      };

      const response = await axios(config);
      if (response?.status === 200) {
        setFileId(response?.data?.file?._id);
        Notify("success", "Your audio file has been successfully uploaded!");

        if (delayedProgress) {
          setProgressPercent(100);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: ProducersSubmit.js:175 ~ UploadFilehandler ~ error:",
        error
      );

      // if (error?.response?.status === 401) {
      //   setFileId("asdjsakhdkajshd");
      // }
      // Notification("error", error.message);
      SetStatusPopupOpen(true);
      setIsSuccess(false);
      HandleFileDelete();

      // if (error?.response?.status === 401) {
      //   Notification("error", "You need to login before upload");
      // } else {
      //   Notification("error", error.message);
      // }

      // Notification("error", error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  // ================ End file upload  ======================

  // ================ submit ======================

  const submitHandler = async (e) => {
    setIsLoading(true);

    // if (!fileId) {
    //   Notification("error", "Beat  is required");
    //   return;
    // }

    try {
      if (!name) {
        Notification("error", "File name is required");
        return;
      }

      if (!isLoopBeat) {
        if (!email) {
          Notification("error", "Email is required");
          return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          Notification("error", "Please enter a valid email address");
          return;
        }
        if (!MusicianName) {
          Notification("error", "Musician Name is required");
          return;
        }
        if (!PhoneNumber) {
          Notification("error", "Phone Number is required");
          return;
        }
      }

      if (!pubSplit) {
        Notification("error", "Publishing split is required");
        return;
      }
      if (!tone) {
        Notification("error", "Tone/Vibe is required");
        return;
      }

      if (!isLoopBeat) {
        if (!exclusivePrice) {
          Notification("error", "Beat exclusive price is required");
          return;
        }

        if (!nonExclusivePrice) {
          Notification("error", "Beat non-exclusive price is required");
          return;
        }

        if (!pitchPeriod) {
          Notification("error", "Beat pitching period is required");
          return;
        }
      }

      if (hascollaborators === "No" && pubSplit > 40) {
        Notification("error", "Your Publishing cannot be more than  40%");
        return;
      }
      if (!hascollaborators) {
        Notification("error", "Please select collaborator ");
        return;
      }
      if (hascollaborators === "Yes" && !CollaboratorsData) {
        Notification("error", "Please add  collaborators");
        return;
      }
      if (hascollaborators === "Yes" && CollaboratorsData?.length > 0) {
        const totalSplitPrice = CollaboratorsData.reduce(
          (total, musician) => total + (parseInt(musician.splitPrice) || 0),
          0
        );
        const TotalPubSplit = Number(pubSplit) + totalSplitPrice;
        if (TotalPubSplit > 40) {
          Notification(
            "error",
            "Your Total Publishing cannot be more than  40%"
          );
          return;
        }
      }
      if (!masterSample) {
        Notification("error", "Please select  master sample");
        return;
      }
      if (!FirstCheck || !SecCheck) {
        Notification("error", "Please complete all fields");
        return;
      }
      if (!fileId) {
        Notification("error", "Beat is required");
        return;
      }
      // if (!Token) {
      //   Notification("error", "You need to login before upload");

      //   // =============== Handle PreUploading ===================

      //   let obj = {
      //     inboxId: id,
      //     audioFileId: fileId,
      //     publishingSplit: parseFloat(pubSplit),
      //     tone: tone || "Happy",
      //     FirstCheck: FirstCheck,
      //     SecCheck: SecCheck,
      //     beatExclusivePrice: parseFloat(exclusivePrice),
      //     beatPitchingPeriod: Number(pitchPeriod),
      //     filename: name,
      //     collaborators: hascollaborators,
      //     processBar: Progressbar,
      //     UploadFileName: filename,
      //     fileSize: fileSize,
      //     containsMasterSample: masterSample || 1,
      //     noOfMusicians: hascollaborators === "No" ? 0 : Number(SelectedInputs),
      //     collaboratorsDetails:
      //       hascollaborators === "Yes" ? CollaboratorsData : {},
      //   };

      //   localStorage.setItem("PendingUpload", JSON.stringify(obj));

      //   navigate("/producer/auth/welcome");
      //   // window.open("/producer/auth/welcome", "_blank");
      //   return;
      // }

      // const [email, setEmail] = useState(null);
      // const [MusicianName, setMusicianName] = useState(null);
      // const [PhoneNumber, setPhoneNumber] = useState(null);

      const variable = {
        inboxId: id,
        audioFileId: fileId,
        publishingSplit: parseFloat(pubSplit),
        tone: tone || "Happy",
        filename: name,
        collaborators: hascollaborators,
        containsMasterSample: masterSample || 1,
        noOfMusicians: hascollaborators === "No" ? 0 : Number(SelectedInputs),
        collaboratorsDetails:
          hascollaborators === "Yes" ? CollaboratorsData : {},
      };

      if (localStorage.getItem("userId")) {
        variable.createrId = localStorage.getItem("userId");
      }

      if (!isLoopBeat) {
        variable.phoneno = parseFloat(PhoneNumber);
        variable.email = email;
        variable.musicianName = MusicianName;
        variable.beatExclusivePrice = parseFloat(exclusivePrice);
        variable.beatNonExclusivePrice = parseFloat(nonExclusivePrice);
        variable.beatPitchingPeriod = Number(pitchPeriod);
      }

      const res = await addBeat({
        variables: variable,
      });

      const { data } = res;
      if (data?.addBeat?.success) {
        // Notification("success", data?.addBeat?.message);
        localStorage.removeItem("PendingUpload");
        // navigate("/beat-inbox");
        // window.location.reload();
        SetStatusPopupOpen(true);
        setIsSuccess(true);
        // const newUrl = `/producer/auth/welcome`;
        // window.location.href = newUrl;
      } else {
        Notification("error", data?.addBeat?.message);
        setIsLoading(false);
        // SetStatusPopupOpen(true);
        // setIsSuccess(false);
      }
    } catch (error) {
      Notification("error", error.message);
      // SetStatusPopupOpen(true);
      // setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };
  // ================ End Submit  ======================

  // =============== Getting Back PreUploading ===================

  useEffect(() => {
    let Data;
    if (localStorage.getItem("PendingUpload")) {
      Data = JSON.parse(localStorage.getItem("PendingUpload"));
    } else {
      Data = "";
    }

    if (Data !== "") {
      setFileId(Data.inboxId);
      setFileId(Data.audioFileId);
      setPublishSplit(Data.publishingSplit);
      setTone(Data.tone);
      setExclusivePrice(Data.beatExclusivePrice);
      setPitchPeriod(Data.beatPitchingPeriod);
      setName(Data.filename);
      sethascollaborators(Data.collaborators);
      setMasterSample(Data.containsMasterSample);
      SetFirstCheck(Data.FirstCheck);
      SetSecCheck(Data.SecCheck);
      setFilename(Data.UploadFileName);
      setCollaboratorsData(Data.collaboratorsDetails);
      SetProgressbar(Data.processBar);
      setuploadfilename(Data.UploadFileName);
      setFileSize(Data.fileSize);
    } else {
    }
  }, []);

  // Progress Bar

  const [Progressbar, SetProgressbar] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [uploadfilename, setuploadfilename] = useState("");
  let delayedProgress = false;

  const formatBytes = (size) => {
    if (size === 0) return "0 MB";
    const megabytes = size / (1024 * 1024);
    return megabytes.toFixed(2) + " MB";
  };

  // ============ End File Progress Bar Code ============

  // ================ Handle File Delete  ======================

  const HandleFileDelete = () => {
    setSelectedFile(null);
    setFileId(null);
    setFilename(null);
    setProgressPercent(0);
    setFileSize(0);
    setuploadfilename(0);
    SetProgressbar(false);
  };

  // ================= Style Progress ====================

  const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 19px 20px 19px;
    background-color: rgba(28, 27, 27, 0.8);
    border-radius: 8px;
    margin-top: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
    }
  `;

  const ProgreessHeading = styled.h6`
    font-size: 18px;
    overflow: hidden;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px
    margin: 0px !important;
    padding:0px !important;
    word-wrap: break-word;
    @media (max-width: 768px) {
      width: 100%;
    }
  `;

  const Smallbox = styled.p`
    font-size: 12px;
    color: #afafaf;
    font-family: "Poppins", sans-serif;
    @media (max-width: 768px) {
      font-size: 12px;
      color: #555;
    }
  `;

  const FileImage = styled.img`
    width: 72px;
    height: 72px;

    @media (max-width: 768px) {
      width: 55px;
      height: 55px;
    }
  `;

  const ProgressContainer = styled.div`
    flex: 1;
    margin-left: 16px;

    @media (max-width: 768px) {
      margin: 0;
      width: 100%;
    }
  `;
  const StyledButton = styled(Button)`
    && {
      background-color: rgba(255, 0, 0, 0.2);
      color: red;
      position: absolute;
      right: 20px;
      text-transform: capitalize;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      &:hover {
        background-color: rgba(255, 0, 0, 0.2);
      }

      @media (max-width: 768px) {
        width: 70px;
        height: 30px;
      }
    }
  `;

  const HandleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const { isAuthenticated } = useAuth();

  return (
    <Main>
      <CenterBoxMain>
        {isAuthenticated && (
          <Box sx={{ textAlign: "right" }}>
            <Button
              sx={{
                color: "#fff",
                padding: "4px 17px",
                margin: 0,
                borderRadius: "5px",
                background: "#414241",
                textTransform: "capitalize",
                "&:hover": {
                  background: "#414241",
                },
                svg: {
                  fontSize: "20px",
                  marginRight: "3px",
                },
              }}
              onClick={HandleLogout}
            >
              <LogoutIcon /> Logout
            </Button>
          </Box>
        )}
        <Grid container spacing={10} className="main_grid">
          <Grid item lg={6} xs={12} className="right">
            <Box className="header-box">
              {loading && <p style={{ color: "#fff" }}> Loading...</p>}

              {!loading && beatBoxes && (
                <>
                  <Avatar
                    src={
                      beatBoxes?.image || beatBoxes?.userId?.profilePic || ""
                    }
                    sx={{ width: 60, height: 60 }}
                  />
                  <Box className="info">
                    <h5>{beatBoxes?.userId?.firstname || ""}</h5>
                    <h6>{beatBoxes?.title || ""}</h6>
                  </Box>
                </>
              )}
            </Box>
            <Box
              className="submit-beat-main"
              ref={dropRef}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <Box className="submit-beat">
                <h5>Submit Audio File</h5>
                <h6>Share Your Sounds & Increase Collaborations</h6>
              </Box>
              {/* // File Uploading Code  */}

              {!Progressbar ? (
                <Box className="file-upload-main">
                  <input
                    type="file"
                    id="file-upload"
                    // accept="audio/*"
                    style={{ display: "none" }}
                    onChange={(e) => UploadFilehandler(e.target.files[0])}
                  />
                  <label htmlFor="file-upload" className="upload-box">
                    {selectedFile ? (
                      <Box className="music-info">
                        <Typography variant="h5">
                          {selectedFile.name}
                        </Typography>
                      </Box>
                    ) : (
                      <img src={musicnote} alt="music-icon" />
                    )}
                    <h5>Upload file for {beatBoxes?.title || ""}</h5>
                    <h6>or drag and drop your files here</h6>
                    <p style={{ color: "#716F6E" }}>{filename}</p>
                  </label>
                </Box>
              ) : (
                <Container>
                  <FileImage src={fileImg} alt="File" />
                  <ProgressContainer>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <ProgreessHeading style={{ margin: "0px !important" }}>
                        {fileId ? "See You Again" : `${uploadfilename}`}
                      </ProgreessHeading>
                      {fileId ? (
                        ""
                      ) : (
                        <Smallbox>{`${Math.round(progressPercent)}%`}</Smallbox>
                      )}
                    </div>
                    {fileId ? (
                      <Smallbox>{uploadfilename}</Smallbox>
                    ) : (
                      <LinearProgress
                        variant="determinate"
                        value={progressPercent}
                      />
                    )}
                    <Smallbox>{formatBytes(fileSize)}</Smallbox>
                  </ProgressContainer>
                  {fileId ? (
                    <StyledButton
                      onClick={HandleFileDelete}
                      variant="contained"
                    >
                      Remove
                    </StyledButton>
                  ) : (
                    ""
                  )}
                </Container>
              )}

              {/* // File Uploading Ending */}
            </Box>
            <Box className="form-row">
              <Box className="fields">
                <MembershipInput
                  label="File Name"
                  value={name}
                  OnChange={setName}
                />
              </Box>
              {!isLoopBeat && (
                <Box className="fields">
                  <MembershipInput
                    label="Email"
                    value={email}
                    OnChange={setEmail}
                  />
                </Box>
              )}

              {isLoopBeat && (
                <Box className="fields">
                  <MembershipInput
                    icon={ErrorIcon}
                    label="Add your publishing split %"
                    title="Music Producers are entitled to 40% off
                     publishing."
                    value={pubSplit}
                    type="number"
                    placeholder="%"
                    OnChange={setPublishSplit}
                    max="40"
                  />
                </Box>
              )}
            </Box>

            {!isLoopBeat && (
              <Box className="form-row">
                <Box className="fields">
                  <MembershipInput
                    label="Musician Name"
                    value={MusicianName}
                    OnChange={setMusicianName}
                  />
                </Box>
                <Box className="fields">
                  <MembershipInput
                    icon={ErrorIcon}
                    label="Phone Number"
                    title="Ensure this is your primary phone number because we reach out to notify you about placements via text"
                    value={PhoneNumber}
                    type="number"
                    OnChange={setPhoneNumber}
                  />
                </Box>
              </Box>
            )}
            <Box className="form-row">
              {!isLoopBeat && (
                <Box className="fields">
                  <MembershipInput
                    icon={ErrorIcon}
                    label="Add your publishing split %"
                    title="Music Producers are entitled to 40% off
                    publishing."
                    value={pubSplit}
                    type="number"
                    placeholder="%"
                    OnChange={setPublishSplit}
                    max="40"
                  />
                </Box>
              )}

              <Box
                className="fields"
                sx={{
                  width: `${isLoopBeat && "48.5% !important"}`,
                  [theme.breakpoints.down("sm")]: {
                    width: `${isLoopBeat && "100% !important"}`,
                  },
                }}
              >
                <ReactSelect
                  label="Tone/Vibe"
                  title="By submitting you are agreeing that United
                    Market and their partners have the selected
                    period exclusively to use this sound if
                    selected."
                  options={toneVibeOptions}
                  // menuPosition={{
                  //   right: "0",
                  //   bottom: "100",
                  //   left: "auto",
                  //   top: "50",
                  // }}
                  menuPosition={{ right: 0, bottom: "80%", left: "auto" }}
                  OnChange={setTone}
                  defaultvalue={tone}
                  placeholder=""
                  isSearch={false}
                />
              </Box>
            </Box>
            {!isLoopBeat && (
              <>
                <Box className="form-row">
                  <Box className="fields">
                    <MembershipInput
                      icon={ErrorIcon}
                      label="Beat Exclusive Price"
                      title="The price you will sell exclusive rights of the
                    beat for,"
                      value={exclusivePrice}
                      type="number"
                      OnChange={setExclusivePrice}
                    />
                  </Box>

                  <Box className="fields">
                    <MembershipInput
                      icon={ErrorIcon}
                      label="Beat Non-Exclusive Price"
                      title="The price you will sell exclusive rights of the
                    beat for,"
                      value={nonExclusivePrice}
                      type="number"
                      OnChange={setNonExclusivePrice}
                    />
                  </Box>
                </Box>

                <Box className="fields">
                  <ReactSelect
                    icon={ErrorIcon}
                    title="By submitting you are agreeing that United
                    Market and their partners have the selected
                    period exclusively to use this sound if
                    selected."
                    label="Beat Pitching Period"
                    options={BeatPitchOptions}
                    menuPosition={{ right: 0, bottom: "70%", left: "auto" }}
                    OnChange={setPitchPeriod}
                    defaultvalue={pitchPeriod}
                    placeholder=""
                    isSearch={false}
                  />
                </Box>
              </>
            )}
          </Grid>
          <Grid item lg={6} xs={12} className="left">
            <Box
              className="main-radio"
              sx={{
                marginBottom:
                  hascollaborators === "Yes" ? "5px !important" : "20px",
              }}
            >
              <RadioButtons
                title="Are there any collaborators on this audio file?"
                label1="Yes"
                label2="No"
                onChange={handleRadioChange}
                defaultValue={hascollaborators}
              />
            </Box>

            {hascollaborators === "Yes" && CollaboratorsData?.length > 0 ? (
              <>
                <MaincollaboratorsList>
                  {CollaboratorsData &&
                    CollaboratorsData?.map((values) => {
                      return (
                        <>
                          <Box
                            key={values?.name}
                            className="collaborators_item"
                          >
                            <Box className="collaborators_name">
                              <Box>Name: {values?.name}</Box>
                              <Box>
                                Splits Percentage: {values?.splitPrice}%
                              </Box>
                            </Box>
                            <Box>
                              <Box>email: {values?.email}</Box>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                </MaincollaboratorsList>
              </>
            ) : null}

            {collaboratorsSelected && (
              <Box className="add-btn" onClick={MusicianSplitOpen}>
                <AddIcon className="add-icon" />
                Add Collaborators
              </Box>
            )}

            <Box className="main-radio">
              <RadioButtons
                title="Does this contain a master sample?"
                label1="Yes"
                label2="No"
                onChange={setMasterSample}
                defaultValue={masterSample}
              />
            </Box>

            <Box className="checkbox-box">
              <Box>
                <MembershipCheckBox
                  text="By submitting for this United Market opportunity, you must opt into our honesty & transparency policy."
                  onChange={SetFirstCheck}
                  checked={FirstCheck}
                  // icon={ErrorIcon}
                  // title="United Market is eligible to receive a publishing percentage for facilitating the collaboration between parties."
                />
              </Box>
              <Box>
                <MembershipCheckBox
                  text="I consent to United Market taking 10% publishing for serving as the A&R on my composition upon release."
                  onChange={SetSecCheck}
                  checked={SecCheck}
                  icon={ErrorIcon}
                  title="United Market is eligible to receive a publishing percentage for facilitating the collaboration between parties."
                />
              </Box>
            </Box>
            <Box className="btn-box" sx={{ marginBottom: "15px" }}>
              {/* <Button variant="outlined" className="btn">
                cancel
              </Button> */}
              <Button
                type="submit"
                variant="contained"
                className="btn btn-green"
                onClick={submitHandler}
                disabled={Isloading}
                sx={{
                  "&:disabled": {
                    background: "gray !important",
                    color: "#fff",
                  },
                }}
              >
                {Isloading ? "Loading..." : "submit"}
              </Button>

              <MusicianSplitModel
                open={open}
                setOpen={setOpen}
                setBioOpen={setCollaboratorModel}
                SelectedInputs={setSelectedInputs}
              />

              <CollaboratorInputsModel
                bioOpen={CollaboratorModel}
                setBioOpen={setCollaboratorModel}
                SelectedNumber={SelectedInputs}
                setCollaboratorsData={setCollaboratorsData}
                setOpen={setOpen}
                setSelectedInputs={setSelectedInputs}
                CollaboratorsData={CollaboratorsData}
              />

              <CreateBeatStatusModel
                successfullOpen={StatusPopupOpen}
                setSuccessfullOpen={SetStatusPopupOpen}
                isSuccess={isSuccess}
              />
            </Box>
          </Grid>
        </Grid>
      </CenterBoxMain>
    </Main>
  );
};

export default memo(ProducersSubmit);
