import React, { useState } from "react";
import useAuth from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import styles from "../auth_pages.module.css";
import SignInForm from "./SigninForms/SignInForm";

const SignIn = ({ stages, completeAccount, setCompleteAccount }) => {
  const { setProfile, user, loginMutation } = useAuth();
  const [newUser, setNewUser] = useState({ ...user });
  const [currentStage, setCurrentStage] = useState(0);

  const [signUpStage, setSignUpStage] = useState(
    stages ? stages : [{ component: SignInForm, stage: 1 }]
  );

  const handleNext = (updatedUser) => {
    console.log("🚀 ~ handleNext ~ updatedUser:", updatedUser);

    // if (currentStage === signUpStage.length - 1) {
    //   updatedUser.profile.joinDate = new Date().toISOString();
    //   updatedUser.profile.isSetup = true;
    //   updatedUser.profile.verified = false;
    //   updatedUser.profile.active = true;
    //   updatedUser.profile._id = updatedUser.credentials._id;
    //   return;
    // }
    setNewUser(updatedUser);
    console.log("New User state after update:", newUser);
    setCurrentStage((prevStage) => prevStage + 1);
  };

  const handleBack = () => {
    setCurrentStage((prevStage) => (prevStage > 0 ? prevStage - 1 : prevStage));
  };

  const Stage = signUpStage[currentStage].component;

  return (
    <>
      <Stage
        onNext={handleNext}
        user={newUser}
        setUser={setNewUser}
        onBack={handleBack}
        completeAccount={completeAccount}
        setSignUpStage={setSignUpStage}
      />
    </>
  );
};

export default SignIn;
