import React, { Fragment, useEffect, useRef, useState } from 'react';
import styles from './../submissions.module.css';

import SyncSubmissionCard from './syncSubmissionCard';
import { Loader } from '../../OpportunitiesComponents/AdditionalComponents/Loader';
import useAppState from '../../../context/useAppState';

import { useDebounce } from '../../../hooks/useDebounce';
import { Box, Grid, Typography } from '@mui/material';
import { LOOKING_FOR } from '../../../shared/constants';

import useAuth from '../../../context/AuthContext';
import BeatInfoData from '../ViewSubmissionInformation/BeatInfoData';
import { useNavigate } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import SettingThumbIcon from '../../../assets/Icons/SettingThumb.png';

function SyncSubmissionsComponent() {
  const tabArray = ['Sync Placement'];
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState(tabArray[0]);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState(tabArray[0]);

  const { user } = useAuth();
  const navigate = useNavigate();

  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  const searchQuery = useDebounce(query, 500);

  const { fetchMySubmission } = useAppState('sync');
  const PAGE_LIMIT = 10;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setPage(0);
  }, [type]);

  useEffect(() => {
    if (false) {
      setLoading(true);
      const queryString = new URLSearchParams({
        page: 1,
        limit: PAGE_LIMIT,

        search: searchQuery,
        sort: sortBy,
      }).toString();

      fetchMySubmission(queryString)
        .then((response) => {
          const newRows = response.data;

          setHasNextPage(newRows.length < response.totalCount);
          setData(newRows);
          setPage((page) => page + 1);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [searchQuery, sortBy, type]);

  useEffect(() => {
    const tabEl = [...tabsRef.current.children].find(
      (child) => child.textContent === activeTab
    );
    const { offsetLeft, offsetWidth } = tabEl;
    underlineRef.current.style.left = `${offsetLeft}px`;
    underlineRef.current.style.width = `${offsetWidth}px`;
  }, [activeTab]);

  const getMoreData = async () => {
    try {
      const newPage = page + 1;
      const queryString = new URLSearchParams({
        page: newPage,
        limit: PAGE_LIMIT,
        search: searchQuery,
        sort: sortBy,
      }).toString();
      fetchMySubmission(queryString)
        .then((response) => {
          const newRows = [...data, ...response.data];

          console.info('newRows => ', newRows);

          setHasNextPage(newRows.length < response.totalCount);
          setData(newRows);
          setPage((page) => page + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setError(err);
      // console.log("error on fetch more...", err);
    }
  };

  const fetchMore = () => {
    console.log('fetch more...');
    getMoreData();
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Submissions</p>

      <Box>
        <Box sx={{ padding: '15px 0px 10px 0' }}>
          <div className={styles.search_box}>
            <img
              className={styles.search_icon}
              src='/v2Assets/connect_assets/Search.png'
              alt='search'
            ></img>
            <input
              className={styles.search_box_input}
              type='text'
              disabled={true}
              placeholder={'Search'}
              onChange={(data) => {
                console.info('data => ', data);
                setQuery(data.target.value);
              }}
            />
          </div>
        </Box>
      </Box>

      <div className={styles.tabs} ref={tabsRef}>
        {tabArray.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => {
              setActiveTab(tab);
              setType(type === tab ? '' : tab);
            }}
          >
            {tab}
          </div>
        ))}
        <div className={styles.underline} ref={underlineRef} />
      </div>

      <div className={styles.tab_layout}>
        {!loading ? (
          <Fragment>
            {data?.length > 0 ? (
              <>
                {data.map((o, key) => (
                  <Fragment key={key}>
                    <SyncSubmissionCard
                      onView={() => {}}
                      isShowBeatDetail={true}
                      data={o}
                      isShowOpportunityName={true}
                      beatInfoBtn={<BeatInfoData beatData={o} />}
                    />
                  </Fragment>
                ))}
              </>
            ) : (
              // <Box
              //   width={'100%'}
              //   id={'maintance_Modal'}
              //   bgcolor={'#ffffff10'}
              //   height={'60vh'}
              //   display={'flex'}
              //   justifyContent={'center'}
              //   alignItems={'center'}
              // >
              //   <Box
              //     bgcolor={'#141515'}
              //     width={'30%'}
              //     padding={'40px'}
              //     borderRadius={'10px'}
              //     display={'flex'}
              //     flexDirection={'column'}
              //     alignItems={'center'}
              //   >
              //     <img src={SettingThumbIcon} height={'48px'} width={'48px'} />

              //     <p
              //       style={{
              //         fontFamily: 'Poppins',
              //         fontWeight: 500,
              //         fontSize: '18px',
              //         color: '#fff',
              //         lineHeight: '27px',
              //         textAlign: 'center',
              //       }}
              //     >
              //       We are under maintenance
              //     </p>
              //     <p
              //       style={{
              //         fontFamily: 'Poppins',
              //         fontWeight: 400,
              //         fontSize: '14px',
              //         color: '#fff',
              //         lineHeight: '20px',
              //         textAlign: 'center',
              //       }}
              //     >
              //       All your submissions have been safely delivered. We are
              //       currently updating this area and you will be able to view
              //       your submissions soon.🙂
              //     </p>
              //   </Box>
              // </Box>
              
              <>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: '#fff',
                    alignItems: 'center',
                  }}
                >
                  <p classes={styles.empty_page_text}>No Data Found</p>
                </Box>
              </>
            )}
          </Fragment>
        ) : (
          <Loader />
        )}

        {(loading || hasNextPage) && (
          <Grid container justifyContent={'center'} ref={sentryRef}>
            <Grid item>
              <Typography style={{ color: '#2ebb55' }}>Loading..</Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default SyncSubmissionsComponent;
