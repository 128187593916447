import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import SubmissionsComponent from "../../components/SubmissionsComponents/OpportunitySubmissions/OpportunitySubmissionsComponent";

const OpportunitySubmissionsPage = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<SubmissionsComponent />}
        activeText="My Submissions"
      />
    </Grid>
  );
};

export default OpportunitySubmissionsPage;
