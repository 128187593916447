import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  position: "relative",
}));
const LinksMain = styled(Box)(({ theme }) => ({
  background: "#0D120F",
  height: "max-content",
  marginTop: "-8px",
  position: "sticky",
  left: "0",
  top: "0",
  h1: {
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    padding: "15px 15px",
  },
  ul: {
    padding: 0,
    margin: 0,
    listStyle: "none",
    padding: "0 15px",
  },
  li: {
    position: "relative",

    "&.active": {
      a: {
        color: "#2EBB55",
      },
      img: {
        filter:
          "invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)",
      },
    },

    "&.active::after": {
      content: "''",
      position: "absolute",
      borderRadius: "0px 5px 5px 0px",
      background: "#2EBB55",
      height: "100%",
      width: "5px",
      top: 0,
      left: "-15px",
    },
    padding: "12px 0",
    a: {
      display: "flex",
      gap: "10px",
      alignItems: "center",
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textDecoration: "none",
    },
  },
}));

export { Main, LinksMain };
