import React, { useRef, memo } from "react";
import { Modal } from "@mui/material";
import { ModelContentBox, Modelstyle } from "../styles/style";
// import DescriptionArea from "../../common/inputfield/TextAreaField";
import { Notification } from "../../../NotifyMsg/Notification";
import OutlineButton from "../../AdditionalComponents/OutlineButton";
import NoItemComponents from "../../AdditionalComponents/utils/NoItemComponent";
import BlueButtonComponents from "../../AdditionalComponents/utils/BlueButtonComponent";

import { trashAudioFile, deleteBeatPackbydocId, deleteAudioById } from "../../../../context/apis/audio-library";

const DeletePackModal = ({
  open = false,
  setOpen = () => { },
  editData,
  type = "",
  index,
  deleteCard,
}) => {
  const deleteModalClose = (event) => {
    setOpen(!open);
  };

  const deleteAudioFile = (event) => {
    trashAudioFile(editData.basicInfo.userID, editData._id)
      .then((data) => {
      })
      .catch((error) => {
        console.error("Failed to trash the audio file:", error);
      });
    setOpen(!open);
    deleteCard(index);
  };

  const deleteAudioFilePermanantly = (event) => {
    deleteAudioById(editData._id)
      .then((data) => {
      })
      .catch((error) => {
        console.error("Failed to delete the audio file:", error);
      });
    setOpen(!open);
    deleteCard(index);
  };


  const deleteBeatPack = (event) => {
    deleteBeatPackbydocId(editData._id)
      .then((data) => {
      })
      .catch((error) => {
        console.error("Failed to delete the beat pack:", error);
      });
    setOpen(!open);
    deleteCard(index);
  };

  let deleteOperation;

  switch (type) {
    case "file":
      deleteOperation = deleteAudioFile;
      break;
    case "pack":
      deleteOperation = deleteBeatPack;
      break;
    case "deletedFile":
      deleteOperation = deleteAudioFilePermanantly;
      break;
  }


  return (
    <>
      <Modal
        open={open}
        onClose={deleteModalClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="MainInboxCreate"
          sx={{
            ...Modelstyle,
            width: "448",
            display: "flex",
            padding: "80px",
            height: "auto",
            flexDirection: "column",
            borderRadius: "10px",
            background: "#141515",
            overflowY: 'hidden'
          }}
        >
          <NoItemComponents src="/v2Assets/DeletePack.png" text="Delete File" />
          <div
            style={{
              fontSize: "16px",
              color: "#AFAFAF",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Do you really want to delete this pack?
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <BlueButtonComponents
              text="Cancel"
              background="transparent"
              bordercolor="#fff"
              fontsize="18px"
              onClickFunction={deleteModalClose}
            />
            <BlueButtonComponents
              text="Delete"
              fontsize="18px"
              onClickFunction={deleteOperation}
            />
          </div>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(DeletePackModal);
