import { NativeBaseProvider } from "native-base";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Helmet } from "react-helmet";
// import { clearConsoleLogs } from "./helpers/ClearConsole";
import OpportunityProvider from "./context/OpportunityContext";
import "./index.css";

import { Mixpanel } from "./context/mixpanel/mixPanel";

const App = (props) => {
  console.log("CHECKPOINT - APP");
  // clearConsoleLogs();
  const { childComp } = props;
  const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLISHERS_KEY}`);
  // useEffect(() => {
  //   Mixpanel.track(`Session Start`);
  // }, []);


  return (
    <Elements stripe={stripePromise}>
      <OpportunityProvider>
        <NativeBaseProvider>
          <ToastContainer />

          <Helmet>
            {/* <script
						async
						src='https://www.googletagmanager.com/gtag/js?id=AW-11199713092'
					></script>
					<script>
						{`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'AW-11199713092');
			gtag('event', 'conversion', {'send_to': 'AW-11199713092/gfDZCI_NjKYYEMSeuNwp'});
          `}
					</script> */}
          </Helmet>

          <div className="gradient">{childComp}</div>
        </NativeBaseProvider>
      </OpportunityProvider>
    </Elements>
  );
};

export default App;
