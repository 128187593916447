import { useLazyQuery, useMutation } from "@apollo/client";
import StarIcon from "@mui/icons-material/Star";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  beatinbox_message,
  beatsubmit_by,
  import_icon,
  star,
  tickcircle,
} from "../../../../assets/smartlink";
import RoundExclamation from "../../../../assets/smartlink/icons/RoundExclamation";
import { sendConnectionRequest } from "../../../../context/apis/connect-me";
import useAuth from "../../../../context/AuthContext";
import {
  ADDToFavouriteBeats,
  DOWNLOAD_SINGLE_BEAT,
  GetAllinboxBeats,
} from "../../../../Graphql_services/beatinbox/Beats";
import BeatCardCheckoutModal from "../../../BeatsCheckout/BeatCardCheckoutModal";
import { BeatsCheckoutFailedModal } from "../../../BeatsCheckout/BeatsCheckoutFailedModal";
import { BeatsCheckoutSuccessModal } from "../../../BeatsCheckout/BeatsCheckoutSuccessModal";
import { BeatsLicenseModal } from "../../../BeatsCheckout/BeatsLiscenseModal";
import { Notification } from "../../../NotifyMsg/Notification";
import MultiAudioPlayer from "../../common/Audioplayer/MultiAudioplayer";
import LoadingSpinner from "../../common/loader/LoadingSpinner";
import Collaborations from "./Collaborations";
import { CollaborationsIconDiv } from "./CollaborationsStyle";
import { HeaderInfoBox, MainBeatsBox } from "./style";

const MODALS = {
  LICENSE_MODAL: "LICENSE_MODAL",
  CHECKOUT_MODAL: "CHECKOUT_MODAL",
  SUCCESS_MODAL: "SUCCESS_MODAL",
  ERROR_MODAL: "ERROR_MODEL",
};

const InboxBeats = ({ data, index, currentAudio, handleToggle }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [addToFavouriteBeats] = useMutation(ADDToFavouriteBeats, {
    refetchQueries: [GetAllinboxBeats, "getBeats"],
    context: { clientName: "beats" },
  });
  const [downloadSingleBeat] = useLazyQuery(DOWNLOAD_SINGLE_BEAT, {
    context: { clientName: "beats" },
  });
  const [price, setPrice] = useState(0);
  const [modalName, setModalName] = useState(null);

  // Function to close the modal
  const closeModal = () => {
    setModalName(null);
  };

  const handleModal = (name) => {
    setModalName(null);

    setTimeout(() => {
      setModalName(name);
    }, 500);
  };

  const {
    _id,
    remainingDays,
    audioFileId,
    beatExclusivePrice,
    isFavourite,
    collaboratorsDetails,
    musicianName,
    tone,
    containsMasterSample,
    publishingSplit,
    inboxId,
    userName,
    profilePic,
  } = data || {};

  const IsBeat = data?.isBeat;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [currentFav, setcurrentFav] = useState(null);
  const words = audioFileId?.fileName.substr(-22);

  const AddToFavouritehandler = async (id) => {
    try {
      setIsLoading(true);
      if (!id) {
        Notification("error", "id is required!");
        return;
      }
      setcurrentFav(id);
      const res = await addToFavouriteBeats({
        variables: { id },
        context: { clientName: "beats" },
      });
      if (res?.data?.addFavouriteBeats?.success) {
        Notification("success", res?.data?.addFavouriteBeats?.message);
      } else {
        Notification("error", res?.data?.addFavouriteBeats?.message);
      }
    } catch (error) {
      Notification("error", error.message);
    } finally {
      setIsLoading(false);
      setcurrentFav(null);
    }
  };

  const SingleBeatDownload = async (id) => {
    if (!audioFileId?.url) {
      Notification("error", "File Url id required");
      return;
    }
    try {
      downloadSingleBeat({ variables: { downloadBeatId: id } });
      const response = await fetch(audioFileId.url);
      const audioBlob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(audioBlob);
      downloadLink.download = audioFileId.fileName || "audio_file";
      downloadLink.onclick = () => {
        Notification("success", "Download complete!");
      };
      downloadLink.click();
    } catch (error) {
      Notification("error", error.message);
    }
  };

  const capitalizeEachWord = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleBeatDownload = async () => {
    if (!audioFileId?.url) {
      new Notification("error", {
        body: "No audio file found!",
      });
      return;
    }
    const response = await fetch(audioFileId.url);
    const audioBlob = await response.blob();
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(audioBlob);
    downloadLink.download = audioFileId.fileName || "audio_file";
    downloadLink.onclick = () => {
      new Notification("success", {
        body: "Download complete!",
      });
    };
    downloadLink.click();
  };

  const handleSuccess = () => {
    handleModal(MODALS.SUCCESS_MODAL);
    handleBeatDownload();
  };

  // _id,
  // type,
  // audioFile,
  // audioFileName,
  // profileImage,
  // useName,
  // price,
  // isSelected,
  // collaborators,
  // isMasterSample,
  // openModal,
  // closeModal,
  //test

  const createOneTwoOneChat = async () => {
    console.log("---data", data);
    let inboxOwner = data.inboxId.userId;
    let createrId = data.createrId;
    console.log("inboxer id", inboxOwner);
    console.log("createrId id", createrId);
    if (inboxOwner === createrId) {
      toast.error("You can not start a conversation with yourself ");
    } else {
      console.log("sending request");
      let conenction = await sendConnectionRequest(createrId, inboxOwner);
      console.log("connection", conenction);
      if (conenction.chatId) {
        navigate(`/chat/${conenction.chatId}`);
      } else toast.error("Unable to start Chat please try again in a while!");
    }
  };

  return (
    <>
      <MainBeatsBox>
        <HeaderInfoBox>
          <Box className="heading-main">
            <Box className="beats-info">
              <Box className="heading-box">
                <Typography variant="body1">{words}</Typography>
                <img src={tickcircle} alt="circle-tick" />
              </Box>

              {IsBeat && (
                <Box className="inbox-desc">
                  <Box className="user-img-box">
                    <img src={profilePic || beatsubmit_by} alt="profile-pic" />
                  </Box>
                  <span>
                    {musicianName
                      ? `${capitalizeEachWord(musicianName)}`
                      : "No Musician"}{" "}
                  </span>
                  {data?.createrId && (
                    <img
                      src={beatinbox_message}
                      onClick={createOneTwoOneChat}
                      alt="messsag-icon"
                    />
                  )}
                </Box>
              )}
            </Box>

            <Box className="single_filet_icons">
              <Button onClick={() => SingleBeatDownload(_id)}>
                <img src={import_icon} alt="" />
              </Button>

              <CollaborationsIconDiv
                style={{
                  background: isPopoverOpen
                    ? `rgba(79, 132, 93, 0.30)`
                    : `#141515`,
                }}
                onMouseEnter={() => setIsPopoverOpen(true)}
                onMouseLeave={() => setIsPopoverOpen(false)}
              >
                <RoundExclamation />
                {isPopoverOpen && (
                  <Collaborations
                    list={collaboratorsDetails}
                    tone={tone}
                    containsMasterSample={containsMasterSample}
                    userName={userName}
                    publishingSplit={publishingSplit}
                  />
                )}
              </CollaborationsIconDiv>
            </Box>
          </Box>

          {modalName === MODALS.LICENSE_MODAL && (
            <BeatsLicenseModal
              openModal={true}
              closeModal={closeModal}
              data={data}
              handleCheckout={() => handleModal(MODALS.CHECKOUT_MODAL)}
              setPrice={setPrice}
            />
          )}

          {modalName === MODALS.CHECKOUT_MODAL && (
            <BeatCardCheckoutModal
              isOpen={true}
              handleClose={closeModal}
              handleSuccess={handleSuccess}
              price={price}
              handleError={() => {
                console.log("--- handle error reached ----");
                handleModal(MODALS.ERROR_MODAL);
              }}
            />
          )}

          {modalName === MODALS.SUCCESS_MODAL && (
            <BeatsCheckoutSuccessModal
              openModal={true}
              closeModal={closeModal}
            />
          )}

          {modalName === MODALS.ERROR_MODAL && (
            <BeatsCheckoutFailedModal
              openModal={true}
              closeModal={closeModal}
              handleTryAgain={() => handleModal(MODALS.CHECKOUT_MODAL)}
            />
          )}

          {IsBeat && (
            <Box className="remaining-main">
              <Box className="left">
                <Typography variant="body1">remaining days :</Typography>
                <span>{remainingDays}</span>
              </Box>
              <Box className="right">
                <Typography variant="body1">beat price :</Typography>
                <span>${beatExclusivePrice}</span>
              </Box>
            </Box>
          )}

          <Box
            className="btn-box"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {!isFavourite ? (
              <Button
                variant="contained"
                className="btn"
                onClick={() => AddToFavouritehandler(_id)}
                disabled={isLoading}
              >
                {currentFav == _id ? (
                  <LoadingSpinner size={15} />
                ) : (
                  <>
                    <img
                      src={star}
                      alt="ellips-icon"
                      style={{ width: "1rem", height: "1rem" }}
                    />
                    favorite
                  </>
                )}
              </Button>
            ) : (
              <Button
                variant="contained"
                className="btn"
                onClick={() => AddToFavouritehandler(_id)}
                disabled={isLoading}
              >
                {currentFav == _id ? (
                  <LoadingSpinner size={15} />
                ) : (
                  <>
                    <StarIcon
                      className="staricon"
                      sx={{
                        color: "#D9C400 !important",
                        fill: "#D9C400 !important",
                      }}
                    />
                    favorite
                  </>
                )}
              </Button>
            )}

            <Button
              variant="contained"
              className="btn"
              onClick={() => handleModal(MODALS.LICENSE_MODAL)}
              sx={{
                backgroundColor: "#2EBB55 !important",
                color: "white !important",
              }}
            >
              Buy
            </Button>
          </Box>
          <Box className="audio-main">
            <MultiAudioPlayer
              key={index}
              isPlaying={audioFileId?.url == currentAudio}
              onToggle={handleToggle}
              src={audioFileId?.url}
            />
          </Box>
        </HeaderInfoBox>
      </MainBeatsBox>
    </>
  );
};

export default InboxBeats;
