import React, { memo } from "react";
import { InputMain } from "./style";

const InputField = ({ label, field, form, ...props }) => {
  return (
    <>
      <InputMain className={`${!label ? "no_has_label" : null}`}>
        {label && <label>{label}</label>}
        <input {...field} {...props} />
      </InputMain>
    </>
  );
};

export default memo(InputField);
