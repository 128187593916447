//import useOpportunityStore from "../hooks/globalStores/useOpportunityStore";
import React, { useState } from "react";
import styles from "./Libraries.module.css";
import { useMediaQuery } from "@material-ui/core";
import EditCardModal from "./Modals/EditCardModal";
import DeletePackModal from "./Modals/DeletePackModal";
import SendFileModal from "./Modals/SendFileModal";
import DarkTooltip from "../../OpportunitiesComponents/AdditionalComponents/Tooltip/DarkTooltip";
import { Pack_Detail_Tooltop, UNCOMPLETE_LIBRARY_TOOLTIP } from "../../../shared/constants";

import { starAudioFile } from "../../../context/apis/audio-library";

function TimeAgo(createdAT) {
  const currentTime = new Date();
  const pastTime = new Date(createdAT);
  const diffInSeconds = Math.floor((currentTime - pastTime) / 1000);

  let timeAgo = "";
  if (diffInSeconds < 60) {
    timeAgo = `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    // less than 1 hour
    const minutes = Math.floor(diffInSeconds / 60);
    timeAgo = `${minutes} minutes ago`;
  } else if (diffInSeconds < 86400) {
    // less than 1 day
    const hours = Math.floor(diffInSeconds / 3600);
    timeAgo = `${hours} hours ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    timeAgo = `${days} days ago`;
  }

  return timeAgo;
}

function LibraryCard({
  data,
  onDelete,
  index,
  onEditComplete,
  onDeleteComplete,
}) {

  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const cover_image =
    data?.basicInfo?.imageURL ?
      (`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${data?.basicInfo?.imageURL}`) :
      "/images/no_image.png";
  const audio_url = `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${data?.basicInfo?.fileURL}`
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sendFileModalOpen, setSendFileModalOpen] = useState(false);

  const [userInfo, setUserInfo] = useState();
  const [isStarred, setIsStarred] = useState(data.basicInfo.isStared);

  const isFeatured = data?.featured;

  const handleStarred = () => {
    setIsStarred(!isStarred);
    starAudioFile(data.basicInfo.userID, data._id, !isStarred)
      .then((data) => {
      })
      .catch((error) => {
        console.error("Failed to star the audio file:", error);
      });
  };

  const updateLibraryCard = (data) => {
    onEditComplete(index, data);
  };

  React.useEffect(() => {
    if (data?.basicInfo?.userID) {
      setUserInfo(data?.user);
    }
  }, [data]);

  return (
    <div>
      <div>
        {!isDesktop ? (
          <div className={styles.opportunity_card_layout_mobile}>
            <div className={styles.opportunity_card_content_mobile}>
              <div className={styles.opportunity_card_content_left_mobile}>
                <img
                  src={cover_image}
                  className={styles.opportunity_card_image_container_mobile}
                >
                </img>
              </div>

              <div className={styles.opportunity_card_content_layout}>
                <div className={styles.opportunity_card_information}>
                  <div className={styles.opportunity_card_left_side}>
                    <div
                      className={styles.opportunity_card_left_side_information}
                    >
                      <AudioFileTitleInfo />
                      <AudioFileInformation />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.opportunity_card_layout}>
            <div className={styles.opportunity_card_content}>
              <div className={styles.opportunity_card_content_left} style={{ alignItems: "center" }}>
                <img
                  src={cover_image}
                  className={styles.opportunity_card_image_container}
                >
                </img>
                <div className={styles.opportunity_title_info}>
                  <AudioFileTitleInfo />
                </div>
              </div>

              <div className={styles.opportunity_card_content_layout}>
                <div className={styles.opportunity_card_information}>
                  <div className={styles.opportunity_card_left_side}>
                    <div
                      className={styles.opportunity_card_left_side_information}
                    >
                      <AudioFileInformation />
                      <AudioFileAction timeAgo={TimeAgo(data.createdAt)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <EditCardModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        editData={data}
        completeEdit={updateLibraryCard}
      />

      <SendFileModal
        open={sendFileModalOpen}
        setOpen={setSendFileModalOpen}
        type="file"
        id={data?._id}
        imageUrl={data?.basicInfo?.imageURL}
        title={data?.basicInfo?.fileName}
      />

      <DeletePackModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        editData={data}
        type={onDelete ? "deletedFile" : "file"}
        index={index}
        deleteCard={() => onDeleteComplete(index)}
      />
    </div>
  );

  function AudioFileTitleInfo() {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <p className={styles.opportunity_title}>{data.basicInfo.fileName}</p>
          {!data.isComplete && (
            <DarkTooltip title={UNCOMPLETE_LIBRARY_TOOLTIP} placement="bottom">
              <img src="/v2Assets/LibraryCardIcons/Info.png" />
            </DarkTooltip>
          )}
        </div>
        <p className={styles.opportunity_sub_title}>
          {data?.basicInfo?.fileNote}
        </p>
      </div>
    );
  }

  function AudioFileInformation() {
    return (
      <div className={styles.opportunity_data_information}>
        <div className={styles.opportunity_tags_container}>
          {data.basicInfo.tags.map((element, index) => (
            <div key={`audioInfoZ-${index}`} className={styles.opportunity_tags}>
              {element.value}
            </div>
          ))}
        </div>
      </div>
    );
  }

  function AudioFileAction({ timeAgo }) {
    return (
      <div className={styles.opportunity_data_information}>
        <div className={styles.opportunity_tags_container}>
          <div className={styles.opportunity_action_tags}>{timeAgo}</div>
          <div className={styles.opportunity_action_tags_divider} />
          <div
            className={styles.opportunity_action_tags}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setEditModalOpen(true);
            }}
          >
            <img
              alt="edit"
              src="/v2Assets/LibraryCardIcons/Edit.png"
              className={styles.opportunity_action_tags_icon}
            />
            Edit
          </div>
          <div className={styles.opportunity_action_tags_divider} />
          <div
            className={styles.opportunity_action_tags}
            style={{ cursor: "pointer" }}
          >
            <img
              alt="edit"
              src="/v2Assets/LibraryCardIcons/Download.png"
              className={styles.opportunity_action_tags_icon}
            />
            <a href={audio_url} target="_blank" download style={{ color: "#AFAFAF", textDecoration: "none" }}  >MP3 Download</a>
          </div>
          {/* <div className={styles.opportunity_action_tags_divider} /> */}
          {/* <div
            className={styles.opportunity_action_tags}
            style={{ cursor: "pointer" }}
          >
            <img
              alt="contract"
              src="/v2Assets/LibraryCardIcons/SendContract.png"
              className={styles.opportunity_action_tags_icon}
            />
            Send Contract
          </div>
          <div className={styles.opportunity_action_tags_divider} />
          <div
            className={styles.opportunity_action_tags}
            style={{ cursor: "pointer" }}
          >
            <img
              alt="edit"
              src="/v2Assets/LibraryCardIcons/CreatePack.png"
              className={styles.opportunity_action_tags_icon}
            />
            Create Pack
          </div> */}
          <div className={styles.opportunity_action_tags_divider} />
          <div
            className={styles.opportunity_action_tags}
            style={{ cursor: "pointer" }}
            onClick={() => setSendFileModalOpen(true)}
          >
            <img
              alt="edit"
              src="/v2Assets/LibraryCardIcons/Share.png"
              className={styles.opportunity_action_tags_icon}
            />
            Share
          </div>
          <div className={styles.opportunity_action_tags_divider} />
          <div
            className={styles.opportunity_action_tags}
            style={{ cursor: "pointer" }}
            onClick={() => setDeleteModalOpen(true)}
          >
            <img
              alt="edit"
              src="/v2Assets/LibraryCardIcons/Delete.png"
              className={styles.opportunity_action_tags_icon}
            />
            <div style={{ color: !onDelete ? "#AFAFAF" : "#AB1111" }}>
              Delete
            </div>
          </div>
          <div className={styles.opportunity_action_tags_divider} />
          <div
            className={styles.opportunity_action_tags}
            style={{ cursor: "pointer" }}
            onClick={handleStarred}
          >
            <img
              alt="edit"
              src={
                !isStarred
                  ? "/v2Assets/LibraryCardIcons/Starred.png"
                  : "/v2Assets/LibraryCardIcons/star.png"
              }
              className={styles.opportunity_action_tags_icon}
            />
            <div style={{ color: !isStarred ? "#AFAFAF" : "#F6E11F" }}>
              Starred
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LibraryCard;
