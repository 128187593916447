import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
const Submitbtn = styled(Button)(({ theme }) => ({
  padding: "10.5px 30px !important",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px !important",
  fontFamily: theme.typography.fontFamily,
  fontSize: "18px !important",
  color: "#FFFFFF !important",
  background: "#2EBB55 !important",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  cursor: "pointer",
  textTransform: "capitalize",
  "&:hover": {
    background: "#2EBB55",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export { Submitbtn };
