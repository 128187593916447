import { Box } from "@mui/material";
import { styled } from "@mui/system";
const SelectMain = styled(Box)(({ theme }) => ({
  marginTop: "23px",
  color: "#fff",
  "& .label-box": {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& .icon-main": {
      marginLeft: "7px",
      "& .escalimation": {
        color: "#AFAFAF",
        cursor: "pointer",
        fontSize: "20px",
        "&:hover": {
          borderRadius: "100px",
          filter:
            "invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)",
        },
      },
      "& .icon-content": {
        position: "absolute",
        top: "100%",
        left: "0px",
        zIndex: "9999",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "5px",
        background: "#141515",
        padding: "18px",
        color: "#AFAFAF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        width: "240px",
        [theme.breakpoints.down("sm")]: {
          left: "-120px",
        },
      },
    },
    "& h5": {
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
  "& .select-box": {
    marginTop: "12px",
    "& .react-select__control:focus": {
      border: "none",
      boxShadow: "none",
      outline: "none",
    },
  },
}));

export { SelectMain };
