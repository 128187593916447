import React from 'react';
import styles from './../styles.module.css';
export const SoundPackEmpty = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" viewBox="0 0 140 140" fill="none">
  <path d="M100.508 121.162L121.158 100.512" stroke="#565656" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M121.158 121.162L100.508 100.512" stroke="#565656" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M40.8333 61.25V78.75" stroke="#565656" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M70 61.25V78.75" stroke="#565656" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M99.1667 61.25V78.75" stroke="#565656" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M128.333 75.8327V52.4993C128.333 23.3327 116.666 11.666 87.4998 11.666H52.4998C23.3332 11.666 11.6665 23.3327 11.6665 52.4993V87.4993C11.6665 116.666 23.3332 128.333 52.4998 128.333H75.8332" stroke="#565656" stroke-width="15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
	);
};
