import styles from "./profile.module.css";
import ProfileAboutComponent from "./profile_about_component";
import { useNavigate } from "react-router-dom";
import useAuth from "../../context/AuthContext";

function ProfileHeader(props) {
  const { user } = useAuth();
  const profile = props.profile;
  const userId = user._id;
  const navigate = useNavigate();

  function handleEditProfile() {
    navigate("/settings");
  }

  const isSelf = userId === profile._id;

  return (
    <div>
      <div className={styles.header_banner}>
        <div className={styles.shadow_overlay}></div>
        <div className={styles.profile_info}>
          <div className={styles.profile_info_left_side}>
            <div className={styles.profile}>
              <img
                className={styles.profile_photo}
                alt="profile"
                src={profile.profilePic} // TODO: [UMIC-113] Add dummy image
              ></img>
              <div>
                <p className={styles.profile_username}>
                  {props.profile.userName}
                </p>

                <p className={styles.profile_role}>
                  {props.profile.roleId?.roleName}
                </p>
              </div>
            </div>
            <ProfileAboutComponent profile={props.profile} />
          </div>
          <div className={styles.profile_info_right_side}>
            {/* //SOCIAL LINKS */}
            {/* <div className={styles.social_links}>
              <div
                className={styles.social_link}
                // onClick={() => fetchArtist()}
              >
                <p className={styles.social_link_text}>Spotify</p>
              </div>
              <div className={styles.social_link}>
                <p className={styles.social_link_text}>Instagram</p>
              </div>
              <div className={styles.social_link}>
                <p className={styles.social_link_text}>Youtube</p>
              </div>
            </div> */}
            {isSelf && (
              <div
                className={styles.edit_profile_button}
                onClick={handleEditProfile}
              >
                <div
                  className={styles.edit_profile_text}
                  // onClick={() => getArtist("0TnOYISbd1XYRBk9myaseg")}
                >
                  Edit Account
                </div>
              </div>
            )}
          </div>
        </div>
        <img
          className={styles.cover_image}
          src="/images/profileBackground.jpeg"
          alt="Cover"
        ></img>
      </div>
      <div className={styles.black_half_opacity_bg}></div>
    </div>
  );
}

export default ProfileHeader;
