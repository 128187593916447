// import React, { useState } from "react";
// import styles from "./Libraries.module.css";

// const EditableText = ({ text, style, name, onEdit }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   // const [editedText, setEditedText] = useState(text);

//   const handleDoubleClick = () => {
//     setIsEditing(true);
//   };

//   const handleInputChange = (e) => {
//     onEdit(e.target.value);
//     // setEditedText(e.target.value);
//   };

//   const handleSave = () => {
//     setIsEditing(false);
//   };

//   return (
//     <div onDoubleClick={handleDoubleClick}>
//       {isEditing ? (
//         <input
//           type="text"
//           value={text}
//           onChange={handleInputChange}
//           className={style}
//           name={name}
//           onBlur={handleSave}
//           autoFocus
//         />
//       ) : (
//         <span className={style}>{text}</span>
//       )}
//     </div>
//   );
// };

// export default EditableText;

import React, { useState } from "react";
import styles from "./Libraries.module.css";

const EditableText = ({ text: initialText, style, name, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(initialText);

  // Invoke this function when the text has been edited
  const handleEditComplete = () => {
    onEdit(editedText); // Updates the parent state
    setIsEditing(false);
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setEditedText(e.target.value); // Updates local state
  };

  return (
    <div onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <input
          type="text"
          value={editedText}
          onChange={handleInputChange}
          className={style}
          name={name}
          onBlur={handleEditComplete}
          autoFocus
        />
      ) : (
        <span className={style}>{initialText}</span>
      )}
    </div>
  );
};

export default EditableText;
