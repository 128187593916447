import React, { useState } from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  MainTitle,
  PageTitle,
  LinksFlters,
  EmptyCreatesLink,
  CreatedLinksGridType,
  HorizontalCreatedLink,
  PaginationMain,
} from "../../../components/smartlink";
import { GET_ALL_SMART_LINKS } from "../../../Graphql_services/smartlink/SmartLink";
import { useQuery } from "@apollo/client";

const CreatedLinksPage = () => {
  const [layouttype, setlayouttype] = useState("grid");
  const { loading, error, data, refetch } = useQuery(GET_ALL_SMART_LINKS, {
    context: { clientName: "smartlink" },
    fetchPolicy: "network-only",
  });

  const smartLinks = data?.getUserSmartLinks?.smartlinks;

  console.log(smartLinks, "SmaertLinks");

  return (
    <>
      <SmartLinkLayout>
        <MainTitle title="Smart Link" hasbutton={true} islinkpage={true} />
        <HorizontalMenu />
        <LinksFlters layouttype={layouttype} setlayouttype={setlayouttype} />
        {loading && <p style={{ color: "#fff" }}>Loading....</p>}

        {smartLinks && smartLinks?.length > 0 ? (
          layouttype === "grid" ? (
            <CreatedLinksGridType smartLinks={smartLinks} />
          ) : layouttype === "horizontal" ? (
            <HorizontalCreatedLink smartLinks={smartLinks} />
          ) : null
        ) : (
          !loading && <EmptyCreatesLink />
        )}
        {/* <PaginationMain /> */}
      </SmartLinkLayout>
    </>
  );
};

export default CreatedLinksPage;
