import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "native-base";
import { useLazyQuery } from "@apollo/client";
import UploadSoundKitsBasic from "./UploadSoundKitsBasic";
import UploadSoundKitsFile from "./UploadSoundKitsFile";
import UploadSoundKitsTag from "./UploadSoundKitsTag";
import UploadSoundKitsCollaborators from "./UploadSoundKitsCollaborators";
import UploadSoundKitsShare from "./UploadSoundKitsShare";
import SoundKit from "../SoundKit";
import { getMySoundPackById } from "../../../Graphql_services/SoundPackMutation";
import { Loader } from "../../Loader";
import { Grid } from "@mui/material";
import styles from "../styles.module.css";

const CreateSoundPack = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadStep, setUploadStep] = useState(1);
  const [uploadData, setUploadData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [edit, setEdit] = useState(false);

  const [GetMySoundPackById] = useLazyQuery(getMySoundPackById, {
    context: { clientName: "soundpack" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const { getMySoundPackById } = data;
      console.log("getMySoundPackById:", data);
      if (getMySoundPackById) {
        const { isTokenExpired, success, message } = getMySoundPackById;
        if (!isTokenExpired) {
          if (success) {
            setEditData(getMySoundPackById?.soundpack);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            //toast.error(message);
          }
        } else {
          setIsLoading(false);
          localStorage.clear();
          navigate("/signin");
        }
      }
    },
    onError(err) {
      console.log("error on getting user info", err);
    },
  });
  useEffect(() => {
    if (id) {
      console.log("id....", id);
      setIsLoading(true);
      GetMySoundPackById({
        variables: {
          soundPackId: id,
        },
      });
      setEdit(true);
    } else {
      console.log("create page");
    }
  }, [id]);
  return (
    <Box
      w={"100%"}
      minHeight={"calc(100vh - 80px)"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {isLoading ? (
        <Grid className={styles.loader}>
          <Loader />
        </Grid>
      ) : (
        <>
          {uploadStep === 1 ? (
            <UploadSoundKitsBasic
              setStep={(data) => setUploadStep(data)}
              uploadData={uploadData}
              setUploadData={(data) => setUploadData(data)}
              edit={edit}
              editData={editData}
            />
          ) : uploadStep === 2 ? (
            <UploadSoundKitsFile
              setStep={(data) => setUploadStep(data)}
              uploadData={uploadData}
              setUploadData={(data) => setUploadData(data)}
              edit={edit}
              editData={editData}
            />
          ) : uploadStep === 3 ? (
            <UploadSoundKitsTag
              setStep={(data) => setUploadStep(data)}
              uploadData={uploadData}
              setUploadData={(data) => setUploadData(data)}
              edit={edit}
              editData={editData}
            />
          ) : uploadStep === 4 ? (
            <UploadSoundKitsCollaborators
              setStep={(data) => setUploadStep(data)}
              uploadData={uploadData}
              setUploadData={(data) => setUploadData(data)}
              edit={edit}
              editData={editData}
            />
          ) : uploadStep === 5 ? (
            <UploadSoundKitsShare
              uploadData={uploadData}
              setUploadData={(data) => setUploadData(data)}
              setStep={(data) => setUploadStep(data)}
              edit={edit}
              editData={editData}
            />
          ) : (
            <SoundKit />
          )}
        </>
      )}
    </Box>
  );
};

export default CreateSoundPack;
