import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const CosignSectionMain = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "#141515",
  padding: "50px 0 50px 0",
}));
const SliderMainBox = styled(Box)(({ theme }) => ({
  "& .img_box": {
    position: "relative",
    perspective: "1000px",
    padding: "0px 25px",
    transition: "transform 0.9s",
    transformStyle: "preserve-3d",
    height: "400px",
    width: "320px !important",
    "&:hover": {
      transform: "rotateY(180deg)",
    },
  },
  "& .img_box .slide-img": {
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    objectFit: "cover",
    overflow: "hidden",
    borderRadius: "10px",
  },
  "& .img_box .content_main": {
    width: "100% !important",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    transform: "rotateY(180deg)",
    borderRadius: "10px",
    background: "#1C1B1B",
    padding: "20px",
    boxSizing: "border-box",
    color: "#FFFFFF",
    backfaceVisibility: "hidden",
  },
  "& .bottom_box": {
    position: "absolute",
    bottom: "15px",
    right: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    textAlign: "end",
    padding: 0,
    margin: 0,
    h5: {
      color: "#AFAFAF",
      textAlign: "right",
      fontFamily: theme.typography.fontFamily,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "capitalize",
      margin: 0,
    },
    h6: {
      color: "#AFAFAF",
      textAlign: "right",
      fontFamily: theme.typography.fontFamily,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "capitalize",
      margin: 0,
    },
  },
  // [theme.breakpoints.down("xl")]: {
  //   "& .img_box": {
  //     padding: "0px 25px",
  //   },
  // },
}));
export { CosignSectionMain, SliderMainBox };
