import axios from "../../config/axiosInterceptor";
import { HTTP_STATUS } from "../../shared/constants";

export const addSubmission = async (payload) => {
  try {
    const { data, status } = await axios.post(
      `${process.env.REACT_APP_CONNECT_ME_URL}/connect-me/submission/create-submission`,
      payload
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while saving submission");
    }
  } catch (error) {
    console.log("#addSubmission Err");
    throw new Error(error);
  }
};

export const fetchConnectMeSubmissions = async (queryString) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_CONNECT_ME_URL}/connect-me/submission/fetch-connect-submissions?${queryString}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while facing my submission");
    }
  } catch (error) {
    console.log("#getMySubmissions Err");
    throw new Error(error);
  }
};

export const acceptCollaborations = async (token) => {
  try {
    const { data, status } = await axios.post(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/collaborators/accept/${token}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while saving submission");
    }
  } catch (error) {
    console.log("#addSubmission Err");
    throw new Error(error);
  }
};

export const updateSubmissionById = async (id, payload) => {
  try {
    const { data, status } = await axios.patch(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/submission/${id}`,
      payload
    );

    if (status === HTTP_STATUS.OK || status === 201 || status === 1) {
      return data;
    } else {
      throw new Error("Error while saving submission");
    }
  } catch (error) {
    console.log("#updateSubmissionById Err");
    throw new Error(error);
  }
};

export const getSubmission = async (id, queryString) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/submission/${id}?${queryString}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while facing submission");
    }
  } catch (error) {
    console.log("#getSubmission Err");
    throw new Error(error);
  }
};

export const getSubmissionDetailsbyId = async (id) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/submission/byId/${id}`
    );
    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while facing submission");
    }
  } catch (error) {
    console.log("#getSubmission Err");
    throw new Error(error);
  }
};

export const addCollaboratorApi = async (id, payload) => {
  try {
    const { data, status, statusText } = await axios.post(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/submission/collaborators/${id}`,
      payload
    );
    if (statusText === HTTP_STATUS.OK || status === 200) {
      return data;
    }
  } catch (error) {
    console.log("error =", error);
    throw new Error(error);
  }
};

export const updateCollaboratorApi = async (id, payload) => {
  try {
    const { data, status, statusText } = await axios.put(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/submission/collaborators/${id}`,
      payload
    );
    if (statusText === HTTP_STATUS.OK || status === 200) {
      return data;
    }
  } catch (error) {
    console.log("Error while update collaborator ::".error);
    throw new Error(error);
  }
};
