/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Text,
} from "native-base";
import React from "react";
import styles from "./styles.module.css";
function NewLandingPageBanner() {
  return (
    <Box w={"100%"} bg={"#000"}>
      <video autoPlay loop id="video-background" muted>
        <source
          src="https://umapp-prod.s3.us-west-1.amazonaws.com/Static/header.mp4"
          type="video/mp4"
        />
      </video>

      <div className={styles.home_overlay}>
        <div className={styles.overlay_content}>
          <div className={styles.bannerText}>UNITED MARKET</div>
          <Link href="/signup">
            <button className={styles.main_action_button}>
              Let's Win Togetherr
            </button>
          </Link>
        </div>
      </div>
    </Box>
  );
}

export default NewLandingPageBanner;
