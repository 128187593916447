import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { acceptCollaborations } from '../../context/apis/submission';
import { Box, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';

const AcceptCollaborations = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    acceptCollaborations(token).then(() => {
      toast.success("Approved successfully")
      setSuccess(true)
    }).catch((err => {
      console.info('not accepted=>', err);
      toast.error("Something went wrong");
      setError(err)
    })).finally(() => {
      setIsLoading(false)
    })
  }, [token])


  const redirect = (path) => {
    navigate(`/${path}?accepted=true`);
  }

  return (
    <div>
      <Box style={{ color: "#fff", height: "100vh", display: "flex" }} >
        <Grid container justifyContent={"center"} alignContent={"center"}>
          <Grid item>
            {

              <Box>
                {
                  isLoading ? "Approving..." : error ? "Something went wrong, please try again" : success &&
                    <Fragment>
                      <Typography textAlign={"center"}>Success</Typography>
                      {!isLoading && <Grid container>
                        <Grid item>
                          <button style={{
                            display: 'flex',
                            border: '1px solid #fff',
                            paddingLeft: '2rem',
                            paddingRight: '2rem',
                            margin: '0',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            fontFamily: 'Poppins',
                            borderRadius: '4px',
                            background: '#fff',
                            color: '#2eba55',
                            fontSize: '16px',
                            cursor: 'pointer',
                            marginRight: '10px',
                            marginTop: "40px"
                          }} onClick={() => redirect("signin")}>Login</button>
                        </Grid>
                        <Grid item>
                          <button style={{
                            display: 'flex',
                            border: '1px solid #2eba55',
                            paddingLeft: '2rem',
                            paddingRight: '2rem',
                            margin: '0',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            fontFamily: 'Poppins',
                            borderRadius: '4px',
                            background: '#2eba55',
                            color: '#fff',
                            fontSize: '16px',
                            cursor: 'pointer',
                            margin: "auto",
                            marginTop: "40px"
                          }} onClick={() => redirect("signup")}>Sign Up</button>
                        </Grid>
                      </Grid>}
                    </Fragment>
                }
              </Box>
            }
          </Grid>
        </Grid>
      </Box>
    </div >
  )
}

export default AcceptCollaborations