import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const FrequentlySectionMain = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "#141515",
  padding: "100px 0 100px 0",
  [theme.breakpoints.down("md")]: {
    padding: "70px 0 50px 0",
  },
}));
const AccordianMain = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  margin: "0 auto",

  "& .acordian": {
    display: "flex",
    flexDirection: "column",
    width: "44.5%",
    justifyContent: "space-between",
    background: "#141515",
    borderBottom: "1px solid #414241",
    "&.Mui-expanded": {
      borderBottom: "1px solid #2EBB55",
    },
    "& .MuiAccordionSummary-root": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "34px 0 32px 0",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    h5: {
      color: "#FFFFFF",
      textAlign: "right",
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "30px",
      margin: 0,
    },
    h6: {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "30px",
      margin: 0,
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
      margin: 0,
      paddingBottom: "23px",
    },
  },
  [theme.breakpoints.down("xl")]: {
    "& .acordian": {
      width: "60%",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& .acordian": {
      width: "100% !important",
      h5: {
        textAlign: "left !important",
      },
    },
  },
}));
export { FrequentlySectionMain, AccordianMain };
