import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingSpinner = ({ size, color = "success" }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress color={color} size={size} />
    </div>
  );
};

export default LoadingSpinner;
