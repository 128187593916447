import React, { useEffect } from "react";
import { Grid } from "@mui/material";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import ViewSubmission from "../../components/ViewSubmissionComponents/viewSubmission";
import { useNavigate } from "react-router-dom";
import useAuth from "../../context/AuthContext";
import { get } from "lodash";
import { ROLES } from "../../shared/constants";

const ViewSubmissions = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user && get(user, "roleId.roleName", "") === ROLES.MUSIC_PRODUCER) {
      navigate("/opportunities");
    }
  }, [user]);

  return (
    <Grid>
      <Sidebar childComponent={<ViewSubmission />} activeText="Opportunities" />
    </Grid>
  );
};

export default ViewSubmissions;
