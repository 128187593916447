import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  arrow: {
    background: "1px solid #afafaf",
    color: "#1c1b1b",
  },
  tooltip: {
    backgroundColor: "#1C1B1B",
    color: "#AFAFAF",
    border: "1px solid #afafaf",
    fontSize: 14,
    padding: 0,
    margin: 0,
  },
}));

function DarkTooltipWithBorder(props) {
  const classes = useStyles();
  return (
    <Tooltip classes={classes} {...props}>
      {props.children}
    </Tooltip>
  );
}

export default DarkTooltipWithBorder;
