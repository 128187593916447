import { useReducer } from "react";
import {
  addOpportunity,
  getFeedOpportunityApi,
  getMyOpportunityApi,
  getMyOpportunityByQuery,
  getOpportunity,
  getSelectedBeatsByQuery,
  pauseOpportunityApi,
  removeOpportunity,
  updateOpportunityById,
  toggleFeaturedStatus,
} from "../apis/opportunity";
import { toast } from "react-toastify";

const initialArgs = {
  data: {
    title: "",
    description: "",
    lookingFor: "",
    artist: {
      name: "",
      status: "",
    },
    monthlyListeners: "",
    expireDate: "",
    genre: "",
    bpm: "",
    key: "",
    scale: "",
    fileFormat: "",
    hasTerms: false,
    terms: {
      advance: "",
      publishing: "",
      points: "",
    },
    hasSpotifyLink: false,
    spotifyLink: null,
    coverPhoto: null,
    hasReferenceTrack: false,
    referenceTrack: null,
    userId: "",
  },
};

function reducer(state, action) {
  return { ...state, ...action };
}

const useOpportunity = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const resetOpportunity = async () => {
    setState({
      ...state,
      data: initialArgs.data,
    });
  };

  const saveOpportunity = async (payload) => {
    const data = await addOpportunity(payload);
    if (data?.status === 200) {
      toast.success("successfully created");
      resetOpportunity();
    } else {
      toast.error(
        data?.response?.data?.msg || "something went wrong please try again"
      );
    }
  };

  const updateOpportunity = async (id, payload) => {
    try {
      const data = await updateOpportunityById(id, payload);
      resetOpportunity();
      return data.msg;
    } catch (err) {
      toast.error("Error");
    }
  };

  const fetchOpportunityById = async (id) => {
    try {
      const { data } = await getOpportunity(id);
      console.info("fetchOpportunityById data => ", data);

      setState({ ...state, data });
      return data;
    } catch (err) {
      toast.error("error while fetching opportunity");
      return err;
    }
  };

  const deleteOpportunityById = async (id) => {
    try {
      const { data } = await removeOpportunity(id);
      console.info("deleteOpportunityById data => ", data);

      setState({ ...state, data });
      return data;
    } catch (err) {
      toast.error("error while deleting opportunity");
      return err;
    }
  };

  const updateOpportunityState = async (key, value) => {
    try {
      console.info("key, value => ", key, value);

      const _state = { ...state };
      _state.data[key] = value;
      setState(_state);
    } catch (error) {
      console.info(
        "@updateOpportunityState:  Error while updating state => ",
        error
      );
    }
  };

  const pauseOpportunity = async (id) => {
    try {
      const data = await pauseOpportunityApi(id);
      if (data) {
        toast.success("Opportunity pause successfully.");
      }
    } catch (error) {
      console.log("error while pause the opportunity = ", error);
    }
  };

  const fetchFeedOpportunity = async (queryString) => {
    try {
      const data = await getFeedOpportunityApi(queryString);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while pause the opportunity = ", error);
    }
  };

  const fetchMyOpportunity = async (id) => {
    try {
      const data = await getMyOpportunityApi(id);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while pause the opportunity = ", error);
    }
  };

  const fetchMyOpportunityByQuery = async (queryString) => {
    try {
      const data = await getMyOpportunityByQuery(queryString);

      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while fetching my opportunity = ", error);
    }
  };

  const fetchSelectedBeatByQuery = async (queryString) => {
    try {
      const data = await getSelectedBeatsByQuery(queryString);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while fetching selected beats = ", error);
    }
  };

  const toggleOpportunityFeaturedStatus = async (id) => {
    try {
      const data = await toggleFeaturedStatus(id);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while toggling featured status = ", error);
    }
  };

  return {
    ...state,
    setState,
    saveOpportunity,
    updateOpportunity,
    fetchOpportunityById,
    deleteOpportunityById,
    updateOpportunityState,
    resetOpportunity,
    pauseOpportunity,
    fetchFeedOpportunity,
    fetchMyOpportunity,
    fetchMyOpportunityByQuery,
    fetchSelectedBeatByQuery,
    toggleOpportunityFeaturedStatus,
  };
};

export default useOpportunity;
