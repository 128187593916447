import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import Opportunities from "../../components/OpportunitiesComponents/Opportunities/Opportunities";
import Sync from "../../components/OpportunitiesComponents/Sync/Sync";

const SyncPage = () => {
  return (
    <Grid>
      <Sidebar childComponent={<Sync />} activeText="Sync Placements" />
    </Grid>
  );
};

export default SyncPage;
