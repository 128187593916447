import React from "react";

const Dot = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3"
      height="3"
      viewBox="0 0 3 3"
      fill="none"
    >
      <circle cx="1.5" cy="1.5" r="1.5" fill="#AFAFAF" />
    </svg>
  );
};

export default Dot;
