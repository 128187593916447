import React from "react";

const Circle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
    >
      <path
        d="M5.69567 15.9184C5.67976 15.9025 5.64794 15.8919 5.63203 15.8759C5.11497 14.3306 5.11497 12.6589 5.63203 11.1136C5.64794 11.0977 5.67976 11.0871 5.69567 11.0712C6.15762 11.9089 6.66943 12.7183 7.22832 13.4948C6.67178 14.2728 6.16006 15.082 5.69567 15.9184Z"
        fill="#AFAFAF"
      />
      <path
        d="M15.1094 20.6005C13.5197 21.1277 11.8 21.1128 10.2197 20.5581C11.092 20.095 11.9334 19.5759 12.7388 19.0042C13.5131 19.5621 14.3207 20.0721 15.1571 20.5316C15.1306 20.5475 15.1253 20.5846 15.1094 20.6005Z"
        fill="#AFAFAF"
      />
      <path
        d="M19.7866 16.0072C19.3246 15.1399 18.8092 14.3021 18.2434 13.4987C18.816 12.6998 19.3317 11.8616 19.7866 10.9902C20.3594 12.6135 20.3594 14.3839 19.7866 16.0072Z"
        fill="#AFAFAF"
      />
      <path
        d="M15.1463 6.46716C14.3115 6.92847 13.5057 7.44031 12.7333 7.99981C11.9319 7.4234 11.092 6.90246 10.2195 6.44064C11.7998 5.88597 13.5195 5.87105 15.1092 6.39822C15.1251 6.41413 15.1304 6.45125 15.1463 6.46716Z"
        fill="#AFAFAF"
      />
      <path
        d="M17.4477 12.4816C16.3555 11.1137 15.1139 9.87208 13.746 8.77992C14.6031 8.17691 15.5035 7.63804 16.44 7.16771C16.4848 7.13527 16.5273 7.09983 16.5673 7.06165C17.0985 7.38051 17.5899 7.76149 18.031 8.19656C18.4643 8.63593 18.8436 9.12554 19.1606 9.65496C19.1241 9.69686 19.0905 9.74115 19.0599 9.78755C18.5887 10.7236 18.0499 11.624 17.4477 12.4816Z"
        fill="#AFAFAF"
      />
      <path
        d="M8.0079 12.4816C7.40413 11.625 6.86523 10.7245 6.3957 9.78755C6.36325 9.74278 6.32782 9.70025 6.28963 9.66027C6.60849 9.12904 6.98948 8.63768 7.42454 8.19656C7.86391 7.76324 8.35353 7.38401 8.88295 7.06695C8.92484 7.10346 8.96914 7.13712 9.01553 7.16771C9.95027 7.64123 10.8505 8.17997 11.7096 8.77992C10.3467 9.87768 9.10566 11.1187 8.0079 12.4816Z"
        fill="#AFAFAF"
      />
      <path
        d="M18.0316 18.8026C17.5905 19.2377 17.0991 19.6187 16.5679 19.9376C16.5279 19.8994 16.4854 19.8639 16.4406 19.8315C15.5063 19.3572 14.6061 18.8185 13.7465 18.2193C15.1145 17.1271 16.3561 15.8855 17.4482 14.5176C18.0512 15.3747 18.5901 16.2752 19.0604 17.2117C19.088 17.2601 19.1219 17.3047 19.1612 17.3442C18.8438 17.8734 18.4646 18.363 18.0316 18.8026Z"
        fill="#AFAFAF"
      />
      <path
        d="M11.7095 18.2193C10.8529 18.8231 9.95238 19.362 9.01544 19.8315C8.96905 19.8621 8.92475 19.8957 8.88286 19.9322C8.35344 19.6152 7.86383 19.236 7.42445 18.8026C6.98939 18.3615 6.6084 17.8702 6.28954 17.3389C6.32773 17.2989 6.36316 17.2564 6.39561 17.2117C6.86913 16.2769 7.40786 15.3767 8.00781 14.5176C8.54874 15.2052 9.12771 15.8621 9.74199 16.4851C10.3648 17.0996 11.0217 17.6785 11.7095 18.2193V18.2193Z"
        fill="#AFAFAF"
      />
      <path
        d="M12.728 17.4905C11.2353 16.3325 9.8942 14.9914 8.73623 13.4987C9.8942 12.006 11.2353 10.6649 12.728 9.50691C14.2207 10.6649 15.5619 12.006 16.7198 13.4987C15.5619 14.9914 14.2207 16.3325 12.728 17.4905Z"
        fill="#AFAFAF"
      />
    </svg>
  );
};

export default Circle;
