/* eslint-disable require-jsdoc */
import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledMenu = withStyles({
  paper: {
    background: "#1c1b1b",
    border: "1px solid #AFAFAF",
    minWidth: "12rem",
    padding: "10px",
    width: '350px'
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: props.position ?? "left",
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: "#AFAFAF",
    borderBottom: "1px solid #AFAFAF",
    fontFamily: "Poppins !important",
    fontSize: "18px !important",
    "&.active": {
      backgroundColor: "#2EBB55",
      color: "#FFF",
    },
    "&:hover": {
      backgroundColor: "#2EBB55",
      color: "#FFF",
    },
  },
}))(MenuItem);
