import React, { memo } from "react";
import { Box, Button, IconButton, Modal } from "@mui/material";
import { Modelstyle, ModelContentBox } from "../../../socialintegrate/style";
import {
  close_icon,
  green_clock,
  submitsuccessfully_icon,
} from "../../../../../assets/smartlink";
import { WelcomeMain } from "../styles/MainStyles";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";

const LoginAlertPopup = ({
  Alertopen = false,
  AlertopenCallBack = () => {},
  commingFrom = "",
}) => {
  const navigate = useNavigate();

  const CreateModelClose = () => {
    if (commingFrom === "signup") {
      navigate("/producer/auth/login");
    } else {
      AlertopenCallBack(!Alertopen);
    }
  };

  const redirectToInstagram = () => {
    window.location.href = "https://www.instagram.com/unitedmarket/?utm_medium";
  };

  return (
    <>
      <Modal
        open={Alertopen}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.50)",
          },
        }}
      >
        <ModelContentBox
          sx={{
            ...Modelstyle,
            width: { xs: "100%", sm: "650px" },
            padding: "2rem",
            borderRadius: "20px",
            background:
              "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
            backdropFilter: "blur(200px)",
          }}
          className="ModelContentBox"
        >
          <Box
            className="popup_header"
            sx={{ justifyContent: "end !important" }}
          >
            {/* <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "2rem",
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton> */}
            <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "2rem",
              }}
            >
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>

          <WelcomeMain>
            <Box
              sx={{
                marginTop: "25px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <img
                src={
                  commingFrom === "signup"
                    ? submitsuccessfully_icon
                    : green_clock
                }
                alt="green_clock"
              />
            </Box>
            {/* <h6>Sign In to Your Account</h6> */}
            <p style={{ color: "#fff" }}>
              {commingFrom === "signup"
                ? "Thanks for creating your account you will receive an email when your account is ready."
                : "Your account is not ready yet, you will received an email when it is ready."}
            </p>

            {commingFrom === "login" && (
              <Box
                sx={{
                  marginTop: "40px",
                  marginBottom: "25px",
                  textAlign: "center",
                }}
              >
                <Button
                  sx={{
                    borderRadius: "5px",
                    border: "1px solid #FFF",
                    background: "transparent !important",
                    gap: "10px",
                    width: "50% !important",
                    margin: "0 auto !important",
                    justifyContent: "center",
                    alignItems: "center",
                    span: {
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    },
                  }}
                  onClick={redirectToInstagram}
                >
                  <InstagramIcon /> <span>Follow us on Instagram</span>
                </Button>
              </Box>
            )}
          </WelcomeMain>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(LoginAlertPopup);
