import React from "react";
import ProducersLayouts from "./ProducersLayouts";
import SignUpPopup from "../../../../components/smartlink/producers/auths/SignUpPopup";
import { ToastContainer } from "react-toastify";

const ProducersSignUpPage = () => {
  return (
    <>
      <ProducersLayouts>
        <SignUpPopup />
      </ProducersLayouts>
    </>
  );
};

export default ProducersSignUpPage;
