import React, { memo } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modelstyle, ModelContentBox } from "../../../socialintegrate/style";
import { Box, Button, IconButton, Modal, useTheme } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import {
  WelcomeMain,
  FormMain,
  SignUpFormMain,
  TextAreaMainBox,
} from "../../../../smartlink/producers/auths/styles/MainStyles";
// import TextAreaField from "../../../common/inputfield/TextAreaField";
import { close_icon } from "../../../../../assets/smartlink";
import { useMutation } from "@apollo/client";
import { CONTACT_US } from "../../../../../Graphql_services/beatinbox/Beats";
import { Notification } from "../../../../NotifyMsg/Notification";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  message: Yup.string().required("Message is required"),
});

const ContactUsPopup = ({ opencontactus, setopencontactus }) => {
  const [contactUsMutation, { loading, error }] = useMutation(CONTACT_US, {
    context: { clientName: "beats" },
  });

  const theme = useTheme();
  const handleContactusClose = (value, reason) => {
    if (reason && reason == "backdropClick") return;
    setopencontactus(!opencontactus);
  };
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  };
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { data } = await contactUsMutation({
        variables: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber.toString(),
          text: values.message,
        },
      });

      if (data.contactUs?.success) {
        Notification("success", data.contactUs?.message);
        setopencontactus(false);
      } else {
        Notification("error", data.contactUs?.message);
      }
    } catch (error) {
      console.error(error);
    }

    resetForm();
  };

  return (
    <>
      <Modal
        open={opencontactus}
        onClose={handleContactusClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="ModelContentBox"
          sx={{
            ...Modelstyle,
            width: { xs: "98%", sm: "794px" },
            padding: "40px 90px 70px 90px",
            borderRadius: "20px",
            background: "#141515",
            height: "auto",
            maxHeight: "80vh",
            [theme.breakpoints.down("sm")]: {
              padding: "40px 20px",
            },
          }}
        >
          <Box className="popup_header">
            <IconButton
              onClick={handleContactusClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "24px",
                padding: "0",
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <IconButton
              onClick={handleContactusClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "30px",
                padding: "0",
              }}
            >
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>

          <WelcomeMain>
            <h6>Contact us</h6>
            <p className="contact-us-desc">
              We'd love to hear from you. Please provide the following
              information so we can assist you better
            </p>
          </WelcomeMain>
          <FormMain className="signUpMain">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <SignUpFormMain>
                    <div
                      className={`form__field ${
                        errors.firstName && touched.firstName ? "error" : ""
                      }`}
                    >
                      <label htmlFor="firstName">First Name:</label>
                      <Field
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="Enter your first name"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div
                      className={`form__field ${
                        errors.lastName && touched.lastName ? "error" : ""
                      }`}
                    >
                      <label htmlFor="lastName">Last Name:</label>
                      <Field
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Enter your last name"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </div>
                  </SignUpFormMain>
                  <SignUpFormMain>
                    <div
                      className={`form__field ${
                        errors.email && touched.email ? "error" : ""
                      }`}
                    >
                      <label htmlFor="email">Email:</label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div
                      className={`form__field ${
                        errors.phoneNumber && touched.phoneNumber ? "error" : ""
                      }`}
                    >
                      <label htmlFor="phoneNumber">Phone Number:</label>
                      <Field
                        type="number"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Enter your phone number"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error"
                      />
                    </div>
                  </SignUpFormMain>
                  <TextAreaMainBox
                    className={`form__field ${
                      errors.message && touched.message
                        ? "contact_us_error"
                        : ""
                    }`}
                  >
                    <h6>
                      Describe how your organization would like to work with
                      United Market?
                    </h6>
                    <Field
                      as="textarea"
                      placeholder="Enter message here"
                      name="message"
                      id="message"
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="error"
                    />
                  </TextAreaMainBox>
                  <Button
                    type="submit"
                    sx={{
                      marginTop: "22px !important",
                    }}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Send Message"}
                  </Button>
                </Form>
              )}
            </Formik>
          </FormMain>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(ContactUsPopup);
