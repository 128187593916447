import React, { useState, useEffect } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { ModelContentBox, Modelstyle, MultiButton } from "./style";
import { MultiCrown } from "../../../../assets/smartlink";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const MultiModal = ({
  open ,
  heading ,
  text ,
  icon ,
  type ,
  title,
  RedirectTo,
  setPaymentOpen,
}) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const handleModalClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setModalOpen(false);
    setPaymentOpen(false);
  };

  const HandelClick = () => {
    if (type === "link") {
      console.log("RedirectTo",RedirectTo)
      navigate(RedirectTo);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <ModelContentBox
        sx={{
          ...Modelstyle,
          width: { xs: "100%", sm: "675px" },
          padding: "80px",
          borderRadius: "0.625rem",
          background: "#141515",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "40px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
            position: "absolute",
            top: "15px",
            right: "5%",
          }}
        >
          <IconButton
            onClick={handleModalClose}
            aria-label="settings"
            sx={{
              color: "#fff",
              //   color: theme.palette.primary.dark,
              backgroundColor: "none",
              fontSize: "2rem",
              padding: "0 !important",
              margin: "0 !important",
              marginTop: "7px !important",
              svg: {
                color: "#fff",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="ImgBox">
          <img
            style={{ height: "64px", width: "64px" }}
            src={icon}
            alt="img.svg"
          />
        </Box>
        <Box className="ContentBox">
          <h1 className="MultiHead">{heading}</h1>
          <p className="MultiPara">{text}</p>
        </Box>
        <MultiButton onClick={HandelClick}>{title}</MultiButton>
      </ModelContentBox>
    </Modal>
  );
};

export default MultiModal;
