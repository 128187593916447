import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Seeonsection = styled(Box)(({ theme }) => ({
  //   border: "1px solid white",
  width: "100%",
  background: "#1C1B1B",
  padding: "50px 0 41px 0",
  color: "red",

  "& .MainBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "91.5%",
    margin: "auto",
    // border: "1px solid white",
  },
}));

export { Seeonsection };
