import React, { useState } from "react";
import { ReleaseBoxWrapper } from "../../../style";
import { DeleteButtonWrapper } from "../duplicateForm/style";
import DeleteIcon from "../../../../../../assets/smartlink/icons/DeleteIcon";
import GhostButton from "../../../commons/GhostButton";
import { DELETE_SMART_LINK } from "../../../../../../Graphql_services/smartlink/SmartLink";
import { useMutation } from "@apollo/client";
import { Notification } from "../../../../../NotifyMsg/Notification";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../../common/loader/LoadingSpinner";

const DeleteLinkForm = ({ id }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deleteSmartLink] = useMutation(DELETE_SMART_LINK);
  const handleDelete = async () => {
    try {
      setLoading(true);
      const result = await deleteSmartLink({
        variables: {
          linkId: id,
        },
        context: { clientName: "smartlink" },
      });

      console.log(result.data.deleteSmartLink.message, "Result");
      if (result.data.deleteSmartLink.message.success === false) {
        Notification("error", result.data.deleteSmartLink.message);
      } else {
        Notification("success", result.data.deleteSmartLink.message);
        navigate("/smart-link/created-link");
      }
    } catch (error) {
      console.error("Mutation Error:", error);
      Notification("error", error.message);
    }
  };
  return (
    <ReleaseBoxWrapper>
      <h5>Delete Link</h5>
      <p>This action is irreversible</p>
      <DeleteButtonWrapper onClick={handleDelete}>
        <GhostButton>
          {!loading ? (
            <>
              <DeleteIcon /> delete link
            </>
          ) : (
            <LoadingSpinner size={25} />
          )}
        </GhostButton>
      </DeleteButtonWrapper>
    </ReleaseBoxWrapper>
  );
};

export default DeleteLinkForm;
