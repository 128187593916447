import React from "react";
import { useState } from "react";
import styles from "./ai_songwriter.module.css";
import { DynamicFormField } from "../UniversalComponents/DynamicFormField";

import axios from "axios";

const apiKey = "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";

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  },
};

const generateSong = async (prompt) => {
  const payload = {
    model: "gpt-3.5-turbo",
    messages: [
      {
        role: "system",
        content: prompt,
      },
    ],
  };

  try {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      payload,
      config
    );
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error(`Error: ${error}`);
    return null;
  }
};

function CreateSong() {
  const [song, setSong] = useState("");
  const [loading, setLoading] = useState(false); // Introducing loading state

  const [songTitle, setSongTitle] = useState("");
  const [songDescription, setSongDescription] = useState("");
  const [toneVibe, setToneVibe] = useState([]);
  const [genre, setGenre] = useState([]);
  const [wordLimit, setWordLimit] = useState("");

  const canGenerate =
    songTitle && songDescription && toneVibe && genre && wordLimit;

  const prompt = `You are the world's best songwriter. Write an 
  amazing song to the following specifications and details. The name
  of the song is ${songTitle}. A description of the song is as 
  follows, ${songDescription}. The tones and vibes of the song 
  are ${toneVibe}, and the genre's of the song are ${genre}`;

  const handleGenerateSong = async () => {
    setLoading(true); // Setting loading to true before API call
    const newSong = await generateSong(prompt);
    setSong(newSong);
    setLoading(false); // Setting loading to false after API call
  };

  function handleExit() {}

  return (
    <div className={styles.main_layout_create}>
      <div className={styles.generator_form}>
        <div>
          <DynamicFormField
            title="Song Title"
            dropdown={false}
            content={songTitle}
            onValueChange={(value) => setSongTitle(value)}
          />
          <DynamicFormField
            title="Song Description"
            dropdown={false}
            content={songDescription}
            paragraph={true}
            onValueChange={(value) => setSongDescription(value)}
          />
          <DynamicFormField
            title="Tone / Vibe"
            dropdown={true}
            content={toneVibe.join(", ")} // display selected values as a comma-separated string
            dropDownData={[
              "Dark",
              "Happy",
              "Epic",
              "Energy",
              "Virtual",
              "Atmospheric",
              "Pain",
              "Emotional",
              "Smooth",
              // "Custom",
            ]}
            onValueChange={(value) => {
              if (toneVibe.length < 3 && !toneVibe.includes(value)) {
                setToneVibe((prev) => [...prev, value]);
              }
            }}
          />
          <DynamicFormField
            title="Genre"
            dropdown={true}
            content={genre.join(", ")} // display selected values as a comma-separated string
            dropDownData={[
              "Rap / Hip-Hop",
              "R&B",
              "Alternative",
              "Indie",
              "Country",
              "Pop",
              // "Other",
            ]}
            onValueChange={(value) => {
              if (genre.length < 3 && !genre.includes(value)) {
                setGenre((prev) => [...prev, value]);
              }
            }}
          />

          <DynamicFormField
            title="Word Limit"
            dropdown={false}
            content={wordLimit}
            onValueChange={(value) => setWordLimit(value)}
          />
        </div>
        <div className={styles.action_controls}>
          <div className={styles.exit_button}>Exit</div>
          {canGenerate ? (
            <div
              className={styles.generate_button}
              onClick={handleGenerateSong}
            >
              {loading ? (
                <svg viewBox="25 25 50 50" className={styles.auth_loader}>
                  <circle r="20" cy="50" cx="50"></circle>
                </svg>
              ) : (
                <p>Generate</p>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className={styles.content_box}>
        {song.split("\n\n").map((verse, index) => (
          <p className={styles.song_text} key={index}>
            {verse}
          </p>
        ))}
      </div>
    </div>
  );
}

export default CreateSong;
