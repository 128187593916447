import React from "react";

const CopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M11.625 9.86345H9.99748C9.67932 9.86355 9.36427 9.80096 9.07031 9.67925C8.77635 9.55755 8.50926 9.37911 8.28429 9.15414C8.05932 8.92917 7.88089 8.66208 7.75918 8.36812C7.63747 8.07417 7.57488 7.75911 7.57498 7.44096V5.81346C7.57508 5.73956 7.5606 5.66637 7.53236 5.59808C7.50413 5.52979 7.4627 5.46774 7.41045 5.41549C7.35819 5.36323 7.29614 5.32181 7.22785 5.29357C7.15956 5.26534 7.08637 5.25086 7.01248 5.25096H4.63498C4.21702 5.22701 3.79871 5.2917 3.40751 5.44078C3.01631 5.58986 2.66104 5.81996 2.36501 6.11599C2.06898 6.41201 1.83888 6.76728 1.6898 7.15849C1.54072 7.54969 1.47604 7.96799 1.49998 8.38595V13.366C1.47604 13.7839 1.54072 14.2022 1.6898 14.5934C1.83888 14.9846 2.06898 15.3399 2.36501 15.6359C2.66104 15.932 3.01631 16.1621 3.40751 16.3111C3.79871 16.4602 4.21702 16.5249 4.63498 16.501H9.05248C9.47044 16.5249 9.88874 16.4602 10.2799 16.3111C10.6712 16.1621 11.0264 15.932 11.3224 15.6359C11.6185 15.3399 11.8486 14.9846 11.9977 14.5934C12.1467 14.2022 12.2114 13.7839 12.1875 13.366V10.426C12.1876 10.3521 12.1731 10.2789 12.1449 10.2106C12.1166 10.1423 12.0752 10.0802 12.0229 10.028C11.9707 9.97573 11.9086 9.93431 11.8404 9.90607C11.7721 9.87784 11.6989 9.86336 11.625 9.86345Z"
        fill="white"
      />
      <path
        d="M13.3648 1.499H8.94731C8.54069 1.47582 8.13366 1.53681 7.75169 1.67817C7.36972 1.81952 7.02105 2.03819 6.72748 2.3205C6.43391 2.6028 6.20177 2.94266 6.04559 3.31881C5.8894 3.69495 5.81254 4.09929 5.81981 4.5065C5.86481 4.5065 5.90231 4.499 5.94731 4.499H10.3648C10.7828 4.47506 11.2011 4.53975 11.5923 4.68883C11.9835 4.83791 12.3387 5.06801 12.6348 5.36403C12.9308 5.66006 13.1609 6.01533 13.31 6.40653C13.4591 6.79774 13.5237 7.21604 13.4998 7.634V12.6215C13.4998 12.6665 13.4923 12.704 13.4923 12.7415C13.8993 12.7503 14.3037 12.6747 14.68 12.5195C15.0563 12.3643 15.3965 12.1329 15.679 11.8398C15.9615 11.5467 16.1803 11.1983 16.3215 10.8166C16.4628 10.4348 16.5235 10.0279 16.4998 9.6215V4.634C16.5237 4.21604 16.4591 3.79774 16.31 3.40653C16.1609 3.01533 15.9308 2.66006 15.6348 2.36403C15.3387 2.06801 14.9835 1.83791 14.5923 1.68883C14.2011 1.53975 13.7828 1.47506 13.3648 1.499Z"
        fill="white"
      />
      <path
        d="M8.98498 5.36344C8.93462 5.31148 8.87001 5.27558 8.79929 5.26027C8.72857 5.24495 8.65489 5.25089 8.58755 5.27735C8.5202 5.30381 8.46218 5.3496 8.4208 5.40896C8.37941 5.46832 8.35651 5.53859 8.35498 5.61094V7.57744C8.36306 7.98186 8.53089 8.36664 8.82179 8.6477C9.1127 8.92877 9.50302 9.08326 9.90748 9.07744C10.44 9.08494 11.1825 9.08494 11.82 9.08494C11.8905 9.08728 11.96 9.06842 12.0197 9.0308C12.0794 8.99318 12.1264 8.93853 12.1546 8.87392C12.1829 8.8093 12.1911 8.7377 12.1783 8.66835C12.1654 8.59901 12.132 8.53512 12.0825 8.48494C11.265 7.66594 9.80998 6.20344 8.98498 5.36344Z"
        fill="white"
      />
    </svg>
  );
};

export default CopyIcon;
