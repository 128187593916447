import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "& .card-header-box": {
    background: "none",
    "& h3": {
      color: "#E3E3E3",
      fontFamily: theme.typography.fontFamily,
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
  },
  "& .card-display-main": {
    width: "100%",
    padding: "30px",
    display: "flex",
    justifyContent: "center",
    "& .card-box": {
      textAlign: "right",
      borderRadius: "18px",
      height: "200px",
      background: "#2EBB55",
      overflow: "hidden",
      width: "312px",
      position: "relative",
      "& .wifi-box": {
        position: "absolute",
        top: "24px",
        left: "30px",
        right: "24px",
        width: "250px",
      },
      "& .card-number": {
        textAlign: "center",
        color: "#FFF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "19px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0.95px",
        marginTop: "15px",
        marginBottom: "10px",
      },
      "& h4": {
        textAlign: "left",
        color: "#FFF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        letterSpacing: "0.48px",
        textTransform: "capitalize",
      },

      "& .bottom-box": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "60px",
        background: "#EBF4B3",
        padding: "0px 30px",
        "& .expiry": {
          color: "#111",
          fontFamily: theme.typography.fontFamily,
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          letterSpacing: "0.48px",
          textTransform: "capitalize",
        },
        "& span": {
          color: "#111",
          fontFamily: theme.typography.fontFamily,
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
          letterSpacing: "0.48px",
          marginLeft: "3px",
        },
      },
    },
  },
  "& .form-main": {
    textAlign: "left",
    padding: "0px 30px",
    "& h6": {
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
    "& .card-button-box": {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
      borderRadius: "5px",
      background: "#414241",
      marginBottom: "24px",
      "& .card-btn": {
        width: "100%",
        height: "48px",
        borderRadius: "0px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      },
      "& .first": {
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
      },
      "& .middle": {
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
      },
      "& .third": {
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
      },
      "& .selected": {
        background: "#2EBB55",
      },
    },

    "& .inputs-main": {
      display: "flex",
      flexDirection: "column",
      "& h3": {
        color: "#E3E3E3",
        fontFamily: theme.typography.fontFamily,
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginBottom: "12px",
        textTransform: "capitalize",
      },
      "& label": {
        color: "#AFAFAF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginBottom: "5px",
      },
      "& .name-input": {
        height: "40px",
        padding: "0px 10px",
        marginBottom: "10px",
        border: "none",
        borderRadius: "5px",
        background: "#414241",
        color: "#FFF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        outline: "none",
      },
      [theme.breakpoints.down("sm")]: {
        "& .cvc-input": {
          width: "170px",
        },
      },
      "& .number-input": {
        display: "block",
        textAlign: "center",
        height: "40px",
        padding: "12px 0px 0px 10px",
        background: "#414241",
        color: "#fff",
        fontSize: "12px",
        borderRadius: "5px",
        marginBottom: "10px",
      },
      "& .expiry-cvc-box": {
        width: "100%",
        display: "flex",
        gap: "12px",
        marginBottom: "24px",

        "& .expiry-input": {
          height: "40px",
          background: "#414241",
          width: "214px",
          fontSize: "12px",
          padding: "12px 0px 0px 10px",
          borderRadius: "5px",
          marginTop: "5px",
        },
        "& .cvc-box": {
          width: "100%",
        },

        "& .cvc-input": {
          height: "40px",
          background: "#414241",
          width: "100%",
          padding: "12px 0px 0px 10px",
          borderRadius: "5px",
          marginTop: "5px",
          fontSize: "12px",
          color: "#fff",
        },
      },
    },
    "& .pay-btn": {
      height: "56px",
      background: "#2EBB55",
      borderRadius: "5px",
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
  },
}));

export { Main };
