import React, { useEffect, useState } from "react";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MainDatePickerBox } from "./style";

const DatePickers = ({ icon: Icon, setSelectedDate, maxDate }) => {
  const [cleared, setCleared] = React.useState(false);

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  // ********** Handles the change of date in the date picker. **********
  const handleDateChange = (date) => {
    if (date) {
      try {
        const currentDate = new Date(date);

        currentDate.setDate(currentDate.getDate() + 1);

        const formattedDate = currentDate.toISOString();

        setSelectedDate(formattedDate);
      } catch (error) {
        console.error("Error occurred while parsing date:", error);
      }
    } else {
      console.error("Invalid date provided:", date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MainDatePickerBox>
        <DemoItem>
          <DatePicker
            className="DatePicker"
            onChange={handleDateChange}
            sx={{
              width: "auto",
              "& .MuiStack-root": {
                width: "100%",
              },
              "& .MuiOutlinedInput-root": {
                background: "#1C1B1B",
                color: "#fff",
                width: "100%",

                "&.Mui-focused": {
                  borderColor: "red",
                  // background: theme.palette.primary.main,
                  border: "1px solid #414241",
                },
                "&:hover fieldset": {
                  borderColor: "#1C1B1B",
                  border: "1px solid #414241",
                },
              },
              "& .MuiInputBase-input": {
                // color: theme.palette.secondary.dark,
                background: "#1C1B1B",
              },
              "& .MuiInputLabel-root": {
                // color: theme.palette.primary.dark,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#414241",
              },
              "& .MuiIconButton-root": {
                color: "#AFAFAF",
                fontSize: "30px",
                background: "none",
              },
            }}
            slotProps={{
              field: { clearable: true, onClear: () => setCleared(true) },
              openPickerButton: {},
              inputAdornment: {
                position: "start",
              },
            }}
            maxDate={maxDate}
          />
        </DemoItem>
      </MainDatePickerBox>
    </LocalizationProvider>
  );
};

export default DatePickers;
