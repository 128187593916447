import { Box, Button } from "@mui/material";

import { styled } from "@mui/material/styles";

export const CollaborationsIconDiv = styled(Box)(({ theme }) => ({
  width: "1.875rem",

  height: "1.875rem",

  display: "flex",

  alignItems: "center",

  justifyContent: "center",

  flexShrink: 0,

  borderRadius: "0.1875rem",

  position: "relative",

  userSelect: "none",

  transition: "ease 0.3s",
}));

export const CollaborationsPopover = styled(Box)(({ theme }) => ({
  padding: "1.2rem",

  width: "12rem",

  borderRadius: "0.3125rem",

  background: "#141515",

  zIndex: 5,

  position: "absolute",

  top: "2.5rem",

  right: 0,

  transition: "ease 0.5s",

  "& .collaborator": {
    borderBottom: "2px solid #AFAFAF",

    paddingBottom: "0.25rem",

    "& h5": {
      color: "#FFF !important",
      fontFamily: theme.typography.fontFamily,
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },

    "& h6": {
      color: "#AFAFAF !important",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.h6,
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      margin: "0.25rem 0.12rem",
    },
  },

  "& .tone-box": {
    marginTop: "0.75rem",
    borderBottom: "2px solid #AFAFAF",
    paddingBottom: "0.25rem",
    "& h5": {
      color: "#FFF !important",
      fontFamily: theme.typography.fontFamily,
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    "& h6": {
      color: "#AFAFAF !important",
      fontFamily: theme.typography.fontFamily,
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      margin: "0.25rem 0.12rem",
    },
  },

  "& .master-box": {
    marginTop: "0.75rem",
    paddingBottom: "0.25rem",
    "& h5": {
      color: "#FFF !important",
      fontFamily: theme.typography.fontFamily,
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    "& h6": {
      color: "#AFAFAF !important",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.h6,
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      margin: "0.25rem 0.12rem",
    },
  },
}));
