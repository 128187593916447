import React, { memo, useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modelstyle, ModelContentBox } from "../../socialintegrate/style";
import { Box, Button, IconButton, Modal } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { close_icon } from "../../../../assets/smartlink";
import {
  WelcomeMain,
  FormMain,
  SignUpFormMain,
  UserTypeMain,
} from "./styles/MainStyles";
import { GetRoles } from "../../../../Graphql_services/SignUpMutation";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const UserTypePopup = ({ open = false, setOpen = () => {} }) => {
  const navigate = useNavigate();
  const [roledata, setroledata] = useState([]);
  const { loading, data } = useQuery(GetRoles);
  const [usertype, setUserType] = useState(null);
  const [rolleId, setroleid] = useState(null);
  const CreateModelClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(!open);
  };

  const SelectUserType = (value, id) => {
    setUserType(value);
    setroleid(id);
  };

  const SignUphandler = () => {
    navigate(`/producer/auth/signup/${rolleId}/${usertype}`);
  };

  useEffect(() => {
    const roles = data?.getRoles?.filter(
      (value) =>
        value.roleName === "Music Producer" || value.roleName === "Artist"
    );

    setroledata(roles || []);
  }, [data]);

  return (
    <>
      {/* <Modal
        open={true}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.50)",
          },
        }}
      > */}
      <ModelContentBox
        sx={{
          ...Modelstyle,
          width: { xs: "100%", sm: "500px" },
          padding: "2rem",
          borderRadius: "20px",
          background:
            "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
          backdropFilter: "blur(200px)",
        }}
        className="ModelContentBox"
      >
        <Box className="popup_header">
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            aria-label="settings"
            sx={{
              color: "#AFAFAF",
              backgroundColor: "none",
              fontSize: "2rem",
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <IconButton
            onClick={CreateModelClose}
            aria-label="settings"
            sx={{
              color: "#AFAFAF",
              backgroundColor: "none",
              fontSize: "2rem",
            }}
          >
            <img src={close_icon} alt="cancel-icon" />
          </IconButton>
        </Box>

        <WelcomeMain>
          <h6>Join Our Community</h6>
          <p style={{ textAlign: "left", color: "#fff" }}>
            Choose your user type:
          </p>
        </WelcomeMain>

        <UserTypeMain>
          <Box className="type_main">
            {!loading && roledata ? (
              roledata
                .slice()
                .sort((a, b) => a.roleName.localeCompare(b.roleName))
                .map((Role, index) => (
                  <Box
                    key={index}
                    className={`left type_items ${
                      usertype === Role.roleName ? "selected" : ""
                    }`}
                    onClick={() => SelectUserType(Role.roleName, Role._id)}
                  >
                    <span></span>
                    {Role.roleName}
                  </Box>
                ))
            ) : (
              <p style={{ margin: "0" }}>Loading...</p>
            )}
          </Box>

          <Button
            className="continue_main"
            disabled={!usertype}
            onClick={SignUphandler}
          >
            Continue
          </Button>

          <Box className="othre__acount">
            <Button
              sx={{
                color: "#fff !imortant",
                marginTop: "20px !imortant",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </Box>
        </UserTypeMain>
      </ModelContentBox>
      {/* </Modal> */}
    </>
  );
};

export default memo(UserTypePopup);
