// import React, { useState, useEffect, useRef } from "react";
// import DashboardArtistSpotlight from "../DashboardComponents/dashboard_artist_spotlight";
// import styles from "./settings.module.css";
// import AccountSettings from "./settings_tabs/accountSettings";
// import PaymentSettings from "./settings_tabs/paymentSettings";
// import NotificationSettings from "./settings_tabs/notificationSettings";
// import useAuth from "../../context/AuthContext";

// export default function SettingsComponent() {
//   const [activeTab, setActiveTab] = useState("Account");
//   const { user } = useAuth();

//   const tabs = ["Account"];

//   if (user.roleId.roleName === "Music Producer") {
//     tabs.push("Payment");
//   }

//   const tabsRef = useRef(null);
//   const underlineRef = useRef(null);

//   useEffect(() => {
//     const tabEl = [...tabsRef.current.children].find(
//       (child) => child.textContent === activeTab
//     );
//     const { offsetLeft, offsetWidth } = tabEl;
//     underlineRef.current.style.left = `${offsetLeft}px`;
//     underlineRef.current.style.width = `${offsetWidth}px`;
//   }, [activeTab]);

//   return (
//     <div className={styles.accountContainer}>
//       <p className={styles.settingsTitle}>Account Settings</p>
//       <div className={styles.tabs} ref={tabsRef}>
//         {tabs.map((tab) => (
//           <div
//             key={tab}
//             className={`${styles.tab} ${activeTab === tab && styles.active}`}
//             onClick={() => setActiveTab(tab)}
//           >
//             {tab}
//           </div>
//         ))}
//         <div className={styles.underline} ref={underlineRef} />
//       </div>
//       <div className={styles.tab_layout}>
//         {activeTab === "Account" && <AccountSettings />}
//         {user.roleId.roleName === "Music Producer" &&
//           activeTab === "Payment" && <PaymentSettings />}
//         {activeTab === "Notifications" && <NotificationSettings />}
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect, useRef } from "react";
import styles from "./settings.module.css";
import AccountSettings from "./settings_tabs/accountSettings";
import PaymentSettings from "./settings_tabs/paymentSettings";
import NotificationSettings from "./settings_tabs/notificationSettings";
import useAuth from "../../context/AuthContext";
import SubscriptionSettings from "./settings_tabs/manageSubscription";
import ManageMembership from "./settings_tabs/ManageMembership";

export default function SettingsComponent() {
  const [activeTab, setActiveTab] = useState("Account");
  const { user, loadUser } = useAuth();

  const tabs = ["Account", "Manage Subscription", "Manage Membership"];

  // if (user.roleId.roleName === "Music Producer") {
  //   tabs.push("Payment");
  // }

  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  useEffect(() => {
    const tabEl = [...tabsRef.current.children].find(
      (child) => child.textContent === activeTab
    );
    const { offsetLeft, offsetWidth } = tabEl;
    underlineRef.current.style.left = `${offsetLeft}px`;
    underlineRef.current.style.width = `${offsetWidth}px`;
  }, [activeTab]);

  return (
    <div className={styles.accountContainer}>
      <p className={styles.settingsTitle}>Account Settings</p>
      <div className={styles.tabs} ref={tabsRef}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
        <div className={styles.underline} ref={underlineRef} />
      </div>
      <div className={styles.tab_layout}>
        {activeTab === "Account" && <AccountSettings refetchUser={loadUser} />}
        {activeTab === "Manage Subscription" && (
          <SubscriptionSettings user={user} />
        )}
          {activeTab === "Manage Membership" && <ManageMembership />}
        {/* {activeTab === "Notifications" && <NotificationSettings />} */}
        {activeTab === "Notifications" && <NotificationSettings />}
      </div>
    </div>
  );
}
