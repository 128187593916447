import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const MainBeatsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: theme.palette.primary.light,
  padding: "1rem",
  borderRadius: "0.3125rem",
  zIndex: 1,
  "& .desc-box": {
    marginTop: "0.75rem",
    "& h6": {
      color: theme.palette.secondary.dark,
      fontSize: theme.typography.h6,
      fontFamily: theme.typography.fontFamily,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
  "& .single_filet_icons": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    button: {
      borderRadius: "3px",
      background: "#141515",
      width: "1.875rem",
      minWidth: "1.875rem",
      height: "1.875rem",
      padding: "0",
      margin: "0",
      "&:hover": {
        background: "rgba(79, 132, 93, 0.3)",
      },
    },
  },
}));
const HeaderInfoBox = styled(Box)(({ theme }) => ({
  "& .heading-main": {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "0.5rem",
    borderBottom: "1px solid #1C1B1B",

    "& .beats-info": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      "& .heading-box": {
        display: "flex",
        gap: "0.5rem",
        "& .MuiTypography-body1": {
          color: theme.palette.secondary.light,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.body1,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        },
      },
      "& .inbox-desc": {
        display: "flex",
        alignItems: "center",
        gap: "6px",
        "& .user-img-box": {
          width: "20px",
          height: "20px",
          overflow: "hidden",
          borderRadius: "100px",
          img: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            overflow: "hidden",
          },
        },
        "& span": {
          fontFamily: theme.typography.fontFamily,
          color: "#2EBB55",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          textDecorationLine: "underline",
          textTransform: "capitalize",
        },
        img: {
          width: "12px",
          height: "12px",
          cursor: "pointer",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  "& .remaining-main": {
    display: "flex",
    alignItems: "center",
    marginTop: "0.75rem",
    gap: "1rem",
    "& .left , & .right": {
      display: "flex",
      alignItems: "center",
      gap: "0.3rem",
      "& .MuiTypography-body1": {
        color: theme.palette.secondary.dark,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body1,
        textTransform: "capitalize",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },

      "& span": {
        color: "#F00",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body1,
        textTransform: "capitalize",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        textAlign: "center",
      },
    },
    "& .right": {
      "& span": {
        color: theme.palette.secondary.light,
      },
    },
  },
  "& .btn-box": {
    display: "flex",
    marginTop: "0.75rem",
    gap: "1.12rem",
    "& .btn": {
      padding: "0.4375rem 1.125rem",
      gap: "0.50rem",
      textTransform: "capitalize",
      fontFamily: theme.typography.fontFamily,
      borderRadius: "5px",
      background: "#141515",
      color: "#AFAFAF",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
      "&:disabled": {
        color: "#fff",
        cursor: "not-allowed",
      },
    },
    "& .staricon": {
      fontSize: "19px",
      color: "rgba(175, 175, 175, 1)",
    },
  },
  "& .audio-main": {
    marginTop: "1.12rem",
  },
}));

// header

const HeaderMain = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "12px",
    "& .header_left": {
      width: "100%",
    },
    "& .header_right": {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  a: {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textDecoration: "none",
  },

  "& .header_right": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  "& .refresh-btn": {
    background: "#414241",
    padding: "10px 8px",
    borderRadius: "5px",
    cursor: "pointer",
    svg: {
      color: "#FFF",
    },
    "&:hover": {
      background: "rgba(46, 187, 85, 0.20)",
    },
  },
  "& .total_beat": {
    display: "flex",
    textTransform: "capitalize",
    alignItems: "center",
    gap: "10px",
    borderRadius: "5px",
    background: "#414241",
    padding: "10px 10px",
    cursor: "pointer",
    svg: {
      color: "#FFF",
    },
    "&:hover": {
      background: "rgba(46, 187, 85, 0.30);",
    },
    span: {
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
  },
  "& .add-collaborator-btn": {
    background: "#414241",
    padding: "10px 8px",
    borderRadius: "5px",
    cursor: "pointer",
    svg: {
      color: "#FFF",
    },
    "&:hover": {
      background: "rgba(46, 187, 85, 0.20)",
    },
  },
}));

const UlMain = styled("ul")(({ theme }) => ({
  padding: "0",
  margin: 0,
  listStyle: "none",
  li: {
    fontFamily: theme.typography.fontFamily,
    display: "flex",
    alignItems: "center",
    gap: "10px",
    color: "#AFAFAF",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    padding: "10px",
    cursor: "pointer",
    "&:first-child": {
      borderBottom: "1px solid #AFAFAF",
    },
    svg: {
      color: "rgba(175, 175, 175, 1)",

      fontSize: "20px",
    },
  },
  "&.havemore": {
    li: {
      borderBottom: "1px solid #AFAFAF",
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
}));

export { MainBeatsBox, HeaderInfoBox, HeaderMain, UlMain };
