import { useLazyQuery } from "@apollo/client";
import React from "react";
import useAppState from "./useAppState";
import { getUser } from "../components/Hooks/Authentication/User/query";
import axios from "axios";
import { Mixpanel } from "./mixpanel/mixPanel";

const AuthContext = React.createContext();

export const AuthProvider = (props) => {
  const [user, setUser] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [isFreemium, setFreemium] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [userToken, setUserToken] = React.useState(null);
  const [showBetaFeatures, setShowBetaFeatures] = React.useState(false);
  const [currentRoleType, setCurrentRoleType] = React.useState(
    user?.roleId?.roleName
  );

  const [userPrivileges, setUserPrivileges] = React.useState();

  const { getUserPrivileges } = useAppState("general");

  const fetchUserPrivileges = async (userId) => {
    try {
      const privileges = await getUserPrivileges(userId);
      setUserPrivileges(privileges.rolePrivilege);
    } catch (error) {
      console.error("Error fetching user privileges:", error);
    }
  };

  React.useEffect(() => {
    setCurrentRoleType(user?.roleId?.roleName);
  }, [user]);

  const [artistData, setArtistData] = React.useState(null);

  const [musoArtistData, setMusoArtistData] = React.useState({
    artistData: null,
    creditData: null,
    collabData: null,
  });
  const [authToken, setAuthToken] = React.useState(null);

  console.log(localStorage.getItem("token"));
  const [fetchUser] = useLazyQuery(getUser, {
    context: { clientName: "user" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data.getUser.user) {
        // console.log("getUser authcontext", data.getUser.user);
        setIsAuthenticated(true);
        setUser(data.getUser.user);
        fetchUserPrivileges(data.getUser.user._id);
        // Mixpanel.identify(data.getUser.user._id);

        // Mixpanel.track(`Session Start`);

        if (data?.getUser?.user?.isFreemium) {
          setFreemium(data.getUser.user.isFreemium);
          setUserToken(data?.getUser?.user?.tokens);
        }

        setLoading(false);
      } else {
        // setIsAuthenticated(false);
        setLoading(false);
      }
    },
    onError(err) {
      setLoading(false);
      // setIsAuthenticated(false);
      console.log("error ... login:", err);
    },
  });

  const loadUser = () => {
    setLoading(true);
    fetchUser();
  };

  React.useEffect(() => {
    loadUser();
    // console.log(
    //   "🚀 ~ file: AuthContext.js:68 ~ React.useEffect ~ loadUser:",
    //   loadUser
    // );
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const clientId = "1630433aaf7d4efd880ae1af2a2a7c5c";
        const clientSecret = "364e433bd9de47588cd035eab0de2dad";

        const tokenResponse = await fetch(
          "https://accounts.spotify.com/api/token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Basic " + btoa(clientId + ":" + clientSecret),
            },
            body: new URLSearchParams({
              grant_type: "client_credentials",
            }),
          }
        );

        const tokenData = await tokenResponse.json();
        setAuthToken(tokenData.access_token);
        // console.log("Access token:", tokenData.access_token);

        if (tokenResponse.ok) {
          const artistResponse = await fetch(
            `https://api.spotify.com/v1/artists/${"6fWVd57NKTalqvmjRd2t8Z"}`,
            {
              headers: {
                Authorization: `Bearer ${tokenData.access_token}`,
              },
            }
          );

          if (artistResponse.ok) {
            const artistData = await artistResponse.json();
            setArtistData({ ...artistData, topTrackData: null });

            // Fetch artist's top tracks
            const topTracksResponse = await fetch(
              `https://api.spotify.com/v1/artists/${"6fWVd57NKTalqvmjRd2t8Z"}/top-tracks?market=US`,
              {
                headers: {
                  Authorization: `Bearer ${tokenData.access_token}`,
                },
              }
            );

            if (topTracksResponse.ok) {
              const topTracksData = await topTracksResponse.json();

              const updatedArtistData = {
                ...artistData,
                topTrackData: topTracksData,
              };

              setArtistData(updatedArtistData);

              // console.log("Artist Data:", updatedArtistData);
            } else {
              throw new Error("Error fetching top tracks");
            }
          } else {
            throw new Error("Error fetching artist");
          }
        } else {
          throw new Error("Error fetching access token");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    fetchData();
  }, []);

  const apiKey = "yJ6p8a5uUq8w5JmHh4A0I7EVtj0OqI4U4uVry0Fo";
  const idKey = "id";

  //React.useEffect(() => {
    //const source = axios.CancelToken.source();
    //const idValue = "62da97dc-3bb0-4bf8-bbd0-4d6e9889ee15";

   // axios
      //.get(
       // `https://api-production.muso.ai/api/c/v3/artist/${idKey}/${idValue}`,
        //{
         // headers: {
            //"x-api-key": apiKey,
          //},
         // cancelToken: source.token,
        //}
      //)
      //.then((response) => {
        //setMusoArtistData((prevState) => ({
          //...prevState,
         // artistData: response.data,
       // }));
        // console.log(response.data);
     // })
     // .catch((error) => {
       // console.error("There was an error!", error);
     // });
    //return () => {
     /// source.cancel("Operation cancelled by the user.");
   // };
  //}, []);

  // React.useEffect(() => {
   // const idKey = "id";
    //const idValue = "62da97dc-3bb0-4bf8-bbd0-4d6e9889ee15";
    //const offset = 0; // This is the default value
    //const limit = 20; // This is the default value
    //const apiKey = "yJ6p8a5uUq8w5JmHh4A0I7EVtj0OqI4U4uVry0Fo"; // Replace with your actual API key

    //axios
     // .get(
       // `https://api-production.muso.ai/api/c/v3/artist/${idKey}/${idValue}/credits?offset=${offset}&limit=${limit}`,
        //{
         // headers: {
           // "x-api-key": apiKey,
          //},
       // }
      //)
      //.then((response) => {
        //setMusoArtistData((prevState) => ({
          //...prevState,
         // creditData: response.data,
       // }));
        // console.log(response.data);
     // })
     // .catch((error) => console.error(error));
 // }, []);

  //React.useEffect(() => {
   // const idKey = "id";
   // const idValue = "62da97dc-3bb0-4bf8-bbd0-4d6e9889ee15";
   // const offset = 0; // This is the default value
    //const limit = 20; // This is the default value
    //const apiKey = "yJ6p8a5uUq8w5JmHh4A0I7EVtj0OqI4U4uVry0Fo"; // Replace with your actual API key

    //axios
      //.get(
       // `https://api-production.muso.ai/api/c/v3/artist/${idKey}/${idValue}/collaborators?offset=${offset}&limit=${limit}`,
        //{
         // headers: {
           // "x-api-key": apiKey,
          //},
        //}
      //)
      //.then((response) => {
       // setMusoArtistData((prevState) => ({
         // ...prevState,
          //collabData: response.data,
        //}));
        // console.log(response.data);
      //})
      //.catch((error) => console.error(error));
  //}, []);

  // setMusoArtistData({
  //   profile: profileData.data, // Adjusted based on response structure
  //   credits: creditsData.data.credits, // Adjusted based on response structure
  //   collaborators: collaboratorsData.data.collaborators, // Adjusted based on response structure
  // });

  //   React.useEffect(() => {
  //     const loadData = async () => {
  //       try {
  //         console.log("loading token.........");
  //         // setIsLoading(true);
  //         localStorage.removeItem("accounts:accessToken");
  //         localStorage.removeItem("accounts:fileUploadToken");
  //         const cookie = Cookies.get("sessionId");
  //         const data = await get_tokens(cookie);
  //         let tokens = data.getSessionTokens;
  //         if (tokens.reactionAccessToken && tokens.accessToken && tokens.user) {
  //           console.log("running tokens apis...", tokens);
  //           if (tokens.user.subscriptionStatus === "past_due") {
  //             localStorage.clear();
  //             toast.error("Please renew your subscription.");
  //             window.location.href = `${process.env.NEXT_PUBLIC_REDIRECT_LINK}/user/settings`;
  //             return;
  //           } else {
  //             localStorage.setItem(
  //               "accounts:accessToken",
  //               tokens.reactionAccessToken
  //             );
  //             Cookies.set("reactionAccessToken", tokens.reactionAccessToken);
  //             Cookies.remove("userId");
  //             Cookies.set("userId", tokens.user?._id);
  //             localStorage.setItem(
  //               "accounts:fileUploadToken",
  //               tokens.accessToken
  //             );
  //             setUserTokenData(tokens.user);
  //             setPrivileges(
  //               tokens?.user?.rolePrivilege?.privileges?.find(
  //                 (prv) => prv.value === "opportunity"
  //               )
  //             );
  //             // setIsLoading(false);
  //           }
  //         } else {
  //           toast.error("Session expired");
  //           //   setIsLoading(false);
  //           window.location.href = "https://unitedmarket.com/signin";
  //         }
  //       } catch (err) {
  //         toast.error("Session Expired.");
  //         // setIsLoading(false);
  //         window.location.href = "https://unitedmarket.com/signin";
  //       }
  //     };
  //     loadData();
  //   }, []); // Run this effect only once on mount

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isFreemium,
        setFreemium,
        loading,
        setLoading,
        loadUser,
        isAuthenticated,
        setIsAuthenticated,
        userToken,
        setUserToken,
        // getArtist,
        artistData,
        musoArtistData,
        showBetaFeatures,
        setShowBetaFeatures,
        currentRoleType,
        setCurrentRoleType,
        userPrivileges,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export default function useAuth() {
  const authContext = React.useContext(AuthContext);
  return authContext;
}
