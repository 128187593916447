import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { membership_bg } from "../../../../../assets/smartlink";

const Main = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url("${membership_bg}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  overflowX: "hidden",
  position: "relative",
  zIndex: 1,
  "&:after": {
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, 0.50)",
    zIndex: "-1",
  },
}));
const CenterBoxMain = styled(Box)(({ theme }) => ({
  width: "50%",
  padding: "80px 70px",
  borderRadius: "20px",
  textAlign: "center",
  background:
    "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
  backdropFilter: "blur(200px)",
  "& .logo": {
    marginBottom: "40px",
  },
  "& .img-box": {
    width: "100%",
    textAlign: "center",
    marginBottom: "10px",
    img: {
      borderRadius: "50%",
      objectFit: "cover",
      [theme.breakpoints.up("md")]: {
        height: "100px !important",
        width: "100px !important",
      },
    },
  },
  "& .inbox_social": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    margin: "10px 0px",
  },
  "& .title-box": {
    marginBottom: "18px",
    "& h5": {
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
      marginBottom: "0",
      marginTop: "0",
    },
    "& span": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      marginBottom: "5px",
    },
  },
  "& .desc": {
    textAlign: "center",
    marginBottom: "40px",
    padding: "0px 110px",
    "& p": {
      color: "#AFAFAF",
      textAlign: "center",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
  },
  "& .btn-box": {
    display: "flex",
    justifyContent: "center",
    gap: "24px",
    "& .btn": {
      padding: "10.5px 30px",
      borderRadius: "5px",
      border: "1px solid #AFAFAF",
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
    "& .green": {
      borderRadius: "5px",
      background: "#2EBB55",
      color: "#fff",
      border: "1px solid #2EBB55",
    },
  },
  [theme.breakpoints.down("lg")]: {
    width: "60%",
    padding: "20px",
    "& .logo": {
      img: {
        width: "30% !important",
      },
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "98%",
    padding: "20px",
    "& .logo": {
      img: {
        width: "20% !important",
      },
    },
    "& .img-box": {
      img: {
        width: "80px",
        height: "80px",
      },
    },
  },
}));

export { Main, CenterBoxMain };
