import React from "react";
import artistData from "../../backend/dummy_data/artist_data";
import styles from "./dashboard.module.css";
import opportunities_data from "../../backend/dummy_data/opportunities_data";

function DashboardArtistSpotlight() {
  return (
    <div className={styles.artist_spotlight_card}>
      <div className={styles.header_banner}>
        <div className={styles.shadow_overlay}></div>

        <img
          className={styles.cover_image}
          src="/v2Assets/dashboard_assets/artist_spotlight_image.png"
          alt="Cover"
        ></img>
      </div>
    </div>
  );
}

export default DashboardArtistSpotlight;
