import React from "react";
import SelectDropDown from "../../dashboard/Analytics/SelectDropDown";
import { Main } from "../../dashboard/Analytics/styles";
import { Grid, Box } from "@mui/material";
import AnalyticsCard from "../../common/Analytics/AnalyticsCard/AnalyticsCard";
import AnalyticsChart from "../../common/Analytics/AnalyticsChart/AnalyticsChart";
import { WidgetsMain } from "./InsightsMainStyle";
import { Link } from "react-router-dom";
import InsightsTables from "../widgets/InsightsTables";
import { image2 } from "../../../../assets/smartlink";

const InsightsMain = () => {
  const BodyData = [
    {
      id: "0",
      Link: {
        image: image2,
        title: "Tu Hai Kaha",
        desc: "Uran Official",
      },
      Visits: "147",
      CTR: "100%",
      ClickThroughs: "1",
    },
    {
      id: "1",
      Link: {
        image: image2,
        title: "Tu Hai Kaha",
        desc: "Uran Official",
      },
      Visits: "173",
      CTR: "50%",
      ClickThroughs: "2",
    },
  ];
  const CitysBodyData = [
    {
      id: "0",
      Link: {
        image: image2,
        title: "Unknow",
        desc: "UK",
      },
      Visits: "147",
      CTR: "100%",
      ClickThroughs: "1",
    },
  ];
  const RefersBodyData = [
    {
      id: "0",
      Link: {
        image: image2,
        title: "Tu Hai Kaha",
      },
      Visits: "147",
      CTR: "100%",
      ClickThroughs: "1",
    },
    {
      id: "1",
      Link: {
        image: image2,
        title: "Tu Hai Kaha",
      },
      Visits: "173",
      CTR: "50%",
      ClickThroughs: "2",
    },
  ];
  return (
    <>
      <Main>
        <Box className="header">
          <h6>Activity</h6>
          <SelectDropDown />
        </Box>
        <Box className="box_AnalyticsCard">
          <Grid container spacing={2}>
            <Grid item lg={4} sm={12}>
              <AnalyticsCard title="vistis" value="0" />
            </Grid>
            <Grid item lg={4} sm={12}>
              <AnalyticsCard title="click-throughs" value="0" />
            </Grid>
            <Grid item lg={4} sm={12}>
              <AnalyticsCard title="ctr" value="0" />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <AnalyticsChart />
        </Box>
      </Main>
      <WidgetsMain>
        <Box className="header">
          <Box>
            <h4>Top Channels</h4>
            <h6>VISITS BY CHANNELS</h6>
          </Box>
          <Box>
            <Link to="#">View Channels Breakdown</Link>
          </Box>
        </Box>
        <InsightsTables BodyData={BodyData} ProgressBar={true} />
      </WidgetsMain>
      {/* citys */}
      <WidgetsMain>
        <Box className="header">
          <Box>
            <h4>Top Cities</h4>
            <h6>VISITS BY CITIES</h6>
          </Box>
          <Box>
            <Link to="#">View Refers Breakdown</Link>
          </Box>
        </Box>
        <InsightsTables
          BodyData={CitysBodyData}
          ProgressBar={true}
          roundedimage={true}
        />
      </WidgetsMain>
      {/* Top Refers */}
      <WidgetsMain>
        <Box className="header">
          <Box>
            <h4>Top Refers</h4>
            <h6>VISITS BY CITIES</h6>
          </Box>
          <Box>
            <Link to="#">View Refers Breakdown</Link>
          </Box>
        </Box>
        <InsightsTables
          BodyData={RefersBodyData}
          ProgressBar={true}
          roundedimage={true}
        />
      </WidgetsMain>

      {/* Top Refers */}
      <WidgetsMain>
        <Box className="header">
          <Box>
            <h4>Top Services</h4>
            <h6>VISITS BY CITIES</h6>
          </Box>
          <Box>
            <Link to="#">View Refers Breakdown</Link>
          </Box>
        </Box>
        <InsightsTables
        // BodyData={}
        // ProgressBar={true}
        // roundedimage={true}
        />
      </WidgetsMain>
    </>
  );
};

export default InsightsMain;
