import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";

import styles from "./admin.module.css";
import PageSidebar from "../UniversalComponents/page_sidebar";
import AdminAnalytics from "./admin_pages/AdminAnalytics";
import AdminOpportunities from "./admin_pages/AdminOpportunity";
import AdminPendingOpportunities from "./admin_pages/AdminPendingOpportunity";
import AdminUsers from "./admin_pages/AdminUsers";
import analytics_data from "../../backend/dummy_data/admin_data/analytics_data";
import AdminUnderReview from "./admin_pages/under_review/admin_under_review_users";
import AdminSelectedBeats from "./admin_pages/AdminSelectedBeats";
import useAppState from "../../context/useAppState";
import AdminNotifications from "./admin_pages/AdminNotifications";

const keyMapper = {
  analytics: "Analytics",
  users: "Users",
  "under-review": "Under Review",
  opportunities: "Opportunities",
  "selected-beats": "Selected Beats",
  "pending-opportunities": "Pending",
  notificatoins: "Notificatoins",
};

export default function AdminPanel() {
  const tabs = [
    "Analytics",
    "Users",
    "Opportunities",
    "Under Review",
    "Selected Beats",
    "Pending",
    "Notifications",
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const navigate = useNavigate();
  const [refreshLoading, setRefreshLoading] = useState(false);
  const { refreshAllData } = useAppState("general");

  const handleDataRefresh = async () => {
    try {
      setRefreshLoading(true);
      const success = await refreshAllData();
      if (success) {
        console.log("Data refreshed successfully");
      }
    } catch (error) {
      console.error("Error occurred during data refresh:", error);
    } finally {
      setRefreshLoading(false);
    }
  };

  const { tab = "analytics" } = useParams();

  useEffect(() => {
    setActiveTab(keyMapper[tab]);
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case tabs[0]:
        navigate("/adminpanel/analytics");
        break;
      case tabs[1]:
        navigate("/adminpanel/users");
        break;
      case tabs[2]:
        navigate("/adminpanel/opportunities");
        break;
      case tabs[3]:
        navigate("/adminpanel/under-review");
        break;
      case tabs[4]:
        navigate("/adminpanel/selected-beats");
        break;
      case tabs[5]:
        navigate("/adminpanel/pending-opportunities");
        break;
      case tabs[6]:
        navigate("/adminpanel/notificatoins");
        break;
      default:
        navigate("/adminpanel/analytics");
        break;
    }
  }, [activeTab]);

  const isActive = (page) => {
    return keyMapper[tab] === page;
  };

  return (
    <div className={styles.main_layout}>
      <Grid container justifyContent={"flex-start"}>
        <Grid item className={styles.left}>
          <PageSidebar
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            refreshButton={true}
            handleDataRefresh={handleDataRefresh}
            refreshLoading={refreshLoading}
          />
        </Grid>
        <Grid item className={styles.right}>
          {isActive("Analytics") && (
            <AdminAnalytics analytics_data={analytics_data} />
          )}
          {isActive("Users") && <AdminUsers />}
          {isActive("Opportunities") && <AdminOpportunities />}
          {isActive("Under Review") && <AdminUnderReview />}
          {isActive("Pending") && <AdminPendingOpportunities />}
          {isActive("Selected Beats") && <AdminSelectedBeats />}
          {isActive("Notificatoins") && <AdminNotifications />}
        </Grid>
      </Grid>
    </div>
  );
}
