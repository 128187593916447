import React from "react";
import artistData from "../../backend/dummy_data/artist_data";
import styles from "./dashboard.module.css";
import opportunities_data from "../../backend/dummy_data/opportunities_data";

function DashboardOpportunities() {
  return (
    <div className={styles.opporrtunities_list_grid}>
      {opportunities_data.map((opportunity) => (
        <div className={styles.opporrtunities_card}>
          <div className={styles.opporrtunities_top_info}>
            <div>
              <p className={styles.opporrtunities_title}>
                Beats for Lil Baby’s upcoming project
              </p>
              <p className={styles.opporrtunities_subtitle}>
                Apply to have your beat on his next album
              </p>
            </div>
            <div>
              <p>P</p>
            </div>
          </div>
          <div>
            <div className={styles.profile_info}>
              <div className={styles.artist_profile_info}>
                <img
                  className={styles.profile_image}
                  alt="profile"
                  src="/v2Assets/profile_picture_assets/imageOne.png"
                ></img>
                <p className={styles.artist_name}>Lil Baby</p>
              </div>
              <p className={styles.monthly_listeners}>Monthly Listeners: 43k</p>
            </div>
            <div className={styles.profile_info}> </div>
          </div>
          <div className={styles.opporrtunities_bottom_info}>
            <div>
              {/* <img className={styles.time_image}></img> */}
              <p className={styles.time_text}>08 days remaining</p>
            </div>
            <div className={styles.details_button}>
              <p className={styles.details_button_text}>Submit</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default DashboardOpportunities;
