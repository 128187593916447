import React, { useEffect, useRef, useState } from "react";

import WaveSurfer from "wavesurfer.js";
import styles from "./inbox.module.css";
import useAppState from "../../context/useAppState";

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: "#eee",
  progressColor: "rgb(46 187 85)",
  cursorColor: "rgb(46 187 85)",
  barWidth: 1,
  barRadius: 3,
  responsive: true,
  height: 25,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
});

export default function Waveform({ url }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(1);

  const { currentUrl, updateURL } = useAppState("common");

  useEffect(() => {
    if (!currentUrl) return;
    if (url === currentUrl) {
      wavesurfer.current?.play();
      setPlay(true);
    } else {
      wavesurfer.current?.pause();
      setPlay(false);
    }
  }, [currentUrl]);

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", function () {
      // make sure object stillavailable when file loaded
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [url]);

  const handlePlayPause = () => {
    updateURL(url);
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  return (
    <div className={styles.waveform_layout}>
      <div onClick={handlePlayPause} className={styles.play_button}>
        {!playing ? (
          <img
            className={styles.play_icon}
            alt="play"
            src="/v2Assets/PlayIcon.png"
          />
        ) : (
          <img
            className={styles.play_icon}
            alt="pause"
            src="/v2Assets/PauseIcon.png"
          />
        )}
      </div>
      <div style={{ width: "180px" }}>
        {/* This sets the container width to 80% of its parent */}
        <div id="waveform" ref={waveformRef} />
      </div>
    </div>
  );
}
