import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

export const MainBioBox = styled(Box)(({ theme }) => ({
    borderRadius: '5px',
    display: "flex",
    gap : '30px',
    h5: {
        margin: 0,
        fontWeight: 400,
        fontSize: 24,
        color: '#E3E3E3'
    },
    p: {
        margin: '12px 0'
    }
}));