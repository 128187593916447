import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "0 auto",

  "& .logomain": {
    width: "45px",
    height: "45px",
  },
  h5: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "36px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "#fff",
    marginBottom: "20px",
  },
  "& .input-field-box": {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    marginBottom: "20px",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "27px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#fff",
    marginBottom: "6px",
  },
  input: {
    background:
      "linear-gradient(0deg, #1C1B1B, #1C1B1B),\nlinear-gradient(0deg, #414241, #414241)",
    height: "40px",
    border: "1px solid rgba(65, 66, 65, 1)",
    fontFamily: theme.typography.fontFamily,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "27px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#fff",
    borderRadius: "5px",
    padding: "8px",
  },

  "& .button_main": {
    textAlign: "right",
    button: {
      background: "rgba(46, 187, 85, 1)",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "27px",
      letterSpacing: "0em",
      textAlign: "left",
      padding: "7px 20px",
      textTransform: "capitalize",
    },
  },
}));

export { Main };
