import React from "react";
import { useState, useRef, useEffect } from "react";
import styles from "./connect.module.css";
import PageSearchHeader from "../UniversalComponents/page_search_header";
import ConnectMeUsers from "./ConnectMeUsers";
import ConnectMeNetwork from "./ConnectMeNetwork";
import ConnectMeRequests from "./ConnectMeRequests";
// import ConnectMeNetwork from "./connect_me_network";
// import PastSubmissions from "../submissions_page/past_submissions";
// import ConnectMeReceivedSubmissions from "./conect_me_received";
// import ConnectMeRequests from "./connect_me_requests";
import useAuth from "../../context/AuthContext";

function ConnectMeComponent() {
  const tabs = ["Connect", "My Network", "Requests"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [searchQuery, setSearchQuery] = useState("");
  const { isDesktop } = useAuth();

  const [type, setType] = useState(tabs[0]);
  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  useEffect(() => {
    const tabEl = [...tabsRef.current.children].find(
      (child) => child.textContent === activeTab
    );
    const { offsetLeft, offsetWidth } = tabEl;
    underlineRef.current.style.left = `${offsetLeft}px`;
    underlineRef.current.style.width = `${offsetWidth}px`;
  }, [activeTab]);

  return (
    <div className={styles.main_layout}>
      <PageSearchHeader
        title="ConnectMe"
        searchBar="Search ConnectMe"
        showCreate={false}
        showFilter={false}
        showSort={false}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
      />
      <div className={styles.tabs} ref={tabsRef}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => {
              setActiveTab(tab);
              setType(type === tab ? "" : tab);
            }}
          >
            {tab}
          </div>
        ))}
        <div className={styles.underline} ref={underlineRef} />
      </div>
      {/* <SliderMenu
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        notifCount={3}
      /> */}
      {activeTab === "Connect" && (
        <ConnectMeUsers query={searchQuery} isDesktop={isDesktop} />
      )}
      {activeTab === "My Network" && (
        <ConnectMeNetwork query={searchQuery} isDesktop={isDesktop} />
      )}
      {/* {activeTab === "Received Submissions" && (
        <ConnectMeReceivedSubmissions query={searchQuery} />
      )} */}
      {activeTab === "Requests" && (
        <ConnectMeRequests query={searchQuery} isDesktop={isDesktop} />
      )}
    </div>
  );
}

export default ConnectMeComponent;
