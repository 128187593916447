/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { toast } from "react-toastify";
import DarkTooltipWithBorder from "../AdditionalComponents/Tooltip/DarkTooltipWithBorder";
import Tooltip from "@material-ui/core/Tooltip";
import { get } from "lodash";
import styles from "../Opportunities/Opportunities.module.css";
import DeletePopup from "../../Popups/DeletePopup";
import { formatCount, remainngTimeCount } from "../../../shared/funs";
import useAuth from "../../../context/AuthContext";
import useAppState from "../../../context/useAppState";
import moment from "moment";
import WavePlayer from "../../../components-old/WavePlayer";
import { useMediaQuery } from "@material-ui/core";

function SyncCard({ displayOnly, opportunityData, isSelf, onDelete }) {
  // console.log(opportunityData);
  const [tokenModal, setTokenModal] = React.useState(false);
  const navigate = useNavigate();
  const { deleteOpportunityById } = useAppState("sync");
  const { user, currentRoleType } = useAuth();

  const isDesktop = useMediaQuery("(min-width: 1200px)");

  const cover_image =
    opportunityData?.coverPhoto?.url || "/images/Image_not_available.png";
  const [open, setOpen] = React.useState(false);
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState();

  const isFeatured = opportunityData?.featured;

  const handleOpen = (id) => {
    console.info("id => ", id);

    navigate(`/sync/apply/${id}`);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseTokenModal = (buyToken, id) => {
    setTokenModal(false);
    if (buyToken === "yes") {
      window.location.href = `${process.env.NEXT_PUBLIC_REDIRECT_LINK}/freemium`;
      return;
    }
  };

  React.useEffect(() => {
    if (opportunityData?.userId) {
      setUserInfo(opportunityData?.user);
    }
  }, [opportunityData]);

  const handleDeletePopup = () => {
    setDeletePopup(true);
  };

  const handleClosePopup = () => {
    setDeletePopup(false);
  };

  const cancelHandler = (isDelete, opportunityId) => {
    if (isDelete === "yes") {
      deleteHandler(opportunityId);
      handleClosePopup(false);
    } else {
      handleClosePopup();
    }
  };

  const deleteHandler = async (opportunityId) => {
    try {
      const response = await deleteOpportunityById(opportunityData?._id);
      onDelete(opportunityData?._id);
      toast.success("Opportunity deleted successfully.");
      console.info("response => ", response);
    } catch (err) {
      console.log("error on delete oportunity id..", err);
    }
  };

  // const handleShareModalClose = () => {
  //   setOpenShareModal(false);
  // };

  const linkHandler = (link) => {
    window.open(link, "_blank");
  };

  const remainingMinutes = Math.floor(
    moment
      .duration(moment(opportunityData.expireDate).diff(moment()))
      .asMinutes()
  );

  return (
    <>
      {!isDesktop ? (
        <div className={styles.opportunity_card_layout_mobile}>
          <div className={styles.opportunity_card_content_mobile}>
            <div className={styles.opportunity_card_content_left_mobile}>
              <div className={styles.opportunity_card_image_container_mobile}>
                <div
                  className={styles.opportunity_card_image}
                  style={{
                    background: `url(${cover_image})center center / cover no-repeat`,
                  }}
                />
              </div>
            </div>

            <div className={styles.opportunity_card_content_layout}>
              <div className={styles.opportunity_card_information}>
                <div className={styles.opportunity_card_left_side}>
                  <div className={styles.sync_card_left_side_information}>
                    <OpportunityTitleInfo />
                    <SyncInformation />
                    {opportunityData.referanceSpotifyLink && (
                      <SyncReferanceLink
                        referanceSpotifyLink={
                          opportunityData.referanceSpotifyLink
                        }
                      />
                    )}
                    {/* <OpportunityInformation /> */}
                    {opportunityData?.terms === "true" ||
                      (opportunityData?.terms === true && <OpportunityTerms />)}
                  </div>
                </div>
                <div className={styles.opportunity_card_right_side}>
                  <div className={styles.uploaded_by_wrapper}>
                    {!isSelf && !displayOnly && (
                      <div className={styles.posted_by_info}>
                        <OpportunityPostedBy />
                      </div>
                    )}
                    {isFeatured && (
                      <div className={styles.feature_tag}>Featured</div>
                    )}
                  </div>
                  <div className={styles.opportunity_card_status_controls}>
                    {isSelf && (
                      <p className={styles.submission_count_text}>
                        {get(opportunityData, "submissionCount", 0)} Submissions
                      </p>
                    )}
                    {isSelf && <CreatorOpportunityControls />}
                  </div>
                </div>
              </div>
              <div className={styles.opportunity_card_bottom_mobile}>
                <div className={styles.submit_button_mobile}>
                  {!displayOnly ? (
                    <div className={styles.submit_button_mobile}>
                      {isSelf ? (
                        <ViewSubmissionsButton />
                      ) : (
                        user.roleId.roleName === "Music Producer" ||
                        (user.roleId.roleName === "Admin" && (
                          <OpportunitySubmitButton />
                        ))
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className={styles.spacer_mobile}></div>
                <OpportunityExpirationCountdown />
              </div>
            </div>
          </div>
          <Modals />
        </div>
      ) : (
        <div className={styles.opportunity_card_layout}>
          <div className={styles.opportunity_card_content}>
            <div className={styles.opportunity_card_content_left}>
              <div className={styles.opportunity_card_image_container}>
                <div
                  className={styles.opportunity_card_image}
                  style={{
                    background: `url(${cover_image})center center / cover no-repeat`,
                  }}
                />
              </div>
            </div>

            <div className={styles.opportunity_card_content_layout}>
              <div className={styles.opportunity_card_information}>
                <div className={styles.opportunity_card_left_side}>
                  <div className={styles.sync_card_left_side_information}>
                    <OpportunityTitleInfo />
                    <SyncInformation />
                    {opportunityData.referanceSpotifyLink && (
                      <SyncReferanceLink
                        referanceSpotifyLink={
                          opportunityData.referanceSpotifyLink
                        }
                      />
                    )}

                    {/* <OpportunityInformation /> */}

                    {opportunityData?.terms === "true" ||
                      (opportunityData?.terms === true && <OpportunityTerms />)}
                  </div>
                </div>
                <div className={styles.opportunity_card_right_side}>
                  <div className={styles.uploaded_by_wrapper}>
                    {/* {!isSelf && !displayOnly && (
                      <div className={styles.posted_by_info}>
                        <OpportunityPostedBy />
                      </div>
                    )} */}
                    {isFeatured && (
                      <div className={styles.feature_tag}>Featured</div>
                    )}
                  </div>
                  <div className={styles.opportunity_card_status_controls}>
                    {isSelf && (
                      <p className={styles.submission_count_text}>
                        {get(opportunityData, "submissionCount", 0)} Submissions
                      </p>
                    )}
                    {isSelf && <CreatorOpportunityControls />}
                    {!isSelf && opportunityData?.referenceTrack?.url && (
                      <div className={styles.referance_track_layout}>
                        <p className={styles.referance_track_text}>
                          Reference Track:
                        </p>

                        <div className={styles.referance_track}>
                          {get(opportunityData, "referenceTrack.url") && (
                            <>
                              <WavePlayer
                                key={
                                  opportunityData?._id ||
                                  opportunityData?.referenceTrack?.url
                                }
                                file={opportunityData?.referenceTrack}
                                url={opportunityData?.referenceTrack?.url}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.opportunity_card_bottom}>
                {!displayOnly ? (
                  <>
                    {isSelf ? (
                      <ViewSubmissionsButton />
                    ) : (
                      (currentRoleType === "Music Producer" ||
                        currentRoleType === "Admin") && (
                        <OpportunitySubmitButton />
                      )
                    )}
                  </>
                ) : (
                  <div></div>
                )}
                <OpportunityExpirationCountdown />
              </div>
            </div>
          </div>
          <Modals />
        </div>
      )}
    </>
  );

  function Modals() {
    return (
      <>
        {/* <SubmissionModal
          open={open}
          handleClose={handleClose}
          data={opportunityData}
          setBidsCount={setSubmissionCount}
          bidsCount={submissionCount}
        /> */}
        <DeletePopup
          open={deletePopup}
          title="Delete Opportunity"
          content="Do you really want to remove this opportunity?"
          label1="Delete"
          label2="Cancel"
          handler={cancelHandler}
          id={opportunityData?.id}
        />
        <DeletePopup
          open={tokenModal}
          title="Insufficient Token"
          content="We are sorry to inform you that you can’t submit to opportunity because you have insufficient amount of token. For submitting on the opportunity get more tokens."
          label1="Get More Tokens"
          label2="Cancel"
          handler={handleCloseTokenModal}
          id={"dfdsfdsfdf"}
        />
      </>
    );
  }

  function SyncInformation() {
    return (
      <div className={styles.sync_info}>
        <p className={styles.project_info}>
          Company Name:{" "}
          <span className={styles.project_info_green}>
            {opportunityData?.companyName}
          </span>
        </p>
        <div className={styles.dot}></div>
        <p className={styles.project_info}>
          Media Type:{" "}
          <span className={styles.project_info_green}>
            {opportunityData?.mediaType}
          </span>
        </p>
      </div>
    );
  }

  function SyncReferanceLink(props) {
    function openDocument() {
      window.open(props.referanceSpotifyLink, "_blank").focus();
    }

    function getSpotifyLinkType(url) {
      if (!url.startsWith("https://open.spotify.com/")) {
        return "Invalid Spotify link";
      }

      const parts = url.split("/");
      const type = parts[3];

      return `Reference Spotify ${
        type.charAt(0).toUpperCase() + type.slice(1)
      } Link`;
    }

    return (
      <div className={styles.referance_link_display}>
        <p className={styles.document_file_title} onClick={openDocument}>
          {getSpotifyLinkType(props.referanceSpotifyLink)}
        </p>
      </div>
    );
  }

  function OpportunityTitleInfo() {
    return (
      <>
        <p className={styles.opportunity_title}>{opportunityData?.title}</p>
        <Tooltip placement="bottom-start" title={opportunityData?.description}>
          <p className={styles.sync_description}>
            {opportunityData?.description}
          </p>
        </Tooltip>
      </>
    );
  }

  function OpportunityPostedBy() {
    return (
      <Box className={styles.posted_by}>
        <Box>
          <p className={styles.opportunity_posted_by}>Posted By</p>
          {opportunityData?.postedByName ? (
            <p className={styles.opportunity_owner}>
              {opportunityData?.postedByName}
            </p>
          ) : (
            <p className={styles.opportunity_owner}>
              {userInfo?.userName || "User"}
            </p>
          )}
        </Box>
        {opportunityData?.postedByImage ? (
          <img
            src={opportunityData?.postedByImage.url}
            className={styles.avatar}
            alt="posted by"
          />
        ) : (
          <img
            src={userInfo?.profilePic}
            className={styles.avatar}
            alt="posted by"
          />
        )}
      </Box>
    );
  }

  function OpportunityInformation() {
    return (
      <div className={styles.opportunity_data_information}>
        <div className={styles.opportunity_tags_container}>
          <div className={styles.opportunity_tags}>
            Genre: {opportunityData?.genre}
          </div>
          {opportunityData?.bpm && (
            <div className={styles.opportunity_tags}>
              BPM: {opportunityData?.bpm}
            </div>
          )}
          {opportunityData?.monthlyListeners && (
            <div className={styles.opportunity_tags}>
              Monthly Listeners:{" "}
              {formatCount(opportunityData.monthlyListeners, true)}
            </div>
          )}
          <div className={styles.opportunity_tags}>
            File Format: {opportunityData?.fileFormat}
          </div>
        </div>
      </div>
    );
  }

  function OpportunityReferanceTrack() {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography style={{ color: "#afafaf", flexShrink: 0 }}>
            Reference Track:
          </Typography>
        </Grid>
        <Grid item>
          {get(opportunityData, "referenceTrack.url") && (
            <WavePlayer
              key={opportunityData?._id || opportunityData?.referenceTrack?.url}
              file={opportunityData?.referenceTrack}
              url={opportunityData?.referenceTrack?.url}
            />
          )}
        </Grid>
      </Grid>
    );
  }

  function OpportunitySubmitButton() {
    return (
      <div
        className={styles.submit_button}
        onClick={() => handleOpen(opportunityData._id)}
      >
        Submit
      </div>
    );
  }

  function ViewSubmissionsButton() {
    return (
      <div
        className={styles.submit_button}
        onClick={() => navigate(`/sync/viewsubmission/${opportunityData?._id}`)}
      >
        View Submissions
      </div>
    );
  }

  function CreatorOpportunityControls() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
        }}
      >
        <button
          type="button"
          className={styles.edit_button}
          onClick={() => navigate(`/sync/edit/${opportunityData?._id}`)}
        >
          Edit
        </button>
        <button
          className={styles.delete_button}
          onClick={(e) => handleDeletePopup()}
        >
          Delete
        </button>
      </div>
    );
  }

  function OpportunityExpirationCountdown() {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "center",
          alignItems: "row",
          columnGap: "48px",
          backgroundColor: "rgba(0, 0, 0, 0.85)",
          borderRadius: "5px",
          paddingLeft: "35px",
          paddingRight: "21px",
          paddingTop: "3px",
          paddingBottom: "3px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "4px 3px",
          }}
        >
          <span
            style={
              remainingMinutes > 0
                ? { color: "#7E7E7E", fontSize: "15px" }
                : { color: "red" }
            }
          >
            {remainngTimeCount(opportunityData.expireDate).days > 0
              ? remainngTimeCount(opportunityData.expireDate).days
              : 0}
          </span>
          <span
            style={
              remainingMinutes > 0
                ? { color: "#7E7E7E", fontSize: "15px" }
                : { color: "red" }
            }
          >
            {remainngTimeCount(opportunityData.expireDate).days <= 1
              ? "day"
              : "days"}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "4px 3px",
          }}
        >
          <span
            style={
              remainingMinutes > 0
                ? { color: "#7E7E7E", fontSize: "15px" }
                : { color: "red" }
            }
          >
            {remainngTimeCount(opportunityData.expireDate).hours > 0
              ? remainngTimeCount(opportunityData.expireDate).hours
              : 0}
          </span>
          <span
            style={
              remainingMinutes > 0
                ? { color: "#7E7E7E", fontSize: "15px" }
                : { color: "red" }
            }
          >
            hours
          </span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "4px 3px",
          }}
        >
          <span
            style={
              remainingMinutes > 0
                ? { color: "#7E7E7E", fontSize: "15px" }
                : { color: "red" }
            }
          >
            {remainngTimeCount(opportunityData.expireDate).minutes > 0
              ? remainngTimeCount(opportunityData.expireDate).minutes
              : 0}
          </span>
          <span
            style={
              remainingMinutes > 0
                ? { color: "#2EBB55", fontSize: "14px" }
                : { color: "red" }
            }
          >
            minutes
          </span>
        </div>
      </Box>
    );
  }

  function OpportunityTerms() {
    return (
      <Box display={"flex"} style={{ paddingTop: 5, paddingBottom: 6 }}>
        <DarkTooltipWithBorder
          placement="bottom-start"
          title={
            <React.Fragment>
              <Box>
                <p
                  style={{
                    fontSize: 16,
                    padding: "15px 10px",
                    margin: "0px",
                    borderBottom: "1px solid #afafaf",
                  }}
                >
                  Advance ($):{" "}
                  <span className={styles.text_white}>
                    ${opportunityData?.advance || 0}
                  </span>
                </p>
                <p
                  style={{
                    fontSize: 16,
                    padding: "15px 10px",
                    margin: "0px",
                    borderBottom: "1px solid #afafaf",
                  }}
                >
                  Points:{" "}
                  <span className={styles.text_white}>
                    {opportunityData?.points || 0}
                  </span>
                </p>

                <p
                  style={{
                    fontSize: 16,
                    padding: "15px 10px",
                    margin: "0px",
                  }}
                >
                  Publishing (%):
                  <span className={styles.text_white}>
                    {" "}
                    {opportunityData?.publishing || 0}
                  </span>
                  %
                </p>
              </Box>
            </React.Fragment>
          }
        >
          <Box display={"flex"} style={{ gap: "5px" }}>
            <img
              src="/images/info-circle.png"
              alt=""
              width={"15px"}
              height={"15px"}
            />
            <Typography style={{ color: "#afafaf", fontSize: 12 }}>
              Terms are pre-set for this opportunity
            </Typography>
          </Box>
        </DarkTooltipWithBorder>
        {/* <Tooltip arrow placement="bottom"></Tooltip> */}
      </Box>
    );
  }
}
export default SyncCard;
