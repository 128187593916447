import React, { useState, useEffect, useMemo } from "react";

import styles from "../admin.module.css";
import CircularLoader from "../../UniversalComponents/CircularLoader/CircularLoader";
import { Box } from "native-base";
import { Loader } from "../../UniversalComponents/Loader";
import { Tooltip } from "@mui/material";
import PageSearchHeader from "../../UniversalComponents/page_search_header";
import { useDebounce } from "../../../hooks/useDebounce";
import Pagination from "../../../components-old/Pagination/Pagination";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  EXPORT_NOTIFICATIONS,
  GET_NOTIFICATIONS,
} from "../../../Graphql_services/beatinbox/Beats";
import { toast } from "react-toastify";
import brandLogo from "../../../assets/Images/favicon.png";

const notificatoinsData = [
  { id: 1, title: "Total Notification", count: 0 },
  { id: 2, title: "Success Notification", count: 0 },
  { id: 3, title: "Error Notification", count: 0 },
];

const AdminNotifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationStatsData, setNotificationStatsData] =
    useState(notificatoinsData);
  const [searchQueryValue, setSearchQueryValue] = useState("");
  const [filterAnchor, setFilterAnchor] = useState(null);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const [activeFilterTab, setActiveFilterTab] = useState("");
  const [dateFilterValue, setDateFilterValue] = useState({
    start: null,
    end: null,
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [exportNotificationList, setExportNotificationList] = useState([]);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const searchQueryData = useDebounce(searchQueryValue, 1000);

  // ********** Defines the options for filtering notifications based on different criteria **********
  const filterData = [
    {
      id: 1,
      title: "Time",
      key: "time",
    },
    {
      id: 2,
      title: "Beat Type",
      key: "type",
      filterBy: [
        "beat-download",
        "beat-favorite",
        "beat-select",
        "beat-submission",
        "beat-expire-one-day",
        "beat-expire-two-day",
      ],
    },
    {
      id: 3,
      title: "Opportunity Type",
      key: "type",
      filterBy: [
        "new-opportunity",
        "opportunity-download",
        "opportunity-expire-one-day",
        "opportunity-expire-one-week",
        "opportunity-submission",
        "opportunity-favorited",
        "opportunity-selected",
      ],
    },
    {
      id: 4,
      title: "Status",
      key: "status",
      filterBy: ["fail"],
    },
  ];
  // ********** Executes a lazy query to retrieve notifications **********
  const [loadNotification] = useLazyQuery(GET_NOTIFICATIONS, {
    context: { clientName: "admin" },
    fetchPolicy: "no-cache",
    onError(err) {
      console.log("error on getting notification info", err);
      setIsLoading(false);
    },
    onCompleted(notificationItems) {
      if (notificationItems) {
        const {
          getAllNotifications: {
            items,
            lastPage,
            totalNotificationCount,
            successNotificationCount,
            failNotificationCount,
          },
        } = notificationItems;

        setNotificationList(items);
        setTotalCount(lastPage);

        const updateNotificationStatus = notificationStatsData.map((data) => {
          switch (data.id) {
            case 1: {
              return {
                ...data,
                count: totalNotificationCount || 0,
              };
            }

            case 2: {
              return { ...data, count: successNotificationCount || 0 };
            }

            case 3: {
              return { ...data, count: failNotificationCount || 0 };
            }

            default:
              return data;
          }
        });

        setNotificationStatsData(updateNotificationStatus);
      }
      setIsLoading(false);
    },
  });

  // ********** Executes a lazy query to retrieve export notifications **********
  const [exportNotification] = useLazyQuery(EXPORT_NOTIFICATIONS, {
    context: { clientName: "admin" },
    fetchPolicy: "no-cache",
    onError(err) {
      console.log("error on getting notification info", err);
      setIsLoading(false);
    },
    onCompleted(exportNotificationItems) {
      if (exportNotificationItems) {
        const { items } = exportNotificationItems.getExportedNotifications;
        if (dateFilterValue?.start && dateFilterValue?.end) {
          setExportNotificationList(items);
        }
      }
      setIsLoading(false);
    },
  });

  // ********** Handles the search query input and updates the search query **********
  const handleSearchQuery = (value) => {
    setSearchQueryValue(value);
  };

  // ********** Handles the click event for opening the filter dropdown **********
  const handleFilterClick = (event) => {
    setFilterAnchor(event.currentTarget);
  };

  // ********** Handles the click event for closing the filter **********
  const handleFilterClose = () => {
    setFilterAnchor(null);
  };

  // ********** Handles the toggles visibility of the date filter or opens/closes other dropdowns **********
  const handleClickDropdown = (id) => {
    if (id === 1) {
      setShowDateFilter(true);
    } else {
      setOpenDropdownId(openDropdownId === id ? null : id);
    }
  };

  // ********** Updates the current page number when pagination changes **********
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // ********** Converts exportNotificationList data to CSV format **********
  const convertToCSV = () => {
    const fieldsToInclude = ["_id", "title", "created_at", "notification_type"];

    const header = fieldsToInclude.join(",");
    const csvData = exportNotificationList?.map((notification) =>
      fieldsToInclude.map((field) => notification[field]).join(",")
    );

    return `${header}\n${csvData.join("\n")}`;
  };
  // ********** Handles the export of notification data to a CSV file **********
  const handleExport = async () => {
    if (
      dateFilterValue.start &&
      dateFilterValue.end &&
      notificationList.length
    ) {
      setIsLoading(true);

      const csvContent = await convertToCSV();
      setIsLoading(false);
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "user_notification_list.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      toast.warn(
        "please add the start and end date or There is no notification"
      );
    }
  };
  // ********** Applies filtering based on the selected value and dropdown ID **********
  const applyFiltering = (value, dropdownId) => {
    setCurrentPage(1);
    setFilterValue(value);
    setActiveFilterTab(dropdownId);
  };
  // ********** Applies date filtering based on the selected value **********
  const applyDateFilter = () => {
    const startDateString = startDate;
    const endDateString = endDate;
    if (startDateString && endDateString) {
      const startDate = new Date(startDateString);
      let endDate = new Date(endDateString);

      const currentDate = new Date();

      // Check if endDate is after the current date, if so, set it to the current date
      if (endDate > currentDate) {
        endDate = currentDate;
        // toast.warn("Start date cannot exceed end date.");
        // console.log(
        //   "End date cannot be beyond the current date. Set to current date."
        // );
      }
      // Ensure start date is not after end date
      if (startDate > endDate) {
        toast.warn("Start date cannot exceed end date.");
        console.error("Start date cannot exceed end date.");
        return;
      }

      if (!isNaN(startDate) && !isNaN(endDate)) {
        const startData = startDate.toISOString();
        const endData = endDate.toISOString();
        setDateFilterValue({
          start: startData,
          end: endData,
        });
        setCurrentPage(1);
      } else {
        console.error("Invalid date input");
      }
    } else {
      toast.warn("Please select both start and end dates");
      console.error("Please select both start and end dates");
    }
  };
  // ********** Resets all filter values and states **********
  const handleFilterResetClick = () => {
    setFilterValue("");
    setActiveFilterTab(null);
    setCurrentPage(1);
    setDateFilterValue({ start: null, end: null });

    setShowDateFilter(false);
    setSearchQueryValue("");
    setFilterAnchor(null);
    if (startDate && endDate) {
      setStartDate(null);
      setEndDate(null);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    loadNotification({
      variables: {
        page: currentPage > 1 ? currentPage : null,
        pageSize: 10,
        from: dateFilterValue?.start || "",
        to: dateFilterValue?.end || "",
        searchQuery: searchQueryData,
        notification_type: filterValue !== "fail" ? filterValue : null,
        notification_status: filterValue === "fail" ? "fail" : null,
      },
    });
  }, [currentPage, filterValue, searchQueryData, dateFilterValue]);

  useEffect(() => {
    if (dateFilterValue.start && dateFilterValue.end) {
      setIsLoading(true);
      exportNotification({
        variables: {
          from: dateFilterValue?.start || "",
          to: dateFilterValue?.end || "",
          searchQuery: searchQueryData,
          notification_type: filterValue !== "fail" ? filterValue : null,
          notification_status: filterValue === "fail" ? "fail" : null,
        },
      });
    }
  }, [filterValue, searchQueryData, dateFilterValue]);

  return (
    <div className={styles.sub_page_layout}>
      <NotificationOverallStats
        notificationStatsData={notificationStatsData}
        isLoading={isLoading}
      />

      <div
        className={styles.control_box}
        style={{
          height: showDateFilter && "300px",
        }}
      >
        <PageSearchHeader
          title="Notifications"
          searchBar="Search "
          showFilter={filterValue !== "fail" && true}
          setSearchQueryValue={handleSearchQuery}
          handleFilterClick={handleFilterClick}
          handleFilterClose={handleFilterClose}
          filterData={filterData}
          filterAnchor={filterAnchor}
          openDropdownId={openDropdownId}
          handleClickDropdown={handleClickDropdown}
          applyFiltering={applyFiltering}
          filterValue={filterValue}
          handleExport={handleExport}
          showTimeButton={showDateFilter}
          showExport={showDateFilter}
          activeFilterTab={activeFilterTab}
          searchQueryValue={searchQueryValue}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          showDateFilter={showDateFilter}
          applyDateFilter={applyDateFilter}
          showRest={true}
          handleFilterResetClick={handleFilterResetClick}
          showSearchBar={filterValue !== "fail" && true}
        />
      </div>

      <div className={styles.table_sections}>
        <div className={styles.checkbox_column_admin}></div>

        <div className={styles.admin_profile_column_header}>
          <p className={styles.row_titles}>Title</p>
        </div>
        <div className={styles.role_column}>
          <p className={styles.row_titles}>Timestamp</p>
        </div>

        <div className={styles.membership_column}>
          <p className={styles.row_titles}>Type</p>
        </div>
        <div className={styles.submissions_column}>
          <p className={styles.row_titles}>
            {filterValue === "fail" ? "Message" : "Status"}
          </p>
        </div>
      </div>

      {isLoading ? (
        <Box className={styles.selected_beat_main_layout}>
          <Loader />
        </Box>
      ) : notificationList?.length > 0 ? (
        <div className={styles.sub_page_layout}>
          <div className={styles.user_section_layout}>
            <div className={styles.admin_users_table_content}>
              {notificationList.map((data, index) => {
                let date = new Date(data.created_at);
                const month = date.toLocaleString("en-US", { month: "short" });
                const day = date.getDate();
                const year = date.getFullYear();

                const formattedDate = `${month} ${day} ${year}`;

                return (
                  <div className={styles.user_content} key={data._id}>
                    <div
                      className={`${styles.user_info} ${styles.admin_profile_column}`}
                    >
                      <img
                        src={
                          data?.created_by !== null && data?.created_by.length
                            ? data?.created_by[0].profilePic
                            : brandLogo
                        }
                        className={styles.user_image}
                        alt="user"
                      ></img>
                      <div className={styles.admin_user_info_text}>
                        <div>
                          <p className={styles.user_title}>
                            {data?.title || "No Title"}
                          </p>
                          <p className={styles.user_subtitle}>
                            <Tooltip
                              placement="top"
                              arrow
                              title="sachin@gmail.com"
                            >
                              <span className={styles.truncate}>
                                {data?.description || "-"}
                              </span>
                            </Tooltip>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className={styles.membership_column}>
                      <p className={styles.info_title}>
                        {data?.created_at !== null ? formattedDate : "-"}
                      </p>
                    </div>

                    <div className={styles.submissions_column}>
                      <p className={styles.info_title_value}>
                        {data?.notification_type || "-"}
                      </p>
                    </div>
                    <div className={styles.submissions_column}>
                      <p className={styles.info_title_value}>
                        {data?.is_read !== null
                          ? data?.is_read
                            ? "Viewed"
                            : "Not Viewed"
                          : "-"}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <Pagination
            currentPage={currentPage}
            totalPages={totalCount}
            onPageChange={handlePageChange}
          />
        </div>
      ) : (
        <p
          className={styles.info_title_value}
          style={{ paddingTop: "10px", textAlign: "center" }}
        >
          No Notification
        </p>
      )}
    </div>
  );
};

export default AdminNotifications;

function NotificationOverallStats(props) {
  return (
    <div className={styles.stats_top_row_three}>
      {props.notificationStatsData?.map((data, index) => (
        <div className={styles.top_row_count_stat} key={data?.id || index}>
          <div className={styles.top_row_content}>
            <p className={styles.top_row_title}>
              {data?.title || "Not Available"}
            </p>
            <p className={styles.top_row_value}>{data?.count || 0}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
