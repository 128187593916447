import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

const LinkHeaderBox = styled(Box)(({ theme }) => ({
  width: "100%",

  display: "flex",

  justifyContent: "space-between",

  alignItems: "center",

  "& .left": {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontFamily: theme.typography.fontFamily,
      color: "#fff",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
  },

  "& .right": {
    "& .active_image_box": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "50px",
      height: "30px",
      borderRadius: "3px",
      background: "rgba(46, 187, 85, 0.10)",
      img: {
        filter: "brightness(0) invert(1)",
      },
    },
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    "& .img-box1": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      borderRadius: "0.1875rem",
    },

    "& .img-box2": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      borderRadius: "0.1875rem",
    },
  },
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  borderRadius: "3px",
  gap: "5px",
  paddingLeft: "8px",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  background: "#0A0B0A",
  "& .MuiSvgIcon-root": {
    marginLeft: "8px",
    width: "100%",
    color: "#2EBB55",
  },
  "& input": {
    width: "100%",
    color: "#AFAFAF",
    height: "30px",
    boxShadow: "none",
    "&:focus": {
      outline: "none",
    },
    "&:focus-within": {
      "& .MuiSvgIcon-root": {
        filter: "brightness(0) invert(1)",
      },
    },
  },
}));

export { SearchContainer, LinkHeaderBox };
