import { Box, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledMenuItem = styled(MenuItem)(({ theme, height }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  color: "#FFFFFF",
}));

const SelectMain = styled(Box)(({ theme, height }) => ({
  minWidth: 130,

  "& .select-box": {
    height: height,
    border: "1px solid #AFAFAF !important",
    padding: "8px",
    color: theme.palette.secondary.dark,
    background: "transparent",
    opacity: "999",
    "& svg": {
      fill: "#AFAFAF !important",
      fontSize: "12px",
    },

    "&.select-box.open svg": {
      transform: "rotate(90deg)",
      fontSize: "12px",
    },
  },
}));

export { SelectMain, StyledMenuItem };
