import React from "react";

import { Wrapper } from "../styles/TitleDetaisStyle";

const TitleDetaisWidgets = ({ title = "", details = "" }) => {
  return (
    <>
      <Wrapper>
        <h6>
          <span>{title}</span>
        </h6>
        {details && <p>{details}</p>}
      </Wrapper>
    </>
  );
};

export default TitleDetaisWidgets;
