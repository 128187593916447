import React, { memo, useState } from "react";
import { RemainingMain } from "./styles";
import useAuth from "../../../../context/AuthContext";

const RemainingLimits = ({ beatBoxes, RemainingLinks }) => {
  const { user } = useAuth();
  const isPlanNameAvailable = user?.planId?.planName;
  const IsFree =
    isPlanNameAvailable === "Free" || isPlanNameAvailable === "Freemium";

  return (
    <>
      <RemainingMain>
        {IsFree ? `Remaining Inboxes : ${3 - beatBoxes?.length}` : null}
      </RemainingMain>
    </>
  );
};

export default memo(RemainingLimits);
