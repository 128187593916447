import { Box, Button } from "@mui/material";

import { styled } from "@mui/material/styles";

export const LoadingContentWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: "30px",
  position: "relative",
}));

export const LoadingDetailsWrapper = styled(Box)(({ theme }) => ({
  width: "340px",
  background: "red",
  position: "relative",

  div: {
    top: "0",
    position: "absolutes",
  },
  img: {
    position: "absolute",
    display: "block",
  },
}));

export const DetailsWrapperFlex = styled(Box)(({ theme }) => ({
  width: "570px",

  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: "12px",
}));
export const DetailsWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "40px 60px",
  borderRadius: "10px",
  background: "#141515",
  textAlign: "center",

  h5: {
    color: "#E3E3E3",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textAlign: "center",
    margin: "0 0 12px 0",
  },

  p: {
    color: "#AFAFAF",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    width: "100%",
    margin: "0 0 40px 0",
  },
}));

export const InputFieldWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "48px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "15px",

  input: {
    background: "#323333",
    border: "1px solid #AFAFAF",
    display: "block",
    width: 480,
    padding: "15px",
    height: "100%",
    outline: "none",
    borderRadius: 5,
    color: "white",
  },
}));

export const CopyButton = styled(Button)(({ theme }) => ({
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  width: "70px",
  height: "48px",
  borderRadius: 5,
  display: "flex",
  gap: "5px",
  background: "#2EBB55",
  border: "none",
  outline: "none",
}));

export const SocialTray = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "40px",
}));

export const IconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "18px",
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",

  img: {
    width: "60px",
    height: "60px",
  },
}));

export const ProfileHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "18px",

  "& .user": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "18px",
  },

  div: {
    marginLeft: 0,
    h6: {
      color: "#E3E3E3",
      fontFamily: "Poppins",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      margin: "0 0 5px 0",
    },
    p: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      color: "#AFAFAF",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      margin: 0,
      textAlign: "left",
    },
  },

  img: {
    width: "80px",
    height: "80px",
  },
}));

export const ProfileLink = styled(Box)(({ theme }) => ({
  margin: "18px 0 24px 0",
  h5: {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    margin: "0",
    textAlign: "left",
  },
  p: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    textAlign: "left",
  },
}));

export const CoverImage = styled(Box)(({ theme }) => ({
  width: "100%",
  img: {
    width: "100%",
    display: "block",
    objectFit: "cover",
  },

  "&::after": {
    content: "''",
    display: "block",
    width: "100%",
    height: "1px",
    background: "#263238",
    marginTop: "24px",
  },
}));

export const ReactionIcons = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 18,
}));

export const ReactIconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#AFAFAF",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
}));
