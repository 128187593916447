import React from "react";
import styles from "./dashboard.module.css";
import DashboardHeader from "../../components/DashboardComponents/DashboardHeader";

const TempDashboard = () => {
  return (
    <div className={styles.main_layout}>
      <div className={styles.background_image}>
        <img
          className={styles.dashboard_header_image}
          alt="header"
          src="/v2Assets/dashboard_assets/Dashboard_Header.png"
        ></img>
      </div>
    </div>
  );
};

export default TempDashboard;
