import React from "react";
import { Box } from "@mui/material";
import { SliderMain } from "./styles/Bannersliderstyle";
import Grid from "@mui/material/Grid";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Serv1,
  Serv2,
  Serv3,
  Serv4,
  Serv5,
  Serv6,
} from "../../assets/smartlink";

const Services = [
  {
    img: Serv1,
  },
  {
    img: Serv2,
  },
  {
    img: Serv3,
  },
  {
    img: Serv4,
  },
  {
    img: Serv5,
  },
  {
    img: Serv6,
  },
];

const BannerSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <SliderMain>
      <Box className="slidermain">
        <Grid columns={9} container spacing={2}>
          <Grid item xs={9} xl={2}>
            <h1 className="bannerSliderhead">Who We Replace</h1>
          </Grid>
          <Grid
            item
            xs={9}
            xl={7}
            sx={{
              marginTop: "50px",
            }}
            className="slider-grid"
          >
            <div>
              <Slider {...settings}>
                {Services.length > 0 &&
                  Services.map((item, index) => {
                    return (
                      <div key={index + 1}>
                        <img src={item.img} alt={`Serv${index}.svg`} />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </Grid>
        </Grid>
      </Box>
    </SliderMain>
  );
};

export default BannerSlider;
