import moment from "moment";
import { get } from "lodash";

import styles from "./profile.module.css";
import { formatCount } from "../../shared/funs";

function ProfileAboutComponent({ profile }) {
  return (
    <div className={styles.profile_top_information}>
      <p className={styles.about_title}>About {profile.role}</p>
      <div className={styles.green_line}></div>
      <div className={styles.column_information}>
        <div className={styles.column}>
          <p className={styles.title}>Member Since</p>
          <p className={styles.subtitle}>{moment(profile.createdAt).format('MMMM Do, YYYY')}</p>
        </div>
        <div className={styles.white_line}></div>
        <div className={styles.column}>
          <p className={styles.title}>Location</p>
          <p className={styles.subtitle}>{profile.countrycode}</p>
        </div>
        <div className={styles.white_line}></div>
        <div className={styles.column}>
          <p className={styles.title}>Monthly Listeners</p>
          <p className={styles.subtitle}>{formatCount(get(profile,"noOfMonthlyListeners","N/A"),true)}</p>
        </div>
        <div className={styles.white_line}></div>
        <div className={styles.column}>
          <p className={styles.title}>Label Affiliation</p>
          <p className={styles.subtitle}>{profile.label}</p>
        </div>
      </div>
    </div>
  );
}

export default ProfileAboutComponent;
