import React, { useEffect, useState } from 'react';
import useAuth from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import styles from '../auth_pages.module.css';
// import { writeProfile } from "../../../V2BackendComponents/Mutations/profile_mutations/ProfileMutations";
// import { useMutation } from "@apollo/client";

import AccountType from './SignUpForms/AccountType';
import { toast } from 'react-toastify';
import SelectMembership from './SelectMembership'
import Payment from '../signup_flow/SignUpForms/Payment'


const music_producer_stages = [

  { component: SelectMembership, stage: 7 },
  { component: Payment, stage: 8 },

];

const SignUpForm = ({ stages, completeAccount, setCompleteAccount , approve }) => {
  console.log("approve",approve)
  const { setProfile, user, loginMutation } = useAuth();
  const [newUser, setNewUser] = useState({ ...user });
  const [currentStage, setCurrentStage] = useState(0);
  const navigate = useNavigate();

  const [signUpStage, setSignUpStage] = useState(
    stages ? stages : [{ component: AccountType, stage: 1 }]
  );

  useEffect(()=>{
    if(approve==='true' ||approve=== true){
      setSignUpStage(stages ? stages : [{ component: SelectMembership, stage: 7 },{ component: Payment, stage: 8 }])
    } else{
      setSignUpStage(stages ? stages : [{ component: AccountType, stage: 1 }])
    }
  },[])


  // const [updateProfile] = useMutation(writeProfile, {
  //   context: { clientName: "opportunities" },
  //   fetchPolicy: "no-cache",
  //   onCompleted(data) {
  //     const { upsertProfile } = data;
  //     if (upsertProfile) {
  //       setProfile(upsertProfile);
  //       setCompleteAccount(false);
  //       upsertProfile.role === "Music Producer" &&
  //       !newUser?.subscription?.isPaid &&
  //       !upsertProfile.verified
  //         ? navigate("/select_membership")
  //         : navigate("/dashboard");
  //     }
  //   },
  //   onError(err) {
  //     console.error(err);
  //     toast.error("failed to login" + err);
  //   },
  // });

  

  const handleNext = (updatedUser) => {
    console.log("updated user", updatedUser )
    if (currentStage === signUpStage.length - 1) {
      updatedUser.profile.joinDate = new Date().toISOString();
      updatedUser.profile.isSetup = true;
      updatedUser.profile.verified = false;
      updatedUser.profile.active = true;
      updatedUser.profile._id = updatedUser.credentials?._id || updatedUser._id ;
      // updateProfile({ variables: { profile: updatedUser.profile } });
      return;
    }

    setCurrentStage((prevStage) => prevStage + 1);
    console.log('first', updatedUser);
    setNewUser(updatedUser);
    
  };

  const handleBack = () => {
    setCurrentStage((prevStage) => (prevStage > 0 ? prevStage - 1 : prevStage));
  };

  const Stage = signUpStage[currentStage].component;

  return (
    <>
      <Stage
        onNext={handleNext}
        user={newUser}
        setUser={setNewUser}
        onBack={handleBack}
        completeAccount={completeAccount}
        setSignUpStage={setSignUpStage}
      />
    </>
  );
};

export default SignUpForm;
