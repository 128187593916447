import React, { useContext, useState, useEffect } from "react";

const SpotifyApiContext = React.createContext();

export function useSpotify() {
  const spotifyContext = useContext(SpotifyApiContext);

  if (!spotifyContext) {
    throw new Error(
      "useSpotify must be used within a SpotifyProvider. Make sure to wrap your component with <SpotifyProvider>."
    );
  }

  return spotifyContext;
}

export function SpotifyProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [artistData, setArtistData] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    async function getToken() {
      const clientId = "1630433aaf7d4efd880ae1af2a2a7c5c";
      const clientSecret = "364e433bd9de47588cd035eab0de2dad";

      const response = await fetch("https://accounts.spotify.com/api/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic " + btoa(clientId + ":" + clientSecret),
        },
        body: new URLSearchParams({
          grant_type: "client_credentials",
        }),
      });

      const data = await response.json();
      setAuthToken(data.access_token);
    }

    getToken();
  }, []);

  async function getArtist(artistId) {
    if (!authToken) {
      return; // or handle this situation differently
    }

    try {
      const response = await fetch(
        `https://api.spotify.com/v1/artists/${artistId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setArtistData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  }

  const value = {
    loading,
    artistData,
    getArtist,
  };

  return (
    <SpotifyApiContext.Provider value={value}>
      {children}
    </SpotifyApiContext.Provider>
  );
}
