import React from "react";
import { Heading, Box, Text, VStack } from "native-base";
function FormTitle(props) {
  const verticalSpace = props.mb ? 2 : props.subtitle === "I’m a/an" ? 2 : 1;
  return (
    <Box mb={{ lg: 10, base: 5 }} textAlign={props.textCenter ? "center" : ""}>
      <VStack space={verticalSpace}>
        <Heading
          fontFamily="poppins"
          fontSize={{ lg: "40px", md: "2xl", sm: "lg", base: "1.1rem" }}
          fontWeight="400"
          textTransform={
            props.title === "Welcome to United Market" ? "" : "capitalize"
          }
          textAlign={{ md: "start", base: "left" }}
          color={"#fff"}
        >
          {props.title}
        </Heading>
        {(props.isMobile || !props.isMobile) && (
          <Text
            textAlign={{
              md: props.subtitle === "I’m a/an" ? "left" : "left",
              base: "left",
            }}
            fontFamily="poppins"
            color={props.subtitle === "I’m a/an" ? "#E3E3E3" : "#AFAFAF"}
            fontSize={{
              lg: props.subtitle === "I’m a/an" ? "24px" : "18px",
              md: "lg",
              sm: "md",
              base: "1rem",
            }}
            pt={props.subtitle === "I’m a/an" ? 25 : 0}
            fontWeight="normal"
            margin="0"
          >
            {props.subtitle}
          </Text>
        )}
      </VStack>
    </Box>
  );
}

export default FormTitle;
