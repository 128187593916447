import React from "react";
import { useEffect } from "react";
import styles from "./Popup.module.css";

const ViewCollaboratorsPopup = ({ collaborators, isOpen, onClose }) => {
  useEffect(() => {
    const handleClose = (e) => {
      if (e.target.className === styles.fullscreen_modal) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClose);

    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className={styles.fullscreen_modal}>
      <div className={styles.modal_body}>
        <div className={styles.modal_header}>
          <p className={styles.modal_title}>Collaborators On Track</p>
        </div>
        <div className={styles.collaborator_section}>
          <div className={styles.line_divider}></div>
          <p className={styles.section_title}>Collaborators</p>
          <div className={styles.collaborator_list}>
            {collaborators?.map((collaborator, index) => (
              <div key={`viewcollabX-${index}`} className={styles.collaborator_item}>
                <p className={styles.collaborator_text}>{collaborator.name}</p>
                <p className={styles.collaborator_text}>
                  {collaborator.publishingPercentage}%
                </p>
              </div>
            ))}
          </div>
          <div className={styles.line_divider}></div>
        </div>
        <div className={styles.modal_footer} onClick={onClose}>
          <div className={styles.close_button}>Close</div>
        </div>
      </div>
    </div>
  );
};

export default ViewCollaboratorsPopup;
