import React from "react";
import useAuth from "../../../../context/AuthContext";
import {
  payment_free,
  pro_payment,
  elite_payment,
} from "../../../../assets/smartlink";
import { PaymentMain } from "./styles";

const MembershipBadge = () => {
  const { user } = useAuth();
  const isPlanNameAvailable = user?.planId?.planName;

  const FreeMem =
    isPlanNameAvailable === "Free" || isPlanNameAvailable === "Freemium";

  // const PlanImage =
  //   isPlanNameAvailable === "Free" || isPlanNameAvailable === "Freemium"
  //     ? payment_free
  //     : isPlanNameAvailable === "Pro"
  //     ? pro_payment
  //     : isPlanNameAvailable === "Elite"
  //     ? elite_payment
  //     : isPlanNameAvailable === "HitMaker"
  //     ? elite_payment
  //     : null;

  const UserPlan = user?.planId?.planName;

  const ShowPlanName =
    UserPlan === "Monthly Plan"
      ? "Monthly"
      : UserPlan === "Yearly Plan"
      ? "Yearly"
      : UserPlan === "Freemium"
      ? "Free"
      : UserPlan;

  return (
    <>
      {isPlanNameAvailable && FreeMem && (
        <PaymentMain
          className={`${
            isPlanNameAvailable === "Freemium" ? "badge_freemium" : ""
          }`}
        >
          {/* {PlanImage && <img src={PlanImage} alt={isPlanNameAvailable} />} */}
          <span>{ShowPlanName}</span>
        </PaymentMain>
      )}
    </>
  );
};

export default MembershipBadge;
