import React from "react";

function DiscordIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4894 2.00999C24.4824 1.071 22.3365 0.388577 20.093 0C19.8175 0.498142 19.4956 1.16815 19.2737 1.70115C16.8888 1.34249 14.5258 1.34249 12.1848 1.70115C11.9629 1.16815 11.6337 0.498142 11.3557 0C9.10981 0.388577 6.96144 1.07351 4.9545 2.01497C0.906497 8.13203 -0.19085 14.0972 0.357824 19.9776C3.04267 21.9826 5.64462 23.2005 8.20265 23.9975C8.83424 23.1283 9.39754 22.2042 9.8828 21.2304C8.9586 20.8792 8.07341 20.4459 7.23701 19.9427C7.4589 19.7783 7.67595 19.6065 7.88564 19.4296C12.9871 21.8157 18.5299 21.8157 23.5704 19.4296C23.7826 19.6065 23.9996 19.7783 24.2191 19.9427C23.3802 20.4483 22.4926 20.8817 21.5684 21.2329C22.0536 22.2042 22.6145 23.1308 23.2485 24C25.809 23.203 28.4134 21.9851 31.0982 19.9776C31.742 13.1607 29.9985 7.25033 26.4894 2.00999ZM10.5778 16.3612C9.0464 16.3612 7.79053 14.9315 7.79053 13.1906C7.79053 11.4496 9.01958 10.0175 10.5778 10.0175C12.1361 10.0175 13.3919 11.4471 13.3651 13.1906C13.3675 14.9315 12.1361 16.3612 10.5778 16.3612ZM20.8783 16.3612C19.3469 16.3612 18.091 14.9315 18.091 13.1906C18.091 11.4496 19.32 10.0175 20.8783 10.0175C22.4365 10.0175 23.6924 11.4471 23.6655 13.1906C23.6655 14.9315 22.4365 16.3612 20.8783 16.3612Z"
        fill="#AFAFAF"
      />
    </svg>
  );
}

export default DiscordIcon;
