import React, { useEffect, useState } from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  BeatDelete,
  CreateBeatInboxPopup,
  HorizontalMenu,
  MainTitle,
  PaginationMain,
} from "../../../components/smartlink";
import { image_placeholder_icon } from "../../../assets/smartlink";
import { GetAllDeletedBeatBoxes } from "../../../Graphql_services/beatinbox/Beats";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import BugsPopup from "../../../components/smartlink/producers/producerssubmit/popups/BugsPopup";

const BeatDeletePage = () => {
  const [Bugopen, setBugopen] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [open, setOpen] = useState(false);

  const { loading, error, data, refetch } = useQuery(GetAllDeletedBeatBoxes, {
    context: { clientName: "beats" },
  });

  const createModelOpen = () => {
    setOpen(!open);
  };

  const deletedBoxes = data?.getDeletedBeatBoxes?.beatBoxes;

  const totalPages = data?.getDeletedBeatBoxes?.totalPages;

  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (error) {
      setBugopen(true);
    }
  }, [error]);

  return (
    <>
      <SmartLinkLayout>
        <MainTitle
          title="Beat Inbox"
          hasbutton={true}
          isBeatinbox={true}
          openCreateBeatPopup={createModelOpen}
        />
        <HorizontalMenu isBeatInbox={true} />
        <BeatDelete data={deletedBoxes} loading={loading} />

        {totalPages > 1 && (
          <>
            <Box sx={{ marginBottom: "1rem" }}>
              <PaginationMain
                pageCount={totalPages}
                page={currentPage}
                handelChange={handlePageChange}
              />
            </Box>
          </>
        )}
        <CreateBeatInboxPopup
          open={open}
          setOpen={setOpen}
          image={image_placeholder_icon}
        />
        <BugsPopup open={Bugopen} setOpen={setBugopen} />
      </SmartLinkLayout>
    </>
  );
};

export default BeatDeletePage;
