import React from 'react'
import { DetailsWrapper, IconBox, SocialTray } from '../style'

const facebook = require('./../../../../../../../assets/Images/facebook.png');
const instagram = require('./../../../../../../../assets/Images/instagram.png');
const twitter = require('./../../../../../../../assets/Images/twitter.png');
const mail = require('./../../../../../../../assets/Images/mail.png');

const ShareDetails = () => {
  return (
    <DetailsWrapper>
      <h5>Share Now Directly To Social Channels</h5>
      <SocialTray>
        <IconBox>
          <img src= {facebook}/>
          Facebook
        </IconBox>
        <IconBox>
          <img src= {twitter}/>
          X
        </IconBox>
        <IconBox>
          <img src= {instagram}/>
          Instagram
        </IconBox>
        <IconBox>
          <img src= {mail}/>
          Mail
        </IconBox>
      </SocialTray>
    </DetailsWrapper>
  )
}

export default ShareDetails