import { Box, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

const MenuItemBox = styled(MenuItem)(({ theme, height }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "20px ",
  gap: "18px",
  "& .MuiMenuItem-root": {
    background: "none",
    "&:hover": {
      background: "transparent",
    },
  },
}));

const SelectMain = styled(Box)(({ theme, height }) => ({
  "& .select-box": {
    height: height,
    border: "1px solid #AFAFAF",
    background: "red",
    color: theme.palette.secondary.dark,
    "& .MuiSvgIcon-root": {
      fill: theme.palette.secondary.dark,
    },

    "&.open svg": {
      transform: "rotate(90deg)",
    },
    "& .custom-menu-paper": {
      padding: "2rem",
    },
  },
}));
const MatrixBox = styled(Box)(({ theme, height }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 12px",
  height: "height",
  borderRadius: "5px",
  border: "1px solid #AFAFAF",
  gap: "5px",
  color: "#AFAFAF",
  fontFamily: theme.typography.fontFamily,
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
}));
const ButtonBox = styled(Box)(({ theme, height }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "20px",
  marginRight: "10px",
  height: height,
  "& .btn": {
    padding: "8px 24px",
    background: "#2EBB55",
    borderRadius: "3px",
    color: "#FFFFFF",
    textTransform: "capitalize",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    "&:hover": {
      background: "#2EBB55",
    },
  },
}));

export { SelectMain, MatrixBox, MenuItemBox, ButtonBox };
