import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Wrapper = styled(Box)(({ theme }) => ({
  paddingTop: "120px",
  background: "#141515",
}));
const CardWrapper = styled(Box)(({ theme }) => ({
  width: "91.5%",
  margin: "0 auto",
  "& .image_wrapper": {
    position: "relative",
    perspective: "1000px",
    transition: "transform 0.9s",
    transformStyle: "preserve-3d",
    "& .main_image": {
      width: "100%",
      height: "auto",
      backfaceVisibility: "hidden",
      objectFit: "cover",
      overflow: "hidden",
    },
    "&:hover": {
      transform: "rotateY(180deg)",
    },
  },
  "& .image_hover_wrapper": {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "10px",
    background: "#1C1B1B",
    padding: "30px 20px",
    transform: "rotateY(180deg)",
    backfaceVisibility: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .quoteup": {
      height: "auto",
      width: "30px",
      marginBottom: "10px",
    },
    h5: {
      margin: 0,
      marginTop: "15px",
      color: "#FFF",
      fontFamily: '"Poppins", sans-serif',
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "16px",
      marginBottom: "18px",
    },
    p: {
      color: "#FFF",
      textAlign: "justify",
      fontFamily: '"Poppins", sans-serif',
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      marginBottom: "20px",
    },
    h6: {
      textAlign: "right",
      color: "#AFAFAF",
      textAlign: "right",
      fontFamily: '"Poppins", sans-serif',
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      margin: 0,
    },
  },
}));

export { Wrapper, CardWrapper };
