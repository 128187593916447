import { gql } from "@apollo/client";

export const CreateBeatsMutation = gql`
  mutation CreateBeatBox(
    $title: String!
    $description: String!
    $image: String
    $isPublic: Boolean!
    $isBeat: Boolean!
    $spotifyUrl: String
    $instagramUrl: String
    $password: String
    $musicianName: String
  ) {
    createBeatBox(
      title: $title
      description: $description
      image: $image
      isPublic: $isPublic
      isBeat: $isBeat
      spotifyUrl: $spotifyUrl
      instagramUrl: $instagramUrl
      password: $password
      musicianName: $musicianName
    ) {
      beatBox {
        _id
        userId {
          _id
          firstname
          lastname
          profilePic
        }
        title
        totalBeats
        description
        image
        isDeleted
        createdAt
        updatedAt
        isPublic
        beats {
          _id
        }
        beatboxShareLink
        selectedInbox
        spotifyUrl
        instagramUrl
        isBeat
        beatInboxPassword
      }
      success
      message
    }
  }
`;

export const UPDATE_BEAT_INBOX = gql`
  mutation UpdateBeatBox(
    $title: String!
    $description: String!
    $inboxId: String
    $image: String
    $isPublic: Boolean
    $spotifyUrl: String
    $instagramUrl: String
    $isBeat: Boolean
    $password: String
    $musicianName: String
  ) {
    updateBeatBox(
      title: $title
      description: $description
      inboxId: $inboxId
      image: $image
      isPublic: $isPublic
      spotifyUrl: $spotifyUrl
      instagramUrl: $instagramUrl
      isBeat: $isBeat
      password: $password
      musicianName: $musicianName
    ) {
      success
      message
    }
  }
`;

export const GetAllActiveBeatBoxes = gql`
  query Query($page: Int!, $perPage: Int!) {
    getActiveBeatBoxes(page: $page, perPage: $perPage) {
      success
      message
      totalPages
      beatBoxes {
        _id
        userId {
          _id
          firstname
          lastname
          profilePic
        }
        title
        totalBeats
        description
        image
        isDeleted
        createdAt
        updatedAt
        isPublic
        beats {
          _id
        }
        beatboxShareLink
        selectedInbox
        spotifyUrl
        instagramUrl
        isBeat
        beatInboxPassword
        musicianName
      }
      remainingBeatInbox
    }
  }
`;

export const DELETE_BEAT_Box_MUTATION = gql`
  mutation DeleteBeatBox($id: ID!) {
    deleteBeatBox(id: $id) {
      success
      message
    }
  }
`;

// get all deleted beat inbox

export const GetAllDeletedBeatBoxes = gql`
  query GetActiveBeatBoxes {
    getDeletedBeatBoxes {
      success
      message
      totalPages
      beatBoxes {
        _id
        title
        description
        image
        isDeleted
        remainingDays
        createdAt
        updatedAt
      }
    }
  }
`;

// recover deleted beats inbox

export const Recover_BEAT_Box_MUTATION = gql`
  mutation RecoverBeatInbox($id: ID!, $status: Boolean!) {
    recoverBeatInbox(id: $id, status: $status) {
      success
      message
    }
  }
`;

//  get the all inbox beats

export const GetAllinboxBeats = gql`
  query GetBeats(
    $inboxId: ID!
    $page: Int!
    $perPage: Int!
    $status: listStatus
    $splitPriceStatus: status
  ) {
    getBeats(
      inboxId: $inboxId
      page: $page
      perPage: $perPage
      status: $status
      splitPriceStatus: $splitPriceStatus
    ) {
      success
      message
      totalBeats
      totalPages
      allDownloadAbleBeats {
        _id
        audioFileId {
          _id
          url
          fileName
          typeFile
        }
      }
      beats {
        _id
        inboxId {
          _id
          userId
          title
        }
        beatExclusivePrice
        beatNonExclusivePrice
        splitPriceStatus
        tone
        audioFileId {
          _id
          url
          fileName
          typeFile
        }

        publishingSplit
        tone
        musicianName
        beatExclusivePrice
        beatPitchingPeriod
        collaborators
        containsMasterSample
        optInPolicy
        publishingAgreement
        collaboratorsDetails
        noOfMusicians
        isDeleted
        updatedAt
        createdAt
        remainingDays
        isFavourite
        userName
        profilePic
        isBeat
        userId
        createrId
      }
    }
  }
`;

// Add into the select inbox

export const ADDToSelectBeat = gql`
  mutation addSelectedBeats($id: ID!) {
    addSelectedBeats(id: $id) {
      success
      message
    }
  }
`;

// add to the ADDToFavouriteBeats

export const ADDToFavouriteBeats = gql`
  mutation CreateBeatBox($id: ID!) {
    addFavouriteBeats(id: $id) {
      success
      message
    }
  }
`;

// get all Favourite Beats

export const GetAllFavouriteBeats = gql`
  query GetFavouriteBeats {
    getFavouriteBeats {
      success
      message
      beats {
        _id
        inboxId {
          _id
          title
        }
        userId
        filename
        audioFileId {
          _id
          url
          typeFile
          fileName
        }
        publishingSplit
        tone
        beatExclusivePrice
        beatPitchingPeriod
        filename
        collaborators
        collaboratorsDetails
        noOfMusicians
        optInPolicy
        publishingAgreement
        containsMasterSample
        remainingDays
      }
    }
  }
`;

// get the single inbox data

export const GET_BEATBOX_BY_ID = gql`
  query GetBeatBoxById($id: ID!) {
    getBeatBoxById(id: $id) {
      success
      message
      beatBox {
        _id
        title
        description
        userId {
          _id
          firstname
          lastname
          profilePic
        }
        image
        isDeleted
        createdAt
        updatedAt
        spotifyUrl
        instagramUrl
        isBeat
        isPublic
        musicianName
      }
    }
  }
`;

// add the beats

export const ADD_BEAT_MUTATION = gql`
  mutation addBeat(
    $inboxId: ID!
    $audioFileId: ID!
    $publishingSplit: Float!
    $tone: String!
    $beatExclusivePrice: Float
    $beatNonExclusivePrice: Float
    $beatPitchingPeriod: Int
    $filename: String!
    $collaborators: CollaboratorsEnum
    $containsMasterSample: ContainsMasterSampleEnum
    $noOfMusicians: Int!
    $collaboratorsDetails: JSON!
    $phoneno: Float
    $email: String
    $musicianName: String
    $createrId: ID
  ) {
    addBeat(
      inboxId: $inboxId
      audioFileId: $audioFileId
      publishingSplit: $publishingSplit
      tone: $tone
      beatExclusivePrice: $beatExclusivePrice
      beatNonExclusivePrice: $beatNonExclusivePrice
      beatPitchingPeriod: $beatPitchingPeriod
      filename: $filename
      collaborators: $collaborators
      containsMasterSample: $containsMasterSample
      noOfMusicians: $noOfMusicians
      collaboratorsDetails: $collaboratorsDetails
      phoneno: $phoneno
      email: $email
      musicianName: $musicianName
      createrId: $createrId
    ) {
      success
      message
    }
  }
`;

// update the beat inbox link

export const UPDATE_BEATBOX_SHARE_LINK = gql`
  mutation UpdateBeatBoxShareLink($inboxId: ID!, $beatboxShareLink: String) {
    updateBeatBoxShareLink(
      inboxId: $inboxId
      beatboxShareLink: $beatboxShareLink
    ) {
      success
      message
      beatboxShareLink
    }
  }
`;

// update the beat inbox type

export const UPDATE_BEATBOX_INBOX_TYPE = gql`
  mutation updateInboxType($inboxType: String) {
    updateInboxType(inboxType: $inboxType) {
      success
      message
      inboxType
    }
  }
`;

// contact us

export const CONTACT_US = gql`
  mutation ContactUs(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $text: String!
  ) {
    contactUs(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      text: $text
    ) {
      success
      message
    }
  }
`;

export const CONFIRM_BEATBOX_PASSWORD = gql`
  mutation ConfirmBeatBoxPassword($inboxId: String, $password: String) {
    confirmBeatBoxPassword(inboxId: $inboxId, password: $password) {
      success
      message
    }
  }
`;

export const UPDATE_COLLABORATOR_STATUS = gql`
  mutation addBeatInboxCollaborator($email: String, $link: String) {
    addBeatInboxCollaborator(email: $email, link: $link) {
      success
      message
    }
  }
`;

// downlaod inbox

export const DOWNLOAD_SINGLE_BEAT = gql`
  query Query($downloadBeatId: ID!) {
    downloadBeat(id: $downloadBeatId) {
      success
      message
    }
  }
`;

// ********** To get all notification **********
export const GET_NOTIFICATIONS = gql`
query(
  $page: Float
  $pageSize: Float
  $notification_type: String
  $status: Boolean
  $from: ISODate
  $to: ISODate
  $searchQuery: String
  $notification_status:String
) {
  getAllNotifications(
    page: $page
    pageSize: $pageSize
    notification_type: $notification_type
    searchQuery: $searchQuery
    status: $status
    from: $from
    to: $to
    notification_status:$notification_status
  ) {
    items {
      _id
      title
      notification_type
      description
      link
      is_read
      created_by {
        _id
        userName
        profilePic
      }
      created_at
      updated_at
      errorMessage
    }
    currentPage
    nextPage
    lastPage
    hasNextPage
    hasPreviousPage
    totalItems
    totalNotificationCount
    successNotificationCount
    failNotificationCount
  }
}
`;
// ********** To get export notification **********
export const EXPORT_NOTIFICATIONS = gql`
query(
  $notification_type: String
  $status: Boolean
  $from: ISODate
  $to: ISODate
  $searchQuery: String
) {
  getExportedNotifications(
    notification_type: $notification_type
    searchQuery: $searchQuery
    status: $status
    from: $from
    to: $to
  ) {
    success
    message
    items {
      _id
      errorMessage
      title
      notification_type
      description
      link
      is_read
      created_by {
        _id
        userName
        profilePic
      }
      created_at
      updated_at
    }

  }
}
`;
