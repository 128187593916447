import React, { useState, useContext } from "react";
import PhoneInput from "react-phone-input-2";
import { AuthContext } from "../Layout";
import { useBreakpointValue } from "native-base";
import "./../Form.css";

import "react-phone-input-2/lib/style.css";

function CountryCodes(props) {
  const { setphonenum, phonenum } = props;
  const height = useBreakpointValue({
    xl: "60px",
    base: "45px",
  });
  const heightDropDown = useBreakpointValue({
    lg: "150px",
    base: "107px",
  });

  const fontsize = useBreakpointValue({
    base: {
      fontSize: "14px",
    },

    md: {
      fontSize: "16px",
    },
    lg: {
      fontSize: "18px",
    },
  });
  function handleOnChange(value, data, formattedValue) {
    // console.log(value,data)
    // console.log(data.dialCode.length)
    // setCountrycode(data.dialCode)

    // setphonenum(value.split("").splice(data.dialCode.length,value.length).join(""))
    setphonenum(value);
    // console.log("dialcode is",value.split("").splice(data.dialCode.length,value.length).join(""))
    // this.setState({ rawPhone: value.slice(data.dialCode.length) })
  }
  return (
    <div className={props.type === "phone" ? "dropdownSignIn" : "dropdown"}>
      {/* <PhoneInput
      inputStyle={{width:"100%", height:"60px", backgroundColor:"transparent",border:"1px solid rgb(175, 175, 175)",fontSize:"1.4rem",color:"#AFAFAF",fontFamily:"popins"}}
      dropdownStyle={{width:"200px"}}

    
      
  country={'us'}
  value={phonenum.phone}
  onChange={handleOnChange}
/> */}

      <PhoneInput
        inputClass="phoneinput"
        inputStyle={{
          width: "100%",
          height: height,
          backgroundColor: "transparent",
          border: "1px solid rgb(175, 175, 175)",
          fontSize: fontsize.fontSize,
          color: "#AFAFAF",
          fontFamily: "poppins",
          paddingLeft: "60px",
        }}
        dropdownStyle={{ width: "200px", height: heightDropDown }}
        country="us"
        value={phonenum}
        onChange={handleOnChange}
      />
      {/* <Select backgroundColor={"#555555"}  color={"#AFAFAF"}   selectedValue={countrycode} width="100px"   _ mt={1} onValueChange={itemValue => setCountrycode(itemValue)} >
      <Select.Item label="+92" value="92"  onValueChange={itemValue => setCountrycode(itemValue)}  />
      <Select.Item label="+21" value="21"  onValueChange={itemValue => setCountrycode(itemValue)}  />
      <Select.Item label="+35" value="35"  onValueChange={itemValue => setCountrycode(itemValue)}  />
      <Select.Item label="+45" value="45"  onValueChange={itemValue => setCountrycode(itemValue)}  />
      <Select.Item label="+91" value="91"  onValueChange={itemValue => setCountrycode(itemValue)}  />
    </Select> */}
    </div>
  );
}

export default CountryCodes;
