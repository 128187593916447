const audioTracks = [
  {
    title: "Kabhi Jo Badal Barse",
    artist: "Artist 1",
    color: "#00aeb0",
    image: "path/to/image1.jpg",
    audioSrc: "Audio1",
  },
  {
    title: "TUMHE APNA BANANE KA",
    artist: "Artist 2",
    color: "#ff0000",
    image: "path/to/image2.jpg",
    audioSrc: "Audio2",
  },
];

export default audioTracks;
