import React, { useEffect } from 'react';
import styles from '../../auth_pages.module.css';
import EmailAndPassword from './EmailAndPassword';
import FirstLastDisplayName from './FirstLastDisplayName';
import UserInformation from './UserInformation';
import ConnectAccounts from './ConnectAccounts';
import ChooseGenre from './ChooseGenre';
import PublishWithUs from './PublishWithUs';
import LabelName from './LabelName';
import PartOfLabel from './PartOfLabel';
import ProfilePic from './ProfilePic';
import { GetRoles } from '../../../../Graphql_services/SignUpMutation';
import { useQuery } from '@apollo/client';
import Finished from './Finished';
import Payment from './Payment';
import SelectMembership from '../SelectMembership';
import ProducerProfilePic from './ProducerProfilePic';

const AccountType = ({ onNext, user, completeAccount, setSignUpStage }) => {
  const [canContinue, setCanContinue] = React.useState(true);
  const [accountType, setAccountType] = React.useState(null);
  const [accountList, setaccountList] = React.useState([]);
  const { loading, error, data } = useQuery(GetRoles);

  // ------ get the user role from the API

  useEffect(() => {
    if (data) {
      const rolesWithoutAdmin = data.getRoles?.filter(
        (role) => role.roleName.toLowerCase() !== 'admin'
      );
      setaccountList(rolesWithoutAdmin || []);
    }
  }, [data]);

  // ------ End get the user role from the API

  const music_producer_stages = [
    { component: AccountType, stage: 1 },
    { component: EmailAndPassword, stage: 2 },
    { component: FirstLastDisplayName, stage: 3 },
    { component: UserInformation, stage: 4 },
    // { component: ConnectAccounts, stage: 5 },
    { component: ChooseGenre, stage: 5 },
    { component: ProducerProfilePic, stage: 6 },
    // { component: PublishWithUs, stage: 8 },
    { component: SelectMembership, stage: 7 },
    { component: Payment, stage: 8 },
    // { component: Finished, stage: 10 },
  ];

  const artist_stages = [
    { component: AccountType, stage: 1 },
    { component: EmailAndPassword, stage: 2 },
    { component: FirstLastDisplayName, stage: 3 },
    { component: ChooseGenre, stage: 4 },
    { component: UserInformation, stage: 5 },
    // { component: ConnectAccounts, stage: 6 },
    { component: ProfilePic, stage: 6 },
    // { component: Finished, stage: 7 },
    { component: SelectMembership, stage: 7 },
    { component: Payment, stage: 8 },
  ];

  const manager_stages = [
    { component: AccountType, stage: 1 },
    { component: EmailAndPassword, stage: 2 },
    { component: FirstLastDisplayName, stage: 3 },
    { component: PartOfLabel, stage: 4 },
    { component: ProfilePic, stage: 5 },
    // { component: Finished, stage: 6 },
    { component: SelectMembership, stage: 6 },
    { component: Payment, stage: 7 },
  ];

  const label_stages = [
    { component: AccountType, stage: 1 },
    { component: EmailAndPassword, stage: 2 },
    { component: LabelName, stage: 3 },
    { component: ProfilePic, stage: 4 },
    // { component: Finished, stage: 5 },
    { component: SelectMembership, stage: 5 },
    { component: Payment, stage: 6 },
  ];

  const a_and_r_stages = [
    { component: AccountType, stage: 1 },
    { component: EmailAndPassword, stage: 2 },
    { component: FirstLastDisplayName, stage: 3 },
    { component: PartOfLabel, stage: 4 },
    // { component: UserInformation, stage: 5 },
    { component: ProfilePic, stage: 5 },
    // { component: Finished, stage: 6 },
    { component: SelectMembership, stage: 6 },
    { component: Payment, stage: 7 },
  ];

  const handleTypeSelect = (item) => {
    setAccountType(item);
    switch (item.roleName) {
      case 'Music Producer':
        setSignUpStage(music_producer_stages);
        break;
      case 'Artist':
        setSignUpStage(artist_stages);
        break;
      case 'Manager':
        setSignUpStage(manager_stages);
        break;
      case 'Label':
        setSignUpStage(label_stages);
        break;
      case 'A & R':
        setSignUpStage(a_and_r_stages);
        break;
    }
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
        >
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}>
              <p className={styles.auth_title}> Choose Your Account Type!</p>
              <p className={styles.auth_subtitle}>I am a(n)</p>
            </div>
            <div className={styles.type_list}>
              {loading && <p style={{ color: '#fff' }}>Loading...</p>}

              {!loading && accountList.length > 0
                ? accountList.map((type, i) => {
                    if (type.roleName !== 'N/A') {
                      return (
                        <div
                          key={type._id}
                          className={
                            accountType === type
                              ? styles.account_type_option_selected
                              : styles.account_type_option
                          }
                          onClick={() => handleTypeSelect(type)}
                        >
                          <p className={styles.account_type_option_text}>
                            {type.roleName}
                          </p>
                        </div>
                      );
                    }
                  })
                : !loading && <p style={{ color: '#fff' }}>No roles found</p>}
            </div>
          </div>

          {!loading && (
            <div className={styles.buttonControls}>
              {accountType ? (
                <div
                  onClick={() => {
                    let updatedUser = { ...user };
                    let updatedProfile = { ...user.profile };
                    updatedProfile.roleID = accountType._id;
                    updatedProfile.roleName = accountType.roleName;
                    updatedUser.profile = updatedProfile;
                    onNext(updatedUser);
                  }}
                  className={styles.signin_box}
                >
                  <p className={styles.connect_text}>Continue</p>
                </div>
              ) : (
                <div className={styles.signin_box_inactive}>
                  <p className={styles.connect_text}>Continue</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountType;
