import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import ConnectSubmissionsComponent from "../../components/SubmissionsComponents/ConnectSubmissions/connectSubmissionsComponent";

const ConnectSubmissions = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<ConnectSubmissionsComponent />}
        activeText="My Submissions"
      />
    </Grid>
  );
};

export default ConnectSubmissions;
