import React from "react";
import TitleDetaisWidgets from "../Distribution/widgets/TitleDetaisWidgets";
import { Memberssection } from "./styles/Membersstyle";
import Grid from "@mui/material/Grid";
import { Logo1, Logo2, Logo3, Logo4, Logo5 } from "../../assets/smartlink";

const Logos = [
  {
    img: Logo1,
  },
  {
    img: Logo2,
  },
  {
    img: Logo3,
  },
  {
    img: Logo4,
  },
  {
    img: Logo5,
  },
];

const Members = () => {
  return (
    <>
      <TitleDetaisWidgets title="Members From Industry Leaders" details="" />
      <Memberssection>
        <Grid container columns={10} spacing={2}>
          {Logos.length > 0 &&
            Logos.map((item, index) => (
              <Grid className="LogoGrid" item key={index} xs={10} md={2}>
                {" "}
                {/* Added 'item' prop */}
                <img src={item.img} alt={`Logo ${index + 1}`} />
              </Grid>
            ))}
        </Grid>
      </Memberssection>
    </>
  );
};

export default Members;
