import { useRef, useState } from "react";
import { image2 } from "../../../../assets/smartlink";

// @mui
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import MenuPopover from "./MenuPopover";
import { AvatarMain } from "./styles";
import { useNavigate } from "react-router-dom";

// mocks_
// import account from "../../_mock/account";

// ----------------------------------------------------------------------

const userData = {
  displayName: "Jaydon Frankie",
  email: "demo@minimals.cc",
  photoURL: image2,
};
const MENU_OPTIONS = [
  // {
  //   label: "Profile",
  //   icon: "eva:person-fill",
  //   linkTo: "/user",
  // },
  {
    label: "Setting",
    icon: "eva:person-fill",
    linkTo: "/setting",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  var Nameuser = localStorage.getItem("userName");
  var profilePic = localStorage.getItem("profilePic");
  var accountType = localStorage.getItem("accountType");
  const navigate = useNavigate();

  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const Logouthandle = () => {
    setOpen(null);
    localStorage.clear();
    return navigate("/signin");
  };

  const MenuClick = (val) => {
    if (val === "Setting") {
      navigate("/settings");
      setOpen(null);
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => (theme.palette.background.default, 0.8),
            },
          }),
        }}
      >
        <AvatarMain>
          <Avatar src={profilePic || userData.photoURL} alt="photoURL" />
          <Box>
            <h6
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "10ch",
              }}
            >
              {Nameuser}
            </h6>
            <p>{accountType}</p>
          </Box>
        </AvatarMain>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          background: "#414241",
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
            // background: "#0B0E0B",
            color: "#fff",
          },
        }}
      >
        {/* <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userData && userData?.name ? userData?.name : userData.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {userData && userData?.email ? userData?.email : userData.email}
          </Typography>
        </Box> */}

        {/* <Divider sx={{ borderStyle: "dashed" }} /> */}

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              //   component={RouterLink}
              onClick={() => MenuClick(option.label)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed", background: "#fff" }} />

        <MenuItem onClick={Logouthandle} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
