import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "flex-start",
  paddingTop: "20px",
  paddingBottom: "20px",
  justifyContent: "space-between",
  // alignItems: "center",
  "& h6": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "36px",
    letterSpacing: "0em",
    textAlign: "left",
    margin: "0px 0px",
    color: "#FFFFFF",
  },
  a: {
    borderRadius: "0px",
    background: "transparent",
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "36px",
    letterSpacing: "0em",
    textAlign: "left",
    padding: "0px 0px",
    textTransform: "capitalize",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    "&:hover": { background: "transparent" },
  },
}));

export { Main };
