import React, { useEffect } from "react";
import {
    Backdrop,
    Box,
    Button, 
    Modal,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import { FormGroup, InputLabel, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const MenuProps = {
    PaperProps: {
        style: {
            width: 240,
            backgroundColor: "#1C1B1B",
            color: "#afafaf",
        },
    },
};
const UpdatePremiumModal = ({ open, onClose, opportunity }) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [isPremium, setIsPremium] = React.useState();
    const [premiumLimit, setPremiumLimit] = React.useState();
    useEffect(() => {
        setIsPremium(opportunity?.isPremium || false)
        setPremiumLimit(opportunity?.premiumLimit || 0)
    }, [opportunity, open]);
    const handleSubmit = () => {
        let token = localStorage.getItem("token");
        var config = {
          method: "patch",
          url: `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/admin/premium/${opportunity?._id}`,
          headers: { 
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
         },
          data: {
            isPremium: isPremium,
            premiumLimit: premiumLimit
          },
        };
        axios(config)
          .then(function (response) {
            console.log('acsaca', response)
            navigate('/adminpanel/analytics')
          })
          .catch(function (error) {
            console.log(error);
          });
      };
    return (
        <Modal
            open={open}
            disableAutoFocus={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            sx={{
                backdropFilter: "blur(5px)",
            }}
        >
            <Box
                disableAutoFocus
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "600px",
                    borderRadius: "30px",
                    outline: "none !important",
                    backgroundColor: "rgba(20, 20, 20, 1)",
                    border: "none",
                    borderColor: "rgba(20, 20, 20, 1)",
                    //   boxShadow: 24,
                    p: 4,
                    "@media (max-width: 600px)": {
                        width: "90vw",
                    },
                }}
            >
                <form >
                    <FormGroup>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                id="isAllowOpportunities"
                                className={styles.label}
                            >
                                Opportunity Type
                            </InputLabel>
                            <Select
                                id="isPremium"
                                label="Is Premium"
                                labelId="isPremium"
                                className={classes.selectOptions}
                                value={isPremium}
                                onChange={(e) => setIsPremium(e.target.value)}
                                style={{
                                    height: "48px",
                                    paddingTop: "16px",
                                    backgroundColor: "#000",
                                    border: "1px solid #000",
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                <MenuItem
                                    style={{ paddingLeft: "15px", display: "block" }}
                                    value={true}
                                >
                                    Premium
                                </MenuItem>
                                <MenuItem
                                    style={{ paddingLeft: "15px", display: "block" }}
                                    value={false}
                                >
                                    Regilar
                                </MenuItem>
                            </Select>
                        </FormControl>
                        {isPremium && (
                        <FormControl className={classes.formControl}>
                            <InputLabel className={styles.label}>Subbmisions limit</InputLabel>
                            <TextField
                                value={premiumLimit}
                                type="number"
                                name="premiumLimit"
                                placeholder="premiumLimit"
                                variant="outlined"
                                onChange={(e) => setPremiumLimit(e.target.value)}
                                InputLabelProps={{
                                    style: { color: "white" },
                                }}
                                className={styles.input_field}
                            />
                        </FormControl>
                        )}
                    </FormGroup>
                </form>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        // marginTop: "20px !important",
                    }}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={onClose}
                        sx={{
                            color: "#FFF !important",
                            fontFamily: "Poppins !important",
                            fontSize: "16px !important",
                            fontStyle: "normal !important",
                            fontWeight: "500 !important",
                            lineHeight: "normal !important",
                            borderRadius: "5px !important",
                            marginY: "20px !important",
                            background: "#transparent !important",
                            padding: "8px 24px !important",
                            textTransform: "capitalize !important",
                            "&:hover": {
                                backgroundColor: "rgba(255, 0, 0, 0.5) !important",
                            },
                            "@media (max-width: 600px)": {
                                fontSize: "14px !important",
                                padding: "8px 16px !important",
                            },
                        }}
                    >
                        CANCEL
                    </Button>
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        // marginTop: "20px !important",
                    }}
                >
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={() => handleSubmit()}
                        sx={{
                            color: "#FFF !important",
                            fontFamily: "Poppins !important",
                            fontSize: "16px !important",
                            fontStyle: "normal !important",
                            fontWeight: "500 !important",
                            lineHeight: "normal !important",
                            borderRadius: "5px !important",
                            background: "#2EBB55 !important",
                            padding: "8px 24px !important",
                            textTransform: "capitalize !important",
                            "@media (max-width: 600px)": {
                                fontSize: "14px !important",
                                padding: "8px 16px !important",
                            },
                        }}
                    >
                        SAVE
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};
export default UpdatePremiumModal;
const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: "20px",
        minWidth: 230,
    },
    selectOptions: {
        "& .MuiListItem-root": {
            borderRadius: 20,
        },
        "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
            backgroundColor: "red",
            color: "red",
        },
        border: "1px solid #afafaf",
        borderRadius: "5px",
        color: "#afafaf",
        padding: "10px",
        height: "3.1876em",
        icon: {
            fill: "white",
        },
        root: {
            color: "white",
        },
    },
}));