import React, { useState, memo } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { ModelContentBox, Modelstyle } from "../styles/style";
import { close_icon } from "../../../../assets/smartlink";

import universalStyles from "../../../UniversalComponents/universal.module.css";
import BlueButtonComponents from "../../AdditionalComponents/utils/BlueButtonComponent";

import { generateToken, sendFileByShareLink } from "../../../../context/apis/audio-library";
import useAuth from "../../../../context/AuthContext";
import { toast } from "react-toastify";
import { Notification } from "../../../NotifyMsg/Notification";
import Backdrop from '@mui/material/Backdrop';
import CircularLoaderPage from "../../../UniversalComponents/CircularLoader/CircularLoaderPage";
import { setMilliseconds } from "date-fns";
const SendFileModal = ({
  open = false,
  setOpen = () => { },
  type = "",
  id = "",
  imageUrl = "",
  trackCount = 0,
  title = ""
}) => {

  const { user } = useAuth();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const closeShareModal = (event) => {
    setOpen(!open);
    setEmailError("");
    setEmail("");
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChange = (email) => {
    setEmail(email);
    setEmailError("")
  }

  const handleSend = async () => {
    if (email == "") {
      setEmailError("Please input Email");
      return
    }

    console.log('user avatar image url: ', localStorage.getItem('profilePic'));

    if (validateEmail(email)) {
      setIsLoading(true);
      setEmailError('');
      if (type == "file") {
        console.log("Before Calling Email Send API in SendFileModal.js");
        const payload = {
          receiverEmail: email,
          senderName: `${user?.firstname} ${user?.lastname}`,
          senderId: user._id,
          docId: id,
          docType: type,
          imageUrl: localStorage.getItem('profilePic'),
          trackCount: trackCount,
          docTitle: title
        };
        console.log("What is Payload??", payload);
        const emailResponse = await sendFileByShareLink(payload);
        console.log("email response: ", emailResponse);
        if (emailResponse == 'Email sent successfully')
          Notification("success", "Sent email successfully");
        else
          Notification("error", "Something went wrong");
        closeShareModal();
        setIsLoading(false);
      }
      else {
        console.log("Before Calling Email Send API in SendFileModal.js");
        const payload = {
          receiverEmail: email,
          senderName: `${user?.firstname} ${user?.lastname}`,
          senderId: user._id,
          docId: id,
          docType: type,
          imageUrl: localStorage.getItem('profilePic'),
          trackCount: trackCount,
          docTitle: title,
        };
        console.log("What is Payload??", payload);
        const emailResponse = await sendFileByShareLink(payload);
        if (emailResponse)
          Notification("success", "Sent email successfully");
        else
          Notification("error", "Something went wrong");
        closeShareModal();
        setIsLoading(false);
      }
    } else {
      setEmailError('Please enter a valid email address.');
    }
  }

  const handleCopyLink = async () => { // Make the function asynchronous

    setEmailError('');

    const payload = {
      senderName: `${user?.firstname} ${user?.lastname}`,
      senderId: user._id,
      docId: id,
      docType: type,
      imageUrl: imageUrl,
      trackCount: trackCount,
      docTitle: title
    };
    try {
      // Wait for the token to be generated
      const token = await generateToken(payload);
      const deepLink = `${process.env.REACT_APP_DEV_URL}/linkshared/${token}`;

      // Copy the deep link to the clipboard
      await copyToClipboard(deepLink);

      toast.success("Copied Link");
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle any errors here, such as informing the user
    }
  };

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Deep link copied to clipboard:', text);
        // Optionally, inform the user that the copy was successful
      },
      (err) => {
        console.error('Could not copy text to clipboard:', err);
        // Optionally, inform the user that the copy failed
      }
    );
  }




  return (
    <>
      <Modal
        open={open}
        onClose={closeShareModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="MainInboxCreate"
          sx={{
            ...Modelstyle,
            width: "480px",
            display: "flex",
            padding: "24px",
            height: "auto",
            flexDirection: "column",
            borderRadius: "0.625rem",
            background: "#141515",
          }}
        >
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularLoaderPage />
            </Backdrop>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "16px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: "24px",
                  color: "#AFAFAF",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Send {type == "file" ? "" : "Pack"}
              </div>
              <Box
                sx={{
                  height: "40px",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <IconButton
                  onClick={closeShareModal}
                  aria-label="settings"
                  sx={{
                    color: "#fff",
                    //   color: theme.palette.primary.dark,
                    backgroundColor: "none",
                    fontSize: "2rem",
                    padding: "0 !important",
                    margin: "0 !important",
                    marginTop: "-7px !important",
                  }}
                >
                  <img src={close_icon} alt="cancel-icon" />
                </IconButton>
              </Box>
            </div>
            <div
              className={universalStyles.search_box_in_lib}
              style={{ background: "#414241", margin: "15px 0px" }}
            >
              <img
                className={universalStyles.search_icon}
                src="/v2Assets/connect_assets/Search.png"
                alt="search"
              ></img>
              <input
                className={universalStyles.search_box_input}
                type="text"
                placeholder={"Insert Email"}
                onChange={(e) => handleChange(e.target.value)}
                required
              />
            </div>
            {emailError && <div style={{ color: 'red', marginTop: "-25px" }}>{emailError}</div>}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "15px",
                justifyContent: "end",
              }}
            >
              <BlueButtonComponents
                text="Copy Link"
                background="transparent"
                bordercolor="#fff"
                onClickFunction={() => handleCopyLink()}
                fontsize="16px"
              />
              <BlueButtonComponents
                text={type == "file" ? "Send" : "Send Pack"}
                onClickFunction={() => handleSend()}
                fontsize="16px"
              />
            </div>
          </div>
        </ModelContentBox>
      </Modal>

    </>
  );
};

export default memo(SendFileModal);
