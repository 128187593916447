import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "../mycontracts.module.css";
import axios from "axios";
import { format } from "date-fns";

import { lighten, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
} from "@material-ui/core";
import DeleteIcon from "../../../assets/trash.png";
import { getTimeFromUTC } from "../../../shared/constants";
import InfoCircle from "../../../assets/info-circle.png";
import DropDownIcon from "../../../assets/Icons/DropDownIconWhite.png";
import SearchIcon from "../../../assets/Search.png";
import RevokeIcon from "../../../assets/revokeIcon.png";
import { Notification } from "../../NotifyMsg/Notification";
import { useTableStyles } from "../../../shared/constants";

function createData(creator, subject, status, createdate, action) {
  return { creator, subject, status, createdate, action };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "creator", numeric: false, disablePadding: true, label: "Created By" },
  { id: "subject", numeric: true, disablePadding: false, label: "Subject" },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "createdate",
    numeric: true,
    disablePadding: false,
    label: "Date Created",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className={classes.tableCell}></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              classes={{
                active: classes.tablesortlabelactive,
                icon: classes.tablesortLabelIcon,
              }}
              style={{
                color: "white",
                fontSize: 18,
                fontWeight: 500,
                lineHeight: 1.5,
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function DraftComponent() {
  const classes = useTableStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [contracts, setContracts] = useState([]);

  useEffect(async () => {
    try {
      const config = {
        url: `${process.env.REACT_APP_CONTRACT_URL}/templates/draft`,
        method: "get",
        headers: {
          accesstoken: localStorage.getItem("token"),
        },
      };
      const response = await axios(config);
      setRows(
        response.data.pending.map((item) => {
          let recepients = item.roleFirstNames.join(",");
          return createData(
            `${item.firstname} ${item.lastname}`,
            { subject: item.title, to: recepients },
            item.status,
            getTimeFromUTC(item.createDate),
            item.contractId
          );
        })
      );
      setContracts(response.data.pending);
    } catch (err) {
      Notification("error", err.message);
    }
  }, [setRows, Notification]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.creator);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const createSign = async (contractId) => {
    try {
      const config = {
        url: `${process.env.REACT_APP_CONTRACT_URL}/templates/sign/${contractId}`,
        method: "post",
        headers: {
          accesstoken: localStorage.getItem("token"),
        },
      };
      const response = await axios(config);
      console.log(response.data);
      window.location.assign(response.data);
    } catch (err) {
      Notification("error", err.message);
    }
  };
  const changeSearchText = (txt) => {
    const rowStream = contracts
      .filter((item) => {
        const username = `${item.firstname} ${item.lastname}`;
        return (
          username.toLowerCase().indexOf(txt.toLowerCase()) !== -1 ||
          item.title.toLowerCase().indexOf(txt.toLowerCase()) !== -1
        );
      })
      .map((item, index) => {
        let recepients = item.roleFirstNames.join(",");
        return createData(
          `${item.firstname} ${item.lastname}`,
          { subject: item.title, to: recepients },
          item.status,
          getTimeFromUTC(item.createDate),
          item.contractId
        );
      });
    console.log(rowStream);
    setRows(rowStream);
  };

  const deleteContract = async (contractId) => {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_CONTRACT_URL}/templates/send/${contractId}`,
      headers: {
        accesstoken: localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    if (response.data.status === 200) {
      Notification("success", "Successfully Deleted");
    } else {
      Notification("error", "Server Error");
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className={styles.draft_user_name}>
            <img src={SearchIcon} />
            <input
              type="text"
              className={styles.draft_user_name_input}
              placeholder="Search by user or subject"
              onChange={(e) => changeSearchText(e.target.value)}
            />
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.creator);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.creator)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.index}
                      //   selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        className={classes.tableCell}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 20,
                            paddingRight: 20,
                          }}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            style={{
                              color: "#AFAFAF",
                              borderRadius: 2,
                              padding: 0,
                            }}
                            onChange={(event) =>
                              handleClick(event, row.creator)
                            }
                          />
                          <img src={InfoCircle} />
                        </div>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className={classes.tableCell}
                      >
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: 1.5,
                            color: "#E3E3E3",
                            margin: 0,
                          }}
                        >
                          {row.creator}
                        </p>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: 1.5,
                            color: "#FFFFFF",
                            margin: 0,
                          }}
                        >
                          {row.subject.subject}
                        </p>
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: 1.5,
                            color: "#AFAFAF",
                            margin: 0,
                          }}
                        >
                          {row.subject.to}
                        </p>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 18,
                            lineHeight: 1.5,
                            color: "#2EBB55",
                            margin: 0,
                          }}
                        >
                          {row.status === 0 ? "Pending" : "Completed"}
                        </p>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: 1.5,
                            color: "#FFFFFF",
                            margin: 0,
                          }}
                        >
                          {row.createdate.date}
                        </p>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: 1.5,
                            color: "#FFFFFF",
                            margin: 0,
                          }}
                        >
                          {row.createdate.time}
                        </p>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: 60,
                            }}
                          >
                            <img
                              src={RevokeIcon}
                              style={{
                                height: 24,
                                width: 24,
                                cursor: "pointer",
                              }}
                              onClick={() => deleteContract(row.action)}
                            />
                          </div>
                          <div>
                            <button
                              className={classes.useButton}
                              onClick={() => createSign(row.action)}
                            >
                              Sign
                            </button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
