import {
  OpportunityIcon,
  BeatsIcon,
  LinkIcon,
  SongWriterIcon,
  LogoutIcon,
  HelpIcon,
  SettingICon,
  SongAiIcon,
} from "../../../../assets/smartlink";

const PrefMenu = [
  {
    id: 1,
    name: "Setting",
    icon: SettingICon,
    url: "/settings",
  },
  // {
  //   id: 2,
  //   name: "Help",
  //   icon: HelpIcon,
  // },
  // {
  //   id: 3,
  //   name: "Log Out",
  //   icon: LogoutIcon,
  //   url: false,
  // },
];

const menuItem = [
  {
    id: 1,
    name: "Opportunities",
    icon: OpportunityIcon,
    url: "/opportunities",
  },
  {
    id: 2,
    name: "Beat Inbox",
    icon: BeatsIcon,
    url: "/beat-inbox",
    is_beta: false,
  },
  {
    id: 3,
    name: "Distribution",
    icon: "/v2Assets/SidebarIcons/SidebarAdminIcon.png",
    url: "/distribute",
    is_beta: false,
  },
  {
    id: 4,
    name: "Soundpacks",
    icon: "/v2Assets/SidebarIcons/SidebarSoundPackIcon.png",
    url: "/soundPack",
    is_beta: false,
  },
  {
    id: 5,
    name: "My Contracts",
    icon: "/v2Assets/SidebarIcons/SidebarChatIcon.png",
    url: "#",
    is_beta: true,
  },
  {
    id: 6,
    name: "My Audio Library",
    icon: "/v2Assets/SidebarIcons/SidebarSoundPackIcon.png",
    url: "#",
    is_beta: true,
  },

  // {
  //   id: 3,
  //   name: "Smart Link",
  //   icon: LinkIcon,
  //   url: "/smart-link/dashboard",
  // },
  // {
  //   id: 4,
  //   name: "SongCompletion.AI",
  //   icon: SongAiIcon,
  //   url: "#",
  // },
  // {
  //   id: 5,
  //   name: "AI SongWriter",
  //   icon: SongWriterIcon,
  //   url: "#",
  // },
];

export { menuItem, PrefMenu };
