import React from "react";
import { Grid } from "@mui/material";

// import styles from "./styles.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import { SpotifyProvider } from "../../context/SpotifyContext";

import InboxComponent from "../../components/InboxComponents/inboxComponent";
import PersonalProfileComponent from "../../components/personal_profile_page/personal_profile_component";
import ProfileComponent from "../../components/ProfileComponents/profile_component";

const PersonalProfilePage = () => {
  return (
    <Grid>
      <SpotifyProvider>
        <Sidebar childComponent={<ProfileComponent />} />
      </SpotifyProvider>
    </Grid>
  );
};

export default PersonalProfilePage;
