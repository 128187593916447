import React from "react";
import { Grid } from "@mui/material";

import styles from "./styles.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import UploadSoundKitsCollaborators from "../../components-old/SoundPacks/UploadSoundKitsTabs/UploadSoundKitsCollaborators";

import CreateSoundPack from "../../components-old/SoundPacks/UploadSoundKitsTabs/CreateSoundPack";
const CreateMySoundPack = () => {
  return (
    <Grid className={styles.bgBlack}>
      <Sidebar
        activeText="SoundPacks"
        childComponent={<CreateSoundPack />}
        activeSubText="Create A Sound Packs"
      />
    </Grid>
  );
};

export default CreateMySoundPack;
