/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable require-jsdoc */
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { toast } from "react-toastify";

import Tooltip from "@material-ui/core/Tooltip";
import { get } from "lodash";
import styles from "../../../OpportunitiesComponents/Opportunities/Opportunities.module.css";
import {
  formatCount,
  getInitialsFromName,
  remainngTimeCount,
} from "../../../../shared/funs";
import useAppState from "../../../../context/useAppState";
import { spotify_icon } from "../../../../assets/smartlink";
import Waveform from "../../../../components-old/Submission/Waveform";
import moment from "moment";
import DeletePopup from "../../../Popups/DeletePopup";
import allImages from "../../../../assets";
import { updateOpportunityById } from "../../../../context/apis/admin/opportunity";
import WavePlayer from "../../../../components-old/WavePlayer";
import UpdatePremiumModal from "../updatePremiumModal";

function AdminOpportunityCard({
  opportunityData,
  onApprove,
  removeOpportunity,
  isAdmin,
  showApproveBtns = false,
  showExpCountDown = true,
}) {
  const navigate = useNavigate();
  const { deleteOpportunityById, toggleOpportunityFeaturedStatus } =
    useAppState("opportunity");

  const cover_image =
    opportunityData?.coverPhoto?.url || "/images/Image_not_available.png";
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState();

  const [opportunity, setOpportunity] = React.useState();
  const [premiumModal, setPremiumModal] = React.useState(false);

  const handlePremiumModalOpen = () => {
    setPremiumModal(true);
  };
  const handleOpportunity = (selectedOpportunity) => {
    setOpportunity(selectedOpportunity);
  };
  const handlePremiumModalClose = () => {
    setPremiumModal(false);
  };

  const [isFeatured, setIsFeatured] = React.useState(opportunityData?.featured);

  function handleFeatureStatus() {
    setIsFeatured(!isFeatured);
    toggleOpportunityFeaturedStatus(opportunityData?._id);
  }

  React.useEffect(() => {
    if (opportunityData?.userId) {
      setUserInfo(opportunityData?.user);
    }
  }, [opportunityData]);

  const handleDeletePopup = () => {
    setDeletePopup(true);
  };

  const handleClosePopup = () => {
    setDeletePopup(false);
  };

  const cancelHandler = (isDelete, opportunityId) => {
    if (isDelete === "yes") {
      deleteHandler(opportunityId);
      handleClosePopup(false);
    } else {
      handleClosePopup();
    }
  };

  const deleteHandler = async () => {
    try {
      const response = await deleteOpportunityById(opportunityData?._id);
      toast.success("Opportunity deleted successfully.", { toastId: 1 });
      removeOpportunity(opportunityData?._id);
      console.info("response => ", response);
    } catch (err) {
      console.log("error on delete oportunity id..", err);
    }
  };

  const updateOpportunityStatusHandler = async (status) => {
    try {
      const response = await updateOpportunityById(opportunityData?._id, {
        status,
      });
      toast.success("Opportunity updated successfully.", { toastId: 1 });
      removeOpportunity(opportunityData?._id);
    } catch (err) {
      console.log("error on update opportunity id..", err);
    }
  };

  const linkHandler = (link) => {
    window.open(link, "_blank");
  };

  const remainingMinutes = Math.floor(
    moment
      .duration(moment(opportunityData.expireDate).diff(moment()))
      .asMinutes(),
  );

  return (
    <div className={styles.opportunity_card_layout}>
      <div className={styles.opportunity_card_content}>
        <div className={styles.opportunity_card_content_left}>
          <div className={styles.opportunity_card_image_container}>
            <div
              className={styles.opportunity_card_image}
              style={{
                background: `url(${cover_image})center center / cover no-repeat`,
              }}
            />
          </div>
        </div>

        <div className={styles.opportunity_card_content_layout}>
          <div className={styles.opportunity_card_information}>
            <div className={styles.opportunity_card_left_side}>
              <div className={styles.opportunity_card_left_side_information}>
                <OpportunityTitleInfo />
                <OpportunityInformation />
              </div>
            </div>
            <div className={styles.opportunity_card_right_side}>
              <div className={styles.uploaded_by_wrapper}>
                <p className={styles.submission_count_text_admin}>
                  {get(opportunityData, "submissionCount", 0)} Submissions
                </p>
                {/* <div className={styles.posted_by_info}>
                    <OpportunityPostedBy />
                  </div> */}

                <>
                  {isFeatured ? (
                    <div
                      className={styles.unfeature_button}
                      onClick={handleFeatureStatus}
                    >
                      Featured
                    </div>
                  ) : (
                    <div
                      className={styles.feature_button}
                      onClick={handleFeatureStatus}
                    >
                      Feature
                    </div>
                  )}
                </>

                {opportunityData?.spotifyLink && (
                  <img
                    src={spotify_icon}
                    onClick={() => linkHandler(opportunityData.spotifyLink)}
                    alt="spotify link"
                    className={styles.spotify_icon_admin}
                  />
                )}
              </div>
              <div className={styles.opportunity_card_status_controls}>
                <CreatorOpportunityControls />

                <div className={styles.referance_track_layout_admin}>
                  <div className={styles.referance_track}>
                    {get(opportunityData, "referenceTrack.url") && (
                      <>
                        <WavePlayer
                          key={
                            opportunityData?._id ||
                            opportunityData?.referenceTrack?.url
                          }
                          file={opportunityData?.referenceTrack}
                          url={opportunityData?.referenceTrack?.url}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.opportunity_card_bottom}>
            <ViewSubmissionsButton />

            <OpportunityExpirationCountdown />
          </div>
        </div>
      </div>
      <Modals />
    </div>
  );

  function Modals() {
    return (
      <>
        <DeletePopup
          open={deletePopup}
          title="Delete Opportunity"
          content="Do you really want to remove this opportunity?"
          label1="Delete"
          label2="Cancel"
          handler={cancelHandler}
          id={opportunityData?.id}
        />
      </>
    );
  }

  function OpportunityTitleInfo() {
    return (
      <div>
        <p className={styles.opportunity_title}>{opportunityData?.title}</p>
        <Tooltip placement="bottom-start" title={opportunityData?.description}>
          <p className={styles.opportunity_description}>
            {opportunityData?.description}
          </p>
        </Tooltip>
      </div>
    );
  }

  function OpportunityPostedBy() {
    return (
      <Box className={styles.posted_by}>
        <Box>
          <p className={styles.opportunity_posted_by}>Posted By</p>
          <p className={styles.opportunity_owner}>
            {userInfo?.userName || "User"}
          </p>
        </Box>
        <img
          src={userInfo?.profilePic}
          className={styles.avatar}
          alt="posted by"
        />
      </Box>
    );
  }

  function OpportunityInformation() {
    return (
      <div className={styles.opportunity_data_information}>
        {get(opportunityData, "artist.name", "") && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Avatar
                alt={opportunityData?.artist?.name}
                style={{ height: 28, width: 28, fontSize: 12 }}
              >
                {getInitialsFromName(opportunityData?.artist?.name)}
              </Avatar>
              <Typography
                style={{
                  color: "#2EBB55",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                {opportunityData?.artist?.name || "Guest"}
              </Typography>
            </Box>
          </Box>
        )}
        <div className={styles.opportunity_tags_container}>
          <div className={styles.opportunity_tags}>
            Genre: {opportunityData?.genre}
          </div>
          {opportunityData?.bpm && (
            <div className={styles.opportunity_tags}>
              BPM: {opportunityData?.bpm}
            </div>
          )}
          {opportunityData?.monthlyListeners && (
            <div className={styles.opportunity_tags}>
              Monthly Listeners:{" "}
              {formatCount(opportunityData.monthlyListeners, true)}
            </div>
          )}
          <div className={styles.opportunity_tags}>
            File Format: {opportunityData?.fileFormat}
          </div>
        </div>
      </div>
    );
  }

  function OpportunityReferanceTrack() {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography style={{ color: "#afafaf", flexShrink: 0 }}>
            Reference Track:
          </Typography>
        </Grid>
        <Grid item>
          {get(opportunityData, "referenceTrack.url") && (
            <WavePlayer
              key={opportunityData?._id || opportunityData?.referenceTrack?.url}
              file={opportunityData?.referenceTrack}
              url={opportunityData?.referenceTrack?.url}
            />
          )}
        </Grid>
      </Grid>
    );
  }

  // function OpportunitySubmitButton() {
  //   return (
  //     <div
  //       className={styles.submit_button}
  //       onClick={() => handleOpen(opportunityData._id)}
  //     >
  //       Submit
  //     </div>
  //   );
  // }

  function ViewSubmissionsButton() {
    return (
      <div
        className={styles.submit_button}
        onClick={() =>
          navigate(`/opportunities/viewsubmission/${opportunityData?._id}`)
        }
      >
        View Submissions
      </div>
    );
  }

  function CreatorOpportunityControls() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
        }}
      >
        <button
          type="button"
          className={styles.edit_button}
          onClick={() =>
            navigate(`/opportunities/edit/${opportunityData?._id}`)
          }
        >
          Edit
        </button>
        <button
          type="button"
          className={styles.edit_button}
          onClick={() => {
            handleOpportunity(opportunityData);
            handlePremiumModalOpen();
          }}
        >
          Premium
        </button>
        <button
          className={styles.delete_button}
          onClick={(e) => handleDeletePopup()}
        >
          Delete
        </button>
      </div>
    );
  }

  function OpportunityExpirationCountdown() {
    return (
      <>
      <Box
        style={{
          display: "flex",
          flexDirection: "center",
          alignItems: "row",
          columnGap: "48px",
          backgroundColor: "rgba(0, 0, 0, 0.85)",
          borderRadius: "5px",
          paddingLeft: "35px",
          paddingRight: "21px",
          paddingTop: "3px",
          paddingBottom: "3px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "4px 3px",
          }}
        >
          <span
            style={
              remainingMinutes > 0
                ? { color: "#7E7E7E", fontSize: "15px" }
                : { color: "red" }
            }
          >
            {remainngTimeCount(opportunityData.expireDate).days > 0
              ? remainngTimeCount(opportunityData.expireDate).days
              : 0}
          </span>
          <span
            style={
              remainingMinutes > 0
                ? { color: "#7E7E7E", fontSize: "15px" }
                : { color: "red" }
            }
          >
            {remainngTimeCount(opportunityData.expireDate).days <= 1
              ? "day"
              : "days"}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "4px 3px",
          }}
        >
          <span
            style={
              remainingMinutes > 0
                ? { color: "#7E7E7E", fontSize: "15px" }
                : { color: "red" }
            }
          >
            {remainngTimeCount(opportunityData.expireDate).hours > 0
              ? remainngTimeCount(opportunityData.expireDate).hours
              : 0}
          </span>
          <span
            style={
              remainingMinutes > 0
                ? { color: "#7E7E7E", fontSize: "15px" }
                : { color: "red" }
            }
          >
            hours
          </span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "4px 3px",
          }}
        >
          <span
            style={
              remainingMinutes > 0
                ? { color: "#7E7E7E", fontSize: "15px" }
                : { color: "red" }
            }
          >
            {remainngTimeCount(opportunityData.expireDate).minutes > 0
              ? remainngTimeCount(opportunityData.expireDate).minutes
              : 0}
          </span>
          <span
            style={
              remainingMinutes > 0
                ? { color: "#2EBB55", fontSize: "14px" }
                : { color: "red" }
            }
          >
            minutes
          </span>
        </div>
      </Box>
        <UpdatePremiumModal
          open={premiumModal}
          onClose={handlePremiumModalClose}
          opportunity={opportunity}
        />
    </>
    );
  }

  // function OpportunityTerms() {
  //   return (
  //     <Box display={"flex"} style={{ paddingTop: 5, paddingBottom: 6 }}>
  //       <DarkTooltipWithBorder
  //         placement="bottom-start"
  //         title={
  //           <React.Fragment>
  //             <Box>
  //               <p
  //                 style={{
  //                   fontSize: 16,
  //                   padding: "15px 10px",
  //                   margin: "0px",
  //                   borderBottom: "1px solid #afafaf",
  //                 }}
  //               >
  //                 Advance ($):{" "}
  //                 <span className={styles.text_white}>
  //                   ${opportunityData?.advance || 0}
  //                 </span>
  //               </p>
  //               <p
  //                 style={{
  //                   fontSize: 16,
  //                   padding: "15px 10px",
  //                   margin: "0px",
  //                   borderBottom: "1px solid #afafaf",
  //                 }}
  //               >
  //                 Points:{" "}
  //                 <span className={styles.text_white}>
  //                   {opportunityData?.points || 0}
  //                 </span>
  //               </p>

  //               <p
  //                 style={{
  //                   fontSize: 16,
  //                   padding: "15px 10px",
  //                   margin: "0px",
  //                 }}
  //               >
  //                 Publishing (%):
  //                 <span className={styles.text_white}>
  //                   {" "}
  //                   {opportunityData?.publishing || 0}
  //                 </span>
  //                 %
  //               </p>
  //             </Box>
  //           </React.Fragment>
  //         }
  //       >
  //         <Box display={"flex"} style={{ gap: "5px" }}>
  //           <img
  //             src="/images/info-circle.png"
  //             alt=""
  //             width={"15px"}
  //             height={"15px"}
  //           />
  //           <Typography style={{ color: "#afafaf", fontSize: 12 }}>
  //             Terms are pre-set for this opportunity
  //           </Typography>
  //         </Box>
  //       </DarkTooltipWithBorder>
  //       {/* <Tooltip arrow placement="bottom"></Tooltip> */}
  //     </Box>
  //   );
  // }
}
export default AdminOpportunityCard;
