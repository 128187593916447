import { Box } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
const MainNavbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "20px 80px",
  marginRight: "60px",
  marginLeft: "60px",
  alignItems: "center",
  "& .NavItemBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexShrink: 0,
    gap: "150px",

    "& .UlList": {
      "& .MainUlLists": {
        "& .ListItem": {
          cursor: "pointer",
          color: "#AFAFAF",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          textDecoration: "none",
          "&:hover": {
            color: "white",
          },
        },
      },
    },
  },

  "& .BtnMain": {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    "& .SignupBtn": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      borderRadius: "5px",
      background: "#2EBB55",
      padding: "8px 24px",
      textTransform: "capitalize",
    },
    "& .LoginBtn": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      borderRadius: "5px",
      padding: "8px 24px",
      textTransform: "capitalize",
    },
  },
  "& .UlList": {
    "& .MainUlLists": {
      display: "flex",
      alignItems: "flex-start",
      gap: "40px",
    },
  },
  "& .mobileLogoImg": {
    display: "none",
  },
  [theme.breakpoints.down("xl")]: {
    marginRight: "20px",
    marginLeft: "20px",
    "& .NavItemBox": {
      gap: "50px",
    },
  },
  [theme.breakpoints.down("md")]: {
    marginRight: "0px",
    marginLeft: "0px",
    padding: "20px",
    width: "100%",
    "& .NavItemBox": {
      "& .UlList": {
        display: "none",
      },
    },
    "& .BtnMain": {
      gap: "15px",
      "& .SignupBtn": {
        fontSize: "14px",
        padding: "8px 16px",
      },
      "& .LoginBtn": {
        fontSize: "14px",
        padding: "8px 16px",
      },
    },
    "& .logoImg": {
      display: "none",
    },
    "& .mobileLogoImg": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "10px",
      a: {
        img: {
          width: "40px",
          height: "auto",
        },
      },
    },
  },
}));

export { MainNavbar };
