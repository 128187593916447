import React, { useContext, useEffect, useState } from "react";
import ViralLoops from "viral-loops-react";
import { config } from "./config";
import "./../Form.css";
import FormTitle from "../FormTitle";
import { Box, Button, Text } from "native-base";
import ButtonComponent from "../../UniversalComponents/ButtonComponent";
import { AuthContext } from "../Layout";
import { useBreakpointValue } from "native-base";
import { useNavigate } from "react-router";

function Referal(props) {
  const navigate = useNavigate();
  const {
    activeStep,
    disableBtn,
    setActiveStep,
    progressbarvalue,
    setProgressbarValue,
  } = useContext(AuthContext);

  const authdata = useContext(AuthContext);

  const [btnText, setBtnText] = useState("skip");
  const referUserData = localStorage.getItem(
    "2dkqKSlcxCouU9hsJKTyw1tt4BI#user"
  );
  //
  const isSignedUp = referUserData && Object.keys(referUserData).length;
  // useEffect(() => {
  //   const btn = document.getElementsByClassName("button-custom-style");
  //   console.log(btn);
  //   // btn.addEventListener("click", (e) => {
  //   //   console.log(e.target);
  //   // });
  // }, []);
  // setTimeout(() => {
  //   const btn = document.getElementById("participationSubmitNoReferrer");
  //   console.log("dsad", btn);
  //   // btn.addEventListener("click", (e) => {

  //   // });
  // }, 6000);
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });
  const handlePress = (e) => {
    if (authdata.accountType === "Music Producer") {
      // navigate("/beat-inbox/more-storage");
      window.location.href = "/beat-inbox/more-storage";
    } else {
      navigate("/opportunities");
    }
  };

  const handlePrevious = () => {
    setProgressbarValue((prevActiveStep) => prevActiveStep - 1);
    setActiveStep((activeStep) => activeStep - 1);
  };
  return (
    <div
      className="referDiv"
      //   style={{
      //     backgroundColor: "#ffff",
      //     height: "100%",
      //     width: "100%",
      //     minHeight: "100vh",
      //   }}
    >
      <div style={{ width: "100%" }}>
        <Box
          w={{ lg: "90%", md: "90%", sm: "85%", base: "94%" }}
          fontFamily="poppins"
          mx={"auto"}
          mb={10}
        >
          <div>
            {/* {isMobile && (
							<Text
								textTransform={'capitalize'}
								fontFamily={'poppins'}
								color={'#2EBB55'}
								fontSize='1rem'
							>
								refer a friend
							</Text>
						)} */}

            {/* <FormTitle
							//   activeStep={activeStep}
							color={'#000201'}
							title={'refer a friend'}
						//   subtitle={props.subtitle}
						//   isMobile={isMobile}
						/> */}
            {/* <ViralLoops
							publicToken={'2dkqKSlcxCouU9hsJKTyw1tt4BI'}
							formWidget
							milestoneWidget={false}
						/> */}
            {/* <Button
              onPress={handlePress}
              _text={{
                color: "#E3E3E3",
                fontWeight: "normal",
                fontSize: { xl: "1.2rem", md: "1rem", base: "1rem" },
                fontFamily: "poppins",
                textTransform: "capitalize",
              }}
              height={{ lg: "45px", sm: "35px", base: "48px" }}
              backgroundColor={"#2EBB55"}
              mt={activeStep === 5 ? 12 : 4}
              //   onpress={handlePress}
            >
              {props.btnText}
            </Button> */}
            {/* <Box>
              <Text
                fontFamily="poppins"
                onPress={handlePrevious}
                mt={5}
                mx={"auto"}
                //   w={"100%"}
                //   textAlign={"center"}
                color={"#AFAFAF"}
                textTransform={"uppercase"}
                letterSpacing={{ md: "0.8", base: "0" }}
                fontWeight="normal"
                fontSize={{ md: "1.2rem", sm: "0.9rem", base: "0.95rem" }}
                textDecorationLine={"underline"}
              >
                previous step
              </Text>
            </Box> */}
          </div>
          <Button
            _text={{
              color: "#E3E3E3",
              fontWeight: "normal",
              fontSize: { xl: "1.2rem", md: "1rem", base: "1rem" },
              fontFamily: "poppins",
              textTransform: "capitalize",
            }}
            height={{ lg: "45px", sm: "35px", base: "48px" }}
            backgroundColor={"#2EBB55"}
            _hover={{
              backgroundColor: "#000",
              borderColor: "#2EBB55",
              borderWidth: 1,
              color: "#2EBB55",
              _text: { color: "#2EBB55" },
            }}
            isDisabled={disableBtn ? true : false}
            mt={activeStep === 5 ? 12 : 4}
            flag={false}
            // text={isSignedUp === 0 ? "skip" : "done"}
            onPress={handlePress}
          >
            {"finish"}
          </Button>
        </Box>
      </div>
    </div>
  );
}

export default Referal;
