import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

const MainBeatsBox = styled(Box)(({ theme }) => ({
  marginTop: "15px",
}));
const HeaderMain = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& h6": {
    fontFamily: theme.typography.fontFamily,
    color: "#FFF",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    margin: "0",
  },
  [theme.breakpoints.down("md")]: {
    "& h6": {
      fontSize: "18px",
    },
    "& .ProgressMain": {
      width: "100%",
    },
    gap: "5px",
    width: "100%",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
  },
}));
const ProgressMain = styled(Box)(({ theme }) => ({
  "& .progress_text": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
    h5: {
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      margin: "0",
    },
    a: {
      color: "#2EBB55",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textDecorationLine: "underline",
    },
  },
  width: "25%",
}));
const FilterMain = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginTop: "45px",
  h6: {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    margin: "0",
  },
  p: {
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0",
  },
}));

const Main = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "20px",
  width: "100%",
  "& .btn-box": {
    marginBottom: "40px",
    textAlign: "center",
    "& .btn": {
      borderRadius: "5px",
      padding: "12px 30px",
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      background: "#0E1210",
      textTransform: "capitalize",
      marginRight: "8px",
      "&.active": {
        background: "#2EBB55",
        color: "#fff",
      },
    },
  },
}));

export { MainBeatsBox, HeaderMain, ProgressMain, FilterMain, Main };
