import { useState, useRef, useEffect } from "react";
import React from "react";
import styles from "./inbox.module.css";
import useAppState from "../../context/useAppState";
import useAuth from "../../context/AuthContext";
import socket from "../../context/web-socket/socket";
import Waveform from "./chatWaveform";
import { handleDownload } from "../../shared/funs";

export default function InboxComponent() {
  const { user } = useAuth();
  const userId = user._id;
  const { loadAllChats, markReadChat } = useAppState("chat");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTabFilter, setActiveTabFilter] = useState("All");
  const [tabFilteredChats, setTabFilteredChats] = useState([]);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);

  const { acceptRequest, recindRequest } = useAppState("connectMe");

  function getUserName(profile) {
    if (profile?.name) {
      return profile.name.firstName + " " + profile.name.lastName;
    } else if (profile?.userName) {
      return profile.userName;
    } else {
      return "Guest";
    }
  }

  function formatLocalTime(isoString) {
    const date = new Date(isoString);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const lowercaseAMPM = ampm.toLowerCase();
    hours = hours % 12;
    hours = hours ? hours : 12;

    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}${lowercaseAMPM}`;
  }

  function sortChatsByTime(chats) {
    return chats
      .slice()
      .sort(
        (a, b) => new Date(b.recentTimestamp) - new Date(a.recentTimestamp)
      );
  }

  function updateChatsLocally(prevChats, message) {
    const updatedChats = prevChats.map((chat) => {
      if (chat._id === message.connectionId) {
        return {
          ...chat,
          messages: [...chat.messages, message],
          recentTimestamp: new Date().toISOString(),
        };
      }
      return chat;
    });
    return sortChatsByTime(updatedChats); // Sort the updated chats array
  }

  useEffect(() => {
    setLoading(true);
    loadAllChats(userId)
      .then((response) => {
        console.log(response);
        const loadedChats = response.chats.filter(
          (chat) =>
            (chat.messages && chat.messages.length > 0) ||
            chat.connectionId?.status === "accepted" ||
            (chat.connectionId?.status === "pending" &&
              chat.connectionId?.participants[1] === userId)
        );
        console.log("loadedChats",loadedChats)
        setChats(loadedChats);
        setActiveTabFilter("All");
        setTabFilteredChats(loadedChats);
        tabFilteredChats.forEach((chat) => {
          socket.emit("joinRoom", chat._id);
        });
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const currentChatIds = chats.map((chat) => chat._id);
    const newChatIds = tabFilteredChats.map((chat) => chat._id);
    const chatsToLeave = currentChatIds.filter(
      (id) => !newChatIds.includes(id)
    );

    chatsToLeave.forEach((chatId) => {
      socket.emit("leaveRoom", chatId);
    });

    tabFilteredChats.forEach((chat) => {
      socket.emit("joinRoom", chat._id);
    });
  }, [tabFilteredChats]);

  useEffect(() => {
    const messageHandler = (message) => {
      console.log("Received message:", message);

      setTabFilteredChats((prevChats) =>
        updateChatsLocally(prevChats, message)
      );
      setChats((prevChats) => updateChatsLocally(prevChats, message));

      if (selectedChat && selectedChat._id === message.connectionId) {
        setSelectedChat((prevSelectedChat) => ({
          ...prevSelectedChat,
          messages: [...prevSelectedChat.messages, message],
        }));
      }
    };

    socket.on("chat_message", messageHandler);

    return () => {
      socket.off("chat_message", messageHandler);
    };
  }, [selectedChat]);

  const textAreaRef = useRef(null);

  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isEditing]);

  const leaveChat = (chat) => {
    socket.emit("leaveRoom", chat._id);
  };

  const selectChat = (chat) => {
    setSelectedChat(chat);
    markRead(chat._id, userId);
    // socket.emit("joinRoom", chat._id);
  };

  // useEffect(() => {
  //   return () => {
  //     if (selectedChat) {
  //       leaveChat(selectedChat); // leave the current chat room when component unmounts
  //     }
  //   };
  // }, [selectedChat]); // the cleanup runs when component unmounts and when selectedChat changes

  const markRead = async (chatId, userId) => {
    try {
      await markReadChat(chatId, userId);
      setTabFilteredChats((prevChats) =>
        prevChats.map((chat) =>
          chat._id === chatId
            ? {
                ...chat,
                messages: chat.messages.map((message) => ({
                  ...message,
                  readBy: [...new Set([...message.readBy, userId])],
                })),
                unreadCount: 0,
              }
            : chat
        )
      );
      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat._id === chatId
            ? {
                ...chat,
                messages: chat.messages.map((message) => ({
                  ...message,
                  readBy: [...new Set([...message.readBy, userId])],
                })),
                unreadCount: 0,
              }
            : chat
        )
      );
    } catch (error) {
      console.log("Error while marking chat as read = ", error);
      throw error;
    }
  };

  const sendAcceptMessage = (chatId) => {
    const message = {
      content: `${getUserName(user)} accepted your connection request.`,
      sender: userId,
      timestamp: new Date().toISOString(),
      connectionId: chatId,
      readBy: [userId],
    };

    socket.emit("chat_message", message);

    setTabFilteredChats((prevChats) => updateChatsLocally(prevChats, message));
    setChats((prevChats) => updateChatsLocally(prevChats, message));

    setSelectedChat((prevSelectedChat) => ({
      ...prevSelectedChat,
      messages: [...prevSelectedChat.messages, message],
    }));
  };

  const updateAcceptChatState = (chatId) => {
    setTabFilteredChats((prevChats) =>
      prevChats.map((chat) =>
        chat._id === chatId
          ? {
              ...chat,
              connectionId: { ...chat.connectionId, status: "accepted" },
            }
          : chat
      )
    );
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat._id === chatId
          ? {
              ...chat,
              connectionId: { ...chat.connectionId, status: "accepted" },
            }
          : chat
      )
    );
  };

  const updateRejectChatState = (chatId) => {
    setTabFilteredChats((prevChats) => {
      const newChats = prevChats.filter((chat) => chat._id !== chatId);
      console.log("Updated chats:", newChats);
      return newChats;
    });
    setChats((prevChats) => {
      const newChats = prevChats.filter((chat) => chat._id !== chatId);
      console.log("Updated chats:", newChats);
      return newChats;
    });
  };

  const handleAcceptConnection = async (profileId, chatId) => {
    try {
      await acceptRequest(userId, profileId);
      updateAcceptChatState(chatId);
      sendAcceptMessage(chatId);
    } catch (error) {
      console.error("Failed to accept the connection request.", error);
    }
  };

  const handleRejectConnection = async (profileId, chatId) => {
    try {
      await recindRequest(userId, profileId);
      updateRejectChatState(chatId);
    } catch (error) {
      console.error("Failed to reject the connection request.", error);
    }
  };

  useEffect(() => {
    setTabFilter(activeTabFilter);
  }, [activeTabFilter]);

  function setTabFilter(tabFilter) {
    let result = [];
    switch (tabFilter) {
      case "All":
        result = chats.filter(
          (chat) =>
            (chat.messages && chat.messages.length > 0) ||
            chat.connectionId.status === "accepted" ||
            (chat.connectionId.status === "pending" &&
              chat.connectionId.participants[1] === userId)
        );
        break;
      case "Connected":
        result = chats.filter(
          (chat) => chat.connectionId?.status === "accepted"
        );
        break;
      case "Requests":
        result = chats.filter(
          (chat) =>
            chat.connectionId?.status === "pending" &&
            chat.connectionId?.participants[1] === userId
        );
        console.log("results",result)
        break;
      default:
        console.error(`Unknown tab filter: ${tabFilter}`);
    }
    setTabFilteredChats(result);
  }

  const MessageInput = () => {
    const [inputValue, setInputValue] = useState("");

    const sendMessage = () => {
      if (inputValue.trim() !== "") {
        const message = {
          content: inputValue,
          sender: userId,
          timestamp: new Date().toISOString(),
          connectionId: selectedChat._id,
          readBy: [userId],
        };

        console.info("message has been sent :: ", message);

        console.info(
          "message socket emit while sending message :: ",
          socket.emit("chat_message", message)
        );

        setTabFilteredChats((prevChats) =>
          updateChatsLocally(prevChats, message)
        );
        setChats((prevChats) => updateChatsLocally(prevChats, message));

        if (selectedChat && selectedChat._id === message.connectionId) {
          setSelectedChat((prevSelectedChat) => ({
            ...prevSelectedChat,
            messages: [...prevSelectedChat.messages, message],
          }));
        }

        setInputValue("");
      }
    };

    return (
      <div className={styles.message_input_container}>
        <input
          className={styles.message_input}
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type message..."
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
        />
        <button
          className={
            inputValue ? styles.send_button_active : styles.send_button_inactive
          }
          disabled={!inputValue}
          onClick={sendMessage}
        >
          Send
        </button>
      </div>
    );
  };

  const getUnreadMessageCount = (messages, userId) => {
    // Assuming messages are ordered from oldest to newest
    let unreadCount = 0;
    for (let i = messages.length - 1; i >= 0; i--) {
      if (!messages[i].readBy.includes(userId)) {
        unreadCount++;
      } else {
        // Stop the iteration as we've reached a message read by the user
        break;
      }
    }
    return unreadCount;
  };

  const chatChannels = tabFilteredChats.map((chat) => {
    const incomingConnectionRequest =
      chat.connectionId?.status === "pending" &&
      chat.connectionId?.participants[1] === userId;

    const unreadMessageCount = getUnreadMessageCount(chat.messages, userId);

    return (
      <React.Fragment key={chat.id}>
        <div className={styles.chat_card_box}>
          <div
            onClick={() => selectChat(chat)}
            className={`${styles.chat_card} ${
              selectedChat?._id === chat._id
                ? styles.selected_chat
                : styles.non_selected_chat
            }`}
          >
            <div className={`${styles.chat_info}`}>
              <div className={styles.chat_left_info}>
                <div className={styles.chat_user_info}>
                  <div className={styles.chat_user_avatar}>
                    <img
                      className={styles.chat_user_avatar_image}
                      src={chat.users[0]?.profilePic}
                      alt={"Profile"}
                    />
                  </div>
                  <div className={styles.user_info_box}>
                    <p className={styles.chat_user_name}>
                      {getUserName(chat.users[0])}
                    </p>
                    <p className={styles.chat_user_role}>
                      {chat.users[0]?.roleId?.roleName}
                    </p>
                  </div>
                </div>
                {chat.messages.length > 0 && (
                  <div className={styles.chat_recent_message}>
                    <p className={styles.chat_message}>
                      {chat.messages[chat.messages.length - 1].content}
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.chat_right_info}>
                <div className={styles.chat_time_box}>
                  {chat.messages.length > 0 && (
                    <p className={styles.chat_time}>
                      {formatLocalTime(
                        chat.messages[chat.messages.length - 1].timestamp
                      )}
                    </p>
                  )}
                </div>
                {incomingConnectionRequest ? (
                  <div className={styles.connection_controls}>
                    <div
                      className={styles.connection_controls_accept}
                      onClick={() => {
                        handleAcceptConnection(chat.users[0]?._id, chat._id);
                      }}
                    >
                      <img
                        className={styles.control_icon}
                        src="/v2Assets/connect_assets/accept.png"
                        alt="accept"
                      ></img>
                    </div>
                    <div
                      className={styles.connection_controls_reject}
                      onClick={() => {
                        handleRejectConnection(chat.users[0]?._id, chat._id);
                      }}
                    >
                      <img
                        className={styles.control_icon}
                        src="/v2Assets/connect_assets/reject.png"
                        alt="reject"
                      ></img>
                    </div>
                  </div>
                ) : (
                  <>
                    {unreadMessageCount !== 0 && (
                      <div className={styles.unread_messages}>
                        <p>{unreadMessageCount}</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });

  const MessageComponent = ({ selectedChat }) => {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    useEffect(scrollToBottom, [selectedChat]);

    return (
      <div className={styles.message_wrapper}>
        {selectedChat.messages.map((message, index) => {
          const isSenderSelf = message.sender === userId;

          return (
            <div
              key={index}
              className={`${styles.message_container} ${
                isSenderSelf ? styles.reversed : ""
              }`}
            >
              <div
                className={`${styles.message_avatar} ${
                  isSenderSelf
                    ? styles.message_avatar_self
                    : styles.message_avatar_other
                }`}
              >
                <img
                  className={styles.message_avatar_image}
                  src={
                    isSenderSelf
                      ? user.profilePic
                      : selectedChat.users[0]?.profilePic
                  }
                  alt={isSenderSelf ? "Self" : "Other"}
                />
              </div>
              <div
                className={`${styles.message_content} ${
                  isSenderSelf
                    ? styles.self_message_content
                    : styles.other_message_content
                }`}
              >
                <div
                  className={`${styles.message_info} ${
                    isSenderSelf
                      ? styles.self_message_info
                      : styles.other_message_info
                  }`}
                >
                  <strong className={styles.message_sender}>
                    {message.sender === userId
                      ? getUserName(user)
                      : getUserName(selectedChat.users[0])}
                  </strong>
                  <span className={styles.message_time}>
                    {formatLocalTime(message.timestamp)}
                  </span>
                </div>
                <div className={styles.message_text_content}>
                  {message.submissionId ? (
                    <>
                      {/* <p className={styles.message_text}>Sent an Audio File:</p> */}
                      {message.submissionId.file?.url && (
                        <div className={styles.waveform}>
                          <div className={styles.submission_top_info}>
                            <img
                              className={styles.submission_image}
                              src={selectedChat.users[0].profilePic}
                              alt="submission"
                            />
                            <div className={styles.submission_text}>
                              <p className={styles.submission_title}>
                                {message.submissionId.file.name}
                              </p>
                              <p className={styles.submission_subtitle}>
                                {getUserName(selectedChat.users[0])}
                              </p>
                            </div>
                          </div>
                          <Waveform url={message.submissionId.file.url} />
                          <div className={styles.submission_bottom_controls}>
                            <div className={styles.submission_info_button}>
                              Beat Information
                            </div>
                            <div
                              className={styles.submission_download_button}
                              onClick={() =>
                                handleDownload(
                                  message.submissionId.file.url,
                                  message.submissionId.file.name
                                )
                              }
                            >
                              Download
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <p className={styles.message_text}>{message.content}</p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>
    );
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.sidebar}>
        {/* SEARCH BUTTON */}
        <div className={styles.search_container}>
          <input
            className={styles.search_input}
            value={searchQuery}
            type="text"
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
          />
        </div>
        <div className={styles.search_filter_options}>
          <div
            className={`${styles.search_filter} ${
              activeTabFilter === "All" ? styles.search_filter_active : ""
            }`}
            onClick={() => setActiveTabFilter("All")}
          >
            <p className={styles.search_filter_text}>All</p>
          </div>
          {/* <div
            className={`${styles.search_filter} ${
              activeTabFilter === "Connected" ? styles.search_filter_active : ""
            }`}
            onClick={() => setActiveTabFilter("Connected")}
          >
            <p className={styles.search_filter_text}>Connected</p>
          </div> */}
          {/* <div
            className={`${styles.search_filter} ${
              activeTabFilter === "Requests" ? styles.search_filter_active : ""
            }`}
            onClick={() => setActiveTabFilter("Requests")}
          >
            <p className={styles.search_filter_text}>Requests</p>
          </div> */}
        </div>

        <div className={styles.divider}></div>
        <div className={styles.chat_list}>{chatChannels}</div>
      </div>

      <div className={styles.chat_container}>
        {selectedChat ? (
          <div className={styles.chat_layout}>
            <div className={styles.chat_layout_content_info}>
              <div className={styles.chat_header}>
                <div className={styles.chat_avatar}>
                  <img
                    className={styles.header_avatar}
                    src={selectedChat.users[0]?.profilePic}
                    alt="chat"
                  />
                </div>
                <div className={styles.chat_name}>
                  {getUserName(selectedChat.users[0])}
                </div>
              </div>
              <div className={styles.chat_messages}>
                <MessageComponent selectedChat={selectedChat} />
              </div>
            </div>
            <div className={styles.chat_input}>
              <MessageInput />
            </div>
          </div>
        ) : (
          <div className={styles.no_chat_selected}>
            <div className={styles.select_chat_box}>
              <p className={styles.select_chat_text}>Select a Chat</p>
              <img
                className={styles.select_chat_image}
                src="/images/NoSelectedChatIcon.svg"
                alt="no chat"
              ></img>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
