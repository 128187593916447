import React from "react";
import styles from "../Opportunities/Opportunities.module.css";

export const Loader = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "15rem",
    }}
  >
    <img src="/svgs/loader.svg" alt="loader" className={styles.loader} />
  </div>
);
