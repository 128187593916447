/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable require-jsdoc */
import React, { Fragment, useEffect, useState } from "react";
import { size } from "lodash";
import { NavLink } from "react-router-dom";
import { Grid, Tab, Tabs } from "@mui/material";
import { Box, Button, Typography } from "@material-ui/core";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ListItemText from "@material-ui/core/ListItemText";

import styles from "./Opportunities.module.css";
import universalStyles from "../../UniversalComponents/universal.module.css";

import OpportunityCard from "./OpportunityCard";
import OutlineButton from "../AdditionalComponents/OutlineButton";
import { StyledMenu, StyledMenuItem } from "../AdditionalComponents/StyledMenu";
import Setting from "../AdditionalComponents/utils/Setting.js";
import MultiModal from "../../smartlink/common/MultiModal/MultiModal";
import useAuth from "../../../context/AuthContext";
import { useDebounce } from "../../../hooks/useDebounce";
import { GENRE } from "../../../shared/constants";
import useAppState from "../../../context/useAppState";

import { useMediaQuery } from "@material-ui/core";

import { getFeedOpportunityApi } from "../../../context/apis/opportunity";
import allImages from "../../../assets";

import CircularLoader from "../../UniversalComponents/CircularLoader/CircularLoader";
import CircularLoaderPage from "../../UniversalComponents/CircularLoader/CircularLoaderPage";
import { PaginationMain } from "../../smartlink/index.js";
import { crown } from "../../../assets/smartlink/index.js";
const sortData = [
  {
    id: 1,
    title: "Most Recent",
    key: "createdAt",
    order: -1,
  },
  {
    id: 2,
    title: "Most Upvotes",
    key: "upVotes",
    order: -1,
  },
  {
    id: 3,
    title: "Monthly Listeners (High - Low)",
    key: "monthlyListeners",
    order: -1,
  },
  {
    id: 4,
    title: "Monthly Listeners (Low - High)",
    key: "monthlyListeners",
    order: 1,
  },
];

export const getAllMetaFields = (metafields) => {
  const obj = {};

  for (let i = 0; i < metafields?.length; i++) {
    if (metafields[i].key === "collaborator") {
      console.log("meta fields...", metafields[i]);
      if (metafields[i].valueType && metafields[i].valueType !== "undefined") {
        let arr = {};
        arr["collab_id"] = metafields[i].valueType;
        arr["userName"] = metafields[i].value;
        arr["profilePic"] = metafields[i].description;
        if (obj["collaborators"]) {
          obj["collaborators"] = [...obj["collaborators"], arr];
        } else {
          obj["collaborators"] = [arr];
        }
      }
    } else if (metafields[i].key === "artist") {
      obj["artistPhoto"] = metafields[i].description;
      obj["artistName"] = metafields[i].value;
      obj["artistStatus"] = metafields[i].valueType;
    } else {
      obj[metafields[i].key] = metafields[i].value;
    }
  }
  return obj;
};

const PAGE_LIMIT = 10;

const Opportunities = () => {
  const isDesktop = useMediaQuery("(min-width: 1200px)");

  // ============ custom pagination =====
  const [currentPage, setcurrentPage] = useState(1);
  const [TotalPages, setTotalPages] = useState(0);
  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };
  // ============ End custom pagination =====

  const [anchorEl, setAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState(sortData[0]);
  const [sortAnchor, setSortAnchor] = useState(null);

  const [filter, setFilter] = useState("");

  const [feedsLoading, setFeedsLoading] = useState(false);
  const [feedsOpportunityData, setFeedsOpportunityData] = useState([]);
  const [hasNextFeedsPage, setHasNextFeedsPage] = useState(true);
  const [feedsPage, setFeedsPage] = useState(0);

  const [loading, setLoading] = useState(false);
  const [myOpportunityData, setMyOpportunityData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [page, setPage] = useState(0);

  const [error, setError] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [query, setQuery] = useState("");
  const searchQuery = useDebounce(query, 500);

  const { user, userPrivileges } = useAuth();
  const { fetchMyOpportunityByQuery } = useAppState("opportunity");

  // remaing counts
  const [PaymentOpen, setPaymentOpen] = useState(false);
  const [remaingcounts, setremaingcounts] = useState({});

  const [RemaingLoading, setRemaingLoading] = useState(false);
  // const canCreate = userPrivileges?.privileges[0].isCreate;

  let canCreate;

  if (user.roleId.roleName === "Admin") {
    canCreate = true;
  } else if (user.roleId.roleName === "Music Producer") {
    canCreate = userPrivileges?.privileges[0]?.isCreate || false;
  } else {
    canCreate = true;
  }

  useEffect(() => {
    if (tabIndex === 0) {
      setFeedsLoading(true);
      const queryString = new URLSearchParams({
        id: user._id,
        page: currentPage,
        limit: PAGE_LIMIT,
        search: searchQuery,
        sort: sortBy.key,
        order: sortBy.order,
        genre: filter,
      }).toString();

      getFeedOpportunityApi(queryString)
        .then((response) => {
          if (response?.data) {
            const newRows = response?.data || [];
            const TotalPages =
              response?.totalPages !== null &&
              response?.totalPages !== undefined &&
              response?.totalPages > 1
                ? response?.totalPages
                : 1;
            setTotalPages(TotalPages || 1);
            // setHasNextFeedsPage(newRows.length < response.totalCount);
            setFeedsOpportunityData(newRows);
            // setFeedsPage(1);
          }
        })
        .finally(() => {
          setFeedsLoading(false);
          setIsRefresh(false);
        });
    }

    // get my OpportunityBy
    if (tabIndex === 1 && user.roleId.roleName !== "Music Producer") {
      setLoading(true);
      const myQueryString = new URLSearchParams({
        id: user._id,
        page: currentPage,
        limit: PAGE_LIMIT,
        search: searchQuery,
        sort: sortBy.key,
        order: sortBy.order,
        genre: filter,
      }).toString();

      fetchMyOpportunityByQuery(myQueryString)
        .then((response) => {
          console.log("🚀 ~ .then ~ response:", response);
          const newRows = response?.data || [];

          const TotalPages =
            response?.totalPages !== null &&
            response?.totalPages !== undefined &&
            response?.totalPages > 1
              ? response?.totalPages
              : 1;

          setTotalPages(TotalPages || 1);

          // if (response?.totalCount) {
          //   setHasNextPage(newRows.length < response.totalCount);
          // } else {
          //   setHasNextPage(false);
          // }
          setMyOpportunityData(newRows);
          // setPage(1);
        })
        .finally(() => {
          setLoading(false);
          setIsRefresh(false);
        });
    }
  }, [searchQuery, sortBy, filter, isRefresh, currentPage, tabIndex]);

  useEffect(() => {
    setFeedsPage(0);
    setPage(0);
    setcurrentPage(1);
  }, [filter, sortBy]);

  // useEffect(() => {
  //   switch (tabIndex) {
  //     case 0:
  //       getMoreFeedsData(true);
  //       break;
  //     case 1:
  //       getMyOpportunityData(true);
  //       break;
  //     default:
  //       break;
  //   }
  // }, [tabIndex]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortClick = (event) => {
    setSortAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortClose = () => {
    setSortAnchor(null);
  };

  const applyFilter = (value) => {
    if (value === filter) {
      setFilter("");
      setAnchorEl(null);
    } else {
      setFilter(value);
      setAnchorEl(null);
    }
  };

  const applySorting = (value) => {
    if (value.id === sortBy.id) {
      setSortBy(sortData[0]);
      setSortAnchor(null);
    } else {
      setSortBy(value);
      setSortAnchor(null);
    }
  };

  // feed
  // const getMoreFeedsData = async (isFirstPage) => {
  //   setFeedsLoading(true);
  //   try {
  //     const newPage = isFirstPage ? 1 : feedsPage + 1;

  //     const queryString = new URLSearchParams({
  //       id: user._id,
  //       page: newPage,
  //       limit: PAGE_LIMIT,
  //       search: searchQuery,
  //       sort: sortBy.key,
  //       order: sortBy.order,
  //       genre: filter,
  //     }).toString();
  //     getFeedOpportunityApi(queryString)
  //       .then((response) => {
  //         const newRows = [...feedsOpportunityData, ...response.data];

  //         setHasNextFeedsPage(newRows.length < response.totalCount);
  //         setFeedsOpportunityData(newRows);
  //         setFeedsPage(newPage);
  //       })
  //       .catch((e) => {
  //         setError(e);
  //       })
  //       .finally(() => {
  //         setFeedsLoading(false);
  //       });
  //   } catch (err) {
  //     setError(true);
  //   }
  // };

  // const getMyOpportunityData = (isFirstPage) => {
  //   setLoading(true);
  //   const newPage = isFirstPage ? 1 : page + 1;
  //   const queryString = new URLSearchParams({
  //     id: user._id,
  //     page: newPage,
  //     limit: PAGE_LIMIT,
  //     search: searchQuery,
  //     sort: sortBy.key,
  //     order: sortBy.order,
  //     genre: filter,
  //   }).toString();

  //   fetchMyOpportunityByQuery(queryString)
  //     .then((response) => {
  //       const newRows = [...myOpportunityData, ...response.data];

  //       console.info("response.totalCount User=> ", response.totalCount);

  //       setHasNextPage(newRows.length < response.totalCount);
  //       setMyOpportunityData(newRows);
  //       setPage(newPage);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const handleTabChange = (event, newTabIndex) => {
    setPage(0);
    setcurrentPage(1);
    setTabIndex(newTabIndex);
    setMyOpportunityData([]);
    setFeedsOpportunityData([]);
  };

  const onRefresh = () => {
    setIsRefresh((prev) => !prev);
  };

  const onDelete = (id) => {
    const data = [...myOpportunityData];
    const index = data.findIndex(({ _id }) => _id === id);

    if (index >= 0) {
      data.splice(index, 1);
      setMyOpportunityData(data);
    }
  };

  // const fetchFeedsMore = () => {
  //   if (feedsLoading) return;
  //   getMoreFeedsData();
  // };

  // const fetchMore = () => {
  //   if (loading) return;
  //   getMyOpportunityData();
  // };

  // const [sentryRef] = useInfiniteScroll({
  //   loading,
  //   hasNextPage,
  //   onLoadMore: fetchMore,
  //   disabled: !!error,
  //   rootMargin: "0px 0px 400px 0px",
  // });

  // const [sentryFeedRef] = useInfiniteScroll({
  //   loading: feedsLoading,
  //   hasNextPage: hasNextFeedsPage,
  //   onLoadMore: fetchFeedsMore,
  //   disabled: !!error,
  //   rootMargin: "0px 0px 400px 0px",
  // });

  const layoutType = isDesktop ? styles.main_layout : styles.main_layout_mobile;
  const titleType = isDesktop ? styles.page_title : styles.page_title_mobile;

  // get the user plan
  const isPlanNameAvailable = user?.planId?.planName;

  const IsFree =
    isPlanNameAvailable === "Free" || isPlanNameAvailable === "Freemium";
  
    const roleName = user.roleId?.roleName;


  // get the remaing Opportunity

  useEffect(() => {
    GetAllRemaingOpportunityData();
  }, []);

  const GetAllRemaingOpportunityData = () => {
    setRemaingLoading(true);
    const newPage = 1;
    const queryString = new URLSearchParams({
      id: user._id,
      page: newPage,
      limit: PAGE_LIMIT,
      search: searchQuery,
      sort: sortBy.key,
      order: sortBy.order,
      genre: filter,
    }).toString();

    fetchMyOpportunityByQuery(queryString)
      .then((response) => {
        if (response.message == "Data found") {
          setremaingcounts({
            submissionsCount: response?.submissionsCount || 0,
            totalCount: response?.totalCount || 0,
          });
        }
      })
      .finally(() => {
        setRemaingLoading(false);
      });
  };

  return (
    <>
      <Box
        className={layoutType}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box
          className={styles.parent_wrapper}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box
            className={styles.titleWrapper}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p className={titleType}>Opportunities</p>
            <Button className={styles.filterBtn2} onClick={handleClick}>
              <img src="/images/setting.png" alt="" />
            </Button>
          </Box>

          <Box className={`${styles.buttonGroup} ${styles.buttonsWrapper}`}>
            {!RemaingLoading && user.roleId.roleName === "Music Producer" && (
              <div className={`${styles.RemainingLinks}`}>
                {IsFree && roleName=== "Music Producer" &&
                (remaingcounts?.totalCount !== "unlimited" ||
                  remaingcounts?.submissionsCount !== "unlimited")
                  ? `Remaining Submissions: ${
                      parseInt(3) -
                      parseInt(remaingcounts?.submissionsCount)
                    }`
                  : null}
              </div>
            )}
            <div className={styles.refreshBtn} onClick={onRefresh}>
              <img
                src={allImages.refresh}
                className={`${isRefresh ? styles.loading : ""}`}
                alt="refresh"
              />
            </div>
            <div
              className={`${styles.filterBtn1} ${
                anchorEl && styles.filterActive
              }`}
              onClick={handleClick}
            >
              <Setting />
            </div>
            <StyledMenu
              id="customized-menu1"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {GENRE.map((genre, index) => (
                <StyledMenuItem
                  key={index}
                  className={filter === genre ? "active" : ""}
                >
                  <ListItemText
                    primary={genre}
                    onClick={(e) => applyFilter(genre)}
                    style={{ textAlign: "center" }}
                  />
                </StyledMenuItem>
              ))}
            </StyledMenu>
            <OutlineButton
              title="Sort By"
              handler={handleSortClick}
              filter={sortAnchor}
            />
            <StyledMenu
              id="customized-menu2"
              anchorEl={sortAnchor}
              keepMounted
              open={Boolean(sortAnchor)}
              onClose={handleSortClose}
            >
              {sortData.map(({ id, key, title, order }) => (
                <StyledMenuItem
                  key={id}
                  className={sortBy.id === id ? "active" : ""}
                >
                  <ListItemText
                    primary={title}
                    onClick={(e) => applySorting({ order, key, id })}
                    style={{ textAlign: "center" }}
                  />
                </StyledMenuItem>
              ))}
            </StyledMenu>
            {user.roleId.roleName !== "Music Producer" && (
              <NavLink to={"/opportunities/create"}>
                <div className={styles.create_button}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <path
                      className={styles.plus_icon}
                      d="M15 25H35M25 15V35"
                      // stroke="#fff"
                      // stroke-opacity="0.6"
                      // stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </NavLink>
            )}
            {!RemaingLoading &&
              user.roleId.roleName == "Music Producer" &&
              (remaingcounts?.submissionsCount === 3 && (
                <div
                  className={styles.create_button}
                  onClick={() => setPaymentOpen(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <path
                      className={styles.plus_icon}
                      d="M15 25H35M25 15V35"
                      stroke="#fff"
                      stroke-opacity="0.6"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              ) )
             }
          </Box>
        </Box>

        <Box sx={{ padding: "15px 0px 10px 0" }}>
          <div className={universalStyles.search_box}>
            <img
              className={universalStyles.search_icon}
              src="/v2Assets/connect_assets/Search.png"
              alt="search"
            ></img>
            <input
              className={universalStyles.search_box_input}
              type="text"
              placeholder={"Search"}
              onChange={(data) => {
                setcurrentPage(1);
                // setQuery(data.target.value);
                setQuery(data.target.value.toLowerCase());
              }}
            />
          </div>
        </Box>

        <Box>
          <Box>
            {user.roleId.roleName !== "Music Producer" && (
              <Tabs
                sx={{
                  borderBottom: "1px solid rgba(46, 187, 85, 0.25);",
                  "& .MuiTab-root": {
                    color: "#AFAFAF",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#2ebb55",
                    color: "#2ebb55",
                    height: "2px",
                    borderRadius: "3px 3px 0px 0px",
                  },
                  "& .Mui-selected": {
                    color: "white !important",
                  },
                }}
                value={tabIndex}
                onChange={handleTabChange}
                classes={styles.tabWrapper}
              >
                <Tab
                  style={{ textTransform: "none" }}
                  label="Opportunitites Feed"
                  sx={{
                    color: "white",
                    fontSize: "1.2em",
                    "&:hover": {
                      color: "rgba(46, 187, 85, 0.75)",
                    },
                    "&.Mui-selected": {
                      color: "rgba(46, 187, 85, 1)",
                    },
                  }}
                />
                {user.roleId.roleName !== "Music Producer" && (
                  <Tab
                    style={{ textTransform: "none" }}
                    label="My Opportunities"
                    sx={{
                      marginLeft: "20px",
                      fontSize: "1.2em",
                      "&:hover": {
                        color: "rgba(46, 187, 85, 0.75)",
                      },
                      "&.Mui-selected": {
                        color: "rgba(46, 187, 85, 1)",
                      },
                    }}
                  />
                )}
              </Tabs>
            )}
          </Box>
        </Box>

        {user.roleId.roleName !== "Music Producer" && (
          <Box sx={{ padding: 4 }} className="non_producerMain">
            {tabIndex === 0 && (
              <Box>
                {!feedsLoading ? (
                  feedsOpportunityData?.length > 0 ? (
                    feedsOpportunityData.map((opportunity, key) => (
                      <Fragment key={key}>
                        <OpportunityCard
                          opportunityData={opportunity}
                          isSelf={false}
                        />
                      </Fragment>
                    ))
                  ) : (
                    !feedsLoading && (
                      <Box
                        style={{
                          marginTop: "100px",
                          display: "flex",
                          justifyContent: "center",

                          alignItems: "center",
                        }}
                      >
                        <p classes={styles.empty_page_text}>No Data Found</p>
                      </Box>
                    )
                  )
                ) : (
                  <CircularLoaderPage />
                )}

                {tabIndex === 0 && !feedsLoading && TotalPages > 1 && (
                  <>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <PaginationMain
                        pageCount={TotalPages}
                        page={currentPage}
                        handelChange={handlePageChange}
                      />
                    </Box>
                  </>
                )}
              </Box>
            )}
            {tabIndex === 1 && user.roleId.roleName !== "Music Producer" && (
              <Box>
                {!loading ? (
                  <>
                    {myOpportunityData?.length > 0
                      ? myOpportunityData.map((opportunity, key) => (
                          <Fragment key={key}>
                            <OpportunityCard
                              opportunityData={opportunity}
                              isSelf={true}
                              onDelete={onDelete}
                            />
                          </Fragment>
                        ))
                      : !loading && (
                          <Box
                            style={{
                              marginTop: "100px",
                              display: "flex",
                              justifyContent: "center",

                              alignItems: "center",
                            }}
                          >
                            <p classes={styles.empty_page_text}>
                              No Data Found
                            </p>
                          </Box>
                        )}
                  </>
                ) : (
                  <CircularLoaderPage />
                )}

                {tabIndex === 1 && !loading && TotalPages > 1 && (
                  <>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <PaginationMain
                        pageCount={TotalPages}
                        page={currentPage}
                        handelChange={handlePageChange}
                      />
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        )}

        {/* {user.roleId.roleName === "Music Producer" && (
          <Box className="producerMain">
            {size(feedsOpportunityData) > 0
              ? feedsOpportunityData.map((opportunity, key) => (
                  <Fragment key={key}>
                    <OpportunityCard
                      opportunityData={opportunity}
                      isSelf={false}
                    />
                  </Fragment>
                ))
              : !feedsLoading &&
                feedsOpportunityData?.length === 0 && (
                  <Box
                    style={{
                      marginTop: "100px",
                      display: "flex",
                      justifyContent: "center",

                      alignItems: "center",
                    }}
                  >
                    <p classes={styles.empty_page_text}>No Data Found</p>
                  </Box>
                )}

           {(feedsLoading || hasNextFeedsPage) && <CircularLoaderPage />} 

            {TotalPages > 1 && (
              <>
                <Box sx={{ marginBottom: "1rem" }}>
                  <PaginationMain
                    pageCount={TotalPages}
                    page={currentPage}
                    handelChange={handlePageChange}
                  />
                </Box>
              </>
            )}
          </Box>
        )} */}

        {/* add the custom pagination  */}

        {user.roleId.roleName === "Music Producer" && (
          <Box className="producerMain">
            {!feedsLoading && feedsOpportunityData.length != 0 ? (
              size(feedsOpportunityData) > 0 ? (
                feedsOpportunityData.map((opportunity, key) => (
                  <Fragment key={key}>
                    <OpportunityCard
                      opportunityData={opportunity}
                      isSelf={false}
                    />
                  </Fragment>
                ))
              ) : (
                <Box
                  style={{
                    marginTop: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p classes={styles.empty_page_text}>No Data Found</p>
                </Box>
              )
            ) : (
              <CircularLoaderPage />
            )}

            {!feedsLoading && TotalPages > 1 && (
              <>
                <Box sx={{ marginBottom: "1rem" }}>
                  <PaginationMain
                    pageCount={TotalPages}
                    page={currentPage}
                    handelChange={handlePageChange}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>

      <MultiModal
        open={PaymentOpen}
        heading="Upgrade Your Plan"
        text="You have exceeded your limit of submissions for this month. Upgrade your membership now to submit to unlimited opportunities and unlock exclusive member-only features!"
        type="link"
        title="Upgrade"
        RedirectTo="/beat-inbox/more-storage"
        setPaymentOpen={setPaymentOpen}
      />
    </>
  );
};

export default Opportunities;
