import { Grid } from '@mui/material';
import { Button, HStack, Heading } from 'native-base';
import React, { useEffect, useRef, useState } from 'react'
import { AiFillPauseCircle, AiFillPlayCircle } from 'react-icons/ai';
import Download from './Svgs/Download';
import { onSoundPackDownload } from '../../Graphql_services/SoundPackMutation';
import { useMutation } from '@apollo/client';
import styles from './styles.module.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import WaveSurfer from 'wavesurfer.js';
const formWaveSurferOptions = (ref) => ({
	container: ref,

	responsive: true,
	height: 0,
	// If true, normalize by the maximum peak instead of 1.0.
	normalize: true,
	// Use the PeakCache to improve rendering speed of large waveforms.
	partialRender: true,
});

function PurchaseSoundCard(props) {
	const [playing, setPlay] = useState(false);
	const [isLoaded, setIsLoaded] = useState(true);
	const [volume, setVolume] = useState(0.5);
    const wavesurfer = useRef(null);
	const waveformRef = useRef(null);
    const navigate = useNavigate();
    const { data, setSoundId, soundId,index } = props;
	const {
		soundPackCover,
		soundPackTitle,
		soundPackFile,
		soundPackZipFile,
		tags,
	} = data;
	

    const [OnSoundPackDownload] = useMutation(onSoundPackDownload, {
		context: { clientName: 'soundpack' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			if (!data?.onSoundPackDownload?.isTokenExpired) {
				if (data?.onSoundPackDownload?.success) {
					console.log('success onSoundPackDownload');
				} else {
					console.log('error onSoundPackDownload');
				}
			} else {
				localStorage.clear();
				navigate('/signin');
			}
		},
		onError(err) {
			console.log('error on getting user info', err);
		},
	});

    const downloadAudio = (url) => {
		toast.success('Download in progess');
		fetch(url)
			.then((res) => res.blob())
			.then((file) => {
				const fileName = url.split(
					'https://du6zmi1l7ys6.cloudfront.net/fileupload'
				);
				if (fileName.length > 1) {
					const filename = fileName[1];
					let tempUrl = URL.createObjectURL(file);
					const aTag = document.createElement('a');
					aTag.href = tempUrl;
					aTag.download = filename;
					document.body.appendChild(aTag);
					aTag.click();
					aTag.remove();
					toast.success('Downloaded Successfully');
					OnSoundPackDownload({
						variables: {
							soundPackId: data._id,
						},
					});
				} else {
					toast.error('Something went wrong');
				}
			})
			.catch((err) => {
				// console.log("error......", err);
				toast.error('Audio could not download successfully.');
			});
	};
    const handlePlayPause = () => {
        if(!isLoaded) {
          setPlay(!playing);
          wavesurfer.current.playPause();
        }
      }
	const handleDownload = () => {
		downloadAudio(soundPackZipFile ? soundPackZipFile : soundPackFile);
	};


    useEffect(() => {
		if (soundPackFile) {
			setPlay(false);

			const options = formWaveSurferOptions(waveformRef.current);
			wavesurfer.current = WaveSurfer.create(options);
			wavesurfer.current.load(soundPackFile);

			wavesurfer.current.on('ready', function () {
				if (wavesurfer.current) {
					wavesurfer.current.setVolume(volume);
					setVolume(volume);
					setIsLoaded(false);
				}
			});

			// Removes events, elements and disconnects Web Audio nodes.
			// when component unmount
			return () => wavesurfer.current.destroy();
		}
	}, [soundPackFile]);

  return (
        <Grid key={index} container className={styles.purchases_content_wrapper} >
            <div ref={waveformRef} style={{display:'none'}} />
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            width={'100%'}
            className={styles.soundPackBasic}
          >
            {/* <HStack w={'100%'} space={4}> */}
            <img
              src={soundPackCover}
              className={styles.purchases_soundPackCover}
              alt={'cover'}
            />
            {/* <VStack alignSelf={'center'}> */}
            <div>
              <Heading
                mb={2}
                textTransform={'capitalize'}
                fontFamily={'Poppins'}
                fontSize={{ lg: '20px', base: '16px' }}
                fontWeight={'500'}
                textAlign={'left'}
                color={'#fff'}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {soundPackTitle}
              </Heading>
              
              {tags.length > 0 && (
            <HStack w={'100%'} mt={{ '2xl': 2, lg: 2, base: 3 }}>
                <Heading
                    alignSelf={'center'}
                    fontFamily={'Poppins'}
                    fontSize={{ '2xl': '16px', lg: '14px', base: '14px' }}
                    fontWeight={'normal'}
                    textAlign={'left'}
                    color={'#afafaf'}
                >
                    {tags.filter((obj) => obj.type === 'Genre').length != 0
                        ? 'Genres:'
                        : ' '}
                </Heading>
                <HStack px={{ '2xl': 3, lg: 2, base: 2 }} w={'100%'}>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            width: '90%',
                        }}
                    >
                        {tags.filter((obj) => obj.type === 'Genre').length != 0 &&
                            tags
                                .filter((obj) => obj.type === 'Genre')
                                .map((item, index) => {
                                    return (
                                        <Heading
                                            key={index}
                                            alignSelf={'center'}
                                            fontFamily={'Poppins'}
                                            fontSize={{ '2xl': '16px', lg: '14px', base: '14px' }}
                                            fontWeight={'normal'}
                                            textAlign={'left'}
                                            color={'#fff'}
                                        >
                                            {index != 0 && '|'}
                                            <Heading
                                                alignSelf={'center'}
                                                fontFamily={'Poppins'}
                                                fontSize={{
                                                    '2xl': '16px',
                                                    lg: '14px',
                                                    base: '14px',
                                                }}
                                                fontWeight={'normal'}
                                                textAlign={'left'}
                                                color={'#fff'}
                                            >
                                                {' ' + item.value + ' '}
                                            </Heading>
                                        </Heading>
                                    );
                                })}
                    </div>
                </HStack>
            </HStack>
        )}
            </div>
          </Grid>
          <HStack space={'8'} marginRight={"10px"}
            justifyContent={'space-between'}
            mt={{ '2xl': 7, lg: 5, base: 5 }}
        >
  <HStack space={3} >
                <HStack space={1.5} alignSelf={'center'}	style={{
                                    cursor: 'pointer',
                                    alignSelf: 'center',
                                    color: '#AFAFAF',
                                }} >
                    <Download  onclick={handleDownload} style={{width: "42px",
height: "42px"}}/>
                </HStack>
            </HStack>
            <HStack alignSelf={'center'}>
                {soundPackFile && (
                    <Button
                        alignSelf={'center'}
                        variant={'ghost'}
                        p={0}
                        _hover={{ bg: 'transparent' }}
                        _pressed={{ bg: 'transparent' }}
                        onPress={() => {
                            if(data._id){
                                setSoundId(data._id);
                                handlePlayPause();
                            }
                        }}
                    >
                        {!playing ? (
                            <AiFillPlayCircle
                                size={30}
                                style={{
                                    cursor: 'pointer',
                                    alignSelf: 'center',
                                    color: '#AFAFAF',
              width: "42px",
              height: "42px"
                                }}
                            />
                        ) : (
                            <AiFillPauseCircle
                                size={30}
                                style={{
                                    cursor: 'pointer',
                                    alignSelf: 'center',
                                    color: '#AFAFAF',
              width: "42px",
              height: "42px"
                                }}
                            />
                        )}
                    </Button>
                )}
            </HStack>
            
        </HStack>
        </Grid>
  )
}

export default PurchaseSoundCard
