import React from "react";
import { CardMainBox } from "./styles";
import { Box, Typography } from "@mui/material";

const AnalyticsCard = ({ value = "", title }) => {
  return (
    <Box sx={{ marginTop: "2rem" }}>
      <CardMainBox>
        <Typography variant="h5">{title}</Typography>
        <span>{value}</span>
      </CardMainBox>
    </Box>
  );
};

export default AnalyticsCard;
