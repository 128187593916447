import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import styles from "./connect.module.css";

import { toast } from "react-toastify";
import useAppState from "../../context/useAppState";
import useAuth from "../../context/AuthContext";

export default function ConnectMeCard(props) {
  const { user } = useAuth();
  const selfUsername = user.userName;
  const userId = user._id;
  const profile = props.user;

  const navigate = useNavigate();

  const { sendRequest, recindRequest, acceptRequest } =
    useAppState("connectMe");

  const { sendChatMessage } = useAppState("chat");

  const [message, setMessage] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);

  function sendSubmission() {
    navigate(`/connect/apply/${profile._id}`, {});
  }

  function visitUserProfile() {
    navigate(`/profile/${profile._id}`, {});
  }

  const sendConnectionRequest = async () => {
    profile.connectionStatus = "Outgoing";

    props.onConnectionStatusChanged({ ...profile });

    try {
      await sendRequest(userId, profile._id);
      toast.success("Request sent successfully");
    } catch (error) {
      profile.connectionStatus = "None";
      props.onConnectionStatusChanged({ ...profile });
      toast.error("Failed to send the connection request.");
    }
  };

  const recindConnectionRequest = async () => {
    profile.connectionStatus = "None";

    props.onConnectionStatusChanged({ ...profile });

    try {
      await recindRequest(userId, profile._id);
    } catch (error) {
      profile.connectionStatus = "Outgoing";
      props.onConnectionStatusChanged({ ...profile });
      toast.error("Failed to send the connection request.");
    }
  };
  //chatId, content, sender
  // content: `${getUserName(user)} accepted your connection request.`,

  const acceptConnectionRequest = async () => {
    console.log(profile);
    profile.connectionStatus = "Accepted";
    props.onConnectionStatusChanged({ ...profile });
    try {
      await acceptRequest(userId, profile._id);
      await sendChatMessage(
        profile.chatId,
        `${selfUsername} accepted your connection request.`,
        userId
      );
    } catch (error) {
      profile.connectionStatus = "Incoming";
      props.onConnectionStatusChanged({ ...profile });
      toast.error("Failed to accept the connection request.");
    }
  };

  const user_name = profile?.name
    ? profile?.name?.firstName + " " + profile?.name?.lastName
    : profile?.userName
    ? profile?.userName
    : "Guest";

  const ConnectionControls = () => {
    switch (profile.connectionStatus) {
      case "Outgoing":
        return (
          <>
            <div className={styles.submit_form_button} onClick={sendSubmission}>
              <img
                className={styles.send_music_icon}
                src="/v2Assets/connect_assets/SendMusicIcon.png"
                alt="send"
              />
            </div>
            <div
              className={styles.submit_form_button}
              onClick={recindConnectionRequest}
            >
              <img
                className={styles.send_music_icon}
                src="/v2Assets/connect_assets/ConnectMeSentIcon.png"
                alt="sent"
              />
            </div>
          </>
        );
      case "Incoming":
        return (
          <>
            <div
              className={styles.connection_controls_accept}
              onClick={acceptConnectionRequest}
            >
              <img
                className={styles.control_icon}
                src="/v2Assets/connect_assets/accept.png"
                alt="accept"
              ></img>
            </div>
            <div
              className={styles.connection_controls_reject}
              onClick={recindConnectionRequest}
            >
              <img
                className={styles.control_icon}
                src="/v2Assets/connect_assets/reject.png"
                alt="accept"
              ></img>
            </div>
          </>
        );
      case "Accepted":
        return (
          <div className={styles.submit_form_button} onClick={sendSubmission}>
            <img
              className={styles.send_music_icon}
              src="/v2Assets/connect_assets/SendMusicIcon.png"
              alt="Send Audio"
            />
          </div>
        );
      case "None":
      default:
        return (
          <>
            <div className={styles.submit_form_button} onClick={sendSubmission}>
              <img
                className={styles.send_music_icon}
                src="/v2Assets/connect_assets/SendMusicIcon.png"
                alt="send"
              />
            </div>
            <div
              className={styles.submit_form_button}
              onClick={sendConnectionRequest}
            >
              <img
                className={styles.send_music_icon}
                src="/v2Assets/connect_assets/ConnectIcon.png"
                alt="connect"
              />
            </div>
          </>
        );
    }
  };

  return (
    <div>
      <div className={styles.profile_card}>
        <div className={styles.profile_card_top_info}>
          <div className={styles.artist_info}>
            <div className={styles.artist_title}>
              <p className={styles.artist_name} onClick={visitUserProfile}>
                {user_name}

                {profile._id === userId && <span> (You)</span>}
              </p>
              {profile?.verified && (
                <img
                  className={styles.verified_icon}
                  src="/v2Assets/connect_assets/verify.png"
                  alt="verified"
                />
              )}
            </div>
            <p className={styles.artist_genre}>{profile?.roleId.roleName}</p>
          </div>
          {profile._id !== userId && (
            <div className={styles.connect_control_buttons}>
              <ConnectionControls />
            </div>
          )}
        </div>
        <div className={styles.profile_card_controls}>
          <div className={styles.top_left_content}></div>
          <div className={styles.top_right_content}></div>
          <div className={styles.profile_card_image_box}>
            <img
              className={styles.profile_card_image}
              src={profile.profilePic}
              alt="profile"
              // style={{ backgroundImage: `url(${profile.profilePic})` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
