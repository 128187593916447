import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AudioPlayerWrapper = styled(Box)(({ theme }) => ({
  padding: "20px 23px",
  background: "#0A0B0A",
  position: "fixed",
  width: "calc(100% - 280px)",
  left: "300px",
  bottom: 0,
}));
