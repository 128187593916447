import React from "react";
import { Main } from "./styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const TableMain = ({ TableHeadData = [], BodyData = [] }) => {
  return (
    <Main>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {TableHeadData?.map((value) => {
                return (
                  <>
                    <TableCell key={value.title}> {value.title}</TableCell>
                  </>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {BodyData?.map((row, index) => {
              return (
                <>
                  <TableRow key={index}>
                    {Object?.entries(row)?.map(([key, value], cellIndex) => {
                      return (
                        <>
                          <TableCell key={cellIndex + key} className={`${key}`}>
                            {(() => {
                              if (key === "Link") {
                                return (
                                  <div className="link_main">
                                    <img src={value?.image} alt={value.title} />
                                    <div className="link_title">
                                      <strong>{value?.title || "N/A"}</strong>
                                      <p>{value?.desc || "N/A"}</p>
                                    </div>
                                  </div>
                                );
                              }
                              return value || "N/A";
                            })()}
                          </TableCell>
                        </>
                      );
                    })}
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Main>
  );
};

export default TableMain;
