import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import ViewSubmissionDetails from "../../components/ViewSubmissionComponents/ViewSubmissionDetails/ViewSubmissionDetails";
import useAuth from "../../context/AuthContext";
import { ROLES } from "../../shared/constants";

const ViewSubmissionDetailsPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user && get(user, "roleId.roleName", "") === ROLES.MUSIC_PRODUCER) {
      navigate("/opportunities");
    }
  }, [user]);

  return (
    <Grid>
      <Sidebar
        childComponent={<ViewSubmissionDetails />}
        activeText="ViewSubmissionDetailsPage"
      />
    </Grid>
  );
};

export default ViewSubmissionDetailsPage;
