import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { capitalize } from "lodash";

const NotifyIconMain = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "60px",
  height: "60px",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  "& .circle_box": {
    display: "flex",
    width: "48px",
    height: "48px",
    overflow: "hidden",
    alignItems: "Center",
    justifyContent: "Center",
    borderRadius: "100%",
    border: "1px solid #AFAFAF",
  },
  span: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "5px",
    background: "#FF0000",
    borderRadius: "100px",
    zIndex: 99999,
  },
}));
const NotificationsMainBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  padding: "24px 5px 40px 5px",
  gap: "36px",
  borderBottom: "1px solid #323333",
  "& .right-main": {
    width: "100%",
    "& .notify_title": {
      color: "#FFFFFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      margin: 0,
      marginBottom: "12px",
    },
    "& .notify_desc": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      margin: 0,
      "@media (min-width: 1200px)": {
        paddingRight: "250px",
      },
    },
    "& .btn-main": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: "20px",
      span: {
        color: "#AFAFAF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },
      "& .btn-box": {
        display: "flex",
        alignItems: "Center",
        gap: "12px",
        marginRight: "5px",
        button: {
          padding: "9px 27px 9px 28px",
          color: "#FFFFFF",
          fontFamily: theme.typography.fontFamily,
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          borderRadius: "5px",
          background: "#2EBB55",
          textTransform: "capitalize",
        },
        "& .btn_cancel": {
          borderRadius: "5px",
          border: "1px solid #AFAFAF",
          background: "transparent",
          padding: "6px 10px",
        },
      },
    },
  },
}));
const AccordianMain = styled(Box)(({ theme }) => ({
  display: "flex",
  "& .acordian": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    background: "#141515",
    borderBottom: "1px solid #414241",
    "&.Mui-expanded": {
      borderBottom: "1px solid #2EBB55",
    },
    "& .MuiAccordionSummary-root": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "34px 0 32px 0",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    h5: {
      color: "#FFFFFF",
      textAlign: "right",
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "30px",
      margin: 0,
    },
    h6: {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "30px",
      margin: 0,
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
      margin: 0,
      paddingBottom: "23px",
    },
  },
}));

const SliderMainBox = styled(Box)(({ theme }) => ({
  "& .img_box": {
    position: "relative",
    perspective: "1000px",
    padding: "0px 15px",
    transition: "transform 0.9s",
    transformStyle: "preserve-3d",
    height: "400px",
    width: "320px !important",
    "&:hover": {
      transform: "rotateY(180deg)",
    },
  },
  "& .img_box .slide-img": {
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    objectFit: "cover",
    overflow: "hidden",
    borderRadius: "10px",
  },
  "& .img_box .content_main": {
    width: "100% !important",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    transform: "rotateY(180deg)",
    borderRadius: "10px",
    background: "#1C1B1B",
    padding: "20px",
    boxSizing: "border-box",
    color: "#FFFFFF",
    backfaceVisibility: "hidden",
  },
  "& .bottom_box": {
    position: "absolute",
    bottom: "15px",
    right: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    textAlign: "end",
    padding: 0,
    margin: 0,
    h5: {
      color: "#AFAFAF",
      textAlign: "right",
      fontFamily: theme.typography.fontFamily,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "capitalize",
      margin: 0,
    },
    h6: {
      color: "#AFAFAF",
      textAlign: "right",
      fontFamily: theme.typography.fontFamily,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "capitalize",
      margin: 0,
    },
  },
}));
const OpportunityMain = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& .img_box": {
    width: "413px",
    height: "500px",
    display: "flex",
    borderRadius: "10px",
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "center",
    cursor: "pointer",
    position: "relative",
    transition: "background 0.3s ease",
    "&:hover": {
      "& .btn_box": {
        opacity: 1,
      },
    },
  },
  "& .btn_box": {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    background: "rgba(0, 0, 0, 0.70)",
    opacity: 0,
    transition: "opacity 0.6s ease",
    "& .btn": {
      padding: "8px 24px",
      borderRadius: "5px",
      background: "#2EBB55",
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
  },
  "& .desc_box": {
    width: "auto",
    position: "absolute",
    bottom: 20,
    paddingLeft: "20px",
    h5: {
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      margin: 0,
    },
    h6: {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      margin: 0,
    },
  },
}));

export {
  NotifyIconMain,
  NotificationsMainBox,
  AccordianMain,
  SliderMainBox,
  OpportunityMain,
};
