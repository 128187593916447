import React from "react";
import { image2 } from "../../../../assets/smartlink";
import { Main } from "./styles";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const RecentUpdate = () => {
  return (
    <Main>
      <h6>Recent Update</h6>
      <Box className="recent_update_main">
        <Box className="recent_update_list">
          <Box className="recent_update_images">
            <img src={image2} alt="image2" />
            <Box className="recent_update_images_tit">
              <strong>Untitled Link</strong>
              <p>Ron Freeman</p>
            </Box>
          </Box>
          <Box className="recent_update_time">
            <span>19 hour ago</span>
          </Box>
        </Box>
        <Box className="recent_update_list">
          <Box className="recent_update_images">
            <img src={image2} alt="image2" />
            <Box className="recent_update_images_tit">
              <strong>Untitled Link</strong>
              <p>Ron Freeman</p>
            </Box>
          </Box>
          <Box className="recent_update_time">
            <span>19 hour ago</span>
          </Box>
        </Box>
        <Box className="recent_update_list">
          <Box className="recent_update_images">
            <img src={image2} alt="image2" />
            <Box className="recent_update_images_tit">
              <strong>Untitled Link</strong>
              <p>Ron Freeman</p>
            </Box>
          </Box>
          <Box className="recent_update_time">
            <span>19 hour ago</span>
          </Box>
        </Box>
      </Box>
      <Box className="goto_link">
        <Link to="#">Go To Linked</Link>
      </Box>
    </Main>
  );
};

export default RecentUpdate;
