import React, { useContext, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { ADDSUBSCRIPTIONPLAN } from "./Mutation";
import { Notification } from "../../../NotifyMsg/Notification";
import useAuth from "../../../../context/AuthContext";
import { AuthContext } from "../../../Authentication/Layout";

function useAttachPlanMutation() {
  const {
    activeStep,
    setActiveStep,

    disableBtn,
    progressbarvalue,
    setProgressbarValue,
    setIsDisableBtn,
    sendotpAgain,
    setSendOtpAgain,
  } = useContext(AuthContext);
  const { setUser, setIsAuthenticated } = useAuth();
  const [attachPlan] = useMutation(ADDSUBSCRIPTIONPLAN, {
    onCompleted(data) {
      console.log(data);
      if (data?.attachPlan?.success) {
        localStorage.setItem("profilePic", data?.attachPlan?.user?.profilePic);
        localStorage.setItem("userName", data?.attachPlan?.user?.userName);
        localStorage.setItem(
          "accountType",
          data?.attachPlan?.user?.roleId?.roleName
        );
        localStorage.setItem("token", data?.attachPlan?.accessToken);
        localStorage.setItem("userId", data?.attachPlan?.user?._id);
        localStorage.setItem(
          "opportunityToken",
          data?.attachPlan?.reactionAccessToken
        );
        document.cookie = `sessionId=${data?.attachPlan?.sessionId};path=/;domain=unitedmarket.com`;
        document.cookie = `reactionAccessToken=${data.attachPlan?.reactionAccessToken};path=/;domain=unitedmarket.com`;
        setUser(data?.attachPlan?.user);
        setIsAuthenticated(true);
      } else {
        // console.log('success');
        // Notification('error', data.attachPlan.message);
        Notification("error", "Something went wrong");
        setActiveStep(activeStep);
        setProgressbarValue(progressbarvalue);
        setIsDisableBtn(false);
      }
    },
  });
  const [attachPlan_HowDoYouKnow] = useMutation(ADDSUBSCRIPTIONPLAN, {
    onCompleted(data) {
      console.log(data);
      if (data?.attachPlan?.success) {
        localStorage.setItem("profilePic", data?.attachPlan?.user?.profilePic);
        localStorage.setItem("userName", data?.attachPlan?.user?.userName);
        localStorage.setItem(
          "accountType",
          data?.attachPlan?.user?.roleId?.roleName
        );
        localStorage.setItem("token", data?.attachPlan?.accessToken);
        localStorage.setItem("userId", data?.attachPlan?.user?._id);
        localStorage.setItem(
          "opportunityToken",
          data?.attachPlan?.reactionAccessToken
        );
        document.cookie = `sessionId=${data?.attachPlan?.sessionId};path=/;domain=unitedmarket.com`;
        document.cookie = `reactionAccessToken=${data.attachPlan?.reactionAccessToken};path=/;domain=unitedmarket.com`;

        setUser(data?.attachPlan?.user);
        setIsAuthenticated(true);
        setTimeout(() => {
          setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setIsDisableBtn(false);
        }, 1400);
      } else {
        // console.log('success');
        // Notification('error', data.attachPlan.message);
        Notification("error", "Something went wrong");
        setActiveStep(activeStep);
        setProgressbarValue(progressbarvalue);
        setIsDisableBtn(false);
      }
    },
  });

  return { attachPlan, attachPlan_HowDoYouKnow };
}

export default useAttachPlanMutation;
