import React, { useEffect, useRef, useState } from "react";
import styles from "./connect.module.css";

import useAuth from "../../context/AuthContext";

import { useNavigate } from "react-router-dom";
import useAppState from "../../context/useAppState";
import { useDebounce } from "../../hooks/useDebounce";
import { size } from "lodash";
import { toast } from "react-toastify";

const hasUser = (id, users) => {
  return users.filter((user) => user._id === id).length > 0;
};
function ConnectMeNetwork({ query }) {
  const { user } = useAuth();
  const userId = user._id;

  const { loadUserNetwork, recindRequest } = useAppState("connectMe");
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  //   const [hasNextPage, setHasNextPage] = useState(true);
  const [error, setError] = useState(false);
  const searchQuery = useDebounce(query.trim(), 500);

  const PAGE_LIMIT = 12;

  useEffect(() => {
    setLoading(true);
    loadUserNetwork(userId)
      .then((response) => {
        console.log(response);
        const loadedConnectionRequests = response;
        setData(loadedConnectionRequests);
        // setHasNextPage(loadedUsers.hasNextPage);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    loadUserNetwork(userId, searchQuery)
      .then((response) => {
        console.log(response);
        const loadedConnectionRequests = response;
        setData(loadedConnectionRequests);
        // setHasNextPage(loadedUsers.hasNextPage);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchQuery]);

  const removeConnectionFromState = (connectionId) => {
    setData((prevData) => ({
      ...prevData,
      acceptedConnections: prevData.acceptedConnections.filter(
        (connection) => connection._id !== connectionId
      ),
    }));
  };

  const handleRemoveConnection = async (profileId, connectionId) => {
    try {
      await recindRequest(userId, profileId);
      removeConnectionFromState(connectionId);
      toast.success("Connection remove successfully");
    } catch (error) {
      console.error("Failed to rescind the connection request.", error);
    }
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            marginTop: 70,
            fontSize: "16px",
          }}
        >
          Loading...
        </div>
      ) : size(data.acceptedConnections) > 0 ? (
        data.acceptedConnections.map((acceptedConnection, index) => (
          <Profile
          key={`connectmeZ-${index}`}
            profile={acceptedConnection}
            onRemoveConnection={handleRemoveConnection}
          />
        ))
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            marginTop: 70,
            fontSize: "16px",
          }}
        >
          No users found.
        </div>
      )}
    </div>
  );
}

const Profile = (props) => {
  const userProfile = props.profile.participantDetails;
  const connectionID = props.profile._id;
  const chatId = props.profile.chatId;
  console.log("ChatId", chatId);
  console.log(userProfile);
  const navigate = useNavigate();
  const messagesPage = () => {
    navigate(`/chat/${chatId}`, {});
  };
  //   function visitUserProfile() {
  //     navigate(`/chat/${chatId}`, {});
  //   }

  function getUserName(profile) {
    if (profile?.firstname) {
      return profile.firstname + " " + profile.lastname;
    } else if (profile?.username) {
      return profile.username;
    } else {
      return "Guest";
    }
  }

  return (
    <div className={styles.user_profile}>
      <div className={styles.user_profile_layout}>
        <img
          src={userProfile?.profilePic}
          alt="profile"
          className={styles.user_profile_image}
        ></img>
        <div className={styles.user_profile_text}>
          <p className={styles.user_profile_name}>{getUserName(userProfile)}</p>
          <p className={styles.user_profile_genre}>{props.profile.genre}</p>
        </div>
      </div>
      <div className={styles.user_profile_layout}>
        {/* <div className={styles.pending_icon}>
          <p className={styles.pending_text}>Pending</p>
        </div> */}
        <div className={styles.profile_chat_button} onClick={messagesPage}>
          <img
            src="/v2Assets/SidebarIcons/SidebarChatIcon.png"
            alt="message"
            className={styles.network_icon}
          />
        </div>
        <MoreMenu
          userID={userProfile?._id}
          connectionID={connectionID}
          onRemoveConnection={props.onRemoveConnection}
        />
        {/* <div className={styles.profile_more_button}>
          <img
            src="/v2Assets/connect_assets/more_icon.png"
            alt="more"
            className={styles.network_icon}
          />
        </div> */}
      </div>
    </div>
  );
};

export default ConnectMeNetwork;

const MoreMenu = (props) => {
  const [connectionProfileMap, setConnectionProfileMap] = useState([]);

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();

  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const handleOpenUserMenu = () => {
    navigate(`/profile/${props.userID}`);
  };

  function onReject(connection) {
    setShowMenu(false);
    connection.declined = true;
    // updateConnection({ variables: { connection: connection } });
  }

  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener("mousedown", handleClickOutside);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.more_container} ref={menuRef}>
      <div onClick={toggleMenu} className={styles.profile_more_button}>
        <img
          src="/v2Assets/connect_assets/more_icon.png"
          alt="more"
          className={styles.network_icon}
        />
      </div>
      {showMenu && (
        <div className={styles.menu}>
          <div className={styles.menu_item} onClick={handleOpenUserMenu}>
            <p className={styles.menu_item_text}>View Profile</p>
          </div>
          <div
            className={styles.menu_item}
            onClick={() =>
              props.onRemoveConnection(props.userID, props.connectionID)
            }
          >
            <p className={styles.menu_item_text}>Remove Connection</p>
          </div>
          {/* <div className={styles.menu_item}>
            <p className={styles.menu_item_text}>Block User</p>
          </div> */}
        </div>
      )}
    </div>
  );
};
