import React, { useEffect, useRef, useState } from "react";
import markdownit from "markdown-it";
import {
  Box,
  Text,
  ScrollView,
  Heading,
  Flex,
  Icon,
  Button,
} from "native-base";
import { MrLoader } from "../../components-old/Loader/market_loader";
import { toast } from "react-toastify";

const MarketingAiPreview = ({ loading, textSummary, btnLoading }) => {
  const md = markdownit({
    html: false,
    linkify: true,
    typographer: true,
  });

  const scrollViewRef = useRef(null);

  useEffect(() => {
    if (scrollViewRef.current) {
      setTimeout(() => {
        scrollViewRef.current.scrollToEnd({ animated: true });
      }, 1000);
    }
  }, [textSummary,btnLoading]);

  const renderedText = React.useMemo(
    () => md.render(textSummary),
    [textSummary]
  );

  const handleCopyClick = (input) => {
    navigator.clipboard
      .writeText(input)
      .then(() => {
        toast.success("Copied to clipboard");
      })
      .catch((error) => {
        toast.error("Cant copy this");
      });
  };

  return (
    <>
      <Box
        w={{ xl: "63%", lg: "56%", md: "56%", base: "100%" }}
        rounded="8px"
        p={{ xl: "20px", lg: "20px", base: "14px" }}
        borderColor={"#414241"}
        borderWidth={1}
        mt={{ base: "24px", md: "0" }}
      >
        <div className="scrollview_box" style={{ display: "contents" }}>
          <ScrollView w={"100%"} h={"auto"} ref={scrollViewRef}>
            <Text
              fontFamily="poppins"
              textTransform="capitalize"
              color="#fff"
              fontSize={{ lg: "16px", md: "16px", base: "16px" }}
            >
              {loading ? (
                <>
                  {" "}
                  <Flex alignItems="end" direction="row">
                    {/* <Spinner size="sm" color="#2eba55" /> */}
                    <MrLoader />
                    <Heading color="#fff" fontSize="md" ml={2}>
                      Generating! Hold on please...
                    </Heading>
                  </Flex>
                </>
              ) : textSummary ? (
                <>
                  <p
                    className="genrate_para"
                    dangerouslySetInnerHTML={{ __html: renderedText }}
                  />
                  {!btnLoading && (
                    <Button
                      backgroundColor={"rgba(255,255,255,0.1)"}
                      onPress={() => handleCopyClick(textSummary)}
                    >
                      <svg
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="#fff"
                          d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z"
                        />
                      </svg>
                    </Button>
                  )}
                </>
              ) : (
                <p>
                  Input data you would like considered for your marketing plan.
                </p>
              )}
            </Text>
          </ScrollView>
        </div>
      </Box>
    </>
  );
};

export default MarketingAiPreview;
