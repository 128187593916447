import React, { useState, memo, useEffect } from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import // HorizontalMenu,
// MainTitle,
// BeatEmpty,
// CreateBeatInboxPopup,
// BeatsBox,
//   InboxBeats,
"../../../components/smartlink";
// import { image_placeholder_icon } from "../../../assets/smartlink";
import { Grid, Box } from "@mui/material";
import InboxBeats from "../../../components/smartlink/beatinbox/inboxbeat/InboxBeats";
import InboxBeatsHeader from "../../../components/smartlink/beatinbox/inboxbeat/InboxBeatsHeader";
import { GetAllinboxBeats } from "../../../Graphql_services/beatinbox/Beats";
import { useQuery } from "@apollo/client";
import { PaginationMain } from "../../../components/smartlink";
import { useParams } from "react-router-dom";

const SingleBeatsPage = () => {
  const { id, name } = useParams();

  const [currentPage, setcurrentPage] = useState(1);
  const [hasstatus, Sethasstatus] = useState("undefined");
  const [hassort, Sethassort] = useState("default");

  const inboxId = id;
  const perPage = 9;

  const { loading, error, data, refetch } = useQuery(GetAllinboxBeats, {
    variables: {
      inboxId,
      page: currentPage,
      perPage,
      status: hassort,
      splitPriceStatus: hasstatus,
    },
    context: { clientName: "beats" },
  });

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, hassort]);

  const [open, setOpen] = useState(false);
  const createModelOpen = () => {
    setOpen(!open);
  };
  const beatBoxes = data?.getBeats?.beats;

  const TotalBeats = data?.getBeats?.totalBeats;

  const totalPages = data?.getBeats?.totalPages;

  const allDownloadAbleBeats = data?.getBeats?.allDownloadAbleBeats;

  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };

  /// MultiAudio Functions

  const [currentAudio, setCurrentAudio] = useState(null);

  const handleToggle = (src) => {
    setCurrentAudio(src === currentAudio ? null : src);
  };

  return (
    <>
      <SmartLinkLayout>
        <InboxBeatsHeader
          totalBeat={TotalBeats > 0 ? TotalBeats : 0}
          data={allDownloadAbleBeats}
          Sethasstatus={Sethasstatus}
          Sethassort={Sethassort}
          Name={name}
          isloading={loading}
          refetch={refetch}
        />
        {loading && (
          <p
            style={{
              color: "#AFAFAF",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              textAlign: "center",
            }}
          >
            Loading...
          </p>
        )}
        <Box sx={{ marginTop: "25px" }}>
          <Grid container spacing={2}>
            {!loading && beatBoxes?.length > 0
              ? beatBoxes?.map((beatBox, index) => {
                  return (
                    <>
                      <Grid
                        item
                        sm={12}
                        md={6}
                        lg={4}
                        key={beatBox?._id}
                        sx={{ width: "100%" }}
                      >
                        <InboxBeats
                          index={index}
                          data={beatBox}
                          currentAudio={currentAudio}
                          handleToggle={handleToggle}
                        />
                      </Grid>
                    </>
                  );
                })
              : !loading && (
                  <>
                    <Grid item sm={12} lg={12}>
                      <h6
                        style={{
                          color: "#AFAFAF",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {name}
                      </h6>
                    </Grid>
                  </>
                )}
            {/* <Grid item sm={12} lg={4}>
              <InboxBeats />
            </Grid> */}
          </Grid>
          {!loading && totalPages > 1 && (
            <>
              <Box sx={{ marginBottom: "1rem" }}>
                <PaginationMain
                  pageCount={totalPages}
                  page={currentPage}
                  handelChange={handlePageChange}
                />
              </Box>
            </>
          )}
        </Box>
      </SmartLinkLayout>
    </>
  );
};

export default memo(SingleBeatsPage);
