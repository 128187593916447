import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme, selectedButton }) => ({
  h6: {
    fontFamily: theme.typography.fontFamily,
    color: "#fff",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginTop: "5px",
    marginBottom: "5px",
    textTransform: "capitalize",
  },

  //.......................Deleted Header styles Start.........................//
  "& .deleted_header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "30px",
    "& .btn_box": {
      display: "flex",
      alignItems: "center",
      gap: "14px",
      "& .files_btn": {
        padding: "10px 26px",
        borderRadius: "5px",
        color: "#AFAFAF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        textTransform: "capitalize",
        background: selectedButton === "files" ? "#2EBB55" : "#0E1210",
        "&.selected": {
          background: "#2EBB55",
          color: "#fff",
          "& img": {
            filter: "brightness(0) invert(1)",
          },
        },
      },
      "& .inboxes_btn": {
        padding: "10px 13px",
        borderRadius: "5px",
        color: "#AFAFAF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        textTransform: "capitalize",
        background: selectedButton === "inboxes" ? "#2EBB55" : "#0E1210",
        "&.selected": {
          background: "#2EBB55",
          color: "#fff",
          "& img": {
            filter: "brightness(0) invert(1)",
          },
        },
      },
    },
  },
  //.......................Deleted Header styles End.........................//
}));
const TableMain = styled(Box)(({ theme }) => ({
  table: {
    background: "#141515",
    "&.starred_beats_table": {
      "& .inboxname,& .remainingdays,& .price ": {
        color: "#AFAFAF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },
      "& .remainingdays": {
        paddingLeft: "50px",
      },
    },

    "& .delete_beat_image img": {
      width: "50px",
      height: "50px",
      borderRadius: "3px",
      objectFit: "cover",
    },
    "& .name_value": {
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "& .date_value": {
      fontFamily: theme.typography.fontFamily,
      color: "#AFAFAF",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    p: {
      margin: "0px",
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      whiteSpace: "pre-line",
      wordWrap: "break-word",
    },
    button: {
      color: "#F00",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      padding: "0px",
      background: "transparent",
      textTransform: "capitalize",
      border: "none",
      cursor: "pointer",
    },
    "& .description_td": {
      width: "70%",
    },
  },
  "& .css-11xur9t-MuiPaper-root-MuiTableContainer-root": {
    borderRadius: "0px",
  },
  thead: {
    tr: {
      th: {
        fontFamily: theme.typography.fontFamily,
        color: "#AFAFAF",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        borderBottom: "1px solid #414241",
        [theme.breakpoints.down("md")]: {
          whiteSpace: "nowrap",
        },
      },
      "& th:first-of-type": {
        paddingLeft: "0px",
      },
      "& th:last-of-type": {
        paddingRight: "0px",
        textAlign: "right",
      },
    },
  },
  tbody: {
    tr: {
      td: {
        fontFamily: theme.typography.fontFamily,
        borderBottom: "1px solid #414241",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0em",

        color: "#FFFFFF",
      },
      "& td:first-of-type": {
        paddingLeft: "0px",
        textAlign: "left",
      },
      "& td:last-of-type": {
        paddingRight: "0px",
        textAlign: "right",
      },
      "& .noData_found": {
        textAlign: "center !important",
      },
    },
  },
}));

export { Main, TableMain };
