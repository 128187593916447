import React, { useState, useEffect } from "react";
import { Modal, IconButton } from "@mui/material";
import { MainVideoBox } from "./style";
import BeatVideo from "../../../../assets/smartlink/video/Beatinbox.MOV";
import CloseIcon from "@mui/icons-material/Close";

const VideoPopup = ({ open = false }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [videoRef, setVideoRef] = useState(null);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const handleModalClose = () => {
    setModalOpen(false);
    if (videoRef) {
      videoRef.pause();
      videoRef.currentTime = 0;
    }
  };

  const handleVideoPlay = () => {
    if (videoRef) {
      videoRef.play();
    }
  };

  const handleVideoPause = () => {
    if (videoRef) {
      videoRef.pause();
    }
  };

  useEffect(() => {
    if (videoRef) {
      videoRef.play();
    }
  }, [videoRef]);

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <MainVideoBox>
          <video
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            controls
            ref={(ref) => setVideoRef(ref)}
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            src={BeatVideo}
          />
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              bottom: 10,
              right: 10,
              backgroundColor: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
        </MainVideoBox>
      </Modal>
    </>
  );
};

export default VideoPopup;
