import {
  data,
  dollar_circle,
  like,
  tag_right,
  EditIcon,
} from "../../../../assets/smartlink";

const CUSTOMIZE = [
  {
    name: "General",
    icon: EditIcon,
    url: "#",
    Dropdown: [
      {
        title: "Organize",
        id: "OrganizeForm",
      },
      {
        title: "Delete link",
        id: "DeleteForm",
      },
    ],
  },
  {
    name: "Social Cards",
    icon: like,
    url: "#",
  },
];

const ADVANCED = [
  {
    name: "Conditional",
    icon: tag_right,
    url: "#",
  },
  {
    name: "Affilate Program",
    icon: dollar_circle,
    url: "#",
  },
  {
    name: "Integrations",
    icon: data,
    url: "#",
  },
];

export { CUSTOMIZE, ADVANCED };
