import React, { memo } from "react";
import { IconButton } from "@mui/material";
import AccountPopover from "./AccountPopover";
import { HeaderMain, AppBarMain, ToolbarMain, PaymentMain } from "./styles";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Notification,
  iconhelp,
  payment_free,
  pro_payment,
  elite_payment,
} from "../../../../assets/smartlink";
import useAuth from "../../../../context/AuthContext";

const Index = ({ handleDrawerToggle = () => {}, drawerWidth = "" }) => {
  const { user } = useAuth();

  const isPlanNameAvailable = user?.planId?.planName;

  const PlanImage =
    isPlanNameAvailable === "Free"
      ? payment_free
      : isPlanNameAvailable === "Pro"
      ? pro_payment
      : isPlanNameAvailable === "Elite"
      ? elite_payment
      : isPlanNameAvailable === "HitMaker"
      ? elite_payment
      : null;

  return (
    <>
      <AppBarMain
        position="fixed"
        className="AppBarMain"
        sx={{
          background: "#121412",
          height: "80px",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <ToolbarMain>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <HeaderMain className="HeaderMain">
            {/* {isPlanNameAvailable && (
              <PaymentMain>
                {PlanImage && <img src={PlanImage} alt={isPlanNameAvailable} />}
                <span>{user?.planId?.planName}</span>
              </PaymentMain>
            )} */}
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className="notificationBtn"
            >
              <img src={iconhelp} alt="HelpIcon" />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className="notificationBtn"
            >
              <img src={Notification} alt="Notification" />
            </IconButton> */}

            <AccountPopover />
          </HeaderMain>
        </ToolbarMain>
      </AppBarMain>
    </>
  );
};

export default memo(Index);
