/* eslint-disable react/prop-types */
/* eslint-disable key-spacing */
/* eslint-disable import/order */
/* eslint-disable indent */
/* eslint-disable quote-props */
/* eslint-disable require-jsdoc */
/* eslint-disable quotes */

import React from "react";

import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { Mixpanel } from "../../context/mixpanel/mixPanel";

import DiscordIcon from "./SocialMediaIcons/DiscordIcon";

import InstaIcon from "./SocialMediaIcons/InstaIcon";
import Twitter from "./SocialMediaIcons/Twitter";
import YoutubeIcon from "./SocialMediaIcons/YoutubeIcon";
import Navbar from "../Navbar/Navbar";
import {
  Box,
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";

import { useNavigate } from "react-router";

import { ExpandLess, ExpandMore } from "@material-ui/icons";
import classes from "./Sidebar.module.css";

import useWindowDimensions from "../../utils/WindowDimensions";

import useAuth from "../../context/AuthContext";

import { NavLink } from "react-router-dom";

import { main_menu } from "./main_menu";

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    customMd: 1100,
    lg: 1200,
    xl: 1560,
  },
};

const breakPointTheme = createTheme({
  breakpoints: {
    ...customBreakpointValues,
  },
});

export function Sidebar(props) {
  const router = useNavigate();
  const { isFreemium, user, showBetaFeatures, currentRoleType } = useAuth();

  const { activeText, activeSubText } = props;
  const isDesktop = useMediaQuery("(min-width: 1200px)");

  const [open, setOpen] = React.useState("");
  const [show, setShow] = React.useState(true);
  const { height, width } = useWindowDimensions();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleClick = (value) => {
    if (value === open) {
      setOpen("");
    } else {
      setOpen(value);
    }
  };

  React.useEffect(() => {}, [isDesktop]);

  React.useEffect(() => {
    if (width > 1100) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [width]);

  const navigateTo = (url, isSelf, text) => {
    // Mixpanel.track("Page Clicked", {
    //   Page: text.title,
    // });
    if (isSelf) {
      return router(url);
    } else {
      return router(url);
    }
  };
  // const userRole = user?.roleId?.roleName;
  const userRole = currentRoleType;
  // const userRole = "Test Sidebar";

  const sidebar_menu = main_menu.filter((item) => {
    if (userRole === "Admin" && showBetaFeatures) {
      return true;
    }

    if (userRole === "Admin") {
      return item.released === true;
    }

    if (item.released === false) {
      return false;
    }

    if (item.allowedUsers.includes("All")) {
      return true;
    }

    if (item.allowedUsers.includes(userRole)) {
      return true;
    }

    return false;
  });

  function filterSubmenu(submenu) {
    if (userRole === "Admin" && showBetaFeatures) {
      return true;
    }

    return submenu.released === true;
  }

  return (
    <ThemeProvider theme={breakPointTheme}>
      <div className={classes.root}>
        <CssBaseline />
        <Navbar open={openDrawer} setOpen={setOpenDrawer} />
        <div className={classes.dflex}>
          {isDesktop ? <SidebarDesktop /> : <SidebarMobile />}
          <main className={`${classes.content} ${classes.gradient_bg}`}>
            <div className={classes.toolbar} />
            {props.childComponent}
          </main>
        </div>
      </div>
    </ThemeProvider>
  );

  function SidebarDesktop() {
    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Box px={3} py={2}>
          <NavLink to="/opportunities">
            <img
              src="/images/united_market.png"
              alt=""
              style={{ userSelect: "none" }}
              width={200}
            />
          </NavLink>
        </Box>
        <Divider />
        <Typography
          style={{
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: 15,
            padding: "20px 30px 10px",
          }}
        >
          Main Menu
        </Typography>
        <List className={classes.list}>
          {sidebar_menu.map((text, index) => {
            return text.isExpanded ? (
              <React.Fragment key={index}>
                <ListItem
                  button
                  className={`${classes.listItem} ${
                    activeText === text.title && classes.active
                  }`}
                  onClick={(e) => {
                    handleClick(text);
                  }}
                >
                  <ListItemIcon
                    className={`${classes.listIcon} ${classes[text.iconKey]}`}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.title}
                    className={classes.listItemText}
                  />
                  {open === text ? (
                    <ExpandLess style={{ color: "white" }} />
                  ) : (
                    <ExpandMore style={{ color: "white" }} />
                  )}
                </ListItem>
                <Collapse
                  in={open === text}
                  timeout="auto"
                  unmountOnExit
                  className={classes.collapseMenu}
                >
                  <List>
                    {text.subMenu.map(
                      (submenu) =>
                        filterSubmenu(submenu, index) && (
                          <ListItem
                            button
                            key={index}
                            className={`${classes.listSubItem} ${
                              classes.expandMenu
                            } ${
                              activeSubText === submenu.title &&
                              classes.subActive
                            }`}
                            onClick={(e) =>
                              navigateTo(submenu.url, text.isSelf, text)
                            }
                          >
                            <ListItemText
                              primary={submenu.title}
                              className={`${classes.listSubItemText}`}
                              style={{ paddingLeft: "10px" }}
                            />
                          </ListItem>
                        )
                    )}
                  </List>
                </Collapse>
              </React.Fragment>
            ) : (
              <>
                <ListItem
                  button
                  key={index}
                  className={
                    activeText === text.title
                      ? classes.activeListItem
                      : classes.listItem
                  }
                  onClick={(e) => navigateTo(text.url, text.isSelf, text)}
                >
                  <ListItemIcon
                    className={`${classes.listIcon} ${classes[text.iconKey]}`}
                  >
                    {text.icon}
                  </ListItemIcon>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <ListItemText
                      primary={text.title}
                      className={classes.listItemText}
                    />
                    {text.isComingImg && !text.isBeta && (
                      <div
                        className={`${classes.comingSoonText} ${
                          activeText === text.title ? classes.active : ""
                        }`}
                      >
                        <span>Coming Soon</span>
                      </div>
                    )}
                    {!text.isComingImg && text.isBeta && (
                      <div
                        className={`${classes.betaText} ${
                          activeText === text.title ? classes.active : ""
                        }`}
                      >
                        <span>Beta</span>
                      </div>
                    )}
                  </div>
                </ListItem>
              </>
            );
          })}
        </List>

        <div className={classes.sidebar_bottom_layout}>
          <div className={classes.sidebar_bottom_options}>
            <Box className={classes.iconWrapper}>
              <a
                href="https://www.instagram.com/unitedmarket/?utm_medium"
                target="_blank"
                rel="noreferrer"
              >
                <InstaIcon />
              </a>
              <a
                href="https://twitter.com/unitedmarketapp?s=11&t=BeQLHqOtLHY__VpauHyFEw"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>
              <a
                href="https://discord.com/invite/VNTqdtK3PY"
                target="_blank"
                rel="noreferrer"
              >
                <DiscordIcon />
              </a>
              <a
                href="https://www.youtube.com/channel/UCuWxIf970SF9k-2KrAVxcOQ?app=desktop"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeIcon />
              </a>
            </Box>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                alignItems: "flex-end",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  color: "#AFAFAF",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                @{new Date().getFullYear()} United Market
              </Typography>
            </Box>
          </div>
        </div>
      </Drawer>
    );
  }

  function SidebarMobile() {
    return (
      <Drawer
        className={classes.drawer}
        // variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={openDrawer}
        anchor="left"
        onClose={(e) => setOpenDrawer(false)}
      >
        <Box px={2} pt={4} pb={0}>
          <img
            src="/svgs/add2.svg"
            alt=""
            onClick={() => setOpenDrawer(false)}
          />
          {/* <img src="/images/united_market.png" alt="" /> */}
        </Box>
        <Box px={2}>{/* <UserInfo user={user} /> */}</Box>
        <Divider style={{ backgroundColor: "#323333" }} />
        <Typography
          style={{
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: 15,
            padding: "20px 30px 10px",
          }}
        >
          Main Menu
        </Typography>
        <List className={classes.list}>
          {sidebar_menu.map((text, index) => {
            return text.isExpanded ? (
              <React.Fragment key={index}>
                <ListItem
                  button
                  // key={text.id}
                  className={`${classes.listItem} ${
                    activeText === text.title && classes.active
                  }`}
                  onClick={(e) => handleClick(text)}
                >
                  <ListItemIcon
                    className={`${classes.listIcon} ${classes[text.iconKey]}`}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.title}
                    className={classes.listItemText}
                  />
                  {open === text ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={open === text}
                  timeout="auto"
                  unmountOnExit
                  className={classes.collapseMenu}
                >
                  <List>
                    {text.subMenu.map(
                      (submenu, key) =>
                        filterSubmenu(submenu) && (
                          <ListItem
                            button
                            key={submenu.id}
                            className={`${classes.listSubItem} ${
                              classes.expandMenu
                            } ${
                              activeSubText === submenu.title &&
                              classes.subActive
                            }`}
                            onClick={(e) =>
                              navigateTo(submenu.url, text.isSelf, text)
                            }
                          >
                            <ListItemText
                              primary={submenu.title}
                              className={`${classes.listSubItemText}`}
                              style={{ paddingLeft: "10px" }}
                            />
                          </ListItem>
                        )
                    )}
                  </List>
                </Collapse>
              </React.Fragment>
            ) : (
              <ListItem
                button
                key={text.id}
                className={classes.listItem}
                onClick={(e) => navigateTo(text.url, text.isSelf, text)}
              >
                <ListItemIcon
                  className={`${classes.listIcon} ${classes[text.iconKey]}`}
                >
                  {text.icon}
                </ListItemIcon>
                <ListItemText
                  primary={text.title}
                  className={classes.listItemText}
                />
              </ListItem>
            );
          })}
        </List>
        <Box className={classes.iconWrapper}>
          <a
            href="https://www.instagram.com/unitedmarket/?utm_medium"
            target="_blank"
            rel="noreferrer"
          >
            <InstaIcon />
          </a>
          <a
            href="https://twitter.com/unitedmarketapp?s=11&t=BeQLHqOtLHY__VpauHyFEw"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter />
          </a>
          <a
            href="https://discord.com/invite/VNTqdtK3PY"
            target="_blank"
            rel="noreferrer"
          >
            <DiscordIcon />
          </a>
          <a
            href="https://www.youtube.com/channel/UCuWxIf970SF9k-2KrAVxcOQ?app=desktop"
            target="_blank"
            rel="noreferrer"
          >
            <YoutubeIcon />
          </a>
        </Box>
        <Box
          style={{
            flexGrow: 1,
            display: "flex",
            alignItems: "flex-end",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography
            style={{
              color: "#AFAFAF",
              textAlign: "center",
              width: "100%",
            }}
          >
            @2023 United Market
          </Typography>
        </Box>
      </Drawer>
    );
  }
}
