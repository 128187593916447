import { AppBar, Box, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

const HeaderMain = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  width: "100%",
}));
const AppBarMain = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
}));

const DeskTopDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    background: theme.palette.primary.light,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
}));
const SideBarMain = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "14px",
  paddingTop: "30px",
  gap: "1rem",
  background: theme.palette.primary.light,
  "& h5": {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginTop: "50px",
    color: "#E3E3E3",
  },
  ul: {
    width: "100%",
  },
  li: {
    width: "100%",
  },
}));
const MenuListBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "3rem",
  width: "100%",
  color: theme.palette.secondary.dark,
  gap: "8px",
  borderRadius: "0.3125rem",
  background: theme.palette.primary.dark,
  cursor: "pointer",
  margin: "9px 0px",
  paddingLeft: "15px",
  "& h4": {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
}));
const PreferenceBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
  ul: {
    width: "100%",
  },
  li: {
    width: "100%",
  },
  "& h4": {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
}));

const MainComponent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    overflowX: "hidden",
  },
}));

export {
  SideBarMain,
  MenuListBox,
  PreferenceBox,
  DeskTopDrawer,
  AppBarMain,
  HeaderMain,
  MainComponent,
};
