import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const InputMain = styled(Box)(({ theme }) => ({
  width: "100%",

  "& .content": {
    display: "flex",
    position: "relative",
    alignItems: "center",
    gap: "8px",
    "& .icon-main": {
      marginTop: "20px",
      "& .escalimation": {
        color: "#AFAFAF",
        cursor: "pointer",
        fontSize: "20px",
        "&:hover": {
          borderRadius: "100px",
          filter:
            "invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)",
        },
      },
      "& .icon-content": {
        position: "absolute",
        top: "100%",
        left: "0",
        zIndex: "9999",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "5px",
        background: "#141515",
        padding: "18px",
        color: "#AFAFAF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        width: "230px",
        [theme.breakpoints.down("sm")]: {
          left: "-160px",
        },
      },
    },
  },
  "& h5": {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "1.5rem",
    textTransform: "capitalize",
  },
  "& input": {
    width: "100%",
    height: "50px",
    padding: "0rem 0.9rem",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    fontFamily: theme.typography.fontFamily,
    color: "#AFAFAF",
    marginTop: "10px",
    borderRadius: "5px",
    border: "1px solid #414241",
    background: "#1C1B1B",

    "::placeholder": {
      color: "#414241",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    "::-webkit-input-placeholder": {
      color: "#414241",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
  },
}));

export { InputMain };
