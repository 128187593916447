import React, { memo, useState, useEffect } from "react";
import { Modelstyle, ModelContentBox } from "./style";
import { Box, Button, IconButton, Modal } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { close_icon } from "../../../assets/smartlink";
import {
  LoginSocialFacebook,
  LoginSocialTiktok,
  LoginSocialInstagram,
  LoginSocialTwitter,
} from "reactjs-social-login";
import { useMutation, useLazyQuery, gql } from "@apollo/client";

const FACEBOOK_LOGIN_MUTATION = gql`
  mutation Facebooklogin($accessToken: String!) {
    facebooklogin(accessToken: $accessToken) {
      user {
        id
        name
        email
      }
    }
  }
`;

const TWITTER_LOGIN_QUERY = gql`
  query GetTwitterRequestToken {
    getTwitterRequestToken {
      success
      message
      redirect_url
    }
  }
`;

const SocialPopup = ({
  open = false,
  setOpen = () => {},
  selectedsocial = [],
  setSuccessOpen = () => {},
}) => {
  const CreateModelClose = () => {
    setOpen(!open);
  };

  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);

  // =============== Instagram Login ====================

  const handleInstagramLogin = async () => {
    try {
      const instagramAuthUrl = `https://api.instagram.com/oauth/authorize?client_id=6762161127225371&redirect_uri=https://localhost:3000/smart-link/integrate&response_type=code&scope=user_profile`;
      window.location.href = instagramAuthUrl;
    } catch (error) {
      console.error("Error initiating Instagram login:", error);
    }
  };

  // =============== Twiter Login ====================

  const [getTwitterRequestToken, { loading, error, data: TwitterData }] =
    useLazyQuery(TWITTER_LOGIN_QUERY, {
      context: { clientName: "smartlink" },
    });

  const handleTwiiterLogin = () => {
    getTwitterRequestToken();

    if (TwitterData) {
      const redirect = TwitterData?.getTwitterRequestToken?.redirect_url;
      window.location.href = redirect;
    }
  };

  // Facebook Token

  const [FacebookToken, setFacebookToken] = useState({});

  const [facebookLogin] = useMutation(FACEBOOK_LOGIN_MUTATION, {
    variables: { accessToken: FacebookToken },
    context: { clientName: "smartlink" },
  });

  useEffect(() => {
    const loginWithFacebook = async () => {
      try {
        const { data } = await facebookLogin();

        console.log(data, "Facebook Data");
        CreateModelClose();
        setSuccessOpen(true);
      } catch (error) {
        console.error(error, "Facebook Error");
      }
    };

    loginWithFacebook();
  }, [FacebookToken]);

  // Data States

  // =============== Rander Social Buttons ====================

  const renderSocialLoginButton = () => {
    if (selectedsocial && selectedsocial.name && selectedsocial.AppId) {
      let lowerCaseProvider;

      if (selectedsocial.name === "X") {
        lowerCaseProvider = "twitter";
      } else {
        lowerCaseProvider = selectedsocial.name.toLowerCase();
      }

      switch (lowerCaseProvider) {
        case "facebook":
          return (
            <LoginSocialFacebook
              isOnlyGetToken
              appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID || ""}
              onResolve={({ provider, data }) => {
                setFacebookToken(data.accessToken);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <button style={{ cursor: "pointer" }}>
                {" "}
                Log In with {selectedsocial?.name}
              </button>
            </LoginSocialFacebook>
          );

        case "instagram":
          return (
            <div>
              <button
                style={{ cursor: "pointer" }}
                onClick={handleInstagramLogin}
              >
                {" "}
                Log In with {selectedsocial?.name}
              </button>
            </div>
          );
        case "tiktok":
          return (
            <LoginSocialTiktok
              client_key={process.env.REACT_APP_TIKTOK_CLIENT_ID}
              redirect_uri={encodeURIComponent(
                process.env.REACT_APP_TIKTOK_CALLBACK_URL
              )}
              onResolve={({ provider, data }) => {
                setProvider(provider);
                setProfile(data);
                console.log(data);
                console.log();
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <button style={{ cursor: "pointer" }}>
                {" "}
                Log In with {selectedsocial?.name}
              </button>
            </LoginSocialTiktok>
          );
        case "twitter":
          return (
            <div>
              <button
                style={{ cursor: "pointer" }}
                onClick={handleTwiiterLogin}
              >
                {" "}
                Log In with {selectedsocial?.name}
              </button>
            </div>
          );

        default:
          return null;
      }
    }
    return null;
  };

  return (
    <>
      <Modal
        open={open}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...Modelstyle,
            width: { xs: "100%", sm: "500px" },
            padding: "2rem",
            borderRadius: "0.625rem",
            background: "#141515",
          }}
        >
          <Box className="popup_header">
            <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "2rem",
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "2rem",
              }}
            >
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>
          {selectedsocial && (
            <>
              <Box className="social_logo">
                <img src={selectedsocial?.icon} alt="cancel-icon" />
              </Box>
              <Box className="social_info">
                <h6>Connect with {selectedsocial?.name}</h6>
                <p>
                  To access this service, we need your permission to connect
                  with your {selectedsocial?.name} account
                </p>
                {renderSocialLoginButton()}
              </Box>
            </>
          )}
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(SocialPopup);
