import React from "react";
import styles from "../../auth_pages.module.css";
import { useState } from "react";
import { FormField } from "../../signup_flow/FormInput";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ForgetPass,
  isEmailexist,
} from "../../../../Graphql_services/SignUpMutation";
import { useMutation } from "@apollo/client";
import { Notification } from "../../../NotifyMsg/Notification";

const ForgotPasswordEmailForm = ({ onBack, onNext, user, completeAccount }) => {
  const [email, setEmail] = useState("");

  const [forgetPasswordMutation, { loading, error }] = useMutation(ForgetPass);

  const [validationChecker, setValidationChecker] = useState({
    emailValidationMessage: "",
  });

  function isValidEmail(email) {
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
      email
    );
  }
  const handleEmailChange = (value) => {
    if (value.length > 0) {
      if (!isValidEmail(value)) {
        setValidationChecker({ emailValidationMessage: "Email is Invalid" });
      } else {
        setValidationChecker({ emailValidationMessage: "" });
        setEmail(value);
      }
    } else {
      setValidationChecker({ emailValidationMessage: "" });
    }
  };

  const canContinue = email !== "";

  const HandelEmailChecker = async () => {
    try {
      const response = await forgetPasswordMutation({
        variables: { email: email },
      });
      if (!response?.data?.forgetPassword.success) {
        Notification("error", response?.data?.forgetPassword?.message);
        return;
      } else {
        Notification("success", response?.data?.forgetPassword?.message);
        let updatedUser = { ...user };
        onNext(updatedUser);
      }
    } catch (error) {
      Notification(
        "error",
        error.message || "something went wrong please try again"
      );
    }
  };

  // ----- End send the request for checking the email exit or not ----

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
        >
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}>
              <p className={styles.auth_title}>
                Get Registered On United Market
              </p>
              <p className={styles.auth_subtitle}>Forgot Password</p>
            </div>
            <div className={styles.input_list}>
              <FormField
                title="Email"
                onValueChange={(value) => handleEmailChange(value)}
                required={validationChecker.emailValidationMessage}
                type="email"
              />
            </div>
          </div>

          <div className={styles.buttonControls}>
            {canContinue ? (
              <div className={styles.signin_box} onClick={HandelEmailChecker}>
                <p className={styles.connect_text}>
                  {loading ? (
                    <CircularProgress size={25} sx={{ color: "white" }} />
                  ) : (
                    "Continue"
                  )}
                </p>
              </div>
            ) : (
              <div className={styles.signin_box_inactive}>
                <p className={styles.connect_text}>Continue</p>
              </div>
            )}
            <p className={styles.previous_text} onClick={onBack}>
              Previous Step
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordEmailForm;
