import React, { useEffect, useRef, useState } from "react";
import styles from "./connect.module.css";

import useAuth from "../../context/AuthContext";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import useAppState from "../../context/useAppState";
import { useDebounce } from "../../hooks/useDebounce";
import { size } from "lodash";

//    toast.success(`You're now connected with ${props.profile.userName}`);

const hasUser = (id, users) => {
  return users.filter((user) => user._id === id).length > 0;
};
const ConnectMeRequests = ({ query }) => {
  const { user } = useAuth();
  const userId = user._id;
  const selfUsername = user.userName;
  const searchQuery = useDebounce(query.trim(), 500);

  const { loadUserConnectionRequests, acceptRequest, recindRequest } =
    useAppState("connectMe"); // Use the context hook for loading data
  const { sendChatMessage } = useAppState("chat");
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  //   const [hasNextPage, setHasNextPage] = useState(true);
  const [error, setError] = useState(false);
  const PAGE_LIMIT = 12;

  useEffect(() => {
    setLoading(true);
    loadUserConnectionRequests(userId) // Pass the query to the API request
      .then((response) => {
        console.log(response);
        const loadedConnectionRequests = response;
        setData(loadedConnectionRequests);
        // setHasNextPage(loadedUsers.hasNextPage);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    loadUserConnectionRequests(userId, searchQuery) // Pass the query to the API request
      .then((response) => {
        console.log(response);
        const loadedConnectionRequests = response;
        setData(loadedConnectionRequests);
        // setHasNextPage(loadedUsers.hasNextPage);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchQuery]);

  const removeConnectionFromState = (connectionId) => {
    setData((prevData) => ({
      ...prevData,
      incomingConnections: prevData.incomingConnections.filter(
        (connection) => connection._id !== connectionId
      ),
    }));
  };

  const handleAcceptConnection = async (profileId, connectionId, chatId) => {
    try {
      await acceptRequest(userId, profileId);
      toast.success("Request accepted successfully");
      removeConnectionFromState(connectionId);
      await sendChatMessage(
        chatId,
        `${selfUsername} accepted your connection request.`,
        userId
      );
    } catch (error) {
      console.error("Failed to accept the connection request.", error);
    }
  };

  const handleRejectConnection = async (profileId, connectionId) => {
    try {
      await recindRequest(userId, profileId);
      toast.success("Request rejected successfully");
      removeConnectionFromState(connectionId);
    } catch (error) {
      console.error("Failed to accept the connection request.", error);
    }
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            marginTop: 70,
            fontSize: "16px",
          }}
        >
          Loading...
        </div>
      ) : size(data.incomingConnections) > 0 ? (
        data.incomingConnections.map((incomingRequest, index) => (
          <Profile
          key={`connectmeX-${index}`}
            profile={incomingRequest}
            handleAcceptConnection={handleAcceptConnection}
            handleRejectConnection={handleRejectConnection}
          />
        ))
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            marginTop: 70,
            fontSize: "16px",
          }}
        >
          No users found.
        </div>
      )}
    </div>
  );
};

const Profile = (props) => {
  console.log(props);
  const userProfile = props.profile.participantDetails;
  const connectionId = props.profile._id;
  const chatId = props.profile.chatId;
  const userProfileId = userProfile._id;
  console.log(connectionId);
  console.log(userProfileId);

  function getUserName(profile) {
    if (profile?.name) {
      return profile.name.firstName + " " + profile.name.lastName;
    } else if (profile?.userName) {
      return profile.userName;
    } else {
      return "Guest";
    }
  }

  return (
    <div className={styles.user_profile_requests}>
      <div className={styles.user_profile_layout}>
        <img
          src={userProfile?.profilePic}
          alt="profile"
          className={styles.user_profile_image_requests}
        ></img>
        <div className={styles.user_profile_text}>
          <p className={styles.user_profile_name_request}>
            {getUserName(userProfile)}
          </p>
          <p className={styles.user_profile_genre_request}>
            {props.profile.genre}
          </p>
        </div>
      </div>
      <div className={styles.user_profile_layout}>
        <div className={styles.connection_controls}>
          <div
            className={styles.connection_controls_accept}
            onClick={() => {
              props.handleAcceptConnection(userProfileId, connectionId, chatId);
            }}
          >
            <img
              className={styles.control_icon}
              src="/v2Assets/connect_assets/accept.png"
              alt="accept"
            ></img>
          </div>
          <div
            className={styles.connection_controls_reject}
            onClick={() => {
              props.handleRejectConnection(userProfileId, connectionId);
            }}
          >
            <img
              className={styles.control_icon}
              src="/v2Assets/connect_assets/reject.png"
              alt="reject"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectMeRequests;

const MoreMenu = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();

  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const handleOpenUserMenu = () => {
    navigate(`/profile/${props.userID}`);
  };

  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener("mousedown", handleClickOutside);
    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.more_container} ref={menuRef}>
      <div onClick={toggleMenu} className={styles.profile_more_button}>
        <img
          src="/v2Assets/connect_assets/more_icon.png"
          alt="more"
          className={styles.network_icon}
        />
      </div>
      {showMenu && (
        <div className={styles.menu}>
          <div className={styles.menu_item} onClick={handleOpenUserMenu}>
            <p className={styles.menu_item_text}>View Profile</p>
          </div>
          <div className={styles.menu_item}>
            <p className={styles.menu_item_text}>Remove Connection</p>
          </div>
          {/* <div className={styles.menu_item}>
            <p className={styles.menu_item_text}>Block User</p>
          </div> */}
        </div>
      )}
    </div>
  );
};
