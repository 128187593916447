import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import SendTemplateComponent from "../../components/MyContractsComponents/MyContracts/SendTemplateComponent";

const SendTemplatePage = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<SendTemplateComponent />}
        activeText="My Contracts"
      />
    </Grid>
  );
};

export default SendTemplatePage;
