import React from "react";
import styles from "../../auth_pages.module.css";
import { useState } from "react";
import useAuth from "../../../../context/AuthContext";
import axios from "axios";
import { FormField } from "../FormInput";

const ChooseGenre = ({ onBack, user, onNext, completeAccount }) => {
  const [genre, setGenre] = useState("");

  const canContinue = genre !== "";

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
        >
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}>
              <p className={styles.auth_title}>What genre describes you?</p>
              <p className={styles.auth_subtitle}>Pick below</p>
            </div>
            <div className={styles.input_list}>
              <FormField
                title="Genre"
                onValueChange={(value) => setGenre(value)}
                content={genre}
                dropDownData={[
                  "Rap / Hip-Hop",
                  "R&B",
                  "Alternative",
                  "Indie",
                  "Country",
                  "Pop",
                  "Other",
                ]}
                dropdown={true}
              />
            </div>
          </div>

          <div className={styles.buttonControls}>
            {canContinue ? (
              <div
                onClick={() => {
                  let updatedUser = { ...user };
                  let updatedProfile = { ...user.profile };
                  updatedProfile.genre = genre;
                  updatedUser.profile = updatedProfile;
                  onNext(updatedUser);
                }}
                className={styles.signin_box}
              >
                <p className={styles.connect_text}>Continue</p>
              </div>
            ) : (
              <div className={styles.signin_box_inactive}>
                <p className={styles.connect_text}>Continue</p>
              </div>
            )}
            <p className={styles.previous_text} onClick={onBack}>
              Previous Step
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseGenre;
