import React, { useRef, useState } from "react";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "./Libraries.module.css";
import { StyledMenu, StyledMenuItem } from "../AdditionalComponents/StyledMenu";

const ToggleExpand = ({ onClick = () => { }, primary, menu = [], setValue }) => {
  const toogleBtnRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [isArrowDown, setIsArrowDown] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);

  const handleClick = (event) => {
    setIsArrowDown(!isArrowDown);
    setShowMenu(true);
    if (toogleBtnRef.current) {
      setAnchorElement(toogleBtnRef.current);
    }
  };

  return (
    <div
      style={{
        fontFamily: "Poppins !important",
        fontSize: "18px !important",
        color: "#AFAFAF",
        display: "flex",
        flexDirection: "row",
        marginTop: "8px",
        marginBottom: "8px",
      }}
    >
      <div
        ref={toogleBtnRef}
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <ListItemText primary={primary} />
        <img
          alt="tag"
          src={
            isArrowDown ? "/v2Assets/arrow-down.png" : "/v2Assets/arrow-right.png"
          }
        />
      </div>
      <StyledMenu
        open={showMenu}
        onClose={() => setShowMenu(false)}
        anchorEl={anchorElement}
        position="left"
      >
        {menu.map((item, index) => (
          <StyledMenuItem
            key={`ToogleMenuItem-${index}`}
            onClick={(event) => {
              console.log("======", item);
              setValue(item);
              setShowMenu(false);
            }}
            disableRipple
          >
            {item}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default ToggleExpand;
