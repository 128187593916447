import React from 'react';
import styles from '../../auth_pages.module.css';
import { useState } from 'react';
// import useAuth from "../../../../context/AuthContext";
import { FormField } from '../FormInput';
import CircularProgress from '@mui/material/CircularProgress';
import useAuth from '../../../../context/AuthContext';
import { isEmailexist } from '../../../../Graphql_services/SignUpMutation';
import { useMutation } from '@apollo/client';
import { Notification } from '../../../NotifyMsg/Notification';
import CountryCodes from '../../../Auth/CountryCode/CountryCodes';

const EmailAndPassword = ({ onBack, onNext, user, completeAccount }) => {
  const { setCredentials, setIsAuthenticated } = useAuth();
  const [checkEmailExists] = useMutation(isEmailexist);

  const [email, setEmail] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);
  const [password, setPassword] = useState(null);
  const [rePassword, setRePassword] = useState(null);
  const [loading, setLoading] = useState(false);

  const [validationChecker, setValidationChecker] = useState({
    emailValidationMessage: '',
    phoneNoValidationMessage: '',
    passwordValidationMessage: '',
    rePasswordValidationMessage: '',
  });

  function isValidEmail(email) {
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
      email
    );
  }
  const handleEmailChange = (value) => {
    if (value.length > 0) {
      if (!isValidEmail(value)) {
        setValidationChecker({ emailValidationMessage: 'Email is Invalid' });
      } else {
        setValidationChecker({ emailValidationMessage: '' });
        setEmail(value);
      }
    } else {
      setValidationChecker({ emailValidationMessage: '' });
    }
  };
  const handlePassword = (value) => {
    if (value.length < 8) {
      setValidationChecker({
        passwordValidationMessage: 'Password Must Be 8 Character Long',
      });
    } else {
      setValidationChecker({ passwordValidationMessage: '' });
      setPassword(value);
    }
  };

  const handlePhoneNo = (value) => {
    let phoneNumber = value;

    if (phoneNumber.length > 15 || phoneNumber.length < 7) {
      setValidationChecker({
        phoneNoValidationMessage: 'Enter a valid phone number',
      });
    } else {
      setValidationChecker({ phoneNoValidationMessage: '' });
      phoneNumber = parseInt(value);
      setPhoneNo(phoneNumber);
    }
  };

  const handleRePassword = (value) => {
    if (value !== password) {
      setValidationChecker({
        rePasswordValidationMessage: 'Password do not match',
      });
    } else {
      setValidationChecker({ rePasswordValidationMessage: '' });
      setRePassword(value);
    }
  };

  const canContinue =
    email !== '' &&
    password !== '' &&
    rePassword !== '' &&
    password === rePassword;

  // ----- send the request for checking the email exit or not -----

  const HandelEmailChecker = async () => {
    setLoading(true);
    try {
      const response = await checkEmailExists({
        variables: { email: email },
      });
      // Notification("success", "Item Recover successfully.");
      if (!response?.data?.isEmailExists.success) {
        Notification('error', response?.data?.isEmailExists.message);
        return;
      } else {
        let updatedUser = { ...user };
        let updatedProfile = { ...user.profile };
        updatedProfile.emial = email;
        updatedProfile.phoneNo = phoneNo;
        updatedProfile.password = password;
        updatedUser.profile = updatedProfile;
        onNext(updatedUser);
      }
    } catch (error) {
      console.log('🚀 ~ HandelEmailChecker ~ error:', error);
      Notification(
        'error',
        error.message || 'something went wrong please try again'
      );
    } finally {
      setLoading(false);
    }
  };

  // ----- End send the request for checking the email exit or not ----

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
        >
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}>
              <p className={styles.auth_title}>
                Get Registered On United Market
              </p>
              <p className={styles.auth_subtitle}>Create your account</p>
            </div>
            <div className={styles.input_list}>
              <FormField
                title='Email'
                onValueChange={(value) => handleEmailChange(value)}
                required={validationChecker.emailValidationMessage}
                type='email'
              />
              {/* <CountryCodes setphonenum={setPhoneNo} phonenum={phoneNo} /> */}

              <FormField
                title='Phone Number'
                onValueChange={(value) => handlePhoneNo(value)}
                required={validationChecker.phoneNoValidationMessage}
                type='number'
              />

              <FormField
                title='Password'
                onValueChange={(value) => handlePassword(value)}
                required={validationChecker.passwordValidationMessage}
                type='password'
              />
              <FormField
                title='Re-Type Password'
                onValueChange={(value) => handleRePassword(value)}
                required={validationChecker.rePasswordValidationMessage}
                type='password'
              />
            </div>
          </div>

          <div className={styles.buttonControls}>
            {canContinue ? (
              <div className={styles.signin_box} onClick={HandelEmailChecker}>
                {/* <p className={styles.connect_text} onClick={() => onNext()}> */}
                <p className={styles.connect_text}>
                  {loading ? (
                    <CircularProgress size={25} sx={{ color: 'white' }} />
                  ) : (
                    'Continue'
                  )}
                </p>
              </div>
            ) : (
              <div className={styles.signin_box_inactive}>
                <p className={styles.connect_text}>Continue</p>
              </div>
            )}
            <p className={styles.previous_text} onClick={onBack}>
              Previous Step
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailAndPassword;
