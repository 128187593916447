import useOpportunity from "./useOpportunity";
import useSync from "./useSync";
import useFile from "./useFile";
import useSubmission from "./useSubmittion";
import useSoundPack from "./useSoundPack";
import useConnectMe from "./useConnectMe";
import useConnectMeSubmissions from "./useConnectMeSubmissions";
import useChat from "./useChat";
import useBeatInbox from "./useBeatInbox";
import useCommon from "./useCommon";
import useGeneral from "./useGeneral";

const containers = {
  opportunity: useOpportunity,
  sync: useSync,
  connectMe: useConnectMe,
  connectMeSubmissions: useConnectMeSubmissions,
  chat: useChat,
  file: useFile,
  submission: useSubmission,
  beatInbox: useBeatInbox,
  common: useSoundPack,
  general: useGeneral,
  common: useCommon,
};

export default containers;
