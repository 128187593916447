import React from "react";
import { Grid } from "@mui/material";

import styles from "./styles.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import SoundPack from "../../components-old/SoundPacks/MySoundKit";
import MySoundKit from "../../components-old/SoundPacks/MySoundKit";

const SoundKit = () => {
  return (
    <Grid className={styles.bgBlack}>
      <Sidebar
        activeText="Sound Packs"
        childComponent={<MySoundKit />}
        activeSubText="My Sound Kits"
      />
    </Grid>
  );
};

export default SoundKit;
