import React, { useContext } from 'react';
import { Box, Text, Heading, HStack, VStack, Button } from 'native-base';
import { AuthContext } from '../Layout';
function Nbox(props) {
	const {
		purchased,
		setPurchased,
		Subscriptionprice,
		setSubscriptionprice,
		priceID,
		setPriceId,
	} = useContext(AuthContext);
	const { title, price, duration, desc, priceid, subtitle, isPreSignUp } =
		props;
	const handlePurchase = (e) => {
		setPriceId(e.currentTarget.id);

		setSubscriptionprice(
			e.currentTarget.id === `${process.env.REACT_APP_MONTHLY_SUBSCRIPTION}`
				? '45'
				: '300'
		);
		setPurchased(true);
	};
	return (
		<Box
			w={{ lg: '40%', base: '100%' }}
			backgroundColor={'#1C1B1B'}
			px={8}
			borderRadius={'10px'}
			py={'40px'}
			mb={10}
		>
			<Text
				color={'#fff'}
				fontFamily={'poppins'}
				textTransform={'capitalize'}
				fontSize={{ lg: '1.2rem', md: '1rem' }}
				textAlign={'center'}
			>
				{title}
			</Text>
			<HStack
				alignItems={'center'}
				space={3}
				my={{ lg: 3 }}
				justifyContent={'center'}
			>
				<Text
					//   nativeID={price}
					fontWeight={'bold'}
					fontSize={{ lg: '3.5rem', md: '3rem', base: '2rem' }}
					color={'#fff'}
				>
					${price}
				</Text>
				<VStack>
					<Text
						textTransform={'capitalize'}
						fontFamily={'poppins'}
						color={'#fff'}
						// width={"50%"}
						fontSize={{ lg: '0.9rem', base: '0.6rem' }}
					>
						per {'\n'} {duration === 'month' ? 'month' : 'year'}
					</Text>
					{/* <Text
            textTransform={"capitalize"}
            fontFamily={"poppins"}
            color={"#fff"}
            fontSize={"0.9rem"}
          >
            month
          </Text> */}
				</VStack>
			</HStack>
			<Text
				textAlign={'center'}
				fontSize={'1rem'}
				textTransform={'capitalize'}
				fontFamily={'poppins'}
				color={'#fff'}
				my={2}
			>
				{price === '45' ? 'Yearly $360' : 'Save $210'}
			</Text>
			<Text
				textAlign={'center'}
				fontFamily={'poppins'}
				fontSize={'0.9rem'}
				color={'#AFAFAF'}
				my={3}
			>
				{desc}
			</Text>
			<Button
				nativeID={priceid}
				onPress={handlePurchase}
				borderColor='#2EBB55'
				borderWidth={1}
				_hover={{
					backgroundColor: 'transparent',

					_text: { color: '#2EBB55' },
				}}
				mt={3}
				_text={{
					fontFamily: 'poppins',
					fontSize: '1.11rem',
					textTransform: 'capitalize',
				}}
				backgroundColor={'#2EBB55'}
				py={2}
			>
				{isPreSignUp ? 'Reserve' : 'Purchase'}
			</Button>
		</Box>
	);
}

export default Nbox;
