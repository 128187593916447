import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { membership_bg } from "../../../../../assets/smartlink";

const Main = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url("${membership_bg}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  overflowX: "hidden",
  position: "relative",
  zIndex: 1,
  "&:after": {
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, 0.50)",
    zIndex: "-1",
  },

  [theme.breakpoints.down("lg")]: {
    "& .ModelContentBox": {
      width: "60%",
    },
  },

  [theme.breakpoints.down("md")]: {
    "& .ModelContentBox": {
      width: "98%",
      padding: "1rem",
      paddingBottom: "1.6rem",
      h6: {
        fontSize: "18px",
      },
    },
  },
}));

export { Main };
