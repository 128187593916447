import React, { memo } from "react";
import { Modelstyle, ModelContentBox } from "./style";
import { Box, Button, IconButton, Modal } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { close_icon, disconnect_icon } from "../../../assets/smartlink";

const DisconnectPopup = ({ open = false, setOpen = () => {} }) => {
  const CreateModelClose = () => {
    setOpen(!open);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...Modelstyle,
            width: { xs: "100%", sm: "500px" },
            padding: "2rem",
            borderRadius: "0.625rem",
            background: "#141515",
          }}
        >
          <Box className="popup_header">
            <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "2rem",
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "2rem",
              }}
            >
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>

          <Box className="social_logo">
            <img src={disconnect_icon} alt="cancel-icon" />
          </Box>
          <Box className="social_info">
            <h6>Disconnect Account</h6>
            <p>Are you sure you want to disconnect your account?</p>
            <Button sx={{ marginTop: "15px !important" }}>Disconnect</Button>
          </Box>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(DisconnectPopup);
