import React from "react";
import { ImageBox } from "./style";
import ReleaseImage from "../../../../../assets/smartlink/svgComponents/ReleaseImage";
import UrlForm from "./urlForm/UrlForm";
import { ReleaseBoxWrapper } from "../../style";
import { content_release_icon } from "../../../../../assets/smartlink";

const CreateUrl = ({ setdata = () => {}, Role }) => {
  return (
    <ReleaseBoxWrapper>
      <h5>Let's Supercharge Your Music Release Strategy</h5>
      <p>
        "Share Your Music with the World! Whether it's on Apple Music, Spotify,
        or any platform, just drop the URL. Got a UPC or ISRC? We've got you
        covered. Let's amplify your tunes and set the stage for a global
        audience!"
      </p>
      <ImageBox>
        {Role === "content" ? (
          <img src={content_release_icon} alt="" />
        ) : (
          <ReleaseImage />
        )}
      </ImageBox>
      <UrlForm setdata={setdata} />
    </ReleaseBoxWrapper>
  );
};

export default CreateUrl;
