import { useState } from "react";
import styles from "./universal.module.css";

export const DynamicFormField = ({
  title,
  info,
  paragraph,
  content,
  dropdown,
  dropDownData,
  onValueChange,
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleOptionClick = (option) => {
    onValueChange(option);
    toggleDropdown();
  };

  const handleInputChange = (event) => {
    onValueChange(event.target.value);
  };

  return (
    <div className={styles.user_info_layout}>
      <div className={styles.field_info}>
        <p className={styles.user_info_title}>{title}</p>
        {info && (
          <img
            src="/v2Assets/submission_form_assets/MoreInfoIcon.png"
            alt="info"
            className={styles.infoImage}
          />
        )}
      </div>
      {paragraph ? (
        <div className={styles.input_box_paragraph}>
          <textarea
            className={styles.input_box_text_paragraph}
            value={content}
            onChange={handleInputChange}
          />
        </div>
      ) : (
        <div className={styles.input_box}>
          <input
            className={styles.input_box_text}
            value={content}
            onChange={handleInputChange}
          />
          {dropdown && (
            <div
              className={styles.input_box_dropdown_button}
              onClick={toggleDropdown}
            >
              <div className={styles.dropdown_green_bg}>
                <img
                  src="/v2Assets/submission_form_assets/DropDownArrow.png"
                  alt="options"
                  className={styles.drop_down_image}
                />
                {isDropdownVisible && (
                  <div className={styles.dropdown_menu}>
                    {dropDownData.map((option, i) => (
                      <div
                        key={i}
                        className={styles.dropdown_menu_option_box}
                        onClick={() => handleOptionClick(option)}
                      >
                        <p className={styles.dropdown_menu_option}>{option}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
