import React, { useState } from "react";
import {
  Box,
  Stack,
  FormControl,
  CheckIcon,
  Button,
  Heading,
  Tooltip,
  InfoOutlineIcon,
  Flex,
  ChevronDownIcon,
  TextArea,
} from "native-base";
import Select from "react-select";
import { generateMarketingSummary } from "../../AdminAxiosInstance";
import {
  GenerateFormData,
  audience_sug,
  formValidation,
  specific_options,
  tagsValidation,
} from "../../utils/marketing_ai";
import { MrBtnLoader } from "../../components-old/Loader/market_loader";

const MarketingAiForm = ({summary, setLoading, setSummary, btnLoading, setBtnLoading }) => {
  let initValue = {
    target: "",
    goals: "",
    focus_area: "",
    coustom_info: "",
    knowledge_idea: "",
    specific_ideas: "",
    regions: "",
  };
  const [details, setDetails] = useState(initValue);
  const [backupDetails, setBackupDetails] = useState({});
  const [error, setError] = useState(initValue);
  const [tags, setTags] = useState([]);
  const [openArea, setOpenArea] = useState([]);
  const [isGenerate, setIsGenerate] = useState(false);

  const handleFinal = () => {
    // setTimeout(() => {
      setBtnLoading(false);
      setDetails(initValue);
      setError(initValue);
      setOpenArea([]);
      setTags([]);
      setIsGenerate(false)
    // }, 2500);
  };
  const handleChange = (e, valname) => {
    let { name, value } = e.target;
    if (!name) {
      name = valname;
    }
    setDetails(() => {
      return {
        ...details,
        [name]: value,
      };
    });
    setError({ ...error, [name]: "" });
  };

  const handleSelect = (e, valname) => {
    setDetails(() => {
      return {
        ...details,
        [valname]: e,
      };
    });
    setError({ ...error, [valname]: "" });
  };

  const handleKeyDown = (e) => {
    let name = e?.target?.name;
    if (
      e.key === "Enter" &&
      details?.target.trim() !== "" &&
      !tags.includes(details?.target?.trim())
    ) {
      setTags([...tags, details?.target.trim()]);
      setDetails(() => {
        return {
          ...details,
          [name]: "",
        };
      });
      setError({ ...error, [name]: "" });
    }
  };

  const handleTagClick = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
    setDetails(() => {
      return {
        ...details,
        target: "",
      };
    });
    setError({ ...error, target: "" });
  };

  const handleSuggestionClick = (suggestion) => {
    setTags([...tags, suggestion]);
    setDetails(() => {
      return {
        ...details,
        target: "",
      };
    });
    setError({ ...error, target: "" });
  };

  const handleToggle = (val) => {
    let area = [...openArea];
    if (area.includes(val)) {
      let remove = area.filter((it) => it !== val);
      setOpenArea(remove);
    } else {
      let add = area.concat(val);
      setOpenArea(add);
    }
  };

  const handleProceed = async () => {
    let isValid = formValidation(details);
    let isTags = tagsValidation(tags);
    let combinedErrors = { ...isValid, ...isTags };

    setError(combinedErrors);
    if (Object.keys(combinedErrors)?.length != 0) return;
    setLoading(true);
    setBtnLoading(true);
    let data = {
      target_audience: tags.join(", "),
      coustom_information: details?.coustom_info,
      knowledge_idea: details?.knowledge_idea,
      demographic_regions: details?.regions,
      focus_area: details?.focus_area,
      goals_and_objectives: details?.goals,
      specific_ideas: details?.specific_ideas?.value,
    };
    setIsGenerate(true);
    setBackupDetails(data)
    let responce = await generateMarketingSummary({
      data,
      setSummary,
      setLoading,
    });
    if (responce) {
      setTimeout(() => {
        setBtnLoading(false);
        setError(initValue);
      }, 2500);
    }
  };

  const matchingAudience = () => {
    const matchingSuggestions = audience_sug
      .filter((item) => !tags.includes(item))
      .map((suggestion, index) => (
        <span
          style={{ cursor: "pointer" }}
          key={index}
          onClick={() => handleSuggestionClick(suggestion)}
        >
          {audience_sug.length-1 != index ? suggestion + " , " : suggestion + " . "}
        </span>
      ));

    if (matchingSuggestions?.length === 0) return null;
    return (
      <div className="target-suggestions-wrapper">
        Suggestions: {matchingSuggestions}
      </div>
    );
  };

  const getCount = (val) => {
    let foValue = val?.length.toString();
    if (val?.length > 5000) {
      foValue = `5000+`;
    }
    return (
      <p>
        {foValue}
        <span>/</span>5000
      </p>
    );
  };

  // const handleRefresh=async ()=>{
  //   console.log(backupDetails)
  //   setLoading(true);
  //   setBtnLoading(true);
  //   let responce = await generateMarketingSummary({
  //     data:backupDetails,
  //     setSummary,
  //     setLoading,
  //   });
  //   if (responce) {
  //     handleFinal();
  //   }
  // }

  const isData = () => {
    const keysToIgnore = ['target', 'knowledge_idea', 'regions'];

    const isDetailsNotEmpty = Object.keys(details)
      .filter(key => !keysToIgnore.includes(key))
      .every(key => details[key] !== "");
  
    const areTagsNotEmpty = tags.length > 0;
    return isDetailsNotEmpty && areTagsNotEmpty;
  };

  return (
    <>
      <Box
        w={{ xl: "35%", lg: "40%", md: "40%", base: "100%" }}
        bg="#141515"
        rounded="8px"
        p={{ xl: "24px", lg: "24px", base: "12px" }}
        borderColor="#2eba55"
        borderWidth={1}
      >
        <Stack space={4}>
          <Box>
            <FormControl.Label
              fontFamily="poppins"
              mt={0}
              _text={{
                fontSize: { lg: "14px", md: "14px", base: "12px" },
                fontFamily: "poppins",
                fontWeight: "regular",
                color: "#E3E3E3",
              }}
              textTransform={"capitalize"}
            >
              Target Audience{" "}
              <Tooltip
                label="Define Audience: Input keywords (e.g., 'Music Producers', 'Corporate Partners')"
                openDelay={500}
                _text={{
                  color: "#fff",
                  fontSize: "14px",
                }}
                bg="rgb(20, 21, 21)"
                placement={"top"}
                textAlign={"center"}
                borderColor="#2eba55"
                borderWidth="1"
                maxWidth={"200px"}
              >
                <span
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    marginLeft: "5px",
                  }}
                >
                  <InfoOutlineIcon />
                </span>
              </Tooltip>
            </FormControl.Label>
            <div className="custom-tag-input-box">
              <div className="custom-tag-input" onChange={handleChange}>
                {tags.map((tag, index) => (
                  <div
                    key={index}
                    className="tag"
                    onClick={() => handleTagClick(index)}
                  >
                    {tag}
                    <span>&times;</span>
                  </div>
                ))}
                <input
                  type="text"
                  value={details?.target}
                  name="target"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Add items"
                />
              </div>

              {matchingAudience()}

              <span style={{ fontSize: "14px", color: "red" }}>
                {error?.target && error?.target}
              </span>
            </div>

            {GenerateFormData.slice(0,2).map((val, i) => (
              <>
                <div className="custom-accordian">
                  <div
                    className={
                      openArea.includes(val?.name)
                        ? "custom-accordian-head custom-accordian-active"
                        : "custom-accordian-head"
                    }
                    onClick={() => handleToggle(val.name)}
                  >
                    <div className="check-icons">
                      {openArea.includes(val?.name) ? (
                        <div className="dotted-box"></div>
                      ) : (
                        <CheckIcon size="4" mt="0.5" color="emerald.500" />
                      )}
                      <div>
                        <p>{val.Title}</p>
                      </div>
                    </div>
                    <div className="down-arrow">
                      <ChevronDownIcon size="3" mt="0.5" color="emerald.500" />
                    </div>
                  </div>

                  {openArea.includes(val?.name) && (
                    <div className="textarea-box">
                      <label>{val.subTitle}</label>
                      <TextArea
                        w="100%"
                        maxW="100%"
                        onChange={(e) => handleChange(e, val.name)}
                        value={details[val?.name]}
                        type={"text"}
                        placeholder={val.Placeholder}
                        name={val?.name}
                      />
                      {getCount(details[val?.name])}
                    </div>
                  )}
                </div>
                <span style={{ fontSize: "14px", color: "red" }}>
                  {error[val?.name] && error[val?.name]}
                </span>
              </>
            ))}

            
            <div className="select_option_div margin_b0">
              <FormControl.Label
                fontFamily="poppins"
                mt={0}
                _text={{
                  fontSize: { lg: "14px", md: "14px", base: "12px" },
                  fontFamily: "poppins",
                  fontWeight: "regular",
                  color: "#E3E3E3",
                }}
                textTransform={"capitalize"}
              >
                Specific Ideal Outcome
                <Tooltip
                  label="Input your ideal outcome"
                  openDelay={500}
                  _text={{
                    color: "#fff",
                    fontSize: "14px",
                  }}
                  bg="rgb(20, 21, 21)"
                  placement={"top"}
                  textAlign={"center"}
                  borderColor="#2eba55"
                  borderWidth="1"
                  maxWidth={"200px"}
                >
                  <span
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginLeft: "5px",
                    }}
                  >
                    <InfoOutlineIcon />
                  </span>
                </Tooltip>
              </FormControl.Label>

              <Select
               menuPlacement="top"
                value={
                  details.specific_ideas !== ""
                    ? details.specific_ideas
                    : null}
                onChange={(e) => handleSelect(e, "specific_ideas")}
                name="specific_ideas"
                placeholder="Select an option"
                options={specific_options}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? ""
                      : "rgba(255, 255, 255, 0.3)",
                    background: "transparent",
                    height: "45px",
                    boxShadow: state.isFocused
                      ? "0 0 0 2px rgba(46, 187, 85, 0.6)"
                      : "",
                    fontSize: "14px",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 4,
                  colors: {
                    ...theme.colors,
                    primary25: "#2eba55",
                    primary: "#000",
                    neutral80: "white",
                  },
                })}
              />
              <span style={{ fontSize: "14px", color: "red" }}>
                {error?.specific_ideas && error?.specific_ideas}
              </span>
            </div>

            {GenerateFormData.slice(2,3).map((val, i) => (
              <>
                <div className="custom-accordian">
                  <div
                    className={
                      openArea.includes(val?.name)
                        ? "custom-accordian-head custom-accordian-active"
                        : "custom-accordian-head"
                    }
                    onClick={() => handleToggle(val.name)}
                  >
                    <div className="check-icons">
                      {openArea.includes(val?.name) ? (
                        <div className="dotted-box"></div>
                      ) : (
                        <CheckIcon size="4" mt="0.5" color="emerald.500" />
                      )}
                      <div>
                        <p>{val.Title}</p>
                      </div>
                    </div>
                    <div className="down-arrow">
                      <ChevronDownIcon size="3" mt="0.5" color="emerald.500" />
                    </div>
                  </div>

                  {openArea.includes(val?.name) && (
                    <div className="textarea-box">
                      <label>{val.subTitle}</label>
                      <TextArea
                        w="100%"
                        maxW="100%"
                        onChange={(e) => handleChange(e, val.name)}
                        value={details[val?.name]}
                        type={"text"}
                        placeholder={val.Placeholder}
                        name={val?.name}
                      />
                      {getCount(details[val?.name])}
                    </div>
                  )}
                </div>
                <span style={{ fontSize: "14px", color: "red" }}>
                  {error[val?.name] && error[val?.name]}
                </span>
              </>
            ))}
            {/* Button Box */}
            <Box>
            {isData() && <Button mt={3} backgroundColor={"#82828c"} text={{color: "#fff"}} onPress={!btnLoading ? handleFinal : undefined}>Reset</Button>}
              <Button
                mt={3}
                borderWidth={0} // Corrected attribute name
                backgroundColor={"#2eba55"}
                _text={{
                  color: "#fff",
                  fontSize: { lg: "14px", md: "14px", base: "14px" },
                  fontFamily: "poppins",
                }}
                onPress={!btnLoading ? handleProceed : undefined}
                // isDisabled={btnLoading}
              >
                {btnLoading ? (
                  <>
                    <Flex direction="row">
                      <MrBtnLoader />
                      {/* <Spinner size="sm" color="#fff" /> */}
                      <Heading color="#fff" fontSize="md" ml={2}>
                        Loading
                      </Heading>
                    </Flex>
                  </>
                ):(
                  <>
                  {summary && isData() && isGenerate?'Re-Generate':'Generate Now'}
                  </>
                )}
              </Button>
              {/* <Button onPress={handleRefresh}>Re-Generate</Button> */}
              
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default MarketingAiForm;
