import React from "react";
import { Navigate } from "react-router-dom";

function PublicRoutes({ children }) {
  const Token = localStorage.getItem("token");

  return !Token ? children : children;
}

export default PublicRoutes;
