import React from "react";
import styles from "../distribution.module.css";

const DistributionApply = () => {
  return (
    <div className={styles.apply_layout}>
      <div className="typeform-embed">
        <iframe
          title="My Typeform"
          src="https://form.typeform.com/to/zafXwjGZ"
          className={styles.apply_frame}
          allow="camera; microphone; autoplay; encrypted-media;"
        ></iframe>
      </div>
    </div>
  );
};

export default DistributionApply;
