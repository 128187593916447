import React, { useState, useEffect } from 'react';
import { Box, Button, Heading, HStack, Text, IconButton } from 'native-base';
import {
	Elements,
	CardElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { addPaymentMethod } from '../../Graphql_services/PaymentsMutation';

function AddCard(props) {
	const { setActive, GetMyPaymentMethod } = props;

	const navigate = useNavigate();
	const [errMsg, setErrMsg] = useState('');

	const [AddPaymentMethod] = useMutation(addPaymentMethod, {
		context: { clientName: 'user' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			if (data?.addPaymentMethod?.success == true) {
				toast.success('Payment method updated successfully');
				setActive(true);
				GetMyPaymentMethod();
			} else if (data?.addPaymentMethod?.success == false) {
				toast.error(data?.addPaymentMethod?.message);
			} else {
				localStorage.clear();
				navigate('/signin');
			}
		},
		onError(err) {
			console.log('error on  AddPaymentMethod', err);
		},
	});

	const handleSubmit = (stripe, elements) => async () => {
		const cardElement = elements.getElement(CardElement);
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});
		if (error) {
			console.log('[error]', error);
			setErrMsg(error.message);
		} else {
			setErrMsg('');

			AddPaymentMethod({
				variables: {
					paymentMethodId: paymentMethod?.id,
				},
			});
		}
	};

	const stripes = useStripe();
	const elements = useElements();

	return (
		<Box borderWidth={1} borderColor={'#323333'} rounded={'md'} my={3}>
			<HStack
				py={3}
				px={{ lg: 3, base: 2 }}
				w={'100%'}
				justifyContent={'space-between'}
				flexWrap={'wrap'}
			>
				<form
					onSubmit={handleSubmit(stripes, elements)}
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Box
						mx={{ lg: 3, base: 1 }}
						bg={'transparrent'}
						w={{ lg: '85%', base: '75%' }}
						alignSelf={'center'}
					>
						<CardElement
							options={{
								style: {
									base: {
										fontSize: '16px',
										color: '#fff',
										border: '1px solid #afafaf',
										borderRadius: '4px',
										padding: '8px',
										'::placeholder': {
											color: '#aab7c4',
										},
									},
								},
							}}
						/>
						{errMsg != '' && (
							<Text
								mt={10}
								textTransform='capitalize'
								color='#FF0000'
								fontWeight={'normal'}
								fontFamily='poppins'
								fontSize={{
									lg: '1.1rem',
									md: '0.9rem',
									base: '0.9rem',
								}}
							>
								{errMsg}
							</Text>
						)}
					</Box>
					<Button
						_text={{
							fontFamily: 'Poppins',
							fontWeight: 'normal',
							fontSize: { lg: '14px', base: '10px' },
							color: '#fff',
						}}
						borderWidth={1}
						borderColor={'#2ebb55'}
						p={0}
						py={{ lg: 2, base: 2 }}
						w={{ lg: '10%', base: '20%' }}
						alignSelf={'center'}
						rounded={'lg'}
						bg={'#2ebb55'}
						onPress={handleSubmit(stripes, elements)}
						_hover={{
							bg: 'transparent',
						}}
						_pressed={{ bg: '#2ebb55', _text: { color: '#fff' } }}
					>
						Save
					</Button>
				</form>
			</HStack>
		</Box>
	);
}

export default AddCard;
