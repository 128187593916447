import React, { useContext } from "react";
import { Box, Button, Text } from "native-base";
import { useBreakpointValue } from "native-base";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { AuthContext } from "../Layout";
import { SignUpMutation } from "../../../Graphql_services/SignUpMutation";
import { Notification } from "../../NotifyMsg/Notification";
import FormTitle from "../FormTitle";
import clock from "../../../assets/LoginScreens/clock.svg";
import styles from "./styles.module.css";
export default function ReviewApplication() {
  const {
    purchased,
    setActiveStep,
    setAccountType,
    Subscriptionprice,
    setSubscriptionprice,
    Email,
    Password,
    picture,
    instaMail,
    genre,
    phonenum,
    priceID,
    NewRoleId,
    othersSocial,
    rolesID,
    accountType,
    setIsDisableBtn,
    activeStep,
    selectedAccount,
    username,
    firstName,
    lastName,
    noOfMonthlyListeners,
    spotifyUrl,
    labelName,
    otherHear,
    setPassword,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });
  const [signUp] = useMutation(SignUpMutation, {
    onCompleted(data) {
      if (data?.signUp?.success) {
        setActiveStep(0);
        setPassword("");
        navigate("/signin");
      } else {
        Notification("error", data.signUp.message);
        setActiveStep(activeStep);
        setIsDisableBtn(false);
      }
    },
    onError(err) {
      const { graphQLErrors } = err;
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          Notification("error", message);
          setActiveStep(activeStep);
        });
      }
    },
  });
  const handlePress = (e) => {
    signUp({
      variables: {
        firstname: firstName,
        lastname: lastName,
        email: Email,
        password: Password,
        countrycode: null,
        phoneno: Number(phonenum),
        labelName: labelName,
        roleId: rolesID,
        instaUrl: instaMail,
        profilePic: picture,
        genre: genre,
        username: username,
        noOfMonthlyListeners: noOfMonthlyListeners
          ? parseInt(noOfMonthlyListeners)
          : null,
        spotifyUrl: spotifyUrl,
        isPreSignUp: false,
        howDidYouKnow: NewRoleId,
        howDidYouKnowText: otherHear,
      },
    });
  };
  return (
    <div style={{ width: "100%" }}>
      <Box
        w={{ lg: "90%", md: "90%", sm: "85%", base: "94%" }}
        fontFamily="poppins"
        mx={"auto"}
        mb={10}
      >
        <div className={styles.applicationReview}>
          <FormTitle
            color={"#000201"}
            title={"Your Application Is Under Review"}
            subtitle={
              "Our team is reviewing your application and you will receive an email with your status update soon"
            }
          />

          <img src={clock} alt="clock" />
        </div>
        <Button
          _text={{
            color: "#E3E3E3",
            fontWeight: "normal",
            fontSize: { xl: "1.2rem", md: "1rem", base: "1rem" },
            fontFamily: "poppins",
            textTransform: "capitalize",
          }}
          height={{ lg: "45px", sm: "35px", base: "48px" }}
          backgroundColor={"#2EBB55"}
          _hover={{
            backgroundColor: "#000",
            borderColor: "#2EBB55",
            borderWidth: 1,
            color: "#2EBB55",
            _text: { color: "#2EBB55" },
          }}
          onPress={handlePress}
        >
          {"finish"}
        </Button>
      </Box>
    </div>
  );
}
