import React, { memo, useEffect, useRef, useState } from "react";
import { MainProfileBox, PersonalInfoMain, ButtonBox } from "./style";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Avatar, Box, Button, Typography } from "@mui/material";
import ProfilePictureModel from "./ProfilePictureModel";
import InputField from "../common/inputfield/inputField";
import {
  profile_background,
  beats,
  edit_green,
  refresh_icon,
} from "../../../assets/smartlink";
import { UPDATE_PROFILE_MUTATION } from "../../../Graphql_services/smartlink/SmartLink";
import { useMutation } from "@apollo/client";

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("First Name is required"),
  lastname: Yup.string().required("Last Name is required"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("Email Address is required"),
});

const defaultBackgroundImage = `url(${profile_background})`;

const CustomizationProfile = ({ Data = {} }) => {
  const [updateProfile, { loading, error, data }] = useMutation(
    UPDATE_PROFILE_MUTATION
  );

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(defaultBackgroundImage);
  const [profileData, setProfileData] = useState({
    image: Data.profilePic || defaultBackgroundImage,
    activeColor: null,
  });

  const fileInputRef = useRef(null);

  const ProfilePictureModelOpen = () => {
    setOpen(!open);
  };

  const handleImageUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (event) => {
    console.log("File selected:", event.target.files[0]);
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setImage(URL.createObjectURL(selectedFile));
    }
  };
  const handleSaveProfile = ({ image, activeColor }) => {
    setProfileData({
      ...profileData,
      image: image || defaultBackgroundImage,
      activeColor: activeColor,
    });
  };

  useEffect(() => {
    document.querySelector(
      ".header-img"
    ).style.backgroundImage = `url(${image})`;
  }, [image]);

  const initialValues = {
    firstname: Data.firstname || "",
    lastname: Data.lastname || "",
    email: Data.email || "",
    phoneno: Data.phoneno || "",
    userName: Data.userName || "",
    state: Data.state || "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      console.log("Form submitted with values:", values);
      // Add logic to save/update user profile data
    },
  });

  // const handleFileChange = async (event) => {
  //   const selectedFile = event.target.files[0];
  //   setUploadImage(selectedFile);
  //   setSelectedImage(URL.createObjectURL(selectedFile));

  //   const profileData = new FormData();
  //   setIsLoadingProfile(true);
  //   try {
  //     profileData.append("file", selectedFile);
  //     profileData.append("type", "Public");

  //     var config = {
  //       method: "post",
  //       url: `${process.env.REACT_APP_FILE_URL}/file/uploadprofilepicture`,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //       data: profileData,
  //     };
  //     const response = await axios(config);
  //     if (response.status === 200) {
  //       setCreateImageUrl(response?.data?.fileLocation);
  //       Notification("success", "Image uploaded successfully!");
  //     }
  //   } catch (error) {
  //     console.log(
  //       "🚀 ~ file: CreateBeatInboxPopup.js:65 ~ UploadImagehandler ~ error:",
  //       error
  //     );
  //     Notification("error", error.message);
  //   } finally {
  //     setIsLoadingProfile(false);
  //   }
  // };

  const handleUpdateProfile = async () => {
    try {
      const { data } = await updateProfile({
        variables: {
          firstname: "test",
          lastname: "test",
          userName: "userName",
          profilePic:
            "https://united-market.s3.us-east-2.amazonaws.com/f…oad/1701961772115/MicrosoftTeams-image%281%29.png",
          coverPic:
            "https://united-market.s3.us-east-2.amazonaws.com/f…oad/1701961772115/MicrosoftTeams-image%281%29.png",
          state: "state",
          phoneno: "12321323213",
          email: "demo3@gmail.com",
          country: "country",
          postalCode: "246",
          taxId: "21321321",
          domainName: "unitedmarket",
          websiteUrl: "https://unitedmarket.com/",
          backgroundColor: "#fff",
        },
        context: { clientName: "smartlink" },
      });

      console.log("Mutation Result:", data);
    } catch (error) {
      console.error("Mutation Error:", error.message);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <MainProfileBox>
          <Box className="header-main">
            <Box
              className="header-img"
              style={{ backgroundImage: image, backgroundSize: "cover" }}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Button className="upload-btn" onClick={handleImageUpload}>
                upload image
              </Button>
            </Box>
            <Box className="profile-img-main">
              <Box className="profileInfo_main">
                <Avatar
                  className="profile-pic"
                  src={profileData.image}
                  alt=""
                  sx={{
                    background: profileData.activeColor,
                  }}
                />
                <Box className="profile-info">
                  <Box className="profileInfo_name">
                    <Typography className="profile-name">
                      {`${Data?.firstname + " " + Data?.lastname}`}
                    </Typography>
                    <Box className="profile-desc">
                      <img src={beats} alt="beats icon" />
                      <p>united.market/sdjkw</p>
                    </Box>
                    <Box className="edit-box">
                      <img
                        src={edit_green}
                        alt="edit icon "
                        onClick={ProfilePictureModelOpen}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="profileInfo_syncBtn">
                <Button className="SyncBtn">
                  <img src={refresh_icon} alt="refresh icon" />
                  Sync With Platform
                </Button>
              </Box>
            </Box>
          </Box>
        </MainProfileBox>
        <PersonalInfoMain>
          <h5 className="info-heading">Personal Information</h5>
          <Box className="form-main">
            <Box className="input-main">
              <InputField
                className="field_name"
                title="First Name"
                type="text"
                label="First Name"
                name="firstname"
                {...formik.getFieldProps("firstname")}
              />
              <InputField
                className="field_name"
                title="Last Name"
                type="text"
                label="Last Name"
                name="lastname"
                {...formik.getFieldProps("lastname")}
              />
            </Box>
          </Box>
          <Box className="form-main">
            <Box className="input-main">
              <InputField
                className="field_name"
                title="email address"
                type="email"
                label="Email Address"
                name="email"
                {...formik.getFieldProps("email")}
              />
              <InputField
                className="field_name"
                title="Phone Number"
                type="number"
                label="Phone Number"
                name="phoneno"
                {...formik.getFieldProps("phoneno")}
              />
            </Box>
          </Box>
          <Box className="form-main-last">
            <Box className="input-main">
              <InputField
                className="field_name"
                title="User Name"
                type="text"
                label="User Name"
                name="userName"
                {...formik.getFieldProps("userName")}
              />
            </Box>
          </Box>
        </PersonalInfoMain>
        <PersonalInfoMain>
          <h5 className="info-heading">address</h5>
          <Box className="form-main">
            <Box className="input-main">
              <InputField
                className="field_name"
                title="Country"
                type="text"
                label="Country"
                name="userName"
                {...formik.getFieldProps("userName")}
              />
              <InputField
                className="field_name"
                title="City/ Estate"
                type="text"
                label="City/ Estate"
                name="state"
                {...formik.getFieldProps("state")}
              />
            </Box>
          </Box>
          <Box className="form-main">
            <Box className="input-main">
              <InputField
                className="field_name"
                title="Postal Code"
                type="number"
                label="Postal Code"
              />
              <InputField
                className="field_name"
                title="Tax ID"
                type="number"
                label="Tax ID"
              />
            </Box>
          </Box>
        </PersonalInfoMain>
        <PersonalInfoMain>
          <h5 className="info-heading">Website Information</h5>
          <Box className="form-main">
            <Box className="input-main">
              <InputField
                className="field_name"
                title="Website"
                type="text"
                label="Website"
              />
              <InputField
                className="field_name"
                title="Domain Name"
                type="text"
                label="Domain Name"
                // placeholder="unitedmarket.com"
              />
            </Box>
          </Box>
        </PersonalInfoMain>
        <ButtonBox>
          <Button variant="contained" className="btn">
            save
          </Button>
        </ButtonBox>
      </form>
      <ProfilePictureModel
        open={open}
        setOpen={setOpen}
        handleSave={handleSaveProfile}
        Data={Data}
      />
    </>
  );
};

export default memo(CustomizationProfile);
