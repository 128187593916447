import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const WidgetsMain = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  background: "#0D120F",
  padding: "30px",
  marginBottom: "40px",
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItem: "center",
    marginBottom: "20px",
  },
  h4: {
    margin: "0",
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: "2px",
  },
  h6: {
    margin: "0",
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  a: {
    color: "#2EBB55",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textDecorationLine: "underline",
  },
}));

export { WidgetsMain };
