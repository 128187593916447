import React, { Fragment, useEffect, useState } from "react";
import { size } from "lodash";
import { Box } from "@material-ui/core";

import styles from "./Libraries.module.css";

import LibraryCard from "./LibraryCard.js";
import useAuth from "../../../context/AuthContext.js";
import { useDebounce } from "../../../hooks/useDebounce.jsx";
import CircularLoaderPage from "../../UniversalComponents/CircularLoader/CircularLoaderPage.js";
import { PaginationMain } from "../../smartlink/index.js";
import NoItemComponents from "../AdditionalComponents/utils/NoItemComponent.js";
import { feedAudioFilesByQuery } from "../../../context/apis/audio-library.js";
import MassUploadModal from "./Modals/MassUploadModal.js";
import useLibraryStore from "../../../store/library.js";

const PAGE_LIMIT = 10;

const LibraryContentAreaTrashed = ({ tags, fileKey, minBpm, maxBpm, scale, mode, fileName, sortBy }) => {

  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);

  const [filter, setFilter] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [feedsLoading, setFeedsLoading] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);

  const [libraryData, setLibraryData] = useState([]);

  const [massUploadModalOpen, setMassUploadModalOpen] = useState(false);

  const { newAdded, usedAudioStorage } = useLibraryStore();

  useEffect(() => {
    setFeedsLoading(true);
    const queryString = new URLSearchParams({
      userId: user._id,
      page: currentPage,
      limit: PAGE_LIMIT,
      tags: JSON.stringify(tags),
      minBpm: minBpm,
      maxBpm: maxBpm,
      fileKey: fileKey,
      scale: scale,
      mode: mode,
      fileName: fileName,
      sortOrder: sortBy.title,
      isTrashed: true,
    }).toString();

    feedAudioFilesByQuery(queryString)
      .then((response) => {
        if (response?.data) {
          const newRows = response.data || [];
          setLibraryData(newRows);
          setIsEmpty(newRows.length == 0);
          const totalPages = Math.max(response.totalPages || 1, 1);
          setTotalPages(totalPages);
        }
      })
      .finally(() => {
        setFeedsLoading(false);
      });
  }, [newAdded, currentPage, sortBy]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter, sortBy]);

  const updateData = (index, data) => {
    const tempLibraryData = [...libraryData];
    tempLibraryData[index] = data;
    setLibraryData(tempLibraryData);
  };

  const deleteLibrary = (index) => {
    let tempLibraryData = [...libraryData];
    tempLibraryData.splice(index, 1);
    setLibraryData(tempLibraryData);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);   
    console.log('current Page', currentPage) 
  };

  const handleMassUploadModal = () => {
    if(usedAudioStorage > 2) {
      Notification("error", "You have reached your storage limit!");
      return;
    }
    setMassUploadModalOpen(!massUploadModalOpen);
  };

  return (
    <>
      {isEmpty ? (
        <>
          <NoItemComponents
            src="/v2Assets/NoFilesFound.png"
            text="No Files Found"
            isButton
            handler={handleMassUploadModal}
          />
          <MassUploadModal
            massUploadModalOpen={massUploadModalOpen}
            setMassUploadModalOpen={setMassUploadModalOpen}
          />
        </>
      ) : (
        <>
          {/* <Box sx={{ overflowY: 'auto' }} className="producerMain"> */}
          <Box
      className="producerMain"
      sx={{
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none' // This hides the scrollbar in WebKit browsers
        },
        scrollbarWidth: 'none', // This hides the scrollbar in Firefox
        msOverflowStyle: 'none',  // This hides the scrollbar in IE and Edge
      }}
    >
            {!feedsLoading ? (
              size(libraryData) > 0 &&
              libraryData.map((library, index) => {
                return (
                  <Fragment key={`LibraryCardX-${index}`}>
                    <LibraryCard
                      data={library}
                      isSelf={false}
                      index={index}
                      tags={tags}
                      fileKey={fileKey}
                      minBpm={minBpm}
                      maxBpm={maxBpm}
                      scale={scale}
                      onDelete={true}
                      onEditComplete={updateData}
                      onDeleteComplete={deleteLibrary}
                    />
                  </Fragment>
                );
              })
            ) : (
              <div>
                <CircularLoaderPage />
              </div>
            )}

            {!feedsLoading && totalPages > 1 && (
              <>
                <Box sx={{ marginBottom: "1rem" }}>
                  <PaginationMain
                    pageCount={totalPages}
                    page={currentPage}
                    handelChange={handlePageChange}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default LibraryContentAreaTrashed;
