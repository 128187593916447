import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import SendContractComponent from "../../components/MyContractsComponents/MyContracts/SendContractComponent";

const SendContracts = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<SendContractComponent />}
        activeText="My Contracts"
      />
    </Grid>
  );
};

export default SendContracts;
