import { ResponsivePie } from "@nivo/pie";

export default function MembershipTypeChart(props) {
  const planBreakdownData = props.analyticsData.usersData.planBreakdownData;

  const filteredPlanData = Object.entries(planBreakdownData).filter(
    ([_, plan]) => plan.productName !== "Storage Subscription",
  );

  const totalValue = filteredPlanData.reduce(
    (total, [_, plan]) => total + plan.count,
    0,
  );

  const baseOpacity = 0.5;
  const data = filteredPlanData.map(([planId, plan], index) => {
    const proportion = plan.count / totalValue;

    const colorOpacity = (baseOpacity + proportion * (1 - baseOpacity)).toFixed(
      2,
    );
    const color = `rgba(46, 186, 85, ${colorOpacity})`;

    return {
      id: `${plan.productName}-${index}`,
      label: plan.productName,
      value: plan.count,
      color: color,
    };
  });

  return (
    <ResponsivePie
      data={data}
      colors={({ data }) => data.color}
      margin={{ top: 50, right: 60, bottom: 30, left: 60 }}
      innerRadius={0.75}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={2}
      arcLinkLabelsStraightLength={5}
      arcLinkLabelsDiagonalLength={10}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={17}
      arcLinkLabelsTextColor="white"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
    />
  );
}
