import moment from "moment";
// import { object, string, number, boolean, date, array, mixed } from "yup";
import * as Yup from "yup";

const today = moment().format("YYYY-MM-DD");

// const LibrariesSchema = object().shape({
//   forms: array().of(
//     object.shape({
//       coverTitle: string().required("Scale is required"),
//       coverDescription: string().required("Key is required"),
//     })
//   )
// })

const BeatSchema = Yup.object().shape({

  basicInfo: Yup.object().shape({
    fileName: Yup.string().required("FileName is Requried"),
    title: Yup.string().required("Title is Requried"),
    scale: Yup.string().required("Scale is Requried"),
    key: Yup.string().required("KEY is Requried"),
    bpm: Yup.number()
      .required("BPM is Requried")
      .typeError("BPM must be a number")
      .max(200, "Must be less than 200"),
    tags: Yup.array()
      .required("Tags is Requried")
      .test("tags", "Please select at least one tag", (value) => {
        return value && value.length > 0;
      })
      .typeError("Please select one tag at least"),
    email: Yup.string().required("Email is Requried"),
    // file: Yup.mixed().required("Please select a file")
  }),

  beatInfo: Yup.object().shape({
    exclusivePrice: Yup.number()
      .required("BPM is Requried")
      .typeError("BPM must be a number"),
      nonExclusivePrice: Yup.number()
      .required("BPM is Requried")
      .typeError("BPM must be a number")

  })

});
// const LibrariesSchema = object().shape({
//   title: string()
//     .required("Title is required")
//     .min(3, "Title must be greater than 3 characters")
//     .max(50, "Title must be less than or equal to 50 characters"),
//   scale: string().required("Scale is required"),
//   key: string().required("Key is required"),
//   tags: string().required("Key is required"),
//   bpm: number().nullable().typeError('BPM must be a Number').required("BPM is required").max(200, "BPM should be less than 200"),
//   email: string().required("Email is required"),

//   beat_exc: string().required("Beat Exclusive Price is required"),
//   publisher_name: string().required("Publisher Name is required"),
//   email: string().required("Email is required"),
//   split: number().nullable().typeError('Split must be a Number').required("Email is required"),
//   beat_non: string().required("Beat Non-Exclusive is required"),
//   publisher_pro: string().required("Publisher Pro is required"),
//   mode_tone : string().required("Beat Non-Exclusive is required"),
//   coverImage: mixed().required("Please upload a file"),

// });

export default BeatSchema;
