import { Stack } from "@mui/material";
import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import styles from "../../Libraries/Libraries.module.css";
import OutlineButton from "../OutlineButton.js";
// import { PaginatorProps } from "types";
import BlueButtonComponents from "./BlueButtonComponent.js";
import { useDropzone } from "react-dropzone";
import { register } from "mixpanel-browser";

const UploadField = ({
  fileType = "'audio/*",
  onDrop,
  title = "",
  desc = "",
  id = "upload_audio_image",
  hasLink = false,
  handleLink,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: fileType,
    onDrop,
    multiple: true, // Allow multiple file selection
  });
  return (
    <div {...getRootProps()} className={styles.audioDropzone}>
      <img
        src="/images/music_icon.png"
        alt="upload"
        className={styles.drag_track_image}
      ></img>
      <p className={styles.drag_track_text}>{title}</p>
      <p className={styles.drag_track_text_browse}>{desc}</p>

      <input
        {...getInputProps()}
        id={id}
        // id="uploadImage"
        accept="audio/*"
      />
      {hasLink && (
        <div className={styles.dropbox_link}>
          <input
            className={styles.search_box_input}
            type="text"
            placeholder={"Please here the Link"}
          />
          <div className={styles.link_button} onClick={handleLink}>
            Next
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadField;
