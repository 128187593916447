import React from "react";
import { Grid } from "@mui/material";

import styles from "./styles.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import Payment from "../../components-old/User/Payment";
const MyPayment = () => {
  return (
    <Grid className={styles.bgBlack}>
      <Sidebar activeText="Settings" childComponent={<Payment />} />
    </Grid>
  );
};

export default MyPayment;
