import React from "react";
import { Wrapper, CardWrapper } from "./styles/JoinUsStyle";
import TitleDetaisWidgets from "./widgets/TitleDetaisWidgets";
import { Container, Grid, Box } from "@mui/material";
import Team1 from "../../assets/Distribution/team1.svg";
import Team2 from "../../assets/Distribution/team2.svg";
import Team3 from "../../assets/Distribution/team3.svg";
import Team4 from "../../assets/Distribution/team4.svg";
import Team5 from "../../assets/Distribution/team5.svg";
import Team6 from "../../assets/Distribution/team6.svg";
import Team7 from "../../assets/Distribution/team7.svg";
import Team8 from "../../assets/Distribution/team8.svg";
import Team9 from "../../assets/Distribution/team9.svg";
import quoteup from "../../assets/Distribution/quoteup.svg";

const Teams = [
  {
    title: "Upload Your Music",
    details:
      "After you create your next hit single, album, or EP, upload it to United Market and watch the magic happen. Input where you want your song streaming and we’ll take it from there so you can get back in the studio to and create more music. United Market is a platform that is really going to change the game. It’s hard to keep up with bookings, and communicate with clients, so U.M is a game-changer since you can do everything from one app. It also allows me to host calls with my team to keep the business all in one place.",
    artistName: "-Hoodrichkeem",
    image: Team1,
  },
  {
    title: "Upload Your Music",
    details:
      "After you create your next hit single, album, or EP, upload it to United Market and watch the magic happen. Input where you want your song streaming and we’ll take it from there so you can get back in the studio to and create more music. United Market is a platform that is really going to change the game. It’s hard to keep up with bookings, and communicate with clients, so U.M is a game-changer since you can do everything from one app. It also allows me to host calls with my team to keep the business all in one place.",
    artistName: "-Hoodrichkeem",
    image: Team2,
  },
  {
    title: "Upload Your Music",
    details:
      "After you create your next hit single, album, or EP, upload it to United Market and watch the magic happen. Input where you want your song streaming and we’ll take it from there so you can get back in the studio to and create more music. United Market is a platform that is really going to change the game. It’s hard to keep up with bookings, and communicate with clients, so U.M is a game-changer since you can do everything from one app. It also allows me to host calls with my team to keep the business all in one place.",
    artistName: "-Hoodrichkeem",
    image: Team3,
  },
  {
    title: "Upload Your Music",
    details:
      "After you create your next hit single, album, or EP, upload it to United Market and watch the magic happen. Input where you want your song streaming and we’ll take it from there so you can get back in the studio to and create more music. United Market is a platform that is really going to change the game. It’s hard to keep up with bookings, and communicate with clients, so U.M is a game-changer since you can do everything from one app. It also allows me to host calls with my team to keep the business all in one place.",
    artistName: "-Hoodrichkeem",
    image: Team4,
  },
  {
    title: "Upload Your Music",
    details:
      "After you create your next hit single, album, or EP, upload it to United Market and watch the magic happen. Input where you want your song streaming and we’ll take it from there so you can get back in the studio to and create more music. United Market is a platform that is really going to change the game. It’s hard to keep up with bookings, and communicate with clients, so U.M is a game-changer since you can do everything from one app. It also allows me to host calls with my team to keep the business all in one place.",
    artistName: "-Hoodrichkeem",
    image: Team5,
  },
  {
    title: "Upload Your Music",
    details:
      "After you create your next hit single, album, or EP, upload it to United Market and watch the magic happen. Input where you want your song streaming and we’ll take it from there so you can get back in the studio to and create more music. United Market is a platform that is really going to change the game. It’s hard to keep up with bookings, and communicate with clients, so U.M is a game-changer since you can do everything from one app. It also allows me to host calls with my team to keep the business all in one place.",
    artistName: "-Hoodrichkeem",
    image: Team6,
  },
  {
    title: "Upload Your Music",
    details:
      "After you create your next hit single, album, or EP, upload it to United Market and watch the magic happen. Input where you want your song streaming and we’ll take it from there so you can get back in the studio to and create more music. United Market is a platform that is really going to change the game. It’s hard to keep up with bookings, and communicate with clients, so U.M is a game-changer since you can do everything from one app. It also allows me to host calls with my team to keep the business all in one place.",
    artistName: "-Hoodrichkeem",
    image: Team7,
  },
  {
    title: "Upload Your Music",
    details:
      "After you create your next hit single, album, or EP, upload it to United Market and watch the magic happen. Input where you want your song streaming and we’ll take it from there so you can get back in the studio to and create more music. United Market is a platform that is really going to change the game. It’s hard to keep up with bookings, and communicate with clients, so U.M is a game-changer since you can do everything from one app. It also allows me to host calls with my team to keep the business all in one place.",
    artistName: "-Hoodrichkeem",
    image: Team8,
  },
  {
    title: "Upload Your Music",
    details:
      "After you create your next hit single, album, or EP, upload it to United Market and watch the magic happen. Input where you want your song streaming and we’ll take it from there so you can get back in the studio to and create more music. United Market is a platform that is really going to change the game. It’s hard to keep up with bookings, and communicate with clients, so U.M is a game-changer since you can do everything from one app. It also allows me to host calls with my team to keep the business all in one place.",
    artistName: "-Hoodrichkeem",
    image: Team9,
  },
];

const JoinUs = () => {
  return (
    <>
      <Wrapper>
        <TitleDetaisWidgets
          title="Join Us."
          details="We invest in you and your music. Get funding for your career. You will get a personalized funding plan to fit your needs, so every dollar goes towards getting to the next level!"
        />

        <CardWrapper>
          <Grid container spacing={3}>
            {Teams.map((value) => {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={value.title}
                    sx={{ width: "100%" }}
                  >
                    <Box className="image_wrapper">
                      <img
                        src={value.image}
                        alt={value.title}
                        className="main_image"
                      />
                      <Box className="image_hover_wrapper">
                        <img
                          src={quoteup}
                          alt="quoteup icon"
                          className="quoteup"
                        />
                        <h5>{value.title}</h5>
                        <p>{value.details}</p>
                        <h6>{value.artistName}</h6>
                      </Box>
                    </Box>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </CardWrapper>
      </Wrapper>
    </>
  );
};

export default JoinUs;
