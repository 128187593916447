import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import GenerateMarketing from "./GenerateMarketing";

const MarketingPage = () => {
  return (
    <Grid>
      <Sidebar childComponent={<GenerateMarketing />} activeText="AI Tools" />
    </Grid>
  );
};

export default MarketingPage;
