import React, { useEffect, useRef, useState } from "react";
import { Box, HStack, Heading } from "native-base";
import MarketingAiForm from "../../components/Marketing_Ai/MarketingAiForm";
import MarketingAiPreview from "../../components/Marketing_Ai/MarketingAiPreview";
import styles from "../../components/SubmissionsComponents/submissions.module.css";
import { summarySocket } from "../../context/web-socket/socket";

export default function GenerateMarketingSummary() {
  const [summary, setSummary] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    summarySocket.on("summary", (messages) => {
      console.log(summary,"summary")
      if (messages && messages.summary) {
        setLoading(false);
        setSummary(messages?.summary);
      }
    });
  
    summarySocket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
  
    return () => {
      summarySocket.off("summary");
      summarySocket.off("disconnect");
    };
  }, [summarySocket]);

  return (
    <div className={styles.main_layout}>
      <Box w={"100%"}>
        <Heading
          textTransform={"uppercase"}
          textAlign={"start"}
          fontWeight={"medium"}
          color={"#fff"}
          fontSize={{ lg: "32px", base: "18px" }}
          lineHeight={{ lg: "35px", base: "22px" }}
          fontFamily="poppins"
          mb={{ xl: 7, lg: 5, base: 5 }}
        >
          Marketing.AI
        </Heading>

        {/* empty space */}
        <Box w={"100%"} h={"30px"} />

        <HStack
          w={"100%"}
          space={7}
          justifyContent="center"
          flexDirection={{
            base: "column",
            md: "row",
          }}
          bg="#ffffff10"
          p={5}
          rounded="md"
        >
          <MarketingAiForm
           btnLoading={btnLoading}
           setBtnLoading={setBtnLoading}
            setLoading={setLoading}
            setSummary={setSummary}
            summary={summary}
          />
          <MarketingAiPreview
            btnLoading={btnLoading}
            loading={isLoading}
            setLoading={setLoading}
            textSummary={summary}
          />
        </HStack>
      </Box>
    </div>
  );
}
