/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	CloseIcon,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Menu,
} from 'native-base';
import Modal from '@material-ui/core/Modal';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import styles from '../styles.module.css';
import { previewOfCollaborators } from '../../../Graphql_services/SoundPackMutation';
import useAuth from '../../../context/AuthContext';

export function AddCollaborator(props) {
	const {
		openModal,
		closeModal,
		collaboratorData,
		setCollaboratorData,
		mySplit,
	} = props;
	const navigate = useNavigate();
	const [formData, setFormData] = useState(null);
	const { user } = useAuth();

	const schema = yup.object().shape({
		musicianName: yup.string().required(),
		email: yup
			.string()
			.email()
			.test(
				'email-check',
				'Cannot add yourself as collaborator',
				function (value) {
					return value !== user.email;
				}
			)
			.required(),

		split: yup
			.number()
			.required()
			.min(0)
			.max(100)
			.typeError('split must be a number'),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		setValue,
		getValues,
	} = useForm({
		resolver: yupResolver(schema),
	});
	const [PreviewOfCollaborators] = useLazyQuery(previewOfCollaborators, {
		context: { clientName: 'soundpack' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			const { previewOfCollaborators } = data;
			if (previewOfCollaborators) {
				const { isTokenExpired, success, message } = previewOfCollaborators;
				if (!isTokenExpired) {
					if (success) {
						setCollaboratorData([
							...collaboratorData,
							{
								musicianName: formData.musicianName,
								email: previewOfCollaborators?.collaborators[0].email,
								split: parseFloat(formData.split),
								role: previewOfCollaborators?.collaborators[0]?.roleId
									?.roleName,
								profilePic:
									previewOfCollaborators?.collaborators[0]?.profilePic,
							},
						]);
						// toast.success(message);
						setFormData(null);
						closeModal();
					} else {
						toast.error(message);
					}
				} else {
					localStorage.clear();
					navigate('/signin');
				}
			}
		},
		onError(err) {
			console.log('error on getting user info', err);
		},
	});

	const onSubmitHandler = async(data) => {
		setFormData(data);
		let splitSum = collaboratorData.reduce((sum, item) => sum + item.split, 0);

		splitSum = mySplit ? splitSum + mySplit.split : splitSum;

		if (splitSum + data.split > 100) {
			toast.error('The sum of collaborators split cant be greater than 100');
			closeModal();
		} else {
			const response = await PreviewOfCollaborators({
				variables: {
					collaborators: { ...data },
				},
			});
			response.data.previewOfCollaborators.success && toast.success("Collaborator added successfully")
		}
	};

	useEffect(() => {
		if (openModal) {
			reset();
		}
	}, [openModal]);

	const darkTheme = createTheme({
		palette: {
			type: 'dark',
		},
	});
	function getModalStyle() {
		const top = 50;
		const left = 50;

		return {
			top: `${top}%`,
			left: `${left}%`,
			transform: `translate(-${top}%, -${left}%)`,
		};
	}
	const modalStyle = getModalStyle();

	return (
		<ThemeProvider theme={darkTheme}>
			<Modal open={openModal} onClose={closeModal}>
				<div style={modalStyle} className={styles.modalPaper}>
					<Box bg={'#141313'} rounded={'lg'}>
						<HStack
							alignSelf={'center'}
							justifyContent={'space-between'}
							my={5}
							w={'100%'}
						>
							<Heading
								color={'#e3e3e3'}
								fontWeight={'normal'}
								fontFamily={'Poppins'}
								alignSelf={'center'}
								textAlign={'center'}
								fontSize={{ lg: '2xl', base: 'lg' }}
							>
								Add a Collaborator
							</Heading>

							<Button
								variant={'ghost'}
								px={5}
								top={0}
								right={0}
								_hover={{ bg: 'transparent' }}
								_pressed={{ bg: 'transparent' }}
								onPress={closeModal}
								alignItems={'center'}
							>
								<CloseIcon sx={{ color: '#AFAFAF' }} />
							</Button>
						</HStack>
						<form onSubmit={handleSubmit(onSubmitHandler)}>
							<Box w={'90%'} alignSelf={'center'}>
								<Box w={'100%'} alignSelf={'center'}>
									<Heading
										my={5}
										textTransform={'capitalize'}
										fontSize={{
											xl: '20px',
											lg: '18px',
											md: '16px',
											base: '14px',
										}}
										fontWeight={400}
										fontFamily={'Poppins'}
										textAlign={'left'}
										color={'#afafaf'}
									>
										Musician Name:
									</Heading>
									<Input
										_text={{ fontSize: '1rem', fontFamily: 'poppins' }}
										_hover={{ borderColor: '#AFAFAF' }}
										_focus={{ bg: 'transparent' }}
										focusOutlineColor='#2EBB55'
										fontFamily='poppins'
										fontSize={{ lg: '18px', md: '16px', base: '14px' }}
										color={'#AFAFAF'}
										borderColor='#AFAFAF'
										height={{
											xl: '50px',
											base: '35px',
										}}
										onChangeText={(text) => setValue('musicianName', text)}
									/>
									<p>{errors.musicianName?.message}</p>
								</Box>
								<Box w={'100%'} alignSelf={'center'}>
									<Heading
										my={5}
										textTransform={'capitalize'}
										fontSize={{
											xl: '20px',
											lg: '18px',
											md: '16px',
											base: '14px',
										}}
										fontWeight={400}
										fontFamily={'Poppins'}
										textAlign={'left'}
										color={'#afafaf'}
									>
										Email:
									</Heading>
									<Input
										_text={{ fontSize: '1rem', fontFamily: 'poppins' }}
										_hover={{ borderColor: '#AFAFAF' }}
										_focus={{ bg: 'transparent' }}
										focusOutlineColor='#2EBB55'
										fontFamily='poppins'
										fontSize={{ lg: '18px', md: '16px', base: '14px' }}
										color={'#AFAFAF'}
										borderColor='#AFAFAF'
										height={{
											xl: '50px',
											base: '35px',
										}}
										onChangeText={(text) => setValue('email', text)}
									/>
									<p>{errors.email?.message}</p>
								</Box>
								<Box w={'100%'} alignSelf={'center'}>
									<Heading
										my={5}
										textTransform={'capitalize'}
										fontSize={{
											xl: '20px',
											lg: '18px',
											md: '16px',
											base: '14px',
										}}
										fontWeight={400}
										fontFamily={'Poppins'}
										textAlign={'left'}
										color={'#afafaf'}
									>
										Split %
									</Heading>
									<Input
										_text={{ fontSize: '1rem', fontFamily: 'poppins' }}
										_hover={{ borderColor: '#AFAFAF' }}
										_focus={{ bg: 'transparent' }}
										focusOutlineColor='#2EBB55'
										fontFamily='poppins'
										fontSize={{ lg: '18px', md: '16px', base: '14px' }}
										color={'#AFAFAF'}
										borderColor='#AFAFAF'
										height={{
											xl: '50px',
											base: '35px',
										}}
										onChangeText={(text) => setValue('split', text)}
									/>
									<p>{errors.split?.message}</p>
								</Box>

								<Box w={{ lg: '50%', base: '100%' }} alignSelf={'flex-end'}>
									<HStack w={'100%'} my={10} justifyContent={'space-between'}>
										<Button
											w={'45%'}
											variant={'ghost'}
											_hover={{
												backgroundColor: '#2EBA55',
												_text: {
													color: '#fff',
												},
											}}
											_pressed={{
												bg: '#2EBB55',
												_text: {
													color: '#fff',
												},
											}}
											_focus={{ bg: 'transparent' }}
											// mt={10}
											p={0}
											py={2.5}
											borderColor='#fff'
											borderWidth={1}
											alignSelf={'center'}
											_text={{
												color: '#fff',
												fontWeight: 'medium',
												fontSize: {
													xl: '18px',
													lg: '18px',
													md: '16px',
													base: '14px',
												},
												fontFamily: 'Poppins',
											}}
											onPress={() => {
												closeModal();
											}}
										>
											CANCEL
										</Button>
										<button type='submit' className={styles.submitBtn}>
											SENT
										</button>
									</HStack>
								</Box>
							</Box>
						</form>
					</Box>
				</div>
			</Modal>
		</ThemeProvider>
	);
}
