import React from "react";

import styles from "../distribution.module.css";

const DistributionPerks = (props) => {
  const perks = [
    "Global Release Planning",
    "Digital Strategy",
    "Funding",
    "Worldwide Playlisting",
    "Funding",
    "Digital Strategy",
    "Marketing",
  ];
  return (
    <div className={styles.sub_page_layout}>
      <div className={styles.header_container}>
        <img
          src="/images/producerimage.jpeg"
          alt="background"
          className={styles.background_image}
        />
        <div className={styles.shadow_div}>
          <div className={styles.header_titles}>
            <p className={styles.header_title}>Jump start your career with </p>
            <p className={styles.header_subtitle}>
              United Market
              <span className={styles.header_subtitle_green}>
                {" "}
                Distribution
              </span>
            </p>
          </div>
          <div className={styles.apply_button} onClick={props.switchToApplyTab}>
            Get Started
          </div>
        </div>
        {/* Other elements */}
      </div>
      <div className={styles.tile_continer}>
        <div className={styles.tile}>
          <img
            src="/images/syncImage.jpeg"
            alt="background"
            className={styles.tile_image}
          />
          <div className={styles.tile_content}>
            <p className={styles.tile_title}>Sync Placements</p>
            <p className={styles.tile_paragraph}>
              This feature integrates music and multimedia across various
              platforms, ensuring optimal audience engagement. It's essential
              for movies, TV shows, ads, and games, maximizing creators' reach
              and content integrity.
            </p>
          </div>
        </div>
        <div className={styles.tile}>
          {" "}
          <img
            src="/images/techMusicImage.jpeg"
            alt="background"
            className={styles.tile_image}
          />
          <div className={styles.tile_content}>
            <p className={styles.tile_title}>Tech Empowered A&R</p>
            <p className={styles.tile_paragraph}>
              Revolutionizing traditional A&R with technology, this feature
              streamlines talent discovery and uses data- driven insights to
              predict market trends, positioning artists for success.
            </p>
          </div>
        </div>
        <div className={styles.tile}>
          {" "}
          <img
            src="/images/musicRoyaltyImage.avif"
            alt="background"
            className={styles.tile_image}
          />
          <div className={styles.tile_content}>
            <p className={styles.tile_title}>Global Royalty Collection</p>
            <p className={styles.tile_paragraph}>
              Facilitating global royalty management, this tool ensures artists
              are compensated fairly for their work across borders and
              platforms, simplifying the often complex process of international
              revenue collection.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.banner_container}>
        <div className={styles.banner_content}>
          <p className={styles.banner_text}>
            Get the funding you need to take back control of your music career
            when you distribute through United Market. Not only can you qualify
            for up to a million dollars in up-front funding, but you’ll maintain
            control of your masters! Everything you need will be in your
            customized artist portal.
          </p>
          <div className={styles.apply_button} onClick={props.switchToApplyTab}>
            Apply Now
          </div>
        </div>
      </div>
      <p className={styles.perks_title}>Designed for you sucess</p>
      <div className={styles.perks_container}>
        {perks.map((perk, index) => (
          <div key={index} className={styles.perk_item}>
            <p className={styles.perk_item_text}>{perk}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DistributionPerks;
