import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import MyContractsComponent from "../../components/MyContractsComponents/MyContracts/MyContractsComponent.js";
import DraftsPageComponent from "../../components/MyContractsComponents/Drafts/DraftsPageComponent.js";

const DraftsPage = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<DraftsPageComponent />}
        activeText="My Contracts"
      />
    </Grid>
  );
};

export default DraftsPage;
