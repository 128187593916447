import {
  graphQLAdminUserRequest,
  graphQLAdminUserUpdateMembershipRequest,
  graphQLAuthRequest,
  graphQLUserRequest,
} from "../graphQLRequest";
import {
  updateUser,
  getUser,
  allowUserAccess,
  changeAccountStatus,
  attachFreemium,
  allowUsersAccessRequest,
  verifyUsersAccessRequest,
  deleteUser,
} from "./user";

export const updateUserRequest = async (
  email,
  profilePic,
  username,
  firstname,
  lastname,
  roleId,
  userId,
  phoneno,
  allowCreate,
  isVerified,
  membershipType,
  instaUrl,
  password,
  isFreemium,
) => {
  try {
    const data = await graphQLUserRequest(updateUser, {
      email,
      profilePic,
      username,
      firstname,
      lastname,
      roleId,
      userId,
      phoneno,
      allowCreate,
      isVerified,
      membershipType,
      password,
      instaUrl,
      isFreemium,
    });

    return data;
  } catch (err) {
    console.log("error on update opportunity", err);
    return null;
  }
};
export const getUserRequest = async (id) => {
  try {
    const data = await graphQLAuthRequest(getUser, { id });
    return data;
  } catch (err) {
    console.log("error on get user", err);
    return null;
  }
};
export const AllowAccessRequest = async (id, isPaid) => {
  try {
    const data = await graphQLAdminUserRequest(allowUserAccess, { id, isPaid });
    return data;
  } catch (err) {
    console.log("error on get user", err);
    return null;
  }
};

export const AllowUsersAccessRequest = async (
  userIds,
  isPaid,
  isAdminReviewed,
) => {
  console.log(
    "this is my allowUserAccessRequest =>",
    userIds,
    isPaid,
    isAdminReviewed,
  );
  try {
    const data = await graphQLUserRequest(allowUsersAccessRequest, {
      userIds,
      isPaid,
      isAdminReviewed,
    });
    return data;
  } catch (err) {
    console.log("error on get user", err);
    return null;
  }
};

export const VerifyUsersRequest = async (userIds, isVerified) => {
  try {
    const data = await graphQLUserRequest(verifyUsersAccessRequest, {
      userIds,
      isVerified,
    });
    return data;
  } catch (err) {
    console.log("error on get user", err);
    return null;
  }
};

export const DeleteUserRequest = async (userId) => {
  try {
    const data = await graphQLAdminUserRequest(deleteUser, { userId });
    return data;
  } catch (err) {
    console.log("error on get user", err);
    return null;
  }
};

export const Update_AccountStatus = async (accountStatus, userId) => {
  try {
    const data = await graphQLAdminUserRequest(changeAccountStatus, {
      accountStatus,
      userId,
    });
    return data;
  } catch (err) {
    console.log("error on get user", err);
    return null;
  }
};

export const updateUserAccountStatus = async (accountStatus, userId) => {
  try {
    const data = await graphQLAdminUserUpdateMembershipRequest(
      changeAccountStatus,
      { accountStatus, userId },
    );
    return data;
  } catch (err) {
    console.log("error on get user", err);
    return null;
  }
};

export const attchFreemiumPlan = async (userId) => {
  try {
    const data = await graphQLUserRequest(attachFreemium, { userId });
    return data;
  } catch (err) {
    console.log("error on get user", err);
    return null;
  }
};
