import React, { useState, memo } from "react";
import { Box, IconButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { get } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import LibrariesSchema from "../../YupSchema/LibrariesSchema";

import FormField from "./FormField";
import { ModelContentBox, Modelstyle } from "../styles/style";
import styles from "../Libraries.module.css";

import submitStyles from "../../Sync/submit_content.module.css";
// import modalStyles from "../Libraries.module.css";
import { Modal, makeStyles, Checkbox } from "@material-ui/core";


import PaymentDetailModal from "./PaymentDetailModal";

import { close_icon } from "../../../../assets/smartlink";

import { CardElement, PaymentElement, useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import UnionIcon from "../../../../assets/smartlink/icons/UnionIcon.js";

import master_card from "../../../../assets/smartlink";
import paypal_card from "../../../../assets/smartlink";
import visa_card from "../../../../assets/smartlink";

import LogoIcon from "../../../../assets/Logo/pay-logo.png";
import CloseIcon from "../../../../assets/close.png";
import MicoLogo from "../../../../assets/mic-logo.png";
import TwoCircle from "../../../../assets/two-circle.png";

import axios from "axios";
import { Notification } from "../../../NotifyMsg/Notification";



const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      fontWeight: 500,
      fontSize: "12px",
      fontSmoothing: "antialiased",
      color: "#fff",
      ":-webkit-autofill": { color: "red" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  modaltitle: {
    fontSize: 24,
    color: "#E3E3E3",
    fontWeight: 400,
    margin: 0,
  },
  futureContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
    paddingBottom: 15,
  },
  continueButton: {
    width: "100%",
    height: 56,
    fontSize: 20,
    fontWeight: 500,
    borderRadius: 5,
    backgroundColor: "#2EBB55",
    color: "white",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
    "&:active": {
      opacity: 1,
    },
  },
  cardContainer: {
    width: 310,
    height: 200,
    backgroundColor: "#030303",
    border: "1px dashed #424142",
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addedcardContainer: {
    width: 310,
    height: 200,
    backgroundColor: "#2EBB55",
    border: "none",
    borderRadius: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  overlayCard: {
    backgroundColor: "#EBF4B3",
    height: 64,
    width: "100%",
    borderRadius: "0 0 18px 18px",
    position: "absolute",
    bottom: 0,
  },
  addcardtext: {
    fontSize: 20,
    fontWeight: 400,
    color: "#AFAFAF",
    margin: 0,
  },
  carddetailcontainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingTop: 30,
    paddingBottom: 30,
  },
  addcardmodalimgcontainer: {
    gap: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  micImg: {
    position: "absolute",
    top: 24,
    left: 24,
  },
  carddetail: {
    display: "flex",
    justifyContent: "space-between",
  },
  carddetailtext: {
    fontSize: 24,
    color: "#E3E3E3",
    margin: 0,
  },
  addcardbutton: {
    color: "#2EBB55",
    fontSize: 16,
    fontWeight: 500,
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  carditemcontainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: 20,
  },
  cardinputgroup: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  inputtag: { fontSize: 16, color: "#AFAFAF", margin: 0, textAlign: "left" },
  inputelement: {
    backgroundColor: "#211D1E",
    color: "white",
    padding: "15px 16px",
    height: 48,
    border: "none",
    "&:focus": {
      outline: "none",
    },
  },
  cardnumber: {
    margin: 0,
    fontSize: 19,
    fontWeight: 700,
    color: "white",
    position: "absolute",
    top: 73,
  },
  circleImage: {
    position: "absolute",
    left: 233,
    top: 10,
  },
  expdate: {
    position: "absolute",
    left: 24,
    margin: 0,
    fontSize: 12,
    top: 20,
  },
  cardname: {
    margin: 0,
    fontSize: 12,
    fontWeight: 400,
    color: "white",
    position: "absolute",
    top: 105,
    left: 24,
  },
  modalpaper: {
    position: "absolute",
    width: 544,
    backgroundColor: "#141515",
    boxShadow: theme.shadows[5],
    padding: "40px 80px  80px 80px",
    borderRadius: 10,
    textAlign: "right",
  },
  addcardmodalpaper: {
    position: "absolute",
    width: 544,
    backgroundColor: "#141515",
    boxShadow: theme.shadows[5],
    padding: "20px 40px 60px 40px",
    borderRadius: 10,
    textAlign: "right",
  },

  modaltext: {
    color: "white",
    fontSize: 18,
    fontWeight: 400,
    margin: 0,
    textAlign: "center",
  },
  modaltest1: {
    color: "white",
    fontSize: 24,
    fontWeight: 400,
    margin: 0,
    textAlign: "center",
    fontFamily: "Poppins",
  },
  modaltext2: {
    fontSize: 18,
    fontWeight: 400,
    color: "#AFAFAF",
    textAlign: "center",
    margin: 0,
    fontFamily: "Poppins",
    paddingTop: 10,
  },
  modalimgcontainer: {
    gap: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  modalcloseimg: {
    cursor: "pointer",
  },
  modalbuttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 40,
  },
  modalonebuttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 40,
  },
  modalCancelbutton: {
    background: "none",
    border: "1px solid #FFFFFF",
    borderRadius: 5,
    fontSize: 18,
    fontWeight: 500,
    color: "white",
    padding: "10.5px 30px 10.5px 30px",
    cursor: "pointer",
    width: 180,
    height: 56,
  },
  modalDeletebutton: {
    background: "#2EBB55",
    color: "white",
    border: "none",
    borderRadius: 5,
    fontSize: 18,
    fontWeight: 500,
    padding: "14.5px 40px 14.5px 40px",
    cursor: "pointer",
    width: 180,
    height: 56,
  },
  priceText: {
    color: "#2EBB55",
    fontSize: 22,
  },
}));


const PaymentMethodModal = ({ open = false, setOpen = () => { }, planFee }) => {

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
    },
    resolver: yupResolver(LibrariesSchema),
    reValidateMode: "onSubmit",
    mode: "onChange",
  });

  const CreateModelClose = (event) => {
    setOpen(!open);
  };

  const classes = useStyles();
  const elements = useElements();
  const [cardNumber, setCardNumber] = useState("**** **** **** ****");
  const [cardName, setCardName] = useState("*******");
  const [cardExpirationDate, setCardExpirationdate] = useState("**/**");
  const [paymentDetailModalOpen, setPaymentDetailModalOpen] = useState(false);

  const stripe = useStripe();

  const addCardSubmit = async (e) => {
    e.preventDefault();

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    const { token, error } = await stripe.createToken(cardNumberElement);


    if (!error) {
      try {
        const token = localStorage.getItem("token");
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_CONTRACT_URL}/create-payment-intent`,
          data: {
            amount: planFee*100,
          },
          headers: {
            accesstoken: token,
          },
        };
        const response = await axios(config);
        const confirmPayment = await stripe.confirmCardPayment(
          response.data.clientSecret,
          { payment_method: { card: cardNumberElement } }
        );

        if (confirmPayment.paymentIntent) {
          // setIsCardShow(false);
          // createTemplate();
          CreateModelClose();
          setPaymentDetailModalOpen(true);
        } else if (confirmPayment.error) {
          // Payment failed, handle the error
          Notification("error", `${confirmPayment.error.message}`);
          // CreateModelClose();
          // setPaymentDetailModalOpen(true);
        }
      } catch (error) {
        Notification("error", `${error.message}`);
      } finally {
      }
    } else {
      Notification("error", error.message);
    }
    setPaymentDetailModalOpen(true);
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%",
          }}
          className={classes.addcardmodalpaper}
        >
          <img
            className={classes.modalcloseimg}
            src={CloseIcon}
            onClick={() => CreateModelClose()}
          />
          <div className={classes.addcardmodalimgcontainer}>
            <p className={classes.modaltitle}>Pay Now</p>
            <div className={classes.addedcardContainer}>
              <img src={MicoLogo} className={classes.micImg} />
              <p className={classes.cardnumber}>{cardNumber}</p>
              <p className={classes.cardname}>{cardName}</p>
              <div className={classes.overlayCard}>
                <p className={classes.expdate}>
                  Exp{" "}
                  <span style={{ fontSize: 12, fontWeight: 600 }}>
                    {cardExpirationDate}
                  </span>
                </p>
                <img src={TwoCircle} className={classes.circleImage} />
              </div>
            </div>
          </div>

          <form onSubmit={addCardSubmit}>
            <div className={classes.carddetailcontainer}>
              <div className={classes.carddetail}>
                <p className={classes.carddetailtext}>Card Detail</p>
              </div>
              <div className={classes.carditemcontainer}>
                <div className={classes.cardinputgroup}>
                  <p className={classes.inputtag}>Name on Card</p>
                  <input
                    className={classes.inputelement}
                    name="card-name"
                    onChange={(e) => setCardName(e.target.value)}
                  />
                </div>
                <div className={classes.cardinputgroup}>
                  <p className={classes.inputtag}>Card Number</p>
                  <CardNumberElement
                    options={CARD_OPTIONS}
                    className={classes.inputelement}
                  />
                </div>
                <div className={classes.cardinputgroup}>
                  <p className={classes.inputtag}>Expiration Date</p>

                  <CardExpiryElement
                    options={CARD_OPTIONS}
                    className={classes.inputelement}
                  />
                </div>
                <div className={classes.cardinputgroup}>
                  <p className={classes.inputtag}>CVV</p>
                  <CardCvcElement
                    options={CARD_OPTIONS}
                    className={classes.inputelement}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={classes.futureContainer}>
                <Checkbox style={{ color: "#AFAFAF", padding: 0 }} />
                <p style={{ color: "#AFAFAF", fontSize: 16, margin: 0 }}>
                  Use this card for future purchases
                </p>
              </div>
              <button className={classes.continueButton}>Continue</button>
            </div>
          </form>
        </div>
      </Modal>
      <PaymentDetailModal
        open={paymentDetailModalOpen}
        setOpen={setPaymentDetailModalOpen}
      />
      
    </>
  );
};

export default memo(PaymentMethodModal);
