import { ResponsiveLine } from "@nivo/line";

export default function UserGrowthChart(props) {
  let chartData = [];
  if (
    props.analyticsData &&
    props.analyticsData.usersData &&
    props.analyticsData.usersData.activeUsersData
  ) {
    const dataPointsToShow = props.analyticsData.usersData.activeUsersData
      .slice(0, 6)
      .reverse();

    chartData = [
      {
        id: "Users",
        color: "rgb(46, 186, 85)",
        data: dataPointsToShow.map((dataPoint) => ({
          x: getMonthAbbreviation(dataPoint.monthYear),
          y: dataPoint.totalActiveUsers,
        })),
      },
    ];
  }

  const customTheme = {
    grid: {
      line: {
        stroke: "rgba(255, 255, 255, 0.3)",
        strokeWidth: 1,
      },
    },
    axis: {
      textColor: "#FFFFFF",
      legend: {
        text: {
          fill: "#FFFFFF",
        },
      },
      ticks: {
        line: {
          stroke: "#FFFFFF",
        },
        text: {
          fill: "#FFFFFF",
        },
      },
    },
  };

  return (
    <ResponsiveLine
      theme={customTheme}
      data={chartData}
      margin={{ top: 30, right: 20, bottom: 50, left: 50 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "Months",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "Users",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      colors={"rgb(46, 186, 85)"} // Forcing the line color to green
      pointSize={4} // Smaller point size
      pointColor={"rgb(46, 186, 85)"} // Filled green dot
      pointBorderWidth={0} // No border
      useMesh={true}
    />
  );
}

const getMonthAbbreviation = (monthYear) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthIndex = parseInt(monthYear.split("-")[0], 10) - 1;
  return monthNames[monthIndex];
};
