import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
 
const CardMain = styled(Box)(({ theme, isSelected }) => ({
  borderRadius: "10px",
  background: "#0E1210",
  padding: "35px",
  height:"100%",
  "& h5": {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: "2px",
    marginTop: "2px",
    textTransform: "capitalize",
  },
  "& h6": {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "15px 0",
  },
  "& span": {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: "18px",
  },
  "& .btn-box": {
    marginTop: "18px",
    "& .is_freemium_selected":{
      marginTop:"16px"
    },
    button: {
      color: "#FFF !important",
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
      borderRadius: "5px",
      background: "#2EBB55 !important",
      textAlign: "center",
      padding: "9px 10px",
      "&:disabled": {
        background: "gray !important",
        color: "#fff !important",
      },
    },
  },
  "& .package-info-box": {
    display: "flex",
    gap: "10px",
    "& .tick-icon": {
      height: "24px",
      width: "24px",
      color: "#AFAFAF",
    },
    "& span": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
}));
const ProducerPaymentCard = styled(Box)(({ theme, isSelected }) => ({
  borderRadius: "10px",
  background: "#1C1B1B",
  padding: "35px",
  textAlign: "left",
  width: "100%",
  position: "relative",
  "& .header-box": {
    display: "flex",
    gap: "7px",
    alignItems: "center",
    "& .left": {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
    h4: {
      color: "#FFFFFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
      margin: 0,
    },
    span: {
      padding: "2px 6px",
      color: "#2EBB55",
      fontFamily: theme.typography.fontFamily,
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "capitalize",
      borderRadius: "2px",
      background: "rgba(46, 187, 85, 0.20)",
    },
  },
  "& .saveprice-box": {
    padding: "2px 10.08px 2px 6.31px",
    borderRadius: "3px 3px 0px 3px",
    background: "rgba(46, 187, 85, 0.20)",
    color: "#FFFFFF",
    textAlign: "right",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    position: "absolute",
    right: "-5px",
    top: "37px",
  },
  "& .saveprice-rectangle": {
    position: "absolute",
    right: "-5px",
    top: "17%",
    width: "5px",
    height: "12px",
  },
  h5: {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: 0,
    marginTop: "10px",
  },
  h6: {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
    margin: 0,
    marginTop: "18px",
  },
  "& .card-btn": {
    width: "100%",
    padding: "9px 10px",
    borderRadius: "5px",
    background: "#2EBB55",
    color: "#FFFFFF",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
    marginTop: "18px",
    "&:hover": {
      background: "#2EBB55",
    },
  },
  "& .package-info-box": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginTop: "16px",
    "& .tick-icon": {
      height: "24px",
      width: "24px",
      color: "#AFAFAF",
    },
    "& .tick-icon-second": {
      height: "24px",
      width: "24px",
      color: "#AFAFAF",
      marginTop: "-25px",
    },
    "& span": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
    h6: {
      marginTop: "10px",
      fontSize: "20px",
    },
    "& .card-btn": {
      marginTop: "10px",
    },
    "& .saveprice-box": {
      top: "23px",
      right: "-7px",
    },
    "& .saveprice-rectangle": {
      right: "-3px",
      top: "16%",
    },
  },
}));
 
export { CardMain, ProducerPaymentCard };
