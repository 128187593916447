import React from "react";
import TitleDetaisWidgets from "./widgets/TitleDetaisWidgets";
import { Container, Box, Button } from "@mui/material";
import { Wrapper } from "./styles/FundCareerStyle";

const FundCareer = () => {
  return (
    <>
      <Wrapper>
        <Container maxWidth="lg">
          <TitleDetaisWidgets
            title="Fund Your Career"
            details="We invest in you and your music. Get funding for your career. You will get a personalized funding plan to fit your needs, so every dollar goes towards getting to the next level!"
          />
          <Box className="FundCareerContent">
            <Box className="item">We offer a tailored distribution deal</Box>
            <Box className="item">we help you grow your music & Audience</Box>
            <Box className="item">Your music achieves new heights</Box>
          </Box>
          <Box className="fundingBtn_main">
            <Button>Get Funding</Button>
          </Box>
        </Container>
      </Wrapper>
    </>
  );
};

export default FundCareer;
