import React from "react";
import { Button } from "native-base";
import "../../components/Authentication/Form.css";
import styles from "./universal.module.css";
import CircularLoader from "./CircularLoader/CircularLoader";

const ButtonComponent = (props) => {
  const {
    textProperties,
    text,
    isLoading,
    backgroundColor,
    borderwidth,
    paddingXMd,
    paddingXSm,
    paddingYMd,
    bordercolor,
    paddingYSm,
    flag,
    height,

    isDisabled,
    mt,
    w,
    isText,
    onpress,
    paddingXBase,
    paddingYBase,

    // label,
  } = props;

  return (
      <Button
          className={styles.centered_content}
          zIndex={-1}
          _hover={{
              backgroundColor: "#141515",
              _text: { color: "#2EBB55" },
              borderColor: "#2EBB55",
          }}
          _text={{ color: "#2EBB55", ...textProperties }}
          px={{ md: paddingXMd, base: paddingXSm }}
          py={{ md: paddingYMd, base: paddingYSm }}
          h={height}
          mt={mt}
          borderColor={bordercolor || "#2EBB55"}
          backgroundColor={backgroundColor || "#141515"}
          borderWidth={borderwidth || "1px"}
          onPress={onpress}
          isDisabled={isDisabled}
          w={w}
      >
          {isLoading ? <CircularLoader size="25px" /> : text}
      </Button>

  );
};

export default ButtonComponent;
