import axios from "../../config/axiosInterceptor";
import { HTTP_STATUS } from "../../shared/constants";

export const addOpportunity = async (payload) => {
  try {
    const { data, status } = await axios.post(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync`,
      payload
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while saving opportunity");
    }
  } catch (error) {
    console.log("#addOpportunity Err");
    throw new Error(error);
  }
};

export const updateOpportunityById = async (id, payload) => {
  try {
    const { data, status } = await axios.put(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/${id}`,
      payload
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while saving opportunity");
    }
  } catch (error) {
    console.log("#updateOpportunityById Err");
    throw new Error(error);
  }
};

export const getOpportunity = async (id) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/detail?id=${id}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while facing opportunity");
    }
  } catch (error) {
    console.log("#getOpportunity Err");
    throw new Error(error);
  }
};

export const pauseOpportunityApi = async (id) => {
  const payload = { isPause: true };
  try {
    const data = await axios.put(`/sync/${id}`, payload);
    if (data.status === 200) {
      return data.data.msg;
    }
    console.log("this is my data message =", data);
  } catch (error) {
    console.log("this is my error =", error);
  }
};

export const removeOpportunity = async (id) => {
  try {
    const { data, status } = await axios.delete(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/${id}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while deleting opportunity");
    }
  } catch (error) {
    console.log("#removeOpportunity Err");
    throw new Error(error);
  }
};

export const getFeedOpportunityApi = async (queryString) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/feeds?${queryString}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while deleting opportunity");
    }
  } catch (error) {}
};

export const getMyOpportunityApi = async (id) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/user?id=${id}`
    );
    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while deleting opportunity");
    }
  } catch (error) {}
};

export const getMyOpportunityByQuery = async (queryString) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/user?${queryString}`
    );
    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while Getting MyOpportunity");
    }
  } catch (error) {}
};

export const getSelectedBeatsByQuery = async (queryString) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/admin/selected-beats?${queryString}`
    );
    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while Getting selected beats");
    }
  } catch (error) {}
};

export const toggleFeaturedStatus = async (id) => {
  try {
    const { data, status } = await axios.put(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/feature/${id}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while toggling featured status");
    }
  } catch (error) {
    console.log("#toggleFeaturedStatus Err");
    throw new Error(error);
  }
};

export const addSubmission = async (payload) => {
  try {
    const { data, status } = await axios.post(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/submission`,
      payload
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while saving submission");
    }
  } catch (error) {
    console.log("#addSubmission Err");
    throw new Error(error);
  }
};

export const acceptCollaborations = async (token) => {
  try {
    const { data, status } = await axios.post(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/collaborators/accept/${token}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while saving submission");
    }
  } catch (error) {
    console.log("#addSubmission Err");
    throw new Error(error);
  }
};

export const updateSubmissionById = async (id, payload) => {
  try {
    const { data, status } = await axios.patch(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/submission/${id}`,
      payload
    );

    if (status === HTTP_STATUS.OK || status === 201 || status === 1) {
      return data;
    } else {
      throw new Error("Error while saving submission");
    }
  } catch (error) {
    console.log("#updateSubmissionById Err");
    throw new Error(error);
  }
};

export const getSubmission = async (id, queryString) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/submission/${id}?${queryString}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while facing submission");
    }
  } catch (error) {
    console.log("#getSubmission Err");
    throw new Error(error);
  }
};

export const getMySubmissions = async (queryString) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/submission/my-submission?${queryString}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while facing my submission");
    }
  } catch (error) {
    console.log("#getMySubmissions Err");
    throw new Error(error);
  }
};

export const getSubmissionDownloadFileList = async (id) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/submission/download/${id}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while facing submission");
    }
  } catch (error) {
    console.log("#getSubmission Err");
    throw new Error(error);
  }
};

export const getSubmissionDetailsbyId = async (id) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/submission/byId/${id}`
    );
    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while facing submission");
    }
  } catch (error) {
    console.log("#getSubmission Err");
    throw new Error(error);
  }
};

export const addCollaboratorApi = async (id, payload) => {
  try {
    const { data, status, statusText } = await axios.post(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/submission/collaborators/${id}`,
      payload
    );
    if (statusText === HTTP_STATUS.OK || status === 200) {
      return data;
    }
  } catch (error) {
    console.log("error =", error);
    throw new Error(error);
  }
};

export const updateCollaboratorApi = async (id, payload) => {
  try {
    const { data, status, statusText } = await axios.put(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/submission/collaborators/${id}`,
      payload
    );
    if (statusText === HTTP_STATUS.OK || status === 200) {
      return data;
    }
  } catch (error) {
    console.log("Error while update collaborator ::".error);
    throw new Error(error);
  }
};

export const getCheckoutSubmissionCard = async (id) => {
  try {
    const { data, status, statusText } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/sync/submission/bySubmissionId/${id}`
    );
    if (statusText === HTTP_STATUS.OK || status === 200) {
      return data;
    }
  } catch (error) {
    console.log("Error while update collaborator ::".error);
    throw new Error(error);
  }
};
