import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

export const LinkSourceWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "5px",
  background: "#0D120F",
  display: "block",
  h5: {
    margin: 0,
    fontWeight: 400,
    fontSize: "24px",
    color: "#E3E3E3",
    fontFamily: theme.typography.fontFamily,
    textTransform: "capitalize",
  },
  p: {
    margin: "12px 0",
    fontSize: "12px",
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    textTransform: "capitalize",
  },

  input: {
    width: "732px",
    height: "48px",
    margin: "18px 0 40px 0",
    background: "none",
    outline: "none",
    border: "1px solid #414241",
    borderRadius: "5px",
    padding: "9px 25px",
  },
}));
