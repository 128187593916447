import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer } from "react-toastify";

import { useMutation } from "@apollo/client";
import {
  SignUpMutation,
  SetRole,
  verifyOTP,
} from "../../../Graphql_services/SignUpMutation";
import useCheckMutation from "../../Hooks/Authentication/CheckE/useCheckMutation";
import Genre from "./Genre";
import {
  Box,
  Text,
  HStack,
  Image,
  Pressable,
  VStack,
  Button,
} from "native-base";
import { AuthContext } from "../Layout";
import AccountType from "../AccountType";
import Validations from "../../Validations/Validations";
import { Notification } from "../../NotifyMsg/Notification";
import ButtonComponent from "../../UniversalComponents/ButtonComponent";
import useEmailPhoneNoApi from "../../../GraphqlApis/SignupForm/useEmailPhoneNoApi";
import usePaymentMutation from "../../Hooks/PaymentCheckout/usePaymentMutation";
import Textfield from "../../UniversalComponents/Textfield";
import FormTitle from "../FormTitle";
import PreSubscription from "../Subscription/PreSubscription";
import UploadProfile from "./UploadProfile";
import { useBreakpointValue } from "native-base";
import useAttachPlanMutation from "../../Hooks/Authentication/AttachPlan/useAttachPlanMutation";
import { HowDoYouKnow } from "../../../Graphql_services/UsersMutation";
import PartOfLabel from "./PartOfLabel";
import ReviewApplication from "./ReviewApplication";
import ReCAPTCHA from "react-google-recaptcha";
const RECAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

const LoginForm = (props) => {
  const recaptchaRef = React.useRef();
  const { showLabel } = props;
  const onSubmitWithReCAPTCHA = async () => {
    try {
      const token = await recaptchaRef.current.executeAsync();
      console.log(token, "asdhaksdhkasjhdkjahksdjhaksjdhksjhdkajshdkj");
    } catch (err) {
      Notification("error", err);
      console.log("error", err);
    }
    // // apply to form data
  };
  const navigate = useNavigate(null);
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });
  const text = [
    "User Type",
    "Email Information",
    "Phone Number Information",
    "OTP Verification",
    "Password",
    "Social Handle",
    "Add Your Profile Picture",
    "How did you hear abour us?",
    // "Refer a friend",
  ];
  const { email_phoneNoValidation, ValidatePassword } = Validations();
  const { sendOtp } = useEmailPhoneNoApi();
  const { attachPlan, attachPlan_HowDoYouKnow } = useAttachPlanMutation();
  const { isEmailExists } = useCheckMutation();
  const [isResendOtpClicked, setIsResendOtpClicked] = useState(false);
  const [isUsernameAndProfileFilled, setIsUsernameAndProfileFilled] =
    useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [otp, setOtp] = useState("");
  const [showPervoiusStep, setshowPervoiusStep] = useState(true);
  //   const [countdown, setcountdown] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    activeStep,
    setActiveStep,
    accountType,
    rolesID,

    setSendOtpAgain,
    isPassword,
    setIsPassword,

    Email,
    setEmail,
    instaMail,
    setInstaMail,
    Password,

    setPassword,
    NewRoleId,
    isSimpleSignup,
    userId,
    errMsg,
    setErrMsg,

    disableBtn,
    setIsDisableBtn,

    phonenum,
    selectedAccount,
    picture,
    othersSocial,
    setothersSocial,
    othersGenre,
    setothersGenre,
    genre,
    setGenre,
    setAccountType,
    username,
    setUsername,
    genreField,
    setgenreField,
    socialField,
    purchased,
    setPurchased,
    setSocialField,
    userID,
    priceID,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    setLabel,
    progressbarvalue,
    setProgressbarValue,
    labelPart,
    showLabelPart,
    setShowLabelPart,
    setOtpValue,
    setOtherHear,
    otherHear,
    setNoOfMonthlyListeners,
    setSpotifyUrl,
    showOtherHear,
    setShowOtherHear,
    showStripeForm,
    setShowStripeForm,
  } = useContext(AuthContext);

  const isSingleField =
    activeStep === 1 ||
    activeStep === 2 ||
    activeStep === 3 ||
    activeStep === 5;
  const isDoubleField = activeStep === 4;

  const setbtndisabled = () => {
    setIsDisableBtn(false);
  };

  const [storeHowDoYouKnow] = useMutation(HowDoYouKnow, {
    onCompleted(data) {
      if (data?.setHowDidYouKnow) {
        if (data?.setHowDidYouKnow?.success) {
          //Notification('success', data.setHowDidYouKnow.message);
          setTimeout(() => {
            setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setbtndisabled();
          }, 1400);
        } else {
          Notification("error", data.setHowDidYouKnow.message);
          setActiveStep(activeStep);
          setbtndisabled();
        }
      } else {
        Notification("error", "Something went worng");
        setActiveStep(activeStep);
        setbtndisabled();
      }
    },
    onError(err) {
      const { graphQLErrors } = err;
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          Notification("error", message);
          setActiveStep(activeStep);
        });
      }
    },
  });

  const [setRole] = useMutation(SetRole, {
    onCompleted(data) {
      if (data?.setRole?.success) {
        Notification("success", data.setRole.message);

        setTimeout(() => {
          setIsPassword(true);

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setbtndisabled();
        }, 1400);
      } else {
        Notification("error", data.setRole.message);
        setActiveStep(activeStep);
        setbtndisabled();
      }
    },
    onError(err) {
      const { graphQLErrors } = err;
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          Notification("error", message);
          setActiveStep(activeStep);
        });
      }
    },
  });

  const [verifyOtp] = useMutation(verifyOTP, {
    onCompleted(data) {
      if (data?.verifyOtp?.success) {
        Notification("success", data.verifyOtp.message);

        setTimeout(() => {
          setIsPassword(true);
          setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setbtndisabled();
        }, 1400);
      } else {
        Notification("error", data.verifyOtp.message);
        setActiveStep(activeStep);
        setbtndisabled();
      }
    },
    onError(err) {
      const { graphQLErrors } = err;
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          Notification("error", message);
          setActiveStep(activeStep);
        });
      }
    },
  });
  const handlePrevious = () => {
    console.log("in previous step", activeStep);
    if (activeStep === 2) {
      setProgressbarValue((prevActiveStep) => prevActiveStep - 1);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSendOtpAgain(false);
    } 
    // hide the step of the otp part . 
    else if(activeStep === 4){
      setProgressbarValue((prevActiveStep) => prevActiveStep - 2);
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
      setSendOtpAgain(false);
    }

    else if (purchased && activeStep <= 8) {
      setPurchased(false);
      setShowStripeForm(false);
    } else if (showLabelPart && activeStep === 6 && accountType === "A & R") {
      setShowLabelPart(false);
      setErrMsg("");
    } else if (showLabelPart && activeStep === 6 && accountType === "Manager") {
      setShowLabelPart(false);
      setErrMsg("");
    } else if (othersGenre && activeStep === 6) {
      setothersGenre(false);
    } else if (othersGenre && activeStep === 6) {
      setothersGenre(false);
    } else if (
      (activeStep === 7 && othersGenre) ||
      (!othersGenre && othersSocial && activeStep === 10) ||
      (othersGenre && othersSocial && activeStep === 11)
    ) {
      setProgressbarValue((prevActiveStep) => prevActiveStep - 1);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else if (activeStep === 9 && othersSocial) {
      setothersSocial(false);
    } else {
      setProgressbarValue((prevActiveStep) => prevActiveStep - 1);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const onChangeEmail = (text) => {
    const trimmedEmail = text.trim();
    setEmailValue(trimmedEmail);
    if (/\S+@\S+\.\S+/.test(trimmedEmail)) {
      setErrMsg("");
    }
  };
  const onChangeOtp = (text) => {
    const trimmedEmail = text.trim();
    setOtp(trimmedEmail);
  };

  useEffect(() => {
    if (isResendOtpClicked) {
      setOtp("");
    }
  }, [isResendOtpClicked]);

  const onSubmit = (data) => {
    data.email = emailValue;
    // data.otp = otp;
    if (accountType && activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
    } else if (genre && activeStep === 6) {
      if (genre === "Other" && !othersGenre) {
        setothersGenre(true);
      } else if (othersGenre && data.others && activeStep === 6) {
        setgenreField(data.others);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      } else if (othersGenre && !data.others && activeStep === 6) {
        setErrMsg("field should not be empty");
        return;
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      }
      return;
    } else if (
      (!accountType && activeStep === 0) ||
      (!genre &&
        activeStep === 6 &&
        (accountType === "Music Producer" || accountType === "Artist"))
    ) {
      setErrMsg(!accountType ? "select role" : !genre ? "select genre" : "");
      return;
    } else if (
      (!accountType && activeStep === 0) ||
      (labelPart == null && activeStep === 6 && accountType === "A & R") ||
      (labelPart == null && activeStep === 6 && accountType === "Manager")
    ) {
      setErrMsg(!accountType ? "select role" : !genre ? "select option" : "");
      return;
    }

    // else if (
    // 	(othersGenre && !data.others && activeStep === 7) ||
    // 	(!othersGenre && !data.others && othersSocial && activeStep === 10) ||
    // 	(othersGenre && !data.others && othersSocial && activeStep === 11)
    // ) {
    // 	setErrMsg('field should not be empty');
    // 	return;
    // }
    // else if (
    // 	(!othersGenre && othersSocial && activeStep === 9) ||
    // 	(othersGenre && othersSocial && activeStep === 10)
    // ) {
    // 	setProgressbarValue(progressbarvalue);
    // 	setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // 	return;
    // }
    apicall(data);
  };

  const EnterPassword = (data) => {
    if (ValidatePassword(data)) {
      setPassword(data.password);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      setErrMsg("");
      return true;
    }
  };

  function isInDesiredForm(str) {
    return /^\+?(0|[1-9]\d*)$/.test(str);
  }
  function isValidUrl(str) {
    const pattern = new RegExp(
      "^([a-zA-Z]+:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );
    return pattern.test(str);
  }

  const apicall = async (data) => {
    if (data.email) {
      if (email_phoneNoValidation(data)) {
        setIsDisableBtn(true);

        isEmailExists({
          variables: {
            email: data.email,
          },
        });
        setEmail(data.email);

        //setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        //setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      }
    } else if (!data.email && activeStep === 1) {
      setErrMsg("Invalid email address");

      return;
    }

    if (phonenum.length > 7 && activeStep === 2) {
      // phonenum.length<8 &&setErrMsg("invalid phone number")
      setshowPervoiusStep(false);
      setIsDisableBtn(false);
      onSubmitWithReCAPTCHA();

      // hide the OTP section 

      // sendOtp({
      //   variables: {
      //     countrycode: null,
      //     phoneno: phonenum ? Number(phonenum) : null,
      //     email: Email,
      //   },
      // });
      setProgressbarValue((prevActiveStep) => prevActiveStep + 2);
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
      return;
    } else if ((phonenum.length < 8 || !phonenum) && activeStep === 2) {
      setshowPervoiusStep(false);

      setErrMsg("Invalid phone number");
      setIsDisableBtn(false);
      return;
    }
          // hide the OTP section 

    // if (data.otp) {
    //   if (data.otp > 0) {
    //     setIsDisableBtn(true);
    //     verifyOtp({
    //       variables: {
    //         otp: data.otp,
    //         email: Email,
    //       },
    //     });
    //   }



    //   setOtpValue(data.otp);

    // already commented
    //   // setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
    //   // setActiveStep((prevActiveStep) => prevActiveStep + 1);
     // End already commented
     
    //   return;
    // } else if (!data.otp && activeStep === 3) {
    //   setErrMsg("Enter valid OTP");
    //   return;
    // }
    //end hide the OTP section 

    if (data.password && data.confirmPassword) {
      EnterPassword(data);
      return;
    } else if ((!data.password || !data.confirmPassword) && activeStep === 4) {
      setErrMsg("Password and confirm password should not be empty");
      return;
    }

    if (data.email_username) {
      setInstaMail(data.email_username);
      setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    } else if (!data.email_username && activeStep === 5) {
      setInstaMail("");
      setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }

    if (
      (accountType === "A & R" ||
        accountType === "Label" ||
        accountType === "Manager") &&
      activeStep > 5
    ) {
      console.log(showLabelPart, activeStep, !data.labelName, labelPart);
      if (labelPart === false && activeStep === 6 && accountType === "A & R") {
        setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      }
      if (
        labelPart &&
        activeStep === 6 &&
        accountType === "A & R" &&
        !showLabelPart
      ) {
        setShowLabelPart(true);
        setErrMsg("");
        return;
      }
      if (
        labelPart === false &&
        activeStep === 6 &&
        accountType === "Manager"
      ) {
        setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      }
      if (
        labelPart &&
        activeStep === 6 &&
        accountType === "Manager" &&
        !showLabelPart
      ) {
        setShowLabelPart(true);
        setErrMsg("");
        return;
      }
      if (showLabelPart && activeStep === 6 && data.labelName) {
        setLabel(data.labelName);
        setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      } else if (showLabelPart && activeStep === 6 && !data.labelName) {
        setErrMsg("Label name is required");
        return;
      }
      if (data.username && picture) {
        setUsername(data.username);
        setIsUsernameAndProfileFilled(true);
        setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      } else {
        if (isUsernameAndProfileFilled) {
          setErrMsg("");
          return;
        }
        if (!data.username && !picture && activeStep === 7) {
          setErrMsg("Profile Pic and Username is required");
          return;
        } else if (!data.username && activeStep === 7) {
          setErrMsg("Username is required");
          return;
        } else if (!picture && activeStep === 7) {
          // setErrMsg("Profile Pic is required");
          // return;
        }
      }

      if (data.firstName && data.lastName && activeStep === 8) {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      } else if ((!data.firstName || !data.lastName) && activeStep === 8) {
        setErrMsg("All fields are required");
        return;
      }
      if (activeStep === 9 && NewRoleId) {
        if (othersSocial && !showOtherHear) {
          setShowOtherHear(true);
          return;
        }
        if (showOtherHear && data.others) {
          setOtherHear(data.others);
          setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          return;
        } else if (!data.others && showOtherHear) {
          setErrMsg("Field is required");
          return;
        }
        if (!showOtherHear) {
          setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          return;
        }
      } else if (activeStep === 9 && !NewRoleId && !othersSocial) {
        setErrMsg("Please select an option");
        return;
      }
    } else if (accountType === "Artist" && activeStep > 6) {
      if (data.monthlyListerner && data.spotifyLink && activeStep === 7) {
        if (!isInDesiredForm(data.monthlyListerner)) {
          setErrMsg("Monthly listerner should be positive number");
          return;
        } else if (!isValidUrl(data.spotifyLink)) {
          setErrMsg("Please enter a valid link");
          return;
        } else {
          setNoOfMonthlyListeners(data.monthlyListerner);
          setSpotifyUrl(data.spotifyLink);
          setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          return;
        }
      } else if (
        (!data.monthlyListerner || !data.spotifyLink) &&
        activeStep === 7
      ) {
        setErrMsg("All Fields are required");
        return;
      }
      if (
        data.username &&
        data.firstName &&
        data.lastName &&
        picture &&
        activeStep === 8
      ) {
        setUsername(data.username);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      } else if (
        (!data.username || !picture || !data.firstName || !data.lastName) &&
        activeStep === 8
      ) {
        setErrMsg("All fields are required ");
        return;
      }
      if (activeStep === 9 && NewRoleId) {
        if (othersSocial && !showOtherHear) {
          setShowOtherHear(true);
          return;
        }
        if (showOtherHear && data.others) {
          setOtherHear(data.others);
          setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          return;
        } else if (!data.others && showOtherHear) {
          setErrMsg("Field is required");
          return;
        }
        if (!showOtherHear) {
          setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          return;
        }
      } else if (activeStep === 9 && !NewRoleId && !othersSocial) {
        setErrMsg("Please select an option");
      }
    } else {
      if (
        data.username &&
        data.firstName &&
        data.lastName &&
        picture &&
        accountType === "Music Producer"
      ) {
        setUsername(data.username);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        return;
      } else if (
        (!data.username || !picture || !data.firstName || !data.lastName) &&
        activeStep === 7 &&
        accountType === "Music Producer"
      ) {
        setErrMsg("All fields are required ");
        return;
      }
      if (
        (NewRoleId && !othersGenre && !othersSocial && activeStep === 9) ||
        (NewRoleId && othersGenre && !othersSocial && activeStep === 10) ||
        (NewRoleId &&
          !othersGenre &&
          othersSocial &&
          activeStep === 10 &&
          data.others) ||
        (NewRoleId &&
          othersGenre &&
          othersSocial &&
          activeStep === 11 &&
          data.others)
      ) {
        if (isSimpleSignup) {
          setSocialField(data.others);
          if (
            localStorage.getItem("accountType") === "Music Producer" ||
            accountType === "Music Producer"
          ) {
            setIsDisableBtn(true);
            console.log("asjdhaskjdhaksd");
            storeHowDoYouKnow({
              variables: {
                userId: localStorage.getItem("userId"),
                howDidYouKnowText: othersSocial ? data.others : "",
                howDidYouKnow: NewRoleId,
              },
            });
            // attachPlan_HowDoYouKnow({
            // 	variables: {
            // 		userToken: userID,
            // 		priceId: null,
            // 		howDidYouKnowText: othersSocial ? data.others : '',
            // 		howDidYouKnow: NewRoleId,
            // 	},
            // });
          } else {
            setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }

          return;
        } else if (!isSimpleSignup) {
          setRole({
            variables: {
              userId: userId,
              roleId: rolesID,
              labelName: "",
              howDidYouKnow: NewRoleId,
              instaUrl: instaMail,
            },
          });
          return;
        }
      } else if (
        (!NewRoleId && !othersGenre && activeStep === 9) ||
        (!NewRoleId && othersGenre && activeStep === 10)
      ) {
        setErrMsg("Select option");
        return;
      }
    }
  };

  const ResendOTP = () => {
    setIsResendOtpClicked(true);
    setSendOtpAgain(true);
    sendOtp({
      variables: {
        countrycode: null,
        phoneno: phonenum ? Number(phonenum) : null,
        email: Email,
      },
    });
  };

  useEffect(() => {
    setErrMsg("");
    //console.log(activeStep, accountType, 'in useEffect');

    // if (activeStep === 9 && accountType === 'Music Producer') {
    // 	setAccountType(localStorage.getItem('accountType'));
    // 	attachPlan({
    // 		variables: {
    // 			userToken: userID,
    // 			priceId: priceID,
    // 			howDidYouKnowText: null,
    // 			howDidYouKnow: null,
    // 		},
    // 	});
    // }

    if (activeStep === 6 && accountType === "Label") {
      setShowLabelPart(true);
    }
  }, [activeStep]);
  return (
    <>
      <Box px={{ xl: 1, base: 1 }} w={"100%"}>
        <div className={"outerContainer"}>
          <Box
            w={{ md: "90%", sm: "90%", base: "94%" }}
            fontFamily="poppins"
            mx={"auto"}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_KEY}
              onLoad={() => console.log("recaptcha loaded")}
            />

            <Box w={"100%"} justifyContent="center">
              {isMobile && (
                <Text fontFamily={"poppins"} color={"#2EBB55"} fontSize="1rem">
                  {text[activeStep]}
                </Text>
              )}
              <FormTitle
                activeStep={activeStep}
                color={"#000201"}
                title={props.title}
                subtitle={props.subtitle}
                isMobile={isMobile}
              />

              <Box>
                {activeStep === 0 && (
                  <AccountType
                    flag={true}
                    w={{
                      xl: "181px",
                      lg: "140px",
                      md: "120px",
                      sm: "120px",
                      base: "120px",
                    }}
                    h={{
                      xl: "132px",
                      lg: "110px",
                      md: "90px",
                      sm: "80px",
                      base: "80px",
                    }}
                    borderadius={{ md: 10, sm: 5, base: 5 }}
                  />
                )}
                {activeStep === 6 &&
                  !othersGenre &&
                  (showLabel ? (
                    showLabelPart ? (
                      <>
                        <Textfield
                          color={"#E3E3E3"}
                          label={"Label Name:"}
                          control={control}
                          type={"labelName"}
                          placeholder={"Enter Label Name"}
                        />
                      </>
                    ) : (
                      <PartOfLabel
                        w={{
                          xl: "181px",
                          lg: "140px",
                          md: "120px",
                          sm: "120px",
                          base: "120px",
                        }}
                        h={{
                          xl: "132px",
                          lg: "110px",
                          md: "90px",
                          sm: "80px",
                          base: "80px",
                        }}
                        borderadius={{ md: 10, sm: 5, base: 5 }}
                      />
                    )
                  ) : (
                    <Genre
                      w={{
                        xl: "181px",
                        lg: "140px",
                        md: "120px",
                        sm: "120px",
                        base: "120px",
                      }}
                      h={{
                        xl: "132px",
                        lg: "110px",
                        md: "90px",
                        sm: "80px",
                        base: "80px",
                      }}
                      borderadius={{ md: 10, sm: 5, base: 5 }}
                    />
                  ))}
                {((!showOtherHear && activeStep === 9) ||
                  (othersGenre && activeStep === 10)) && (
                  <AccountType
                    flag={false}
                    w={{
                      xl: "181px",
                      lg: "140px",
                      md: "120px",
                      sm: "120px",
                      base: "120px",
                    }}
                    h={{
                      xl: "132px",
                      lg: "110px",
                      md: "90px",
                      sm: "80px",
                      base: "80px",
                    }}
                  />
                )}

                {((activeStep === 7 && accountType === "Music Producer") ||
                  (activeStep === 8 && accountType === "Artist") ||
                  (activeStep === 7 &&
                    (accountType === "Label" ||
                      accountType === "A & R" ||
                      accountType === "Manager"))) && (
                  <Box
                    mt={{ "2xl": 10, xl: 10, lg: 10, md: 10, base: 120 }}
                    mb={{ "2xl": 0, xl: 10, lg: 10, md: 10, base: 0 }}
                    textAlign={"center"}
                  >
                    <UploadProfile />
                  </Box>
                )}

                {activeStep === 8 && accountType === "Music Producer" && (
                  <PreSubscription />
                )}
                {(activeStep === 0 ||
                  (!genre && activeStep === 6) ||
                  (!NewRoleId && activeStep === 9 && !showOtherHear)) && (
                  // (!othersGenre && !picture && activeStep === 7) ||
                  // (othersGenre && !picture && activeStep === 8)
                  <Text
                    textTransform="capitalize"
                    color="#FF0000"
                    fontWeight={"normal"}
                    fontFamily="poppins"
                    fontSize={{ lg: "1.1rem", md: "0.9rem", base: "0.9rem" }}
                  >
                    {errMsg}
                  </Text>
                )}
                {(isSingleField || // otp ,insta
                  isDoubleField || //email phone password confirm password
                  (othersGenre &&
                    activeStep === 6 &&
                    (accountType === "Artist" ||
                      accountType === "Music Producer")) ||
                  activeStep === 7 ||
                  (showOtherHear && activeStep === 9) ||
                  // (!othersGenre && activeStep === 7) || //username
                  // (othersGenre && activeStep === 8) || // //username
                  (!othersGenre && showOtherHear && activeStep === 10) ||
                  (othersGenre && showOtherHear && activeStep === 11) ||
                  (accountType !== "Music Producer" && activeStep === 8)) && (
                  <div className="textfields">
                    <VStack space={8}>
                      <Box>
                        {(activeStep === 7 &&
                          accountType === "Music Producer") ||
                        (activeStep === 8 && accountType === "Artist") ? (
                          <>
                            <Textfield
                              color={"#E3E3E3"}
                              label={props.label}
                              control={control}
                              type={props.type}
                              placeholder={props.placeholder}
                            />
                            <br />
                            <Textfield
                              color={"#E3E3E3"}
                              label={props.label1}
                              control={control}
                              type={props.type1}
                              placeholder={props.placeholder1}
                            />
                            <br />
                            <Textfield
                              color={"#E3E3E3"}
                              label={props.label2}
                              control={control}
                              type={props.type2}
                              placeholder={props.placeholder2}
                            />
                          </>
                        ) : accountType !== "Music Producer" &&
                          activeStep === 8 ? (
                          <>
                            <Textfield
                              color={"#E3E3E3"}
                              label={props.label}
                              control={control}
                              type={props.type}
                              placeholder={props.placeholder}
                            />
                            <br />
                            <Textfield
                              color={"#E3E3E3"}
                              label={props.label1}
                              control={control}
                              type={props.type1}
                              placeholder={props.placeholder1}
                            />
                          </>
                        ) : accountType === "Artist" && activeStep === 7 ? (
                          <>
                            {" "}
                            <Textfield
                              color={"#E3E3E3"}
                              label={props.label}
                              control={control}
                              type={props.type}
                              placeholder={props.placeholder}
                            />
                            <br />
                            <Textfield
                              color={"#E3E3E3"}
                              label={props.label1}
                              control={control}
                              type={props.type1}
                              placeholder={props.placeholder1}
                            />
                          </>
                        ) : (
                          <>
                            <Textfield
                              color={"#E3E3E3"}
                              label={props.label}
                              control={control}
                              type={props.type}
                              placeholder={props.placeholder}
                              onChangeEmail={onChangeEmail}
                              onChangeOtp={onChangeOtp}
                              emailValue={emailValue}
                              otp={otp}
                              customType={
                                props.type === "email"
                                  ? "email"
                                  : props.type === "otp"
                                  ? "otp"
                                  : ""
                              }
                            />
                          </>
                        )}
                        {(isSingleField ||
                          (othersGenre &&
                            activeStep === 6 &&
                            (accountType === "Artist" ||
                              accountType === "Music Producer")) ||
                          (activeStep === 7 && accountType === "Artist") ||
                          (activeStep === 8 &&
                            accountType === "Artist" &&
                            (!firstName ||
                              !lastName ||
                              !username ||
                              !picture)) ||
                          (activeStep === 7 &&
                            accountType === "Music Producer" &&
                            (!firstName ||
                              !lastName ||
                              !username ||
                              !picture)) ||
                          (activeStep === 8 &&
                            (accountType === "A & R" ||
                              accountType === "Label") &&
                            (!firstName || !lastName)) ||
                          (activeStep === 7 &&
                            (accountType === "A & R" ||
                              accountType === "Label") &&
                            (!username || !picture)) ||
                          (showOtherHear &&
                            activeStep === 9 &&
                            (accountType === "Artist" ||
                              accountType === "Music Producer" ||
                              accountType === "A & R" ||
                              accountType === "Label"))) && (
                          <>
                            <HStack justifyContent={"space-between"}>
                              <Text
                                fontFamily={"poppins"}
                                fontSize={{
                                  lg: "18px",
                                  md: "14px",
                                  base: "12px",
                                }}
                                color="#AFAFAF"
                                mt={2}
                              >
                                {props.smallText}
                              </Text>
                            </HStack>
                            <Text
                              textTransform="capitalize"
                              color="#FF0000"
                              fontWeight={"normal"}
                              fontFamily="poppins"
                              fontSize={{
                                lg: "1.1rem",
                                md: "0.9rem",
                                base: "0.9rem",
                              }}
                            >
                              {errMsg}
                            </Text>
                          </>
                        )}
                      </Box>
                      {(isDoubleField ||
                        (activeStep > 9 && accountType === "Label")) && (
                        <Box>
                          <Textfield
                            color={"#E3E3E3"}
                            label={props.label2}
                            control={control}
                            type={props.type2}
                            placeholder={props.placeholder2}
                          />
                          <Text
                            mt={2}
                            textTransform="capitalize"
                            color="#FF0000"
                            fontWeight={"normal"}
                            fontFamily="poppins"
                            fontSize={{
                              lg: "1.1rem",
                              md: "0.9rem",
                              base: "0.9rem",
                            }}
                          >
                            {errMsg}
                          </Text>
                        </Box>
                      )}
                    </VStack>
                  </div>
                )}
                {(!othersGenre &&
                  activeStep === 8 &&
                  accountType === "Music Producer") ||
                (othersGenre &&
                  activeStep === 8 &&
                  accountType === "Music Producer") ? (
                  ""
                ) : (
                  <ButtonComponent
                    textProperties={{
                      color: "#E3E3E3",
                      fontWeight: "normal",
                      fontSize: { xl: "1.2rem", md: "1rem", base: "1rem" },
                      fontFamily: "poppins",
                      textTransform: "capitalize",
                    }}
                    height={{ lg: "45px", sm: "35px", base: "48px" }}
                    backgroundColor={"#2EBB55"}
                    isDisabled={disableBtn ? true : false}
                    mt={activeStep === 5 ? 12 : 4}
                    flag={false}
                    text={props.btnText}
                    onpress={handleSubmit(onSubmit)}
                  ></ButtonComponent>
                )}

                {activeStep === 3 && (
                  <Button
                    mt={3}
                    onPress={ResendOTP}
                    borderwidth={0}
                    backgroundColor={"transparent"}
                    _text={{
                      color: "#2EBB55",
                      fontSize: { lg: "1.1rem", md: "0.9rem", base: "0.9rem" },
                      fontFamily: "poppins",
                    }}
                  >
                    Resend OTP
                  </Button>
                )}

                {activeStep > 0 &&
                  activeStep < 11 &&
                  (!(
                    (!othersGenre &&
                      activeStep === 8 &&
                      !purchased &&
                      accountType === "Music Producer") ||
                    (othersGenre &&
                      activeStep === 8 &&
                      !purchased &&
                      accountType === "Music Producer") ||
                    (!othersGenre && activeStep === 9) ||
                    (othersGenre && activeStep === 10)
                  ) ||
                    (othersGenre &&
                      activeStep === 6 &&
                      (accountType === "Artist" ||
                        accountType === "Music Producer")) ||
                    (activeStep === 9 &&
                      (accountType === "Label" ||
                        accountType === "A & R" ||
                        accountType === "Manager")) ||
                    (activeStep === 8 && accountType === "Label")) &&
                  // ((!othersGenre && othersSocial && activeStep <= 10) ||
                  //   (othersGenre && othersSocial && activeStep <= 11)) &&
                  // (!(!othersGenre && activeStep === 9) ||
                  //   !(othersGenre && activeStep === 10)) &&
                  // !(
                  //   (!othersGenre && activeStep === 8) ||
                  //   (othersGenre && activeStep === 9)
                  // ) &&
                  // ((!othersGenre && activeStep <= 8 && purchased) ||
                  //   (othersGenre && activeStep <= 9 && purchased)) &&
                  !isMobile &&
                  isSimpleSignup &&
                  activeStep !== 3 && (
                    <Text
                      fontFamily="poppins"
                      onPress={handlePrevious}
                      mt={5}
                      textAlign={"center"}
                      color={"#AFAFAF"}
                      textTransform={"capitalize"}
                      letterSpacing={{ md: "0.8", base: "0" }}
                      fontWeight="normal"
                      fontSize={{
                        md: "1.2rem",
                        sm: "0.9rem",
                        base: "0.95rem",
                      }}
                      textDecorationLine={"underline"}
                    >
                      previous step
                    </Text>
                  )}
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
      {activeStep > 0 &&
        activeStep < 11 &&
        (!(
          (!othersGenre &&
            activeStep === 8 &&
            !purchased &&
            accountType === "Music Producer") ||
          (othersGenre &&
            activeStep === 8 &&
            !purchased &&
            accountType === "Music Producer") ||
          (!othersGenre && activeStep === 9) ||
          (othersGenre && activeStep === 10)
        ) ||
          (activeStep === 9 &&
            (accountType === "Label" || accountType === "A & R")) ||
          (activeStep === 8 && accountType === "Label")) &&
        isMobile &&
        isSimpleSignup && (
          <div className="previousStep">
            <Text
              fontFamily="poppins"
              onPress={handlePrevious}
              color={"#AFAFAF"}
              textTransform={"capitalize"}
              letterSpacing={{ md: "0.8", base: "0" }}
              fontWeight="normal"
              fontSize={{ md: "1.2rem", sm: "0.9rem", base: "0.95rem" }}
              textDecorationLine={"underline"}
            >
              previous step
            </Text>
          </div>
        )}
    </>
  );
};

export default LoginForm;
