import axios from "../../../config/axiosInterceptor";
import { HTTP_STATUS } from "../../../shared/constants";

export const updateOpportunityById = async (id, payload) => {
  try {
    const { data, status } = await axios.patch(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/admin/${id}`,
      payload
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while saving opportunity");
    }
  } catch (error) {
    console.log("Admin #updateOpportunityById Err");
    throw new Error(error);
  }
};

export const getOpportunity = async (id) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/detail?id=${id}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while facing opportunity");
    }
  } catch (error) {
    console.log("Admin #getOpportunity Err");
    throw new Error(error);
  }
};

export const removeOpportunity = async (id) => {
  try {
    const { data, status } = await axios.delete(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/admin/${id}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while deleting opportunity");
    }
  } catch (error) {
    console.log("Admin #removeOpportunity Err");
    throw new Error(error);
  }
};

export const getOpportunities = async (queryString) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/admin?${queryString}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while deleting opportunity");
    }
  } catch (error) {}
};
