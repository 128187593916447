import React, { useState, useContext, useEffect, useRef } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "../mycontracts.module.css";
import TrashIcon from "../../../assets/trash.png";
import EditIcon from "../../../assets/edit-2.png";
import AddIcon from "../../../assets/add-square.png";
import MaskGroupIcon from "../../../assets/Mask-group.png";
import DropDownIcon from "../../../assets/Icons/DropDownIconWhite.png";
import AudioFileUploadIcon from "../../../assets/recip-audio-file.png";
import PdfIcon from "../../../assets/pdfIcon.png";
import { Notification } from "../../NotifyMsg/Notification";

//import { DropzoneAreaBase } from "@material-ui-dropzone";

function TemplatesComponent() {
  const [picture, setPicture] = useState("");
  const [showRoleTypes, setShowRoleTypes] = useState([false]);
  const [roleTypes, setRoleTypes] = useState(["Select ..."]);
  const [roleFirstNames, setRoleFirstNames] = useState([""]);
  const [roleLastNames, setRoleLastNames] = useState([""]);
  const [roleEmails, setRoleEmails] = useState([""]);
  const [uploadFile, setUploadFile] = useState("");
  const [templateTitle, setTemplateTitle] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [documentMessage, setDocumentMessage] = useState("");
  const [titleRemain, setTitleRemain] = useState(52);
  const [messageRemain, setMessageRemain] = useState(10000);

  const router = useNavigate();

  const envelopRef = useRef(null);

  const verifyImageFile = (files) => {
    if (!files || files.length === 0) {
      Notification("error", "No file uploaded.");
      return false; // Provide feedback for no file uploaded
    }
    const currentFile = files[0];
    console.log(currentFile);
    const currentFileName = currentFile.name;

    if (!currentFileName.match(/.(pdf|docx|doc)$/i)) {
      Notification("error", "We only support PDF, DOCX, and DOC files.");
      return false;
    }
    return true;
  };

  const addRecipients = () => {
    let showRoleTypeStream = [...showRoleTypes];
    showRoleTypeStream.push(false);
    setShowRoleTypes(showRoleTypeStream);

    let roleTypeStream = [...roleTypes];
    roleTypeStream.push("Select ...");
    setRoleTypes(roleTypeStream);

    let roleFirstNameStream = [...roleFirstNames];
    roleFirstNameStream.push("");
    setRoleFirstNames(roleFirstNameStream);

    let roleLastNameStream = [...roleLastNames];
    roleLastNameStream.push("");
    setRoleLastNames(roleLastNameStream);

    let roleEmailStream = [...roleEmails];
    roleEmailStream.push("");
    setRoleEmails(roleEmailStream);
  };

  const deleteRecipients = (id) => {
    setShowRoleTypes(showRoleTypes.filter((item, index) => index !== id));
    setRoleTypes(roleTypes.filter((item, index) => index !== id));
    setRoleFirstNames(roleFirstNames.filter((item, index) => index !== id));
    setRoleLastNames(roleLastNames.filter((item, index) => index !== id));
    setRoleEmails(roleEmails.filter((item, index) => index !== id));
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const uploadTemplateDoc = async (file) => {
    var data = new FormData();
    console.log(file);
    data.append("file", file);
    data.append("type", "Public");
    var config = {
      method: "post",
      url: `http://localhost:5000/templates/upload`,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        // setValue("profilePic", response.data.file.url, {
        //   shouldValidate: true,
        // });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleOnDropLogoImage = async (files, rejectedFiles) => {
    if (!verifyImageFile(files)) return;
    if (files && files[0]) {
      // await uploadTemplateDoc(files[0])
      setUploadFile(files[0]);
      let reader = new FileReader();
      reader.onloadend = function () {
        setPicture({
          filename: files[0].name,
          filetype: files[0].type,
          fileimage: reader.result,
          datetime: files[0].lastModifiedDate.toLocaleString("en-IN"),
          filesize: filesizes(files[0].size),
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };
  const createTemplate = async () => {
    var data = new FormData();
    console.log(uploadFile);
    data.append("file", uploadFile);
    data.append("roleTypes", roleTypes);
    data.append("roleFirstNames", roleFirstNames);
    data.append("roleLastNames", roleLastNames);
    data.append("roleEmails", roleEmails);
    data.append("title", templateTitle);
    data.append("type", "Public");
    data.append("owner", "Ilarion");
    data.append("description", templateDescription);
    data.append("documentTitle", documentTitle);
    data.append("documentMessage", documentMessage);

    if (templateTitle === "") {
      Notification("error", "Enter the Template Title");
      return;
    }
    if (uploadFile === "") {
      Notification("error", "Select the file");
      return;
    }
    for (let i = 0; i < roleTypes.length; i++) {
      if (roleTypes[i] === "Select ...") {
        Notification("error", "Select the Role Types");
        return;
      }
    }
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_CONTRACT_URL}/templates/create`,
      data,
      headers: {
        accesstoken: localStorage.getItem("token"),
      },
    };
    await axios(config)
      .then(function (response) {
        console.log(response.data);
        window.location.assign(response.data.createUrl);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRoleType = (type, id) => {
    let roleTypeStream = [...roleTypes];
    roleTypeStream[id] = type;
    setRoleTypes(roleTypeStream);
    handleShowRoleType(id);
  };

  const handleShowRoleType = (id) => {
    let showRoleTypeStream = [...showRoleTypes];
    showRoleTypeStream[id] = !showRoleTypeStream[id];
    setShowRoleTypes(showRoleTypeStream);
  };

  const handleRoleFirstName = (e, id) => {
    const name = e.target.value;
    let roleFirstNameStream = [...roleFirstNames];
    roleFirstNameStream[id] = name;
    setRoleFirstNames(roleFirstNameStream);
  };
  const handleRoleLastName = (e, id) => {
    const name = e.target.value;
    let roleLastNameStream = [...roleLastNames];
    roleLastNameStream[id] = name;
    setRoleLastNames(roleLastNameStream);
  };
  const handleRoleEmail = (e, id) => {
    const email = e.target.value;
    let roleEmailStream = [...roleEmails];
    roleEmailStream[id] = email;
    setRoleEmails(roleEmailStream);
  };

  return (
    <div>
      <div className={styles.template_container}>
        <div className={styles.middle_title_layout}>
          <div className={styles.middle_title_font}>
            <p className={styles.middle_title_recip}>Title</p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <input
              type="text"
              className={styles.recipient_user_name}
              style={{ width: "100%" }}
              value={templateTitle}
              onChange={(e) => setTemplateTitle(e.target.value)}
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <label className={styles.recipient_group_user_label}>
              Description
            </label>
            <textarea
              type="text"
              className={styles.email_message}
              rows={5}
              value={templateDescription}
              onChange={(e) => {
                setTemplateDescription(e.target.value);
              }}
            />
          </div>
        </div>

        <div className={styles.fileUpload_layout}>
          <Dropzone
            onDrop={handleOnDropLogoImage}
            onFileDialogCancel={() => setPicture("")}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles.imageDropzone}>
                {picture ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {picture.filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                      <div className={styles.profile_file_image}>
                        <img src={picture.fileimage} />
                      </div>
                    ) : (
                      <div className={styles.license_file_image}>
                        <img src={PdfIcon} />
                      </div>
                    )}
                    <div className={styles.profile_file_detail}>
                      <h6>{picture.filename}</h6>
                      <p>
                        <span>Size : {picture.filesize}</span>{" "}
                        <span>Modified Time : {picture.datetime}</span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <img
                      className={styles.profile_pic_upload_image}
                      src={MaskGroupIcon}
                      alt="DefaultProfile"
                    />
                    <p className={styles.auth_title}>
                      Drag & Drop a file to upload
                    </p>
                  </>
                )}

                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </div>

        <div className={styles.middle_title_layout}>
          <div className={styles.middle_title_font}>
            <p className={styles.middle_title_recip}>Recipients</p>
            <button className={styles.add_recip_button} onClick={addRecipients}>
              <img src={AddIcon} />
              Add more recipients
            </button>
          </div>

          {showRoleTypes.map((item, index) => (
            <div key={index} className={styles.recipient_group}>
              <div className={styles.recipient_rectangle} />
              <div className={styles.recipient_form_group}>
                <div className={styles.user_name_group}>
                  <label className={styles.user_name_label}>
                    User Name {index + 1}
                  </label>
                  <button
                    className={styles.user_name_delete}
                    onClick={() => deleteRecipients(index)}
                  >
                    Delete{" "}
                    <img src={TrashIcon} style={{ width: 20, height: 20 }} />
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 24,
                      width: "50%",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        First Name
                      </label>
                      <input
                        type="text"
                        className={styles.recipient_user_name}
                        value={roleFirstNames[index]}
                        onChange={(e) => handleRoleFirstName(e, index)}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        Last Name
                      </label>
                      <input
                        type="text"
                        className={styles.recipient_user_name}
                        value={roleLastNames[index]}
                        onChange={(e) => handleRoleLastName(e, index)}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        Email
                      </label>
                      <input
                        type="email"
                        className={styles.recipient_user_name}
                        value={roleEmails[index]}
                        onChange={(e) => handleRoleEmail(e, index)}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_role_type_label}>
                        Role Type
                      </label>
                      <div
                        className={styles.recipient_role_type_group}
                        ref={envelopRef}
                      >
                        <div
                          className={styles.role_type_nav_item}
                          onClick={() => handleShowRoleType(index)}
                        >
                          <div className={styles.account_info}>
                            <p className={styles.account_name}>
                              {roleTypes[index]}
                            </p>
                          </div>
                          <img
                            src={DropDownIcon}
                            alt="dropdown"
                            className={`${styles.dropdown_icon_icon} ${
                              showRoleTypes[index] ? styles.rotate_180 : ""
                            }`}
                          ></img>
                        </div>
                        {showRoleTypes[index] && (
                          <div className={styles.role_type_menu}>
                            <div
                              className={styles.menu_item}
                              onClick={() => handleRoleType("Manager", index)}
                            >
                              <p className={styles.menu_item_text}>Manager</p>
                            </div>
                            <div
                              className={styles.menu_item}
                              onClick={() => handleRoleType("Label", index)}
                            >
                              <p className={styles.menu_item_text}>Label</p>
                            </div>
                            <div
                              className={styles.menu_item}
                              onClick={() => handleRoleType("Publisher", index)}
                            >
                              <p className={styles.menu_item_text}>Publisher</p>
                            </div>
                            <div
                              className={styles.menu_item}
                              onClick={() => handleRoleType("Artist", index)}
                            >
                              <p className={styles.menu_item_text}>Artist</p>
                            </div>
                            <div
                              className={styles.menu_item}
                              onClick={() =>
                                handleRoleType("Music Producer", index)
                              }
                            >
                              <p className={styles.menu_item_text}>
                                Music Producer
                              </p>
                            </div>
                            <div
                              className={styles.menu_item}
                              onClick={() => handleRoleType("Lawyer", index)}
                            >
                              <p className={styles.menu_item_text}>Lawyer</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        Audio File
                      </label>
                      <div className={styles.recipient_user_name}>
                        <img src={AudioFileUploadIcon} />
                        <input type="file" style={{ visibility: "hidden" }} />
                      </div>
                    </div> */}
                  </div>
                  <img src={EditIcon} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.middle_title_layout}>
          <div className={styles.middle_title_font}>
            <p style={{ fontSize: 30, fontWeight: 500, margin: 0 }}>Document</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 24,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 24,
                width: "100%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={styles.recipient_group_user_label}>
                  Title
                </label>
                <input
                  type="text"
                  className={styles.recipient_user_name}
                  value={documentTitle}
                  onChange={(e) => {
                    const lenth = e.target.value.length;
                    if (lenth > 52) return;
                    setTitleRemain(52 - lenth);
                    setDocumentTitle(e.target.value);
                  }}
                />
                <label
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    fontWeight: 400,
                    height: 18,
                    color: "#E3E3E3",
                  }}
                >
                  Character remaining:{titleRemain}
                </label>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={styles.recipient_group_user_label}>
                  Message
                </label>
                <textarea
                  type="text"
                  className={styles.email_message}
                  rows={5}
                  value={documentMessage}
                  onChange={(e) => {
                    const lenth = e.target.value.length;
                    if (lenth > 10000) return;
                    setMessageRemain(10000 - lenth);
                    setDocumentMessage(e.target.value);
                  }}
                />
                <label
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    fontWeight: 400,
                    height: 18,
                    color: "#E3E3E3",
                  }}
                >
                  Character remaining:{messageRemain}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sidebar_bottom_button_container}>
        <button
          className={styles.sidebar_bottom_button}
          onClick={() => router("/my-contracts/templates")}
        >
          Prev
        </button>
        <button
          className={styles.sidebar_bottom_button}
          onClick={createTemplate}
        >
          Next
        </button>
      </div>
    </div>
  );
}
export default TemplatesComponent;
