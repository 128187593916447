import React, { useEffect, useMemo, useState } from "react";
// import io from "socket.io-client";
import { IconButton } from "@mui/material";
import {
  Notification as NotificationIcon,
  Greennotification,
} from "../../assets/smartlink";
import classes from "./Navbar.module.css";
import { useNavigate } from "react-router-dom";
import { Notificationsocket } from "../../context/web-socket/socket";
import { Notification as ToastNotification } from "../../components/NotifyMsg/Notification";
import { useLocation } from "react-router-dom";
import axios from "axios";
import FormatNumber from "../../utils/FormatNumber";
import useAuth from "../../context/AuthContext";

export default function Notification() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState(
    localStorage.getItem("notificationCount") || ""
  );
  const { user } = useAuth();

  // console.log(process.env.REACT_APP_SOCKET_URL);
  // const socket =
  // 	_id &&
  // 	io.connect(process.env.REACT_APP_SOCKET_URL, {
  // 		path: '/chatt',
  // 		query: {
  // 			userId: _id,
  // 		},
  // 	});
  // React.useEffect(() => {
  // 	socket?.on('recieveLiveNotifcation', (data) => {
  // 		console.log('in if data', data);
  // 		toast.success(data.notification.message);
  // 		console.log('socket io data', data);
  // 	});
  // 	// }
  // }, [socket]);
  // return true;

  useEffect(() => {
    const handleNewNotification = (value) => {
      ToastNotification("success", value?.description);
      setHasNewNotification(true);
    };
    Notificationsocket.on("send-notification-user", handleNewNotification);
    // Cleanup the event listener to avoid memory leaks
    return () => {
      Notificationsocket.off("send-notification-user", handleNewNotification);
    };
  }, [Notificationsocket]);

  useEffect(() => {
    const fetchNotificationCount = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFICATIONS_URL}/notification/get-unread-notifications-count/${user?._id}`
        );
        if (response?.data?.success) {
          const newCount = response?.data?.unread;
          if (newCount !== notificationCount) {
            const formatData = FormatNumber(newCount);
            setNotificationCount(formatData);
            localStorage.setItem("notificationCount", formatData);
          }
        } else {
          throw new Error(response?.message);
        }
      } catch (error) {
        console.error("Error fetching notification count:", error);
      }
    };

    const notificationCountTimeId = setInterval(fetchNotificationCount, 20000);

    // Fetch initial count
    fetchNotificationCount();

    return () => {
      clearInterval(notificationCountTimeId);
    };
  }, [user?._id]);

  return (
    <>
      <div className="demo">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={`${classes.notificationBtn} ${
            hasNewNotification && pathname !== "/notification"
              ? classes.notificationActive
              : ""
          }`}
          onClick={() => {
            setHasNewNotification(false);
            navigate("/notification");
          }}
        >
          <img src={Greennotification} alt="Notification" />
          {notificationCount > 0 && (
            <span className="notification_icon_button__badge">
              {notificationCount}
            </span>
          )}
        </IconButton>
      </div>
    </>
  );
}
