import React, { Fragment, useEffect, useState, useCallback, useHistory } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useLibraryStore from "../../store/library";
import {
  Box,
  useMediaQuery,
} from "@material-ui/core";

import { useNavigate } from "react-router";

import classes from "./Sidebar.module.css";

import useWindowDimensions from "../../utils/WindowDimensions";

import useAuth from "../../context/AuthContext";

import styles from "../../../src/components/LibrariesComponents/Libraries/Libraries.module.css";
import BlueButtonComponents from "../../components/LibrariesComponents/AdditionalComponents/utils/BlueButtonComponent";
import universalStyles from "../../../src/components/UniversalComponents/universal.module.css";
import ShowStorage from "../../components/LibrariesComponents/Libraries/ShowStorage";
import Setting from "../../components/LibrariesComponents/AdditionalComponents/utils/Setting";
import { SearchTagsHorizontal } from "../../components/LibrariesComponents/AdditionalComponents/SearchTags";
import LibLineDivider from "../../components/LibrariesComponents/Libraries/LibLineDivider";
import { BPMRangeSlider } from "../../components/LibrariesComponents/AdditionalComponents/utils/BPMRangeSlider";
import ToggleExpand from "../../components/LibrariesComponents/Libraries/ToggleExpand";
import { KEY, MODE_TONE, SCALE } from "../../shared/constants";
import OutlineButton from "../../components/LibrariesComponents/AdditionalComponents/OutlineButton";
import Sharing from "../../components/LibrariesComponents/AdditionalComponents/utils/Sharing";
import SubTitleBar from "../../components/LibrariesComponents/Libraries/SubTitleBar";
import MassUploadModal from "../../components/LibrariesComponents/Libraries/Modals/MassUploadModal";
import WelcomePack from "../../components/LibrariesComponents/Libraries/Modals/WelcomePack";
import { StyledMenu, StyledMenuItem } from "../../components/LibrariesComponents/AdditionalComponents/StyledMenu";
import allImages from "../../../src/assets";
import { getTotalFileSize } from "../../context/apis/audio-library";
import { Notification } from "../../components/NotifyMsg/Notification";

const sortData = [
  {
    id: 1,
    title: "Newest - Oldest",
  },
  {
    id: 2,
    title: "Oldest - Newest",
  },
];

export function SubSideBar(props) {

  const router = useNavigate();
  const { isFreemium, user, showBetaFeatures, currentRoleType } = useAuth();

  const {
    range,
    fileKey,
    scale,
    subTitle,
    selectedTags,
    mode,
    setMode,
    sortBy,
    setSortBy,
    setRange,
    setFileKey,
    setScale,
    setSelectedTags,
    setQuery,
    currentSubBarIndex,
    newAdded,
    usedAudioStorage,
    setUsedAudioStorage,
    activeBar,
    setActiveBar
  } = useLibraryStore();
  console.log("ACTIVE Bar==", activeBar);
  const isDesktop = useMediaQuery("(min-width: 1200px)");

  const [sortAnchor, setSortAnchor] = useState(null);
  const [sharingAnchor, setSharingAnchor] = useState(null);
  const [uploadAnchor, setUploadAnchor] = useState(null);

  const [show, setShow] = React.useState(true);
  const { height, width } = useWindowDimensions();
  const [welcomePackModalOpen, setWelcomePackModalOpen] = useState(true);


  const layoutType = isDesktop ? styles.main_layout : styles.main_layout_mobile;
  const titleType = isDesktop ? styles.page_title : styles.page_title_mobile;

  const [settingAnchor, setSettingAnchor] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);

  const handleSettingClick = (event) => {
    setSettingAnchor(event.currentTarget);
  };

  const handleSortClick = (event) => {
    setSortAnchor(event.currentTarget);
  };

  const handleShareClick = (event) => {
  };

  const onRefresh = async () => {
    // setIsRefresh((prev) => !prev);
    resetFiliter();

  };

  const resetFiliter = () => {
    setSelectedTags([]);
    setRange([0, 200]);
    setFileKey("");
    setQuery("");
    setScale("");
    setSortBy(sortData[0]);
    setMode("");
  }

  const [massUploadModalOpen, setMassUploadModalOpen] = useState(false);

  // const [activeBar, setActiveBar] = useState(0);


  React.useEffect(() => { }, [isDesktop]);

  React.useEffect(() => {
    if (width > 1100) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [width]);

  React.useEffect(async () => {
    await getTotalFileSize(user._id)
      .then((response) => {
        if (response?.data) {
          const totalSizeInGB = (response?.data.totalSize / (1024 * 1024 * 1024)).toFixed(2);
          setUsedAudioStorage(totalSizeInGB);
        }
        else {
          Notification("error", "Total File Size Went Wrong!");
        }
      })
      .catch((error) => {
        Notification("error", "Total File Size Went Wrong!");
      });
  }, [newAdded]);


  const subList = [
    { text: "All Files", imageSrc: "/v2Assets/LibSidebarIcons/AllFiles.png" },
    // { text: "Recent", imageSrc: "/v2Assets/LibSidebarIcons/Recent.png" },
    { text: "Beat Packs", imageSrc: "/v2Assets/LibSidebarIcons/BeatPacks.png" },
    { text: "Inbox", imageSrc: "/v2Assets/LibSidebarIcons/Inbox.png" },
    { text: "Starred", imageSrc: "/v2Assets/LibSidebarIcons/Starred.png" },
    { text: "Trash", imageSrc: "/v2Assets/LibSidebarIcons/Trash.png" },
    { text: "Sent", imageSrc: "/v2Assets/LibSidebarIcons/sent.svg" },
    // {
    //   text: "Get More Storage",
    //   imageSrc: "/v2Assets/LibSidebarIcons/Getmorestorage.png",
    // },
  ];

  const handleSubMenuClick = (item, index) => {
    setActiveBar(index);
    resetFiliter();
    switch (index) {
      case 0:
        router(`/myaudiolibrary`)
        break;
      // case 1:
      //   router(`/myaudiolibrary/recent`)
      //   break;
      case 1:
        router(`/myaudiolibrary/beat_pack`)
        break;
      case 2:
        router(`/myaudiolibrary/inbox`)
        break;
      case 3:
        router(`/myaudiolibrary/starred`)
        break;
      case 4:
        router(`/myaudiolibrary/trash`)
        break;
      case 5:
        router(`/myaudiolibrary/sent`)
        break;
      // case 5:
      //   router(`/myaudiolibrary/get_more_storage`)
      //   break;

      default:
        router(`/myaudiolibrary`)
        break;
    }
  };

  const handleTagStatusChange = (tags) => {
    // Do something with the child's status in the parent
    setSelectedTags(tags);
  };

  const handleSettingClose = () => {
    setSettingAnchor(null);
  };

  const handleSortClose = () => {
    setSortAnchor(null);
  };

  const applySorting = (value) => {
    setSortBy(value);
    setSortAnchor(null);
  };

  const handleMassUploadModal = () => {
    if (usedAudioStorage > 2) {
      Notification("error", "You have reached your storage limit!");
      return;
    }
    setMassUploadModalOpen(!massUploadModalOpen);
    setUploadAnchor(null);
  };

  const SubTitleBarContent = useCallback(({ index }) => {
    switch (index) {
      case 0:
        return (
          <>
            <SubTitleBar
              // title="Library"
              title={subTitle}
              hasSubOption={true}
              tags={selectedTags}
              onTagStatusChange={handleTagStatusChange}
            />
          </>
        );
      case 1:
        return (
          <>
            <SubTitleBar
              // title="Beat Pack"
              title={subTitle}
              hasSubOption={true}
              tags={selectedTags}
              onTagStatusChange={handleTagStatusChange}
              createButton
            />

          </>
        );
      case 2:  // Inbox
        return (
          <>

          </>
        );

      case 3: // Starred
        return (
          <>
            <SubTitleBar
              title={subTitle}
              hasSubOption={true}
              tags={selectedTags}
              onTagStatusChange={handleTagStatusChange}
            />

          </>
        );
      case 4: // Trash
        return (
          <>
            <SubTitleBar
              title={subTitle}
              hasSubOption={true}
              tags={selectedTags}
              onTagStatusChange={handleTagStatusChange}
            />

          </>
        );
      case 5: // Sent
        return (
          <>
          </>
        );

      case 6:
        return (
          <>
            <SubTitleBar
              title={subTitle}
              hasSubOption={false}
            />
          </>
        );
      case 7:
        return (
          <>
            <SubTitleBar
              // title="Pack Detail"
              title={subTitle}
              hasSubOption={true}
              createButton
            />
          </>
        );
      case 8:
        return (
          <>
            <SubTitleBar
              // title="Pack More Detail"
              title={subTitle}
              hasSubOption={true}
              createButton
            />
          </>
        );
      default:
        return (
          <>
            <SubTitleBar
              // title="Library"
              title={subTitle}
              hasSubOption={true}
              tags={selectedTags}
              onTagStatusChange={handleTagStatusChange}
            />
          </>
        );
    }
  }, [currentSubBarIndex, subTitle]);


  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <Box
        sx={{
          minWidth: "210px",
          backgroundColor: "#0E1210",
          display: "flex",
          flexDirection: "column",
          height: '100%',

        }}
      >
        <List style={{ margin: "5px" }}>
          {subList.map((item, index) => (
            <React.Fragment key={`submenufragmentlibrary-${index}`}>
              <ListItem
                className={
                  index == activeBar
                    ? classes.activeListItem
                    : classes.listItem
                }
                onClick={(e) => {
                  handleSubMenuClick(item, index);
                }}
              >
                <ListItemIcon style={{ minWidth: "30px" }}>
                  <img alt={item.text} src={item.imageSrc} />
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  className={
                    index == activeBar
                      ? styles.activeTitle3
                      : styles.title3
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
        <ShowStorage useStorage={usedAudioStorage} totalStorage={2} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", height: "100%", width: "100%" }}>
        <Box
          className={layoutType}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          {/* When activeBar is not inbox */}
          {activeBar != 2 && (
            <Box
              className={styles.parent_wrapper}
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "30px",
                borderBottom: "1px solid #414241",
              }}
            >
              <Box
                className={styles.titleWrapper}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginRight: "15px !important"
                }}
              >
                <p className={titleType}>My Library</p>
              </Box>

              <Box className={`${styles.buttonGroup} ${styles.buttonsWrapper}`}>
                <div className={universalStyles.search_box_in_lib} style={{ background: "#414241" }}>
                  <img
                    className={universalStyles.search_icon}
                    src="/v2Assets/connect_assets/Search.png"
                    alt="search"
                  ></img>
                  <input
                    className={universalStyles.search_box_input}
                    type="text"

                    placeholder={"Search in Library"}
                    onChange={(data) => {
                      // setQuery(data.target.value);
                      setQuery(data.target.value.toLowerCase());
                    }}
                  />
                </div>
                <div
                  className={`${styles.filterBtn1} ${settingAnchor && styles.filterActive
                    }`}
                  onClick={handleSettingClick}
                >
                  <Setting />
                </div>
                <div className={styles.refreshBtn} onClick={onRefresh}>
                  <img
                    src={allImages.refresh}
                    className={`${isRefresh ? styles.loading : ""}`}
                    alt="refresh"
                  />
                </div>

                <StyledMenu
                  id="customized-menu1"
                  anchorEl={settingAnchor}
                  keepMounted
                  open={Boolean(settingAnchor)}
                  onClose={handleSettingClose}
                  position="right"
                  style={{ width: "500px" }}
                >

                  <SearchTagsHorizontal foundText={false} onTagStatusChange={handleTagStatusChange} tags={selectedTags} />
                  <LibLineDivider />
                  <div>
                    <div className={styles.slider_style_16}>BPM</div>
                    <BPMRangeSlider range={range} setRange={setRange} />
                  </div>

                  <LibLineDivider />

                  <div>
                    <div className={styles.slider_style_16}>Key</div>
                    <div style={{ borderBottom: "1px solid #AFAFAF" }}>
                      <ToggleExpand primary={fileKey} menu={KEY} setValue={setFileKey} />
                    </div>
                  </div>
                  <div>
                    <div className={styles.slider_style_16}>Scale</div>
                    <div style={{ borderBottom: "1px solid #AFAFAF" }}>
                      <ToggleExpand
                        primary={scale}
                        menu={SCALE}
                        setValue={setScale}
                      />
                    </div>
                  </div>
                  <div className={styles.slider_style_16}>Mode / Tone</div>
                  <ToggleExpand
                    primary={mode}
                    menu={MODE_TONE}
                    setValue={setMode}
                  />

                </StyledMenu>
                <OutlineButton
                  title="Sort By"
                  handler={handleSortClick}
                  filter={sortAnchor}
                />

                <StyledMenu
                  id="sortbymenu"
                  anchorEl={sortAnchor}
                  keepMounted
                  open={Boolean(sortAnchor)}
                  onClose={handleSortClose}
                  position="right"
                >
                  {sortData.map(({ id, title }) => (
                    <StyledMenuItem
                      key={`sortby-${id}`}
                      className={sortBy.id === id ? "active" : ""}
                    >
                      <ListItemText
                        primary={title}
                        onClick={() => applySorting({ id, title })} // onClick moved up to the menu item
                        style={{ textAlign: "center" }}
                      />
                    </StyledMenuItem>
                  ))}
                </StyledMenu>
                {/* 
                <div
                  className={`${styles.filterBtn1} ${sharingAnchor && styles.filterActive
                    }`}
                  onClick={handleShareClick}
                >
                  <Sharing />
                </div> */}
                <BlueButtonComponents text="UPLOAD" onClickFunction={handleMassUploadModal} cursor="pointer" />
              </Box>
            </Box>
          )}

          <SubTitleBarContent index={activeBar} />
          {props.childComponent}
        </Box>
      </Box>

      <MassUploadModal
        massUploadModalOpen={massUploadModalOpen}
        setMassUploadModalOpen={setMassUploadModalOpen}
      />
      {currentSubBarIndex == 2 && (
        <WelcomePack
          open={welcomePackModalOpen}
          setOpen={setWelcomePackModalOpen}
        />
      )}
    </Box>
  );

}
