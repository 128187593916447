import React from "react";

const PauseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M15 2.5C12.5277 2.5 10.111 3.23311 8.05538 4.60663C5.99976 5.98015 4.39761 7.93238 3.45151 10.2165C2.50542 12.5005 2.25787 15.0139 2.74019 17.4386C3.2225 19.8634 4.41301 22.0907 6.16117 23.8388C7.90933 25.587 10.1366 26.7775 12.5614 27.2598C14.9861 27.7421 17.4995 27.4946 19.7836 26.5485C22.0676 25.6024 24.0199 24.0002 25.3934 21.9446C26.7669 19.889 27.5 17.4723 27.5 15C27.5 11.6848 26.183 8.50537 23.8388 6.16117C21.4946 3.81696 18.3152 2.5 15 2.5V2.5ZM18.325 17.1625L16.725 18.0875L15.125 19.0125C13.0625 20.2 11.375 19.225 11.375 16.85V13.15C11.375 10.7625 13.0625 9.8 15.125 10.9875L16.725 11.9125L18.325 12.8375C20.3875 14.025 20.3875 15.975 18.325 17.1625Z"
        fill="white"
      />
    </svg>
  );
};

export default PauseIcon;
