import React from 'react';
import styles from '../../auth_pages.module.css';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Box, Heading } from 'native-base';
import { DeleteSoundPack } from '../../../soundpack_page/Modals/DeleteSoundPack';
// import { useMutation } from "@apollo/client";
// import { upload } from "../../../../V2BackendComponents/Mutations/file_mutations/FileMutations";
// import { toast } from "react-toastify";
import DefaultProfile from '../../../../assets/LoginScreens/default.png';
import { Loader } from '../../../UniversalComponents/Loader';
import { SignUpMutation } from '../../../../Graphql_services/SignUpMutation';
import { Notification } from '../../../NotifyMsg/Notification';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation } from '@apollo/client';

const ProducerProfilePic = ({ onBack, user, onNext, completeAccount }) => {
  const types = ['image/jpeg', 'image/png'];
  const [profilePic, setProfilePic] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const canContinue = profilePic !== null;
  const [signUpMutation, { loading, error, data }] =
    useMutation(SignUpMutation);

  // export default UploadProfile;
  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;

      if (!types.includes(currentFileType)) {
        console.log('not accepted');
        return false;
      }
      return true;
    }
  };

  const onDrop = async (files, rejectedFiles) => {
    const profileData = new FormData();
    if (rejectedFiles && rejectedFiles.length > 0) {
      verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (verifyImageFile(files)) {
        try {
          profileData.append('file', files[0]);
          profileData.append('type', 'Public');
          setIsLoadingProfile(true);

          const response = await axios.post(
            `${process.env.REACT_APP_FILE_URL}/file/uploadprofilepicture`,
            profileData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
                setLoadingProgress(progress);
              },
            }
          );

          if (response.status === 200) {
            const imageUrl = response.data.file.url;
            setProfilePic({
              url: imageUrl,
              name: files[0].name,
            });
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          // Handle the error, e.g., show a toast or error message
        } finally {
          setIsLoadingProfile(false);
        }
      }
    }
  };

  const ImageDropzone = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    onDrop,
  });

  const handleSignUp = async () => {
    const { profile = {} } = user;

    const Variables = {
      firstname: profile.name.firstName,
      lastname: profile.name.firstName,
      email: profile.emial,
      password: profile.password,
      phoneno: parseFloat(profile.phoneNo),
      roleId: profile.roleID,
      profilePic: profilePic?.url,
      username: profile.userName,
      isFreemium: false,
      isVerified: true,
      isAdminReviewed: true,
    };

    if (profile.label) {
      Variables.labelName = profile.label;
    }
    if (profile.spotifyId) {
      Variables.spotifyUrl = profile.spotifyId;
    }
    if (profile.monthlyListeners) {
      Variables.noOfMonthlyListeners = Number(profile.monthlyListeners);
    }
    if (profile.genre) {
      Variables.genre = profile.genre;
    }

    try {
      const result = await signUpMutation({
        variables: Variables,
      });

      if (result?.data?.signUp?.success) {
        const UserResponseData = result?.data?.signUp;

        localStorage.setItem('profilePic', UserResponseData?.user?.profilePic);
        localStorage.setItem('userName', UserResponseData?.user?.userName);
        localStorage.setItem(
          'accountType',
          UserResponseData?.user?.roleId?.roleName
        );
        localStorage.setItem('token', UserResponseData?.accessToken);
        localStorage.setItem('userId', UserResponseData?.user?._id);
        localStorage.setItem(
          'opportunityToken',
          UserResponseData?.reactionAccessToken
        );
        document.cookie = `sessionId=${data?.UserResponseData?.sessionId};path=/;domain=app.unitedmarket.com`;
        document.cookie = `reactionAccessToken=${data?.UserResponseData?.reactionAccessToken};path=/;domain=app.unitedmarket.com`;

        // Notification('success', result?.data?.signUp?.message);
        // window.location.href = '/home';

        // go to the next step

        let updatedUser = { ...user };
        let updatedProfile = { ...user.profile };
        updatedProfile.profilePic = profilePic?.url;
        updatedUser.profile = updatedProfile;

        onNext(updatedUser);
      } else {
        Notification('error', result?.data?.signUp?.message);
      }
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const errorMessage = error.graphQLErrors[0]?.message;
        Notification('error', `SignUp Error: ${errorMessage}`);
      } else {
        Notification(
          'error',
          `An unexpected error occurred. Please try again.`
        );
      }
    }
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
        >
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}></div>
            {/* <div className={styles.input_list}></div> */}
            <div className={styles.drag_image}>
              {isLoadingProfile && (
                <div className={styles.loaderProfile}>
                  <Loader />
                </div>
              )}

              {!isLoadingProfile && !profilePic ? (
                <>
                  <div
                    {...ImageDropzone.getRootProps()}
                    className={styles.imageDropzone}
                  >
                    <img
                      className={styles.profile_pic_upload_image}
                      src={DefaultProfile}
                      alt='DefaultProfile'
                    />
                    <p className={styles.auth_title}>
                      Upload Your Profile Picture
                    </p>
                    <p className={styles.auth_subtitle}>
                      Images with higher resolution are preferred
                    </p>
                    <div className={styles.drag_track_button}>
                      <p className={styles.drag_track_button_text}>
                        Choose File
                      </p>
                    </div>
                    <input
                      {...ImageDropzone.getInputProps()}
                      id='uploadImage'
                      accept='image/*'
                    />
                  </div>
                </>
              ) : (
                !isLoadingProfile && (
                  <Box width={{ lg: '48%', base: '20%' }}>
                    <Displayfile
                      fileName={profilePic?.name}
                      file={profilePic?.url}
                      setDeletePopup={(data) => setDeletePopup(data)}
                      setDeleteName={() => {}}
                      desc={'Are you sure you want to delete?'}
                    />
                  </Box>
                )
              )}
            </div>
          </div>

          <div className={styles.buttonControls}>
            {canContinue ? (
              <div onClick={() => handleSignUp()} className={styles.signin_box}>
                <p className={styles.connect_text}>
                  {loading ? (
                    <CircularProgress size={25} sx={{ color: 'white' }} />
                  ) : (
                    'Continue'
                  )}
                </p>
              </div>
            ) : (
              <div className={styles.signin_box_inactive}>
                <p className={styles.connect_text}>Continue</p>
              </div>
            )}
            <p className={styles.previous_text} onClick={onBack}>
              Previous Step
            </p>
          </div>
        </div>
      </div>
      <DeleteSoundPack
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        deleteFtn={() => setProfilePic(null)}
        desc={'Are you sure you want to delete?'}
      />
    </div>
  );
};

function Displayfile(props) {
  const { fileName, file, setDeletePopup, setDeleteName } = props;
  return (
    <Box w={'100%'}>
      <Box alignSelf={'center'} my={8}>
        <img src={file} alt='file' className={styles.profile_pic}></img>
      </Box>

      <Heading
        fontFamily={'Poppins'}
        fontWeight={500}
        fontSize={'20px'}
        textAlign={'center'}
        color={'#fff'}
        py={5}
        alignSelf={'center'}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        maxW={'80%'}
      >
        {fileName}
      </Heading>
      {/* <div
        className={styles.delete_button}
        onClick={() => {
          setDeletePopup(true);
          setDeleteName(fileName);
        }}
      >
        Delete
      </div> */}
    </Box>
  );
}

export default ProducerProfilePic;
