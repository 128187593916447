import React from "react";

const CommentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M15.888 14.2606L16.213 16.894C16.2313 17.0481 16.2063 17.2043 16.1406 17.345C16.0749 17.4856 15.9712 17.6051 15.8413 17.69C15.7114 17.7749 15.5603 17.8218 15.4051 17.8255C15.2499 17.8291 15.0968 17.7893 14.963 17.7106L12.0797 15.994C11.9865 15.937 11.9194 15.8456 11.8929 15.7396C11.8664 15.6336 11.8826 15.5215 11.938 15.4273C12.3561 14.6602 12.5766 13.801 12.5797 12.9273C12.5369 11.421 11.8991 9.993 10.8058 8.95593C9.71249 7.91886 8.25282 7.35722 6.74635 7.39398C6.10101 7.39127 5.45946 7.49257 4.84635 7.69398C4.77531 7.71729 4.69931 7.72105 4.62632 7.70487C4.55333 7.6887 4.48604 7.65318 4.4315 7.60205C4.37695 7.55092 4.33718 7.48605 4.31633 7.41425C4.29548 7.34246 4.29434 7.26637 4.31302 7.19398C4.75006 5.64279 5.69059 4.281 6.98652 3.32301C8.28245 2.36501 9.86019 1.86522 11.4714 1.90232C15.538 1.90232 18.8297 4.97732 18.8297 8.76898C18.8198 9.85396 18.547 10.9204 18.0346 11.8768C17.5223 12.8333 16.7857 13.6512 15.888 14.2606Z"
        fill="#AFAFAF"
      />
      <path
        d="M11.3332 12.9283C11.3302 13.8954 10.9814 14.8294 10.3498 15.5617C9.90728 16.083 9.35524 16.5004 8.73301 16.7842C8.11078 17.0679 7.43366 17.2111 6.74984 17.2033L4.57484 18.495C4.49133 18.5446 4.39562 18.5699 4.29851 18.568C4.2014 18.5661 4.10676 18.537 4.02528 18.4841C3.9438 18.4313 3.87874 18.3567 3.83742 18.2688C3.79611 18.1809 3.78021 18.0831 3.7915 17.9867L3.99984 16.345C3.43958 15.9674 2.97982 15.4589 2.66037 14.8635C2.34092 14.2682 2.1714 13.604 2.1665 12.9283C2.17235 12.2211 2.35733 11.5268 2.70415 10.9104C3.05098 10.294 3.54836 9.77548 4.14984 9.40334C4.92421 8.90286 5.82783 8.6393 6.74984 8.64501C7.92539 8.60611 9.06833 9.03533 9.92768 9.83843C10.787 10.6415 11.2925 11.7528 11.3332 12.9283Z"
        fill="#AFAFAF"
      />
    </svg>
  );
};

export default CommentIcon;
