import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { ModelContentBox, Modelstyle, EditCoverButton } from "./style";
import useAuth from "../../../../context/AuthContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MassEditModal from "./MassEditModal";
import styles from "../Libraries.module.css";
import { get } from "lodash";
import { useDropzone } from "react-dropzone";
import useAppState from "../../../../context/useAppState";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LibrariesSchema from "../../YupSchema/LibrariesSchema";
import { toast } from "react-toastify";
import universalStyles from "../../../UniversalComponents/universal.module.css";
import BlueButtonComponents from "../../AdditionalComponents/utils/BlueButtonComponent";
import { ChangeHistoryOutlined } from "@material-ui/icons";
import EditableText from "../EditableText";
import AnalyzeModal from "./AnalyzeModal";
import UploadField from "../../AdditionalComponents/utils/UploadFiled";
import { useLibrary } from "../../../../context/LibraryContext";
import { DEFAULT_VALUE } from "../../../../shared/constants";
import { TrashRed } from "../../../../assets/smartlink";
import LinearProgress from '@mui/material/LinearProgress';
import CircularLoaderPage from "../../../UniversalComponents/CircularLoader/CircularLoaderPage";
import { Notification } from "../../../NotifyMsg/Notification";
import { downloadResource, fetchFileBlob, fetchFileList, deleteDownloadedFiles } from "../../../../context/apis/audio-library";


const MassUploadModal = ({
  massUploadModalOpen = false,
  type = "",
  RedirectTo = "",
  setMassUploadModalOpen = () => { },
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [massEditModalOpen, setMassEditModalOpen] = useState(false);
  const [analyzeModal, setAnalyzeModal] = useState(false);

  const [coverTitle, setCoverTitle] = useState("");
  const [coverDescription, setCoverDescription] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);

  const initialValues = {
    forms: [

    ],
  };

  const [dataList, setDataList] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [dropbox_link, setDropbox_link] = useState("");

  const handleModalClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setMassUploadModalOpen(false);
    // resetForm();
  };

  useEffect(() => {
    if (massUploadModalOpen) {
      resetForm();
    }
  }, [massUploadModalOpen]);

  const HandelClick = () => {
    if (type === "link") {
      navigate(RedirectTo);
    }
  };

  const changeTitle = (newText) => {
    setCoverTitle(newText);
  };

  const changeDescription = (newText) => {
    setCoverDescription(newText);
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0]; // Assuming only one file is selected
    if (file) {
      setFile(file);

      const imageUrl = URL.createObjectURL(file);
      setCoverImage(imageUrl);
    } else {
      // If no file selected, reset file and coverImage to their initial states.
      setFile(null);  // Set file to null if no file is chosen
      setCoverImage("");
    }
  };

  const handleDivClick = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };

  const handleLinkInputChange = (e) => {
    setDropbox_link(e.target.value);
  };


  const [audioFiles, setAudioFiles] = useState([]);

  useEffect(() => {
    setAudioFiles(audioFiles)
  }, [audioFiles])

  const extractDropBoxLink = async () => {
    
    if (dropbox_link == "") {
      Notification("error", "Please input Dropbox Link");
      return;
    }
    try {
      setIsLoading(true);
      const convertedLink = dropbox_link.replace(/dl=0$/, "dl=1");
      console.log("convertedLink==== ", convertedLink);

      const result = await downloadResource(convertedLink);
      console.log("dddddddd", result.message);
      if (result.status === 200) {
        const fileNames = await fetchFileList();
        const downloadPromises = fileNames.map(fetchFileBlob);
        // Wait for all files to be fetched
        const newFiles = await Promise.all(downloadPromises);
        // Update the state with the new files
        setIsLoading(false);
        setAudioFiles((prevFiles) => [...prevFiles, ...newFiles]);
        if (newFiles.length > 0) {
          const updatedData = newFiles.map(() => ({ ...DEFAULT_VALUE }));
          setDataList((prevFormData) => ({
            ...prevFormData,
            forms: [...prevFormData.forms, ...updatedData],
          }));
        }
        await deleteDownloadedFiles();
      }
      else {
        setIsLoading(false);
        Notification("error", "The link looks invalid");
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching audio files:', error);
      Notification("error", "The link looks invalid");
    }
  };


  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setErrorMessage("Please upload only audio files.");
    } else {
      setErrorMessage("");
      setAudioFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }
  }, []);

  const resetForm = () => {
    setCoverTitle("");
    setCoverDescription("");
    setCoverImage("");
    setAudioFiles([]);
    setFile(null);

  };

  const makeDataList = () => {
    const newInitialValues = {
      forms: [],
    };

    const defaultValue = DEFAULT_VALUE;
    if (audioFiles.length > 0) {
      for (let index = 0; index < audioFiles.length; index++) {
        newInitialValues.forms.push({ ...defaultValue });
      }
    }

    setDataList(newInitialValues);
  };

  const truncateString = (str) => {
    if (str.length > 6) {
      return str.substring(0, 6) + '...';
    } else {
      return str;
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }



  const handleNextButton = () => {
    if (audioFiles.length < 1) {
      setErrorMessage("You should upload at lease one file")
      return;
    }
    // makeDataList();

    makeDataList();
    setDataList((prevState) => {
      const updatedForms = [...prevState.forms];
      updatedForms[0] = {
        ...updatedForms[0],
        basicInfo: {
          ...updatedForms[0].basicInfo,
          imageURL: coverImage,
          fileName: coverTitle,
          fileNote: coverDescription,
          ...(file && { file: file }),
        },
      };
      console.log("Updated Form", updatedForms);
      return { ...prevState, forms: updatedForms };
    });
    setMassEditModalOpen(true);
    handleModalClose();
  };

  const handleDelete = (index) => {
    const updatedFiles = [...audioFiles];
    updatedFiles.splice(index, 1);
    setAudioFiles(updatedFiles);
  }


  return (
    <>
      <Modal
        open={massUploadModalOpen}
        onClose={handleModalClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...Modelstyle,
            width: { xs: "100%", sm: "540px" },
            padding: "20px",
            paddingTop: "40px",
            borderRadius: "0.625rem",
            background: "#141515",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            position: "relative",
          }}
        >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box
            sx={{
              textAlign: "right",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              width: "100%",
              position: "absolute",
              top: "15px",
              right: "5%",
            }}
          >
            <IconButton
              onClick={handleModalClose}
              aria-label="settings"
              sx={{
                color: "#fff",
                //   color: theme.palette.primary.dark,
                backgroundColor: "none",
                fontSize: "2rem",
                padding: "0 !important",
                margin: "0 !important",
                marginTop: "7px !important",
                svg: {
                  color: "#fff",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {/* Audio Cover Field Start*/}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="40px"
            style={{ padding: "0px 15px" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <div
                style={{
                  width: "80px",
                  height: "80px",
                  background: "transparent",
                }}
                onClick={handleDivClick}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleCoverImageChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <img
                  src={coverImage || "/images/no_image.png"}
                  alt="Uploaded"
                  style={{ maxWidth: "100%" }}
                />
              </div>

              <div style={{ marginLeft: "10px" }}>
                <div style={{ color: "#FFFFFF", fontSize: "20px" }}>
                  <EditableText
                    text={coverTitle || "Untitled"}
                    onEdit={setCoverTitle}
                    style={styles.edited_title}
                  />
                </div>
                <div style={{ fontSize: "12px", color: "#AFAFAF" }}>
                  <EditableText
                    text={coverDescription || "Add a note"}
                    onEdit={setCoverDescription}
                    style={styles.edited_desc}
                  />
                </div>
              </div>
            </Box>
          </Box>

          <Box className="ContentBox">
            <div className={styles.scanning_item_container}>
              {audioFiles.map((file, index) => (
                <div className={styles.scanning_item} key={index}>
                  <img
                    src="/images/music_icon.png"
                    alt="upload"
                    className={styles.scanning_audio_icon}
                  ></img>
                  <div style={{ width: '25%' }}>
                    <div className={styles.scanning_file_name}>{truncateString(file.name)}</div>
                    <div className={styles.scanning_file_size}>{formatBytes(file.size)}</div>
                  </div>
                  {/* <div className={styles.progress_bar}></div> */}
                  <div style={{ width: '55%' }}>
                    <LinearProgress variant="determinate" color="success" value={100} className={styles.progress_bar} />
                  </div>
                  <img className={styles.delete_icon} src={TrashRed} onClick={() => handleDelete(index)} />
                </div>
              )
              )}
            </div>

            <div className={styles.drag_image}>
              <UploadField
                onDrop={onDrop}
                title="Upload Files"
                desc="or Paste the Dropbox link for Mass Upload"
              />

            </div>
            {errorMessage && (
              <div style={{ color: "red" }}>{errorMessage}</div>
            )}
            <div className={styles.dropbox_link}>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <input
                  className={universalStyles.search_box_input}
                  type="text"
                  placeholder={"Paste Dropbox link here"}
                  onChange={handleLinkInputChange}
                />
                <div
                  className={styles.link_button}
                  onClick={extractDropBoxLink}
                >
                  Next
                </div>
              </div>
            </div>
            <div className={styles.link_button_container}>
              <BlueButtonComponents
                text="Next"
                fontsize="14px"
                background="#414241"
                color="#AFAFAF"
                onClickFunction={handleNextButton}
                padding="8px 50px"
              />
            </div>
          </Box>


        </ModelContentBox>
      </Modal>

      <MassEditModal
        massEditModalOpen={massEditModalOpen}
        setMassEditModal={setMassEditModalOpen}
        audioFiles={audioFiles}
        setAudioFiles={setAudioFiles}
        data={dataList}
        setDataList={setDataList}
        editMore={false}
      />
    </>
  );
};

export default MassUploadModal;
