import React, { memo } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { ModelContentBox, Modelstyle } from "../styles/style";
import { close_icon } from "../../../../assets/smartlink";
import BPMSlider from "../../AdditionalComponents/utils/BPMSlider";
import ToggleExpand from "../ToggleExpand";

import { KEY, SCALE } from "../../../../shared/constants";
import { BPMRangeSlider } from "../../AdditionalComponents/utils/BPMRangeSlider";
import modalStyles from "../Libraries.module.css"
import LibLineDivider from "../LibLineDivider";
import useLibraryStore from "../../../../store/library";

const KeysModal = ({
  open = false,
  setOpen,
}) => {
  const { range, setRange,  fileKey, setFileKey, scale, setScale } = useLibraryStore();

  const closeKeyBpmModal = (event) => {
    setOpen(!open);
  };

  const handleModalContentClick = (event) => {
    event.stopPropagation();
  };


  return (
    <>
      <Modal
        open={open}
        onClose={closeKeyBpmModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"

      >
        <ModelContentBox
          className="MainInboxCreate"
          sx={{
            ...Modelstyle,
            width: "600px",
            display: "flex",
            padding: "24px",
            height: "auto",
            flexDirection: "column",
            borderRadius: "0.625rem",
            background: "#141515",
            overflowY: 'hidden'
          }}
          onClick={handleModalContentClick}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "30px",
              padding: "24px",
            }}
            onClick={handleModalContentClick}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: "24px",
                  color: "white",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Key & BPM
              </div>
              <Box
                sx={{
                  height: "40px",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <IconButton
                  onClick={closeKeyBpmModal}
                  aria-label="settings"
                  sx={{
                    color: "#fff",
                    //   color: theme.palette.primary.dark,
                    backgroundColor: "none",
                    fontSize: "2rem",
                    padding: "0 !important",
                    margin: "0 !important",
                    marginTop: "-7px !important",
                  }}
                >
                  <img src={close_icon} alt="cancel-icon" />
                </IconButton>
              </Box>
            </div>
            <div>
              <div className={modalStyles.slider_style_16}>BPM</div>
              <BPMRangeSlider range={range} setRange={setRange} />
            </div>

            <LibLineDivider />

            <div>
              <div className={modalStyles.slider_style_16}>Key</div>
              <div style={{ borderBottom: "1px solid #AFAFAF" }}>
                <ToggleExpand
                  primary={fileKey}
                  menu={KEY}
                  setValue={setFileKey}
                />
              </div>
            </div>
            <div>
              <div className={modalStyles.slider_style_16}>Scale</div>
              <div style={{ borderBottom: "1px solid #AFAFAF" }}>
                <ToggleExpand
                  primary={scale}
                  menu={SCALE}
                  setValue={setScale}
                />
              </div>
            </div>
          </div>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(KeysModal);
