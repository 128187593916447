import React, { useState, memo, useCallback, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { get, wrap } from "lodash";

import { Box, IconButton, Modal } from "@mui/material";
import { ModelContentBox, Modelstyle } from "../styles/style";
import { CheckIcon, close_icon, Type, UncheckIcon, DeleteRedIcon, EditSmallIcon } from "../../../../assets/smartlink";

import useAppState from "../../../../context/useAppState";

import universalStyles from "../../../UniversalComponents/universal.module.css";
import styles from "../Libraries.module.css";
import audioStyles from "../../../../../src/pages/LinkSharedPage/linkshared.module.css"
import PackImage from "../../../../../src/assets/Images/pack.png";
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';


import LibLineDivider from "../LibLineDivider";
import BlueButtonComponents from "../../AdditionalComponents/utils/BlueButtonComponent";
import TagsModal from "./TagsModal";
import KeysModal from "./KeysModal";
import EditableText from "../EditableText";
import NoItemComponents from "../../AdditionalComponents/utils/NoItemComponent";
import EditPackModal from "./EditPackModal";
import useAuth from "../../../../context/AuthContext";
import { toast } from "react-toastify";
import { Edit } from "@material-ui/icons";

import { submitBeatPack, feedAudioFilesByQuery } from "../../../../context/apis/audio-library";
import CircularLoaderPage from "../../../UniversalComponents/CircularLoader/CircularLoaderPage.js";

import useLibraryStore from "../../../../store/library.js";
import TypeModal from "./TypeModal.js";
import { Notification } from "../../../NotifyMsg/Notification.js";
// import AudioPlayer from "../../../../pages/LinkSharedPage/audioPlayer/AudioPlayer.jsx";
import AudioPlayer from "../../../../components/audioPlayer/AudioPlayer.jsx"

import tracks from "../../../../components/audioPlayer/track.js";
import MassUploadModal from "./MassUploadModal.js";

const SelectFileModal = ({
  open = false,
  setOpen = () => { },
}) => {


  const [libraryData, setLibraryData] = useState([]);

  const [feedsLoading, setFeedsLoading] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const { uploadFile, uploadAudio } = useAppState("file");

  const [isPackChecked, setIsPackChecked] = useState(true);
  const [disabledDownload, setDisableDownload] = useState(true);
  const [notifyMe, setNotifyMe] = useState(true);
  const [allowLyrics, setAllowLyrics] = useState(true);

  const [tagOpen, setTagOpen] = useState(false);
  const [keyOpen, setKeyOpen] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);

  const [beatKey, setBeatKey] = useState("");
  const [beatBpm, setBeatBpm] = useState("");
  const [beatScale, setBeatScale] = useState("");
  const [beatRange, setBeatRange] = useState([20, 80]);

  const beatPackCoverInputRef = useRef(null);

  const [coverTitle, setCoverTitle] = useState("");
  const [coverDescription, setCoverDescription] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [file, setFile] = useState({});

  const [selectedList, setSelectedList] = useState([]);

  const [isSelected, setIsSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [currentIndex, setCurrentIndex] = useState();
  const { user } = useAuth();
  const { selectedTags, range, fileKey, scale, mode, query, sortBy, allLibraryData, setAllLibraryData, currentSubBarInde, setSelectedTags, setRange, setFileKey, setQuery, setScale, setMode, newAdded, setNewAdded, usedAudioStorage } = useLibraryStore();

  const [isPlaying, setIsPlaying] = useState(false);
  const [massUploadModalOpen, setMassUploadModalOpen] = useState(false);



  useEffect(() => {
    setFeedsLoading(true);
    const queryString = new URLSearchParams({
      userId: user._id,
      tags: JSON.stringify(selectedTags),
      minBpm: range[0],
      maxBpm: range[1],
      fileKey: fileKey,
      scale: scale,
      mode: mode,
      fileName: query,
      sortOrder: sortBy.title,
    }).toString();

    console.log("QUERY = ", queryString);

    feedAudioFilesByQuery(queryString)
      .then((response) => {
        if (response?.data) {
          const newRows = response.data || [];
          setLibraryData(newRows);
        }
      })
      .finally(() => {
        setFeedsLoading(false);
      });
  }, [openEdit, open, range, fileKey, scale, mode, query, sortBy, selectedTags, newAdded]);


  const handleDisabledDownloadChanged = () => {
    setDisableDownload(!disabledDownload);
  };

  const handlenotifyMe = () => {
    setNotifyMe(!notifyMe);
  };


  const handleDivClick = () => {
    beatPackCoverInputRef.current.click(); // Programmatically click the hidden file input
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0]; // Assuming only one file is selected
    setFile(file);

    const imageUrl = URL.createObjectURL(file);
    setCoverImage(imageUrl);
  };

  const handleAddClick = (library) => {
    setSelectedList(prev => [...prev, library]);
    const tempData = libraryData.filter((objA) => objA._id != library._id);
    setLibraryData(tempData);
  }

  const handleRemoveClick = (library) => {
    console.log("item", library);
    var updatedArray = [...libraryData, library]
    setLibraryData(updatedArray);
    console.log(" ", libraryData);
    const tempData = selectedList.filter((objA) => objA._id != library._id);
    setSelectedList(tempData);
  }

  const handleEdit = (index, item) => {
    setEditData(item);
    setOpenEdit(true);
    setCurrentIndex(index);
  }

  const handleUpdateSelectedList = (updatedItem) => {
    setSelectedList((currentSelectedList) =>
      currentSelectedList.map((item) =>
        item._id === updatedItem._id ? updatedItem : item
      )
    );
  };


  const resetData = () => {
    setFile(null);
    setCoverTitle()
    setCoverImage("");
    setCoverDescription("");
    setSelectedList([]);

    setSelectedTags([]);
    setRange([0, 200]);
    setFileKey("");
    setQuery("");
    setScale("");
    setMode("");

    setDisableDownload(false);
    setNotifyMe(false);
  }

  const handleUpload = async () => {
    const fieldIds = [];
    if (selectedList.length <= 0) {
      toast.warning("You should select one at least");
      return false;
    } else {
      selectedList.forEach(item => {
        // Modify this line to match schema expectations
        fieldIds.push({ fileId: item._id });
      });

      const packData = {
        basicInfo: {
          userID: user._id,
          packName: coverTitle,
          packNote: coverDescription,
          imageURL: coverImage,
          fileIds: fieldIds,
          enableDownload: disabledDownload,
          notifyAccessed: notifyMe,
        }
      }
      const formData = new FormData();
      formData.append("data", JSON.stringify(packData));
      formData.append("user_id", user._id);
      formData.append("imageFile", file);
      console.log("PACK DATA = ", packData);

      try {
        submitBeatPack(formData).then((response) => {
          setNewAdded();
          Notification("success", "Added Create Pack Successfully");
          closeModal();
        })
      } catch (error) {
        Notification("error", "Internal Server Error")
      }
    }
  }

  const handleClickAudioCard = (library) => {
    console.log("LibraryData = ", library);
    setIsPlaying(false);
    setSelectedItem(library);
    setIsSelected(true);
  }

  const {
    setValue,
    getValues,
    setError,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      file: null,
    },
    // resolver: yupResolver(SyncSubmissionSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onDropAudio = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0] || null;

    const payload = new FormData();

    if (file) {
      setIsUploading(true);
      payload.append("file", file);
      payload.append("type", "Public");
      try {
        const { fileLocation, audioWaveFileLocation } =
          await uploadAudio(payload);

        if (fileLocation) {
          setValue(
            "file",
            {
              name: file.name,
              url: fileLocation,
              waveUrl: audioWaveFileLocation,
            },
            { shouldValidate: true },
          );
          setIsUploading(false);
        }
      } catch (error) {
        console.info("error=>", error);
        setError("file", {
          type: "custom",
          message: get(error, "response.data.message", "Invalid file upload"),
        });
      } finally {
        setIsUploading(false);
        setIsUploading(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropAudio,
  });

  const closeModal = async (event) => {
    resetData();
    setOpen(!open);
  };



  const handleTagStatusChange = (tags) => {
    setSelectedTags(tags);
  };

  const handleModeStatusChange = (mode) => {
    setMode(mode);
  };

  const handleMassUploadModal = () => {
    if(usedAudioStorage > 2) {
      Notification("error", "You have reached your storage limit!");
      return;
    }
    setMassUploadModalOpen(!massUploadModalOpen);
  };



  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="MainInboxCreate"
          sx={{
            ...Modelstyle,
            width: "1000px",
            display: "flex",
            padding: "24px",
            height: "auto",
            flexDirection: "column",
            // borderRadius: "0.625rem",
            background: "transparent",
            overflow: "hidden"
          }}
        >
          <div style={{ display: "flex", gap: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <div
                style={{
                  height: "700px",
                  display: "flex",
                  width: "500px",
                  flexDirection: "column",
                  gap: "16px",
                  background: "#141515",
                  padding: "24px",
                  borderRadius: "4px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{ fontSize: "24px", color: "#AFAFAF", width: "100%" }}
                  >
                    Select Files
                  </div>
                </div>
                <div
                  className={universalStyles.search_box_in_lib}
                  style={{ background: "#414241" }}
                >
                  <img
                    className={universalStyles.search_icon}
                    src="/v2Assets/connect_assets/Search.png"
                    alt="search"
                  ></img>
                  <input
                    className={universalStyles.search_box_input}
                    type="text"
                    placeholder={"Search Files"}
                    onChange={(e) => { setQuery(e.target.value) }}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "end", gap: "2px" }}
                >
                  <div className={`${styles.buttonGroup} ${styles.buttonsWrapper}`}>
                    <div
                      className={`${styles.beat_filter_button} ${tagOpen && styles.beat_filter_button_active}`}
                      onClick={() => setTagOpen(true)}
                    >
                      <img
                        alt="tag"
                        src="/v2Assets/TagIcons/Tags.png"
                        className={styles.opportunity_large_tags_icon}
                      />
                      Tags
                    </div>
                    <div
                      className={`${styles.beat_filter_button} ${keyOpen && styles.beat_filter_button_active}`}
                      onClick={() => setKeyOpen(true)}
                    >
                      <img
                        alt="key"
                        src="/v2Assets/TagIcons/Key.png"
                        className={styles.opportunity_large_tags_icon}
                      />
                      Key & BPM
                    </div>
                    <div
                      className={`${styles.beat_filter_button} ${typeOpen && styles.beat_filter_button_active}`}
                      onClick={() => setTypeOpen(true)}
                    >
                      <img
                        alt="key"
                        src={Type}
                        className={styles.opportunity_large_tags_icon}
                      />
                      Type
                    </div>
                  </div>
                </div>

                <LibLineDivider />

                <div className={styles.add_file_container}>
                  {!feedsLoading ? (libraryData?.length > 0 ?
                    (libraryData.map((library, index) => {

                      const cover_image =
                        library?.basicInfo?.imageURL ?
                          (`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${library?.basicInfo?.imageURL}`) :
                          "/images/no_image.png";
                      return (
                        <div
                          key={`library-item-${index}`}
                          className={styles.library_item_container}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex", gap: "12px", cursor: "pointer" }} onClick={() => handleClickAudioCard(library)}>
                              <div className={styles.library_item_image_wrap}>
                                <img src={cover_image} width={56} height={56} style={{ borderRadius: '5px' }} />
                              </div>

                              <div className={styles.library_item_content_wrap}>
                                <div style={{ color: "white", fontSize: "20px" }}>
                                  {library.basicInfo.fileName}
                                </div>
                                <div style={{ color: "#AFAFAF", fontSize: "12px" }}>
                                  {library.basicInfo.fileNote}
                                </div>
                              </div>
                            </div>
                            <div className={styles.plus_button} onClick={() => handleAddClick(library)}>+</div>
                          </div>
                          <div className={styles.library_item_label_wrap} >
                            {library.basicInfo.tags.map((tag, index) => (
                              <div key={`audio_item_${index}`} className={styles.library_item_tag} >
                                {tag.label}
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    })
                    ) : <NoItemComponents src="/images/no_image.png" text="No Audio Library" />) : <div>
                    <CircularLoaderPage />
                  </div>}
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <BlueButtonComponents
                    text="Upload"
                    fontsize="16px"
                    onClickFunction={handleMassUploadModal} />
                </div>
              </div>
              {isSelected &&
                <Box className={styles.audio_part_wrapper}>
                  <Box className={audioStyles.audio_card_part} >
                    {isPlaying ?
                      <PauseCircleFilledOutlinedIcon
                        sx={{ fontSize: "14px", color: "white" }}
                        className={styles.audio_player_button}
                        onClick={() => setIsPlaying(false)}
                      /> :
                      <PlayCircleFilledOutlinedIcon
                        sx={{ fontSize: "14px", color: "white" }}
                        className={styles.audio_player_button}
                        onClick={() => setIsPlaying(true)}
                      />}
                    <img
                      src={selectedItem?.basicInfo?.imageURL ?
                        (`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${selectedItem?.basicInfo?.imageURL}`) :
                        "/images/no_image.png"}
                      alt="user_avatar"
                      className={styles.audio_user_avatar} />
                    <div className={styles.mobile_name_part}>
                      <div className={audioStyles.pack_owner}>{selectedItem?.basicInfo?.fileName}</div>
                      <Box display={"flex"} gap={1}>
                        <div className={audioStyles.audio_sub_title}>{selectedItem?.basicInfo?.fileNote}</div>
                      </Box>
                    </div>
                  </Box>
                  <AudioPlayer audioSrc={`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${selectedItem?.basicInfo?.fileURL}`} tracks={tracks} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
                </Box>
              }
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  background: "#141515",
                  padding: "24px",
                  borderRadius: "4px",
                  flex: 1,
                  overflow: "auto",
                }}
              >
                {/* Cancel Icon */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <Box sx={{ height: "40px", display: "flex" }}>
                    <IconButton
                      onClick={closeModal}
                      aria-label="settings"
                      sx={{
                        color: "#fff",
                        //   color: theme.palette.primary.dark,
                        backgroundColor: "none",
                        fontSize: "2rem",
                        padding: "0 !important",
                        margin: "0 !important",
                        marginTop: "-7px !important",
                      }}
                    >
                      <img src={close_icon} alt="cancel-icon" />
                    </IconButton>
                  </Box>
                </div>
                {/* Image Cover Field Start*/}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="0px"
                  marginBottom="20px"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        background: "transparent",
                      }}
                      onClick={handleDivClick}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleCoverImageChange}
                        style={{ display: "none" }}
                        ref={beatPackCoverInputRef}
                      />
                      <img
                        src={coverImage || "/images/no_image.png"}
                        alt="Uploaded"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>

                    <div style={{ marginLeft: "10px" }}>
                      <div style={{ color: "#FFFFFF", fontSize: "20px" }}>
                        <EditableText
                          text={coverTitle || "Untitled"}
                          onEdit={setCoverTitle}
                          style={styles.edited_title}
                        />
                      </div>
                      <div style={{ fontSize: "12px", color: "#AFAFAF" }}>
                        <EditableText
                          text={coverDescription || "Add a note"}
                          onEdit={setCoverDescription}
                          style={styles.edited_desc}
                        />
                      </div>
                    </div>
                  </Box>
                </Box>
                {/* Image Cover Field End*/}
                <div className={styles.library_items_container}>
                  {selectedList.map((item, index) => {
                    const cover_image =
                      item?.basicInfo?.imageURL ?
                        (`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${item?.basicInfo?.imageURL}`) :
                        "/images/no_image.png";
                    return (
                      <div
                        key={`selected_list_${index}`}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "space-between",
                          marginBottom: "12px"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div className={styles.library_item_image_wrap}>
                            <img src={cover_image} alt="library_item" width={56} height={56} />
                          </div>

                          <div style={{ display: "flex", flexDirection: "column", marginRight: "15px" }}>
                            <div style={{ fontSize: "20px", color: "white" }}>
                              {item.basicInfo.fileName}
                            </div>
                            <div style={{ display: "flex", gap: "4px" }}>
                              {item.basicInfo.tags.map((tag, i) => (
                                <div key={`select_tag_${i}`} className={styles.library_item_tag}>
                                  {tag.label}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <img src={DeleteRedIcon} alt="delete" onClick={() => handleRemoveClick(item)} />
                          <img src={EditSmallIcon} alt="edit" onClick={() => handleEdit(index, item)} />
                        </div>
                      </div>
                    )
                  })}
                </div>


              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "32px",
                  background: "#141515",
                  padding: "24px",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <img src={disabledDownload ? CheckIcon : UncheckIcon} onClick={handleDisabledDownloadChanged} />
                    <div style={{ fontSize: "16px", color: "#afafaf" }}>
                      Disable Downloads
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <img src={notifyMe ? CheckIcon : UncheckIcon} onClick={handlenotifyMe} />

                    <div style={{ fontSize: "16px", color: "#afafaf" }}>
                      Notify Me When Accessed
                    </div>
                  </div>

                </div>
                <div
                  style={{
                    alignSelf: "end",
                    display: "flex",
                    gap: "20px",
                    justifyContent: "right",
                    marginTop: "18px",
                  }}
                >
                  <BlueButtonComponents
                    text="Copy Link"
                    color="#fff"
                    bordercolor="#fff"
                    background="transparent"
                    fontsize="16px"
                  />
                  <BlueButtonComponents text="Upload" fontsize="16px" onClickFunction={handleUpload} />
                </div>
              </div>
            </div>
          </div>
          <TagsModal
            open={tagOpen}
            setOpen={setTagOpen}
            tags={selectedTags}
            onTagStatusChange={handleTagStatusChange}
          />


          <KeysModal
            open={keyOpen}
            setOpen={setKeyOpen}
            bpm={beatBpm}
            key={beatKey}
            scale={beatScale}
            range={beatRange}

            setScale={setBeatScale}
            setBpm={setBeatBpm}
            setKey={setBeatKey}
            setRange={setBeatRange}
            setTypeOpen
          />

          {/* <TypeModal
              open={typeOpen}
              setOpen={setTypeOpen}
              tags={mode}
              onTagStatusChange={handleModeStatusChange}
            /> */}

          <EditPackModal
            open={openEdit}
            setOpen={setOpenEdit}
            editData={editData}
            setEditData={setEditData}
            onUpdateSelectedList={handleUpdateSelectedList}
            index={currentIndex}
          />

          <MassUploadModal
            massUploadModalOpen={massUploadModalOpen}
            setMassUploadModalOpen={setMassUploadModalOpen}
          />
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(SelectFileModal);
