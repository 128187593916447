import React, { useState, useRef , useEffect } from "react";
import Select from "react-select";
import { SelectMain } from "./style/ReactSelectStyle";
import { Box } from "@mui/material";

const Option = ({ innerProps, label }) => (
  <>
    <Box
      {...innerProps}
      sx={{
        padding: "8px",
        borderBottom: "1px solid #414241",
        "&:last-child": { borderBottom: "none" },
        ":hover": { background: "#414241", borderRadius: "5px" },
      }}
    >
      {label}
    </Box>
  </>
);

const ReactSelect = ({
  label = "",
  title = "",
  icon: IconComponent,
  options,
  menuPosition = {},
  OnChange = () => {},
  placeholder,
  isSearch = true,
  defaultvalue,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultvalue);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [isIconClicked, setIsIconClicked] = useState(false);
  const iconContentRef = useRef(null);

  const customStyles = {
    control: (provided, state, base) => ({
      ...provided,
      ...base,
      height: "50px",
      borderRadius: "5px",
      border: state.isFocused ? "1px solid #414241" : "1px solid #414241",
      background: "#1C1B1B",
      color: "#fff",
      boxShadow: "none",
      "&:hover": { borderColor: "#414241" },
    }),

    menuList: (base) => ({
      ...base,
      padding: "10px",
      textTransform: "capitalize",
      "&::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#2A2B2B",
        borderRadius: "100px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "100px",
        background: "#414241",
        width: "25px",
        height: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#AFAFAF",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menuPortalTarget: document.body,
    menu: (provided) => ({
      ...provided,
      width: "250px",
      maxHeight: "180px",
      left: menuPosition.left || "auto",
      right: menuPosition.right || 0,
      top: menuPosition.top || "auto",
      bottom: menuPosition.bottom || "100%",
      background: "#141515",
      borderRadius: "5px",
      color: "#AFAFAF",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      padding: "10px  10px",
      overflowY: "hidden",
      marginBottom: "20px",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#AFAFAF",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#fff" : "transparent",
      color: state.isFocused ? "#000" : "#AFAFAF",
    }),
  };

  const handleMenuOpen = (state) => {
    setIsMenuOpen(state.menuIsOpen);
  };

  const HandlerOnChcnge = (value) => {
    OnChange(value.value);
    setSelectedOption(value.value);
  };

  // =============== Getting Default Vaue ==================

  useEffect(() => {
    if (defaultvalue) {
      const defaultOption = options.find(
        (option) => option.value == defaultvalue
      );
      setSelectedOption(defaultOption || null);
    }
  }, [defaultvalue, options]);


  
  const handleIconClick = () => {
    setIsIconClicked(!isIconClicked);
  };

  const handleIconHover = () => {
    setIsIconClicked(!isIconClicked);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        iconContentRef.current &&
        !iconContentRef.current.contains(event.target)
      ) {
        setIsIconClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SelectMain>
      <Box className="label-box">
        <h5>{label}</h5>
        <Box className="icon-main" sx={{ position: "relative" }}>
          {IconComponent && (
            <IconComponent
              className="escalimation"
              onClick={handleIconClick}
              onMouseEnter={() => handleIconHover()}
              onMouseLeave={() => handleIconHover()}
            />
          )}
          {isIconClicked && (
            <Box className="icon-content" ref={iconContentRef}>
              {title}
            </Box>
          )}
        </Box>
      </Box>
      <Select
        required
        className="select-box"
        defaultValue={selectedOption}
        onChange={HandlerOnChcnge}
        onMenuOpen={() => handleMenuOpen({ menuIsOpen: true })}
        onMenuClose={() => handleMenuOpen({ menuIsOpen: false })}
        options={options}
        styles={customStyles}
        placeholder={placeholder}
        components={{
          Option,
          IndicatorSeparator: () => null,
        }}
        value={selectedOption}
        menuIsOpen={isMenuOpen}
        ref={(ref) => {
          menuRef.current = ref ? ref.menuListRef : null;
        }}
        isSearchable={isSearch}
      />
    </SelectMain>
  );
};

export default ReactSelect;
