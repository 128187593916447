import React from "react";
import { ToastContainer } from "react-toastify";
import { setContext } from "@apollo/client/link/context";
import "react-toastify/dist/ReactToastify.css";
import { HttpLink } from "@apollo/client";
import ReactDOM from "react-dom";
import "./index.css";

import { SpotifyProvider } from "./context/SpotifyContext";

import OpportunityProvider from "./context/OpportunityContext";

import Routes from "./routes";
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ApolloProvider,
} from "@apollo/client";
import { AuthProvider } from "./context/AuthContext";
import containers from "./context/state";
import AppStateProvider from "./context/AppStateProvider";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

const token = localStorage.getItem("token");
const accessToken = localStorage.getItem("accounts:accessToken");

const auth = new HttpLink({
  uri: `${process.env.REACT_APP_AUTH_URL}/auth`,
  //	credentials: 'include',
  // other link options...
});
const admin = new HttpLink({
  uri: `${process.env.REACT_APP_ADMIN_URL}/adminapis`,
  headers: {
    Authorization: token,
  },
  //	credentials: 'include',
  // other link options...
});

const user = new HttpLink({
  // uri: `https://api-united.demotrt.com/user`,
  uri: `${process.env.REACT_APP_USER_URL}/user`,
  headers: {
    Authorization: token,
  },
  //	credentials: 'include',
});
const soundpack = new HttpLink({
  uri: `${process.env.REACT_APP_SOUNDPACK_URL}/soundpack`,
  headers: {
    Authorization: token,
  },
  //	credentials: 'include',
});

const opportunities = new HttpLink({
  uri: `${
    process.env.REACT_APP_STAGE === "DEV"
      ? `http://localhost:8005`
      : process.env.REACT_APP_API_URL
  }/opportunities`, // THIS IS NOT WORKING
  uri: `${
    process.env.REACT_APP_STAGE === "DEV"
      ? `http://localhost:8005`
      : process.env.REACT_APP_API_URL
  }/opportunities`, // THIS IS NOT WORKING

  headers: {
    Authorization: token,
  },
  //	credentials: 'include',
});

// Create a new ApolloLink for beats

const beats = new HttpLink({
  uri: `${process.env.REACT_APP_BEAT_URL}/beat`,
  headers: {
    Authorization: token,
  },
});

///

const smartlink = new HttpLink({
  uri: `${process.env.REACT_APP_SMART_LINK_URL}/smartlink`,
  headers: {
    Authorization: token,
  },
});

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === "user",
    user,
    ApolloLink.split(
      (operation) => operation.getContext().clientName === "beats",
      beats,
      ApolloLink.split(
        (operation) => operation.getContext().clientName === "smartlink",
        smartlink,
        ApolloLink.split(
          (operation) => operation.getContext().clientName === "admin",
          admin,
          ApolloLink.split(
            (operation) => operation.getContext().clientName === "soundpack",
            soundpack,
            ApolloLink.split(
              (operation) =>
                operation.getContext().clientName === "opportunities",
              opportunities,
              auth
            )
          )
        )
      )
    )
  ),
  cache: new InMemoryCache(),
  // other options
});

ReactDOM.render(
  <AppStateProvider containers={containers}>
    <ApolloProvider client={client}>
      {/* <ToastContainer /> */}
      <AuthProvider>
        <SpotifyProvider>
          <Routes />
        </SpotifyProvider>
      </AuthProvider>
    </ApolloProvider>
  </AppStateProvider>,
  document.getElementById("root")
);
