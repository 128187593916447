import React, { useEffect, useRef, useState } from "react";
import { InputMain } from "./style/MembershipInputStyle";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const MembershipInput = ({
  label = "",
  icon: IconComponent,
  field,
  title = "",
  adornIcon = "",
  type = "text",
  value = "",
  OnChange = () => {},
  placeholder = "",
  ...props
}) => {
  const [isValid, setIsValid] = useState(true);
  const [isIconClicked, setIsIconClicked] = useState(false);
  const iconContentRef = useRef(null);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    OnChange(e.target.value);

    const isInputValid = inputValue.trim() !== "";
    setIsValid(isInputValid);
  };

  const handleIconClick = () => {
    setIsIconClicked(!isIconClicked);
  };

  const handleIconHover = () => {
    setIsIconClicked(!isIconClicked);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        iconContentRef.current &&
        !iconContentRef.current.contains(event.target)
      ) {
        setIsIconClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <InputMain>
        <Box className="content">
          <h5>{label}</h5>
          <Box className="icon-main" sx={{ position: "relative" }}>
            {IconComponent && (
              <IconComponent
                className="escalimation"
                onClick={handleIconClick}
                onMouseEnter={() => handleIconHover()}
                onMouseLeave={() => handleIconHover()}
              />
            )}
            {isIconClicked && (
              <Box className="icon-content" ref={iconContentRef}>
                {title}
              </Box>
            )}
          </Box>
        </Box>
        <input
          required
          {...field}
          {...props}
          type={type}
          onChange={handleInputChange}
          value={value}
          placeholder={placeholder}
        />
      </InputMain>
    </>
  );
};

export default MembershipInput;
