import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

const SelectMain = styled(Box)(({ theme }) => ({
  minWidth: 130,
  "& .select-box": {
    height: "40px",
    border: "1px solid #AFAFAF",
    color: theme.palette.secondary.dark,
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontWeight: 500,
    "& svg": {
      fill: theme.palette.secondary.dark,
    },
  },
}));

export { SelectMain };
