import {
  fetchAllChats,
  fetchSingleChat,
  markChatAsRead,
  sendMessage,
  sendSubmissionMessage,
  sendSubmissionConnection,
} from "../apis/chat";

const useChat = () => {
  const loadAllChats = async (userId) => {
    try {
      const chats = await fetchAllChats(userId);
      return chats;
    } catch (error) {
      console.log("Error while loading all chats = ", error);
      throw error;
    }
  };

  const loadSingleChat = async (chatId, userId) => {
    try {
      const chat = await fetchSingleChat(chatId, userId);
      return chat;
    } catch (error) {
      console.log("Error while loading single chat = ", error);
      throw error;
    }
  };

  const markReadChat = async (chatId, userId) => {
    try {
      const response = await markChatAsRead(chatId, userId);
      return response;
    } catch (error) {
      console.log("Error while marking chat as read = ", error);
      throw error;
    }
  };

  const sendChatMessage = async (chatId, content, sender) => {
    try {
      const response = await sendMessage(chatId, content, sender);
      return response;
    } catch (error) {
      console.log("Error while sending chat_message = ", error);
      throw error;
    }
  };

  const sendChatSubmissionMessage = async (chatId, submissionId, sender) => {
    try {
      const response = await sendSubmissionMessage(
        chatId,
        submissionId,
        sender
      );
      return response;
    } catch (error) {
      console.log("Error while sending chat_message = ", error);
      throw error;
    }
  };

  return {
    loadAllChats,
    markReadChat,
    sendChatMessage,
    loadSingleChat,
    sendChatSubmissionMessage,
  };
};

export default useChat;
