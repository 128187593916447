import React, { useState, useEffect, useRef } from "react";

import styles from "./universal.module.css";
import CircularLoader from "./CircularLoader/CircularLoader";
//CircularLoader

export default function PageSidebar({
  tabs,
  activeTab,
  setActiveTab,
  refreshButton,
  handleDataRefresh,
  refreshLoading,
}) {
  return (
    <div className={styles.sidebar}>
      {tabs.map((tab) => (
        <div
          key={tab}
          className={`${styles.tab} ${activeTab === tab && styles.active}`}
          onClick={() => setActiveTab(tab)}
        >
          <p className={styles.tab_text}> {tab}</p>
        </div>
      ))}
      <div className={styles.flex_grow}></div>
      {refreshButton && activeTab === "Analytics" && (
        <div className={styles.refresh_button_box}>
          {refreshLoading ? (
            <div
              className={styles.refresh_button_loading}
              onClick={handleDataRefresh}
            >
              <CircularLoader />
            </div>
          ) : (
            <div className={styles.refresh_button} onClick={handleDataRefresh}>
              Refresh Data
            </div>
          )}
        </div>
      )}
    </div>
  );
}
