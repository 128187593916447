import React, { useEffect, createContext, useContext } from "react";
import { Box, Button, HStack } from "native-base";
import { useParams } from "react-router-dom";
import Calendly from "../../Calendly/Calendly";
import Referal from "./Referal";
import Sidebar from "./Sidebar";
import LoginForm from "./SignUpForm";
import { AuthContext } from "../Layout";
import "../../Authentication/Form.css";
import ReviewApplication from "./ReviewApplication";

const SignUp = ({ flag }) => {
  const { step } = useParams();
  const {
    activeStep,
    setActiveStep,
    accountType,
    setAccountType,
    rolesID,
    isPassword,
    setIsPassword,
    setshowCalender,
    Email,
    instaMail,
    setInstaMail,
    Password,
    isEmail,
    setIsEmail,
    setPassword,
    NewRoleId,
    isSimpleSignup,
    userId,
    errMsg,
    setErrMsg,
    phoneNum,
    showCalender,
    othersSocial,
    setothersSocial,
    othersGenre,
    setothersGenre,
    showOtherHear,
  } = useContext(AuthContext);
  useEffect(() => {
    console.log(
      "active step in sign up",
      activeStep,
      othersSocial,
      accountType
    );
  }, [activeStep]);
  useEffect(() => {
    if (localStorage.getItem("accountType")) {
      setAccountType(localStorage.getItem("accountType"));
    }
  }, []);
  return (
    <Box minH={"100vh"}>
      {showCalender && (
        <Button
          opacity={0.6}
          position={"absolute"}
          backgroundColor="#000"
          zIndex={222}
          top={"0"}
          left="0"
          right="0"
          bottom="0"
          onPress={() => setshowCalender(false)}
        ></Button>
      )}
      {/* {showmodal && !isSaved && (
          <ModalPopup flag={true} text={"are you part of label?"} />
        )}
        {showmodal && isSaved && (
          <ModalPopup text={"What label are you a part of??"} flag={false} />
        )} */}
      {showCalender && <Calendly />}
      <HStack minH={"100vh"}>
        <div className="sidebarSignup">
          <Sidebar />
        </div>
        <div style={{ width: "55%" }} className="containerSignup">
          {activeStep === 0 && (
            <LoginForm
              title="choose your account type!"
              subtitle="I’m a/an"
              label=""
              btnText="Next"
              type="account type"
            />
          )}
          {activeStep === 1 && (
            <LoginForm
              title="Get Registered On United Market"
              subtitle="Enter Your Email Address "
              label="Email Address:"
              placeholder="Enter Email"
              btnText="next"
              type={"email"}
            />
          )}
          {activeStep === 2 && (
            <LoginForm
              title="Get Registered On United Market"
              subtitle="Enter Your Phone Number"
              label="Phone Number:"
              btnText="next"
              type={"phoneNumber"}
            />
          )}
          {activeStep === 3 && (
            <LoginForm
              smallText={`Please provide the OTP code sent to your ${
                isEmail ? "email" : "phone number"
              }`}
              title="Please Verify Your Account"
              subtitle="Enter Your Verification Code"
              label="Code:"
              btnText="Submit Code"
              type="otp"
              placeholder="Enter OTP"
            />
          )}
          {activeStep === 4 && (
            <LoginForm
              title="Create Your Password"
              subtitle="Enter Your Password"
              label="Password:"
              label2="Confirm Password:"
              btnText="next"
              type={"password"}
              type2={"confirmPassword"}
              placeholder="Enter Password"
              placeholder2="Enter Confirm Password"
            />
          )}
          {activeStep === 5 && (
            <LoginForm
              title="Add Your Instagram"
              subtitle="Enter your Instagram Link"
              label="Instagram:"
              btnText="next"
              type="email_username"
              placeholder="@username"
            />
          )}
          {activeStep === 6 &&
            !othersGenre &&
            (accountType === "Label" ||
            accountType === "A & R" ||
            accountType === "Manager" ? (
              <LoginForm
                title="Are You Part Of A Label?"
                subtitle=""
                btnText="Next"
                showLabel={true}
              />
            ) : (
              <LoginForm
                title="What Is Your Musical Genre?"
                subtitle=""
                btnText="submit"
              />
            ))}
          {othersGenre && activeStep === 6 && (
            <LoginForm
              title="What Is Your Musical Genre?"
              subtitle=""
              btnText="finish"
              label="others"
              type="others"
            />
          )}
          {activeStep === 7 &&
            (accountType === "Label" ||
              accountType === "A & R" ||
              accountType === "Manager") && (
              <LoginForm
                title="add your profile pic"
                subtitle=""
                label="Preferred Username:"
                btnText="Next"
                type="username"
              />
            )}
          {activeStep === 7 && accountType === "Artist" && (
            <LoginForm
              title="Information"
              subtitle="Number of Monthly listeners and link to Spotify Profile "
              label="Number of Monthly Listeners:"
              btnText="Next"
              type="monthlyListerner"
              placeholder="Enter Number of Monthly Listeners"
              label1="Spotify Profile Link:"
              type1={"spotifyLink"}
              placeholder1="Enter Spotify Profile Link"
            />
          )}
          {activeStep === 8 &&
            (accountType === "Label" ||
              accountType === "A & R" ||
              accountType === "Manager") && (
              <LoginForm
                title="Enter your name"
                subtitle=""
                btnText="Next"
                label="First Name:"
                type={"firstName"}
                placeholder="Enter First Name"
                label1="Last Name:"
                type1={"lastName"}
                placeholder1="Enter Last Name"
              />
            )}

          {((activeStep === 7 && accountType === "Music Producer") ||
            (activeStep === 8 && accountType === "Artist")) && (
            <LoginForm
              title="add your profile info"
              subtitle=""
              label="Preferred Username:"
              btnText="Next"
              type="username"
              label1="First Name:"
              type1={"firstName"}
              placeholder1="Enter First Name"
              label2="Last Name:"
              type2={"lastName"}
              placeholder2="Enter Last Name"
              placeholder="Enter Username"
            />
          )}
          {activeStep === 8 && accountType === "Music Producer" && (
            <LoginForm
              // title='Choose Your Membership'
              // subtitle='You will not be charged unless you're accepted.'
              //subtitle={`Subscribe to Early Member Pricing (${accountType})`}
              btnText="Add Card Info"
              type=""
            />
          )}
          {((activeStep === 9 && accountType === "Music Producer") ||
            (activeStep === 9 &&
              !showOtherHear &&
              accountType !== "Music Producer")) && (
            <LoginForm
              title="How did you hear about us?"
              subtitle=""
              btnText="submit"
            />
          )}
          {(!othersGenre && showOtherHear && activeStep === 10) ||
            (othersGenre && showOtherHear && activeStep === 11) ||
            (showOtherHear && activeStep === 9 && (
              <LoginForm
                title="How did you hear about us?"
                subtitle=""
                btnText="finish"
                label="others"
                type="others"
              />
            ))}
          {(accountType === "A & R" ||
            accountType === "Label" ||
            accountType === "Manager" ||
            accountType === "Artist") &&
          activeStep === 10 ? (
            <ReviewApplication />
          ) : (
            ""
          )}

          {((!othersGenre && !showOtherHear && activeStep === 10) ||
            (showOtherHear && !othersGenre && activeStep === 11) ||
            (!showOtherHear && othersGenre && activeStep === 11) ||
            (showOtherHear && othersGenre && activeStep === 12)) &&
          accountType === "Music Producer" ? (
            <Referal title="Review" />
          ) : (
            ""
          )}
        </div>
        {/* {!others && activeStep === 10 && <Referal title="Review" />} */}
        {/* {activeStep >= 8 && (
          <LoginForm
            title="Review"
            subtitle="Your application is complete. Our team will review it ...and reach out via email"
            label=""
            btnText="Schedule a Demo"
            imgUrl="clock.svg"
            type=""
          />
        )} */}
      </HStack>
    </Box>
  );
};
export default SignUp;
