import React, { useState, useRef, useEffect } from "react";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Modal,
  makeStyles,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import styles from "../mycontracts.module.css";

import TrashIcon from "../../../assets/trash.png";
import EditIcon from "../../../assets/edit-2.png";
import AddIcon from "../../../assets/add-square.png";
import MaskGroupIcon from "../../../assets/Mask-group.png";
import PdfIcon from "../../../assets/pdfIcon.png";
import LogoIcon from "../../../assets/Logo/pay-logo.png";
import CloseIcon from "../../../assets/close.png";
import MicoLogo from "../../../assets/mic-logo.png";
import TwoCircle from "../../../assets/two-circle.png";
import FailedIcon from "../../../assets/FailedIcon.png";
import SuccessIcon from "../../../assets/trash-success.png";
import { Notification } from "../../NotifyMsg/Notification";
import { useStyles, CARD_OPTIONS } from "../../../shared/constants";

function SendContracts() {
  const classes = useStyles();
  const [picture, setPicture] = useState("");
  const [showRoleTypes, setShowRoleTypes] = useState([false]);
  const [roleTypes, setRoleTypes] = useState(["Select ..."]);
  const [roleFirstNames, setRoleFirstNames] = useState([""]);
  const [roleLastNames, setRoleLastNames] = useState([""]);
  const [roleEmails, setRoleEmails] = useState([""]);
  const [uploadFile, setUploadFile] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [documentMessage, setDocumentMessage] = useState("");
  const [titleRemain, setTitleRemain] = useState(52);
  const [messageRemain, setMessageRemain] = useState(10000);
  const [isFreemium, setIsFreemium] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [showPayDialog, setShowPayDialog] = useState(false);
  const [isCardShow, setIsCardShow] = useState(false);
  const [isConnect, setIsConnnect] = useState(false);

  const [showFailedModal, setShowFailedModal] = useState(false);
  const [showContractSentModal, setShowContractSentModal] = useState(false);

  const [cardName, setCardName] = useState("*******");
  const [cardError, setCardError] = useState("");

  const router = useNavigate();

  const envelopRef = useRef(null);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(async () => {
    try {
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_CONTRACT_URL}/templates/get-info`,
        headers: {
          accesstoken: localStorage.getItem("token"),
        },
      };
      const response = await axios(config);
      setIsFreemium(response.data.isFreemium);
    } catch (err) {
      Notification("error", err.message);
    }
  }, []);

  const verifyImageFile = (files) => {
    if (!files || files.length === 0) {
      Notification("error", "No file uploaded.");
      return false; // Provide feedback for no file uploaded
    }
    const currentFile = files[0];
    console.log(currentFile);
    const currentFileName = currentFile.name;

    if (!currentFileName.match(/.(pdf|docx|doc)$/i)) {
      Notification("error", "We only support PDF, DOCX, and DOC files.");
      return false;
    }
    return true;
  };

  const addRecipients = () => {
    let showRoleTypeStream = [...showRoleTypes];
    showRoleTypeStream.push(false);
    setShowRoleTypes(showRoleTypeStream);

    let roleTypeStream = [...roleTypes];
    roleTypeStream.push("Select ...");
    setRoleTypes(roleTypeStream);

    let roleFirstNameStream = [...roleFirstNames];
    roleFirstNameStream.push("");
    setRoleFirstNames(roleFirstNameStream);

    let roleLastNameStream = [...roleLastNames];
    roleLastNameStream.push("");
    setRoleLastNames(roleLastNameStream);

    let roleEmailStream = [...roleEmails];
    roleEmailStream.push("");
    setRoleEmails(roleEmailStream);
  };

  const deleteRecipients = (id) => {
    setShowRoleTypes(showRoleTypes.filter((item, index) => index !== id));
    setRoleTypes(roleTypes.filter((item, index) => index !== id));
    setRoleFirstNames(roleFirstNames.filter((item, index) => index !== id));
    setRoleLastNames(roleLastNames.filter((item, index) => index !== id));
    setRoleEmails(roleEmails.filter((item, index) => index !== id));
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleOnDropLogoImage = async (files, rejectedFiles) => {
    if (!verifyImageFile(files)) return;
    if (files && files[0]) {
      // await uploadTemplateDoc(files[0])
      setUploadFile(files[0]);
      let reader = new FileReader();
      reader.onloadend = function () {
        setPicture({
          filename: files[0].name,
          filetype: files[0].type,
          fileimage: reader.result,
          datetime: files[0].lastModifiedDate.toLocaleString("en-IN"),
          filesize: filesizes(files[0].size),
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };
  const isValide = async () => {
    if (documentTitle === "") {
      Notification("error", "Enter the Document Title");
      return;
    }
    if (uploadFile === "") {
      Notification("error", "Select the file");
      return;
    }
    for (let i = 0; i < roleFirstNames.length; i++) {
      if (roleFirstNames[i] === "") {
        Notification("error", "Enter the usernames");
        return;
      }
      if (roleEmails[i] === "") {
        Notification("error", "Enter the emails");
        return;
      }
    }
    let isPay = true;
    if (!isFreemium) {
      try {
        const config = {
          method: "get",
          url: `${process.env.REACT_APP_CONTRACT_URL}/templates/get-user-contract-counts`,
          headers: {
            accesstoken: localStorage.getItem("token"),
          },
        };
        const response = await axios(config);
        isPay = response.data >= 3;
      } catch (err) {
        Notification("error", err.message);
        return;
      }
    }

    if (isPay) {
      setShowPayDialog(true);
    } else {
      createTemplate();
    }
  };
  const createTemplate = async () => {
    var data = new FormData();
    console.log(uploadFile);
    data.append("file", uploadFile);
    data.append("roleFirstNames", roleFirstNames);
    data.append("roleLastNames", roleLastNames);
    data.append("roleEmails", roleEmails);
    data.append("type", "Public");
    data.append("owner", "Ilarion");
    data.append("documentTitle", documentTitle);
    data.append("documentMessage", documentMessage);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_CONTRACT_URL}/templates/send`,
      data,
      headers: {
        accesstoken: localStorage.getItem("token"),
      },
    };
    await axios(config)
      .then(function (response) {
        console.log(response.data);
        window.location.assign(response.data.sendUrl);
      })
      .catch(function (error) {
        setIsCardShow(false);
        Notification("error", error.response.data.message);
      });
    setIsLoading(false)
  };

  const handleRoleFirstName = (e, id) => {
    const name = e.target.value;
    let roleFirstNameStream = [...roleFirstNames];
    roleFirstNameStream[id] = name;
    setRoleFirstNames(roleFirstNameStream);
  };
  const handleRoleLastName = (e, id) => {
    const name = e.target.value;
    let roleLastNameStream = [...roleLastNames];
    roleLastNameStream[id] = name;
    setRoleLastNames(roleLastNameStream);
  };
  const handleRoleEmail = (e, id) => {
    const email = e.target.value;
    let roleEmailStream = [...roleEmails];
    roleEmailStream[id] = email;
    setRoleEmails(roleEmailStream);
  };

  const addCardSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    const { token, error } = await stripe.createToken(cardNumberElement);

    if (!error) {
      try {
        const token = localStorage.getItem("token");
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_CONTRACT_URL}/create-payment-intent`,
          data: {
            amount: isFreemium ? 575 : 275,
          },
          headers: {
            accesstoken: token,
          },
        };
        const response = await axios(config);
        console.log(response.data);
        const confirmPayment = await stripe.confirmCardPayment(
          response.data.clientSecret,
          { payment_method: { card: cardNumberElement } }
        );

        if (confirmPayment.paymentIntent) {
          createTemplate();
          return;
        } else if (confirmPayment.error) {
          setShowFailedModal(true);
        }
      } catch (error) {
        Notification("error", `${error.message}`);
      } finally {
      }
    } else {
      console.log(error.code);
      setCardError(error.code);
    }
    setIsLoading(false);

    setIsConnnect(true);
  };

  return (
    <div>
      <div className={styles.template_container}>
        <div className={styles.fileUpload_layout}>
          <Dropzone
            onDrop={handleOnDropLogoImage}
            onFileDialogCancel={() => setPicture("")}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles.imageDropzone}>
                {picture ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {picture.filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                      <div className={styles.profile_file_image}>
                        <img src={picture.fileimage} alt="" />
                      </div>
                    ) : (
                      <div className={styles.license_file_image}>
                        <img src={PdfIcon} alt="" />
                      </div>
                    )}
                    <div className={styles.profile_file_detail}>
                      <h6>{picture.filename}</h6>
                      <p>
                        <span>Size : {picture.filesize}</span>{" "}
                        <span>Modified Time : {picture.datetime}</span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <img
                      className={styles.profile_pic_upload_image}
                      src={MaskGroupIcon}
                      alt="DefaultProfile"
                    />
                    <p className={styles.auth_title}>
                      Drag & Drop a file to upload
                    </p>
                  </>
                )}

                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </div>

        <div className={styles.middle_title_layout}>
          <div className={styles.middle_title_font}>
            <p className={styles.middle_title_recip}>Recipients</p>
            <button className={styles.add_recip_button} onClick={addRecipients}>
              <img src={AddIcon} alt="" />
              Add more recipients
            </button>
          </div>

          {showRoleTypes.map((item, index) => (
            <div key={index} className={styles.recipient_group}>
              <div className={styles.recipient_rectangle} />
              <div className={styles.recipient_form_group}>
                <div className={styles.user_name_group}>
                  <label className={styles.user_name_label}>
                    User Name {index + 1}
                  </label>
                  <button
                    className={styles.user_name_delete}
                    onClick={() => deleteRecipients(index)}
                  >
                    Delete{" "}
                    <img
                      src={TrashIcon}
                      style={{ width: 20, height: 20 }}
                      alt=""
                    />
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 24,
                      width: "50%",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        First Name
                      </label>
                      <input
                        type="text"
                        className={styles.recipient_user_name}
                        value={roleFirstNames[index]}
                        onChange={(e) => handleRoleFirstName(e, index)}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        Last Name
                      </label>
                      <input
                        type="text"
                        className={styles.recipient_user_name}
                        value={roleLastNames[index]}
                        onChange={(e) => handleRoleLastName(e, index)}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        Email
                      </label>
                      <input
                        type="email"
                        className={styles.recipient_user_name}
                        value={roleEmails[index]}
                        onChange={(e) => handleRoleEmail(e, index)}
                      />
                    </div>

                    {/* <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        Audio File
                      </label>
                      <div className={styles.recipient_user_name}>
                        <img src={AudioFileUploadIcon} alt="" />
                        <input type="file" style={{ visibility: "hidden" }} />
                      </div>
                    </div> */}
                  </div>
                  <img src={EditIcon} alt="" />
                </div>
              </div>
            </div>
          ))}
          <Modal
            open={showPayDialog}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%",
              }}
              className={classes.modalpaper}
            >
              <div className={classes.modalimgcontainer}>
                <img src={LogoIcon} style={{ width: 100, height: 100 }} />
                <div>
                  <div>
                    <p className={classes.modaltext2}>
                      You need to pay{" "}
                      <span className={classes.priceText}>
                        {isFreemium ? "$5.75" : "$2.75"}
                      </span>{" "}
                      to send a contract.
                    </p>
                  </div>
                  <div className={classes.modalbuttonContainer}>
                    <button
                      className={classes.modalCancelbutton}
                      onClick={() => setShowPayDialog(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className={classes.modalDeletebutton}
                      onClick={() => {
                        setIsCardShow(true);
                        setShowPayDialog(false);
                      }}
                    >
                      Pay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            open={showFailedModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%",
              }}
              className={classes.modalpaper}
            >
              <img
                className={classes.modalcloseimg}
                src={CloseIcon}
                onClick={() => setShowFailedModal(false)}
              />
              <div className={classes.modalimgcontainer}>
                <img src={FailedIcon} style={{ width: 100, height: 100 }} />
                <p className={classes.modaltest1}>Payment Failed</p>

                <div>
                  <div>
                    <p className={classes.modaltext2}>
                      Something went wrong we couldn’t process your payment.
                    </p>
                  </div>
                  <div className={classes.modalonebuttonContainer}>
                    <button
                      className={classes.modalDeletebutton}
                      onClick={() => {
                        setIsCardShow(true);
                        setShowPayDialog(false);
                      }}
                    >
                      Try Again
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            open={showContractSentModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%",
              }}
              className={classes.modalpaper}
            >
              <img
                className={classes.modalcloseimg}
                src={CloseIcon}
                onClick={() => {
                  setShowContractSentModal(false);
                  router("/my-contracts/sent");
                }}
                alt=""
              />
              <div className={classes.modalimgcontainer}>
                <img
                  src={SuccessIcon}
                  style={{ width: 100, height: 100 }}
                  alt=""
                />
                <p className={classes.modaltest1}>Contract Sent</p>

                <div>
                  <div>
                    <p className={classes.modaltext2}>
                      Congratulation your contract has been successfully sent.
                    </p>
                  </div>
                  <div className={classes.modalonebuttonContainer}>
                    <button
                      className={classes.modalDeletebutton}
                      onClick={() => {
                        setShowContractSentModal(false);
                        router("/my-contracts/sent");
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            open={isCardShow}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%",
              }}
              className={classes.addcardmodalpaper}
            >
              <img
                className={classes.modalcloseimg}
                src={CloseIcon}
                onClick={() => setIsCardShow(false)}
              />
              <div className={classes.addcardmodalimgcontainer}>
                <p className={classes.modaltitle}>Pay Now</p>
                <div className={classes.addedcardContainer}>
                  <img src={MicoLogo} className={classes.micImg} />
                  <p className={classes.cardnumber}>**** **** **** ****</p>
                  <p className={classes.cardname}>{cardName}</p>
                  <div className={classes.overlayCard}>
                    <p className={classes.expdate}>
                      Exp{" "}
                      <span style={{ fontSize: 12, fontWeight: 600 }}>
                        {"**/**"}
                      </span>
                    </p>
                    <img src={TwoCircle} className={classes.circleImage} />
                  </div>
                </div>
              </div>

              <form onSubmit={addCardSubmit}>
                <div className={classes.carddetailcontainer}>
                  <div className={classes.carddetail}>
                    <p className={classes.carddetailtext}>Card Detail</p>
                  </div>
                  <div className={classes.carditemcontainer}>
                    <div className={classes.cardinputgroup}>
                      <p className={classes.inputtag}>Name on Card</p>
                      <input
                        className={classes.inputelement}
                        name="card-name"
                        onChange={(e) => setCardName(e.target.value)}
                      />
                    </div>
                    <div className={classes.cardinputgroup}>
                      <p className={classes.inputtag}>Card Number</p>
                      <CardNumberElement
                        options={CARD_OPTIONS}
                        className={classes.inputelement}
                        onChange={() => setCardError("")}
                      />
                      {cardError.indexOf("number") !== -1 && (
                        <p style={{ margin: 0, color: "red" }}>
                          Invalid Card Number
                        </p>
                      )}
                    </div>
                    <div className={classes.cardinputgroup}>
                      <p className={classes.inputtag}>Expiration Date</p>

                      <CardExpiryElement
                        options={CARD_OPTIONS}
                        className={classes.inputelement}
                        onChange={() => setCardError("")}
                      />
                      {cardError.indexOf("expiry") !== -1 && (
                        <p style={{ margin: 0, color: "red" }}>
                          Invalid Expiration Date
                        </p>
                      )}
                    </div>
                    <div className={classes.cardinputgroup}>
                      <p className={classes.inputtag}>CVV</p>
                      <CardCvcElement
                        options={CARD_OPTIONS}
                        className={classes.inputelement}
                        onChange={() => setCardError("")}
                      />
                      {cardError.indexOf("cvc") !== -1 && (
                        <p style={{ margin: 0, color: "red" }}>Invalid CVC</p>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={classes.futureContainer}>
                    <Checkbox style={{ color: "#AFAFAF", padding: 0 }} />
                    <p style={{ color: "#AFAFAF", fontSize: 16, margin: 0 }}>
                      Use this card for future purchases
                    </p>
                  </div>
                  <button
                    className={classes.continueButton}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress style={{ color: "white" }} />
                    ) : (
                      "Continue"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        </div>

        <div className={styles.middle_title_layout}>
          <div className={styles.middle_title_font}>
            <p style={{ fontSize: 30, fontWeight: 500, margin: 0 }}>Document</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 24,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 24,
                width: "100%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={styles.recipient_group_user_label}>
                  Title
                </label>
                <input
                  type="text"
                  className={styles.recipient_user_name}
                  value={documentTitle}
                  onChange={(e) => {
                    const lenth = e.target.value.length;
                    if (lenth > 52) return;
                    setTitleRemain(52 - lenth);
                    setDocumentTitle(e.target.value);
                  }}
                />
                <label
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    fontWeight: 400,
                    height: 18,
                    color: "#E3E3E3",
                  }}
                >
                  Character remaining:{titleRemain}
                </label>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={styles.recipient_group_user_label}>
                  Message
                </label>
                <textarea
                  type="text"
                  className={styles.email_message}
                  rows={5}
                  value={documentMessage}
                  onChange={(e) => {
                    const lenth = e.target.value.length;
                    if (lenth > 10000) return;
                    setMessageRemain(10000 - lenth);
                    setDocumentMessage(e.target.value);
                  }}
                />
                <label
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    fontWeight: 400,
                    height: 18,
                    color: "#E3E3E3",
                  }}
                >
                  Character remaining:{messageRemain}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sidebar_bottom_button_container}>
        <button
          className={styles.sidebar_bottom_button}
          onClick={() => router("/my-contracts")}
        >
          Prev
        </button>
        <button
          className={styles.sidebar_bottom_button}
          onClick={async () => {
            isValide();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}
export default SendContracts;
