import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Wrapper = styled(Box)(({ theme }) => ({
  paddingTop: "80px",
  background: "#141515",
  "& .FundCareerContent": {
    textAlign: "center",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    "& .item": {
      display: "flex",
      height: "80px",
      padding: "0px 51px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "10px",
      background: "#FFF",
      width: "fit-content",
      color: "#000",
      fontFamily: '"Poppins", sans-serif',
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "100px",
    },
  },
  "& .fundingBtn_main": {
    marginTop: "56px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    button: {
      fontFamily: '"Poppins", sans-serif',
      display: "flex",
      padding: "8px 24px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      borderRadius: "5px",
      background: "#2EBB55",
      color: "#FFF",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      textTransform: "capitalize",
      lineHeight: "normal",
    },
  },
}));

export { Wrapper };
