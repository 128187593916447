export const updateUser = `
mutation updateUserAsAdmin($email: String!, $profilePic: String!, $username:String!,$firstname:String!,$lastname:String!,$roleId:String!,$userId:String!,$isFreemium:Boolean, $phoneno:Float!, $allowCreate: Boolean, $isVerified: Boolean, $membershipType: String, $password:String,$instaUrl: String){
    updateUserAsAdmin(Updateprofilebody:{
      email: $email,
      profilePic: $profilePic,
      userName:$username
      roleId: $roleId
      isFreemium:$isFreemium,
      firstname:$firstname,
      lastname:$lastname,
      phoneno: $phoneno,
      allowCreate: $allowCreate
      isVerified: $isVerified
      membershipType: $membershipType      
      password: $password
      instaUrl:$instaUrl
    },userId:$userId){
        success
        message
        isTokenExpired
        updatedUser{
          email
          userName
          genre   
          profilePic
          coverPic
          instaUrl
        }
    }
  }
`;
export const getUser = `
query getUserByOpportunityId($id: String!){
  getUserByOpportunityId(id:$id){
    success
    message
    user{
      userName
      firstname
      lastname
      opportunityId
      profilePic   
      isFreemium
      isSoundPackAllowed
    }
  }
}
`;

export const deleteUser = `
query deleteUser($userId: String) {
  deleteUser(userId: $userId) {
    success
    message
    isTokenExpired
  }
}
`;

export const allowUserAccess = `
mutation updateUserAsAdmin ($isPaid:Boolean,$id:String!){
  updateUserAsAdmin(Updateprofilebody:{
    isPaid:$isPaid
  },userId:$id){
    success
    isTokenExpired
    message
    updatedUser{
      firstname
      lastname
      email
      userName
      credits
      accolades
      genre
      aboutMe
      beatSpecialties{
        name
      }
      loopSpecialties{
        name
      }
      experience{
        title
        description
        profileHeadline
        companyName
        startDate
        endDate
      }
      profilePic
      coverPic
      isPaid
      instaUrl
    }
  }
}
`;

export const allowUsersAccessRequest = `
mutation updateUsersAccess ($isPaid:Boolean,$isAdminReviewed:Boolean,$userIds: [String!]!){
  updateUsersAccess(Updateprofilebody:{
    isPaid:$isPaid
    isAdminReviewed:$isAdminReviewed
  },userIds: $userIds){
    success
    isTokenExpired
    message
  }
}
`;

export const verifyUsersAccessRequest = `
mutation verifyUsers ($isVerified:Boolean,$userIds: [String!]!){
  verifyUsers(Updateprofilebody:{
    isVerified:$isVerified
  },userIds: $userIds){
    success
    isTokenExpired
    message
  }
}
`;

export const changeAccountStatus = `
    mutation updateAccountStatusAsAdmin($accountStatus: String!, $userId: String!) {
        updateAccountStatusAsAdmin(accountStatus: $accountStatus, userId: $userId) {
            success
            message
            isTokenExpired
            updatedUser {
                accountStatus
                _id
                userName
            }
        }
    }
`;
export const attachFreemium = `
mutation attachFreemiumPlanAsAdmin($userId:ID){
  attachFreemiumPlanAsAdmin(userId:$userId)
  {
   message
    success
    isTokenExpired
  }
}
`;
export const updateFreemium = `mutation updateUserAsAdmin($Updateprofilebody:{
  isFreemium:Boolean
},$userId:ID){
  updateUserAsAdmin(Updateprofilebody:$Updateprofilebody,userId:$userId){
    success
    isTokenExpired
    message
    updatedUser{
      firstname
      lastname
      email
      userName
      credits
      accolades
      genre
      aboutMe
      beatSpecialties{
        name
      }
      loopSpecialties{
        name
      }
      experience{
        title
        description
        profileHeadline
        companyName
        startDate
        endDate
      }
      profilePic
      coverPic
      isPaid
      instaUrl
    }
  }
}`;
