import React from "react";
import styles from "../../auth_pages.module.css";
import { useState } from "react";
import { FormField } from "../FormInput";

const UserInformation = ({ onBack, user, onNext, completeAccount }) => {
  let l = user?.profile?.location?.split(", ");
  const [state, setState] = useState(l ? l[1] : "");
  const [city, setCity] = useState(l ? l[0] : "");
  const [country, setCountry] = useState(l ? l[2] : "");

  //   const canContinue = state !== "" && city !== "" && label !== "";
  const canContinue = state !== "" && city !== "" && country !== "";

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
        >
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}>
              <p className={styles.auth_title}>
                Enter some information about yourself
              </p>
              <p className={styles.auth_subtitle}></p>
            </div>
            <div className={styles.input_list}>
              <FormField
                title="Country"
                onValueChange={(value) => setCountry(value)}
                dropdown={false}
                content={country}
              />
              <FormField
                title="State"
                onValueChange={(value) => setState(value)}
                content={state}
                dropDownData={[
                  "AL",
                  "AK",
                  "AZ",
                  "AR",
                  "CA",
                  "CO",
                  "CT",
                  "DE",
                  "FL",
                  "GA",
                  "HI",
                  "ID",
                  "IL",
                  "IN",
                  "IA",
                  "KS",
                  "KY",
                  "LA",
                  "ME",
                  "MD",
                  "MA",
                  "MI",
                  "MN",
                  "MS",
                  "MO",
                  "MT",
                  "NE",
                  "NV",
                  "NH",
                  "NJ",
                  "NM",
                  "NY",
                  "NC",
                  "ND",
                  "OH",
                  "OK",
                  "OR",
                  "PA",
                  "RI",
                  "SC",
                  "SD",
                  "TN",
                  "TX",
                  "UT",
                  "VT",
                  "VA",
                  "WA",
                  "WV",
                  "WI",
                  "WY",
                ]}
                dropdown={true}
              />
              <FormField
                title="City"
                onValueChange={(value) => setCity(value)}
                dropdown={false}
                content={city}
              />
              {/* <FormField
                title="Label Affiliation"
                onValueChange={(value) => setLabel(value)}
                content={label}
                dropDownData={[
                  "Independent Artist",
                  "Major Label",
                  "Indie Label",
                  "Self-Released",
                  "Unsigned",
                  "Previous Label Affiliation",
                  "Not Disclosed",
                ]}
                dropdown={true}
              /> */}
            </div>
          </div>

          <div className={styles.buttonControls}>
            {canContinue ? (
              <div
                onClick={() => {
                  let updatedUser = { ...user };
                  let updatedProfile = { ...user.profile };
                  updatedProfile.location = `${city}, ${state}, ${country}`;
                  updatedUser.profile = updatedProfile;
                  onNext(updatedUser);
                }}
                className={styles.signin_box}
              >
                <p className={styles.connect_text}>Continue</p>
              </div>
            ) : (
              <div className={styles.signin_box_inactive}>
                <p className={styles.connect_text}>Continue</p>
              </div>
            )}
            <p className={styles.previous_text} onClick={onBack}>
              Previous Step
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
