import React from "react";
import styles from './Pagination.module.css';
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  if(totalPages <= 1){
    return null;
  }

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
      <div className={styles.pagination}>
      <button
        className={styles.pagination_button}
        onClick={handlePreviousClick}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span className={styles.pagination_info}>
        Page {currentPage} of {totalPages}
      </span>
      <button
        className={styles.pagination_button}
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;