import React, { memo } from "react";
import { Box, Button, Modal } from "@mui/material";
import { ModelContentBox } from "./styles/CongradulationModelStyle";
import { crown } from "../../../../../assets/smartlink";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "#141515",
  pt: 2,
  px: 4,
  pb: 3,
};

const CongradulationModel = ({
  open,
  setOpen,
  selectedPlans = "",
  commingFrom = "",
}) => {
  const CongradulationPlanModelOpen = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(!open);
  };

  const handelContinue = () => {
    setOpen(false);
    if (commingFrom === "ProducerPage") {
      window.location.href = "/beat-inbox";
    } else {
      window.location.reload();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={CongradulationPlanModelOpen}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="main"
          sx={{
            ...style,
            width: { xs: "100%", sm: "600px" },
            padding: "80px",
            borderRadius: "20px",
          }}
        >
          <Box className="img-box">
            <img src={crown} alt="crown" />
          </Box>
          {/* <h5>Congratulation on Unlocking Pro Plan</h5> */}
          {/* <h5>Congratulations On Becoming A {selectedPlans || ""} Member </h5> */}
          <h5>Congratulations On Becoming A HitMaker Member! </h5>
          <h6>
            {/* You're now a {selectedPlans || ""} member! Get ready to enjoy */}
            You're now a  HitMaker Member! Get ready to enjoy
            premium features and exclusive benefits made just for you.
          </h6>
          <Button variant="contained" className="btn" onClick={handelContinue}>
            Continue
          </Button>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(CongradulationModel);
