import React, { useState } from "react";
import { Box, Button, CssBaseline, ThemeProvider } from "@mui/material";
import { Main, CenterBoxMain } from "./styles/style";
import theme from "../../../../components/smartlink/theme";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_BEATBOX_BY_ID } from "../../../../Graphql_services/beatinbox/Beats";
import logo from "../../../../assets/Logo/logo.png";
import { ToastContainer } from "react-toastify";
import PrivateInboxPasswordModel from "./popups/PrivateInboxPasswordModel";
import spotifyicon from "../../../../assets/Icons/spotify.png";
import { colered_instragram, LockIcon } from "../../../../assets/smartlink";

const SingleSharedInbox = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setopen] = useState(false);

  const { loading, error, data } = useQuery(GET_BEATBOX_BY_ID, {
    variables: {
      id: id,
    },
    context: { clientName: "beats" },
  });
  const beatBoxes = data?.getBeatBoxById?.beatBox;
  const HandelNavigation = (id) => {
    if (!beatBoxes?.isPublic) {
      setopen(true);
    } else {
      navigate(`/shared-beat-create/${id}`);
    }
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Main>
          <CenterBoxMain>
            <Box className="logo">
              <img src={logo} alt="logo-icon" style={{ width: "8%" }} />
            </Box>

            {loading && (
              <p style={{ color: "#fff", textAlign: "center" }}>Loading....</p>
            )}
            {!loading && !beatBoxes && (
              <p style={{ color: "#fff", textAlign: "center" }}>
                No Inbox Found.
              </p>
            )}

            {!loading && beatBoxes && (
              <>
                <Box className="img-box">
                  <img
                    src={beatBoxes?.image}
                    alt="beats-img"
                    // style={{
                    //   objectFit: "cover",
                    //   borderRadius: "50%",
                    //   width: "120px",
                    //   height: "120px",
                    // }}
                  />
                </Box>
                <Box className="title-box">
                  <h5>{beatBoxes?.title}</h5>
                  <Box className="inbox_social">
                    <span>
                      {/* {beatBoxes?.userId?.firstname +
                        " " +
                        beatBoxes?.userId?.lastname} */}
                      {beatBoxes?.musicianName && beatBoxes?.musicianName}
                    </span>
                    {beatBoxes?.isPublic && (
                      <>
                        {beatBoxes?.spotifyUrl && (
                          <Link to={beatBoxes?.spotifyUrl}>
                            <img src={spotifyicon} alt="spotifyicon" />
                          </Link>
                        )}

                        {beatBoxes?.instagramUrl && (
                          <Link to={beatBoxes?.instagramUrl}>
                            <img
                              src={colered_instragram}
                              alt="instragram icon"
                            />
                          </Link>
                        )}
                      </>
                    )}
                    {!beatBoxes?.isPublic && (
                      <>
                        <img src={LockIcon} alt="spotifyicon" />
                      </>
                    )}
                  </Box>
                </Box>
                <Box className="desc">
                  <p>{beatBoxes?.description || ""}</p>
                </Box>
                <Box className="btn-box">
                  {/* <Button variant="outlined" className="btn">
                    cancel
                  </Button> */}
                  <Button
                    variant="contained"
                    className="btn green"
                    onClick={() => HandelNavigation(beatBoxes?._id)}
                  >
                    submit
                  </Button>
                </Box>
              </>
            )}
          </CenterBoxMain>
        </Main>
        <PrivateInboxPasswordModel
          open={open}
          setOpen={setopen}
          BeatInfo={beatBoxes}
        />
      </ThemeProvider>
      <ToastContainer />
    </>
  );
};

export default SingleSharedInbox;
