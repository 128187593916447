import React from "react";
import { messageremove } from "../../../assets/smartlink";
import { MainBox, InboxContentBox } from "./EmptyCreatesLinkStyle";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const EmptyCreatesLink = () => {
  const navigate = useNavigate();
  return (
    <MainBox>
      <InboxContentBox>
        <Box className="icon-box">
          <img src={messageremove} alt="message-remove" />
        </Box>
        <Typography variant="h4">Smart Links</Typography>
        <Typography variant="h5">You don't have any smart links yet</Typography>
        <Box>
          <Button
            variant="contained"
            className="btn"
            onClick={() => {
              navigate("/smart-link/createlink");
            }}
          >
            Create Link
          </Button>
        </Box>
      </InboxContentBox>
    </MainBox>
  );
};

export default EmptyCreatesLink;
