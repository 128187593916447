import React from "react";
import styles from "./loader.module.css";

function CircularLoaderPage({ size = "3.25em" }) {
  return (
    <div className={styles.loader_page}>
      <svg
        viewBox="25 25 50 50"
        className={styles.circular_loader}
        style={{ width: size }}
      >
        <circle
          r="20"
          cy="50"
          cx="50"
          className={styles.circular_loader_circle}
        ></circle>
      </svg>
    </div>
  );
}

export default CircularLoaderPage;
