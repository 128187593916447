import { object, array, string, number, bool, boolean } from "yup";

const SyncSubmissionSchema = object().shape({
  file: object()
    .shape({
      name: string("File name is required").required("File name is required"),
      url: string("File URL is required").required("File URL is required"),
    })
    .default(undefined)
    .required("File is required"),

  songName: string()
    .required("Song Name is required")
    .min(3, "Song Name must be greater than 3 characters"),

  musicianName: string()
    .required("Musician Name is required")
    .min(1, "Musician Name must be greater than 1 characters")
    .max(50, "Musician Name must be less than 50 characters"),

  isReleased: boolean().required("This answer is required"),

  isrcCode: string().when("isReleased", {
    is: true,
    then: (schema) => schema.required("Required if song is released"),
    otherwise: (schema) => schema.notRequired(),
  }),

  spotifySongLink: string().when("isReleased", {
    is: true,
    then: (schema) => schema.required("Required if song is released"),
    otherwise: (schema) => schema.notRequired(),
  }),

  // isIndependent: boolean().required("This answer is required"),

  labelName: string().when("isIndependent", {
    is: true,
    then: (schema) => schema.required("Label Name is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  canClear: string().when("isIndependent", {
    is: true,
    then: (schema) => schema.required("Answer is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  publisherName: string().required("Publisher Name is required"),

  publisherPRO: string().required("Publisher PRO is required"),

  masterOwnerName: string().when("isMasterOwner", {
    is: false,
    then: (schema) => schema.required("Label Name is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  masterOwnerEmail: string().when("isMasterOwner", {
    is: false,
    then: (schema) => schema.required("Label Name is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  agreeSyncAgreement: boolean()
    .required("This field is required")
    .oneOf([true], "You must agree to the Sync Agreement"),

  agreePolicy: boolean()
    .required("This field is required")
    .oneOf([true], "You must agree to the Policy"),
  collaboratorOnLoop: string().required("This field is required"),
  collaborators: array()
    .of(
      object().shape({
        name: string()
          .required("Collaborator name is required")
          .min(3, "Collaborator name must be greater than 3 characters")
          .max(50, "Collaborator name must be less than 50 characters"),
        email: string()
          .required("Collaborator email is required")
          .email("Invalid email"),
        publishingName: string().max(
          50,
          "Publishing name must be less than 50 characters"
        ),
        publishingPRO: string().max(
          50,
          "Publishing PRO must be less than 50 characters"
        ),
        publishingPercentage: number("Collaborator split must be number")
          .typeError("Collaborator split must be number")
          .required("Split is required")
          .max(45)
          .default(0),
      })
    )
    .when("collaboratorOnLoop", {
      is: "yes",
      then: (schema) =>
        schema
          .required("Collaborators information is required")
          .test("collaborators", (collaborators = [], context) => {
            if (collaborators.length > 0) {
              return true;
            } else {
              return context.createError({
                message: `Please add collaborators details`,
              });
            }
          }),
      // .test("mySplit", (collaborators = [], context) => {
      //   const total = collaborators.reduce((total, row) => {
      //     return total + (parseInt(row.publishingPercentage) || 0);
      //   }, 0);
      //   if (total <= 45 - context.parent.mySplit) {
      //     return true;
      //   } else {
      //     if (context.parent.mySplit) {
      //       if (45 - context.parent.mySplit <= 0) {
      //         return context.createError({
      //           message: `You do not have enough split % to share with collaborators.`,
      //         });
      //       } else {
      //         return context.createError({
      //           message: `Sum of all collaborators split must be less than or equal to ${
      //             45 - context.parent.mySplit
      //           }% of total`,
      //         });
      //       }
      //     }
      //     return context.createError({
      //       message: `Enter Add your publishing split %`,
      //     });
      //   }
      // }),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export default SyncSubmissionSchema;
