import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const InsightsTableMain = styled(Box)(({ theme }) => ({
  tbody: {
    tr: {
      borderTop: "1px solid #414241",
      borderBottom: "1px solid #414241",
    },
  },
  "& td.visits_count": {
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  "& .ProgressBar_count": {
    textAlign: "right",
  },
  "& .ProgressBar_count_bar": {
    width: "30%",
  },
}));

export { InsightsTableMain };
