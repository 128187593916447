import React, { useState } from "react";
import styles from "./Libraries.module.css";

const EditableFormikText = ({ text, style, name, setFieldValue, field }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(text);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setEditedText(e.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    setFieldValue(name, editedText);
    // onEdit(editedText); // Callback to parent component with edited text
  };

  return (
    <div onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <input
          type="text"
          value={editedText}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className={style}
          name={name}
          autoFocus
        />
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
};

export default EditableFormikText;
