import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

const ModelContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  "& .error": {
    fontFamily: theme.typography.fontFamily,
    color: "red",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  "& .error-input input": {
    border: "1px solid red",
  },
  "& .heading-box": {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& h3": {
      color: "#fff",
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.h3,
      fontStyle: "normal",
      fontWeight: 400,

      lineHeight: "normal",
    },
  },

  "& .CreateModelMead": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },

  "& .send-main-box": {
    display: "flex",
    flexDirection: "column",
    "& p": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "1.125rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      marginBottom: "5px",
    },
  },
  "& .add_collaboratore": {
    borderBottom: "none !important",
    paddingBottom: "40px !important",
  },
  "& .add_collaboratore .search_box": {
    overflow: "hidden !important",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "86% !important",
  },

  "& .search_main": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: "5px",
    borderBottom: "1px solid #414241",
    paddingBottom: "20px",

    "& .search_box": {
      borderRadius: "4px",
      background: "#414241",
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "50px",
      gap: "10px",
      padding: "10px",
      input: {
        color: "#AFAFAF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        width: "100%",
        background: "transparent",
        border: "none",
        "&:focus": {
          border: "none",
          outline: "none",
        },
      },
    },
    "& .mail_icon": {
      borderRadius: "4px",
      background: "#414241",
      width: "48px",
      height: "48px",
    },
    button: {
      borderRadius: "4px",
      background: "#414241",
      width: "50px",
      height: "50px",
      padding: "0px",
      "&.copySuccess": {
        background: "rgba(46, 187, 85, 1)",
        color: "#fff",
      },
    },
  },

  "& .user_list": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "15px",
    "& .user_icon": {
      button: { color: "#AFAFAF" },
      svg: { color: "#AFAFAF" },
    },
  },
  "& .user_info": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    img: { width: "56px", height: "56px", borderRadius: "50%" },
    h6: {
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      margin: "0px",
    },
    p: {
      fontFamily: theme.typography.fontFamily,
      color: "#AFAFAF",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      margin: "0px",
    },
  },

  "& .sendinbox_main": {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    button: {
      borderRadius: "5px",
      background: "#2EBB55",
      color: "#fff",
      fontFamily: theme.typography.fontFamily,
      padding: "10.5px 30px",
      textTransform: "capitalize",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      "&:disabled": {
        background: "gray",
      },
    },
  },
  // .......................Add Collaborator style..........................//
  "& .header": {
    width: "100%",
    display: "flex",
    gap: "64px",
    justifyContent: "flex-end",
    alignItems: "center",
    h3: {
      color: "#E3E3E3",
      fontFamily: theme.typography.fontFamily,
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "capitalize",
      margin: 0,
    },
    "& .close-icon": {
      width: "30px",
      height: "30px",
      cursor: "pointer",
    },
  },
  "& .input-box": {
    width: "100%",
    marginTop: "33px",
    marginBottom: "30px",
    input: {
      borderRadius: "5px",
      padding: "22px 17px !important",
      marginTop: "7px",
    },
  },
  "& .btn": {
    width: "100%",
    padding: "10.5px 30px",
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
    borderRadius: "5px",
    cursor: "pointer",
    background: "#2EBB55 !important",
    border: "none",
  },
}));
const SocialMain = styled(Box)(({ theme }) => ({
  textAlign: "center",
  h3: {
    color: "#E3E3E3",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
    margin: 0,
    marginBottom: "30px",
  },
  img: {
    width: "48px",
    height: "48px",
    marginBottom: "12px",
    cursor: "pointer",
  },
  h5: {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
    cursor: "pointer",
    margin: 0,
  },
}));

const HeaderInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  borderRadius: "5px",
  alignItems: "center",
  background: "#0A0B0A",
  padding: "10px",
  "& .left": {
    img: {
      width: "50px",
      height: "50px",
      borderRadius: "3px",
      objectFit: "cover",
    },
    display: "flex",
    width: "100%",
    gap: "0.56rem",
    "& .beat-info": {
      display: "flex",
      flexDirection: "column",
      gap: "0.12rem",
      "& h4": {
        color: "#fff",
        fontFamily: theme.typography.fontFamily,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginTop: "0",
      },
      "& .desc": {
        display: "flex",
        gap: "0.5rem",
        p: {
          color: "#AFAFAF",
          fontFamily: theme.typography.fontFamily,
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        },
        span: {
          color: "#AFAFAF",
          fontFamily: theme.typography.fontFamily,
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        },
      },
    },
  },
  "& .right": {
    display: "flex",

    alignItems: "center",

    "& .icon-box": {
      display: "flex",
      alignItems: "flex-start",
      gap: "0.75rem",
      button: {
        borderRadius: "3px",
        background: "#141515",
        color: "#AFAFAF",
        svg: {
          color: "#AFAFAF",
        },
      },
      "& .icon": {
        cursor: "pointer",
      },
    },
  },
}));

export { ModelContentBox, HeaderInfoBox, SocialMain };
