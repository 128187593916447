import React from "react";
import { ProducersSubmit } from "../../../components/smartlink";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "../../../components/smartlink/theme";
import { ToastContainer } from "react-toastify";

const ProducersSubmitBeatPage = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ProducersSubmit />
      </ThemeProvider>
      <ToastContainer />
    </>
  );
};

export default ProducersSubmitBeatPage;
