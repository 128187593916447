import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { mainLogo } from "../../assets/smartlink";
import mobileLogo from "../../assets/Images/favicon.png";
import { MainNavbar } from "./styles/navstyle";
import { Link, useLocation } from "react-router-dom";
import NavSidebar from "./NavSidebar";
import MenuIcon from "@mui/icons-material/Menu";

const NavList = [
  {
    list: "Home",
    path: "/",
  },
  {
    list: "Features",
    path: "#",
  },
  {
    list: "Distribution",
    path: "/distribution",
  },
  {
    list: "Blog",
    path: "https://blog.unitedmarket.com/",
  },
  {
    list: "Pricing",
    path: "#",
  },
];
const DistributionLink = [
  {
    list: "Home",
    path: "/",
  },
  {
    list: "Who We Are",
    path: "#",
  },
  {
    list: "Demo",
    path: "#",
  },
  {
    list: "Case Study",
    path: "#",
  },
  {
    list: "Blog",
    path: "https://blog.unitedmarket.com/",
  },
];

const Navbar = () => {
  const [scroll, setScroll] = useState(false);
  const [CurrentUrls, setCurrentUrls] = useState(null);
  const [show, setShow] = useState(false);

  const location = useLocation();

  const currentPath = location.pathname;
  const Token = localStorage.getItem("token");

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 80) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (currentPath === "/distribution") {
      setCurrentUrls(DistributionLink);
    } else {
      setCurrentUrls(NavList);
    }
  }, [currentPath]);
  const handelLogout = () => {
    localStorage.clear();
    window.location.href = "/signin";
  };

  return (
    <>
      {show && <NavSidebar show={show} setShow={setShow} />}
      <AppBar
        sx={{
          background: scroll ? "#141515" : "transparent",
          boxShadow: "none",
        }}
        position="fixed"
      >
        <MainNavbar>
          <Box>
            <Link to="/">
              <img src={mainLogo} alt="Logo.svg" className="logoImg" />
            </Link>
            <Box className="mobileLogoImg">
              <MenuIcon
                onClick={() => setShow(true)}
                sx={{ fontSize: "36px", cursor: "pointer" }}
              />
              <Link to="/">
                <img src={mobileLogo} alt="Logo.svg" />
              </Link>
            </Box>
          </Box>

          <Box className="NavItemBox">
            <Box className="UlList">
              <List className="MainUlLists">
                {CurrentUrls?.map((item, index) => (
                  <ListItem key={index}>
                    <Link
                      style={{
                        color: `${
                          location.pathname == item.path ? "#fff" : ""
                        }`,
                      }}
                      className="ListItem"
                      to={item.path}
                    >
                      <ListItemText
                        primary={item.list}
                        sx={{ width: "max-content" }}
                      />
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Box>

            <Box className="BtnMain">
              {!Token && (
                <>
                  <Link to={"/signin"} style={{ textDecoration: "none" }}>
                    <Button className="LoginBtn">Log in</Button>
                  </Link>
                  <Link to={"/signup"} style={{ textDecoration: "none" }}>
                    <Button
                      className="SignupBtn"
                      variant="contained"
                      size="medium"
                    >
                      Sign up
                    </Button>
                  </Link>
                </>
              )}
              {Token && (
                <Button
                  className="SignupBtn"
                  variant="contained"
                  size="medium"
                  onClick={handelLogout}
                >
                  Logout
                </Button>
              )}
            </Box>
          </Box>
        </MainNavbar>
      </AppBar>
    </>
  );
};

export default Navbar;
