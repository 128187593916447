import React, { useState, useEffect } from "react";
import { Box, Button, Heading, HStack, Text, IconButton } from "native-base";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import {
  addPaymentMethod,
  defaultPaymentMethod,
  deletePaymentMethod,
  renewSubscription,
} from "../../Graphql_services/PaymentsMutation";
import { DeletePayment } from "./Modal/DeletePayment";
import { DefualtPayment } from "./Modal/DefaultPayment";

import FlagSvg from "../../assets/cardSvg/Flag";
import VisaSvg from "../../assets/cardSvg/visa";
import PLaceholderSvg from "../../assets/cardSvg/Placeholder";
import AmericanSvg from "../../assets/cardSvg/American";
import MasterCardSvg from "../../assets/cardSvg/MasterCard";
import HiperCardSvg from "../../assets/cardSvg/HiperCard";
import UnionPaySvg from "../../assets/cardSvg/UnionPay";
import JCBSvg from "../../assets/cardSvg/JCB";
import DiscoverSvg from "../../assets/cardSvg/Discover";
import DinerClubSvg from "../../assets/cardSvg/DinerClub";
import DeleteSvg from "../../assets/cardSvg/Delete";
function CardDetail(props) {
  const {
    cardNumber,
    expireDate,
    defaultPayment,
    GetMyPaymentMethod,
    type,
    data,
    id,
    noOfCards,
  } = props;
  console.log(data, "asdasd");
  const navigate = useNavigate();

  const [deletePopup, setDeletePopup] = useState(false);
  const [defaultPopup, setDefaultPopup] = useState(false);
  

  const [AddDefaultPaymentMethod] = useMutation(defaultPaymentMethod, {
    context: { clientName: "user" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data?.setDefaultPaymentMethod?.success == true) {
        toast.success("Payment method attached as default successfully");
        GetMyPaymentMethod();
      } else if (data?.setDefaultPaymentMethod?.success == false) {
        toast.error(data?.setDefaultPaymentMethod?.message);
      } else {
        localStorage.clear();
        navigate("/signin");
      }
    },
    onError(err) {
      console.log("error on  AddDefaultPaymentMethod", err);
    },
  });

  const [DeletePaymentMethod] = useMutation(deletePaymentMethod, {
    context: { clientName: "user" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data?.deletePaymentMethod?.success == true) {
        toast.success("Card deleted successfully");
        GetMyPaymentMethod();
      } else if (data?.deletePaymentMethod?.success == false) {
        toast.error(data?.deletePaymentMethod?.message);
      } else {
        localStorage.clear();
        navigate("/signin");
      }
    },
    onError(err) {
      console.log("error on  DeletePaymentMethod", err);
    },
  });

  const [RenewSubscription] = useMutation(renewSubscription, {
    context: { clientName: "user" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data.renewSubscription?.success === true) {
        toast.success(data.renewSubscription?.message);
        localStorage.clear();
        navigate("/signin");
      } else if (data.renewSubscription?.success === false) {
        toast.error(data.renewSubscription?.message);
      } else {
        localStorage.clear();
        navigate("/signin");
      }
    },
    onError(err) {
      console.log("error on  RenewSubscription", err);
    },
  });

  return (
    <Box borderWidth={1} borderColor={"#323333"} rounded={"md"} my={3}>
      <HStack
        py={3}
        px={{ lg: 3, base: 2 }}
        w={"100%"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
      >
        <HStack
          w={"100%"}
          justifyContent={{ lg: "space-between", base: "flex-end" }}
          flexWrap={"wrap-reverse"}
        >
          <HStack w={{ lg: "70%", base: "77%" }} py={{ base: 2 }}>
            <Box w={{ lg: "10%", base: "25%" }} alignSelf={"center"} p={1}>
              {type == "visa" ? (
                <VisaSvg width={"60px"} height={"35px"} />
              ) : type == "mastercard" ? (
                <MasterCardSvg width={"60px"} height={"35px"} />
              ) : type == "amex" ? (
                <AmericanSvg width={"60px"} height={"35px"} />
              ) : type == "hiper" ? (
                <HiperCardSvg width={"60px"} height={"35px"} />
              ) : type == "unionpay" ? (
                <UnionPaySvg width={"60px"} height={"35px"} />
              ) : type == "jcb" ? (
                <JCBSvg width={"60px"} height={"35px"} />
              ) : type == "discover" ? (
                <DiscoverSvg width={"60px"} height={"35px"} />
              ) : type == "diners" ? (
                <DinerClubSvg width={"60px"} height={"35px"} />
              ) : (
                <PLaceholderSvg width={"60px"} height={"35px"} />
              )}
            </Box>
            <Box
              w={{ lg: "90%", base: "75%" }}
              alignSelf={"center"}
              px={{ lg: 3, base: 2 }}
            >
              <Heading
                fontSize={{ lg: "20px", base: "14px" }}
                fontWeight={"medium"}
                fontFamily={"Poppins"}
                color={"#fff"}
              >
                {cardNumber}
              </Heading>

              <Heading
                fontSize={{ lg: "16px", base: "10px" }}
                fontWeight={"normal"}
                fontFamily={"Poppins"}
                color={"#898989"}
              >
                {"Expire: " + expireDate}
              </Heading>
            </Box>
          </HStack>
          <HStack
            justifyContent={"space-between"}
            w={{ "2xl": "12%", lg: "20%", base: "23%" }}
            flexWrap={"wrap-reverse"}
          >
            <IconButton
              icon={
                <FlagSvg
                  color={defaultPayment ? "#2ebb55" : "#afafaf"}
                  width={"20px"}
                  height={"20px"}
                />
              }
              p={{ lg: 2, base: 1.5 }}
              rounded={"full"}
              bg={defaultPayment ? "#2ebb5520" : "#323333"}
              _hover={{ bg: defaultPayment ? "#2ebb5550" : "#32333380" }}
              _pressed={{ bg: defaultPayment ? "#2ebb5520" : "#323333" }}
              disabled={defaultPayment}
              alignSelf={"center"}
              onPress={() => {
                setDefaultPopup(true);
              }}
            />

            <IconButton
              icon={
                <DeleteSvg colorSvg={noOfCards >= 2 ? "#ff0000" : "#afafaf"} />
              }
              p={{ lg: 2, base: 1.5 }}
              rounded={"full"}
              bg={noOfCards >= 2 ? "#ff000020" : "#323333"}
              _hover={{ bg: noOfCards >= 2 ? "#ff000050" : "#32333380" }}
              _pressed={{ bg: noOfCards >= 2 ? "#ff000020" : "#323333" }}
              disabled={noOfCards >= 2 ? false : true}
              alignSelf={"center"}
              onPress={() => {
                setDeletePopup(true);
              }}
            />
            <Button
              _text={{
                fontFamily: "Poppins",
                fontWeight: "normal",
                fontSize: { xl: "14px", lg: "12px", base: "10px" },
                color: "#fff",
              }}
              borderWidth={1}
              borderColor={"#2ebb55"}
              p={0}
              py={1}
              my={{ base: 2 }}
              w={{ lg: "45%", base: "100%" }}
              alignSelf={"center"}
              rounded={"lg"}
              bg={"#2ebb55"}
              _hover={{ bg: "transparent", _text: { color: "#2ebb55" } }}
              _pressed={{ bg: "#2ebb55", _text: { color: "#fff" } }}
              onPress={() => {
                RenewSubscription({
                  variables: {
                    paymentMethodId: id,
                  },
                });
              }}
            >
              Pay Now
            </Button>
          </HStack>
        </HStack>
      </HStack>
      <DeletePayment
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        deleteFtn={() =>
          DeletePaymentMethod({
            variables: {
              paymentMethodId: id,
            },
          })
        }
      />
      <DefualtPayment
        openModal={defaultPopup}
        closeModal={() => setDefaultPopup(false)}
        defaultFtn={() =>
          AddDefaultPaymentMethod({
            variables: {
              paymentMethodId: id,
            },
          })
        }
      />
    </Box>
  );
}

export default CardDetail;
