import { AppBar, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Toolbar } from "@mui/material";

const HeaderMain = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  width: "100%",
  "& .notificationBtn:first-child": {
    margin: "0px 0px",
  },
  "& .notificationBtn": {
    borderRadius: "50%",
    background: "#414241",
    margin: "0px 15px",
    padding: "12px 13px",
  },
  "& .notificationBtn:hover": {
    background: "#414241",
  },
}));
const AppBarMain = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
}));
const ToolbarMain = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const AvatarMain = styled(Box)(({ theme }) => ({
  width: "144px",
  marginLeft: "20px",
  borderRadius: "5px",
  background: "#414241",
  padding: "7px",
  display: "flex",
  alignItems: "center",
  color: "#fff",
  gap: "10px",
  textAlign: "left",
  fontFamily: theme.typography.fontFamily,
  "& h6": {
    color: "#FFF",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    fontFamily: theme.typography.fontFamily,
    margin: "0px 0px",
  },
  "& p": {
    color: "#AFAFAF",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    fontFamily: theme.typography.fontFamily,
    margin: "0px 0px",
  },
}));

const PaymentMain = styled(Box)(({ theme }) => ({
  "&.badge_freemium":{
    minWidth: "133px",
  },
  background: "rgba(65, 66, 65, 1)",
  minWidth: "113px",
  borderRadius: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  padding: "8px 0",
  // marginRight: "10px",
  span: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "27px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "rgba(46, 187, 85, 1)",
  },

  [theme.breakpoints.between("md", "sm")]: {
    display: "none",
  },
}));

export { AvatarMain, HeaderMain, AppBarMain, ToolbarMain, PaymentMain };
