import axios from "../../config/axiosInterceptor";
import { HTTP_STATUS } from "../../shared/constants";

export const fetchUser = async (userId) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_CONNECT_ME_URL}/connect-me/fetch-user/${userId}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while fetching user information");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchConnectMeUsers = async (userID, page, limit, query) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_CONNECT_ME_URL}/connect-me/connect-me-users?page=${page}&limit=${limit}&query=${query}&userID=${userID}` // Include the userID as a query parameter
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while fetching ConnectMe users");
    }
  } catch (error) {
    throw error;
  }
};

export const sendConnectionRequest = async (senderUserId, receiverUserId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CONNECT_ME_URL}/connect-me/create-connection`,
      {
        senderUserId,
        receiverUserId,
      }
    );

    if (response.status === HTTP_STATUS.CREATED) {
      return { success: true, message: "Connection request sent successfully" };
    } else {
      return { success: false, message: response.data.message , chatId:response.data.chatId };
    }
  } catch (error) {
    console.error("Error while sending connection request:", error);
    throw error;
  }
};

export const sendSubmissionConnection = async (
  senderUserId,
  receiverUserId,
  submissionId
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CONNECT_ME_URL}/connect-me/send-submission-connection`,
      {
        senderUserId,
        receiverUserId,
        submissionId,
      }
    );

    if (response.status === HTTP_STATUS.CREATED) {
      return { success: true, message: "Connection request sent successfully" };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (error) {
    console.error("Error while sending connection request:", error);
    throw error;
  }
};

export const recindConnectionRequest = async (senderUserId, receiverUserId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CONNECT_ME_URL}/connect-me/recind-user-connection`,
      {
        senderUserId,
        receiverUserId,
      }
    );

    if (response.status === 200) {
      return {
        success: true,
        message: "Connection request recinded successfully",
      };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (error) {
    console.error("Error while recinding connection request:", error);
    throw error;
  }
};

export const acceptConnectionRequest = async (senderUserId, receiverUserId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CONNECT_ME_URL}/connect-me/accept-connection-request`,
      {
        senderUserId,
        receiverUserId,
      }
    );

    if (response.status === 200) {
      return {
        success: true,
        message: "Connection request accepted successfully",
      };
    } else {
      return { success: false, message: response.data.message };
    }
  } catch (error) {
    console.error("Error while accepting connection request:", error);
    throw error;
  }
};

export const fetchUserConnectionRequests = async (userId, search = "") => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_CONNECT_ME_URL}/connect-me/user-connection-requests/${userId}?q=${search}`
    );

    if (status === HTTP_STATUS.OK) {
      return data;
    } else {
      throw new Error("Error while fetching user connections");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUserNetwork = async (userId, search = "") => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_CONNECT_ME_URL}/connect-me/user-network/${userId}?q=${search}`
    );

    if (status === HTTP_STATUS.OK) {
      return data;
    } else {
      throw new Error("Error while fetching user connections");
    }
  } catch (error) {
    throw error;
  }
};
