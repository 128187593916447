import React, { memo, useRef, useState } from "react";
import { Main, DeleteBox } from "./style/style";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { Notification } from "../../../NotifyMsg/Notification";
import CircularProgress from "@mui/material/CircularProgress";

const ImageUploader = ({ setuploadimageurl = () => {}, img = "" }) => {
  const [image, setImage] = useState(img);
  const [IsLoadingProfile, setIsLoadingProfile] = useState(false);
  const fileInputRef = useRef(null);

  const validateFile = (file) => {
    const allowedFileTypes = ["image/png", "image/jpeg", "image/gif"];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      Notification(
        "error",
        "Invalid file type. Please upload a .png, .jpg, or .gif file."
      );
      return false;
    }

    if (file.size > maxSize) {
      Notification(
        "error",
        "File size exceeds 10MB. Please upload a smaller file."
      );
      return false;
    }

    return true;
  };

  const handleImageUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.type === "application/zip") {
      Notification("error", "Zip files are not allowed.");
      return;
    }
    if (selectedFile) {
      if (!validateFile(selectedFile)) {
        return;
      }
      setImage(URL.createObjectURL(selectedFile));
    }
    const profileData = new FormData();
    setIsLoadingProfile(true);
    try {
      profileData.append("file", selectedFile);
      profileData.append("type", "Public");

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_FILE_URL}/file/uploadprofilepicture`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: profileData,
      };
      const response = await axios(config);
      if (response.status === 200) {
        setuploadimageurl(response?.data?.fileLocation);
        Notification("success", "Image uploaded successfully!");
      }
    } catch (error) {
      Notification("error", error.message);
    } finally {
      setIsLoadingProfile(false);
    }
  };

  const handleDeleteImage = () => {
    setImage(null);
    setuploadimageurl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <Main onClick={handleImageUpload}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        {!IsLoadingProfile && image ? (
          <img
            src={image}
            alt="Selected"
            className="uploaded-image"
            style={{
              objectFit: "cover",
              maxWidth: "100%",
              height: "100%",
            }}
          />
        ) : IsLoadingProfile ? (
          <CircularProgress color="secondary" />
        ) : (
          <AddIcon className="upload-icon" />
        )}
      </Main>
      {/* {!IsLoadingProfile && image && (
        <DeleteBox onClick={handleDeleteImage}>
          <span>Delete image</span>
        </DeleteBox>
      )} */}
    </>
  );
};

export default memo(ImageUploader);
