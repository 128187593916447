import React, { useState } from "react";
import BeatAddModal from "../../components-old/BeatAddModal/BeatAddModal";
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Popover,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from "@mui/material";
import styles from "./selectbeatinfo.module.css";
import { get, last, size } from "lodash";
import allImages from "../../assets";
import { getTimeRemainingText, handleDownload } from "../../shared/funs";
import useAppState from "../../context/useAppState";
import WavePlayer from "../../components-old/WavePlayer";
import useAuth from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import DarkTooltip from "../OpportunitiesComponents/AdditionalComponents/Tooltip/DarkTooltip";

function SelectBeatInfoData({ data, isShowOpportunityName = false }) {
  const [showInfo, setShowInfo] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkBox, setCheckBox] = useState({
    AR: true,
    Producer: false,
    Pubsplit: false,
    purchaseBeat: true,
    arAgreement: true,
  });

  const [error, setError] = useState({
    purchaseBeat: "",
    arAgreement: "",
  });
  const { user } = useAuth();
  const navigate = useNavigate();

  const { updateBeatStatus } = useAppState("submission");
  const {
    collaborators,
    isMasterSample,
    _id,
    file,
    exclusivePrice,
    opportunity,
    isSelected,
  } = data;

  const { title, coverPhoto, bpm, key, scale, expireDate } = opportunity || {};

  console.info("opp :: ", data);

  const allAccepted =
    collaborators &&
    collaborators.every((element) => element.status === "Accepted");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSelectButton = async () => {
    const payload = { isSelected: true };

    try {
      // updateBeatStatus(_id, payload);
      setShowInfo(false);
      navigate(
        `/checkout-summary?q=${btoa(exclusivePrice)}&i=${btoa(
          opportunity._id
        )}&n=${btoa(_id)}`
      );
    } catch (error) {
      console.info("erroer ::", error);
    }
  };

  const open = Boolean(anchorEl);
  const fileName = decodeURIComponent(last(get(file, "url", "").split("/")));

  console.info("checkBox => ", checkBox);

  return (
    <div>
      <button
        onClick={() => setShowInfo(true)}
        className={isSelected ? styles.info_button_cancel : styles.info_button}
      >
        {isSelected ? "Beat Selected" : "Select this beat"}
      </button>
      {showInfo && (
        <BeatAddModal
          title="Select This beat"
          handleClose={() => setShowInfo(false)}
        >
          <div className={styles.select_beat_wrapper}>
            <div className={styles.submission_info}>
              <img
                className={styles.submission_image}
                src={coverPhoto?.url}
                alt="submission"
              />
              <div className={styles.text}>
                {/* <div className={styles.title}>lookingFor:{lookingFor}</div> */}
                {isShowOpportunityName && (
                  <div className={styles.title}>{title}</div>
                )}
                <Tooltip title={fileName}>
                  <div
                    className={
                      isShowOpportunityName ? styles.subtitle : styles.title
                    }
                  >
                    {fileName}
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className={styles.bottom_info}>
              {file?.url && (
                <div className={styles.waveform}>
                  <WavePlayer
                    key={_id}
                    url={file?.url}
                    file={file}
                    isSelected={true}
                  />
                </div>
              )}
              {file?.url && (
                <div className={styles.download_button_wrapper}>
                  <div
                    className={styles.download_button}
                    onClick={() => handleDownload(file.url, file.name)}
                  >
                    Download
                  </div>
                </div>
              )}
              <div className={styles.time_remaining}>
                <img
                  src={allImages.clock}
                  alt="clock"
                  className={styles.time_icon}
                ></img>
                <p className={styles.time_remaining_text}>
                  {getTimeRemainingText(data?.createdAt, data?.pitchingPeriod)}
                </p>
                {size(collaborators) === 0 ? (
                  ""
                ) : (
                  <div>
                    {allAccepted ? (
                      <img
                        src={allImages.greenTick}
                        alt="greentick"
                        className={styles.time_icon}
                      />
                    ) : (
                      <img
                        src={allImages.warning}
                        alt="warning"
                        className={styles.time_icon}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className={styles.bottom_text}>
                {bpm && <p className={styles.bottom_info_text}>BPM: {bpm}</p>}
                {key && <p className={styles.bottom_info_text}>KEY: {key}</p>}
                {scale && (
                  <p className={styles.bottom_info_text}>SCALE: {scale}</p>
                )}
                {size(collaborators) > 0 && (
                  <div>
                    <IconButton
                      variant="contained"
                      onClick={handleClick}
                      size="small"
                    >
                      <img src={allImages.info} className={styles.time_icon} />
                    </IconButton>
                    {/* Collaborators & Splits  popver */}
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{ paper: styles.collaboratorsInfo }}
                    >
                      <Grid container rowGap={1}>
                        <Grid item xs={12}>
                          <Typography className={styles.heading}>
                            Collaborators & Splits{" "}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container rowGap={1}>
                            {collaborators.map(
                              (
                                { name, lastname, publishingPercentage },
                                index
                              ) => (
                                <Grid xs={12} key={index}>
                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                  >
                                    <Grid item>
                                      <Grid
                                        container
                                        alignItems={"center"}
                                        columnGap={1}
                                      >
                                        <Avatar className={styles.avatar} />
                                        <Typography className={styles.name}>
                                          {name}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <Typography className={styles.name}>
                                        {" "}
                                        {publishingPercentage}%
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className={styles.beatPriceWrapper}>
                          <Grid
                            container
                            rowGap={1}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Grid item>
                              <Typography className={styles.beatPriceLabel}>
                                Beat Price{" "}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className={styles.price}>
                                ${exclusivePrice}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider className={styles.divider} />
                        </Grid>
                        {isMasterSample && (
                          <Grid item xs={12}>
                            <Typography className={styles.isMasterText}>
                              It’s contain <b>Master</b> Sample
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Popover>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.beatPurchase_checkBox_list}>
              <div className={styles.beatPurchase_checkbox_wrapper}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkBox.purchaseBeat}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setError((prev) => {
                              return { ...prev, purchaseBeat: "" };
                            });
                          }
                          setCheckBox({
                            ...checkBox,
                            purchaseBeat: e.target.checked,
                          });
                        }}
                        sx={{
                          color: "green",
                          "&.Mui-checked": {
                            color: "green",
                          },
                        }}
                      />
                    }
                    label="Purchase Beat"
                  />
                  <span
                    style={{
                      marginLeft: "13px",
                      color: "#bb2e2e",
                    }}
                  >
                    {error.purchaseBeat}
                  </span>
                </FormGroup>
                <div className={styles.purchasePriceDiv}>
                  ${exclusivePrice}.00
                </div>
              </div>
            </div>
            <div className={styles.beatPurchase_checkBox_list}>
              <div className={styles.beatPurchase_checkbox_wrapper}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkBox.arAgreement}
                        onChange={(e) => {
                          console.info(
                            "e.target.checked => ",
                            e.target.checked
                          );

                          if (e.target.checked) {
                            setError((prev) => {
                              return { ...prev, arAgreement: "" };
                            });
                          }
                          setCheckBox({
                            ...checkBox,
                            arAgreement: e.target.checked,
                          });
                        }}
                        sx={{
                          color: "green",
                          "&.Mui-checked": {
                            color: "green",
                          },
                        }}
                      />
                    }
                    label="A&R Agreement"
                  />
                  <span
                    style={{
                      marginLeft: "13px",
                      color: "#bb2e2e",
                    }}
                  >
                    {error.arAgreement}
                  </span>
                </FormGroup>
                <div className={styles.purchasePriceDiv}> ${150}.00 </div>
              </div>
            </div>
          </div>
          <div className={styles.parent_button_div}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <button className={styles.price_button}>
                $
                {parseFloat(exclusivePrice) +
                  150 +
                  ((parseFloat(exclusivePrice) + 150) * 7) / 100}
              </button>

              <p className={styles.united_market_cut_text}>
                <DarkTooltip title={"We take a 7% Service Fee"} placement="top">
                  <IconButton variant="contained" size="small">
                    <img src={allImages.info} />
                  </IconButton>
                </DarkTooltip>
              </p>
            </div>

            <div className={styles.button_wrapper}>
              <button
                onClick={() => setShowInfo(false)}
                className={styles.info_button_cancel}
              >
                CANCEL
              </button>
              <button
                disabled={isSelected}
                onClick={() => {
                  if (!checkBox.arAgreement) {
                    setError((prev) => {
                      return { ...prev, arAgreement: "Required" };
                    });
                  } else {
                    setError((prev) => {
                      return { ...prev, arAgreement: "" };
                    });
                  }
                  if (!checkBox.purchaseBeat) {
                    setError((prev) => {
                      return { ...prev, purchaseBeat: "Required" };
                    });
                  } else {
                    setError((prev) => {
                      return { ...prev, purchaseBeat: "" };
                    });
                  }
                  if (checkBox.purchaseBeat && checkBox.arAgreement) {
                    onSelectButton();
                  }
                }}
                className={
                  isSelected ? styles.disable_button : styles.info_button
                }
              >
                SELECT
              </button>
            </div>
          </div>
        </BeatAddModal>
      )}
    </div>
  );
}

export default SelectBeatInfoData;
