import React from "react";

function PLaceholderSvg(props) {
  const { width, height } = props;
  return (
    <svg
      aria-label="Placeholder card"
      width={width}
      height={height}
      viewBox="0 0 24 16"
      images="[object Object]"
    >
      <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
        <g>
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            x="0"
            y="0"
            width="24"
            height="16"
            rx="1"
          ></rect>
          <rect
            id="Rectangle"
            fill="#A6A6A6"
            x="0.923076923"
            y="10.3529412"
            width="4.61538462"
            height="1.88235294"
            rx="0.941176471"
          ></rect>
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            x="16.6153846"
            y="3.76470588"
            width="4.61538462"
            height="2.82352941"
            rx="1"
          ></rect>
          <rect
            id="Rectangle"
            fill="#A6A6A6"
            x="6.46153846"
            y="10.3529412"
            width="4.61538462"
            height="1.88235294"
            rx="0.941176471"
          ></rect>
          <rect
            id="Rectangle"
            fill="#A6A6A6"
            x="11.9230769"
            y="10.3529412"
            width="5.61538462"
            height="1.88235294"
            rx="0.941176471"
          ></rect>
          <rect
            id="Rectangle"
            fill="#A6A6A6"
            x="18.4615385"
            y="10.3529412"
            width="4.61538462"
            height="1.88235294"
            rx="0.941176471"
          ></rect>
        </g>
      </g>
    </svg>
  );
}
export default PLaceholderSvg;
