import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import {
  Box,
  Button,
  Heading,
  HStack,
  Text,
  Input,
  Divider,
  Image,
  Checkbox,
  Menu,
} from "native-base";
import { SoundCard } from "./SoundCard";
import slider from "../../assets/Notification/slider.png";
import { TermAndCondition } from "./Modals/TermAndConditions";
import { useNavigate } from "react-router-dom";
import { SoundPackEmpty } from "./Svgs/SoundPackEmpty";

import {
  getSoundPacks,
  getSoundPacksBySort,
} from "../../Graphql_services/SoundPackMutation";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Loader } from "../Loader";
import useAuth from "../../context/AuthContext";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";

const SoundPack = () => {
  const navigate = useNavigate();
  const [soundData, setSoundData] = useState([]);
  const [soundId, setSoundId] = useState("");
  const [sortCheck, setSortCheck] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [GetSoundPacks] = useLazyQuery(getSoundPacks, {
    context: { clientName: "soundpack" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const { getSoundPacks } = data;
      if (getSoundPacks) {
        const { isTokenExpired, success, message } = getSoundPacks;
        if (!isTokenExpired) {
          if (success) {
            setSoundData(getSoundPacks?.soundpacks);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            //toast.error(message);
          }
        } else {
          setIsLoading(false);
          localStorage.clear();
          navigate("/signin");
        }
      }
    },
    onError(err) {
      console.log("error on getting user info", err);
    },
  });

  const [GetSoundPacksBySort] = useLazyQuery(getSoundPacksBySort, {
    context: { clientName: "soundpack" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      console.info("data => ", data);

      const { getSoundPacksBySort } = data;
      if (getSoundPacksBySort) {
        const { isTokenExpired, success, message } = getSoundPacksBySort;

        console.info("isTokenExpired => ", isTokenExpired);

        if (!isTokenExpired) {
          if (success) {
            setSoundData(getSoundPacksBySort?.soundpacks);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            if (message === "No Soundpacks Found Against Your Query") {
            } else {
              toast.error(message);
            }
          }
        } else {
          setIsLoading(false);
          // localStorage.clear();
          // navigate("/signin");
        }
      }
    },
    onError(err) {
      console.log("error on getting user info", err);
    },
  });

  const HandleSort = (sortType) => {
    if (sortCheck != sortType) {
      setIsLoading(true);
      GetSoundPacksBySort({
        variables: {
          filterType: sortType,
        },
      });
      setSortCheck(sortType);
    } else {
      setIsLoading(true);
      GetSoundPacks();
      setSortCheck("");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    GetSoundPacks();
  }, []);

  return (
    <div w={"100%"} bg={"#000"}>
      <Box w={"100%"} px={"3%"} alignSelf={"center"} mt={5}>
        <HStack justifyContent={"space-between"} my={8}>
          <Heading
            alignSelf={"center"}
            textTransform={"capitalize"}
            fontFamily={"Poppins"}
            fontSize={{
              xl: "40px",
              lg: "36px",
              md: "30px",
              base: "28px",
            }}
            fontWeight={500}
            textAlign={"left"}
            color={"#fff"}
          >
            My Sound Packs
          </Heading>

          <>
            {soundData.length == 0 ? null : (
              <HStack space={{ lg: 8, base: 4 }}>
                <Sort
                  HandleSort={(data) => HandleSort(data)}
                  sortCheck={sortCheck}
                />
                <Filter
                  HandleSort={(data) => HandleSort(data)}
                  sortCheck={sortCheck}
                />
              </HStack>
            )}
          </>
        </HStack>
        {isLoading ? (
          <Box
            minH={"760px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid className={styles.loader}>
              <Loader />
            </Grid>
          </Box>
        ) : (
          <>
            {soundData.length == 0 ? (
              <SoundKitTab />
            ) : (
              <Box>
                <HStack flexWrap={"wrap"} w={"100%"} padding={"1%"}>
                  {soundData.map((item, index) => (
                    <Box key={index} w={{ lg: "33%", base: "100%" }} my={4}>
                      <Box w={{ lg: "90%", base: "100%" }} alignSelf={"center"}>
                        <SoundCard
                          data={item}
                          soundId={soundId}
                          setSoundId={setSoundId}
                        />
                      </Box>
                    </Box>
                  ))}
                </HStack>

                {/* <Box
									w={{ lg: '80%', base: '90%' }}
									alignSelf={'center'}
									my={{ '2xl': 40, lg: 20, base: 10 }}
								>
									<Button
										w={'60%'}
										borderColor='#2EBA55'
										borderWidth={1}
										_pressed={{
											bg: '#2EBB55',
											_text: {
												color: '#fff',
											},
										}}
										backgroundColor='#2EBA55'
										py={2.5}
										_text={{
											color: '#fff',
											fontSize: {
												xl: '18px',
												lg: '18px',
												md: '16px',
												base: '14px',
											},
											fontWeight: 'medium',
											textTransform: 'capitalize',
											fontFamily: 'Poppins',
										}}
										_hover={{
											backgroundColor: 'transparent',
											_text: {
												color: '#2EBA55',
											},
										}}
										alignSelf={'center'}
									>
										Share My Sample Packs
									</Button>
								</Box> */}
              </Box>
            )}
          </>
        )}
      </Box>
    </div>
  );
};
const Sort = (props) => {
  const { HandleSort, sortCheck } = props;
  return (
    <Menu
      rounded={"lg"}
      borderColor={"#fff"}
      borderWidth={1}
      alignSelf={"center"}
      color={"#afafaf"}
      w={"180px"}
      mx={3}
      p={0}
      _text={{
        color: "#afafaf",
      }}
      background={"#1C1B1B"}
      placement={"bottom right"}
      trigger={(triggerProps) => {
        return (
          <Button
            variant={"ghost"}
            rounded={"md"}
            borderColor="#2EBA55"
            borderWidth={1}
            _pressed={{
              bg: "transparent",
              _text: {
                color: "#2EBB55",
              },
            }}
            bg="transparent"
            _text={{
              color: "#2EBB55",
              fontSize: { xl: "22px", lg: "20px", md: "18px", base: "12px" },
              fontWeight: "medium",
              textTransform: "capitalize",
              fontFamily: "Poppins",
            }}
            _hover={{
              backgroundColor: "#2EBA5540",

              _text: {
                color: "#2EBA55",
              },
            }}
            {...triggerProps}
            alignSelf={"center"}
            px={{ lg: 4, base: 2 }}
            py={2}
          >
            Sort By
          </Button>
        );
      }}
    >
      <Menu.Item
        roundedTop={"lg"}
        bg={sortCheck == "mostRecent" ? "#2ebb55" : "#1C1B1B"}
        borderBottomWidth="1px"
        borderBottomColor="#afafaf"
        m={0}
        p={0}
        _focus={{
          bg: "#1C1B1B",
        }}
        _hover={{
          bg: "#1C1B1B",
        }}
        _pressed={{
          bg: "#1C1B1B",
        }}
        w={"100%"}
        alignSelf={"center"}
        onPress={() => HandleSort("mostRecent")}
      >
        <Heading
          my={3}
          fontSize={{ xl: "lg", base: "md" }}
          fontWeight={"normal"}
          fontFamily={"Poppins"}
          textAlign={"center"}
          textTransform={"capitalize"}
          color={sortCheck == "mostRecent" ? "#fff" : "#afafaf"}
          w={"100%"}
          alignSelf={"center"}
        >
          Most Recent
        </Heading>
      </Menu.Item>
      <Menu.Item
        roundedBottom={"lg"}
        bg={sortCheck == "upvotes" ? "#2ebb55" : "#1C1B1B"}
        m={0}
        p={0}
        _focus={{
          bg: "#1C1B1B",
        }}
        _hover={{
          bg: "#1C1B1B",
        }}
        _pressed={{
          bg: "#1C1B1B",
        }}
        alignSelf={"center"}
        w={"100%"}
        onPress={() => HandleSort("upvotes")}
      >
        <Heading
          my={3}
          fontSize={{ xl: "lg", base: "md" }}
          fontWeight={"normal"}
          fontFamily={"Poppins"}
          textAlign={"center"}
          textTransform={"capitalize"}
          color={sortCheck == "upvotes" ? "#fff" : "#afafaf"}
          w={"100%"}
          alignSelf={"center"}
        >
          Most Upvotes
        </Heading>
      </Menu.Item>
    </Menu>
  );
};
const Filter = (props) => {
  const { HandleSort, sortCheck } = props;
  return (
    <Menu
      rounded={"lg"}
      borderColor={"#fff"}
      borderWidth={1}
      alignSelf={"center"}
      color={"#afafaf"}
      // fontSize={{ md: '16px' }}
      w={"150px"}
      mx={3}
      p={0}
      _text={{
        color: "#afafaf",
      }}
      background={"#1C1B1B"}
      placement={"bottom right"}
      trigger={(triggerProps) => {
        return (
          <Button
            variant={"ghost"}
            rounded={"md"}
            _hover={{ bg: "transparent" }}
            _focused={{ bg: "2ebb55" }}
            _pressed={{ bg: "transparent" }}
            {...triggerProps}
            bg={"#1c1b1b"}
            alignSelf={"center"}
            p={{ lg: 3, base: 2 }}
          >
            <Box alignSelf={"center"}>
              <Image source={slider} size={{ lg: 6, base: 4 }} />
            </Box>
          </Button>
        );
      }}
    >
      <Menu.Item
        rounded={"lg"}
        bg={sortCheck == "genre" ? "#2ebb55" : "#1C1B1B"}
        m={0}
        p={0}
        _focus={{
          bg: "#1C1B1B",
        }}
        _hover={{
          bg: "#1C1B1B",
        }}
        _pressed={{
          bg: "#1C1B1B",
        }}
        alignSelf={"center"}
        w={"100%"}
        onPress={() => HandleSort("genre")}
      >
        <Heading
          my={2}
          fontSize={{ xl: "lg", base: "md" }}
          fontWeight={"normal"}
          fontFamily={"Poppins"}
          textAlign={"center"}
          textTransform={"capitalize"}
          color={sortCheck == "genre" ? "#fff" : "#afafaf"}
          w={"100%"}
          alignSelf={"center"}
        >
          Genre
        </Heading>
      </Menu.Item>
    </Menu>
  );
};
const SoundKitTab = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <Box w={"100%"}>
      <Box
        mt={{ "2xl": "20vh", xl: "10vh", lg: "10vh", base: "10vh" }}
        w={"100%"}
        justifyContent={"center"}
      >
        <Box alignSelf={"center"}>
          <SoundPackEmpty />
        </Box>
        <Heading
          fontFamily={"Poppins"}
          fontWeight={500}
          fontSize={{ "2xl": "40px", xl: "36px", lg: "32px", base: "22px" }}
          lineHeight={{ "2xl": "60px", xl: "50x", lg: "40px", base: "30px" }}
          textAlign={"center"}
          color={"#e3e3e3"}
          py={"50px"}
        >
          You have no active sound kits.
        </Heading>
      </Box>
      {true || user?.isSoundPackAllowed === true ? (
        <Button
          w={{ lg: "40%", base: "60%" }}
          borderColor="#2EBA55"
          borderWidth={1}
          borderRadius={"5px"}
          _pressed={{
            bg: "#2EBB55",
            _text: {
              color: "#fff",
            },
          }}
          backgroundColor="#2EBA55"
          py={2.5}
          my={5}
          _text={{
            color: "#fff",
            fontSize: { xl: "18px", lg: "18px", md: "16px", base: "14px" },
            fontWeight: "medium",
            textTransform: "capitalize",
            fontFamily: "Poppins",
          }}
          _hover={{
            backgroundColor: "transparent",
            _text: {
              color: "#2EBA55",
            },
          }}
          alignSelf={"center"}
          onPress={() => navigate("/soundPack/create")}
        >
          Upload A New Sound Kit
        </Button>
      ) : (
        ""
      )}
    </Box>
  );
};

export default SoundPack;
