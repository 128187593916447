import React from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  BestPerforming,
  DashBoardAnalytics,
  HorizontalMenu,
  MainTitle,
  PageTitle,
  RecentUpdate,
} from "../../../components/smartlink";
import { Grid } from "@mui/material";
import { DashboardMain } from "./SmartLinkDashboardStyles";

const SmartLinkDashboard = () => {
  return (
    <SmartLinkLayout>
      <MainTitle title="Smart Link" islinkpage={true} hasbutton={true} />
      <HorizontalMenu />
      <PageTitle title="Dashboard" hasNavigate={false} />
      <DashboardMain>
        <Grid container spacing={3}>
          <Grid item xl={8} lg={8} md={8} sm={12} sx={{ width: "100%" }}>
            <DashBoardAnalytics />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} sx={{ width: "100%" }}>
            <RecentUpdate />
          </Grid>
        </Grid>
      </DashboardMain>
      <Grid container spacing={2}>
        <Grid item xl={8} lg={8} md={8} sm={12} sx={{ width: "100%" }}>
          <BestPerforming />
        </Grid>
      </Grid>
    </SmartLinkLayout>
  );
};

export default SmartLinkDashboard;
