import React from "react";
import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";

const ProfilePopup = () => {
  return (
    <Modal
      open={true}
      disableAutoFocus={true}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        disableAutoFocus
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "600px",
          borderRadius: "30px",
          outline: "none !important",
          backgroundColor: "rgba(20, 20, 20, 1)",
          border: "none",
          borderColor: "rgba(20, 20, 20, 1)",
          //   boxShadow: 24,
          p: 4,
          "@media (max-width: 600px)": {
            width: "90vw",
          },
        }}
      >
        <AccountCircleIcon
          style={{
            fontSize: "60px",
            color: "#2ebb55",
            margin: "auto",
            display: "block",
          }}
        />
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            mt: "20px !important",
            fontFamily: "Poppins !important",
            fontStyle: "normal !important",
            fontWeight: "400 !important",
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "30px",
            color: "#FFFFFF",
          }}
        >
          Please complete your profile
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{
            mt: "10px !important",
            mb: "20px !important",
            fontFamily: "Poppins !important",
            fontStyle: "normal !important",
            fontWeight: "400 !important",
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "30px",
            color: "grey",
          }}
        >
          We want to ensure we have the most up to date information
        </Typography>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            // marginTop: "20px !important",
          }}
        >
          <Link to={"/settings"} style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              size="medium"
              sx={{
                color: "#FFF !important",
                fontFamily: "Poppins !important",
                fontSize: "16px !important",
                fontStyle: "normal !important",
                fontWeight: "500 !important",
                lineHeight: "normal !important",
                borderRadius: "5px !important",
                background: "#2EBB55 !important",
                padding: "8px 24px !important",
                textTransform: "capitalize !important",
                "@media (max-width: 600px)": {
                  fontSize: "14px !important",
                  padding: "8px 16px !important",
                },
              }}
            >
              COMPLETE PROFILE
            </Button>
          </Link>
        </div>
      </Box>
    </Modal>
  );
};

export default ProfilePopup;
