import React from "react";
import { ButtonsWrapper } from "./style";
import SolidButton from "../../../../commons/SolidButton";
import GhostButton from "../../../../commons/GhostButton";
import RestartIcon from "../../../../../../../assets/smartlink/icons/RestartIcon";

const RescanButtons = () => {
  return (
    <ButtonsWrapper>
      <GhostButton>
        <RestartIcon />
        Schedule Rescan
      </GhostButton>
      <SolidButton>
        <RestartIcon stroke="white" />
        Rescan Now
      </SolidButton>
    </ButtonsWrapper>
  );
};

export default RescanButtons;
