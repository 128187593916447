import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectMain, StyledMenuItem } from "./LeftSelecterDropdownstyle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Divider } from "@mui/material";

const menuPaperStyle = {
  // marginTop: "5px",
  marginLeft: "18px",
  padding: "20px",
  background: "#141515",
  borderRadius: "10px",
  color: "#FFFFFF",
};
const LeftSelecterDropdown = ({
  title1 = "",
  title2 = "",
  height = "auto",
  width = "auto",
  label = "",
}) => {
  const [age, setAge] = React.useState({ label });
  const [isSelectOpen, setIsSelectOpen] = React.useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleOpen = () => {
    setIsSelectOpen(true);
  };

  const handleClose = () => {
    setIsSelectOpen(false);
  };

  return (
    <SelectMain height={height} width={width}>
      <FormControl fullWidth>
        <Select
          className={`select-box ${isSelectOpen ? "open" : ""}`}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label={label}
          onChange={handleChange}
          IconComponent={ArrowForwardIosIcon}
          onClose={handleClose}
          onOpen={handleOpen}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "right",
              horizontal: "center",
            },
            PaperProps: {
              style: menuPaperStyle,
            },
          }}
        >
          <StyledMenuItem value={1}>{title1}</StyledMenuItem>
          <Divider
            sx={{
              height: "1px",
              background: "#414241 !important",
              width: "auto",
            }}
          />
          <StyledMenuItem value={2}>{title2}</StyledMenuItem>
        </Select>
      </FormControl>
    </SelectMain>
  );
};
export default LeftSelecterDropdown;
