import React from "react";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Loader } from "../../UniversalComponents/Loader";

import styles from "../admin.module.css";
import { Box, ListItemText, Tooltip, Typography } from "@mui/material";
import { useDebounce } from "../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import useAppState from "../../../context/useAppState";
import { last } from "lodash";
import { get } from "lodash";
import allImages from "../../../assets";
import Pagination from "../../../components-old/Pagination/Pagination";

const AdminSelectedBeats = () => {
  const theme = useTheme();
  const [checkedItems, setCheckedItems] = useState(new Set());

  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedBeatData, setSelectedBeatData] = useState([]);

  const [query, setQuery] = useState("");
  const PAGE_LIMIT = 10;
  const searchQuery = useDebounce(query.trim(), 500);
  const navigate = useNavigate();

  const filterData = ["Contracts", "Beat Purchased"];

  const { fetchSelectedBeatByQuery } = useAppState("opportunity");

  const getSelectedBeatData = () => {
    setIsLoading(true);
    const queryString = new URLSearchParams({
      page: currentPage,
      limit: PAGE_LIMIT,
      search: searchQuery,
      // sort: sortBy,
      // genre: filter,
    }).toString();
    fetchSelectedBeatByQuery(queryString)
      .then((response) => {
        if (response.data) {
          const newRows = response.data;
          const totalCountData = response.totalCount
            ? response.totalCount
            : totalCount;
          setTotalCount(Math.ceil(totalCountData / PAGE_LIMIT));
          setSelectedBeatData(newRows);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSelectedBeatData();
  }, [searchQuery, currentPage]);

  const toggleCheck = (id) => {
    setCheckedItems((prevChecked) => {
      const newChecked = new Set(prevChecked);
      if (newChecked.has(id)) {
        newChecked.delete(id);
      } else {
        newChecked.add(id);
      }
      return newChecked;
    });
  };

  const handleView = (id) => {
    navigate(`/opportunities/viewsubmissiondetails/${id}`);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getFilename = (file) => {
    const fileName = decodeURIComponent(last(get(file, "url", "").split("/")));
    return fileName;
  };

  const applyFilter = (value) => {
    if (value === filter) {
      setFilter("");
      setAnchorEl(null);
    } else {
      setFilter(value);
      setAnchorEl(null);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.toString();
    const numericOnly = phoneNumber.replace(/\D/g, "");
    if (numericOnly.length >= 11) {
      const digitsWithoutCountryCode = numericOnly.substring(1);
      const formattedNumber = `${digitsWithoutCountryCode.substring(
        0,
        3
      )}-${digitsWithoutCountryCode.substring(
        3,
        6
      )}-${digitsWithoutCountryCode.substring(6)}`;
      return formattedNumber;
    }
    if (numericOnly.length >= 10) {
      return `${numericOnly.substring(0, 3)}-${numericOnly.substring(
        3,
        6
      )}-${numericOnly.substring(6)}`;
    }
    return numericOnly;
  };

  return isLoading ? (
    <Box className={styles.selected_beat_main_layout}>
      <Loader />
    </Box>
  ) : (
    <Box>
      <Box
        className={styles.titleWrapper}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 19,
          paddingLeft: 20,
        }}
      >
        <Typography
          className={styles.title_head}
          style={{
            color: "#FFF",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "normal",
            fontFamily: "Poppins",
          }}
        >
          Selected Beats
        </Typography>

        {/* <div className={`${styles.selected_beat_filterBtn}`} onClick={handleClick}>
            <Setting />
            {filter && <div className={styles.isAppliedFilter}></div>}
          </div>
          <StyledMenu
            id="customized-menu1"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {filterData.map((genre, index) => (
              <StyledMenuItem
                key={index}
                className={filter === genre ? "active" : ""}
              >
                <ListItemText
                  primary={genre}
                  onClick={(e) => applyFilter(genre)}
                  style={{ textAlign: "center" }}
                />
              </StyledMenuItem>
            ))}
          </StyledMenu>
           */}
      </Box>
      <div className={styles.selected_beat_sub_page_layout}>
        <Box
          sx={{ padding: "15px 0px 10px 0" }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div className={styles.selected_beat_search_box}>
            <img
              className={styles.selected_beat_search_icon}
              src="/v2Assets/connect_assets/Search.png"
              alt="search"
            ></img>
            <input
              className={styles.selected_beat_search_box_input}
              type="text"
              placeholder={"Search Selected Beats"}
              value={query}
              onChange={(data) => {
                setQuery(data.target.value);
              }}
            />
          </div>
        </Box>
        <div className={styles.selected_beat_table_sections}>
          {/* <div className={styles.checkbox_column}></div> */}
          <div className={`${styles.selector_column}`}>
            <p
              style={{ textAlign: "left", paddingLeft: "13%" }}
              className={styles.selected_beat_row_titles}
            >
              Selector
            </p>
          </div>
          <div className={`${styles.beat_column}`}>
            <p
              style={{ textAlign: "left", paddingLeft: "12%" }}
              className={styles.selected_beat_row_titles}
            >
              Beat
            </p>
          </div>
          <div className={styles.contracts_column}>
            <p className={styles.selected_beat_row_titles}>Contracts</p>
          </div>
          <div className={styles.beat_purchased_column}>
            <p className={styles.selected_beat_row_titles}>Beat Purchased</p>
          </div>
          <div className={styles.view_button_column}></div>
        </div>

        <div className={styles.table_content}>
          <Box
            className={styles.content}
            id="scrolldiv"
            style={{
              overflowY: "auto",
              maxHeight: "calc(100vh - 390px)",
            }}
          >
            {selectedBeatData.length > 0 ? (
              selectedBeatData.map((data, index) => (
                <div key={`beatDataZ-${index}`} className={styles.selected_beat_user_content}>

                  <div
                    className={`${styles.user_info} ${styles.selector_column}`}
                  >
                    {data.profilePic ? (
                      <img
                        src={data.profilePic}
                        className={styles.selector_image}
                        alt="user"
                      ></img>
                    ) : (
                      <img
                        src={allImages.defaultCoverImage}
                        className={styles.selector_image}
                        alt="user"
                      ></img>
                    )}

                    <div className={styles.selector_info_text}>
                      <p className={styles.selector_title}>{data.name}</p>
                      <p className={styles.selector_subtitle}>
                        <Tooltip placement="top" arrow title={data.email}>
                          <span className={styles.selector_subtitle}>
                            {data.email}
                          </span>
                        </Tooltip>
                      </p>
                      <p className={styles.selector_phone_number}>
                        <Tooltip
                          placement="top"
                          arrow
                          title={formatPhoneNumber(data.phoneNumber)}
                        >
                          <span className={styles.selector_phone_number}>
                            {formatPhoneNumber(data.phoneNumber)}
                          </span>
                        </Tooltip>
                      </p>
                    </div>
                  </div>

                  <div className={styles.beat_info_text}>
                    <p
                      style={{ textAlign: "left" }}
                      className={styles.beat_title}
                    >
                      {getFilename(data.file)}
                    </p>
                    <p
                      style={{ textAlign: "left", justifyContent: "left" }}
                      className={styles.username_title}
                    >
                      <Tooltip
                        placement="top"
                        arrow
                        title={`@${data.username}`}
                      >
                        <span className={styles.username_title}>
                          @{data.username}
                        </span>
                      </Tooltip>
                    </p>
                  </div>

                  <div
                    className={`${styles.role_type_box} ${styles.contracts_column}`}
                  >
                    <p className={styles.isbeatPurchased}>-</p>
                  </div>
                  <div
                    className={`${styles.role_type_box} ${styles.beat_purchased_column}`}
                  >
                    <p className={styles.isbeatPurchased}>-</p>
                  </div>
                  <div className={styles.view_button_column}>
                    <div
                      className={styles.view_button}
                      onClick={() => handleView(data._id)}
                    >
                      <p className={styles.view_button_title}>View</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#fff",
                  alignItems: "center",
                  marginTop: 80,
                }}
              >
                <Typography>No Data Found</Typography>
              </Box>
            )}
          </Box>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalCount}
          onPageChange={handlePageChange}
        />
      </div>
    </Box>
  );
};

export default AdminSelectedBeats;
