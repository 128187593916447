import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "../submissions.module.css";

import ConnectSubmissionCard from "./connectSubmissionCard";
import { Loader } from "../../OpportunitiesComponents/AdditionalComponents/Loader";
import useAppState from "../../../context/useAppState";
import { useDebounce } from "../../../hooks/useDebounce";
import { Box, Grid, Typography } from "@mui/material";
import useAuth from "../../../context/AuthContext";
import ConnectBeatInfoData from "./ConnectBeatInfoData";
import { useNavigate } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";

function ConnectSubmissionsComponent() {
  const pageTabs = ["Sent", "Received"];
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("createdAt");
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState(pageTabs[0]);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState(pageTabs[0]);

  const { user } = useAuth();
  const navigate = useNavigate();

  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  // useEffect(() => {
  //   if (
  //     (user && get(user, "roleId.roleName", "") !== ROLES.MUSIC_PRODUCER) ||
  //     ROLES.ADMIN
  //   ) {
  //     navigate("/opportunities");
  //   }
  // }, [user]);

  const searchQuery = useDebounce(query, 500);

  const { fetchMyConnectSubmission } = useAppState("connectMeSubmissions");
  const PAGE_LIMIT = 10;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setPage(0);
  }, [type]);

  useEffect(() => {
    setLoading(true);
    const queryString = new URLSearchParams({
      page: 1,
      limit: PAGE_LIMIT,
      type: type,
      search: searchQuery,
      sort: sortBy,
    }).toString();

    fetchMyConnectSubmission(queryString)
      .then((response) => {
        const newRows = response.data;

        setHasNextPage(newRows.length < response.totalCount);
        setData(newRows);
        console.log(data);
        setPage((page) => page + 1);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchQuery, sortBy, type]);

  useEffect(() => {
    const tabEl = [...tabsRef.current.children].find(
      (child) => child.textContent === activeTab
    );
    const { offsetLeft, offsetWidth } = tabEl;
    underlineRef.current.style.left = `${offsetLeft}px`;
    underlineRef.current.style.width = `${offsetWidth}px`;
  }, [activeTab]);

  const getMoreData = async () => {
    try {
      const newPage = page + 1;
      const queryString = new URLSearchParams({
        page: newPage,
        limit: PAGE_LIMIT,
        type: type,
        search: searchQuery,
        sort: sortBy,
      }).toString();
      fetchMyConnectSubmission(queryString)
        .then((response) => {
          const newRows = [...data, ...response.data];

          console.info("newRows => ", newRows);

          setHasNextPage(newRows.length < response.totalCount);
          setData(newRows);
          setPage((page) => page + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setError(err);
    }
  };

  const fetchMore = () => {
    console.log("fetch more...");
    getMoreData();
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Submissions</p>

      <Box>
        <Box sx={{ padding: "15px 0px 10px 0" }}>
          <div className={styles.search_box}>
            <img
              className={styles.search_icon}
              src="/v2Assets/connect_assets/Search.png"
              alt="search"
            ></img>
            <input
              className={styles.search_box_input}
              type="text"
              placeholder={"Search"}
              onChange={(data) => {
                console.info("data => ", data);
                setQuery(data.target.value);
              }}
            />
          </div>
        </Box>
      </Box>

      <div className={styles.tabs} ref={tabsRef}>
        {pageTabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => {
              setActiveTab(tab);
              setType(tab);
            }}
          >
            {tab}
          </div>
        ))}
        <div className={styles.underline} ref={underlineRef} />
      </div>

      <div className={styles.tab_layout}>
        {!loading ? (
          <Fragment>
            {data?.length > 0 ? (
              <>
                {data.map((submission, key) => (
                  <Fragment key={key}>
                    <ConnectSubmissionCard
                      onView={() => {}}
                      isShowBeatDetail={true}
                      data={submission}
                      isShowOpportunityName={true}
                      beatInfoBtn={
                        <ConnectBeatInfoData beatData={submission} />
                      }
                      sender={submission.createdBy}
                      reciever={submission.receiverId}
                    />
                  </Fragment>
                ))}
              </>
            ) : (
              <>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "#fff",
                    alignItems: "center",
                  }}
                >
                  <Typography>No Data Found</Typography>
                </Box>
              </>
            )}
          </Fragment>
        ) : (
          <Loader />
        )}

        {(loading || hasNextPage) && (
          <Grid container justifyContent={"center"} ref={sentryRef}>
            <Grid item>
              <Typography style={{ color: "#2ebb55" }}>Loading..</Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default ConnectSubmissionsComponent;
