import React, { memo, useState, useEffect } from "react";
import { Modelstyle, ModelContentBox } from "../../socialintegrate/style";
import { Box, Button, IconButton, Modal } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { close_icon } from "../../../../assets/smartlink";
import { WelcomeMain } from "./styles/MainStyles";
import { useNavigate } from "react-router-dom";
import logo from "../../../../assets/Logo/logo.png";

const AuthWelcomePopup = ({ open = false, setOpen = () => {} }) => {
  const navigate = useNavigate();
  const CreateModelClose = (event, reason) => {
    // if (reason && reason == "backdropClick") return;
    // setOpen(!open);
  };

  return (
    <>
      {/* <Modal
        open={true}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.50)",
          },
        }}
      > */}
      <ModelContentBox
        sx={{
          ...Modelstyle,
          width: { xs: "100%", sm: "500px" },
          padding: "2rem",
          borderRadius: "20px",
          background:
            "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
          backdropFilter: "blur(200px)",
        }}
        className="ModelContentBox"
      >
        <Box className="popup_header">
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            aria-label="settings"
            sx={{
              color: "#AFAFAF",
              backgroundColor: "none",
              fontSize: "2rem",
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <IconButton
            onClick={CreateModelClose}
            aria-label="settings"
            sx={{
              color: "#AFAFAF",
              backgroundColor: "none",
              fontSize: "2rem",
            }}
          >
            <img src={close_icon} alt="cancel-icon" />
          </IconButton>
        </Box>

        <WelcomeMain>
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <h6>Welcome</h6>
          <p style={{ marginBottom: "20px" }}>
            {/* If you already have an account select “Sign In” to access your
            beats. If you’re new, choose “Sign Up” to begin collaborating with
            musicians worldwide. */}
            Create an account to successfully submit your audio file.
          </p>
          <Box className="buttonMain">
            <Button
              onClick={() => {
                navigate("/producer/auth/login");
              }}
            >
              Sign In
            </Button>
            <p>Log In to successfully submit your audio file. </p>
            <Button
              onClick={() => {
                navigate("/producer/auth/user-type");
              }}
            >
              Sign Up
            </Button>
          </Box>
        </WelcomeMain>
      </ModelContentBox>
      {/* </Modal> */}
    </>
  );
};

export default memo(AuthWelcomePopup);
