import React from "react";
import ProducersLayouts from "./ProducersLayouts";
import UserTypePopup from "../../../../components/smartlink/producers/auths/UserTypePopup";

const ProducersUserTypePage = () => {
  return (
    <ProducersLayouts>
      <UserTypePopup />
    </ProducersLayouts>
  );
};

export default ProducersUserTypePage;
