import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const ModelContentBox = styled(Box)(({ theme }) => ({
  "& .ImgBox": {
    height: "100px",
    width: "100px",
    borderRadius: "50%",
    backgroundColor: "rgba(46, 187, 85, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  " .ContentBox": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px",
    "& .MultiHead": {
      color: "#E3E3E3",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      margin: 0,
    },
    "& .MultiPara": {
      color: "#AFAFAF",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      margin: 0,
    },
  },
}));

const MultiButton = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "10.5px 30px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  borderRadius: "5px",
  background: "#2EBB55",
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  cursor: "pointer",
}));

const Modelstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "675px",
  bgcolor: "background.paper",
  //   boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export { ModelContentBox, Modelstyle, MultiButton };
