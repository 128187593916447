import React, { useState, useEffect } from "react";
import { Box, Button, Heading, HStack, Input, Image } from "native-base";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import {
  getTags,
  searchTag,
} from "../../../Graphql_services/SoundPackMutation";
import ProgressTags from "./ProgressTags";
import { AddTags } from "../Modals/AddTags";
import { Loader } from "../../Loader";
import { Grid } from "@mui/material";
import styles from "../styles.module.css";

const UploadSoundKitsTag = (props) => {
  const { setStep, uploadData, setUploadData, edit, editData } = props;
  const [selectedTags, setSelectedTags] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [genres, setGenres] = useState([]);
  const [moods, setMoods] = useState([]);
  const [artists, setArtists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handleCancel = () => {
    // setUploadData(({ soundPackFile, ...updatedSoundPack }) => updatedSoundPack);
    // console.log('cancele.....', uploadData);
    // setStep(2);
    navigate("/soundPack");
  };
  const HandleNext = () => {
    if (selectedTags.length >= 1) {
      setUploadData({
        ...uploadData,
        tags: selectedTags,
      });
      setStep(4);
    } else {
      toast.error("Add at least 1 tag");
    }
  };
  const [getSoundpackTags] = useLazyQuery(getTags, {
    context: { clientName: "soundpack" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const { getAllTags } = data;
      if (getAllTags) {
        const {
          success,
          message,
          Moods,
          Artists,
          Instruments,
          Genres,
          isTokenExpired,
        } = getAllTags;

        if (success === true) {
          setGenres(Genres);
          setMoods(Moods);
          setInstruments(Instruments);
          setArtists(Artists);
          setIsLoading(false);
        }
        if (success === false) {
          setIsLoading(false);
          if (isTokenExpired) {
            localStorage.clear();
            navigate("/signin");
          } else {
            toast.error(message);
          }
        }
      }
    },
    onError(err) {
      console.log("error on getting getMyPaymentMethod", err);
    },
  });
  const [searchSoundpackTags] = useLazyQuery(searchTag, {
    context: { clientName: "soundpack" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const { getAllTags } = data;
      if (getAllTags) {
        const {
          success,
          message,
          Moods,
          Artists,
          Instruments,
          Genres,
          isTokenExpired,
        } = getAllTags;

        if (success === true) {
          setGenres(Genres);
          setMoods(Moods);
          setInstruments(Instruments);
          setArtists(Artists);
          setIsLoading(false);
        }
        if (success === false) {
          setIsLoading(false);
          if (isTokenExpired) {
            localStorage.clear();
            navigate("/signin");
          } else {
            if (message === "No Tags Found Against Your Query") {
              setGenres(Genres);
              setMoods(Moods);
              setInstruments(Instruments);
              setArtists(Artists);
            } else {
              toast.error(message);
            }
          }
        }
      }
    },
    onError(err) {
      console.log("error on getting getMyPaymentMethod", err);
    },
  });
  const handleSearchTag = (e) => {
    let val = e.target.value;
    setIsLoading(true);
    searchSoundpackTags({ variables: { searchQuery: val } });
  };
  const updateTagsState = (type, value, state, setState) => {
    setState((prevState) => [...prevState, value]);
  };
  const handleUpdateTag = (tag) => {
    switch (tag.type) {
      case "Instrument":
        updateTagsState(tag.type, tag.value, instruments, setInstruments);
        break;
      case "Mood":
        updateTagsState(tag.type, tag.value, moods, setMoods);
        break;
      case "Genre":
        updateTagsState(tag.type, tag.value, genres, setGenres);
        break;
      case "Artist":
        updateTagsState(tag.type, tag.value, artists, setArtists);
        break;
      default:
        return true;
    }
  };

  useEffect(() => {
    if (edit) {
      setSelectedTags(editData.tags.map(({ __typename, ...rest }) => rest));
    } else {
      setSelectedTags([]);
    }
  }, [editData]);
  useEffect(() => {
    setIsLoading(true);
    getSoundpackTags();
  }, []);
  return (
    <>
      <Box w={"100%"}>
        <Box w={"100%"} px={"3%"} alignSelf={"center"}>
          <ProgressTags index={3} />
          <Heading
            my={8}
            textTransform={"capitalize"}
            fontFamily={"Poppins"}
            fontSize={{
              xl: "40px",
              lg: "36px",
              md: "30px",
              base: "28px",
            }}
            fontWeight={"medium"}
            textAlign={"center"}
            color={"#fff"}
          >
            Add Tags
          </Heading>
          <Heading
            fontFamily={"Poppins"}
            fontWeight={400}
            fontSize={{ lg: "12px", base: "10px" }}
            lineHeight={{ lg: "18px", base: "16px" }}
            textAlign={"center"}
            color={"#afafaf"}
            alignSelf={"center"}
            w={"60%"}
          >
            Add at least 1 tags to describe your beat. These help artists
            discover your beats when they search.
            <br /> Maximum of 1 artist tags per beat.
          </Heading>
          <Box alignSelf={"center"} w={"100%"} my={6}>
            <HStack alignSelf={"center"}>
              <Heading
                textTransform={"capitalize"}
                fontFamily={"Poppins"}
                fontSize={{
                  xl: "30px",
                  lg: "24px",
                  md: "20px",
                  base: "18px",
                }}
                fontWeight={"medium"}
                alignSelf={"center"}
                color={"#fff"}
              >
                {selectedTags.length + "/7"}
              </Heading>
              <Heading
                fontFamily={"Poppins"}
                fontWeight={400}
                fontSize={{ lg: "12px", base: "10px" }}
                lineHeight={{ lg: "18px", base: "16px" }}
                color={"#afafaf"}
                alignSelf={"flex-end"}
                py={2}
              >
                Add some tags
              </Heading>
            </HStack>
            <Box
              w={"60%"}
              bg={"#afafaf"}
              my={3}
              rounded={"md"}
              alignSelf={"center"}
            >
              <Box
                bg={"#2ebb55"}
                py={"4px"}
                w={`${(100 / 7) * selectedTags.length}%`}
                rounded={"md"}
              />
            </Box>
          </Box>
          <Input
            my={5}
            _text={{ fontSize: "1rem", fontFamily: "poppins" }}
            _hover={{ borderColor: "transparent" }}
            _focus={{ bg: "#1c1b1b" }}
            fontFamily="poppins"
            fontSize={{ lg: "18px", md: "16px", base: "14px" }}
            color={"#fff"}
            bg={"#1c1b1b"}
            borderColor="transparent"
            height={{
              xl: "60px",
              base: "45px",
            }}
            onChange={(e) => handleSearchTag(e)}
            placeholder={"Start typing to find tags"}
            placeholderTextColor={"#afafaf"}
            focusOutlineColor={"#2eba55"}
            InputLeftElement={
              <Image
                source={require("../../../assets/Icons/searchIcon.png")}
                alt="Search Field"
                color="#ffff"
                size={{ xl: 7, md: 5.5, base: 4 }}
                mx={3}
                alignItems={"center"}
              />
            }
          />
          {isLoading ? (
            <Grid className={styles.loader}>
              <Loader />
            </Grid>
          ) : (
            <>
              <TagFunction
                type={"Instrument"}
                data={instruments}
                selectedTags={selectedTags}
                setSelectedTags={(data) => setSelectedTags(data)}
                handleUpdateTag={handleUpdateTag}
              />
              <TagFunction
                type={"Genre"}
                data={genres}
                selectedTags={selectedTags}
                setSelectedTags={(data) => setSelectedTags(data)}
                handleUpdateTag={handleUpdateTag}
              />
              <TagFunction
                type={"Mood"}
                data={moods}
                selectedTags={selectedTags}
                setSelectedTags={(data) => setSelectedTags(data)}
                handleUpdateTag={handleUpdateTag}
              />
              <TagFunction
                type={"Artist"}
                data={artists}
                selectedTags={selectedTags}
                setSelectedTags={(data) => setSelectedTags(data)}
                handleUpdateTag={handleUpdateTag}
              />
              <Box w={{ lg: "80%", base: "90%" }} alignSelf={"center"}>
                <HStack
                  alignSelf={"center"}
                  w={{ lg: "60%", base: "100%" }}
                  my={{ "2xl": 40, lg: 20, base: 10 }}
                  justifyContent={"space-between"}
                >
                  <Button
                    w={"45%"}
                    variant={"ghost"}
                    _hover={{
                      backgroundColor: "#2EBA55",
                      _text: {
                        color: "#fff",
                      },
                    }}
                    _pressed={{
                      bg: "#2EBB55",
                      _text: {
                        color: "#fff",
                      },
                    }}
                    _focus={{ bg: "transparent" }}
                    // mt={10}
                    p={0}
                    py={2.5}
                    borderColor="#2EBA55"
                    borderWidth={1}
                    alignSelf={"center"}
                    _text={{
                      color: "#2ebb55",
                      fontWeight: "medium",
                      fontSize: {
                        xl: "18px",
                        lg: "18px",
                        md: "16px",
                        base: "14px",
                      },
                      fontFamily: "Poppins",
                    }}
                    onPress={() => handleCancel()}
                  >
                    CANCEL
                  </Button>
                  <Button
                    w={"45%"}
                    borderColor="#2EBA55"
                    borderWidth={1}
                    _pressed={{
                      bg: "#2EBB55",
                      _text: {
                        color: "#fff",
                      },
                    }}
                    backgroundColor="#2EBA55"
                    py={2.5}
                    _text={{
                      color: "#fff",
                      fontSize: {
                        xl: "18px",
                        lg: "18px",
                        md: "16px",
                        base: "14px",
                      },
                      fontWeight: "medium",
                      textTransform: "capitalize",
                      fontFamily: "Poppins",
                    }}
                    _hover={{
                      backgroundColor: "transparent",
                      _text: {
                        color: "#2EBA55",
                      },
                    }}
                    alignSelf={"center"}
                    onPress={() => HandleNext()}
                  >
                    NEXT
                  </Button>
                </HStack>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const TagFunction = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const { type, data, selectedTags, setSelectedTags, handleUpdateTag } = props;
  const handleAddTagModal = (type) => {
    setOpenModal(true);
  };
  const handleChange = (tag) => {
    if (!selectedTags.some((obj) => obj.value === tag && obj.type === type)) {
      if (selectedTags.length < 7) {
        if (
          type === "Artists" &&
          selectedTags.filter((obj) => obj.type === "Artist").length > 2
        ) {
          toast.error("Cannot add more than 3 Artists tags");
        } else {
          setSelectedTags([...selectedTags, { type: type, value: tag }]);
        }
      } else {
        toast.error("Cannot add more than 7 tags");
      }
    } else {
      setSelectedTags(
        selectedTags.filter((obj) => obj.type !== type || obj.value !== tag)
      );
    }
  };

  return (
    <>
      <Box>
        <Heading
          my={5}
          textTransform={"capitalize"}
          fontFamily={"Poppins"}
          fontSize={{
            xl: "24px",
            lg: "22px",
            md: "18px",
            base: "18px",
          }}
          fontWeight={"medium"}
          color={"#fff"}
        >
          {type + "s"}
        </Heading>
        <HStack flexWrap={"wrap"}>
          {data.length > 0 ? (
            <>
              {data.map((item, index) => {
                let selected = false;
                if (
                  selectedTags.some(
                    (obj) => obj.value === item && obj.type === type
                  )
                ) {
                  selected = true;
                }
                return (
                  <Button
                    key={index}
                    variant={"ghost"}
                    bg={selected ? "#2ebb55" : "transparent"}
                    _hover={{
                      bg: "#2ebb55",
                      borderColor: "#fff",
                      _text: { color: "#fff" },
                    }}
                    _pressed={{ bg: "transparent" }}
                    minW={{ lg: "90px", base: "70px" }}
                    _text={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: { lg: "18px", base: "14px" },
                      color: selected ? "#fff" : "#afafaf",
                    }}
                    borderWidth={1}
                    borderColor={selected ? "#fff" : "#afafaf"}
                    p={2}
                    my={2}
                    mr={{ lg: 4, base: 3 }}
                    textAlign={"center"}
                    rounded={"md"}
                    onPress={() => {
                      handleChange(item);
                    }}
                  >
                    {item}
                  </Button>
                );
              })}
              <Button
                variant={"ghost"}
                bg={"transparent"}
                _hover={{
                  bg: "#2ebb55",
                  borderColor: "#fff",
                  _text: { color: "#fff" },
                }}
                _pressed={{ bg: "transparent" }}
                minW={{ lg: "90px", base: "70px" }}
                _text={{
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: { lg: "18px", base: "14px" },
                  color: "#afafaf",
                }}
                borderWidth={1}
                borderColor={"#afafaf"}
                p={2}
                my={2}
                mr={{ lg: 4, base: 3 }}
                textAlign={"center"}
                rounded={"md"}
                onPress={() => {
                  handleAddTagModal(type);
                }}
              >
                +
              </Button>
            </>
          ) : (
            <Heading
              my={5}
              fontFamily={"Poppins"}
              fontSize={{
                xl: "16px",
                lg: "16px",
                md: "16px",
                base: "16px",
              }}
              fontWeight={"medium"}
              color={"#fff"}
            >
              No result found
            </Heading>
          )}
        </HStack>
      </Box>
      {openModal ? (
        <AddTags
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false);
          }}
          type={type}
          heading={type}
          label={type}
          handleUpdateTag={handleUpdateTag}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default UploadSoundKitsTag;
