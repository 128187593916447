import React, { useRef, useState, memo } from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { ModelContentBox } from "./style";
import theme from "../../../theme";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "background.paper",
  //   boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ReleasePageModal = ({ handleModalOpen , open , children , background = theme.palette.primary.main}) => {


  return (
    <>
      <Modal
        open={open}
        onClose={() => handleModalOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...style,
            width: { xs: "100%", sm: "500px" },
            display: "flex",
            alignItems: "center",
            padding: "3rem",
            borderRadius: "0.625rem",
            background,
            backgroundColor: "none",
          }}
        >
          {children}
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default ReleasePageModal;
