import axios from "../../config/axiosInterceptor";
import { HTTP_STATUS } from "../../shared/constants";

export const uploadImages = async (payload) => {
  const { data, status } = await axios.post(
    `${process.env.REACT_APP_FILE_URL}/file/uploadprofilepicture`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  if (status === HTTP_STATUS.OK || status === 201) {
    return data;
  } else {
    throw new Error(data);
  }
};

export const downloadAll = async (payload) => {
  const { data, status } = await axios.post(
    `${process.env.REACT_APP_FILE_URL}/file/downloadMultipleFiles`,
    payload,
    {
      headers: {
        // 'Content-Type': 'application/zip',
        "Content-Disposition": 'attachment; filename="s3-files.zip"',
      },
    }
  );
  if (status === HTTP_STATUS.OK || status === 201) {
    return data;
  } else {
    throw new Error(data);
  }
};

export const uploadTrack = async (payload) => {
  const { data, status } = await axios.post(
    `${process.env.REACT_APP_FILE_URL}/file/upload`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  if (status === HTTP_STATUS.OK || status === 201) {
    return data;
  } else {
    throw new Error(data);
  }
};

export const documentUpload = async (payload) => {
  const { data, status } = await axios.post(
    `${process.env.REACT_APP_FILE_URL}/file/upload-document`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  if (status === HTTP_STATUS.OK || status === 201) {
    return data;
  } else {
    throw new Error(data);
  }
};
