import React from "react";
import styles from "../../auth_pages.module.css";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { VerifySignInOtp } from "../../../../Graphql_services/SignUpMutation";
import { useMutation } from "@apollo/client";
import { Notification } from "../../../NotifyMsg/Notification";
import OtpInput from "react-otp-input";
import { useBreakpointValue } from "native-base";

const VerificationCodeForm = ({ onBack, onNext, user, completeAccount }) => {
  const [otpvalue, setOtpValue] = useState("");
  const [verifySignInOtpMutation, { loading, error }] =
    useMutation(VerifySignInOtp);

  const canContinue = otpvalue?.length === 4;

  const HandelOTPChecker = async () => {
    const otp = otpvalue.toString();
    try {
      const { data } = await verifySignInOtpMutation({
        variables: { otp },
      });
      if (!data?.checkResetOtp.success) {
        Notification("error", data?.checkResetOtp?.message);
        return;
      } else {
        Notification("success", data?.checkResetOtp?.message);
        let updatedUser = { ...user };
        let updatedProfile = { ...user.profile };
        updatedProfile.otp = otp;
        console.log("🚀 ~ HandelOTPChecker ~ updatedProfile:", updatedProfile);
        updatedUser.profile = updatedProfile;
        onNext(updatedUser);
      }
    } catch (error) {
      Notification(
        "error",
        error.message || "something went wrong please try again"
      );
    }
  };

  const size = useBreakpointValue({
    base: {
      textAlign: "center",
      width: "35px",
      height: "35px",
      fontSize: "1.1rem",
      marginRight: "1rem",
      background: "transparent",
      border: "1px solid #AFAFAF",
      color: "white",
      fontFamily: "poppins",
    },
    sm: {
      width: "60px",
      height: "60px",
      fontSize: "2rem",
      marginRight: "0.6rem",
      background: "transparent",
      border: "1px solid #AFAFAF",
      color: "white",
      fontFamily: "poppins",
    },
    md: {
      margin: "0 auto",
      width: "60px",
      height: "60px",
      fontSize: "3rem",
      marginRight: "1rem",
      background: "transparent",
      border: "1px solid #AFAFAF",
      color: "white",
      fontFamily: "poppins",
    },
    lg: {
      margin: "0 auto",
      width: "80px",
      height: "80px",
      fontSize: "3rem",
      marginRight: "1rem",
      background: "transparent",
      border: "1px solid #AFAFAF",
      color: "white",
      fontFamily: "poppins",
    },
  });

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
        >
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}>
              <p className={styles.auth_title}>Verification Code</p>
              <p className={styles.auth_subtitle}>
                We sent you a code on your email
              </p>
            </div>
            <div className={styles.OtpInputMain}>
              <OtpInput
                containerStyle={{ textAign: "center" }}
                className="otpfield"
                inputStyle={size}
                value={otpvalue}
                onChange={setOtpValue}
                numInputs={4}
                separator={" "}
              />
            </div>
          </div>

          <div className={styles.buttonControls}>
            {canContinue ? (
              <div className={styles.signin_box} onClick={HandelOTPChecker}>
                <p className={styles.connect_text}>
                  {loading ? (
                    <CircularProgress size={25} sx={{ color: "white" }} />
                  ) : (
                    "Continue"
                  )}
                </p>
              </div>
            ) : (
              <div className={styles.signin_box_inactive}>
                <p className={styles.connect_text}>Continue</p>
              </div>
            )}
            <p className={styles.previous_text} onClick={onBack}>
              Previous Step
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationCodeForm;
