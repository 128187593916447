import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: "25px",
  "& ul": {
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    gap: "30px",
    paddingLeft: "0px",
  },

  a: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    background: "transparent",
    border: "none",
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textDecoration: "none",
    "&.active": {
      color: "#2EBB55",
      "& img": {
        filter:
          "invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)",
      },
    },
  },

  [theme.breakpoints.down("md")]: {
    "& ul": {
      width: "100%",
      overflowX: "scroll",
      padding: "10px 0",
      margin: "0",
      whiteSpace: "nowrap",
      overflowX: "auto",
      WebkitOverflowScrolling: "touch",
    },
  },
}));

export { Main };
