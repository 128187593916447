/* eslint-disable key-spacing */
/* eslint-disable quote-props */
/* eslint-disable require-jsdoc */
/* eslint-disable quotes */
import React from 'react';
import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';

import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';

import classes from './Navbar.module.css';

import Notification from './Notification';
import useAuth from '../../context/AuthContext';

import { useMediaQuery } from '@material-ui/core';

import { Mixpanel } from '../../context/mixpanel/mixPanel';

import MembershipBadge from '../../components/smartlink/common/header/MembershipBadge';

// Imports Icons

import DropDownIcon from '../../assets/Icons/DropDownIconWhite.png';
import SettingIcon from '../../assets/Icons/settings.png';
import LogoutIcon from '../../assets/Icons/logout.png';

export default function PrimarySearchAppBar(props) {
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const {
    user,
    showBetaFeatures,
    setShowBetaFeatures,
    currentRoleType,
    setCurrentRoleType,
  } = useAuth();
  // const classes = useclasses();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isFreemium, userToken } = useAuth();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { open, setOpen } = props;
  const [chooseUserOpen, setChooseUserOpen] = useState(false);
  // const [showBetaFeatures, setShowBetaFeatures] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const roleTypes = [
    'Admin',
    'Artist',
    'A & R',
    'Label',
    'Manager',
    'Music Producer',
  ];

  const roleTypeMenuRef = useRef();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenuOption = (name, url, stateData = null) => {
    if (name.toLowerCase() === 'logout') {
      localStorage.clear();
      window.location.href = '/signin';
    } else {
      if (stateData) {
        navigate(url, { state: stateData });
      } else {
        navigate(url);
      }
    }
  };

  const handleProfile = () => {
    navigate(`/profile/${user?._id}`);
  };

  const handleSettings = () => {
    navigate('/settings');
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/signin';
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleToggle = () => {
    setShowBetaFeatures(!showBetaFeatures);
  };

  const menuRef = useRef();

  const toggleMenu = () => {
    Mixpanel.track(`Account Box Clicked`);
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
    if (
      roleTypeMenuRef.current &&
      !roleTypeMenuRef.current.contains(event.target)
    ) {
      setChooseUserOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label='show 4 new mails' color='inherit'>
          <Badge badgeContent={4} color='secondary'>
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div position='fixed' className={classes.appbar}>
      {/* <Notification /> */}
      {isDesktop ? (
        <div className={classes.logo_navbar}></div>
      ) : (
        <div>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
          >
            {/* <MenuIcon /> */}
            <img src='/svgs/menu.svg' alt='' />
          </IconButton>
          <img src='/svgs/logo.svg' alt='logo' />
        </div>
      )}

      <div className={classes.navbar_top_controls}>
        {user && user?.roleId?.roleName === 'Admin' && isDesktop ? (
          <div className={classes.admin_controls}>
            <div className={classes.switch_info_button}>
              <p className={classes.hide_info_text}>Show Beta Features</p>
              <div
                className={`${classes.switch} ${
                  showBetaFeatures ? classes.on : classes.off
                }`}
                onClick={handleToggle}
              >
                <div className={classes.slider}></div>
              </div>
            </div>
            <div className={classes.container_button} ref={roleTypeMenuRef}>
              <div
                className={`${classes.edit_role_button} ${
                  chooseUserOpen ? classes.dropdown_open : ''
                }`}
                onClick={() => setChooseUserOpen(!chooseUserOpen)}
              >
                <span className={classes.non_selectable_text}>
                  Viewing Platform As:
                </span>
                <div className={classes.edit_role_button_text}>
                  {currentRoleType}
                </div>
                <img
                  src='./DropDownIconWhite.png'
                  alt='dropdown'
                  className={`${classes.dropdown_icon} ${
                    chooseUserOpen ? classes.rotate_180 : ''
                  }`}
                ></img>
              </div>
              {chooseUserOpen && (
                <ul className={classes.dropdown_menu}>
                  {roleTypes.map((role, index) => (
                    <div
                      key={index}
                      className={classes.dropdown_menu_item}
                      onClick={() => {
                        setCurrentRoleType(role);
                        setChooseUserOpen(false);
                      }}
                    >
                      {role}
                    </div>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ) : (
          <div className={classes.left_spacing} />
        )}

        <div className={classes.container_button} ref={menuRef}>
          
         
          
          <Notification />
          {user?.roleId?.roleName !== 'Admin' && <MembershipBadge />}
          {user && (
            <div className={classes.account_navbar_item} onClick={toggleMenu}>
              <img
                src={
                  localStorage.getItem('profilePic')
                    ? localStorage.getItem('profilePic')
                    : '/DefaultAccountImage.png'
                }
                alt='profile'
                className={classes.account_pfp}
              ></img>
              <div className={classes.account_info}>
                <p className={classes.account_name}>
                  {localStorage.getItem('userName') &&
                  localStorage.getItem('userName') != null &&
                  localStorage.getItem('userName') != undefined &&
                  localStorage.getItem('userName') !== 'null'
                    ? localStorage.getItem('userName')
                    : user?.firstname && user?.lastname
                    ? user?.firstname + user?.lastname
                    : 'Guest'}
                </p>
                {user && (
                  <p className={classes.account_position}>
                    {' '}
                    {localStorage.getItem('accountType') || 'Guest'}
                  </p>
                )}
              </div>

              <img
                src={DropDownIcon}
                alt='dropdown.png'
                className={`${classes.dropdown_icon_icon} ${
                  showMenu ? classes.rotate_180 : ''
                }`}
              ></img>
            </div>
          )}
          {showMenu && (
            <div
              className={classes.menu}
              style={{
                width: `${
                  user && user?.roleId?.roleName === 'Admin' ? '100% ' : '50%'
                }`,
              }}
            >
              <div className={classes.menu_item} onClick={handleSettings}>
                <img
                  className={classes.account_menu_icon}
                  src={SettingIcon}
                  alt='SettingIcon.png'
                ></img>
                <p className={classes.menu_item_text}>Settings</p>
              </div>
              <div className={classes.menu_item} onClick={handleLogout}>
                <img
                  className={classes.account_menu_icon}
                  src={LogoutIcon}
                  alt='LogoutIcon.png'
                ></img>
                <p className={classes.menu_item_text}>Logout</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
