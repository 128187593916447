import React from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  MainTitle,
  PageTitle,
  CreateLink,
} from "../../../components/smartlink";

const CreateLinkPage = () => {
  return (
    <>
      <SmartLinkLayout>
        <MainTitle title="Smart Link" hasbutton={true} islinkpage={true} />
        <HorizontalMenu />
        <PageTitle NavigateTitle="Create Link" hasNavigate={true} />
        <CreateLink />
      </SmartLinkLayout>
    </>
  );
};

export default CreateLinkPage;
