import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "70vh",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  paddingBottom: "50px",
  "& .inbox_setting_main": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    padding: 0,
    h6: {
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      margin: "0px",
    },
    p: {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      margin: "0px",
    },
  },
  "& .inbox_setting_button": {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    button: {
      borderRadius: "5px",
      background: "#2EBB55",
      color: "#fff",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
      padding: "10.5px 30px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    "& .inbox_setting_button": {
      justifyContent: "center",
      paddingTop: "20px",
      button: {
        width: "100%",
      },
    },
  },
}));

const InboxesMain = styled(Box)(({ theme }) => ({
  margin: "15px 0",
  "& .Inboxes_item": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  "& .name_sec": {
    fontFamily: theme.typography.fontFamily,
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  "& .Inboxes_item_checked": {
    "& .is_checked_box": {
      border: "1px solid #2EBB55",
      "&:after": {
        content: "''",
        width: "14px",
        height: "14px",
        position: "absolute",
        left: "50%",
        top: "50%",
        background: "#2EBB55",
        borderRadius: "50%",
        transform: "translate(-50%, -50%)",
      },
    },
  },
  "& .is_checked_box": {
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    border: "1px solid #AFAFAF",
    position: "relative",
  },
  "& .Inboxes_item  ": {
    cursor: "pointer",
  },
}));
const SelectedBoxMain = styled(Box)(({ theme }) => ({
  h6: {
    fontFamily: theme.typography.fontFamily,
    color: "#E3E3E3",
    fontFamily: "Poppins",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0px",
    textAlign: "center",
  },

  "& .selected_main": {
    marginTop: "20px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: "10px",
    "& button:first-child": {
      padding: "0px 0px",
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textDecorationLine: "underline",
      background: "transparent",
    },
    button: {
      borderRadius: "5px",
      background: "#2EBB55",
      color: "#fff",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
      padding: "7px 18px",
    },
  },
}));

export { Main, InboxesMain, SelectedBoxMain };
