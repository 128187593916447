import React, { memo } from "react";
import { Main } from "./style";
import { Box } from "@mui/material";

const HeaderWidgets = ({
  buttons = [],
  activeButton = "",
  setActiveButton = () => {},
  title = "",
}) => {
  return (
    <>
      <Main>
        <Box className="header">
          <h6>{title}</h6>
          <div className="header_button">
            {buttons.map((buttonText, index) => (
              <button
                key={index}
                onClick={() => setActiveButton(buttonText)}
                className={activeButton === buttonText ? "active" : ""}
              >
                {buttonText}
              </button>
            ))}
          </div>
        </Box>
      </Main>
    </>
  );
};

export default memo(HeaderWidgets);
