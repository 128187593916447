import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ModelContentBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "& .header-icon-box": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
    "& .back-icon": {
      width: "24px",
      height: "24px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
    "& .close-icon": {
      width: "30px",
      height: "30px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
  "& h5": {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
    marginBottom: "40px",
  },
  "& .input-main": {
    textAlign: "start",
  },
  "& .btn-box": {
    textAlign: "right",
    marginTop: "40px",
    "& .btn": {
      borderRadius: "5px",
      border: "1px solid #AFAFAF",
      padding: "10px 30px",
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
      marginLeft: "24px",
    },
    "& .green": {
      background: "#2EBB55",
      color: "#FFF",
      border: "none",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      display: "flex",
      width: "100%",
      flexDirection: "column-reverse",
      gap: "10px",
      "& .btn": { marginLeft: "0" },
    },
  },

  "& .successfully-tick-box": {
    textAlign: "right",
    "& .close-icon": {
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
  "& .success-tickmark": {
    width: "100px",
    height: "100px",
  },
  h5: {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
    marginTop: "20px",
    marginBottom: "20px",
  },
  "& .submit-another-btn": {
    padding: "10.5px 30px",
    borderRadius: "5px",
    background: "#2EBB55",
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
  },

  "& .successfully_main": {
    "& .submit_another_padding": {
      paddingRight: "40px !important",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px !important",
      },
    },
    button: {
      width: "65%",
      "&:hover": {
        background: "#2EBB55",
      },
    },
    p: {
      textAlign: "center",
      color: "#FFFFFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "& .submit_another": {
      textAlign: "right",
      button: {
        marginTop: 30,
        width: "30%",
        fontSize: "10px",
        fontWeight: 500,
        padding: "10px 0px",
        minWidth: "auto",
      },
    },
  },

  "& .unsuccesfull_buttons": {
    button: {
      width: "65%",
      "&:last-child": {
        margin: "23px auto",
      },
      "&:hover": {
        background: "#2EBB55",
      },
    },
    hr: {
      width: "65%",
      backgroundColor: "#AFAFAF",
      margin: "23px auto",
    },
  },

  //......................BugsPopup styling start..........................//
  "& .bugpopup_header": {
    textAlign: "right",
    marginBottom: "35px",
    "& .close-icon": {
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
  "& .oops": {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
    marginTop: "13px",
  },
  "& .oops_desc": {
    color: "#AFAFAF",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "19px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  "& .oops_btn": {
    width: "100%",
    padding: "10px",
    color: "#FFFFFF",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    borderRadius: "5px",
    background: "#2EBB55 !important",
    textTransform: "math-auto",
  },
  //......................BugsPopup styling end..........................//

  //......................ReportBugPopup styling start..........................//
  "& .reportbug_header": {
    textAlign: "right",
    "& .close-icon": {
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
  "& .report_bug": {
    color: "#E3E3E3",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  "& .email_box": {
    textAlign: "left",
    input: {
      borderRadius: "5px",
      background: "#211D1E",
      border: "none",
      marginTop: "7px",
    },
  },
  "& .report_desc": {
    textAlign: "left",
    marginTop: "30px",
    marginBottom: "50px",
    DescriptionArea: {
      borderRadius: "5px",
      background: "#211D1E !important",
      height: "238px !important",
      border: "none",
    },
  },
  //......................ReportBugPopup styling end............................//

  [theme.breakpoints.between("xs", "sm")]: {
    padding: "20px",
    h5: {
      marginBottom: "21px",
      fontSize: "18px",
    },
    "& .unsuccesfull_buttons": {
      button: {
        width: "100%",
      },
      hr: {
        width: "100%",
      },
    },
    "& .successfully_main": {
      button: {
        width: "100%",
      },
      "& .submit_another": {
        button: {
          width: "100%",
        },
      },
    },
  },
}));
export { ModelContentBox };
