import { Grid, IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { stripeDirectCheckout } from "../../AdminAxiosInstance/payment.service";
import { close_icon } from "../../assets/smartlink";
import useAuth from "../../context/AuthContext";
import useAppState from "../../context/useAppState";
import style from "./checkout.module.css";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .matches(/^[a-zA-Z ]*$/, "Only alphabets and spaces are allowed")
    .min(2, "Name must be at least 2 characters"),
  number: Yup.string()
    .required("Card number is required")
    .matches(/^\d{16}$/, "Invalid card number"),
  expiry: Yup.string()
    .required("Expiration date is required")
    .matches(
      /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
      "Invalid expiration date (MM/YY format)"
    ),
  cvc: Yup.string()
    .required("CVV is required")
    .matches(/^\d{3,4}$/, "Invalid CVV"),
});

const BeatCardCheckoutModal = ({
  isOpen,
  handleClose,
  handleSuccess,
  handleError,
  price,
}) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  console.log("🚀 ~ BeatCardCheckoutModal ~ user:", user);

  const handleSubmit = async (values) => {
    // initiatePayment();

    setLoading(true);
    try {
      console.log(values);
      await stripeDirectCheckout({
        userId: user._id,
        paymentType: "SINGLE_TRACK",
        amount: parseInt(price),
        currency: "USD",
        source: "tok_visa",
        description: "Single Track Payment",
      });

      handleSuccess();
      // redirect to checkout url from stripe response
      // window.open(response.data?.stripe_checkout_url, "_blank");
    } catch (error) {
      console.log(error);
      handleError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={style.modal}>
        <Formik
          initialValues={{ name: "", number: "", expiry: "", cvc: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Box sx={{ position: "relative", width: "100%" }}>
                <Box
                  sx={{
                    position: "absolute",
                    right: "-20px",
                    top: "-40px",
                  }}
                >
                  <IconButton
                    onClick={handleClose}
                    aria-label="settings"
                    sx={{
                      color: "#fff",
                      backgroundColor: "none",
                      fontSize: "2rem",
                      padding: "0 !important",
                      margin: "0 !important",
                    }}
                  >
                    <img src={close_icon} alt="cancel-icon" />
                  </IconButton>
                </Box>

                <h1 className={style.modal_title3}>
                  Please add a payment method to start using United Market
                </h1>

                <Box
                  sx={{
                    height: "240px",
                    width: "260px",
                    backgroundColor: "#000000",
                    border: "1px dashed #424142",
                    borderRadius: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginX: "auto",
                  }}
                >
                  <Box sx={{ fontSize: "18px", fontWeight: "400" }}>
                    Add a card
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h1 className={style.modal_title2}>Card Detail</h1>
                  <h1 className={style.modal_value_green}>Add Card</h1>
                </Box>

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <h1 className={style.modal_price_year}>Name on Card</h1>
                    <Field
                      type="text"
                      name="name"
                      className={style.inputBoxText}
                      placeholder="Zuraid Zameer"
                    />
                    {errors.name && touched.name && (
                      <div className={style.error}>{errors.name}</div>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <h1 className={style.modal_price_year}>Card Number</h1>
                    <Field
                      type="text"
                      name="number"
                      className={style.inputBoxText}
                      placeholder="1253 5432 3521 3090"
                    />
                    {errors.number && touched.number && (
                      <div className={style.error}>{errors.number}</div>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <h1 className={style.modal_price_year}>Expiration Date</h1>
                    <Field
                      type="text"
                      name="expiry"
                      className={style.inputBoxText}
                      placeholder="09/27"
                    />
                    {errors.expiry && touched.expiry && (
                      <div className={style.error}>{errors.expiry}</div>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <h1 className={style.modal_price_year}>CVV</h1>
                    <Field
                      type="text"
                      name="cvc"
                      className={style.inputBoxText}
                      placeholder="341"
                    />
                    {errors.cvc && touched.cvc && (
                      <div className={style.error}>{errors.cvc}</div>
                    )}
                  </Grid>
                </Grid>
                <br />
                <br />

                <button type="submit" className={style.modal_button}>
                  {loading ? "Loading..." : "Continue"}
                </button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default BeatCardCheckoutModal;
