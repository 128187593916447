import React from "react";
import styles from "./error.module.css";
import errorImg from "../../assets/Images/Error.png";
const Error = () => {
  return (
    <div>
      <div className={styles.wrapperDiv}>
        <span className={styles.title}>Something went wrong</span>
        <span className={styles.subTitle}>Please try again</span>
        <div>
          <img className={styles.errorImg} src={errorImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Error;
