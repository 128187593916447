import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { MainLinkBox } from "./style";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { singer2 } from "../../../../assets/smartlink";
import { Link } from "react-router-dom";
import Timeago from "../../common/Timeago/Timeago";

const SingerData = [
  {
    id: 1,
    name: "MusicLinkPro-by komal Naaz",
    post_info: "Albumb by Kibriya Zameer . 5 hour ago",
    singer_pic: singer2,
  },
  {
    id: 2,
    name: "MusicLinkPro-by Mehvish",
    post_info: "Albumb by AP Dhilon . 5 hour ago",
    singer_pic: singer2,
  },
  {
    id: 3,
    name: "MusicLinkPro-by Sameer Bhola",
    post_info: "Albumb by Kibriya Zameer . 5 hour ago",
    singer_pic: singer2,
  },
];

const HorizontalCreatedLink = ({ smartLinks }) => {
  console.log(smartLinks, "Horizontal View");

  return (
    <>
      <MainLinkBox>
        {smartLinks &&
          smartLinks.map((item, ind) => (
            <Box key={item.id} className="link-box">
              <Box className="right-content">
                <img src={singer2} alt="singer-img" />
                <Box className="singer-info">
                  <Typography variant="h5">{item.title}</Typography>
                  <Typography
                    variant="h6"
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    Albumb by{" "}
                    {item?.artists && item.artists[0]?.name
                      ? item.artists[0].name
                      : "...."}{" "}
                    &nbsp;
                    <Timeago date={item.created} />
                  </Typography>
                </Box>
              </Box>
              <Box className="left_content">
                <MoreHorizIcon className="MoreHorizIcon" />
                <ul>
                  <li>
                    <Link to="/smart-link/insights">Insight</Link>
                  </li>
                  <li>
                    <Link to={`/smart-link/edit/${item.id}`}>Edit</Link>
                  </li>
                  <li>
                    <Link to="#">Export Fan</Link>
                  </li>
                  <li>
                    <Link to="#">Get</Link>
                  </li>
                </ul>
              </Box>
            </Box>
          ))}
      </MainLinkBox>
    </>
  );
};

export default HorizontalCreatedLink;
