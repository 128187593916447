import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { membership_bg } from "../../../../../assets/smartlink";

const Main = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  padding: "31px 79px",
  backgroundImage: `url("${membership_bg}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  overflowX: "hidden",
  position: "relative",
  zIndex: 1,
  "&:after": {
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, 0.50)",
    zIndex: "-1",
  },

  [theme.breakpoints.down("lg")]: {
    width: "95%",
  },

  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "1rem",
  },

  "& h4 ,& h5 ,& h6": {
    margin: 0,
  },
}));
const CenterBoxMain = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "40px 46px",
  borderRadius: "20px",
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.02) 100%)",
  backdropFilter: "blur(200px)",
  "& .main_grid": {
    "& .right": {
      "& .header-box": {
        display: "flex",
        alignItems: "center",
        gap: "17px",
        padding: "0.75rem 1.12rem",
        background: "#1C1B1B",
        borderRadius: "5px",
        "& .info": {
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
          "& h5": {
            color: "#FFF",
            fontFamily: theme.typography.fontFamily,
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            textTransform: "capitalize",
          },
          "& h6": {
            color: "#AFAFAF",
            fontFamily: theme.typography.fontFamily,
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textTransform: "capitalize",
          },
        },
        [theme.breakpoints.down("md")]: {
          marginTop: "20px",
        },
      },
      "& .submit-beat-main": {
        marginTop: "1.88rem",
        "& .submit-beat": {
          textAlign: "center",
          marginBottom: "40px",
          "& h5": {
            color: "#E3E3E3",
            fontFamily: theme.typography.fontFamily,
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textTransform: "capitalize",
          },
          "& h6": {
            color: "#AFAFAF",
            fontFamily: theme.typography.fontFamily,
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textTransform: "capitalize",
          },
        },
        "& .file-upload-main": {
          padding: "50px 40px",
          borderRadius: "0.625rem",
          border: "2px dashed #414241",
          background: "rgba(28, 27, 27, 0.50)",
          textAlign: "center",
          marginBottom: "40px",
          "& .upload-box": {
            cursor: "pointer",
            "& h5": {
              color: "#FFF",
              fontFamily: theme.typography.fontFamily,
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              textTransform: "capitalize",
            },
            "& h6": {
              color: "#AFAFAF",
              fontFamily: theme.typography.fontFamily,
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            },
            "& .music-info": {
              textAlign: "center",
              "& h5": {
                color: "#FFF",
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.h5,
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                textTransform: "capitalize",
                marginTop: "24px",
              },
              "& h6": {
                color: "#AFAFAF",
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.h6,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              },
            },
          },
          [theme.breakpoints.down("md")]: {
            marginBottom: "10px",
            padding: "30px 20px",
            "& h5": {
              fontSize: "18px !important",
            },
          },
        },
      },
      "& .form-row": {
        display: "flex",
        gap: "20px",
        "& .fields": {
          width: "100%",
        },
      },
      "& .form-row-last": {
        width: "48.5%",
      },
      [theme.breakpoints.down("md")]: {
        "& .form-row": {
          flexDirection: "column",
          gap: "0px",
        },
        "& .form-row-last": {
          width: "100%",
        },
      },
    },
  },
  "& .left": {
    "& .main-radio": {
      marginBottom: "24px",

      [theme.breakpoints.down("md")]: {
        marginBottom: "10px",
      },
    },
    "& .add-btn": {
      width: "400px",
      height: "48px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
      borderRadius: "5px",
      border: "1px dashed #AFAFAF",
      cursor: "pointer",
      marginBottom: "24px",
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      "& .add-icon": {
        width: "24px",
        height: "44px",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100%",
      },
    },
    "& .btn-box": {
      display: "flex",
      gap: "30px",
      marginTop: "30px",
      "& .btn": {
        padding: "10.5px 30px",
        color: "#AFAFAF",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        borderRadius: "5px",
        textTransform: "capitalize",
        border: "1px solid #AFAFAF",
        cursor: "pointer",
      },
      "& .btn-green": {
        background: "#2EBB55",
        color: "#fff",
        border: "none",
      },
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100%",
        flexDirection: "column-reverse",
        gap: "10px",
      },
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "35px !important",
    },
  },

  [theme.breakpoints.down("md")]: {
    "& .checkbox-box": {
      label: {
        marginRight: "0",
      },
      h5: {
        fontSize: "13px !important",
        svg: {
          fontSize: "16px !important",
        },
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 20px",
  },
}));

const MaincollaboratorsList = styled(Box)(({ theme }) => ({
  maxHeight: "100px",
  overflowY: "scroll",
  marginBottom: "2px",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#45a049",
  },
  "& .collaborators_item:last-child": {
    marginBottom: "10px",
  },
  "& .collaborators_item:first-child": {
    marginTop: "0px",
    paddingTop: "0px",
  },
  "& .collaborators_item": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "rgba(175, 175, 175, 1)",
    borderBottom: "1px solid rgba(65, 66, 65, 1)",
    padding: "8px 0px",
  },
  "& .collaborators_name": {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    [theme.breakpoints.up("md")]: {
      gap: "40px",
    },
    [theme.breakpoints.down("md")]: {
      gap: "20px",
    },
  },
}));

export { Main, CenterBoxMain, MaincollaboratorsList };
