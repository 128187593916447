import React, { Fragment, useEffect, useRef, useState } from 'react';
import styles from '../submissions.module.css';

import ConnectSubmissionCard from '../ConnectSubmissions/connectSubmissionCard';
import { Loader } from '../../OpportunitiesComponents/AdditionalComponents/Loader';
import useAppState from '../../../context/useAppState';

import { useDebounce } from '../../../hooks/useDebounce';
import { Box, Grid, Modal, Typography } from '@mui/material';
import { LOOKING_FOR } from '../../../shared/constants';

import useAuth from '../../../context/AuthContext';
import BeatInfoData from '../ViewSubmissionInformation/BeatInfoData';
import { useNavigate } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { PaginationMain } from '../../smartlink';
import MultiModal from '../../smartlink/common/MultiModal/MultiModal';
import { crown } from '../../../assets/smartlink';
import {
  ModelContentBox,
  Modelstyle,
  MultiButton,
} from '../../smartlink/common/MultiModal/style';
import { Heading } from 'native-base';
import SettingThumbIcon from '../../../assets/Icons/SettingThumb.png';

function OpportunitySubmissionsComponent() {
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState(LOOKING_FOR[0]);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState(LOOKING_FOR[0]);

  const { user } = useAuth();
  const navigate = useNavigate();

  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  // ============ custom pagination =====
  const [currentPage, setcurrentPage] = useState(1);
  const [TotalPages, setTotalPages] = useState(0);
  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };
  // ============ End custom pagination =====
  const [PaymentOpen, setPaymentOpen] = useState(false);
  const [remaingcounts, setremaingcounts] = useState({});

  const isPlanNameAvailable = user?.planId?.planName;

  const IsFree = 
    isPlanNameAvailable === 'Free' || isPlanNameAvailable === 'Freemium';

  // useEffect(() => {
  //   if (
  //     (user && get(user, "roleId.roleName", "") !== ROLES.MUSIC_PRODUCER) ||
  //     ROLES.ADMIN
  //   ) {
  //     navigate("/opportunities");
  //   }
  // }, [user]);

  const searchQuery = useDebounce(query, 500);

  const { fetchMySubmission } = useAppState('submission');
  const PAGE_LIMIT = 10;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setPage(0);
    setcurrentPage(1);
  }, [type]);

  useEffect(() => {
      setLoading(true);
      const queryString = new URLSearchParams({
        page: currentPage,
        limit: PAGE_LIMIT,
        type: type,
        search: searchQuery,
        sort: sortBy,
      }).toString();

      fetchMySubmission(queryString)
        .then((response) => {
          if (response.status === 1) {
            const newRows = response.data;
            setTotalPages(response?.totalPages || 0);
            setHasNextPage(newRows.length < response.totalCount);
            setPage((page) => page + 1);
            setData(newRows);
            console.log("submissionsCount",response?.submissionsCount , response?.totalCount)
            setremaingcounts({
              submissionsCount: response?.submissionsCount,
              totalCount: response?.totalCount,
            });
          } else {
            setData([]);
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [searchQuery, sortBy, type, currentPage]);

  useEffect(() => {
    const tabEl = [...tabsRef.current.children].find(
      (child) => child.textContent === activeTab
    );
    const { offsetLeft, offsetWidth } = tabEl;
    underlineRef.current.style.left = `${offsetLeft}px`;
    underlineRef.current.style.width = `${offsetWidth}px`;
  }, [activeTab]);

  const getMoreData = async () => {
    try {
      const newPage = page + 1;
      const queryString = new URLSearchParams({
        page: newPage,
        limit: PAGE_LIMIT,
        type: type,
        search: searchQuery,
        sort: sortBy,
      }).toString();
      fetchMySubmission(queryString)
        .then((response) => {
          const newRows = [...data, ...response.data];

          console.info("newRows => ", newRows);

          setHasNextPage(newRows.length < response.totalCount);
          setData(newRows);
          setPage((page) => page + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setError(err);
      // console.log("error on fetch more...", err);
    }
  };

  const fetchMore = () => {
    console.log("fetch more...");
    getMoreData();
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  // const ShowBlurClass =
  //   !loading &&
  //   user.roleId.roleName === 'Music Producer' &&
    // (isPlanNameAvailable === 'Free' || isPlanNameAvailable === 'Freemium');

  const ShowBlurClass =
  !loading && IsFree && user.roleId.roleName == 'Music Producer'  &&

  data?.length > 0 &&
  remaingcounts?.submissionsCount === 3;

  useEffect(() => {
    if (ShowBlurClass) {
      setPaymentOpen(true);
    }
  }, [ShowBlurClass]);
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.main_layout}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className={styles.page_title}>Submissions</p>

        {!loading && (
          <div
            className={`${styles.RemainingLinks}`}
            style={{ color: '#f00', fontSize: '18px', fontWeight: 500 }}
          >
            {IsFree && user.roleId.roleName == 'Music Producer'  &&
            (remaingcounts?.totalCount !== 'unlimited' ||
              remaingcounts?.submissionsCount !== 'unlimited')
              ? `Remaining Submissions: ${
                  parseInt(3) -
                  parseInt(remaingcounts?.submissionsCount)
                }`
              : null}
          </div>
        )}
      </div>
      {/* <Box className={`${styles.buttonGroup} ${styles.buttonsWrapper}`}>
          <Button
            className={`${styles.filterActive} ${anchorEl && styles.filterActive
              }`}
            onClick={handleClick}
          >
            <Setting />
          </Button>
          <StyledMenu
            id="customized-menu1"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {LOOKING_FOR.map((lookingFor, index) => (
              <StyledMenuItem
                key={index}
                className={type === lookingFor ? "active" : ""}
              >
                <ListItemText
                  primary={lookingFor}
                  onClick={(e) => {
                    setType(type === lookingFor ? "" : lookingFor)
                    handleClose()
                  }}
                  style={{ textAlign: "center" }}
                />
              </StyledMenuItem>
            ))}
          </StyledMenu>
        </Box> */}

      <Box>
        <Box sx={{ padding: '15px 0px 10px 0' }}>
          <div className={styles.search_box}>
            <img
              className={styles.search_icon}
              src='/v2Assets/connect_assets/Search.png'
              alt='search'
            ></img>
            <input
              className={styles.search_box_input}
              type='text'
              disabled={true}
              placeholder={'Search'}
              onChange={(data) => {
                setQuery(data.target.value);
              }}
            />
          </div>
        </Box>
      </Box>

      <div className={styles.tabs} ref={tabsRef}>
        {LOOKING_FOR.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => {
              setActiveTab(tab);
              setType(type === tab ? '' : tab);
            }}
          >
            {tab}
          </div>
        ))}
        <div className={styles.underline} ref={underlineRef} />
      </div>

      <div
        className={`${styles.tab_layout} ${
          ShowBlurClass && styles.tab_blurmain
        }`}
      >
        {!loading ? (
          <Fragment>
            {data?.length > 0 ? (
              <>
                {data.map((o, key) => (
                  <Fragment key={key}>
                    <ConnectSubmissionCard
                      onView={() => {}}
                      isShowBeatDetail={true}
                      data={o}
                      isShowOpportunityName={true}
                      canSelect={false}
                      beatInfoBtn={<BeatInfoData beatData={o} />}
                    />
                  </Fragment>
                ))}
              </>
            ) : (
              // <Box
              //   width={'100%'}
              //   id={'maintance_Modal'}
              //   bgcolor={'#ffffff10'}
              //   height={'60vh'}
              //   display={'flex'}
              //   justifyContent={'center'}
              //   alignItems={'center'}
              // >
              //   <Box
              //     bgcolor={'#141515'}
              //     width={'30%'}
              //     padding={'40px'}
              //     borderRadius={'10px'}
              //     display={'flex'}
              //     flexDirection={'column'}
              //     alignItems={'center'}
              //   >
              //     <img src={SettingThumbIcon} height={'48px'} width={'48px'} />

              //     <p
              //       style={{
              //         fontFamily: 'Poppins',
              //         fontWeight: 500,
              //         fontSize: '18px',
              //         color: '#fff',
              //         lineHeight: '27px',
              //         textAlign: 'center',
              //       }}
              //     >
              //       We are under maintenance
              //     </p>
              //     <p
              //       style={{
              //         fontFamily: 'Poppins',
              //         fontWeight: 400,
              //         fontSize: '14px',
              //         color: '#fff',
              //         lineHeight: '20px',
              //         textAlign: 'center',
              //       }}
              //     >
              //       All your submissions have been safely delivered. We are
              //       currently updating this area and you will be able to view
              //       your submissions soon.🙂
              //     </p>
              //   </Box>
              // </Box>
              !loading && (
                <>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#fff',
                      alignItems: 'center',
                    }}
                  >
                    <p classes={styles.empty_page_text}>No Data Found</p>
                  </Box>
                </>
              )
            )}
          </Fragment>
        ) : (
          <Loader />
        )}

        {(loading || hasNextPage) && (
          <Grid container justifyContent={"center"} ref={sentryRef}>
            <Grid item>
              <Typography style={{ color: "#2ebb55" }}>Loading..</Typography>
            </Grid>
          </Grid>
        )}
      </div>
      {!loading && TotalPages > 1 && (
        <>
          <Box sx={{ marginBottom: '1rem' }}>
            <PaginationMain
              pageCount={TotalPages}
              page={currentPage}
              handelChange={handlePageChange}
            />
          </Box>
        </>
      )}

      {/*  upgrade your membership model*/}

      <Modal
        open={open}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
        container={() => document.getElementById('maintance_Modal')}
      >
        <ModelContentBox
          sx={{
            ...Modelstyle,
            width: { xs: '100%', sm: '675px' },
            padding: '80px',
            borderRadius: '0.625rem',
            background: '#141515',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '40px',
            position: 'relative',
          }}
        >
          <div>
            <h2 id='modal-title'>Modal Title</h2>
            <p id='modal-description'>Modal Content</p>
          </div>
        </ModelContentBox>
      </Modal>
      <MultiModal
        open={PaymentOpen}
        heading='Upgrade Your Plan'
        text='Upgrade your membership to track your audio files.'
        icon={crown}
        type='link'
        title='Upgrade'
        RedirectTo='/beat-inbox/more-storage?type=opportunities'
        setPaymentOpen={setPaymentOpen}
      />
    </div>
  );
}

export default OpportunitySubmissionsComponent;
