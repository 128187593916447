import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: "#1c1b1b",
  },
  tooltip: {
    backgroundColor: "#1C1B1B",
    color: "#AFAFAF",
    fontSize: 14,
  },
}));

function DarkTooltip(props) {
  const classes = useStyles();
  return (
    <Tooltip arrow classes={classes} {...props}>
      {props.children}
    </Tooltip>
  );
}

export default DarkTooltip;
