import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import TitleDetaisWidgets from "../Distribution/widgets/TitleDetaisWidgets";
import { FeactureSec } from "./styles/Feacturestyle";
import { Screen1, Screen2, Screen3, Screen4 } from "../../assets/smartlink";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

let FeatureBar = [
  "Opportunities",
  "Collaboration",
  "Web 3 Royalty Protection",
  "Audio File Storage + Protection",
];

let FeatureData = [
  {
    title: "Opportunities",
    img: Screen1,
    heading: "Quit wasting time on folder & duplicates",
    list: [
      "We auto-detect your bpm + key on every upload",
      "Easily search your files by tag, key & bpm",
      "Build packs without ever duplicating your files",
    ],
  },
  {
    title: "Collaboration",
    img: Screen2,
    heading: "Quit wasting time on folder & duplicates",
    list: [
      "We auto-detect your bpm + key on every upload",
      "Easily search your files by tag, key & bpm",
      "Build packs without ever duplicating your files",
    ],
  },
  {
    title: "Web 3 Royalty Protection",
    img: Screen3,
    heading: "Quit wasting time on folder & duplicates",
    list: [
      "We auto-detect your bpm + key on every upload",
      "Easily search your files by tag, key & bpm",
      "Build packs without ever duplicating your files",
    ],
  },
  {
    title: "Audio File Storage + Protection",
    img: Screen4,
    heading: "Quit wasting time on folder & duplicates",
    list: [
      "We auto-detect your bpm + key on every upload",
      "Easily search your files by tag, key & bpm",
      "Build packs without ever duplicating your files",
    ],
  },
];

const Features = () => {
  const [selectedFeature, setSelectedFeature] = useState(0);

  const handleFeatureClick = (index) => {
    setSelectedFeature(index);
  };

  return (
    <div id="#Feature">
      <TitleDetaisWidgets
        title="Features"
        details="We make it easy for you to focus on your music career and collaborations while we take care of the business side by protecting your compositions."
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <FeactureSec>
          <Grid container>
            <Grid item xs={12} md={6} className="FeactureConentBox">
              <Box sx={{ width: "100%" }}>
                <ul className="FeatureListbar">
                  {FeatureBar.map((item, index) => (
                    <li
                      className="ListItem"
                      style={{
                        color: `${index == selectedFeature ? "#FFF" : ""}`,
                        // fontWeight: `${index == selectedFeature ? "500" : ""}`,
                      }}
                      key={index}
                      onClick={() => handleFeatureClick(index)}
                    >
                      {item}
                      <div
                        className={`${
                          index == selectedFeature ? "UnderLine" : ""
                        }`}
                      ></div>
                    </li>
                  ))}
                </ul>
                <h1 className="FeatureHead">
                  {FeatureData[selectedFeature]?.heading}
                </h1>
                {FeatureData[selectedFeature]?.list.map((item, index) => (
                  <p className="FeatureListbox" key={index}>
                    <CheckCircleIcon className="Checkicon" />
                    <span>{item}</span>
                  </p>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                className="featureLaptop"
                src={FeatureData[selectedFeature]?.img}
                alt={`Feature ${selectedFeature + 1}`}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </Grid>
          </Grid>
        </FeactureSec>
      </div>
    </div>
  );
};

export default Features;
