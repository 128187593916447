import axios from "axios";

const userData = JSON.parse(localStorage.getItem("userData"));
const { token } = userData ? userData : "";

const adminAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_OPPORTUNITY_BACKEND_URL}`,
  // timeout: 5000,
  headers: { token: token ? token : "" },
});

export default adminAxiosInstance;