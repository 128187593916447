import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Wrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  h6: {
    margin: 0,
    marginBottom: 25,
    span: {
      color: "#FFF",
      fontFamily: '"Poppins", sans-serif',
      fontSize: "56px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "70px",
      position: "relative",
      "&:after": {
        content: "''",
        width: "4px",
        position: "absolute",
        left: "-30px",
        top: 0,
        background: "#2EBB55",
        height: "100%",
      },
    },
  },
  p: {
    color: "#AFAFAF",
    textAlign: "center",
    fontFamily: '"Poppins", sans-serif',
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    paddingBottom: "80px",
    width: "41%",
    margin: "0 auto",
  },
  [theme.breakpoints.down("md")]: {
    p: {
      width: "90%",
    },
    h6: {
      span: {
        fontSize: "32px !important",
        lineHeight: "42px !important",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    p: {
      width: "100%",
    },
  },
}));

export { Wrapper };
