import React, { useState } from "react";
import { MainBox, InboxContentBox } from "./style";
import { Box, Button, Typography } from "@mui/material";
import {
  messageremove,
  gray_copy_icon,
  image_placeholder_icon,
} from "../../../../assets/smartlink";

import CreateBeatInboxPopup from "../createbeatinbox/CreateBeatInboxPopup";
// import linkIcon from "../../../assets/images/copylink.svg";
// import CreateLinkModel from "../../../components-old/share/models/creatLinkModel/CreateLinkModel";
// import ImgFrameIcon from "../../../assets/images/Frame 239012.svg";
const BeatEmpty = ({ text = "", desc = "" }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <MainBox>
        <InboxContentBox>
          <Box className="icon-box">
            <img src={messageremove} alt="message-remove" />
          </Box>
          <Typography variant="h4">
            {text ? text : "Your inbox is empty"}
          </Typography>
          <Typography variant="h5">
            {desc
              ? desc
              : "Share your inbox link with your network to receive files here"}
          </Typography>
          <Box>
            <Button
              variant="contained"
              className="btn"
              sx={{
                background: "rgba(46, 187, 85, 1) !important",
                color: "#fff !important",
              }}
              onClick={() => setOpen(true)}
            >
              {/* <img src={gray_copy_icon} alt="link-icon" /> */}
              Create Inbox
            </Button>
          </Box>
        </InboxContentBox>
      </MainBox>
      <CreateBeatInboxPopup
        open={open}
        setOpen={setOpen}
        image={image_placeholder_icon}
      />
    </>
  );
};

export default BeatEmpty;
