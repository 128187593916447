import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const MainReportBox = styled(Box)(({ theme, height, width }) => ({
  width: "100%",
  background: "#0D120F",
  borderRadius: "5px",
  padding: "40px 37px",
  "& .header-main": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    h4: {
      color: "#FFFFFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
    "& .selecter-box": {
      display: "flex",
      gap: "24px",
      "& .right": {
        // width: "500px",
        height: "auto",
      },
    },
  },
  "& .header": {
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "capitalize",
    borderBottom: " 1px solid #414241",
  },
  "& .data": {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    borderTop: " 1px solid #414241",
    borderBottom: "none",

    "& .info-box": {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      "& .img-box": {
        displayL: "flex",
        alignItems: "center",
        width: "48px",
        height: "48px",
        "& img": {
          objectFit: "cover",
          width: "100%",
          height: "100%",
          borderRadius: "3px",
        },
      },
    },
    "& .name-box": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "& h5": {
        color: "#FFF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        margin: 0,
        marginTop: "5px",
        textTransform: "capitalize",
      },
      "& p": {
        color: "#AFAFAF",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginTop: "5px",
        textTransform: "capitalize",
      },
    },
  },
}));

export { MainReportBox };
