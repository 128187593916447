import { memo, useState } from "react";
import DeleteLinkForm from "./deleteForm";
import DuplicateDraftForm from "./duplicateForm/Index";
import EvaluationForm from "./evaluationForm/Index";
import FallbackForm from "./fallbackForm";
import LinkSourceForm from "./linkSourceForm/Index";
import { EvaluationWrapper } from "./style";
import ReleasePageModal from "../../commons/modal/ReleasePageModal";
import ModalHeader from "../../commons/modal/ModalHeader";
import LoadingContent from "../../commons/modal/modalContent/LoadingContent";
import ReleaseDetails from "../../commons/modal/modalContent/ReleaseDetails";

const Evaluation = ({ BaseData = null }) => {
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [CreatedLinkData, setCreatedLinkData] = useState({});

  return (
    <EvaluationWrapper>
      {/* Loading details modal */}
      <ReleasePageModal
        handleModalOpen={(value) => setIsLoadingModalOpen(value)}
        open={isLoadingModalOpen}
      >
        <ModalHeader
          handleModalOpen={(value) => {
            setIsLoadingModalOpen(value);
            setIsModalOpen(!value);
          }}
        />
        <LoadingContent />
      </ReleasePageModal>

      {/* Release details modal */}
      <ReleasePageModal
        handleModalOpen={(value) => setIsModalOpen(value)}
        open={isModalOpen}
        background="transparent"
      >
        <ReleaseDetails CreatedLinkData={CreatedLinkData} />
      </ReleasePageModal>

      <EvaluationForm
        BaseData={BaseData}
        handleModalOpen={(value) => setIsLoadingModalOpen(value)}
        shareModelOpen={(value) => setIsModalOpen(value)}
        setCreatedLinkData={(value) => setCreatedLinkData(value)}
      />
      {/* <LinkSourceForm /> */}
      {/* <FallbackForm /> */}
      {/* <DuplicateDraftForm /> */}
      {/* <DeleteLinkForm /> */}
    </EvaluationWrapper>
  );
};

export default memo(Evaluation);
