import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { ModelContentBox, Modelstyle, EditCoverButton } from "./style";
import useAuth from "../../../../context/AuthContext";

import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MassEditModal from "./MassEditModal";
import styles from "../Libraries.module.css";
import { get } from "lodash";
import { useDropzone } from "react-dropzone";
import useAppState from "../../../../context/useAppState";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LibrariesSchema from "../../YupSchema/LibrariesSchema";
import { toast } from "react-toastify";
import universalStyles from "../../../UniversalComponents/universal.module.css";
import BlueButtonComponents from "../../AdditionalComponents/utils/BlueButtonComponent";
import { ChangeHistoryOutlined } from "@material-ui/icons";
import EditableText from "../EditableText";
import AnalyzeModal from "./AnalyzeModal";
import UploadField from "../../AdditionalComponents/utils/UploadFiled";
import { useLibrary } from "../../../../context/LibraryContext";
import { DEFAULT_VALUE } from "../../../../shared/constants";
import { TrashRed, radio_check, radio_uncheck } from "../../../../assets/smartlink";
import LinearProgress from '@mui/material/LinearProgress';
import { feedAudioFilesByQuery } from "../../../../context/apis/audio-library";
import NoItemComponents from "../../AdditionalComponents/utils/NoItemComponent";
import CircularLoaderPage from "../../../UniversalComponents/CircularLoader/CircularLoaderPage";
import { listReducer } from "@mui/base/useList";


const AudioModal = ({
  openModal = false,
  setOpenModal = () => { },
  fromLibrary = false,
  setFromLibrary,
  selectedList,
  setSelectedList

}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [massEditModalOpen, setMassEditModalOpen] = useState(false);
  const [analyzeModal, setAnalyzeModal] = useState(false);

  const [coverTitle, setCoverTitle] = useState("");
  const [coverDescription, setCoverDescription] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [libraryData, setLibraryData] = useState([]);




  const [query, setQuery] = useState("");



  useEffect(() => {
    setLoading(true);
    const queryString = new URLSearchParams({
      userId: user._id,
      fileName: query,
    }).toString();

    feedAudioFilesByQuery(queryString)
      .then((response) => {
        if (response?.data) {
          const newRows = response.data || [];
          console.log("audioData= ",newRows);
          const completeAudioData = newRows.filter(row=>row.isComplete)
          console.log("completeAudioData= ",completeAudioData);
          setLibraryData(completeAudioData);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [openModal, query]);


  const handleModalClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenModal(false);
    setSelectedList([]);
  };

  useEffect(() => {
    console.log("HERE");
    console.log(selectedList);
  }, [selectedList]);

  const handleClick = (index, item) => {

    if (libraryData[index]?.checked == undefined || libraryData[index]?.checked == false) {
      libraryData[index].checked = true;
      setSelectedList([...selectedList, item]);
    } else {
      libraryData[index].checked = false;
      const tempData = selectedList.filter((objA) => objA._id != item._id);
      setSelectedList(tempData);
    }
    setLibraryData([...libraryData]);
  }

  const fetchAndConvertToFile = async (filePath) => {
    const baseUrl = `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/`;
    const absoluteUrl = new URL(filePath, baseUrl);
    try {
      const response = await fetch(absoluteUrl.href);
      const blob = await response.blob();
      const filename = filePath.split('\\').pop().split('/').pop(); // Correctly splitting the path
      const file = new File([blob], filename, { type: blob.type });
      return file;
    } catch (error) {
      console.error('Error fetching file:', error);
    }
  };

  const handleSelect = async() => {
    const fileArray = [];
    if (selectedList.length > 0) {
      const filePromises = selectedList.map((item) => fetchAndConvertToFile(item.basicInfo?.fileURL));
      const files = await Promise.all(filePromises);
      files.forEach(file => fileArray.push(file));

      console.log("=====", fileArray);
      console.log("=====", fileArray.length);
    }
    setOpenModal(false);
    setFromLibrary(true);
  }

    const [audioFiles, setAudioFiles] = useState([]);

    return (
      <>
        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <ModelContentBox
            sx={{
              ...Modelstyle,
              width: { xs: "100%", sm: "800px" },
              height: { xs: "850px", sm: "850px" },
              padding: "20px",
              paddingTop: "40px",
              borderRadius: "0.625rem",
              background: "#141515",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                textAlign: "right",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                width: "100%",
                position: "absolute",
                top: "15px",
                right: "5%",
              }}
            >
              <IconButton
                onClick={handleModalClose}
                aria-label="settings"
                sx={{
                  color: "#fff",
                  //   color: theme.palette.primary.dark,
                  backgroundColor: "none",
                  fontSize: "2rem",
                  padding: "0 !important",
                  margin: "0 !important",
                  marginTop: "7px !important",
                  svg: {
                    color: "#fff",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            {/* Audio Cover Field Start*/}
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px, 15px",
                marginTop: "20px",
                gap: "20px",
              }}
            >
              <div className={styles.audio_modal_title}>My Audio Library</div>
              <div className={universalStyles.search_box_in_lib} style={{ background: "#414241", width: "100%" }}>
                <img
                  className={universalStyles.search_icon}
                  src="/v2Assets/connect_assets/Search.png"
                  alt="search"
                ></img>
                <input
                  className={universalStyles.search_box_input}
                  type="text"

                  placeholder={"Search in Library"}
                  onChange={(e) => {
                    // setQuery(data.target.value);
                    setQuery(e.target.value.toLowerCase());
                  }}
                />
              </div>
              <div className={styles.audio_card_container}>
                {!loading ? (libraryData.length > 0 ?
                  (libraryData.map((item, index) => {
                    const audio_image =
                      item?.basicInfo?.imageURL ?
                        (`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${item?.basicInfo?.imageURL}`.replace(/\\/g, '/').replace(/�/g, '')) :
                        "/images/no_image.png";
                    return (
                      <div className={styles.audio_card}>
                        <div className={styles.name_image_part}>
                          <div className={styles.audio_card_left}>
                            <img src={audio_image} width={64} height={64} />
                            <div className={styles.name_note}>
                              <div className={styles.audio_card_name}>{item?.basicInfo?.fileName}</div>
                              <div className={styles.audio_card_note}>{item?.basicInfo?.fileNote}</div>
                            </div>
                          </div>
                          <div className={styles.audio_card_right}>
                            <div className={styles.card_number}>UM File ID #{item._id.slice(-6)}</div>
                            <div onClick={() => handleClick(index, item)}><img src={!item?.checked ? radio_uncheck : radio_check} /></div>
                          </div>
                        </div>
                        <div className={styles.tag_part}>
                          {item.basicInfo.tags.map((tag, index) => (
                            <div className={styles.tag_item}>{tag.label}</div>
                          ))}
                        </div>
                      </div>)
                  })
                  ) : (<NoItemComponents src="/images/no_image.png" text="No Audio Library" />)) : (<div>
                    <CircularLoaderPage />
                  </div>)}

              </div>
              <div className={styles.button_container}>
                <BlueButtonComponents text="Select" onClickFunction={() => handleSelect()} />

              </div>
            </Box>

          </ModelContentBox>
        </Modal>

      </>
    );
  };

  export default AudioModal;
