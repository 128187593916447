import React, { useEffect, useState } from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  BeatStorage,
  CreateBeatInboxPopup,
  HorizontalMenu,
  MainTitle,
} from "../../../components/smartlink";
import { image_placeholder_icon } from "../../../assets/smartlink";
import { GetAllPricingPlan } from "../../../Graphql_services/smartlink/SmartLink";
import { useQuery } from "@apollo/client";
import useAuth from "../../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";

const BeatStoragePage = () => {
  const location = useLocation();
  const fullUrl = location.pathname + location.search;
  const params = new URLSearchParams(fullUrl?.split("?")[1]);
  const type = params.get("type");

  const navigate = useNavigate();
  const { user } = useAuth();
  const IsAdmin = user?.roleId?.roleName;
  const [planType, setPlantype] = useState("month");
  const [open, setOpen] = useState(false);
  const createModelOpen = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(!open);
  };
  const { loading, error, data, refetch } = useQuery(GetAllPricingPlan, {
    variables: { interval: planType },
    context: { clientName: "smartlink" },
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (
      IsAdmin === "Admin" ||
      IsAdmin === "A & R" ||
      IsAdmin === "Label" ||
      IsAdmin === "Manager"
    ) {
      navigate("/opportunities");
    }
  }, [IsAdmin]);

  return (
    <>
      <SmartLinkLayout>
        <MainTitle
          title={type == "opportunities" ? "Submissions" : "Beat Inbox"}
          hasbutton={type == "opportunities" ? false : true}
          isBeatinbox={type == "opportunities" ? false : true}
          openCreateBeatPopup={createModelOpen}
        />

        {type !== "opportunities" && <HorizontalMenu isBeatInbox={true} />}
        {loading && <p style={{ color: "#fff" }}>Loading...</p>}
        {!loading && <BeatStorage setPlantype={setPlantype} data={data} />}
        <CreateBeatInboxPopup
          open={open}
          setOpen={setOpen}
          image={image_placeholder_icon}
        />
      </SmartLinkLayout>
    </>
  );
};

export default BeatStoragePage;
