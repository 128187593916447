import React, { useEffect } from 'react';
import styles from '../../auth_pages.module.css';
import { useState } from 'react';
// import useAuth from "../../../context/AuthContext";
import axios from 'axios';
import { FormField } from '../FormInput';

const FirstLastDisplayName = ({ onBack, onNext, user, completeAccount }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [displayName, setDisplayName] = useState('');

  const canContinue = firstName !== '' && lastName !== '' && displayName !== '';

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
        >
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}>
              <p className={styles.auth_title}> Enter Your Name</p>
              <p className={styles.auth_subtitle}>What should we call you?</p>
            </div>
            <div className={styles.input_list}>
              <FormField
                title='First Name'
                onValueChange={(value) => setFirstName(value)}
                dropdown={false}
                content={firstName}
              />
              <FormField
                title='Last Name'
                onValueChange={(value) => setLastName(value)}
                dropdown={false}
                content={lastName}
              />
              <FormField
                title='Display Name'
                onValueChange={(value) => setDisplayName(value)}
                dropdown={false}
                content={displayName}
              />
            </div>
          </div>

          <div className={styles.buttonControls}>
            {canContinue ? (
              <div
                onClick={() => {
                  let updatedUser = { ...user };
                  let updatedProfile = { ...user.profile };
                  updatedProfile.name = {
                    firstName: firstName,
                    lastName: lastName,
                  };
                  updatedProfile.userName = displayName;
                  updatedUser.profile = updatedProfile;
                  onNext(updatedUser);
                }}
                className={styles.signin_box}
              >
                <p className={styles.connect_text}>Continue</p>
              </div>
            ) : (
              <div className={styles.signin_box_inactive}>
                <p className={styles.connect_text}>Continue</p>
              </div>
            )}
            <p className={styles.previous_text} onClick={onBack}>
              Previous Step
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstLastDisplayName;
