import { Box, Button, Popover } from "@mui/material";
import React, { useState } from "react";
import { HeaderMain, UlMain } from "./style";
import { Link } from "react-router-dom";
import {
  whitearrowleft,
  import_icon,
  filter_setting,
  refresh_double_icon,
} from "../../../../assets/smartlink";
import JSZip from "jszip";
import { Notification } from "../../../NotifyMsg/Notification";
import FilterListIcon from "@mui/icons-material/FilterList";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AddCollaborator from "../../../../assets/smartlink/icons/AddCollaborator";

const InboxBeatsHeader = ({
  totalBeat,
  data = [],
  Sethasstatus = () => {},
  Sethassort = () => {},
  Name = "",
  isloading = false,
  refetch = () => {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [newanchorEl, newsetanchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const OtherhandleClick = (event) => {
    newsetanchorEl(event.currentTarget);
  };

  const OtherhandleClose = () => {
    newsetanchorEl(null);
  };

  const open = Boolean(anchorEl);
  const Otheropen = Boolean(newanchorEl);
  const id = open ? "simple-popover" : undefined;
  const Otherid = Otheropen ? "simple-popover" : undefined;

  const SingleBeatDownload = async ({}) => {
    Notification("info", "Download has started. It may take a moment to finish. You can continue using the app.");
    const zip = new JSZip();
    if (data?.length > 0) {
      const audioFiles = data?.map((value) => ({
        name: value._id + value.audioFileId?.fileName,
        url: value.audioFileId?.url,
      }));
      // const audioFiles = [
      //   // { name: audioFileId?.fileName, url: audioFileId?.url },
      // ];
      const addAudioToZip = async (audioFile) => {
        try {
          const response = await fetch(audioFile.url);
          if (!response.ok) {
            console.error(`Failed to fetch ${audioFile.url}: ${response.statusText}`);
            return; 
          }
          const audioBlob = await response.blob();
          zip.file(audioFile.name, audioBlob);
        } catch (error) {
          console.error(`Error fetching ${audioFile.url}:`, error);
        }
      };
      const addAudioPromises = audioFiles.map(addAudioToZip);

      await Promise.all(addAudioPromises);
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(zipBlob);
      downloadLink.download = "audio_files.zip";
      downloadLink.onclick = () => {
        Notification("success", "Download complete!");
      };
      console.log("download completed================")
      downloadLink.click();
    }
  };

  // download one by one

  // const SingleBeatDownload = async () => {
  //   if (data?.length > 0) {
  //     const downloadPromises = data.map(async (value) => {
  //       if (value.audioFileId?.url) {
  //         const response = await fetch(value.audioFileId.url);
  //         const audioBlob = await response.blob();

  //         const downloadLink = document.createElement("a");
  //         downloadLink.href = URL.createObjectURL(audioBlob);
  //         downloadLink.download = value.audioFileId.fileName || "audio_file";
  //         downloadLink.click();
  //       }
  //     });

  //     await Promise.all(downloadPromises);

  //     Notification("success", "Downloads complete!");
  //   } else {
  //     Notification("error", "No audio files to download.");
  //   }
  // };

  const hassortHandler = (value) => {
    Sethassort(value);
    OtherhandleClose();
  };

  const handelFilter = (value) => {
    setAnchorEl(null);
    Sethasstatus(value);
  };
  const handleRefreshSingleBeat = () => {
    refetch();
  };
  return (
    <>
      <HeaderMain>
        <Box className="header_left">
          <Link to={-1}>
            <img src={whitearrowleft} alt="whitearrowleft" />{" "}
            {Name ? Name : "BeatBox"}
          </Link>
        </Box>
        <Box className="header_right">
          <Button className="refresh-btn" onClick={handleRefreshSingleBeat}>
            <img
              className={`${isloading ? "IsRotate" : ""}`}
              src={refresh_double_icon}
              alt="refresh-icon"
            />
          </Button>

          <Button
            className="total_beat"
            disabled={data?.length === 0}
            onClick={SingleBeatDownload}
          >
            <span>Total Beats: {totalBeat}</span>
            <img src={import_icon} alt="import icon" />
          </Button>
          {/* <Button variant="contained" className="add-collaborator-btn">
            <AddCollaborator />
          </Button> */}
          <Button
            className="total_beat"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <img src={filter_setting} alt="filter_setting" />
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                width: 220,
                overflow: "inherit",
                p: 1,
                mt: 1,
                ml: 0.75,
                borderRadius: "5px",
                background: "#141515 !important",
                "& .MuiMenuItem-root": {
                  typography: "body2",
                  borderRadius: 0.75,
                  background: "#0B0E0B !important",
                  color: "#fff",
                  boxShadow: "none !important",
                },
              },
            }}
          >
            <UlMain>
              {/* <li onClick={() => Sethasstatus("Approved")}> */}
              <li onClick={() => handelFilter("Pending")}>
                <CheckCircleOutlineIcon /> Good To Go
              </li>
              {/* <li onClick={() => Sethasstatus("Disapproved")}> */}
              <li onClick={() => handelFilter("Approved")}>
                <ScheduleIcon /> Pending Split Approval
              </li>
            </UlMain>
          </Popover>
          <Popover
            id={Otherid}
            open={Otheropen}
            anchorEl={newanchorEl}
            onClose={OtherhandleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                width: 220,
                overflow: "inherit",
                p: 1,
                mt: 1,
                ml: 0.75,
                borderRadius: "5px",
                background: "#141515 !important",
                "& .MuiMenuItem-root": {
                  typography: "body2",
                  borderRadius: 0.75,
                  background: "#0B0E0B !important",
                  color: "#fff",
                  boxShadow: "none !important",
                },
              },
            }}
          >
            <UlMain className="havemore">
              <li onClick={() => hassortHandler("default")}>Most Recent</li>
              <li onClick={() => hassortHandler("highToLow")}>
                High To Low (Beat Price)
              </li>
              <li onClick={() => hassortHandler("lowToHigh")}>
                Low To High (Beat Price)
              </li>
              <li onClick={() => hassortHandler("starred")}>Starred Beats</li>
            </UlMain>
          </Popover>
          <Button
            className="total_beat"
            aria-describedby={Otherid}
            variant="contained"
            onClick={OtherhandleClick}
          >
            <FilterListIcon />
          </Button>
        </Box>
      </HeaderMain>
    </>
  );
};

export default InboxBeatsHeader;
