import { Box } from "@mui/material";
import { styled } from "@mui/system";

const ModelContentBox = styled(Box)(({ theme }) => ({
  "& .header-box": {
    display: "flex",
    justifyContent: "space-between",
    "& .back-arrow": {
      width: "30px",
      height: "30px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
    "& .close-icon": {
      width: "30px",
      height: "30px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
  "& .profile-info-box": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
    "& .profile-pic": {
      width: "100px",
      height: "100px",
    },
    "& .profile-name": {
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      margin: 0,
      marginTop: "10px",
    },
    "& .upload-btn": {
      height: "40px",
      padding: "8px 12px",
      flexShrink: 0,
      borderRadius: "5px",
      border: "1px solid #AFAFAF",
      textTransform: "capitalize",
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      background: "#141515",
    },
  },
  "& .divider-box": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "23px",
    "& span": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
  "& .color-main": {
    marginLeft: "20px",
    "& h4": {
      color: "#AFAFAF",
      fontFamily: "Poppins",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "& .color-box-main": {
      display: "flex",
      width: "100%",
      marginTop: "24px",
      flexWrap: "wrap",
      gap: "24px",
      "& .box": {
        borderRadius: "5px",
        width: "36px",
        height: "36px",
        cursor: "pointer",
        transition: " 0.3s ease",
        position: "relative",
        "&::after": {
          content: "''",
          position: "absolute",
          width: "calc(100% + 3px)",
          height: "calc(100% + 3px)",
          borderRadius: "5px",
          top: "-1px",
          left: "-1px",
        },
      },
    },
    "& .save-btn-box": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "40px",
      marginRight: "25px",
      "& Button": {
        color: "#FFF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        padding: "10.5px 30px",
        lineHeight: "normal",
        borderRadius: "5px",
        background: "#2EBB55",
        textTransform: "capitalize",
      },
    },
  },
}));

export { ModelContentBox };
