import React, { useEffect, useRef, useState } from "react";
import {
  CustomCheckbox,
  CustomCheckboxLabel,
} from "./style/MembershipCheckboxStyle";
import { Box } from "@mui/material";

const MembershipCheckBox = ({
  checked = false,
  onChange = () => {},
  text = "",
  title = "",
  icon: IconComponent,
}) => {
  const [isIconClicked, setIsIconClicked] = useState(false);
  const iconContentRef = useRef(null);

  const handleIconClick = () => {
    setIsIconClicked(!isIconClicked);
  };

  const handleIconHover = () => {
    setIsIconClicked(!isIconClicked);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        iconContentRef.current &&
        !iconContentRef.current.contains(event.target)
      ) {
        setIsIconClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleChange = () => {
    onChange(!checked);
  };
  return (
    <CustomCheckboxLabel
      control={
        <CustomCheckbox
          checked={checked}
          onChange={handleChange}
          name="custom-checkbox"
        />
      }
      label={
        <Box className="content">
          <h5>
            <Box className="icon-main">
              {text
                ? text
                : "By submitting for this United Market opportunity, you must opt into our honesty & transparency policy."}
              {IconComponent && (
                <IconComponent
                  className="escalimation"
                  onClick={handleIconClick}
                  onMouseEnter={() => handleIconHover()}
                  onMouseLeave={() => handleIconHover()}
                />
              )}
              {isIconClicked && (
                <Box
                  className="icon-content"
                  ref={iconContentRef}
                  sx={{ position: "absolute" }}
                >
                  {title}
                </Box>
              )}
            </Box>
          </h5>
        </Box>
      }
    />
  );
};

export default MembershipCheckBox;
