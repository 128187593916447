import React, { useState } from "react";
import { Main, LinksMain } from "./styles";
import { Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { CUSTOMIZE, ADVANCED } from "./EditLinks";

const DropdownMenu = ({ items, isOpen }) => (
  <ul
    style={{
      maxHeight: "100px",
      overflow: "hidden",
      transition: "max-height 0.3s ease, opacity 0.3s ease",
      marginLeft: "20px",
    }}
  >
    {items.map((item, index) => (
      <li key={index}>
        {/* <Link to={`#${item.id}`}>{item.title}</Link>
         */}
        <a style={{ fontSize: "12px" }} href={`#${item.id}`}>
          {item.title}
        </a>
      </li>
    ))}
  </ul>
);

const EditLayout = ({ children }) => {
  const location = useLocation();
  const [CurrentIndex, setCurrentIndex] = useState(0);

  return (
    <>
      <Main>
        <Grid container spacing={2}>
          <Grid item xl={2} lg={2} md={4} sm={12}>
            <LinksMain>
              <h1>CUSTOMIZE</h1>
              <ul>
                {CUSTOMIZE.map((item, index) => {
                  return (
                    <>
                      <li
                        key={item?.url}
                        className={index === CurrentIndex ? "active" : ""}
                        onClick={() => setCurrentIndex(index)}
                      >
                        <Link to={item?.url}>
                          <img src={item?.icon} alt={item?.name} />
                          {item?.name}
                        </Link>
                        {item.Dropdown && (
                          <DropdownMenu items={item.Dropdown} />
                        )}
                      </li>
                    </>
                  );
                })}
              </ul>
              <h1>ADVANCED</h1>
              <ul>
                {ADVANCED.map((item) => {
                  return (
                    <>
                      <li
                        key={item?.url}
                        className={
                          item?.url === location.pathname ? "active" : ""
                        }
                      >
                        <Link to={item?.url}>
                          <img src={item?.icon} alt={item?.name} />
                          {item?.name}
                        </Link>
                      </li>
                    </>
                  );
                })}
              </ul>
            </LinksMain>
          </Grid>
          <Grid item xl={10} lg={10} md={8} sm={12}>
            {children}
          </Grid>
        </Grid>
      </Main>
    </>
  );
};

export default EditLayout;
