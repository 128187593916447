import React from "react";
import { Grid } from "@mui/material";

import { Sidebar } from "../../layout/Sidebar/Sidebar";

import DashboardComopnent from "../../components/DashboardComponents/dashboard_component";
import TempDashboard from "./TempDashboard";

const Dashboard = () => {
  return (
    <Grid>
      <Sidebar childComponent={<DashboardComopnent />} activeText="Dashboard" />
    </Grid>
  );
};

export default Dashboard;
