import React from "react";
import styles from "./universal.module.css";
import { useNavigate } from "react-router-dom";

import { ListItemText } from "@material-ui/core";

import {
  StyledMenu,
  StyledMenuItem,
} from "../OpportunitiesComponents/AdditionalComponents/StyledMenu";

import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DatePickers from "../smartlink/prerelease/prereleaseservices/DatePicker";
import dayjs from "dayjs";

function PageSearchHeader(props) {
  const navigate = useNavigate();
  const createLink = props.createLink;
  const handleClick = () => {
    navigate(`/songwriter/${createLink}`);
  };

  const onChange = (e) => {
    props.setSearchQuery(e.target.value);
  };

  const searchBox = props.adminUsers
    ? styles.search_box_admin_user
    : styles.search_box;

  return (
    <div className={styles.main_layout}>
      <div className={styles.top_controls}>
        <div className={styles.top_controls_top}>
          <p className={styles.page_title}>{props.title}</p>
          <div className={styles.page_controls}>
            {props.showReject && (
              <div className={styles.reject_box} onClick={props.rejectUsers}>
                <p className={styles.reject_text}>Reject All</p>
              </div>
            )}
            <div style={{ margin: "10px" }}></div>
            {props.showVerification && (
              <div className={styles.verify_box} onClick={props.verifyUsers}>
                <p className={styles.verify_text}>
                  {props.showVerificationTitle}
                </p>
              </div>
            )}
            {props.showApprove && (
              <div className={styles.verify_box} onClick={props.approveUsers}>
                <p className={styles.verify_text}>{props.showApproveTitle}</p>
              </div>
            )}
            <div style={{ margin: "10px" }}></div>
            {props.showAddUser && (
              <div className={styles.verify_box} onClick={props.handleAddUser}>
                <p className={styles.verify_text}>Add User</p>
              </div>
            )}
            <div style={{ margin: "10px" }}></div>

            {props.showExport && (
              <div className={styles.verify_box} onClick={props.handleExport}>
                <p className={styles.verify_text}>Export</p>
              </div>
            )}
            <div style={{ margin: "10px" }}></div>
            {props.showSortBy && (
              <div>
                <div
                  className={styles.verify_box}
                  onClick={props.handleSortClick}
                >
                  <p className={styles.verify_text}>Sort By</p>
                </div>
                <StyledMenu
                  id="customized-menu2"
                  anchorEl={props.sortAnchor}
                  keepMounted
                  open={Boolean(props.sortAnchor)}
                  onClose={props.handleSortClose}
                >
                  {props.sortData.map(({ id, key, title }) => (
                    <StyledMenuItem
                      key={id}
                      className={props.sortBy === key ? "active" : ""}
                    >
                      <ListItemText
                        primary={title}
                        onClick={(e) => props.applySorting(key)}
                        style={{ textAlign: "center" }}
                      />
                    </StyledMenuItem>
                  ))}
                </StyledMenu>
              </div>
            )}
            {/* {console.log("show filter =>", props.showFilter)}
            {props.showFilter && (
              <NestedMenuDropdown setFilterBy={props.setFilterBy} />
            )} */}

            {props.showCreate === true && (
              <div className={styles.filter_box} onClick={handleClick}>
                <div className={styles.filter_box_bg}>
                  <img
                    className={styles.create_icon}
                    src="/v2Assets/connect_assets/CreateIcon.png"
                    alt="create"
                  ></img>
                </div>
              </div>
            )}
            <div style={{ margin: "10px" }}></div>

            {props.showRest && (
              <div
                className={styles.verify_box}
                onClick={props.handleFilterResetClick}
              >
                <p className={styles.verify_text}>Reset</p>
              </div>
            )}
            <div style={{ margin: "10px" }}></div>
            {props.showFilter && (
              <div>
                <div
                  className={styles.verify_box}
                  onClick={props.handleFilterClick}
                >
                  <p className={styles.verify_text}>Filter By</p>
                </div>
                <StyledMenu
                  id="customized-menu2"
                  anchorEl={props.filterAnchor}
                  keepMounted
                  open={Boolean(props.filterAnchor)}
                  onClose={props.handleFilterClose}
                >
                  {props?.filterData?.map(({ id, key, title, filterBy }) => (
                    <StyledMenuItem
                      key={id}
                      onClick={(e) => props.handleClickDropdown(id)}
                      style={{ display: "flex", flexDirection: "column" }}
                      className={props.activeFilterTab === id ? "active" : ""}
                    >
                      <div
                        className="demo"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <ListItemText
                          primary={title}
                          style={{ textAlign: "center" }}
                        />

                        {filterBy &&
                          (props.openDropdownId === id ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          ))}
                      </div>

                      {filterBy && (
                        <Collapse
                          in={props.openDropdownId === id}
                          timeout="auto"
                          unmountOnExit
                        >
                          {filterBy?.map((data, index) => {
                            return (
                              <StyledMenuItem
                                key={index}
                                className={`${
                                  props.filterValue === data ? "active" : ""
                                } ${
                                  filterBy.length - 1 === index
                                    ? "removeBorder"
                                    : ""
                                }`}
                              >
                                <ListItemText
                                  primary={data}
                                  onClick={(e) =>
                                    props.applyFiltering(data, id)
                                  }
                                  style={{ textAlign: "center" }}
                                />
                              </StyledMenuItem>
                            );
                          })}
                        </Collapse>
                      )}
                    </StyledMenuItem>
                  ))}
                </StyledMenu>
              </div>
            )}
          </div>
        </div>
        {props.showTimeButton && (
          <div
            style={{
              display: "flex",
              marginBottom: "30px",
              gap: "10px",
              alignItems: "end",
            }}
          >
            <div>
              <h3 className={styles.reject_text}>Start Date</h3>
              <DatePickers
                setSelectedDate={props.setStartDate}
                title="Select date"
                className="date-picker"
              />
            </div>
            <div>
              <h3 className={styles.reject_text}>End Date</h3>
              <DatePickers
                setSelectedDate={props.setEndDate}
                title="Select date"
                className="date-picker"
                maxDate={dayjs()}
              />
            </div>
            <div className={styles.verify_box} onClick={props.applyDateFilter}>
              <p className={styles.verify_text}>Apply</p>
            </div>
          </div>
        )}

        {props.showSearchBar && (
          <div className={searchBox}>
            <img
              className={styles.search_icon}
              src="/v2Assets/connect_assets/Search.png"
              alt="search"
            ></img>
            <input
              className={styles.search_box_input}
              type="text"
              placeholder={props.searchBar}
              onChange={onChange}
              value={props.searchQuery}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PageSearchHeader;
