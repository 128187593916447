/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable require-jsdoc */
import React, { Fragment, useEffect, useState } from "react";
import { size } from "lodash";
import { NavLink } from "react-router-dom";
import { Grid, Tab, Tabs } from "@mui/material";
import { Box, Button, Typography } from "@material-ui/core";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ListItemText from "@material-ui/core/ListItemText";

import styles from "../Opportunities/Opportunities.module.css";
import universalStyles from "../../UniversalComponents/universal.module.css";

import SyncCard from "./SyncCard";
import OutlineButton from "../AdditionalComponents/OutlineButton";
import { StyledMenu, StyledMenuItem } from "../AdditionalComponents/StyledMenu";
import Setting from "../AdditionalComponents/utils/Setting.js";

import useAuth from "../../../context/AuthContext";
import { useDebounce } from "../../../hooks/useDebounce";
import { GENRE } from "../../../shared/constants";
import useAppState from "../../../context/useAppState";

import { useMediaQuery } from "@material-ui/core";

import { getFeedOpportunityApi } from "../../../context/apis/sync";
import allImages from "../../../assets";

import CircularLoaderPage from "../../UniversalComponents/CircularLoader/CircularLoaderPage";

const sortData = [
  {
    id: 1,
    title: "Most Recent",
    key: "createdAt",
    order: -1,
  },
  {
    id: 2,
    title: "Most Upvotes",
    key: "upVotes",
    order: -1,
  },
];

export const getAllMetaFields = (metafields) => {
  const obj = {};

  for (let i = 0; i < metafields?.length; i++) {
    if (metafields[i].key === "collaborator") {
      console.log("meta fields...", metafields[i]);
      if (metafields[i].valueType && metafields[i].valueType !== "undefined") {
        let arr = {};
        arr["collab_id"] = metafields[i].valueType;
        arr["userName"] = metafields[i].value;
        arr["profilePic"] = metafields[i].description;
        if (obj["collaborators"]) {
          obj["collaborators"] = [...obj["collaborators"], arr];
        } else {
          obj["collaborators"] = [arr];
        }
      }
    } else if (metafields[i].key === "artist") {
      obj["artistPhoto"] = metafields[i].description;
      obj["artistName"] = metafields[i].value;
      obj["artistStatus"] = metafields[i].valueType;
    } else {
      obj[metafields[i].key] = metafields[i].value;
    }
  }
  return obj;
};

const PAGE_LIMIT = 10;

const Sync = () => {
  const isDesktop = useMediaQuery("(min-width: 1200px)");

  const [anchorEl, setAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState(sortData[0]);
  const [sortAnchor, setSortAnchor] = useState(null);

  const [filter, setFilter] = useState("");

  const [feedsLoading, setFeedsLoading] = useState(false);
  const [feedsOpportunityData, setFeedsOpportunityData] = useState([]);
  const [hasNextFeedsPage, setHasNextFeedsPage] = useState(true);
  const [feedsPage, setFeedsPage] = useState(0);

  const [loading, setLoading] = useState(false);
  const [myOpportunityData, setMyOpportunityData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [page, setPage] = useState(0);

  const [error, setError] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [query, setQuery] = useState("");
  const searchQuery = useDebounce(query, 500);

  const { user } = useAuth();
  const { fetchMyOpportunityByQuery } = useAppState("sync");

  useEffect(() => {
    setFeedsLoading(true);
    const queryString = new URLSearchParams({
      id: user._id,
      page: 1,
      limit: PAGE_LIMIT,
      search: searchQuery,
      sort: sortBy.key,
      order: sortBy.order,
      genre: filter,
    }).toString();

    getFeedOpportunityApi(queryString)
      .then((response) => {
        if (response?.data) {
          const newRows = response?.data || [];
          setHasNextFeedsPage(newRows.length < response.totalCount);
          setFeedsOpportunityData(newRows);
          setFeedsPage(1);
        }
      })
      .finally(() => {
        setFeedsLoading(false);
      });

    const myQueryString = new URLSearchParams({
      id: user._id,
      page: 1,
      limit: PAGE_LIMIT,
      search: searchQuery,
      sort: sortBy.key,
      order: sortBy.order,
      genre: filter,
    }).toString();

    fetchMyOpportunityByQuery(myQueryString)
      .then((response) => {
        const newRows = response?.data || [];
        console.log(response?.data);
        if (response?.totalCount) {
          setHasNextPage(newRows.length < response.totalCount);
        } else {
          setHasNextPage(false);
        }
        setMyOpportunityData(newRows);
        setPage(1);
      })
      .finally(() => {
        setLoading(false);
        setIsRefresh(false);
      });
  }, [searchQuery, sortBy, filter, isRefresh]);

  useEffect(() => {
    setFeedsPage(0);
    setPage(0);
  }, [filter, sortBy]);

  useEffect(() => {
    switch (tabIndex) {
      case 0:
        getMoreFeedsData(true);
        break;
      case 1:
        getMyOpportunityData(true);
        break;
      default:
        break;
    }
  }, [tabIndex]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortClick = (event) => {
    setSortAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortClose = () => {
    setSortAnchor(null);
  };

  const applyFilter = (value) => {
    if (value === filter) {
      setFilter("");
      setAnchorEl(null);
    } else {
      setFilter(value);
      setAnchorEl(null);
    }
  };

  const applySorting = (value) => {
    if (value.id === sortBy.id) {
      setSortBy(sortData[0]);
      setSortAnchor(null);
    } else {
      setSortBy(value);
      setSortAnchor(null);
    }
  };

  // feed
  const getMoreFeedsData = async (isFirstPage) => {
    setFeedsLoading(true);
    try {
      const newPage = isFirstPage ? 1 : feedsPage + 1;

      const queryString = new URLSearchParams({
        id: user._id,
        page: newPage,
        limit: PAGE_LIMIT,
        search: searchQuery,
        sort: sortBy.key,
        order: sortBy.order,
        genre: filter,
      }).toString();
      getFeedOpportunityApi(queryString)
        .then((response) => {
          const newRows = [...feedsOpportunityData, ...response.data];

          setHasNextFeedsPage(newRows.length < response.totalCount);
          setFeedsOpportunityData(newRows);
          setFeedsPage(newPage);
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setFeedsLoading(false);
        });
    } catch (err) {
      setError(true);
    }
  };

  const getMyOpportunityData = (isFirstPage) => {
    setLoading(true);
    const newPage = isFirstPage ? 1 : page + 1;
    const queryString = new URLSearchParams({
      id: user._id,
      page: newPage,
      limit: PAGE_LIMIT,
      search: searchQuery,
      sort: sortBy.key,
      order: sortBy.order,
      genre: filter,
    }).toString();

    fetchMyOpportunityByQuery(queryString)
      .then((response) => {
        const newRows = [...myOpportunityData, ...response.data];

        console.info("response.totalCount User=> ", response.totalCount);

        setHasNextPage(newRows.length < response.totalCount);
        setMyOpportunityData(newRows);
        setPage(newPage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTabChange = (event, newTabIndex) => {
    setPage(0);
    setTabIndex(newTabIndex);
    setMyOpportunityData([]);
    setFeedsOpportunityData([]);
  };

  const onRefresh = () => {
    setIsRefresh((prev) => !prev);
  };

  const onDelete = (id) => {
    const data = [...myOpportunityData];
    const index = data.findIndex(({ _id }) => _id === id);

    if (index >= 0) {
      data.splice(index, 1);
      setMyOpportunityData(data);
    }
  };

  const fetchFeedsMore = () => {
    if (feedsLoading) return;
    getMoreFeedsData();
  };

  const fetchMore = () => {
    if (loading) return;
    getMyOpportunityData();
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMore,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  const [sentryFeedRef] = useInfiniteScroll({
    loading: feedsLoading,
    hasNextPage: hasNextFeedsPage,
    onLoadMore: fetchFeedsMore,
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  const layoutType = isDesktop ? styles.main_layout : styles.main_layout_mobile;
  const titleType = isDesktop ? styles.page_title : styles.page_title_mobile;

  return (
    <Box
      className={layoutType}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Box
        className={styles.parent_wrapper}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box
          className={styles.titleWrapper}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p className={titleType}>Sync Placements</p>
          <Button className={styles.filterBtn2} onClick={handleClick}>
            <img src="/images/setting.png" alt="" />
          </Button>
        </Box>

        <Box className={`${styles.buttonGroup} ${styles.buttonsWrapper}`}>
          <div className={styles.refreshBtn} onClick={onRefresh}>
            <img
              src={allImages.refresh}
              className={`${isRefresh ? styles.loading : ""}`}
              alt="refresh"
            />
          </div>
          {/* <div
            className={`${styles.filterBtn1} ${
              anchorEl && styles.filterActive
            }`}
            onClick={handleClick}
          >
            <Setting />
          </div> */}
          <StyledMenu
            id="customized-menu1"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {GENRE.map((genre, index) => (
              <StyledMenuItem
              key={`genreT-${index}`}
                className={filter === genre ? "active" : ""}
              >
                <ListItemText
                  primary={genre}
                  onClick={(e) => applyFilter(genre)}
                  style={{ textAlign: "center" }}
                />
              </StyledMenuItem>
            ))}
          </StyledMenu>
          {/* <OutlineButton
            title="Sort By"
            handler={handleSortClick}
            filter={sortAnchor}
          /> */}
          <StyledMenu
            id="customized-menu2"
            anchorEl={sortAnchor}
            keepMounted
            open={Boolean(sortAnchor)}
            onClose={handleSortClose}
          >
            {sortData.map(({ id, key, title, order }) => (
              <StyledMenuItem
              key={`sortPP-${id}`}
                className={sortBy.id === id ? "active" : ""}
              >
                <ListItemText
                  primary={title}
                  onClick={(e) => applySorting({ order, key, id })}
                  style={{ textAlign: "center" }}
                />
              </StyledMenuItem>
            ))}
          </StyledMenu>

          {user.roleId.roleName !== "Music Producer" && (
            <NavLink to={"/sync/create"}>
              <div className={styles.create_button}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                >
                  <path
                    className={styles.plus_icon}
                    d="M15 25H35M25 15V35"
                    // stroke="#fff"
                    // stroke-opacity="0.6"
                    // strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </NavLink>
          )}
        </Box>
      </Box>

      <Box sx={{ padding: "15px 0px 10px 0" }}>
        <div className={universalStyles.search_box}>
          <img
            className={universalStyles.search_icon}
            src="/v2Assets/connect_assets/Search.png"
            alt="search"
          ></img>
          <input
            className={universalStyles.search_box_input}
            type="text"
            placeholder={"Search"}
            onChange={(data) => {
              console.info("data => ", data);
              setQuery(data.target.value);
            }}
          />
        </div>
      </Box>

      <Box>
        <Box>
          {user.roleId.roleName !== "Music Producer" && (
            <Tabs
              sx={{
                borderBottom: "1px solid rgba(46, 187, 85, 0.25);",
                "& .MuiTab-root": {
                  color: "#AFAFAF",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#2ebb55",
                  color: "#2ebb55",
                  height: "2px",
                  borderRadius: "3px 3px 0px 0px",
                },
                "& .Mui-selected": {
                  color: "white !important",
                },
              }}
              value={tabIndex}
              onChange={handleTabChange}
              classes={styles.tabWrapper}
            >
              <Tab
                style={{ textTransform: "none" }}
                label="Sync Feed"
                sx={{
                  color: "white",
                  fontSize: "1.2em",
                  "&:hover": {
                    color: "rgba(46, 187, 85, 0.75)",
                  },
                  "&.Mui-selected": {
                    color: "rgba(46, 187, 85, 1)",
                  },
                }}
              />
              {user.roleId.roleName !== "Music Producer" && (
                <Tab
                  style={{ textTransform: "none" }}
                  label="My Sync Placements"
                  sx={{
                    marginLeft: "20px",
                    fontSize: "1.2em",
                    "&:hover": {
                      color: "rgba(46, 187, 85, 0.75)",
                    },
                    "&.Mui-selected": {
                      color: "rgba(46, 187, 85, 1)",
                    },
                  }}
                />
              )}
            </Tabs>
          )}
        </Box>

        {user.roleId.roleName !== "Music Producer" && (
          <Box sx={{ padding: 4 }}>
            {tabIndex === 0 && (
              <Box>
                {feedsOpportunityData?.length > 0
                  ? feedsOpportunityData.map((opportunity, index) => (
                      <Fragment key={`opX-${index}`}>
                        <SyncCard
                          opportunityData={opportunity}
                          isSelf={false}
                        />
                      </Fragment>
                    ))
                  : !feedsLoading && (
                      <Box
                        style={{
                          marginTop: "100px",
                          display: "flex",
                          justifyContent: "center",

                          alignItems: "center",
                        }}
                      >
                        <p classes={styles.empty_page_text}>No Data Found</p>
                      </Box>
                    )}

                {(feedsLoading || hasNextFeedsPage) && <CircularLoaderPage />}
              </Box>
            )}
            {tabIndex === 1 && user.roleId.roleName !== "Music Producer" && (
              <Box>
                {myOpportunityData?.length > 0
                  ? myOpportunityData.map((opportunity, index) => (
                      <Fragment key={`myoppdata-${index}`}>
                        <SyncCard
                          opportunityData={opportunity}
                          isSelf={true}
                          onDelete={onDelete}
                        />
                      </Fragment>
                    ))
                  : !loading && (
                      <Box
                        style={{
                          marginTop: "100px",
                          display: "flex",
                          justifyContent: "center",

                          alignItems: "center",
                        }}
                      >
                        <p classes={styles.empty_page_text}>No Data Found</p>
                      </Box>
                    )}

                {(loading || hasNextPage) && <CircularLoaderPage />}
              </Box>
            )}
          </Box>
        )}

        {user.roleId.roleName === "Music Producer" && (
          <Box>
            {size(feedsOpportunityData) > 0
              ? feedsOpportunityData.map((opportunity, index) => (
                  <Fragment key={`feedsYY-${index}`}>
                    <SyncCard opportunityData={opportunity} isSelf={false} />
                  </Fragment>
                ))
              : !feedsLoading && (
                  <Box
                    style={{
                      marginTop: "100px",
                      display: "flex",
                      justifyContent: "center",

                      alignItems: "center",
                    }}
                  >
                    <p classes={styles.empty_page_text}>No Data Found</p>
                  </Box>
                )}

            {(feedsLoading || hasNextFeedsPage) && <CircularLoaderPage />}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Sync;
