import { ResponsiveBar } from "@nivo/bar";

export default function MonthlyRevenueChart(props) {
  console.log(props);
  const customTheme = {
    grid: {
      line: {
        stroke: "rgba(255, 255, 255, 0.3)",
        strokeWidth: 1,
      },
    },
    axis: {
      textColor: "#FFFFFF",
      legend: {
        text: {
          fill: "#FFFFFF",
        },
      },
      ticks: {
        line: {
          stroke: "#FFFFFF",
        },
        text: {
          fill: "#FFFFFF",
        },
      },
    },
  };

  const numberOfMonthsToShow = 4;
  const revenueData = props.analyticsData.revenueData
    .slice(0, numberOfMonthsToShow)
    .map((item) => ({
      month: getMonthAbbreviation(item.month),
      revenue: item.revenue,
      formattedRevenue: formatPrice(item.revenue),
      color: "rgba(46, 187, 85, 0.75)",
    }))
    .reverse();

  return (
    <ResponsiveBar
      data={revenueData}
      theme={customTheme}
      colors={({ data }) => data.color}
      keys={["revenue"]}
      indexBy="month"
      label={(d) => formatPrice(d.value)}
      tooltip={({ id, value, indexValue }) => (
        <div>
          {id}: {formatPrice(value)} in month: {indexValue}
        </div>
      )}
      margin={{ top: 30, right: 40, bottom: 40, left: 40 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="white"
      tickColor="white" // Set tick color to white
      legendTextColor="white" // Set legend text color to white
      role="application"
      ariaLabel="Nivo bar chart demo"
    />
  );
}

function formatPrice(priceInCents, currency = "USD") {
  const priceInDollars = priceInCents / 100;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(priceInDollars);
}

const getMonthAbbreviation = (monthYear) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthIndex = parseInt(monthYear.split("-")[0], 10) - 1;
  return monthNames[monthIndex];
};
