import React from "react";
import { Container, Typography, Link } from "@mui/material";

const UserLicence = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ my: 4, color: "#fff", background: "#141515", padding: "20px" }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", marginBottom: "20px" }}
      >
        Mobile Application End User License Agreement
      </Typography>

      <Typography paragraph>
        This Mobile Application End User License Agreement (“Agreement”) is a
        binding agreement between you (“End User” or “you”) and United Market,
        Inc. (“Company”). This Agreement governs your use of the United Market
        Mobile Application (including all related documentation, the
        “Application”). The Application is licensed, not sold, to you.
      </Typography>

      <Typography paragraph>
        BY CLICKING THE “AGREE” BUTTON AND DOWNLOADING, INSTALLING OR USING THE
        APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS
        AGREEMENT; (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER INTO A
        BINDING AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE
        LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT
        DOWNLOAD, INSTALL, OR USE THE APPLICATION AND DELETE IT FROM YOUR MOBILE
        DEVICE.
      </Typography>

      <Typography paragraph>
        License Grant. Subject to the terms of this Agreement, Company grants
        you a limited, non-exclusive, and nontransferable license to:  Download,
        install, and use the Application for your personal, non-commercial use
        on a single mobile device owned or otherwise controlled by you (“Mobile
        Device”) strictly in accordance with the Application’s documentation;
        and  Access, download, and use on such Mobile Device the Content and
        Services (as defined in Section 5) made available in or otherwise
        accessible through the Application, strictly in accordance with this
        Agreement and the Terms of Use applicable to such Content and Services
        as set forth in Section 5. 
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>
          License Restrictions. Licensee shall not:  
        </strong>
        <br />
        Copy the Application, except as expressly permitted by this license; 
        Modify, translate, adapt, or otherwise create derivative works or
        improvements, whether or not patentable, of the Application;  Reverse
        engineer, disassemble, decompile, decode, or otherwise attempt to derive
        or gain access to the source code of the Application or any part
        thereof;  Remove, delete, alter, or obscure any trademarks or any
        copyright, trademark, patent, or other intellectual property or
        proprietary rights notices from the Application, including any copy
        thereof;  Rent, lease, lend, sell, sublicense, assign, distribute,
        publish, transfer, or otherwise make available the Application, or any
        features or functionality of the Application, to any third party for any
        reason, including by making the Application available on a network where
        it is capable of being accessed by more than one device at any time; or 
        Remove, disable, circumvent, or otherwise create or implement any
        workaround to any copy protection, rights management, or security
        features in or protecting the Application. 
      </Typography>

      <Typography paragraph>
        Reservation of Rights. You acknowledge and agree that the Application is
        provided under license, and not sold, to you. You do not acquire any
        ownership interest in the Application under this Agreement, or any other
        rights thereto other than to use the Application in accordance with the
        license granted, and subject to all terms, conditions, and restrictions,
        under this Agreement. Company and its licensors and service providers
        reserve and shall retain their entire right, title, and interest in and
        to the Application, including all copyrights, trademarks, and other
        intellectual property rights therein or relating thereto, except as
        expressly granted to you in this Agreement.
      </Typography>

      <Typography paragraph>
        Collection and Use of Your Information. You acknowledge that when you
        download, install, or use the Application, Company may use automatic
        means (including, for example, cookies and web beacons) to collect
        information about your Mobile Device and about your use of the
        Application. You also may be required to provide certain information
        about yourself as a condition to downloading, installing, or using the
        Application or certain of its features or functionality, and the
        Application may provide you with opportunities to share information
        about yourself with others. All information we collect through or in
        connection with this Application is subject to our Privacy Policy
        located at www.UnitedMarket.com/privacy. By downloading, installing,
        using, and providing information to or through this Application, you
        consent to all actions taken by us with respect to your information in
        compliance with the Privacy Policy. 
      </Typography>

      <Typography paragraph>
        Content and Services. The Application may provide you with access to the
        Company’s website located at www.UnitedMarket.com (the “Website”) and
        products and services accessible thereon, and certain features,
        functionality, and content accessible on or through the Application may
        be hosted on the Website (collectively, “Content and Services”). Your
        access to and use of such Content and Services are governed by the
        Website’s Terms of Use and Privacy Policy located at
        www.UnitedMarket.com/privacy, which are incorporated herein by this
        reference. Your access to and use of such Content and Services may
        require you to acknowledge your acceptance of such Terms of Use and
        Privacy Policy and/or to register with the Website, and your failure to
        do so may restrict you from accessing or using certain of the
        Application’s features and functionality. Any violation of such Terms of
        Use or Privacy Policy will also be deemed a violation of this
        Agreement. 
      </Typography>

      <Typography paragraph>
        Authorized Users Only. You agree that only the individuals whom have
        purchased the requisite licenses in accordance with this Agreement, may
        access and use the Application (each such individual, an “Authorized
        User”). You hereby agree you are liable for the actions of all
        unauthorized users for whom you give unauthorized and unlawful access to
        this Application.
      </Typography>

      <Typography paragraph>
        Geographic Restrictions. The Content and Services are based in the state
        of Georgia in the United States. You acknowledge that you may not be
        able to access all or some of the Content and Services outside of the
        United States and that access thereto may not be legal by certain
        persons or in certain countries. If you access the Content and Services
        from outside the United States, you are responsible for compliance with
        local laws. 
      </Typography>

      <Typography paragraph>
        Updates. Company may from time to time in its sole discretion develop
        and provide Application updates, which may include upgrades, bug fixes,
        patches, other error corrections, and/or new features (collectively,
        including related documentation, “Updates”). Updates may also modify or
        delete in their entirety certain features and functionality. You agree
        that Company has no obligation to provide any Updates or to continue to
        provide or enable any particular features or functionality. Based on the
        settings enabled on your Mobile Device, your Mobile Device will do one
        of the following when connected to the internet:
      </Typography>

      <Typography paragraph>
        The Application will automatically download and install all available
        Updates; or  You may receive notice of or be prompted to download and
        install available Updates.  You shall promptly download and install all
        Updates and acknowledge and agree that the Application or portions
        thereof may not properly operate should you fail to do so. You further
        agree that all Updates will be deemed part of the Application and be
        subject to all terms and conditions of this Agreement.
      </Typography>

      <Typography paragraph>
        Third-Party Materials. The Application may display, include, or make
        available third-party content (including data, information,
        applications, and other products, services, and/or materials) or provide
        links to third-party websites or services, including through third-party
        advertising (“Third-Party Materials”). You acknowledge and agree that
        Company is not responsible for Third-Party Materials, including their
        accuracy, completeness, timeliness, validity, copyright compliance,
        legality, decency, quality, or any other aspect thereof. Company does
        not assume and will not have any liability or responsibility to you or
        any other person or entity for any Third-Party Materials. Third-Party
        Materials and links thereto are provided solely as a convenience to you,
        and you access and use them entirely at your own risk and subject to
        such third parties’ terms and conditions. 
      </Typography>
      <Typography paragraph>
        Term and Termination.  The term of Agreement commences when you download
        or install the Application and acknowledge your acceptance and will
        continue in effect until terminated by you or Company as set forth in
        this Section 9.  You may terminate this Agreement by deleting the
        Application and all copies thereof from your Mobile Device.  Company may
        terminate this Agreement at any time without notice if it ceases to
        support the Application, which Company may do in its sole discretion. In
        addition, this Agreement will terminate immediately and automatically
        without any notice if you violate any of the terms and conditions of
        this Agreement. 
      </Typography>
      <Typography paragraph>
        Upon termination:  All rights granted to you under this Agreement will
        also terminate; and  You must cease all use of the Application and
        delete all copies of the Application from your Mobile Device and
        account.  Termination will not limit any of Company’s rights or remedies
        at law or in equity. 
      </Typography>
      <Typography paragraph>
        Disclaimer of Warranties. THE APPLICATION IS PROVIDED TO LICENSEE “AS
        IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE
        MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN
        BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE
        LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES,
        WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE
        APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND
        WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
        PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE
        FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO
        REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR
        REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH
        ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT
        INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE
        ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. 
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
        IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS
        OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS
        MAY NOT APPLY TO YOU.
      </Typography>
      <Typography paragraph>
        Limitation of Liability. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
        LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR
        RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING
        FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR
        THE CONTENT AND SERVICES FOR:  PERSONAL INJURY, PROPERTY DAMAGE, LOST
        PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF
        GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY
        OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR
        PUNITIVE DAMAGES.  DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE
        EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION.   THE
        FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF
        BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND
        REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT
        ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE
        LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.
      </Typography>
      <Typography paragraph>
        Indemnification. You agree to indemnify, defend, and hold harmless
        Company and its officers, directors, employees, agents, affiliates,
        successors, and assigns from and against any and all losses, damages,
        liabilities, deficiencies, claims, actions, judgments, settlements,
        interest, awards, penalties, fines, costs, or expenses of whatever kind,
        including reasonable attorneys’ fees, arising from or relating to your
        use or misuse of the Application or your breach of this Agreement,
        including but not limited to the content you submit or make available
        through this Application. 
      </Typography>
      <Typography paragraph>
        Export Regulation. The Application may be subject to US export control
        laws, including the US Export Administration Act and its associated
        regulations. You shall not, directly or indirectly, export, re-export,
        or release the Application to, or make the Application accessible from,
        any jurisdiction or country to which export, re-export, or release is
        prohibited by law, rule, or regulation. You shall comply with all
        applicable federal laws, regulations, and rules, and complete all
        required undertakings (including obtaining any necessary export license
        or other governmental approval), prior to exporting, re-exporting,
        releasing, or otherwise making the Application available outside the
        US. 
      </Typography>
      <Typography paragraph>
        Severability. If any provision of this Agreement is illegal or
        unenforceable under applicable law, the remainder of the provision will
        be amended to achieve as closely as possible the effect of the original
        term and all other provisions of this Agreement will continue in full
        force and effect. 
      </Typography>
      <Typography paragraph>
        Governing Law. This Agreement is governed by and construed in accordance
        with the internal laws of the State of Georgia without giving effect to
        any choice or conflict of law provision or rule. Any legal suit, action,
        or proceeding arising out of or related to this Agreement or the
        Application shall be instituted exclusively in the federal courts of the
        United States or the courts of the State of Georgia in each case located
        in the City of Atlanta. By downloading and using the Application, you
        consent to the exercise of jurisdiction over you by such courts and to
        venue in such courts.
      </Typography>
      <Typography paragraph>
        Limitation of Time to File Claims. ANY CAUSE OF ACTION OR CLAIM YOU MAY
        HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE APPLICATION
        MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES,
        OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
      </Typography>
      <Typography paragraph>
        Entire Agreement. This Agreement, the Terms of Use, and our Privacy
        Policy constitute the entire agreement between you and Company with
        respect to the Application and supersede all prior or contemporaneous
        understandings and agreements, whether written or oral, with respect to
        the Application.  
      </Typography>
      <Typography paragraph>
        Waiver. No failure to exercise, and no delay in exercising, on the part
        of either party, any right or any power hereunder shall operate as a
        waiver thereof, nor shall any single or partial exercise of any right or
        power hereunder preclude further exercise of that or any other right
        hereunder. In the event of a conflict between this Agreement and any
        applicable purchase or other terms, the terms of this Agreement shall
        govern.  
      </Typography>
      <Typography paragraph>
        Force Majeure. The performance by either Party of its obligations
        hereunder (other than the payment of money) will be excused during any
        period of time in which the failure to perform results from acts of God,
        the elements, fire, flood, component shortages, force majeure, riot,
        insurrection, industrial dispute, accident, war, embargoes, legal
        restrictions or any other cause beyond the reasonable control of a
        Party.
      </Typography>
      <Typography paragraph>
        Consent to Use of Electronic Signatures and Records. Customer
        acknowledges and agrees that by clicking “I Agree” or “I Accept”
        anywhere on the Site or by otherwise agreeing to the terms and
        conditions set forth in any agreement posted on the Site: (a) Customer
        agrees to conduct electronically the particular transaction into which
        it is entering; (b) Customer read, understands and agrees to be bound by
        the electronic copy of electronic contracts, notices and records to
        which it is agreeing, including, without limitation, this Agreement; (c)
        Customer is capable of printing or storing a copy of electronic records
        of agreements to which it is agreeing including, without limitation,
        this Agreement; and (d) Customer agrees to receive electronically
        information about the agreements to which it is agreeing including,
        without limitation, this Agreement
      </Typography>
    </Container>
  );
};

export default UserLicence;
