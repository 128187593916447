import React, { Fragment, useEffect, useState, useCallback } from "react";
import LibraryContentArea from "./LibraryContentArea.js";
import PackContentArea from "./PackContentArea.js";
import StorageContentArea from "./StorageContentArea.js";
import LibraryContentArea_Stared from "./LibraryContentArea_Stared.js";
import LibraryContentArea_Trashed from "./LibraryContentArea_Trashed.js";
import LibraryContentArea_Sent from "./LibraryContentArea_Sent.js";
import useLibraryStore from "../../../store/library.js";
import { feedAudioFilesByQuery } from "../../../context/apis/audio-library.js";
import PackDetail from "./PackDetail.js";
import PackMoreDetail from "./PackMoreDetail.js";
import { SubSideBar } from "../../../layout/Sidebar/SubSideBar.js";
import CircularLoaderPage from "../../UniversalComponents/CircularLoader/CircularLoaderPage.js";
import useAuth from "../../../context/AuthContext.js";
import InboxInLibrary from "../../../pages/smartlink/beatinboxdashboard/InboxInLibrary.js";
import OpportunitySubmissionsComponent from "../../SubmissionsComponents/AudioLibararySubmission/OpportunitySubmissionsComponent.js";
import SyncSubmissionsComponent from "../../SubmissionsComponents/AudioLibararySubmission/SyncSubmissionComponent.js";



const LibraryWrapper = ({ curretnBar }) => {

  const [loading, setLoading] = useState(false);
  const { selectedTags, range, fileKey, scale, mode, query, sortBy, allLibraryData, setAllLibraryData, currentSubBarIndex } = useLibraryStore();
  const { user } = useAuth();

  useEffect(() => {
    setLoading(true);
    const queryString = new URLSearchParams({
      userId: user._id,
    }).toString();
    feedAudioFilesByQuery(queryString)
      .then((response) => {
        if (response?.data) {
          const newRows = response.data || [];
          setAllLibraryData(newRows);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [])

  const ContentArea = useCallback(({ index }) => {
    switch (index) {
      case 0: // All Files
        return (
          <>
            <LibraryContentArea tags={selectedTags} minBpm={range[0]} maxBpm={range[1]} fileKey={fileKey} scale={scale} mode={mode} fileName={query} sortBy={sortBy} />
            {/* <BeatinboxIndex /> */}
          </>
        );

      case 1: // Beat Packs
        return (
          <>
            <PackContentArea tags={selectedTags} minBpm={range[0]} maxBpm={range[1]} fileKey={fileKey} scale={scale} mode={mode} packName={query} sortBy={sortBy} loading={loading} allLibraryData={allLibraryData} setAllLibraryData={setAllLibraryData} />
          </>
        );
      case 2: // Inbox
        return (
          <>
            <InboxInLibrary />
          </>
        );
      case 3:  // Starred
        return (
          <>
            <LibraryContentArea_Stared tags={selectedTags} minBpm={range[0]} maxBpm={range[1]} fileKey={fileKey} scale={scale} mode={mode} fileName={query} sortBy={sortBy} />

          </>
        );
      case 4:  // Trash
        return (
          <>
            <LibraryContentArea_Trashed tags={selectedTags} minBpm={range[0]} maxBpm={range[1]} fileKey={fileKey} scale={scale} mode={mode} fileName={query} sortBy={sortBy} />

          </>
        );

      case 5: // Sent
        return (
          <>
            <OpportunitySubmissionsComponent />

          </>
        );

      case 6: // Sent/Sync
        return (
          <>
            <SyncSubmissionsComponent />

          </>
        );
      // case 5: // Get more storage
      //   return (
      //     <>
      //       <StorageContentArea />
      //     </>
      //   );

      case 7:
        return (
          <>
            <PackDetail tags={selectedTags} minBpm={range[0]} maxBpm={range[1]} fileKey={fileKey} scale={scale} mode={mode} packName={query} sortBy={sortBy} loading={loading} allLibraryData={allLibraryData} setAllLibraryData={setAllLibraryData} />
          </>
        );
      case 8:
        return (
          <>
            <PackMoreDetail tags={selectedTags} minBpm={range[0]} maxBpm={range[1]} fileKey={fileKey} scale={scale} mode={mode} fileName={query} sortBy={sortBy} loading={loading} allLibraryData={allLibraryData} setAllLibraryData={setAllLibraryData} />
          </>
        );
      default:
        return (
          <>
            <LibraryContentArea tags={selectedTags} minBpm={range[0]} maxBpm={range[1]} fileKey={fileKey} scale={scale} mode={mode} fileName={query} sortBy={sortBy} />
          </>
        );
    }
  }, [currentSubBarIndex, range, fileKey, selectedTags, scale, mode, query, sortBy]);


  return (
    <>
      {!loading ? (
        <SubSideBar
          childComponent={<ContentArea index={curretnBar} />}
        />) : (
        <div>
          <CircularLoaderPage />
        </div>
      )}

    </>
  );
};

export default LibraryWrapper;
