import React, { useEffect, useState } from "react";

import styles from "./Libraries.module.css";

import { useMediaQuery, Box } from "@material-ui/core";

import DeletePackModal from "./Modals/DeletePackModal";

import SendFileModal from "./Modals/SendFileModal";
import { FileIcon, ShareIcon, TrashIcon, ViewIcon } from "../../../assets/smartlink";
import LibLineDivider from "./LibLineDivider";
import FavIcon from "../../../assets/smartlink/icons/FavIcon";
import EyeIcon from "../../../assets/smartlink/icons/EyeIcon";
import useLibraryStore from "../../../store/library";
import { useNavigate } from "react-router";
import { getUserInfo } from "../../../context/apis/audio-library";

function TimeAgo(createdAT) {
  const currentTime = new Date();

  const pastTime = new Date(createdAT);
  const diffInSeconds = Math.floor((currentTime - pastTime) / 1000);

  let timeAgo = "";
  if (diffInSeconds < 60) {
    timeAgo = `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    // less than 1 hour
    const minutes = Math.floor(diffInSeconds / 60);
    timeAgo = `${minutes} minutes ago`;
  } else if (diffInSeconds < 86400) {
    // less than 1 day
    const hours = Math.floor(diffInSeconds / 3600);
    timeAgo = `${hours} hours ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    timeAgo = `${days} days ago`;
  }

  return timeAgo;
}


function PackCard({
  packData,
  onDelete,
  index,
  onDeleteComplete,
}) {
  const isDesktop = useMediaQuery("(min-width: 1200px)");

  const cover_image =
    packData?.basicInfo?.imageURL ?
      (`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${packData?.basicInfo?.imageURL}`.replace(/\\/g, '/').replace(/�/g, '')) :
      "/images/no_image.png";
  const user_avatar = "/images/no_image.png";

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sendFileModalOpen, setSendFileModalOpen] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [packId, setPackId] = useState("");
  const router = useNavigate();

  const goPackDetail = (id, name) => {
    router(`/myaudiolibrary/beat_pack/${id}/?packname=${name}`)
  }

  const openSendModal = (pack_id) => {
    setSendFileModalOpen(true);
    setPackId(pack_id);
  }



  function getActiveAndNonActiveUsers(viewers, downloaders) {
    // Find active users (present in both viewers and downloaders)
    const activeUsers = viewers.filter((viewer) =>
      downloaders.some((downloader) => downloader.personId === viewer.personId)
    );

    // Find non-active viewers (not present in downloaders)
    const nonActiveViewers = viewers.filter(
      (viewer) => !downloaders.some((downloader) => downloader.personId === viewer.personId)
    );

    // Find non-active downloaders (not present in viewers)
    const nonActiveDownloaders = downloaders.filter(
      (downloader) => !viewers.some((viewer) => viewer.personId === downloader.personId)
    );

    return {
      activeUsers,
      nonActiveViewers,
      nonActiveDownloaders,
    };
  }

  const { activeUsers, nonActiveViewers, nonActiveDownloaders } =
    getActiveAndNonActiveUsers(packData?.basicInfo?.viewers, packData?.basicInfo?.downloaders);




  return (
    <Box>
      <div className={styles.opportunity_card_layout}>
        <div className={styles.opportunity_card_content}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className={styles.opportunity_card_image_container}>
                <div
                  className={styles.opportunity_card_image}
                  style={{
                    background: `url(${cover_image})center center / cover no-repeat`,
                  }}
                />
              </div>
              <BeatPackTitleInfo />
            </div>
            <BeatPackActions pack_id={packData?._id} />
          </div>
        </div>
        {isExpand && <ViewerComponent />}
      </div>


      <SendFileModal
        open={sendFileModalOpen}
        setOpen={setSendFileModalOpen}
        type="pack"
        id={packData?._id}
        imageUrl={packData?.basicInfo?.imageURL}
        title={packData?.basicInfo?.packName}
        trackCount={packData?.basicInfo?.fileIds?.length}
      />
      <DeletePackModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        editData={packData}
        type="pack"
        index={index}
        deleteCard={() => onDeleteComplete(index)}
      />

    </Box>
  );

  function ViewerComponent() {
    const [activeUsersAvatar, setActiveUsersAvatar] = useState([]);
    const [nonActiveViewersAvatar, setNonActiveViewersAvatar] = useState([]);
    const [nonActiveDownloadersAvatar, setNonActiveDownloadersAvatar] = useState([]);

    useEffect(() => {
      // Create a function to fetch data as useEffect should not return anything
      const fetchActiveUsersAvatar = async () => {
        try {
          // Use Promise.all to wait for all getUserInfo calls to finish
          const avatarPromises = activeUsers.map(user =>
            getUserInfo(user.personId)
          );

          // Await all promises and then extract profilePic from each resolved promise
          const avatars = (await Promise.all(avatarPromises)).map(
            response => response?.data?.profilePic
          );

          // Set the state with the fetched avatars
          setActiveUsersAvatar(avatars);
        } catch (error) {
          console.error("Failed to fetch activer avatars", error);
        }
      };

      // Create a function to fetch data as useEffect should not return anything
      const fetchNonActiveViewersAvatar = async () => {
        try {
          // Use Promise.all to wait for all getUserInfo calls to finish
          const avatarPromises = nonActiveViewers.map(user =>
            getUserInfo(user.personId)
          );

          // Await all promises and then extract profilePic from each resolved promise
          const avatars = (await Promise.all(avatarPromises)).map(
            response => response?.data?.profilePic
          );

          // Set the state with the fetched avatars
          setNonActiveViewersAvatar(avatars);
        } catch (error) {
          console.error("Failed to fetch nonActiveViewer avatars", error);
        }
      };

      // Create a function to fetch data as useEffect should not return anything
      const fetchNonActiveDownloadersAvatar = async () => {
        try {
          // Use Promise.all to wait for all getUserInfo calls to finish
          const avatarPromises = nonActiveDownloaders.map(user =>
            getUserInfo(user.personId)
          );

          // Await all promises and then extract profilePic from each resolved promise
          const avatars = (await Promise.all(avatarPromises)).map(
            response => response?.data?.profilePic
          );

          // Set the state with the fetched avatars
          setNonActiveDownloadersAvatar(avatars);
        } catch (error) {
          console.error("Failed to fetch nonActiveDownloader avatars", error);
        }
      };


      // Call the function if there are any active users
      if (activeUsers.length > 0) {
        fetchActiveUsersAvatar();
      }

      if (nonActiveViewers.length > 0) {
        fetchNonActiveViewersAvatar();
      }

      if (nonActiveDownloaders.length > 0) {
        fetchNonActiveDownloadersAvatar();
      }

    }, []);

    return (
      <div style={{ marginLeft: "3px" }}>

        {activeUsers && activeUsers.length > 0 && (
          activeUsers.map((activer, index) => (
            <>
              <LibLineDivider background="#323333" />

              <div className={styles.opportunity_card_layout}>
                <div className={styles.opportunity_card_content}> key = {`activerId in pack card is ${index}`}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className={styles.opportunity_card_image_container}>
                        <div
                          className={styles.opportunity_card_image}
                          style={{
                            background: `url(${activeUsersAvatar[index]})center center / cover no-repeat`,
                            borderRadius: "100px"
                          }}
                        />
                      </div>
                      <div>
                        <div className={styles.pack_viewer_title}>{activer.name}</div>
                        <div className={styles.pack_viewer_time}>{TimeAgo(activer.time)}</div>
                      </div>
                    </div>
                    <div className={styles.opportunity_data_information}>
                      <div className={styles.opportunity_tags_container}>
                        <FavIcon color="#2EBB55" />
                        <EyeIcon color="#2EBB55" />
                      </div>
                    </div>
                  </div>
                </div>
              </div >
            </>
          ))
        )}


        {nonActiveViewers && nonActiveViewers.length > 0 && (
          nonActiveViewers.map((viewer, index) => (
            <>
              <LibLineDivider background="#323333" />
              <div className={styles.opportunity_card_layout}>
                <div className={styles.opportunity_card_content}> key = {`viewerId in pack card is ${index}`}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className={styles.opportunity_card_image_container}>
                        <div
                          className={styles.opportunity_card_image}
                          style={{
                            background: `url(${nonActiveViewersAvatar[index]})center center / cover no-repeat`,
                            borderRadius: "100px"
                          }}
                        />
                      </div>
                      <div>
                        <div className={styles.pack_viewer_title}>{viewer.name}</div>
                        <div className={styles.pack_viewer_time}>{TimeAgo(viewer.time)}</div>
                      </div>
                    </div>
                    <div className={styles.opportunity_data_information}>
                      <div className={styles.opportunity_tags_container}>
                        <FavIcon color="#AFAFAF" />
                        <EyeIcon color="#2EBB55" />
                      </div>
                    </div>
                  </div>
                </div>
              </div >
            </>
          ))
        )}

        {nonActiveDownloaders && nonActiveDownloaders.length > 0 && (
          nonActiveDownloaders.map((downloader, index) => (
            <>
              <LibLineDivider background="#323333" />
              <div className={styles.opportunity_card_layout}>
                <div className={styles.opportunity_card_content}> key = {`downloaderId in pack card is ${index}`}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className={styles.opportunity_card_image_container}>
                        <div
                          className={styles.opportunity_card_image}
                          style={{
                            background: `url(${nonActiveDownloadersAvatar[index]})center center / cover no-repeat`,
                            borderRadius: "100px"
                          }}
                        />
                      </div>
                      <div>
                        <div className={styles.pack_viewer_title}>{downloader.name}</div>
                        <div className={styles.pack_viewer_time}>{TimeAgo(downloader.time)}</div>
                      </div>
                    </div>
                    <div className={styles.opportunity_data_information}>
                      <div className={styles.opportunity_tags_container}>
                        <FavIcon color="#2EBB55" />
                        <EyeIcon color="#AFAFAF" />
                      </div>
                    </div>
                  </div>
                </div>
              </div >
            </>
          ))
        )}
      </div>
    )
  }

  function BeatPackTitleInfo() {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <p className={styles.pack_title}>{packData?.basicInfo?.packName}</p>
          {/* <p className={styles.pack_title}>Music Dreams</p> */}
          <p className={styles.pack_track_number} onClick={() => goPackDetail(packData?._id, packData?.basicInfo?.packName)}>{`${packData?.basicInfo?.fileIds?.length} Track`}</p>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "12px", cursor: "pointer" }} onClick={() => setIsExpand(!isExpand)}>
          <p className={styles.pack_download_number} >{`${packData?.basicInfo?.downloaders?.length} Download`}</p>
          <img alt="file" src={FileIcon} />
        </div>
      </div>
    );
  }

  function BeatPackActions({ pack_id }) {
    return (
      <div className={styles.opportunity_data_information}>
        <div className={styles.opportunity_tags_container}>
          <div
            className={styles.opportunity_action_tags}
            style={{ cursor: "pointer" }}
            onClick={() => openSendModal(pack_id)}
          >
            <img
              alt="share"
              src={ShareIcon}
              className={styles.pack_icons}
            />
          </div>
          <div
            className={styles.opportunity_action_tags}
            style={{ cursor: "pointer" }}
          >
            <EyeIcon />
            <span style={{ marginLeft: "5px" }}>{packData?.basicInfo?.viewers?.length}</span>
          </div>
          <div
            className={styles.opportunity_action_tags}
            style={{ cursor: "pointer" }}
          >
            <img
              alt="trash"
              src={TrashIcon}
              className={styles.pack_icons}
              onClick={() => setDeleteModalOpen(true)}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default PackCard;
