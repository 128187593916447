import axios from "../../config/axiosInterceptor";
import { HTTP_STATUS } from "../../shared/constants";

export const fetchAllChats = async (userId) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_CHAT_URL}/fetch-user-chats?userId=${userId}`
    );

    if (status === HTTP_STATUS.OK) {
      return data;
    } else {
      throw new Error("Error while fetching all chats");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSingleChat = async (chatId, userId) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_CHAT_URL}/fetch-single-chat?chatId=${chatId}&userId=${userId}`
    );

    if (status === HTTP_STATUS.OK) {
      return data;
    } else {
      throw new Error("Error while fetching single chat");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendMessage = async (chatId, content, sender) => {
  try {
    const { status } = await axios.post(
      `${process.env.REACT_APP_CHAT_URL}/send-message`,
      {
        chatId,
        content,
        sender,
      }
    );

    if (status === 201) {
      return { success: true };
    } else {
      throw new Error("Error while sending message");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendSubmissionMessage = async (chatId, submissionId, sender) => {
  try {
    const { status } = await axios.post(
      `${process.env.REACT_APP_CHAT_URL}/chat/send-submission-message`,
      {
        chatId,
        submissionId,
        sender,
      }
    );

    if (status === 201) {
      return { success: true };
    } else {
      throw new Error("Error while sending message");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const markChatAsRead = async (chatId, userId) => {
  try {
    const { status } = await axios.post(
      `${process.env.REACT_APP_CHAT_URL}/mark-chat-as-read`,
      {
        chatId,
        userId,
      }
    );

    if (status === 200) {
      return { success: true };
    } else {
      throw new Error("Error while marking chat as read");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
