import { gql } from '@apollo/client';

export const getUserPaginated = gql`
  query {
    getAllUsersByUser(pageNo: 1) {
      success
      message
      users {
        _id
        firstname
        lastname
        email
        userName
        profilePic
        isSuspended
      }
      isTokenExpired
      isLastPage
    }
  }
`;

export const searchUsers = gql`
  query searchUsers($e: String!) {
    searchUsers(query: $e) {
      success
      users {
        firstname
        lastname
        userName
        profilePic
        email
        _id
      }
      message
    }
  }
`;

export const getUser = gql`
  query {
    getUser {
      success
      message
      isTokenExpired
      plan {
        planName
        priceId
      }
      currentPlan {
        planName
        priceId
        currentPeriodStart
        currentPeriodEnd
      }
      nextPlan {
        planName
        priceId
        nextPeriodStart
        nextPeriodEnd
      }
      user {
        profilePic
        coverPic
        tokens
        email
        phoneno
        subscriptionStatus
        cancel_at
        planId
      }
    }
  }
`;

export const resetUserPassword = gql`
  mutation ($password: String!) {
    updateProfile(Updateprofilebody: { password: $password }) {
      success
      message
      updatedUser {
        firstname
        lastname
        email
        userName
        credits
        accolades
        genre
        roleId {
          roleName
        }
        aboutMe
        beatSpecialties {
          name
        }
        loopSpecialties {
          name
        }
        experience {
          title
          description
          profileHeadline
          companyName
          startDate
          endDate
        }
      }
    }
  }
`;
export const UpdateUserImages = gql`
  mutation ($profilePic: String, $coverPic: String) {
    updateProfile(
      Updateprofilebody: { profilePic: $profilePic, coverPic: $coverPic }
    ) {
      success
      message
      updatedUser {
        firstname
        lastname
        email
        userName
        credits
        accolades
        genre
        aboutMe
        beatSpecialties {
          name
        }
        loopSpecialties {
          name
        }
        profilePic
        coverPic
        experience {
          title
          description
          profileHeadline
          companyName
          startDate
          endDate
        }
      }
    }
  }
`;

export const Publishing = gql`
  mutation attachDoYouPublish($email: String!, $doYouPublish: Boolean!) {
    attachDoYouPublish(email: $email, doYouPublish: $doYouPublish) {
      success
      message
    }
  }
`;

export const HowDoYouKnow = gql`
  mutation setHowDidYouKnow(
    $userId: String!
    $howDidYouKnow: String!
    $howDidYouKnowText: String
  ) {
    setHowDidYouKnow(
      userId: $userId
      howDidYouKnow: $howDidYouKnow
      howDidYouKnowText: $howDidYouKnowText
    ) {
      success
      message
    }
  }
`;

export const updateUserProfile = gql`
  mutation (
    $firstname: String
    $lastname: String
    $userName: String
    $spotifyUrl: String
    $countrycode: String
    $state: String
    $phoneno: Float
    $aboutMe: String
    $roleId: String
  ) {
    updateProfile(
      Updateprofilebody: {
        firstname: $firstname
        lastname: $lastname
        userName: $userName
        spotifyUrl: $spotifyUrl
        countrycode: $countrycode
        phoneno: $phoneno
        state: $state
        aboutMe: $aboutMe
        roleId: $roleId
      }
    ) {
      success
      message
      updatedUser {
        firstname
        lastname
        email
        userName
        credits
        accolades
        genre
        aboutMe
        spotifyUrl
        instaUrl
        countrycode
        state
        phoneno
        beatSpecialties {
          name
        }
        loopSpecialties {
          name
        }
        profilePic
        coverPic
        experience {
          title
          description
          profileHeadline
          companyName
          startDate
          endDate
        }
      }
    }
  }
`;

export const DOWNGRADETOFREEMIUM = gql`
  mutation DowngradeToFreemium($isFreemium: Boolean!, $isPaid: Boolean!) {
    downgradeToFreemium(isFreemium: $isFreemium, isPaid: $isPaid) {
      success
      message
    }
  }
`;
