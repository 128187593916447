import React, { memo, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  LinearProgress,
  Modal,
  Button,
  useTheme,
} from "@mui/material";

import { close_icon, saveprice } from "../../../../assets/smartlink";
import logo from "../../../../assets/Logo/logo.png";
// import { useEffect } from "react";
import { ProducerPaymentCard } from "./styles/PricePlanCardstyle";
import {
  Modelstyle,
  PaymentModelContentBox,
} from "../../socialintegrate/style";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { GET_PRODUCER_PLAN } from "../../../../Graphql_services/smartlink/SmartLink";
import { useQuery } from "@apollo/client";
import PaymentCheckoutModel from "./popups/PaymentCheckoutModel";
import PaymentDetailModel from "./popups/PaymentDetailModel";
import CongradulationModel from "./popups/CongradulationModel";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLISHERS_KEY}`);

const ProducerSubscriptionPopup = ({
  paymentOpen = false,
  setpaymentOpen = () => {},
}) => {
  const theme = useTheme();
  useEffect(() => {
    if (paymentOpen) {
      refetch();
    }
  }, [paymentOpen]);

  const { loading, error, data, refetch } = useQuery(GET_PRODUCER_PLAN, {
    context: { clientName: "smartlink" },
    fetchPolicy: "no-cache",
  });
  const PlanList = data?.getProducerPlan;
  const ProducerPaymentOptClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setpaymentOpen(!paymentOpen);
  };

  const [open, setOpen] = useState(false);
  const [Detailopen, setDetailOpen] = useState(false);
  const [Congradulationopen, setCongradulationOpen] = useState(false);
  const [selectedplanid, setselectedplanid] = useState("");
  const [paymentDetais, setpaymentDetais] = useState(null);
  const [selectedPlans, setselectedPlans] = useState(null);

  const PaymentCheckoutModelOpen = (id, price, name) => {
    setOpen(!open);
    setselectedplanid({ id, price });
    setselectedPlans(name);
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <Modal
          open={paymentOpen}
          onClose={ProducerPaymentOptClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <PaymentModelContentBox
            className="ModelContentBox"
            sx={{
              ...Modelstyle,
              width: { xs: "98%", sm: "900px" },
              padding: "35px 62px 91px 79px",
              borderRadius: "20px",
              background:
                "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
              backdropFilter: "blur(200px)",
              [theme.breakpoints.down("sm")]: {
                padding: "40px 20px 20px 20px",
                maxHeight: "700px",
              },
            }}
          >
            <img src={logo} alt="um-logo" className="green-logo" />
            <Box className="progress-box">
              <LinearProgress
                variant="determinate"
                value={100}
                sx={{ height: "5px" }}
              />
            </Box>
            <h3>Choose a Plan</h3>
            {loading && <p style={{ color: "#fff", padding: 0 }}>Loading...</p>}
            <Box sx={{ display: "flex", gap: "30px", width: "100%" }}>
              {PlanList &&
                PlanList?.success &&
                PlanList?.plans.map((values) => {
                  return (
                    <React.Fragment key={values?._id}>
                      {values?.interval === "month" && (
                        <Box className="producerpaymentcard-main">
                          <ProducerPaymentCard>
                            <Box className="header-box">
                              <h4>Elite</h4>
                              <span>monthly</span>
                            </Box>
                            <h5>Take your music career to the next level</h5>
                            <h6>$45</h6>
                            <Button
                              variant="contained"
                              className="card-btn"
                              fullWidth
                              onClick={() => {
                                PaymentCheckoutModelOpen(
                                  values?._id,
                                  values?.price,
                                  "Elite"
                                );
                              }}
                            >
                              Get Started
                            </Button>
                            <Box className="package-info-box">
                              <CheckCircleOutlineIcon className="tick-icon" />
                              <span>50 GB storage space</span>
                            </Box>
                            <Box className="package-info-box">
                              <CheckCircleOutlineIcon className="tick-icon-second" />
                              <span>
                                Unlimited Opportunity Submissions per month
                              </span>
                            </Box>
                          </ProducerPaymentCard>
                        </Box>
                      )}

                      {values?.interval === "year" && (
                        <ProducerPaymentCard>
                          <Box className="header-box">
                            <Box className="left">
                              <h4>Elite Pro</h4>
                              <span>Yearly</span>
                            </Box>
                            <Box className="saveprice-box">Save $240/yr</Box>
                            <img
                              className="saveprice-rectangle"
                              src={saveprice}
                              alt="saveprice-rectangle"
                            />
                          </Box>
                          <h5>Take your music career to the next level</h5>
                          <h6>$300</h6>
                          <Button
                            variant="contained"
                            className="card-btn"
                            fullWidth
                            onClick={() => {
                              PaymentCheckoutModelOpen(
                                values?._id,
                                values?.price,
                                "Elite Pro"
                              );
                            }}
                          >
                            Get Started
                          </Button>
                          <Box className="package-info-box">
                            <CheckCircleOutlineIcon className="tick-icon" />
                            <span>50 GB storage space</span>
                          </Box>
                          <Box className="package-info-box">
                            <CheckCircleOutlineIcon className="tick-icon-second" />
                            <span>Unlimited Opportunity Submissions</span>
                          </Box>
                        </ProducerPaymentCard>
                      )}
                    </React.Fragment>
                  );
                })}

              {PlanList && PlanList?.plans?.length === 0 && (
                <p style={{ color: "#fff", padding: 0 }}>No Plan Found!</p>
              )}
            </Box>
          </PaymentModelContentBox>
        </Modal>

        <PaymentCheckoutModel
          open={open}
          setOpen={setOpen}
          setDetailOpen={setDetailOpen}
          selectedplanid={selectedplanid}
          setpaymentDetais={setpaymentDetais}
        />
        <PaymentDetailModel
          open={Detailopen}
          setOpen={setDetailOpen}
          setCongradulationOpen={setCongradulationOpen}
          paymentDetais={paymentDetais}
          selectedPlans={selectedPlans}
        />
        <CongradulationModel
          open={Congradulationopen}
          setOpen={setCongradulationOpen}
          selectedPlans={selectedPlans}
          commingFrom="ProducerPage"
        />
      </Elements>
    </>
  );
};

export default memo(ProducerSubscriptionPopup);
