import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Heading,
  HStack,
  Text,
  Input,
  Divider,
} from 'native-base';
import DoneIcon from '@mui/icons-material/Done';

const ProgressTags = (props) => {
  const { index } = props;
  return (
    <Box w={'100%'} >
      <Box w={'100%'} alignSelf={'center'}>
        <Heading
          my={8}
          textTransform={'capitalize'}
          fontFamily={'Poppins'}
          fontSize={{
            xl: '40px',
            lg: '36px',
            md: '30px',
            base: '28px',
          }}
          fontWeight={500}
          textAlign={'left'}
          color={'#fff'}
        >
          Upload Sound Kits
        </Heading>
        <HStack space={{ lg: 10, base: 4 }}>
          <UploadTags number={1} name={'Basics'} index={index} />
          <UploadTags number={2} name={'Files'} index={index} />
          <UploadTags number={3} name={'Tags'} index={index} />
          <UploadTags number={4} name={'Collaborators'} index={index} />
          <UploadTags number={5} name={'Share'} index={index} />
        </HStack>
      </Box>
    </Box>
  );
};

const UploadTags = (props) => {
  const { name, number, index } = props;
  return (
    <Box>
      <HStack
        space={{ lg: 4, base: 2 }}
        direction={{ lg: 'row', base: 'column' }}
      >
        <Box
          w={{ '2xl': '30px', xl: '25px', lg: '20px', base: '20px' }}
          h={{ '2xl': '32px', xl: '27px', lg: '22px', base: '22px' }}
          rounded={'sm'}
          alignSelf={'center'}
          justifyContent={'center'}
          alignItems={'center'}
          bg={
            index > number
              ? '#2ebb5540'
              : index === number
              ? '#2ebb55'
              : '#1C1B1B'
          }
          _text={{
            fontFamily: 'Poppins',
            fontSize: { lg: '18px', base: '14px' },
            fontWeight: 'normal',
            color: index === number ? '#fff' : '#afafaf',
          }}
        >
          {index > number ? (
            <DoneIcon
              sx={{
                color: '#2EBB55',
                fontSize: 25,
                alignSelf: 'center',
              }}
            />
          ) : (
            number
          )}
        </Box>
        <Heading
          fontFamily={'Poppins'}
          fontWeight={'normal'}
          fontSize={{ lg: '24px', base: '16px' }}
          color={'#fff'}
          alignSelf={'center'}
        >
          {name}
        </Heading>
      </HStack>
    </Box>
  );
};

export default ProgressTags;
