import React, { memo, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modelstyle, ModelContentBox } from "../../socialintegrate/style";
import { Box, Button, IconButton, Modal } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { close_icon } from "../../../../assets/smartlink";
import { WelcomeMain, FormMain, SignUpFormMain } from "./styles/MainStyles";
import { SignUpMutation } from "../../../../Graphql_services/SignUpMutation";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { Notification } from "../../../NotifyMsg/Notification";
import LoginAlertPopup from "./Popups/LoginAlertPopup";

const SignUpPopup = ({ open = false, setOpen = () => {} }) => {
  const { id, type } = useParams();
  const [Alertopen, setAlertopen] = useState(false);
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const [signUp, { loading, error, data }] = useMutation(SignUpMutation);
  const CreateModelClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(!open);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    musicianName: Yup.string().required("Musician Name is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    // noOfMonthlyListeners: Yup.number()
    //   .typeError("Number of Monthly Listeners must be a number")
    //   .required("Number of Monthly Listeners is required"),
    // spotifyUrl: Yup.string()
    //   .url("Invalid URL")
    //   .required("Website URL is required"),
    noOfMonthlyListeners:
      type === "Artist"
        ? Yup.number()
            .typeError("Number of Monthly Listeners must be a number")
            .required("Number of Monthly Listeners is required")
        : Yup.mixed(),
    spotifyUrl:
      type === "Artist"
        ? Yup.string()
            .url("Invalid URL")
            .required("Spotify Profile link is required")
        : Yup.mixed(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const variables = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        password: values.password,
        phoneno: Number(values.phoneNumber),
        username: values.firstName + values.lastName,
        roleId: id,
        isPreSignUp: true,
        instaUrl: "",
        profilePic:
          "https://united-market.s3.us-east-2.amazonaws.com/fileupload/1701343537844/MicrosoftTeams-image%281%29.png",
        membershipType: "Freemium Membership",
      };
      if (type === "Artist") {
        variables.spotifyUrl = values.spotifyUrl;
        variables.noOfMonthlyListeners = values.noOfMonthlyListeners;
      }

      const res = await signUp({ variables });

      if (res?.data?.signUp?.success) {
        Notification("success", "User Successfully Registered");
        setAlertopen(true);
        // setTimeout(() => {
        //   navigate("/producer/auth/login");
        // }, 3000);
        resetForm();
      }

      if (!res?.data?.signUp?.success) {
        if (
          res?.data?.signUp?.message == "User Already Exists with this email"
        ) {
          Notification(
            "error",
            "A user already exists with this email address"
          );
        } else {
          Notification("error", res?.data?.signUp?.message);
        }
      }
    } catch (error) {
      Notification("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    musicianName: "",
    password: "",
    confirmPassword: "",
    spotifyUrl: "",
    noOfMonthlyListeners: "",
  };

  return (
    <>
      {/* <Modal
        open={true}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.50)",
          },
        }}
      > */}
      <ModelContentBox
        sx={{
          ...Modelstyle,
          width: { xs: "100%", sm: "700px" },
          padding: "2rem",
          borderRadius: "20px",
          background:
            "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
          backdropFilter: "blur(200px)",
        }}
        className="ModelContentBox"
      >
        <Box className="popup_header">
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            aria-label="settings"
            sx={{
              color: "#AFAFAF",
              backgroundColor: "none",
              fontSize: "2rem",
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <IconButton
            onClick={CreateModelClose}
            aria-label="settings"
            sx={{
              color: "#AFAFAF",
              backgroundColor: "none",
              fontSize: "2rem",
            }}
          >
            <img src={close_icon} alt="cancel-icon" />
          </IconButton>
        </Box>

        <WelcomeMain>
          <h6>Join Our Community</h6>
          <p>
            Sign up to get exclusive access to our services, updates, and
            special offers. Start your journey with us today.
          </p>
        </WelcomeMain>
        <FormMain className="signUpMain">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <SignUpFormMain className="producer_SignUpFormMain">
                  <div
                    className={`form__field ${
                      errors.firstName && touched.firstName ? "error" : ""
                    }`}
                  >
                    <label htmlFor="firstName">First Name:</label>
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="Enter your first name"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div
                    className={`form__field ${
                      errors.lastName && touched.lastName ? "error" : ""
                    }`}
                  >
                    <label htmlFor="lastName">Last Name:</label>
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Enter your last name"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="error"
                    />
                  </div>
                </SignUpFormMain>
                <SignUpFormMain>
                  <div
                    className={`form__field ${
                      errors.email && touched.email ? "error" : ""
                    }`}
                  >
                    <label htmlFor="email">Email:</label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div
                    className={`form__field ${
                      errors.phoneNumber && touched.phoneNumber ? "error" : ""
                    }`}
                  >
                    <label htmlFor="phoneNumber">Phone Number:</label>
                    <Field
                      type="number"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Enter your phone number"
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="error"
                    />
                  </div>
                </SignUpFormMain>
                <SignUpFormMain>
                  <div
                    className={`form__field ${
                      errors.musicianName && touched.musicianName ? "error" : ""
                    }`}
                  >
                    <label htmlFor="musicianName">Musician Name:</label>
                    <Field
                      type="text"
                      id="musicianName"
                      name="musicianName"
                      placeholder="Enter musician name"
                    />
                    <ErrorMessage
                      name="musicianName"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div
                    className={`form__field ${
                      errors.password && touched.password ? "error" : ""
                    }`}
                  >
                    <label htmlFor="password">Password:</label>
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </div>
                </SignUpFormMain>
                <SignUpFormMain>
                  <div
                    className={`form__field ${
                      errors.confirmPassword && touched.confirmPassword
                        ? "error"
                        : ""
                    }`}
                  >
                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <Field
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Enter your password"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error"
                    />
                  </div>
                </SignUpFormMain>

                {type === "Artist" && (
                  <SignUpFormMain>
                    <div
                      className={`form__field  ${
                        errors.spotifyUrl && touched.spotifyUrl ? "error" : ""
                      }`}
                    >
                      <label htmlFor="spotifyUrl">Spotify Profile link</label>
                      <Field
                        type="text"
                        id="spotifyUrl"
                        name="spotifyUrl"
                        placeholder="Spotify Profile link"
                      />
                      <ErrorMessage
                        name="spotifyUrl"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div
                      className={`form__field  ${
                        errors.noOfMonthlyListeners &&
                        touched.noOfMonthlyListeners
                          ? "error"
                          : ""
                      }`}
                    >
                      <label htmlFor="noOfMonthlyListeners">
                        # of Monthly Listeners
                      </label>
                      <Field
                        type="number"
                        id="noOfMonthlyListeners"
                        name="noOfMonthlyListeners"
                        placeholder="Enter monthly listeners"
                      />
                      <ErrorMessage
                        name="noOfMonthlyListeners"
                        component="div"
                        className="error"
                      />
                    </div>
                  </SignUpFormMain>
                )}

                <Button
                  type="submit"
                  sx={{
                    marginTop: "22px !important",
                  }}
                  disabled={isloading}
                >
                  {isloading ? "Loading..." : "Sign Up"}
                </Button>

                <Box
                  className="othre__acount"
                  sx={{ marginTop: "25px !important" }}
                >
                  <span>Do you have an account?</span>
                  <Button
                    onClick={() => {
                      navigate("/producer/auth/login");
                    }}
                  >
                    Sign In
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </FormMain>
      </ModelContentBox>
      {/* </Modal> */}

      <LoginAlertPopup
        Alertopen={Alertopen}
        AlertopenCallBack={setAlertopen}
        commingFrom="signup"
      />
    </>
  );
};

export default memo(SignUpPopup);
