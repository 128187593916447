import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  quote_up,
  slider_image1,
  slider_image2,
  slider_image3,
  slider_image4,
} from "../../assets/smartlink";
import { SliderMainBox } from "./styles/CosignsStyle";

const data = [
  {
    slideImage: slider_image1,
    quoteIcon: quote_up,
    flipContent:
      "Partnering up with United Market put me in the position to gain major label resources and technology to empower myself as a musician to take my career to the next level. There’s a real family-like environment here where I know they always have my best interest in mind and want to help me succeed. I decided to rock with them.",
    name: "Saviii 3rd",
    desc: "",
  },
  {
    slideImage: slider_image2,
    quoteIcon: quote_up,
    flipContent:
      "We are thrilled to partner with United Market to enhance their product offering,” said Symphonic CEO Jorge Brea. “The team is extremely dedicated, hard working, and understands what is needed for new, developing, and established artists. We’re excited to build new products right alongside them!",
    name: "Jorge Brea CEO at",
    desc: "Symphonic Distribution",
  },
  {
    slideImage: slider_image3,
    quoteIcon: quote_up,
    flipContent:
      "United Market is a platform that is really going to change the game. It’s hard to keep up with bookings, and communicate with clients, so U.M is a game-changer since you can do everything from one app. It also allows me to host calls with my team to keep the business all in one place.",
    name: "Chris illes Minnesota Twins",
    desc: "",
  },
  {
    slideImage: slider_image1,
    quoteIcon: quote_up,
    flipContent:
      "We at the Minnesota Twins are huge fans of what United Market is building and look forward to incorporating their incredible music and artists into our game-day playlists",
    name: "Saviii 3rd",
    desc: "",
  },
  {
    slideImage: slider_image4,
    quoteIcon: quote_up,
    flipContent:
      "I joined united market because it looked like a great platform for networking as well as a good platform to get my music out",
    name: "Hoodrichkeem",
    desc: "",
  },
  {
    slideImage: slider_image2,
    quoteIcon: quote_up,
    flipContent:
      "I joined united market because it looked like a great platform for networking as well as a good platform to get my music out",
    name: "Saviii 3rd",
    desc: "",
  },
  {
    slideImage: slider_image4,
    quoteIcon: quote_up,
    flipContent:
      "I joined united market because it looked like a great platform for networking as well as a good platform to get my music out",
    name: "Hoodrichkeem",
    desc: "",
  },
  {
    slideImage: slider_image3,
    quoteIcon: quote_up,
    flipContent:
      "United Market is a platform that is really going to change the game. It’s hard to keep up with bookings, and communicate with clients, so U.M is a game-changer since you can do everything from one app. It also allows me to host calls with my team to keep the business all in one place.",
    name: "Chris illes Minnesota Twins",
    desc: "",
  },
];
const CosignSlider = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "40px",
    slidesToShow: 6,
    speed: 500,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <SliderMainBox>
      <Slider {...settings}>
        {data.map((items, id) => (
          <div className="img_box" key={id}>
            <img
              src={items.slideImage}
              alt="slider_image"
              className="slide-img"
            />
            <div className="content_main">
              <div className="top_box">
                <img src={items.quoteIcon} alt="quote_up" />
                <p>{items.flipContent}</p>
              </div>
              <div className="bottom_box">
                <h5>-{items.name}</h5>
                <h6>{items.desc}</h6>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </SliderMainBox>
  );
};

export default CosignSlider;
