import React from 'react';
import styles from './../styles.module.css';
export const AddStem = () => {
  return (
    <svg
      className={styles.addPhoto}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M47.4525 13.8404H15.8175C15.2942 13.8369 14.7933 13.6275 14.4233 13.2574C14.0532 12.8874 13.8438 12.3865 13.8403 11.8632C13.8438 11.3399 14.0532 10.839 14.4233 10.4689C14.7933 10.0989 15.2942 9.88944 15.8175 9.88599H47.4525C47.9758 9.88944 48.4767 10.0989 48.8468 10.4689C49.2168 10.839 49.4263 11.3399 49.4297 11.8632C49.4263 12.3865 49.2168 12.8874 48.8468 13.2574C48.4767 13.6275 47.9758 13.8369 47.4525 13.8404V13.8404Z'
        fill='#AFAFAF'
      />
      <path
        d='M39.5437 7.24966H23.7262C23.2029 7.24621 22.702 7.03679 22.3319 6.66674C21.9619 6.29669 21.7525 5.79579 21.749 5.27248C21.7525 4.74916 21.9619 4.24826 22.3319 3.87821C22.702 3.50816 23.2029 3.29874 23.7262 3.29529L39.5437 3.29529C40.067 3.29874 40.5679 3.50816 40.938 3.87821C41.308 4.24826 41.5174 4.74916 41.5209 5.27248C41.5174 5.79579 41.308 6.29669 40.938 6.66674C40.5679 7.03679 40.067 7.24621 39.5437 7.24966V7.24966Z'
        fill='#AFAFAF'
      />
      <path
        d='M36.6704 42.7361C36.2375 42.7382 35.823 42.911 35.5169 43.2171C35.2108 43.5232 35.038 43.9377 35.0359 44.3706C35.038 44.8034 35.2108 45.2179 35.5169 45.524C35.823 45.8301 36.2375 46.003 36.6704 46.005C37.1032 46.003 37.5177 45.8301 37.8238 45.524C38.1299 45.2179 38.3028 44.8034 38.3048 44.3706C38.3028 43.9377 38.1299 43.5232 37.8238 43.2171C37.5177 42.911 37.1032 42.7382 36.6704 42.7361V42.7361Z'
        fill='#AFAFAF'
      />
      <path
        d='M25.0707 46.7694C25.069 46.5565 25.0253 46.346 24.9423 46.15C24.8592 45.9539 24.7383 45.7762 24.5865 45.6268C24.4348 45.4775 24.2551 45.3595 24.0577 45.2797C23.8603 45.1998 23.6491 45.1596 23.4362 45.1613C23.0034 45.1634 22.5889 45.3363 22.2828 45.6423C21.9767 45.9484 21.8038 46.3629 21.8018 46.7958C21.8038 47.2286 21.9767 47.6432 22.2828 47.9492C22.5889 48.2553 23.0034 48.4282 23.4362 48.4303C23.8702 48.4174 24.2824 48.2375 24.587 47.928C24.8915 47.6186 25.0648 47.2035 25.0707 46.7694V46.7694Z'
        fill='#AFAFAF'
      />
      <path
        d='M47.4525 18.4537H15.8175C13.0233 18.4621 10.346 19.5757 8.37024 21.5515C6.39447 23.5273 5.28081 26.2046 5.27246 28.9987L5.27246 47.4525C5.28081 50.2466 6.39447 52.9239 8.37024 54.8997C10.346 56.8755 13.0233 57.9891 15.8175 57.9975H47.4525C50.2466 57.9891 52.9239 56.8755 54.8997 54.8997C56.8754 52.9239 57.9891 50.2466 57.9975 47.4525V28.9987C57.9891 26.2046 56.8754 23.5273 54.8997 21.5515C52.9239 19.5757 50.2466 18.4621 47.4525 18.4537V18.4537ZM42.259 32.8477V44.3681C42.259 44.4208 42.2327 44.4472 42.2327 44.4999C42.2143 45.6841 41.8231 46.8324 41.1148 47.7816C40.4065 48.7308 39.417 49.4326 38.287 49.7874C37.157 50.1421 35.944 50.1317 34.8203 49.7576C33.6965 49.3835 32.7193 48.6648 32.0274 47.7035C31.3355 46.7423 30.9641 45.5874 30.966 44.4031C30.968 43.2187 31.3431 42.0651 32.0382 41.1061C32.7333 40.1472 33.7129 39.4316 34.8378 39.0612C35.9628 38.6909 37.1759 38.6844 38.3047 39.0428V35.4312L29.0251 37.962V46.7671C29.0411 47.9648 28.6771 49.1367 27.9853 50.1146C27.2935 51.0925 26.3096 51.8259 25.1749 52.2096C24.0402 52.5933 22.813 52.6074 21.6698 52.25C20.5265 51.8926 19.526 51.182 18.8119 50.2203C18.0978 49.2586 17.7068 48.0953 17.6952 46.8976C17.6837 45.6998 18.052 44.5292 18.7474 43.5539C19.4427 42.5786 20.4294 41.8488 21.5655 41.4693C22.7016 41.0898 23.9288 41.0802 25.0707 41.4418V32.215C25.0438 31.1106 25.395 30.0301 26.0663 29.1527C26.7375 28.2753 27.6885 27.6536 28.7614 27.3906L35.9584 25.4134C36.7138 25.1465 37.5222 25.0646 38.3158 25.1747C39.1094 25.2848 39.8649 25.5836 40.5191 26.0461C41.121 26.5566 41.5927 27.203 41.8952 27.9318C42.1978 28.6607 42.3225 29.4512 42.259 30.2378V32.8477Z'
        fill='#AFAFAF'
      />
    </svg>
  );
};
