import React from "react";

const ReleaseImage = () => {
  return (
    <svg
      width="354"
      height="354"
      viewBox="0 0 354 354"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.4745 167.844L38.8126 192.426C38.836 192.847 39.0189 193.243 39.324 193.534C39.6292 193.824 40.0337 193.988 40.4551 193.991L84.691 194.316C84.9091 194.318 85.1255 194.277 85.3277 194.195C85.5299 194.113 85.7139 193.992 85.8691 193.839C86.0244 193.685 86.1478 193.503 86.2324 193.302C86.3169 193.101 86.3609 192.885 86.3619 192.667L86.5035 173.551C86.5053 173.332 86.4639 173.115 86.3814 172.912C86.299 172.71 86.1773 172.525 86.0232 172.37C85.8692 172.215 85.6859 172.091 85.484 172.007C85.2821 171.923 85.0655 171.88 84.8467 171.88H57.6383C57.4209 171.88 57.2056 171.837 57.005 171.753C56.8044 171.669 56.6226 171.546 56.4701 171.391L51.776 166.704C51.4689 166.394 51.0514 166.219 50.6149 166.216L39.1453 166.131C38.9217 166.129 38.7 166.172 38.4936 166.258C38.2871 166.344 38.1002 166.471 37.9441 166.631C37.788 166.791 37.6659 166.981 37.5852 167.19C37.5045 167.398 37.4668 167.621 37.4745 167.844Z"
        fill="#EBEBEB"
      />
      <path
        d="M86.5382 168.977L38.2043 168.623L37.9926 197.502L86.3265 197.856L86.5382 168.977Z"
        fill="#F5F5F5"
      />
      <path
        d="M34.338 171.187L37.8072 201.334C37.8382 201.593 37.9625 201.831 38.1567 202.004C38.351 202.178 38.6018 202.274 38.8621 202.276L85.9654 202.623C86.2346 202.627 86.4953 202.528 86.6941 202.347C86.8928 202.165 87.0146 201.914 87.0344 201.646L89.6257 171.549C89.6384 171.402 89.6206 171.254 89.5735 171.114C89.5264 170.975 89.4509 170.847 89.3519 170.738C89.2528 170.629 89.1323 170.541 88.9979 170.481C88.8635 170.421 88.7181 170.389 88.5708 170.387L35.4 169.998C35.2495 169.998 35.1007 170.03 34.9633 170.091C34.8259 170.153 34.7031 170.243 34.6028 170.355C34.5026 170.467 34.4272 170.6 34.3815 170.743C34.3359 170.886 34.3211 171.038 34.338 171.187Z"
        fill="#EBEBEB"
      />
      <path
        d="M238.313 172.092L239.467 193.262C239.485 193.625 239.642 193.967 239.906 194.217C240.169 194.468 240.519 194.607 240.883 194.607L278.973 194.89C279.349 194.89 279.709 194.741 279.974 194.475C280.24 194.21 280.389 193.85 280.389 193.474L280.51 176.999C280.51 176.623 280.36 176.263 280.095 175.998C279.829 175.732 279.469 175.583 279.094 175.583H255.673C255.485 175.584 255.299 175.548 255.125 175.476C254.951 175.404 254.793 175.298 254.661 175.165L250.632 171.073C250.368 170.807 250.009 170.657 249.634 170.655L239.757 170.584C239.561 170.58 239.366 170.617 239.184 170.692C239.003 170.767 238.839 170.879 238.703 171.021C238.567 171.162 238.462 171.331 238.395 171.515C238.328 171.7 238.3 171.896 238.313 172.092Z"
        fill="#EBEBEB"
      />
      <path
        d="M280.559 173.069L238.943 172.764L238.761 197.635L280.376 197.94L280.559 173.069Z"
        fill="#F5F5F5"
      />
      <path
        d="M235.615 174.968L238.596 200.923C238.62 201.147 238.726 201.353 238.894 201.504C239.061 201.654 239.277 201.737 239.502 201.737L280.064 202.035C280.295 202.036 280.518 201.949 280.688 201.793C280.859 201.637 280.964 201.423 280.984 201.192L283.214 175.28C283.226 175.153 283.211 175.026 283.171 174.906C283.131 174.785 283.066 174.675 282.981 174.581C282.896 174.487 282.792 174.412 282.676 174.36C282.56 174.309 282.435 174.282 282.308 174.281L236.529 173.941C236.399 173.942 236.271 173.969 236.152 174.022C236.034 174.075 235.928 174.153 235.842 174.25C235.756 174.347 235.691 174.461 235.652 174.585C235.613 174.709 235.6 174.839 235.615 174.968Z"
        fill="#EBEBEB"
      />
      <path
        d="M314.366 33.375H246.674V145.423H314.366V33.375Z"
        fill="#E0E0E0"
      />
      <path
        d="M312.115 33.375H244.423V145.423H312.115V33.375Z"
        fill="#EBEBEB"
      />
      <path
        d="M309.233 35.6465H247.304V143.149H309.233V35.6465Z"
        fill="white"
      />
      <path
        d="M297.169 49.6172H259.093V129.182H297.169V49.6172Z"
        fill="#EBEBEB"
      />
      <path
        d="M259.128 101.031C259.043 93.5974 265.727 86.3404 273.083 85.2997C276.623 84.8111 280.326 85.505 283.604 84.1598C289.459 81.7597 291.321 73.9858 297.211 71.6777L297.48 129.181H259.128V101.031Z"
        fill="#E0E0E0"
      />
      <path
        d="M310.451 176.929C310.451 176.929 304.844 176.526 305.36 189.107C305.877 201.688 307.159 208.379 307.159 208.379C307.159 208.379 306.996 211.168 304.837 210.623C302.677 210.078 298.613 207.359 296.61 209.391C294.606 211.423 295.264 214.722 298.026 218.524C300.787 222.326 306.522 230.872 307.045 231.651C307.569 232.429 307.753 233.838 306.224 233.697C304.695 233.555 294.358 230.058 292.992 235.063C291.625 240.069 301.056 249.393 302.068 250.427C303.081 251.46 303.13 252.126 302.72 252.756C302.309 253.386 298.946 251.878 295.965 251C292.985 250.122 289.381 249.634 288.829 252.586C288.276 255.538 292.439 264.176 307.994 273.26L315.478 276.998L322.558 272.481C337.128 261.861 340.392 252.876 339.55 249.995C338.707 247.113 335.167 247.963 332.286 249.131C329.404 250.299 326.218 252.133 325.744 251.545C325.269 250.958 325.262 250.306 326.161 249.166C327.061 248.027 335.514 237.838 333.652 232.974C331.79 228.111 321.864 232.649 320.349 232.939C318.834 233.229 318.933 231.785 319.329 230.978C319.726 230.171 324.625 221.066 326.99 217.016C329.355 212.966 329.772 209.674 327.485 207.812C325.199 205.95 321.517 209.101 319.421 209.858C317.326 210.616 316.887 207.862 316.887 207.862C316.887 207.862 317.496 201.072 316.752 188.505C316.009 175.938 310.331 176.95 310.331 176.95"
        fill="#EBEBEB"
      />
      <path
        d="M310.897 188.193C310.897 188.193 310.897 188.285 310.897 188.455C310.897 188.625 310.897 188.901 310.961 189.227C311.003 189.935 311.074 190.919 311.159 192.201C311.329 194.778 311.548 198.516 311.803 203.132C312.32 212.336 312.922 225.08 313.531 239.205C314.14 253.329 314.72 266.059 315.18 275.313C315.414 279.922 315.605 283.653 315.733 286.244C315.796 287.526 315.846 288.524 315.881 289.218C315.881 289.551 315.881 289.806 315.917 289.99V290.259C315.917 290.259 315.917 290.167 315.881 289.99C315.846 289.813 315.881 289.551 315.825 289.218C315.775 288.51 315.711 287.533 315.626 286.251C315.449 283.674 315.23 279.936 314.975 275.32C314.465 266.116 313.864 253.372 313.248 239.212C312.632 225.052 312.065 212.364 311.605 203.104C311.371 198.495 311.18 194.764 311.046 192.172C310.982 190.891 310.932 189.893 310.897 189.199C310.897 188.866 310.897 188.611 310.897 188.427C310.893 188.349 310.893 188.271 310.897 188.193Z"
        fill="#E0E0E0"
      />
      <path
        d="M302.139 213.412C302.309 213.524 302.466 213.655 302.606 213.802C302.889 214.063 303.314 214.453 303.789 214.941C304.78 215.918 306.104 217.306 307.541 218.864L311.201 222.871L312.32 224.075C312.467 224.215 312.598 224.372 312.709 224.542C312.539 224.434 312.382 224.305 312.242 224.16C311.952 223.898 311.534 223.501 311.06 223.013C310.076 222.021 308.766 220.62 307.336 219.062L303.647 215.083L302.528 213.879C302.379 213.741 302.249 213.584 302.139 213.412Z"
        fill="#E0E0E0"
      />
      <path
        d="M313.453 224.294C313.396 224.237 316.129 221.37 319.563 217.922C322.997 214.474 325.822 211.663 325.878 211.72C325.935 211.776 323.195 214.644 319.768 218.092C316.342 221.54 313.51 224.35 313.453 224.294Z"
        fill="#E0E0E0"
      />
      <path
        d="M313.417 245.979C313.577 245.847 313.753 245.735 313.941 245.647L315.407 244.825C316.639 244.117 318.338 243.169 320.179 242.036C322.02 240.903 323.655 239.834 324.838 239.041L326.254 238.114C326.418 237.988 326.596 237.881 326.785 237.795C326.647 237.949 326.49 238.085 326.317 238.198C326.006 238.446 325.546 238.793 324.972 239.211C323.825 240.039 322.204 241.151 320.356 242.277C318.508 243.402 316.78 244.351 315.52 245.002C314.89 245.328 314.373 245.583 314.012 245.746C313.822 245.843 313.623 245.921 313.417 245.979Z"
        fill="#E0E0E0"
      />
      <path
        d="M301.02 238.391C301.204 238.444 301.378 238.526 301.537 238.631C301.863 238.808 302.33 239.063 302.897 239.389C304.036 240.033 305.601 240.947 307.314 241.966C309.028 242.986 310.585 243.927 311.697 244.621L313.007 245.449C313.172 245.546 313.326 245.66 313.467 245.789C313.284 245.734 313.11 245.653 312.95 245.549C312.624 245.379 312.164 245.117 311.591 244.791C310.451 244.147 308.886 243.233 307.173 242.214C305.46 241.194 303.909 240.253 302.79 239.559L301.481 238.73C301.316 238.634 301.161 238.52 301.02 238.391Z"
        fill="#E0E0E0"
      />
      <path
        d="M298.564 257.527C298.792 257.615 299.012 257.721 299.222 257.846L300.964 258.809C302.429 259.63 304.44 260.784 306.628 262.073C308.815 263.361 310.84 264.544 312.292 265.407L313.984 266.448C314.198 266.564 314.4 266.702 314.586 266.859C314.354 266.779 314.133 266.672 313.927 266.54L312.228 265.584C310.77 264.763 308.752 263.609 306.564 262.321C304.376 261.032 302.316 259.835 300.9 258.986L299.201 257.945C298.974 257.829 298.761 257.688 298.564 257.527Z"
        fill="#E0E0E0"
      />
      <path
        d="M314.281 266.533C314.481 266.363 314.7 266.215 314.933 266.094L316.752 264.989C318.289 264.055 320.405 262.752 322.699 261.251C324.993 259.75 327.046 258.355 328.519 257.322L330.268 256.111C330.472 255.95 330.693 255.81 330.926 255.693C330.744 255.881 330.545 256.052 330.332 256.203C329.942 256.515 329.362 256.954 328.639 257.492C327.188 258.561 325.156 259.998 322.855 261.492C320.554 262.986 318.416 264.267 316.851 265.159C316.072 265.605 315.435 265.959 314.989 266.193C314.763 266.326 314.526 266.439 314.281 266.533Z"
        fill="#E0E0E0"
      />
      <path
        d="M325.531 279.447H304.829L311.584 330.593H318.77L325.531 279.447Z"
        fill="#E0E0E0"
      />
      <path
        d="M305.898 285.734C305.925 285.8 305.963 285.86 306.012 285.911C306.144 286.072 306.314 286.196 306.507 286.272C306.865 286.401 307.248 286.446 307.625 286.404C308.003 286.362 308.367 286.235 308.688 286.032C309.523 285.571 310.253 284.665 311.371 284.262C312.614 283.863 313.955 283.891 315.18 284.339C316.426 284.767 317.615 285.344 318.72 286.06C319.646 286.637 320.608 287.153 321.602 287.603C322.279 287.904 322.993 288.112 323.726 288.219C323.925 288.247 324.126 288.261 324.328 288.262C324.462 288.262 324.54 288.262 324.54 288.262C323.56 288.111 322.607 287.823 321.708 287.405C320.734 286.929 319.789 286.397 318.876 285.812C317.756 285.068 316.545 284.47 315.272 284.035C313.977 283.568 312.563 283.554 311.258 283.992C310.705 284.219 310.193 284.534 309.743 284.927C309.369 285.259 308.974 285.566 308.561 285.847C308.27 286.048 307.939 286.184 307.59 286.245C307.242 286.306 306.885 286.291 306.543 286.201C306.302 286.085 306.084 285.927 305.898 285.734Z"
        fill="#F5F5F5"
      />
      <path
        d="M306.656 295.151C306.656 295.151 306.819 294.938 307.145 294.606C307.612 294.141 308.175 293.783 308.794 293.558C309.715 293.218 310.695 293.069 311.676 293.119C312.886 293.212 314.089 293.378 315.279 293.614C316.495 293.848 317.738 293.899 318.968 293.763C319.972 293.637 320.951 293.358 321.871 292.935C322.487 292.653 323.071 292.307 323.613 291.901C323.823 291.765 324.009 291.595 324.165 291.398C323.405 291.903 322.605 292.344 321.772 292.715C320.867 293.095 319.914 293.345 318.94 293.459C317.736 293.569 316.523 293.51 315.336 293.282C314.133 293.041 312.915 292.883 311.69 292.807C310.673 292.775 309.661 292.954 308.716 293.331C308.076 293.588 307.506 293.994 307.052 294.514C306.935 294.657 306.831 294.811 306.741 294.974C306.67 295.087 306.649 295.144 306.656 295.151Z"
        fill="#F5F5F5"
      />
      <path
        d="M323.181 300.192C323.014 300.055 322.822 299.954 322.614 299.895C322.062 299.687 321.482 299.563 320.894 299.526C320.027 299.469 319.157 299.519 318.303 299.675C317.34 299.831 316.306 300.093 315.216 300.326C314.207 300.564 313.173 300.68 312.136 300.673C311.293 300.651 310.462 300.471 309.686 300.142C308.977 299.818 308.345 299.348 307.831 298.762C307.908 298.965 308.026 299.151 308.178 299.307C308.575 299.75 309.056 300.109 309.594 300.362C310.388 300.739 311.251 300.95 312.129 300.985C313.193 301.011 314.257 300.902 315.294 300.659C316.391 300.426 317.418 300.149 318.359 299.951C319.192 299.793 320.039 299.719 320.887 299.732C321.668 299.785 322.439 299.94 323.181 300.192Z"
        fill="#F5F5F5"
      />
      <path
        d="M308.483 307.271C309.117 307.002 309.819 306.933 310.493 307.073C311.23 307.248 311.944 307.512 312.617 307.858C313.477 308.268 314.402 308.522 315.35 308.609C317.082 308.762 318.818 308.36 320.306 307.462C320.751 307.192 321.164 306.874 321.538 306.513C321.681 306.385 321.805 306.237 321.906 306.074C321.346 306.505 320.762 306.904 320.158 307.271C318.692 308.073 317.022 308.425 315.357 308.283C313.396 308.17 311.895 307.002 310.536 306.867C310.011 306.775 309.472 306.822 308.971 307.002C308.789 307.049 308.621 307.142 308.483 307.271Z"
        fill="#F5F5F5"
      />
      <path
        d="M309.637 317.106C310.231 317.122 310.82 317.006 311.364 316.767C312.405 316.413 313.694 315.62 315.195 315.018C315.896 314.737 316.641 314.581 317.396 314.558C318.009 314.558 318.614 314.696 319.166 314.961C319.667 315.235 320.103 315.611 320.448 316.066C320.42 315.907 320.352 315.758 320.25 315.634C319.999 315.269 319.667 314.969 319.28 314.756C318.706 314.434 318.061 314.259 317.403 314.246C316.605 314.249 315.814 314.405 315.074 314.706C313.517 315.329 312.242 316.122 311.279 316.54C310.316 316.958 309.63 317.05 309.637 317.106Z"
        fill="#F5F5F5"
      />
      <path
        d="M310.656 321.672C311.07 321.876 311.511 322.019 311.966 322.096C314.125 322.615 316.346 322.835 318.565 322.748C319.025 322.757 319.485 322.702 319.931 322.585C319.931 322.479 317.807 322.585 315.272 322.316C312.738 322.047 310.678 321.572 310.656 321.672Z"
        fill="#F5F5F5"
      />
      <path
        d="M94.5322 45.6367H26.8686V143.709H94.5322V45.6367Z"
        fill="#E0E0E0"
      />
      <path
        d="M91.1408 45.6367H23.4773V143.709H91.1408V45.6367Z"
        fill="#F5F5F5"
      />
      <path
        d="M82.7298 56.2578H31.8813V133.083H82.7298V56.2578Z"
        fill="white"
      />
      <path
        d="M82.7298 133.103C82.7298 133.103 82.7298 132.968 82.7298 132.727C82.7298 132.487 82.7298 132.111 82.7298 131.637C82.7298 130.667 82.7298 129.258 82.7298 127.389C82.7298 123.707 82.7298 118.348 82.6873 111.565C82.6873 98.0213 82.6378 78.8345 82.6024 56.2422L82.7723 56.4121L31.86 56.4404L32.0441 56.2563C32.0441 85.2419 32.0441 111.962 32.0441 133.089L31.8954 132.94L68.9097 133.018L79.1332 133.053H81.8165H82.744H81.8448H79.1898H69.0017L31.86 133.23H31.7113V133.103C31.7113 111.976 31.7113 85.256 31.7113 56.2705V56.0723H31.86H82.7015H82.8714V56.2422C82.8714 78.8982 82.8077 98.0991 82.7865 111.679C82.7865 118.44 82.7865 123.792 82.744 127.467C82.744 129.287 82.744 130.688 82.744 131.644C82.744 132.111 82.744 132.465 82.744 132.713C82.744 132.961 82.7298 133.103 82.7298 133.103Z"
        fill="#E0E0E0"
      />
      <path
        d="M91.332 45.6367C90.9864 46.256 90.5835 46.8415 90.1284 47.3855C89.3425 48.4404 88.2239 49.8706 86.9566 51.414C85.6893 52.9574 84.4927 54.331 83.6077 55.3009C83.161 55.8538 82.6632 56.3635 82.1209 56.8231C82.5008 56.2229 82.9319 55.6568 83.4095 55.131L86.6663 51.1804C87.9053 49.6865 89.0169 48.3484 89.9231 47.2297C90.3466 46.6596 90.818 46.1267 91.332 45.6367Z"
        fill="#E0E0E0"
      />
      <path
        d="M82.9918 132.842C85.5182 136.861 88.4919 140.58 91.856 143.929L82.9918 132.842Z"
        fill="white"
      />
      <path
        d="M91.856 143.93C91.2942 143.512 90.777 143.037 90.3125 142.514C87.9555 140.107 85.8468 137.47 84.0184 134.641C83.6123 134.075 83.2681 133.467 82.9918 132.828C83.4538 133.36 83.8751 133.925 84.252 134.52C84.96 135.568 86.0433 136.998 87.2681 138.528C88.493 140.057 89.6611 141.36 90.5178 142.351C91.0027 142.843 91.45 143.371 91.856 143.93Z"
        fill="#E0E0E0"
      />
      <path
        d="M23.4631 143.632L32.3273 132.693L23.4631 143.632Z"
        fill="white"
      />
      <path
        d="M32.3273 132.693C31.0114 134.629 29.5934 136.494 28.0793 138.279C26.6499 140.145 25.1208 141.932 23.4985 143.632C24.8201 141.703 26.2403 139.843 27.7536 138.06C29.1862 136.195 30.7128 134.404 32.3273 132.693Z"
        fill="#E0E0E0"
      />
      <path
        d="M23.4773 45.6367C25.0026 47.2703 26.4372 48.9863 27.7749 50.7768C29.2145 52.4899 30.5548 54.2841 31.7892 56.1505C30.2621 54.5061 28.8274 52.7783 27.4917 50.975C26.0567 49.2705 24.7165 47.4884 23.4773 45.6367Z"
        fill="#E0E0E0"
      />
      <path
        d="M66.8918 71.8555H49.8644V120.198H66.8918V71.8555Z"
        fill="#E0E0E0"
      />
      <path
        d="M179.329 29.2324H126.151V84.6688H179.329V29.2324Z"
        fill="#E0E0E0"
      />
      <path
        d="M175.726 29.2324H122.548V84.6688H175.726V29.2324Z"
        fill="#FAFAFA"
      />
      <path
        d="M175.747 84.6906H122.534V29.2188H175.747V84.6906ZM122.569 84.6552H175.711V29.2541H122.569V84.6552Z"
        fill="#E0E0E0"
      />
      <path
        d="M168.582 36.6875H129.699V77.2205H168.582V36.6875Z"
        fill="white"
      />
      <path
        d="M129.699 77.4902C129.422 77.8952 129.102 78.2681 128.743 78.6018C128.12 79.2602 127.249 80.1452 126.265 81.1081C125.281 82.071 124.389 82.9348 123.73 83.5436C123.394 83.8994 123.016 84.2132 122.604 84.4782C122.877 84.0705 123.198 83.6973 123.56 83.3666C124.176 82.7153 125.054 81.8232 126.038 80.8603C127.022 79.8974 127.914 79.0337 128.58 78.4177C128.915 78.0658 129.291 77.7545 129.699 77.4902Z"
        fill="#E0E0E0"
      />
      <path
        d="M168.582 77.3906C168.989 77.6675 169.364 77.988 169.701 78.3464C170.352 78.9695 171.237 79.8403 172.207 80.8173C173.177 81.7944 174.026 82.7006 174.635 83.3591C174.991 83.6952 175.305 84.0732 175.57 84.4848C175.162 84.2121 174.789 83.8912 174.458 83.529C173.807 82.9059 172.915 82.0351 171.952 81.051C170.989 80.0669 170.125 79.1748 169.516 78.5093C169.161 78.1751 168.848 77.7995 168.582 77.3906Z"
        fill="#E0E0E0"
      />
      <path
        d="M168.504 36.8158C168.782 36.4098 169.105 36.0369 169.467 35.7043C170.083 35.0458 170.954 34.1608 171.938 33.198C172.922 32.2351 173.814 31.3713 174.472 30.7624C174.803 30.4001 175.176 30.0791 175.584 29.8066C175.311 30.2143 174.99 30.5875 174.628 30.9182C174.012 31.5766 173.134 32.4616 172.15 33.4316C171.166 34.4016 170.274 35.2582 169.609 35.8671C169.279 36.2256 168.908 36.5441 168.504 36.8158Z"
        fill="#E0E0E0"
      />
      <path
        d="M129.564 36.893C129.153 36.6217 128.777 36.3007 128.445 35.9372C127.794 35.3141 126.909 34.4433 125.946 33.4663C124.983 32.4892 124.119 31.5901 123.511 30.9245C123.155 30.5884 122.841 30.2104 122.576 29.7988C122.984 30.0715 123.357 30.3924 123.688 30.7546C124.339 31.3777 125.231 32.2485 126.194 33.2326C127.157 34.2167 128.021 35.1159 128.637 35.7743C128.988 36.1096 129.3 36.4851 129.564 36.893Z"
        fill="#E0E0E0"
      />
      <path
        d="M168.504 77.2217C168.504 77.2217 168.504 77.1509 168.504 77.0235C168.504 76.896 168.504 76.6978 168.504 76.4571C168.504 75.9473 168.504 75.2181 168.504 74.2694C168.504 72.3507 168.504 69.5541 168.504 66.007C168.504 58.927 168.454 48.7955 168.426 36.6887L168.504 36.8162H129.734L129.904 36.6462C129.904 51.5142 129.904 65.4406 129.904 77.1792L129.755 77.0306L157.834 77.1084L165.672 77.1722H167.754H168.292H167.796H165.736H157.884L129.713 77.25H129.564V77.1014C129.564 65.3627 129.564 51.4434 129.564 36.5684V36.3984H129.741H168.511H168.66V36.5471C168.66 48.6964 168.603 58.842 168.582 65.9645C168.582 69.5045 168.582 72.2799 168.547 74.1915C168.547 75.126 168.547 75.8482 168.547 76.3509C168.547 76.5845 168.547 76.7615 168.547 76.896C168.543 77.0057 168.529 77.1148 168.504 77.2217Z"
        fill="#E0E0E0"
      />
      <path
        d="M155.633 50.4668H142.655V63.4444H155.633V50.4668Z"
        fill="#EBEBEB"
      />
      <path
        d="M98.3695 253.392L99.7006 277.84C99.7222 278.26 99.9036 278.656 100.208 278.947C100.512 279.237 100.915 279.401 101.336 279.404L145.324 279.723C145.541 279.725 145.756 279.684 145.956 279.603C146.157 279.522 146.34 279.402 146.495 279.25C146.649 279.098 146.772 278.917 146.857 278.718C146.942 278.518 146.986 278.304 146.988 278.087L147.129 259.056C147.13 258.839 147.088 258.623 147.006 258.422C146.924 258.22 146.802 258.037 146.649 257.883C146.496 257.728 146.314 257.605 146.113 257.521C145.913 257.437 145.697 257.393 145.48 257.393H118.42C118.204 257.392 117.99 257.348 117.791 257.265C117.591 257.181 117.411 257.058 117.259 256.904L112.586 252.238C112.278 251.934 111.865 251.762 111.432 251.757L100.026 251.672C99.8029 251.671 99.5817 251.715 99.376 251.802C99.1702 251.889 98.9843 252.016 98.8294 252.177C98.6745 252.338 98.5538 252.529 98.4748 252.738C98.3958 252.946 98.3599 253.169 98.3695 253.392Z"
        fill="#EBEBEB"
      />
      <path
        d="M147.159 254.52L99.0938 254.168L98.8832 282.891L146.948 283.243L147.159 254.52Z"
        fill="#F5F5F5"
      />
      <path
        d="M95.2543 256.714L98.7023 286.697C98.7318 286.955 98.8549 287.192 99.0482 287.364C99.2414 287.537 99.4912 287.632 99.7501 287.632L146.591 287.979C146.859 287.98 147.117 287.881 147.314 287.702C147.512 287.522 147.636 287.275 147.66 287.009L150.238 257.075C150.249 256.928 150.231 256.781 150.183 256.643C150.136 256.504 150.06 256.377 149.961 256.268C149.862 256.16 149.742 256.073 149.608 256.014C149.474 255.954 149.329 255.922 149.183 255.921L96.288 255.531C96.1402 255.534 95.9947 255.568 95.8607 255.631C95.7267 255.693 95.6072 255.783 95.5099 255.894C95.4126 256.005 95.3396 256.136 95.2956 256.277C95.2516 256.418 95.2375 256.567 95.2543 256.714Z"
        fill="#EBEBEB"
      />
      <path
        d="M174.798 267.164L176.129 291.696C176.151 292.116 176.332 292.512 176.636 292.803C176.94 293.094 177.344 293.257 177.765 293.26L221.852 293.586C222.069 293.588 222.285 293.547 222.487 293.465C222.689 293.384 222.872 293.264 223.027 293.111C223.183 292.958 223.306 292.777 223.391 292.577C223.476 292.376 223.521 292.161 223.523 291.944L223.664 272.87C223.665 272.652 223.623 272.436 223.54 272.234C223.457 272.032 223.336 271.849 223.182 271.694C223.028 271.539 222.845 271.417 222.643 271.333C222.442 271.249 222.226 271.206 222.008 271.206H194.891C194.674 271.207 194.459 271.165 194.259 271.082C194.058 271 193.876 270.878 193.723 270.725L189.05 266.045C188.741 265.739 188.324 265.567 187.889 265.564L176.462 265.479C176.241 265.477 176.022 265.519 175.818 265.603C175.614 265.688 175.429 265.812 175.274 265.969C175.118 266.126 174.996 266.313 174.915 266.518C174.833 266.723 174.793 266.943 174.798 267.164Z"
        fill="#EBEBEB"
      />
      <path
        d="M223.689 268.3L175.518 267.947L175.307 296.734L223.478 297.087L223.689 268.3Z"
        fill="#F5F5F5"
      />
      <path
        d="M171.669 270.491L175.131 300.539C175.16 300.797 175.283 301.035 175.476 301.208C175.669 301.382 175.919 301.479 176.179 301.48L223.126 301.827C223.394 301.827 223.652 301.726 223.85 301.545C224.047 301.365 224.171 301.117 224.195 300.85L226.779 270.852C226.791 270.706 226.773 270.559 226.725 270.42C226.677 270.282 226.602 270.154 226.503 270.046C226.404 269.938 226.284 269.851 226.15 269.791C226.016 269.731 225.871 269.7 225.725 269.698L172.752 269.309C172.6 269.306 172.45 269.335 172.31 269.395C172.171 269.455 172.046 269.544 171.944 269.655C171.841 269.767 171.764 269.9 171.716 270.044C171.669 270.188 171.653 270.34 171.669 270.491Z"
        fill="#EBEBEB"
      />
      <path
        d="M66.2405 225.746C58.9764 214.645 60.0384 198.786 68.676 188.746C71.3664 185.631 75.1259 182.919 79.2323 183.273C83.3387 183.627 86.8149 187.231 88.0964 191.253C89.3779 195.274 88.9248 199.621 88.1743 203.77C87.1265 209.576 85.3423 215.516 81.335 219.835C77.3277 224.153 71.423 228.578 66.2121 225.746"
        fill="#2EBB55"
      />
      <path
        d="M68.4353 279.187C67.1313 275.959 66.8762 272.402 67.7059 269.021C68.5357 265.639 70.4083 262.605 73.0585 260.347C74.7294 258.98 76.91 257.926 78.9916 258.478C81.0731 259.03 82.5316 261.211 82.836 263.391C83.1404 265.572 82.5032 267.781 81.7457 269.848C80.6766 272.751 79.2323 275.654 76.7897 277.509C74.3471 279.364 70.8708 281.113 68.4636 279.187"
        fill="#2EBB55"
      />
      <path
        d="M52.746 275.371C54.7425 265.501 49.56 254.669 40.6463 250.039C37.878 248.623 34.4796 247.745 31.6901 249.146C28.9005 250.548 27.4421 254.053 27.6899 257.239C27.9377 260.425 29.4457 263.377 31.152 266.068C33.5167 269.855 36.4195 273.544 40.4622 275.449C44.5049 277.353 49.914 278.819 52.7601 275.371"
        fill="#2EBB55"
      />
      <path
        d="M54.0062 248.756C57.4825 237.542 51.5919 223.976 41.7649 217.512C38.7205 215.516 34.8619 214.128 31.4564 215.388C28.0509 216.648 25.9623 220.606 25.8561 224.337C25.7499 228.069 27.1801 231.658 28.8297 235.007C31.1378 239.694 34.0831 244.324 38.5577 247.043C43.0322 249.762 50.2892 252.424 54.0062 248.749"
        fill="#2EBB55"
      />
      <path
        d="M59.1675 303.569C59.1087 303.395 59.0707 303.214 59.0543 303.031C58.9905 302.62 58.9127 302.103 58.8206 301.466C58.7711 301.119 58.7215 300.758 58.6649 300.334C58.6082 299.909 58.4949 299.477 58.4029 299.002C58.2259 298.047 58.0347 296.949 57.6949 295.767C57.5462 295.165 57.3975 294.542 57.2418 293.898C57.086 293.253 56.8595 292.588 56.6541 291.894C56.2789 290.478 55.7267 289.062 55.2381 287.526C53.9961 284.242 52.5514 281.038 50.9123 277.932C49.2303 274.856 47.36 271.886 45.312 269.04C44.3208 267.766 43.4287 266.505 42.48 265.422L41.1419 263.829L39.8321 262.413C39.0179 261.493 38.2107 260.735 37.5098 260.055L36.5469 259.1L35.6903 258.349L34.515 257.287C34.2459 257.039 34.1114 256.905 34.1256 256.891C34.284 256.985 34.4333 257.094 34.5716 257.216L35.8106 258.208L36.6956 258.916L37.6939 259.836C38.4019 260.501 39.2373 261.252 40.0728 262.158L41.4109 263.574L42.7844 265.16C43.7544 266.236 44.6677 267.489 45.6731 268.771C47.7542 271.627 49.6462 274.617 51.3371 277.72C52.988 280.845 54.4307 284.076 55.6559 287.391C56.1656 288.942 56.7108 290.393 57.0719 291.795C57.2701 292.503 57.4542 293.169 57.6383 293.82C57.8223 294.471 57.9285 295.101 58.0631 295.703C58.3604 296.9 58.5233 298.004 58.679 298.967C58.7569 299.448 58.8348 299.895 58.8985 300.312C58.9622 300.73 58.9835 301.105 59.0189 301.452C59.0755 302.096 59.1251 302.62 59.1605 303.031C59.1807 303.21 59.183 303.39 59.1675 303.569Z"
        fill="#263238"
      />
      <path
        d="M58.9127 305.587C58.9033 305.512 58.9033 305.436 58.9127 305.361C58.9127 305.191 58.9127 304.978 58.9551 304.716C59.0118 304.107 59.0897 303.3 59.1888 302.224C59.2454 301.679 59.3021 301.077 59.3658 300.426C59.4295 299.774 59.4366 299.045 59.472 298.302C59.5286 296.758 59.6773 295.003 59.6207 293.063C59.6207 292.093 59.6207 291.08 59.6207 290.018C59.6207 288.956 59.5145 287.859 59.4578 286.719C59.3799 284.439 59.0755 282.025 58.7994 279.469C58.0494 273.965 56.8994 268.522 55.3585 263.185C53.7387 257.854 51.732 252.648 49.3547 247.609C48.1935 245.322 47.1315 243.12 45.9563 241.166C45.3757 240.182 44.8589 239.205 44.2925 238.334C43.7261 237.463 43.188 236.557 42.6287 235.764C41.6162 234.1 40.5047 232.713 39.6551 231.452C39.2019 230.829 38.7913 230.242 38.3948 229.711C37.9983 229.18 37.6019 228.734 37.262 228.295L35.7115 226.334C35.5557 226.121 35.4212 225.951 35.315 225.817C35.2088 225.682 35.1876 225.633 35.1947 225.626C35.2017 225.619 35.2584 225.675 35.3575 225.781L35.7823 226.27L37.4036 228.181L38.5718 229.597C38.9825 230.114 39.4002 230.702 39.8675 231.318C40.7737 232.564 41.8782 233.945 42.9119 235.566L44.604 238.108C45.1845 239 45.7085 239.977 46.2961 240.94C47.4926 242.894 48.5688 245.089 49.7441 247.382C52.1581 252.413 54.1865 257.62 55.8116 262.958C57.3646 268.327 58.5077 273.807 59.2313 279.349C59.4932 281.912 59.7906 284.34 59.8543 286.627C59.8968 287.774 59.9888 288.878 59.9888 289.94C59.9888 291.002 59.9888 292.022 59.9888 292.999C59.9888 294.946 59.8472 296.702 59.7693 298.245C59.7127 299.017 59.6844 299.732 59.6207 300.369C59.5569 301.006 59.4649 301.629 59.4012 302.167L59.0826 304.653C59.0826 304.915 59.0118 305.127 58.9905 305.297C58.9786 305.397 58.9523 305.495 58.9127 305.587Z"
        fill="#263238"
      />
      <path
        d="M77.8092 196.682C77.7844 196.753 77.7536 196.822 77.7171 196.888L77.3985 197.468L76.1595 199.656C75.8976 200.138 75.5719 200.661 75.2675 201.256L74.2975 203.21C73.9435 203.918 73.5895 204.626 73.1789 205.448C72.7682 206.269 72.4709 207.118 72.039 208.018C71.6071 208.917 71.2106 209.858 70.8071 210.85C70.4035 211.841 70.0212 212.896 69.6035 213.972C69.3981 214.517 69.1857 215.062 68.9733 215.621L68.3857 217.356C67.9963 218.524 67.5857 219.728 67.175 220.96C65.6316 225.916 64.1731 231.474 62.927 237.357C61.6809 243.241 60.888 248.947 60.3003 254.123C59.7127 259.298 59.3799 263.985 59.2737 267.921C59.1746 269.89 59.1534 271.674 59.1463 273.239C59.1392 274.803 59.1109 276.148 59.1463 277.253C59.1817 278.357 59.1463 279.172 59.1463 279.795C59.1463 280.057 59.1463 280.276 59.1463 280.453C59.157 280.528 59.157 280.604 59.1463 280.68C59.1361 280.604 59.1361 280.528 59.1463 280.453C59.1463 280.276 59.1463 280.064 59.1038 279.795C59.1038 279.179 59.033 278.329 58.9835 277.26C58.9339 276.191 58.8914 274.803 58.8914 273.239C58.8914 271.674 58.8914 269.883 58.9481 267.907C59.0118 263.964 59.3729 259.27 59.8755 254.073C60.3782 248.876 61.2915 243.198 62.4951 237.293C63.6987 231.389 65.1997 225.838 66.7431 220.854C67.1609 219.622 67.5786 218.418 67.9751 217.243C68.1804 216.662 68.3786 216.082 68.5769 215.515L69.2211 213.866L70.4601 210.743C70.8708 209.745 71.3168 208.811 71.7133 207.911C72.1098 207.012 72.4992 206.148 72.8886 205.348L74.0426 203.118L75.0551 201.178C75.3737 200.584 75.7064 200.067 75.9825 199.592L77.3065 197.419C77.4481 197.192 77.5614 197.008 77.6605 196.859C77.7596 196.711 77.8021 196.675 77.8092 196.682Z"
        fill="#263238"
      />
      <path
        d="M75.3383 264.793C75.1198 265.267 74.8616 265.722 74.5666 266.152C74.0356 266.995 73.3063 268.276 72.4426 269.82C71.5788 271.363 70.6442 273.303 69.6884 275.484C68.7326 277.664 67.8335 280.086 67.0193 282.684C66.2051 285.282 65.6033 287.824 65.1643 290.139C64.7254 292.455 64.4563 294.571 64.251 296.349C64.0457 298.126 63.9678 299.577 63.9253 300.597C63.9267 301.116 63.884 301.635 63.7979 302.147C63.7368 301.63 63.7178 301.109 63.7412 300.589C63.7412 299.584 63.7412 298.133 63.9182 296.341C64.0815 294.243 64.3533 292.154 64.7324 290.083C65.6533 284.999 67.1842 280.045 69.292 275.328C70.269 273.162 71.246 271.257 72.124 269.699C73.0019 268.142 73.7948 266.91 74.3754 266.089C74.663 265.633 74.9849 265.2 75.3383 264.793Z"
        fill="#263238"
      />
      <path
        d="M44.9084 298.578H76.7967L72.9381 331.139H48.8803L44.9084 298.578Z"
        fill="#455A64"
      />
      <path
        d="M46.0766 327.627L48.7812 331.139H72.9381L75.7206 327.733L46.0766 327.627Z"
        fill="#263238"
      />
      <path
        d="M75.5507 308.15C75.5507 308.284 68.9379 308.39 60.7747 308.39C52.6115 308.39 46.02 308.284 46.02 308.15C46.02 308.015 52.6327 307.916 60.796 307.916C68.9592 307.916 75.5507 308.022 75.5507 308.15Z"
        fill="white"
      />
      <path
        d="M75.5507 310.325C75.5507 310.452 68.9379 310.558 60.7747 310.558C52.6115 310.558 46.02 310.452 46.02 310.325C46.02 310.197 52.6327 310.084 60.796 310.084C68.9592 310.084 75.5507 310.19 75.5507 310.325Z"
        fill="white"
      />
      <path
        d="M75.1542 312.42C75.1542 312.555 68.7822 312.661 60.8951 312.661C53.008 312.661 46.636 312.555 46.636 312.42C46.636 312.286 53.008 312.18 60.8951 312.18C68.7822 312.18 75.1542 312.293 75.1542 312.42Z"
        fill="white"
      />
      <path
        d="M326.969 331.174C326.969 331.281 257.917 331.358 172.759 331.358C87.6008 331.358 18.5354 331.281 18.5354 331.174C18.5354 331.068 87.5725 330.99 172.759 330.99C257.946 330.99 326.969 331.075 326.969 331.174Z"
        fill="#263238"
      />
      <path
        d="M314.31 106.095C311.931 103.086 311.881 97.5993 311.959 96.1337C312.143 92.799 304.709 73.3361 286.287 81.3719C279.681 81.3719 279.575 87.8926 278.322 89.2024C277.069 90.5122 275.674 91.8786 275.341 93.6628C274.761 96.8276 277.763 99.9003 276.998 103.023C276.715 104.191 275.929 105.189 275.377 106.258C274.782 107.214 274.315 108.772 274.569 109.635C274.889 110.478 275.31 111.28 275.823 112.021C276.341 112.77 276.579 113.677 276.495 114.584C276.205 116.184 274.371 117.076 273.847 118.627C273.323 120.177 274.315 121.721 275.185 123.059C276.056 124.397 276.892 126.089 276.191 127.526C275.726 128.239 275.15 128.873 274.485 129.403C273.588 130.336 272.986 131.512 272.755 132.785C272.523 134.058 272.672 135.371 273.182 136.561L283.802 134.437C284.274 129.391 284.746 124.34 285.218 119.285C288.319 120.057 291.71 118.075 293.183 117.699L294.988 116.722C298.939 117.43 303.201 117.168 306.663 115.151C310.125 113.133 315.258 115.717 314.586 111.759C314.21 109.862 315.52 107.617 314.31 106.095Z"
        fill="#263238"
      />
      <path
        d="M302.231 87.6794L287.809 83.4314C283.165 82.7234 279.837 88.3874 279.348 93.0319C278.81 98.2215 278.364 104.579 278.825 108.48C279.745 116.339 285.685 117.571 285.685 117.571C285.685 117.571 284.644 128.694 284.552 131.476L302.316 122.357L304.56 90.561C304.602 89.8766 304.386 89.2016 303.955 88.6684C303.524 88.1352 302.909 87.7824 302.231 87.6794Z"
        fill="#FFD3C1"
      />
      <path
        d="M280.248 99.4117C280.234 99.6719 280.323 99.9272 280.495 100.123C280.666 100.319 280.908 100.44 281.168 100.46C281.295 100.475 281.423 100.465 281.546 100.431C281.669 100.396 281.784 100.337 281.883 100.257C281.983 100.177 282.066 100.078 282.126 99.9657C282.187 99.8534 282.225 99.73 282.237 99.6029C282.251 99.3426 282.162 99.0874 281.99 98.8916C281.818 98.6958 281.577 98.575 281.317 98.555C281.19 98.5383 281.061 98.5474 280.938 98.5818C280.814 98.6162 280.699 98.6752 280.599 98.7553C280.5 98.8353 280.417 98.9347 280.356 99.0475C280.296 99.1603 280.259 99.2842 280.248 99.4117Z"
        fill="#263238"
      />
      <path
        d="M279.71 97.124C280.384 97.1734 281.055 97.2584 281.72 97.3789C282.361 97.6386 282.987 97.9317 283.596 98.2568C283.667 98.2568 283.596 98.0232 283.299 97.74C282.877 97.36 282.373 97.0815 281.826 96.9258C281.297 96.7636 280.736 96.732 280.191 96.8338C279.816 96.9258 279.66 97.0603 279.71 97.124Z"
        fill="#263238"
      />
      <path
        d="M290.23 100.226C290.219 100.487 290.309 100.742 290.482 100.937C290.655 101.133 290.897 101.253 291.158 101.273C291.285 101.288 291.414 101.277 291.537 101.242C291.66 101.206 291.774 101.146 291.874 101.066C291.973 100.986 292.056 100.886 292.116 100.773C292.177 100.661 292.215 100.537 292.227 100.41C292.237 100.149 292.146 99.8949 291.973 99.6997C291.801 99.5046 291.559 99.3835 291.3 99.3618C291.172 99.3471 291.044 99.3579 290.921 99.3936C290.798 99.4292 290.683 99.489 290.584 99.5694C290.484 99.6498 290.402 99.7493 290.341 99.8619C290.28 99.9746 290.243 100.098 290.23 100.226Z"
        fill="#263238"
      />
      <path
        d="M290.415 98.5408C290.57 98.6116 291.342 98.0947 292.539 97.9248C293.735 97.7549 294.698 98.0168 294.811 97.9248C294.924 97.8328 294.698 97.7195 294.28 97.5991C293.68 97.4387 293.053 97.4049 292.439 97.5C291.841 97.573 291.267 97.7812 290.761 98.1089C290.429 98.3213 290.337 98.5054 290.415 98.5408Z"
        fill="#263238"
      />
      <path
        d="M286.69 105.57C286.132 105.365 285.548 105.234 284.956 105.18C284.68 105.145 284.425 105.074 284.389 104.89C284.377 104.601 284.456 104.316 284.616 104.076C284.899 103.417 285.204 102.73 285.515 102.008C286.761 99.0772 287.682 96.6558 287.561 96.6062C287.441 96.5567 286.336 98.9002 285.083 101.831C284.786 102.539 284.496 103.247 284.22 103.906C284.034 104.232 283.969 104.613 284.035 104.982C284.072 105.079 284.13 105.167 284.205 105.239C284.28 105.311 284.369 105.366 284.467 105.4C284.619 105.448 284.776 105.477 284.935 105.485C285.514 105.585 286.104 105.613 286.69 105.57Z"
        fill="#263238"
      />
      <path
        d="M285.685 117.528C289.316 117.8 292.948 117.016 296.142 115.27C296.05 115.27 292.942 120.7 285.522 119.595L285.685 117.528Z"
        fill="#F4AF9B"
      />
      <path
        d="M287.044 108.184C287.26 107.934 287.536 107.743 287.845 107.63C288.155 107.516 288.488 107.482 288.814 107.533C289.051 107.564 289.279 107.643 289.485 107.765C289.69 107.886 289.869 108.048 290.011 108.241C290.147 108.425 290.224 108.646 290.23 108.876C290.237 109.105 290.173 109.331 290.046 109.522C289.866 109.706 289.636 109.832 289.384 109.886C289.132 109.94 288.87 109.92 288.63 109.826C288.129 109.624 287.672 109.326 287.285 108.949C287.167 108.859 287.068 108.745 286.995 108.616C286.955 108.557 286.934 108.488 286.934 108.418C286.934 108.347 286.955 108.278 286.995 108.219"
        fill="#F4AF9B"
      />
      <path
        d="M289.834 106.442C289.657 106.442 289.607 107.596 288.574 108.381C287.54 109.167 286.301 108.99 286.287 109.153C286.273 109.316 286.556 109.394 287.08 109.429C287.763 109.465 288.437 109.259 288.984 108.849C289.509 108.452 289.862 107.869 289.968 107.22C290.032 106.732 289.912 106.434 289.834 106.442Z"
        fill="#263238"
      />
      <path
        d="M290.549 94.7102C290.648 95.0005 291.724 94.9085 292.985 95.1138C294.245 95.3191 295.25 95.7014 295.427 95.4466C295.505 95.3191 295.342 95.043 294.939 94.7386C294.402 94.3693 293.789 94.1271 293.145 94.0302C292.501 93.9333 291.843 93.9843 291.222 94.1792C290.754 94.3421 290.514 94.5686 290.549 94.7102Z"
        fill="#263238"
      />
      <path
        d="M280.871 94.0091C281.048 94.264 281.798 94.0091 282.69 94.0657C283.582 94.1224 284.333 94.2923 284.517 94.0657C284.602 93.9454 284.517 93.6976 284.17 93.4498C283.734 93.159 283.221 93.0039 282.697 93.0039C282.173 93.0039 281.661 93.159 281.225 93.4498C280.906 93.6409 280.786 93.8887 280.871 94.0091Z"
        fill="#263238"
      />
      <path
        d="M286.11 82.3906C291.919 83.3049 297.597 84.9117 303.024 87.1767C304.284 87.7077 305.601 88.3237 306.309 89.4848C307.017 90.6459 306.96 92.2601 306.84 93.7328L305.035 114.697L304.284 114.654C302.16 115.008 301.976 113.904 300.426 112.353C299.659 111.567 299.087 110.613 298.752 109.568C298.418 108.522 298.332 107.413 298.5 106.328C298.811 104.424 299.916 102.512 299.321 100.664C298.535 98.2286 295.201 97.3648 294.401 94.9364C293.94 93.5204 294.464 91.9203 293.926 90.5326C293.388 89.1449 292.107 88.4086 290.811 87.913C289.515 87.4174 288.121 87.0068 287.052 86.1076C285.982 85.2085 285.324 83.5447 286.11 82.3906Z"
        fill="#263238"
      />
      <path
        d="M238.787 68.3438H181.637C179.311 68.3438 177.425 70.2298 177.425 72.5564V118.491C177.425 120.818 179.311 122.704 181.637 122.704H238.787C241.114 122.704 243 120.818 243 118.491V72.5564C243 70.2298 241.114 68.3438 238.787 68.3438Z"
        fill="#263238"
      />
      <path
        d="M210.212 103.921C215.432 103.921 219.664 99.6895 219.664 94.4694C219.664 89.2493 215.432 85.0176 210.212 85.0176C204.992 85.0176 200.76 89.2493 200.76 94.4694C200.76 99.6895 204.992 103.921 210.212 103.921Z"
        fill="#2EBB55"
      />
      <path
        d="M207.94 97.2367V91.6577C207.939 91.5962 207.955 91.5357 207.986 91.4821C208.016 91.4286 208.06 91.3839 208.113 91.3525C208.166 91.321 208.226 91.3039 208.287 91.3028C208.349 91.3017 208.41 91.3167 208.464 91.3462L213.42 94.1782C213.474 94.21 213.519 94.2555 213.55 94.3102C213.582 94.3648 213.598 94.4267 213.598 94.4897C213.598 94.5527 213.582 94.6146 213.55 94.6692C213.519 94.7239 213.474 94.7694 213.42 94.8012L208.464 97.5624C208.409 97.5952 208.346 97.6122 208.282 97.6116C208.219 97.6111 208.156 97.593 208.102 97.5594C208.048 97.5257 208.004 97.4778 207.976 97.4209C207.947 97.364 207.934 97.3003 207.94 97.2367Z"
        fill="white"
      />
      <path
        d="M269.521 305.532L271.164 321.285C271.164 321.285 255.701 328.237 255.715 331.197L286.372 329.129L284.828 304.441L269.521 305.532Z"
        fill="#455A64"
      />
      <g opacity="0.3">
        <path
          d="M269.521 305.532L271.164 321.285C271.164 321.285 255.701 328.237 255.715 331.197L286.372 329.129L284.828 304.441L269.521 305.532Z"
          fill="black"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M280.545 319.309C280.846 319.378 281.11 319.556 281.288 319.808C281.465 320.06 281.544 320.369 281.508 320.675C281.449 320.979 281.276 321.249 281.025 321.43C280.773 321.61 280.462 321.687 280.156 321.645C279.837 321.569 279.557 321.379 279.371 321.11C279.184 320.841 279.103 320.512 279.143 320.187C279.237 319.877 279.447 319.615 279.73 319.457C280.012 319.299 280.345 319.256 280.658 319.337"
          fill="white"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M286.365 329.108L286.117 326.623L256.813 329.54C256.813 329.54 255.489 330.248 255.708 331.176L286.365 329.108Z"
          fill="white"
        />
      </g>
      <path
        d="M270.647 321.163C270.647 321.311 271.419 321.325 272.184 321.757C272.948 322.189 273.387 322.791 273.522 322.727C273.656 322.664 273.373 321.807 272.431 321.311C271.49 320.816 270.605 321.028 270.647 321.163Z"
        fill="#263238"
      />
      <path
        d="M267.525 322.707C267.525 322.856 268.162 323.068 268.714 323.663C269.267 324.258 269.422 324.923 269.606 324.909C269.79 324.895 269.79 324.038 269.09 323.309C268.389 322.58 267.518 322.565 267.525 322.707Z"
        fill="#263238"
      />
      <path
        d="M265.953 326.679C266.095 326.679 266.25 325.971 265.812 325.15C265.373 324.328 264.65 324.074 264.594 324.201C264.537 324.328 265.004 324.739 265.351 325.397C265.698 326.056 265.804 326.679 265.953 326.679Z"
        fill="#263238"
      />
      <path
        d="M270.676 317.531C270.746 317.659 271.419 317.411 272.269 317.411C273.118 317.411 273.798 317.63 273.862 317.496C273.925 317.361 273.267 316.873 272.261 316.887C271.256 316.901 270.591 317.418 270.676 317.531Z"
        fill="#263238"
      />
      <path
        d="M270.399 314.353C270.912 314.455 271.443 314.416 271.936 314.24C272.483 314.105 273.011 313.903 273.507 313.638C273.802 313.483 274.086 313.308 274.357 313.114C274.524 313.007 274.672 312.873 274.796 312.718C274.886 312.604 274.935 312.463 274.935 312.318C274.935 312.172 274.886 312.031 274.796 311.918C274.715 311.819 274.612 311.74 274.495 311.689C274.378 311.637 274.251 311.614 274.123 311.62C273.927 311.63 273.734 311.67 273.55 311.741C273.228 311.843 272.915 311.974 272.615 312.13C272.114 312.4 271.658 312.746 271.263 313.157C270.598 313.865 270.392 314.438 270.449 314.466C270.506 314.495 270.831 314.02 271.518 313.426C271.918 313.082 272.36 312.791 272.835 312.562C273.114 312.43 273.403 312.319 273.699 312.229C274.017 312.123 274.293 312.102 274.407 312.229C274.52 312.357 274.449 312.307 274.407 312.406C274.312 312.515 274.205 312.612 274.088 312.696C273.826 312.888 273.564 313.057 273.302 313.213C272.852 313.478 272.378 313.701 271.886 313.879C271.38 314.001 270.883 314.16 270.399 314.353Z"
        fill="#263238"
      />
      <path
        d="M270.612 314.551C270.871 314.091 270.978 313.56 270.916 313.036C270.903 312.467 270.793 311.904 270.591 311.372C270.473 311.055 270.321 310.751 270.137 310.466C270.041 310.268 269.895 310.099 269.714 309.975C269.533 309.851 269.323 309.776 269.104 309.758C268.968 309.769 268.838 309.819 268.73 309.902C268.622 309.985 268.541 310.097 268.495 310.225C268.432 310.411 268.392 310.603 268.374 310.799C268.3 311.734 268.601 312.661 269.21 313.376C269.861 314.133 270.498 314.268 270.506 314.218C270.513 314.169 270.01 313.885 269.5 313.149C269.203 312.726 269.003 312.242 268.913 311.733C268.873 311.439 268.873 311.142 268.913 310.848C268.913 310.515 269.04 310.246 269.167 310.268C269.295 310.289 269.592 310.487 269.741 310.735C269.907 310.992 270.051 311.262 270.173 311.542C270.381 312.025 270.517 312.535 270.576 313.057C270.647 313.956 270.527 314.53 270.612 314.551Z"
        fill="#263238"
      />
      <path
        d="M266.859 190.24C266.859 190.24 253.28 251.893 252.862 257.401C252.444 262.909 269.698 310.43 269.698 310.43L284.566 307.598C284.566 307.598 283.625 237.414 283.15 236.041C282.676 234.667 286.938 200.166 286.938 200.166L266.859 190.24Z"
        fill="#263238"
      />
      <path
        d="M276.347 306.268L276.509 322.098C276.509 322.098 260.466 327.585 260.225 330.517L290.938 330.835L291.696 306.615L276.347 306.268Z"
        fill="#455A64"
      />
      <g opacity="0.6">
        <path
          d="M286.032 321.016C286.319 321.111 286.561 321.307 286.714 321.567C286.868 321.828 286.922 322.134 286.867 322.432C286.78 322.723 286.585 322.972 286.323 323.126C286.061 323.281 285.749 323.331 285.451 323.267C285.141 323.163 284.881 322.947 284.72 322.662C284.559 322.377 284.509 322.043 284.581 321.724C284.701 321.422 284.934 321.18 285.23 321.048C285.526 320.915 285.862 320.904 286.167 321.016"
          fill="white"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M290.924 330.835V328.811L261.479 328.988C261.479 328.988 260.098 329.547 260.225 330.517L290.924 330.835Z"
          fill="white"
        />
      </g>
      <path
        d="M276.014 321.936C276.014 322.084 276.764 322.169 277.494 322.644C278.223 323.118 278.591 323.784 278.733 323.727C278.874 323.67 278.669 322.799 277.784 322.212C276.899 321.624 275.985 321.794 276.014 321.936Z"
        fill="#263238"
      />
      <path
        d="M272.757 323.182C272.722 323.331 273.366 323.6 273.854 324.244C274.343 324.888 274.485 325.568 274.633 325.568C274.782 325.568 274.895 324.718 274.272 323.925C273.649 323.133 272.764 323.04 272.757 323.182Z"
        fill="#263238"
      />
      <path
        d="M270.831 326.99C270.966 326.99 271.192 326.281 270.831 325.453C270.47 324.625 269.776 324.278 269.706 324.398C269.635 324.519 270.06 324.972 270.35 325.658C270.64 326.345 270.683 326.975 270.831 326.99Z"
        fill="#263238"
      />
      <path
        d="M276.382 318.318C276.439 318.459 277.132 318.268 277.975 318.318C278.817 318.367 279.476 318.686 279.561 318.558C279.646 318.431 279.023 317.85 278.025 317.801C277.026 317.751 276.311 318.197 276.382 318.318Z"
        fill="#263238"
      />
      <g opacity="0.3">
        <path
          d="M276.382 309.404L276.403 312.222L291.505 312.47L291.59 309.928L276.382 309.404Z"
          fill="black"
        />
      </g>
      <path
        d="M276.403 315.125C276.906 315.267 277.437 315.267 277.94 315.125C278.499 315.042 279.046 314.89 279.568 314.672C279.876 314.545 280.174 314.396 280.46 314.226C280.636 314.136 280.796 314.019 280.934 313.879C281.035 313.774 281.099 313.639 281.115 313.495C281.132 313.35 281.101 313.204 281.026 313.079C280.953 312.973 280.856 312.886 280.743 312.824C280.63 312.763 280.504 312.729 280.375 312.725C280.179 312.715 279.983 312.737 279.795 312.789C279.464 312.858 279.142 312.96 278.832 313.093C278.316 313.315 277.838 313.616 277.416 313.985C276.708 314.63 276.432 315.182 276.481 315.21C276.531 315.238 276.906 314.799 277.642 314.276C278.081 313.979 278.557 313.741 279.058 313.568C279.348 313.458 279.646 313.372 279.95 313.313C280.276 313.242 280.552 313.242 280.658 313.391C280.764 313.539 280.694 313.468 280.623 313.56C280.52 313.662 280.404 313.748 280.276 313.815C280.009 313.98 279.732 314.129 279.448 314.261C278.966 314.487 278.463 314.664 277.947 314.792C277.424 314.864 276.909 314.975 276.403 315.125Z"
        fill="#263238"
      />
      <path
        d="M276.594 315.343C276.894 314.911 277.05 314.396 277.04 313.871C277.079 313.301 277.022 312.729 276.87 312.178C276.782 311.852 276.659 311.537 276.502 311.237C276.425 311.031 276.296 310.848 276.127 310.706C275.959 310.565 275.756 310.47 275.539 310.43C275.402 310.429 275.267 310.468 275.152 310.542C275.036 310.616 274.944 310.722 274.888 310.847C274.803 311.024 274.746 311.213 274.718 311.407C274.662 311.749 274.662 312.098 274.718 312.44C274.788 313.019 275 313.571 275.334 314.048C275.915 314.855 276.538 315.053 276.545 315.003C276.552 314.954 276.085 314.628 275.646 313.849C275.391 313.402 275.237 312.904 275.193 312.391C275.169 312.095 275.189 311.797 275.249 311.506C275.306 311.18 275.433 310.918 275.554 310.954C275.674 310.989 275.957 311.209 276.078 311.47C276.22 311.741 276.341 312.023 276.439 312.313C276.596 312.813 276.684 313.332 276.701 313.856C276.686 314.763 276.517 315.315 276.594 315.343Z"
        fill="#263238"
      />
      <path
        d="M301.374 189.291L266.859 190.24L271.815 251.652L274.888 310.848L292.793 311.471C293.707 300.043 293.77 259.666 293.77 259.666L297.36 235.283C298.195 228.705 300.836 221.795 302.111 215.891C302.854 212.428 303.944 207.933 304.178 206.46C304.893 202.12 301.374 189.291 301.374 189.291Z"
        fill="#263238"
      />
      <path
        d="M268.87 204.371C268.878 204.463 268.878 204.555 268.87 204.647C268.87 204.845 268.87 205.114 268.87 205.454C268.87 206.162 268.799 207.224 268.75 208.555C268.7 209.886 268.622 211.55 268.566 213.462C268.509 215.373 268.41 217.554 268.367 219.961C268.233 224.776 268.162 230.51 268.19 236.882C268.219 243.254 268.389 250.249 268.75 257.591C269.111 264.933 269.677 271.921 270.314 278.258C270.661 281.423 270.966 284.432 271.348 287.242C271.532 288.658 271.688 290.004 271.872 291.306C272.056 292.609 272.247 293.855 272.424 295.045C272.75 297.431 273.132 299.576 273.437 301.466C273.741 303.357 274.031 305.006 274.286 306.302L274.831 309.353C274.888 309.693 274.938 309.955 274.966 310.146C274.974 310.238 274.974 310.331 274.966 310.422C274.936 310.335 274.912 310.245 274.895 310.153C274.86 309.962 274.803 309.7 274.732 309.367C274.584 308.659 274.378 307.633 274.116 306.323C273.854 305.013 273.529 303.385 273.21 301.495C272.892 299.604 272.502 297.466 272.141 295.08C271.957 293.891 271.766 292.645 271.568 291.342C271.376 290.039 271.214 288.68 271.022 287.278C270.626 284.446 270.314 281.458 269.953 278.286C269.302 271.95 268.736 264.962 268.36 257.613C267.985 250.264 267.858 243.254 267.829 236.882C267.801 230.51 267.921 224.769 268.084 219.954C268.141 217.547 268.254 215.366 268.332 213.455C268.41 211.543 268.495 209.915 268.573 208.548C268.651 207.182 268.721 206.176 268.764 205.447C268.764 205.107 268.806 204.845 268.821 204.647C268.829 204.554 268.846 204.462 268.87 204.371Z"
        fill="#455A64"
      />
      <path
        d="M249.223 122.244L240.387 112.807L233.421 117.685L240.387 127.993L249.223 122.244Z"
        fill="#FFD3C1"
      />
      <path
        d="M305.106 125.727L304.681 119.744L284.722 119.461L284.552 126.973C284.552 126.973 274.754 137.593 271.192 149.452C265.217 169.432 263.05 194.863 263.05 194.863H303.895C303.47 187.825 300.567 172.08 300.567 172.08L309.644 144.999C311.902 135.264 305.106 125.727 305.106 125.727Z"
        fill="#2EBB55"
      />
      <path
        d="M255.687 152.765C255.687 152.765 255.8 152.878 256.027 153.07C256.352 153.365 256.7 153.635 257.068 153.877C258.41 154.781 259.94 155.37 261.542 155.597C262.647 155.755 263.769 155.743 264.87 155.562C266.052 155.427 267.32 155.264 268.651 155.087C271.32 154.726 274.244 154.273 277.302 153.756L292.949 151.016L297.7 150.238L298.995 150.032L299.442 149.947L298.988 150.004L297.693 150.181C296.567 150.337 294.939 150.584 292.928 150.889C288.914 151.533 283.391 152.553 277.274 153.593C274.215 154.11 271.299 154.563 268.636 154.932C267.305 155.109 266.038 155.279 264.849 155.42C262.154 155.873 259.388 155.299 257.096 153.813C256.733 153.573 256.383 153.313 256.048 153.034C255.815 152.857 255.687 152.765 255.687 152.765Z"
        fill="#263238"
      />
      <path
        d="M306.366 143.117C306.388 143.065 306.402 143.01 306.408 142.954C306.408 142.812 306.472 142.656 306.507 142.494C306.585 142.09 306.684 141.509 306.791 140.78C307.1 138.888 307.049 136.954 306.642 135.081C306.186 133.192 305.055 131.535 303.463 130.422C302.979 130.101 302.446 129.862 301.884 129.714C301.68 129.655 301.472 129.615 301.261 129.594C302.018 129.775 302.737 130.089 303.385 130.521C304.929 131.639 306.023 133.271 306.472 135.123C306.876 136.984 306.939 138.904 306.656 140.787C306.571 141.495 306.486 142.097 306.422 142.501C306.422 142.671 306.401 142.826 306.359 142.968C306.355 143.018 306.357 143.067 306.366 143.117Z"
        fill="#263238"
      />
      <path
        d="M263.879 147.972C263.89 147.823 263.89 147.674 263.879 147.526L263.765 146.308C263.656 144.973 263.616 143.633 263.645 142.294C263.683 140.953 263.789 139.616 263.964 138.286C264.027 137.854 264.084 137.458 264.141 137.083C264.169 136.936 264.185 136.786 264.19 136.637C264.143 136.778 264.105 136.922 264.077 137.069C264.013 137.352 263.935 137.777 263.857 138.272C263.435 140.935 263.366 143.641 263.652 146.322C263.709 146.832 263.765 147.25 263.815 147.533C263.821 147.681 263.843 147.828 263.879 147.972Z"
        fill="#263238"
      />
      <path
        d="M284.786 126.86C285.286 125.471 286.024 124.18 286.967 123.044C288.383 121.111 289.94 119.086 292.17 118.173C293.685 117.557 297.601 117.896 298.649 116.636C301.905 112.686 297.133 110.264 298.861 104.806C300.765 98.8654 299.781 99.7787 299.93 95.0988L307.159 91.9766C308.299 92.8262 311.456 95.8918 311.109 97.2724C310.773 98.7834 310.868 100.359 311.385 101.818C312.497 105.308 316.341 107.361 318.154 110.54C319.966 113.719 317.913 118.109 317.864 121.868C317.814 125.628 319.23 130.124 322.89 130.938C321.506 133.706 319.253 135.944 316.476 137.31L314.182 134.188C314.365 135.614 314.04 137.06 313.263 138.271C312.486 139.481 311.307 140.38 309.934 140.807C309.162 137.515 308.355 134.131 306.359 131.398C304.362 128.665 300.9 126.732 297.636 127.61C295.448 128.191 293.763 129.876 291.809 131.016C289.855 132.156 287.094 132.644 285.536 131.016C285.064 130.446 284.746 129.765 284.614 129.036C284.483 128.308 284.542 127.558 284.786 126.86Z"
        fill="#263238"
      />
      <g opacity="0.3">
        <path
          d="M271.164 154.614C280.58 152.901 293.112 150.848 302.528 149.127C302.875 149.028 303.243 149.028 303.59 149.127C303.885 149.289 304.104 149.559 304.202 149.881C304.3 150.202 304.268 150.549 304.114 150.848C303.78 151.435 303.263 151.897 302.642 152.164C299.548 153.779 295.93 153.913 292.446 154.005L271.164 154.607V154.614Z"
          fill="black"
        />
      </g>
      <path
        d="M264.318 155.556C267.249 155.613 300.95 149.453 300.95 149.453L264.318 155.556Z"
        fill="#2EBB55"
      />
      <path
        d="M300.376 128.41C302.847 128.714 304.454 129.118 307.343 132.233C307.677 132.6 307.964 133.007 308.199 133.444C311.414 139.108 309.092 146.896 302.932 149.055L296.971 150.28L264.318 155.554C264.318 155.554 257.365 157.112 251.39 147.844C249.202 144.439 234.114 121.939 234.114 121.939L244.026 115.305L264.006 136.679L300.376 128.41Z"
        fill="#2EBB55"
      />
      <path
        d="M255.687 152.765C255.687 152.765 255.8 152.878 256.027 153.07C256.352 153.365 256.7 153.635 257.068 153.877C258.41 154.781 259.94 155.37 261.542 155.597C262.647 155.755 263.769 155.743 264.87 155.562C266.052 155.427 267.32 155.264 268.651 155.087C271.32 154.726 274.244 154.273 277.302 153.756L292.949 151.016L297.7 150.238L298.995 150.032L299.442 149.947L298.988 150.004L297.693 150.181C296.567 150.337 294.939 150.584 292.928 150.889C288.914 151.533 283.391 152.553 277.274 153.593C274.215 154.11 271.299 154.563 268.636 154.932C267.305 155.109 266.038 155.279 264.849 155.42C262.154 155.873 259.388 155.299 257.096 153.813C256.733 153.573 256.383 153.313 256.048 153.034C255.815 152.857 255.687 152.765 255.687 152.765Z"
        fill="#263238"
      />
      <path
        d="M306.366 143.117C306.388 143.065 306.402 143.01 306.408 142.954C306.408 142.812 306.472 142.656 306.507 142.494C306.585 142.09 306.684 141.509 306.791 140.78C307.1 138.888 307.049 136.954 306.642 135.081C306.186 133.192 305.055 131.535 303.463 130.422C302.979 130.101 302.446 129.862 301.884 129.714C301.68 129.655 301.472 129.615 301.261 129.594C302.018 129.775 302.737 130.089 303.385 130.521C304.929 131.639 306.023 133.271 306.472 135.123C306.876 136.984 306.939 138.904 306.656 140.787C306.571 141.495 306.486 142.097 306.422 142.501C306.422 142.671 306.401 142.826 306.359 142.968C306.355 143.018 306.357 143.067 306.366 143.117Z"
        fill="#263238"
      />
      <path
        d="M263.879 147.972C263.89 147.823 263.89 147.674 263.879 147.526L263.765 146.308C263.656 144.973 263.616 143.633 263.645 142.294C263.683 140.953 263.789 139.616 263.964 138.286C264.027 137.854 264.084 137.458 264.141 137.083C264.169 136.936 264.185 136.786 264.19 136.637C264.143 136.778 264.105 136.922 264.077 137.069C264.013 137.352 263.935 137.777 263.857 138.272C263.435 140.935 263.366 143.641 263.652 146.322C263.709 146.832 263.765 147.25 263.815 147.533C263.821 147.681 263.843 147.828 263.879 147.972Z"
        fill="#263238"
      />
      <path
        d="M240.345 105.493C240.148 104.148 240.086 102.787 240.161 101.429C240.168 101.361 240.159 101.291 240.135 101.226C240.111 101.161 240.073 101.102 240.024 101.053C239.974 101.005 239.914 100.969 239.848 100.947C239.782 100.925 239.713 100.918 239.644 100.927C239.218 100.983 238.818 101.163 238.495 101.445C238.171 101.727 237.938 102.099 237.824 102.513C237.606 103.254 237.536 104.031 237.619 104.8C237.619 106.06 237.619 107.058 237.619 107.058C237.619 107.058 231.332 99.5321 230.058 99.1922C229.952 99.1409 229.837 99.1112 229.719 99.1048C229.602 99.0984 229.484 99.1155 229.374 99.1551C229.263 99.1946 229.161 99.2558 229.074 99.3352C228.988 99.4145 228.917 99.5103 228.868 99.617C228.634 99.9923 229.102 100.87 229.102 100.87L232.642 105.939C232.642 105.939 227.282 99.6383 226.312 99.6524C225.342 99.6666 224.896 100.36 225.526 101.529C225.987 102.385 230.666 107.901 230.666 107.901C230.666 107.901 225.661 103.072 224.663 103.107C223.664 103.143 223.834 104.205 224.358 104.644C224.882 105.083 228.606 109.394 228.606 109.394C228.606 109.394 224.988 106.336 224.259 106.562C223.53 106.789 223.841 107.61 224.224 108.269C224.606 108.927 233.682 117.997 233.682 117.997L241.555 114.046C241.76 112.941 241.719 111.805 241.435 110.718C240.96 109.002 240.595 107.257 240.345 105.493Z"
        fill="#FFD3C1"
      />
      <path
        d="M124.743 194.92C122.827 194.912 120.992 194.146 119.64 192.788C118.287 191.431 117.528 189.593 117.528 187.677V124.707C117.584 123.118 118.267 121.615 119.428 120.528C120.589 119.442 122.134 118.859 123.723 118.909H129.642C130.807 118.932 131.954 118.629 132.956 118.033C133.957 117.438 134.771 116.573 135.306 115.539L136.573 112.955C137.106 111.926 137.915 111.065 138.909 110.47C139.903 109.875 141.043 109.568 142.202 109.585H170.303C171.358 109.571 172.401 109.823 173.334 110.318C174.267 110.813 175.06 111.535 175.641 112.417L177.021 114.604C177.601 115.486 178.393 116.207 179.325 116.702C180.257 117.197 181.298 117.449 182.353 117.436H218.177C219.769 117.385 221.315 117.967 222.477 119.056C223.639 120.145 224.321 121.651 224.372 123.242V187.67C224.372 189.591 223.609 191.433 222.251 192.791C220.893 194.15 219.051 194.913 217.13 194.913L124.743 194.92Z"
        fill="#2EBB55"
      />
      <g opacity="0.3">
        <path
          d="M124.743 194.92C122.827 194.912 120.992 194.146 119.64 192.788C118.287 191.431 117.528 189.593 117.528 187.677V124.707C117.584 123.118 118.267 121.615 119.428 120.528C120.589 119.442 122.134 118.859 123.723 118.909H129.642C130.807 118.932 131.954 118.629 132.956 118.033C133.957 117.438 134.771 116.573 135.306 115.539L136.573 112.955C137.106 111.926 137.915 111.065 138.909 110.47C139.903 109.875 141.043 109.568 142.202 109.585H170.303C171.358 109.571 172.401 109.823 173.334 110.318C174.267 110.813 175.06 111.535 175.641 112.417L177.021 114.604C177.601 115.486 178.393 116.207 179.325 116.702C180.257 117.197 181.298 117.449 182.353 117.436H218.177C219.769 117.385 221.315 117.967 222.477 119.056C223.639 120.145 224.321 121.651 224.372 123.242V187.67C224.372 189.591 223.609 191.433 222.251 192.791C220.893 194.15 219.051 194.913 217.13 194.913L124.743 194.92Z"
          fill="black"
        />
      </g>
      <path
        d="M178.756 117.472L155.958 117.422L153.926 113.613C153.235 112.377 152.223 111.35 150.997 110.64C149.771 109.931 148.376 109.567 146.96 109.585H124.679C120.714 109.585 117.5 112.594 117.5 116.311V187.281C117.5 191.529 121.153 194.92 125.656 194.92H216.152C220.662 194.92 224.309 191.5 224.309 187.281V122.69C224.309 121.306 223.759 119.979 222.78 119C221.802 118.021 220.475 117.472 219.091 117.472H178.756Z"
        fill="#2EBB55"
      />
      <path
        d="M190.247 171.252H180.158C179.97 171.252 179.79 171.178 179.657 171.045C179.524 170.912 179.45 170.732 179.45 170.544C179.45 170.356 179.524 170.176 179.657 170.044C179.79 169.911 179.97 169.836 180.158 169.836H190.247C192.863 169.841 195.378 168.824 197.255 167.002C199.132 165.179 200.223 162.695 200.295 160.08C200.367 157.465 199.415 154.925 197.641 153.002C195.867 151.078 193.412 149.924 190.799 149.786C190.648 149.778 190.503 149.723 190.386 149.627C190.269 149.532 190.185 149.401 190.148 149.255C189.402 146.376 187.871 143.762 185.725 141.704C183.579 139.646 180.904 138.226 177.997 137.601C175.09 136.977 172.067 137.173 169.265 138.168C166.463 139.164 163.994 140.918 162.132 143.237C162.056 143.332 161.957 143.406 161.844 143.452C161.731 143.498 161.609 143.514 161.488 143.499C160.981 143.433 160.47 143.4 159.958 143.399C156.507 143.479 153.223 144.907 150.81 147.376C148.397 149.846 147.046 153.161 147.046 156.614C147.046 160.067 148.397 163.383 150.81 165.852C153.223 168.322 156.507 169.749 159.958 169.829H168.136C168.324 169.829 168.504 169.904 168.636 170.036C168.769 170.169 168.844 170.349 168.844 170.537C168.844 170.725 168.769 170.905 168.636 171.038C168.504 171.17 168.324 171.245 168.136 171.245H159.923C156.04 171.245 152.316 169.702 149.57 166.957C146.824 164.211 145.282 160.487 145.282 156.604C145.282 152.72 146.824 148.996 149.57 146.251C152.316 143.505 156.04 141.962 159.923 141.962C160.358 141.962 160.793 141.986 161.226 142.033C163.259 139.603 165.915 137.772 168.91 136.736C171.904 135.701 175.124 135.499 178.224 136.154C181.325 136.809 184.188 138.295 186.508 140.453C188.828 142.611 190.517 145.36 191.394 148.405C194.335 148.679 197.056 150.077 198.99 152.31C200.925 154.542 201.922 157.435 201.774 160.385C201.626 163.335 200.344 166.114 198.196 168.141C196.048 170.169 193.2 171.288 190.247 171.266V171.252Z"
        fill="white"
      />
      <path
        d="M173.934 177.709C173.747 177.709 173.567 177.634 173.434 177.501C173.301 177.369 173.226 177.189 173.226 177.001V158.593C173.226 158.405 173.301 158.225 173.434 158.092C173.567 157.959 173.747 157.885 173.934 157.885C174.122 157.885 174.302 157.959 174.435 158.092C174.568 158.225 174.642 158.405 174.642 158.593V177.001C174.642 177.189 174.568 177.369 174.435 177.501C174.302 177.634 174.122 177.709 173.934 177.709Z"
        fill="white"
      />
      <path
        d="M179.315 165.92C179.204 165.921 179.094 165.896 178.995 165.846C178.895 165.797 178.808 165.725 178.742 165.636L174.055 159.349C174.038 159.329 174.016 159.312 173.991 159.301C173.967 159.29 173.94 159.285 173.913 159.286C173.888 159.286 173.862 159.292 173.839 159.303C173.816 159.314 173.795 159.33 173.779 159.349L168.823 165.459C168.703 165.607 168.53 165.701 168.342 165.721C168.153 165.741 167.965 165.685 167.817 165.566C167.67 165.446 167.576 165.273 167.556 165.085C167.536 164.896 167.592 164.708 167.711 164.56L172.667 158.45C172.823 158.264 173.018 158.114 173.238 158.011C173.458 157.908 173.698 157.855 173.941 157.855C174.183 157.859 174.421 157.918 174.637 158.027C174.853 158.136 175.041 158.293 175.188 158.486L179.889 164.78C179.945 164.855 179.986 164.94 180.009 165.031C180.032 165.122 180.037 165.217 180.023 165.31C180.01 165.403 179.977 165.492 179.929 165.572C179.88 165.653 179.816 165.723 179.74 165.778C179.617 165.87 179.468 165.92 179.315 165.92Z"
        fill="white"
      />
      <path
        d="M160.121 209.838V206.666H160.716V209.817C160.716 211.134 161.332 211.735 162.408 211.735C163.484 211.735 164.107 211.134 164.107 209.817V206.666H164.681V209.838C164.681 211.431 163.824 212.252 162.408 212.252C160.992 212.252 160.121 211.431 160.121 209.838Z"
        fill="#263238"
      />
      <path
        d="M170.536 210.127C170.552 210.406 170.509 210.685 170.412 210.947C170.314 211.208 170.162 211.446 169.967 211.646C169.772 211.846 169.537 212.003 169.278 212.107C169.019 212.211 168.741 212.26 168.462 212.251C168.149 212.261 167.839 212.191 167.561 212.047C167.284 211.904 167.048 211.691 166.876 211.43V213.752H166.316V208.088H166.854V208.86C167.023 208.588 167.261 208.366 167.543 208.216C167.825 208.065 168.142 207.992 168.462 208.003C168.74 207.995 169.018 208.044 169.277 208.149C169.535 208.253 169.77 208.41 169.965 208.61C170.159 208.81 170.311 209.048 170.409 209.309C170.508 209.57 170.551 209.848 170.536 210.127ZM169.977 210.127C169.964 209.822 169.862 209.527 169.684 209.28C169.505 209.032 169.258 208.842 168.972 208.734C168.687 208.626 168.376 208.604 168.078 208.671C167.78 208.738 167.509 208.891 167.297 209.112C167.086 209.332 166.943 209.609 166.888 209.91C166.833 210.21 166.868 210.52 166.987 210.801C167.107 211.082 167.306 211.321 167.561 211.49C167.815 211.658 168.114 211.748 168.419 211.748C168.63 211.752 168.839 211.711 169.034 211.63C169.229 211.549 169.404 211.429 169.551 211.277C169.697 211.124 169.81 210.944 169.883 210.746C169.957 210.548 169.988 210.338 169.977 210.127Z"
        fill="#263238"
      />
      <path
        d="M171.683 206.332H172.242V212.208H171.683V206.332Z"
        fill="#263238"
      />
      <path
        d="M173.389 210.128C173.389 209.708 173.514 209.297 173.747 208.948C173.981 208.599 174.312 208.326 174.7 208.166C175.089 208.005 175.516 207.963 175.928 208.045C176.34 208.127 176.718 208.329 177.015 208.626C177.312 208.923 177.514 209.302 177.596 209.714C177.678 210.126 177.636 210.553 177.476 210.941C177.315 211.329 177.043 211.661 176.693 211.894C176.344 212.127 175.933 212.252 175.513 212.252C175.232 212.262 174.951 212.214 174.689 212.11C174.427 212.007 174.189 211.851 173.989 211.652C173.79 211.453 173.634 211.214 173.531 210.952C173.427 210.69 173.379 210.409 173.389 210.128ZM177.057 210.128C177.043 209.824 176.94 209.532 176.761 209.286C176.582 209.04 176.335 208.853 176.05 208.746C175.766 208.64 175.456 208.62 175.16 208.688C174.864 208.756 174.594 208.91 174.385 209.13C174.175 209.35 174.035 209.627 173.981 209.926C173.928 210.225 173.964 210.533 174.084 210.812C174.204 211.091 174.404 211.329 174.658 211.495C174.912 211.662 175.209 211.75 175.513 211.749C175.724 211.753 175.932 211.712 176.126 211.631C176.32 211.55 176.495 211.429 176.64 211.277C176.785 211.124 176.897 210.944 176.969 210.746C177.04 210.548 177.07 210.338 177.057 210.128Z"
        fill="#263238"
      />
      <path
        d="M181.892 209.624V212.208H181.354V211.557C181.202 211.79 180.991 211.978 180.742 212.102C180.493 212.226 180.216 212.283 179.938 212.265C178.997 212.265 178.423 211.776 178.423 211.054C178.423 210.332 178.834 209.879 180.03 209.879H181.347V209.624C181.347 208.916 180.944 208.519 180.172 208.519C179.655 208.507 179.152 208.683 178.756 209.015L178.501 208.59C178.984 208.215 179.582 208.018 180.193 208.031C180.415 207.997 180.642 208.013 180.856 208.08C181.07 208.146 181.267 208.26 181.43 208.414C181.594 208.567 181.721 208.756 181.801 208.966C181.881 209.175 181.912 209.4 181.892 209.624ZM181.326 210.955V210.247H180.023C179.216 210.247 178.954 210.566 178.954 210.99C178.954 211.415 179.343 211.776 180.009 211.776C180.284 211.796 180.558 211.731 180.793 211.589C181.029 211.447 181.215 211.235 181.326 210.983V210.955Z"
        fill="#263238"
      />
      <path
        d="M187.231 206.333V212.209H186.693V211.381C186.526 211.655 186.289 211.88 186.007 212.031C185.724 212.183 185.406 212.257 185.085 212.244C184.522 212.244 183.982 212.021 183.583 211.622C183.185 211.224 182.961 210.684 182.961 210.12C182.961 209.557 183.185 209.017 183.583 208.618C183.982 208.22 184.522 207.996 185.085 207.996C185.398 207.985 185.708 208.055 185.986 208.199C186.264 208.343 186.5 208.556 186.671 208.818V206.318L187.231 206.333ZM186.678 210.127C186.664 209.824 186.562 209.531 186.383 209.285C186.204 209.04 185.957 208.852 185.672 208.746C185.387 208.639 185.078 208.619 184.782 208.687C184.486 208.755 184.216 208.909 184.006 209.129C183.797 209.349 183.657 209.626 183.603 209.925C183.55 210.224 183.585 210.533 183.705 210.812C183.826 211.091 184.025 211.328 184.279 211.495C184.534 211.662 184.831 211.75 185.135 211.749C185.345 211.751 185.553 211.71 185.747 211.628C185.94 211.547 186.115 211.426 186.26 211.274C186.404 211.122 186.516 210.942 186.588 210.744C186.66 210.547 186.691 210.337 186.678 210.127Z"
        fill="#263238"
      />
      <path
        d="M188.434 211.813C188.432 211.757 188.442 211.701 188.462 211.649C188.482 211.596 188.512 211.548 188.551 211.508C188.59 211.468 188.637 211.436 188.689 211.414C188.74 211.392 188.796 211.381 188.852 211.381C188.908 211.381 188.964 211.392 189.016 211.414C189.068 211.436 189.115 211.468 189.155 211.508C189.194 211.548 189.226 211.596 189.246 211.648C189.267 211.7 189.278 211.756 189.277 211.813C189.277 211.926 189.232 212.035 189.153 212.116C189.073 212.196 188.965 212.243 188.852 212.245C188.796 212.244 188.741 212.232 188.69 212.21C188.639 212.187 188.592 212.155 188.554 212.115C188.515 212.075 188.484 212.028 188.464 211.976C188.443 211.924 188.433 211.869 188.434 211.813Z"
        fill="#263238"
      />
      <path
        d="M190.112 211.813C190.111 211.757 190.121 211.702 190.142 211.65C190.162 211.598 190.193 211.55 190.232 211.51C190.27 211.47 190.317 211.438 190.368 211.416C190.419 211.394 190.474 211.382 190.53 211.381C190.586 211.381 190.642 211.392 190.694 211.414C190.746 211.436 190.793 211.468 190.833 211.508C190.872 211.548 190.903 211.596 190.924 211.648C190.945 211.7 190.956 211.756 190.955 211.813C190.955 211.926 190.91 212.035 190.831 212.116C190.751 212.196 190.643 212.243 190.53 212.245C190.474 212.244 190.419 212.232 190.368 212.21C190.317 212.187 190.27 212.155 190.232 212.115C190.193 212.075 190.162 212.028 190.142 211.976C190.121 211.924 190.111 211.869 190.112 211.813Z"
        fill="#263238"
      />
      <path
        d="M191.79 211.813C191.788 211.757 191.798 211.701 191.818 211.649C191.838 211.596 191.868 211.548 191.907 211.508C191.946 211.468 191.993 211.436 192.045 211.414C192.096 211.392 192.152 211.381 192.208 211.381C192.264 211.381 192.32 211.392 192.372 211.414C192.424 211.436 192.471 211.468 192.511 211.508C192.55 211.548 192.581 211.596 192.602 211.648C192.623 211.7 192.634 211.756 192.633 211.813C192.633 211.926 192.588 212.035 192.509 212.116C192.429 212.196 192.321 212.243 192.208 212.245C192.152 212.244 192.097 212.232 192.046 212.21C191.995 212.187 191.948 212.155 191.91 212.115C191.871 212.075 191.84 212.028 191.82 211.976C191.799 211.924 191.789 211.869 191.79 211.813Z"
        fill="#263238"
      />
      <path
        d="M227.841 221.682H117.422C114.829 221.682 112.728 223.783 112.728 226.376V227.742C112.728 230.335 114.829 232.436 117.422 232.436H227.841C230.434 232.436 232.536 230.335 232.536 227.742V226.376C232.536 223.783 230.434 221.682 227.841 221.682Z"
        fill="#E0E0E0"
      />
      <path
        d="M167.938 221.682H117.422C114.829 221.682 112.728 223.783 112.728 226.376V227.742C112.728 230.335 114.829 232.436 117.422 232.436H167.938C170.53 232.436 172.632 230.335 172.632 227.742V226.376C172.632 223.783 170.53 221.682 167.938 221.682Z"
        fill="#2EBB55"
      />
      <g opacity="0.3">
        <path
          d="M121.776 221.682L115.404 231.799L116.402 232.245C116.685 232.372 116.992 232.437 117.301 232.436H119.326L126.251 221.682H121.776Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M133.175 221.682L126.399 232.436H130.725L137.642 221.682H133.175Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M144.567 221.682L137.791 232.436H142.117L149.041 221.682H144.567Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M155.965 221.682L149.183 232.436H153.509L160.433 221.682H155.965Z"
          fill="white"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M167.357 221.682L160.581 232.436H164.907L170.359 223.94L171.01 223.02C170.175 222.121 168.893 221.745 167.357 221.682Z"
          fill="white"
        />
      </g>
      <path
        d="M227.976 232.437C227.976 232.437 228.195 232.437 228.62 232.367C229.252 232.28 229.857 232.06 230.397 231.722C231.283 231.18 231.964 230.358 232.33 229.386C232.562 228.771 232.668 228.116 232.642 227.46C232.686 226.712 232.628 225.962 232.472 225.23C232.247 224.38 231.786 223.612 231.141 223.014C230.497 222.417 229.697 222.014 228.833 221.853C228.345 221.783 227.852 221.757 227.36 221.775H222.517H207.069L117.634 221.824C116.58 221.825 115.56 222.191 114.745 222.86C113.931 223.528 113.373 224.459 113.167 225.492C113.088 226.012 113.059 226.538 113.082 227.064C113.059 227.588 113.095 228.112 113.188 228.628C113.4 229.629 113.944 230.529 114.732 231.182C115.52 231.835 116.505 232.203 117.528 232.225H126.944H150.691L190.898 232.267L218.007 232.324L225.378 232.36H218.007L190.898 232.423L150.691 232.459H126.944H117.528C116.413 232.449 115.335 232.06 114.47 231.356C113.606 230.652 113.006 229.675 112.77 228.586C112.67 228.044 112.632 227.493 112.657 226.943C112.632 226.394 112.663 225.844 112.749 225.301C112.969 224.182 113.571 223.175 114.452 222.452C115.333 221.729 116.438 221.334 117.578 221.336L207.012 221.393H222.461H227.303C227.808 221.373 228.313 221.404 228.811 221.485C229.703 221.655 230.529 222.074 231.191 222.695C231.854 223.315 232.328 224.111 232.557 224.989C232.708 225.737 232.76 226.501 232.713 227.262C232.73 227.933 232.617 228.602 232.38 229.23C231.993 230.215 231.291 231.044 230.383 231.588C229.829 231.92 229.212 232.132 228.571 232.211C228.38 232.304 228.181 232.38 227.976 232.437Z"
        fill="#263238"
      />
    </svg>
  );
};

export default ReleaseImage;
