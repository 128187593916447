import React from "react";
import { useState, useEffect } from "react";

import styles from "../admin.module.css";
import UserGrowthChart from "../admin_visual_charts/userGrowthChart";
import MembershipTypeChart from "../admin_visual_charts/memberTypeChart";
import MonthlyRevenueChart from "../admin_visual_charts/revenueChart";
import useAppState from "../../../context/useAppState";
import CircularLoader from "../../UniversalComponents/CircularLoader/CircularLoader";
import BulletChart from "../admin_visual_charts/bulletChart";

const AdminAnalytics = (props) => {
  const {
    fetchRecentSignUps,
    fetchAnalyticsFromDatabase,
    fetchUserData,
    fetchSubmissionCounts,
    refreshAllData,
  } = useAppState("general");

  const [recentSignUps, setRecentSignUps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [headerStats, setHeaderStats] = useState(header_stats);

  const [submissionData, setSubissionData] = useState([{}, {}, {}, {}, {}]);
  const [analyticsData, setAnalyticsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        fetchUserData();
        const analyticsData = await fetchAnalyticsFromDatabase();
        const submissionCountData = await fetchSubmissionCounts();
        const signUps = await fetchRecentSignUps();
        setAnalyticsData(analyticsData);
        setSubissionData(submissionCountData);
        setRecentSignUps(signUps);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (
      analyticsData &&
      analyticsData.revenueData &&
      analyticsData.revenueData.length >= 2
    ) {
      const revenueData = analyticsData.revenueData;
      const latestRevenue = revenueData[0].revenue;
      const previousRevenue = revenueData[1].revenue;
      const revenueChange = calculatePercentChange(
        latestRevenue,
        previousRevenue,
      );

      setHeaderStats((prevStats) =>
        prevStats.map((stat) => {
          if (stat.id === "1") {
            return {
              ...stat,
              number: formatPrice(latestRevenue),
              change: revenueChange.toFixed(2),
            };
          }
          return stat;
        }),
      );
    }

    if (
      analyticsData &&
      analyticsData.usersData &&
      analyticsData.usersData.activeUsersData &&
      analyticsData.usersData.activeUsersData.length >= 2
    ) {
      const activeUsersData = analyticsData.usersData.activeUsersData;
      const latestMonthData = activeUsersData[0];
      const previousMonthData = activeUsersData[1];

      const latestMonthUsers = latestMonthData.totalActiveUsers;
      const previousMonthUsers = previousMonthData.totalActiveUsers;

      const userChange = calculatePercentChange(
        latestMonthUsers,
        previousMonthUsers,
      );

      setHeaderStats((prevStats) =>
        prevStats.map((stat) => {
          if (stat.id === "2") {
            return {
              ...stat,
              number: latestMonthUsers,
              change: userChange.toFixed(2),
            };
          }
          return stat;
        }),
      );
    }

    if (
      analyticsData &&
      analyticsData.usersData &&
      analyticsData.usersData.cancelledUsersData
    ) {
      const cancelledUsersData = analyticsData.usersData.cancelledUsersData;
      const latestMonth = cancelledUsersData[0].canceledUsers;

      let previousMonth;
      if (cancelledUsersData.length >= 2) {
        previousMonth = cancelledUsersData[1].canceledUsers;
      } else {
        // Default to 1 (or another value) if there's only one record
        previousMonth = latestMonth - 1;
      }

      const userChange = calculatePercentChange(latestMonth, previousMonth);

      setHeaderStats((prevStats) =>
        prevStats.map((stat) => {
          if (stat.id === "3") {
            return {
              ...stat,
              number: latestMonth,
              change: userChange.toFixed(2),
            };
          }
          return stat;
        }),
      );
    }

    if (
      analyticsData &&
      analyticsData.sessionData &&
      analyticsData.sessionData.length
    ) {
      const latestDaySessionCount = analyticsData.sessionData[0].sessionCount;

      let previousDaySessionCount;
      if (analyticsData.sessionData.length >= 2) {
        previousDaySessionCount = analyticsData.sessionData[1].sessionCount;
      } else {
        previousDaySessionCount = 1;
      }

      const sessionChange = calculatePercentChange(
        latestDaySessionCount,
        previousDaySessionCount,
      );

      setHeaderStats((prevStats) =>
        prevStats.map((stat) => {
          if (stat.id === "4") {
            return {
              ...stat,
              number: latestDaySessionCount,
              change: sessionChange.toFixed(2),
            };
          }
          return stat;
        }),
      );
    }
  }, [analyticsData]);

  return (
    <div className={styles.sub_page_layout}>
      <AnalyitcsTopRowStats
        submissionCountData={submissionData}
        isLoading={isLoading}
      />
      <AnalyitcsRowOne
        data={headerStats}
        isLoading={isLoading}
        analyticsData={analyticsData}
      />
      <AnalyitcsRowTwo
        data={props.analytics_data}
        isLoading={isLoading}
        analyticsData={analyticsData}
      />
      <AnalyitcsRowThree
        data={props.analytics_data}
        isLoading={isLoading}
        recentSignUps={recentSignUps}
        analyticsData={analyticsData}
      />
      <AnalyitcsRowFour
        data={props.analytics_data}
        isLoading={isLoading}
        analyticsData={analyticsData}
        recentSignUps={recentSignUps}
      />
    </div>
  );
};

function formatUnixTimestamp(timestamp) {
  const date = new Date(timestamp * 1000);

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

function getMonthRevenue(revenueData) {
  const currentRev = revenueData[revenueData.length - 1];
  return formatPrice(currentRev.revenue);
}

function calculatePercentChange(current, previous) {
  if (previous === 0) return current === 0 ? 0 : 100;
  return ((current - previous) / previous) * 100;
}

const header_stats = [
  {
    id: "1",
    number: null,
    title: "Revenue",
    change: null,
    icon: "/AnalyticsIcons/coins.png",
    time_frame: "This Month",
  },
  {
    id: "2",
    number: null,
    title: "Users",
    change: null,
    icon: "/AnalyticsIcons/group.png",
    time_frame: "Current Total",
  },
  {
    id: "3",
    number: 0,
    title: "Churn",
    change: null,
    icon: "/AnalyticsIcons/churn.png",
    time_frame: "This Month",
  },
  {
    id: "4",
    number: 0,
    title: "Sessions",
    change: null,
    icon: "/AnalyticsIcons/session.png",
    time_frame: "Today",
  },
];

function formatPrice(priceInCents, currency = "USD") {
  const priceInDollars = priceInCents / 100;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(priceInDollars);
}

export default AdminAnalytics;

function AnalyitcsTopRowStats(props) {
  function formatNumber(num) {
    if (num < 1000) {
      return num.toString();
    } else if (num < 1000000) {
      return (num / 1000).toFixed(1) + "k";
    } else {
      return (num / 1000000).toFixed(1) + "M";
    }
  }

  return (
    <div className={styles.stats_top_row}>
      {props.submissionCountData.map((data, index) => (
        <div className={styles.top_row_count_stat} key={`AnalyitcsTopRowStats-${index}`}>
          {props.isLoading ? (
            <div className={styles.loading_box}>{<CircularLoader />}</div>
          ) : (
            <div className={styles.top_row_content}>
              <p className={styles.top_row_title}>{data.title}</p>
              {data.collection === "beatinboxes" ? (
                <p className={styles.top_row_value}>
                  {formatNumber(data.count)} Created
                </p>
              ) : (
                <p className={styles.top_row_value}>
                  {formatNumber(data.count)} Submissions
                </p>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function AnalyitcsRowOne(props) {
  return (
    <div className={styles.stats_row_one}>
      {props.data.map((data, index) => (
        <div className={styles.top_stat} key={`AnalyitcsRowOne-${index}`} >
          {props.isLoading ? (
            <div className={styles.loading_box}>{<CircularLoader />}</div>
          ) : (
            <>
              <div className={styles.top_stat_info_row_top}>
                <p className={styles.top_stat_title}>{data.title}</p>
                <div className={styles.top_stat_icon}>
                  <img
                    src={data.icon}
                    alt="icon"
                    className={styles.top_stat_image}
                  ></img>
                </div>
              </div>
              <div>
                <p className={styles.top_stat_value}>{data.number}</p>
              </div>
              <div className={styles.top_stat_info_row_bottom}>
                <div
                  className={
                    data.change > 0
                      ? styles.percent_tag
                      : styles.percent_tag_neg
                  }
                >
                  <p className={styles.top_stat_percent_value}>
                    {data.change > 0 ? `+${data.change}%` : `${data.change}%`}
                  </p>
                </div>
                <p className={styles.top_stat_value_time_frame}>
                  {data.time_frame}
                </p>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

function AnalyitcsRowTwo(props) {
  return (
    <div className={styles.stats_row_two}>
      <div className={styles.tile}>
        <p className={styles.tile_title}>User Growth</p>
        <div className={styles.tile_content}>
          {props.isLoading ? (
            <div className={styles.loading_box}>{<CircularLoader />}</div>
          ) : (
            <UserGrowthChart analyticsData={props.analyticsData} />
          )}
        </div>
      </div>
      <div className={styles.tile}>
        <div className={styles.tile_title_box}>
          <p className={styles.tile_title}>Page Visits</p>

          <div className={styles.tile_content}>
            {props.isLoading ? (
              <div className={styles.loading_box}>{<CircularLoader />}</div>
            ) : (
              <div className={styles.time_spent_stats}>
                {props.analyticsData?.pageClickData?.map((data, index) => (
                  <div className={styles.time_spent_stat} key={`AnalyitcsRowTwoZ-${index}`}>
                    <div className={styles.counter_stat_line}></div>
                    <div className={styles.time_spent_stat_info}>
                      <p className={styles.data_title}>{data.pageTitle}</p>
                      <p className={styles.data_percent}>{data.percent}%</p>
                    </div>
                    <div
                      className={styles.time_spent_stat_line}
                      style={{ width: `${data.percent}%` }}
                    ></div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function AnalyitcsRowThree(props) {
  return (
    <div className={styles.stats_row_three}>
      <div className={styles.tile}>
        <div className={styles.tile_title_box}>
          <p className={styles.tile_title}>Users By City</p>
          <div className={styles.tile_content}>
            {props.isLoading ? (
              <div className={styles.loading_box}>
                <CircularLoader />
              </div>
            ) : (
              <BulletChart analyticsData={props.analyticsData} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.tile}>
        <div className={styles.tile_title_box}>
          <p className={styles.tile_title}>Membership Types</p>
          <div className={styles.tile_content}>
            {props.isLoading ? (
              <div className={styles.loading_box}>
                <CircularLoader />
              </div>
            ) : (
              <MembershipTypeChart analyticsData={props.analyticsData} />
              // <div className={styles.recentSignUps_scroll}>
              //   {props.recentSignUps.map((data, index) => (
              //     <div key={index} className={styles.new_user_cell}>
              //       <div className={`${styles.user_info}`}>
              //         <img
              //           src={data.profilePic}
              //           className={styles.user_image}
              //           alt="user"
              //         />
              //         <div className={styles.user_info_text}>
              //           <p className={styles.user_title}>
              //             {data.firstname} {data.lastname}
              //           </p>
              //           <p className={styles.user_subtitle}>{data.email}</p>
              //         </div>
              //       </div>
              //       <p className={styles.role_type}>{data.userType}</p>
              //     </div>
              //   ))}
              // </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function AnalyitcsRowFour(props) {
  return (
    <div className={styles.stats_row_two}>
      <div className={styles.tile}>
        <div className={styles.tile_title_box}>
          <p className={styles.tile_title}>Monthly Revenue</p>
        </div>
        <div className={styles.tile_content}>
          {props.isLoading ? (
            <div className={styles.loading_box}>{<CircularLoader />}</div>
          ) : (
            <MonthlyRevenueChart analyticsData={props.analyticsData} />
          )}
        </div>
      </div>
      <div className={styles.tile}>
        <div className={styles.tile_title_box}>
          <p className={styles.tile_title}>Recent Sign Ups</p>
        </div>
        <div className={styles.tile_content}>
          {props.isLoading ? (
            <div className={styles.loading_box}>{<CircularLoader />}</div>
          ) : (
            <div className={styles.recentSignUps_scroll}>
              {props.recentSignUps.map((data, index) => (
                <div key={index} className={styles.new_user_cell}>
                  <div className={`${styles.user_info}`}>
                    <img
                      src={data.profilePic}
                      className={styles.user_image}
                      alt="user"
                    />
                    <div className={styles.user_info_text}>
                      <p className={styles.user_title}>
                        {data.firstname} {data.lastname}
                      </p>
                      <p className={styles.user_subtitle}>{data.email}</p>
                    </div>
                  </div>
                  <p className={styles.role_type}>{data.userType}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
