import React, { Fragment, useEffect, useState, useMemo } from "react";
import { size } from "lodash";
import { Box } from "@material-ui/core";
import { useParams } from 'react-router-dom';

import styles from "./Libraries.module.css";

import PackCard from "./PackCard.js";
import useAuth from "../../../context/AuthContext.js";
import { useDebounce } from "../../../hooks/useDebounce.jsx";
import useAppState from "../../../context/useAppState.js";

import { getFeedOpportunityApi } from "../../../context/apis/opportunity.js";

import CircularLoaderPage from "../../UniversalComponents/CircularLoader/CircularLoaderPage.js";
import { PaginationMain } from "../../smartlink/index.js";
import OutlineButton from "../AdditionalComponents/OutlineButton.js";

import SingleUploadModal from "./Modals/SingleUploadModal.js";
import SelectFileModal from "./Modals/SelectFileModal.js";

import EditPackModal from "./Modals/EditPackModal.js";

import NoItemComponents from "../AdditionalComponents/utils/NoItemComponent.js";

import { feedAudioFilesByQuery } from "../../../context/apis/audio-library.js";
import TagsModal from "./Modals/TagsModal.js";
import KeysModal from "./Modals/KeysModal.js";
import { TAGS } from "../../../shared/constants.js";
import BlueButtonComponents from "../AdditionalComponents/utils/BlueButtonComponent.js";
import PackDetailCard from "./PackDetailCard.js";
import FavIcon from "../../../assets/smartlink/icons/FavIcon.js";
import EyeIcon from "../../../assets/smartlink/icons/EyeIcon.js";
import DownloadSvg from "../../../assets/smartlink/icons/DownloadSvg.js";
import StarSvg from "../../../assets/smartlink/icons/StarSvg.js";
import { getAudioLibraryById, getUserInfo } from "../../../context/apis/audio-library.js";

import LibLineDivider from "./LibLineDivider.js";

const PAGE_LIMIT = 10;
function formatMongoDate(mongoDate) {
  console.log("✔ --- Origin Date ---", mongoDate);

  // Convert to Date object if mongoDate is a string
  const dateObject = (typeof mongoDate === "string") ? new Date(mongoDate) : mongoDate;

  // Ensure dateObject is a valid Date object
  if (!(dateObject instanceof Date) || isNaN(dateObject)) {
    throw new Error('Invalid date object');
  }

  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const date = String(dateObject.getDate()).padStart(2, '0');
  const year = String(dateObject.getFullYear()).slice(-2); // Get last 2 digits of the year

  return `${month}/${date}/${year}`;
}

function formatDuration(duration) {
  // Ensure duration is a number and not negative
  if (typeof duration !== "number" || duration < 0) {
    throw new Error('Invalid duration. Must be a non-negative number.');
  }

  const minutes = Math.floor(duration / 60); // Get whole minutes
  const seconds = Math.round(duration % 60); // Get remaining seconds

  // Pad with zero if necessary to always have two digits
  const minutesStr = String(minutes).padStart(2, '0');
  const secondsStr = String(seconds).padStart(2, '0');

  return `${minutesStr}:${secondsStr}`;
}

function TimeAgo(createdAT) {
  const currentTime = new Date();

  console.log(" ---Current Time--- ", currentTime);


  const pastTime = new Date(createdAT);
  const diffInSeconds = Math.floor((currentTime - pastTime) / 1000);

  let timeAgo = "";
  if (diffInSeconds < 60) {
    timeAgo = `${diffInSeconds} secs ago`;
  } else if (diffInSeconds < 3600) {
    // less than 1 hour
    const minutes = Math.floor(diffInSeconds / 60);
    timeAgo = `${minutes} mins ago`;
  } else if (diffInSeconds < 86400) {
    // less than 1 day
    const hours = Math.floor(diffInSeconds / 3600);
    timeAgo = `${hours} hours ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    timeAgo = `${days} days ago`;
  }

  return timeAgo;
}

const PackMoreDetail = (props) => {

  const params = useParams();
  const id2 = params.id2;

  const [query, setQuery] = useState("");
  const { user, userPrivileges } = useAuth();
  const searchQuery = useDebounce(query, 500);
  const [currentPage, setcurrentPage] = useState(1);

  const [feedsOpportunityData, setFeedsOpportunityData] = useState([]);

  const [isRefresh, setIsRefresh] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [TotalPages, setTotalPages] = useState(0);
  const [singleUploadModalOpen, setSingleUploadModalOpen] = useState(false);


  const [editPackModalOpen, setEditPackModalOpen] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  // const [selectedPack, setSelectedPack] = useState(null);

  const [audioFileData, setAudioFileData] = useState();

  const [uploadAnchor, setUploadAnchor] = useState(null);

  const [activeUsers, setActiveUsers] = useState([]);
  const [nonActiveViewers, setNonActiveViewers] = useState([]);
  const [nonActiveDownloaders, setNonActiveDownloaders] = useState([]);

  const [activeUsersAvatar, setActiveUsersAvatar] = useState([]);
  const [nonActiveViewersAvatar, setNonActiveViewersAvatar] = useState([]);
  const [nonActiveDownloadersAvatar, setNonActiveDownloadersAvatar] = useState([]);

  const user_avatar = "/images/no_image.png";

  const handleEditPackModal = () => {
    // setSelectedPack(packData);  // Set the data for the pack to be edited
    setEditPackModalOpen(true); // Open the modal
  };

  let canCreate;

  if (user.roleId.roleName === "Admin") {
    canCreate = true;
  } else if (user.roleId.roleName === "Music Producer") {
    canCreate = userPrivileges?.privileges[0]?.isCreate || false;
  } else {
    canCreate = true;
  }

  useEffect(() => {
    getAudioLibraryById(id2)
      .then((response) => {
        if (response?.data) {
          const newRow = response.data;
          console.log(' ----newRow PackMoreDtail--- ', newRow);
          setAudioFileData(newRow);
          const { activeUsers, nonActiveViewers, nonActiveDownloaders } =
            getActiveAndNonActiveUsers(newRow?.basicInfo?.viewers, newRow?.basicInfo?.downloaders);
          setActiveUsers(activeUsers);
          setNonActiveViewers(nonActiveViewers);
          setNonActiveDownloaders(nonActiveDownloaders);
          const fetchActiveUsersAvatar = async () => {
            try {
              // Use Promise.all to wait for all getUserInfo calls to finish
              const avatarPromises = activeUsers.map(user =>
                getUserInfo(user.personId)
              );

              // Await all promises and then extract profilePic from each resolved promise
              const avatars = (await Promise.all(avatarPromises)).map(
                response => response?.data?.profilePic
              );

              // Set the state with the fetched avatars
              setActiveUsersAvatar(avatars);
            } catch (error) {
              console.error("Failed to fetch activer avatars", error);
            }
          };

          // Create a function to fetch data as useEffect should not return anything
          const fetchNonActiveViewersAvatar = async () => {
            try {
              // Use Promise.all to wait for all getUserInfo calls to finish
              const avatarPromises = nonActiveViewers.map(user =>
                getUserInfo(user.personId)
              );

              // Await all promises and then extract profilePic from each resolved promise
              const avatars = (await Promise.all(avatarPromises)).map(
                response => response?.data?.profilePic
              );

              // Set the state with the fetched avatars
              setNonActiveViewersAvatar(avatars);
            } catch (error) {
              console.error("Failed to fetch nonActiveViewer avatars", error);
            }
          };

          // Create a function to fetch data as useEffect should not return anything
          const fetchNonActiveDownloadersAvatar = async () => {
            try {
              // Use Promise.all to wait for all getUserInfo calls to finish
              const avatarPromises = nonActiveDownloaders.map(user =>
                getUserInfo(user.personId)
              );

              // Await all promises and then extract profilePic from each resolved promise
              const avatars = (await Promise.all(avatarPromises)).map(
                response => response?.data?.profilePic
              );

              // Set the state with the fetched avatars
              setNonActiveDownloadersAvatar(avatars);
            } catch (error) {
              console.error("Failed to fetch nonActiveDownloader avatars", error);
            }
          };


          // Call the function if there are any active users
          if (activeUsers.length > 0) {
            fetchActiveUsersAvatar();
          }

          if (nonActiveViewers.length > 0) {
            fetchNonActiveViewersAvatar();
          }

          if (nonActiveDownloaders.length > 0) {
            fetchNonActiveDownloadersAvatar();
          }
        }
      })
      .finally(() => {
      });
  }, []);


  useEffect(() => {
    setcurrentPage(1);
  }, []);

  function getActiveAndNonActiveUsers(viewers, downloaders) {
    // Find active users (present in both viewers and downloaders)

    console.log("viwers", viewers);
    const activeUsers = viewers.filter((viewer) =>
      downloaders.some((downloader) => downloader.personId === viewer.personId)
    );

    // Find non-active viewers (not present in downloaders)
    const nonActiveViewers = viewers.filter(
      (viewer) => !downloaders.some((downloader) => downloader.personId === viewer.personId)
    );

    // Find non-active downloaders (not present in viewers)
    const nonActiveDownloaders = downloaders.filter(
      (downloader) => !viewers.some((viewer) => viewer.personId === downloader.personId)
    );

    return {
      activeUsers,
      nonActiveViewers,
      nonActiveDownloaders,
    };
  }




  return (
    <>
      <div>
        <div className={`${styles.pack_detail_card_layout}`} style={{ display: "flex", gap: "10px" }}>
          <div className={`${styles.pack_detail_card_header} ${styles.w_20} ${styles.text_left}`}>Name</div>
          <div className={`${styles.pack_detail_card_header} ${styles.w_20} ${styles.text_center}`}>Viewed</div>
          <div className={`${styles.pack_detail_card_header} ${styles.w_20} ${styles.text_center} `}>Download</div>
          <div className={`${styles.pack_detail_card_header} ${styles.w_20} ${styles.text_center}`}>Favorite</div>
          <div className={`${styles.pack_detail_card_header} ${styles.w_20} ${styles.text_center}`}>Date</div>
        </div>

        {activeUsers && activeUsers.length > 0 && (
          activeUsers.map((activerObj, index) => (
            <Fragment key={`pack-detail-card-${index}`}>
              <div style={{ marginLeft: "3px" }}>
                <div className={styles.pack_detail_card_layout} style={{ border: "none", borderTop: "1px solid #323333" }}>
                  <div className={styles.opportunity_card_content}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          width: "20%"
                        }}
                      >
                        <div className={styles.opportunity_card_image_container}>
                          <div
                            className={styles.opportunity_card_image}
                            style={{
                              background: `url(${activeUsersAvatar[index]})center center / cover no-repeat`,
                              borderRadius: "100px"
                            }}
                          />
                        </div>
                        <div>
                          {/* <div className={styles.pack_detail_card_title}>{viewerObj.name}</div>
                          <div className={styles.pack_detail_content_title}>{TimeAgo(viewerObj.time)}</div> */}

                          <div className={styles.pack_detail_card_title}>{activerObj.name}</div>
                          <div className={styles.pack_detail_content_title}>{TimeAgo(activerObj.time)}</div>
                        </div>
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center}`}>
                        <EyeIcon color="#2EBB55" />
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center}`}>
                        <DownloadSvg color="#2EBB55" />
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center}`}>
                        <StarSvg color={activerObj.isFavorited ? "#F6E11F" : "#AFAFAF"} />
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center} ${styles.pack_detail_card_subtitle}`}>{formatMongoDate(activerObj.time)}</div>
                    </div>
                  </div>
                </div >
              </div>
            </Fragment>
          ))
        )}


        {nonActiveViewers && nonActiveViewers.length > 0 && (
          nonActiveViewers.map((viewerObj, index) => (
            <Fragment key={`pack-detail-card-${index}`}>
              <div style={{ marginLeft: "3px" }}>
                <div className={styles.pack_detail_card_layout} style={{ border: "none", borderTop: "1px solid #323333" }}>
                  <div className={styles.opportunity_card_content}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          width: "20%"
                        }}
                      >
                        <div className={styles.opportunity_card_image_container}>
                          <div
                            className={styles.opportunity_card_image}
                            style={{
                              background: `url(${nonActiveViewersAvatar[index]})center center / cover no-repeat`,
                              borderRadius: "100px"
                            }}
                          />
                        </div>
                        <div>
                          {/* <div className={styles.pack_detail_card_title}>{viewerObj.name}</div>
                          <div className={styles.pack_detail_content_title}>{TimeAgo(viewerObj.time)}</div> */}

                          <div className={styles.pack_detail_card_title}>{viewerObj.name}</div>
                          <div className={styles.pack_detail_content_title}>{TimeAgo(viewerObj.time)}</div>
                        </div>
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center}`}>
                        <EyeIcon color="#2EBB55" />
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center}`}>
                        <DownloadSvg color="#AFAFAF" />
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center}`}>
                        <StarSvg color={viewerObj.isFavorited ? "#F6E11F" : "#AFAFAF"} />
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center} ${styles.pack_detail_card_subtitle}`}>{formatMongoDate(viewerObj.time)}</div>
                    </div>
                  </div>
                </div >
              </div>
            </Fragment>
          ))
        )}

        {nonActiveDownloaders && nonActiveDownloaders.length > 0 && (
          nonActiveDownloaders.map((downloaderObj, index) => (
            <Fragment key={`pack-detail-card-${index}`}>
              <div style={{ marginLeft: "3px" }}>
                <div className={styles.pack_detail_card_layout} style={{ border: "none", borderTop: "1px solid #323333" }}>
                  <div className={styles.opportunity_card_content}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          width: "20%"
                        }}
                      >
                        <div className={styles.opportunity_card_image_container}>
                          <div
                            className={styles.opportunity_card_image}
                            style={{
                              background: `url(${nonActiveDownloadersAvatar[index]})center center / cover no-repeat`,
                              borderRadius: "100px"
                            }}
                          />
                        </div>
                        <div>
                          <div className={styles.pack_detail_card_title}>{downloaderObj.name}</div>
                          <div className={styles.pack_detail_content_title}>{TimeAgo(downloaderObj.time)}</div>
                        </div>
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center}`}>
                        <EyeIcon color="#AFAFAF" />
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center}`}>
                        <DownloadSvg color="#2EBB55" />
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center}`}>
                        <StarSvg color={downloaderObj.isFavorited ? "#F6E11F" : "#AFAFAF"} />
                      </div>
                      <div className={`${styles.w_20} ${styles.text_center} ${styles.pack_detail_card_subtitle}`}>{formatMongoDate(downloaderObj.time)}</div>
                    </div>
                  </div>
                </div >
              </div>
            </Fragment>
          ))
        )}
      </div>
      <EditPackModal open={editPackModalOpen} setOpen={setEditPackModalOpen} />

    </>
  );
};

export default PackMoreDetail;
