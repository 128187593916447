import React, { memo } from "react";
import { Box, Button, Modal } from "@mui/material";
import { ModelContentBox } from "./style/MusicianSplitModelStyle";
import CloseIcon from "@mui/icons-material/Close";
import { green_sad_cloud } from "../../../../../assets/smartlink";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "520px",
  bgcolor: "#1C1B1B",
  pt: 2,
  px: 4,
  pb: 3,
};

const BugsPopup = ({ open = false, setOpen = () => {} }) => {
  const PopupClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(!open);
    RefreshPage();
  };

  const RefreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={PopupClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="main"
          sx={{
            ...style,
            width: { xs: "98%", sm: "520px" },
            padding: "42px 60px 88px 60px",
            borderRadius: "20px",
            background: "#141617",
            backdropFilter: "blur(200px)",
          }}
        >
          <Box className="bugpopup_header">
            <CloseIcon className="close-icon" onClick={PopupClose} />
          </Box>
          <img src={green_sad_cloud} alt="green_sad_cloud" />
          <h5 className="oops">Ooops!</h5>
          <h6 className="oops_desc">
            Sorry we are experiencing a high volume of traffic please come back
            later
          </h6>
          <Button
            variant="contained"
            className="oops_btn"
            sx={{ textTransform: "capitalize !important" }}
            onClick={RefreshPage}
          >
            try again later
          </Button>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(BugsPopup);
