/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { Button, Image, Text, VStack, Box } from 'native-base';
import React from 'react';
import Modal from '@material-ui/core/Modal';
import { ThemeProvider, createTheme } from '@material-ui/core';
import styles from './styles.module.css';
export function DeletePayment(props) {
  const { openModal, closeModal, deleteFtn } = props;
  const darkTheme = createTheme({
    palette: {
      type: 'dark',
    },
  });
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const modalStyle = getModalStyle();

  return (
    <Modal open={openModal} onClose={closeModal}>
      <div style={modalStyle} className={styles.modalPaper}>
        <Box backgroundColor={'#141313'}>
          {/* <Modal.CloseButton
						_hover={{ bg: '#141313' }}
						_focused={{ bg: '#2EBA55' }}
						_pressed={{ bg: 'transparent' }}
						mx={5}
					/> */}

          <VStack
            pt='20'
            backgroundColor={'#141313'}
            w={'90%'}
            alignSelf={'center'}
          >
            <Image
              w={'30%'}
              height={{ lg: '80px', sm: '50px', base: '30px' }}
              width={{ lg: '80px', sm: '50px', base: '30px' }}
              borderRadius={30}
              borderColor={'#141313'}
              alignSelf={'center'}
              source={require('../../../assets/Popup/trash.png')}
              alt='delete'
            />

            <Text
              w={'70%'}
              fontSize={{ lg: '20px', md: '16px', sm: '16px' }}
              fontStyle={'bold'}
              color={'#afafaf'}
              py='2'
              alignSelf={'center'}
              textAlign={'center'}
            >
              Do you really want to delete this card?
            </Text>
          </VStack>
          <Button.Group
            justifyContent={'space-between'}
            alignSelf={'center'}
            width={'50%'}
            my={8}
          >
            <Button
              size={'sm'}
              bg='#141313'
              borderStyle={'solid'}
              borderWidth={'1'}
              borderColor={'#2EBA55'}
              w={'40%'}
              rounded={'md'}
              alignSelf={'center'}
              _text={{
                color: '#2EBA55',
                fontSize: {
                  lg: '18px',
                  md: '16px',
                  sm: '14px',
                  base: '14px',
                },
                fontFamily: 'Poppins',
              }}
              _hover={{
                bg: '#2EBA55',
                _text: {
                  color: '#fff',
                },
              }}
              _focused={{ bg: '#2EBA55' }}
              _pressed={{ bg: 'transparent' }}
              onPress={() => {
                closeModal();
              }}
            >
              CANCEL
            </Button>
            <Button
              size={'sm'}
              bg='#2EBA55'
              borderWidth={1}
              borderColor='#2EBA55'
              _text={{
                color: '#fff',
                fontSize: {
                  lg: '18px',
                  md: '16px',
                  sm: '14px',
                  base: '14px',
                },
                fontFamily: 'Poppins',
              }}
              _hover={{
                bg: 'transparent',
                _text: {
                  color: '#2EBA55',
                },
              }}
              onPress={() => {
                deleteFtn();
                closeModal();
              }}
              w={'40%'}
              rounded={'md'}
              alignSelf={'center'}
              _focused={{ bg: '#2EBA55' }}
              _pressed={{ bg: '#2EBA55' }}
            >
              DELETE
            </Button>
          </Button.Group>
        </Box>
      </div>
    </Modal>
  );
}
