import React, { memo, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modelstyle, ModelContentBox } from "../../socialintegrate/style";
import { Box, Button, IconButton, Modal } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { close_icon } from "../../../../assets/smartlink";
import { WelcomeMain, FormMain } from "./styles/MainStyles";
import ForgetPasswordPopup from "./ForgetPasswordPopup";
import { useMutation } from "@apollo/client";
import { Login } from "../../../Hooks/Authentication/SignIn/Login/Mutation";
import { Notification } from "../../../NotifyMsg/Notification";
import { useNavigate } from "react-router-dom";
import LoginAlertPopup from "./Popups/LoginAlertPopup";
import ProducerSubscriptionPopup from "../../common/priceplans/ProducerSubscriptionPopup";
// import { TrendingDownOutlined } from "@material-ui/icons";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const SignInPopup = ({ open = false, setOpen = () => {} }) => {
  const [Alertopen, setAlertopen] = useState(false);
  const [PaymentOpen, sePaymentOpen] = useState(false);
  const navigate = useNavigate();
  const storedId = localStorage.getItem("beat_id");

  const [isloading, setIsLoading] = useState(false);
  const [loginMutation, { data, error }] = useMutation(Login);

  const CreateModelClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(!open);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const res = await loginMutation({
        variables: {
          email: values.email,
          password: values.password,
          countrycode: null,
          phoneno: null,
        },
      });

      if (res?.data?.login?.success) {
        Notification("success", res?.data?.login?.message);
        const { data } = res;
        console.log(
          "🚀 ~ file: SignInPopup.js:59 ~ handleSubmit ~ data:",
          data.isPreSignUpUser
        );

        localStorage.setItem("token", data.login.accessToken);
        localStorage.setItem(
          "opportunityToken",
          data.login?.reactionAccessToken
        );
        localStorage.setItem(
          "accountType",
          data?.login?.user?.roleId?.roleName
        );
        localStorage.setItem("name", data.login.user.email.split("@")[0]);
        localStorage.setItem("userName", data.login.user?.userName);
        if (data.login.user.profilePic) {
          localStorage.setItem("profilePic", data.login.user.profilePic);
        }
        if (data.login.user.coverPic) {
          localStorage.setItem("coverPhoto", data.login.user.coverPic);
        }
        if (data.login.user._id) {
          localStorage.setItem("userId", data.login.user._id);
        }
        if (data.login.sessionId) {
          document.cookie = `sessionId=${data.login.sessionId};path=/;domain=unitedmarket.com`;
          document.cookie = `reactionAccessToken=${data.login?.reactionAccessToken};path=/;domain=unitedmarket.com`;
        }

        if (
          data?.login?.user?.roleId?.roleName == "Music Producer" &&
          !data?.login?.isPaid
        ) {
          sePaymentOpen(true);
        } else {
          const newUrl = `/beat-inbox`;
          window.location.href = newUrl;
        }

        //
        if (storedId) {
          // window.open(`/shared-beat-create/${storedId}`);
          // const newUrl = `/shared-beat-create/${storedId}`;
          // const newUrl = `/beat-inbox`;
          // window.location.href = newUrl;
        }
        resetForm();
        // /shared-beat-create/:id
      } else {
        if (res?.data?.login?.message === "Please choose your membership") {
          const newUrl = `/signin`;
          window.location.href = newUrl;
        }
        if (
          res?.data?.login?.message ==
          "You will be allowed to login once account is approved by admin."
        ) {
          setAlertopen(true);
        } else {
          Notification("error", res?.data?.login?.message);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* <Modal
        open={true}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.50)",
          },
        }}
      > */}
      <ModelContentBox
        sx={{
          ...Modelstyle,
          width: { xs: "100%", sm: "500px" },
          padding: "2rem",
          borderRadius: "20px",
          background:
            "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
          backdropFilter: "blur(200px)",
        }}
        className="ModelContentBox"
      >
        <Box className="popup_header">
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            aria-label="settings"
            sx={{
              color: "#AFAFAF",
              backgroundColor: "none",
              fontSize: "2rem",
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <IconButton
            onClick={CreateModelClose}
            aria-label="settings"
            sx={{
              color: "#AFAFAF",
              backgroundColor: "none",
              fontSize: "2rem",
            }}
          >
            <img src={close_icon} alt="cancel-icon" />
          </IconButton>
        </Box>

        <WelcomeMain>
          <h6>Sign In to Your Account</h6>
          <p>
            Welcome back! Please enter your credentials to access your account.
          </p>
        </WelcomeMain>
        <FormMain>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors }) => (
              <Form>
                <div className={`form__field ${errors.email ? "error" : ""}`}>
                  <label htmlFor="email">Email Address:</label>
                  <Field type="text" id="email" name="email" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div
                  className={`form__field ${errors.password ? "error" : ""}`}
                >
                  <label htmlFor="password">Password</label>
                  <Field type="password" id="password" name="password" />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>

                <Box className="forgot__password">
                  <Button
                    onClick={() => {
                      navigate("/producer/auth/forgote-password");
                    }}
                  >
                    Forgot Password?
                  </Button>
                </Box>

                <Button type="submit" disabled={isloading}>
                  {isloading ? "Loading..." : "Sign In"}
                </Button>

                <Box
                  className="othre__acount"
                  sx={{ marginTop: "20px !important" }}
                >
                  <span>Don’t have an account?</span>

                  <Button
                    onClick={() => {
                      navigate("/producer/auth/user-type");
                    }}
                  >
                    Sign Up
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </FormMain>
      </ModelContentBox>
      {/* </Modal> */}
      <LoginAlertPopup
        Alertopen={Alertopen}
        AlertopenCallBack={setAlertopen}
        commingFrom="login"
      />
      <ProducerSubscriptionPopup
        paymentOpen={PaymentOpen}
        setpaymentOpen={sePaymentOpen}
      />
    </>
  );
};

export default memo(SignInPopup);
