import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ModelContentBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "& .header-icon-box": {
    textAlign: "right",

    "& .close-icon": {
      width: "30px",
      height: "30px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
}));
export { ModelContentBox };
