import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Modal,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { toDate, formatInTimeZone } from "date-fns-tz";
import { format, parse, parseISO } from "date-fns";
import axios from "axios";

import { getTimeFromUTC } from "../../../shared/constants";
import TrashSuccessIcon from "../../../assets/trash-success.png";
import styles from "../mycontracts.module.css";

import DeleteIcon from "../../../assets/trash.png";
import DropDownIcon from "../../../assets/Icons/DropDownIconWhite.png";
import SearchIcon from "../../../assets/Search.png";
import CloseIcon from "../../../assets/close.png";
import GreenDeleteIcon from "../../../assets/GreenDelete.png";
import EditIcon from "../../../assets/edit-2.png";
import { Notification } from "../../NotifyMsg/Notification";
import { useTableStyles } from "../../../shared/constants";

function createData(creator, owner, createDate, modifyDate, folder, action) {
  return { creator, owner, createDate, modifyDate, folder, action };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "title", numeric: false, disablePadding: true, label: "Title" },
  { id: "owner", numeric: true, disablePadding: false, label: "Owner" },
  {
    id: "createDate",
    numeric: true,
    disablePadding: false,
    label: "Date Created",
  },
  {
    id: "modifyDate",
    numeric: true,
    disablePadding: false,
    label: "Last Change",
  },
  { id: "folder", numeric: true, disablePadding: false, label: "Folder" },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className={classes.tableCell}></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.id !== "action" && headCell.id !== "creator"
                ? "right"
                : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              classes={{
                active: classes.tablesortlabelactive,
                icon: classes.tablesortLabelIcon,
              }}
              style={{
                color: "white",
                fontSize: 18,
                fontWeight: 500,
                lineHeight: 1.5,
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function TemplatesManage() {
  const classes = useTableStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [isDeleteConfirm, setIsDeletConfirm] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [templateId, setTemplateId] = useState("");

  const router = useNavigate();

  useEffect(async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_CONTRACT_URL}/templates`,
        headers: {
          "Content-Type": "application/json",
          accesstoken: `${token}`,
        },
      };
      const response = await axios(config);
      let rowStream = [];
      for (let i = 0; i < response.data.length; i++) {
        rowStream.push(
          createData(
            response.data[i].title,
            response.data[i].firstname + " " + response.data[i].lastname,
            getTimeFromUTC(response.data[i].createDate),
            getTimeFromUTC(response.data[i].createDate),
            response.data[i].templateId,
            ""
          )
        );
      }
      setRows(rowStream);
      setTemplates(response.data);
    } catch (err) {
      console.log(err);
      Notification("error", err.message);
    }
  }, [isDelete, setRows, isDeleteConfirm]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.creator);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const deleteTemplate = async () => {
    setIsDelete(false);
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_CONTRACT_URL}/templates/${templateId}`,
      headers: {
        accesstoken: localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    if (response.data.status === 200) {
      setIsDeletConfirm(true);
    } else {
      setIsDeletConfirm(true);
    }
  };

  const changeSearchText = (txt) => {
    const rowStream = templates
      .filter((item) => {
        const username = `${item.firstname} ${item.lastname}`;
        return (
          username.toLowerCase().indexOf(txt.toLowerCase()) !== -1 ||
          item.title.toLowerCase().indexOf(txt.toLowerCase()) !== -1
        );
      })
      .map((item, index) =>
        createData(
          item.title,
          item.firstname + " " + item.lastname,
          getTimeFromUTC(item.createDate),
          getTimeFromUTC(item.createDate),
          item.templateId,
          ""
        )
      );
    console.log(rowStream);
    setRows(rowStream);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {/* <button
          className={styles.btn_createNewTemplate_layout}
          onClick={() => {
            router("create");
          }}
        >
          Create New Template
        </button> */}
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className={styles.draft_user_name}>
            <img src={SearchIcon} />
            <input
              type="text"
              className={styles.draft_user_name_input}
              placeholder="Search by user or title"
              onChange={(e) => changeSearchText(e.target.value)}
            />
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.creator);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.index}
                    >
                      <TableCell
                        padding="checkbox"
                        className={classes.tableCell}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 20,
                            paddingRight: 20,
                          }}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            style={{
                              color: "#AFAFAF",
                              borderRadius: 2,
                              padding: 0,
                            }}
                            onChange={(event) =>
                              handleClick(event, row.creator)
                            }
                          />
                        </div>
                      </TableCell>
                      <TableCell
                        align="right"
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className={classes.tableCell}
                      >
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: 1.5,
                            color: "#E3E3E3",
                            margin: 0,
                          }}
                        >
                          {row.creator}
                        </p>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: 1.5,
                            color: "#FFFFFF",
                            margin: 0,
                          }}
                        >
                          {row.owner}
                        </p>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: 1.5,
                            color: "#FFFFFF",
                            margin: 0,
                          }}
                        >
                          {row.createDate.date}
                        </p>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: 1.5,
                            color: "#FFFFFF",
                            margin: 0,
                          }}
                        >
                          {row.createDate.time}
                        </p>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: 1.5,
                            color: "#FFFFFF",
                            margin: 0,
                          }}
                        >
                          {row.createDate.date}
                        </p>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            lineHeight: 1.5,
                            color: "#FFFFFF",
                            margin: 0,
                          }}
                        >
                          {row.createDate.time}
                        </p>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 18,
                            lineHeight: 1.5,
                            color: "#AFAFAF",
                            margin: 0,
                          }}
                        ></p>
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: 60,
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              onClick={() => {
                                setIsDelete(true);
                                setTemplateId(row.folder);
                              }}
                              style={{
                                height: 24,
                                width: 24,
                                cursor: "pointer",
                              }}
                            />
                            {/* <img
                              src={EditIcon}
                              style={{
                                height: 24,
                                width: 24,
                                cursor: "pointer",
                              }}
                              // onClick={() => router(`edit/${row.folder}`) }
                            /> */}
                          </div>
                          <div>
                            <button
                              className={classes.useButton}
                              onClick={() => router(`send/${row.folder}`)}
                            >
                              Use
                            </button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Modal
        open={isDeleteConfirm}
        onClose={() => {
          setIsDeletConfirm(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%" }}
          className={classes.modalpaper}
        >
          <img
            className={classes.modalcloseimg}
            src={CloseIcon}
            onClick={() => setIsDeletConfirm(false)}
          />
          <div className={classes.modalimgcontainer}>
            <img src={TrashSuccessIcon} />
            <p className={classes.modaltext}>
              Your template has been successfully deleted!
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        open={isDelete}
        onClose={() => {
          setIsDelete(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%" }}
          className={classes.modalpaper}
        >
          <div className={classes.modalimgcontainer}>
            <img src={GreenDeleteIcon} />
            <div>
              <div>
                <p className={classes.modaltest1}>Delete Template</p>
                <p className={classes.modaltext2}>
                  Do you really want to delete this template?
                </p>
              </div>
              <div className={classes.modalbuttonContainer}>
                <button
                  className={classes.modalCancelbutton}
                  onClick={() => setIsDelete(false)}
                >
                  Cancel
                </button>
                <button
                  className={classes.modalDeletebutton}
                  onClick={deleteTemplate}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
