import { Box } from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/material/styles";

const InputMain = styled(Box)(({ theme }) => ({
  width: "100%",
  gap: "0.2rem",
  //   marginTop: "15px",

  "&.no_has_label": {
    marginTop: "auto",
  },

  "&.main_error": {
    border: "0.2px solid #D83A52",
  },

  "& input": {
    width: "100%",
    height: "2.5rem",
    padding: "0rem 0.9rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    background: "#1C1B1B",
    border: "1px solid #414241",
    fontFamily: theme.typography.fontFamily,
    color: "#AFAFAF !important",
    "::placeholder": {
      color: "#AFAFAF",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      fontFamily: theme.typography.fontFamily,
    },
    "::-webkit-input-placeholder": {
      color: "#AFAFAF !important",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      fontFamily: theme.typography.fontFamily,
    },
    "&:focus": {
      border: "1px solid #414241",
      outline: "none",
    },
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    color: "#AFAFAF",
    marginBottom: "5px",
    display: "block",
    textTransform: "capitalize",
  },
}));

// text area

const TextareaMain = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",

  width: "100%",

  gap: "1rem",

  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    color: "#AFAFAF",
    textTransform: "capitalize",
  },
}));

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme, padding, border, backgroundColor, height }) => ({
    width: "100%",
    borderRadius: "0.3125rem",
    resize: "none",
    padding: padding || "0.5rem",
    background: backgroundColor || "#1C1B1B",
    border: border || "1px solid #414241",
    color: theme.palette.secondary.light,
    fontFamily: theme.typography.fontFamily,
    height: height || "",
    "&:focus": {
      border: "1px solid #414241",
      outline: "none",
    },
  })
);

const TagsInputsMain = styled(Box)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",

  width: "100%",

  gap: "1rem",

  label: {
    color: theme.palette.secondary.light,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    color: "#AFAFAF",
  },
}));

export { InputMain, TextareaMain, Textarea, TagsInputsMain };
