import { useReducer } from "react";
import {
  addCollaboratorApi,
  addSubmission,
  fetchConnectMeSubmissions,
  extendPitchingPeriod,
  getSubmission,
  getSubmissionDetailsbyId,
  updateSubmissionById,
  updateCollaboratorApi,
} from "../apis/connect-me-submission";
import { toast } from "react-toastify";

const initialArgs = {
  isLoading: false,
};

function reducer(state, action) {
  return { ...state, ...action };
}

const useConnectMeSubmissions = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const saveSubmission = async (payload) => {
    try {
      setState({ ...state, isLoading: true });
      const res = await addSubmission(payload);
      if (res) {
        toast.success("Beat Successfully Submitted");
      }
      return res;
    } catch (err) {
      toast.error("error while submitting the beat");
    } finally {
      setState({ ...state, isLoading: false });
    }
  };

  const fetchMyConnectSubmission = async (queryString) => {
    try {
      const data = await fetchConnectMeSubmissions(queryString);

      setState({ ...state, data });
      return data;
    } catch (err) {
      toast.error("error while fetching submission");
      return err;
    }
  };

  const updateSubmission = async (id, payload) => {
    try {
      await updateSubmissionById(id, payload);
    } catch (err) {
      console.info("updateSubmission err => ", err);
    }
  };

  const fetchSubmissionById = async (id, queryString) => {
    try {
      const { data } = await getSubmission(id, queryString);

      setState({ ...state, data });
      return data;
    } catch (err) {
      toast.error("error while fetching submission");
      return err;
    }
  };

  const fetchSubmissionDetailsById = async (id) => {
    try {
      const { data, opportunity } = await getSubmissionDetailsbyId(id);
      Object.assign(data, {
        opportunity: opportunity,
      });

      console.info("fetchSubmissionDetailsById data => ", data);

      setState({ ...state, data });
      return data;
    } catch (err) {
      toast.error("error while fetching Submission Details");
      return err;
    }
  };
  const updateSubmissionState = async (key, value) => {
    try {
      console.info("key, value => ", key, value);

      const _state = { ...state };
      _state.data[key] = value;
      setState(_state);
    } catch (error) {
      console.info(
        "@updateSubmissionState:  Error while updating state => ",
        error
      );
    }
  };

  const updateFavorite = async (id, payload) => {
    try {
      const updateFavorite = await updateSubmissionById(id, payload);
      if (updateFavorite.message === "Data updated") {
        toast.success(
          `Marked as ${
            payload?.isFavorite ? "favorite" : "unfavorite"
          } successfully.`
          // { toastId: payload?.isFavorite ? 1 : 2 }
        );
      }
      return updateFavorite.message;
    } catch (error) {
      console.info(
        "@UpdateFavorite status: Error while updating the favorite status =>",
        error
      );
    }
  };

  const updateFlag = async (id, payload) => {
    try {
      const updateFlag = await updateSubmissionById(id, payload);
      if (updateFlag.message === "Data updated") {
        toast.success(
          `Beat ${payload?.isFlag ? "reported" : "unreported"} successfully.`
          // { toastId: 1 }
        );
      }
      return updateFlag.message;
    } catch (error) {
      console.info(
        "@UpdateFlag status: Error while updating the flag status =>",
        error
      );
    }
  };

  const updateBeatPrice = async (id, payload) => {
    try {
      const response = await updateSubmissionById(id, payload);
      console.log("response =", response);
      if (response.status === 1) {
        toast.success("Beat price updated successfully!");
      }
      return response.data;
    } catch (error) {
      console.info("Error while updating pitching period.", error);
    }
  };

  const extendPitching = async (id, payload) => {
    try {
      const data = await updateSubmissionById(id, payload);
      if (data.message === "Data updated") {
        toast.success("Pitching Period Extended!");
      }
      return data.message;
    } catch (error) {
      console.log("error while extend the pitching period.");
    }
  };

  const addCollaboratorData = async (id, payload) => {
    try {
      const data = await addCollaboratorApi(id, payload);
      if (data.message === "Data updated") {
        toast.success("Collaborator Added successfully!");
      }
      return { data, message: data.message };
    } catch (error) {
      console.log("Error while adding collaborator =", error);
    }
  };

  const updateCollaborator = async (id, payload) => {
    try {
      const response = await updateCollaboratorApi(id, payload);
      console.info("response =", response);
      if (response) {
        toast.success("Collaborator Updated successfully!");
      }
      return response;
    } catch (error) {
      console.log("Error while updating the collaborator =", error);
    }
  };

  const updateBeatStatus = async (id, payload) => {
    try {
      const data = await updateSubmissionById(id, payload);
      if (data.message === "Data updated") {
        toast.success("Beat selected successfully");
      }
      return data.message;
    } catch (error) {
      console.log("error while selecting beat.");
    }
  };

  return {
    ...state,
    setState,
    saveSubmission,
    fetchMyConnectSubmission,
    updateSubmission,
    fetchSubmissionById,
    fetchSubmissionDetailsById,
    updateSubmissionState,
    updateFavorite,
    updateFlag,
    updateBeatPrice,
    extendPitching,
    addCollaboratorData,
    updateCollaborator,
    updateBeatStatus,
  };
};

export default useConnectMeSubmissions;
