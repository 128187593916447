import React from "react";

const ShareSvg = ({ color = "#AFAFAF" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M15.0004 12.5001C14.3458 12.5006 13.7174 12.7579 13.2504 13.2167L6.6671 10.2834V10.0001V9.72507L13.2504 6.78341C13.6628 7.18759 14.2023 7.43658 14.7774 7.48808C15.3525 7.53959 15.9277 7.39044 16.4053 7.06596C16.8829 6.74149 17.2335 6.26169 17.3975 5.70807C17.5615 5.15444 17.5288 4.56112 17.3049 4.02887C17.0811 3.49662 16.6799 3.05827 16.1695 2.78825C15.6592 2.51824 15.071 2.43321 14.5051 2.54761C13.9391 2.66202 13.4302 2.9688 13.0648 3.41586C12.6994 3.86291 12.5 4.42267 12.5004 5.00008V5.28341L5.9171 8.21674C5.56573 7.87154 5.12022 7.63782 4.63649 7.54493C4.15276 7.45204 3.65236 7.50412 3.19813 7.69463C2.74389 7.88514 2.35605 8.20559 2.0833 8.61575C1.81055 9.02591 1.66504 9.5075 1.66504 10.0001C1.66504 10.4926 1.81055 10.9742 2.0833 11.3844C2.35605 11.7946 2.74389 12.115 3.19813 12.3055C3.65236 12.496 4.15276 12.5481 4.63649 12.4552C5.12022 12.3623 5.56573 12.1286 5.9171 11.7834L12.5004 14.7251V15.0001C12.5004 15.4945 12.6471 15.9779 12.9218 16.389C13.1965 16.8001 13.5869 17.1206 14.0437 17.3098C14.5005 17.499 15.0032 17.5485 15.4882 17.452C15.9731 17.3556 16.4186 17.1175 16.7682 16.7678C17.1178 16.4182 17.3559 15.9728 17.4524 15.4878C17.5489 15.0028 17.4994 14.5002 17.3101 14.0434C17.1209 13.5866 16.8005 13.1961 16.3894 12.9214C15.9782 12.6467 15.4949 12.5001 15.0004 12.5001Z" fill={color} />
    </svg>
  );
};

export default ShareSvg;