import axios from "axios";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token || ""
}
const axiosInterceptor = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

const requestHandler = (request) => {
  request.headers["Authorization"] = `Bearer ${getToken()}`;
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = async (error) => {
  return Promise.reject(error)

  // const originalRequest = error.config;

  // if (error?.response?.status === 401 && !triedToRefreshToken) {
  //   triedToRefreshToken = true;
  //   try {
  //     const { refreshToken, token } = await getOrCreateToken(true);
  //     if (size(token) > 0) {
  //       return axiosInterceptor(originalRequest);
  //     } else {
  //       triedToRefreshToken = false;
  //       alert("Authentication failure due to error in token verification, please Login");
  //       return Promise.reject(error);
  //     }
  //   } catch (err) {
  //     triedToRefreshToken = false;
  //     alert("Authentication failure due to error in token verification, please Login");
  //     return Promise.reject(err);
  //   }
  // } else {
  //   triedToRefreshToken = false;
  //   return Promise.reject(error);
  // }
};

axiosInterceptor.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axiosInterceptor.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default axiosInterceptor;
