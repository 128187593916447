import React from "react";

const EyeIcon = ({ color = "#AFAFAF" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9375 6.86227C14.205 4.13977 11.67 2.57227 9 2.57227C7.64829 2.58219 6.32619 2.96921 5.1825 3.68977C3.91778 4.49994 2.85146 5.5842 2.0625 6.86227C1.6938 7.51297 1.5 8.24811 1.5 8.99602C1.5 9.74392 1.6938 10.4791 2.0625 11.1298C3.795 13.8598 6.33 15.4198 9 15.4198C10.3517 15.4098 11.6738 15.0228 12.8175 14.3023C14.0822 13.4921 15.1485 12.4078 15.9375 11.1298C16.3062 10.4791 16.5 9.74392 16.5 8.99602C16.5 8.24811 16.3062 7.51297 15.9375 6.86227ZM9 12.0298C8.40072 12.0298 7.8149 11.8521 7.31662 11.5191C6.81834 11.1862 6.42998 10.713 6.20065 10.1593C5.97131 9.60564 5.91131 8.9964 6.02822 8.40864C6.14514 7.82088 6.43372 7.28099 6.85747 6.85723C7.28122 6.43348 7.82112 6.1449 8.40888 6.02799C8.99664 5.91107 9.60587 5.97108 10.1595 6.20041C10.7132 6.42974 11.1864 6.81811 11.5194 7.31639C11.8523 7.81467 12.03 8.40049 12.03 8.99977C12.0305 9.39781 11.9525 9.79204 11.8004 10.1599C11.6483 10.5277 11.4251 10.8619 11.1436 11.1434C10.8622 11.4249 10.528 11.648 10.1601 11.8001C9.79228 11.9522 9.39805 12.0303 9 12.0298Z" fill={color} />
      <path d="M9.00078 6.85547C8.5776 6.85621 8.16413 6.98233 7.81258 7.2179C7.46103 7.45347 7.18716 7.78792 7.02556 8.17903C6.86396 8.57014 6.82187 9.00036 6.90461 9.41537C6.98735 9.83039 7.19121 10.2116 7.49044 10.5108C7.78967 10.81 8.17086 11.0139 8.58587 11.0966C9.00089 11.1794 9.43111 11.1373 9.82222 10.9757C10.2133 10.8141 10.5478 10.5402 10.7834 10.1887C11.0189 9.83712 11.145 9.42365 11.1458 9.00047C11.1461 8.7187 11.0908 8.43964 10.9831 8.17926C10.8754 7.91889 10.7174 7.68231 10.5182 7.48307C10.3189 7.28383 10.0824 7.12584 9.82199 7.01815C9.56161 6.91045 9.28255 6.85517 9.00078 6.85547Z" fill={color} />
    </svg>

  );
};

export default EyeIcon;
