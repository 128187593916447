import React, { memo } from "react";
import { Box, Button, Divider, Modal, useTheme } from "@mui/material";
import { ModelContentBox } from "./style/MusicianSplitModelStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  unsuccesfullusubmit_icon,
  submitsuccessfully_icon,
} from "../../../../../assets/smartlink";
import { useNavigate } from "react-router";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "#1C1B1B",
  pt: 2,
  px: 4,
  pb: 3,
};

const CreateBeatStatusModel = ({
  successfullOpen = false,
  setSuccessfullOpen = () => {},
  isSuccess = false,
  // setIsSuccess = "",
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const SuccessfullySubmittedClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setSuccessfullOpen(!successfullOpen);

    if (isSuccess) {
      window.location.reload();
    }
    // setIsSuccess(false);
  };
  const handleTryAgainClick = () => {
    window.location.reload();
  };

  const token = localStorage.getItem("token");

  return (
    <>
      <Modal
        open={successfullOpen}
        onClose={SuccessfullySubmittedClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="main"
          sx={{
            ...style,
            width: { xs: "98%", sm: "600px" },
            padding: "60px",
            borderRadius: "20px",
            background:
              "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
            backdropFilter: "blur(200px)",
            paddingBottom: `${isSuccess ? "20px !important" : "60px"}`,
            paddingRight: `${isSuccess ? "20px !important" : "60px"}`,
          }}
        >
          <Box
            className="successfully-tick-box"
            sx={{
              paddingRight: `${isSuccess ? "60px !important" : "60px"}`,
              [theme.breakpoints.down("sm")]: {
                paddingRight: `${isSuccess ? "0px !important" : "0px"}`,
              },
            }}
          >
            <CloseIcon
              className="close-icon"
              onClick={SuccessfullySubmittedClose}
            />
          </Box>
          {isSuccess ? (
            <>
              <Box className="successfully_main">
                <Box className="submit_another_padding">
                  <img
                    src={submitsuccessfully_icon}
                    alt="successfull-tick"
                    className="success-tickmark"
                  />
                  <h5>SENT</h5>

                  {!token && (
                    <>
                      <Button
                        variant="contained"
                        className="submit-another-btn"
                        onClick={() => navigate("/producer/auth/login")}
                      >
                        Log In
                      </Button>
                      <p>
                        Login to track your audio file and communicate directly
                        with the artist.
                      </p>
                      <Button
                        variant="contained"
                        className="submit-another-btn"
                        onClick={() => navigate("/producer/auth/user-type")}
                      >
                        Create an Account
                      </Button>
                      <p>
                        Want to land your next placement? Create an Account here
                        to submit to our global network of musicians.
                      </p>
                    </>
                  )}
                </Box>

                <Box className="submit_another">
                  <Button
                    variant="contained"
                    className="submit-another-btn"
                    onClick={handleTryAgainClick}
                  >
                    Submit Another Audio File
                  </Button>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <img
                src={unsuccesfullusubmit_icon}
                alt="unsuccessful-submit"
                className="success-tickmark"
              />
              <h5>Audio File Unsuccessfully Submitted</h5>
              <Box className="unsuccesfull_buttons">
                <Button
                  variant="contained"
                  className="submit-another-btn"
                  onClick={handleTryAgainClick}
                >
                  Try Again
                </Button>

                {!token && (
                  <>
                    <Divider className="divider_line" />
                    <Button
                      variant="contained"
                      className="submit-another-btn"
                      onClick={() => navigate("/producer/auth/login")}
                    >
                      Log In
                    </Button>
                    <Button
                      variant="contained"
                      className="submit-another-btn"
                      onClick={() => navigate("/producer/auth/user-type")}
                    >
                      Create an Account
                    </Button>
                  </>
                )}
              </Box>
            </>
          )}
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(CreateBeatStatusModel);
