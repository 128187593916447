import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const FeactureSec = styled(Box)(({ theme }) => ({
  //   border: "1px solid white",
  width: "94.5%",
  "& .FeactureConentBox": {
    display: "flex",
    alignItems: "center",
    "& .FeatureListbar": {
      width: "95%",
      display: "flex",
      alignItems: "flex-start",
      padding: 0,
      gap: "18px",
      "&::-webkit-scrollbar": {
        width: "1px",
        height: "2px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        display: "none",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
      "& .ListItem": {
        color: "#AFAFAF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        listStyle: "none",
        cursor: "pointer",
        position: "relative",
        "& .UnderLine": {
          width: "100%",
          height: "2px",
          background: "#2EBB55;",
          position: "absolute",
          strokeWidth: "3px",
          stroke: "#2EBB55",
          borderRadius: "50px",
          bottom: "-2px",
        },
      },
    },
    "& .FeatureHead": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "56px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "70px",
    },
    "& .FeatureListbox": {
      display: "flex",
      alignItems: "center",
      gap: "20px",

      "& .Checkicon": {
        color: "#2EBB55",
      },
      span: {
        color: "#AFAFAF",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      },
    },
  },
  [theme.breakpoints.down("xl")]: {
    "& .FeatureListbar": {
      width: "100%",
      height: "40px",
      overflowY: "hidden",
      overflowX: "scroll",
      whiteSpace: "nowrap",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& .FeatureHead": {
      fontSize: "28px !important",

      lineHeight: "50px !important",
    },
    "& .FeatureListbar": {},
    "& .FeatureListbox": {
      fontSize: "15px !important",
    },
    "& .featureLaptop": {
      marginTop: "25px !important",
    },
  },
}));

export { FeactureSec };
