import { useLazyQuery, useMutation } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
// import { withApollo } from "lib/apollo/withApollo";
// import withAollo

import Cookies from "js-cookie";
import { toast } from "react-toastify";
// import inject from "hocs/inject";

import {
  get_my_opportunities,
  get_my_submissions,
  test_collaborators,
  publish_product,
} from "../staticUtils/opportunity/createOpportunity";

import { getAllMetaFields } from "../components/OpportunitiesComponents/Opportunities/Opportunities";
export const OpportunityContext = React.createContext();

export const dataTransformToMetaFields = (data) => {
  let meta_fields = [];

  for (const [key, value] of Object.entries(data)) {
    let obj = {};
    if (key === "artistPhoto") {
      if (meta_fields.some((mf) => mf.key === "artist")) {
        let index = meta_fields.findIndex((mf) => mf.key === "artist");
        meta_fields[index]["description"] = value;
        // obj['key'] = 'artist';
        // obj['description'] = value;
        // meta_fields.push(obj);
      } else {
        obj["key"] = "artist";
        obj["value"] = "";
        obj["description"] = value;
        obj["valueType"] = "";
        meta_fields.push(obj);
      }
    } else if (key === "artistName") {
      if (meta_fields.some((mf) => mf.key === "artist")) {
        let index = meta_fields.findIndex((mf) => mf.key === "artist");
        meta_fields[index]["value"] = value;
        // obj['key'] = 'artist';
        // obj['value'] = value;
        // meta_fields.push(obj);
      } else {
        obj["key"] = "artist";
        obj["value"] = value;
        obj["description"] = "";
        obj["valueType"] = "";
        meta_fields.push(obj);
      }
    } else if (key === "collaborators") {
      if (value?.length > 0) {
        value.forEach((element) => {
          meta_fields.push({
            key: "collaborator",
            value: element.userName,
            description: element.profilePic,
            valueType: element.collab_id,
          });
        });
      }
    } else {
      obj["key"] = key;
      obj["value"] = value;
      obj["description"] = "";
      obj["valueType"] = "";
      meta_fields.push(obj);
    }
  }
  return meta_fields;
};

export const getCollaboratorsFromMetaFields = (metafields, opportunity) => {
  let collabs = metafields.filter(
    (fields) => fields.key === "collaborator" && fields.value
  );
  // let collaborator = [];
  if (collabs.length > 1) {
    return collabs.map((clb) => ({
      userName: clb.value,
      opportunity_id: opportunity._id,
      title: opportunity.title,
      description: opportunity.description,
      profilePic: clb.description,
      createdAt: opportunity.publishedAt,
    }));
  }
  return [];
};

export const getTitleFromSlug = (slug) => {
  return slug
    .split("-")
    .map((word) => word)
    .join(" ");
};

export const getUniqueDataById = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

const OpportunityProvider = ({ children }) => {
  const [opportunities, setOpportunities] = React.useState([]);
  const [submissions, setSubmissions] = React.useState([]);
  const [opportunityLoader, setOpportunityLoader] = React.useState(false);
  const [submissionLoader, setSubmissionLoader] = React.useState(false);
  const [collaborators, setCollaborators] = React.useState([]);
  const [colabLoader, setCollabLoader] = React.useState(false);
  const [playingSong, setPlayingSong] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [user, setUser] = React.useState();
  const [privileges, setPrivileges] = React.useState(null);

  //const { loadData } = useContext(OpportunityContext);

  // const [getCollaborators] = useLazyQuery(getBidsByAccountId, {
  //   fetchPolicy: 'no-cache',
  //   onCompleted(data) {
  //     setCollabLoader(false);
  //     if(data.getBidsbyAccountId) {
  //       setCollaborators([...data.getBidsbyAccountId])
  //     }
  //     console.log('submitted collaboration ...', data)
  //   },
  //   onError(err) {
  //   setCollabLoader(false);
  //     console.log('error ...', err)
  //   }
  // })
  const [myOpportunities] = useLazyQuery(get_my_opportunities, {
    context: { clientName: "opportunities" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data && Object.keys(data).length > 0) {
        const opportunityData = data.viewer?.Product;
        // console.log('my posted opportunities...', opportunityData)
        if (opportunityData && Object.keys(opportunityData).length > 0) {
          const newData = opportunityData
            .filter((prd) => prd.publishedAt)
            .map((prd) => ({
              id: prd?._id,
              type: "opportunity",
              description: prd?.description,
              variantId: prd?.variants[0]?._id,
              title: prd?.title,
              upVotes: prd?.upVotes,
              slug: prd?.slug,
              count: 0,
              createdAt: prd?.createdAt,
              meta_fields: prd?.metafields && getAllMetaFields(prd.metafields),
            }))
            .reverse();
          setOpportunities([...newData]);
          getSubmissionsCount(newData);
          let collaboratorsdata = opportunityData
            .filter((prd) => prd.publishedAt)
            .map((prd) => getCollaboratorsFromMetaFields(prd?.metafields, prd));
          let newArray = [];
          for (var i = 0; i < collaboratorsdata.length; i++) {
            if (collaboratorsdata[i].length > 0) {
              collaboratorsdata[i].forEach((clb) => {
                newArray.push(clb);
              });
            }
          }
          setCollaborators([...newArray]);
        }
      }
      setOpportunityLoader(false);
    },
    onError({ graphQLErrors, networkError }) {
      setOpportunityLoader(false);
      if (graphQLErrors) {
        graphQLErrors.every(({ message }) => {
          // console.log("error : ", message)
          if (message === "Unauthenticated user") {
            toast.error("Session Expired..");
            localStorage.removeItem("accounts:fileUploadToken");
            localStorage.removeItem("accounts:accessToken");
            Cookies.remove("sessionId", { path: "/" });
            return (window.location.href = "https://unitedmarket.com/signin");
          }
        });
      }
    },
  });

  const [mySubmissions] = useLazyQuery(get_my_submissions, {
    context: { clientName: "opportunities" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      // console.log('submisisons data ...', data)
      setSubmissionLoader(false);
      if (data && Object.keys(data).length > 0) {
        const submissionsData = data?.getBidsbyAccountId;
        if (submissionsData && submissionsData.length > 0) {
          let submission = submissionsData.map((submission) => ({
            id: submission.productId,
            title: getTitleFromSlug(submission.productSlug),
            productId: submission.reactionProductId,
            variantId: submission.reactionVariantId,
          }));
          let uniqueSubmission = getUniqueDataById(submission, "id");
          // console.log('unique submissions ...', uniqueSubmission)
          setSubmissions([...uniqueSubmission]);
        }
      }
    },
    onError({ graphQLErrors, networkError }) {
      setSubmissionLoader(false);
      if (graphQLErrors) {
        graphQLErrors.every(({ message }) => {
          // console.log("error : ", message)
          if (message === "Unauthenticated user") {
            toast.error("Session Expired..");
            localStorage.removeItem("accounts:fileUploadToken");
            localStorage.removeItem("accounts:accessToken");
            Cookies.remove("sessionId", { path: "/" });
            return (window.location.href = "https://unitedmarket.com/signin");
          }
        });
      }
    },
  });

  // const getSubmissionsCount = async (data) => {
  //   const submissionData = await Promise.allSettled(
  //     data.map(async (record) => ({
  //       id: record.id,
  //       count: await getBidsCount({ productId: record.id, variantId: record.variantId }),
  //     })),
  //   );
  //   const filterdSubmission = submissionData
  //     .filter((record) => record.status === "fulfilled")
  //     .map((record) => ({
  //       id: record.value?.id,
  //       count: record.value.count,
  //     }));
  //   console.log("submission data...", data);
  //   let newOpportunities = data;
  //   filterdSubmission.forEach((sub) => {
  //     let index = newOpportunities.findIndex((ele) => ele.id === sub.id);
  //     if (index >= 0) {
  //       newOpportunities[index].count = sub.count;
  //     }
  //   });
  //   setOpportunities([...newOpportunities]);
  // };

  const [removeCollaborators] = useMutation(test_collaborators, {
    context: { clientName: "opportunities" },
    fetchPolicy: "no-cache",
  });
  const [publishProduct] = useMutation(publish_product, {
    context: { clientName: "opportunities" },
    fetchPolicy: "no-cache",
  });

  // const [getBidsCount] = useLazyQuery(get_bids_count, {
  //   fetchPolicy: "no-cache",
  // });

  const opportunityHandler = () => {
    setOpportunityLoader(true);
    // console.log("running my opportunities before...");
    myOpportunities();
  };

  const submissionHandler = () => {
    setSubmissionLoader(true);
    mySubmissions();
  };

  const setUserData = (data) => {
    if (Object.keys(data).length > 0) {
      setUser(data);
    }
  };

  const addOpportunity = (data) => {
    if (Object.keys(data).length > 0) {
      setOpportunities([data, ...opportunities]);
    }
  };

  const deleteOpportunityByID = (id) => {
    if (id) {
      setOpportunities(
        opportunities.filter((opportunity) => opportunity.id !== id)
      );
    }
  };

  const addSubmission = (data) => {
    if (submissions.length > 0) {
      if (!submissions.some((sb) => sb.id === data.id)) {
        setSubmissions([data, ...submissions]);
      }
    }
  };

  const deleteSubmissionByID = (id) => {
    if (id) {
      setSubmissions(submissions.filter((submission) => submission.id !== id));
    }
  };
  const deleteSubmissionByProductId = (id) => {
    if (id) {
      setSubmissions(
        submissions.filter((submission) => submission.productId !== id)
      );
    }
  };

  const deleteCollaboratorByID = async (opportunityId, id) => {
    if (opportunityId && id) {
      let opportunitiesData = opportunities;
      const index = opportunitiesData.findIndex(
        (opportunity) => opportunity.id === opportunityId
      );
      if (index >= 0) {
        let collaborator = opportunitiesData[
          index
        ].meta_fields?.collaborators.filter(
          (collaborator) => collaborator.collab_id !== id
        );
        console.log("collaborator..", collaborator);
        opportunitiesData[index]["meta_fields"]["collaborators"] = collaborator;
        let metafields = dataTransformToMetaFields(
          opportunities[index]["meta_fields"]
        );
        try {
          const response = await removeCollaborators({
            variables: {
              productId: opportunities[index].id,
              metafields: metafields,
            },
          });
          // console.log("response of remove collaborator..", response);
          if (response.data?.updateProduct?.product) {
            const publishStatus = await publishProduct({
              variables: {
                productId: opportunities[index].id,
              },
            });
            // console.log("publish status...");
            setOpportunities([...opportunitiesData]);
            return toast.success("Collaborator removed successfully.");
          } else {
            return toast.error("something went wrong.");
          }
        } catch (err) {
          // console.log("error on deleting collaborator..", err);
          return toast.error("something went wrong");
        }
      }
    }
  };

  const addCollaborator = async (opportunityId, data) => {
    let opportunitiesData = opportunities;
    const index = opportunitiesData.findIndex(
      (opportunity) => opportunity.id === opportunityId
    );
    if (index >= 0) {
      let collaborators =
        opportunitiesData[index]["meta_fields"]["collaborators"] || [];
      opportunitiesData[index]["meta_fields"]["collaborators"] = [
        ...collaborators,
        data,
      ];
      setOpportunities([...opportunitiesData]);
    }
  };

  const getSubmissionsCount = async (data) => {
    const submissionData = await Promise.allSettled(
      data.map(async (record) => ({
        id: record.id,
        // count: await getBidsCount({
        //   productId: record.id,
        //   variantId: record.variantId,
        // }), ADD
      }))
    );
    const filterdSubmission = submissionData
      .filter((record) => record.status === "fulfilled")
      .map((record) => ({
        id: record.value?.id,
        count: record.value.count,
      }));
    // console.log("submission data...", data);
    let newOpportunities = data;
    filterdSubmission.forEach((sub) => {
      let index = newOpportunities.findIndex((ele) => ele.id === sub.id);
      if (index >= 0) {
        newOpportunities[index].count = sub.count;
      }
    });
    setOpportunities([...newOpportunities]);
  };
  // const getAllCollaborators = () => {
  //   console.log('colaborations...')
  //   setCollabLoader(true);
  //   getCollaborators();
  // }

  return (
    <OpportunityContext.Provider
      value={{
        opportunities,
        submissions,
        submissionLoader,
        collaborators,
        colabLoader,
        isLoading,
        user,
        setUser,
        setUserData,
        setIsLoading,
        addOpportunity,
        addSubmission,
        publishProduct,
        opportunityHandler,
        opportunityLoader,
        deleteOpportunityByID,
        deleteSubmissionByProductId,
        deleteSubmissionByID,
        submissionHandler,
        playingSong,
        setPlayingSong,
        deleteCollaboratorByID,
        addCollaborator,
        privileges,
        setPrivileges,
      }}
    >
      {children}
    </OpportunityContext.Provider>
  );
};

export default OpportunityProvider;
