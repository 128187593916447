import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

const MainVideoBox = styled(Box)(({ theme }) => ({
  height: "80%",
  width: "30%",
  margin: "auto",
  marginTop: "5%",
}));

export { MainVideoBox };
