import React from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  LinkInsightsLayout,
  MainTitle,
  PageTitle,
  InsightSources,
} from "../../../components/smartlink";

const InsightDevicesPage = () => {
  return (
    <>
      <SmartLinkLayout>
        <MainTitle title="Smart Link" hasbutton={false} />
        <HorizontalMenu />
        <PageTitle NavigateTitle="Insight" hasNavigate={true} />
        <LinkInsightsLayout>
          <InsightSources />
        </LinkInsightsLayout>
      </SmartLinkLayout>
    </>
  );
};

export default InsightDevicesPage;
