import React, { memo } from "react";
import Pagination from "@mui/material/Pagination";
import { Main } from "./style";

const PaginationMain = ({
  pageCount = 0,
  page = 1,
  handelChange = () => {},
}) => {
  return (
    <>
      <Main>
        <Pagination count={pageCount} page={page} onChange={handelChange} />
      </Main>
    </>
  );
};

export default memo(PaginationMain);
