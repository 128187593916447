import React, { useState, memo } from "react";
import { Box, Button, IconButton, Modal } from "@mui/material";
import { ModelContentBox } from "./sharestyle";
import {
  close_icon,
  gray_copy,
  search_icon,
  refresh_icon,
  grey_refresh,
} from "../../../../../assets/smartlink";
import DoneIcon from "@mui/icons-material/Done";
import InputField from "../../../common/inputfield/inputField";
import { Notification } from "../../../../NotifyMsg/Notification";
import LoadingSpinner from "../../../common/loader/LoadingSpinner";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { UPDATE_COLLABORATOR_STATUS } from "../../../../../Graphql_services/beatinbox/Beats";
import { useMutation } from "@apollo/client";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "background.paper",
  pt: 2,
  px: 4,
  pb: 3,
};

const AddCollaboratorPopup = ({
  collaboratorOpen = false,
  setCollaboratorOpen = () => {},
  SharedData = [],
}) => {
  const [
    updateCollaboratorStatus,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_COLLABORATOR_STATUS);

  const [copySuccess, setCopySuccess] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [loading, setloading] = useState(false);

  // /inbox-beats/${data?.title.toLowerCase()}/unitedmarket/${
  //   data?._id

  const inputRef = useRef(null);

  useEffect(() => {
    if (SharedData) {
      const Link = `inbox-beats/${SharedData?.title?.toLowerCase()}/unitedmarket/${
        SharedData?._id
      }`;
      setInputValue(Link);
    }
  }, [SharedData]);

  const copyToClipboard = async (textToCopy) => {
    const Url = window.location.href.split("/");
    const BaseUrl = Url[0] + "//" + Url[2];
    try {
      await navigator.clipboard.writeText(`${BaseUrl}${textToCopy}`);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
      Notification("success", `${SharedData.title} link copied`);
    } catch (err) {
      console.error("Copy failed: ", err);
    }
  };

  const handleEditClick = () => {
    setIsEditable(true);
    const newValue = inputValue.replace("/unitedmarket", "");
    setInputValue(newValue);
  };

  const handleBlur = () => {
    setIsEditable(false);
  };

  const validateInput = () => {
    // const lettersOnlyRegex = /^[A-Za-z]+$/;
    const lettersOnlyRegex = /^[A-Za-z0-9.]+$/;
    const Value = inputValue.trim();
    if (!Value) {
      Notification("error", "Please enter a value.");
      return false;
    }

    if (!lettersOnlyRegex.test(Value)) {
      Notification(
        "error",
        "Please enter only letters, numbers, or decimal points without special characters."
      );
      return false;
    }

    return true;
  };

  const AddCollaboratorClose = () => {
    setCollaboratorOpen(!collaboratorOpen);
  };

  const handleSubmit = async (value, { resetForm }) => {
    const Url = window.location.href.split("/");
    const BaseUrl = Url[0] + "//" + Url[2];
    const Link = `${BaseUrl}/${inputValue}`;

    try {
      const { data } = await updateCollaboratorStatus({
        variables: {
          email: value.email,
          link: Link,
        },
        context: { clientName: "beats" },
      });

      if (data?.addBeatInboxCollaborator?.success) {
        Notification("success", "Email Send Successfully");
        resetForm();
        console.log("Collaborator status updated successfully");
      } else {
        Notification(
          "error",
          data?.addBeatInboxCollaborator?.message || "something went wrong"
        );
      }
    } catch (error) {
      Notification("error", error.message || "something went wrong");
      console.error("Error updating collaborator status:", error);
    }
  };

  return (
    <>
      <Modal
        open={collaboratorOpen}
        onClose={AddCollaboratorClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...style,
            width: { xs: "100%", sm: "505px" },
            display: "flex",
            alignItems: "center",
            padding: "40px",
            borderRadius: "10px",
            background: "#141515",
            gap: "0 !important",
          }}
        >
          <Box className="header">
            <h3>Add Collaborator</h3>
            <img
              className="close-icon"
              src={close_icon}
              alt="cancel-icon"
              onClick={AddCollaboratorClose}
            />
          </Box>

          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, setFieldValue, errors }) => (
              <Form>
                <Box className="input-box">
                  <Field name="email">
                    {({ field, form }) => (
                      <>
                        <Box
                          className={`${
                            form.errors.email ? "error-input" : ""
                          }`}
                        >
                          <InputField
                            field={field}
                            label="Email"
                            placeholder="enter collaborator email"
                            name="email"
                            type="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          />
                        </Box>
                      </>
                    )}
                  </Field>
                </Box>
                <Box className="search_main add_collaboratore">
                  <Box className="search_box">
                    {!isEditable && <img src={search_icon} alt="gray_copy" />}
                    {isEditable ? (
                      <input
                        ref={inputRef}
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value.trim())}
                        // onBlur={handleBlur}
                        autoFocus
                        className="editable_input"
                      />
                    ) : (
                      // <input
                      //   // type="text"
                      //   value={inputValue}
                      //   readOnly
                      //   style={{ userSelect: "none" }}
                      // >
                      <span
                        style={{
                          userSelect: "none",
                          color: "#FFF",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                        }}
                      >
                        {inputValue}
                      </span>
                    )}
                  </Box>

                  {!isEditable && (
                    <>
                      {/* <IconButton
                  color="primary"
                  aria-label="gray_copy"
                  onClick={handleEditClick}
                >
                  <img src={grey_refresh} alt="refresh_icon" />
                </IconButton> */}
                      <IconButton
                        color="primary"
                        aria-label="gray_copy"
                        onClick={() => copyToClipboard(`/${inputValue}`)}
                        className={`${copySuccess ? "copySuccess" : ""}`}
                      >
                        {!copySuccess ? (
                          <img src={gray_copy} alt="gray_copy" />
                        ) : (
                          <DoneIcon sx={{ color: "#fff" }} />
                        )}
                      </IconButton>
                    </>
                  )}
                  {isEditable && (
                    <IconButton
                      color="primary"
                      aria-label="gray_copy"
                      // onClick={handleUpdateBeatLink}
                      className="copySuccess"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress style={{ color: "#fff" }} size={20} />
                      ) : (
                        <DoneIcon sx={{ color: "#fff" }} />
                      )}
                    </IconButton>
                  )}
                </Box>
                <Button
                  variant="contained"
                  className="btn"
                  type="submit"
                  sx={{
                    "&:disabled": {
                      background: "gray !important",
                      color: "#fff !important",
                    },
                  }}
                  disabled={mutationLoading}
                >
                  {mutationLoading ? (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "#fff",
                      }}
                    />
                  ) : (
                    "invite"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(AddCollaboratorPopup);
