import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.MuiCheckbox-root": {
    color: "#AFAFAF",
    fontSize: "25px",
    borderRadius: "100px",
    "&.Mui-checked": {
      color: "#2AC171",
    },
  },
  "& .MuiCheckbox-indeterminate": {
    color: "#2AC171",
  },
}));

const CustomCheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiTypography-root": {
    marginLeft: "12px",
    marginTop: "30px",
    display: "flex",
    "& h5": {
      color: "#AFAFAF",
      width: "auto",
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      fontStyle: "italic",
      fontWeight: 400,
    },
    "& .content": {
      display: "flex",
      position: "relative",
      alignItems: "center",
      "& .icon-main": {
        "& .escalimation": {
          marginLeft: "10px",
          color: "#AFAFAF",
          cursor: "pointer",
          marginBottom: "-5px",
          fontSize: "20px",
          "&:hover": {
            borderRadius: "100px",
            filter:
              "invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)",
          },
        },
        "& .icon-content": {
          position: "absolute",
          top: "100%",
          right: "30",
          zIndex: "9999",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "5px",
          background: "#141515",
          padding: "18px",
          color: "#AFAFAF",
          fontFamily: theme.typography.fontFamily,
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          width: "230px",
          [theme.breakpoints.down("sm")]: {
            left: "0px",
          },
        },
      },
    },
  },
}));
export { CustomCheckbox, CustomCheckboxLabel };
