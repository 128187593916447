import React, { useRef, useState, memo, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { ModelContentBox, Modelstyle } from "./styles/style";
import RadioButtons from "./radiobuttons";
import {
  close_icon,
  Spotifylink,
  Instagramlink,
} from "../../../../assets/smartlink";
import InputField from "../../common/inputfield/inputField";
// import DescriptionArea from "../../common/inputfield/TextAreaField";
import axios from "axios";
import {
  CreateBeatsMutation,
  GetAllActiveBeatBoxes,
  UPDATE_BEAT_INBOX,
} from "../../../../Graphql_services/beatinbox/Beats";
import CircularProgress from "@mui/material/CircularProgress";
import { Notification } from "../../../NotifyMsg/Notification";
import InboxPasswordPopup from "./InboxPasswordPopup";
import BugsPopup from "../../producers/producerssubmit/popups/BugsPopup";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .matches(/^[a-zA-Z0-9\s]+$/, "Title must contain only letters and numbers")
    .max(20, "Title must be less than 20 characters"),
  musicianName: Yup.string().required("Nusician Name is required"),
  description: Yup.string()
    .required("Description is required")
    .max(150, "Description must be less than 150 characters"),
  SpotifyLink: Yup.string()
    .required("Spotify Link required")
    .matches(
      /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/,
      "Invalid Spotify Link format. Include http/https"
    ),
  InstagramLink: Yup.string()
    .required("Instagram Link required")
    .matches(
      /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/,
      "Invalid Instagram Link format. Include http/https"
    ),
});

const CreateBeatInboxPopup = ({
  open = false,
  setOpen = () => {},
  EditData = {},
  setEditData = () => {},
  image = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [UploadImage, setUploadImage] = useState(null);
  const [CreateImageUrl, setCreateImageUrl] = useState(null);
  const [inboxPasswordOpen, setinboxPasswordOpen] = useState(false);
  const [InboxType, SetInboxType] = useState("Public");
  const [beatloop, Setbeatloop] = useState("Beats");
  const [getpassword, Setgetpassword] = useState(null);
  const [Bugopen, setBugopen] = useState(false);

  const ProfileImage = localStorage.getItem("profilePic");

  const CreateModelClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(!open);
    setCreateImageUrl(null);
    setSelectedImage(null);
    setEditData({});
    setinboxPasswordOpen(false);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setUploadImage(selectedFile);
    setSelectedImage(URL.createObjectURL(selectedFile));

    const profileData = new FormData();
    setIsLoadingProfile(true);
    try {
      profileData.append("file", selectedFile);
      profileData.append("type", "Public");

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_FILE_URL}/file/uploadprofilepicture`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: profileData,
      };
      const response = await axios(config);
      if (response.status === 200) {
        setCreateImageUrl(response?.data?.fileLocation);
        Notification("success", "Image uploaded successfully!");
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: CreateBeatInboxPopup.js:65 ~ UploadImagehandler ~ error:",
        error
      );
      Notification("error", error.message);
    } finally {
      setIsLoadingProfile(false);
    }
  };

  const [createBeat, { loading: createLoading, error: createError }] =
    useMutation(CreateBeatsMutation, {
      refetchQueries: [GetAllActiveBeatBoxes, "getActiveBeatBoxes"],
      context: { clientName: "beats" },
    });

  const [UpdateBeat, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_BEAT_INBOX, {
      refetchQueries: [GetAllActiveBeatBoxes, "getActiveBeatBoxes"],
      context: { clientName: "beats" },
    });

  const handleSubmit = async (value, { resetForm }) => {
    if (
      InboxType === "Private" &&
      !getpassword &&
      Object.keys(EditData).length === 0
    ) {
      Notification("error", "Please Enter The Password");
      setinboxPasswordOpen(true);
      return;
    }
    // if (
    //   InboxType === "Private" &&
    //   !getpassword &&
    //   Object.keys(EditData).length != 0
    // ) {
    //   Notification("error", "Please Enter The Password");
    //   return;
    // }

    setIsLoading(true);
    if (!CreateImageUrl && !ProfileImage) {
      Notification("error", "Please upload image");
      setIsLoading(false);
      return;
    }

    try {
      const VariableData = {
        title: value.title,
        description: value.description,
        image: CreateImageUrl || ProfileImage,
        spotifyUrl: value.SpotifyLink,
        instagramUrl: value.InstagramLink,
        musicianName: value.musicianName,
        isPublic: InboxType === "Public" ? true : false,
        isBeat: beatloop === "Beats" ? true : false,
      };
      if (
        InboxType === "Private" &&
        getpassword &&
        Object.keys(EditData).length === 0
      ) {
        VariableData.password = getpassword;
      }
      if (
        InboxType === "Private" &&
        getpassword &&
        Object.keys(EditData).length > 0
      ) {
        VariableData.password = getpassword;
      }
      let createdData;
      if (Object.keys(EditData).length !== 0) {
        VariableData.inboxId = EditData?._id;
        const { data } = await UpdateBeat({
          variables: VariableData,
        });
        createdData = data;
        if (data?.updateBeatBox?.success) {
          Notification("success", data?.updateBeatBox?.message);
          setCreateImageUrl(null);
          setSelectedImage(null);
          console.clear();
          setOpen(false);
        } else {
          Notification("error", data?.createBeatBox?.message);
        }
      } else {
        const { data } = await createBeat({
          variables: VariableData,
        });
        createdData = data;
        if (data?.createBeatBox?.success) {
          Notification("success", `${value.title} created`);
          setCreateImageUrl(null);
          setSelectedImage(null);
          setOpen(false);
        } else {
          Notification("error", data?.createBeatBox?.message);
        }
      }

      if (
        createdData?.createBeatBox?.success ||
        createdData?.updateBeatBox?.success
      ) {
        setEditData({});
        resetForm();
        Setgetpassword(null);
        SetInboxType("Public");
        setinboxPasswordOpen(false);
      }
    } catch (error) {
      Notification("error", error?.message || "something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  function isImageFile(file) {
    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setCreateImageUrl(null);
      return false;
    }
    return true;
  }

  // useEffect(() => {
  //   if (InboxType === "Private" && Object.keys(EditData).length === 0) {
  //     setinboxPasswordOpen(true);
  //   }
  // }, [InboxType, EditData]);

  useEffect(() => {
    setCreateImageUrl(EditData?.image || null);
    if (Object.keys(EditData).length !== 0) {
      SetInboxType(EditData?.isPublic ? "Public" : "Private");
      Setbeatloop(EditData?.isBeat ? "Beats" : "Loops");
    }
  }, [EditData]);

  useEffect(() => {
    if (createError || createError) {
      setBugopen(true);
    }
  }, [createError, createError]);

  return (
    <>
      <Modal
        open={open}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="MainInboxCreate"
          sx={{
            ...Modelstyle,
            width: { xs: "100%", sm: "500px" },
            display: "flex",
            alignItems: "center",
            overflowY: "scroll",
            padding: "3rem",
            borderRadius: "0.625rem",
            background: "#141515",
          }}
        >
          <Box className="CreateModelMead">
            <Box className="heading-box">
              <Typography variant="h3">Create Inbox</Typography>
            </Box>
            <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#fff",
                //   color: theme.palette.primary.dark,
                backgroundColor: "none",
                fontSize: "2rem",
                padding: "0 !important",
                margin: "0 !important",
                marginTop: "-7px !important",
              }}
            >
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>

          <Formik
            initialValues={{
              title: EditData?.title || "",
              description: EditData?.description || "",
              image: EditData?.image || null,
              SpotifyLink: EditData?.spotifyUrl || "",
              InstagramLink: EditData?.instagramUrl || "",
              musicianName: EditData?.musicianName || "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, setFieldValue, errors }) => (
              <Form>
                <Box className={`main-icon-box`}>
                  <img
                    src={selectedImage || EditData?.image || image}
                    alt="Icon"
                    onClick={handleUploadClick}
                    style={{ border: errors.image ? "1px solid red" : "none" }}
                  />
                </Box>

                {/* <Button
                    variant="standard"
                    className="upload-btn"
                    onClick={UploadImagehandler}
                    disabled={isLoadingProfile || UploadImage === null}
                  >
                    {isLoadingProfile ? (
                      <CircularProgress size={20} />
                    ) : (
                      "Upload Image"
                    )}
                  </Button> */}

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  // onChange={handleFileChange}
                  onChange={(event) => {
                    const selectedFile = event.currentTarget.files[0];

                    if (selectedFile && isImageFile(selectedFile)) {
                      handleFileChange(event);
                      setFieldValue("image", selectedFile);
                    } else {
                      Notification(
                        "error",
                        "Please select a valid image file (JPG,JPEG, PNG, GIF)"
                      );
                    }
                  }}
                  // accept="image/jpeg, image/png, image/gif"
                />

                <Box className="input-box">
                  <Field name="title">
                    {({ field, form }) => (
                      <>
                        <Box
                          className={`${
                            form.errors.title ? "error-input" : ""
                          }`}
                        >
                          <InputField
                            label="Title"
                            name="title"
                            field={field}
                          />
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="error"
                          />
                        </Box>
                      </>
                    )}
                  </Field>
                  <Field name="musicianName">
                    {({ field, form }) => (
                      <>
                        <Box
                          className={`${
                            form.errors.musicianName ? "error-input" : ""
                          }`}
                        >
                          <InputField
                            label="Musician Name"
                            name="musicianName"
                            field={field}
                          />
                          <ErrorMessage
                            name="musicianName"
                            component="div"
                            className="error"
                          />
                        </Box>
                      </>
                    )}
                  </Field>
                  <Field name="description">
                    {({ field, form }) => (
                      <>
                        <Box
                          className={`description_main ${
                            form.errors.description ? "error-description" : ""
                          }`}
                        >
                          <label>Description</label>
                          <textarea {...field} />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="error"
                          />
                        </Box>
                      </>
                    )}
                  </Field>
                  <Field name="SpotifyLink">
                    {({ field, form }) => (
                      <Box
                        className={`${
                          form.errors.SpotifyLink ? "error-input" : ""
                        }`}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <label
                              htmlFor="spotifyInput"
                              style={{ marginRight: "10px" }}
                            >
                              Enter Spotify Link
                            </label>
                            <img
                              src={Spotifylink}
                              alt="Spotifylink.svg"
                              style={{
                                width: "18px",
                                height: "18px",
                                marginTop: "5px",
                              }}
                            />
                          </div>
                          <InputField
                            id="spotifyInput"
                            name="SpotifyLink"
                            field={field}
                            style={{ marginRight: "10px" }}
                          />
                        </div>
                        <ErrorMessage
                          name="SpotifyLink"
                          component="div"
                          className="error"
                        />
                      </Box>
                    )}
                  </Field>
                  <Field name="InstagramLink">
                    {({ field, form }) => (
                      <Box
                        className={`${
                          form.errors.InstagramLink ? "error-input" : ""
                        }`}
                      >
                        <div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <label
                              htmlFor="InstagramLink"
                              style={{ marginRight: "10px" }}
                            >
                              Enter Instagram Link
                            </label>
                            <img
                              src={Instagramlink}
                              alt="Instagramlink.svg"
                              style={{
                                width: "18px",
                                height: "18px",
                                marginTop: "5px",
                              }}
                            />
                          </div>
                          <InputField
                            id="InstagramLink"
                            name="InstagramLink"
                            field={field}
                            style={{ marginRight: "10px" }}
                          />
                        </div>
                        <ErrorMessage
                          name="InstagramLink"
                          component="div"
                          className="error"
                        />
                      </Box>
                    )}
                  </Field>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <RadioButtons
                    title="Inbox Type"
                    label1="Public"
                    label2="Private"
                    onChange={SetInboxType}
                    defaultValue={InboxType}
                  />
                  {InboxType === "Private" &&
                    Object.keys(EditData).length != 0 && (
                      <Box
                        sx={{
                          marginTop: "10px",
                          label: {
                            color: "#FFF",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            display: "block",
                            marginBottom: "10px !important",
                          },
                          input: {
                            display: "block",
                            width: "100%",
                            height: "2.5rem",
                            padding: "0rem 0.9rem",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "normal",
                            background: "#1C1B1B",
                            border: "1px solid #414241",
                            color: "#AFAFAF !important",
                            "&:foucs": {
                              outLine: "none",
                            },
                          },
                        }}
                      >
                        <label>Update Password</label>
                        <input
                          type="password"
                          value={getpassword}
                          onChange={(e) => Setgetpassword(e.target.value)}
                        />
                      </Box>
                    )}

                  <RadioButtons
                    title="What type of Audio Files are you looking for?"
                    label1="Beats"
                    label2="Loops"
                    onChange={Setbeatloop}
                    defaultValue={beatloop}
                  />
                </Box>

                <Box className="btn-box">
                  <Button
                    variant="outline"
                    className="cancel-btn"
                    onClick={CreateModelClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="create-btn"
                    type="submit"
                    // disabled={isLoading || isLoadingProfile || !usertype}
                    disabled={isLoading || isLoadingProfile}
                    sx={{
                      "&:disabled": {
                        background: "gray !important",
                        color: "#fff !important",
                      },
                    }}
                  >
                    {isLoading || isLoadingProfile ? (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: "#fff",
                        }}
                      />
                    ) : Object.keys(EditData).length === 0 ? (
                      "Create"
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </ModelContentBox>
      </Modal>
      <InboxPasswordPopup
        Hasopen={inboxPasswordOpen}
        HassetOpen={setinboxPasswordOpen}
        Setgetpassword={Setgetpassword}
      />
      <BugsPopup open={Bugopen} setOpen={setBugopen} />
    </>
  );
};

export default memo(CreateBeatInboxPopup);
