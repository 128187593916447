import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./submit_sync.module.css";
import classes from "./submit_content.module.css";

import CloseIcon from "@material-ui/icons/Close";
import CircularLoader from "../../UniversalComponents/CircularLoader/CircularLoader";
import PageNavigationControls from "../../UniversalComponents/page_navigation_controls";
import useAppState from "../../../context/useAppState";
import CheckboxWithInfoIcon from "../AdditionalComponents/Checkbox/CheckboxWithInfoIcon";
import { RadioGroup } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";

import { Controller, useForm } from "react-hook-form";
import SyncSubmissionSchema from "../YupSchema/SyncSubmissionSchema";
import RadioButtonWithLabel from "../AdditionalComponents/Checkbox/RadioButtonWithLabel";
import useAuth from "../../../context/AuthContext";
import { get } from "lodash";
import { Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import AudioPlayer from "../../UniversalComponents/audio_player";
import { remainngTimeCount } from "../../../shared/funs";
import { DeleteSoundPack } from "../../soundpack_page/Modals/DeleteSoundPack";
import { ConfirmDeletePopup } from "../../ScreenPopups/ConfirmDeletePopup";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

import allImages from "../../../assets";

import CheckboxWithLabel from "../AdditionalComponents/Checkbox/CheckboxWithLabel";

const collaborator = {
  name: "",
  email: "",
  publishingName: "",
  publishingPRO: "",
  publishingPercentage: 0,
};

const SyncForm = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteType, setDeleteType] = useState("");

  function confirmDeleteFunction() {
    if (deleteType === "audio") {
      setValue("file", null, { shouldValidate: true });
    } else if (deleteType === "instrumentals") {
      setValue("instrumentals", null, { shouldValidate: true });
    }
  }

  const {
    data,
    fetchOpportunityById,
    saveSubmission,
    isLoading = false,
  } = useAppState("sync");
  // const { saveSubmission, isLoading = false } = useAppState("submission");
  const { uploadFile, uploadAudio } = useAppState("file");

  const [isUploading, setIsUploading] = useState(false);

  const { id } = useParams();

  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      file: null,
      opportunitiesId: id,
      title: "",
      tone: [],
      collaboratorOnLoop: undefined,
      collaborators: [],
      isContainMasterSample: undefined,
      exclusivePrice: "",
      pitchingPeriod: "",
      isMasterSample: undefined,
      agreePolicy: undefined,
      agreepublishingFee: undefined,
      agreeSyncAgreement: undefined,
    },
    resolver: yupResolver(SyncSubmissionSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onDropAudio = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0] || null;

    const payload = new FormData();

    if (file) {
      setIsUploading(true);
      payload.append("file", file);
      payload.append("type", "Public");
      try {
        const { fileLocation, audioWaveFileLocation } =
          await uploadAudio(payload);

        if (fileLocation) {
          setValue(
            "file",
            {
              name: file.name,
              url: fileLocation,
              waveUrl: audioWaveFileLocation,
            },
            { shouldValidate: true }
          );
          setIsUploading(false);
        }
      } catch (error) {
        console.info("error=>", error);
        setError("file", {
          type: "custom",
          message: get(error, "response.data.message", "Invalid file upload"),
        });
      } finally {
        setIsUploading(false);
        setIsUploading(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropAudio,
  });

  const {
    collaborators = [],
    agreePolicy,
    agreepublishingFee,
    agreeSyncAgreement,
    file,
    isIndependent,
    isMasterOwner,
    hasInstrumentals,
    instrumentals,
    collaboratorOnLoop,
    ...rest
  } = watch();

  useEffect(() => {
    if (user?._id) {
      setValue("createdBy", user._id);
    }
  }, [user]);

  useEffect(() => {
    if (collaboratorOnLoop === "no") {
      setValue("collaborators", [], { shouldValidate: true });
    }
  }, [collaboratorOnLoop]);

  useEffect(() => {
    if (id) {
      fetchOpportunityById(id);
    }
  }, [id]);

  const onSubmit = async (data) => {
    const {
      opportunitiesId,
      createdBy,
      songName,
      spotifySongLink,
      isrcCode,
      musicianName,
      publisherName,
      publisherPRO,
      isReleased,
      isIndependent,
      labelName,
      canClear,
      isMasterOwner,
      masterOwnerName,
      masterOwnerEmail,
      masterOwnerPhone,
      tone,
      file,
      mySplit,
      collaboratorOnLoop,
      collaborators,
      isContainMasterSample,
      isMasterSample,
      agreePolicy,
      agreepublishingFee,
      agreeSyncAgreement,
      hasInstrumentals,
      instrumentals,
    } = watch();
    const payload = {
      opportunitiesId,
      createdBy,
      songName,
      spotifySongLink,
      isrcCode,
      musicianName,
      publisherName,
      publisherPRO,
      labelName,
      canClear,
      isReleased,
      isIndependent,
      isMasterOwner,
      masterOwnerName,
      masterOwnerEmail,
      masterOwnerPhone,
      tone,
      file,
      isCollabrator: collaboratorOnLoop === "yes",
      publishingSplit: mySplit,
      collaborators,
      isContainMasterSample,
      isMasterSample,
      agreePolicy,
      agreepublishingFee,
      agreeSyncAgreement,
      hasInstrumentals,
      instrumentals,
    };
    console.info("payload=>", payload);

    try {
      const result = await saveSubmission(payload);
      toast.success("Sync Submission Successful!");
      if (result) {
        setTimeout(() => {
          navigate("/my-submissions/sync");
        }, 0);
      }
    } catch (error) {
      console.info("error while saving submission => ", error);
    }

    console.info("getValues", watch());
  };

  const [isLoadingAudio, setIsLoadingAudio] = useState(false);

  const onDropInstrumentals = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0] || null;

    const payload = new FormData();

    if (file) {
      setIsLoadingAudio(true);
      payload.append("file", file);
      payload.append("type", "Public");

      try {
        const { fileLocation, audioWaveFileLocation } =
          await uploadAudio(payload);
        setValue("instrumentals", {
          name: file.name,
          url: fileLocation,
          waveUrl: audioWaveFileLocation,
        });
      } catch (error) {
        console.info("error while saving file => ");
        toast.error("Something went wrong with file upload, please try again");
      } finally {
        setIsLoadingAudio(false);
      }
    }
  }, []);

  const TrackDropzone = useDropzone({
    onDrop: onDropInstrumentals,
  });

  const addCollaborators = () => {
    if (collaboratorOnLoop === "yes") {
      const { collaborators = [] } = getValues();
      const newCollaborators = [...collaborators];

      newCollaborators.push(collaborator);
      setValue("collaborators", newCollaborators);
    }
  };

  const removeCollaborator = (index) => {
    const { collaborators = [] } = getValues();
    const newCollaborators = [...collaborators];

    newCollaborators.splice(index, 1);
    setValue("collaborators", newCollaborators);
  };

  const renderRemainingDaysCount = (date) => {
    if (date) {
      const days = remainngTimeCount(data?.expireDate).days;
      return `${days} ${days <= 1 ? `day` : `days`} remaining`;
    } else {
      return ``;
    }
  };

  // console.info("errors => ", errors);

  return (
    <div className={styles.container}>
      <div className={styles.top_info}>
        <PageNavigationControls onBack={() => navigate("/opportunities")} />
        <p className={styles.page_title}>{data?.title}</p>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            {isUploading ? (
              <div className={classes.upload_track_layout}>
                <div className={classes.drag_image}>
                  <CircularLoader />
                </div>
              </div>
            ) : !file?.url ? (
              <div className={classes.upload_track_layout}>
                <div className={classes.drag_image}>
                  <div {...getRootProps()} className={classes.audioDropzone}>
                    <img
                      src="/fileUpload.png"
                      alt="upload"
                      className={classes.drag_track_image}
                    />
                    <p className={classes.drag_track_text}>
                      Upload Audio Track
                    </p>
                    <p className={classes.drag_track_text_browse}>
                      Browse Files
                    </p>

                    <input {...getInputProps()} id="uploadAudio" />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.chosen_file}>
                <DisplayReferanceTrack
                  fileName={file?.name}
                  file={file?.url}
                  setDeletePopup={(data) => setDeletePopup(data)}
                  setDeleteName={() => setDeleteType("audio")}
                  desc={"Are you sure you want to delete?"}
                />
              </div>
            )}
          </div>
          {get(errors, "file.message", "") && (
            <p className={classes.error_text_audio_file}>
              {get(errors, "file.message", "")}
            </p>
          )}

          <LineDivider />

          {/* Reference Track */}
          <Controller
            control={control}
            name="hasInstrumentals"
            render={({ field: { name, value } }) => {
              return (
                <CheckboxWithLabel
                  title="Instrumentals"
                  value={value}
                  handler={({ payload }) =>
                    setValue(name, payload, { shouldValidate: true })
                  }
                  action="hasInstrumentals"
                />
              );
            }}
          />

          <div>
            {hasInstrumentals && (
              <>
                {isLoadingAudio ? (
                  <div className={classes.track_layout}>
                    <div className={classes.drag_image}>
                      <CircularLoader />
                    </div>
                  </div>
                ) : (
                  <div className={classes.track_layout}>
                    {!instrumentals ? (
                      <div className={classes.drag_image}>
                        <>
                          <div
                            {...TrackDropzone.getRootProps()}
                            className={classes.audioDropzone}
                          >
                            <img
                              src="/fileUpload.png"
                              alt="upload"
                              className={classes.drag_track_image}
                            ></img>
                            <p className={classes.drag_track_text}>
                              Upload Instrumentals
                            </p>
                            <p className={classes.drag_track_text_browse}>
                              Browse Files
                            </p>

                            <input
                              {...TrackDropzone.getInputProps()}
                              id="uploadAudio"
                            />
                            {get(errors, `coverPhoto.message`, "") && (
                              <p className={classes.error_text}>
                                {get(errors, `coverPhoto.message`, "")}
                              </p>
                            )}
                          </div>
                        </>
                      </div>
                    ) : (
                      <div className={classes.chosen_file}>
                        <DisplayReferanceTrack
                          fileName={instrumentals?.name}
                          file={instrumentals?.url}
                          setDeletePopup={(data) => setDeletePopup(data)}
                          setDeleteName={() => setDeleteType("instrumentals")}
                          desc={"Are you sure you want to delete?"}
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>

          <LineDivider />

          <p className={styles.section_title}>
            Is this track released or unreleased?
          </p>
          <Controller
            control={control}
            name="isReleased"
            render={({
              field: { name, onBlur, onChange, value },
              formState,
            }) => {
              return (
                <>
                  <RadioGroup
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "-5px",
                    }}
                  >
                    <RadioButtonWithLabel value="true" label="Released" />
                    <RadioButtonWithLabel value="false" label="Unreleased" />
                  </RadioGroup>
                  {get(formState, `errors[${name}].message`, "") && (
                    <span style={{ color: "red" }}>
                      {get(formState, `errors[${name}].message`, "")}
                    </span>
                  )}
                </>
              );
            }}
          />

          <LineDivider />

          <div className={styles.account_info_container}>
            <Controller
              control={control}
              name="songName"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => {
                return (
                  <FormField
                    error={get(formState, `errors[${name}].message`, "")}
                    title="Song Name"
                    content={value}
                    onValueChange={(value) =>
                      setValue(name, value, { shouldValidate: true })
                    }
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="musicianName"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => {
                return (
                  <FormField
                    error={get(formState, `errors[${name}].message`, "")}
                    title="Musician Name"
                    content={value}
                    onValueChange={(value) =>
                      setValue(name, value, { shouldValidate: true })
                    }
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="spotifySongLink"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => {
                return (
                  <FormField
                    error={get(formState, `errors[${name}].message`, "")}
                    logo={true}
                    title="Spotify Song Link"
                    content={value}
                    onValueChange={(value) =>
                      setValue(name, value, { shouldValidate: true })
                    }
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="isrcCode"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => {
                return (
                  <FormField
                    error={get(formState, `errors[${name}].message`, "")}
                    title="ISRC Code"
                    content={value}
                    onValueChange={(value) =>
                      setValue(name, value, { shouldValidate: true })
                    }
                  />
                );
              }}
            />
          </div>

          <LineDivider />

          <div className={classes.account_info_container}>
            <Controller
              control={control}
              name="publisherName"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => {
                return (
                  <FormField
                    error={get(formState, `errors[${name}].message`, "")}
                    title="Publisher Name"
                    content={value}
                    onValueChange={(value) =>
                      setValue(name, value, { shouldValidate: true })
                    }
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="publisherPRO"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => {
                return (
                  <FormField
                    error={get(formState, `errors[${name}].message`, "")}
                    title="Publisher PRO Number"
                    content={value}
                    onValueChange={(value) =>
                      setValue(name, value, { shouldValidate: true })
                    }
                  />
                );
              }}
            />
          </div>

          <LineDivider />

          <Controller
            control={control}
            name="isIndependent"
            render={({ field: { name, value } }) => {
              return (
                <CheckboxWithLabel
                  title="Are you currently signed?"
                  value={value}
                  info="Meaning does a label or distributor/publisher have to clear your music for commercial use in sync"
                  handler={({ payload }) =>
                    setValue(name, payload, { shouldValidate: true })
                  }
                  action="isIndependent"
                />
              );
            }}
          />

          <div>
            {isIndependent && (
              <div className={classes.account_info_container_margin}>
                <Controller
                  control={control}
                  name="labelName"
                  render={({
                    field: { name, onBlur, onChange, value },
                    formState,
                  }) => {
                    return (
                      <FormField
                        error={get(formState, `errors[${name}].message`, "")}
                        title="Label Name"
                        content={value}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    );
                  }}
                />

                <Controller
                  control={control}
                  name="canClear"
                  render={({ field: { name, value }, formState }) => {
                    return (
                      <FormField
                        error={get(formState, `errors[${name}].message`, "")}
                        title="Can you clear this track for commercial use? "
                        content={value}
                        dropdown={true}
                        dropDownData={["Yes I Can", "Not Without Permission"]}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    );
                  }}
                />
              </div>
            )}
          </div>

          <LineDivider />

          <Controller
            control={control}
            name="isMasterOwner"
            render={({ field: { name, value } }) => {
              return (
                <CheckboxWithLabel
                  title="I am the Master Owner"
                  value={value}
                  handler={({ payload }) =>
                    setValue(name, payload, { shouldValidate: true })
                  }
                  action="isMasterOwner"
                />
              );
            }}
          />

          <div>
            {!isMasterOwner && (
              <div className={classes.account_info_container_margin}>
                <Controller
                  control={control}
                  name={`masterOwnerName`}
                  render={({
                    field: { name, onBlur, onChange, value },
                    formState,
                  }) => (
                    <FormField
                      error={get(formState, `errors[${name}].message`, "")}
                      name={name}
                      title="Owner's Name"
                      content={value}
                      onValueChange={(value) =>
                        setValue(name, value, { shouldValidate: true })
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`masterOwnerEmail`}
                  render={({
                    field: { name, onBlur, onChange, value },
                    formState,
                  }) => (
                    <FormField
                      error={get(formState, `errors[${name}].message`, "")}
                      title="Owner's Email"
                      content={value}
                      onValueChange={(value) =>
                        setValue(name, value, { shouldValidate: true })
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`masterOwnerPhone`}
                  render={({
                    field: { name, onBlur, onChange, value },
                    formState,
                  }) => (
                    <FormField
                      error={get(formState, `errors[${name}].message`, "")}
                      title="Owner's Phone Number"
                      content={value}
                      onValueChange={(value) =>
                        setValue(name, value, { shouldValidate: true })
                      }
                    />
                  )}
                />
              </div>
            )}
          </div>
          <LineDivider />

          <div className={styles.btnWrapper}>
            <div>
              <p className={styles.section_title}>
                Are there any collaborators on this audio file?
              </p>
              <Controller
                control={control}
                name="collaboratorOnLoop"
                render={({
                  field: { name, onBlur, onChange, value },
                  formState,
                }) => {
                  return (
                    <>
                      <RadioGroup
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "-5px",
                        }}
                      >
                        <RadioButtonWithLabel value="yes" label="Yes" />
                        <RadioButtonWithLabel value="no" label="No" />
                      </RadioGroup>
                      {get(formState, `errors[${name}].message`, "") && (
                        <span style={{ color: "red" }}>
                          {get(formState, `errors[${name}].message`, "")}
                        </span>
                      )}
                    </>
                  );
                }}
              />
            </div>
            {/* collaborators counter new */}

            {collaboratorOnLoop === "yes" ? (
              <div className={styles.add_collab_button}>
                <div className={styles.green_opacity_more_box_bg}>
                  <p className={styles.add_collab_button_text}>
                    Add Collaborator
                  </p>
                  <div className={styles.add_collab_counter}>
                    <img
                      src="/MathIcons/MinusIcon.png"
                      alt="minus"
                      className={styles.counter_icon}
                      onClick={removeCollaborator}
                    ></img>
                    <div className={styles.add_collab_counter_box}>
                      <p className={styles.add_collab_counter_box_text}>
                        {collaborators.length}
                      </p>
                    </div>
                    <img
                      src="/MathIcons/PlusIcon.png"
                      alt="plus"
                      className={styles.counter_icon}
                      onClick={addCollaborators}
                    ></img>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {/* collaborators detail */}
          <span style={{ color: "red" }}>
            {get(errors, `collaborators.message`, "")}
          </span>
          <div>
            {collaboratorOnLoop === "yes" &&
              collaborators.map((collaborator, index) => (
                <div key={`coall-${index}`} className={styles.collaborator_container}>
                  <div
                    className={styles.deleteBtn}
                    onClick={() => removeCollaborator(index)}
                  >
                    <CloseIcon style={{ color: "#AFAFAF" }} />
                  </div>

                  <Controller
                    control={control}
                    name={`collaborators[${index}].name`}
                    render={({
                      field: { name, onBlur, onChange, value },
                      formState,
                    }) => (
                      <FormField
                        error={get(formState, `errors[${name}].message`, "")}
                        name={name}
                        title="Collaborator's Name"
                        content={collaborator.name}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`collaborators[${index}].email`}
                    render={({
                      field: { name, onBlur, onChange, value },
                      formState,
                    }) => (
                      <FormField
                        error={get(formState, `errors[${name}].message`, "")}
                        title="Collaborator's Email"
                        content={collaborator.email}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`collaborators[${index}].publishingName`}
                    render={({
                      field: { name, onBlur, onChange, value },
                      formState,
                    }) => (
                      <FormField
                        error={get(formState, `errors[${name}].message`, "")}
                        title="Collaborator's Publisher Name"
                        content={collaborator.publishingName}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`collaborators[${index}].publishingPRO`}
                    render={({
                      field: { name, onBlur, onChange, value },
                      formState,
                    }) => (
                      <FormField
                        error={get(formState, `errors[${name}].message`, "")}
                        title="Collaborator's Publisher PRO Number"
                        content={collaborator.publishingPRO}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`collaborators[${index}].publishingPercentage`}
                    render={({
                      field: { name, onBlur, onChange, value },
                      formState,
                    }) => (
                      <FormField
                        error={get(formState, `errors[${name}].message`, "")}
                        title="Collaborator's Publishing Percentage"
                        type="number"
                        content={collaborator.title}
                        onValueChange={(value) =>
                          setValue(name, parseInt(value), {
                            shouldValidate: true,
                            shouldTouch: true,
                          })
                        }
                      />
                    )}
                  />
                </div>
              ))}
          </div>
          <LineDivider />

          <div>
            <div>
              <Controller
                control={control}
                name="agreePolicy"
                render={({
                  field: { name, onBlur, onChange, value },
                  formState,
                }) => {
                  return (
                    <>
                      <CheckboxWithInfoIcon
                        title="By submitting for this United Market opportunity, you must opt into our honesty & transparency policy"
                        value={value}
                        handler={(data) => {
                          setValue(name, data.payload, {
                            shouldValidate: true,
                          });
                        }}
                        action="Add_OptIn"
                      />
                      {get(formState, `errors[${name}].message`, "") && (
                        <p className={classes.error_text_audio_file}>
                          {get(formState, `errors[${name}].message`, "")}
                        </p>
                      )}
                    </>
                  );
                }}
              />
            </div>
            <div className={classes.spacer}></div>
            <div>
              <Controller
                control={control}
                name="agreeSyncAgreement"
                render={({
                  field: { name, onBlur, onChange, value },
                  formState,
                }) => {
                  return (
                    <>
                      <CheckboxWithInfoIcon
                        title="By Submitting I opt into and agree to United Market’s Sync Pitching Agreement"
                        syncAgreement={true}
                        value={agreeSyncAgreement}
                        handler={(data) => {
                          setValue(name, data.payload, {
                            shouldValidate: true,
                          });
                        }}
                        action="Add_OptIn"
                      />
                      {get(formState, `errors[${name}].message`, "") && (
                        <p className={classes.error_text_audio_file}>
                          {get(formState, `errors[${name}].message`, "")}
                        </p>
                      )}
                    </>
                  );
                }}
              />
            </div>
          </div>
          <div className={classes.spacer}></div>
          <div className={styles.form_buttons}>
            <div
              className={classes.cancel_button}
              onClick={() => navigate("/sync")}
            >
              <p className={classes.button_text}>Cancel</p>
            </div>
            {isValid ? (
              <div className={classes.submit_button}>
                <button className={classes.button_text}>
                  {isLoading ? "Submitting.." : "Submit"}
                </button>
              </div>
            ) : (
              <div className={classes.submit_button_inactive}>
                <button className={classes.button_text}>
                  {isLoading ? "Submitting.." : "Submit"}
                </button>
              </div>
            )}
          </div>
        </form>
      </div>

      <ConfirmDeletePopup
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        deleteType={deleteType}
        deleteFtn={() => confirmDeleteFunction()}
        desc={"Are you sure you want to delete?"}
      />
    </div>
  );
};

export default SyncForm;

const LineDivider = () => {
  return <div className={styles.line_divider}></div>;
};

function Displayfile(props) {
  const { fileName, setDeletePopup, setDeleteName, type, file } = props;
  return type === "image" ? (
    <Box w={"100%"}>
      <Box alignSelf={"center"} my={8}>
        <img src={file} alt="file" className={styles.song_file_image}></img>
        <div
          className={styles.delete_button}
          onClick={() => {
            setDeletePopup(true);
            setDeleteName(fileName);
          }}
        >
          Delete
        </div>
      </Box>
    </Box>
  ) : (
    <div className={styles.file_display}>
      <div className={styles.file_display_top}>
        <div className={styles.file_info}>
          <img
            src="/v2Assets/submission_form_assets/music_file.png"
            alt="file"
            className={styles.song_file_image}
          ></img>
          <div>
            <p className={styles.file_title}>{fileName}</p>
          </div>
        </div>
      </div>
      <div className={styles.file_display_bottom}>
        <div
          className={styles.delete_button}
          onClick={() => {
            setDeletePopup(true);
            setDeleteName(fileName);
          }}
        >
          Delete
        </div>
        <AudioPlayer src={file} />
      </div>
    </div>
  );
}

const FormField = ({
  title,
  preFix = "",
  error = "",
  logo,
  name,
  info = "",
  content,
  dropdown = false,
  dropDownData,
  onValueChange,
  type = "text",
  isMulti = false,
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleOptionClick = (option) => {
    if (isMulti) {
      const _content = [...content];
      _content.push(option);

      onValueChange([...new Set(_content)]);
    } else {
      onValueChange(option);
    }
    toggleDropdown();
  };

  const handleInputChange = (event) => {
    onValueChange(event.target.value);
  };

  const removeOption = (option) => {
    const _content = [...content];
    const index = _content.indexOf(option);

    if (index >= 0) {
      _content.splice(index, 1);
      onValueChange(_content);
    }
  };

  return (
    <div className={styles.user_info_layout}>
      <div className={styles.field_info}>
        <p className={styles.user_info_title}>{title}</p>
        {info && (
          <Tooltip title={info} arrow>
            <img
              src="/v2Assets/submission_form_assets/moreInfoIcon.png"
              alt="info"
              className={styles.infoImage}
            />
          </Tooltip>
        )}
        {logo && (
          <img
            src="/SubmissionIcons/SpotifyIcon.png"
            alt="info"
            className={styles.spotify_icon_form}
          />
        )}
      </div>
      <div className={styles.input_box}>
        {preFix && <span className={styles.preFixText}>{preFix}</span>}
        {isMulti ? (
          <div className={styles.chipWrapper}>
            {content.map((o) => (
              <div className={styles.chips}>
                <span>{o}</span>{" "}
                <img src={allImages.closeBtn} onClick={() => removeOption(o)} />{" "}
              </div>
            ))}
          </div>
        ) : (
          <input
            name={name}
            type={type}
            className={styles.input_box_text}
            value={content}
            // disabled={dropdown}
            onClick={() => {
              dropdown && toggleDropdown();
            }}
            onChange={(e) => !dropdown && handleInputChange(e)}
          />
        )}
        {dropdown && (
          <div
            className={styles.input_box_dropdown_button}
            onClick={toggleDropdown}
          >
            <div className={styles.dropdown_green_bg}>
              <img
                src="/v2Assets/submission_form_assets/DropDownArrow.png"
                alt="options"
                className={styles.drop_down_image}
              />
              {isDropdownVisible && (
                <div className={styles.dropdown_menu}>
                  {dropDownData?.map((option, i) => {
                    if (
                      typeof content === "object" &&
                      content.includes(option)
                    ) {
                      return;
                    }
                    return (
                      <div
                        key={i}
                        className={styles.dropdown_menu_option_box}
                        onClick={() => handleOptionClick(option)}
                      >
                        <p className={styles.dropdown_menu_option}>{option}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {error && <p className={classes.error_text_audio_file}>{error}</p>}
    </div>
  );
};

function DisplayReferanceTrack(props) {
  const [isReferanceHovered, setIsReferanceHovered] = useState(false);
  const { fileName, setDeletePopup, setDeleteName, type, file } = props;
  return (
    <div
      className={
        isReferanceHovered
          ? `${classes.file_display} ${classes.hovered}`
          : classes.file_display
      }
    >
      <img
        src="/deleteOptionEx.png"
        alt="Check"
        className={classes.delete_icon_corner}
        onMouseEnter={() => setIsReferanceHovered(true)}
        onMouseLeave={() => setIsReferanceHovered(false)}
        onClick={() => {
          setDeletePopup(true);
          setDeleteName(fileName);
        }}
      />
      <p className={classes.file_title}>{fileName}</p>

      <AudioPlayer src={file} />
    </div>
  );
}
