import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme }) => ({
  width: "100%",
  table: {
    background: "#0D120F",
  },
  "& .css-11xur9t-MuiPaper-root-MuiTableContainer-root": {
    borderRadius: "0px",
  },
  thead: {
    tr: {
      th: {
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "18px",
        letterSpacing: "0em",
        textAlign: "left",
        color: "#AFAFAF",
        borderBottom: "1px solid #414241",
        [theme.breakpoints.down("md")]: {
          whiteSpace: "nowrap",
        },
      },
      "& th:first-of-type": {
        paddingLeft: "0px",
      },
      "& th:last-of-type": {
        paddingRight: "0px",
        textAlign: "right",
      },
    },
  },
  tbody: {
    tr: {
      td: {
        fontFamily: theme.typography.fontFamily,
        borderBottom: "1px solid #414241",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0em",

        color: "#FFFFFF",
      },
      "& td:first-of-type": {
        paddingLeft: "0px",
        textAlign: "left",
      },
      "& td:last-of-type": {
        paddingRight: "0px",
        textAlign: "right",
      },
    },
    "& td.no_data": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textAlign: "center !important",
    },
  },
  "& .roundedimage": {
    img: {
      borderRadius: "50% !important",
    },
  },
  "& .link_main": {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  "& .link_main img": {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    objectFit: "cover",
  },
  "& p": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#AFAFAF",
    margin: "0px 0px",
  },
  "& strong": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#E3E3E3",
  },
}));

export { Main };
