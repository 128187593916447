import React from 'react';
import styles from './../styles.module.css';
export const AddPhoto = () => {
  return (
    <svg
      className={styles.addPhoto}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M55.2784 2.63628H47.5284C46.5643 2.54923 45.5964 2.75404 44.7503 3.22412C43.9041 3.6942 43.2189 4.40783 42.7835 5.27234C42.3524 6.11061 42.1437 7.04546 42.1772 7.98747V15.7375C42.1106 16.4568 42.2033 17.182 42.4487 17.8615C42.6941 18.541 43.0861 19.1581 43.597 19.6689C44.1078 20.1797 44.7249 20.5718 45.4044 20.8171C46.0838 21.0625 46.809 21.1552 47.5284 21.0887H55.2784C56.2204 21.1222 57.1552 20.9134 57.9935 20.4824C58.858 20.047 59.5716 19.3617 60.0417 18.5156C60.5118 17.6694 60.7166 16.7015 60.6296 15.7375V7.98747C60.6961 7.26812 60.6034 6.54293 60.358 5.86346C60.1127 5.18398 59.7206 4.5669 59.2098 4.05606C58.6989 3.54523 58.0819 3.15319 57.4024 2.9078C56.7229 2.66242 55.9977 2.56972 55.2784 2.63628V2.63628ZM57.7563 12.996C57.464 13.2777 57.0807 13.446 56.6755 13.4705H52.9586V14.8149L52.985 17.1346C52.97 17.5628 52.7906 17.9687 52.4842 18.2681C52.196 18.5524 51.8082 18.7132 51.4034 18.7162C50.9839 18.7162 50.5816 18.5496 50.285 18.253C49.9884 17.9564 49.8217 17.5541 49.8217 17.1346V13.4441L46.1313 13.4705C45.7094 13.4635 45.3071 13.2911 45.0112 12.9903C44.7154 12.6895 44.5496 12.2844 44.5496 11.8625C44.5496 11.443 44.7163 11.0407 45.0129 10.7441C45.3095 10.4475 45.7118 10.2808 46.1313 10.2808L48.451 10.3072H49.8217V6.61673C49.8217 6.19725 49.9884 5.79496 50.285 5.49834C50.5816 5.20173 50.9839 5.03509 51.4034 5.03509C51.8228 5.03509 52.2251 5.20173 52.5218 5.49834C52.8184 5.79496 52.985 6.19725 52.985 6.61673L52.9586 8.48832V10.2808H56.6755C57.095 10.2808 57.4972 10.4475 57.7939 10.7441C58.0905 11.0407 58.2571 11.443 58.2571 11.8625C58.23 12.2878 58.0525 12.6896 57.7563 12.996V12.996Z'
        fill='#AFAFAF'
      />
      <path
        d='M29.9988 21.0902C29.9988 22.3311 29.6308 23.544 28.9415 24.5758C28.2521 25.6075 27.2723 26.4116 26.1259 26.8865C24.9795 27.3613 23.718 27.4856 22.501 27.2435C21.284 27.0014 20.1661 26.4039 19.2887 25.5265C18.4113 24.6491 17.8138 23.5312 17.5717 22.3142C17.3296 21.0972 17.4539 19.8357 17.9287 18.6893C18.4036 17.5429 19.2077 16.5631 20.2394 15.8737C21.2712 15.1844 22.4841 14.8164 23.725 14.8164C25.3889 14.8164 26.9847 15.4774 28.1612 16.654C29.3378 17.8305 29.9988 19.4263 29.9988 21.0902Z'
        fill='#AFAFAF'
      />
      <path
        d='M55.2779 21.0898H54.039V33.242L53.6963 32.952C52.6471 32.0951 51.3341 31.627 49.9795 31.627C48.6248 31.627 47.3118 32.0951 46.2626 32.952L35.2966 42.3627C34.2475 43.2196 32.9344 43.6877 31.5798 43.6877C30.2252 43.6877 28.9121 43.2196 27.863 42.3627L26.9667 41.6246C25.9994 40.8255 24.8009 40.3583 23.548 40.2919C22.2951 40.2254 21.0539 40.5633 20.0075 41.2556L10.1487 47.8721C9.51627 46.2148 9.20322 44.4528 9.22606 42.679V20.5889C9.22606 13.1552 13.1538 9.22752 20.5875 9.22752H42.1767V7.98857C42.1432 7.04656 42.352 6.11171 42.783 5.27344L20.5875 5.27344C10.9922 5.27344 5.27198 10.9937 5.27198 20.5889V42.679C5.21461 45.2521 5.71834 47.8068 6.74817 50.1654C9.01518 55.174 13.8655 57.9945 20.5875 57.9945H42.6776C52.2728 57.9945 57.9931 52.2743 57.9931 42.679V20.4835C57.1548 20.9145 56.2199 21.1233 55.2779 21.0898V21.0898Z'
        fill='#AFAFAF'
      />
    </svg>
  );
};
