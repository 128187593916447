import React from "react";

const DownloadSvg = ({ color = "#AFAFAF" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M14.0003 7.5003H10.6253V11.2003L12.3503 9.4753C12.4082 9.41715 12.477 9.371 12.5528 9.33952C12.6286 9.30803 12.7099 9.29182 12.7919 9.29182C12.874 9.29182 12.9553 9.30803 13.0311 9.33952C13.1069 9.371 13.1757 9.41715 13.2336 9.4753C13.3498 9.59291 13.415 9.75161 13.415 9.91697C13.415 10.0823 13.3498 10.241 13.2336 10.3586L10.4419 13.1503C10.3243 13.2665 10.1656 13.3317 10.0003 13.3317C9.83491 13.3317 9.67622 13.2665 9.55861 13.1503L6.76694 10.3586C6.65654 10.2402 6.59644 10.0834 6.59929 9.92153C6.60215 9.75961 6.66774 9.60512 6.78225 9.49061C6.89677 9.3761 7.05125 9.31051 7.21317 9.30765C7.37509 9.3048 7.53179 9.3649 7.65027 9.4753L9.37527 11.2003V7.5003H6.00027C5.41947 7.45409 4.83555 7.53448 4.28883 7.7359C3.74212 7.93733 3.24563 8.25501 2.83364 8.667C2.42165 9.07899 2.10397 9.57548 1.90254 10.1222C1.70112 10.6689 1.62073 11.2528 1.66694 11.8336V13.992C1.61989 14.5733 1.69963 15.158 1.90064 15.7055C2.10165 16.2531 2.41915 16.7505 2.83119 17.1633C3.24323 17.5761 3.73999 17.8946 4.28715 18.0966C4.8343 18.2987 5.41881 18.3796 6.00027 18.3336H13.9919C14.5727 18.3798 15.1567 18.2995 15.7034 18.098C16.2501 17.8966 16.7466 17.5789 17.1586 17.1669C17.5706 16.7549 17.8882 16.2585 18.0897 15.7117C18.2911 15.165 18.3715 14.5811 18.3253 14.0003V11.8336C18.3732 11.2532 18.2943 10.6692 18.094 10.1223C17.8937 9.57533 17.5769 9.0785 17.1654 8.66625C16.754 8.254 16.2577 7.93618 15.7112 7.73485C15.1646 7.53352 14.5808 7.45348 14.0003 7.5003Z" fill={color}/>
  <path d="M10.625 2.29199C10.6239 2.12657 10.5577 1.96823 10.4407 1.85126C10.3238 1.73428 10.1654 1.66808 10 1.66699C9.83458 1.66808 9.67624 1.73428 9.55926 1.85126C9.44229 1.96823 9.37609 2.12657 9.375 2.29199V7.50033H10.625V2.29199Z" fill={color}/>
</svg>
  );
};

export default DownloadSvg;