import React from "react";
import { InboxCard, MainInboxCard } from "./styles";
import { Typography } from "@mui/material";

const SmartLinkBoxType = ({ title = "", icon = "", navigate = "#" }) => {
  const HandleRole = () => {
    localStorage.setItem("RouteType", title);
  };

  return (
    <>
      <MainInboxCard>
        <InboxCard onClick={HandleRole} to={navigate}>
          <img src={icon} alt={title} />
          <Typography variant="h4">{title}</Typography>
        </InboxCard>
      </MainInboxCard>
    </>
  );
};

export default SmartLinkBoxType;
