import SidebarIcon from "./SidebarIcon";

export const main_menu = [
  {
    id: 1,
    title: "Admin",
    released: true,
    allowedUsers: [],
    icon: (
      <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarAdminIcon.png" />
      // <HomeIcon />
    ),
    iconKey: "stroke",
    url: "/adminpanel",
    isSelf: true,
  },
  // {
  //   id: 2,
  //   title: "Dashboard",
  //   released: true,
  //   allowedUsers: ["All"],
  //   icon: (
  //     <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarDashboardIcon.png" />
  //   ),
  //   iconKey: "dashboard",
  //   url: `/dashboard`,
  //   isSelf: true,
  // },
  {
    id: 2,
    title: "Home",
    released: true,
    allowedUsers: ["All"],
    icon: (
      <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarHomeIcon.png" />
    ),
    iconKey: "home",
    url: `/home`,
    isSelf: true,
  },

  {
    id: 3,
    title: "Opportunities",
    released: true,
    allowedUsers: ["All"],
    icon: (
      <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarOppurtunitiesIcon.png" />
    ),
    iconKey: "opportunities",
    url: `/opportunities`,
    isSelf: true,
    isExpanded: true,
    subMenu: [
      {
        id: 151,
        title: "Artist Collaborations",
        released: true,
        url: "/opportunities",
      },
      {
        id: 152,
        title: "Sync",
        released: true,
        url: "/sync",
      }
    ],
    
  },
  // Ai marketing tools
  {
    id: 6,
    title: "AI Tools",
    released: true,
    allowedUsers: ["All"],
    icon: (
      <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarAiIcon.png" />
    ),
    iconKey: "fill",
    url: "/ai_marketing",
    isSelf: false,
    isExpanded: true,
    subMenu: [
      {
        id: 61,
        title: "Marketing.AI",
        released: true,
        url: "/ai_marketing",
      },
      // {
      // Hide Songwritter.AI untill implemetned
      //   id: 62,
      //   title: "Songwriter.AI",
      //   released: true,
      //   url: "#",
      // },
    ],
  },
  {
    id: 7,
    title: "Connect",
    released: false,
    allowedUsers: ["All"],
    icon: (
      <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarConnectIcon.png" />
    ),
    iconKey: "connect",
    url: `/connect`,
    isSelf: true,
  },
  {
    id: 8,
    title: "Chat",
    released: true,
    allowedUsers: ["All"],
    icon: (
      <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarChatIcon.png" />
    ),
    iconKey: "fill",
    url: "/chat",
    beta: true,
    isSelf: false,
  },
  // {
  //   id: 9,
  //   title: "AI Songwriter",
  //   released: true,
  //   allowedUsers: ["Artist", "Music Producer"],
  //   icon: (
  //     <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarSongwriterIcon.png" />
  //   ),
  //   iconKey: "fill",
  //   url: "/songwriter",
  //   isSelf: false,
  //   isBeta: true,
  // },
  {
    id: 10,
    title: "Audio Library",
    released: true,
    allowedUsers: ["All"],
    iconKey: "fill",
    url: "/myaudiolibrary",
    isBeta: true,
    icon: <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/beats.svg" />,
  },
  // {
  //   id: 5,
  //   title: "My Submissions",
  //   released: true,
  //   allowedUsers: ["All"],
  //   icon: (
  //     <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarSubmissionsIcon.png" />
  //   ),
  //   iconKey: "fill",
  //   url: "/my-submissions",
  //   isSelf: false,
  //   isExpanded: true,
  //   subMenu: [
  //     {
  //       id: 51,
  //       title: "Artist Collaborations",
  //       released: true,
  //       url: "/my-submissions",
  //     },
  //     {
  //       id: 52,
  //       title: "Sync",
  //       released: true,
  //       url: "/my-submissions/sync",
  //     },
  //     {
  //       id: 53,
  //       title: "ConnectMe",
  //       released: false,
  //       url: "/my-submissions/connect",
  //     },
  //   ],
  // },

  {
    id: 11,
    title: "Distribution",
    released: true,
    allowedUsers: ["Artist", "A & R", "Label", "Manager"],
    icon: (
      <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarDistributionIcon.png" />
    ),
    iconKey: "fill",
    url: "/distribute",
    isSelf: false,
  },
  {
    id: 12,
    title: "Soundpacks",
    released: false,
    allowedUsers: [],
    icon: (
      <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarSoundPackIcon.png" />
    ),
    iconKey: "fill",
    url: "/soundPack",
    isSelf: true,
    isExpanded: true,
    subMenu: [
      {
        id: 111,
        title: "Sound Packs",
        url: `/soundPack`,
      },
      {
        id: 113,
        title: "My Sound Packs",
        url: "/sound-kit",
      },
      {
        id: 112,
        title: "Upload Sound Kits",
        url: `/soundPack/create`,
      },
    ],
    isComingImg: true,
  },
  {
    id: 13,
    title: "My Contracts",
    released: true,
    allowedUsers: ["All"],
    icon: (
      <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarSubmissionsIcon.png" />
    ),
    iconKey: "fill",
    url: "/my-contracts",
    isSelf: false,
    // isExpanded: true,
    // subMenu: [
    //   {
    //     id: 131,
    //     title: "My Contract",
    //     released: true,
    //     url: "/my-contracts",
    //   },
    //   {
    //     id: 132,
    //     title: "Templates",
    //     released: true,
    //     url: "/my-contracts/templates",
    //   },
    //   {
    //     id: 133,
    //     title: "ConnectMe",
    //     released: false,
    //     url: "/my-submissions/connect",
    //   },
    // ],
  },
  {
    id: 14,
    title: "Settings",
    released: true,
    allowedUsers: ["All"],
    icon: (
      <SidebarIcon sidebar_icon="/v2Assets/SidebarIcons/SidebarSettingsIcon.png" />
    ),
    iconKey: "fill",
    url: "/settings",
    isSelf: false,
  },
];
