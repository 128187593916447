import React from "react";

function Setting() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 6.5H16"
        stroke="#AFAFAF"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6.5H2"
        stroke="#AFAFAF"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 6.5C13.5 7.19223 13.2947 7.86892 12.9101 8.4445C12.5256 9.02007 11.9789 9.46867 11.3394 9.73358C10.6999 9.99848 9.99612 10.0678 9.31719 9.93275C8.63825 9.7977 8.01461 9.46436 7.52513 8.97487C7.03564 8.48539 6.7023 7.86175 6.56725 7.18282C6.4322 6.50388 6.50152 5.80015 6.76642 5.16061C7.03133 4.52107 7.47993 3.97444 8.05551 3.58986C8.63108 3.20527 9.30777 3 10 3C10.9283 3 11.8185 3.36875 12.4749 4.02513C13.1313 4.6815 13.5 5.57174 13.5 6.5V6.5Z"
        stroke="#AFAFAF"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 17.5H18"
        stroke="#AFAFAF"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 17.5H2"
        stroke="#AFAFAF"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 17.5C17.5 18.1922 17.2947 18.8689 16.9101 19.4445C16.5256 20.0201 15.9789 20.4687 15.3394 20.7336C14.6999 20.9985 13.9961 21.0678 13.3172 20.9327C12.6383 20.7977 12.0146 20.4644 11.5251 19.9749C11.0356 19.4854 10.7023 18.8617 10.5673 18.1828C10.4322 17.5039 10.5015 16.8001 10.7664 16.1606C11.0313 15.5211 11.4799 14.9744 12.0555 14.5899C12.6311 14.2053 13.3078 14 14 14C14.9283 14 15.8185 14.3687 16.4749 15.0251C17.1313 15.6815 17.5 16.5717 17.5 17.5V17.5Z"
        stroke="#AFAFAF"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Setting;
