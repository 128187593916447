import React, { memo, useEffect, useState } from "react";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Box, Button } from "@mui/material";
import { Main } from "./styles/PaymentFormStyle";
import {
  wifi_logo,
  visa_card,
  master_card,
  paypal_card,
  card_bg__shape,
} from "../../../../assets/smartlink";
import PaymentDetailModel from "./popups/PaymentDetailModel";
import {
  CREATE_PAYMENT_INTENT,
  PURCHASE_SUBSCRIPTION_MUTATION,
} from "../../../../Graphql_services/smartlink/SmartLink";
import { useMutation } from "@apollo/client";
import { Notification } from "../../../NotifyMsg/Notification";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      fontWeight: 500,
      fontSize: "12px",
      fontSmoothing: "antialiased",
      color: "#fff",
      ":-webkit-autofill": { color: "red" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};

const PaymentForm = ({
  HandelPopup = () => {},
  selectedplanid = {},
  setpaymentDetais = () => {},
  setpaymentDisable = () => {},
}) => {
  const [success, setSuccess] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cardholderName, setCardholderName] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryComplete, setExpiryComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [purchaseSubscription, { loading: payloading, error, data }] =
    useMutation(PURCHASE_SUBSCRIPTION_MUTATION);

  const [
    createPaymentIntent,
    { loading: IntentLoading, error: IntentError, data: IntentData },
  ] = useMutation(CREATE_PAYMENT_INTENT);

  const stripe = useStripe();
  const elements = useElements();

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleNameChange = (e) => {
    setCardholderName(e.target.value);
  };

  const handleExpiryChange = (e) => {
    const { value } = e;
    setExpiryDate(value);
  };

  const PaymentDetailModelOpen = () => {
    HandelPopup();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardNumberElement,

    //   // card: {
    //   //   number: cardNumberElement ? cardNumberElement._value : "",
    //   //   exp_month:
    //   //     cardExpiryElement && cardExpiryElement._value
    //   //       ? cardExpiryElement._value.month
    //   //       : "",
    //   //   exp_year:
    //   //     cardExpiryElement && cardExpiryElement._value
    //   //       ? cardExpiryElement._value.year
    //   //       : "",
    //   //   cvc: cardCvcElement ? cardCvcElement._value : "",
    //   // },
    // });
    // console.log(
    //   "🚀 ~ file: PaymentForm.js:84 ~ handleSubmit ~ error, paymentMethod:",
    //   error,
    //   paymentMethod
    // );

    // if (!error) {
    //   try {
    //     const { id } = paymentMethod;
    //     const response = await axios.post("http://localhost:4000/payment", {
    //       amount: 1000,
    //       id,
    //     });

    //     if (response.data.success) {
    //       console.log("Successful payment");
    //       setSuccess(true);
    //     }
    //   } catch (error) {
    //     console.log("Error", error);
    //   }
    // } else {
    //   console.log("error ", error.message);
    // }
    setLoading(true);
    setpaymentDisable(true);
    const { token, error } = await stripe.createToken(cardNumberElement);

    if (!error) {
      try {
        const IntentData = await createPaymentIntent({
          variables: {
            cardToken: token?.id,
            amount: (selectedplanid?.price * 100).toString(),
          },
          context: { clientName: "smartlink" },
        });
        // console.log(
        //   "🚀 ~ file: PaymentForm.js:144 ~ handleSubmit ~ IntentData:",
        //   IntentData?.data?.createPaymentIntent?.success
        // );

        if (IntentData?.data?.createPaymentIntent?.success) {
          // const { paymentIntent, error: confirmationError } =
          //   await stripe.confirmCardPayment(paymentIntentClientSecret, {
          //     payment_method: "card",
          //   });

          const confirmPayment = await stripe.confirmCardPayment(
            IntentData?.data?.createPaymentIntent.clientSecret,
            { payment_method: { card: cardNumberElement } }
          );

          if (confirmPayment.paymentIntent) {
            const result = await purchaseSubscription({
              variables: {
                planId: selectedplanid?.id,
                paymentIntentId: confirmPayment.paymentIntent.id,
              },
              context: { clientName: "smartlink" },
            });

            if (result?.data?.purchaseSubscription?.success) {
              Notification(
                "success",
                result?.data?.purchaseSubscription?.message
              );
              setpaymentDetais(result?.data?.purchaseSubscription);
              HandelPopup();
              setCardholderName("");
            } else {
              Notification(
                "error",
                result?.data?.purchaseSubscription?.message
              );
            }
          } else if (confirmPayment.error) {
            // Payment failed, handle the error
            Notification("error", `${confirmPayment.error.message}`);
          }
        }

        // const result = await purchaseSubscription({
        //   variables: {
        //     planId: selectedplanid?.id,
        //     amount: (selectedplanid?.price * 100).toString(),
        //     cardToken: token?.id,
        //   },
        //   context: { clientName: "smartlink" },
        // });

        // if (result?.data?.purchaseSubscription?.success) {
        //   Notification("success", result?.data?.purchaseSubscription?.message);
        //   setpaymentDetais(result?.data?.purchaseSubscription);
        //   HandelPopup();
        // } else {
        //   Notification("error", result?.data?.purchaseSubscription?.message);
        // }
      } catch (error) {
        Notification("error", `${error.message}`);
      } finally {
        setLoading(false);
        setpaymentDisable(false);
      }
    } else {
      setLoading(false);
      setpaymentDisable(false);
      Notification("error", error.message);
      console.error(error.message);
    }
  };

  return (
    <>
      <Main>
        <Box className="card-header-box">
          <h3 style={{ margin: 0 }}>Payment Method</h3>
        </Box>
        <Box className="card-display-main">
          <Box className="card-box">
            <img
              src={card_bg__shape}
              alt="card-bg-img"
              style={{ width: "140px", height: "140px" }}
            />
            <Box className="wifi-box">
              <Box sx={{ textAlign: "left" }}>
                <img src={wifi_logo} alt="wifi_logo" />
              </Box>
              <Box className="card-number">
                <span>**** **** **** ****</span>
              </Box>
              <h4>{cardholderName}</h4>
            </Box>
            <Box className="bottom-box">
              <h5 className="expiry">
                exp
                <span>**/**</span>
              </h5>
              <Box>
                {/* <img src={paypal_card} alt="card-type-img" /> */}
                {selectedCard === "first" && (
                  <img src={paypal_card} alt="" className="card-img" />
                )}
                {selectedCard === "middle" && (
                  <img src={visa_card} alt="" className="card-img" />
                )}
                {selectedCard === "third" && (
                  <img src={master_card} alt="" className="card-img" />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {!success ? (
          <form onSubmit={handleSubmit} className="form-main">
            <h6>Select a payment method below</h6>
            <Box className="card-button-box">
              {/* <Box
                className={`card-btn first ${
                  selectedCard === "first" ? "selected" : ""
                }`}
                // onClick={() => handleCardClick("first")}
              >
                <img src={paypal_card} alt="" />
              </Box> */}
              <Box
                className={`card-btn middle ${
                  selectedCard === "middle" ? "selected" : ""
                }`}
                // onClick={() => handleCardClick("middle")}
              >
                <img src={visa_card} alt="" />
              </Box>
              <Box
                className={`card-btn third ${
                  selectedCard === "third" ? "selected" : ""
                }`}
                // onClick={() => handleCardClick("third")}
              >
                <img src={master_card} alt="" />
              </Box>
            </Box>
            <Box className="inputs-main">
              <h3>Card Detail</h3>
              <label htmlFor="cardName">Name on Card</label>
              <input
                className="name-input"
                type="text"
                id="nameOnCard"
                required
                onChange={handleNameChange}
              />
              <label htmlFor="cardNumber">Card Number</label>
              <CardNumberElement
                options={CARD_OPTIONS}
                className="number-input"
              />
              <Box className="expiry-cvc-box">
                <Box className="expiry-box">
                  <label htmlFor="expiryDate">Expiry Date</label>
                  <CardExpiryElement
                    className="expiry-input"
                    options={CARD_OPTIONS}
                    onChange={(e) => handleExpiryChange(e)}
                  />
                </Box>
                <Box className="cvc-box">
                  <label htmlFor="cvc">CVC</label>
                  <CardCvcElement
                    options={CARD_OPTIONS}
                    className="cvc-input"
                  />
                </Box>
              </Box>
              <Button
                type="submit"
                variant="contained"
                className="pay-btn"
                // onClick={PaymentDetailModelOpen}
                disabled={loading}
                sx={{
                  "&:disabled": {
                    background: "gray",
                    color: "#fff",
                  },
                }}
              >
                {loading ? "Processing..." : "Continue"}
              </Button>
            </Box>
          </form>
        ) : (
          <div>
            <h2>
              You just bought a sweet spatula congrats this is the best decision
              of you're life
            </h2>
          </div>
        )}
      </Main>
    </>
  );
};

export default memo(PaymentForm);
