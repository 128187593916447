import React, { useEffect, memo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
} from "@mui/material";
import { Main, TableMain } from "./style";
import moment from "moment";
import { image2, sms, files_icon } from "../../../../assets/smartlink";
import {
  GetAllDeletedBeatBoxes,
  Recover_BEAT_Box_MUTATION,
} from "../../../../Graphql_services/beatinbox/Beats";
import { useMutation } from "@apollo/client";
import { Notification } from "../../../NotifyMsg/Notification";
import LoadingSpinner from "../../common/loader/LoadingSpinner";
import BugsPopup from "../../producers/producerssubmit/popups/BugsPopup";

const BeatDelete = ({ data = [], loading = false }) => {
  const [Bugopen, setBugopen] = useState(false);
  const [currentRemover, setCurrentRemover] = useState(null);
  const [selectedButton, setSelectedButton] = useState("inboxes");
  const [recoverBeatInbox, { error }] = useMutation(Recover_BEAT_Box_MUTATION, {
    refetchQueries: [GetAllDeletedBeatBoxes, "getDeletedBeatBoxes"],
    context: { clientName: "beats" },
  });
  const TableHeaddata = [
    { title: "Profile", val: "image" },
    { title: "Name", val: "title" },
    { title: "Description", val: "description" },
    { title: "Recover", val: "updatedAt" },
  ];

  const HandelRecover = async (ID) => {
    if (!ID) {
      Notification("error", "Id is required");
    }
    setCurrentRemover(ID);
    try {
      const response = await recoverBeatInbox({
        variables: { id: ID, status: false },
      });

      if (response?.data?.recoverBeatInbox?.success) {
        Notification("success", "Item Recover successfully.");
      }
    } catch (error) {
      Notification("error", error);
      console.error("An error occurred while deleting the item:", error);
    } finally {
      setCurrentRemover(null);
    }
  };

  useEffect(() => {
    if (error) {
      setBugopen(true);
    }
  }, [error]);

  return (
    <Main>
      <Box className="deleted_header">
        <h6>
          {/* {selectedButton === "files" ? "Deleted files" : "deleted inboxes"} */}
          Deleted inboxes
        </h6>
        {/* <Box className="btn_box">
          <Button
            variant="contained"
            className={`files_btn ${
              selectedButton === "files" ? "selected" : ""
            }`}
            onClick={() => setSelectedButton("files")}
          >
            <img src={files_icon} alt="files_icon" />
            files
          </Button>
          <Button
            variant="contained"
            className={`inboxes_btn ${
              selectedButton === "inboxes" ? "selected" : ""
            }`}
            onClick={() => setSelectedButton("inboxes")}
          >
            <img src={sms} alt="inboxes_icon" />
            inboxes
          </Button>
        </Box> */}
      </Box>
      <TableMain>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {TableHeaddata?.map((value) => {
                  return (
                    <>
                      <TableCell
                        key={value.title}
                        // sx={{
                        //   width:
                        //     value.title === "Description"
                        //       ? "10% !important"
                        //       : undefined,
                        // }}
                      >
                        {value.title}
                      </TableCell>
                    </>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      textAlign: "center !important",
                      color: "#AFAFAF !important",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    Loading...
                  </TableCell>
                </TableRow>
              )}

              {data && data?.length > 0
                ? data?.map((row, index) => {
                    const { _id } = row || {};
                    return (
                      <>
                        <TableRow key={index}>
                          <>
                            <TableCell
                              className={`${row} ${
                                row.description === "description"
                                  ? "description_td"
                                  : ""
                              }`}
                            >
                              {row.image ? (
                                <>
                                  <div className="delete_beat_image">
                                    <img src={row?.image} alt={row?.title} />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="delete_beat_image">
                                    <img src={image2} alt={row?.title} />
                                  </div>
                                </>
                              )}
                            </TableCell>
                            <TableCell sx={{ width: "18%" }}>
                              {row?.title && (
                                <>
                                  <span className="name_value">
                                    {row?.title || "N/A"}
                                  </span>
                                </>
                              )}
                            </TableCell>
                            <TableCell sx={{ width: "45%" }}>
                              {row?.description && (
                                <>
                                  <p style={{ wordBreak: "break-all" }}>
                                    {row?.description || "N/A"}
                                  </p>
                                </>
                              )}
                            </TableCell>
                            {/* <TableCell>
                              {row?.updatedAt && (
                                <>
                                  <span className="date_value">
                                    {moment(parseInt(row?.createdAt)).format(
                                      "MM-DD-YY"
                                    )}
                                  </span>
                                </>
                              )}
                            </TableCell> */}
                            <TableCell>
                              {currentRemover == _id ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <LoadingSpinner size={25} />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    gap: "6px",
                                  }}
                                >
                                  <button onClick={() => HandelRecover(_id)}>
                                    Recover
                                  </button>
                                  <div>
                                    <p
                                      style={{
                                        fontFamily: "Poppins",
                                        fontWeight: "400",
                                        color: "#AFAFAF",
                                        fontStyle: "normal",
                                        fontSize: "8px",
                                      }}
                                    >
                                      Remaining Days
                                    </p>
                                    <p
                                      style={{
                                        fontFamily: "Poppins",
                                        fontWeight: "400",
                                        color: "#FF0000",
                                        fontStyle: "normal",
                                        fontSize: "12px",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      {row?.remainingDays} Days
                                    </p>
                                  </div>
                                </div>
                              )}
                            </TableCell>
                          </>
                        </TableRow>
                      </>
                    );
                  })
                : !loading && (
                    <TableRow>
                      <TableCell
                        colSpan={TableHeaddata?.length}
                        align="center"
                        sx={{ textAlign: "center" }}
                        className="noData_found"
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableMain>
      <BugsPopup open={Bugopen} setOpen={setBugopen} />
    </Main>
  );
};

export default memo(BeatDelete);
