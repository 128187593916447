import React, { Fragment, useEffect, useState, useMemo } from "react";
import { useParams } from 'react-router-dom';
import { size } from "lodash";
import { Box } from "@material-ui/core";

import styles from "./Libraries.module.css";

import PackCard from "./PackCard.js";
import useAuth from "../../../context/AuthContext.js";
import { useDebounce } from "../../../hooks/useDebounce.jsx";
import useAppState from "../../../context/useAppState.js";

import { getFeedOpportunityApi } from "../../../context/apis/opportunity.js";

import CircularLoaderPage from "../../UniversalComponents/CircularLoader/CircularLoaderPage.js";
import { PaginationMain } from "../../smartlink/index.js";
import OutlineButton from "../AdditionalComponents/OutlineButton.js";

import SingleUploadModal from "./Modals/SingleUploadModal.js";
import SelectFileModal from "./Modals/SelectFileModal.js";

import EditPackModal from "./Modals/EditPackModal.js";

import NoItemComponents from "../AdditionalComponents/utils/NoItemComponent.js";

import { feedAudioFilesByQuery } from "../../../context/apis/audio-library.js";
import TagsModal from "./Modals/TagsModal.js";
import KeysModal from "./Modals/KeysModal.js";
import { TAGS } from "../../../shared/constants.js";
import BlueButtonComponents from "../AdditionalComponents/utils/BlueButtonComponent.js";
import PackDetailCard from "./PackDetailCard.js";

import { getAudioLibraryById, getBeatPackById } from "../../../context/apis/audio-library.js";
import useLibraryStore from "../../../store/library.js";


const PAGE_LIMIT = 10;
const PackDetail = ({ tags, fileKey, minBpm, maxBpm, scale, mode, packName, sortBy }) => {

  const { id } = useParams();
  console.log("Pack ID is", id);

  const { user, userPrivileges } = useAuth();
  const [currentPage, setcurrentPage] = useState(1);


  const [isRefresh, setIsRefresh] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [filter, setFilter] = useState("");
  const [TotalPages, setTotalPages] = useState(0);


  const [singlePackData, setSinglePackData] = useState();
  const [audioFilesData, setAudioFilesData] = useState([]);




  let canCreate;

  if (user.roleId.roleName === "Admin") {
    canCreate = true;
  } else if (user.roleId.roleName === "Music Producer") {
    canCreate = userPrivileges?.privileges[0]?.isCreate || false;
  } else {
    canCreate = true;
  }

  const testData = [
    {
      basicInfo: {
        userID: "",
        packName: "ABC-1",
        packNote: "This is description of ABC-1",
        imageURL: "https://united-market.s3.us-east-2.amazonaws.com/fileupload/1708649298280/Screenshot2024-02-22at7.48.14%C3%A2%C2%80%C2%AFPM.png",
        packFile: null,
        fieldIds: ["1", "2"],
        enableDownload: false,
        notifyAccessed: false,

      },
      beatInfo: {
        isBeat: false,
        exclusivePrice: null,
        nonExclusivePrice: null,
      }
    },
    {
      basicInfo: {
        userID: "",
        packName: "ABC-2",
        packNote: "This is description of ABC-2",
        imageURL: "https://united-market.s3.us-east-2.amazonaws.com/fileupload/1708648424019/Screenshot2024-02-22at7.33.40%C3%A2%C2%80%C2%AFPM.png",
        fieldIds: ["1", "2"],
        enableDownload: false,
        notifyAccessed: false,
        tags: [],
        scale: "",
        bmp: 30,
        key: "",
      },
      beatInfo: {
        isBeat: false,
        exclusivePrice: null,
        nonExclusivePrice: null,
      }
    }
  ]

  useEffect(() => {

    getBeatPackById(id)
      .then((response) => {
        if (response?.data) {
          const newRow = response.data;
          setSinglePackData(newRow);
        }
      })
      .finally(() => {
      });
  }, []);

  useEffect(() => {
    // Function to load all audio library data
    const loadAudioData = async () => {
      if (singlePackData?.basicInfo?.fileIds) {
        // Assuming `singlePackData` is available in your props or state
        const audioDataPromises = singlePackData.basicInfo.fileIds.map(fileIdObj =>
          getAudioLibraryById(fileIdObj.fileId)
        );
        
        // Wait for all the promises to resolve
        const results = await Promise.all(audioDataPromises);
        
        // Set the loaded data into state
        setAudioFilesData(results);
      }
    };

    // Call the function to load data
    loadAudioData();
  }, [singlePackData]);




  useEffect(() => {
    setcurrentPage(1);
  }, [filter, sortBy]);

  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };

  return (
    <>
      <div>
        <div className={`${styles.pack_detail_card_layout}`} style={{ display: "flex", gap: "10px" }}>
          <div className={`${styles.pack_detail_card_header} ${styles.w_30}`}>File Name</div>
          <div className={`${styles.pack_detail_card_header} ${styles.w_20} ${styles.text_left}`}>Beat Information</div>
          <div className={`${styles.pack_detail_card_header} ${styles.w_10} ${styles.text_center} `}>Duration</div>
          <div className={`${styles.pack_detail_card_header} ${styles.w_10} ${styles.text_center}`}>Viewed</div>
          <div className={`${styles.pack_detail_card_header} ${styles.w_10} ${styles.text_center}`}>Date</div>
          <div className={`${styles.pack_detail_card_header} ${styles.w_20} ${styles.text_right}`}>Action</div>
        </div>
        {audioFilesData.map((audioFileData, key) => (
          <React.Fragment key={`pack-detail-card-${key}`}>
            <PackDetailCard packData={singlePackData} audioFileData={audioFileData} />
          </React.Fragment>
        ))}
      </div>


    </>
  );
};

export default PackDetail;
