import React from "react";
import { Container, Typography, Link } from "@mui/material";

const PrivacyPolicyPage = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ my: 4, color: "#fff", background: "#141515", padding: "20px" }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", marginBottom: "20px" }}
      >
        Privacy Policy
      </Typography>
      <strong style={{ marginBottom: "15px" }}>
        Effective as of May 1, 2023
      </strong>
      <Typography paragraph>
        As the owner of the website, www.UnitedMarket.com, and mobile
        application, the UM App, (collectively described as the “Site”), United
        Market, Inc. (“United Market”, “we” or “us”) understands that your
        privacy is of critical importance. This Privacy Policy describes what
        information we collect from you via the Site and how we use and disclose
        such information.
      </Typography>

      <Typography paragraph>
        By accessing the Website and using the United Market App, you accept the
        terms and conditions of our Privacy Policy, and you consent to United
        Market’s collection, storage, use and disclosure of your Personal
        Information as described in this Privacy Policy.
      </Typography>

      <Typography paragraph>
        You acknowledge that it is in your best interest to read this Privacy
        Policy carefully and to ensure that you understand the terms and
        conditions contained herein. Your acceptance of our Privacy Policy is
        deemed to have occurred upon your first use of the Site. If you do not
        accept and agree with this Privacy Policy, please refrain from using
        and/or accessing the Site immediately.
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>
          Scope of Privacy Policy
        </strong>
        <br />
        This Privacy Policy applies only to your access to the Site. The Site
        may contain links to third party websites and resources. United Market,
        Inc. has no control over how your information is collected, stored, or
        used by these third-party websites and/or resources. United Market, Inc.
        therefore advises you to check the privacy policies of any such
        third-party websites and/or resources before providing any information
        to them.
      </Typography>

      <Typography paragraph>
        In the context of the law and this notice, the terms "process" and
        "processing" mean to collect, store, transfer, use or otherwise act on
        data.
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>Our Use of Cookies</strong>
        <br />
        A cookie is a file containing an identifier (a string of letters and
        numbers) that is sent by a web server to a web browser and is stored by
        the browser. The identifier is then sent back to the server each time
        the browser requests a page from the server. Cookies may be either
        “persistent” cookies or “session” cookies: a persistent cookie will be
        stored by a web browser and will remain valid until its set expiry date,
        unless deleted by the user before the expiry date; a session cookie, on
        the other hand, will expire at the end of the user session, when the web
        browser is closed. Cookies do not typically contain any information that
        personally identifies a user, but personal information that we store
        about you may be linked to the information stored in and obtained from
        cookies.
        <br />
        We use cookies for the following purposes:
        <ul>
          <li>
            (a) [authentication – we use cookies to identify you when you visit
            our website and as you navigate our website];
          </li>
          <li>
            (b) [advertising – we use cookies [to help us to display
            advertisements that will be relevant to you]; and
          </li>
          <li>
            (c) [analysis – we use cookies [to help us to analyze the use and
            performance of our website and services];
          </li>
        </ul>
        We use Google Analytics to analyze the use of our website. Google
        Analytics gathers information about website use by means of cookies. The
        information gathered relating to our website is used to create reports
        about the use of our website. Google’s privacy policy is available at: 
        <Link
          href="https://www.google.com/policies/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.google.com/policies/privacy/
        </Link>
        <br />
        Most browsers allow you to refuse to accept cookies and to delete
        cookies. The methods for doing so vary from browser to browser, and from
        version to version. You can however obtain up-to-date information about
        blocking and deleting cookies on each browser’s website.
        <br />
        Please note that blocking cookies may have a negative impact on the
        functions of many websites, including our Site. Some features of the
        Site may cease to be available to you.
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>
          Email Addresses and Phone Numbers
        </strong>
        <br />
        We may collect your email address or phone number, but only if you
        voluntarily provide it to us. This may occur, for example, if you sign
        up for an account. We will use your email address and/or phone number
        for the purposes for which you provided it to us, and also from time to
        time to send you emails or text messages regarding the Site or other
        products or services that we believe may be of interest to you. You may
        opt out of such communications at any time by clicking the “unsubscribe”
        button in an email or responding STOP via text message.
        <br />
        We will not share your email address or phone number with any third
        parties.
        <br />
        If you are a resident of a country in the European Economic Area (EEA),
        please refer to the section below entitled “Additional Rights of EEA
        Residents.”
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>Transactional Data</strong>
        <br />
        We may process information relating to transactions, including purchases
        of goods and services, that you enter with us. (“Transactional Data”).
        The Transactional Data may include your contact details, your card
        details and the transaction details. Transaction Data may be processed
        for the purpose of supplying the purchased goods and services and
        keeping proper records of those transactions. Payment processing on our
        Site is handled by our payment services providers. We will share
        transaction data with our payment services providers only to the extent
        necessary for the purposes of processing your payments, refunding such
        payments and dealing with complaints and queries relating to such
        payments and refunds.
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>
          Additional Rights of EEA (European Economic Area) Residents
        </strong>
        <br />
        If you are a resident of a country in the EEA, you have the rights,
        among others, to:
        <ul>
          <li>access your personal data,</li>
          <li>ensure the accuracy of your personal data,</li>
          <li>the right to have us delete your personal data,</li>
          <li>
            the right to restrict further processing of your personal data, and
          </li>
          <li>
            the right to complain to a supervisory authority in your country of
            residence if data is misused.
          </li>
        </ul>
        If you believe that our processing of your personal information
        infringes data protection laws, you have a legal right to lodge a
        complaint with a supervisory authority responsible for data protection.
        You may do so in the EU member state of your residence, your place of
        work or the place of the alleged infringement.
        <br />
        You may exercise any of your rights in relation to your personal data by
        written notice to us addressed to the following:
        <Link href="mailto:support@unitedmarket.com">
          support@unitedmarket.com
        </Link>
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>
          Sale of Business or Assets
        </strong>
        <br />
        In the event that the Site or substantially all of its assets are sold
        or disposed of, whether by merger, sale of assets or otherwise, or in
        the event of an insolvency, bankruptcy or receivership, the information
        we have collected about you may be one of the assets sold or merged in
        connection with that transaction.
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>
          Changes to the Privacy Policy
        </strong>
        <br />
        We may change this Privacy Policy from time to time. The most recent
        version of the Privacy Policy will always be posted on the Site, with
        the “Effective Date” posted at the top of the Policy. We may revise and
        update this Privacy Policy if our practices change, as technology
        changes, or as we add new services or change existing ones. If we make
        any material changes to our Privacy Policy or how we handle your
        personal information, or we are going to use any personal information in
        a manner that is materially different from that stated in our Privacy
        Policy at the time we collected such information, we will give you a
        reasonable opportunity to consent to the change. If you do not consent,
        your personal information will be used as agreed to under the terms of
        the privacy policy in effect at the time we obtained that information.
        By using our Site after the Effective Date, you are deemed to consent to
        our then-current privacy policy. We will use information previously
        obtained in accordance with the Privacy Policy in effect when the
        information was obtained from you.
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>Contacting Us</strong>
        <br />
        Should you have any questions or concerns regarding privacy while using
        our Site, please send us a detailed message to
        <Link href="mailto:support@unitedmarket.com">
          support@unitedmarket.com
        </Link>
        , and United Market will use our best endeavors to resolve your
        concerns.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicyPage;
