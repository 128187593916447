import React from "react";

function EditSvg(props) {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16797 1.66699H7.5013C3.33464 1.66699 1.66797 3.33366 1.66797 7.50033V12.5003C1.66797 16.667 3.33464 18.3337 7.5013 18.3337H12.5013C16.668 18.3337 18.3346 16.667 18.3346 12.5003V10.8337"
        stroke="#2EBB55"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3668 2.51639L6.80011 9.08306C6.51862 9.39183 6.32824 9.7726 6.25011 10.1831L5.89178 12.6914C5.84825 12.8864 5.85467 13.0893 5.91045 13.2811C5.96623 13.473 6.06956 13.6477 6.21085 13.789C6.35214 13.9303 6.52682 14.0336 6.7187 14.0894C6.91057 14.1452 7.11343 14.1516 7.30844 14.1081L9.81678 13.7497C10.226 13.6681 10.6059 13.4781 10.9168 13.1997L17.4834 6.63306C18.6168 5.49972 19.1501 4.18306 17.4834 2.51639C15.8168 0.849722 14.5001 1.38306 13.3668 2.51639Z"
        stroke="#2EBB55"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4238 3.45801C12.7002 4.43923 13.2238 5.33306 13.9446 6.05388C14.6654 6.7747 15.5593 7.29835 16.5405 7.57467"
        stroke="#2EBB55"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default EditSvg;
