import React, { memo } from "react";
import { NotifyIconMain } from "./style/NotificationStyle";
import {
  beat_downloaded,
  beat_favorite,
  beatheart_selected,
  new_notification,
  beat_opportunity,
  beat_like_opportunity,
  opportunity_posted,
  opportunity_expired,
  new_connection,
} from "../../../../assets/smartlink";

import { Box } from "@mui/material";
const NotifyIcon = ({ isRead = false, type = "" }) => {
  const NotificationType =
    type === "beat-download"
      ? beat_downloaded
      : type === "beat-selected"
      ? beatheart_selected
      : type === "beat-favorite"
      ? beat_favorite
      : new_notification;
  return (
    <>
      <NotifyIconMain>
        {!isRead && <span></span>}
        <Box className="circle_box">
          <img
            src={NotificationType}
            alt="notify_icon"
            style={{ objectFit: "cover" }}
          />
        </Box>
      </NotifyIconMain>
    </>
  );
};

export default memo(NotifyIcon);
