/* eslint-disable semi */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable require-jsdoc */
import React from "react";
import Button from "@material-ui/core/Button";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import IconButton from "@material-ui/core/IconButton";
// import DialogTitle from "@material-ui/core/DialogTitle";

import { Typography } from "@material-ui/core";
import customStyles from "./Popup.module.css";

const darkTheme = createTheme({
  palette: {
    type: "dark",
  },
});

const styles = (theme) => ({
  root: {
    margin: "0px",
    padding: theme.spacing(1),
    position: "relative",
    paddingTop: "15px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: "50%",
    transform: "translateY(-50%)",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        variant="h6"
        style={{
          color: "#AFAFAF",
          fontFamily: "Poppins",
          fontSize: 20,
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose("no")}
          style={{ borderRadius: 2, backgroundColor: "" }}
        >
          <ClearOutlinedIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function DeletePopup(props) {
  const { handler, open, title, content, id, label1, label2 } = props;

  return (
    <div>
      <ThemeProvider theme={darkTheme}>
        <Dialog
          open={open}
          onClose={() => handler("no")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: customStyles.MuiDialogPaper }}
        >
          <DialogTitle id="alert-dialog-title" onClose={handler}>
            {title}
          </DialogTitle>
          <DialogContent style={{ paddingTop: 20, paddingBottom: 40 }}>
            <DialogContentText
              id="alert-dialog-description"
              className={customStyles.MuiDialogContent}
            >
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "center", paddingBottom: "25px" }}
          >
            <div
              className={customStyles.cancel_button}
              onClick={(e) => handler("no", id)}
            >
              {label2 || "No"}
            </div>
            <div
              className={customStyles.delete_button}
              onClick={(e) => handler("yes", id)}
            >
              {label1 || "Yes"}
            </div>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}

export const DownloadingPopup = (props) => {
  const { open, title, content, handler } = props;
  return (
    <div>
      <ThemeProvider theme={darkTheme}>
        <Dialog
          open={open}
          onClose={() => handler("no")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: customStyles.MuiDialogPaper }}
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent style={{ paddingTop: 20, paddingBottom: 40 }}>
            <DialogContentText
              id="alert-dialog-description"
              className={customStyles.MuiDialogContent}
            >
              {content}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};
