import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Modal,
} from "@material-ui/core";

import { getTimeFromUTC } from "../../../shared/constants";
import DeleteIcon from "../../../assets/trash.png";
import InfoCircle from "../../../assets/info-circle.png";
import CloseIcon from "../../../assets/close.png";
import GreenDeleteIcon from "../../../assets/GreenDelete.png";
import TrashSuccessIcon from "../../../assets/trash-success.png";
import RevokeIcon from "../../../assets/revokeIcon.png";
import HeroIcon from "../../../assets/heroicon.png";
import axios from "axios";

import { Notification } from "../../NotifyMsg/Notification";

function createData(creator, subject, status, createdate, action) {
  return { creator, subject, status, createdate, action };
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "creator", numeric: false, disablePadding: true, label: "Created By" },
  { id: "subject", numeric: true, disablePadding: false, label: "Subject" },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "createdate",
    numeric: true,
    disablePadding: false,
    label: "Date Created",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          className={classes.tableheadCell}
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableheadCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              classes={{
                active: classes.tablesortlabelactive,
                icon: classes.tablesortLabelIcon,
              }}
              style={{
                color: "white",
                fontSize: 18,
                fontWeight: 500,
                lineHeight: 1.5,
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 50,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
    backgroundColor: "#141515",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
    backgroundColor: "white",
  },
  tableCell: {
    color: "#E3E3E3",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    borderBottom: "1px solid #424142",
  },
  tableheadCell: {
    color: "#E3E3E3",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    borderBottom: "1px solid #424142",
    position: "sticky",
    top: 0,
  },
  tableContainer: {
    border: "none",
  },
  tablesortLabelIcon: {
    color: "white !important",
  },
  tablesortlabelactive: {
    color: "white",
  },
  useButton: {
    backgroundColor: "#2EBB55",
    fontSize: 16,
    lineHeight: 1.5,
    border: "none",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  modalpaper: {
    position: "absolute",
    width: 544,
    backgroundColor: "#141515",
    boxShadow: theme.shadows[5],
    padding: "40px 80px  80px 80px",
    borderRadius: 10,
    textAlign: "right",
  },
  modaltext: {
    color: "white",
    fontSize: 18,
    fontWeight: 400,
    margin: 0,
    textAlign: "center",
  },
  modaltest1: {
    color: "white",
    fontSize: 24,
    fontWeight: 400,
    margin: 0,
    textAlign: "center",
    fontFamily: "Poppins",
  },
  modaltext2: {
    fontSize: 18,
    fontWeight: 400,
    color: "#AFAFAF",
    textAlign: "center",
    margin: 0,
    fontFamily: "Poppins",
    paddingTop: 10,
  },
  modalimgcontainer: {
    gap: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "0 50px",
  },
  modalcloseimg: {
    cursor: "pointer",
  },
  modalbuttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 40,
  },
  modalCancelbutton: {
    background: "none",
    border: "1px solid #FFFFFF",
    borderRadius: 5,
    fontSize: 18,
    fontWeight: 500,
    color: "white",
    padding: "10.5px 30px 10.5px 30px",
    cursor: "pointer",
  },
  modalDeletebutton: {
    background: "#2EBB55",
    color: "white",
    border: "none",
    borderRadius: 5,
    fontSize: 18,
    fontWeight: 500,
    padding: "10.5px 30px 10.5px 30px",
    cursor: "pointer",
  },
}));

export default function DashboardTable({ data }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [isDeleteConfirm, setIsDeletConfirm] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [contractId, setContractId] = useState("");

  const router = useNavigate();

  useEffect(() => {
    const rowsData = data.map((item) => {
      let recepients = item.roleFirstNames.join(",");
      return createData(
        `${item.firstname} ${item.lastname}`,
        { subject: item.title, to: recepients },
        item.status,
        getTimeFromUTC(item.createDate),
        item.contractId
      );
    });
    setRows(rowsData);
  }, [data, setRows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.creator);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const deleteContract = async () => {
    setIsDelete(false);
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_CONTRACT_URL}/templates/send/${contractId}`,
      headers: {
        accesstoken: localStorage.getItem("token"),
      },
    };
    const response = await axios(config);
    if (response.data.status === 200) {
      setIsDeletConfirm(true);
      router("/my-contracts/trash");
    } else {
      setIsDeletConfirm(true); 
      router("/my-contracts/trash");
    }
  };

  const createSign = async (contractId) => {
    try {
      const config = {
        url: `${process.env.REACT_APP_CONTRACT_URL}/templates/sign/${contractId}`,
        method: "post",
        headers: {
          accesstoken: localStorage.getItem("token"),
        },
      };
      const response = await axios(config);
      window.location.assign(response.data);
    } catch (err) {
      Notification("error", err.message);
    }
  };
  const downloadDocument = async (contractId, filename) => {
    try {
      const config = {
        url: `${process.env.REACT_APP_CONTRACT_URL}/templates/download/${contractId}`,
        method: "post",
        responseType: "blob", // Ensure correct response type for binary data
        headers: {
          accesstoken: localStorage.getItem("token"),
        },
      };

      const response = await axios(config);

      // Handle potential errors in response type (consider custom error handling)
      if (!response.data) {
        throw new Error("Invalid response: Expected PDF data");
      }

      const blob = response.data;
      const fileName = `${filename}.pdf`;

      // Download using a blob URL (consider security implications and alternatives)
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl; // Set download URL using blob URL
      link.download = fileName;
      link.click();

      // Revoke the blob URL after download to prevent memory leaks (security best practice)
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      const errorMessage = JSON.parse(await error.response.data.text());
      console.error("Error downloading document:", errorMessage);
      Notification("error", errorMessage["message"]); // Handle errors appropriately (e.g., display user-friendly message)
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Paper className={classes.paper}>
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.creator);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row.creator)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.index}
                    //   selected={isItemSelected}
                  >
                    <TableCell padding="checkbox" className={classes.tableCell}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 20,
                          paddingRight: 20,
                        }}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          style={{
                            color: "#AFAFAF",
                            borderRadius: 2,
                            padding: 0,
                          }}
                          onChange={(event) => handleClick(event, row.creator)}
                        />
                        <img src={InfoCircle} />
                      </div>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      className={classes.tableCell}
                    >
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: 16,
                          lineHeight: 1.5,
                          color: "#E3E3E3",
                          margin: 0,
                        }}
                      >
                        {row.creator}
                      </p>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: 16,
                          lineHeight: 1.5,
                          color: "#FFFFFF",
                          margin: 0,
                        }}
                      >
                        {row.subject.subject}
                      </p>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 16,
                          lineHeight: 1.5,
                          color: "#AFAFAF",
                          margin: 0,
                        }}
                      >
                        {row.subject.to}
                      </p>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: 18,
                          lineHeight: 1.5,
                          color: "#AFAFAF",
                          margin: 0,
                        }}
                      >
                        {row.status === 1 ? "Completed" : "Pending"}
                      </p>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: 16,
                          lineHeight: 1.5,
                          color: "#FFFFFF",
                          margin: 0,
                        }}
                      >
                        {row.createdate.date}
                      </p>
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: 16,
                          lineHeight: 1.5,
                          color: "#FFFFFF",
                          margin: 0,
                        }}
                      >
                        {row.createdate.time}
                      </p>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: 60,
                          }}
                        >
                          <img
                            src={row.status === 1 ? DeleteIcon : RevokeIcon}
                            style={{ height: 24, width: 24, cursor: "pointer" }}
                            onClick={() => {
                              setIsDelete(true);
                              setContractId(row.action);
                            }}
                          />
                          {/* <img
                            src={HeroIcon}
                            style={{ height: 24, width: 24 }}
                          /> */}
                        </div>
                        <div>
                          {row.status === 1 ? (
                            <button
                              className={classes.useButton}
                              onClick={() =>
                                downloadDocument(
                                  row.action,
                                  row.subject.subject
                                )
                              }
                            >
                              Download
                            </button>
                          ) : (
                            <button
                              className={classes.useButton}
                              onClick={() => createSign(row.action)}
                            >
                              Sign
                            </button>
                          )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={isDeleteConfirm}
        onClose={() => setIsDeletConfirm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%" }}
          className={classes.modalpaper}
        >
          <img
            className={classes.modalcloseimg}
            src={CloseIcon}
            onClick={() => setIsDeletConfirm(false)}
          />
          <div className={classes.modalimgcontainer}>
            <img src={TrashSuccessIcon} />
            <p className={classes.modaltext}>
              Your document has been successfully deleted!
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={isDelete}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={() => setIsDelete(false)}
      >
        <div
          style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%" }}
          className={classes.modalpaper}
        >
          <div className={classes.modalimgcontainer}>
            <img src={GreenDeleteIcon} />
            <div>
              <div>
                <p className={classes.modaltest1}>Delete Document</p>
                <p className={classes.modaltext2}>
                  Do you really want to delete this document?
                </p>
              </div>
              <div className={classes.modalbuttonContainer}>
                <button
                  className={classes.modalCancelbutton}
                  onClick={() => setIsDelete(false)}
                >
                  Cancel
                </button>
                <button
                  className={classes.modalDeletebutton}
                  onClick={deleteContract}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Paper>
  );
}
