import moment from "moment";
import { object, string, number, boolean, date } from "yup";

const today = moment().format("YYYY-MM-DD");
const SyncSchema = object().shape({
  title: string()
    .required("File Name is required")
    .min(3, "File Name must be greater than 3 characters")
    .max(70, "File Name must be less than or equal to 70 characters"),
  mediaType: string().required("Media Type is required"),
  companyName: string().required("Company Name is required"),
  projectName: string(),
  lookingFor: string().required("Looking For is required"),
  expireDate: date()
    .min(today, "Expiration date can’t be before today’s date")
    .required()
    .typeError("Invalid Date selection!"),
  genre: string().required("Genre is required"),
  bpm: string(),

  key: string(),
  scale: string(),

  coverPhoto: object()
    .shape({
      name: string().required(),
      url: string().required(),
    })
    .required("Cover Photo is required"),

  // hasReferenceTrack: boolean(),
  // referenceTrack: object()
  //   .shape()
  //   .nullable(true)
  //   .when("hasReferenceTrack", {
  //     is: true,
  //     then: () =>
  //       object()
  //         .shape({
  //           name: string().required(),
  //           url: string().required(),
  //         })
  //         .required("Upload reference track"),
  //   }),
});

export default SyncSchema;
