import React from "react";
import { Grid } from "@mui/material";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import SettingsComponent from "../../components/SettingsComponents/settings_component";

const SettingsPage = () => {
  return (
    <Grid>
      <Sidebar childComponent={<SettingsComponent />} activeText="Settings" />
    </Grid>
  );
};

export default SettingsPage;
