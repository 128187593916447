import React, { useContext, useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Box, Button, HStack, Text, VStack, Input, Heading } from "native-base";
import { Notification } from "../../NotifyMsg/Notification";
import { AuthContext } from "../Layout";
import FormTitle from "../FormTitle";

import { useNavigate, useParams } from "react-router-dom";
import { SignUpMutation } from "../../../Graphql_services/SignUpMutation";
import { verify_Freemium_PromoCode } from "../../../Graphql_services/PromoCodeMutation";
import { verify_ReferralCode } from "../../../Graphql_services/ReferralCodeMutation";
import CustomStripeForm from "../SignUp/CustomStripeForm";
import useAuth from "../../../context/AuthContext";
import Nbox from "./Nbox";
import ButtonComponent from "../../UniversalComponents/ButtonComponent";
import { useBreakpointValue } from "native-base";
function Subscription(props) {
  const { title, price } = props;
  const { setUser, setIsAuthenticated } = useAuth();
  const { isSignup } = useParams();

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });
  const {
    purchased,
    setActiveStep,
    Subscriptionprice,
    setSubscriptionprice,
    Email,
    Password,
    picture,
    instaMail,
    genre,
    phonenum,
    priceID,
    NewRoleId,
    othersSocial,
    rolesID,
    accountType,
    setIsDisableBtn,
    activeStep,
    selectedAccount,
    username,
    firstName,
    lastName,
    showStripeForm,
    setShowStripeForm,
    noOfMonthlyListeners,
    spotifyUrl,
    labelName,
    otherHear,
    isSignUp,
    setIsSignUp,
    setProgressbarValue,
  } = useContext(AuthContext);
  //const { setUser } = useAuth();
  const { id } = useParams();
  const [promo, setPromo] = useState("");
  const [isDisbaled, setIsDisabled] = useState(false);
  const [showReferal, setShowReferal] = useState(false);
  const [referral, setReferral] = useState("");
  const [signUp] = useMutation(SignUpMutation, {
    onCompleted(data) {
      if (data?.signUp?.success) {
        localStorage.setItem("email", Email);
        localStorage.setItem("accountType", accountType);
        localStorage.setItem("userId", data?.signUp?.user?._id);
        localStorage.setItem("userName", data.signUp.user?.userName);
      } else {
        Notification("error", data.signUp.message);
        setActiveStep(activeStep);
        setIsDisableBtn(false);
      }
    },
    onError(err) {
      const { graphQLErrors } = err;
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          Notification("error", message);
          setActiveStep(activeStep);
        });
      }
    },
  });
  const [verifyFreemiumPromoCode] = useMutation(verify_Freemium_PromoCode, {
    fetchPolicy: "no-cache",
    context: { clientName: "auth" },
    onCompleted(data) {
      const { attachFreemiumPlan } = data;
      if (attachFreemiumPlan?.success) {
        Notification("success", data?.attachFreemiumPlan?.message);
        document.cookie = `sessionId=${data?.attachFreemiumPlan?.sessionId};path=/;domain=unitedmarket.com`;
        document.cookie = `reactionAccessToken=${data?.attachFreemiumPlan?.reactionAccessToken};path=/;domain=unitedmarket.com`;
        setUser(data?.attachFreemiumPlan?.user);
        localStorage.setItem(
          "profilePic",
          data?.attachFreemiumPlan?.user?.profilePic
        );
        localStorage.setItem(
          "userName",
          data?.attachFreemiumPlan?.user?.userName
        );
        localStorage.setItem(
          "accountType",
          data?.attachFreemiumPlan?.user?.roleId?.roleName
        );
        localStorage.setItem("token", data?.attachFreemiumPlan?.accessToken);
        localStorage.setItem("userId", data?.attachFreemiumPlan?.user?._id);
        localStorage.setItem(
          "opportunityToken",
          data?.attachFreemiumPlan?.reactionAccessToken
        );
        setIsAuthenticated(true);

        setShowReferal(true);
        //setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        //setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if (attachFreemiumPlan?.success === false) {
        Notification("error", data?.attachFreemiumPlan?.message);
      }
    },
    onError(error) {
      console.log("error.....", error);
      Notification("error", error);
      setIsDisabled(false);
    },
  });
  const [verifyReferralCode] = useMutation(verify_ReferralCode, {
    fetchPolicy: "no-cache",
    context: { clientName: "auth" },
    onCompleted(data) {
      const { verifyReferralCode } = data;
      if (verifyReferralCode?.success) {
        Notification("success", data?.verifyReferralCode?.message);
        setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if (verifyReferralCode?.success === false) {
        Notification("error", data?.verifyReferralCode?.message);
      }
    },
    onError(error) {
      console.log("error.....", error);
      Notification("error", error);
      setIsDisabled(false);
    },
  });
  const verifyPromo = async () => {
    await verifyFreemiumPromoCode({
      variables: {
        promoCode: promo,
        email: localStorage.getItem("email"),
      },
    });
  };
  const gotoCheckout = () => {
    if (isSignup === "1") {
      if (localStorage.getItem("email")) {
        setShowStripeForm(true);
      } else {
        Notification("error", "Something went worng");
      }
    } else {
      // if (!isSignUp) {
      // 	setIsDisableBtn(true);
      // 	signUp({
      // 		variables: {
      // 			firstname: firstName,
      // 			lastname: lastName,
      // 			email: Email,
      // 			password: Password,
      // 			countrycode: null,
      // 			phoneno: Number(phonenum),
      // 			labelName: labelName,
      // 			roleId: rolesID,
      // 			instaUrl: instaMail,
      // 			profilePic: picture,
      // 			genre: genre,
      // 			username: username,
      // 			noOfMonthlyListeners: noOfMonthlyListeners,
      // 			spotifyUrl: spotifyUrl,
      // 			isPreSignUp: false,
      // 			howDidYouKnow: NewRoleId,
      // 			howDidYouKnowText: otherHear,
      // 		},
      // 	});
      // }
      //else {
      setShowStripeForm(true);
      setIsSignUp(true);
      //setIsDisableBtn(false);
      //}
    }
  };
  const verifyReferalCode = async () => {
    if (referral.length > 0) {
      await verifyReferralCode({
        variables: {
          referralCode: referral,
          email: localStorage.getItem("email"),
        },
      });
    } else {
      setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  useEffect(() => {
    if (id) {
      setReferral(id);
    }

    if (!isSignup) {
      signUp({
        variables: {
          firstname: firstName,
          lastname: lastName,
          email: Email,
          password: Password,
          countrycode: null,
          phoneno: Number(phonenum),
          labelName: labelName,
          roleId: rolesID,
          instaUrl: instaMail,
          profilePic: picture,
          genre: genre,
          username: username,
          noOfMonthlyListeners: noOfMonthlyListeners,
          spotifyUrl: spotifyUrl,
          isPreSignUp: false,
          howDidYouKnow: NewRoleId,
          howDidYouKnowText: otherHear,
        },
      });
    }
  }, []);
  return (
    <Box>
      {!showStripeForm ? (
        purchased ? (
          <Box>
            <FormTitle
              activeStep={activeStep}
              color={"#000201"}
              title={"Choose Your Membership"}
              subtitle={""}
              isMobile={isMobile}
            />
            <HStack
              alignItems={"center"}
              space={3}
              my={3}
              justifyContent={"center"}
            >
              <Text color={"#2EBB55"} fontWeight={"bold"} fontSize={"4rem"}>
                ${Subscriptionprice}
              </Text>
              <VStack>
                <Text
                  textTransform={"capitalize"}
                  fontFamily={"poppins"}
                  color={"#fff"}
                  fontSize={"0.9rem"}
                >
                  per
                </Text>
                <Text
                  textTransform={"capitalize"}
                  fontFamily={"poppins"}
                  color={"#fff"}
                  fontSize={"0.9rem"}
                >
                  {Subscriptionprice === "45" ? "month" : "year"}
                </Text>
              </VStack>
            </HStack>
            <Text
              textAlign={"center"}
              fontFamily={"poppins"}
              fontSize={"1.2rem"}
              color={"#AFAFAF"}
              px={8}
              my={3}
            >
              {Subscriptionprice === "45"
                ? "Included in this monthly membership pricing is platform access."
                : "Included in this yearly membership pricing is platform access."}
            </Text>
            <Button
              onPress={gotoCheckout}
              _hover={{
                backgroundColor: "transparent",
                borderColor: "#2EBB55",
                borderWidth: 1,
                _text: { color: "#2EBB55" },
              }}
              mt={3}
              _text={{
                fontFamily: "poppins",
                fontSize: "1.11rem",
                textTransform: "capitalize",
              }}
              backgroundColor={"#2EBB55"}
              py={2}
            >
              Add Card Info
            </Button>
          </Box>
        ) : showReferal ? (
          <Box>
            <FormTitle
              activeStep={activeStep}
              color={"#000201"}
              title={"Enter the Referral Code"}
              subtitle={
                "Enter the referral code that was shared by your Friend"
              }
              isMobile={isMobile}
            />
            <VStack width={"100%"}>
              <Heading
                color="#fff"
                fontSize={{ xl: "24px", lg: "20px", md: "18px", base: "16px" }}
                fontFamily="poppins"
                mb={5}
              >
                Referral Code:
              </Heading>
              <Input
                placeholder="Enter referral code here"
                color="#E3E3E3"
                borderWidth={0}
                bg={"#1c1b1b"}
                pt={3}
                pb={3}
                fontSize={{ xl: "1.2rem", md: "1rem", base: "0.9rem" }}
                onChange={(e) => setReferral(e.target.value)}
                value={referral}
                focusOutlineColor={"#2EBB55"}
              ></Input>
              <Button
                _hover={{
                  backgroundColor: "transparent",
                  borderColor: "#2EBB55",
                  borderWidth: 1,
                  _text: { color: "#2EBB55" },
                }}
                _text={{
                  color: "#E3E3E3",
                  fontWeight: "semibold",
                  fontSize: { xl: "1.2rem", md: "1rem", base: "0.9rem" },
                  fontFamily: "poppins",
                  textTransform: "uppercase",
                }}
                _pressed={{ bg: "#2ebb55", _text: { color: "#e3e3e3" } }}
                px={{ md: 7, base: 3 }}
                py={{ md: 2, base: 1 }}
                mx={3}
                my={3}
                h={{ lg: "45px", sm: "35px", base: "48px" }}
                borderColor={5}
                backgroundColor={"#2EBB55"}
                borderWidth={1}
                // type='submit'
                onPress={() => verifyReferalCode()}
              >
                Next
              </Button>
            </VStack>
          </Box>
        ) : (
          <>
            <FormTitle
              activeStep={activeStep}
              color={"#000201"}
              title={"Choose Your Membership"}
              subtitle={""}
              isMobile={isMobile}
            />

            <HStack
              justifyContent={"space-between"}
              width={"100%"}
              flexDirection={{ lg: "row", base: "column" }}
              flexWrap="wrap"
              flexGrow={1}
              space={5}
            >
              <Nbox
                // desc={
                // 	'You are receiving a discounted membership price for being an early adopter.'
                // }
                duration={"month"}
                title={"Monthly Membership"}
                priceid={`${process.env.REACT_APP_MONTHLY_SUBSCRIPTION}`}
                price={"45"}
                isPreSignUp={true}
              />
              <Nbox
                // desc={
                // 	'Included in this yearly membership pricing is platform access'
                // }
                duration={"year"}
                priceid={`${process.env.REACT_APP_YEARLY_SUBSCRIPTION}`}
                title={"yearly membership"}
                price={"300"}
                isPreSignUp={true}
              />
            </HStack>
            <VStack width={"100%"}>
              <Heading
                color="#fff"
                fontSize={{ xl: "24px", lg: "20px", md: "18px", base: "16px" }}
                fontFamily="poppins"
                mb={5}
              >
                Promo Code:
              </Heading>
              <Input
                placeholder="Enter promo code here"
                color="#E3E3E3"
                borderWidth={0}
                bg={"#1c1b1b"}
                fontSize={{ xl: "1.2rem", md: "1rem", base: "0.9rem" }}
                onChange={(e) => setPromo(e.target.value)}
                value={promo}
                focusOutlineColor={"#2EBB55"}
                InputRightElement={
                  <Button
                    _hover={{
                      backgroundColor: "transparent",
                      borderColor: "#2EBB55",
                      borderWidth: 1,
                      _text: { color: "#2EBB55" },
                    }}
                    _text={{
                      color: "#E3E3E3",
                      fontWeight: "semibold",
                      fontSize: { xl: "1.2rem", md: "1rem", base: "0.9rem" },
                      fontFamily: "poppins",
                      textTransform: "uppercase",
                    }}
                    _pressed={{ bg: "#2ebb55", _text: { color: "#e3e3e3" } }}
                    px={{ md: 7, base: 3 }}
                    py={{ md: 2, base: 1 }}
                    mx={3}
                    my={3}
                    h={{ lg: "45px", sm: "35px", base: "48px" }}
                    disabled={isDisbaled}
                    borderColor={5}
                    backgroundColor={"#2EBB55"}
                    borderWidth={1}
                    // type='submit'
                    onPress={() => verifyPromo()}
                  >
                    Apply
                  </Button>
                }
              ></Input>
            </VStack>
          </>
        )
      ) : (
        <>
          <FormTitle
            activeStep={activeStep}
            color={"#000201"}
            title={"Add Card Information"}
            subtitle={"You will not be charged unless you’re accepted."}
            isMobile={isMobile}
          />
          <CustomStripeForm />
        </>
      )}
    </Box>
  );
}

export default Subscription;
