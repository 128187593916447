import { Box } from "@mui/material";
import { styled } from "@mui/system";
const SelectMain = styled(Box)(({ theme }) => ({
  "& h5": {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "capitalize",
    marginBottom: "20px",
  },
}));

export { SelectMain };
