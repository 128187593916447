import React from "react";
import { LinkHeaderBox } from "./style";
import { Box, Typography } from "@mui/material";
import { menu, category } from "../../../assets/smartlink";
import SelectDropdown from "../common/selectdropdown/SelectDropdown";
import SearchMain from "./SearchMain";

const LinksFlters = ({ layouttype = "grid", setlayouttype = () => {} }) => {
  return (
    <>
      <LinkHeaderBox>
        <Box className="left">
          <Typography variant="h3">Links</Typography>
        </Box>
        <Box className="right">
          <SearchMain />
          <SelectDropdown title="Creation Date" height="35px" />
          <Box
            className={`img-box1 ${
              layouttype === "grid" ? "active_image_box" : ""
            }`}
            onClick={() => {
              setlayouttype("grid");
            }}
          >
            <img src={category} alt="category" />
          </Box>
          <Box
            className={`img-box2 ${
              layouttype === "horizontal" ? "active_image_box" : ""
            }`}
            onClick={() => {
              setlayouttype("horizontal");
            }}
          >
            <img src={menu} alt="menu" />
          </Box>
        </Box>
      </LinkHeaderBox>
    </>
  );
};

export default LinksFlters;
