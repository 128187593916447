import React from "react";

const UnionIcon = ({ color = "#AFAFAF" }) => {
  return (
    <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.76922 0.945834C8.18153 0.626906 8.77432 0.702607 9.09324 1.11492C13.6419 6.99543 13.6289 14.6883 9.09446 20.5763C8.77641 20.9893 8.18378 21.0663 7.77079 20.7482C7.35781 20.4302 7.28084 19.8375 7.59889 19.4245C11.6125 14.2129 11.6207 7.46772 7.60013 2.26986C7.2812 1.85755 7.35691 1.26476 7.76922 0.945834Z" fill="white" />
      <path d="M4.00225 3.38051C4.41429 3.06123 5.00714 3.13643 5.32642 3.54847C8.73847 7.95186 8.7246 13.7315 5.3281 18.1412C5.01002 18.5541 4.41739 18.631 4.00442 18.313C3.59146 17.9949 3.51454 17.4023 3.83262 16.9893C6.70889 13.255 6.71629 8.42401 3.83429 4.70467C3.51501 4.29264 3.59021 3.69978 4.00225 3.38051Z" fill="white" />
      <path d="M2.19184 6.90036C1.87391 6.48728 1.28131 6.41013 0.868224 6.72806C0.455138 7.04598 0.377995 7.63858 0.69592 8.05167C1.99894 9.74471 2.00682 11.9191 0.696354 13.6191C0.378119 14.032 0.454815 14.6246 0.867662 14.9429C1.28051 15.2611 1.87317 15.1844 2.1914 14.7716C4.02796 12.389 4.01458 9.26869 2.19184 6.90036Z" fill="white" />
    </svg>
  );
};

export default UnionIcon;
