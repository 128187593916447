import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Wrapper = styled(Box)(({ theme }) => ({
  // paddingTop: "120px",
  background: "#141515",
}));
const Main = styled(Box)(({ theme }) => ({
  width: "91.5%",
  margin: "0 auto",
}));
const MenuMain = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #414241",
  paddingBottom: "30px",
  ".left__side ": {
    width: "30%",
  },
  ".left__side img": {
    width: "200px",
  },
  ".right__side": {
    width: "70%",
    display: "flex",
    justifyContent: "end",
  },
  ".right__side ul": {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    gap: "30px",
    justifyContent: "end",
  },
  ".right__side a": {
    color: "#AFAFAF",
    fontFamily: '"Poppins", sans-serif',
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textDecoration: "none",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    ".left__side ": {
      width: "100%",
      textAlign: "center",
    },
    ".right__side": {
      width: "100% !important",
      justifyContent: "center !important",
      ".right__side ul": {
        justifyContent: "center !important",
        padding: "0 !important",
      },
    },
  },
}));

const CopyRightMain = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "40px 0",
  paddingBottom: "20px",
  ".left__side ": {
    width: "50%",
    p: {
      color: "#AFAFAF",
      fontFamily: '"Poppins", sans-serif',
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
      margin: 0,
    },
  },

  ".right__side": {
    width: "50%",
    display: "flex",
    justifyContent: "end",
  },
  ".right__side ul": {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    gap: "34px",
    justifyContent: "end",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    ".left__side ": {
      width: "100%",
      textAlign: "center",
    },
    ".right__side": {
      width: "100% !important",
      justifyContent: "center !important",
      ".right__side ul": {
        justifyContent: "center !important",
        padding: "0 !important",
      },
    },
  },
}));

export { Wrapper, Main, MenuMain, CopyRightMain };
