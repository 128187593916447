import React, { useState } from "react";
import styles from "../../OpportunitiesComponents/Sync/submit_content.module.css";
// get the css file from the auth
import authstyle from "../auth_pages.module.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const FormField = ({
  title,
  info,
  infoText,
  content,
  dropdown = false,
  password,
  fullWidth,
  required = false,
  onValueChange,
  dropDownData,
  type = "text",
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleOptionClick = (option) => {
    onValueChange(option);
    toggleDropdown();
  };

  const handleInputChange = (event) => {
    onValueChange(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.user_info_layout}>
      <div className={styles.field_info}>
        <p className={styles.user_info_title}>{title}</p>

        {info && (
          <div
            className={styles.tooltipContainer}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <img
              src="/v2Assets/submission_form_assets/MoreInfoIcon.png"
              alt="info"
              className={styles.infoImage}
            />
            {showTooltip && <div className={styles.tooltip}>{infoText}</div>}
          </div>
        )}
      </div>
      <div
        className={`${styles.auth_input_box} ${authstyle.forminput_inputmain}`}
      >
        <input
          className={styles.input_box_text}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          value={content}
          onChange={handleInputChange}
          readOnly={dropdown}
        />
        {type === "password" && (
          <div
            className={authstyle.passwordToggle}
            onClick={togglePasswordVisibility}
          >
            {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </div>
        )}

        {dropdown && (
          <div
            className={`${styles.input_box_dropdown_button} Dropdown_Main`}
            onClick={toggleDropdown}
          >
            <div className={styles.dropdown_green_bg}>
              <img
                src="/v2Assets/submission_form_assets/DropDownArrow.png"
                alt="options"
                className={styles.drop_down_image}
              />
              {isDropdownVisible && (
                <div className={styles.dropdown_menu}>
                  {dropDownData.map((option) => (
                    <div
                      key={option}
                      className={styles.dropdown_menu_option_box}
                      onClick={() => handleOptionClick(option)}
                    >
                      <p className={styles.dropdown_menu_option}>{option}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {required && (
        <div className={styles.required_tag}>
          <p className={styles.required}>{required}</p>
        </div>
      )}
    </div>
  );
};
