import React from "react";
import ProducersLayouts from "./ProducersLayouts";
import ForgetPasswordPopup from "../../../../components/smartlink/producers/auths/ForgetPasswordPopup";

const ProducersForgotePassPage = () => {
  return (
    <ProducersLayouts>
      <ForgetPasswordPopup />
    </ProducersLayouts>
  );
};

export default ProducersForgotePassPage;
