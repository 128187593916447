import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const SliderMain = styled(Box)(({ theme }) => ({
  // border: "1px solid white",
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  "& .slidermain": {
    marginTop: "130px",
    height: "100px",
    width: "95%",
    // border: "1px solid red",
    "& .bannerSliderhead": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "70px",
    },
    "& .MainGridSlide": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    padding: 0,
    "& .bannerSliderhead": {
      textAlign: "center !important",
      fontSize: "35px !important",
    },
    "& .slider-grid": {
      marginTop: "0px",
    },
  },
}));

export { SliderMain };
