import React, { Fragment, useEffect, useRef, useState } from 'react';
import styles from '../submissions.module.css';

import ConnectSubmissionCard from '../ConnectSubmissions/connectSubmissionCard';
import { Loader } from '../../OpportunitiesComponents/AdditionalComponents/Loader';
import useAppState from '../../../context/useAppState';

import { useDebounce } from '../../../hooks/useDebounce';
import { Box, Grid, Modal, Typography, Popover, IconButton, MenuItem, Stack } from '@mui/material';
import { LOOKING_FOR } from '../../../shared/constants';

import useAuth from '../../../context/AuthContext';
import BeatInfoData from '../ViewSubmissionInformation/BeatInfoData';
import { useNavigate } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { PaginationMain } from '../../smartlink';
import MultiModal from '../../smartlink/common/MultiModal/MultiModal';
import { crown } from '../../../assets/smartlink';
import {
  ModelContentBox,
  Modelstyle,
  MultiButton,
} from '../../smartlink/common/MultiModal/style';

import PopoverButton from './PopoverButton'
import libraryStyles from '../../LibrariesComponents/Libraries/Libraries.module.css'



function OpportunitySubmissionsComponent() {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState(LOOKING_FOR[0]);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState(LOOKING_FOR[0]);


  const { user } = useAuth();
  const navigate = useNavigate();

  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  // ============ custom pagination =====
  const [currentPage, setcurrentPage] = useState(1);
  const [TotalPages, setTotalPages] = useState(0);
  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };
  // ============ End custom pagination =====
  const [PaymentOpen, setPaymentOpen] = useState(false);
  const [remaingcounts, setremaingcounts] = useState({});

  const isPlanNameAvailable = user?.planId?.planName;

  const IsFree =
    isPlanNameAvailable === 'Free' || isPlanNameAvailable === 'Freemium';

  const searchQuery = useDebounce(query, 500);

  const { fetchMySubmission } = useAppState('submission');
  const PAGE_LIMIT = 10;


  useEffect(() => {
    setPage(0);
    setcurrentPage(1);
  }, [type]);

  useEffect(() => {
    setLoading(true);
    const queryString = new URLSearchParams({
      page: currentPage,
      limit: PAGE_LIMIT,
      type: "", //for merging beats and loops
      search: searchQuery,
      sort: sortBy,
    }).toString();

    fetchMySubmission(queryString)
      .then((response) => {
        if (response.status === 1) {
          const newRows = response.data;
          setTotalPages(response?.totalPages || 0);
          setHasNextPage(newRows.length < response.totalCount);
          setPage((page) => page + 1);
          setData(newRows);
          console.log("submissionsCount", response?.submissionsCount, response?.totalCount)
          setremaingcounts({
            submissionsCount: response?.submissionsCount,
            totalCount: response?.totalCount,
          });
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchQuery, sortBy, type, currentPage]);

  const getMoreData = async () => {
    try {
      const newPage = page + 1;
      const queryString = new URLSearchParams({
        page: newPage,
        limit: PAGE_LIMIT,
        type: type,
        search: searchQuery,
        sort: sortBy,
      }).toString();
      fetchMySubmission(queryString)
        .then((response) => {
          const newRows = [...data, ...response.data];

          console.info("newRows => ", newRows);

          setHasNextPage(newRows.length < response.totalCount);
          setData(newRows);
          setPage((page) => page + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setError(err);
      // console.log("error on fetch more...", err);
    }
  };

  const fetchMore = () => {
    console.log("fetch more...");
    getMoreData();
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  // const ShowBlurClass =
  //   !loading &&
  //   user.roleId.roleName === 'Music Producer' &&
  // (isPlanNameAvailable === 'Free' || isPlanNameAvailable === 'Freemium');

  const ShowBlurClass =
    !loading && IsFree && user.roleId.roleName == 'Music Producer' &&

    data?.length > 0 &&
    remaingcounts?.submissionsCount === 3;

  useEffect(() => {
    if (ShowBlurClass) {
      setPaymentOpen(true);
    }
  }, [ShowBlurClass]);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={libraryStyles.page_sub_link}>Sent</div>
        <div style={{ margin: "25px 0px 0px" }}>
          <PopoverButton />
        </div>
      </div>

      <div className={styles.main_layout_for_inbox} >
        {!loading && (
          <div
            className={`${styles.RemainingLinks}`}
            style={{ color: "#f00", fontSize: "18px", fontWeight: 500 }}
          >
            {IsFree &&
              user.roleId.roleName == "Music Producer" &&
              (remaingcounts?.totalCount !== "unlimited" ||
                remaingcounts?.submissionsCount !== "unlimited")
              ? `Remaining Submissions: ${parseInt(3) - parseInt(remaingcounts?.submissionsCount)
              }`
              : null}
          </div>
        )}
        <div
          className={`${styles.tab_layout} ${ShowBlurClass && styles.tab_blurmain
            }`}
        >
          {!loading ? (
            <Fragment>
              {data?.length > 0 ? (
                <>
                  {data.map((o, key) => (
                    <Fragment key={key}>
                      <ConnectSubmissionCard
                        onView={() => { }}
                        isShowBeatDetail={true}
                        data={o}
                        isShowOpportunityName={true}
                        canSelect={false}
                        beatInfoBtn={<BeatInfoData beatData={o} />}
                      />
                    </Fragment>
                  ))}
                </>
              ) : (
                !loading && (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "#fff",
                        alignItems: "center",
                      }}
                    >
                      <p classes={styles.empty_page_text}>No Data Found</p>
                    </Box>
                  </>
                )
              )}
            </Fragment>
          ) : (
            <Loader />
          )}

          {(loading || hasNextPage) && (
            <Grid container justifyContent={"center"} ref={sentryRef}>
              <Grid item>
                <Typography style={{ color: "#2ebb55" }}>Loading..</Typography>
              </Grid>
            </Grid>
          )}
        </div>
        {!loading && TotalPages > 1 && (
          <>
            <Box sx={{ marginBottom: "1rem" }}>
              <PaginationMain
                pageCount={TotalPages}
                page={currentPage}
                handelChange={handlePageChange}
              />
            </Box>
          </>
        )}

        {/*  upgrade your membership model*/}

        <Modal
          open={open}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          container={() => document.getElementById("maintance_Modal")}
        >
          <ModelContentBox
            sx={{
              ...Modelstyle,
              width: { xs: "100%", sm: "675px" },
              padding: "80px",
              borderRadius: "0.625rem",
              background: "#141515",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "40px",
              position: "relative",
            }}
          >
            <div>
              <h2 id="modal-title">Modal Title</h2>
              <p id="modal-description">Modal Content</p>
            </div>
          </ModelContentBox>
        </Modal>
        <MultiModal
          open={PaymentOpen}
          heading="Upgrade Your Plan"
          text="Upgrade your membership to track your audio files."
          icon={crown}
          type="link"
          title="Upgrade"
          RedirectTo="/beat-inbox/more-storage?type=opportunities"
          setPaymentOpen={setPaymentOpen}
        />
      </div>
    </>
  );
}

export default OpportunitySubmissionsComponent;
