import React, { memo, useState } from "react";
import {
  HeaderMain,
  MainBeatsBox,
  ProgressMain,
  FilterMain,
  Main,
} from "./styles/style";
import CustomProgressBar from "../../common/proressbar/CustomProgressBar";
import { Box, Button, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import PricePlanCard from "../../common/priceplans/PricePlanCard";
import useAuth from "../../../../context/AuthContext";
import ContactUsPopup from "./popup/ContactUsPopup";

const monthlyPlans = [
  {
    title: "Free",
    desc: "Send files once in a while",
    price: "$0",
    buttonName: "Subscribed",
    storage: "3 GB storage space",
    smarLink: "3 Smart Links",
    songWriter: "No Songwriter AI tokens (0)",
  },
  {
    title: "Basic",
    desc: "Send really big files regularly",
    price: "$15/mo",
    buttonName: "Get Started",
    storage: "25 GB Storage",
    smarLink: "10 Smart Links",
    songWriter: "10 SongWriter AI Tokens (Monthly)",
  },
  {
    title: "Elite",
    desc: "Share work with no limits",
    price: "$50/mo",
    buttonName: "Get Started",
    storage: "Unlimited Storage",
    smarLink: "Unlimited Smart Links",
    songWriter: "50 SongWriter AI Tokens (Monthly)",
  },
  {
    title: "Enterprise Plan",
    desc: "Full suite of features, unlimited links, tailored solutions",
    price: "Custom Quote",
    buttonName: "Contact Us",
    storage: "No size limit on transfer",
    smarLink: "Unlimited storage",
    songWriter: "Unlimited portals and reviews",
  },
];
//  yearly Subscription plans
const yearlyPlans = [
  {
    title: "Free",
    desc: "Send files once in a while",
    price: "$0",
    buttonName: "Subscribed",
    storage: "3 GB storage space",
    smarLink: "3 Smart Links",
    songWriter: "No Songwriter AI tokens (0)",
  },
  {
    title: "Basic",
    desc: "Send really big files regularly",
    price: "$150/yr",
    buttonName: "Get Started",
    storage: "25 GB Storage",
    smarLink: "10 Smart Links",
    songWriter: "10 SongWriter AI Tokens (Yearly)",
  },
  {
    title: "Elite",
    desc: "Share work with no limits",
    price: "$500/yr",
    buttonName: "Get Started",
    storage: "Unlimited Storage",
    smarLink: "Unlimited Smart Links",
    songWriter: "50 SongWriter AI Tokens (Yearly)",
  },
  {
    title: "Enterprise Plan",
    desc: "Full suite of features, unlimited links, tailored solutions",
    price: "Custom Quote",
    buttonName: "Contact Us",
    storage: "No size limit on transfer",
    smarLink: "Unlimited storage",
    songWriter: "Unlimited portals and reviews",
  },
];

const BeatStorage = ({ setPlantype, data  }) => {
  const location = useLocation();
  const fullUrl = location.pathname + location.search;
  const params = new URLSearchParams(fullUrl?.split("?")[1]);
  const type = params.get("type");

  const [activeButton, setActiveButton] = useState(
    data?.planListing?.interval || ""
  );
  const [opencontactus, setopencontactus] = useState(false);
  const handleButtonClick = (type) => {
    setActiveButton(type);
    setPlantype(type);
  };

  // const CongradulationModelOpen = () => {
  //   setOpen(!open);
  // };

  const selectedPlans = activeButton === "monthly" ? monthlyPlans : yearlyPlans;

  const { user } = useAuth();

  return (
    <MainBeatsBox>
      <HeaderMain>
        <h6>{`${user?.roleId?.roleName} Membership Upgrade`}</h6>
        {data && data?.planListing && user?.planId?.planName !== "Elite" && (
          <>
            <ProgressMain className="ProgressMain">
              <Box className="progress_text">
                <h5>
                  {data?.planListing?.totalStorage} GB total {""}
                  {(user?.planId?.planName === "Pro" ||
                    user?.planId?.planName === "Free") &&
                    data?.planListing?.usedStorage + " used"}{" "}
                </h5>
                {/* <Link>Add Space</Link> */}
              </Box>
              <CustomProgressBar value={data?.planListing?.usedStorage} />
            </ProgressMain>
          </>
        )}
      </HeaderMain>
      <FilterMain>
        <h6>Choose Your Plan</h6>
        <p>
          {type === "opportunities"
            ? "Unlock exclusive member-only benefits and take complete control of your career with our HitMaker Membership."
            : "Nothing Is Faster Than Subscription. Nobody Does It Like Subscription."}
        </p>
      </FilterMain>

      {/* Price plan */}
      <Main className="price_main">
        <Box className="btn-box">
          <Button
            variant="contained"
            className={`btn ${activeButton === "month" ? "active" : ""}`}
            isSelected={activeButton === "month"}
            onClick={() => handleButtonClick("month")}
          >
            monthly
          </Button>
          <Button
            variant="contained"
            className={`btn ${activeButton === "year" ? "active" : ""}`}
            onClick={() => handleButtonClick("year")}
          >
            yearly
          </Button>
        </Box>
        <Box className={activeButton === "month" ? "month" : "year"}>
          <Grid container spacing={4}>
            {data?.planListing?.plans
              ?.filter(
                (obj) =>
                  obj.planName !== "Enterprise Plan" &&
                  obj.planName !== "HitMaker"
              )
              .map((plan, index) => {
                // const Free = plan.planName === "Free Membership";
                // const Basic = plan.planName === "Basic";
                // const Elite = plan.planName === "Elite";

                return (
                  <>
                    <Grid
                      item
                      sm={12}
                      md={6}
                      lg={4}
                      key={index}
                      sx={{ width: "100%" }}
                    >
                      <PricePlanCard
                        plan={plan}
                        setopencontactus={setopencontactus}
                      />
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </Box>
      </Main>
      {data && data?.planListing?.plans?.length === 0 && (
        <p style={{ color: "#AFAFAF", textAlign: "center" }}>No Plan Found</p>
      )}
      <ContactUsPopup
        opencontactus={opencontactus}
        setopencontactus={setopencontactus}
      />
    </MainBeatsBox>
  );
};

export default memo(BeatStorage);
