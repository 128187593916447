/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
	Box,
	Button,
	Checkbox,
	CloseIcon,
	Divider,
	Heading,
	HStack,
	Image,
	Input,
	Menu,
} from 'native-base';
import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { ThemeProvider, createTheme } from '@material-ui/core';

import styles from '../styles.module.css';
export function TermAndCondition(props) {
	const { openModal, closeModal, handleDownload, isCustom, termsAndCondition } =
		props;
	const darkTheme = createTheme({
		palette: {
			type: 'dark',
		},
	});
	function getModalStyle() {
		const top = 50;
		const left = 50;

		return {
			top: `${top}%`,
			left: `${left}%`,
			transform: `translate(-${top}%, -${left}%)`,
		};
	}
	const modalStyle = getModalStyle();

	return (
		<ThemeProvider theme={darkTheme}>
			<Modal open={openModal} onClose={closeModal}>
				<div style={modalStyle} className={styles.modalPaper}>
					<Box w={'100%'} bg={'#141313'} rounded={'lg'}>
						<HStack
							alignSelf={'center'}
							justifyContent={'space-between'}
							my={5}
							w={'90%'}
						>
							<Heading
								w={'100%'}
								color={'#fff'}
								fontWeight={'Medium'}
								fontFamily={'Poppins'}
								alignSelf={'center'}
								textAlign={'center'}
								fontSize={{ lg: '24px', base: '18px' }}
							>
								Terms of Use
							</Heading>

							<Button
								variant={'ghost'}
								px={{ lg: 5, base: 2 }}
								py={1}
								position={'absolute'}
								right={0}
								justifyContent={'flex-end'}
								_hover={{ bg: 'transparent' }}
								_pressed={{ bg: 'transparent' }}
								onPress={closeModal}
							>
								<CloseIcon sx={{ color: '#AFAFAF' }} />
							</Button>
						</HStack>
						<Box w={'100%'} mt={5} alignSelf={'center'}>
							{/* <Heading
                w={'100%'}
                color={'#e3e3e3'}
                fontWeight={'normal'}
                fontFamily={'Poppins'}
                alignSelf={'center'}
                textAlign={'center'}
                fontSize={{ lg: '16px', base: '12px' }}
                lineHeight={{ lg: '24px', base: '18px' }}
              >
                Terms of service are the legal agreements between a service
                provider and a person who wants to use that service. The person
                must agree to abide by the terms of service in order to use the
                offered service. Terms of service can also be merely a
                disclaimer, especially regarding the use of websites.
              </Heading>
              <Heading
                w={'100%'}
                color={'#e3e3e3'}
                fontWeight={'normal'}
                fontFamily={'Poppins'}
                alignSelf={'center'}
                textAlign={'center'}
                fontSize={{ lg: '16px', base: '12px' }}
                lineHeight={{ lg: '24px', base: '18px' }}
                my={5}
              >
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose.{' '}
              </Heading> */}
							<Heading
								w={'100%'}
								color={'#e3e3e3'}
								fontWeight={'normal'}
								fontFamily={'Poppins'}
								alignSelf={'center'}
								textAlign={'center'}
								fontSize={{ lg: '16px', base: '12px' }}
								lineHeight={{ lg: '24px', base: '18px' }}
							>
								{isCustom
									? termsAndCondition
									: 'No terms and conditions are available'}
							</Heading>
							<Box w={{ lg: '60%', base: '100%' }} alignSelf={'center'} my={8}>
								<Button
									w={'100%'}
									borderColor='#2EBA55'
									borderWidth={1}
									_pressed={{
										bg: '#2EBB55',
										_text: {
											color: '#fff',
										},
									}}
									backgroundColor='#2EBA55'
									py={2.5}
									_text={{
										color: '#fff',
										fontSize: {
											xl: '16px',
											lg: '16px',
											md: '16px',
											base: '14px',
										},
										fontWeight: 'medium',
										textTransform: 'capitalize',
										fontFamily: 'Poppins',
									}}
									_hover={{
										backgroundColor: 'transparent',
										_text: {
											color: '#2EBA55',
										},
									}}
									alignSelf={'center'}
									onPress={() => {
										closeModal();
										handleDownload();
									}}
								>
									Accept Terms & Conditions
								</Button>
							</Box>
						</Box>
					</Box>
				</div>
			</Modal>
		</ThemeProvider>
	);
}
