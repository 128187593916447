import axios from "../../config/axiosInterceptor";
import { HTTP_STATUS } from "../../shared/constants";

export const addOpportunity = async (payload) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities`,
      payload
    );
    return data;
  } catch (error) {
    return error;

    // throw new Error(error);
  }
};

export const updateOpportunityById = async (id, payload) => {
  try {
    const { data, status } = await axios.put(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/${id}`,
      payload
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while saving opportunity");
    }
  } catch (error) {
    console.log("#updateOpportunityById Err");
    throw new Error(error);
  }
};

export const getOpportunity = async (id) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/detail?id=${id}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while facing opportunity");
    }
  } catch (error) {
    console.log("#getOpportunity Err");
    throw new Error(error);
  }
};

export const pauseOpportunityApi = async (id) => {
  const payload = { isPause: true };
  try {
    const data = await axios.put(`/opportunities/${id}`, payload);
    if (data.status === 200) {
      return data.data.msg;
    }
    console.log("this is my data message =", data);
  } catch (error) {
    console.log("this is my error =", error);
  }
};

export const removeOpportunity = async (id) => {
  try {
    const { data, status } = await axios.delete(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/${id}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while deleting opportunity");
    }
  } catch (error) {
    console.log("#removeOpportunity Err");
    throw new Error(error);
  }
};

export const getFeedOpportunityApi = async (queryString) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/feeds?${queryString}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while deleting opportunity");
    }
  } catch (error) {}
};

export const getMyOpportunityApi = async (id) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/user?id=${id}`
    );
    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while deleting opportunity");
    }
  } catch (error) {}
};

export const getMyOpportunityByQuery = async (queryString) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/user?${queryString}`
    );
    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while Getting MyOpportunity");
    }
  } catch (error) {}
};

export const getSelectedBeatsByQuery = async (queryString) => {
  try {
    const { data, status } = await axios.get(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/admin/selected-beats?${queryString}`
    );
    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while Getting selected beats");
    }
  } catch (error) {}
};

export const toggleFeaturedStatus = async (id) => {
  try {
    const { data, status } = await axios.put(
      `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/feature/${id}`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while toggling featured status");
    }
  } catch (error) {
    console.log("#toggleFeaturedStatus Err");
    throw new Error(error);
  }
};
