import React, { useState, useEffect, useRef } from 'react';

import {
	Box,
	Button,
	Heading,
	HStack,
	Text,
	Input,
	Divider,
	Image,
	Checkbox,
	Menu,
	Pressable,
	Avatar,
} from 'native-base';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PlayBtn } from './Svgs/PlayBtn';
import { useNavigate } from 'react-router-dom';
import fireIcon from '../../assets/Icons/fireIcon.png';
import redFire from '../../assets/Icons/redFire.png';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { AiFillPauseCircle, AiFillPlayCircle } from 'react-icons/ai';
import DownloadIcon from '@mui/icons-material/Download';
import {
	getCollaboratorsOfSoundPack,
	onSoundPackDownload,
	upvoteDownvoteSoundPack,
} from '../../Graphql_services/SoundPackMutation';
import WaveSurfer from 'wavesurfer.js';
import styles from './styles.module.css';
import { TermAndCondition } from './Modals/TermAndConditions';
import Download from './Svgs/Download';
const formWaveSurferOptions = (ref) => ({
	container: ref,

	responsive: true,
	height: 0,
	// If true, normalize by the maximum peak instead of 1.0.
	normalize: true,
	// Use the PeakCache to improve rendering speed of large waveforms.
	partialRender: true,
});

export const SoundCard = (props) => {
	const { data, setSoundId, soundId } = props;
	const {
		soundPackCover,
		userInfo,
		soundPackTitle,
		soundPackFile,
		soundPackZipFile,
		totalDownloads,
		tags,
		createdBy,
		upvotes,
	} = data;

	const [modal, setModal] = useState(false);

	const navigate = useNavigate();
	const userId = localStorage.getItem('userId');
	const [upVotes, SetUpvotes] = useState(data.upvotedBy ?? []);
	const [numDownloads, SetNumDownloads] = useState(totalDownloads ?? 0);
	const [upvoteCount, setUpvoteCount] = useState(upvotes);
	const [collaborator, setCollaborator] = useState([]);
	const [playing, setPlay] = useState(false);
	const wavesurfer = useRef(null);
	const waveformRef = useRef(null);

	const [isLoaded, setIsLoaded] = useState(true);
	const [volume, setVolume] = useState(0.5);
	const [collaboratorLoader, setCollaboratorLoader] = useState(false);

	const [OnSoundPackDownload] = useMutation(onSoundPackDownload, {
		context: { clientName: 'soundpack' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			if (!data?.onSoundPackDownload?.isTokenExpired) {
				if (data?.onSoundPackDownload?.success) {
					SetNumDownloads(numDownloads + 1);
					console.log('success onSoundPackDownload');
				} else {
					console.log('error onSoundPackDownload');
				}
			} else {
				localStorage.clear();
				navigate('/signin');
			}
		},
		onError(err) {
			console.log('error on getting user info', err);
		},
	});
	const [UpvoteDownvoteSoundPack] = useMutation(upvoteDownvoteSoundPack, {
		context: { clientName: 'soundpack' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			if (!data?.upvoteDownvoteSoundPack?.isTokenExpired) {
				if (data?.upvoteDownvoteSoundPack?.success) {
					SetUpvotes(data?.upvoteDownvoteSoundPack?.soundpack.upvotedBy);
					setUpvoteCount(data?.upvoteDownvoteSoundPack?.soundpack?.upvotes);
					toast.success(data?.upvoteDownvoteSoundPack?.message);
				} else {
					toast.error(data?.upvoteDownvoteSoundPack?.message);
				}
			} else {
				localStorage.clear();
				navigate('/signin');
			}
		},
		onError(err) {
			console.log('error on getting user info', err);
		},
	});
	const [GetCollaboratorsOfSoundPack] = useLazyQuery(
		getCollaboratorsOfSoundPack,
		{
			context: { clientName: 'soundpack' },
			fetchPolicy: 'no-cache',
			onCompleted(data) {
				const { getCollaboratorsOfSoundPack } = data;
				if (getCollaboratorsOfSoundPack) {
					const { success, message, isTokenExpired } =
						getCollaboratorsOfSoundPack;
					if (success) {
						setCollaborator(getCollaboratorsOfSoundPack?.collaborators);
						setCollaboratorLoader(false);
					} else {
						if (success === false) {
							if (isTokenExpired) {
								localStorage.clear();
								navigate('/signin');
							} else {
								toast.error(message);
							}
						}
					}
				}
			},
			onError(err) {
				console.log('error on getting user info', err);
			},
		}
	);
	const downloadAudio = (url) => {
		toast.success('Download in progess');
		fetch(url)
			.then((res) => res.blob())
			.then((file) => {
				const fileName = url.split(
					'https://du6zmi1l7ys6.cloudfront.net/fileupload'
				);
				if (fileName.length > 1) {
					const filename = fileName[1];
					// const filename = fileName[1];
					// console.log("filenae........", filenames);
					let tempUrl = URL.createObjectURL(file);
					const aTag = document.createElement('a');
					aTag.href = tempUrl;
					aTag.download = filename;
					document.body.appendChild(aTag);
					aTag.click();
					aTag.remove();
					toast.success('Downloaded Successfully');
					OnSoundPackDownload({
						variables: {
							soundPackId: data._id,
						},
					});
				} else {
					toast.error('Something went wrong');
				}
			})
			.catch((err) => {
				// console.log("error......", err);
				toast.error('Audio could not download successfully.');
			});
	};
	let test = null;
	const handlePlayPause = () => {
		if (!isLoaded) {
			if (soundId === data._id) {
				setPlay(!playing);
				wavesurfer.current.playPause();
			} else {
				setPlay(false);
				wavesurfer.current.pause();
			}
		}
	};
	const handleDownload = () => {
		downloadAudio(soundPackZipFile ? soundPackZipFile : soundPackFile);
	};
	const handleCloseModal = () => {
		setModal(false);
		// downloadAudio(data.soundPackFile);
	};
	useEffect(() => {
		if (soundPackFile) {
			setPlay(false);

			const options = formWaveSurferOptions(waveformRef.current);
			wavesurfer.current = WaveSurfer.create(options);
			wavesurfer.current.load(soundPackFile);

			wavesurfer.current.on('ready', function () {
				if (wavesurfer.current) {
					wavesurfer.current.setVolume(volume);
					setVolume(volume);
					setIsLoaded(false);
				}
			});

			// Removes events, elements and disconnects Web Audio nodes.
			// when component unmount
			return () => wavesurfer.current.destroy();
		}
	}, [soundPackFile]);
	return (
		<Box w={'100%'} bg={'#1C1B1B'} rounded={'md'}>
			<div ref={waveformRef} />
			<Box w={'90%'} alignSelf={'center'} my={{ '2xl': 7, lg: 5, base: 5 }}>
				<HStack space={5} mb={{ '2xl': 7, lg: 5, base: 5 }}>
					<img
						src={soundPackCover}
						className={styles.soundPackCover}
						alt={'cover'}
					/>
					<Heading
						alignSelf={'center'}
						fontFamily={'Poppins'}
						textTransform={'capitalize'}
						fontSize={{ '2xl': '20px', lg: '18px', base: '16px' }}
						fontWeight={'normal'}
						textAlign={'left'}
						color={'#E3E3E3'}
						style={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							wordWrap: 'break-word',
						}}
					>
						{soundPackTitle}
					</Heading>
				</HStack>

				{tags.length > 0 ? (
					<HStack w={'100%'} mt={{ '2xl': 2, lg: 2, base: 3 }}>
						<Heading
							alignSelf={'center'}
							fontFamily={'Poppins'}
							fontSize={{ '2xl': '16px', lg: '14px', base: '14px' }}
							fontWeight={'normal'}
							textAlign={'left'}
							color={'#afafaf'}
						>
							{tags.filter((obj) => obj.type === 'Genre').length != 0
								? 'Genres:'
								: ' '}
						</Heading>
						<HStack px={{ '2xl': 3, lg: 2, base: 2 }} w={'100%'}>
							<div
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									width: '90%',
									//   height: '70px',
									//   overflow: 'hidden',
									//   textOverflow: 'ellipsis',
									//   whiteSpace: 'nowrap',
								}}
							>
								{tags.filter((obj) => obj.type === 'Genre').length != 0 &&
									tags
										.filter((obj) => obj.type === 'Genre')
										.map((item, index) => {
											return (
												<Heading
													key={index}
													alignSelf={'center'}
													fontFamily={'Poppins'}
													fontSize={{ '2xl': '16px', lg: '14px', base: '14px' }}
													fontWeight={'normal'}
													textAlign={'left'}
													color={'#fff'}
												>
													{index != 0 && '|'}
													<Heading
														alignSelf={'center'}
														fontFamily={'Poppins'}
														fontSize={{
															'2xl': '16px',
															lg: '14px',
															base: '14px',
														}}
														fontWeight={'normal'}
														textAlign={'left'}
														color={'#fff'}
													>
														{' ' + item.value + ' '}
													</Heading>
												</Heading>
											);
										})}
							</div>
						</HStack>
					</HStack>
				) : (
					''
				)}
				<HStack
					mt={{ '2xl': 3, lg: 3, base: 3 }}
					mb={{ '2xl': 7, lg: 5, base: 5 }}
				>
					<Heading
						alignSelf={'center'}
						fontFamily={'Poppins'}
						fontSize={{ '2xl': '16px', lg: '14px', base: '14px' }}
						fontWeight={'normal'}
						textAlign={'left'}
						color={'#afafaf'}
					>
						Posted by:{' '}
						{userInfo
							? userInfo.userName
								? userInfo.userName
								: (userInfo.firstname && userInfo.lastname
										? `${userInfo.firstname} ${userInfo.lastname}`
										: userInfo.firstname || userInfo.lastname) || 'User'
							: 'beenish'}
					</Heading>
				</HStack>
				<HStack width={'100%'}>
					<InfoIcon sx={{ color: '#afafaf', fontSize: 30 }} />
					<Heading
						alignSelf={'center'}
						fontFamily={'Poppins'}
						fontSize={{ '2xl': '16px', lg: '14px', base: '14px' }}
						fontWeight={'normal'}
						textAlign={'left'}
						color={'#afafaf'}
						px={{ '2xl': 3, lg: 2, base: 2 }}
					>
						The user must agree to abide by the terms of service
					</Heading>
				</HStack>
				<HStack
					justifyContent={'space-between'}
					mt={{ '2xl': 7, lg: 5, base: 5 }}
				>
					<HStack alignSelf={'center'}>
						{soundPackFile && (
							<Button
								alignSelf={'center'}
								variant={'ghost'}
								p={0}
								_hover={{ bg: 'transparent' }}
								_pressed={{ bg: 'transparent' }}
								onPress={() => {
									setSoundId(data._id);
									handlePlayPause();
								}}
							>
								{!playing ? (
									<AiFillPlayCircle
										size={30}
										style={{
											cursor: 'pointer',
											alignSelf: 'center',
											color: '#AFAFAF',
										}}
									/>
								) : (
									<AiFillPauseCircle
										size={30}
										style={{
											cursor: 'pointer',
											alignSelf: 'center',
											color: '#AFAFAF',
										}}
									/>
								)}
							</Button>
						)}
						<Button
							borderColor='#2EBA55'
							borderWidth={1}
							_pressed={{
								bg: '#2EBB55',
								_text: {
									color: '#fff',
								},
							}}
							backgroundColor='#2EBA55'
							py={1.5}
							px={{ '2xl': 5, lg: 4, base: 4 }}
							ml={
								// soundPackFile?.slice(-3) === 'zip' ||
								// soundPackFile?.slice(-3) === 'rar'
								soundPackFile ? { '2xl': 3, lg: 2, base: 2 } : 0
							}
							_text={{
								color: '#fff',
								fontSize: {
									'2xl': '14px',
									xl: '12px',
									lg: '12px',
									base: '12px',
								},
								fontWeight: 'normal',
								textTransform: 'capitalize',
								fontFamily: 'Poppins',
							}}
							_hover={{
								backgroundColor: 'transparent',
								_text: {
									color: '#2EBA55',
								},
							}}
							alignSelf={'center'}
							onPress={() => setModal(true)}
						>
							Download
						</Button>
						{/* <Heading
              _text={{
                color: '#fff',
                fontSize: {
                  '2xl': '14px',
                  xl: '12px',
                  lg: '12px',
                  base: '12px',
                },
                fontWeight: 'normal',
                textTransform: 'capitalize',
                fontFamily: 'Poppins',
              }}
              ml={2}
            >
              {data.totalDownloads}
            </Heading> */}
					</HStack>
					<HStack space={3}>
						<HStack space={1.5} alignSelf={'center'}>
							<Download />

							<Heading
								alignSelf={'center'}
								textTransform={'capitalize'}
								fontFamily={'Poppins'}
								fontSize={{ lg: '12px', base: '10px' }}
								fontWeight={'medium'}
								textAlign={'left'}
								color={'#afafaf'}
							>
								{numDownloads}
							</Heading>
						</HStack>
						{/* {createdBy !== userId && ( */}
						<>
							<Button
								variant={'ghost'}
								_pressed={{ bg: 'transparent' }}
								_hover={{ bg: 'transparent' }}
								p={0}
								alignSelf={'center'}
								onPress={() =>
									UpvoteDownvoteSoundPack({
										variables: {
											soundPackId: data._id,
										},
									})
								}
							>
								<img
									src={upVotes.includes(userId) ? redFire : fireIcon}
									height={'22px'}
									width={'22px'}
									alt={'upvotes'}
								/>
							</Button>
							<Heading
								alignSelf={'center'}
								textTransform={'capitalize'}
								fontFamily={'Poppins'}
								fontSize={{ lg: '12px', base: '10px' }}
								fontWeight={'medium'}
								textAlign={'left'}
								color={'#afafaf'}
							>
								{upvoteCount}
							</Heading>
						</>
						{/* )} */}
						<Menu
							alignSelf={'center'}
							color={'#afafaf'}
							fontSize={{ md: '16px' }}
							mt={-2}
							w={{ lg: '20vw', base: '60vw' }}
							_text={{
								color: '#afafaf',
							}}
							onOpen={() => {
								setCollaboratorLoader(true);
								GetCollaboratorsOfSoundPack({
									variables: {
										soundPackId: data._id,
									},
								});
							}}
							background={'#1C1B1B'}
							placement={'bottom right'}
							trigger={(triggerProps) => {
								return (
									<Pressable
										alignSelf={'center'}
										bg={'transparent'}
										_hover={{ borderColor: '#AFAFAF' }}
										_focus={{ bg: 'transparent' }}
										accessibilityLabel='More options menu'
										{...triggerProps}
									>
										<InfoOutlinedIcon sx={{ color: '#afafaf', fontSize: 35 }} />
									</Pressable>
								);
							}}
						>
							<Menu.Item
								bg={'#1C1B1B'}
								shadow={8}
								m={0}
								p={0}
								_focus={{ bg: 'transparent' }}
								_hover={{ bg: 'transparent' }}
								_pressed={{ bg: 'transparent' }}
								w={'100%'}
							>
								<Box w={'100%'} alignSelf={'center'}>
									<Heading
										my={5}
										textTransform={'capitalize'}
										fontFamily={'Poppins'}
										fontSize={{
											xl: '16px',
											lg: '16px',
											md: '14px',
											base: '14px',
										}}
										fontWeight={'medium'}
										textAlign={'left'}
										color={'#fff'}
									>
										Collaborator's Splits %
									</Heading>

									<Box>
										{collaborator.length > 0 ? (
											collaborator.map((item, index) => (
												<HStack
													key={index}
													justifyContent={'space-between'}
													w={'100%'}
													my={2}
												>
													<HStack flexWrap={'wrap'} space={3}>
														{item.profilePic ? (
															<Image
																source={item.profilePic}
																size={'30px'}
																rounded={'full'}
																alignSelf={'center'}
															/>
														) : (
															<Avatar
																bg='#AFAFAF'
																size={'30px'}
																alignSelf={'center'}
																_text={{
																	textTransform: 'uppercase',
																	fontSize: { lg: '12px', base: '10px' },
																}}
															>
																{item.musicianName[0]}
															</Avatar>
														)}
														<Heading
															alignSelf={'center'}
															textTransform={'capitalize'}
															fontFamily={'Poppins'}
															fontSize={{ lg: '12px', base: '10px' }}
															fontWeight={'medium'}
															textAlign={'left'}
															color={'#afafaf'}
														>
															{item.isMySplit ? '(ME)' : item?.musicianName}
														</Heading>
													</HStack>

													<Heading
														alignSelf={'center'}
														textTransform={'capitalize'}
														fontFamily={'Poppins'}
														fontSize={{ lg: '12px', base: '10px' }}
														fontWeight={'medium'}
														textAlign={'left'}
														color={'#afafaf'}
													>
														{item.split + '%'}
													</Heading>
												</HStack>
											))
										) : (
											<Heading
												my={5}
												textTransform={'capitalize'}
												fontFamily={'Poppins'}
												fontSize={{
													xl: '12px',
													lg: '12px',
													md: '12px',
													base: '12px',
												}}
												fontWeight={'medium'}
												textAlign={'left'}
												color={'#fff'}
											>
												No collaborators
											</Heading>
										)}
									</Box>
								</Box>
							</Menu.Item>
						</Menu>
					</HStack>
				</HStack>
			</Box>
			<TermAndCondition
				openModal={modal}
				closeModal={handleCloseModal}
				handleDownload={handleDownload}
				isCustom={data.isCustomTermsAndCodition}
				termsAndCondition={data.termsAndCondition}
			/>
		</Box>
	);
};
