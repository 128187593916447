import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectMain } from "./SelectStyles";

const SelectDropDown = () => {
  const [age, setAge] = React.useState(1);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <SelectMain>
      <FormControl fullWidth>
        <Select
          className="select-box"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value={1}>Monthly</MenuItem>
          <MenuItem value={2}>weekly</MenuItem>
          <MenuItem value={3}>Yearly</MenuItem>
        </Select>
      </FormControl>
    </SelectMain>
  );
};
export default SelectDropDown;
