import React, { useState, useEffect } from 'react';
import { Box, Button, Heading, HStack, Menu } from 'native-base';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import ProgressTags from './ProgressTags';
import { AddStem } from '../Svgs/AddStem';
import { MusicFile } from '../Svgs/MusicFile';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import styles from '../styles.module.css';
import { DeleteSoundPack } from '../Modals/DeleteSoundPack';

const UploadSoundKitsFile = (props) => {
	const { setStep, uploadData, setUploadData, edit, editData } = props;
	const headerkey = `Bearer ${localStorage.getItem('token')}`;
	const navigate = useNavigate();
	const [deletePopup, setDeletePopup] = useState(false);
	const [deleteName, setDeleteName] = useState('');
	const [fileName, setFileName] = useState('');
	const [selectedFile, setSelectedFile] = useState('');
	const [fileNameStem, setFileNameStem] = useState('');
	const [selectedFileStem, setSelectedFileStem] = useState('');
	const [isLoadingAudio, setIsLoadingAudio] = useState(false);
	const [loadingProgress, setLoadingProgress] = useState(0);
	const [isLoadingZip, setIsLoadingZip] = useState(false);

	const handleCancel = () => {
		// setUploadData(
		//   ({
		//     soundPackCover,
		//     soundPackTitle,
		//     soundPackKit,
		//     soundPackKey,
		//     soundPackBPM,
		//     soundPackDescription,
		//     ...updatedSoundPack
		//   }) => updatedSoundPack
		// );
		// console.log('cancele.....', uploadData);
		// setStep(2);
		navigate('/soundPack');
	};
	const HandleNext = () => {
		if (selectedFile == '' && selectedFileStem == '') {
			toast.error('Please upload a valid audio file or steam file');
		} else {
			setUploadData({
				...uploadData,
				soundPackZipFile: selectedFileStem,
				soundPackFile: selectedFile,
			});
			setStep(3);
		}
	};
	const onDrop = (acceptedFiles) => {
		const fileSizeLimitInMB = 15;
		const allowedFileSize = fileSizeLimitInMB * 1024 * 1024;
		const marginOfError = 1024 * 1024;
		console.log('fileaccepted...:', acceptedFiles);
		acceptedFiles.forEach((file) => {
			const isZipFile = file.name.slice(-3) === 'zip' || file.name.slice(-3) === 'rar';
			if (file.size >  allowedFileSize + marginOfError) {
				toast.error(`File size should not exceed ${fileSizeLimitInMB} MB`);
				return;
			}
			var data = new FormData();
			data.append('file', file);
			data.append('type', 'Public');

			if (isZipFile) {
				setIsLoadingZip(true);
			  } else {
				setIsLoadingAudio(true);
			  }

			var config = {
				method: 'post',
				url: `${process.env.REACT_APP_FILE_URL}/file/soundpackUpload`,
				headers: { Authorization: headerkey },
				data: data,
				onUploadProgress: (progressEvent) => {
					const progress = (progressEvent.loaded / progressEvent.total) * 100;
					setLoadingProgress(progress);
				},
			};

			axios(config)
				.then(function (response) {
					console.log('response....', response);
					if (file.name.slice(-3) === 'zip' || file.name.slice(-3) === 'rar') {
						setFileNameStem(file.name);
						setSelectedFileStem(response.data.file.url);
					} else {
						setFileName(file.name);
						setSelectedFile(response.data.file.url);
					}

					toast.success('File Uploaded Successfully');
					setLoadingProgress(0);
					if (isZipFile) {
						setIsLoadingZip(false);
					  } else {
						setIsLoadingAudio(false);
					  }
				})
				.catch(function (error) {
					console.log(error);
					if (isZipFile) {
						setIsLoadingZip(false);
					  } else {
						setIsLoadingAudio(false);
					  }
					setLoadingProgress(0);
					if (error?.response?.status === 401) {
						localStorage.clear();
						navigate('/signin');
					} else {
						toast.error('Failed to file Uploaded ');
					}
				});
		});
	};

	
	const zipDropzone = useDropzone({
		multiple: false,
		accept: {
			'application/zip': ['.zip'],
			'application/x-rar-compressed': ['.rar'],
		},
		onDrop,
	});
	const Mp3Dropzone = useDropzone({
		multiple: false,
		accept: {
			'audio/mp3': ['.mp3', '.MP3']
		},
		onDrop,
	});
	useEffect(() => {
		if (edit) {
			setSelectedFile(editData?.soundPackFile);
			setFileName(
				editData?.soundPackFile
					? editData?.soundPackFile.substring(editData.soundPackFile.lastIndexOf('/') + 1)
					: ''
			);
			setSelectedFileStem(editData?.soundPackZipFile);
			setFileNameStem(
				editData?.soundPackZipFile
					? editData?.soundPackZipFile.substring(editData.soundPackZipFile.lastIndexOf('/') + 1)
					: ''
			);
		}
	}, [editData]);
	return (
		<Box w={'100%'} >
			<Box w={'100%'} px={'3%'} alignSelf={'center'}>
				<ProgressTags index={2} />

				<Box
					w={{ lg: '80%', base: '90%' }}
					alignSelf={'center'}
					my={{ lg: 10, base: 5 }}
				>
					
						<HStack
							width={'100%'}
							justifyContent={'space-between'}
							flexWrap={'wrap'}
							flexGrow={1}
						>
							{fileNameStem == '' ? (
								<Box width={{ lg: '48%', base: '100%' }}>
									{isLoadingZip ? (
						<div className={styles.loaderProfile}>
							<Box position={'relative'} display={'inline-flex'}>
								<CircularProgress
									variant='determinate'
									value={loadingProgress}
									size={80}
									sx={{
										color: '#2ebb55',
									}}
								/>
								<Box
									top={0}
									left={0}
									bottom={0}
									right={0}
									position={'absolute'}
									display={'flex'}
									alignItems={'center'}
									justifyContent={'center'}
								>
									<Heading
										fontFamily={'Poppins'}
										fontWeight={400}
										fontSize={'15px'}
										textAlign={'center'}
										color={'#afafaf'}
									>
										{Math.round(loadingProgress) + '%'}
									</Heading>
								</Box>
							</Box>
						</div>
					):(<><Button
						variant={'ghost'}
						alignSelf={'center'}
						w={{ '2xl': '40%', lg: '60%', base: '100%' }}
						px={8}
						py={2}
						my={{ lg: 10, base: 5 }}
						borderWidth={1}
						borderColor={'#afafaf'}
						borderStyle={'dashed'}
						rounded={'md'}
						_hover={{ bg: 'transparent' }}
						_pressed={{ bg: '#2ebb5550' }}
					>
						<div
							{...zipDropzone.getRootProps()}
							className={styles.audioDropzone}
						>
							<Box alignSelf={'center'}>
								<AddStem />
							</Box>
							<Heading
								fontFamily={'Poppins'}
								fontWeight={700}
								fontSize={'20px'}
								textAlign={'center'}
								color={'#afafaf'}
								py={3}
								alignSelf={'center'}
								w={'85%'}
							>
								Upload Stem Files
							</Heading>
							<input
								{...zipDropzone.getInputProps()}
								id='uploadAudio'
								accept='.zip,.rar'
							/>
						</div>
					</Button></>)}
									
									<Heading
										fontFamily={'Poppins'}
										fontWeight={600}
										fontSize={{
											'2xl': '40px',
											xl: '36px',
											lg: '32px',
											base: '22px',
										}}
										lineHeight={{
											'2xl': '60px',
											xl: '50x',
											lg: '40px',
											base: '30px',
										}}
										textAlign={'center'}
										color={'#fff'}
										mt={{ lg: 5, base: 2 }}
										py={3}
										alignSelf={'center'}
										w={'95%'}
									>
										Upload Zip Files
									</Heading>
									<Heading
										fontFamily={'Poppins'}
										fontWeight={400}
										fontSize={{ lg: '12px', base: '10px' }}
										lineHeight={{ lg: '18px', base: '16px' }}
										textAlign={'center'}
										color={'#afafaf'}
										alignSelf={'center'}
										w={'95%'}
									>
										Add a ZIP file containing your track stems to allow an
										artist to create songs using individual sounds from your
										beat. Your selected license will automatically be updated to
										include use of stems.
									</Heading>
								</Box>
							) : (
								<Box width={{ lg: '48%', base: '100%' }}>
									<Displayfile
										fileName={fileNameStem}
										edit={edit}
										setDeletePopup={(data) => setDeletePopup(data)}
										setDeleteName={(data) => setDeleteName(data)}
										stem={true}
									/>
								</Box>
							)}
							{fileName == '' ? (
								<Box width={{ lg: '48%', base: '100%' }}>
									{isLoadingAudio ? (
						<div className={styles.loaderProfile}>
							<Box position={'relative'} display={'inline-flex'}>
								<CircularProgress
									variant='determinate'
									value={loadingProgress}
									size={80}
									sx={{
										color: '#2ebb55',
									}}
								/>
								<Box
									top={0}
									left={0}
									bottom={0}
									right={0}
									position={'absolute'}
									display={'flex'}
									alignItems={'center'}
									justifyContent={'center'}
								>
									<Heading
										fontFamily={'Poppins'}
										fontWeight={400}
										fontSize={'15px'}
										textAlign={'center'}
										color={'#afafaf'}
									>
										{Math.round(loadingProgress) + '%'}
									</Heading>
								</Box>
							</Box>
						</div>
					):(<><Button
						variant={'ghost'}
						alignSelf={'center'}
						w={{ '2xl': '40%', lg: '60%', base: '100%' }}
						px={8}
						py={2}
						my={{ lg: 10, base: 5 }}
						borderWidth={1}
						borderColor={'#afafaf'}
						borderStyle={'dashed'}
						rounded={'md'}
						_hover={{ bg: 'transparent' }}
						_pressed={{ bg: '#2ebb5550' }}
					>
						<div
							{...Mp3Dropzone.getRootProps()}
							className={styles.audioDropzone}
						>
							<Box alignSelf={'center'}>
								<MusicFile />
							</Box>
							<Heading
								fontFamily={'Poppins'}
								fontWeight={700}
								fontSize={'20px'}
								textAlign={'center'}
								color={'#afafaf'}
								py={3}
								alignSelf={'center'}
								w={'70%'}
							>
								Upload MP3 Files
							</Heading>
							<input
								{...Mp3Dropzone.getInputProps()}
								id='uploadAudio'
								accept='audio/*'
							/>
						</div>
					</Button></>)}
									
									<Heading
										fontFamily={'Poppins'}
										fontWeight={600}
										fontSize={{
											'2xl': '40px',
											xl: '36px',
											lg: '32px',
											base: '22px',
										}}
										lineHeight={{
											'2xl': '60px',
											xl: '50x',
											lg: '40px',
											base: '30px',
										}}
										textAlign={'center'}
										color={'#fff'}
										mt={{ lg: 5, base: 2 }}
										py={3}
										alignSelf={'center'}
										w={'95%'}
									>
										MP3 Files
									</Heading>
									<Heading
										fontFamily={'Poppins'}
										fontWeight={400}
										fontSize={{ lg: '12px', base: '10px' }}
										lineHeight={{ lg: '18px', base: '16px' }}
										textAlign={'center'}
										color={'#afafaf'}
										alignSelf={'center'}
										w={'95%'}
									>
										Upload a audio file for your beat. MP3 files are
										automatically converted to a watermarked MP3 if you choose
										to make a free download available
									</Heading>
								</Box>
							) : (
								<Box width={{ lg: '48%', base: '100%' }}>
									<Displayfile
										fileName={fileName}
										edit={edit}
										setDeletePopup={(data) => setDeletePopup(data)}
										setDeleteName={(data) => setDeleteName(data)}
										stem={false}
									/>
								</Box>
							)}
						</HStack>
					
					{/* <Box>
               <Box alignSelf={'center'} my={8}>
                  {fileName.slice(-3) === 'zip' ||
                  fileName.slice(-3) === 'rar' ? (
                    <AddStem />
                  ) : (
                    <MusicFile />
                  )}
                </Box>
                <HStack space={10} alignSelf={'center'}>
                  <Heading
                    fontFamily={'Poppins'}
                    fontWeight={500}
                    fontSize={'20px'}
                    textAlign={'center'}
                    color={'#fff'}
                    py={5}
                    alignSelf={'center'}
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    maxW={'70%'}
                  >
                    {fileName}
                  </Heading>
                  {edit ? (
                    <Button
                      p={2}
                      borderColor={'afafaf'}
                      borderWidth={1}
                      bg={'transparent'}
                      _pressed={{ bg: 'transparent' }}
                      _hover={{ bg: 'transparent' }}
                      onPress={() => {
                        setDeletePopup(true);
                      }}
                    >
                      <CloseIcon sx={{ color: '#afafaf', fontSize: '30px' }} />
                    </Button>
                  ) : null}
                </HStack>
              </Box> */}
					<HStack
						alignSelf={'center'}
						w={{ lg: '60%', base: '100%' }}
						my={{ '2xl': 40, lg: 20, base: 10 }}
						justifyContent={'space-between'}
					>
						<Button
							w={'45%'}
							variant={'ghost'}
							_hover={{
								backgroundColor: '#2EBA55',
								_text: {
									color: '#fff',
								},
							}}
							_pressed={{
								bg: '#2EBB55',
								_text: {
									color: '#fff',
								},
							}}
							_focus={{ bg: 'transparent' }}
							// mt={10}
							p={0}
							py={2.5}
							borderColor='#2EBA55'
							borderWidth={1}
							alignSelf={'center'}
							_text={{
								color: '#2ebb55',
								fontWeight: 'medium',
								fontSize: { xl: '18px', lg: '18px', md: '16px', base: '14px' },
								fontFamily: 'Poppins',
							}}
							onPress={() => handleCancel()}
						>
							CANCEL
						</Button>
						<Button
							w={'45%'}
							borderColor='#2EBA55'
							borderWidth={1}
							_pressed={{
								bg: '#2EBB55',
								_text: {
									color: '#fff',
								},
							}}
							backgroundColor='#2EBA55'
							py={2.5}
							_text={{
								color: '#fff',
								fontSize: { xl: '18px', lg: '18px', md: '16px', base: '14px' },
								fontWeight: 'medium',
								textTransform: 'capitalize',
								fontFamily: 'Poppins',
							}}
							_hover={{
								backgroundColor: 'transparent',
								_text: {
									color: '#2EBA55',
								},
							}}
							alignSelf={'center'}
							onPress={() => HandleNext()}
						>
							NEXT
						</Button>
					</HStack>
				</Box>
			</Box>
			<DeleteSoundPack
				openModal={deletePopup}
				closeModal={() => setDeletePopup(false)}
				deleteFtn={() => {
					if (deleteName == fileName) {
						setFileName('');
						setSelectedFile('');
						toast.success('File deleted successfully');
					} else {
						setFileNameStem('');
						setSelectedFileStem('');
						toast.success('File deleted successfully');
					}
				}}
				desc={'Are you sure you want to delete?'}
			/>
		</Box>
	);

	function Displayfile(props) {
		const { fileName, edit, setDeletePopup, setDeleteName, stem } = props;
		return (
			<Box w={'100%'}>
				<Box alignSelf={'center'} my={8}>
					{stem ? <AddStem /> : <MusicFile />}
				</Box>
				<HStack
					space={10}
					alignSelf={'center'}
					w={'100%'}
					justifyContent={'center'}
				>
					<Heading
						fontFamily={'Poppins'}
						fontWeight={500}
						fontSize={'20px'}
						textAlign={'center'}
						color={'#fff'}
						py={5}
						alignSelf={'center'}
						style={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
						maxW={'80%'}
					>
						{fileName}
					</Heading>

					<Button
						p={2}
						// borderColor={'afafaf'}
						// borderWidth={1}
						bg={'transparent'}
						_pressed={{ bg: 'transparent' }}
						_hover={{ bg: 'transparent' }}
						onPress={() => {
							setDeletePopup(true);
							setDeleteName(fileName);
						}}
					>
						<CloseIcon sx={{ color: '#afafaf', fontSize: '25px' }} />
					</Button>
				</HStack>
			</Box>
		);
	}
};

export default UploadSoundKitsFile;
