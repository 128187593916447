import React, { useEffect } from "react";
import styles from "../distribution.module.css";

const DistributeCalculate = () => {
  // Load scripts dynamically
  useEffect(() => {
    // Config
    window.GET_FUNDED_API_URL = "https://api.beatbread.com";
    // window.WIDGET_HEADLINE = "See funding options";

    // Scripts
    const scriptChunk = document.createElement("script");
    scriptChunk.src = "https://widget.beatbread.com/static/js/chunk.js";
    document.body.appendChild(scriptChunk);

    const scriptMain = document.createElement("script");
    scriptMain.src = "https://widget.beatbread.com/static/js/main.chunk.js";
    document.body.appendChild(scriptMain);

    const scriptRuntime = document.createElement("script");
    scriptRuntime.src =
      "https://widget.beatbread.com/static/js/runtime-main.js";
    document.body.appendChild(scriptRuntime);

    // Clean-up
    return () => {
      document.body.removeChild(scriptChunk);
      document.body.removeChild(scriptMain);
      document.body.removeChild(scriptRuntime);
    };
  }, []);

  return (
    <div className={styles.sub_page_layout}>
      {/* Links and Styles */}
      <link
        href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <link
        href="https://widget.beatbread.com/static/css/chunk.css"
        rel="stylesheet"
      />
      <link
        href="https://widget.beatbread.com/static/css/main.chunk.css"
        rel="stylesheet"
      />
      <style>
        {`
          .getFundedContainer {
            margin: 0; /* Override form container style here */
          
          }
          .getFundedHeaderText {
            /* Add style for headline */
            /* Overwrite existing with !important */
            
          }
        `}
      </style>

      {/* Widget */}
      <div id="getFunded" className={styles.container}></div>
    </div>
  );
};

export default DistributeCalculate;
