import React, { useState, useEffect, useRef } from "react";
import { Loader } from "../Loader";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import styles from "./styles.module.css";
import { Avatar, Button, Heading, HStack, Image, VStack } from "native-base";
import { EditIcon } from "./Svgs/EditIcon";
import { DeleteIcon } from "./Svgs/DeleteIcon";
import {
  deleteMySoundPack,
  getMySoundPacks,
  previewOfCollaborators,
} from "../../Graphql_services/SoundPackMutation";
import { DeleteSoundPack } from "./Modals/DeleteSoundPack";
import { SOUNDPACKS_TABS } from "../../shared/constants";
import Pagination from "../Pagination/Pagination";
import PurchaseSoundCard from "./PurchaseSoundCard";
const MySoundKit = () => {
  const navigate = useNavigate();
  const [soundData, setSoundData] = useState([]);
  const [soundId, setSoundId] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(SOUNDPACKS_TABS[0]);
  const [type, setType] = useState(SOUNDPACKS_TABS[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPagePurchase, setCurrentPagePurchase] = useState(1);
  const [totalCountPurchase, setTotalCountPurchase] = useState(0);

  const tabsRef = useRef(null);
  const underlineRef = useRef(null);

  const [GetMySoundPacks] = useLazyQuery(getMySoundPacks, {
    context: { clientName: "soundpack" },
    fetchPolicy: "no-cache",
    variables: { pageNO: currentPage },
    onCompleted(data) {
      const { getMySoundPacks } = data;

      if (getMySoundPacks) {
        const { isTokenExpired, success, message } = getMySoundPacks;
        if (!isTokenExpired) {
          if (success) {
            console.log("asdasdasdasdasd");
            setSoundData(getMySoundPacks?.soundpacks);
            console.log(getMySoundPacks?.soundpacks.length, "length");
            setTotalCount(getMySoundPacks?.soundPacksPages);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            //toast.error(message);
          }
        } else {
          setIsLoading(false);
          localStorage.clear();
          navigate("/signin");
        }
      }
    },
    onError(err) {
      console.log("error on getting user info", err);
    },
  });
  const [DeleteMySoundPack] = useMutation(deleteMySoundPack, {
    context: { clientName: "soundpack" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const { deleteMySoundPack } = data;
      if (deleteMySoundPack) {
        const { isTokenExpired, success, message } = deleteMySoundPack;
        if (!isTokenExpired) {
          if (success) {
            setIsLoading(false);

            //GetMySoundPacks();
            setSoundData((prevItems) =>
              prevItems.filter((item) => item._id !== soundId)
            );
            setSoundId("");
            setDeletePopup(false);
            toast.success(message);
          } else {
            toast.error(message);
            setDeletePopup(false);
          }
        } else {
          setIsLoading(false);
          localStorage.clear();
          navigate("/signin");
        }
      }
    },
    onError(err) {
      console.log("error on getting sound pack deleted", err);
    },
  });
  useEffect(() => {
    setIsLoading(true);
    GetMySoundPacks();
  }, [currentPage]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   GetMySoundPacks();
  // }, [currentPagePurchase]);

  useEffect(() => {
    const tabEl = [...tabsRef.current.children].find(
      (child) => child.textContent === activeTab
    );
    const { offsetLeft, offsetWidth } = tabEl;
    underlineRef.current.style.left = `${offsetLeft}px`;
    underlineRef.current.style.width = `${offsetWidth}px`;
  }, [activeTab]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handlePurchasePageChange = (newPage) => {
    setCurrentPagePurchase(newPage);
  };

  return (
    <Grid className={styles.mainContainer} px={"3%"} py={"4%"}>
      <Grid container rowSpacing={5}>
        <Grid item xs={12} className={styles.headingDiv}>
          <Heading
            alignSelf={"center"}
            textTransform={"capitalize"}
            fontFamily={"Poppins"}
            fontSize={{
              xl: "40px",
              lg: "36px",
              md: "30px",
              base: "24px",
            }}
            fontWeight={500}
            textAlign={"left"}
            color={"#fff"}
          >
            {activeTab === SOUNDPACKS_TABS[0]
              ? "My Sound Packs"
              : "Purchased Sound Packs"}
          </Heading>
        </Grid>
        <div className={styles.tabs} ref={tabsRef}>
          {SOUNDPACKS_TABS.map((tab) => (
            <div
              key={tab}
              className={`${styles.tab} ${activeTab === tab && styles.active}`}
              onClick={() => {
                setActiveTab(tab);
                setType(type === tab ? "" : tab);
              }}
            >
              {tab}
            </div>
          ))}
          <div className={styles.underline} ref={underlineRef} />
        </div>
        <div className={isLoading ? styles.loader_wrapper : styles.tab_layout}>
          {isLoading ? (
            <Grid className={styles.loader}>
              <Loader />
            </Grid>
          ) : (
            <>
              {activeTab === SOUNDPACKS_TABS[0] ? (
                <>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid container className={styles.tabMain}>
                        <Grid item xs={4}>
                          <p className={styles.tabHeading}>Kit Details</p>
                        </Grid>
                        <Grid item xs={3}>
                          <p className={styles.tabHeading}>Tags</p>
                        </Grid>
                        <Grid item xs={5}>
                          <p className={styles.tabHeading}>
                            Collaborators Details
                          </p>
                        </Grid>
                      </Grid>
                      {soundData?.length > 0 ? (
                        soundData.map((item, index) => (
                          <Grid
                            key={index}
                            container
                            className={styles.tabContent}
                          >
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={12}
                              xs={12}
                              width={"100%"}
                              className={styles.soundPackBasic}
                            >
                              {/* <HStack w={'100%'} space={4}> */}
                              <img
                                src={item.soundPackCover}
                                className={styles.soundPackCover}
                                alt={"cover"}
                              />
                              {/* <VStack alignSelf={'center'}> */}
                              <div>
                                <Heading
                                  mb={2}
                                  textTransform={"capitalize"}
                                  fontFamily={"Poppins"}
                                  fontSize={{ lg: "18px", base: "14px" }}
                                  fontWeight={"normal"}
                                  textAlign={"left"}
                                  color={"#fff"}
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item.soundPackTitle}
                                </Heading>
                                <br />
                                <Heading
                                  my={1}
                                  textTransform={"capitalize"}
                                  fontFamily={"Poppins"}
                                  fontSize={{ lg: "16px", base: "12px" }}
                                  fontWeight={"normal"}
                                  textAlign={"left"}
                                  color={"#afafaf"}
                                >
                                  {item.soundPackKitType}
                                </Heading>
                                <br />
                                <Heading
                                  my={1}
                                  textTransform={"capitalize"}
                                  fontFamily={"Poppins"}
                                  fontSize={{ lg: "16px", base: "12px" }}
                                  fontWeight={"normal"}
                                  textAlign={"left"}
                                  color={"#afafaf"}
                                >
                                  {item.soundPackBPM}
                                </Heading>
                                <br />
                                <Heading
                                  my={1}
                                  textTransform={"capitalize"}
                                  fontFamily={"Poppins"}
                                  fontSize={{ lg: "16px", base: "12px" }}
                                  fontWeight={"normal"}
                                  textAlign={"left"}
                                  color={"#afafaf"}
                                >
                                  {item.soundPackKey}
                                </Heading>
                                <br />
                                <Heading
                                  my={1}
                                  textTransform={"capitalize"}
                                  fontFamily={"Poppins"}
                                  fontSize={{ lg: "16px", base: "12px" }}
                                  fontWeight={"normal"}
                                  textAlign={"left"}
                                  color={"#2EBB55"}
                                >
                                  ${item.soundPackPrice}
                                </Heading>
                              </div>
                              {/* </VStack> */}
                              {/* </HStack> */}
                            </Grid>
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                              <HStack flexWrap={"wrap"}>
                                {item.tags.map((tag, index) => (
                                  <Button
                                    key={index}
                                    variant={"ghost"}
                                    _hover={{
                                      bg: "transparent",
                                      borderColor: "#afafaf",
                                      _text: { color: "#afafaf" },
                                    }}
                                    _pressed={{ bg: "transparent" }}
                                    minW={{
                                      "2xl": "75px",
                                      lg: "65px",
                                      base: "50px",
                                    }}
                                    _text={{
                                      fontFamily: "Poppins",
                                      fontWeight: 400,
                                      fontSize: {
                                        "2xl": "16px",
                                        lg: "14px",
                                        base: "10px",
                                      },
                                      color: "#afafaf",
                                    }}
                                    borderWidth={1}
                                    borderColor={"#afafaf"}
                                    p={1}
                                    my={1}
                                    mr={{ lg: 2, base: 2 }}
                                    textAlign={"center"}
                                    rounded={"md"}
                                  >
                                    {tag.value}
                                  </Button>
                                ))}
                              </HStack>
                            </Grid>
                            <Grid item lg={5} md={5} sm={12} xs={12}>
                              <Grid container columnSpacing={3}>
                                <Grid item xs={8}>
                                  <Collaborator
                                    collabData={item.collaborators}
                                  />
                                </Grid>
                                <Grid item xs={3} className={styles.iconRow}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    className={styles.iconRow}
                                  >
                                    <Button
                                      variant={"ghost"}
                                      _hover={{
                                        bg: "transparent",
                                      }}
                                      _pressed={{ bg: "transparent" }}
                                      p={1}
                                      my={1}
                                      onPress={() =>
                                        navigate(`/soundPack/edit/${item._id}`)
                                      }
                                    >
                                      <EditIcon />
                                    </Button>
                                    <Button
                                      variant={"ghost"}
                                      _hover={{
                                        bg: "transparent",
                                      }}
                                      _pressed={{ bg: "transparent" }}
                                      p={1}
                                      my={1}
                                      onPress={() => {
                                        setDeletePopup(true);
                                        setSoundId(item._id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <p className={styles.noKit}>No sound kits available</p>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Grid container>
                      {/* {soundData?.length > 0 ? (
              soundData.map((item, index) => (
                <PurchaseSoundCard 
                data={item}
                soundId={soundId}
                setSoundId={setSoundId}
                key={index}
                />
              ))
            ) : ( */}
                      <p className={styles.noKit}>
                        No Purchased Sound Packs available
                      </p>
                      {/* )} */}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </div>
      </Grid>
      {activeTab === SOUNDPACKS_TABS[0] && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalCount}
          onPageChange={handlePageChange}
        />
      )}
      {/* {activeTab === SOUNDPACKS_TABS[1] && <Pagination
      currentPage={currentPagePurchase}
      totalPages={totalCountPurchase}
      onPageChange={handlePurchasePageChange}
    />} */}

      <DeleteSoundPack
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        deleteFtn={async () =>
          await DeleteMySoundPack({
            variables: {
              soundPackId: soundId,
            },
          })
        }
        desc={"Are you sure you want to delete the soundpack?"}
      />
    </Grid>
  );

  function Collaborator(props) {
    const { collabData } = props;
    const [collaboratorData, setCollaboratorData] = useState([]);

    const [PreviewOfCollaborators] = useLazyQuery(previewOfCollaborators, {
      context: { clientName: "soundpack" },
      fetchPolicy: "no-cache",
      onCompleted(data) {
        console.log("previewOfCollaborators", data);
        const { previewOfCollaborators } = data;
        if (previewOfCollaborators) {
          const { isTokenExpired, success, message } = previewOfCollaborators;
          if (!isTokenExpired) {
            if (success) {
              setCollaboratorData(previewOfCollaborators.collaborators);
            } else {
              toast.error(message);
            }
          } else {
            localStorage.clear();
            navigate("/signin");
          }
        }
      },
      onError(err) {
        console.log("error on getting user info", err);
      },
    });
    useEffect(() => {
      if (collabData.length > 0) {
        PreviewOfCollaborators({
          variables: {
            collaborators: collabData.map(({ __typename, ...rest }) => rest),
          },
        });
      }
    }, []);

    return (
      <VStack alignSelf={"center"}>
        {collaboratorData.length > 0 ? (
          collaboratorData.map((item, index) => (
            <HStack
              key={index}
              w={"100%"}
              justifyContent={"space-between"}
              my={1}
            >
              <HStack space={3} w={"45%"} overflow={"hidden"}>
                {item.profilePic ? (
                  <Image
                    source={item?.profilePic}
                    size={{ lg: "30px", base: "24px" }}
                    rounded={"full"}
                    alignSelf={"center"}
                    alt={"profile"}
                  />
                ) : (
                  <Avatar
                    bg="#AFAFAF"
                    size={{ lg: "30px", base: "24px" }}
                    alignSelf={"center"}
                    _text={{
                      textTransform: "uppercase",
                      fontSize: {
                        "2xl": "18px",
                        xl: "16px",
                        lg: "14px",
                        base: "12px",
                      },
                    }}
                  >
                    {item?.musicianName[0]}
                  </Avatar>
                )}
                <Heading
                  alignSelf={"center"}
                  textTransform={"capitalize"}
                  fontFamily={"Poppins"}
                  fontSize={{
                    "2xl": "16px",
                    xl: "14px",
                    lg: "12px",
                    base: "12px",
                  }}
                  fontWeight={"medium"}
                  textAlign={"left"}
                  color={"#afafaf"}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                  }}
                >
                  {item.isMySplit ? "(ME)" : item?.musicianName}
                </Heading>
              </HStack>
              <Heading
                alignSelf={"center"}
                textTransform={"capitalize"}
                fontFamily={"Poppins"}
                fontSize={{
                  "2xl": "16px",
                  xl: "14px",
                  lg: "12px",
                  base: "12px",
                }}
                fontWeight={"medium"}
                textAlign={"left"}
                color={"#afafaf"}
                w={"35%"}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                }}
              >
                {item?.roleId?.roleName}
              </Heading>
              <Heading
                alignSelf={"center"}
                textTransform={"capitalize"}
                fontFamily={"Poppins"}
                fontSize={{
                  "2xl": "16px",
                  xl: "14px",
                  lg: "12px",
                  base: "12px",
                }}
                fontWeight={"medium"}
                textAlign={"left"}
                color={"#afafaf"}
                w={"11%"}
              >
                {item?.split + "%"}
              </Heading>
            </HStack>
          ))
        ) : (
          <Heading
            fontFamily={"Poppins"}
            fontSize={{
              xl: "20px",
              lg: "20px",
              md: "18px",
              base: "16px",
            }}
            fontWeight={"medium"}
            textAlign={"left"}
            color={"#afafaf"}
          >
            No collaborators
          </Heading>
        )}
      </VStack>
    );
  }
};

export default MySoundKit;
