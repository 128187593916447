import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import { useMutation } from "@apollo/client";
import {
  SignUpMutation,
  SetRole,
  verifyOTP,
} from "../../../Graphql_services/SignUpMutation";
import useCheckMutation from "../../Hooks/Authentication/CheckE/useCheckMutation";
import Genre from "./Genre";
import { Box, Text, HStack, VStack, Button } from "native-base";
import { AuthContext } from "../Layout";
import AccountType from "../AccountType";
import Validations from "../../Validations/Validations";
import { Notification } from "../../NotifyMsg/Notification";
import ButtonComponent from "../../UniversalComponents/ButtonComponent";
import useEmailPhoneNoApi from "../../../GraphqlApis/SignupForm/useEmailPhoneNoApi";
import Textfield from "../../UniversalComponents/Textfield";
import FormTitle from "../FormTitle";
import Subscription from "../Subscription/PreSubscription";
import UploadProfile from "./UploadProfile";
import { useBreakpointValue } from "native-base";
import useAttachPlanMutation from "../../Hooks/Authentication/AttachPlan/useAttachPlanMutation";
import { Publishing } from "../../../Graphql_services/UsersMutation";
import InterestedInPublishing from "../Subscription/InterestedInPulising";
const LoginForm = (props) => {
  const navigate = useNavigate(null);
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });
  const text = [
    "User Type",
    "Email Information",
    "Phone Number Information",
    "OTP Verification",
    "Password",
    "Social Handle",
    "Add Your Profile Picture",
    "How did you hear abour us?",
  ];
  const { email_phoneNoValidation, ValidatePassword } = Validations();
  const { sendOtp } = useEmailPhoneNoApi();
  const { attachPlan, attachPlan_HowDoYouKnow } = useAttachPlanMutation();
  const { isEmailExists } = useCheckMutation();
  //   const [countdown, setcountdown] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    activeStep,
    setActiveStep,
    accountType,
    rolesID,
    setOtpValue,
    setSendOtpAgain,
    isPassword,
    setIsPassword,

    Email,
    setEmail,
    instaMail,
    setInstaMail,
    Password,

    setPassword,
    NewRoleId,
    isSimpleSignup,
    userId,
    errMsg,
    setErrMsg,

    disableBtn,
    setIsDisableBtn,

    phonenum,
    selectedAccount,
    picture,
    othersSocial,
    setothersSocial,
    othersGenre,
    setothersGenre,
    genre,
    setGenre,
    setAccountType,
    username,
    setUsername,
    genreField,
    setgenreField,
    socialField,
    purchased,
    setPurchased,
    setSocialField,
    userID,
    priceID,
    progressbarvalue,
    setProgressbarValue,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    selectedPublish,
    setShowStripeForm,
  } = useContext(AuthContext);

  const isSingleField =
    activeStep === 1 ||
    activeStep === 2 ||
    activeStep === 3 ||
    activeStep === 5;
  const isDoubleField = activeStep === 4;

  const setbtndisabled = () => {
    setIsDisableBtn(false);
  };

  const [setRole] = useMutation(SetRole, {
    onCompleted(data) {
      if (data?.setRole?.success) {
        Notification("success", data.setRole.message);

        setTimeout(() => {
          setIsPassword(true);

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setbtndisabled();
        }, 1400);
      } else {
        Notification("error", data.setRole.message);
        setActiveStep(activeStep);
        setbtndisabled();
      }
    },
    onError(err) {
      const { graphQLErrors } = err;
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          Notification("error", message);
          setActiveStep(activeStep);
        });
      }
    },
  });

  const [verifyOtp] = useMutation(verifyOTP, {
    onCompleted(data) {
      if (data?.verifyOtp?.success) {
        Notification("success", data.verifyOtp.message);

        setTimeout(() => {
          setIsPassword(true);
          setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setbtndisabled();
        }, 1400);
      } else {
        Notification("error", data.verifyOtp.message);
        setActiveStep(activeStep);
        setbtndisabled();
      }
    },
    onError(err) {
      const { graphQLErrors } = err;
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          Notification("error", message);
          setActiveStep(activeStep);
        });
      }
    },
  });

  const [WouldYouLikeToPublish] = useMutation(Publishing, {
    onCompleted(data) {
      if (data?.attachDoYouPublish?.success) {
        setTimeout(() => {
          setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setbtndisabled();
        }, 1400);
      } else {
        Notification("error", data.attachDoYouPublish.message);
        setActiveStep(activeStep);
        setbtndisabled();
      }
    },
    onError(err) {
      const { graphQLErrors } = err;

      if (graphQLErrors) {
        if (graphQLErrors.length < 1) {
          setErrMsg("Select option");
        } else {
          graphQLErrors.forEach(({ message }) => {
            Notification("error", message);
            setActiveStep(activeStep);
          });
        }
      }
    },
  });
  const handlePrevious = () => {
    if (activeStep === 2) {
      setProgressbarValue((prevActiveStep) => prevActiveStep - 1);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSendOtpAgain(false);
    } else if (purchased && activeStep <= 8) {
      setPurchased(false);
      setShowStripeForm(false);
    } else if (
      (activeStep === 7 && othersGenre) ||
      (!othersGenre && othersSocial && activeStep === 10) ||
      (othersGenre && othersSocial && activeStep === 11)
    ) {
      setProgressbarValue(progressbarvalue);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setProgressbarValue((prevActiveStep) => prevActiveStep - 1);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const onSubmit = (data) => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if ((accountType && activeStep === 0) || (genre && activeStep === 6)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      genre === "Other"
        ? setProgressbarValue(progressbarvalue)
        : setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      return;
    } else if (
      (!accountType && activeStep === 0) ||
      (!genre && activeStep === 6)
    ) {
      setErrMsg(!accountType ? "select role" : !genre ? "select genre" : "");
      return;
    } else if (othersGenre && data.others && activeStep === 7) {
      setgenreField(data.others);

      setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      return;
    } else if (
      (othersGenre && !data.others && activeStep === 7) ||
      (!othersGenre && !data.others && othersSocial && activeStep === 10) ||
      (othersGenre && !data.others && othersSocial && activeStep === 11)
    ) {
      setErrMsg("field should not be empty");
      return;
    } else if (
      (!othersGenre && othersSocial && activeStep === 9) ||
      (othersGenre && othersSocial && activeStep === 10)
    ) {
      setProgressbarValue(progressbarvalue);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }
    apicall(data);
  };

  const EnterPassword = (data) => {
    if (ValidatePassword(data)) {
      setPassword(data.password);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      return true;
    }
  };

  //

  const apicall = async (data) => {
    if (data.email) {
      if (email_phoneNoValidation(data)) {
        setIsDisableBtn(true);
        isEmailExists({
          variables: {
            email: data.email,
          },
        });
        setEmail(data.email);
        return;
      }
    } else if (!data.email && activeStep === 1) {
      setErrMsg("Invalid email address");
      return;
    }

    if (phonenum.length > 7 && activeStep === 2) {
      // phonenum.length<8 &&setErrMsg("invalid phone number")
      setIsDisableBtn(true);

      sendOtp({
        variables: {
          countrycode: null,
          phoneno: phonenum ? Number(phonenum) : null,
          email: Email,
        },
      });

      return;
    } else if ((phonenum.length < 8 || !phonenum) && activeStep === 2) {
      setErrMsg("Invalid phone number");
      setIsDisableBtn(false);
      return;
    }
    if (data.otp) {
      if (data.otp > 0) {
        setIsDisableBtn(true);
        verifyOtp({
          variables: {
            otp: data.otp,
          },
        });

        setOtpValue(data.otp);
      }
      return;
    } else if (!data.otp && activeStep === 3) {
      setErrMsg("Enter valid OTP");
      return;
    }

    if (data.password && data.confirmPassword) {
      EnterPassword(data);
      return;
    } else if ((!data.password || !data.confirmPassword) && activeStep === 4) {
      setErrMsg("Password and confirm password should not be empty");
      return;
    }

    if (data.email_username) {
      setInstaMail(data.email_username);
      setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    } else if (!data.email_username && activeStep === 5) {
      setInstaMail("");
      setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      return;
    }
    if (data.username && data.firstName && data.lastName && picture) {
      setUsername(data.username);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    } else if (
      (!othersGenre && (!data.username || !picture) && activeStep === 7) ||
      (othersGenre && (!data.username || !picture) && activeStep === 8)
    ) {
      setErrMsg("All fields are required");
      return;
    }

    if (
      (!othersGenre && !othersSocial && activeStep === 9) ||
      (othersGenre && !othersSocial && activeStep === 10) ||
      (!othersGenre && othersSocial && activeStep === 10 && data.others) ||
      (othersGenre && othersSocial && activeStep === 11 && data.others)
    ) {
      if (isSimpleSignup) {
        console.log("isSimpleSignup", isSimpleSignup);
        WouldYouLikeToPublish({
          variables: {
            email: localStorage.getItem("email"),
            doYouPublish: selectedPublish,
          },
        });
        return;
      } else if (!isSimpleSignup) {
        setRole({
          variables: {
            userId: userId,
            roleId: rolesID,
            labelName: "",
            howDidYouKnow: NewRoleId,
            instaUrl: instaMail,
          },
        });
        return;
      }
    } else if (
      (!othersGenre && activeStep === 9) ||
      (othersGenre && activeStep === 10)
    ) {
      setErrMsg("Select option");
      return;
    }
  };

  const ResendOTP = () => {
    setSendOtpAgain(true);
    sendOtp({
      variables: {
        countrycode: null,
        phoneno: phonenum ? Number(phonenum) : null,
        email: Email,
      },
    });
  };

  useEffect(() => {
    setErrMsg("");
  }, [activeStep]);
  return (
    <>
      <Box px={{ xl: 1, base: 1 }} w={"100%"}>
        <div className={"outerContainer"}>
          <Box
            w={{ md: "90%", sm: "90%", base: "94%" }}
            fontFamily="poppins"
            mx={"auto"}
          >
            <Box w={"100%"} justifyContent="center">
              {isMobile && (
                <Text fontFamily={"poppins"} color={"#2EBB55"} fontSize="1rem">
                  {text[activeStep]}
                </Text>
              )}
              {(!othersGenre && activeStep === 8) ||
              (othersGenre && activeStep === 9) ? (
                ""
              ) : (
                <FormTitle
                  activeStep={activeStep}
                  color={"#000201"}
                  title={props.title}
                  subtitle={props.subtitle}
                  isMobile={isMobile}
                />
              )}

              <Box>
                {activeStep === 0 && (
                  <AccountType
                    flag={true}
                    w={{
                      xl: "181px",
                      lg: "140px",
                      md: "120px",
                      sm: "120px",
                      base: "120px",
                    }}
                    h={{
                      xl: "132px",
                      lg: "110px",
                      md: "90px",
                      sm: "80px",
                      base: "80px",
                    }}
                    borderadius={{ md: 10, sm: 5, base: 5 }}
                  />
                )}
                {activeStep === 6 && (
                  <Genre
                    w={{
                      xl: "181px",
                      lg: "140px",
                      md: "120px",
                      sm: "120px",
                      base: "120px",
                    }}
                    h={{
                      xl: "132px",
                      lg: "110px",
                      md: "90px",
                      sm: "80px",
                      base: "80px",
                    }}
                    borderadius={{ md: 10, sm: 5, base: 5 }}
                  />
                )}
                {((!othersGenre && activeStep === 9) ||
                  (othersGenre && activeStep === 10)) && (
                  <InterestedInPublishing
                    w={{
                      xl: "200px",
                      lg: "150px",
                      md: "120px",
                      sm: "120px",
                      base: "120px",
                    }}
                    h={{
                      xl: "140px",
                      lg: "120px",
                      md: "90px",
                      sm: "80px",
                      base: "80px",
                    }}
                    borderadius={{ md: 10, sm: 5, base: 5 }}
                  />
                )}
                {((!othersGenre && activeStep === 7) ||
                  (othersGenre && activeStep === 8)) && (
                  <Box
                    mt={{ xl: 10, lg: 10, md: 20, base: 120 }}
                    mb={{ xl: 10, lg: 10, md: 10, base: 0 }}
                    textAlign={"center"}
                  >
                    <UploadProfile />
                  </Box>
                )}
                {((!othersGenre && activeStep === 8) ||
                  (othersGenre && activeStep === 9)) && <Subscription />}
                {(activeStep === 0 ||
                  (!genre && activeStep === 6) ||
                  (!othersGenre && activeStep === 9) ||
                  (othersGenre && activeStep === 10)) && (
                  // (!othersGenre && !picture && activeStep === 7) ||
                  // (othersGenre && !picture && activeStep === 8)
                  <Text
                    textTransform="capitalize"
                    color="#FF0000"
                    fontWeight={"normal"}
                    fontFamily="poppins"
                    fontSize={{ lg: "1.1rem", md: "0.9rem", base: "0.9rem" }}
                  >
                    {errMsg}
                  </Text>
                )}
                {(isSingleField || // otp ,insta
                  isDoubleField || //email phone password confirm password
                  (othersGenre && activeStep === 7) || //othersgenre
                  (!othersGenre && activeStep === 7) || //username
                  (othersGenre && activeStep === 8) || // //username
                  (!othersGenre && othersSocial && activeStep === 10) ||
                  (othersGenre && othersSocial && activeStep === 11) ||
                  (activeStep >= 8 && accountType === "Label")) && (
                  <div className="textfields">
                    <VStack space={8}>
                      <Box>
                        {(!othersGenre && activeStep === 7) || //username
                        (othersGenre && activeStep === 8) ? (
                          <>
                            <Textfield
                              color={"#E3E3E3"}
                              label={props.label}
                              control={control}
                              type={props.type}
                            />
                            <br />
                            <Textfield
                              color={"#E3E3E3"}
                              label={props.label1}
                              control={control}
                              type={props.type1}
                            />
                            <br />
                            <Textfield
                              color={"#E3E3E3"}
                              label={props.label2}
                              control={control}
                              type={props.type2}
                            />
                          </>
                        ) : (
                          <Textfield
                            color={"#E3E3E3"}
                            label={props.label}
                            control={control}
                            type={props.type}
                          />
                        )}
                        {(isSingleField ||
                          (!othersGenre &&
                            (!picture || !username) &&
                            activeStep === 7) ||
                          (othersGenre &&
                            (!picture || !username) &&
                            activeStep === 8) ||
                          (othersGenre && activeStep === 7) ||
                          (!othersGenre && othersSocial && activeStep === 10) ||
                          (othersGenre &&
                            othersSocial &&
                            activeStep === 11)) && (
                          <>
                            <HStack justifyContent={"space-between"}>
                              <Text
                                fontFamily={"poppins"}
                                fontSize={{
                                  lg: "18px",
                                  md: "14px",
                                  base: "12px",
                                }}
                                color="#AFAFAF"
                                mt={2}
                              >
                                {props.smallText}
                              </Text>
                            </HStack>
                            <Text
                              textTransform="capitalize"
                              color="#FF0000"
                              fontWeight={"normal"}
                              fontFamily="poppins"
                              fontSize={{
                                lg: "1.1rem",
                                md: "0.9rem",
                                base: "0.9rem",
                              }}
                            >
                              {errMsg}
                            </Text>
                          </>
                        )}
                      </Box>
                      {(isDoubleField ||
                        (activeStep >= 8 && accountType === "Label")) && (
                        <Box>
                          <Textfield
                            color={"#E3E3E3"}
                            label={props.label2}
                            control={control}
                            type={props.type2}
                          />
                          <Text
                            mt={2}
                            textTransform="capitalize"
                            color="#FF0000"
                            fontWeight={"normal"}
                            fontFamily="poppins"
                            fontSize={{
                              lg: "1.1rem",
                              md: "0.9rem",
                              base: "0.9rem",
                            }}
                          >
                            {errMsg}
                          </Text>
                        </Box>
                      )}
                    </VStack>
                  </div>
                )}
                {(!othersGenre && activeStep === 8) ||
                (othersGenre && activeStep === 9) ? (
                  ""
                ) : (
                  <ButtonComponent
                    textProperties={{
                      color: "#E3E3E3",
                      fontWeight: "normal",
                      fontSize: { xl: "1.2rem", md: "1rem", base: "1rem" },
                      fontFamily: "poppins",
                      textTransform: "capitalize",
                    }}
                    height={{ lg: "45px", sm: "35px", base: "48px" }}
                    backgroundColor={"#2EBB55"}
                    isDisabled={disableBtn ? true : false}
                    mt={activeStep === 5 ? 12 : 4}
                    flag={false}
                    text={props.btnText}
                    onpress={handleSubmit(onSubmit)}
                  ></ButtonComponent>
                )}

                {activeStep === 3 && (
                  <Button
                    mt={3}
                    onPress={ResendOTP}
                    borderwidth={0}
                    backgroundColor={"transparent"}
                    _text={{
                      color: "#2EBB55",
                      fontSize: { lg: "1.1rem", md: "0.9rem", base: "0.9rem" },
                      fontFamily: "poppins",
                    }}
                  >
                    Resend OTP
                  </Button>
                )}

                {activeStep > 0 &&
                  activeStep < 11 &&
                  !(
                    (!othersGenre && activeStep === 8 && !purchased) ||
                    (othersGenre && activeStep === 9 && !purchased) ||
                    (!othersGenre && activeStep === 9) ||
                    (othersGenre && activeStep === 10)
                  ) &&
                  !isMobile &&
                  isSimpleSignup && (
                    <Text
                      fontFamily="poppins"
                      onPress={handlePrevious}
                      mt={5}
                      textAlign={"center"}
                      color={"#AFAFAF"}
                      textTransform={"uppercase"}
                      letterSpacing={{ md: "0.8", base: "0" }}
                      fontWeight="normal"
                      fontSize={{
                        md: "1.2rem",
                        sm: "0.9rem",
                        base: "0.95rem",
                      }}
                      textDecorationLine={"underline"}
                    >
                      previous step
                    </Text>
                  )}
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
      {activeStep > 0 &&
        activeStep < 11 &&
        !(
          (!othersGenre && activeStep === 8 && !purchased) ||
          (othersGenre && activeStep === 9 && !purchased) ||
          (!othersGenre && activeStep === 9) ||
          (othersGenre && activeStep === 10)
        ) &&
        isMobile &&
        isSimpleSignup && (
          <div className="previousStep">
            <Text
              fontFamily="poppins"
              onPress={handlePrevious}
              color={"#AFAFAF"}
              textTransform={"uppercase"}
              letterSpacing={{ md: "0.8", base: "0" }}
              fontWeight="normal"
              fontSize={{ md: "1.2rem", sm: "0.9rem", base: "0.95rem" }}
              textDecorationLine={"underline"}
            >
              previous step
            </Text>
          </div>
        )}
    </>
  );
};
export default LoginForm;
