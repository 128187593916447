import {
  IntegratedIcon,
  EditIcon,
  InboxSettingIcon,
  dashboard,
  link_icon,
  presentionchart,
  book,
  star,
  sms,
  trash,
  foldercloud,
  send_white
} from "../../../../assets/smartlink";

const SubMenu = [
  // {
  //   id: 1,
  //   name: "Dashboard",
  //   icon: dashboard,
  //   url: "/smart-link/dashboard",
  // },
  // {
  //   id: 1,
  //   name: "Integrate",
  //   icon: IntegratedIcon,
  //   url: "/smart-link/integrate",
  // },
  {
    id: 2,
    name: "Link",
    icon: link_icon,
    url: "/smart-link/created-link",
  },
  // {
  //   id: 2,
  //   name: "Inbox Setting",
  //   icon: InboxSettingIcon,
  //   url: "/smart-link/inbox-setting",
  // },
  // {
  //   id: 3,
  //   name: "Customization",
  //   icon: EditIcon,
  //   url: "/smart-link/customization",
  // },
  // {
  //   id: 3,
  //   name: "Board Insight",
  //   icon: presentionchart,
  //   url: "/smart-link/board-insight",
  // },
  // {
  //   id: 3,
  //   name: "Report",
  //   icon: book,
  //   url: "/smart-link/report",
  // },
];
const BeatInboxMenu = [
  {
    id: 0,
    name: "Inbox",
    icon: sms,
    url: "/beat-inbox",
  },
  {
    id: 1,
    name: "Sent",
    icon: InboxSettingIcon,
    url: "/beat-inbox/my-submissions",
  },
  {
    id: 2,
    name: "Starred Beats",
    icon: star,
    url: "/beat-inbox/starred-beats",
  },
  
  {
    id: 3,
    name: "Deleted",
    icon: trash,
    url: "/beat-inbox/deleted",
  },
  // {
  //   id: 3,
  //   name: "Get More Storage",
  //   icon: foldercloud,
  //   url: "/beat-inbox/more-storage",
  // },
];

export { SubMenu, BeatInboxMenu };
