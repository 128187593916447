import React from "react";

const ShuffleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 11.9859L3.69999 11.9926C4.30666 11.9926 4.87333 11.6926 5.20666 11.1926L9.46667 4.80597C9.80001 4.30597 10.3667 3.9993 10.9733 4.00597L14.0067 4.01931"
        stroke="#AFAFAF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.667 13.3197L14.0003 11.9863"
        stroke="#AFAFAF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.92668 5.74678L5.20667 4.74678C4.86667 4.27345 4.31999 3.99345 3.73999 4.00012L2 4.00679"
        stroke="#AFAFAF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.64648 10.2539L9.45982 11.3006C9.79982 11.7406 10.3331 12.0006 10.8931 12.0006L14.0065 11.9872"
        stroke="#AFAFAF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0003 4.01302L12.667 2.67969"
        stroke="#AFAFAF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShuffleIcon;
