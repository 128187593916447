import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Modal, Tab, Tabs, Typography } from "@mui/material";
import moment from "moment/moment";
import React, { useMemo, useState } from "react";
import { close_icon, DownloadIcon } from "../../assets/smartlink/index.js";
import CheckBox from "../smartlink/prerelease/prereleaseservices/CheckBox.js";
import {
  getExclusiveContractDetails,
  getNonExclusiveContractDetails,
  handleExclusiveDownload,
  handleNonExclusiveDownload,
} from "./helpers.js";
import useAuth from "../../context/AuthContext.js";

export function BeatsLicenseModal({
  openModal,
  closeModal,
  data,
  handleCheckout,
  setPrice,
}) {
  const {
    beatExclusivePrice,
    beatNonExclusivePrice,
    musicianName,
    publishingSplit,
    userName,
    exclusivePrice,
    nonExclusivePrice,
    audioFileId,
    opportunity,
  } = data || {};

  const { user } = useAuth();
  console.log("🚀 ~ user:", user);

  console.log("🚀 ~ data:", data);
  console.log("------- base url --------", process.env.REACT_APP_PAYMENTS_URL);

  const [value, setValue] = useState(0);
  const [checked, setChecked] = useState(false);
  console.log(checked);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [contractDetails, beatPrice] = useMemo(() => {
    let parameters = {
      producerName: musicianName,
      licensee: user?.firstname + " " + user?.lastname,
      startDate: moment(new Date()).format("ll"),
      percentage: publishingSplit,
      beatPrice: beatExclusivePrice ?? exclusivePrice,
    };

    if (opportunity) {
      const artist = opportunity?.artist;
      const recordList = opportunity?.recordList;
      parameters.producerName = artist?.name;
      parameters.percentage = recordList?.publishingSplit;
    }

    let contract = getNonExclusiveContractDetails(parameters);
    let price = beatNonExclusivePrice ?? nonExclusivePrice ?? 0;

    if (value === 0) {
      contract = getExclusiveContractDetails(parameters);
      price = beatExclusivePrice ?? exclusivePrice ?? 0;
    }
    setPrice(price);

    return [contract, price];
  }, [value]);

  const handlePdfDownload = () => {
    let parameters = {
      producerName: musicianName,
      licensee: user?.firstname + " " + user?.lastname,
      startDate: moment(new Date()).format("ll"),
      percentage: publishingSplit,
      beatPrice: beatExclusivePrice ?? exclusivePrice,
    };

    if (opportunity) {
      const artist = opportunity?.artist;
      const recordList = opportunity?.recordList;
      parameters.producerName = artist?.name;
      parameters.percentage = recordList?.publishingSplit;
    }

    if (value === 0) {
      handleExclusiveDownload(parameters);
    } else {
      handleNonExclusiveDownload(parameters);
    }
  };

  const StyledTab = withStyles({
    root: {
      minHeight: "auto",
      padding: 0,
      height: "40px",
      borderRadius: "5px",
      "&.Mui-selected": {
        color: "black",
        backgroundColor: "#2EBB55",
        borderBottom: "none", // Remove the underline on the selected tab
      },
    },
  })(Tab);

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          // width: "8px",
          backgroundColor: "#141617",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#AFAFAF",
          borderRadius: "4px",
          marginRight: "4px",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "95%", sm: "70%", md: "60%", lg: "40%" },
          color: "white",
          boxShadow: 24,
          p: { xs: 2, sm: 3, md: 4, lg: 5 },
          height: "88vh",
          bgcolor: "#141617",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <img
            src={close_icon}
            alt="close"
            style={{ cursor: "pointer" }}
            onClick={closeModal}
          />
        </Box>
        <Typography variant="h5" component="h2" align="center">
          License Preview
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          size
          sx={{
            bgcolor: "black",
            padding: 1,
            borderRadius: "5px",
            minHeight: 0,
            marginTop: "20px",
            border: "none !important",
            outline: "none",
          }}
          TabIndicatorProps={{
            display: "none",
          }}
        >
          <StyledTab
            label="Exclusive"
            sx={{
              color: "white",
              fontSize: "16px",

              "&.Mui-selected": {
                color: "black",
                bgcolor: "#2EBB55",
              },
            }}
          />
          <StyledTab
            label="Non Exclusive"
            sx={{
              color: "white",
              fontSize: "16px",

              "&.Mui-selected": {
                color: "black",
                bgcolor: "#2EBB55",
              },
            }}
          />
        </Tabs>

        <p
          style={{
            fontSize: "16px",
            fontWeight: "500px",
            margin: "20px 0px",
          }}
        >
          LICENSE DETAILS{" "}
        </p>

        <Box
          sx={{
            maxHeight: "54%",
            overflowY: "auto",
            mb: 2,
            color: "white",
            bgcolor: "#211D1E",
            padding: "10px",
            fontSize: "14px",
          }}
        >
          {contractDetails}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // margin: "20px 0px",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "500px",
            }}
          >
            Beat Price
          </p>
          <p
            style={{
              color: "#2EBB55",
              fontSize: "20px",
              fontWeight: "600px",
            }}
          >
            ${beatPrice}
          </p>
        </Box>

        <CheckBox
          title="I agree to the Usage Terms & Conditions mentioned in license."
          value={value}
          onChange={(data) => {
            console.log(data);
            setChecked(data);
          }}
          action="Add_OptIn"
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant=""
            onClick={handleCheckout}
            sx={{
              flex: 1,
              backgroundColor: checked
                ? "#2EBB55 !important"
                : "#AFAFAF !important",
              color: "white !important",
              borderRadius: "5px !important",
              height: { lg: "45px", md: "35px", base: "48px" },
              marginRight: "10px !important",
              fontSize: "16px !important",
            }}
            disabled={!checked}
          >
            Purchase
          </Button>
          <Box
            height={{ lg: "45px", md: "35px", base: "48px" }}
            width={{ lg: "45px", md: "35px", base: "48px" }}
            sx={{
              backgroundColor: "#424142",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handlePdfDownload}
          >
            <img src={DownloadIcon} alt="download" />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
