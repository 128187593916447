import React, { useState } from "react";
import { ModelContentBox, Modelstyle } from "../socialintegrate/style";
import { Box, Button, IconButton, Modal } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { close_icon } from "../../../assets/smartlink";
import { InboxesMain } from "./style";
import SelectedBoxPopup from "./SelectedBoxPopup";
import { useMutation } from "@apollo/client";
import { BEAT_INBOX_SETTING } from "../../../Graphql_services/smartlink/SmartLink";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { Notification } from "../../NotifyMsg/Notification";

const SelectInboxPopup = ({
  open = false,
  setOpen = () => {},
  setOpen2 = () => {},
  ActiveData = [],
}) => {
  console.log(ActiveData, "From Props");

  // Create Smart Link
  const [ConfirmBeatinbox, { data, loading }] = useMutation(BEAT_INBOX_SETTING);

  const [checkedIndex, setCheckedIndex] = useState(-1);
  const [Selectedopen, setSelectedopen] = useState(false);
  const [SelectedValue, setSelectedValue] = useState([]);

  const updateInboxTypeHandler = async () => {};
  console.log(
    "🚀 ~ file: SelectInboxPopup.js:13 ~ SelectInboxPopup ~ SelectedValue:",
    SelectedValue
  );
  const CreateModelClose = () => {
    setOpen(!open);
  };
  const HandelOnClick = (e, index) => {
    setCheckedIndex(index);
    setSelectedValue(e);
  };

  const handleSubmitt = async () => {
    console.log(SelectedValue, "This Box");
    try {
      const result = await ConfirmBeatinbox({
        variables: {
          beatboxSettingId: SelectedValue?._id,
          selectedInbox: SelectedValue?.title,
          thumbnailStatus: false,
          beatboxStatus: true,
          autofitMessageStatus: false,
        },
        context: { clientName: "beats" },
      });

      Notification("success", result?.data?.beatboxSetting?.message);
      setOpen(false);
      setOpen2(true)
    } catch (err) {
      console.log(err.message);
      Notification("Error", err.message);
    }
  };

  const InboxesData = [
    {
      name: "BeatBox",
      select: true,
    },
    {
      name: "GrooveVault",
      select: false,
    },
    {
      name: "RhythmReel",
      select: true,
    },
  ];

  const ConfirmHaldler = () => {
    updateInboxTypeHandler();
    setOpen(false);
    setSelectedopen(true);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...Modelstyle,
            width: { xs: "100%", sm: "500px" },
            padding: "2rem",
            borderRadius: "0.625rem",
            background: "#141515",
          }}
        >
          <Box className="popup_header">
            <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "2rem",
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "2rem",
              }}
            >
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>

          <Box className="social_info">
            <h6>Select Inbox</h6>
            <p>Choose the inbox you want to connect to or create a new one</p>
            <InboxesMain className="Inboxes_Main">
              {ActiveData && ActiveData.length > 0 ? (
                ActiveData.map((item, index) => {
                  return (
                    <>
                      <Box
                        className={`Inboxes_item ${
                          checkedIndex === index ? "Inboxes_item_checked" : ""
                        }`}
                        key={item?.title + index}
                        onClick={() => HandelOnClick(item, index)}
                      >
                        <Box className="name_sec">{item?.title}</Box>
                        <Box className="is_checked_box"></Box>
                      </Box>
                    </>
                  );
                })
              ) : (
                <p style={{ color: "white" }}>Loading</p>
              )}
            </InboxesMain>
            <Box style={{ textAlign: "right" }}>
              <Button
                onClick={handleSubmitt}
                disabled={SelectedValue?.length === 0 ? true : false}
              >
                Confirm
              </Button>
            </Box>
            {loading && (
              <CircularProgress style={{ color: "#fff" }} size={20} />
            )}
          </Box>
        </ModelContentBox>
      </Modal>
      <SelectedBoxPopup
        open={Selectedopen}
        setOpen={setSelectedopen}
        SelectedValue={SelectedValue}
      />
    </>
  );
};

export default SelectInboxPopup;
