import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FormWrapper = styled(Box)(({ theme }) => ({
  margin: "40px auto 20px auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: 2,
  width: 570,

  "& .inputmain": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: 15,
  },
  input: {
    padding: "16px",
    flexGrow: 1,
    outline: 0,
    background: "transparent",
    color: "#AFAFAF",
    borderRadius: "5px",
    border: "1px solid #AFAFAF",

    "&::placeholder": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: 15,
    },
  },
  "& .error": {
    border: "1px solid red",
    color: "red",
  },
  "& .error_span": {
    color: "red",
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontStyle: "italic",
  },

  button: {
    width: "56px",
    height: "56px",
    border: 0,
    outline: 0,
    cursor: "pointer",
    borderRadius: "5px",
    background: "#2EBB55",
    ":hover": {
      background: "#2EBB55",
    },
  },
}));
