import React from "react";
import { Container, Typography, Link } from "@mui/material";

export const TermAndConditions = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ my: 4, color: "#fff", background: "#141515", padding: "20px" }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", marginBottom: "20px" }}
      >
        United Market Terms & Conditions Agreement
      </Typography>
      <strong style={{ marginBottom: "15px" }}>Terms of Use</strong>
      <Typography paragraph>
        These Terms of Use (the "Terms of Use") govern the use of the
        internet-based platform, offered by United Market, Inc. (the "Company,"
        "United Market," "we" or "us") at and through the website theUMapp.com
        (the "Website") and through the United Market mobile application (such
        platform, applications and the Website are collectively referred to as
        the "Platform"). The Company's Privacy Policy as well as any community
        guidelines or dispute resolution procedures that the Company may provide
        or make available, are all considered part of these Terms of Use and are
        incorporated by reference into these Terms of Use in their entirety. Any
        reference to these Terms of Use shall include and mean a reference to
        all such incorporated policies and guidelines. In the event of a
        conflict between these Terms of Use and any other terms posted on the
        Website, the terms of these Terms of Use shall govern, and in the event
        of a conflict between these Terms of Use and the End User License
        Agreement in mobile applications (the "EULA"), the terms of the EULA
        shall govern.
      </Typography>

      <Typography paragraph>
        PLEASE READ THESE TERMS OF USE CAREFULLY. THIS IS A LEGAL AGREEMENT
        BETWEEN UNITED MARKET AND YOU WHICH GOVERNS YOUR USE OF THE PLATFORM.
        YOUR USE OF THE PLATFORM CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT TO
        ALL OF THE TERMS AND CONDITIONS IN THESE TERMS OF USE AND YOUR
        REPRESENTATION THAT YOU ARE 18 YEARS OF AGE OR OLDER. IF YOU OBJECT TO
        ANYTHING IN THESE TERMS OF USE, YOU ARE NOT PERMITTED TO USE THE
        PLATFORM.
      </Typography>

      <Typography paragraph>
        If you are accepting these Terms of Use and using the Platform on behalf
        of a company, organization, government, or other legal entity, you
        represent and warrant to United Market that you have full power and
        authority to do so.
      </Typography>

      <Typography paragraph>
        United Market may update or change these Terms of Use from time to time
        and recommends that you review the Terms of Use on a regular basis as
        posted on United Market's website. You understand and agree that your
        continued use of the Platform after the Terms of Use have changed
        constitutes your acceptance of the Terms of Use as revised.
      </Typography>

      <Typography paragraph>
        The Platform is protected by copyright laws and international copyright
        treaties, as well as other intellectual property laws and treaties.
        Unauthorized reproduction or distribution of the Platform, or any
        portion of it, may result in severe civil and criminal penalties, and
        will be prosecuted to the maximum extent possible under the law. Use of
        the Platform is licensed, not sold
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>
          Scope of Privacy Policy
        </strong>
        <br />
        This Privacy Policy applies only to your access to the Site. The Site
        may contain links to third party websites and resources. United Market,
        Inc. has no control over how your information is collected, stored, or
        used by these third-party websites and/or resources. United Market, Inc.
        therefore advises you to check the privacy policies of any such
        third-party websites and/or resources before providing any information
        to them.
      </Typography>

      <Typography paragraph>
        In the context of the law and this notice, the terms "process" and
        "processing" mean to collect, store, transfer, use or otherwise act on
        data.
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>1. Definitions</strong>
        <br />
        <ul>
          <li>
            “Artist” means any person who uses the Platform to find or purchase
            Music Production Services.
          </li>
          <li>
            “Company” means United Market, Inc. and all its affiliates,
            subsidiaries, and assigns.
          </li>
          <li>
            “Music Production Services” means the act or service of producing,
            engineering, or writing music.
          </li>
          <li>
            “Pitching Period” means the time period during which the User has
            given the Company an exclusive right to pitch their beats or sounds
            to third parties. This period may last 30 days, 60 days, or 90 days
            with the option to renew.
          </li>
          <li>
            “Platform” includes the United Market website, the United Market
            App, and all affiliated software.
          </li>
          <li>
            “Users” means any Service Provider or Artist who accesses the
            Platform.
          </li>
          <li>
            “Work” means the work product created by Service Providers’ Music
            Production Services.
          </li>
        </ul>
        <Typography paragraph>
          <strong style={{ marginBottom: "15px" }}>
            2. The United Market Platform
          </strong>
          <br />
          <ul>
            <li> The Platform</li>
            {/* <Link
          href="https://www.google.com/policies/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.google.com/policies/privacy/
        </Link>
        <br /> */}
            <Typography paragraph>
              United Market is a platform that connects music producers,
              engineers, songwriters, and studios (collectively or individually,
              "Service Providers”) with Artists who are seeking their Music
              Production Services. All Service Providers and Artists are users
              of the Platform provided by United Market and are hereinafter
              referred to collectively as "Users."
            </Typography>

            <Typography paragraph>
              United Market solely provides a platform for Service Providers and
              Artists to connect and serves only as a medium to facilitate the
              provision of Music Production Services. United Market does not
              provide or contract for Music Production Services. Artists
              contract independently for the provision of Music Production
              Services. Each Artist is solely responsible for selecting the
              Music Production Services to be provided and the location at which
              Music Production Services will be performed, whether on the
              location provided by a Service Provider or at a site designated by
              the Artist. Any decision by an Artist to receive Music Production
              Services or by a Service Provider to provide Music Production
              Services is a decision made at such person's sole discretion and
              at their own risk.
            </Typography>

            <Typography paragraph>
              United Market does not have control over the quality, suitability,
              reliability, timing, durability, legality, failure to provide, or
              any other aspect whatsoever of any Music Production Services
              provided by Service Providers nor of the integrity, responsibility
              or any of the actions or omissions whatsoever of any Service
              Providers or Artists. United Market makes no representations or
              warranties whatsoever with respect to Music Production Services
              offered or provided by Service Providers or requested by Artists
              through use of the Platform, whether in public, private, or
              offline interactions, or about the accreditation, registration, or
              licensing of any Service Provider. You 3 understand that United
              Market does not routinely screen its Users, inquire into the
              background of its Users or attempt to verify information provided
              by any User.
            </Typography>

            <Typography paragraph>
              United Market does not verify or confirm that any User is who they
              claim to be or is accurately representing themselves and does not
              verify or confirm any representations with respect to Music
              Production Services. United Market does not assume any
              responsibility for the accuracy or reliability of this
              information, or any information provided through the Platform.
              Notwithstanding the foregoing, United Market may, but is not
              obligated to, check the background and experience of Service
              Providers via third-party background check Platform and first
              party interviews, including but not limited to a verification of
              identity and a comprehensive criminal background check, and may
              terminate a Service Provider based on any information discovered
              during such background checks and first party interviews. Whether
              or not United Market conducts a background check or first party
              interview on a Service Provider, each Artist must decide whether a
              Service Provider is suited to such Artist needs and should
              exercise caution and common sense to protect their personal safety
              and property, just as they would when interacting with anyone you
              don't know.
            </Typography>

            <Typography paragraph>
              United Market from time to time may contract with third-party
              affiliates, including SoStero, to shop and market products that
              are uploaded to the Platform. Artists and Service Providers agree
              to allow United Market to contract with such third-party
              affiliates and agree that such third-party affiliates be afforded
              the same licensing as described in Section 3.2 of this Terms of
              Use Agreement.
            </Typography>

            <Typography paragraph>
              NEITHER COMPANY NOR ITS AFFILIATES OR LICENSORS IS RESPONSIBLE FOR
              THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE
              PLATFORM AND YOU HEREBY RELEASE THE COMPANY AND ITS AFFILIATES OR
              LICENSORS FROM ANY LIABILITY RELATED THERETO. COMPANY AND ITS
              AFFILIATES AND LICENSORS WILL NOT BE LIABLE FOR ANY CLAIM, INJURY
              OR DAMAGE ARISING IN CONNECTION WITH YOUR USE OF THE PLATFORM
            </Typography>
          </ul>
          <ul>
            <li> Eligibilit</li>
            {/* <Link
          href="https://www.google.com/policies/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.google.com/policies/privacy/
        </Link>
        <br /> */}
            <Typography paragraph>
              Our services are intended for individuals who can form legally
              binding contracts under applicable law. These terms form a legally
              binding agreement between Users and the Company. If you are under
              age 18, you may only use the Platform with the consent of your
              parent or legal guardian. Please be sure your parent or legal
              guardian has reviewed and discussed these terms with you. The
              Company does hereby disclaim any liability whatsoever for any
              misstatements and/or misrepresentations made by any User of this
              Site. Users do hereby represent, understand, and agree to forever
              release and hold the Company harmless from any misstatements
              and/or misrepresentations made by any Users of this Site or from
              any and all claims of intellectual property infringement,
              plagiarism, or other claims regarding the use or ownership of any
              audio files or other intellectual property introduced to or
              through the Platform by any User.
            </Typography>
            <li> Fees and Payment</li>
            <Typography paragraph>
              United Market charges Users an annual or monthly fee, to cover the
              operational costs of providing access to the Platform and Service
              Providers charge a fee to make use of their Music Production
              Services. These fees are subject to change and may require you to
              enter credit card or other payment information. By entering your
              payment information when requested, you authorize United Market
              and its payment processors to charge and process the fees and
              charges assessed in connection with your purchase in accordance
              with these Terms. While United Market takes what it believes to be
              reasonable efforts to ensure secure transmission of your
              information to third parties who assess and process payments,
              United Market is not responsible for any fees or charges assessed
              by third parties or any errors in the processing of payments by
              third parties, including any errors that result from third-party
              negligence, improper transmission of payment information, your
              mistaken submission of payment information, or your submission of
              erroneous payment information.
            </Typography>
            <li> Account</li>
            2.4.1. Account Registration
            <Typography paragraph>
              By creating an account with United Market (an "Account"), you are
              granted a right to use the Platform provided by United Market
              subject to the restrictions set forth in these Terms of Use and
              any other restrictions stipulated to you by us in writing. Our
              registration process will ask you for information including your
              name and other personal information, and in registering for an
              Account, you agree to provide true, accurate, current and complete
              information about yourself as prompted by the Platform's
              registration process and as requested from time to time by United
              Market (such information, "Registration Data"). You further agree
              that, in providing such Registration Data, you will not knowingly
              omit or misrepresent any material facts or information and that
              you will promptly enter corrected or updated Registration Data via
              the Platform, or otherwise advise us promptly in writing of any
              changes or updates to your Registration Data. You further consent
              and authorize us to verify your Registration Data as required for
              your use of and access to the Platform. We reserve the right to
              suspend or terminate the Account of any User who provides
              inaccurate, untrue, or incomplete information, or who fails to
              comply with the account registration requirements.
            </Typography>
            2.4.2. Account ID
            <Typography paragraph>
              Once you register for the Platform, you shall receive a unique
              user identification and password in connection with your Account
              (collectively referred to herein as "Account IDs"). You must use 5
              your true and accurate name when signing up for an Account. You
              are required to select an appropriate and non-offensive Account
              ID. Ideally, the Account ID you choose will be a reasonably
              descriptive name that clearly identifies you. We reserve the right
              to remove or reclaim any Account ID if we believe, in our sole
              discretion, the Account ID is inappropriate, offensive or
              confusing or potentially violates the rights of a third party
              (such as when a trademark owner provides notice that an Account ID
              is similar to the trademark and the Account ID does not closely
              relate to the User's actual name or trademark rights).
            </Typography>
            2.4.3. Your Account
            <Typography paragraph>
              You agree to maintain your Account solely for your own use. You
              agree that you will not allow another person to use your Account
              IDs to access or use the Platform under any circumstances. You are
              solely and entirely responsible for maintaining the
              confidentiality of your Account IDs and for any charges, damages,
              liabilities or losses incurred or suffered as a result of your
              failure to do so. We are not liable for any harm caused by or
              related to the theft of your Account IDs, your disclosure of your
              Account IDs, or your authorization to allow another person to
              access or use the Platform using your Account IDs. Furthermore,
              you are solely and entirely responsible for any and all activities
              that occur under your Account including any charges incurred
              relating to the Platform. You agree to immediately notify us of
              any unauthorized use of your Account or any other breach of
              security known to you. You acknowledge that the complete privacy
              of your data and messages transmitted while using the Platform
              cannot be guaranteed.
            </Typography>
            2.4.4. Linking Your Account
            <Typography paragraph>
              As part of the functionality of the Platform, you may link your
              Account with online accounts you may have with third party service
              providers (each such account, a "Third Party Account'') by either:
              (i) providing your Third Party Account login information through
              the Platform; or (ii) allowing United Market to access your Third
              Party Account, as is permitted under the applicable terms and
              conditions that govern your use of each Third Party Account. You
              represent that you are entitled to disclose your Third Party
              Account login information to United Market and/or grant United
              Market access to your Third Party Account (including, but not
              limited to, for use for the purposes described herein), without
              breach by you of any of the terms and conditions that govern your
              use of the applicable Third Party Account and without obligating
              United Market to pay any fees or making United Market subject to
              any usage limitations imposed by such third party service
              providers. By granting United Market access to any Third Party
              Accounts, you understand that (i) United Market may access, make
              available and store (if applicable) any content that you have
              provided to and stored in your Third Party Account (the "SNS
              Content") so that it is available on and through the Platform via
              your Account, including without limitation any friend lists, and
              (ii) United Market may submit and receive additional information
              to your Third Party Account to the extent you are notified when
              you link your Account with the Third Party Account. Unless
              otherwise specified in these Terms of Use, all SNS Content, if
              any, shall be considered to be Content (as defined below) for all
              purposes of these Terms of Use. Depending on the Third-Party
              Accounts you choose and subject to the privacy settings that you
              have set in such Third-Party Accounts, personally identifiable
              information that you post to your Third Party Accounts may be
              available on and through your Account on the Platform. Please note
              that if a Third-Party Account or associated service becomes
              unavailable or United Market's access to such Third-Party Account
              6 is terminated by the third party service provider, then SNS
              Content may no longer be available on and through the Platform.
              You will have the ability to disable the connection between your
              Account and your Third-Party Accounts at any time.
            </Typography>
            <Typography paragraph>
              PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
              PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED
              SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
              PROVIDERS.
            </Typography>
            <Typography paragraph>
              The Platform may contain links to third party websites that are
              not owned or controlled by United Market. United Market has no
              control over, and assumes no responsibility for, the content,
              privacy policies, or practices of any third-party websites. In
              addition, United Market will not and cannot censor or edit the
              content of any third-party site. By using the Platform, you
              expressly relieve United Market from any and all liability arising
              from your use of any third-party website.
            </Typography>
            <Typography paragraph>
              You acknowledge and agree that the Platform may access your e-mail
              address book associated with a Third-Party Account and your
              contacts list stored on your mobile device or tablet computer
              solely for the purposes of identifying and informing you of those
              contacts who have also registered to use the Platform.
            </Typography>
            2.4.5. Termination of Accounts Right to Terminate
            <Typography paragraph>
              United Market reserves the right, in its sole discretion, to
              terminate your Account if you violate these Terms of Use or for
              any reason or no reason at any time. We may also suspend your
              access to the Platform and your Account (including any funds in
              your Account) if you (a) have violated the terms of these Terms of
              Use, any other agreement you have with United Market, including
              without limitation the EULA, or United Market's policies, (b) pose
              an unacceptable credit or fraud risk to us, (c) provide any false,
              incomplete, inaccurate, or misleading information or otherwise
              engage in fraudulent or illegal conduct, or (d) for any other
              reason in United Market's sole discretion.
            </Typography>
            <Typography paragraph>
              You may terminate these Terms of Use and/or the EULA by
              terminating your Account at any time. Upon closure of an Account,
              any pending transactions will be cancelled.
            </Typography>
            2.4.6. Termination Effects
            <Typography paragraph>
              If your Account is terminated or suspended for any reason or no
              reason, you agree: (a) to continue to be bound by these Terms of
              Use, (b) to immediately stop using the Platform, (c) that any
              licenses granted to you under these Terms of Use shall end, (d)
              that we reserve the right (but have no obligation) to hide or
              delete all of your information and account data stored on our
              servers, and (e) that United Market shall not be liable to you or
              any third party for termination or suspension of access to the
              Platform or for deletion or hiding of your information or account
              data. You agree that United Market may retain and use your
              information and account data as needed to comply with
              investigations and applicable law.
            </Typography>
            <Typography paragraph>
              We will not be liable to you for compensation, reimbursement. or
              damages in connection with your use of the Platform, or in
              connection with any termination or suspension of the Platform. Any
              7 termination of these Terms of Use does not relieve you of any
              obligations to pay any Fees or costs accrued prior to the
              termination and any other amounts owed by you to us as provided in
              these Terms of Use.
            </Typography>
            <Typography paragraph>
              United Market is not liable for any losses relating to
              chargebacks, fraudulent charges, or other actions by any User that
              are deceptive, fraudulent or otherwise invalid as determined in
              United Market's sole discretion (“Fraudulent Actions"). By using
              the Platform, you hereby release United Market from any liability
              arising from Fraudulent Actions. You will also use best efforts to
              promptly notify United Market of any Fraudulent Actions which may
              affect the Platform. United Market reserves the right, in its sole
              discretion, to terminate the account of any User that engages in,
              or enables any other User to engage in, Fraudulent Actions.
            </Typography>
            <li> Use of Platform; Limitations and Changes to the Platform.</li>
            <Typography paragraph>
              We may change, modify, suspend, or discontinue all or any part of
              the Platform at any time, with or without reason. You acknowledge
              that the operation of the Platform may from time to time encounter
              technical or other problems and may not necessarily continue
              uninterrupted or without technical or other errors and United
              Market shall not be responsible to you or others for any such
              interruptions, errors or problems or an outright discontinuance of
              the Platform. United Market has no obligation to maintain or
              update the Platform or to continue producing or releasing new
              versions of the Platform. We will make reasonable efforts to keep
              the Platform operational 24 hours a day/7 days a week, except for:
              (i) planned downtime (of which we will endeavor to provide at
              least 8 hours prior notice); or (ii) any unavailability caused by
              circumstances beyond our control, including but not limited to,
              acts of God, acts of government, flood, fire, earthquakes, civil
              unrest, acts of terror, strikes or other labor problems or
              Internet service provider failures or delays.
            </Typography>
            <Typography paragraph>
              When you publish content or information using the "public"
              setting, then everyone, including people outside of the United
              Market community, will have access to that information and we may
              not have control over what they do with it. We always appreciate
              your feedback or other suggestions about United Market, but you
              understand that we may use them, and you hereby grant us all
              proprietary rights or any other rights to such suggestions,
              comments, ideas, brands and strategies without any obligation to
              compensate you for their usage just as you have no obligation to
              offer them). We do our best to keep United Market safe and spam
              free but cannot guarantee it. To help us do so, you agree not to:
              send or otherwise post unauthorized commercial communications
              (such as spam) on the Platform; collect Users' content or
              information, or otherwise access the Platform, using automated
              means (such as harvesting bots, robots, spiders, or scrapers)
              without our permission; engage in unlawful multi-level marketing,
              such as a pyramid scheme, on the Platform; upload viruses or other
              malicious code, solicit login information or Account IDs or access
              an 8 account belonging to someone else; bully, intimidate, or
              harass any User, post content that is hateful, threatening,
              pornographic, or that contains nudity or graphic or gratuitous
              violence; develop, advertise, or otherwise market alcohol-related
              or other mature content, use United Market to do anything
              unlawful, misleading, malicious, or discriminatory, do anything
              that could disable, overburden, or impair the proper working of
              United Market or the Platform, such as a denial-ofservice attack,
              facilitate or encourage any violations of these Terms of Use. To
              make sure we are able to provide a service to our Users, we need
              to make sure our pages are accurate and up to date. To help us do
              so, you agree to: Keep your Registration Data and contact
              information accurate and up to date, keep your Account IDs and
              Account information confidential and to not share your login
              information or Account IDs, not to let anyone else access your
              Account, or do anything else that might jeopardize the security of
              your Account.
            </Typography>
            <li> Intellectual Property Rights</li>
            3.1. Company’s Proprietary Materials
            <Typography paragraph>
              All text, graphics, editorial content, data, formatting, graphs,
              designs, HTML, look and feel, photographs, music, sounds, images,
              software, videos, designs, typefaces and other content
              (collectively "Proprietary Material") on the Site and provided by
              United Market, Inc. is owned by the Company or is used with
              permission. This Proprietary Material is protected in all forms,
              media and technologies now known or hereinafter developed. The
              Company owns all Proprietary Material as well as the coordination,
              selection, arrangement, and enhancement of such Proprietary
              Materials as a Collective Work under the United States Copyright
              Act, as amended. Except as expressly provided on the Platform or
              in these Terms of Use, Users may not copy, download, use redesign,
              reconfigure, or retransmit anything from the Platform without the
              Company’s prior express written permission. Furthermore, Users are
              not allowed to post or distribute any material that they do not
              own, or which they do not have permission to use. Violation of
              this policy may result in copyright, trademark or other
              intellectual property rights violations and liability, and subject
              Users to termination from the use of this Platform or civil or
              criminal penalties. In addition, the Platform contains material
              protected by the domestic and international laws of copyright,
              patents, and other proprietary rights and laws. Any use of such
              Proprietary Material, other than as permitted therein, is
              expressly prohibited without the prior permission of United
              Market, Inc. and/or the relevant right holder. 3.2. Users’
              Proprietary Materials When the Service Provider’s Work is
              delivered, the Artist and the Company are granted a nonexclusive,
              transferable, sub-licensable, royalty free license to use the
              Work, including but not limited to, copyrights for the work
              delivered from the Service Provider, and the Service Provider
              waives any and all moral rights therein. The delivered Work by the
              Service Provider shall be considered work-for-hire under the U.S.
              Copyright Act. In the event the delivered Work does not meet the
              requirements of work-for-hire or when US Copyright Act does not
              apply, the Service Provider expressly agrees to assign to Artist
              and the Company the copyright in the delivered work.
            </Typography>
            <Typography paragraph>
              All transfer and assignment of intellectual property to the Artist
              shall be subject to full payment for the applicable Music
              Production Services and such work may not be used by the Artist if
              payment is cancelled for any reason. Service Provider confirms
              that all information received from the Artist, which is not public
              domain, shall be kept confidential and shall not be shared or used
              for any purpose whatsoever other than for the delivery of the
              applicable work from the Service Provider. Service Provider shall
              not be considered an author, joint author, or copyright owner or
              have any other rights to the lyrics, melodies, or any other sounds
              created, written, produced over their Work on the Platform by the
              Company or Artists. By uploading your Work to the Platform,
              Service Providers provide the Company exclusive rights to the Work
              for the Pitching Period. In the event that the Work is placed, you
              agree that the Work will be exclusively sold to the Artist and to
              waive any upfront producer fee or compensation. Service Provider
              acknowledges that they will only be entitled to publishing and/or
              a percentage of the master. To operate the Platform, the Company
              needs to make certain use of User’s publicly posted Content.
              Therefore, by posting, uploading or submitting to United Market,
              or making available for inclusion in publicly accessible areas of
              United Market, any text, images, photos, graphics, audio or video,
              including any content protected by intellectual property rights
              (collectively, "Content"), User represents that you have full
              authorization to do so. User hereby grants United Market a
              nonexclusive, transferable, sub-licensable, royalty-free,
              worldwide license to use, distribute, reproduce, modify, adapt,
              publicly perform, publicly display and make derivative works of
              such Content in connection with the Platform and any platform or
              platforms affiliated with the Platform, regardless of the form of
              media used or of whether such Platform or Platforms now exist or
              are developed in the future. When you delete Content, it is
              deleted in a manner similar to emptying the recycle bin on a
              computer. However, you understand that removed Content may persist
              in backup copies for a reasonable period of time (but will not be
              available to others). Any Content posted by a User belongs to the
              person that posted such Content. You may use any Content posted by
              you in any way without restriction. You may only use Content
              posted by others in the ways described in these Terms of Use. The
              Company reserves the right to remove or hide any Content from the
              Platform, at its sole discretion. The Company respects the
              intellectual property of others. It may, in appropriate
              circumstances and at its discretion, disable and/or terminate the
              Accounts of Users who the Company, in its determination, believes
              have repeatedly infringed others' rights.
            </Typography>
            <li> Disclaimer</li>
            <Typography paragraph></Typography>
          </ul>
        </Typography>
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>
          Email Addresses and Phone Numbers
        </strong>
        <br />
        We may collect your email address or phone number, but only if you
        voluntarily provide it to us. This may occur, for example, if you sign
        up for an account. We will use your email address and/or phone number
        for the purposes for which you provided it to us, and also from time to
        time to send you emails or text messages regarding the Site or other
        products or services that we believe may be of interest to you. You may
        opt out of such communications at any time by clicking the “unsubscribe”
        button in an email or responding STOP via text message.
        <br />
        We will not share your email address or phone number with any third
        parties.
        <br />
        If you are a resident of a country in the European Economic Area (EEA),
        please refer to the section below entitled “Additional Rights of EEA
        Residents.”
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>Transactional Data</strong>
        <br />
        We may process information relating to transactions, including purchases
        of goods and services, that you enter with us. (“Transactional Data”).
        The Transactional Data may include your contact details, your card
        details and the transaction details. Transaction Data may be processed
        for the purpose of supplying the purchased goods and services and
        keeping proper records of those transactions. Payment processing on our
        Site is handled by our payment services providers. We will share
        transaction data with our payment services providers only to the extent
        necessary for the purposes of processing your payments, refunding such
        payments and dealing with complaints and queries relating to such
        payments and refunds.
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>
          Additional Rights of EEA (European Economic Area) Residents
        </strong>
        <br />
        If you are a resident of a country in the EEA, you have the rights,
        among others, to:
        <ul>
          <li>access your personal data,</li>
          <li>ensure the accuracy of your personal data,</li>
          <li>the right to have us delete your personal data,</li>
          <li>
            the right to restrict further processing of your personal data, and
          </li>
          <li>
            the right to complain to a supervisory authority in your country of
            residence if data is misused.
          </li>
        </ul>
        If you believe that our processing of your personal information
        infringes data protection laws, you have a legal right to lodge a
        complaint with a supervisory authority responsible for data protection.
        You may do so in the EU member state of your residence, your place of
        work or the place of the alleged infringement.
        <br />
        You may exercise any of your rights in relation to your personal data by
        written notice to us addressed to the following:
        <Link href="mailto:support@unitedmarket.com">
          support@unitedmarket.com
        </Link>
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>
          Sale of Business or Assets
        </strong>
        <br />
        In the event that the Site or substantially all of its assets are sold
        or disposed of, whether by merger, sale of assets or otherwise, or in
        the event of an insolvency, bankruptcy or receivership, the information
        we have collected about you may be one of the assets sold or merged in
        connection with that transaction.
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>
          Changes to the Privacy Policy
        </strong>
        <br />
        We may change this Privacy Policy from time to time. The most recent
        version of the Privacy Policy will always be posted on the Site, with
        the “Effective Date” posted at the top of the Policy. We may revise and
        update this Privacy Policy if our practices change, as technology
        changes, or as we add new services or change existing ones. If we make
        any material changes to our Privacy Policy or how we handle your
        personal information, or we are going to use any personal information in
        a manner that is materially different from that stated in our Privacy
        Policy at the time we collected such information, we will give you a
        reasonable opportunity to consent to the change. If you do not consent,
        your personal information will be used as agreed to under the terms of
        the privacy policy in effect at the time we obtained that information.
        By using our Site after the Effective Date, you are deemed to consent to
        our then-current privacy policy. We will use information previously
        obtained in accordance with the Privacy Policy in effect when the
        information was obtained from you.
      </Typography>

      <Typography paragraph>
        <strong style={{ marginBottom: "15px" }}>Contacting Us</strong>
        <br />
        Should you have any questions or concerns regarding privacy while using
        our Site, please send us a detailed message to
        <Link href="mailto:support@unitedmarket.com">
          support@unitedmarket.com
        </Link>
        , and United Market will use our best endeavors to resolve your
        concerns.
      </Typography>
    </Container>
  );
};
export default TermAndConditions;
