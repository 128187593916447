import React, { useEffect } from 'react';
import {
	Box,
	Button,
	CloseIcon,
	Heading,
	HStack,
	Input,
} from 'native-base';
import Modal from '@material-ui/core/Modal';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import styles from '../styles.module.css';

function EditCollaborator(props) {
    const {
		openModal,
		closeModal,
		collaboratorData,
		setCollaboratorData,
        collaboratorIndex,
		setCollaboratorIndex,
		mySplit,
		setMySplit,
		isEditMysplit,
		setIsEditMysplit
	} = props;

	const schema = yup.object().shape({
		musicianName: yup.string().required(),
		email: yup
			.string()
			.email()
			.required(),

		split: yup
			.number()
			.required()
			.min(0)
			.max(100)
			.typeError('split must be a number'),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		setError
	} = useForm({
		resolver: yupResolver(schema),
	});
	const onSubmitHandler = (data) => {
		if(isEditMysplit){
			let splitSum = collaboratorData.reduce((sum, item) => sum + item.split, 0);
			const newSplitSum = splitSum + data.split;
			if (newSplitSum > 100) {
				const remainingPercentage = 100 - splitSum;
				setError("split", {
					type: "manual",
					message: `The sum of collaborators split exceed 100%. Remaining: ${remainingPercentage}%`,
				  })
			}else{
				setMySplit({
					musicianName: data.musicianName,
					email: mySplit.email,
					split: data.split,
					role: mySplit.role,
					profilePic: mySplit.profilePic,
				  });
				toast.success('Collaborator updated successfully');
				reset();
				setIsEditMysplit(false);
				closeModal();
			}
		}else{
			let updatedCollaboratorData = collaboratorData.filter((_, index) => index !== collaboratorIndex);
	
			let splitSum = updatedCollaboratorData.reduce((sum, item) => sum + item.split, 0);
			
			splitSum = mySplit ? splitSum + mySplit.split : splitSum;
	
			const newSplitSum = splitSum + data.split;
		
			if (newSplitSum > 100) {
				const remainingPercentage = 100 - splitSum;
				setError("split", {
					type: "manual",
					message: `The sum of collaborators split exceed 100%. Remaining: ${remainingPercentage}%`,
				  })
			}else{
				updatedCollaboratorData.splice(collaboratorIndex, 0, {
					musicianName: data.musicianName,
					email: data.email,
					split: data.split,
				});
				setCollaboratorData(updatedCollaboratorData);
				toast.success('Collaborator updated successfully');
				reset();
				setIsEditMysplit(false);
				setCollaboratorIndex(null);
				closeModal();
			}
		}
	};
    useEffect(() => {
        if (openModal) {
			if (isEditMysplit) {
                reset({
                    musicianName: mySplit.musicianName,
                    email: mySplit.email,
                    split: mySplit.split,
                });
            }
			if (collaboratorData[collaboratorIndex]) {
                reset({
                    musicianName: collaboratorData[collaboratorIndex].musicianName,
                    email: collaboratorData[collaboratorIndex].email,
                    split: collaboratorData[collaboratorIndex].split,
                });
            } 
        }
    }, [openModal, collaboratorData, reset]);
	const darkTheme = createTheme({
		palette: {
			type: 'dark',
		},
	});
	function getModalStyle() {
		const top = 50;
		const left = 50;

		return {
			top: `${top}%`,
			left: `${left}%`,
			transform: `translate(-${top}%, -${left}%)`,
		};
	}
	const modalStyle = getModalStyle();

	return (
		<ThemeProvider theme={darkTheme}>
			<Modal open={openModal} onClose={()=>{
				reset();
				setIsEditMysplit(false);
				setCollaboratorIndex(null);
				closeModal();
			}}>
				<div style={modalStyle} className={styles.modalPaper}>
					<Box bg={'#141313'} rounded={'lg'}>
						<HStack
							alignSelf={'center'}
							justifyContent={'space-between'}
							my={5}
							w={'100%'}
						>
							<Heading
								color={'#e3e3e3'}
								fontWeight={'normal'}
								fontFamily={'Poppins'}
								alignSelf={'center'}
								textAlign={'center'}
								fontSize={{ lg: '2xl', base: 'lg' }}
							>
								Edit a Collaborator
							</Heading>

							<Button
								variant={'ghost'}
								px={5}
								top={0}
								right={0}
								_hover={{ bg: 'transparent' }}
								_pressed={{ bg: 'transparent' }}
								onPress={()=>{
									reset();
									setIsEditMysplit(false);
									setCollaboratorIndex(null);
									closeModal();
								}}
								alignItems={'center'}
							>
								<CloseIcon sx={{ color: '#AFAFAF' }} />
							</Button>
						</HStack>
						<form onSubmit={handleSubmit(onSubmitHandler)}>
							<Box w={'90%'} alignSelf={'center'}>
								<Box w={'100%'} alignSelf={'center'}>
									<Heading
										my={5}
										textTransform={'capitalize'}
										fontSize={{
											xl: '20px',
											lg: '18px',
											md: '16px',
											base: '14px',
										}}
										fontWeight={400}
										fontFamily={'Poppins'}
										textAlign={'left'}
										color={'#afafaf'}
									>
										Musician Name:
									</Heading>
									<Input
										isDisabled={isEditMysplit?true:false}
										_text={{ fontSize: '1rem', fontFamily: 'poppins' }}
										_hover={{ borderColor: '#AFAFAF' }}
										_focus={{ bg: 'transparent' }}
										focusOutlineColor='#2EBB55'
										fontFamily='poppins'
										fontSize={{ lg: '18px', md: '16px', base: '14px' }}
										color={'#AFAFAF'}
										borderColor='#AFAFAF'
										height={{
											xl: '50px',
											base: '35px',
										}}
                                        {...register('musicianName')}
										onChangeText={(text) => setValue('musicianName', text)}
									/>
									<p>{errors.musicianName?.message}</p>
								</Box>
								<Box w={'100%'} alignSelf={'center'}>
									<Heading
										my={5}
										textTransform={'capitalize'}
										fontSize={{
											xl: '20px',
											lg: '18px',
											md: '16px',
											base: '14px',
										}}
										fontWeight={400}
										fontFamily={'Poppins'}
										textAlign={'left'}
										color={'#afafaf'}
									>
										Email:
									</Heading>
									<Input
										isDisabled={isEditMysplit?true:false}
										_text={{ fontSize: '1rem', fontFamily: 'poppins' }}
										_hover={{ borderColor: '#AFAFAF' }}
										_focus={{ bg: 'transparent' }}
										focusOutlineColor='#2EBB55'
										fontFamily='poppins'
										fontSize={{ lg: '18px', md: '16px', base: '14px' }}
										color={'#AFAFAF'}
										borderColor='#AFAFAF'
										height={{
											xl: '50px',
											base: '35px',
										}}
                                        {...register('email')}
										onChangeText={(text) => setValue('email', text)}
									/>
									<p>{errors.email?.message}</p>
								</Box>
								<Box w={'100%'} alignSelf={'center'}>
									<Heading
										my={5}
										textTransform={'capitalize'}
										fontSize={{
											xl: '20px',
											lg: '18px',
											md: '16px',
											base: '14px',
										}}
										fontWeight={400}
										fontFamily={'Poppins'}
										textAlign={'left'}
										color={'#afafaf'}
									>
										Split %
									</Heading>
									<Input
										_text={{ fontSize: '1rem', fontFamily: 'poppins' }}
										_hover={{ borderColor: '#AFAFAF' }}
										_focus={{ bg: 'transparent' }}
										focusOutlineColor='#2EBB55'
										fontFamily='poppins'
										fontSize={{ lg: '18px', md: '16px', base: '14px' }}
										color={'#AFAFAF'}
										borderColor='#AFAFAF'
										height={{
											xl: '50px',
											base: '35px',
										}}
                                        {...register('split')}
										onChangeText={(text) => setValue('split', text)}
									/>
									<p>{errors.split?.message}</p>
								</Box>

								<Box w={{ lg: '50%', base: '100%' }} alignSelf={'flex-end'}>
									<HStack w={'100%'} my={10} justifyContent={'space-between'}>
										<Button
											w={'45%'}
											variant={'ghost'}
											_hover={{
												backgroundColor: '#2EBA55',
												_text: {
													color: '#fff',
												},
											}}
											_pressed={{
												bg: '#2EBB55',
												_text: {
													color: '#fff',
												},
											}}
											_focus={{ bg: 'transparent' }}
											// mt={10}
											p={0}
											py={2.5}
											borderColor='#fff'
											borderWidth={1}
											alignSelf={'center'}
											_text={{
												color: '#fff',
												fontWeight: 'medium',
												fontSize: {
													xl: '18px',
													lg: '18px',
													md: '16px',
													base: '14px',
												},
												fontFamily: 'Poppins',
											}}
											onPress={() => {
												reset();
												setIsEditMysplit(false);
												setCollaboratorIndex(null);
												closeModal();
											}}
										>
											CANCEL
										</Button>
										<button type='submit' className={styles.submitBtn}>
											Update
										</button>
									</HStack>
								</Box>
							</Box>
						</form>
					</Box>
				</div>
			</Modal>
		</ThemeProvider>
	);
}

export default EditCollaborator
