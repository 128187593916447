import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import PaymentCheckoutModel from "../../../components-old/share/models/paymentmodel/PaymentCheckoutModel";
import { CardMain } from "./styles/PricePlanCardstyle";
import PaymentCheckoutModel from "./popups/PaymentCheckoutModel";
import PaymentDetailModel from "./popups/PaymentDetailModel";
import CongradulationModel from "./popups/CongradulationModel";
// import { ToastContainer } from "react-toastify";
import useAuth from "../../../../context/AuthContext";
import { Notification } from "../../../NotifyMsg/Notification";
import { DOWNGRADETOFREEMIUM } from "../../../../Graphql_services/UsersMutation";
import { useMutation } from "@apollo/client";

const PricePlanCard = ({
  // title = "",
  // desc = "",
  // price = "",
  // buttonName = "",
  // storage = "",
  // smarLink = "",
  // songWriter = "",
  // isSelected = false,
  plan,
  setopencontactus,
}) => {
  console.log("plan",plan)
  const [downgradeToFreemium, { loading }] = useMutation(DOWNGRADETOFREEMIUM);
  const [open, setOpen] = useState(false);
  const [Detailopen, setDetailOpen] = useState(false);
  const [Congradulationopen, setCongradulationOpen] = useState(false);
  const [selectedplanid, setselectedplanid] = useState("");
  const [paymentDetais, setpaymentDetais] = useState(null);

  const { user } = useAuth();
  const [selectedPlans, setselectedPlans] = useState(null);

  const PaymentCheckoutModelOpen = (id, price, name) => {
    setOpen(!open);
    setselectedplanid({ id, price });
    setselectedPlans(name);
  };

  const desc =
    (plan?.interval === "month" || plan?.interval === "year") &&
    plan?.planName === "Free"
      ? "Handle the basics of your music career"
      : (plan?.interval === "month" || plan?.interval === "year") &&
        plan?.planName === "Monthly Plan"
      ? "Increase your collaborations by 10x and earn more money as a HitMaker Member."
      : (plan?.interval === "month" || plan?.interval === "year") &&
        plan?.planName === "Yearly Plan"
      ? "Increase your collaborations by 10x and earn more money as a HitMaker Member."
      : (plan?.interval === "month" || plan?.interval === "year") &&
        plan?.planName === "HitMaker"
      ? "Send really big files regularly"
      : "";

  const isFreemiumDisable =
    (user?.planId?.planName !== "Free" || "Freemium") &&
    (plan?.planName === "Free" || "Freemium");

  const HandelContactUs = () => {
    setopencontactus(true);
  };

  const UpdradeFreePlan = async () => {
    try {
      const variables = {
        isFreemium: true,
        isPaid: false,
      };

      const res = await downgradeToFreemium({
        variables: variables,
        context: { clientName: "user" },
      });
      if (res?.data?.downgradeToFreemium?.success) {
        Notification("success", "Downgrade successfully!");
        window.location.reload();
      }
    } catch (error) {
      console.log("🚀 ~ UpdradeFreePlan ~ error:", error);
    }
  };

  const UserPlanName =
    user?.planId?.planName === "Free" || user?.planId?.planName === "Freemium"
      ? "Free"
      : user?.planId?.planName;

  const PlanIntervel =
    (user?.planId?.planName === "Free" ||
      user?.planId?.planName === "Freemium") &&
    (plan?.interval === "month" || plan?.interval === "year")
      ? "month"
      : plan?.interval;

  return (
    <>
      <CardMain>
        <h5>{plan?.planName}</h5>

        {plan?.planName === "Enterprise Plan" ? (
          <>
            <h6>Full suite of features, unlimited links, tailored solutions</h6>
            <h5
              style={{
                marginTop: "0",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "30px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Custom Quote
            </h5>
          </>
        ) : (
          <h6>{desc}</h6>
        )}

        <span>
          {plan?.planName !== "Enterprise Plan" &&
            `$${plan?.price}${plan?.interval === "month" ? "/mo" : "/yr"}`}
        </span>
        <Box className="btn-box">
          {plan?.planName === "Enterprise Plan" ? (
            <>
              <Button
                variant="contained"
                // className={`btn ${isSelected ? "selected" : ""}`}
                fullWidth
                onClick={HandelContactUs}
              >
                Contact Us
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                className={`btn ${
                  isFreemiumDisable ? "is_freemium_selected" : ""
                }`}
                fullWidth
                onClick={() => {
                  if (plan.planName === "Free") {
                    UpdradeFreePlan();
                  } else {
                    PaymentCheckoutModelOpen(
                      plan._id,
                      plan.price,
                      plan.planName
                    );
                  }
                }}
                disabled={
                  loading ||
                  (UserPlanName === plan?.planName &&
                    PlanIntervel === user?.planId?.interval)
                }
              >
                {loading
                  ? "Loading..."
                  : user?.planId?.planName === plan?.planName &&
                    plan?.interval === user?.planId?.interval
                  ? "Subscribed"
                  : user?.planId?.planName !== "Free" &&
                    user?.planId?.planName !== "Freemium" &&
                    plan?.planName === "Free"
                  ? "Downgrade"
                  : (user?.planId?.planName === "Free" ||
                      user?.planId?.planName === "Freemium") &&
                    plan?.planName === "Free"
                  ? "Subscribed"
                  : "Get Started"}
              </Button>
            </>
          )}
        </Box>

        {plan?.planName === "Free" && (
          <>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>Create up to 3 audio file Inboxes</span>
            </Box>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>15 opportunity submissions per month</span>
            </Box>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>Cannot store files on United Market</span>
            </Box>
          </>
        )}

        {plan?.planName === "Monthly Plan" && (
          <>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>50 GB storage space</span>
            </Box>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>Unlimited opportunity submissions</span>
            </Box>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>Create unlimited inboxes</span>
            </Box>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>
                Sell & upload unlimited soundpacks{" "}
                <span style={{ color: "#2EBB55" }}>(Coming Soon)</span>
              </span>
            </Box>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>
                Store audio files on United Market.{" "}
                <span style={{ color: "#2EBB55" }}>(Coming Soon)</span>
              </span>
            </Box>
          </>
        )}

        {plan?.planName === "Yearly Plan" && (
          <>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>50 GB storage space</span>
            </Box>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>Unlimited opportunity submissions</span>
            </Box>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>Create unlimited inboxes</span>
            </Box>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>
                Sell & upload unlimited soundpacks{" "}
                <span style={{ color: "#2EBB55" }}>(Coming Soon)</span>
              </span>
            </Box>
            <Box className="package-info-box">
              <CheckCircleOutlineIcon className="tick-icon" />
              <span>
                Store audio files on United Market.{" "}
                <span style={{ color: "#2EBB55" }}>(Coming Soon)</span>
              </span>
            </Box>
          </>
        )}

        <PaymentCheckoutModel
          open={open}
          setOpen={setOpen}
          setDetailOpen={setDetailOpen}
          selectedplanid={selectedplanid}
          setpaymentDetais={setpaymentDetais}
        />
        <PaymentDetailModel
          open={Detailopen}
          setOpen={setDetailOpen}
          setCongradulationOpen={setCongradulationOpen}
          paymentDetais={paymentDetais}
          selectedPlans={selectedPlans}
        />
        <CongradulationModel
          open={Congradulationopen}
          setOpen={setCongradulationOpen}
          selectedPlans={selectedPlans}
        />
      </CardMain>
    </>
  );
};

export default PricePlanCard;
