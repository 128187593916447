import React from "react";
import { MainReportBox } from "./Reportstyle";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { image2 } from "../../../assets/smartlink";
import LeftSelecterDropdown from "./LeftSelecterDropdown";
import RightSelecterDropdown from "./RightSelecterDropdown";

const Users = [
  {
    id: 1,
    picture: image2,
    name: "Kibriya Channel",
    desc: "Content",
    clicks: "10",
    visits: "10",
    ctr: "50%",
  },
  {
    id: 2,
    picture: image2,
    name: "Kibriya Channel",
    desc: "Content",
    clicks: "10",
    visits: "10",
    ctr: "50%",
  },
  {
    id: 3,
    picture: image2,
    name: "Kibriya Channel",
    desc: "Content",
    clicks: "10",
    visits: "10",
    ctr: "50%",
  },
  {
    id: 4,
    picture: image2,
    name: "Kibriya Channel",
    desc: "Content",
    clicks: "10",
    visits: "10",
    ctr: "50%",
  },
];
const Report = () => {
  return (
    <>
      <MainReportBox>
        <Box className="header-main">
          <h4>Report</h4>
          <Box className="selecter-box">
            <LeftSelecterDropdown
              className="right"
              label="channel by services"
              title1="Channels broken down by services"
              title2="Link broken down by channels"
              height="40px"
              width="165px"
            />
            <RightSelecterDropdown
              className="right"
              label="Metrics"
              title1="Channels broken down by services"
              title2="Link broken down by channels"
              height="38px"
            />
          </Box>
        </Box>
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="header">#</TableCell>
                  <TableCell className="header">Channel</TableCell>
                  <TableCell className="header">Click-Throughs</TableCell>
                  <TableCell className="header">Visits</TableCell>
                  <TableCell className="header">CTR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Users.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="data">{item.id}</TableCell>
                    <TableCell className="data">
                      <Box className="info-box">
                        <Box className="img-box">
                          <img src={item.picture} alt="" />
                        </Box>
                        <Box className="name-box">
                          <h5>{item.name}</h5>
                          <p>{item.desc}</p>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell className="data">{item.clicks}</TableCell>
                    <TableCell className="data">{item.visits}</TableCell>
                    <TableCell className="data">{item.ctr}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </MainReportBox>
    </>
  );
};

export default Report;
