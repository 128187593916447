import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "../mycontracts.module.css";

import LicenseDropZone from "./LicenseDropZone";
import LicenseTable from "./LicenseTable";
import LicenseForm from "./LicenseForm";

function LicenseComponent() {
  const [picture, setPicture] = useState("");
  const [showEvelope, setShowEvelopeType] = useState(false);
  const [envelopsType, setEnvelopeType] = useState("Select ...");
  const [recipientCount, setRecipientCount] = useState(["1"]);
  const [showRoleTypes, setShowRoleTypes] = useState([false]);
  const [roleTypes, setRoleTypes] = useState(["Select ..."]);

  const envelopRef = useRef(null);
  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      console.log(currentFile);
      const currentFileName = currentFile.name;

      if (!currentFileName.match(/.(jpg|jpeg|png|gif|svg|pdf|docx|doc)$/i)) {
        console.log("not accepted");
        return false;
      }
      return true;
    }
  };

  const handleClickOutside = (event) => {
    if (envelopRef.current && !envelopRef.current.contains(event.target)) {
      setShowEvelopeType(false);
    }
  };


  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEvelopeType = (type) => {
    setEnvelopeType(type);
    setShowEvelopeType(false);
  };

  const handleRoleType = (type, id) => {
    let roleTypeStream = [...roleTypes];
    roleTypeStream[id] = type;
    setRoleTypes(roleTypeStream);
    handleShowRoleType(id);
  };
  const handleShowRoleType = (id) => {
    let showRoleTypeStream = [...showRoleTypes];
    showRoleTypeStream[id] = !showRoleTypeStream[id];
    setShowRoleTypes(showRoleTypeStream);
  };

  return (
    <div>
      <div className={styles.template_container}>
        <div className={styles.fileUpload_layout}>
            <LicenseDropZone />
        </div>
        {/* <LicenseForm /> */}
        <LicenseTable />

      </div>
      <div className={styles.sidebar_bottom_button_container}>
        <button className={styles.sidebar_bottom_button}>Next</button>
      </div>
    </div>
  );
}
export default LicenseComponent;
