import React from "react";
import { Grid } from "@mui/material";

// import styles from "./styles.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";

import ConnectMeComponent from "../../components/ConnectComponents/ConnectMeComponent";

const Connect = () => {
  return (
    <Grid>
      <Sidebar activeText="Connect" childComponent={<ConnectMeComponent />} />
    </Grid>
  );
};

export default Connect;
