import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CreatorWrapper = styled(Box)(({ theme }) => ({
    background: '#0D120F',
    width: 700,
    padding: 40,
    p: {
        fontSize: 14,
        span: {
            color: '#2EBB55',
            fontSize: 12
        }
    }
}));
