import { useReducer } from "react";
import { toast } from "react-toastify";
import {
  downloadAll,
  uploadImages,
  uploadTrack,
  documentUpload,
} from "../apis/files";

const initialArgs = {
  data: {},
};

function reducer(state, action) {
  return { ...state, ...action };
}

const useFile = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const uploadFile = async (payload) => {
    try {
      const result = await uploadImages(payload);
      return result;
    } catch (error) {
      console.info("@uploadFile:  Error while uploading image => ", error);
      toast.error("Invalid file upload");
      return error;
    }
  };

  const uploadAudio = async (payload) => {
    try {
      const result = await uploadTrack(payload);
      return result;
    } catch (error) {
      console.info(
        "@uploadFile:  Error while uploading audio track => ",
        error
      );
      throw error;
    }
  };

  const downloadAllBeat = async (payload) => {
    try {
      const result = await downloadAll(payload);
      return result;
    } catch (error) {
      console.info(
        "@downloadAllBeat:  Error while downloading all beats => ",
        error
      );
      return error;
    }
  };

  const uploadDocument = async (payload) => {
    try {
      const result = await documentUpload(payload);
      return result;
    } catch (error) {
      console.info("Error uploading document", error);
      return error;
    }
  };

  return {
    ...state,
    setState,
    uploadFile,
    uploadAudio,
    downloadAllBeat,
    uploadDocument,
  };
};

export default useFile;
