import { GraphQLClient } from "graphql-request";

export default async function graphQLRequest(query, variables) {
  const endpoint = `${process.env.REACT_APP_OPPORTUNITY_BACKEND_URL}/graphql`;
  let token = JSON.parse(localStorage.getItem("userData"));
  // try {
  const graphQLClient = new GraphQLClient(endpoint, {
    timeout: 20000,
    headers: {
      authorization: token,
    },
  });
  return new Promise((resolve, reject) => {
    graphQLClient
      .request(query, variables)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
  // console.log('graphql client..', graphQLClient);
  // const data = graphQLClient.request(query, variables);
  //     return data;
  // } catch (err) {
  //     console.log('error-fetching-graphql', err);
  //     return err;
  // }
}
export async function graphQLAdminUserRequest(query, variables) {
  const endpoint = `${process.env.REACT_APP_ADMIN_URL}/adminapis`;
  let token = localStorage.getItem("token");
  // try {
  const graphQLClient = new GraphQLClient(endpoint, {
    timeout: 20000,
    headers: {
      authorization: token,
    },
  });
  return new Promise((resolve, reject) => {
    graphQLClient
      .request(query, variables)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}

export async function graphQLAdminUserUpdateMembershipRequest(
  query,
  variables,
) {
  const endpoint = `${process.env.REACT_APP_USER_URL}/user`;
  let token = localStorage.getItem("token");
  // try {
  const graphQLClient = new GraphQLClient(endpoint, {
    timeout: 20000,
    headers: {
      authorization: token,
    },
  });
  return new Promise((resolve, reject) => {
    graphQLClient
      .request(query, variables)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}

export async function graphQLAuthRequest(query, variables) {
  const endpoint = `${process.env.REACT_APP_USER_URL}/user`;
  // try {
  const graphQLClient = new GraphQLClient(endpoint, {
    timeout: 20000,
  });
  return new Promise((resolve, reject) => {
    graphQLClient
      .request(query, variables)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}

export async function graphQLUserRequest(query, variables) {
  const endpoint = `${process.env.REACT_APP_USER_URL}/user`;
  let token = localStorage.getItem("token");
  const graphQLClient = new GraphQLClient(endpoint, {
    timeout: 20000,
    headers: {
      authorization: token,
    },
  });
  return new Promise((resolve, reject) => {
    graphQLClient
      .request(query, variables)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}
