import React from "react";
import { Box, Button, Modal } from "@mui/material";
import { ModelContentBox } from "./style/MusicianSplitModelStyle";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SplitSelect from "./selects/SplitSelect";
// import SplitSelect from "../../react_select/splitselect/SplitSelect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "#1C1B1B",
  pt: 2,
  px: 4,
  pb: 3,
};
const MusicianMember = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

const MusicianSplitModel = ({
  open,
  setOpen = () => {},
  SelectedInputs = () => {},
  setBioOpen = () => {},
}) => {
  const MusicianSplitClose = () => {
    setOpen(!open);
  };

  const HandlerOnsubmit = () => {
    setOpen(false);
    setBioOpen(true);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={MusicianSplitClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="main"
          sx={{
            ...style,
            width: { xs: "98%", sm: "600px" },
            padding: "3rem 3.75rem 3.75rem 3.75rem",
            borderRadius: "20px",
            background:
              "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
            backdropFilter: "blur(200px)",
          }}
        >
          <Box className="header-icon-box">
            <ArrowBackIcon className="back-icon" onClick={MusicianSplitClose} />
            <CloseIcon className="close-icon" onClick={MusicianSplitClose} />
          </Box>
          <h5>Add Musician & Splits</h5>
          <Box className="input-main">
            <SplitSelect
              label="Number of collaborators:"
              options={MusicianMember}
              placeholder="Choose number of collaborators"
              onChange={SelectedInputs}
            />
          </Box>
          <Box className="btn-box">
            <Button
              variant="outlined"
              className="btn"
              onClick={MusicianSplitClose}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              className="btn green"
              onClick={HandlerOnsubmit}
            >
              submit
            </Button>
          </Box>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default MusicianSplitModel;
