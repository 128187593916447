import React from "react";
import styles from "./auth_pages.module.css";
import { useState } from "react";
import useAuth from "../../context/AuthContext";
import axios from "axios";
import SignUpForm from "./signup_flow/SignUp";
import SignIn from "./signin_flow/SignIn";

function AuthPages(props) {
  const { handleSearch, musoSearchData } = useAuth();
  const [keyword, setSearchKeyword] = useState("");

  const [musoArtistData, setMusoArtistData] = React.useState(null);
  //   const [searchData, setSearchData] = useState([]);

  const onSearchInputChange = (event) => {
    const keyword = event.target.value;
    handleSearch(keyword); // handleSearch returns a Promise, so use then() to handle the response
  };

  const handleProfileClick = (item) => {
    setMusoArtistData(item);
    handleSearch("");
  };

  const removeArtist = () => {
    setMusoArtistData(null);
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        {props.page === "signup" ? (
          <SignUpForm setCompleteAccount={() => {}} approve= {props.isApproved} />
        ) : (
          <SignIn />
        )}
      </div>
      <img
        className={styles.cover_image}
        src="/SignInBanner.jpeg"
        alt="Cover"
      ></img>
    </div>
  );
}

export default AuthPages;
