import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "../mycontracts.module.css";
import DashboardTable from "./DashboardTable";
import ContractItem from "./ContractItem";
import axios from "axios";
import { Notification } from "../../NotifyMsg/Notification";

function ContractDasboardComponent() {
  const [sentContracts, setSentContracts] = useState([]);
  const [receiveContracts, setReceiveContracts] = useState([]);
  const [draftContracts, setDraftContracts] = useState([]);
  const [completeContracts, setCompleteContracts] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [active, setActive] = useState("receive");

  useEffect(async () => {
    try {
      const config = {
        url: `${process.env.REACT_APP_CONTRACT_URL}/templates/dashboard`,
        method: "get",
        headers: {
          accesstoken: localStorage.getItem("token"),
        },
      };
      const response = await axios(config);
      setSentContracts(response.data.sent);
      setReceiveContracts(response.data.receives);
      setCompleteContracts(response.data.completed);
      setDraftContracts(response.data.pending);
      setActiveData(response.data.receives);
    } catch (err) {
      Notification("error", err.message);
    }
  }, []);
  return (
    <div>
      <div className={styles.template_container}>
        <div className={styles.contract_title_container}>
          {/* <p className={styles.contract_dashboard_title}>Manage Contracts</p> */}
          <div className={styles.contract_item_container}>
            <ContractItem
              iconName={"receive"}
              title={"Receive Contracts"}
              count={receiveContracts.length}
              isActive={active === "receive"}
              onClick={() => {
                setActiveData(receiveContracts);
                setActive("receive");
              }}
            />
            <ContractItem
              iconName={"sent"}
              title={"Sent Contracts"}
              count={sentContracts.length}
              isActive={active === "sent"}
              onClick={() => {
                setActiveData(sentContracts);
                setActive("sent");
              }}
            />
            <ContractItem
              iconName={"completed"}
              title={"Completed Contracts"}
              count={completeContracts.length}
              isActive={active === "completed"}
              onClick={() => {
                setActiveData(completeContracts);
                setActive("completed");
              }}
            />
            <ContractItem
              iconName={"draft"}
              title={"Draft Contracts"}
              isActive={active === "draft"}
              count={draftContracts.length}
              onClick={() => {
                setActiveData(draftContracts);
                setActive("draft");
              }}
            />
          </div>
        </div>

        <DashboardTable data={activeData} />
      </div>
    </div>
  );
}
export default ContractDasboardComponent;
