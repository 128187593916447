import React, { useEffect, useState } from "react";

import "react-tagsinput/react-tagsinput.css";
import CreateUrl from "./pages/createURL/CreateUrl";
import Evaluation from "./pages/evaluateLink/Index";

const Index = ({Role}) => {
  const [createUrlPage, setCreateUrlPage] = useState(true);
  const [data, setdata] = useState({});

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setCreateUrlPage(false);
    }
  }, [data]);

  return (
    <>
      {createUrlPage ? (
        <CreateUrl Role={Role} setdata={setdata} />
      ) : (
        <Evaluation BaseData={data} />
      )}
    </>
  );
};

export default Index;
