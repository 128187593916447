import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';

import { getUser } from '../../Hooks/Authentication/User/query';
import { updateUserProfile } from '../../../Graphql_services/UsersMutation';

import styles from './settings_tabs.module.css';
import { Loader } from '../../../components-old/Loader';
import RoleIdDropdown from '../../ProfileComponents/roleIdDropDown';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  firstname: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid first name')
    .max(40)
    .required('Required'),
  lastname: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid last name')
    .max(40)
    .required('Required'),
  userName: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid User name')
    .max(40)
    .required('Required'),
  roleId: yup.string().required('Required'),
  phoneno: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(7, 'too short')
    .max(15, 'too long'),
});

/**
 * Common Textbox with validation check
 * @param {*} name, control, label, placeholder and error object from react-hook-form
 * @returns React DOM
 */
const TextBox = ({ name, control, label, placeholder, errors }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div className={styles.userInfoLayout}>
          <p className={styles.userInfoTitle}>{label}</p>
          <div>
            <input
              className={styles.inputBoxText}
              placeholder={placeholder}
              type='text'
              {...field}
            />
            {errors[name]?.message ? (
              <span className={styles.error}>{errors[name].message}</span>
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    />
  );
};

export default function AccountSettings({ refetchUser }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [role, setRole] = useState({});
  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: '',
      lastname: '',
      userName: '',
      countrycode: "",
      roleId: '',
      phoneno: '',
      spotifyUrl: '',
      aboutMe: '',
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const [getProfileData] = useLazyQuery(getUser, {
    context: { clientName: 'user' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      console.info('getUser', data);
      if (data.getUser.success) {
        const keysMapper = [
          { key: 'firstname', state: 'firstname' },
          { key: 'lastname', state: 'lastname' },
          { key: 'userName', state: 'userName' },
          { key: "countrycode", state: "countrycode" },
          { key: 'phoneno', state: 'phoneno' },
          { key: 'spotifyUrl', state: 'spotifyUrl' },
          { key: 'aboutMe', state: 'aboutMe' },
          { key: 'roleId', state: 'roleId' },
        ];

        // Set existing values for user profile
        keysMapper.forEach(({ key, state }) => {
          if (key === 'roleId') {
            setRole(get(data, `getUser.user.${key}`, ''));
            setValue(state, get(data, `getUser.user.${key}.roleName`, ''), {
              shouldDirty: true,
            });
          }
          setValue(state, get(data, `getUser.user.${key}`, ''), {
            shouldDirty: true,
          });
        });
      }
      setIsFetching(false);
    },
    onError(err) {
      console.info('error on getting user info', err);
      setIsFetching(false);
    },
  });

  // UPDATE USER PROFILE DATA THOUGH MUTATION
  const [updateProfile] = useMutation(updateUserProfile, {
    onCompleted(data) {
      if (data.updateProfile?.success) {
        toast.success('Profile updated');
        localStorage.setItem(
          'userName',
          data.updateProfile?.updatedUser?.userName
        );
        localStorage.setItem('accountType', role.roleName);
        refetchUser();
      } else {
        toast.error('Profile not updated');
      }
      setIsLoading(false);
    },
    onError(err) {
      console.info('onError while updating profile => ', err);
      toast.error('Something went wrong!');
      setIsLoading(false);
    },
  });

  // STORE THE USER PROFILE DATA
  const onSubmit = (event) => {
    setIsLoading(true);

    const payload = {
      firstname: event.target.form.firstname.value,
      lastname: event.target.form.lastname.value,
      userName: event.target.form.userName.value,
      countrycode:  event.target.form.countrycode.value,
      phoneno: parseFloat( event.target.form.phoneno.value),
      spotifyUrl: event.target.form.spotifyUrl.value,
      aboutMe: event.target.form.aboutMe.value,
      roleId: role?._id,
    };

    updateProfile({
      variables: payload,
      context: { clientName: 'user' },
    });
  };

  useEffect(() => {
    setIsFetching(true);

    // FETCH THE USER PROFILE DATA
    getProfileData();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formWrapper}>
        {isFetching && (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        )}
        <div className={styles.account_info_container}>
          <TextBox
            control={control}
            errors={errors}
            name={'firstname'}
            label='First Name'
            placeholder={'First Name'}
          />
          <TextBox
            control={control}
            errors={errors}
            name={'lastname'}
            label='Last Name'
            placeholder={'Last Name'}
          />
          <TextBox
            control={control}
            errors={errors}
            name={'userName'}
            label='Display Name'
            placeholder={'Display Name'}
          />
          <TextBox
            control={control}
            errors={errors}
            name={"countrycode"}
            label="Country"
            placeholder={"Country"}
          />
          <TextBox
            control={control}
            errors={errors}
            name={'phoneno'}
            label='Mobile Number'
            placeholder={'Mobile Number'}
          />
          <TextBox
            control={control}
            errors={errors}
            name={'spotifyUrl'}
            label='Spotify Url'
            placeholder={'Spotify Url'}
          />

          <div>
            <p
              style={{
                marginBottom: '10px',
              }}
              className={styles.userInfoTitle}
            >
              {'Role'}
            </p>
            <RoleIdDropdown
              // role={getValues("roleId.roleName")}
              // name={"roleId"}
              // errors={errors}
              // label="RoleId"
              // placeholder={"RoleId"}
              // control={control}
              role={role}
              setRole={setRole}
              setValue={setValue}
              getValues={getValues}
              // isFetching={isFetching}
            />
          </div>
        </div>
        <div></div>
        <div style={{ marginTop: 10 }}>
          <TextBox
            control={control}
            errors={errors}
            name={'aboutMe'}
            label='About Me'
            placeholder={'About Me'}
          />
        </div>
      </div>
      <div className={styles.settingBtnWrapper}>
        <button type='submit' disabled={isLoading} onClick={onSubmit} className={styles.editBtn}>
          {isLoading ? `Updating...` : `Update`}
        </button>
      </div>
    </form>
  );
}
