export const getChartaUrl = async (data) => {
  const url = "https://api.unitedmarket.chartacontract.net/auth/getChartaUrl";
  var myHeaders = new Headers();
  myHeaders.append("key", "eShVmYq3t6w9z$C&F)J@NcRfTjWnZr4u");
  myHeaders.append("Content-Type", "application/json");

  const config = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(url, config)
      .then((res) => res.text())
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
