import { gql } from '@apollo/client';

export const uploadSoundPack = gql`
  mutation uploadSoundKit(
    $soundPackCover: String
    $soundPackTitle: String
    $soundPackKitType: String
    $soundPackKey: String
    $soundPackBPM: String
    $soundPackFile: String
    $soundPackZipFile: String
    $soundPackPrice:Float
    $soundPackIsRoyaltyFree:Boolean
    $soundPackDescription: String
    $tags: [taginput]
    $collaborators: [collaboratorinput]
    $isCustomTermsAndCodition: Boolean
    $termsAndCondition: String
  ) {
    uploadSoundKit(
      soundPackCover: $soundPackCover
      soundPackTitle: $soundPackTitle
      soundPackKitType: $soundPackKitType
      soundPackKey: $soundPackKey
      soundPackBPM: $soundPackBPM
      soundPackFile: $soundPackFile
      soundPackZipFile: $soundPackZipFile
      soundPackPrice:$soundPackPrice
      soundPackIsRoyaltyFree:$soundPackIsRoyaltyFree
      soundPackDescription: $soundPackDescription
      tags: $tags
      collaborators: $collaborators
      isCustomTermsAndCodition: $isCustomTermsAndCodition
      termsAndCondition: $termsAndCondition
    ) {
      success
      message
      isTokenExpired
      soundpack {
        soundPackKey
        soundPackBPM
        soundPackTitle
        soundPackPrice
        soundPackIsRoyaltyFree
      }
    }
  }
`;
export const editMySoundPack = gql`
  mutation editMySoundPack(
    $soundPackId: ID
    $soundPackCover: String
    $soundPackTitle: String
    $soundPackKitType: String
    $soundPackKey: String
    $soundPackBPM: String
    $soundPackFile: String
    $soundPackZipFile: String
    $soundPackPrice:Float
    $soundPackIsRoyaltyFree:Boolean
    $soundPackDescription: String
    $tags: [taginput]
    $collaborators: [collaboratorinput]
    $isCustomTermsAndCodition: Boolean
    $termsAndCondition: String
  ) {
    editMySoundPack(
      soundPackId: $soundPackId
      soundPackCover: $soundPackCover
      soundPackTitle: $soundPackTitle
      soundPackKitType: $soundPackKitType
      soundPackKey: $soundPackKey
      soundPackBPM: $soundPackBPM
      soundPackFile: $soundPackFile
      soundPackZipFile: $soundPackZipFile
      soundPackPrice:$soundPackPrice
      soundPackIsRoyaltyFree:$soundPackIsRoyaltyFree
      soundPackDescription: $soundPackDescription
      tags: $tags
      collaborators: $collaborators
      isCustomTermsAndCodition: $isCustomTermsAndCodition
      termsAndCondition: $termsAndCondition
    ) {
      success
      message
      isTokenExpired
      soundpack {
        soundPackKey
        soundPackBPM
        soundPackTitle
      }
    }
  }
`;

export const getSoundPacks = gql`
  query {
    getSoundPacks(pageNo: null) {
      success
      message
      isTokenExpired
      soundpacks {
        _id
        soundPackKey
        soundPackBPM
        soundPackFile
        soundPackZipFile
        soundPackCover
        soundPackTitle
        soundPackPrice
        soundPackIsRoyaltyFree
        soundPackDescription
        upvotes
        tags {
          type
          value
        }
        collaborators {
          email
          musicianName
          split
        }
        createdBy
        upvotedBy
        isCustomTermsAndCodition
        termsAndCondition
        createdAt
        updatedAt
        totalDownloads
        userInfo {
          firstname
          lastname
          email
          userName
          profilePic
        }
      }
    }
  }
`;

export const getMySoundPackById = gql`
  query ($soundPackId: String) {
    getMySoundPackById(soundPackId: $soundPackId) {
      success
      message

      isTokenExpired
      soundpack {
        _id
        soundPackKey
        soundPackBPM
        soundPackFile
        soundPackZipFile
        soundPackCover
        soundPackTitle
        soundPackKitType
        soundPackPrice
        soundPackIsRoyaltyFree
        soundPackDescription
        upvotes
        tags {
          type
          value
        }
        collaborators {
          email
          musicianName
          split
        }
        isCustomTermsAndCodition
        termsAndCondition
        createdAt
        updatedAt
        upvotedBy
        createdBy
        totalDownloads
        userInfo {
          firstname
          lastname
          email
          userName
          profilePic
        }
      }
    }
  }
`;

export const getMySoundPacks = gql`
  query getMySoundPacks($pageNO: Float) {
    getMySoundPacks(pageNo: $pageNO) {
      success
      message
      soundPacksPages
      isTokenExpired
      soundpacks {
        _id
        soundPackKey
        soundPackBPM
        soundPackFile
        soundPackZipFile
        soundPackCover
        soundPackTitle
        soundPackKitType
        soundPackPrice
        soundPackIsRoyaltyFree
        soundPackDescription
        upvotes
        tags {
          type
          value
        }
        collaborators {
          email
          musicianName
          split
        }
        isCustomTermsAndCodition
        termsAndCondition
        createdAt
        updatedAt
        upvotedBy
        createdBy
        totalDownloads
        userInfo {
          firstname
          lastname
          email
          userName
          profilePic
        }
      }
    }
  }
`;
export const getSoundPacksBySort = gql`
  query getSoundPacksBySort($filterType: String) {
    getSoundPacksBySort(filterType: $filterType, pageNo: 1, genre: null) {
      success
      message
      isTokenExpired
      soundpacks {
        _id
        soundPackKey
        soundPackBPM
        soundPackFile
        soundPackZipFile
        soundPackCover
        soundPackTitle
        soundPackPrice
        soundPackIsRoyaltyFree
        soundPackDescription
        upvotes
        tags {
          type
          value
        }
        collaborators {
          email
          musicianName
          split
        }
        createdBy
        upvotedBy
        isCustomTermsAndCodition
        termsAndCondition
        createdAt
        updatedAt
        totalDownloads
        userInfo {
          firstname
          lastname
          email
          userName
          profilePic
        }
      }
    }
  }
`;

export const previewOfCollaborators = gql`
  query previewOfCollaborators($collaborators: [collaboratorinput]) {
    previewOfCollaborators(collaborators: $collaborators) {
      success
      message
      isTokenExpired
      isSplitExceed
      collaborators {
        isMySplit
        firstname
        lastname
        userName
        email
        split
        musicianName
        profilePic
        roleId {
          roleName
        }
      }
    }
  }
`;

export const getCollaboratorsOfSoundPack = gql`
  query getCollaboratorsOfSoundPack($soundPackId: ID) {
    getCollaboratorsOfSoundPack(soundPackId: $soundPackId) {
      success
      message
      isTokenExpired
      collaborators {
        isMySplit
        email
        profilePic
        split
        musicianName
        roleId {
          roleName
        }
      }
    }
  }
`;
export const deleteMySoundPack = gql`
  mutation deleteMySoundPack($soundPackId: ID) {
    deleteMySoundPack(soundPackId: $soundPackId) {
      success
      message
      isTokenExpired
    }
  }
`;

export const upvoteDownvoteSoundPack = gql`
  mutation upvoteDownvoteSoundPack($soundPackId: ID) {
    upvoteDownvoteSoundPack(soundPackId: $soundPackId) {
      success
      message
      isTokenExpired
      soundpack {
        upvotes
        upvotedBy
      }
    }
  }
`;

export const getTags = gql`
  query {
    getAllTags {
      success
      message
      tags {
        type
        value
      }
      Moods
      Artists
      Instruments
      Genres
      isTokenExpired
    }
  }
`;
export const searchTag = gql`
  query getAllTags($searchQuery: String) {
    getAllTags(searchQuery: $searchQuery) {
      success
      message
      tags {
        type
        value
      }
      Moods
      Artists
      Instruments
      Genres
      isTokenExpired
    }
  }
`;
export const addTag = gql`
  mutation addTag($type: String, $value: String) {
    addTag(type: $type, value: $value) {
      success
      message
      tag {
        type
        value
      }
      isTokenExpired
    }
  }
`;
export const onSoundPackDownload = gql`
  mutation upvoteDownvoteSoundPack($soundPackId: ID) {
    onSoundPackDownload(soundPackId: $soundPackId) {
      success
      message
      isTokenExpired
    }
  }
`;
