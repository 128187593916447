import React, { memo, useState } from "react";
import { Box, Modal } from "@mui/material";
import { ModelContentBox } from "./styles/PaymentCheckoutModelStyle";
import CloseIcon from "@mui/icons-material/Close";
import PaymentForm from "../PaymentForm";
// import PaymentContainer from "./PaymentContainer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "#141515",
  pt: 2,
  px: 4,
  pb: 3,
};

const PaymentCheckoutModel = ({
  open = false,
  setOpen = () => {},
  setDetailOpen = () => {},
  selectedplanid = "",
  setpaymentDetais = () => {},
}) => {
  const PaymentCheckoutModelClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };

  const HandelPopup = () => {
    setOpen(false);
    setDetailOpen(true);
  };

  const [paymentDisable, setpaymentDisable] = useState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={PaymentCheckoutModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="main"
          sx={{
            ...style,
            width: { xs: "100%", sm: "504px" },
            padding: "30px 40px 60px 40px",
            borderRadius: "20px",
            background: "#141515",
            overflowY: "auto",
            maxHeight: "80vh",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Box className="header-icon-box">
            <button
              onClick={PaymentCheckoutModelClose}
              disabled={paymentDisable}
              style={{ background: "transparent", padding: 0, border: "none" }}
            >
              <CloseIcon className="close-icon" />
            </button>
          </Box>

          <PaymentForm
            HandelPopup={HandelPopup}
            selectedplanid={selectedplanid}
            setpaymentDetais={setpaymentDetais}
            setpaymentDisable={setpaymentDisable}
          />
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(PaymentCheckoutModel);
