import * as React from "react";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import modalStyles from '../../Libraries/Libraries.module.css'

function valuetext(value) {
  return `${value}`;
}

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 0,
    label: "0",
  },
  {
    value: 200,
    label: "200",
  },
];


export const  BPMRangeSlider = ({range, setRange}) => {

  const handleChange = (event, newValue) => {
    console.log('Current Value', newValue);
    setRange(newValue);
  };
  return (
    <Stack sx={{ padding: "0px 10px" }}>
      
      <Slider
        getAriaLabel={() => "BPM"}
        
        getAriaValueText={valuetext}
        value={range}
        valueLabelDisplay="auto"
        min={0}
        max={200}
        step={1}
        marks={marks}
        onChange={handleChange}
        sx={{
          color: "#2EBB55", // Color of the slider track and thumb
          // To change the color of the labels that appear next to the marks
          "& .MuiSlider-markLabel": {
            color: "#afafaf", // Replace 'blue' with any desired color
          },
          // To change the color of the popup value label
          "& .MuiSlider-valueLabel": {
            color: "white", // Set the text color to white for the popup label
            // If you wish to change the background color of the popup label:
            backgroundColor: "green", // Replace 'green' with any desired color
          },
        }}
      />
    </Stack>
  );
}
