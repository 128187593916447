import { gql } from '@apollo/client';

export const addUpdateBillingInfo = gql`
	mutation addUpdateBillingInformation(
		$country: String
		$state: String
		$address: String
		$zipCode: String
	) {
		addUpdateBillingInformation(
			country: $country
			state: $state
			address: $address
			zipCode: $zipCode
		) {
			success
			message
			isTokenExpired
			billingInformation {
				country
				state
				address
				zipCode
			}
		}
	}
`;
export const getMyBillingInfo = gql`
	query {
		getMyBillingInformations {
			success
			message
			isTokenExpired
			invoices{
				id
				account_name
				amount_due
				amount_paid
				amount_remaining
				created
				currency
				status
			}
			billingInformation {
				country
				address
				zipCode
				state
			}
		}
	}
`;
