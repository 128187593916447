import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const Memberssection = styled(Box)(({ theme }) => ({
  //   border: "1px solid white",
  width: "91.5%",
  margin: "auto",
  "& .LogoGrid": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export { Memberssection };
