import React from "react";

const RepeatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_13575_46963)">
        <path
          d="M4.06033 12.6667H12.0603L11.1937 13.5267C11.0681 13.6522 10.9976 13.8225 10.9976 14C10.9976 14.1775 11.0681 14.3478 11.1937 14.4733C11.3192 14.5989 11.4895 14.6694 11.667 14.6694C11.8445 14.6694 12.0148 14.5989 12.1403 14.4733L14.1403 12.4733C14.2028 12.4114 14.2524 12.3376 14.2863 12.2564C14.3201 12.1751 14.3375 12.088 14.3375 12C14.3375 11.912 14.3201 11.8249 14.2863 11.7436C14.2524 11.6624 14.2028 11.5886 14.1403 11.5267L12.1403 9.52667C12.0784 9.46418 12.0046 9.41458 11.9234 9.38074C11.8421 9.34689 11.755 9.32947 11.667 9.32947C11.579 9.32947 11.4918 9.34689 11.4106 9.38074C11.3294 9.41458 11.2556 9.46418 11.1937 9.52667C11.1312 9.58864 11.0816 9.66238 11.0477 9.74362C11.0139 9.82485 10.9965 9.91199 10.9965 10C10.9965 10.088 11.0139 10.1751 11.0477 10.2564C11.0816 10.3376 11.1312 10.4114 11.1937 10.4733L12.0603 11.3333H4.06033C3.92374 11.336 3.78798 11.3117 3.66078 11.2619C3.53359 11.212 3.41746 11.1376 3.31902 11.0429C3.22059 10.9482 3.14178 10.835 3.08709 10.7098C3.03241 10.5846 3.00293 10.4499 3.00033 10.3133V8.66667C3.00033 8.48986 2.93009 8.32029 2.80506 8.19526C2.68004 8.07024 2.51047 8 2.33366 8C2.15685 8 1.98728 8.07024 1.86225 8.19526C1.73723 8.32029 1.66699 8.48986 1.66699 8.66667V10.3133C1.66961 10.625 1.73359 10.9331 1.85528 11.2201C1.97698 11.507 2.154 11.7672 2.37624 11.9857C2.59849 12.2042 2.8616 12.3768 3.15056 12.4937C3.43951 12.6105 3.74865 12.6693 4.06033 12.6667Z"
          fill="#AFAFAF"
        />
        <path
          d="M3.86028 6.47394C3.98582 6.59948 4.15608 6.67 4.33362 6.67C4.51115 6.67 4.68142 6.59948 4.80695 6.47394C4.93249 6.34841 5.00301 6.17814 5.00301 6.00061C5.00301 5.82308 4.93249 5.65281 4.80695 5.52728L3.94028 4.66728H11.9403C12.0769 4.66463 12.2126 4.68891 12.3398 4.73874C12.467 4.78857 12.5832 4.86297 12.6816 4.95769C12.78 5.05241 12.8588 5.16559 12.9135 5.29078C12.9682 5.41596 12.9977 5.55069 13.0003 5.68728V7.33394C13.0003 7.51076 13.0705 7.68032 13.1955 7.80535C13.3206 7.93037 13.4901 8.00061 13.667 8.00061C13.8438 8.00061 14.0133 7.93037 14.1384 7.80535C14.2634 7.68032 14.3336 7.51076 14.3336 7.33394V5.68728C14.331 5.3756 14.267 5.0675 14.1453 4.78055C14.0236 4.49361 13.8466 4.23344 13.6244 4.01491C13.4021 3.79638 13.139 3.62377 12.8501 3.50693C12.5611 3.39009 12.252 3.33131 11.9403 3.33394H3.94028L4.80695 2.47394C4.86944 2.41197 4.91903 2.33823 4.95288 2.25699C4.98672 2.17576 5.00415 2.08862 5.00415 2.00061C5.00415 1.9126 4.98672 1.82547 4.95288 1.74423C4.91903 1.66299 4.86944 1.58925 4.80695 1.52728C4.74498 1.46479 4.67124 1.4152 4.59 1.38135C4.50876 1.3475 4.42163 1.33008 4.33362 1.33008C4.24561 1.33008 4.15847 1.3475 4.07723 1.38135C3.99599 1.4152 3.92226 1.46479 3.86028 1.52728L1.86028 3.52728C1.7978 3.58925 1.7482 3.66299 1.71436 3.74423C1.68051 3.82547 1.66309 3.9126 1.66309 4.00061C1.66309 4.08862 1.68051 4.17576 1.71436 4.25699C1.7482 4.33823 1.7978 4.41197 1.86028 4.47394L3.86028 6.47394Z"
          fill="#AFAFAF"
        />
      </g>
      <defs>
        <clipPath id="clip0_13575_46963">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RepeatIcon;
