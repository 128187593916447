import React, { useState, useContext, useEffect, useRef } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from "../mycontracts.module.css";
import TrashIcon from "../../../assets/trash.png";
import EditIcon from "../../../assets/edit-2.png";
import AddIcon from "../../../assets/add-square.png";
import MaskGroupIcon from "../../../assets/Mask-group.png";
import DropDownIcon from "../../../assets/Icons/DropDownIconWhite.png";
import AudioFileUploadIcon from "../../../assets/recip-audio-file.png";
import PdfIcon from "../../../assets/pdfIcon.png";

//import { DropzoneAreaBase } from "@material-ui-dropzone";

function EditTemplate() {
  const [picture, setPicture] = useState("");
  const [showEvelope, setShowEvelopeType] = useState(false);
  const [envelopsType, setEnvelopeType] = useState("Select ...");
  const [showRoleTypes, setShowRoleTypes] = useState([false]);
  const [roleTypes, setRoleTypes] = useState(["Select ..."]);
  const [roleNames, setRoleNames] = useState([""]);
  const [roleEmails, setRoleEmails] = useState([""]);
  const [uploadFile, setUploadFile] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const [templateTitle, setTemplateTitle] = useState("");

  const router = useNavigate();
  const { id } = useParams();
  const envelopRef = useRef(null);

  useEffect(async () => {
    const response = await axios.get(`http://localhost:5000/templates/${id}`);
    console.log(response.data);
    setSelectedTemplate(response.data);
    setRoleTypes(response.data.roleTypes);
    setRoleNames(response.data.roleNames);
    setRoleEmails(response.data.roleEmails);
    setTemplateTitle(response.data.title);
    setShowRoleTypes(new Array(response.data.roleTypes.length).fill(false));
  }, []);

  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      console.log(currentFile);
      const currentFileName = currentFile.name;

      if (!currentFileName.match(/.(jpg|jpeg|png|gif|svg|pdf|docx|doc)$/i)) {
        console.log("not accepted");
        return false;
      }
      return true;
    }
  };

  const handleClickOutside = (event) => {
    //   if (envelopRef.current && !envelopRef.current.contains(event.target)) {
    //     setShowEvelopeType(false);
    //   }
  };

  const addRecipients = () => {
    let showRoleTypeStream = [...showRoleTypes];
    showRoleTypeStream.push(false);
    setShowRoleTypes(showRoleTypeStream);

    let roleTypeStream = [...roleTypes];
    roleTypeStream.push("Select ...");
    setRoleTypes(roleTypeStream);

    let roleNameStream = [...roleNames];
    roleNameStream.push("");
    setRoleNames(roleNameStream);

    let roleEmailStream = [...roleEmails];
    roleEmailStream.push("");
    setRoleEmails(roleEmailStream);
  };

  const deleteRecipients = (id) => {
    setShowRoleTypes(showRoleTypes.filter((item, index) => index !== id));
    setRoleTypes(roleTypes.filter((item, index) => index !== id));
    setRoleNames(roleNames.filter((item, index) => index !== id));
    setRoleEmails(roleEmails.filter((item, index) => index !== id));
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const uploadTemplateDoc = async (file) => {
    var data = new FormData();
    console.log(file);
    data.append("file", file);
    data.append("type", "Public");
    var config = {
      method: "post",
      url: `http://localhost:5000/templates/upload`,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        // setValue("profilePic", response.data.file.url, {
        //   shouldValidate: true,
        // });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleOnDropLogoImage = async (files, rejectedFiles) => {
    if (!verifyImageFile(files)) return;
    if (files && files[0]) {
      // await uploadTemplateDoc(files[0])
      setUploadFile(files[0]);
      let reader = new FileReader();
      reader.onloadend = function () {
        setPicture({
          filename: files[0].name,
          filetype: files[0].type,
          fileimage: reader.result,
          datetime: files[0].lastModifiedDate.toLocaleString("en-IN"),
          filesize: filesizes(files[0].size),
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };
  const createTemplate = async () => {
    var data = new FormData();
    console.log(uploadFile);
    data.append("file", uploadFile);
    data.append("roleTypes", roleTypes);
    data.append("roleNames", roleNames);
    data.append("roleEmails", roleEmails);
    data.append("title", templateTitle);
    data.append("type", "Public");
    data.append("owner", "Ilarion");

    if (uploadFile === "") {
      return;
    }
    for (let i = 0; i < roleTypes.length; i++) {
      if (roleTypes[i] === "") return;
    }
    var config = {
      method: "post",
      url: `http://localhost:5000/templates/create`,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        console.log(response.data);
        window.location.assign(response.data.createUrl);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleEvelopeType = (type) => {
    setEnvelopeType(type);
    setShowEvelopeType(false);
  };

  const handleRoleType = (type, id) => {
    let roleTypeStream = [...roleTypes];
    roleTypeStream[id] = type;
    setRoleTypes(roleTypeStream);
    handleShowRoleType(id);
  };

  const handleShowRoleType = (id) => {
    let showRoleTypeStream = [...showRoleTypes];
    showRoleTypeStream[id] = !showRoleTypeStream[id];
    setShowRoleTypes(showRoleTypeStream);
  };

  const handleRoleName = (e, id) => {
    const name = e.target.value;
    let roleNameStream = [...roleNames];
    roleNameStream[id] = name;
    setRoleNames(roleNameStream);
  };
  const handleRoleEmail = (e, id) => {
    const email = e.target.value;
    let roleEmailStream = [...roleEmails];
    roleEmailStream[id] = email;
    setRoleEmails(roleEmailStream);
  };

  return (
    <div>
      <div className={styles.template_container}>
        <div className={styles.middle_title_layout}>
          <div className={styles.middle_title_font}>
            <p className={styles.middle_title_recip}>Title</p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <input
              type="text"
              className={styles.recipient_user_name}
              style={{ width: "100%" }}
              value={templateTitle}
              onChange={(e) => setTemplateTitle(e.target.value)}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label className={styles.recipient_group_user_label}>
            Description
          </label>
          <textarea type="text" className={styles.email_message} rows={5} />
          {/* <label
            style={{
              fontSize: 12,
              lineHeight: 1.5,
              fontWeight: 400,
              height: 18,
              color: "#E3E3E3",
            }}
          >
            Character remaining:10000
          </label> */}
        </div>

        <div className={styles.fileUpload_layout}>
          <Dropzone
            onDrop={handleOnDropLogoImage}
            onFileDialogCancel={() => setPicture("")}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={styles.imageDropzone}>
                {picture ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {picture.filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                      <div className={styles.profile_file_image}>
                        <img src={picture.fileimage} />
                      </div>
                    ) : (
                      <div className={styles.license_file_image}>
                        <img src={PdfIcon} />
                      </div>
                    )}
                    <div className={styles.profile_file_detail}>
                      <h6>{picture.filename}</h6>
                      <p>
                        <span>Size : {picture.filesize}</span>{" "}
                        <span>Modified Time : {picture.datetime}</span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <img
                      className={styles.profile_pic_upload_image}
                      src={MaskGroupIcon}
                      alt="DefaultProfile"
                    />
                    <p className={styles.auth_title}>
                      Drag & Drop a file to upload
                    </p>
                  </>
                )}

                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </div>

        <div className={styles.middle_title_layout}>
          <div className={styles.middle_title_font}>
            <p className={styles.middle_title_recip}>Recipients</p>
            <button className={styles.add_recip_button} onClick={addRecipients}>
              <img src={AddIcon} />
              Add more recipients
            </button>
          </div>

          {showRoleTypes.map((item, index) => (
            <div key={index} className={styles.recipient_group}>
              <div className={styles.recipient_rectangle} />
              <div className={styles.recipient_form_group}>
                <div className={styles.user_name_group}>
                  <label className={styles.user_name_label}>User Name</label>
                  <button
                    className={styles.user_name_delete}
                    onClick={() => deleteRecipients(index)}
                  >
                    Delete{" "}
                    <img src={TrashIcon} style={{ width: 20, height: 20 }} />
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 24,
                      width: "50%",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        Name
                      </label>
                      <input
                        type="text"
                        className={styles.recipient_user_name}
                        value={roleNames[index]}
                        onChange={(e) => handleRoleName(e, index)}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        Email
                      </label>
                      <input
                        type="email"
                        className={styles.recipient_user_name}
                        value={roleEmails[index]}
                        onChange={(e) => handleRoleEmail(e, index)}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_role_type_label}>
                        Role Type
                      </label>
                      <div
                        className={styles.recipient_role_type_group}
                        ref={envelopRef}
                      >
                        <div
                          className={styles.role_type_nav_item}
                          onClick={() => handleShowRoleType(index)}
                        >
                          <div className={styles.account_info}>
                            <p className={styles.account_name}>
                              {roleTypes[index]}
                            </p>
                          </div>
                          <img
                            src={DropDownIcon}
                            alt="dropdown"
                            className={`${styles.dropdown_icon_icon} ${
                              showRoleTypes[index] ? styles.rotate_180 : ""
                            }`}
                          ></img>
                        </div>
                        {showRoleTypes[index] && (
                          <div className={styles.role_type_menu}>
                            <div
                              className={styles.menu_item}
                              onClick={() => handleRoleType("Manager", index)}
                            >
                              <p className={styles.menu_item_text}>Manager</p>
                            </div>
                            <div
                              className={styles.menu_item}
                              onClick={() => handleRoleType("Label", index)}
                            >
                              <p className={styles.menu_item_text}>Label</p>
                            </div>
                            <div
                              className={styles.menu_item}
                              onClick={() => handleRoleType("Pulisher", index)}
                            >
                              <p className={styles.menu_item_text}>Pulisher</p>
                            </div>
                            <div
                              className={styles.menu_item}
                              onClick={() => handleRoleType("Artist", index)}
                            >
                              <p className={styles.menu_item_text}>Artist</p>
                            </div>
                            <div
                              className={styles.menu_item}
                              onClick={() =>
                                handleRoleType("Music Producer", index)
                              }
                            >
                              <p className={styles.menu_item_text}>
                                Music Producer
                              </p>
                            </div>
                            <div
                              className={styles.menu_item}
                              onClick={() => handleRoleType("Lawyer", index)}
                            >
                              <p className={styles.menu_item_text}>Lawyer</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        Audio File
                      </label>
                      <div className={styles.recipient_user_name}>
                        <img src={AudioFileUploadIcon} />
                        <input type="file" style={{ visibility: "hidden" }} />
                      </div>
                    </div>
                  </div>
                  <img src={EditIcon} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.middle_title_layout}>
          <div className={styles.middle_title_font}>
            <p style={{ fontSize: 30, fontWeight: 500, margin: 0 }}>Document</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 24,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 24,
                width: "100%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={styles.recipient_group_user_label}>
                  Title
                </label>
                <input type="text" className={styles.recipient_user_name} />
                <label
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    fontWeight: 400,
                    height: 18,
                    color: "#E3E3E3",
                  }}
                >
                  Character remaining:52
                </label>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={styles.recipient_group_user_label}>
                  Message
                </label>
                <textarea
                  type="text"
                  className={styles.email_message}
                  rows={5}
                />
                <label
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    fontWeight: 400,
                    height: 18,
                    color: "#E3E3E3",
                  }}
                >
                  Character remaining:10000
                </label>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={styles.recipient_group_user_label}>
                  Envelops Types
                </label>
                {/* <select  className={styles.recipient_select} /> */}
                <div className={styles.enveloptype_group} ref={envelopRef}>
                  <div
                    className={styles.account_navbar_item}
                    onClick={() => setShowEvelopeType(!showEvelope)}
                  >
                    <div className={styles.account_info}>
                      <p className={styles.account_name}>{envelopsType}</p>
                    </div>
                    <img
                      src={DropDownIcon}
                      alt="dropdown"
                      className={`${styles.dropdown_icon_icon} ${
                        showEvelope ? styles.rotate_180 : ""
                      }`}
                    ></img>
                  </div>
                  {showEvelope && (
                    <div className={styles.menu}>
                      <div
                        className={styles.menu_item}
                        onClick={() => handleEvelopeType("Sales Contracts")}
                      >
                        <p className={styles.menu_item_text}>Sales Contracts</p>
                      </div>
                      <div
                        className={styles.menu_item}
                        onClick={() =>
                          handleEvelopeType("Employment Contracts")
                        }
                      >
                        <p className={styles.menu_item_text}>
                          Employment Contracts
                        </p>
                      </div>
                      <div
                        className={styles.menu_item}
                        onClick={() => handleEvelopeType("Lease Agreement")}
                      >
                        <p className={styles.menu_item_text}>Lease Agreement</p>
                      </div>
                      <div
                        className={styles.menu_item}
                        onClick={() => handleEvelopeType("Purchase Agreement")}
                      >
                        <p className={styles.menu_item_text}>
                          Purchase Agreement
                        </p>
                      </div>
                      <div
                        className={styles.menu_item}
                        onClick={() => handleEvelopeType("Service Agreement")}
                      >
                        <p className={styles.menu_item_text}>
                          Service Agreement
                        </p>
                      </div>
                      <div
                        className={styles.menu_item}
                        onClick={() =>
                          handleEvelopeType("Partnership Agreement")
                        }
                      >
                        <p className={styles.menu_item_text}>
                          Partnership Agreement
                        </p>
                      </div>
                      <div
                        className={styles.menu_item}
                        onClick={() => handleEvelopeType("Licensing Agreement")}
                      >
                        <p className={styles.menu_item_text}>
                          Licensing Agreement
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sidebar_bottom_button_container}>
        <button
          className={styles.sidebar_bottom_button}
          onClick={() => router('/my-contracts/templates')}
        >
          Prev
        </button>
        <button
          className={styles.sidebar_bottom_button}
          onClick={createTemplate}
        >
          Next
        </button>
      </div>
    </div>
  );
}
export default EditTemplate;
