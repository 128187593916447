import React, { memo } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Box, Button, Modal } from "@mui/material";
import { ModelContentBox } from "../../../../../components/smartlink/producers/producerssubmit/popups/style/CollaboratorInputsModelStyle";
import logo from "../../../../../assets/Logo/logo.png";
import { Main } from "./styles/InboxPasswordModelStyle";
import { CONFIRM_BEATBOX_PASSWORD } from "../../../../../Graphql_services/beatinbox/Beats";
import { useMutation } from "@apollo/client";
import { Notification } from "../../../../../components/NotifyMsg/Notification";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object().shape({
  Password: yup.string().required("Password is required"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "#1C1B1B",
  height: "600px",
  // display: "block",
  pt: 2,
  px: 4,
  pb: 3,
};

const PrivateInboxPasswordModel = ({
  open = false,
  setOpen = () => {},
  BeatInfo = [],
}) => {
  const navigate = useNavigate();

  const [confirmBeatBoxPassword, { data, loading, error }] = useMutation(
    CONFIRM_BEATBOX_PASSWORD
  );
  const HandlerSubmit = async (values, { resetForm }) => {
    try {
      const result = await confirmBeatBoxPassword({
        variables: {
          inboxId: BeatInfo?._id,
          password: values.Password,
        },
        context: { clientName: "beats" },
      });
      const Data = result?.data?.confirmBeatBoxPassword;
      if (Data?.success) {
        Notification("success", Data?.message);
        navigate(`/shared-beat-create/${BeatInfo?._id}`);
        resetForm();
      } else {
        Notification("error", Data?.message);
      }

      //
    } catch (error) {
      Notification("error", error.message);
      console.error(error);
    }
  };
  const PasswordPoupClose = () => {
    setOpen(!open);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={PasswordPoupClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="main"
          sx={{
            ...style,
            width: { xs: "98%", sm: "600px" },
            padding: "80px 80px",
            borderRadius: "20px",
            background:
              "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
            backdropFilter: "blur(200px)",
            "& h5": {
              marginBottom: "10px",
            },
            input: {
              color: "#fff",
            },
            height: "inherit",
          }}
        >
          <Main>
            <Box sx={{ marginBottom: "30px" }}>
              <img src={logo} alt="logo" className="logomain" />
            </Box>
            <h5>Enter {BeatInfo && BeatInfo?.title} Password</h5>
            <Formik
              initialValues={{ Password: "" }}
              onSubmit={HandlerSubmit}
              validationSchema={validationSchema}
            >
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <Box className="input-field-box">
                    <label>Password</label>
                    <Field
                      name="Password"
                      placeholder="Enter your password"
                      type="password"
                    />
                    <ErrorMessage
                      className="error"
                      name="Password"
                      component="div"
                    />
                  </Box>
                  <Box className="button_main">
                    <Button
                      variant="contained"
                      className="green-btn"
                      type="submit"
                      disabled={loading}
                      sx={{
                        "&:disabled": {
                          background: "gray !important",
                          color: "#fff !important",
                        },
                      }}
                    >
                      {loading ? "Loading..." : "Continue"}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Main>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(PrivateInboxPasswordModel);
