import React, { useEffect, useState, memo } from "react";
import { RecentIntegrationBox, MainCard } from "./CreatedLinksGridTypeStyle";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  beats,
  cardpic,
  edit_yellow,
  live_icon,
  copy_white,
  send_white,
} from "../../../../assets/smartlink";
import { Link } from "react-router-dom";

const CreatedLinksGridType = ({ smartLinks = [] }) => {
  return (
    <>
      <RecentIntegrationBox>
        <Grid container spacing={2}>
          {smartLinks?.map((data) => (
            <>
              <Grid item key={data._id} xl={4} lg={4} md={6} sm={12}>
                {/* <SmartLinkCard data={item} /> */}
                <MainCard>
                  <Box className="content">
                    <Box className="image_container">
                      <Box
                        className="box_image"
                        sx={{
                          backgroundImage: `url(${cardpic})`,
                          borderRadius: "0.3125rem",
                        }}
                      ></Box>
                      <Box className="image_container_hover">
                        <ul>
                          <li>
                            <Link to="/smart-link/insights">Insight</Link>
                          </li>
                          <li>
                            <Link to={`/smart-link/edit/${data.id}`}>Edit</Link>
                          </li>
                          <li>
                            <Link to="#">Export Fan</Link>
                          </li>
                          <li>
                            <Link to="#">Get</Link>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                    <Box className="main_heading">
                      <Typography variant="h5">{data.title}</Typography>
                      <Box className="main_heading_status">
                        {/* {data.type === "Draft" ? (
                          <img src={edit_yellow} art="" />
                        ) : data.type == "Live" ? (
                          <img src={live_icon} art="" />
                        ) : (
                          ""
                        )} */}
                        {/* <span className={data.type}>{data.type}</span> */}
                      </Box>
                    </Box>
                    <Box className="link_titile">
                      <Box className="beat_link_main">
                        <img src={beats} alt="img" className="beat_icon" />
                        <Typography variant="h6">{data.url}</Typography>
                        <img
                          src={copy_white}
                          alt="copy_white"
                          onClick={() => {}}
                        />
                      </Box>
                      {/* <Box className="beat_link_buttons"> */}
                      <img
                        src={send_white}
                        alt="copy_white"
                        onClick={() => {}}
                      />
                      {/* </Box> */}
                    </Box>

                    <Box className="title-box">
                      <Typography variant="h6">
                        Tags:{" "}
                        {data?.tags
                          ? data.tags.map((tag, index) => (
                              <span key={index}>
                                {tag}
                                {index !== data.tags.length - 1 ? ", " : ""}
                              </span>
                            ))
                          : "No tags available"}
                      </Typography>
                    </Box>
                    <Typography variant="h6" className="artistName">
                      Artist Name:{" "}
                      <span>
                        {data?.artists
                          ? data.artists.map((Artist, index) => (
                              <span key={index}>
                                {Artist.name}
                                {index !== data.artists.length - 1 ? ", " : ""}
                              </span>
                            ))
                          : "No artists available"}
                      </span>
                    </Typography>
                  </Box>
                </MainCard>
              </Grid>
            </>
          ))}
        </Grid>
      </RecentIntegrationBox>
    </>
  );
};

export default memo(CreatedLinksGridType);
