import React from "react";
import artistData from "../../backend/dummy_data/artist_data";
import styles from "./dashboard.module.css";

import DashboardOpportunities from "./dashboard_opportunities";
import DashboardArtistSpotlight from "./dashboard_artist_spotlight";
import NewArtistsComponent from "./dashboard_new_artists";

function DashboardComopnent() {
  return (
    <div className={styles.main_layout}>
      <div className={styles.dashboard_header}>
        <img
          className={styles.dashboard_header_image}
          alt="header"
          src="/v2Assets/dashboard_assets/Dashboard_Header.png"
        ></img>
      </div>
      <DashboardOpportunities />
      <div className={styles.layout_grid}>
        <NewArtistsComponent />
        <DashboardArtistSpotlight />
      </div>
    </div>
  );
}

export default DashboardComopnent;
