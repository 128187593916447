import React, { useEffect } from "react";
import { Grid } from "@mui/material";

// import styles from "./styles.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
// import CreateSync from "../../components/OpportunitiesComponents/PageComponents/createSync";
import { useNavigate } from "react-router-dom";
import useAuth from "../../context/AuthContext";
import { get } from "lodash";
import { ROLES } from "../../shared/constants";
import CreateSync from "../../components/OpportunitiesComponents/Sync/CreateSyncForm";

const CreateSyncPage = ({ isEdit }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user && get(user, "roleId.roleName", "") === ROLES.MUSIC_PRODUCER) {
      navigate("/sync");
    }
  }, [user]);

  return (
    <Grid>
      å
      <Sidebar
        childComponent={<CreateSync isEdit={isEdit} />}
        activeText="Sync Placements"
      />
    </Grid>
  );
};

export default CreateSyncPage;
