/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { Box, Button, Image, Modal, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import FlagSvg from "../../../assets/cardSvg/Flag";

export function DefualtPayment(props) {
  const { openModal, closeModal, defaultFtn } = props;

  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      w={{ xl: "40%", md: "40%", base: "90%" }}
      size={"full"}
      alignSelf={"center"}
      justifyContent={"flex-start"}
      mt={20}
    >
      <Modal.Content backgroundColor={"#141313"}>
        <Modal.CloseButton
          _hover={{ bg: "#141313" }}
          _focused={{ bg: "#2EBA55" }}
          _pressed={{ bg: "transparent" }}
          mx={5}
        />

        <VStack
          pt="20"
          backgroundColor={"#141313"}
          w={"90%"}
          alignSelf={"center"}
        >
          <Box alignSelf={"center"}>
            <FlagSvg color={"#afafaf"} width={"100px"} height={"100px"} />
          </Box>

          <Text
            w={"70%"}
            fontSize={{ lg: "20px", md: "16px", sm: "16px" }}
            fontStyle={"bold"}
            color={"#afafaf"}
            py="2"
            alignSelf={"center"}
            textAlign={"center"}
          >
            Are you sure you want to set this card as default payment method?
          </Text>
        </VStack>
        <Button.Group
          justifyContent={"space-between"}
          alignSelf={"center"}
          width={"60%"}
          my={8}
        >
          <Button
            size={"sm"}
            bg="#141313"
            borderStyle={"solid"}
            borderWidth={"1"}
            borderColor={"#2EBA55"}
            w={"40%"}
            rounded={"md"}
            alignSelf={"center"}
            _text={{
              color: "#2EBA55",
              fontSize: {
                lg: "18px",
                md: "16px",
                sm: "14px",
                base: "14px",
              },
              fontFamily: "Poppins",
            }}
            _hover={{
              bg: "#2EBA55",
              _text: {
                color: "#fff",
              },
            }}
            _focused={{ bg: "#2EBA55" }}
            _pressed={{ bg: "transparent" }}
            onPress={() => {
              closeModal();
            }}
          >
            CANCEL
          </Button>
          <Button
            size={"sm"}
            bg="#2EBA55"
            borderWidth={1}
            borderColor="#2EBA55"
            _text={{
              color: "#fff",
              fontSize: {
                lg: "18px",
                md: "16px",
                sm: "14px",
                base: "14px",
              },
              fontFamily: "Poppins",
            }}
            _hover={{
              bg: "transparent",
              _text: {
                color: "#2EBA55",
              },
            }}
            onPress={() => {
              defaultFtn();
              closeModal();
            }}
            w={"40%"}
            rounded={"md"}
            alignSelf={"center"}
            _focused={{ bg: "#2EBA55" }}
            _pressed={{ bg: "#2EBA55" }}
          >
            Set Default
          </Button>
        </Button.Group>
      </Modal.Content>
    </Modal>
  );
}
