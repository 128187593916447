import styles from "./profile.module.css";
import ProfileBestSongs from "./profile_best_songs";

import MusoCollaborators from "./muso_collaborators";
import MusoCredits from "./muso_credits";

import ProfileHeader from "./profile_header";
import { useEffect, useState } from "react";

import Loading from "../UniversalComponents/loading_component";

import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useAppState from "../../context/useAppState";

import useAuth from "../../context/AuthContext";

function ProfileComponent() {
  const { user } = useAuth();
  const { id: userId } = useParams();
  const [spotifyData, setSpotifyData] = useState(null);
  const [profile, setProfile] = useState(null);
  const [musoData, setMusoData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { loadUser } = useAppState("connectMe");

  console.log(userId);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setIsLoading(true);
        const data = await loadUser(userId);
        console.log(data.user);
        setProfile(data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUserData();
  }, [userId]);

  return isLoading ? (
    <></>
  ) : (
    <div className={styles.main_layout}>
      <div className={styles.black_half_opacity_bg}>
        <div className={styles.profile_page_elements}>
          {/* <div className={styles.profile_page_elements_top}>
            <div className={styles.about_artist_section}>
              <ProfileAboutComponent  profile={profile} />
            </div>
          
          </div> */}
          {/* MusoData: {JSON.stringify(musoData.credits.data.credits[0])} */}
          {/* SpotifyData: {JSON.stringify(spotifyData)} */}
          {profile && <ProfileHeader profile={profile} />}
          {/* {musoData && <MusoCredits musoData={musoData} />}
          {musoData && <MusoCollaborators musoData={musoData} />}
          {spotifyData && <ProfileBestSongs spotifyData={spotifyData} />} */}
        </div>
      </div>
    </div>
  );
}

export default ProfileComponent;
