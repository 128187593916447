import { create } from 'zustand'

const sortData = [
    {
      id: 1,
      title: "Newest - Oldest",
    },
    {
      id: 2,
      title: "Oldest - Newest",
    },
  ];
const useLibraryStore = create((set) => ({
    newAdded: false,
    selectedTags: [],
    range: [0, 200],
    fileKey: "",
    scale: "",
    allLibraryData: [],
    currentSubBarIndex: 0,
    subTitle: "Library", 
    query: "",
    sortBy: sortData[0],
    mode: "",
    usedAudioStorage: 0,
    activeBar: 0,
    setNewAdded: () => set((state) => ({ newAdded: !state.newAdded })),
    setSelectedTags: (selectedTags) => set(() => ({ selectedTags: selectedTags })),
    setRange: (range) => set(() => ({ range })),
    setFileKey: (fileKey) => set(() => ({ fileKey })),
    setQuery: (query) => set(() => ({ query })),
    setScale: (scale) => set(() => ({ scale })),
    setAllLibraryData:(allLibraryData)=> set(()=>({allLibraryData})),
    setCurrentSubBarIndex: (currentSubBarIndex) => set(() => ({ currentSubBarIndex })),
    setSubTitle: (subTitle) => set(() => ({ subTitle })),
    setSortBy: (sortBy) => set(() => ({ sortBy })),
    setMode: (mode) => set(() => ({ mode })),
    setUsedAudioStorage: (usedAudioStorage) => set(() => ({ usedAudioStorage })),
    setActiveBar: (activeBar) => set(() => ({ activeBar })),

    
}))

export default useLibraryStore;