import { Stack } from "@mui/material";
import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import styles from "../../Libraries/Libraries.module.css";
import OutlineButton from "../OutlineButton.js";
// import { PaginatorProps } from "types";

const BlueButtonComponents = ({
  fontsize = "18x",
  text = "",
  background = "#2EBB55",
  bordercolor = "transparent",
  marginRight = "0px",
  padding = "10.5px 24px 10.5px 30px",
  onClickFunction,
  type = "button",
  component,
  disabled,
  cursor
}) => {
  return (
    <>
      <button
        className={styles.blue_button}
        style={{
          fontSize: fontsize,
          background: background,
          borderColor: bordercolor,
          padding: padding,
          marginRight: marginRight,
          cursor: cursor
        }}
        onClick={onClickFunction}
        type={type}
        disabled={disabled}
      >
        {text}
        {component}
      </button>
    </>
  );
};

export default BlueButtonComponents;
