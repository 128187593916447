import React from "react";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

const getExclusiveContractDetails = (props) => {
  const {
    producerName,
    licensee,
    startDate,
    percentage,
    beatPrice,
    endDate = "In Perpetuity",
    country = "United States",
  } = props;

  return (
    <div id="contractDetails">
      <div>**Music Producer Exclusive License Agreement**</div>

      <div>
        <p>
          This Exclusive License Agreement (the "Agreement") is made and entered
          into as of {startDate}, by and between {producerName}, hereinafter
          referred to as the "Producer," and {licensee}, hereinafter referred to
          as the "Licensee."
        </p>
      </div>

      <div>**1. Grant of License**</div>

      <div>
        <p>
          The Producer hereby grants to the Licensee an exclusive license to
          use, reproduce, distribute, publicly perform, and create derivative
          works based on the musical compositions produced by the Producer, as
          described in Exhibit A attached hereto (the "Music"), for the term and
          territory specified herein.
        </p>
      </div>

      <div>**2. Exclusivity**</div>

      <div>
        <p>
          During the term of this Agreement, the Producer shall not grant
          licenses to any third parties for the Music, and the Licensee shall
          have the exclusive right to exploit the Music as provided herein.
        </p>
      </div>

      <div>**3. Term**</div>

      <div>
        <p>
          This Agreement shall commence on {startDate} and continue in full
          force and effect until {endDate}, unless earlier terminated as
          provided herein.
        </p>
      </div>

      <div>**4. Compensation**</div>

      <div>
        <p>
          In consideration for the exclusive license granted hereunder, the
          Licensee agrees to pay the Producer a royalty of {percentage}% of all
          net revenues derived from the exploitation of the Music by the
          Licensee. Additionally, the Licensee will pay the Producer a one-time
          fee of ${beatPrice} in addition to the royalty percentage.
        </p>
      </div>

      <div>**5. United Market's Share**</div>

      <div>
        <p>
          The Licensee acknowledges and agrees to grant United Market a 5% share
          of the publishing royalties generated from the Music for serving as
          the A&R in this transaction. This 5% publishing fee to United Market
          is in perpetuity.
        </p>
      </div>

      <div>**6. Representations and Warranties**</div>

      <div>
        <p>
          Both parties represent and warrant that they have the full right,
          power, and authority to enter into this Agreement and grant the rights
          herein, and that the Music does not infringe upon any copyrights,
          trademarks, or other intellectual property rights of any third
          parties.
        </p>
      </div>

      <div>**7. Termination**</div>

      <div>
        <p>
          Either party may terminate this Agreement upon written notice if the
          other party breaches any material term or condition of this Agreement,
          subject to any cure periods specified herein.
        </p>
      </div>

      <div>**8. Governing Law**</div>

      <div>
        <p>
          This Agreement shall be governed by and construed in accordance with
          the laws of {country}, without regard to its conflict of law
          principles.
        </p>
      </div>

      <div>**9. Limitation of Liability**</div>

      <div>
        <p>
          United Market shall not be held liable for any expenses, federal or
          civil suits arising from or related to this Beat Purchasing Agreement.
          Both the music producer and the artist acknowledge and agree that they
          bear all responsibility for ensuring compliance with applicable laws,
          regulations, and contractual obligations related to the purchase and
          use of the beats through United Market's platform. United Market is
          not responsible for any legal disputes, claims, or liabilities that
          may arise between the music producer, artist, and any third party.
        </p>
      </div>

      <div>**10. Entire Agreement**</div>

      <div>
        <p>
          This Agreement contains the entire understanding and agreement between
          the parties relating to the subject matter hereof and supersedes all
          prior and contemporaneous agreements, negotiations, and
          understandings, whether oral or written.
        </p>
      </div>

      <div>**Acknowledgment of Terms**</div>

      <div>
        <p>
          By checking the box below, the Licensee acknowledges and agrees to all
          the terms and conditions set forth in this Music Producer Exclusive
          License Agreement, including the 5% publishing fee to United Market
          being in perpetuity.
        </p>
      </div>
    </div>
  );
};

const getNonExclusiveContractDetails = (props) => {
  const {
    producerName,
    licensee,
    startDate,
    percentage,
    beatPrice,
    endDate = "In Perpetuity",
    country = "United States",
  } = props;

  return (
    <div>
      <div>**Non-Exclusive Producer Agreement**</div>

      <div>
        <p>
          This Non-Exclusive Producer Agreement (the "Agreement") is made and
          entered into as of {startDate}, by and between {producerName},
          hereinafter referred to as the "Producer," and {licensee}, hereinafter
          referred to as the "Licensee."
        </p>
      </div>

      <div>**1. Services**</div>

      <div>
        <p>
          The Producer agrees to provide musical production services to the
          Licensee, as described in Exhibit A attached hereto (the "Services").
        </p>
      </div>

      <div>**2. Compensation**</div>

      <div>
        <p>
          In consideration for the Services provided by the Producer, the
          Licensee agrees to pay the Producer a royalty of {percentage}% of all
          net revenues derived from the exploitation of the produced music by
          the Licensee. Additionally, the Licensee acknowledges and agrees to
          grant United Market a 5% share of the publishing royalties generated
          from the produced music for serving as the A&R in this transaction.
          This 5% publishing fee to United Market is in perpetuity.
        </p>
      </div>

      <div>**3. Ownership and Rights**</div>

      <div>
        <p>
          The Producer retains all rights, title, and interest in and to the
          original musical compositions and recordings produced by the Producer,
          except as granted to the Licensee under this Agreement.
        </p>
      </div>

      <div>**4. Representations and Warranties**</div>

      <div>
        <p>
          Both parties represent and warrant that they have the full right,
          power, and authority to enter into this Agreement and grant the rights
          herein, and that the produced music does not infringe upon any
          copyrights, trademarks, or other intellectual property rights of any
          third parties.
        </p>
      </div>

      <div>**5. Term and Termination**</div>

      <div>
        <p>
          This Agreement shall commence on {startDate} and continue in full
          force and effect until {endDate}, unless earlier terminated as
          provided herein.
        </p>
      </div>

      <div>**6. Governing Law**</div>

      <div>
        <p>
          This Agreement shall be governed by and construed in accordance with
          the laws of {country}, without regard to its conflict of law
          principles.
        </p>
      </div>

      <div>**7. Entire Agreement**</div>

      <div>
        <p>
          This Agreement contains the entire understanding and agreement between
          the parties relating to the subject matter hereof and supersedes all
          prior and contemporaneous agreements, negotiations, and
          understandings, whether oral or written.
        </p>
      </div>

      <div>**8. Limitation of Liability**</div>

      <div>
        <p>
          United Market shall not be held liable for any expenses, federal or
          civil suits arising from or related to this Beat Purchasing Agreement.
          Both the music producer and the artist acknowledge and agree that they
          bear all responsibility for ensuring compliance with applicable laws,
          regulations, and contractual obligations related to the purchase and
          use of the beats through United Market's platform. United Market is
          not responsible for any legal disputes, claims, or liabilities that
          may arise between the music producer, artist, and any third party.
        </p>
      </div>

      <div>**Acknowledgment of Terms**</div>

      <div>
        <p>
          By checking the box below, the Licensee acknowledges and agrees to all
          the terms and conditions set forth in this Non-Exclusive Producer
          Agreement, including the 5% publishing fee to United Market being in
          perpetuity.
        </p>
      </div>
    </div>
  );
};

const handleExclusiveDownload = (props) => {
  const {
    producerName,
    licensee,
    startDate,
    percentage,
    beatPrice,
    endDate = "In Perpetuity",
    country = "United States",
  } = props;

  const data = {
    content: [
      {
        text: "*Music Producer Exclusive License Agreement*",
        style: "subheader",
      },
      {
        text: `This Exclusive License Agreement (the "Agreement") is made and entered into as of ${startDate}, by and between ${producerName}, hereinafter referred to as the "Producer," and ${licensee}, hereinafter referred to as the "Licensee."`,
        style: "content",
      },
      { text: "**1. Grant of License**", style: "sectionHeader" },
      {
        text: 'The Producer hereby grants to the Licensee an exclusive license to use, reproduce, distribute, publicly perform, and create derivative works based on the musical compositions produced by the Producer, as described in Exhibit A attached hereto (the "Music"), for the term and territory specified herein.',
        style: "content",
      },
      { text: "**2. Exclusivity**", style: "sectionHeader" },
      {
        text: "During the term of this Agreement, the Producer shall not grant licenses to any third parties for the Music, and the Licensee shall have the exclusive right to exploit the Music as provided herein.",
        style: "content",
      },
      { text: "**3. Term**", style: "sectionHeader" },
      {
        text: `This Agreement shall commence on ${startDate} and continue in full force and effect until ${endDate}, unless earlier terminated as provided herein.`,
        style: "content",
      },
      { text: "**4. Compensation**", style: "sectionHeader" },
      {
        text: `In consideration for the exclusive license granted hereunder, the Licensee agrees to pay the Producer a royalty of ${percentage}% of all net revenues derived from the exploitation of the Music by the Licensee. Additionally, the Licensee will pay the Producer a one-time fee of $${beatPrice} in addition to the royalty percentage.`,
        style: "content",
      },
      { text: "**5. United Market's Share**", style: "sectionHeader" },
      {
        text: "The Licensee acknowledges and agrees to grant United Market a 5% share of the publishing royalties generated from the Music for serving as the A&R in this transaction. This 5% publishing fee to United Market is in perpetuity.",
        style: "content",
      },
      { text: "**6. Representations and Warranties**", style: "sectionHeader" },
      {
        text: "Both parties represent and warrant that they have the full right, power, and authority to enter into this Agreement and grant the rights herein, and that the Music does not infringe upon any copyrights, trademarks, or other intellectual property rights of any third parties.",
        style: "content",
      },
      { text: "**7. Termination**", style: "sectionHeader" },
      {
        text: "Either party may terminate this Agreement upon written notice if the other party breaches any material term or condition of this Agreement, subject to any cure periods specified herein.",
        style: "content",
      },
      { text: "**8. Governing Law**", style: "sectionHeader" },
      {
        text: `This Agreement shall be governed by and construed in accordance with the laws of ${country}, without regard to its conflict of law principles. `,
        style: "content",
      },
      { text: "**9. Limitation of Liability**", style: "sectionHeader" },
      {
        text: "United Market shall not be held liable for any expenses, federal or civil suits arising from or related to this Beat Purchasing Agreement. Both the music producer and the artist acknowledge and agree that they bear all responsibility for ensuring compliance with applicable laws, regulations, and contractual obligations related to the purchase and use of the beats through United Market's platform. United Market is not responsible for any legal disputes, claims, or liabilities that may arise between the music producer, artist, and any third party.",
        style: "content",
      },
      { text: "**10. Entire Agreement**", style: "sectionHeader" },
      {
        text: "This Agreement contains the entire understanding and agreement between the parties relating to the subject matter hereof and supersedes all prior and contemporaneous agreements, negotiations, and understandings, whether oral or written.",
        style: "content",
      },
      { text: "---", style: "footer" },
      { text: "**Acknowledgment of Terms**", style: "sectionHeader" },
      {
        text: "By checking the box below, the Licensee acknowledges and agrees to all the terms and conditions set forth in this Music Producer Exclusive License Agreement, including the 5% publishing fee to United Market being in perpetuity.",
        style: "content",
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        decoration: "underline",
        margin: [0, 10, 0, 10],
      },
      subheader2: {
        margin: [0, 0, 10, 10],
      },
      sectionHeader: {
        fontSize: 14,
        margin: [0, 10, 0, 5],
      },
      content: {
        margin: [0, 0, 0, 5],
      },
      footer: {
        margin: [0, 10, 0, 0],
      },
    },
  };

  pdfMake
    .createPdf(data, null, null, pdfFonts.pdfMake.vfs)
    .download("producer_exclusive_agreement");
};

const handleNonExclusiveDownload = (props) => {
  const {
    producerName,
    licensee,
    startDate,
    percentage,
    beatPrice,
    endDate = "In Perpetuity",
    country = "United States",
  } = props;
  const data = {
    content: [
      { text: "**Non-Exclusive Producer Agreement**", style: "subheader" },
      {
        text: `This Non-Exclusive Producer Agreement (the "Agreement") is made and entered into as of ${startDate}, by and between ${producerName}, hereinafter referred to as the "Producer," and ${licensee}, hereinafter referred to as the "Licensee."`,
        style: "subheader2",
      },
      { text: "**1. Services**", style: "sectionHeader" },
      {
        text: 'The Producer agrees to provide musical production services to the Licensee, as described in Exhibit A attached hereto (the "Services").',
        style: "content",
      },
      { text: "**2. Compensation**", style: "sectionHeader" },
      {
        text: `In consideration for the Services provided by the Producer, the Licensee agrees to pay the Producer a royalty of ${percentage}% of all net revenues derived from the exploitation of the produced music by the Licensee. Additionally, the Licensee acknowledges and agrees to grant United Market a 5% share of the publishing royalties generated from the produced music for serving as the A&R in this transaction. This 5% publishing fee to United Market is in perpetuity.`,
        style: "content",
      },
      { text: "**3. Ownership and Rights**", style: "sectionHeader" },
      {
        text: "The Producer retains all rights, title, and interest in and to the original musical compositions and recordings produced by the Producer, except as granted to the Licensee under this Agreement.",
        style: "content",
      },
      { text: "**4. Representations and Warranties**", style: "sectionHeader" },
      {
        text: "Both parties represent and warrant that they have the full right, power, and authority to enter into this Agreement and grant the rights herein, and that the produced music does not infringe upon any copyrights, trademarks, or other intellectual property rights of any third parties.",
        style: "content",
      },
      { text: "**5. Term and Termination**", style: "sectionHeader" },
      {
        text: `This Agreement shall commence on ${startDate} and continue in full force and effect until ${endDate}, unless earlier terminated as provided herein. Either party may terminate this Agreement upon written notice if the other party breaches any material term or condition of this Agreement, subject to any cure periods specified herein.`,
        style: "content",
      },
      { text: "**6. Governing Law**", style: "sectionHeader" },
      {
        text: `This Agreement shall be governed by and construed in accordance with the laws of ${country}, without regard to its conflict of law principles.`,
        style: "content",
      },
      { text: "**7. Entire Agreement**", style: "sectionHeader" },
      {
        text: "This Agreement contains the entire understanding and agreement between the parties relating to the subject matter hereof and supersedes all prior and contemporaneous agreements, negotiations, and understandings, whether oral or written.",
        style: "content",
      },
      { text: "**8. Limitation of Liability**", style: "sectionHeader" },
      {
        text: "United Market shall not be held liable for any expenses, federal or civil suits arising from or related to this Beat Purchasing Agreement. Both the music producer and the artist acknowledge and agree that they bear all responsibility for ensuring compliance with applicable laws, regulations, and contractual obligations related to the purchase and use of the beats through United Market's platform. United Market is not responsible for any legal disputes, claims, or liabilities that may arise between the music producer, artist, and any third party.",
        style: "content",
      },
      { text: "---", style: "footer" },
      { text: "**Acknowledgment of Terms**", style: "sectionHeader" },
      {
        text: "By checking the box below, the Licensee acknowledges and agrees to all the terms and conditions set forth in this Non-Exclusive Producer Agreement, including the 5% publishing fee to United Market being in perpetuity.",
        style: "content",
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        decoration: "underline",
        margin: [0, 10, 0, 10],
      },
      subheader2: {
        margin: [0, 0, 10, 10],
      },
      sectionHeader: {
        fontSize: 14,
        margin: [0, 10, 0, 5],
      },
      content: {
        margin: [0, 0, 0, 5],
      },
      footer: {
        margin: [0, 10, 0, 0],
      },
    },
  };

  pdfMake
    .createPdf(data, null, null, pdfFonts.pdfMake.vfs)
    .download("producer_non-exclusive_agreement");
};

export {
  getExclusiveContractDetails,
  getNonExclusiveContractDetails,
  handleExclusiveDownload,
  handleNonExclusiveDownload,
};
