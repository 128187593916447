import React from "react";
import { Grid } from "@mui/material";

import styles from "./styles.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";

import SoundPack from "../../components-old/SoundPacks/soundPack";
const MySoundPack = () => {
  return (
    <Grid className={styles.bgBlack}>
      <Sidebar
        activeText="Sound Packs"
        childComponent={<SoundPack />}
        activeSubText="Sound Packs"
      />
    </Grid>
  );
};

export default MySoundPack;
