import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const WelcomeMain = styled(Box)(({ theme }) => ({
  "& .logo": {
    marginTop: "10px",
    marginBottom: "10px",
    textAlign: "center",
    img: { height: "60px", maxWidth: "100%" },
  },
  h6: {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    margin: 0,
    textAlign: "center",
  },
  p: {
    color: "#AFAFAF",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "20px",
  },
  "& .contact-us-desc": {
    color: "#AFAFAF",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    padding: "0px 40px",
    marginBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginBottom: "20px",
      padding: "0px 20px",
    },
  },
  "& .buttonMain button:first-child": {
    marginBottom: "20px",
  },
  "& .buttonMain ": {
    p: {
      marginTop: "0px",
      marginBottom: "16px",
    },
  },
  button: {
    borderRadius: "5px",
    background: "#2EBB55",
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    width: "100%",
    padding: "10px 0px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#2EBB55",
    },
  },
}));

const FormMain = styled(Box)(({ theme }) => ({
  "& .form__field": {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
    "&:last-child": {
      marginBottom: "0px",
    },
  },

  "& .error": {
    color: "red",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginTop: "2px",
    input: {
      border: "1px solid red",
    },
  },

  label: {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "5px",
  },

  input: {
    borderRadius: "5px",
    border: "1px solid #414241",
    background: "#1C1B1B",
    height: "40px",
    padding: "5px",
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",

    "&:foucs": {
      outline: "none",
      border: "1px solid #414241",
    },
    "&:focus-visible": {
      outline: "none",
      border: "1px solid #414241",
    },
    "::placeholder": {
      color: "#999",
      fontStyle: "italic",
    },
  },
  button: {
    borderRadius: "5px",
    background: "#2EBB55",
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    width: "100%",
    padding: "10px 0px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#2EBB55",
    },
    marginTop: "20px",
    "&:disabled": {
      background: "#AFAFAF",
      color: "gray",
    },
  },

  "& .forgot__password": {
    textAlign: "right",
    button: {
      color: "#2EBB55",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      padding: "0",
      background: "transparent",
      textAlign: "right",
      justifyContent: "end",
      width: "max-content",
      marginTop: "0px",
    },
  },

  "& .othre__acount": {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    gap: "5px",
    button: {
      color: "#2EBB55",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      padding: "0",
      background: "transparent",
      textAlign: "right",
      justifyContent: "end",
      width: "max-content",
      marginTop: "0px",
      minWidth: "auto",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textDecorationLine: "underline",
    },
    span: {
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },

  "&.forget_password": {
    "& .othre__acount": {
      textAlign: "center",
      button: {
        color: "#fff !important",
        width: "max-content",
        marginTop: "0px",
        minWidth: "auto",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        textDecorationLine: "underline",
        background: "transparent",
        padding: "0",
        marginTop: "20px",
      },
    },
  },

  "&.signUpMain": {
    input: {
      borderRadius: "5px",
      border: "1px solid #414241",
      background: "#1C1B1B",
      fontFamily: theme.typography.fontFamily,
      paddingLeft: "10px",
      color: "#fff",
      "::placeholder": {
        color: "#AFAFAF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },
      "::-webkit-input-placeholder": {
        color: "#AFAFAF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },
    },
  },
}));

const SignUpFormMain = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 24,

  gap: "20px",
  "& .form__field": {
    width: "100%",
    marginBottom: 0,
    position: "relative",
  },
  "& input[name=confirmPassword]": {
    width: "50%",
  },
  "& .error": {
    input: {
      border: "1px solid red !important",
    },
  },
  "& .form__field .error": {
    position: "absolute",
    top: "100%",
    left: "0",
    input: {
      border: "1px solid red !important",
    },
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    "& input[name=confirmPassword]": {
      width: "100%",
    },
  },
}));
const TextAreaMainBox = styled(Box)(({ theme }) => ({
  h6: {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: 0,
  },

  textarea: {
    marginTop: "7px",
    borderRadius: "5px",
    border: "1px solid #414241",
    background: "#1C1B1B",
    height: "110px",
    padding: "10px",
    resize: "none",
    color: "#fff",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    "::placeholder": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "::-webkit-input-placeholder": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
  "&.contact_us_error textarea": {
    border: "1px solid red",
  },
}));
const UserTypeMain = styled(Box)(({ theme }) => ({
  textAlign: "center",

  "& .type_main": {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",

    "& .selected": {
      span: {
        border: "1px solid #2EBB55",
        "&::after": {
          content: "''",
          position: "absolute",
          top: "50%",
          left: "50%",
          borderRadius: "50px",
          width: "11px",
          height: "11px",
          background: "#2EBB55",
          transform: "translate(-50%, -50%)",
        },
      },
    },

    "& .type_items": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      cursor: "pointer",
    },
    span: {
      display: "block",
      width: "18px",
      height: "18px",
      border: "1px solid rgba(175, 175, 175, 1)",
      borderRadius: "50px",
      position: "relative",
    },
  },

  button: {
    color: "#fff",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    padding: "0",
    background: "transparent",
    textAlign: "center",
    justifyContent: "center",
    width: "max-content",
    marginTop: "0px",
    minWidth: "auto",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textDecorationLine: "underline",
    textTransform: "capitalize",
    "&:hover": { textDecorationLine: "underline" },
  },

  "& button.continue_main": {
    margin: "20px 0px !important",
    padding: "10px 0px !important",
    color: "#fff",
    marginTop: "40px !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    background: "#2EBB55",
    textAlign: "center",
    justifyContent: "center",
    width: "100%",
    // minWidth: "auto",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textDecorationLine: "none",
    "&:hover": {},
    "&:disabled": {
      background: "#AFAFAF",
    },
  },
}));

export { WelcomeMain, FormMain, SignUpFormMain, UserTypeMain, TextAreaMainBox };
