import React, { useEffect } from "react";
import { Grid } from "@mui/material";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import useAuth from "../../context/AuthContext";
import { get } from "lodash";
import { ROLES } from "../../shared/constants";
import Checkout from "../../components/ViewSubmissionComponents/Checkout";

const Checkout_page = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user && get(user, "roleId.roleName", "") === ROLES.MUSIC_PRODUCER) {
      navigate("/opportunities");
    }
  }, [user]);

  return (
    <Grid>
      <Sidebar childComponent={<Checkout />} activeText="My Submissions" />
    </Grid>
  );
};

export default Checkout_page;
