import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
	Box,
	Button,
	Heading,
	HStack,
	Input,
	Image,
	Checkbox,
	Avatar,
} from 'native-base';
import ProgressTags from './ProgressTags';
import { AddCollaborator } from '../Modals/AddCollaborator';
import { Await, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import styles from '../styles.module.css';
import { editMySoundPack, previewOfCollaborators, uploadSoundPack } from '../../../Graphql_services/SoundPackMutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DeleteIcon } from '../Svgs/DeleteIcon';
import { DeleteSoundPack } from '../Modals/DeleteSoundPack';
import { AddSplit } from '../Modals/AddSplit';
import { Grid, IconButton, Popover, Typography } from '@mui/material';
import allImages from '../../../assets';
import { EditIcon } from '../Svgs/EditIcon';
import EditCollaborator from '../Modals/EditCollaborator';

const UploadSoundKitsCollaborators = (props) => {
	const { setStep, uploadData, setUploadData, edit, editData } = props;
	const navigate = useNavigate();

	const [deletePopup, setDeletePopup] = useState(false);
	const [mySplit, setMySplit] = useState();
	const [splitPopup, setSplitPopup] = useState(false);
	const [removeObject, setRemoveObject] = useState({});
	const [termCustom, setTermCustom] = useState(false);
	const [modal, setModal] = useState(false);
	const [collaboratorData, setCollaboratorData] = useState([]);
	const [role, setRole] = useState();
	const [anchorEl, setAnchorEl] = useState(null);
	const [editModal, setEditModal] = useState(false);
	const [collaboratorIndex, setCollaboratorIndex] = useState(null);
	const [isEditMysplit, setIsEditMysplit] = useState(false);

	const schema = yup.object().shape({
		termsAndconditions: yup
			.string()
			.required('Terms and conditions are required').max(200,"Terms and conditions should not be more than 200 characters"),
	});
	const {
		handleSubmit,
		formState: { errors },
		setValue,
		register,
		trigger
	} = useForm({
		resolver: yupResolver(schema),
	});
	const HandleClose = () => {
		setModal(false);
	};
	const handleCancel = () => {
		// setUploadData(({ tags, ...updatedSoundPack }) => updatedSoundPack);
		// console.log('cancele.....', uploadData);
		// setStep(3);
		navigate('/soundPack');
	};
	const [UploadSoundPack] = useMutation(uploadSoundPack, {
		context: { clientName: 'soundpack' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
		  const { uploadSoundKit } = data;
		  if (uploadSoundKit) {
			const { isTokenExpired, success, message } = uploadSoundKit;
			if (!isTokenExpired) {
			  if (success) {
				toast.success('Sound pack added successfully');
				setStep(5);
			  } else {
				toast.error(message);
			  }
			} else {
			  localStorage.clear();
			  navigate('/signin');
			}
		  }
		},
		onError(err) {
		  console.log('error on  uploadSoundKit', err);
		},
	  });

	  const [EditMySoundPack] = useMutation(editMySoundPack, {
		context: { clientName: 'soundpack' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
		  const { editMySoundPack } = data;
		  if (editMySoundPack) {
			const { isTokenExpired, success, message } = editMySoundPack;
			if (!isTokenExpired) {
			  if (success) {
				toast.success('Sound pack Edited successfully');
				setStep(5);
			  } else {
				toast.error(message);
			  }
			} else {
			  localStorage.clear();
			  navigate('/signin');
			}
		  }
		},
		onError(err) {
		  console.log('error on  editMySoundPack', err);
		},
	  });

	const [PreviewOfCollaborators] = useLazyQuery(previewOfCollaborators, {
		context: { clientName: 'soundpack' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			const { previewOfCollaborators } = data;
			if (previewOfCollaborators) {
				const { isTokenExpired, success, message } = previewOfCollaborators;
				if (!isTokenExpired) {
					if (success) {
						console.log(
							'previewOfCollaborators on getting user info',
							previewOfCollaborators.collaborators
						);
						setCollaboratorData(
							previewOfCollaborators.collaborators
								.filter((item) => !item.isMySplit)
								.map((item) => ({
									musicianName: item.musicianName,
									email: item.email,
									split: item.split,
									role: item?.roleId?.roleName,
									profilePic: item.profilePic,
								}))
						);
						const foundMySplit = previewOfCollaborators.collaborators.find(
							(item) => item.isMySplit
						);
						console.log('previewOfCollaborators', foundMySplit);
						if (foundMySplit) {
							console.log('foundMySplit on getting user info', foundMySplit);
							const { musicianName, email, split, roleId, profilePic } =
								foundMySplit;
							setMySplit({
								musicianName,
								email,
								split,
								role: roleId?.roleName,
								profilePic,
							});
						} else {
							setMySplit(null);
						}
					} else {
						toast.error(message);
					}
				} else {
					localStorage.clear();
					navigate('/signin');
				}
			}
		},
		onError(err) {
			console.log('error on getting user info', err);
		},
	});

	const onSubmitHandler = async(data) => {
		let newSplitData = mySplit
			? [
					...collaboratorData,
					{
						musicianName: mySplit.musicianName,
						email: mySplit.email,
						split: parseFloat(mySplit.split),
						role: mySplit.role,
						profilePic: mySplit.profilePic,
					},
			  ]
			: collaboratorData;
		
		let newUploadedData={collaborators: newSplitData,
			isCustomTermsAndCodition: termCustom,
			termsAndCondition: termCustom
				?data?.termsAndconditions
				: 'This sound pack is owned by the posted user and they must be credited and compensated for these compositions. For clearing samples, with United Market members please contact us at placements@unitedmarket.com',
		}
		if (edit) {
		  const response = await EditMySoundPack({
			variables: {
			  soundPackId: editData._id,
			  soundPackCover: uploadData.soundPackCover,
			  soundPackFile: uploadData.soundPackFile,
			  soundPackZipFile: uploadData.soundPackZipFile,
			  soundPackTitle: uploadData.soundPackTitle,
			  soundPackKitType: uploadData.soundPackKit,
			  soundPackKey: uploadData.soundPackKey,
			  soundPackBPM: uploadData.soundPackBPM,
			  soundPackPrice:uploadData.soundPackPrice,
			  soundPackIsRoyaltyFree:uploadData.soundPackIsRoyaltyFree,
			  soundPackDescription: uploadData.soundPackDescription,
			  tags: uploadData.tags,
			  collaborators: newUploadedData.collaborators.map(
				({ role, profilePic, ...rest }) => rest
			  ),
			  termsAndCondition: newUploadedData.termsAndCondition,
			  isCustomTermsAndCodition: newUploadedData.isCustomTermsAndCodition,			  
			},
		  });
		  if(!response.data.editMySoundPack.success)return;
		} else {
			const response = await UploadSoundPack({
			variables: {
			  soundPackCover: uploadData.soundPackCover,
			  soundPackFile: uploadData.soundPackFile,
			  soundPackZipFile: uploadData.soundPackZipFile,
			  soundPackTitle: uploadData.soundPackTitle,
			  soundPackKitType: uploadData.soundPackKit,
			  soundPackKey: uploadData.soundPackKey,
			  soundPackBPM: uploadData.soundPackBPM,
			  soundPackPrice:uploadData.soundPackPrice,
			  soundPackIsRoyaltyFree:uploadData.soundPackIsRoyaltyFree,
			  soundPackDescription: uploadData.soundPackDescription,
			  tags: uploadData.tags,
			  collaborators: newUploadedData.collaborators.map(
				({ role, profilePic, ...rest }) => rest
			  ),
			  termsAndCondition: newUploadedData.termsAndCondition,
			  isCustomTermsAndCodition: newUploadedData.isCustomTermsAndCodition,	
			},
		  });
		  if(!response.data.uploadSoundKit.success)return;
		}
		setCollaboratorData([...newSplitData]);
		setUploadData({
			...uploadData,
			...newUploadedData});
	  };

	useEffect(() => {
		if (edit) {
			editData.termsAndCondition && setTermCustom(true);
			setValue('termsAndconditions', editData.termsAndCondition);
			if (editData.collaborators.length > 0) {
				PreviewOfCollaborators({
					variables: {
						collaborators: editData.collaborators.map(
							({ __typename, ...rest }) => rest
						),
					},
				});
			}
		}
	}, [editData]);

	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	  };
	
	  const handleClose = () => {
		setAnchorEl(null);
	  };

	return (
		<Box w={'100%'} >
			<Box w={'100%'} px={'3%'} alignSelf={'center'}>
				<ProgressTags index={4} />
				<div className={styles.collaborators_content_wrapper} >
				<Heading
					my={8}
					textTransform={'capitalize'}
					fontFamily={'Poppins'}
					fontSize={{
						xl: '24px',
						lg: '22px',
						md: '20px',
						base: '20px',
					}}
					fontWeight={'medium'}
					textAlign={'left'}
					color={'#fff'}
				>
					Collaborators
				</Heading>
				<div>
                <IconButton
                  variant="contained"
                  onClick={handleClick}
                  size="small"
                >
                  <img src={allImages.infoCircleIcon} className={styles.info_icon} />
                </IconButton>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  classes={{ paper: styles.collaborators_info }}
                >
                  <Grid container rowGap={1}>
                      <Grid item xs={12}>
                        <Typography className={styles.info_text}>
						Make sure to include YOUR %
                        </Typography>
                      </Grid>                    
                  </Grid>
                </Popover>
              </div>
			  </div>

				<Box w={'100%'} bg={'#1c1b1b'} rounded={'md'} my={{ lg: 4, base: 2 }}>
					<HStack flexWrap={'wrap-reverse'}>
						<Box
							w={{ '2xl': '65%', lg: '70%', base: '100%' }}
							minH={{ lg: '300px', base: '250px' }}
							borderRightColor={'#AFAFAF'}
							borderRightWidth={{ lg: 1, base: 0 }}
						>
							<HStack
								w={'100%'}
								alignSelf={'center'}
								justifyContent={'space-evenly'}
								py={3}
								borderBottomWidth={1}
								borderColor={'#afafaf'}
							>
								<Box w={'30%'}>
									<Heading
										textTransform={'uppercase'}
										fontFamily={'Poppins'}
										fontSize={{
											'2xl': '18px',
											xl: '17px',
											lg: '16px',
											md: '14px',
											base: '10px',
										}}
										fontWeight={'medium'}
										textAlign={'left'}
										color={'#fff'}
									>
										COLLABORATOR
									</Heading>
									<Heading
										fontFamily={'Poppins'}
										fontWeight={400}
										fontSize={{
											'2xl': '16px',
											xl: '15px',
											lg: '14px',
											base: '10px',
										}}
										lineHeight={{ lg: '18px', base: '16px' }}
										textAlign={'left'}
										color={'#afafaf'}
									>
										You
									</Heading>
								</Box>

								<Box w={'20%'}>
									<Heading
										textTransform={'uppercase'}
										fontFamily={'Poppins'}
										fontSize={{
											'2xl': '18px',
											xl: '17px',
											lg: '16px',
											md: '14px',
											base: '10px',
										}}
										fontWeight={'medium'}
										textAlign={'left'}
										color={'#fff'}
									>
										ROLE
									</Heading>
									<Heading
										fontFamily={'Poppins'}
										fontWeight={400}
										fontSize={{
											'2xl': '16px',
											xl: '15px',
											lg: '14px',
											base: '10px',
										}}
										lineHeight={{ lg: '18px', base: '16px' }}
										textAlign={'left'}
										color={'#afafaf'}
									>
										Main Collaborator
									</Heading>
								</Box>

								<Box w={'20%'}>
									<Heading
										textTransform={'uppercase'}
										fontFamily={'Poppins'}
										fontSize={{
											'2xl': '18px',
											xl: '17px',
											lg: '16px',
											md: '14px',
											base: '10px',
										}}
										fontWeight={'medium'}
										textAlign={'left'}
										color={'#fff'}
									>
										PUBLISHING %
									</Heading>
									<Heading
										fontFamily={'Poppins'}
										fontWeight={400}
										fontSize={{
											'2xl': '16px',
											xl: '15px',
											lg: '14px',
											base: '10px',
										}}
										lineHeight={{ lg: '18px', base: '16px' }}
										textAlign={'left'}
										color={'#afafaf'}
									>
										100%
									</Heading>
								</Box>

								<Box w={'15%'}>
									<Button
										w={{ lg: '80%', base: '100%' }}
										borderColor={'#2EBA55'}
										borderWidth={1}
										_pressed={{
											bg: '#2EBB55',
											_text: {
												color: '#fff',
											},
										}}
										backgroundColor={'#2EBA55'}
										py={2.5}
										_text={{
											color: '#fff',
											fontSize: {
												'2xl': '16px',
												xl: '15px',
												lg: '14px',
												base: '10px',
											},
											fontWeight: 'medium',
											fontFamily: 'Poppins',
										}}
										_hover={{
											backgroundColor: 'transparent',
											_text: {
												color: '#2EBA55',
											},
										}}
										alignSelf={'center'}
										onPress={() => setSplitPopup(true)}
									>
										Add My %
									</Button>
								</Box>
							</HStack>
							{mySplit ? (
								<HStack
									w={'100%'}
									alignSelf={'center'}
									justifyContent={'space-evenly'}
									py={2}
								>
									<Box w={'30%'}>
										<HStack
											w={'100%'}
											space={{ lg: 3, base: 2 }}
											overflow={'hidden'}
										>
											{mySplit.profilePic ? (
												<Image
													source={mySplit.profilePic}
													size={{ lg: '40px', base: '30px' }}
													rounded={'full'}
													alignSelf={'center'}
												/>
											) : (
												<Avatar
													bg='#AFAFAF'
													size={{ lg: '40px', base: '30px' }}
													alignSelf={'center'}
													_text={{
														textTransform: 'uppercase',
														fontSize: {
															xl: '16px',
															lg: '14px',
															base: '10px',
														},
													}}
												>
													{mySplit.musicianName[0]}
												</Avatar>
											)}
											<Heading
												alignSelf={'center'}
												fontFamily={'Poppins'}
												fontWeight={400}
												fontSize={{ xl: '16px', lg: '14px', base: '10px' }}
												lineHeight={{ lg: '18px', base: '16px' }}
												textAlign={'left'}
												color={'#afafaf'}
												style={{
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													whiteSpace: 'nowrap',
												}}
											>
												{'(ME)'}
											</Heading>
										</HStack>
									</Box>

									<Box w={'20%'} alignSelf={'center'}>
										<Heading
											fontFamily={'Poppins'}
											fontWeight={400}
											fontSize={{ xl: '16px', lg: '14px', base: '10px' }}
											lineHeight={{ lg: '18px', base: '16px' }}
											textAlign={'left'}
											color={'#afafaf'}
											maxW={'90%'}
											style={{
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
											}}
										>
											{mySplit.role ? mySplit.role : ' '}
										</Heading>
									</Box>

									<Box w={'20%'} alignSelf={'center'}>
										<Heading
											fontFamily={'Poppins'}
											fontWeight={400}
											fontSize={{ xl: '16px', lg: '14px', base: '10px' }}
											lineHeight={{ lg: '18px', base: '16px' }}
											textAlign={'center'}
											color={'#afafaf'}
										>
											{mySplit.split + '%'}
										</Heading>
									</Box>
									<Box w={'10%'}>
										<Button
											variant={'ghost'}
											_hover={{
												bg: 'transparent',
											}}
											_pressed={{ bg: 'transparent' }}
											p={1}
											my={1}
											onPress={() => {
												setEditModal(true);
												setIsEditMysplit(true)
												setCollaboratorIndex(null)
											}}
										>
											<EditIcon />
										</Button>
									</Box>
									<Box w={'10%'}>
										<Button
											variant={'ghost'}
											_hover={{
												bg: 'transparent',
											}}
											_pressed={{ bg: 'transparent' }}
											p={1}
											my={1}
											onPress={() => {
												setDeletePopup(true);
												setRemoveObject(mySplit);
											}}
										>
											<DeleteIcon />
										</Button>
									</Box>
								</HStack>
							) : null}
							{collaboratorData.length > 0 ? (
								<Box py={2}>
									{collaboratorData.map((item, index) => (
										<HStack
											key={index}
											w={'100%'}
											alignSelf={'center'}
											justifyContent={'space-evenly'}
											py={2}
										>
											<Box w={'30%'}>
												<HStack
													w={'100%'}
													space={{ lg: 3, base: 2 }}
													overflow={'hidden'}
												>
													{item.profilePic ? (
														<Image
															source={item.profilePic}
															size={{ lg: '40px', base: '30px' }}
															rounded={'full'}
															alignSelf={'center'}
														/>
													) : (
														<Avatar
															bg='#AFAFAF'
															size={{ lg: '40px', base: '30px' }}
															alignSelf={'center'}
															_text={{
																textTransform: 'uppercase',
																fontSize: {
																	xl: '16px',
																	lg: '14px',
																	base: '10px',
																},
															}}
														>
															{item.musicianName[0]}
														</Avatar>
													)}
													<Heading
														alignSelf={'center'}
														fontFamily={'Poppins'}
														fontWeight={400}
														fontSize={{ xl: '16px', lg: '14px', base: '10px' }}
														lineHeight={{ lg: '18px', base: '16px' }}
														textAlign={'left'}
														color={'#afafaf'}
														style={{
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															whiteSpace: 'nowrap',
														}}
													>
														{item.musicianName}
													</Heading>
												</HStack>
											</Box>

											<Box w={'20%'} alignSelf={'center'}>
												<Heading
													fontFamily={'Poppins'}
													fontWeight={400}
													fontSize={{ xl: '16px', lg: '14px', base: '10px' }}
													lineHeight={{ lg: '18px', base: '16px' }}
													textAlign={'left'}
													color={'#afafaf'}
													maxW={'90%'}
													style={{
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap',
													}}
												>
													{item.role ? item.role : ' '}
												</Heading>
											</Box>

											<Box w={'20%'} alignSelf={'center'}>
												<Heading
													fontFamily={'Poppins'}
													fontWeight={400}
													fontSize={{ xl: '16px', lg: '14px', base: '10px' }}
													lineHeight={{ lg: '18px', base: '16px' }}
													textAlign={'center'}
													color={'#afafaf'}
												>
													{item.split + '%'}
												</Heading>
											</Box>
											<Box w={'10%'}>
										<Button
											variant={'ghost'}
											_hover={{
												bg: 'transparent',
											}}
											_pressed={{ bg: 'transparent' }}
											p={1}
											my={1}
											onPress={() => {
												setEditModal(true);
												setCollaboratorIndex(index)
											}}
										>
											<EditIcon />
										</Button>
									</Box>
											<Box w={'10%'}>
												<Button
													variant={'ghost'}
													_hover={{
														bg: 'transparent',
													}}
													_pressed={{ bg: 'transparent' }}
													p={1}
													my={1}
													onPress={() => {
														setDeletePopup(true);
														setRemoveObject(item);
													}}
												>
													<DeleteIcon />
												</Button>
											</Box>
										</HStack>
									))}

									<Box
										my={3}
										w={'100%'}
										alignItems={'center'}
										justifyContent={'center'}
									>
										<Button
											disabled={collaboratorData.length < 3 ? false : true}
											w={{ lg: '30%', base: '60%' }}
											borderColor={
												collaboratorData.length < 3 ? '#2EBA55' : '#323333'
											}
											borderWidth={1}
											_pressed={{
												bg: '#2EBB55',
												_text: {
													color: '#fff',
												},
											}}
											backgroundColor={
												collaboratorData.length < 3 ? '#2EBA55' : '#323333'
											}
											py={2.5}
											_text={{
												color: '#fff',
												fontSize: {
													xl: '18px',
													lg: '18px',
													md: '16px',
													base: '14px',
												},
												fontWeight: 'medium',
												textTransform: 'capitalize',
												fontFamily: 'Poppins',
											}}
											_hover={{
												backgroundColor: 'transparent',
												_text: {
													color: '#2EBA55',
												},
											}}
											alignSelf={'center'}
											onPress={() => setModal(true)}
										>
											Add Collabrator
										</Button>
									</Box>
								</Box>
							) : (
								<Box
									h={'70%'}
									w={'100%'}
									alignItems={'center'}
									justifyContent={'center'}
								>
									<Button
										w={{ lg: '30%', base: '60%' }}
										borderColor='#2EBA55'
										borderWidth={1}
										_pressed={{
											bg: '#2EBB55',
											_text: {
												color: '#fff',
											},
										}}
										backgroundColor='#2EBA55'
										py={2}
										_text={{
											color: '#fff',
											fontSize: {
												xl: '18px',
												lg: '18px',
												md: '16px',
												base: '14px',
											},
											fontWeight: 'medium',
											textTransform: 'capitalize',
											fontFamily: 'Poppins',
										}}
										_hover={{
											backgroundColor: 'transparent',
											_text: {
												color: '#2EBA55',
											},
										}}
										alignSelf={'center'}
										onPress={() => setModal(true)}
									>
										Add Collabrator
									</Button>
								</Box>
							)}
						</Box>
						<Box
							w={{ '2xl': '35%', lg: '30%', base: '100%' }}
							minH={{ lg: '300px', base: '200px' }}
							alignItems={'center'}
							justifyContent={'center'}
							borderBottomColor={'#AFAFAF'}
							borderBottomWidth={{ lg: 0, base: 1 }}
						>
							<Heading
								fontFamily={'Poppins'}
								fontWeight={400}
								fontSize={{ xl: '16px', lg: '14px', base: '12px' }}
								lineHeight={{ lg: '36px', base: '20px' }}
								textAlign={'left'}
								color={'#afafaf'}
								w={'90%'}
								// alignSelf={'center'}
								py={2}
							>
								Include your collaborators and their percentage splits below.
								Users who download your soundpacks will view this information,
								making it vital to ensure that all collaborators receive proper
								recognition and compensation.
							</Heading>
						</Box>
					</HStack>
				</Box>

				<Heading
          my={8}
          textTransform={'capitalize'}
          fontFamily={'Poppins'}
          fontSize={{
            xl: '24px',
            lg: '22px',
            md: '20px',
            base: '20px',
          }}
          fontWeight={'medium'}
          textAlign={'left'}
          color={'#fff'}
        >
          Terms & Condition
        </Heading>

				<form>
					<Checkbox
            my={2}
            size={'md'}
            value={termCustom}
			isChecked={termCustom}
            // defaultIsChecked
            bg={'transparent'}
            borderColor={'#afafaf'}
            _checked={{
              bg: '#2ebb55',
              borderColor: '#2ebb55',
              _hover: { bg: '#2ebb55', borderColor: '#2ebb55' },
            }}
            _hover={{ borderColor: '#afafaf' }}
            onChange={() => setTermCustom(!termCustom)}
          >
            <Heading
              textTransform={'capitalize'}
              fontFamily={'Poppins'}
              fontSize={{
                xl: '20px',
                lg: '18px',
                md: '16px',
                base: '14px',
              }}
              fontWeight={'medium'}
              textAlign={'left'}
              color={'#afafaf'}
            >
              Enter your terms of use below
            </Heading>
          </Checkbox>
					{termCustom ? (<>
            <Input
              my={8}
              _text={{ fontSize: '1rem', fontFamily: 'poppins' }}
              _hover={{ borderColor: '#AFAFAF' }}
              _focus={{ bg: 'transparent' }}
              focusOutlineColor='#2EBB55'
              multiline={'true'}
              numberOfLines={8}
              fontFamily='poppins'
              fontSize={{ lg: '18px', md: '16px', base: '14px' }}
              color={'#AFAFAF'}
              borderColor='#AFAFAF'
              placeholder={
                'Enter the terms for your soundpack. Ex. I would like an equal share for any time a beat is placed with my melody.'
              }
              placeholderTextColor={'#afafaf'}
              // value={customCondition}
              {...register('termsAndconditions')}
              onChangeText={(text) =>{ setValue('termsAndconditions', text) 
			  trigger('termsAndconditions')}}
            />
		  <p className={styles.error} style={{"width":"100%"}}>{errors.termsAndconditions?.message}</p>
		  </>
          ):( <p className={styles.error}>{edit? "Terms and conditions are required":errors.termsAndconditions?.message}</p>)}
					<Box w={{ lg: '80%', base: '90%' }} alignSelf={'center'}>
						<HStack
							alignSelf={'center'}
							w={{ lg: '60%', base: '100%' }}
							my={{ '2xl': 40, lg: 20, base: 10 }}
							justifyContent={'space-between'}
						>
							<Button
								w={'45%'}
								variant={'ghost'}
								_hover={{
									backgroundColor: '#2EBA55',
									_text: {
										color: '#fff',
									},
								}}
								_pressed={{
									bg: '#2EBB55',
									_text: {
										color: '#fff',
									},
								}}
								_focus={{ bg: 'transparent' }}
								// mt={10}
								p={0}
								py={2.5}
								borderColor='#2EBA55'
								borderWidth={1}
								alignSelf={'center'}
								_text={{
									color: '#2ebb55',
									fontWeight: 'medium',
									fontSize: {
										xl: '18px',
										lg: '18px',
										md: '16px',
										base: '14px',
									},
									fontFamily: 'Poppins',
								}}
								onPress={() => handleCancel()}
							>
								CANCEL
							</Button>
							<Button
								w={'45%'}
								borderColor='#2EBA55'
								borderWidth={1}
								_pressed={{
									bg: '#2EBB55',
									_text: {
										color: '#fff',
									},
								}}
								backgroundColor='#2EBA55'
								py={2.5}
								_text={{
									color: '#fff',
									fontSize: {
										xl: '18px',
										lg: '18px',
										md: '16px',
										base: '14px',
									},
									fontWeight: 'medium',
									textTransform: 'capitalize',
									fontFamily: 'Poppins',
								}}
								_hover={{
									backgroundColor: 'transparent',
									_text: {
										color: '#2EBA55',
									},
								}}
								alignSelf={'center'}
								onPress={handleSubmit(onSubmitHandler)}
							>
								{edit ? 'Save' : 'Create'}
							</Button>
						</HStack>
					</Box>
				</form>
			</Box>
			<DeleteSoundPack
				openModal={deletePopup}
				closeModal={() => setDeletePopup(false)}
				deleteFtn={() => {
					{
						mySplit?.email == removeObject?.email
							? setMySplit(null)
							: setCollaboratorData(
									collaboratorData.filter(
										(item) =>
											item.profilePic !== removeObject.profilePic ||
											item.musicianName !== removeObject.musicianName ||
											item.email !== removeObject.email ||
											item.role !== removeObject.role ||
											item.split !== removeObject.split
									)
							  );
					}
					setRemoveObject({});
				}}
				desc={'Are you sure you want to delete?'}
			/>
			<AddCollaborator
				openModal={modal}
				closeModal={() => HandleClose()}
				collaboratorData={collaboratorData}
				setCollaboratorData={(data) => setCollaboratorData(data)}
				mySplit={mySplit}
			/>
			<AddSplit
				openModal={splitPopup}
				closeModal={() => setSplitPopup(false)}
				mySplit={mySplit}
				collaboratorData={collaboratorData}
				setMySplit={(data) => setMySplit(data)}
			/>
			<EditCollaborator
			openModal={editModal}
			closeModal={() => setEditModal(false)}
			collaboratorData={collaboratorData}
			setCollaboratorData={(data) => setCollaboratorData(data)}
			collaboratorIndex={collaboratorIndex}
			setCollaboratorIndex={setCollaboratorIndex}
			mySplit={mySplit}
			setMySplit={setMySplit}
			isEditMysplit={isEditMysplit}
			setIsEditMysplit={setIsEditMysplit}
			/>
		</Box>
	);
};

export default UploadSoundKitsCollaborators;
