import React, { useState } from "react";
import { Main } from "./styles";
import { Box } from "@mui/material";
import TableMain from "../../common/tables/TableMain";
import { image2 } from "../../../../assets/smartlink";

const BestPerforming = () => {
  const [activeButton, setActiveButton] = useState("Links");

  const buttons = ["Links", "Locations", "Services"];

  const handleButtonClick = (buttonText) => {
    setActiveButton(buttonText);
  };

  const TableHeadData = [
    { title: "#", val: "id" },
    { title: "Link", val: "Link" },
    { title: "Visits", val: "Visits" },
    { title: "Click-Throughs", val: "ClickThroughs" },
    { title: "CTR", val: "CTR" },
  ];

  const BodyData = [
    {
      id: "1",
      Link: {
        image: image2,
        title: "Tu Hai Kaha",
        desc: "Uran Official",
      },
      Visits: "0",
      ClickThroughs: "0",
      CTR: "0%",
    },
    {
      id: "1",
      Link: {
        image: image2,
        title: "Tu Hai Kaha",
        desc: "Uran Official",
      },
      Visits: "0",
      ClickThroughs: "0",
      CTR: "0%",
    },
  ];

  return (
    <Main>
      <Box className="header">
        <h6>Best Performing</h6>
        <div className="header_button">
          {buttons.map((buttonText, index) => (
            <button
              key={index}
              onClick={() => handleButtonClick(buttonText)}
              className={activeButton === buttonText ? "active" : ""}
            >
              {buttonText}
            </button>
          ))}
        </div>
      </Box>
      <TableMain TableHeadData={TableHeadData} BodyData={BodyData} />
    </Main>
  );
};

export default BestPerforming;
