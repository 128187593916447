import React from "react";
import { Outlet, Navigate, useParams, useLocation } from "react-router-dom";
import { Loader } from "../components-old/Loader";
import useAuth from "../context/AuthContext";
import { Box } from "@mui/material";
import ProfilePopup from "../components/ProfilePopup/ProfilePopup";

function ProtectedRoutes({ component: Component, ...rest }) {
  const { isAuthenticated, loading, loadUser, setLoading, user } = useAuth();
  const { name, id } = useParams();
  const location = useLocation();
  const { hash, pathname, search } = location;

  console.log("ProtectedRoutes -> isAuthenticated", user);

  React.useEffect(() => {
    if (isAuthenticated) {
      return (
        <>
          {(!user?.phoneno ||
            user?.phoneno == 0 ||
            user?.phoneno == 123456 ||
            user?.phoneno == "N/A" ||
            user?.phone == "n/a" ||
            !user?.roleId ||
            user?.roleId?.roleName === "N/A" ||
            user?.roleId?.roleName === "n/a") &&
          pathname !== "/settings" ? (
            <ProfilePopup />
          ) : null}
          <Outlet />;
        </>
      );
    } else {
      loadUser();
    }
  }, []);

  return loading ? (
    <Box
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader />
    </Box>
  ) : isAuthenticated ? (
    <>
      {(!user?.phoneno ||
        user?.phoneno == 0 ||
        user?.phoneno == 123456 ||
        user?.phoneno == "N/A" ||
        user?.phone == "n/a" ||
        !user?.roleId ||
        user?.roleId?.roleName === "N/A" ||
        user?.roleId?.roleName === "n/a") &&
      pathname !== "/settings" ? (
        <ProfilePopup />
      ) : null}
      <Outlet />;
    </>
  ) : // <Outlet />
  name && id ? (
    <Navigate to="/producer/auth/welcome" />
  ) : (
    <Navigate to="/signin" />
  );
}

export default ProtectedRoutes;
