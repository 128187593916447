import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "../mycontracts.module.css";
import { Radio, withStyles } from "@material-ui/core";

const GreenRadio = withStyles({
  root: {
    color: "#AFAFAF",
    "&$checked": {
      color: "#2EBB55",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function LicenseForm() {
  const [picture, setPicture] = useState("");
  const [showEvelope, setShowEvelopeType] = useState(false);
  const [envelopsType, setEnvelopeType] = useState("Select ...");
  const [recipientCount, setRecipientCount] = useState(["1"]);
  const [showRoleTypes, setShowRoleTypes] = useState([false]);
  const [roleTypes, setRoleTypes] = useState(["Select ..."]);

  const envelopRef = useRef(null);
  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      console.log(currentFile);
      const currentFileName = currentFile.name;

      if (!currentFileName.match(/.(jpg|jpeg|png|gif|svg|pdf|docx|doc)$/i)) {
        console.log("not accepted");
        return false;
      }
      return true;
    }
  };

  const handleClickOutside = (event) => {
    if (envelopRef.current && !envelopRef.current.contains(event.target)) {
      setShowEvelopeType(false);
    }
  };

  const addRecipients = () => {
    let showRoleTypeStream = [...showRoleTypes];
    showRoleTypeStream.push(false);
    setShowRoleTypes(showRoleTypeStream);
    let roleTypeStream = [...roleTypes];
    roleTypeStream.push("Select ...");
    console.log(roleTypeStream);
    setRoleTypes(roleTypeStream);
  };

  const deleteRecipients = (id) => {
    // console.log("sdfs", "deleterecipient")
    setShowRoleTypes(showRoleTypes.filter((item, index) => index !== id));
    setRoleTypes(roleTypes.filter((item, index) => index !== id));
  };
  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOnDropLogoImage = async (files, rejectedFiles) => {
    if (!verifyImageFile(files)) return;
    if (files && files[0]) {
      let reader = new FileReader();
      reader.onloadend = function () {
        setPicture({
          filename: files[0].name,
          filetype: files[0].type,
          fileimage: reader.result,
          datetime: files[0].lastModifiedDate.toLocaleString("en-IN"),
          filesize: filesizes(files[0].size),
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleEvelopeType = (type) => {
    setEnvelopeType(type);
    setShowEvelopeType(false);
  };

  const handleRoleType = (type, id) => {
    let roleTypeStream = [...roleTypes];
    roleTypeStream[id] = type;
    setRoleTypes(roleTypeStream);
    handleShowRoleType(id);
  };
  const handleShowRoleType = (id) => {
    let showRoleTypeStream = [...showRoleTypes];
    showRoleTypeStream[id] = !showRoleTypeStream[id];
    setShowRoleTypes(showRoleTypeStream);
  };

  return (
    <div style={{gap:30, display:'flex', flexDirection:'column'}}>
      <div className={styles.middle_title_layout}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label className={styles.recipient_group_user_label}>
            Licese Name
          </label>
          <input type="text" className={styles.recipient_user_name} />
        </div>
      </div>
      <div className={styles.middle_title_layout}>
        <div className={styles.middle_title_font}>
          <p className={styles.middle_title_recip}>Contract Type</p>
        </div>
        <div className={styles.license_title_group}>
          <div>
            <p className={styles.license_subtitle_text}>
              Non-Exclusive License
            </p>
            <p className={styles.license_content_text}>
              This type of license allows the purchaser to use the beat while
              permitting the producer to sell the same beat to other artists.
            </p>
          </div>
          <GreenRadio />
        </div>
        <div className={styles.license_title_group}>
          <div>
            <p className={styles.license_subtitle_text}>Exclusive License</p>
            <p className={styles.license_content_text}>
              With an exclusive license, the purchaser gains exclusive rights to
              use the beat, and it will no longer be available for sale to other
              artists.
            </p>
          </div>
          <GreenRadio />
        </div>
        <div className={styles.license_title_group}>
          <div>
            <p className={styles.license_subtitle_text}>Buyout License</p>
            <p className={styles.license_content_text}>
              A buyout license provides the purchaser with complete ownership of
              the beat, including all rights, and removes the producer's ability
              to sell or distribute the beat further.
            </p>
          </div>
          <GreenRadio />
        </div>
        <div className={styles.license_title_group}>
          <div>
            <p className={styles.license_subtitle_text}>Custom License</p>
            <p className={styles.license_content_text}>
              This option allows for the creation of a customized license
              agreement tailored to specific terms and conditions agreed upon
              between the producer and the purchaser.
            </p>
          </div>
          <GreenRadio />
        </div>
      </div>
    </div>
  );
}
export default LicenseForm;
