import React, { useEffect } from "react";
import styles from "../auth_pages.module.css";
import { useState } from "react";
// import useAuth from "../../../../context/AuthContext";
import { FormField } from "./FormInput";
import { useNavigate } from "react-router-dom";
import { CardMain } from "../../smartlink/common/priceplans/styles/PricePlanCardstyle";
// import { addSubscription } from "../../../V2BackendComponents/stripeAPI/StripeService";
// import useAuth from "../../../context/AuthContext";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Grid } from "@mui/material";
import { SignUpMutation } from "../../../Graphql_services/SignUpMutation";
import { useMutation } from "@apollo/client";
import { Notification } from "../../NotifyMsg/Notification";

const monthlyPlans = [
  {
    plantype: "Free",
    title: "Free",
    desc: "Handle the basics of your music career",
    price: "$0",
    buttonName: "Get Started",
    inbox: "Create up to 3 audio file Inboxes",
    storage: "",
    submissions: "3 opportunity submissions per month",
    unlimited: "",
    store: "Cannot store files on United Market",
    
  },
  {
    plantype: "Monthly",
    title: "HitMaker",
    desc: "Increase your collaborations by 10x and earn more money as a HitMaker Member.",
    price: "$9.99 /mo",
    buttonName: "Get Started",
    inbox: "Create Unlimited inboxes",
    storage: "50 GB storage space",
    submissions: "Unlimited opportunity submissions",
    unlimited: "",
    access: "Gain early access to new features!",
    store: "",
  },
];
const yearlyPlans = [
  {
    plantype: "Free",
    title: "Free",
    desc: "Handle the basics of your music career",
    price: "$0",
    buttonName: "Get Started",
    inbox: "Create up to 3 audio file Inboxes",
    storage: "",
    submissions: "3 opportunity submissions per month",
    unlimited: "",
    store: "Cannot store files on United Market",
  },
  {
    plantype: "Yearly",
    title: "HitMaker",
    desc: "Increase your collaborations by 10x and earn more money as a HitMaker Member.",
    price: "$99/yr",
    offer: "Save 17%",
    buttonName: "Get Started",
    inbox: "Create Unlimited inboxes",
    storage: "50 GB storage space",
    submissions: "Unlimited opportunity submissions",
    access: "Gain early access to new features!",
    unlimited: "Store audio files on United Market.",
    store: "",
  },
];



const SelectMembership = ({ onBack, user, onNext, completeAccount }) => {
  
  const [userValue,setUserValue]=useState({...user})
  const url = window.location.pathname;
  useEffect(()=>{
   if(url == '/approved/signup'){
    let newUser= JSON.parse(localStorage.getItem('user'))
    setUserValue({...newUser})
   }else{
    setUserValue({...user})
   }

  },[url])
  console.log("userValue",userValue)

  const NoFreemiumPlans = [
    {
      plantype: "Monthly",
      title: "Monthly",
      desc: "Increase your collaborations by 10x and earn more money as a HitMaker Member.",
      price: "$9.99/mo",
      buttonName: "Get Started",
      inbox: "Create Unlimited inboxes",
      storage: "50 GB storage space",
      submissions: userValue?.roleId?.roleName === "Music Producer" || userValue?.profile?.roleName === "Music Producer"
        ? "Unlimited artist collaboration & sync submissions"
        : userValue?.roleId?.roleName === "Artist" || userValue?.profile?.roleName === "Artist"
        ? "Unlimited Sync submissions"
        : "Gain access to quality production on demand",
      access: "Gain early access to new features!",
      store: "",
      unlimited: "",
    },
    {
      plantype: "Yearly",
      title: "Yearly",
      desc: "Increase your collaborations by 10x and earn more money as a HitMaker Member.",
      price: "$99/yr",
      offer: "Save 17%",
      buttonName: "Get Started",
      inbox: "Create Unlimited inboxes",
      storage: "50 GB storage space",
      submissions: userValue?.roleId?.roleName === "Music Producer" || userValue?.profile?.roleName === "Music Producer"
      ? "Unlimited artist collaboration & sync submissions"
      : userValue?.roleId?.roleName === "Artist" || userValue?.profile?.roleName === "Artist"
      ? "Unlimited Sync submissions"
      : "Gain access to quality production on demand",
      access: "Gain early access to new features!",
      unlimited: "",
      store: "",
    },
  ];
    

  const [activeButton, setActiveButton] = useState("monthly");
  const [promo, setPromo] = useState(null);
  const [SelectedPriceId, setSelectedPriceId] = useState(null);
  // const [SelectedPlan, setSelectedPlan] = useState(monthlyPlans);
  const [SelectedPlan, setSelectedPlan] = useState(NoFreemiumPlans);


  const canContinue = SelectedPriceId !== null;
  const [signUpMutation, { loading, error, data }] =
    useMutation(SignUpMutation);

  const navigate = useNavigate();

  const handelMembership = (priceid) => {
    setSelectedPriceId(priceid);
  };
  const handlePlanButtons = (Type) => {
    setActiveButton(Type);
    const SelectedPlans =
      Type === "monthly" ? monthlyPlans : Type === "yearly" ? yearlyPlans : [];
    setSelectedPlan(SelectedPlans);
  };

  const ClickHandelMembership = (title) => {
    if (title === "Free") {
      window.location.href = "/home";
    } else if (title === "Monthly") {
      let updatedUser = { ...userValue };
      let updatedProfile = { ...userValue.profile };
      updatedProfile.planID = process.env.REACT_APP_MONTHLY_SUBSCRIPTION;
      // updatedProfile.PromoCode = promo;
      updatedUser.profile = updatedProfile;
      onNext(updatedUser);
    } else if (title === "Yearly") {
      let updatedUser = { ...userValue };
      let updatedProfile = { ...userValue.profile };
      updatedProfile.planID = process.env.REACT_APP_YEARLY_SUBSCRIPTION;
      // updatedProfile.PromoCode = promo;
      updatedUser.profile = updatedProfile;
      onNext(updatedUser);
    }
  };


  return (
    <div className={`${styles.main_layout} ${styles.MemberShipWrapper}`}>
      <div className={styles.shadow_overlay}>
        <div className={styles.auth_box}>
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header_payment}>
              <p className={styles.auth_title}>Gain priority access</p>
              <p className={styles.auth_description}>
              We are currently invite-only and are accepting applications on a monthly basis for priority access select a membership.
              </p>
            </div>
            {/* <div className={styles.auth_button_box}>
              <button
                className={`${styles.auth_plan_button} ${
                  activeButton === "monthly" ? styles.active : ""
                }`}
                onClick={() => handlePlanButtons("monthly")}
              >
                Monthly
              </button>
              <button
                className={`${styles.auth_plan_button} ${
                  activeButton === "yearly" ? styles.active : ""
                }`}
                onClick={() => handlePlanButtons("yearly")}
              >
                Yearly
              </button>
            </div> */}
            <div className={styles.auth_card_main}>
              <Grid container spacing={2}>
                {SelectedPlan?.map((items, index) => (
                  <Grid item xs={12} lg={6} md={6} key={index}>
                    <div className={styles.auth_card_box}>
                      <h3 className={styles.auth_package_title}>
                        {items.title}
                      </h3>
                      <h4 className={styles.auth_package_description}>
                        {items.desc}
                      </h4>
                      <h5
                        className={`${styles.auth_package_price} ${
                          items.price === "$0" && styles.auth_free_price
                        }`}
                      >
                        <>
                          {items.price.split("/")[0]}
                          <span style={{ color: "gray", fontSize: "14px" }}>
                            /{items.price.split("/")[1]}
                          </span>
                        </>
                      </h5>
                      <h6 className={styles.auth_package_offer}>
                        {items.offer}

                        {items.offer && (
                          <>
                            <span>Annually</span>
                          </>
                        )}
                      </h6>
                      <button
                        className={styles.auth_package_button}
                        onClick={() => ClickHandelMembership(items.plantype)}
                        disabled={loading}
                      >
                        {items.plantype === "Free" && loading
                          ? "Processing"
                          : items.buttonName}
                      </button>

                      {items.storage && (
                        <div className={styles.auth_package_details_box}>
                          <CheckCircleOutlineIcon
                            className={styles.auth_package_icon}
                          />
                          <p className={styles.auth_package_info}>
                            {items.storage}
                          </p>
                        </div>
                      )}

                      {items.inbox && (
                        <div className={styles.auth_package_details_box}>
                          <CheckCircleOutlineIcon
                            className={styles.auth_package_icon}
                          />
                          <p className={styles.auth_package_info}>
                            {items.inbox
                              .split("Unlimited")
                              .map((part, index) => (
                                <React.Fragment key={index}>
                                  {index > 0 && <span>Unlimited </span>}
                                  {part.trim()}
                                </React.Fragment>
                              ))}
                          </p>
                        </div>
                      )}
                      {/* {items.submissions && (
                        <div className={styles.auth_package_details_box}>
                          <CheckCircleOutlineIcon
                            className={styles.auth_package_icon}
                          />
                          <p className={styles.auth_package_info}>
                          {items.submissions
    .split("Unlimited")
    .map((part, index) => (
        <React.Fragment key={index}>
            {index > 0 && <span style={{margin:"0px"}}>Unlimited </span>}
            {part.trim()}
        </React.Fragment>
    ))}

                          </p>
                        </div>
                      )} */}
                      {items.submissions && (
    <div className={styles.auth_package_details_box}>
        <CheckCircleOutlineIcon className={styles.auth_package_icon} />
        <p className={styles.auth_package_info}>
            {items.submissions
                .split("Unlimited")
                .map((part, index) => {
                    const parts = part.split("Gain access");
                    return (
                        <React.Fragment key={index}>
                            {parts.map((subPart, subIndex) => (
                                <React.Fragment key={subIndex}>
                                    {subIndex === 0 && index > 0 && <span style={{ margin: "0px" }}>Unlimited </span>}
                                    {subIndex > 0 && <span style={{ margin: "0px"}}>Gain access </span>}
                                    {subPart.trim()}
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    );
                })}
        </p>
    </div>
)}


                      {items.store && (
                        <div className={styles.auth_package_details_box}>
                          <HighlightOffIcon
                            className={styles.auth_package_icon}
                          />
                          <p className={styles.auth_package_info}>
                            {items.store}
                          </p>
                        </div>
                      )}
                      {items.access && (
                        <div className={styles.auth_package_details_box}>
                          <CheckCircleOutlineIcon
                            className={styles.auth_package_icon}
                          />
                          <p className={styles.auth_package_info}>
                            {items.access}
                          </p>
                        </div>
                      )}

                      {items.title !== "Free" && (
                        <>
                          {items.store && (
                            <div className={styles.auth_package_details_box}>
                              <CheckCircleOutlineIcon
                                className={styles.auth_package_icon}
                              />
                              <p className={styles.auth_package_info}>
                                {items.store}
                              </p>
                            </div>
                          )}

                          {items.unlimited && (
                            <div className={styles.auth_package_details_box}>
                              <CheckCircleOutlineIcon
                                className={styles.auth_package_icon}
                              />
                              <p className={styles.auth_package_info}>
                                {items.unlimited}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
            {canContinue ? (
              <div
                onClick={() => {
                  let updatedUser = { ...user };
                  let updatedProfile = { ...user.profile };
                  updatedProfile.planID = SelectedPriceId;
                  updatedProfile.PromoCode = promo;
                  updatedUser.profile = updatedProfile;
                  onNext(updatedUser);
                }}
                className={styles.signin_box}
              >
                <p className={styles.connect_text}>Continue</p>
              </div>
            ) : (
              <div className={styles.signin_box_inactive}>
                <p className={styles.connect_text}>Continue</p>
              </div>
            )}
            {/* <p className={styles.previous_text} onClick={onBack}>
              Previous Step
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectMembership;
