import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const OpportunitySectionMain = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "#141515",
  padding: "50px 0 100px 0",
  textAlign: "center",
}));
const OpportunityMain = styled(Box)(({ theme }) => ({
  width: "91.5%",
  margin: "0 auto",
  "& .item_main": {
    "& .item_image": {
      width: "100%",
      height: "auto",
    },
    borderRadius: "10px",
    overflow: "hidden",
    cursor: "pointer",
    position: "relative",
    transition: "background 0.3s ease",
    "&:hover": {
      "& .btn_box": {
        opacity: 1,
      },
    },
  },
  "& .btn_box": {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    background: "rgba(0, 0, 0, 0.70)",
    opacity: 0,
    transition: "opacity 0.6s ease",
    "& .btn": {
      padding: "10px 24px",
      borderRadius: "5px",
      background: "#2EBB55",
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
  },
  "& .desc_box": {
    width: "auto",
    height: "auto",
    position: "absolute",
    bottom: 20,
    paddingLeft: "20px",
    textAlign: "left",
    h5: {
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      margin: 0,
    },
    h6: {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      margin: 0,
    },
  },
}));
export { OpportunitySectionMain, OpportunityMain };
