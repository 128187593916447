import React, { useEffect } from "react";
import styles from "./success.module.css";
import successImg from "../../assets/Images/Sucess.png";
import { useSearchParams } from "react-router-dom";

import { getChartaUrl } from "../../staticUtils/FetchApis/ChartaApis";
import { toast } from "react-toastify";

const Success = () => {
  const [searchParams] = useSearchParams();

  const chartaFun = async (data) => {
    try {
      const { url } = await getChartaUrl(data);

      if (url) {
        console.info("charta Response => ", url);
        if (url) {
          window.location.assign(url, "_blank").focus();
        } else {
          toast.error("Something went wrong.");
        }
      }
    } catch (error) {
      console.log("Try after some time .");
    }
  };
  useEffect(() => {
    let data = {
      id: searchParams.get("id"),
      email: searchParams.get("email"),
      firstName: searchParams.get("fn"),
      lastName: searchParams.get("ln"),
      arAggrement: searchParams.get("ar"),
    };
    console.info(
      "params :: ",
      searchParams.get("email"),
      searchParams.get("fn"),
      searchParams.get("ln"),
      searchParams.get("ar")
    );

    if (data) {
      chartaFun(data);
    }
  });

  return (
    <div>
      <div className={styles.wrapperDiv}>
        <span className={styles.title}>Payment successfully completed</span>
        <span className={styles.subTitle}>
          You will be redirected to the contract page
        </span>
        <div>
          <img className={styles.successImg} src={successImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Success;
