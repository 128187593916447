import {
  coin,
  location,
  monitor_mobbile,
  wallet,
  sales,
} from "../../../../assets/smartlink";

const InsightPageLink = [
  {
    name: "Sources",
    icon: coin,
    url: "/smart-link/insights-sources",
  },
  {
    name: "Locations",
    icon: location,
    url: "/smart-link/insights-location",
  },
  {
    name: "Devices",
    icon: monitor_mobbile,
    url: "/smart-link/insights-devices",
  },
  {
    name: "Commission",
    icon: wallet,
    url: "/smart-link/insights-commission",
  },
  {
    name: "Sales",
    icon: sales,
    url: "/smart-link/insights-sales",
  },
];

export { InsightPageLink };
