import React from "react";
import { Grid } from "@mui/material";

// import styles from "./styles.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import CreateSong from "../../components/AiSongwriterComponents/create_song";

const CreateSongPage = () => {
  return (
    <Grid>
      <Sidebar activeText="AI Songwriter" childComponent={<CreateSong />} />
    </Grid>
  );
};

export default CreateSongPage;
