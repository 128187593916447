import { AppBar, Box, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const SideBarMain = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "14px",
  paddingTop: "30px",
  gap: "1rem",
  background: theme.palette.primary.light,
  "& h5": {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginTop: "50px",
    color: "#E3E3E3",
  },
  ul: {
    width: "100%",
  },
  li: {
    width: "100%",
  },
}));

const PreferenceBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
  ul: {
    width: "100%",
  },
  li: {
    width: "100%",
  },
  "& h4": {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
}));
const MenuListBox = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "3rem",
  width: "100%",
  color: theme.palette.secondary.dark,
  gap: "8px",
  borderRadius: "0.3125rem",
  background: theme.palette.primary.dark,
  cursor: "pointer",
  margin: "9px 0px",
  paddingLeft: "15px",
  transition: "background 0.3s ease",
  textDecoration: "none",
  img: {
    width: "20px",
  },
  "& .menu_main_text": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .menu_bets": {
    paddingRight: "8px",
    span: {
      border: "1px solid #2ebb55",
      padding: "4px 7px",
      fontSize: "9px",
      borderRadius: "5px",
      color: "#2ebb55",
    },
  },
  "& h4": {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  "&.active_menu": {
    background: "#2EBB55",
    color: "#fff",
    borderRadius: "0rem",
    "& img": {
      filter: "brightness(0) invert(1)",
    },
  },
  // Hover effect
  "&:hover": {
    background: "#2EBB55",
    color: "#fff",
    borderRadius: "0rem",
  },
  "&:hover img": {
    filter: "brightness(0) invert(1)",
  },
}));

export { SideBarMain, MenuListBox, PreferenceBox };
