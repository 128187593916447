import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import BeatAddModal from "../../../components-old/BeatAddModal/BeatAddModal";
import {
  PITCHING_PERIOD,
  PUBLISH_PERCENT_AMOUNT,
} from "../../../shared/constants";
import useAppState from "../../../context/useAppState";
import FormSelect from "../../OpportunitiesComponents/AdditionalComponents/FormSelect";
import styles from "../ViewSubmissionInformation/beatinfo.module.css";
import userAdd from "../../../../src/assets/Icons/userAdd.png";
import edit from "../../../../src/assets/Icons/edit.png";
import { dayCount } from "../../../shared/funs";
import { toast } from "react-toastify";

function ConnectBeatInfoData({ beatData }) {
  const {
    updateBeatPrice,
    extendPitching,
    addCollaboratorData,
    updateCollaborator,
  } = useAppState("submission");
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const [showInfo, setShowInfo] = useState(false);
  const [isCollab, setIsCollab] = useState(false);
  const [showPitching, setShowPitching] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCollabId, setIsCollabId] = useState(null);
  const [isShowBeat, setShowBeat] = useState(false);
  const [pitchingDays, setPitchingDays] = useState("30days");
  const [beatDataValue, setBeatDataValue] = useState(null);
  const [publishPercent, setPublishPercent] = useState(0);
  const [singleCollaboratorData, setSingleCollaboratorData] = useState([]);

  useEffect(() => {
    if (beatData) {
      setBeatDataValue(beatData);
    }
  }, [beatData]);

  const calculatePublishPercentage = (data) => {
    const totalPercentage = data.reduce((total, collaborator) => {
      return total + collaborator.publishingPercentage;
    }, 0);
    setPublishPercent(totalPercentage);
    return totalPercentage;
  };

  const onSubmit = (data) => {
    if (isEdit) {
      const collaborators = {
        name: data.musicianName,
        email: data.email,
        publishingPercentage: data.splitPercentage,
        id: isCollabId,
      };

      const updatedData = singleCollaboratorData.map((item) =>
        item._id === isCollabId
          ? { ...item, publishingPercentage: Number(data.splitPercentage) }
          : item
      );
      const totalPublishPercentage = calculatePublishPercentage(updatedData);

      if (
        totalPublishPercentage <=
        PUBLISH_PERCENT_AMOUNT - beatDataValue.publishingSplit
      ) {
        updateCollaborator(beatDataValue._id, collaborators).then((res) => {
          if (res) {
            setBeatDataValue(res.data);
          }
        });
        setIsCollab(false);
        setIsEdit(false);
      } else {
        toast.error(
          `Sum of all collaborators split must be less than or equal to ${
            PUBLISH_PERCENT_AMOUNT - beatDataValue.publishingSplit
          }% of total`
        );
        return;
      }
    } else {
      if (
        publishPercent + Number(data.splitPercentage) <=
        PUBLISH_PERCENT_AMOUNT - beatDataValue.publishingSplit
      ) {
        const collaborators = {
          name: data.musicianName,
          email: data.email,
          publishingPercentage: data.splitPercentage,
        };

        const collaboratorsData = {
          collaborators: [],
        };
        collaboratorsData.collaborators.push(collaborators);

        addCollaboratorData(beatDataValue._id, collaboratorsData).then(
          (res) => {
            if (res) {
              setBeatDataValue(res.data.data);
            }
          }
        );
        setIsCollab(false);
        setIsEdit(false);
      } else {
        toast.error(
          `Sum of all collaborators split must be less than or equal to ${
            PUBLISH_PERCENT_AMOUNT - beatDataValue.publishingSplit
          }% of total`
        );
        return;
      }
    }
  };

  const addCollaborator = (collabData) => {
    calculatePublishPercentage(collabData);
    reset();
    setIsCollab(true);
    setIsEdit(false);
  };

  const editCollabData = (collabData, beatData) => {
    setSingleCollaboratorData(beatData);
    setIsEdit(true);
    setIsCollab(true);
    setIsCollabId(collabData._id);
    setValue("musicianName", collabData.name);
    setValue("email", collabData.email);
    setValue("splitPercentage", collabData.publishingPercentage);
  };

  const handleUpdateBeatPrice = (id, beatPrice) => {
    const payload = {
      exclusivePrice: beatPrice,
    };
    updateBeatPrice(id, payload).then((res) => {
      console.log("res =", res);
      setBeatDataValue(res);
    });
  };

  const onSelect = (data) => {
    setPitchingDays(data.payload);
  };

  // extending pitching period
  const handleSave = () => {
    if (pitchingDays) {
      extendPitching(beatDataValue?._id, {
        pitchingPeriod: pitchingDays?.value,
      }).then((res) => {
        if (res) {
          setBeatDataValue({
            ...beatDataValue,
            pitchingPeriod: pitchingDays?.value,
          });
        }
      });
    }
  };

  return (
    <div>
      <button onClick={() => setShowInfo(true)} className={styles.info_button}>
        Beat information
      </button>
      {showInfo && (
        <div>
          {!isCollab && (
            <BeatAddModal
              handleClose={() => setShowInfo(false)}
              title="Beat Information"
            >
              <div className={styles.section}>
                <span className={styles.subtitle}>Collaborators & Splits</span>
                <div
                  className={styles.addCollaborator}
                  onClick={() => addCollaborator(beatDataValue.collaborators)}
                >
                  <img
                    src={userAdd}
                    alt="user-add image"
                    className={styles.user_add_icon}
                  />
                  <span className={styles.addCollaborator}>
                    Add Collaborator
                  </span>
                </div>
              </div>

              <div className={styles.collaborators}>
                {beatDataValue?.collaborators?.length > 0 ? (
                  beatDataValue?.collaborators?.map((collab, key) => (
                    <div className={styles.collaborator} key={key}>
                      <img
                        src={
                          collab.collaboratorAvatar
                            ? collab.collaboratorAvatar
                            : "https://img.freepik.com/premium-vector/man-avatar-profile-round-icon_24640-14044.jpg?w=740"
                        }
                        alt={collab.collaboratorName}
                        className={styles.avatar}
                      />
                      <span className={styles.collaborator_name}>
                        {collab.name}
                      </span>
                      <span className={styles.collaborator_split}>
                        {collab.publishingPercentage}%
                      </span>
                      <img
                        src={edit}
                        alt="edit image"
                        className={styles.editIcon}
                        onClick={() =>
                          editCollabData(collab, beatDataValue?.collaborators)
                        }
                      />
                    </div>
                  ))
                ) : (
                  <p className={styles.no_collaborator}>
                    No collaborator added
                  </p>
                )}
              </div>

              <div className={styles.section}>
                <span className={styles.title}>Beat Price</span>
                <span className={styles.beat_price}>
                  {beatDataValue?.exclusivePrice === "None"
                    ? "None"
                    : `$${beatDataValue?.exclusivePrice || "0" || "$ 0"}`}

                  <img
                    src={edit}
                    alt="edit image"
                    className={styles.editIcon}
                    onClick={() => (
                      setShowBeat(true),
                      setValue("beatPrice", beatDataValue?.exclusivePrice)
                    )}
                  />
                </span>
              </div>

              {isShowBeat && (
                <div className={styles.beat_exclusive_price}>
                  <div className={styles.beat_price_input}>
                    <Controller
                      className={styles.musicName}
                      name="beatPrice"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => <input {...field} type="number" />}
                    />
                    <button
                      className={styles.beat_price_cancel_btn}
                      onClick={() => setShowBeat(false)}
                    >
                      cancel
                    </button>
                    <button
                      className={styles.beat_price_update_btn}
                      onClick={() =>
                        handleUpdateBeatPrice(
                          beatDataValue._id,
                          getValues("beatPrice")
                        )
                      }
                    >
                      update
                    </button>
                  </div>
                </div>
              )}

              <div className={styles.section}>
                <span className={styles.title}>Beat Pitching Period</span>
                <span className={styles.pitching_period}>
                  {beatDataValue?.pitchingPeriod
                    ? `${dayCount(
                        beatDataValue.createdAt,
                        beatDataValue.pitchingPeriod.split(" ")[0]
                      )} days left`
                    : ""}
                </span>
              </div>

              {!showPitching && (
                <button
                  className={styles.extend_button}
                  onClick={() => setShowPitching(true)}
                >
                  Extend Pitching Period
                </button>
              )}

              {showPitching && (
                <div className={styles.pitchDropdown}>
                  <div className={styles.input_add_pitching}>
                    <FormSelect
                      setSize="small"
                      className={styles.dropDown}
                      data={PITCHING_PERIOD}
                      value={pitchingDays}
                      action="Add_Pitching"
                      handler={onSelect}
                    />
                  </div>

                  <div className={styles.save_btn}>
                    <button
                      type="button"
                      className={styles.cancel_btn}
                      onClick={() => setShowPitching(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className={styles.extend_button}
                      onClick={(_id) => handleSave(beatDataValue.id)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </BeatAddModal>
          )}

          {/* // </div> */}

          {isCollab && (
            <BeatAddModal
              handleClose={() => {
                setShowInfo(false);
                setIsCollab(false);
              }}
              title={isEdit ? "Update Collaborator" : "Add Collaborator"}
            >
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={styles.colloboratorForm}
              >
                <div className={styles.musician}>
                  <label
                    htmlFor="musicianName"
                    className={styles.musician_label}
                  >
                    Musician Name
                  </label>
                  <div className={styles.musician_input}>
                    <Controller
                      className={styles.musicName}
                      name="musicianName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please Enter Name" }}
                      render={({ field }) => (
                        <>
                          <input {...field} type="text" />
                          {errors?.musicianName && (
                            <span style={{ color: "red" }}>
                              {errors?.musicianName?.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className={styles.email}>
                  <label htmlFor="email" className={styles.musician_label}>
                    Email
                  </label>
                  <div className={styles.musician_input}>
                    <Controller
                      // className={styles.email}
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Please Enter Email",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <input {...field} type="email" />
                          {errors?.email && (
                            <span style={{ color: "red" }}>
                              {errors?.email?.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>

                <div className={styles.splitPercentage}>
                  <label
                    htmlFor="splitPercentage"
                    className={styles.musician_label}
                  >
                    Split %
                  </label>
                  <div className={styles.musician_input}>
                    <Controller
                      // className={styles.splitPercentage}
                      name="splitPercentage"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Please Enter Split Percentage",
                        pattern: /^\d+$/,
                      }}
                      render={({ field }) => (
                        <>
                          <input {...field} type="number" />
                          {errors?.splitPercentage && (
                            <span style={{ color: "red" }}>
                              {errors?.splitPercentage?.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className={styles.buttons}>
                  <button
                    type="button"
                    className={styles.cancel}
                    onClick={() => (setIsCollab(false), setIsEdit(false))}
                  >
                    CANCEL
                  </button>
                  <button className={styles.sentbutton}>SEND</button>
                </div>
              </form>
            </BeatAddModal>
          )}
        </div>
      )}
    </div>
  );
}

export default ConnectBeatInfoData;
