import React from "react";
import { Link } from "react-router-dom";

const Forbidden = () => (
  <div
    style={{
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <h1 style={{ color: "#fff", fontFamily: "Poppins" }}>Forbidden</h1>
    <p style={{ color: "#fff", fontFamily: "Poppins" }}>
      You don't have permission to access this page.
    </p>
    <Link to="/" style={{ color: "#fff", fontFamily: "Poppins" }}>
      Go Home
    </Link>
  </div>
);

export default Forbidden;
