import React, { memo, useState, useEffect } from "react";
import { SocialMain, SocialItem, Socialleft, SocialRight } from "./style";
import {
  x_icon,
  TiktokIcon,
  InstagramIcon,
  FacebookIcon,
} from "../../../assets/smartlink";
import { Button } from "@mui/material";
import SocialPopup from "./SocialPopup";
import DisconnectPopup from "./DisconnectPopup";
// import AuthWelcomePopup from "../producers/auths/AuthWelcomePopup";
import { useMutation, gql } from "@apollo/client";
import useAuth from "../../../context/AuthContext";
import LinkSuccessPopup from "./Linksuccess";

const INSTAGRAM_LOGIN_MUTATION = gql`
  mutation InstagramLogin($code: String!) {
    instagramLogin(code: $code) {
      success
      isLogin
      message
      user {
        id
        username
      }
    }
  }
`;

const TWITTER_LOGIN_MUTATION = gql`
  mutation TwitterLogin($oauthToken: String!, $oauthVerifier: String!) {
    twitterLogin(oauthToken: $oauthToken, oauthVerifier: $oauthVerifier) {
      success
      isLogin
      message
      user {
        email
        name
      }
    }
  }
`;

const SocialArray = [
  {
    name: "X",
    icon: x_icon,
    connected: false,
    AppId: process.env.REACT_APP_TWITTER_CONSUMER_KEY,
  },
  {
    name: "instagram",
    icon: InstagramIcon,
    connected: false,
    AppId: process.env.REACT_APP_INSTAGRAM_CLIENT_ID,
  },
  {
    name: "tiktok",
    icon: TiktokIcon,
    connected: false,
    AppId: process.env.REACT_APP_TIKTOK_CLIENT_ID,
  },
  {
    name: "facebook",
    icon: FacebookIcon,
    connected: false,
    AppId: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
  },
];

const SocialIntegrate = () => {
  const [open, setOpen] = useState(false);
  const [Disconnectopen, setDisconnectopen] = useState(false);
  const [SuccessOpen, setSuccessOpen] = useState(false);
  const [selectedsocial, setselectedsocial] = useState([]);

  const OpensModel = (value) => {
    if (!value.connected) {
      setselectedsocial(value);
      setOpen(true);
    } else {
      setDisconnectopen(true);
    }
  };

  // ========================================= Social Logins ==================================

  const [CodeUrl, setCodeurl] = useState("");

  // ===================== Instagram Login Handle ====================

  // Backend User Creation

  const [instagramLogin] = useMutation(INSTAGRAM_LOGIN_MUTATION, {
    variables: { code: CodeUrl },
    context: { clientName: "smartlink" },
  });

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");

    if (code) {
      setCodeurl(code);
      const loginWithInstagram = async () => {
        try {
          const { data } = await instagramLogin();
          console.log(data.instagramLogin);
        } catch (error) {
          console.error(error, "Err");
        }
      };

      loginWithInstagram();
    }
  }, [CodeUrl]);

  // ===================== Instgram Login Ending ==================

  // ===================== Twitter Login Handle ====================

  const [oauthToken, setOuthToken] = useState(null);
  const [oauthVerifier, setOauthVerifier] = useState(null);

  const [TwitterLogin] = useMutation(TWITTER_LOGIN_MUTATION, {
    variables: { oauthToken: oauthToken, oauthVerifier: oauthVerifier },
    context: { clientName: "smartlink" },
  });

  useEffect(() => {
    //// Get Params From URL

    const extractOAuthParams = () => {
      const url = new URL(window.location.href);
      const oauthToken = url.searchParams.get("oauth_token");
      const oauthVerifier = url.searchParams.get("oauth_verifier");

      if (oauthToken && oauthVerifier) {
        setOuthToken(oauthToken);
        setOauthVerifier(oauthVerifier);
      }
    };
    extractOAuthParams();
  }, []);

  // Backend User Creation

  useEffect(() => {
    const loginWithInstagram = async () => {
      try {
        const { data } = await TwitterLogin();
        console.log(data?.twitterLogin?.message);
      } catch (error) {
        console.error(error, "Err");
      }
    };

    loginWithInstagram();
  }, [oauthToken, oauthVerifier]);

  // ===================== Twitter Login Ending ====================

  const { user } = useAuth();
  console.log(user, "Current User");

  return (
    <>
      <SocialMain>
        {SocialArray.map((value) => {
          return (
            <>
              <SocialItem className="Social_item" key={value.name}>
                <Socialleft className="Social_left">
                  <img src={value.icon} alt="x_icon" />
                  <h6>{value.name}</h6>
                </Socialleft>
                <SocialRight className="Social_right">
                  <Button
                    className={value.connected ? "connected" : ""}
                    onClick={() => OpensModel(value)}
                  >
                    {!value.connected ? "connect" : "Disconnect"}
                  </Button>
                </SocialRight>
              </SocialItem>
            </>
          );
        })}
      </SocialMain>
      <SocialPopup
        open={open}
        setOpen={setOpen}
        selectedsocial={selectedsocial}
        setSuccessOpen={setSuccessOpen}
      />
      <DisconnectPopup open={Disconnectopen} setOpen={setDisconnectopen} />
      <LinkSuccessPopup
        SuccessOpen={SuccessOpen}
        setSuccessOpen={setSuccessOpen}
      />
    </>
  );
};

export default memo(SocialIntegrate);
