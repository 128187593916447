import React from "react";
import styles from "./connect.module.css";
import ConnectMeCard from "./ConnectMeCard";
import PageSearchHeader from "../UniversalComponents/page_search_header";
import { useState, useEffect } from "react";

import useAppState from "../../context/useAppState";

import useAuth from "../../context/AuthContext";
import { useDebounce } from "../../hooks/useDebounce";
import { size } from "lodash";

function ConnectMeUsers({ query }) {
  const { user } = useAuth();
  const userId = user._id;
  const { loadConnectMeUsers } = useAppState("connectMe"); // Use the context hook for loading data
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const searchQuery = useDebounce(query.trim(), 500);
  const PAGE_LIMIT = 12;

  useEffect(() => {
    setLoading(true);
    loadConnectMeUsers(userId, pageNum, PAGE_LIMIT, query) // Pass the query to the API request
      .then((response) => {
        console.log(response);
        const loadedUsers = response;
        setData(loadedUsers.users);
        console.log(data);
        setHasNextPage(loadedUsers.hasNextPage);
        setTotalPages(loadedUsers.totalPages);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageNum]);

  useEffect(() => {
    setLoading(true);
    loadConnectMeUsers(userId, 1, PAGE_LIMIT, searchQuery)
      .then((response) => {
        const loadedUsers = response;
        setData(loadedUsers.users);
        setHasNextPage(loadedUsers.hasNextPage);
        setTotalPages(loadedUsers.totalPages);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchQuery]);

  const handleConnectionStatusChange = (updatedProfile) => {
    setData((prevProfiles) =>
      prevProfiles.map((profile) =>
        profile._id === updatedProfile._id ? updatedProfile : profile
      )
    );
  };

  return (
    <div className={styles.artist_list}>
      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              marginTop: 70,
              fontSize: "16px",
            }}
          >
            Loading...
          </div>
        ) : size(data) > 0 ? (
          <div className={styles.artist_list_grid}>
            {data.map((user, index) => (
              <ConnectMeCard
              key={`connectmeC-${index}`}
                user={user}
                onConnectionStatusChanged={handleConnectionStatusChange}
              />
            ))}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              marginTop: 70,
              fontSize: "16px",
            }}
          >
            No users found.
          </div>
        )}
      </div>
      {size(data) > 0 && (
        <div className={styles.bottom_controls}>
          {pageNum > 1 && (
            <div
              className={styles.bottom_controls_button}
              onClick={() => {
                setPageNum(pageNum - 1);
              }}
            >
              <p className={styles.bottom_controls_button_text}>Previous</p>
            </div>
          )}
          {totalPages > pageNum && (
            <div
              className={styles.bottom_controls_button}
              onClick={() => {
                setPageNum(pageNum + 1);
              }}
            >
              <p className={styles.bottom_controls_button_text}>Next</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ConnectMeUsers;
