import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const MainPreReleaseServices = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexShrink: 0,
  h3: {
    FontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    color: theme.palette.secondary.light,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
}));
const PreReleaseServicesBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "25px",
  background: theme.palette.primary.light,
  borderRadius: "0.3125rem",
  padding: "2.5rem",
  h3: {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    margin: "0",
  },
  "& .description": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    color: "#AFAFAF",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "12px",
  },
  "& .releaseDate": {
    color: "#FFF",
    FontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "27px",
  },
  "& .datepicker-main": {
    width: "100%",
    display: "flex",
    gap: "18px",
    marginTop: "12px",
    "& date-picker-box": {
      width: "50%",
    },
    "& .timebox-main": {
      width: "50%",
      display: "flex",
      alignItems: "center",
      gap: "18px",
      "& .time-box": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "15px 22px",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "normal",
        letterSpacing: "3px",
        fontFamily: theme.typography.fontFamily,
        background: "#1C1B1B",
        borderRadius: "5px",
        border: "1px solid #414241",
        color: "#FFFFFF",
        textTransform: "uppercase",
      },
      "& span": {
        textTransform: "uppercase",
        color: theme.palette.secondary.dark,
        cursor: "pointer",
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "25px",
      "& date-picker-box": {
        width: "100%",
      },
      "& .timebox-main": {
        width: "100%",
      },
    },
  },
  "& .release-source": {
    display: "flex",
    alignItems: "center",
    width: "70%",
    marginTop: "12px",
    border: "1px solid #414241",
    borderRadius: "5px",
    "& span": {
      display: "flex",
      fontSize: "1rem",
      justifyContent: "center",
      alignItems: "center",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: theme.palette.secondary.light,
      background: "#1C1B1B",
      padding: "13px 20px",
    },
    "& input": {
      width: "100%",
      padding: "15px 15px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      fontFamily: theme.typography.fontFamily,
      color: "#AFAFAF",
      background: "#1C1B1B",
      outline: "none",
      border: "none",
      borderLeft: "1px solid #414241",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  "& .parts": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "100%",
    marginTop: "2rem",
    h3: {
      color: "#FFFFFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "& span": {
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      fontFamily: theme.typography.fontFamily,
      color: "#AFAFAF",
      fontSize: "12px",
    },
  },
  "& .sourcebox-main": {
    paddingBottom: "50px !important",
  },
}));
const ActiveServices = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: "2.5rem",
  h5: {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: "30px",
    marginTop: "0",
  },
  h4: {
    color: "#AFAFAF",
    FontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
}));

const MainServicesBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center !important",
  width: "100% !important",
  padding: "24px",
  borderTop: "1px solid #414241",
  flexShrink: 0,
}));
const RightBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "18px",
  "& .img-box": {
    width: "40px",
    height: "40px",
    overflow: "hidden",
    img: {
      objectFit: "cover",
      overflow: "hidden",
      width: "100%",
    },
  },
  "& .title": {
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "0",
  },
}));
const LeftBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "30px",
  "& .farwordArrow": {
    cursor: "pointer",
    height: "24px",
    width: "24px",
    color: "#AFAFAF",
  },
  [theme.breakpoints.down("sm")]: { gap: "5px" },
}));

const CheckBoxMain = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: "160px",
  marginTop: "2rem",
  "& .checkbox-column": {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    marginLeft: "11px",
    marginRight: "0px !important",
  },
  [theme.breakpoints.down("md")]: {
    gap: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "10px",
  },
}));
const MainDatePickerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexShrink: 0,
  width: "100%",
  "& .DatePicker": {
    width: "100%",
    height: "48px",
    flexShrink: 0,
    borderRadius: "0.3125rem",
    border: "1px solid #1C1B1B",
    background: theme.palette.primary.light,
    color: theme.palette.secondary.light,
  },
  [theme.breakpoints.down("sm")]: {
    "& .DatePicker": {
      width: "100%",
    },
  },
}));

export {
  MainPreReleaseServices,
  PreReleaseServicesBox,
  ActiveServices,
  CheckBoxMain,
  MainServicesBox,
  RightBox,
  LeftBox,
  MainDatePickerBox,
};
