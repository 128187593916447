import React from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  MainTitle,
  PageTitle,
  CustomizationProfile,
} from "../../../components/smartlink";
import { useQuery } from "@apollo/client";
import { getUser } from "../../../components/Hooks/Authentication/User/query";

const Customization = () => {
  const { loading, error, data } = useQuery(getUser, {
    context: { clientName: "user" },
    fetchPolicy: "no-cache",
  });
  const UserData = data?.getUser?.user;
  console.log(
    "🚀 ~ file: Customization.js:18 ~ Customization ~ UserData:",
    UserData
  );

  return (
    <>
      <SmartLinkLayout>
        <MainTitle title="Smart Link" hasbutton={false} />
        <HorizontalMenu />
        <PageTitle title="Customization" hasNavigate={false} />
        {loading && (
          <p style={{ padding: 0, color: "#fff", margin: 0 }}>loading...</p>
        )}
        {!loading && <CustomizationProfile Data={UserData} />}
      </SmartLinkLayout>
    </>
  );
};

export default Customization;
