import styles from "./personal_profile.module.css";
import ProfileAboutComponent from "./profile_about_component";
import MonthlyListensChart from "./monthly_listens_chart";
import ProfileBestSongs from "./profile_best_songs";
import ArtistsWorkedWith from "./artists_worked_with_component";

import MusoCollaborators from "./muso_collaborators";
import MusoCredits from "./muso_credits";

import useAuth from "../../context/AuthContext";

import ProfileHeader from "./profile_header";
function PersonalProfileComponent() {
  // const accountData = account_data;
  const { artistData, musoArtistData } = useAuth();
  console.log(artistData);
  console.log(musoArtistData);

  // console.log()
  // function fetchArtist() {
  //   console.log("RUNNING");

  //   // getArtist("0TnOYISbd1XYRBk9myaseg")
  //   //   .then(() => {
  //   //     console.log(artistData);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.error("Error fetching artist:", error);
  //   //   });
  // }
  // getArtist("0TnOYISbd1XYRBk9myaseg");
  // console.log(artistData);
  return (
    <div className={styles.main_layout}>
      <div className={styles.black_half_opacity_bg}>
        <div className={styles.profile_page_elements}>
          {/* <div className={styles.profile_page_elements_top}>
   
            <div className={styles.about_artist_section}>
              <ProfileAboutComponent />
            </div>
            <MonthlyListensChart />
          </div> */}
          <ProfileHeader
            musoArtistData={musoArtistData}
            artistData={artistData}
          />
          <MusoCredits musoArtistData={musoArtistData} />
          <MusoCollaborators musoArtistData={musoArtistData} />
          <ProfileBestSongs artistData={artistData} />
        </div>
      </div>
    </div>
  );
}

export default PersonalProfileComponent;
