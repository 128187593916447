import React from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  MainTitle,
  PageTitle,
  SocialIntegrate,
} from "../../../components/smartlink";

const IntegratePage = () => {
  return (
    <>
      <SmartLinkLayout>
        <MainTitle title="Smart Link" hasbutton={false} islinkpage={true} />
        <HorizontalMenu />
        <PageTitle NavigateTitle="Integrate" hasNavigate={true} />
        <SocialIntegrate />
      </SmartLinkLayout>
    </>
  );
};

export default IntegratePage;
