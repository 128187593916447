import { gql } from "@apollo/client";

// get the all pricing plan

const GetAllPricingPlan = gql`
  query PlanListing($interval: interval!) {
    planListing(interval: $interval) {
      success
      message
      totalStorage
      usedStorage
      interval
      plans
    }
  }
`;

const CREATE_PAYMENT_INTENT = gql`
  mutation CreatePaymentIntent($amount: String!, $cardToken: String!) {
    createPaymentIntent(amount: $amount, cardToken: $cardToken) {
      success
      message
      clientSecret
    }
  }
`;

// End get the all pricing plan

const PURCHASE_SUBSCRIPTION_MUTATION = gql`
  mutation PurchaseSubscription($planId: String!, $paymentIntentId: String!) {
    purchaseSubscription(planId: $planId, paymentIntentId: $paymentIntentId) {
      success
      message
      amountPaid
      date
      discount
    }
  }
`;

// const PURCHASE_SUBSCRIPTION_MUTATION = gql`
//   mutation PurchaseSubscription(
//     $planId: String!
//     $amount: String!
//     $cardToken: String!
//   ) {
//     purchaseSubscription(
//       planId: $planId
//       amount: $amount
//       cardToken: $cardToken
//     ) {
//       success
//       message
//       amountPaid
//       date
//       discount
//     }
//   }
// `;

// create smart link

const CREATE_SMART_LINK = gql`
  mutation CreateSmartLink(
    $artist: String!
    $url: String
    $code: String!
    $tags: String!
    $status: statustype
    $isScanning: Boolean
    $image: String
    $releaseDate: String
    $mediaType: MediaType
    $subMediaType: SubMediaType
    $title: String!
    $description: String
    $baseUrl: String
    $fallbackUrl: String
    $upc: String
    $isrc: String
  ) {
    createSmartLink(
      artist: $artist
      url: $url
      code: $code
      tags: $tags
      status: $status
      isScanning: $isScanning
      image: $image
      releaseDate: $releaseDate
      mediaType: $mediaType
      subMediaType: $subMediaType
      title: $title
      description: $description
      baseUrl: $baseUrl
      fallbackUrl: $fallbackUrl
      upc: $upc
      isrc: $isrc
    ) {
      message
      success
      error
      smartlink {
        artist
        url
        code
        tags
        boardId
        created
        updated
        status
        isScanning
        images
        locales
        externalId
        releaseDate
        mediaType
        subMediaType
        title
        description
        baseUrl
        fallbackUrl
        upc
        isrc
      }
    }
  }
`;

const GET_SMART_LINK_BY_ID = gql`
  query GetSmartLinkById($id: String!) {
    getSmartLinkById(id: $id) {
      success
      message
      error
      smartlink {
        id
        artists
        url
        code
        tags
        boardId
        created
        updated
        status
        isScanning
        images
        locales
        externalId
        releaseDate
        mediaType
        subMediaType
        title
        description
        baseUrl
        fallbackUrl
        upc
        isrc
      }
    }
  }
`;

// Update Smart Link

const UPDATE_SMART_LINK = gql`
  mutation UpdateSmartLink(
    $artist: String!
    $code: String!
    $tags: String!
    $title: String!
    $image: String!
    $linkId: String!
    $baseUrl: String
    $fallbackUrl: String
    $mediaType: MediaType!
    $status: statustype!
  ) {
    updateSmartLink(
      artist: $artist
      code: $code
      tags: $tags
      title: $title
      image: $image
      linkId: $linkId
      fallbackUrl: $fallbackUrl
      baseUrl: $baseUrl
      mediaType: $mediaType
      status: $status
    ) {
      success
      message
      error
      smartlink {
        id
        artist
        url
        code
        tags
        boardId
        created
        updated
        status
        isScanning
        images
        locales
        externalId
        releaseDate
        mediaType
        subMediaType
        title
        description
        baseUrl
        fallbackUrl
        upc
        isrc
      }
    }
  }
`;

// Update Pre-Release Smart Link

const UPDATE_PRERELEASE_SMART_LINK = gql`
  mutation Mutation(
    $artist: String!
    $code: String!
    $tags: String!
    $linkId: String
    $status: statustype
    $url: String
    $isScanning: Boolean
    $image: String
    $mediaType: MediaType
    $releaseDate: String
    $subMediaType: SubMediaType
    $description: String
    $title: String
    $baseUrl: String
    $fallbackUrl: String
    $upc: String
    $isrc: String
    $locales: JSON
    $preReleaseServices: [String]
  ) {
    updatePreSmartLink(
      artist: $artist
      code: $code
      tags: $tags
      linkId: $linkId
      status: $status
      url: $url
      isScanning: $isScanning
      image: $image
      mediaType: $mediaType
      releaseDate: $releaseDate
      subMediaType: $subMediaType
      description: $description
      title: $title
      baseUrl: $baseUrl
      fallbackUrl: $fallbackUrl
      upc: $upc
      isrc: $isrc
      locales: $locales
      preReleaseServices: $preReleaseServices
    ) {
      success
      message
      error
      smartlink {
        id
        artist
        url
        code
        tags
        boardId
        created
        updated
        status
        isScanning
        images
        locales
        externalId
        releaseDate
        mediaType
        subMediaType
        title
        description
        baseUrl
        fallbackUrl
        upc
        isrc
      }
    }
  }
`;

const CREATE_CONTENT_SMART_LINK = gql`
  mutation CreateContentSmartLink(
    $code: String!
    $artist: String!
    $tags: String!
    $image: String!
    $title: String!
    $upc: String
    $baseUrl: String
    $isrc: String
  ) {
    createContentSmartLink(
      code: $code
      artist: $artist
      tags: $tags
      image: $image
      title: $title
      upc: $upc
      baseUrl: $baseUrl
      isrc: $isrc
    ) {
      success
      message
      error
      smartlink {
        id
        artist
        url
        code
        tags
        boardId
        created
        updated
        status
        isScanning
        images
        locales
        externalId
        releaseDate
        mediaType
        subMediaType
        title
        description
        baseUrl
        fallbackUrl
        upc
        isrc
      }
    }
  }
`;

// delete smart-link

const DELETE_SMART_LINK = gql`
  mutation DeleteSmartLink($linkId: String!) {
    deleteSmartLink(linkId: $linkId) {
      success
      message
    }
  }
`;

// create the pre-SMART_LINK

const CREATE_PRE_SMART_LINK = gql`
  mutation CreatePreSmartLink(
    $code: String!
    $artist: String!
    $tags: String!
    $status: statustype
    $url: String
    $isScanning: Boolean
    $image: String
    $releaseDate: String
    $mediaType: MediaType
    $subMediaType: SubMediaType
    $title: String
    $description: String
    $baseUrl: String
    $fallbackUrl: String
    $upc: String
    $isrc: String
    $preReleaseServices: [String]
  ) {
    createPreSmartLink(
      code: $code
      artist: $artist
      tags: $tags
      status: $status
      url: $url
      isScanning: $isScanning
      image: $image
      releaseDate: $releaseDate
      mediaType: $mediaType
      subMediaType: $subMediaType
      title: $title
      description: $description
      baseUrl: $baseUrl
      fallbackUrl: $fallbackUrl
      upc: $upc
      isrc: $isrc
      preReleaseServices: $preReleaseServices
    ) {
      success
      message
      error
      smartlink {
        artist
        baseUrl
        id
        url
        code
        tags
        boardId
        created
        updated
        status
        isScanning
        images
        locales
        externalId
        mediaType
        releaseDate
        subMediaType
        title
        description
        fallbackUrl
        upc
        isrc
      }
    }
  }
`;

// get the data from url and UPC ISRC

const EXTRACT_DATA_FROM_URLS = gql`
  query DataExtractionFromUrl($url: String!) {
    dataExtractionFromUrl(data: $url) {
      success
      message
      data
    }
  }
`;

// ==== get the all smartlink ======

const GET_ALL_SMART_LINKS = gql`
  query GetUserSmartLinks {
    getUserSmartLinks {
      success
      message
      totalSmartlinks
      smartlinks {
        id
        artists
        url
        code
        tags
        boardId
        created
        updated
        status
        isScanning
        images
        locales
        externalId
        releaseDate
        mediaType
        subMediaType
        title
        description
        baseUrl
        fallbackUrl
        upc
        isrc
      }
    }
  }
`;

const GET_ALL_ACTIVE_INBOX = gql`
  query GetActiveBeatBoxes($page: Int!, $perPage: Int!) {
    getActiveBeatBoxes(page: $page, perPage: $perPage) {
      totalPages
      success
      message
      beatBoxes {
        _id
        userId {
          _id
          firstname
          lastname
          profilePic
        }
        title
        totalBeats
        description
        image
        isDeleted
        createdAt
        updatedAt
        isPublic
        beats {
          _id
        }
        beatboxShareLink
        selectedInbox
      }
    }
  }
`;

const BEAT_INBOX_SETTING = gql`
  mutation beatboxSetting(
    $beatboxSettingId: ID!
    $selectedInbox: String
    $thumbnailStatus: Boolean
    $beatboxStatus: Boolean
    $autofitMessageStatus: Boolean
  ) {
    beatboxSetting(
      id: $beatboxSettingId
      selectedInbox: $selectedInbox
      thumbnailStatus: $thumbnailStatus
      beatboxStatus: $beatboxStatus
      autofitMessageStatus: $autofitMessageStatus
    ) {
      message
      success
    }
  }
`;

const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateSmartLinkProfile(
    $firstname: String
    $lastname: String
    $userName: String
    $profilePic: String
    $coverPic: String
    $state: String
    $phoneno: String
    $email: String
    $country: String
    $postalCode: String
    $taxId: String
    $domainName: String
    $websiteUrl: String
    $backgroundColor: String
  ) {
    updateSmartLinkProfile(
      updateSmartLinkProfileBody: {
        firstname: $firstname
        lastname: $lastname
        userName: $userName
        profilePic: $profilePic
        coverPic: $coverPic
        state: $state
        phoneno: $phoneno
        email: $email
        country: $country
        postalCode: $postalCode
        taxId: $taxId
        domainName: $domainName
        websiteUrl: $websiteUrl
        backgroundColor: $backgroundColor
      }
    ) {
      success
      message
      updatedUser {
        id
        firstname
        lastname
        userName
        state
        phoneno
        email
        country
        postalCode
        taxId
        domainName
        websiteUrl
        backgroundColor
      }
    }
  }
`;

// get the producer price plan
const GET_PRODUCER_PLAN = gql`
  query GetProducerPlan {
    getProducerPlan {
      success
      message
      plans
    }
  }
`;

export {
  GetAllPricingPlan,
  PURCHASE_SUBSCRIPTION_MUTATION,
  CREATE_SMART_LINK,
  EXTRACT_DATA_FROM_URLS,
  CREATE_PAYMENT_INTENT,
  CREATE_PRE_SMART_LINK,
  UPDATE_SMART_LINK,
  GET_SMART_LINK_BY_ID,
  DELETE_SMART_LINK,
  CREATE_CONTENT_SMART_LINK,
  GET_ALL_SMART_LINKS,
  UPDATE_PRERELEASE_SMART_LINK,
  GET_ALL_ACTIVE_INBOX,
  BEAT_INBOX_SETTING,
  UPDATE_PROFILE_MUTATION,
  GET_PRODUCER_PLAN,
};
