import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Authentication/Layout";
import App from "./App";
// import SignIn from "./components/Authentication/SignIn/SignIn";
import SignInPage from "./pages/AuthenticationPages/SignInPage";
import SignUp from "./components/Authentication/SignUp/SignUp";
import PreSignUp from "./components/Authentication/SignUp/PreSignUp";
import SignInNew from "./components/AuthNewFrontend/auth_page";
import Help from "./pages/Help/Help";
import ProtectedRoutes from "./routes/protectedRoutes";
import NotFound from "./pages/NotFound";
import Distribution from "./LandingPage/Distribution/Distribution";
// old landing page
// import LandingPage from "./LandingPage/LandingPage";
import MyPayment from "./pages/User/Payment";
import CreateMySoundPack from "./pages/SoundPacks/CreateSoundPack";
import Tutorial from "./pages/Tutorial";
import SoundKit from "./pages/SoundPacks/MySoundKit";
import MySoundPack from "./pages/SoundPacks/SoundPacks";
import OpportunitySubmissionsPage from "./pages/SubmissionPages/OpportunitySubmissionsPage";
import SyncSubmissionsPage from "./pages/SubmissionPages/SyncSubmissionsPage";
import ConnectSubmissions from "./pages/SubmissionPages/ConnectSubmissionsPage";
import BeatInboxSubmissions from "./pages/SubmissionPages/BeatInboxSubmissionsPage";
import ViewSyncSubmissions from "./pages/view_submission_page/SyncSubmissionPage";
import Connect from "./pages/ConnectPage/Connect";
import Chat from "./pages/ChatPage/Chat_Page";
import Dashboard from "./pages/DashboardPage/DashboardPage";
import HomePage from "./pages/DashboardPage/HomePage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import SettingsPage from "./pages/SettingsPage/Settings_Page";
import OpportunitiesPage from "./pages/OpportunitiesPages/OpportunitiesPage";
import MyAudioLibraryPage from "./pages/MyAudioLibraryPages/MyAudioLibraryPage";
import SyncPage from "./pages/SyncPage/SyncPage";
import CreateSyncPage from "./pages/SyncPage/CreateSyngPage";
import SubmitSyncPage from "./pages/SyncPage/SubmitSyncPage";
import OpportunityProvider from "./context/OpportunityContext";
import { LibraryProvider } from "./context/LibraryContext";
import CreateEditOpportunityPage from "./pages/OpportunitiesPages/CreateOpportunityPage";
import ConnectMeSubmissionPage from "./components/ConnectComponents/submissions";
import SubmitOpportunityPage from "./pages/OpportunitiesPages/SubmitOpportunityPage";
// import Submissions from "./components/OpportunitiesComponents/PageComponents/Submission";
import ViewSubmissions from "./pages/view_submission_page/ViewSubmission_Page";
import ViewSubmissionDetailsPage from "./pages/View_Submission_Details_page/ViewSubmissionDetails_Page";
import AdminPage from "./pages/AdminConsolePages/AdminPage";
import DistributionPage from "./pages/DistributionPage/Distribution_Page";
import SongwriterPage from "./pages/SongwriterPage/SongWriterPage";
import CreateSongPage from "./pages/SongwriterPage/CreateSongPage";
import useAuth from "./context/AuthContext";
import AcceptCollaborations from "./pages/Collaborations";
//new landing page
import Landingpage from "./LandingPage/updatedlandingpage/Landingpage";
import LandingPage from "./LandingPage/LandingPage";
import OpportunitySubmissionsComponent from "./components/SubmissionsComponents/AudioLibararySubmission/OpportunitySubmissionsComponent";
import SyncSubmissionsComponent from "./components/SubmissionsComponents/AudioLibararySubmission/SyncSubmissionComponent";
// ==========  Smart Link Routes ========
import {
  SmartLinkDashboard,
  CreateLink,
  Report,
  Integrate,
  InboxSetting,
  Customization,
  BoardInsight,
  PreReleasePage,
  BeatinboxIndex,
  BeatDeletePage,
  StarredBeatsPage,
  BeatStoragePage,
  CreatedLinksPage,
  SingleBeatsPage,
  LinkInsightsPage,
  InsightSourcesPage,
  InsightCommissionPage,
  InsightSalesPage,
  InsightDevicesPage,
  InsightLocationsPage,
  BioPage,
  ReleasePage,
  ProducersSubmitBeatPage,
  SingleSharedInbox,
  ProducersWelcomePage,
  ProducersForgotePassPage,
  ProducersSignUpPage,
  ProducersLoginPage,
  ProducersResetPassPage,
  ProducersUserTypePage,
  ProducerOTPPage,
  NotificationPage,
} from "./pages/smartlink";
// ==========  End Smart Link Routes ========
import DirectChat from "./pages/ChatPage/Direct_Chat_Page";
import SuccessPage from "./components-old/Success/Success";
import ErrorPage from "./components-old/Error/Error";
import CheckoutPage from "./pages/CheckoutSummary/Checkout_page";
import SmartLinkAllowedRoutes from "./routes/SmartLinkAllowedRoutes";
import Forbidden from "./pages/Forbidden";
import EditReleasePage from "./pages/smartlink/release/EditReleasePage";
import PrivacyPolicyPage from "./pages/privacypolicies/PrivacyPolicyPage";
import TermAndConditions from "./pages/privacypolicies/TermAndConditions";
import UserLicence from "./pages/privacypolicies/UserLicence";
import AuthPages from "./components/AuthNewFrontend/auth_page";
import PublicRoutes from "./routes/PublicRoutes";
import MarketingAiPage from "./pages/Marketing_Ai";

import MyContracts from "./pages/MyContractsPages/MyContractsPage";
import SendContracts from "./pages/MyContractsPages/SendContractPage";
import CreateTemplates from "./pages/MyContractsPages/CreateTemplatePage";
import SendTemplatePage from "./pages/MyContractsPages/SendTemplatePage";
import TemplatePage from "./pages/MyContractsPages/TemplatePage";
import SentPage from "./pages/MyContractsPages/SentPage";
import TrashPage from "./pages/MyContractsPages/TrashPage";
import LicensePage from "./pages/MyContractsPages/LicensePage";
import DraftsPage from "./pages/MyContractsPages/DraftsPage";
import EditTemplatesPage from "./pages/MyContractsPages/EditTemplatePage";

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/landing" replace />;
  }
  return children;
};
const UserRole = () => {
  const { user } = useAuth();
  const [userRoleName, setUserRoleName] = useState(null);
  useEffect(() => {
    if (user) {
      const fetchUserRoleName = async () => {
        const roleName = user.roleId.roleName;
        setUserRoleName(roleName);
      };
      fetchUserRoleName();
    }
  }, [user]);

  return userRoleName;
};
function routes() {
  let isAdmin = UserRole() === "Admin" ? true : false;
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App childComp={<LandingPage />} />} />
          <Route
            path="/signup"
            element={
              <App
                childComp={
                  <PublicRoutes>
                    <AuthPages page="signup" isApproved="false" />
                  </PublicRoutes>
                }
              />
            }
          />
          <Route
            path="/approved/signup"
            element={
              <App
                childComp={
                  <PublicRoutes>
                    <AuthPages page="signup" isApproved="true" />
                  </PublicRoutes>
                }
              />
            }
          />
          <Route
            path="/signin"
            element={
              <App
                childComp={
                  <PublicRoutes>
                    <AuthPages page="signin" isApproved="false" />
                  </PublicRoutes>
                }
              />
            }
          />
          <Route
            path="/collaborations/accept/:token"
            element={<App childComp={<AcceptCollaborations />} />}
          />
          <Route
            path="/success"
            element={<App childComp={<SuccessPage />} />}
          />
          <Route path="/error" element={<App childComp={<ErrorPage />} />} />
          <Route
            path="/checkout-summary"
            element={
              <App childComp={<Layout childComp={<CheckoutPage />} />} />
            }
          />
          <Route
            path="/collaborations/accept/:token"
            element={<App childComp={<AcceptCollaborations />} />}
          />
          <Route
            path="/success"
            element={<App childComp={<SuccessPage />} />}
          />
          <Route path="/error" element={<App childComp={<ErrorPage />} />} />
          <Route
            path="/checkout-summary"
            element={
              <App childComp={<Layout childComp={<CheckoutPage />} />} />
            }
          />
          {/* <Route
            path="/approved/signup"
            element={<App childComp={<Layout childComp={<SignUp />} />} />}
          /> */}
          <Route
            path="/referral/signup/:id"
            element={<App childComp={<Layout childComp={<SignUp />} />} />}
          />
          {/* <Route
            path="/signup"
            element={<App childComp={<Layout childComp={<SignUp />} />} />}
          /> */}
          <Route
            path="/pre-signup"
            element={<App childComp={<Layout childComp={<PreSignUp />} />} />}
          />
          <Route
            path="/approved/signup/:isSignup"
            element={<App childComp={<Layout childComp={<SignUp />} />} />}
          />
          <Route
            path="/pre-signup/:isSignup"
            element={<App childComp={<Layout childComp={<PreSignUp />} />} />}
          />
          {/* <Route
            path="/signin"
            element={<App childComp={<Layout childComp={<SignInPage />} />} />}
          /> */}
          {/* <Route path="/home" element={<App childComp={<LandingPage />} />} /> */}
          {/* <Route
            path="/publishing"
            element={<App childComp={<Publishing />} />}
          /> */}
          <Route
            path="/distribution"
            element={<App childComp={<Distribution />} />}
          />
          <Route
            path="/user/payment"
            element={<App childComp={<MyPayment />} />}
          />
          <Route path="/sound-kit" element={<App childComp={<SoundKit />} />} />
          <Route
            path="/soundPack"
            element={<App childComp={<MySoundPack />} />}
          />
          <Route
            path="/soundPack/create"
            element={<App childComp={<CreateMySoundPack />} />}
          />
          <Route
            path="/soundPack/edit/:id?"
            element={<App childComp={<CreateMySoundPack />} />}
          />
          <Route exact element={<ProtectedRoutes />}>
            <Route
              path="/dashboard"
              element={<App childComp={<Dashboard />} />}
            />
            <Route path="/home" element={<App childComp={<HomePage />} />} />
            <Route
              path="/opportunities"
              element={
                <OpportunityProvider>
                  <App childComp={<OpportunitiesPage />} />
                </OpportunityProvider>
              }
            />
            <Route
              path="/myaudiolibrary"
              element={
                <LibraryProvider>
                  <App childComp={<MyAudioLibraryPage currentSubBar={0} />} />
                </LibraryProvider>
              }
            />

            <Route
              path="/myaudiolibrary/beat_pack"
              element={
                <LibraryProvider>
                  <App childComp={<MyAudioLibraryPage currentSubBar={1} />} />
                </LibraryProvider>
              }
            />

            <Route
              path="/myaudiolibrary/inbox"
              element={
                <LibraryProvider>
                  <App childComp={<MyAudioLibraryPage currentSubBar={2} />} />
                </LibraryProvider>
              }
            />

            <Route
              path="/myaudiolibrary/starred"
              element={
                <LibraryProvider>
                  <App childComp={<MyAudioLibraryPage currentSubBar={3} />} />
                </LibraryProvider>
              }
            />

            <Route
              path="/myaudiolibrary/trash"
              element={
                <LibraryProvider>
                  <App childComp={<MyAudioLibraryPage currentSubBar={4} />} />
                </LibraryProvider>
              }
            />

            <Route
              path="/myaudiolibrary/sent"
              element={
                <LibraryProvider>
                  <App childComp={<MyAudioLibraryPage currentSubBar={5} />} />
                </LibraryProvider>
              }
            />

            <Route
              path="/myaudiolibrary/sent/sync"
              element={
                <LibraryProvider>
                  <App childComp={<MyAudioLibraryPage currentSubBar={6} />} />
                </LibraryProvider>
              }
            />

            <Route
              path="/myaudiolibrary/beat_pack/:id"
              element={
                <LibraryProvider>
                  <App childComp={<MyAudioLibraryPage currentSubBar={7} />} />
                </LibraryProvider>
              }
            />

            <Route
              path="/myaudiolibrary/beat_pack/:id1/:id2"
              element={
                <LibraryProvider>
                  <App childComp={<MyAudioLibraryPage currentSubBar={8} />} />
                </LibraryProvider>
              }
            />
            <Route
              path="/sync"
              element={
                <OpportunityProvider>
                  <App childComp={<SyncPage />} />
                </OpportunityProvider>
              }
            />
            <Route
              path="/sync/apply/:id"
              element={<App childComp={<SubmitSyncPage />} />}
            />
            <Route
              path="/opportunities/apply/:id"
              element={<App childComp={<SubmitOpportunityPage />} />}
            />
            {/* <Route
              path="/sync/apply/:id"
              element={<App childComp={<Submissions />} />}
            /> */}
            <Route
              path="/connect/apply/:id"
              element={<App childComp={<ConnectMeSubmissionPage />} />}
            />
            <Route
              path="/opportunities/create"
              element={<App childComp={<CreateEditOpportunityPage />} />}
            />
            <Route
              path="/sync/create"
              element={<App childComp={<CreateSyncPage />} />}
            />
            <Route
              path="/opportunities/edit/:id"
              element={
                <App childComp={<CreateEditOpportunityPage isEdit={true} />} />
              }
            />
            <Route
              path="/sync/edit/:id"
              element={<App childComp={<CreateSyncPage isEdit={true} />} />}
            />
            <Route
              path="/opportunities/admin/edit/:id"
              element={
                <App childComp={<CreateEditOpportunityPage isEdit={true} />} />
              }
            />
            <Route
              path="/opportunities/viewsubmission/:id"
              element={<App childComp={<ViewSubmissions />} />}
            />
            <Route
              path="/sync/viewsubmission/:id"
              element={<App childComp={<ViewSyncSubmissions />} />}
            />
            <Route
              path="/opportunities/viewsubmissiondetails/:id"
              element={<App childComp={<ViewSubmissionDetailsPage />} />}
            />
            <Route
              path="/profile/:id"
              element={<App childComp={<ProfilePage />} />}
            />
            {/* <Route
              path="/terms-and-conditions"
              element={<App childComp={<BarTermsCondition />} />}
            /> */}
            <Route path="/help" element={<App childComp={<Help />} />} />
            {/* <Route
              path="/settings"
              element={<App childComp={<Settings_Page />} />}
            /> */}
            {/* <Route
              path="/freemium"
              element={<App childComp={<Freemium />} />}
            /> */}
            <Route
              path="/tutorial"
              element={<App childComp={<Tutorial />} />}
            />
            {/* <Route
              path="/opporunity"
              element={<App childComp={<BarOpportunities />} />}
            />
            <Route
              path="/marketplace"
              element={<App childComp={<BarMarketPlace />} />}
            /> */}
            {/* <Route
              path="/book/collab"
              element={<App childComp={<BookACollab />} />}
            /> */}
            <Route path="/connect" element={<App childComp={<Connect />} />} />
            <Route path="/chat" element={<App childComp={<Chat />} />} />
            <Route
              path="/chat/:id"
              element={<App childComp={<DirectChat />} />}
            />
            <Route
              path="/settings"
              element={<App childComp={<SettingsPage />} />}
            />
            <Route
              path="/my-submissions"
              element={<App childComp={<OpportunitySubmissionsPage />} />}
            />
            <Route
              path="/my-submissions/sync"
              element={<App childComp={<SyncSubmissionsPage />} />}
            />
            <Route
              path="/my-submissions/connect"
              element={<App childComp={<ConnectSubmissions />} />}
            />
            <Route
              path="/my-submissions/beat-inbox"
              element={<App childComp={<BeatInboxSubmissions />} />}
            />

            {/* generating page route */}
            <Route
              path="/ai_marketing"
              element={<App childComp={<MarketingAiPage />} />}
            />

            <Route
              path="/distribute"
              element={<App childComp={<DistributionPage />} />}
            />
            <Route
              path="/songwriter"
              element={<App childComp={<SongwriterPage />} />}
            />
            <Route
              path="/songwriter/createsong"
              element={<App childComp={<CreateSongPage />} />}
            />
            <Route
              path="/adminpanel/:tab?"
              element={
                isAdmin ? (
                  <App childComp={<AdminPage />} />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            {/* <Route
              path="/submissions"
              element={<App childComp={<Submissions />} />}
            /> */}
            {/* ======== smart link  ========*/}
            {/* <Route exact element={<SmartLinkAllowedRoutes />}> */}
            <Route
              path="/smart-link/dashboard"
              element={<App childComp={<SmartLinkDashboard />} />}
            />
            <Route
              path="/smart-link/created-link"
              element={<App childComp={<CreatedLinksPage />} />}
            />
            <Route
              path="/smart-link/createlink"
              element={<App childComp={<CreateLink />} />}
            />
            <Route
              path="/smart-link/integrate"
              element={<App childComp={<Integrate />} />}
            />
            <Route
              path="/smart-link/inbox-setting"
              element={<App childComp={<InboxSetting />} />}
            />
            <Route
              path="/smart-link/customization"
              element={<App childComp={<Customization />} />}
            />
            <Route
              path="/smart-link/board-insight"
              element={<App childComp={<BoardInsight />} />}
            />
            <Route
              path="/smart-link/report"
              element={<App childComp={<Report />} />}
            />
            <Route
              path="/smart-link/pre-release-create"
              element={<App childComp={<PreReleasePage />} />}
            />
            <Route
              path="/smart-link/pre-release-services"
              element={<App childComp={<PreReleasePage />} />}
            />
            {/* <Route
              path="/smart-link/bio-create"
              element={<App childComp={<BioPage />} />}
            /> */}
            <Route
              path="/smart-link/release"
              element={<App childComp={<ReleasePage />} />}
            />
            <Route
              path="/smart-link/edit/:id"
              element={<App childComp={<EditReleasePage />} />}
            />
            <Route
              path="/smart-link/insights"
              element={<App childComp={<LinkInsightsPage />} />}
            />
            <Route
              path="/smart-link/insights-sources"
              element={<App childComp={<InsightSourcesPage />} />}
            />
            <Route
              path="/smart-link/insights-commission"
              element={<App childComp={<InsightCommissionPage />} />}
            />
            <Route
              path="/smart-link/insights-sales"
              element={<App childComp={<InsightSalesPage />} />}
            />
            <Route
              path="/smart-link/insights-devices"
              element={<App childComp={<InsightDevicesPage />} />}
            />
            <Route
              path="/smart-link/insights-location"
              element={<App childComp={<InsightLocationsPage />} />}
            />

            {/* My Contract */}
            <Route
              path="/my-contracts"
              element={<App childComp={<MyContracts />} />}
            />
            <Route
              path="/my-contracts/send-contract"
              element={<App childComp={<SendContracts />} />}
            />
            <Route
              path="/my-contracts/templates"
              element={<App childComp={<TemplatePage />} />}
            />
            <Route
              path="/my-contracts/trash"
              element={<App childComp={<TrashPage />} />}
            />
            <Route
              path="/my-contracts/license"
              element={<App childComp={<LicensePage />} />}
            />
            <Route
              path="/my-contracts/sent"
              element={<App childComp={<SentPage />} />}
            />
            <Route
              path="/my-contracts/drafts"
              element={<App childComp={<DraftsPage />} />}
            />
            <Route
              path="/my-contracts/templates/create"
              element={<App childComp={<CreateTemplates />} />}
            />
            <Route
              path="/my-contracts/templates/send/:id"
              element={<App childComp={<SendTemplatePage />} />}
            />
            <Route
              path="/my-contracts/templates/edit/:id"
              element={<App childComp={<EditTemplatesPage />} />}
            />
            <Route
              path="/my-contracts/templates"
              element={<App childComp={<ConnectSubmissions />} />}
            />

            {/* beat inbox */}
            <Route
              path="/beat-inbox"
              element={<App childComp={<BeatinboxIndex />} />}
            />
            <Route
              path="/inbox-beats/:name/unitedmarket/:id"
              element={<App childComp={<SingleBeatsPage />} />}
            />
            <Route
              path="/beat-inbox/deleted"
              element={<App childComp={<BeatDeletePage />} />}
            />
            <Route
              path="/beat-inbox/starred-beats"
              element={<App childComp={<StarredBeatsPage />} />}
            />
            <Route
              path="/beat-inbox/more-storage"
              element={<App childComp={<BeatStoragePage />} />}
            />
            <Route
              path="/beat-inbox/my-submissions"
              element={<App childComp={<OpportunitySubmissionsComponent />} />}
            />
            <Route
              path="/beat-inbox/my-submissions/sync"
              element={<App childComp={<SyncSubmissionsComponent />} />}
            />

            {/* </Route> */}
            {/* ======== smart link  ========*/}
            <Route
              path="/notification"
              element={<App childComp={<NotificationPage />} />}
            />
          </Route>
          {/* ======== Producer  pages ========*/}
          <Route
            path="/producer/auth/welcome"
            element={<ProducersWelcomePage />}
          />
          <Route
            path="/producer/auth/user-type"
            element={<ProducersUserTypePage />}
          />
          <Route
            path="/producer/auth/forgote-password"
            element={<ProducersForgotePassPage />}
          />
          <Route
            path="/producer/auth/reset-password/:code"
            element={<ProducersResetPassPage />}
          />
          <Route
            path="/producer/auth/otp/:email"
            element={<ProducerOTPPage />}
          />
          <Route path="/producer/auth/login" element={<ProducersLoginPage />} />
          <Route
            path="/producer/auth/signup/:id/:type"
            element={<ProducersSignUpPage />}
          />
          <Route
            path="/shared-beat-create/:id"
            element={<ProducersSubmitBeatPage />}
          />
          {/* <Route
            path="/shared-inbox/unitedmarket/:id"
            element={<SingleSharedInbox />}
          /> */}
          <Route
            path="/:something/unitedmarket/:id"
            element={<SingleSharedInbox />}
          />

          {/* <Route path="/ai_marketing" element={<App childComp={<MarketingAiPage />} />} /> */}

          {/* ============  End Producer  pages ==============*/}
          <Route
            path="/privacy-policy"
            element={<App childComp={<PrivacyPolicyPage />} />}
          />
          <Route
            path="/term-condition"
            element={<App childComp={<TermAndConditions />} />}
          />
          <Route
            path="/user-Licence"
            element={<App childComp={<UserLicence />} />}
          />
          <Route path="*" element={<NotFound />} />
          <Route path="/403" element={<Forbidden />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default routes;
