import React from "react";
import styles from "./dashboard.module.css";

function NewArtistsComponent() {
  return (
    <div className={styles.new_artists_component}>
      <div className={styles.container}>
        <div className={`${styles.column} ${styles.column1}`}>
          <div className={`${styles.rectangle} ${styles.rectangle1}`}>
            <img
              className={styles.new_artist_image}
              src="/v2Assets/profile_picture_assets/imageTwo.png"
              alt="artist"
            ></img>
            <p className={styles.new_artist_name}>YUNG CUTTA</p>
          </div>
          <div className={`${styles.rectangle} ${styles.rectangle2}`}>
            {" "}
            <img
              className={styles.new_artist_image}
              src="/v2Assets/profile_picture_assets/imageTwo.png"
              alt="artist"
            ></img>
            <p className={styles.new_artist_name}>YUNG CUTTA</p>
          </div>
        </div>
        <div className={`${styles.column} ${styles.column2}`}>
          <div className={`${styles.rectangle} ${styles.rectangle3}`}>
            {" "}
            <img
              className={styles.new_artist_image}
              src="/v2Assets/profile_picture_assets/imageTwo.png"
              alt="artist"
            ></img>
            <p className={styles.new_artist_name}>YUNG CUTTA</p>
          </div>
          <div className={`${styles.rectangle} ${styles.rectangle4}`}>
            {" "}
            <img
              className={styles.new_artist_image}
              src="/v2Assets/profile_picture_assets/imageTwo.png"
              alt="artist"
            ></img>
            <p className={styles.new_artist_name}>YUNG CUTTA</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewArtistsComponent;
