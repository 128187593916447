import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const MainInboxCard = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: "20px",
}));

const InboxCard = styled(Link)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "17.5rem",
  background: theme.palette.primary.light,
  borderRadius: "0.3125rem",
  flexShrink: 0,
  transition: "background 0.3s",
  textDecoration: "none",
  gap: "15px",
  "&:hover": {
    background: "rgba(46, 187, 85, 0.10)",
    textDecoration: "none",
  },
  "& h4": {
    color: theme.palette.secondary.light,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    cursor: "pointer",
    margin: "0px",
    marginTop: "10px",
  },
}));

export { MainInboxCard, InboxCard };
