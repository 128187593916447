import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function Notify(type, message) {
  //   const { type, message } = props;
  switch (type) {
    case "info":
      return toast.info(message, {
        position: "top-center",
        className: "SubmittToast",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      });

    case "success":
      return toast.success(message, {
        position: "top-center",
        className: "SubmittToast",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      });

    case "warning":
      return toast.warning(message, {
        position: "top-center",
        className: "SubmittToast",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      });
    // break;
    case "error":
      return toast.error(message, {
        position: "top-center",
        className: "SubmittToast",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      });
    // break;
    default:
      return false;
  }
}
