import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import styles from "./submit_content.module.css";

import PageNavigationControls from "../../UniversalComponents/page_navigation_controls";
import useAppState from "../../../context/useAppState";

import ConnectSubmissionCard from "../../SubmissionsComponents/ConnectSubmissions/connectSubmissionCard";
import defaultCoverImage from "../../../assets/Images/defaultCoverImage.png";

const ViewSubmissionDetails = () => {
  const navigate = useNavigate();

  const { data: opportunityData, fetchOpportunityById } =
    useAppState("opportunity");
  const { data, fetchSubmissionDetailsById } = useAppState("submission");
  const { id } = useParams();
  const location = useLocation();
  const typeParam = new URLSearchParams(location.search).get("type");

  useEffect(() => {
    if (id) {
      fetchSubmissionDetailsById(id);
    }
  }, [id]);

  useEffect(() => {
    if (data?.opportunitiesId) {
      fetchOpportunityById(data?.opportunitiesId);
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.top_info}>
        <PageNavigationControls onBack={() => navigate(-1)} />
        <p className={styles.page_title}>Apply to {opportunityData?.title}</p>
      </div>
      <div>
        {data && opportunityData && (
          <ConnectSubmissionCard
            isShowOpportunityName={true}
            isShowBeatDetail={true}
            isShowView={false}
            isShowFavorite={true}
            isShowFlag={true}
            isShowStatus={true}
            showDownloadbtn={true}
            isShowCollaborators={false}
            data={data}
            commingFrom={typeParam === "my_submissions" ? "my_submissions" : ""}
          />
        )}

        <div className={styles.contentWrapper}>
          <div className={styles.collaborators_wrapper}>
            <p className={styles.collaborators_title}>Collaborators</p>
            {data?.collaborators && (
              <div className={styles.collaborator_div}>
                {data?.collaborators.length > 0 ? (
                  data?.collaborators.map((collaborator) => {
                    return (
                      <div className={styles.collaborator_info}>
                        <div className={styles.collaborator_info_inner}>
                          <img
                            className={styles.collaborator_image}
                            src={defaultCoverImage}
                            alt="collaborator"
                          />
                          <p className={styles.collaborator_name}>
                            {collaborator.name}
                          </p>
                        </div>
                        <p className={styles.collaborator_percentage}>
                          {collaborator.publishingPercentage}%
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p className={styles.collaborator_name}>
                    There are no collaborators added yet
                  </p>
                )}
              </div>
            )}
          </div>

          {/* <div className={styles.vertical_line_divider}></div> */}

          <div className={styles.beat_price_section}>
            <div className={styles.beat_price_wrapper}>
              <p className={styles.beat_title}>Beat Price</p>
              {data?.exclusivePrice && (
                <p className={styles.beat_price_title}>
                  ${data?.exclusivePrice}
                </p>
              )}
            </div>
            {/* <div className={styles.purchase_beat_button}>Purchase the beat</div> */}
            {data?.isMasterSample && (
              <p className={styles.master_sample_title}>
                It’s contain <span className={styles.master_span}>Master</span>{" "}
                Sample
              </p>
            )}
          </div>
        </div>
        <LineDivider />
      </div>
    </div>
  );
};

export default ViewSubmissionDetails;

const LineDivider = () => {
  return <div className={styles.line_divider}></div>;
};
