import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, Typography, useTheme } from "@mui/material";
import { useState } from "react";

const CheckBox = ({ title = "false", checked, onChange }) => {
  const [state, setState] = useState({ [title]: false });
  const theme = useTheme();

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });

    onChange && onChange(event.target.checked);
  };

  return (
    <Box>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={state[title] || checked}
                onChange={handleChange}
                name={title}
                sx={{
                  width: 24,
                  height: 24,
                  marginBottom: "18px",
                  marginTop: "20px",

                  "& .MuiSvgIcon-root": {
                    borderRadius: "100%",
                    fill: "#AFAFAF",
                  },
                  "&.Mui-checked": {
                    "& .MuiSvgIcon-root": {
                      color: "#2AC171",
                      "& path": {
                        fill: "#2AC171",
                        borderRadius: "100%",
                      },
                    },
                  },
                }}
              />
            }
            label={
              <Typography
                variant="body1"
                style={{
                  color: state[title] ? "#FFFFFF" : "#AFAFAF",
                  marginLeft: "12px",
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  "&.MuiFormControlLabel-root": {
                    marginLeft: "0px !important",
                    marginRight: "0px !important",
                    marginTop: "10px !important",
                  },
                }}
              >
                {title}
              </Typography>
            }
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};
export default CheckBox;
