import React from "react";
import styles from "../../auth_pages.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { FormField } from "../../signup_flow/FormInput";
import { Notification } from "../../../NotifyMsg/Notification";
import { useMutation } from "@apollo/client";
import { Login } from "../../../../Graphql_services/SignUpMutation";
import ForgotPasswordEmailForm from "./ForgotPasswordEmailForm";
import VerificationCodeForm from "./VerificationCodeForm";
import SetNewPasswordForm from "./SetNewPasswordForm";
import { decodeToken } from "../../../../context/apis/audio-library";

const SignInForm = ({ onNext, user, completeAccount, setSignUpStage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [login, { loading, error, data }] = useMutation(Login);
  const canContinue = email !== "" && password !== "";
  const [validationChecker, setValidationChecker] = useState({
    emailValidationMessage: "",
  });

  const { token } = useParams();
  console.log("Token is", token)

  const apicall = async () => {

    if (email && password) {
      try {
        const response = await login({ variables: { email, password } });
        if (response?.data?.login?.success) {
          const data = response?.data;
          if (data?.login?.success) {
            localStorage.setItem("token", data.login.accessToken);
            localStorage.setItem(
              "opportunityToken",
              data.login?.reactionAccessToken,
            );
            Notification("success", data.login.message);
            localStorage.setItem(
              "accountType",
              data?.login?.user?.roleId?.roleName,
            );
            localStorage.setItem("name", data.login.user.email.split("@")[0]);
            localStorage.setItem("userName", data.login.user?.userName);
            if (data.login.user.profilePic) {
              localStorage.setItem("profilePic", data.login.user.profilePic);
            }
            if (data.login.user.coverPic) {
              localStorage.setItem("coverPhoto", data.login.user.coverPic);
            }
            if (data.login.user._id) {
              localStorage.setItem("userId", data.login.user._id);
            }
            if (data.login.sessionId) {
              document.cookie = `sessionId=${data.login.sessionId};path=/;domain=unitedmarket.com`;
              document.cookie = `reactionAccessToken=${data.login?.reactionAccessToken};path=/;domain=unitedmarket.com`;
            }

            if (token) {
              window.location.href = `/linkshared/${token}`;
            } else {
              window.location.href = "/home";
            }
          }
        } else {
          Notification("error", response?.data?.login?.message);
        }
      } catch (error) {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          const errorMessage = error.graphQLErrors[0]?.message;
          Notification("error", `SignIn Error: ${errorMessage}`);
        } else {
          console.log("unexpected error",error);
          Notification(
            "error",
            `An unexpected error occurred. Please try again.`,
          );
        }
      }
    } else if (!email || !password) {
      if (!email) return setErrorMessage("invalid email/phone number");
      if (!password) return setErrorMessage("Enter password");
      if (!email && !password)
        return setErrorMessage("enter email and password");
    }
  };

  const handleSignIn = async () => {
    if (token) {
      const valid = await decodeToken(token);
      if (!valid) {
        apicall();
      } else {
        Notification("error", "Your Token is not invalid");
      }
    }
    else {
      apicall();
    }
  };


  function isValidEmail(email) {
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
      email,
    );
  }
  const handleEmailChange = (value) => {
    if (value.length > 0) {
      if (!isValidEmail(value)) {
        setValidationChecker({ emailValidationMessage: "Email is Invalid" });
      } else {
        setValidationChecker({ emailValidationMessage: "" });
        setEmail(value.toLowerCase().trim());
      }
    } else {
      setValidationChecker({ emailValidationMessage: "" });
    }
  };

  const stages = [
    { component: SignInForm, stage: 1 },
    { component: ForgotPasswordEmailForm, stage: 2 },
    { component: VerificationCodeForm, stage: 3 },
    { component: SetNewPasswordForm, stage: 4 },
  ];

  const handelForgotPassword = () => {
    let updatedUser = { ...user };
    setSignUpStage(stages);
    onNext(updatedUser);
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div className={styles.auth_box}>
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}>
              <p className={styles.auth_title}>Welcome Back!</p>
              <p className={styles.auth_subtitle}>Sign in to your account</p>
            </div>
            <div className={styles.input_list}>
              <FormField
                title="Email"
                onValueChange={(value) => handleEmailChange(value)}
                required={validationChecker.emailValidationMessage}
                type="email"
              />
              <FormField
                title="Password"
                password={true}
                onValueChange={(value) => setPassword(value)}
                type="password"
              />
              <div className={styles.ForgotPasswordMain}>
                <button type="button" onClick={handelForgotPassword}>
                  forgot password?
                </button>
              </div>
            </div>
          </div>
          <div className={styles.buttonControls}>
            {canContinue ? (
              <div onClick={handleSignIn} className={styles.signin_box}>
                {loading ? (
                  <svg viewBox="25 25 50 50" className={styles.auth_loader}>
                    <circle r="20" cy="50" cx="50"></circle>
                  </svg>
                ) : (
                  <p className={styles.connect_text}>Sign In</p>
                )}
              </div>
            ) : (
              <div className={styles.signin_box_inactive}>
                <p className={styles.connect_text}>Sign In</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
