import { gql } from "@apollo/client";

export const getUser = gql`
  query {
    getUser {
      success
      message
      isTokenExpired
      user {
        userName
        firstname
        lastname
        tokens
        countrycode
        state
        _id
        email
        phoneno
        accountType
        labelName
        aboutMe
        genre
        roleId {
          roleName
          _id
        }
        rolePrivilege {
          privileges {
            isCreate
            isUpdate
            isRead
            isDelete
          }
        }
        credits
        accolades
        experience {
          title
          employmentType
          companyName
          profileHeadline
          startDate
          endDate
          description
        }
        howDidYouKnow {
          name
        }
        instaUrl
        spotifyUrl
        profilePic
        coverPic
        isFreemium
        isSoundPackAllowed
        opportunityId
        cancel_at
        planId {
          planName
          interval
          price
          totalStorageInGBs
          totalLink
          totalBeatboxes
          totalSubmission
          totalSoundpack
        }
      }
    }
  }
`;
