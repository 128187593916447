import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, Heading, HStack, Input, Image } from 'native-base';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  addUpdateBillingInfo,
  getMyBillingInfo,
} from '../../Graphql_services/BillingMutation';
import {
  getMyPaymentMethod,
  renewSubscription,
} from '../../Graphql_services/PaymentsMutation';
import CardDetail from './CardDetail';

import wallet from '../../assets/Icons/wallet.png';
import AddCard from './AddCard';

const Payment = () => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(null);
  const [data, setData] = useState([]);
  const [cardInfo, setCardInfo] = useState([]);
  const [update, setUpdate] = useState(true);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [errorMsg, setErrorMsg] = useState(false);
  const [isActive, setActive] = useState(true);

  const [GetMyPaymentMethod] = useLazyQuery(getMyPaymentMethod, {
    context: { clientName: 'user' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data?.getMyPaymentMethods?.success === true) {
        if (data?.getMyPaymentMethods?.paymentMethods) {
          setCardInfo(data?.getMyPaymentMethods?.paymentMethods);
          setEdit(null);
        } else {
          setCardInfo([]);
        }
      }
      if (data?.getMyPaymentMethods?.success === false) {
        toast.error(data.getMyPaymentMethods.message);
        if (data?.getMyPaymentMethods?.isTokenExpired) {
          localStorage.clear();
          navigate('/signin');
        }
      }
    },
    onError(err) {
      console.log('error on getting getMyPaymentMethod', err);
    },
  });

  const [GetBilling] = useLazyQuery(getMyBillingInfo, {
    context: { clientName: 'user' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      console.log('getMyBillingInfo', data);
      if (data?.getMyBillingInformations?.success == true) {
        if (data?.getMyBillingInformations?.billingInformation == null) {
          setUpdate(false);
        } else {
          setCountry(
            data.getMyBillingInformations?.billingInformation?.country
          );
          setState(data.getMyBillingInformations?.billingInformation?.state);
          setAddress(
            data.getMyBillingInformations?.billingInformation?.address
          );
          setZipCode(
            data.getMyBillingInformations?.billingInformation?.zipCode
          );
        }
      } else if (data.getMyBillingInformations?.success == false) {
        //toast.error(data?.getMyBillingInformations?.message);
        if (data?.getMyBillingInformations?.billingInformation == null) {
          setUpdate(false);
        }
      } else {
        localStorage.clear();
        navigate('/signin');
      }
    },
    onError(err) {
      console.log('error on getting getMyBillingInfo', err);
    },
  });

  const [addUpdateBilling] = useMutation(addUpdateBillingInfo, {
    context: { clientName: 'user' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      // console.log('addUpdateBilling', data);
      if (data.addUpdateBillingInformation?.success == true) {
        toast.success('Billing Information Updated successfully');
        setUpdate(true);
      } else if (data.addUpdateBillingInformation?.success == false) {
        toast.error(data.addUpdateBillingInformation?.message);
      } else {
        localStorage.clear();
        navigate('/signin');
      }
    },
    onError(err) {
      console.log('error on  addUpdateBilling', err);
    },
  });

  const handleAddUpdateBilling = () => {
    if (country == '') {
      setErrorMsg(true);
    } else if (state == '') {
      setErrorMsg(true);
    } else if (address == '') {
      setErrorMsg(true);
    } else if (zipCode == '') {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
      addUpdateBilling({
        variables: {
          country: country,
          state: state,
          address: address,
          zipCode: zipCode,
        },
      });
    }
  };

  useEffect(() => {
    GetBilling();
    GetMyPaymentMethod();
  }, []);

  return (
    <Box w={'100%'} bg={'#000201'}>
      <Box w={'100%'} alignSelf={'center'} mt={10}>
        <HStack mb={5}>
          <Heading
            my={5}
            alignSelf={'center'}
            textTransform={'capitalize'}
            fontSize={{
              xl: '22px',
              lg: '20px',
              md: '16px',
              base: '14px',
            }}
            fontWeight={'normal'}
            textAlign={'left'}
            color={'#fff'}
          >
            Payment Methods
          </Heading>
          <Button
            leftIcon={<Image source={wallet} size={{ lg: 4, base: 3 }} />}
            _text={{
              fontFamily: 'Poppins',
              fontWeight: 'normal',
              fontSize: { xl: '14px', lg: '12px', base: '10px' },
              color: '#fff',
            }}
            disabled={cardInfo.length < 3 && isActive ? false : true}
            borderWidth={1}
            borderColor={
              cardInfo.length < 3 && isActive ? '#2ebb55' : '#afafaf'
            }
            p={0}
            py={1}
            px={2}
            mx={5}
            alignSelf={'flex-end'}
            mb={'5'}
            rounded={'lg'}
            bg={cardInfo.length < 3 && isActive ? '#2ebb55' : '#afafaf'}
            _hover={{ bg: 'transparent', _text: { color: '#2ebb55' } }}
            _pressed={{ bg: '#2ebb55', _text: { color: '#fff' } }}
            onPress={() => {
              setActive(false);
            }}
          >
            Add Card
          </Button>
        </HStack>
        {cardInfo.length != 0 ? (
          <>
            {cardInfo.map((item, index) => {
              return (
                <CardDetail
                  key={index}
                  id={item.id}
                  cardNumber={'**** **** **** ' + item?.card?.last4}
                  expireDate={moment({
                    year: item?.card?.exp_year,
                    month: item?.card?.exp_month - 1,
                  }).format('MM/YY')}
                  type={item?.card?.brand}
                  defaultPayment={item?.isDefaultPaymentMethod}
                  data={item}
                  noOfCards={cardInfo.length}
                  GetMyPaymentMethod={() => GetMyPaymentMethod()}
                />
              );
            })}
            {!isActive && (
              <AddCard
                setActive={(bool) => setActive(bool)}
                GetMyPaymentMethod={() => GetMyPaymentMethod()}
              />
            )}
          </>
        ) : (
          <>
            {isActive ? (
              <Heading
                textTransform={'capitalize'}
                fontSize={{
                  xl: '22px',
                  lg: '20px',
                  md: '16px',
                  base: '12px',
                }}
                fontWeight={'normal'}
                textAlign={'left'}
                color={'#2ebb55'}
                w={'100%'}
                rounded={'lg'}
                py={3}
                px={5}
                my={5}
                bg={'#2ebb5520'}
              >
                You didn’t add any cards yet.
              </Heading>
            ) : (
              <AddCard
                setActive={(bool) => setActive(bool)}
                GetMyPaymentMethod={() => GetMyPaymentMethod()}
              />
            )}
          </>
        )}

        <Heading
          my={5}
          textTransform={'capitalize'}
          fontSize={{
            xl: '22px',
            lg: '20px',
            md: '16px',
            base: '14px',
          }}
          fontWeight={'normal'}
          textAlign={'left'}
          color={'#fff'}
        >
          Billing Address
        </Heading>
        <HStack
          width={'100%'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          flexGrow={1}
        >
          <Box width={{ lg: '48%', base: '100%' }}>
            <Heading
              my={5}
              textTransform={'capitalize'}
              fontSize={{ xl: '24px', lg: '24px', md: '20px', base: '18px' }}
              fontWeight={500}
              fontFamily={'Poppins'}
              textAlign={'left'}
              color={'#afafaf'}
            >
              Country
            </Heading>
            <Input
              _text={{ fontSize: '1rem', fontFamily: 'poppins' }}
              _hover={{ borderColor: '#AFAFAF' }}
              _focus={{ bg: 'transparent' }}
              onChangeText={(text) => setCountry(text)}
              value={country}
              focusOutlineColor='#2EBB55'
              fontFamily='poppins'
              fontSize={{ lg: '18px', md: '16px', base: '14px' }}
              color={'#AFAFAF'}
              borderColor='#AFAFAF'
              height={{
                xl: '60px',
                base: '45px',
              }}
            />
          </Box>
          <Box width={{ lg: '48%', base: '100%' }}>
            <Heading
              my={5}
              textTransform={'capitalize'}
              fontSize={{ xl: '24px', lg: '24px', md: '20px', base: '18px' }}
              fontWeight={500}
              fontFamily={'Poppins'}
              textAlign={'left'}
              color={'#afafaf'}
            >
              State
            </Heading>
            <Input
              _text={{ fontSize: '1rem', fontFamily: 'poppins' }}
              _hover={{ borderColor: '#AFAFAF' }}
              _focus={{ bg: 'transparent' }}
              onChangeText={(text) => setState(text)}
              value={state}
              focusOutlineColor='#2EBB55'
              fontFamily='poppins'
              fontSize={{ lg: '18px', md: '16px', base: '14px' }}
              color={'#AFAFAF'}
              borderColor='#AFAFAF'
              height={{
                xl: '60px',
                base: '45px',
              }}
            />
          </Box>
        </HStack>
        <HStack
          width={'100%'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          flexGrow={1}
        >
          <Box width={{ lg: '48%', base: '100%' }}>
            <Heading
              my={5}
              textTransform={'capitalize'}
              fontSize={{ xl: '24px', lg: '24px', md: '20px', base: '18px' }}
              fontWeight={500}
              fontFamily={'Poppins'}
              textAlign={'left'}
              color={'#afafaf'}
            >
              Address
            </Heading>

            <Input
              _text={{ fontSize: '24px', fontFamily: 'poppins' }}
              _hover={{ borderColor: '#AFAFAF' }}
              _focus={{ bg: 'transparent' }}
              onChangeText={(text) => setAddress(text)}
              value={address}
              focusOutlineColor='#2EBB55'
              fontFamily='poppins'
              fontSize={{ lg: '18px', md: '16px', base: '14px' }}
              color={'#AFAFAF'}
              borderColor='#AFAFAF'
              height={{
                xl: '60px',
                base: '45px',
              }}
            />
          </Box>
          <Box width={{ lg: '48%', base: '100%' }}>
            <Heading
              my={5}
              textTransform={'capitalize'}
              fontSize={{ xl: '24px', lg: '24px', md: '20px', base: '18px' }}
              fontWeight={500}
              fontFamily={'Poppins'}
              textAlign={'left'}
              color={'#afafaf'}
            >
              Zip Code
            </Heading>
            <Input
              _text={{ fontSize: '1rem', fontFamily: 'poppins' }}
              _hover={{ borderColor: '#AFAFAF' }}
              _focus={{ bg: 'transparent' }}
              inputMode={'numeric'}
              onChangeText={(text) => setZipCode(text)}
              value={zipCode}
              focusOutlineColor='#2EBB55'
              fontFamily='poppins'
              fontSize={{ lg: '18px', md: '16px', base: '14px' }}
              color={'#AFAFAF'}
              borderColor='#AFAFAF'
              height={{
                xl: '60px',
                base: '45px',
              }}
            />
          </Box>
        </HStack>
        {errorMsg ? (
          <Heading
            my={5}
            textTransform={'capitalize'}
            fontSize={{ lg: '14px', base: '10px' }}
            fontWeight={400}
            fontFamily={'Poppins'}
            textAlign={'left'}
            color={'#f00'}
          >
            All fields are required
          </Heading>
        ) : null}
        <Button
          w={{ lg: '20%', base: '40%' }}
          borderColor='#2EBA55'
          borderWidth={1}
          _pressed={{
            bg: '#2EBB55',
            _text: {
              color: '#fff',
            },
          }}
          backgroundColor='#2EBA55'
          my={8}
          py={2.5}
          _text={{
            color: '#fff',
            fontSize: { xl: '18px', lg: '18px', md: '16px', base: '14px' },
            fontWeight: 'medium',
            textTransform: 'capitalize',
            fontFamily: 'Poppins',
          }}
          _hover={{
            backgroundColor: 'transparent',
            _text: {
              color: '#2EBA55',
            },
          }}
          alignSelf={'flex-end'}
          onPress={() => handleAddUpdateBilling()}
        >
          {update ? 'Update' : 'Add'}
        </Button>
      </Box>
    </Box>
  );
};

export default Payment;
