import React from "react";
import { DetailsWrapper, InputFieldWrapper } from "../style";
import CopyIcon from "../../../../../../../assets/smartlink/icons/CopyIcon";
import { CopyButton } from "../style";

const CopyDetails = ({ CreatedLinkData }) => {
  let Data = CreatedLinkData;
  console.log(Data, "Recived From Copy");

  const { url } = CreatedLinkData;

  return (
    <DetailsWrapper>
      <h5>Link Has Been Created</h5>
      <p>
        To Craft Your Vision, Visit the Edit Page! Your masterpiece is in your
        hands. Head to the edit link page and shape your creation exactly the
        way you envision it
      </p>

      <InputFieldWrapper>
        <input value={url} />
        <CopyButton>
          <CopyIcon />
          Copy
        </CopyButton>
      </InputFieldWrapper>
    </DetailsWrapper>
  );
};

export default CopyDetails;
