import React from "react";
import { Grid } from "@mui/material";

// import styles from "./styles.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";

import DistributionPages from "../../components/DistributionComponents/distribution_page";

const DistributionPage = () => {
  return (
    <Grid>
      <Sidebar
        activeText="Distribution"
        childComponent={<DistributionPages />}
      />
    </Grid>
  );
};

export default DistributionPage;
