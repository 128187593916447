import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import EditTemplateComponent from "../../components/MyContractsComponents/MyContracts/EditTemplateComponent";

const EditTemplatesPage = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<EditTemplateComponent />}
        activeText="My Contracts"
      />
    </Grid>
  );
};

export default EditTemplatesPage;
