import React from "react";

const StarSvg = ({ color = "#F6E11F" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={color}>
      <path d="M11.4431 2.92471L12.9097 5.85804C13.035 6.07813 13.205 6.2695 13.4089 6.41979C13.6127 6.57007 13.8458 6.67594 14.0931 6.73054L16.7514 7.17221C18.4514 7.45554 18.8514 8.68887 17.6264 9.90554L15.5597 11.9722C15.3789 12.175 15.246 12.4158 15.171 12.6769C15.096 12.9381 15.0807 13.2127 15.1264 13.4805L15.7181 16.0389C16.1847 18.0639 15.1097 18.8472 13.3181 17.7889L10.8264 16.3139C10.5712 16.1825 10.2884 16.1139 10.0014 16.1139C9.71438 16.1139 9.43153 16.1825 9.17639 16.3139L6.68472 17.7889C4.90139 18.8472 3.81806 18.0555 4.28472 16.0389L4.87639 13.4805C4.92205 13.2127 4.9068 12.9381 4.83178 12.6769C4.75677 12.4158 4.6239 12.175 4.44306 11.9722L2.37639 9.90804C1.15972 8.69137 1.55139 7.45804 3.25139 7.17471L5.90972 6.73054C6.15556 6.67474 6.38708 6.56834 6.58952 6.41812C6.79197 6.2679 6.9609 6.07716 7.08556 5.85804L8.55222 2.92471C9.35139 1.33304 10.6514 1.33304 11.4431 2.92471Z" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default StarSvg;
