import { useState } from "react";
import { Box, HStack, VStack, Button, Hidden, Text } from "native-base";
import React, { useEffect } from "react";
import { AuthContext } from "./Layout";
import { useContext } from "react";
import { useQuery } from "@apollo/client";

import { getNewRoles, GetRoles } from "../../Graphql_services/SignUpMutation";

const AccountType = (props) => {
  const { w, h, flag, borderadius } = props;
  const { loading, error, data } = useQuery(flag ? GetRoles : getNewRoles);
  const [accounts, setAccounts] = useState([]);
  const {
    setAccountType,
    SetRoleID,
    SetNewRoleId,

    selectedroleid,
    setselectedroleID,
    selectedHear,
    setselectedHear,

    setothersSocial,
    setselectedAccount,
  } = useContext(AuthContext);

  const handlePress = (e) => {
    if (flag) {
      setAccountType(e.currentTarget.innerText.trim());
    }
    if (e.currentTarget.innerText === "Other") {
      setothersSocial(true);
    } else {
      setothersSocial(false);
    }
    flag ? SetRoleID(e.currentTarget.id) : SetNewRoleId(e.currentTarget.id);
    flag && setselectedAccount(e.currentTarget.innerText);
    flag
      ? setselectedroleID(e.currentTarget.id)
      : setselectedHear(e.currentTarget.id);
  };

  useEffect(() => {
    if (data) {
      if (data && flag) {
        const rolesWithoutAdmin = data?.getRoles?.filter(
          (role) => role.roleName.toLowerCase() !== "admin"
        );
        setAccounts(rolesWithoutAdmin);
      } else if (data && !flag) {
        setAccounts(data?.getHowDoYouKnows);
      }
    }
  }, [data]);

  return (
    <Box mb={{ md: 10, base: 5 }}>
      {loading && <p style={{ color: "#fff" }}>Loading...</p>}

      {!loading && (
        <Hidden from="base" till="sm">
          <VStack space={10}>
            <HStack
              space={{ "2xl": "30px", xl: "20px", md: "30px", base: "30px" }}
              flexGrow={1}
              justifyContent={{
                lg: "center",
                md: "space-between",
                base: "center",
              }}
              flexDirection="row"
              flexWrap="wrap"
            >
              {accounts && accounts?.length > 0 ? (
                <>
                  {accounts?.map((value, index) => {
                    return (
                      <>
                        <div className="button btn" key={`accoutypeZ-${index}`}>
                          <Button
                            key={value._id}
                            nativeID={value._id}
                            onPress={(e) => handlePress(e)}
                            _focus={{
                              backgroundColor: "#2EBB55",
                              _text: {
                                color: "#E3E3E3",
                              },
                            }}
                            mb={{ md: 4, base: 5 }}
                            _text={{
                              color:
                                selectedroleid === value._id
                                  ? "#fff"
                                  : "#AFAFAF",
                              textTransform: "capitalize",
                              fontFamily: "poppins",
                              textAlign: "center",
                              fontSize: {
                                xl: "18px",
                                md: "1rem",
                                sm: "0.9rem",
                                base: "0.75rem",
                              },
                            }}
                            _hover={{
                              backgroundColor: "#2EBB55",
                              _text: {
                                color: "#E3E3E3",
                              },
                            }}
                            backgroundColor={
                              selectedroleid === value._id
                                ? "#2EBB55"
                                : "#1C1B1B"
                            }
                            borderRadius={borderadius}
                            w={w}
                            h={h}
                          >
                            {value?.__typename === "howDoYouKnow"
                              ? value.name
                              : value.roleName}
                          </Button>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <p>User Not Found.</p>
              )}
            </HStack>
          </VStack>
        </Hidden>
      )}
    </Box>
  );
};

export default AccountType;
