import React, { useState, useEffect } from "react";
import { Loader } from "../Loader";
import { toast } from "react-toastify";

import {
  Box,
  Button,
  Heading,
  HStack,
  Text,
  Input,
  Divider,
} from "native-base";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import moment from "moment";

import styles from "./styles.module.css";
import { SoundPackEmpty } from "./Svgs/SoundPackEmpty";
import UploadSoundKitsBasic from "./UploadSoundKitsTabs/UploadSoundKitsBasic";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",

    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",

    backgroundColor: "#2ebb55",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.spacing(5),

    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SoundKit = () => {
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box w={"100%"} bg={"#000201"}>
      <Box w={"90%"} alignSelf={"center"}>
        <Heading
          my={8}
          textTransform={"capitalize"}
          fontFamily={"Poppins"}
          fontSize={{
            xl: "40px",
            lg: "36px",
            md: "30px",
            base: "28px",
          }}
          fontWeight={"bold"}
          textAlign={"left"}
          color={"#fff"}
        >
          Sound Pack
        </Heading>
        {/* <UploadSoundKitsBasic /> */}
        <SoundKitTab />
        {/* <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          sx={{
            color: '#afafaf',
            paddingTop: '40px',
          }}
        >
          <StyledTab
            label='&nbsp;&nbsp;PERSONAL INFO&nbsp;&nbsp;'
            {...a11yProps(0)}
            className={styles.tabSpace}
          />
        </StyledTabs>
        <Divider />
        <TabPanel value={value} index={0}>
          <SoundKitTab />
        </TabPanel> */}
      </Box>
    </Box>
  );
};

const SoundKitTab = () => {
  const navigate = useNavigate();

  return (
    <Box w={"100%"} bg={"#000201"}>
      <Box
        mt={{ "2xl": "20vh", xl: "10vh", lg: "10vh", base: "10vh" }}
        w={"100%"}
        justifyContent={"center"}
      >
        <Box alignSelf={"center"}>
          <SoundPackEmpty />
        </Box>
        <Heading
          fontFamily={"Poppins"}
          fontWeight={500}
          fontSize={{ "2xl": "40px", xl: "36px", lg: "32px", base: "22px" }}
          lineHeight={{ "2xl": "60px", xl: "50x", lg: "40px", base: "30px" }}
          textAlign={"center"}
          color={"#e3e3e3"}
          py={10}
        >
          You have no active sound kits.
        </Heading>
      </Box>
      <Button
        w={{ lg: "40%", base: "60%" }}
        borderColor="#2EBA55"
        borderWidth={1}
        _pressed={{
          bg: "#2EBB55",
          _text: {
            color: "#fff",
          },
        }}
        backgroundColor="#2EBA55"
        py={2.5}
        my={5}
        _text={{
          color: "#fff",
          fontSize: { xl: "18px", lg: "18px", md: "16px", base: "14px" },
          fontWeight: "medium",
          textTransform: "capitalize",
          fontFamily: "Poppins",
        }}
        _hover={{
          backgroundColor: "transparent",
          _text: {
            color: "#2EBA55",
          },
        }}
        alignSelf={"center"}
        onPress={() => navigate("/soundPack/create")}
      >
        Upload A New Sound Kit
      </Button>
    </Box>
  );
};

export default SoundKit;
