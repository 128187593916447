import React, { Fragment, useEffect, useState } from "react";
import styles from "./Libraries.module.css";

const ShowStorage = ({ totalStorage, useStorage }) => {
  const storagePercentage = (useStorage / totalStorage) * 100;

  return (
    <div
      style={{
        borderRadius: "5px",
        background: "#0A0B0A",
        margin: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={styles.storage_big_title}>Storage</div>
        <div className={styles.storage_small_title}>
          {`${useStorage} GB of ${totalStorage} GB`}
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "5px",
          background: "#1E1F1E",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        {/* Progress Bar Fill */}
        <div
          style={{
            width: `${storagePercentage}%`,
            height: "100%",
            background: "#4CAF50",
            transition: "width 0.5s ease-in-out",
          }}
        ></div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={styles.storage_small_title}>Used</div>
        <div className={styles.storage_small_upgrade}>Upgrade</div>
      </div>
    </div>
  );
};

export default ShowStorage;
