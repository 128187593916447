import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { Box } from "@mui/material";

const AnalyticsChart = () => {
  const months = ["1 Sep", "15 Sep", "30 Sep", "01 Oct", "15 Oct"];
  const values = [0.3, 2, 3, 1, 4, 3, 2, 6, 5, 7];

  const chartData = months.map((day, index) => ({ day, value: values[index] }));

  return (
    <Box sx={{ marginTop: "40px", marginLeft: "-10px" }}>
      <ResponsiveContainer minHeight={350}>
        <AreaChart data={chartData} style={{ height: "300px" }}>
          <CartesianGrid strokeDasharray="1 7" vertical={false} />

          <XAxis
            dataKey="day"
            tickLine={{ stroke: "0" }}
            strokeWidth={3}
            stroke="#2AC171"
            axisType="radiusAxis"
            tick={{ dy: 15 }}
          />
          <YAxis
            axisLine={{
              stroke: "#2AC171",
              strokeWidth: 0,
              strokeLinecap: "round",
            }}
            tickLine={{ stroke: "0" }}
            axisType="radiusAxis"
            tick={{ dx: -35 }}
          />
          <Area
            type="monotone"
            dataKey="value"
            stroke="#2AC171"
            fill="url(#areaGradient)"
            strokeWidth={3}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default AnalyticsChart;
