import React from "react";
import styles from "./universal.module.css";
import FilterMenu from "../UniversalComponents/filter_menu";
import { useNavigate } from "react-router-dom";

function PageNavigationControls(props) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div className={styles.main_layout}>
      <div className={styles.back_box} onClick={handleClick}>
        <img
          className={styles.back_box_image}
          src="/v2Assets/submission_form_assets/back.png"
          alt="back"
        ></img>
        <p className={styles.back_text}>Back</p>
      </div>
    </div>
  );
}

export default PageNavigationControls;
