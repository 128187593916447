import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import LibraryWrapper from "../../components/LibrariesComponents/Libraries/LibraryWrapper";

const MyAudioLibraryPage = ({currentSubBar}) => {
  return (
    <Grid>
      <Sidebar childComponent={<LibraryWrapper curretnBar={currentSubBar} />} activeText="My Audio Library" />
    </Grid>
  );
};

export default MyAudioLibraryPage;
