import React from "react";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";

const Calendly = () => {
  useCalendlyEventListener({
    onEventScheduled: (e) => console.log(e.data.payload),
  });

  return (
    <div className="calender">
      <InlineWidget
        iframeTitle="Calendly Scheduling Page"
        pageSettings={{
          backgroundColor: "ffffff",
          hideEventTypeDetails: false,
          hideGdprBanner: true,
          hideLandingPageDetails: false,
          primaryColor: "00a2ff",
          textColor: "4d5055",
        }}
        prefill={{
          customAnswers: {},
          date: new Date("2022-09-26T09:23:43.002Z"),
          email: "",
          firstName: "",
          guests: [],
          lastName: "",
          name: "",
        }}
        styles={{
          position: "absolute",

          zIndex: 66666,
        }}
        url="https://calendly.com/UnitedMarket"
        utm={{
          utmCampaign: "Spring Sale 2019",
          utmContent: "Shoe and Shirts",
          utmMedium: "Ad",
          utmSource: "Facebook",
          utmTerm: "Spring",
        }}
      />
    </div>
  );
};

export default Calendly;
