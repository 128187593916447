import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { TextField, Button, Grid, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import styles from '../styles.module.css';
import useAuth from '../../../context/AuthContext';
import { useLazyQuery } from '@apollo/client';
import { previewOfCollaborators } from '../../../Graphql_services/SoundPackMutation';

export function AddSplit(props) {
  const navigate = useNavigate();
  const { openModal, closeModal, mySplit, setMySplit, collaboratorData } =
    props;
  const [inputValue, setInputValue] = useState('');
  const { user } = useAuth();
  const schema = yup.object().shape({
    split: yup
      .number()
      .required()
      .min(0)
      .max(100)
      .typeError('split must be a number'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSave = (data) => {
    // Handle saving the input value
    const splitSum = collaboratorData.reduce(
      (sum, item) => sum + item.split,
      0
    );
    if (splitSum + data.split > 100) {
      toast.error('The sum of collaborators split cant be greater than 100');
    } else {
      setMySplit({
        musicianName: user.userName,
        email: user.email,
        split: parseFloat(data.split),
        role: user.roleId?.roleName,
        profilePic: user.profilePic,
      });
    }
    closeModal();
  };

  useEffect(() => {
    if (openModal) {
      reset();
    }
  }, [openModal]);

  const darkTheme = createTheme({
    palette: {
      type: 'dark',
    },
  });

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const modalStyle = getModalStyle();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Modal open={openModal} onClose={closeModal}>
        <div style={modalStyle} className={styles.modalPaper}>
          <div
            className={styles.modalHeader}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='p' className={styles.heading}>
              Add My %
            </Typography>
            <IconButton onClick={closeModal}>
              <Close sx={{ color: '#AFAFAF' }} />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit(handleSave)}>
            <Grid container direction='row' className={styles.modalHeader}>
              <p className={styles.labelContent}>Add your Split %</p>
              <TextField
                {...register('split')}
                name='split'
                fullWidth
                className={styles.textField}
              />
              {<p>{errors.split?.message}</p>}
            </Grid>
            <Grid container direction='row-reverse'>
              <br />
              <Button
                variant='contained'
                type='submit'
                className={styles.saveBtn}
              >
                ADD
              </Button>
              <Button
                variant='contained'
                onClick={closeModal}
                style={{ marginRight: '10px' }}
                className={styles.cancelBtn}
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </div>
      </Modal>
    </ThemeProvider>
  );
}
