import * as React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#2EBB55",
  },
}));

const CustomProgressBar = ({ value = 0 }) => {
  const numericValue = parseFloat(value);
  return (
    <>
      <BorderLinearProgress variant="determinate" value={numericValue} />
    </>
  );
};

export default React.memo(CustomProgressBar);
