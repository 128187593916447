import React, { useState } from "react";
import { Box, Button, Divider, Modal } from "@mui/material";
import { ModelContentBox } from "./styles/OTPPopupStyle";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OTPInput from "react-otp-input";
import { useMutation } from "@apollo/client";
import {
  sendOTP,
  verifyOTP,
} from "../../../../Graphql_services/SignUpMutation";
import { Notification } from "../../../NotifyMsg/Notification";
import { useNavigate, useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "#1C1B1B",
  pt: 2,
  px: 4,
  pb: 3,
};

const OTPPopup = ({ open = false, setOpen = () => {} }) => {
  const navigate = useNavigate();
  const { email } = useParams();
  const [isloading, setIsLoading] = useState(false);

  const [sendOtp] = useMutation(sendOTP);
  const [verifyOtp] = useMutation(verifyOTP);
  const [otp, setOtp] = useState("");

  const MusicianOtpModelClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(!open);
  };

  const renderCustomInput = (inputProps, index) => (
    <input
      {...inputProps}
      style={{
        width: "60px",
        height: "60px",
        marginRight: index % 3 === 2 ? "30px" : "12px",
        borderRadius: "5px",
        border: "1px solid #414241",
        background: "#1C1B1B",
        textAlign: "center",
        color: "#fff",
      }}
    />
  );

  const handelSubmit = async () => {
    if (!otp) {
      Notification("error", "otp is required");
      return;
    }
    setIsLoading(true);
    try {
      const { data } = await verifyOtp({
        variables: {
          otp,
          email,
        },
      });

      if (data?.verifyOtp?.success) {
        Notification("success", data?.verifyOtp?.message);
        navigate(`/producer/auth/reset-password/${otp}`);
      } else {
        Notification("error", data?.verifyOtp?.message);
      }
    } catch (error) {
      Notification("error", error?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handelresend = async () => {
    try {
      const { data } = await sendOtp({
        variables: {
          email: email,
          countrycode: null,
          phoneno: null,
        },
      });

      if (data?.sendOtp?.success) {
        Notification("success", data?.sendOtp?.message);
      } else {
        Notification("error", data?.sendOtp?.message);
      }
    } catch (error) {
      Notification("error", error?.message);
    }
  };

  return (
    <>
      {/* <Modal
        open={true}
        onClose={MusicianOtpModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.50)",
          },
        }}
      > */}
      <ModelContentBox
        className="main ModelContentBox"
        sx={{
          ...style,
          width: { xs: "100%", sm: "700px" },
          padding: "40px 67px 40px 67px",
          borderRadius: "20px",
          background:
            "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
          backdropFilter: "blur(200px)",
        }}
      >
        <Box className="header-icon-box">
          <ArrowBackIcon
            className="back-icon"
            onClick={() => {
              navigate(-1);
            }}
          />
          <CloseIcon className="close-icon" onClick={MusicianOtpModelClose} />
        </Box>
        <h5>Enter Verification Code</h5>
        <p>
          An OTP code will be sent to your phone for verification. Please enter
          it below when received.
        </p>
        <Box className="input-box-main">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={renderCustomInput}
          />
        </Box>
        <Box className="btn-box">
          <Button
            variant="contained"
            fullWidth
            className="btn"
            onClick={handelSubmit}
            disabled={isloading}
          >
            {isloading ? "Loading..." : "verify"}
          </Button>
        </Box>
        <span onClick={handelresend}>Send the code again</span>
        <Divider className="underline" />
      </ModelContentBox>
      {/* </Modal> */}
    </>
  );
};

export default OTPPopup;
