import React, { useEffect } from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  MainTitle,
  PageTitle,
} from "../../../components/smartlink";
import { GET_SMART_LINK_BY_ID } from "../../../Graphql_services/smartlink/SmartLink";
import { useLazyQuery } from "@apollo/client";
import Index from "../../../components/smartlink/release";
import EvaluationForm from "../../../components/smartlink/release/pages/evaluateLink/evaluationForm/Index";
import LinkSourceForm from "../../../components/smartlink/release/pages/evaluateLink/linkSourceForm/Index";
import FallbackForm from "../../../components/smartlink/release/pages/evaluateLink/fallbackForm";
import DuplicateDraftForm from "../../../components/smartlink/release/pages/evaluateLink/duplicateForm/Index";
import DeleteLinkForm from "../../../components/smartlink/release/pages/evaluateLink/deleteForm";
import { useParams } from "react-router-dom";
import { TroubleshootRounded } from "@mui/icons-material";
import EditLayout from "../../../components/smartlink/Editsmartlink/Editlayout/EditLayout";

const EditReleasePage = () => {
  const { id } = useParams();
  const [getSmartLink, { loading, data, error }] =
    useLazyQuery(GET_SMART_LINK_BY_ID);

  useEffect(() => {
    if (id) {
      getSmartLink({
        variables: { id: id },
        context: { clientName: "smartlink" },
      });
    }
  }, [id, getSmartLink]);

  return (
    <>
      <SmartLinkLayout>
        <MainTitle title="Smart Link" hasbutton={false} />
        <HorizontalMenu />
        <PageTitle NavigateTitle="Edit Link" hasNavigate={true} />
        <EditLayout>
          <>
            {/* <div id="OrganizeForm">
              <EvaluationForm
                heading={"Organize"}
                Subheading="Unlock the Stage for Your Music! Add the magic with tags, and let your artists shine. Our platform transforms your tunes into a mesmerizing experience, captivating audiences worldwide."
              />
            </div>
            <br />
            <div id="DeleteForm">
              <DeleteLinkForm />
            </div> */}

            {data && data?.getSmartLinkById?.success === true ? (
              <>
                <div id="OrganizeForm">
                  <EvaluationForm
                    EditData={data.getSmartLinkById}
                    id={id}
                    heading={"Organize"}
                    Subheading="Unlock the Stage for Your Music! Add the magic with tags, and let your artists shine. Our platform transforms your tunes into a mesmerizing experience, captivating audiences worldwide."
                  />
                </div>
                <br />
                <div id="DeleteForm">
                  <DeleteLinkForm EditData={data.getSmartLinkById} id={id} />
                </div>
              </>
            ) : (
              <>
                <p style={{ color: "white" }}>Loading...</p>
              </>
            )}
          </>
        </EditLayout>

        {/* <>
          <EvaluationForm EditData={data.getSmartLinkById} id={id} />
          <br />
          <LinkSourceForm EditData={data.getSmartLinkById} id={id} />
          <br />
          <FallbackForm EditData={data.getSmartLinkById} id={id} />
          <br />
          <DuplicateDraftForm EditData={data.getSmartLinkById} id={id} />
          <br />
          <DeleteLinkForm EditData={data.getSmartLinkById} id={id} />
        </> */}
      </SmartLinkLayout>
    </>
  );
};

export default EditReleasePage;
