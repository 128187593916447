import info from "./common/icon_info.svg";
import clock from "./common/clock.svg";
import greenTick from "./common/green-tick.svg";
import warning from "./common/warning.svg";
import star from "./common/star.svg";
import yellowStar from "./common/yellow-star.svg";
import flag from "./common/flag.svg";
import redFlag from "./common/red-flag.svg";
import filledYellowStar from "./common/filled-yellow-star.svg";
import filledRedFlag from "./common/filled-red-flag.svg";
import closeBtn from "./common/close-icon.svg";
import refresh from "./common/refresh.svg";
import settings from "./common/settings.svg";
import close from "./common/close.svg";
import tick from "./common/tick.svg";
import defaultCoverImage from "./Images/defaultCoverImage.png";
import defaultProfile from "./common/default-profile.svg";
import verifiedTick from "./common/verified-tick.svg";
import correctIcon from "./common/correct-icon.svg";
import crossWhiteIcon from "./common/crossWhiteIcon.svg";
import infoCircleIcon from "./common/info-circle.svg";

const allImages = {
  info,
  clock,
  greenTick,
  warning,
  star,
  yellowStar,
  flag,
  redFlag,
  filledYellowStar,
  filledRedFlag,
  closeBtn,
  refresh,
  settings,
  close,
  tick,
  defaultCoverImage,
  defaultProfile,
  verifiedTick,
  correctIcon,
  crossWhiteIcon,
  infoCircleIcon,
};

export default allImages;
