import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./submit_opportunity.module.css";

import CloseIcon from "@material-ui/icons/Close";

import PageNavigationControls from "../../UniversalComponents/page_navigation_controls";
import useAppState from "../../../context/useAppState";
import CheckboxWithInfoIcon from "../AdditionalComponents/Checkbox/CheckboxWithInfoIcon";
import { RadioGroup } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";

import { Controller, useForm } from "react-hook-form";
import SubmissionSchema from "../YupSchema/SubmissonSchema";
import RadioButtonWithLabel from "../../UniversalComponents/RadioButtonWithLabel";
import useAuth from "../../../context/AuthContext";
import { get } from "lodash";
import { Box, IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";
import AudioPlayer from "../../UniversalComponents/audio_player";
import { remainngTimeCount } from "../../../shared/funs";
import { DeleteSoundPack } from "../../soundpack_page/Modals/DeleteSoundPack";
import { Tooltip } from "@mui/material";
import {
  PITCHING_PERIOD,
  PUBLISHING_FEE_TOOLTIP,
  TUNEVIBES_DATA,
} from "../../../shared/constants";
import allImages from "../../../assets";
import DarkTooltip from "../AdditionalComponents/Tooltip/DarkTooltip";
import { addSubmission } from "../../../context/apis/submission";
import MultiModal from "../../smartlink/common/MultiModal/MultiModal";
import { crown } from "../../../assets/smartlink";
import { Notification } from "../../NotifyMsg/Notification";
import BlueButtonComponents from "../../LibrariesComponents/AdditionalComponents/utils/BlueButtonComponent";
import MassUploadModal from "../../LibrariesComponents/Libraries/Modals/MassUploadModal";
import AudioModal from "../../LibrariesComponents/Libraries/Modals/AudioModal";

const collaborator = {
  name: "",
  email: "",
  publishingPercentage: 0,
};
const SubmitOpportunity = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteAudio, setDeleteAudio] = useState(false);
  const [deleteType, setDeleteType] = useState("");

  const { data, fetchOpportunityById } = useAppState("opportunity");
  // const { saveSubmission, isLoading = false } = useAppState("submission");

  const [isLoading, setisLoading] = useState(false);
  const [PaymentOpen, setPaymentOpen] = useState(false);
  const { uploadFile, uploadAudio } = useAppState("file");

  const [isUploading, setIsUploading] = useState(false);

  const { id } = useParams();
  const [openAudio, setOpenAudio] = useState(false);
  const [fromLibrary, setFromLibrary] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      file: null,
      opportunitiesId: id,
      title: "",
      tone: [],
      collaboratorOnLoop: undefined,
      collaborators: [],
      isContainMasterSample: undefined,
      exclusivePrice: "",
      // nonExclusivePrice: "",
      pitchingPeriod: "",
      isMasterSample: undefined,
      agreePolicy: undefined,
      agreepublishingFee: undefined,
    },
    resolver: yupResolver(SubmissionSchema),
    reValidateMode: "onBlur",
    mode: "all",
  });

  const onDropAudio = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0] || null;

    const payload = new FormData();

    if (file) {
      setIsUploading(true);
      payload.append("file", file);
      console.log("File=", file);
      payload.append("type", "Public");
      try {
        const { fileLocation, audioWaveFileLocation } =
          await uploadAudio(payload);
        console.log("UPLOAD FINISHED");

        if (fileLocation) {
          setValue(
            "file",
            {
              name: file.name,
              url: fileLocation,
              waveUrl: audioWaveFileLocation,
            },
            { shouldValidate: true },
          );
          setIsUploading(false);
        }
      } catch (error) {
        console.info("error=>", error);
        setError("file", {
          type: "custom",
          message: get(error, "response.data.message", "Invalid file upload"),
        });
      } finally {
        setIsUploading(false);
        setIsUploading(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropAudio,
  });

  const {
    collaborators = [],
    agreePolicy,
    agreepublishingFee,
    file,
    collaboratorOnLoop,
    ...rest
  } = watch();

  const openAudioModal = () => {
    setOpenAudio(true);
  }

  useEffect(() => {
    if (user?._id) {
      setValue("createdBy", user._id);
    }
  }, [user]);

  useEffect(() => {
    if (collaboratorOnLoop === "no") {
      setValue("collaborators", [], { shouldValidate: true });
    }
  }, [collaboratorOnLoop]);

  useEffect(() => {
    if (id) {
      fetchOpportunityById(id);
    }
  }, [id]);

  const onSubmit = async (data) => {
    const {
      opportunitiesId,
      createdBy,
      title,
      tone,
      file,
      mySplit,
      collaboratorOnLoop,
      collaborators,
      isContainMasterSample,
      exclusivePrice,
      // nonExclusivePrice,
      pitchingPeriod,
      isMasterSample,
      agreePolicy,
      agreepublishingFee,
    } = watch();

    const payload = {
      opportunitiesId,
      createdBy,
      title,
      tone,
      file,
      isCollabrator: collaboratorOnLoop === "yes",
      publishingSplit: mySplit,
      collaborators,
      isContainMasterSample,
      exclusivePrice,
      // nonExclusivePrice,
      pitchingPeriod,
      isMasterSample,
      agreePolicy,
      agreepublishingFee,
    };

    console.info("payload=>", payload);
    // const res = await addSubmission(payload);

    try {
      setisLoading(true);
      const res = await addSubmission(payload);
      if (res?.response?.status === 405) {
        setPaymentOpen(true);
      }
      if (res?.msg == "Record has been Added") {
        Notification("success", "Beat Successfully Submitted");
        navigate("/my-submissions");
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      Notification("error", errorMessage || "error while submitting the beat");
    } finally {
      setisLoading(false);
    }

    // const result = await saveSubmission(payload);
    // console.log("🚀 ~ onSubmit ~ result:", result);

    // if (result) {
    //   setTimeout(() => {
    //     navigate("/my-submissions");
    //   }, 3000);
    // }

    // console.info("getValues", watch());
  };

  const addCollaborators = () => {
    if (collaboratorOnLoop === "yes") {
      const { collaborators = [] } = getValues();
      const newCollaborators = [...collaborators];

      newCollaborators.push(collaborator);
      setValue("collaborators", newCollaborators);
    }
  };

  const removeCollaborator = (index) => {
    const { collaborators = [] } = getValues();
    const newCollaborators = [...collaborators];

    newCollaborators.splice(index, 1);
    setValue("collaborators", newCollaborators);
  };

  const renderRemainingDaysCount = (date) => {
    if (date) {
      const days = remainngTimeCount(data?.expireDate).days;
      return `${days} ${days <= 1 ? `day` : `days`} remaining`;
    } else {
      return ``;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.top_info}>
        <PageNavigationControls onBack={() => navigate("/opportunities")} />
        <p className={styles.page_title}>{data?.title}</p>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`${styles.account_info_container} ${styles.topbar}`}>
            <div className={styles.left}>
              <div className={styles.coverImg}>
                <img
                  src={
                    data?.coverPhoto?.url || "/images/Image_not_available.png"
                  }
                />
              </div>
              <div className={styles.titlemeta}>
                <div>
                  <h2>{data?.title}</h2>
                  <p>{data?.description}</p>
                </div>
                <div className={styles.expText}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="24"
                    viewBox="0 0 28 24"
                    fill="none"
                  >
                    <path
                      d="M25.4537 11.998C25.4537 13.9759 24.775 15.9093 23.5034 17.5537C22.2319 19.1982 20.4246 20.48 18.3101 21.2368C16.1955 21.9937 13.8688 22.1918 11.624 21.8059C9.37927 21.42 7.31733 20.4676 5.69895 19.0691C4.08057 17.6706 2.97844 15.8888 2.53193 13.9489C2.08542 12.0091 2.31458 9.99847 3.19045 8.17121C4.06631 6.34395 5.54953 4.78216 7.45254 3.68335C9.35555 2.58454 11.5929 1.99805 13.8816 1.99805C16.9507 1.99805 19.8941 3.05162 22.0643 4.92698C24.2345 6.80234 25.4537 9.34588 25.4537 11.998Z"
                      stroke="#2EBB55"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.1784 15.1783L14.5911 13.3283C14.2606 13.1373 13.9862 12.8824 13.7893 12.5839C13.5925 12.2854 13.4787 11.9514 13.457 11.6083V7.5083"
                      stroke="#2EBB55"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {renderRemainingDaysCount(data?.expireDate)}
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.drag_track}>
                {!fromLibrary ? (
                  isUploading ? (
                    <div className={styles.image_loading} >
                      <svg viewBox="25 25 50 50" className={styles.auth_loader}>
                        <circle r="20" cy="50" cx="50"></circle>
                      </svg>
                    </div>
                  ) : !file?.url ? (
                    <>
                      <div {...getRootProps()} className={styles.audioDropzone}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M43.4397 12.5604V14.9004C43.4786 15.7875 43.3025 16.6709 42.9264 17.4753C42.5503 18.2798 41.9854 18.9814 41.2797 19.5204C40.4008 20.1513 39.3414 20.4811 38.2597 20.4604C37.5589 20.4631 36.8625 20.3482 36.1997 20.1204L25.4397 16.5404V36.0004C25.4512 38.1998 24.7032 40.3358 23.322 42.0475C21.9408 43.7591 20.0111 44.9416 17.859 45.3951C15.7068 45.8486 13.464 45.5454 11.5096 44.5365C9.55513 43.5277 8.0088 41.8752 7.13183 39.8582C6.25485 37.8411 6.10099 35.5832 6.69622 33.4658C7.29145 31.3485 8.5993 29.5014 10.3988 28.2368C12.1983 26.9722 14.3792 26.3675 16.573 26.5248C18.7668 26.6821 20.8391 27.5919 22.4397 29.1004V8.00039C22.4088 7.11234 22.5906 6.22977 22.9697 5.42616C23.3489 4.62255 23.9146 3.92116 24.6197 3.38039C25.3565 2.88942 26.1987 2.5792 27.0779 2.47495C27.9571 2.37069 28.8486 2.47534 29.6797 2.78039L38.5197 5.74039C39.9156 6.27343 41.1251 7.20308 41.9993 8.41486C42.8735 9.62664 43.3742 11.0676 43.4397 12.5604Z"
                            fill="#AFAFAF"
                          />
                        </svg>
                        <p className={styles.drag_track_text}>
                          Drag or Upload Track
                        </p>
                        <div className={styles.drag_track_button}>
                          <p className={styles.drag_track_button_text}>
                            Choose File
                          </p>
                        </div>
                        <input {...getInputProps()} id="uploadAudio" />
                        {/* {
                        isDragActive ?
                          <p>Drop the files here ...</p> :
                          <p>Drag 'n' drop some files here, or click to select files</p>
                      } */}

                        {get(errors, `file.message`, "") && (
                          <span style={{ color: "red" }}>
                            {get(errors, `file.message`, "")}
                          </span>
                        )}
                      </div>
                    </>
                  ) : (
                    <Box width={{ lg: "48%", base: "100%" }}>
                      <Displayfile
                        fileName={file?.name}
                        file={file?.url}
                        setDeletePopup={(data) => setDeletePopup(data)}
                        setDeleteName={() => setDeleteType("audio")}
                        desc={"Are you sure you want to delete?"}
                      />
                    </Box>
                  )) : (
                  <Box
                    sx={{ width: "100%" }}
                  >
                    <LibraryDisplayFile
                      fileName={`${selectedList.length} File are successfully uploaded`}
                      file={file?.url}
                      setDeletePopup={(data) => setDeletePopup(data)}
                      setDeleteName={() => setDeleteType("fromLibrary")}
                      setFromLibrary={setFromLibrary}
                      setSelectedList={setSelectedList}
                      desc={"Are you sure you want to delete?"}
                    />
                  </Box>
                )}
              </div>
            </div>
          </div>
          <div className={styles.account_info_container}>
            <Controller
              control={control}
              name="title"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => {
                return (
                  <FormField
                    error={get(formState, `errors[${name}].message`, "")}
                    title="File Name"
                    content={value}
                    onValueChange={(value) =>
                      setValue(name, value, { shouldValidate: true })
                    }
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="mySplit"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => {
                return (
                  <FormField
                    error={get(formState, `errors[${name}].message`, "")}
                    title={
                      <span>
                        Add your <b>publishing</b> split %{" "}
                      </span>
                    }
                    info="Music Producers are entitled to 50% of publishing"
                    content={value}
                    type="number"
                    onValueChange={(value) =>
                      setValue(name, value, { shouldValidate: true })
                    }
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="tone"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Tone/Vibe"
                  isMulti={true}
                  content={value}
                  dropdown={true}
                  dropDownData={TUNEVIBES_DATA}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              )}
            />

            {/* <BlueButtonComponents text="Upload from My Library" onClickFunction={() => openAudioModal()} /> */}
          </div>
          <LineDivider />
          <div className={styles.btnWrapper}>
            <div>
              <label className={styles.label}>
                Are there any collaborators on this audio file?
              </label>
              <Controller
                control={control}
                name="collaboratorOnLoop"
                render={({
                  field: { name, onBlur, onChange, value },
                  formState,
                }) => {
                  return (
                    <>
                      <RadioGroup
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <RadioButtonWithLabel value="yes" label="Yes" />
                        <RadioButtonWithLabel value="no" label="No" />
                      </RadioGroup>
                      {get(formState, `errors[${name}].message`, "") && (
                        <span style={{ color: "red" }}>
                          {get(formState, `errors[${name}].message`, "")}
                        </span>
                      )}
                    </>
                  );
                }}
              />
            </div>
            <div>
              <button
                className={styles.addCollaboratorsBtn}
                type="button"
                onClick={addCollaborators}
                disabled={collaboratorOnLoop === "no"}
              >
                <div>
                  <span className={styles.label}>Add Collabrator</span>
                </div>
                <div className={styles.addIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                  >
                    <rect x="13" width="1" height="27" fill="#D9D9D9" />
                    <rect
                      y="14"
                      width="1"
                      height="27"
                      transform="rotate(-90 0 14)"
                      fill="#D9D9D9"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          {/* collaborators detail */}
          <span style={{ color: "red" }}>
            {get(errors, `collaborators.message`, "")}
          </span>
          <div>
            {collaboratorOnLoop === "yes" &&
              collaborators.map((collaborator, index) => (
                <div key={`loopZ-${index}`} className={styles.collaborator_container}>
                  <div
                    className={styles.deleteBtn}
                    onClick={() => removeCollaborator(index)}
                  >
                    <CloseIcon style={{ color: "#AFAFAF" }} />
                  </div>

                  <Controller
                    control={control}
                    name={`collaborators[${index}].name`}
                    render={({
                      field: { name, onBlur, onChange, value },
                      formState,
                    }) => (
                      <FormField
                        error={get(formState, `errors[${name}].message`, "")}
                        name={name}
                        title="Collaborator's Name"
                        content={collaborator.name}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`collaborators[${index}].email`}
                    render={({
                      field: { name, onBlur, onChange, value },
                      formState,
                    }) => (
                      <FormField
                        error={get(formState, `errors[${name}].message`, "")}
                        title="Email"
                        content={collaborator.email}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`collaborators[${index}].publishingPercentage`}
                    render={({
                      field: { name, onBlur, onChange, value },
                      formState,
                    }) => (
                      <FormField
                        error={get(formState, `errors[${name}].message`, "")}
                        title="Publishing %"
                        type="number"
                        info="You cannot go above 40%"
                        content={collaborator.title}
                        onValueChange={(value) =>
                          setValue(name, parseInt(value), {
                            shouldValidate: true,
                            shouldTouch: true,
                          })
                        }
                      />
                    )}
                  />
                </div>
              ))}
          </div>
          <LineDivider />

          <div className={styles.account_info_container}>
            <Controller
              control={control}
              name="exclusivePrice"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => (
                <FormField
                  preFix="$"
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Beat Exclusive Price"
                  content={value}
                  type="number"
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              )}
            />

            <Controller
              control={control}
              name="pitchingPeriod"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Beat Pitching Period"
                  content={value}
                  info="Beat Pitching Period is the time that you give the artist(s) to review your audio file."
                  dropdown={true}
                  dropDownData={PITCHING_PERIOD.map((o) => o.value)}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              )}
            />
          </div>
          <LineDivider />
          <div>
            <label className={styles.label}>
              Does this contain a master sample?
            </label>
            <Controller
              control={control}
              name="isMasterSample"
              render={({
                field: { name, onBlur, onChange, value },
                formState,
              }) => {
                return (
                  <>
                    <RadioGroup
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <RadioButtonWithLabel value={"true"} label="Yes" />
                      <RadioButtonWithLabel value={"false"} label="No" />
                    </RadioGroup>
                    {get(formState, `errors[${name}].message`, "") && (
                      <span style={{ color: "red" }}>
                        {get(formState, `errors[${name}].message`, "")}
                      </span>
                    )}
                  </>
                );
              }}
            />
          </div>
          <LineDivider />
          <div className={styles.united_market_cut}>
            <p className={styles.united_market_cut_text}>
              United Market 10%{"  "}
              <DarkTooltip title={PUBLISHING_FEE_TOOLTIP} placement="top">
                <IconButton variant="contained" size="small">
                  <img src={allImages.info} />
                </IconButton>
              </DarkTooltip>
            </p>
            {/* <div className={styles.united_market_cut_learn_more}>
              <div className={styles.green_opacity_more_box_bg_center}>
                <p className={styles.united_market_cut_learn_more_text}>
                  Learn More
                </p>
              </div>
            </div> */}
          </div>
          <div>
            <div style={{ margin: "20px 0" }}>
              <Controller
                control={control}
                name="agreePolicy"
                render={({
                  field: { name, onBlur, onChange, value },
                  formState,
                }) => {
                  return (
                    <>
                      <CheckboxWithInfoIcon
                        title="By submitting for this United Market opportunity, you must opt into our honesty & transparency policy"
                        value={value}
                        handler={(data) => {
                          setValue(name, data.payload, {
                            shouldValidate: true,
                          });
                        }}
                        action="Add_OptIn"
                      />
                      {get(formState, `errors[${name}].message`, "") && (
                        <span style={{ color: "red" }}>
                          {get(formState, `errors[${name}].message`, "")}
                        </span>
                      )}
                    </>
                  );
                }}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="agreepublishingFee"
                render={({
                  field: { name, onBlur, onChange, value },
                  formState,
                }) => {
                  return (
                    <>
                      <CheckboxWithInfoIcon
                        title="By submitting I agree to United Market's 10% publishing fee for placing the song"
                        value={agreepublishingFee}
                        handler={(data) => {
                          setValue(name, data.payload, {
                            shouldValidate: true,
                          });
                        }}
                        action="Add_OptIn"
                      />
                      {get(formState, `errors[${name}].message`, "") && (
                        <span style={{ color: "red" }}>
                          {get(formState, `errors[${name}].message`, "")}
                        </span>
                      )}
                    </>
                  );
                }}
              />
            </div>
          </div>
          <div className={styles.form_buttons} style={{ marginTop: "30px" }}>
            <div
              className={styles.cancel_button}
              onClick={() => navigate("/opportunities")}
            >
              <p className={styles.button_text}>Cancel</p>
            </div>
            <div className={styles.submit_button}>
              <button className={styles.button_text} type="submit">
                {isLoading ? "Submitting.." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>

      <DeleteSoundPack
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        deleteFtn={() => {
          if (deleteType == "fromLibrary") {
            setFromLibrary(false);
            setSelectedList([]);
          } else {
            setValue("file", null, { shouldValidate: true });
          }
        }}
        desc={"Are you sure you want to delete?"}
      />
      <MultiModal
        open={PaymentOpen}
        heading="Upgrade Your Plan"
        text="You have exceeded your limit of submissions for this month, upgrade your membership to submit to unlimited opportunities."
        icon={crown}
        type="link"
        title="Upgrade"
        RedirectTo="/beat-inbox/more-storage"
        setPaymentOpen={setPaymentOpen}
      />

      <AudioModal
        openModal={openAudio}
        setOpenModal={setOpenAudio}
        fromLibrary={fromLibrary}
        setFromLibrary={setFromLibrary}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
      />
    </div>
  );
};

export default SubmitOpportunity;

const LineDivider = () => {
  return <div className={styles.line_divider}></div>;
};

function Displayfile(props) {
  const { fileName, setDeletePopup, setDeleteName, type, file } = props;
  return type === "image" ? (
    <Box w={"100%"}>
      <Box alignSelf={"center"} my={8}>
        <img src={file} alt="file" className={styles.song_file_image}></img>
        <div
          className={styles.delete_button}
          onClick={() => {
            setDeletePopup(true);
            setDeleteName(fileName);
          }}
        >
          Delete
        </div>
      </Box>
    </Box>
  ) : (
    <div className={styles.file_display}>
      <div className={styles.file_display_top}>
        <div className={styles.file_info}>
          <img
            src="/v2Assets/submission_form_assets/music_file.png"
            alt="file"
            className={styles.song_file_image}
          ></img>
          <div>
            <p className={styles.file_title}>{fileName}</p>
          </div>
        </div>
      </div>
      <div className={styles.file_display_bottom}>
        <div
          className={styles.delete_button}
          onClick={() => {
            setDeletePopup(true);
            setDeleteName(fileName);
          }}
        >
          Delete
        </div>
        <AudioPlayer src={file} />
      </div>
    </div>
  );
}

function LibraryDisplayFile(props) {
  const { fileName, setDeletePopup, setDeleteName, type, file } = props;
  return (
    <div className={styles.audio_file_display_top}>
      <div className={styles.audio_file_display_top}>
        <div className={styles.audio_file_info}>
          <img
            src="/v2Assets/submission_form_assets/music_file.png"
            alt="file"
            className={styles.song_file_image}
          ></img>
          <div>
            <p className={styles.audio_file_title}>{fileName}</p>
          </div>
        </div>
      </div>
      <div className={styles.file_display_bottom}>
        <AudioPlayer src={file} />
        <div
          className={styles.delete_button}
          style={{ color: "red" }}
          onClick={() => {
            setDeletePopup(true);
            setDeleteName(fileName);
          }}
        >
          Remove
        </div>

      </div>
    </div>
  );
}

const FormField = ({
  title,
  preFix = "",
  error = "",
  name,
  info = "",
  content,
  dropdown = false,
  dropDownData,
  onValueChange,
  type = "text",
  isMulti = false,
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleOptionClick = (option) => {
    if (isMulti) {
      const _content = [...content];
      _content.push(option);

      onValueChange([...new Set(_content)]);
    } else {
      onValueChange(option);
    }
    toggleDropdown();
  };

  const handleInputChange = (event) => {
    onValueChange(event.target.value);
  };

  const removeOption = (option) => {
    const _content = [...content];
    const index = _content.indexOf(option);

    if (index >= 0) {
      _content.splice(index, 1);
      onValueChange(_content);
    }
  };

  return (
    <div className={styles.user_info_layout}>
      <div className={styles.field_info}>
        <p className={styles.user_info_title}>{title}</p>
        {info && (
          <Tooltip title={info} arrow>
            <img
              src="/v2Assets/submission_form_assets/moreInfoIcon.png"
              alt="info"
              className={styles.infoImage}
            />
          </Tooltip>
        )}
      </div>
      <div className={styles.input_box}>
        {preFix && <span className={styles.preFixText}>{preFix}</span>}
        {isMulti ? (
          <div className={styles.chipWrapper}>
            {content.map((o) => (
              <div className={styles.chips}>
                <span>{o}</span>{" "}
                <img src={allImages.closeBtn} onClick={() => removeOption(o)} />{" "}
              </div>
            ))}
          </div>
        ) : (
          <input
            name={name}
            type={type}
            className={styles.input_box_text}
            value={content}
            // disabled={dropdown}
            onClick={() => {
              dropdown && toggleDropdown();
            }}
            onChange={(e) => !dropdown && handleInputChange(e)}
          />
        )}
        {dropdown && (
          <div
            className={styles.input_box_dropdown_button}
            onClick={toggleDropdown}
          >
            <div className={styles.dropdown_green_bg}>
              <img
                src="/v2Assets/submission_form_assets/DropDownArrow.png"
                alt="options"
                className={styles.drop_down_image}
              />
              {isDropdownVisible && (
                <div className={styles.dropdown_menu}>
                  {dropDownData?.map((option, index) => {
                    if (
                      typeof content === "object" &&
                      content.includes(option)
                    ) {
                      return;
                    }
                    return (
                      <div
                        key={`dropdownB-${index}`}
                        className={styles.dropdown_menu_option_box}
                        onClick={() => handleOptionClick(option)}
                      >
                        <p className={styles.dropdown_menu_option}>{option}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {error && <span style={{ color: "red" }}>{error}</span>}
    </div>
  );
};
