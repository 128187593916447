import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "../mycontracts.module.css";
import Dropzone, { useDropzone } from "react-dropzone";
import MaskGroupIcon from "../../../assets/Mask-group.png";
import PdfIcon from "../../../assets/pdfIcon.png";

//import { DropzoneAreaBase } from "@material-ui-dropzone";

function LicenseDropZone() {
  const [picture, setPicture] = useState("");

  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      console.log(currentFile);
      const currentFileName = currentFile.name;

      if (!currentFileName.match(/.(jpg|jpeg|png|gif|svg|pdf|docx|doc)$/i)) {
        console.log("not accepted");
        return false;
      }
      return true;
    }
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleOnDropLogoImage = async (files, rejectedFiles) => {
    if (!verifyImageFile(files)) return;
    if (files && files[0]) {
      let reader = new FileReader();
      reader.onloadend = function () {
        setPicture({
          filename: files[0].name,
          filetype: files[0].type,
          fileimage: reader.result,
          datetime: files[0].lastModifiedDate.toLocaleString("en-IN"),
          filesize: filesizes(files[0].size),
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <Dropzone
      onDrop={handleOnDropLogoImage}
      onFileDialogCancel={() => setPicture("")}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={styles.imageDropzone}>
          {picture ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {picture.filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                <div className={styles.license_file_image}>
                  <img src={picture.fileimage} />
                </div>
              ) : (
                <div className={styles.license_file_image}>
                  <img src={PdfIcon} />
                </div>
              )}
              <div className={styles.license_file_detail}>
                <h6>Your license is uploaded successfully</h6>
                <p>
                  <span>Size : {picture.filesize}</span>{" "}
                </p>
              </div>
            </div>
          ) : (
            <>
              <img
                className={styles.profile_pic_upload_image}
                src={MaskGroupIcon}
                alt="DefaultProfile"
              />
              <p className={styles.license_drop_zone_text}>
                Drag & Drop your License to attach with your beats
              </p>
            </>
          )}

          <input {...getInputProps()} />
        </div>
      )}
    </Dropzone>
  );
}
export default LicenseDropZone;
