import React from "react";

const ShareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M13.4491 2.70667L5.92412 5.20667C0.865788 6.89834 0.865788 9.65667 5.92412 11.34L8.15745 12.0817L8.89912 14.315C10.5825 19.3733 13.3491 19.3733 15.0325 14.315L17.5408 6.79834C18.6575 3.42084 16.8241 1.57917 13.4491 2.70667ZM13.7158 7.19L10.5475 10.3733C10.4895 10.4315 10.4207 10.4776 10.3449 10.5091C10.2691 10.5406 10.1879 10.5568 10.1058 10.5568C10.0237 10.5568 9.94245 10.5406 9.86666 10.5091C9.79086 10.4776 9.72204 10.4315 9.66412 10.3733C9.54789 10.2557 9.4827 10.097 9.4827 9.93167C9.4827 9.76631 9.54789 9.60762 9.66412 9.49L12.8308 6.30667C12.9493 6.19627 13.106 6.13617 13.2679 6.13902C13.4298 6.14188 13.5843 6.20747 13.6988 6.32198C13.8133 6.43649 13.8789 6.59098 13.8818 6.7529C13.8846 6.91482 13.8245 7.07152 13.7141 7.19H13.7158Z"
        fill="#AFAFAF"
      />
    </svg>
  );
};

export default ShareIcon;
