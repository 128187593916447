/* eslint-disable require-jsdoc */
import React from "react";
import { Button } from "@material-ui/core";
import styles from "../Opportunities/Opportunities.module.css";

function OutlineButton(props) {
  const { title, handler, filter } = props;
  return (
    <Button
      variant="outlined"
      className={`${styles.outline_btn} ${filter && styles.filterActiveBtn}`}
      onClick={handler}
    >
      {title}
    </Button>
  );
}

export default OutlineButton;
