import React, { useContext } from "react";
import { Box, Link } from "native-base";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AuthContext } from "../Layout";
import footerLogo from "../../../assets/footers/logo.png";
const Sidebar = ({ handleNext, handleBack }) => {
  const { progressbarvalue } = useContext(AuthContext);
  const progressBarvalue = (progressbarvalue * 100) / 10;

  return (
    <div className="innersignup">
      <Box
        position={"absolute"}
        top={"50%"}
        left={"50%"}
        mt={"-150px"}
        ml={"-150px"}
        // style={{ transform: "translate(-45%)" }}
      >
        <Box p={1} borderColor="#1C1B1B" borderWidth={4} borderRadius="50%">
          <Box width={"305px"} height={"305px"}>
            <CircularProgressbar
              strokeWidth={2}
              value={progressBarvalue}
              styles={{ background: "transparent" }}
              text={`${progressBarvalue}%`}
              backgroundPadding={2}
              counterClockwise={true}
            />
          </Box>
        </Box>

        {/* <StepperForm handleBack={handleBack} handleNext={handleNext} /> */}
      </Box>
      <Box>
        {/* <Image
          position={"absolute"}
          width={"100%"}
          height={"100%"}
          top={0}
          // mb={5}
          // mx={{ base: 2 }}
          // size={{ lg: "full", md: "full", base: "full" }}
          resizeMode="stretch"
          resizeMethod="scale"
          source={require("../..//..//assets/LoginScreens/signupbg.png")}
        /> */}

        <Box
          // p={{ lg: 8, md: 8 }}
          // px={{ lg: 8, md: 4 }}
          // py={0}
          // marginTop={"70px"}

          m={8}
        >
          {/* <HStack w='150px' height='150px'> */}
          <Link href="/">
            <img
              className="logoHeader"
              // mb={5}
              // ml={{ md: '50px', base: '20px' }}
              // size={{ lg: 'xl', md: 'lg', base: 'sm' }}

              // resizeMode='contain'
              src={footerLogo}
            />
          </Link>
          {/* </HStack> */}
        </Box>
      </Box>
    </div>
    // </div>
  );
};

export default Sidebar;
