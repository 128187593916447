import React from "react";
import ProducersLayouts from "./ProducersLayouts";
import SignInPopup from "../../../../components/smartlink/producers/auths/SignInPopup";

const ProducersLoginPage = () => {
  return (
    <ProducersLayouts>
      <SignInPopup />
    </ProducersLayouts>
  );
};

export default ProducersLoginPage;
