import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const SocialMain = styled(Box)(({ theme }) => ({
  "& .Social_item:first-child": {
    paddingTop: "0px",
  },
}));
const SocialItem = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #414241",
  padding: "20px 0px",
}));
const Socialleft = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "30px",
  img: {
    width: "60px",
    height: "60px",
  },
  h6: {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    margin: "0px",
    lineHeight: "normal",
    textTransform: "capitalize",
  },
  [theme.breakpoints.down("sm")]: {
    gap: "16px",
    img: {
      height: "35px",
      width: "35px",
    },
    h6: {
      fontSize: "16px",
    },
  },
}));
const SocialRight = styled(Box)(({ theme }) => ({
  button: {
    padding: "10.5px 30px",
    borderRadius: "5px",
    border: "1px solid #2EBB55",
    color: "#2EBB55",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
    "&.connected": {
      background: "#2EBB55",
      color: "#fff",
    },
  },
  [theme.breakpoints.down("sm")]: {
    button: {
      padding: "5px 10px",
    },
  },
}));
const ModelContentBox = styled(Box)(({ theme }) => ({
  "& .popup_header": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .social_logo": {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    img: {
      width: "45px",
      height: "45px",
    },
  },
  "& .social_info": {
    textAlign: "center",
    "& h6": {
      fontFamily: theme.typography.fontFamily,
      color: "#E3E3E3",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      marginTop: "10px",
      marginBottom: "5px",
      textAlign: "center",
    },
    "& p": {
      fontFamily: theme.typography.fontFamily,
      color: "#AFAFAF",
      textAlign: "center",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      marginTop: "5px",
      marginBottom: "5px",
    },
    button: {
      fontFamily: theme.typography.fontFamily,
      marginTop: "8px",
      textAlign: "center",
      borderRadius: "5px",
      background: "#2EBB55",
      color: "#FFF",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      border: "none",
      padding: "10.5px 30px",
      textTransform: "capitalize",
      "&:disabled": {
        background: "gray",
      },
    },
  },
}));
const PaymentModelContentBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "& .green-logo": {
    width: "59px",
    height: "57px",
  },
  "& .progress-box": {
    width: "100%",
    marginTop: "30px",
  },
  h3: {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  "& .card-main": {
    width: "100%",
    display: "flex",
    gap: "30px",
  },
  "& .producerpaymentcard-main": {
    display: "flex",
    gap: "30px",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    "& .producerpaymentcard-main": {
      paddingBottom: "20px",
      gap: "20px",
      flexDirection: "column",
      overflowY: "scroll",
      overflowX: "hidden",
      maxHeight: "500px",
      "&::-webkit-scrollbar": {
        width: "12px",
        display: "none",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
    },
    h3: {
      fontSize: "20px",
    },
    "& .progress-box": {
      marginTop: "10px",
    },
  },
}));
const Modelstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "background.paper",
  //   boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export {
  SocialMain,
  SocialItem,
  Socialleft,
  SocialRight,
  ModelContentBox,
  Modelstyle,
  PaymentModelContentBox,
};
