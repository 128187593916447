import React from "react";

import loaderSvg from "./loader.svg";
import styles from "./styles.module.css";

export const MrLoader = () => {
  return <img src={loaderSvg} alt="loader" className={styles.loader} />;
};

export const MrBtnLoader = () => {
  return (
    <img
      src={loaderSvg}
      alt="loader"
      className={styles.loader}
      style={{ width: "20px", color: "#fff" }}
    />
  );
};
