import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  withStyles,
} from "@material-ui/core";
import { green } from "@mui/material/colors";
const GreenCheckbox = withStyles({
  root: {
    color: "#AFAFAF",
    "&$checked": {
      color: green[400],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function createData(id ,name, contracttype, preference, action) {
  return { id ,name, contracttype, preference, action };
}

const rows = [
  createData(
    1,
    "Premium License",
    "Non-Exclusive License/This type of license allows the purchaser to use the beat while permitting the producer to sell the same beat to other artists.",
    "Automatically upload your clients name into agreement",
    ""
  ),
  createData(
    2,
    "Premium License",
    "Non-Exclusive License/This type of license allows the purchaser to use the beat while permitting the producer to sell the same beat to other artists.",
    "Automatically upload your clients name into agreement",
    ""
  ),
  createData(
    3,
    "Premium License",
    "Non-Exclusive License/This type of license allows the purchaser to use the beat while permitting the producer to sell the same beat to other artists.",
    "Automatically upload your clients name into agreement",
    ""
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", numeric: true, disablePadding: true, label: "ID" },
  { id: "name", numeric: false, disablePadding: true, label: "License" },
  {
    id: "contracttype",
    numeric: false,
    disablePadding: false,
    label: "Contract Type",
  },
  {
    id: "preference",
    numeric: false,
    disablePadding: false,
    label: "Preference",
  },
  { id: "action", numeric: false, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.id === "id") return null;
          return (
            <TableCell
              key={headCell.id}
              align={"left"}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.tableCell}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                classes={{
                  active: classes.tablesortlabelactive,
                  icon: classes.tablesortLabelIcon,
                }}
                style={{
                  color: "white",
                  fontSize: 18,
                  fontWeight: 500,
                  lineHeight: 1.5,
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
    backgroundColor: "#141515",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
    backgroundColor: "white",
  },
  tableCell: {
    color: "#E3E3E3",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    border: "none",
    borderBottom: '1px solid #424142'
  },
  tableContainer: {
    border: "none",
  },
  tablesortLabelIcon: {
    color: "white !important",
  },
  tablesortlabelactive: {
    color: "white",
  },
  useButton: {
    backgroundColor: "#2EBB55",
    fontSize: 16,
    lineHeight: 1.5,
    border: "none",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  contractTypeCell: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    color: "#E3E3E3",
  },
  contractTypeCell2: {
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.5,
    color: "#E3E3E3",
  },
  preferenceCell: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#AFAFAF",
  },
}));

export default function LicenseTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.creator);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.creator)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.index}
                    >
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                        style={{ fontSize: 16 }}
                      >
                        <p className={classes.contractTypeCell}>{row.name}</p>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                        style={{ width: "40%" }}
                      >
                        <p className={classes.contractTypeCell}>
                          {row.contracttype.split("/")[0]}
                        </p>
                        <p className={classes.contractTypeCell2}>
                          {row.contracttype.split("/")[1]}
                        </p>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                        style={{ width: "30%" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <GreenCheckbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={(event) =>
                              handleClick(event, row.id)
                            }
                          />
                          <p className={classes.preferenceCell}>
                            {row.preference}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <button className={classes.useButton}>Use</button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
