import React from "react";

const RestartIcon = ({ stroke = "#AFAFAF" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.11013 5.08039C10.0473 4.79661 11.021 4.65173 12.0001 4.65039C13.9311 4.65196 15.8063 5.29864 17.3277 6.48774C18.8492 7.67684 19.9297 9.34018 20.3978 11.2136C20.8658 13.087 20.6945 15.0631 19.9111 16.8281C19.1277 18.593 17.777 20.0456 16.0736 20.9552C14.3703 21.8647 12.4118 22.1791 10.5094 21.8484C8.6069 21.5177 6.86947 20.5608 5.57299 19.1297C4.27651 17.6987 3.49532 15.8755 3.35349 13.9497C3.21165 12.0239 3.7173 10.1059 4.79013 8.50039"
        stroke={`${stroke}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87012 5.32L10.7601 2"
        stroke={`${stroke}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87012 5.32031L11.2401 7.78031"
        stroke={`${stroke}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RestartIcon;
