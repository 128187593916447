import React from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles.module.css";

const useStyles = makeStyles({
  root: {
    color: "#AFAFAF",
    alignSelf: "flex-start",
    "&.Mui-checked": {
      "&, & + .MuiFormControlLabel-label p": {
        color: "#2EBB55 !important",
      },
    },
    "& .MuiFormControlLabel-label p": {
      color: "#AFAFAF",
    },
  },
  checked: {},
});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export default function RadioButtonWithLabel(props) {
  const { value, label, disabled } = props;
  const classes = useStyles();
  return (
    <FormControlLabel
      disabled={disabled}
      value={value}
      control={
        <Radio classes={{ root: classes.root, checked: classes.checked }} />
      }
      label={
        <p className={styles.radio_text}>{capitalizeFirstLetter(label)}</p>
      }
    />
  );
}
