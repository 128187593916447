import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ModelContentBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "& .header-icon-box": {
    textAlign: "right",

    "& .close-icon": {
      width: "30px",
      height: "30px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
  background: "red",
  "& .img-box": {
    marginBottom: "30px",
  },
  "& h5": {
    color: "#E3E3E3",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    padding: "0px 40px",
    textTransform: "capitalize",
    marginBottom: "7px",
  },
  "& h6": {
    color: "#AFAFAF",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    padding: "0px 50px",
    marginBottom: "30px",
  },
  "& .btn": {
    padding: "10.5px 30px",
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    background: "#2EBB55",
    textTransform: "capitalize",
  },
}));
export { ModelContentBox };
