import React, { useRef, useState, memo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { ModelContentBox, Modelstyle } from "./styles/style";
import { close_icon } from "../../../../assets/smartlink";
import InputField from "../../common/inputfield/inputField";

import CircularProgress from "@mui/material/CircularProgress";
import { Notification } from "../../../NotifyMsg/Notification";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const InboxPasswordPopup = ({
  Hasopen = false,
  HassetOpen = () => {},
  Setgetpassword = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [usertype, setUserType] = useState(null);

  const CreateModelClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    HassetOpen(!Hasopen);
  };

  const handleSubmit = async (value, { resetForm }) => {
    console.log(
      "🚀 ~ file: InboxPasswordPopup.js:35 ~ handleSubmit ~ value:",
      value
    );
    Setgetpassword(value.password);
    resetForm();
    HassetOpen(!Hasopen);
  };

  return (
    <>
      <Modal
        open={Hasopen}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...Modelstyle,
            width: { xs: "100%", sm: "500px" },
            display: "flex",
            alignItems: "center",
            padding: "3rem",
            borderRadius: "0.625rem",
            background: "#141515",
            overflow: "hidden",
            height: "auto",
          }}
        >
          <Box sx={{ textAlign: "right", width: "100%" }}>
            <IconButton onClick={CreateModelClose} aria-label="settings">
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>
          <Box className="heading-box">
            <Typography variant="h3">Create Inbox Password</Typography>
          </Box>

          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, setFieldValue, errors }) => (
              <Form>
                <Box className="input-box">
                  <Field name="password">
                    {({ field, form }) => (
                      <Box
                        className={`${
                          form.errors.password ? "error-input" : ""
                        }`}
                      >
                        <InputField
                          label="Password"
                          name="password"
                          type="password"
                          field={field}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error"
                        />
                      </Box>
                    )}
                  </Field>
                  <Field name="confirmPassword">
                    {({ field, form }) => (
                      <Box
                        className={`${
                          form.errors.confirmPassword ? "error-input" : ""
                        }`}
                      >
                        <InputField
                          label="Confirm Password"
                          name="confirmPassword"
                          type="password"
                          field={field}
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="error"
                        />
                      </Box>
                    )}
                  </Field>
                </Box>

                <Box className="btn-box">
                  <Button
                    variant="outline"
                    className="cancel-btn"
                    onClick={CreateModelClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="create-btn"
                    type="submit"
                    disabled={isLoading}
                    sx={{
                      "&:disabled": {
                        background: "gray !important",
                        color: "#fff !important",
                      },
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: "#fff",
                        }}
                      />
                    ) : (
                      "Create"
                    )}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(InboxPasswordPopup);
