import React, { useState, memo, useRef, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "lodash";
import { Box, IconButton, Modal } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import LibrariesSchema from "../../YupSchema/LibrariesSchema";
import { ModelContentBox, Modelstyle } from "../styles/style";
import { close_icon, UncheckIcon, CheckIcon } from "../../../../assets/smartlink";
// import DescriptionArea from "../../common/inputfield/TextAreaField";
import OutlineButton from "../../AdditionalComponents/OutlineButton";
import UMCheckbox from "../UMCheckbox";
import FormField from "./FormField";
import { KEY, SCALE, TAGS } from "../../../../shared/constants";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import modalStyles from "../Libraries.module.css";
import EditableFormikText from "../EditableFormikText";
import { SearchTagsHorizontal, SearchTagsVertical } from "../../AdditionalComponents/SearchTags";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import styles from "../Libraries.module.css";
import ToggleExpand2 from "../ToggleExpand2";
import { green } from '@mui/material/colors';
import BeatSchema from "../../YupSchema/BeatSchema";
import BlueButtonComponents from "../../AdditionalComponents/utils/BlueButtonComponent";
import { updateAudioFile, getTags } from "../../../../context/apis/audio-library";
import { valuesIn } from "lodash";
import { TurnLeft } from "@mui/icons-material";


const EditPackModal = ({
  open = false,
  setOpen = () => { },
  editData,
  setEditData = () => { },
  onUpdateSelectedList,
  index,
}) => {


  const editModalClose = (event) => {
    setOpen(!open);
  };

  const imageRef = useRef();

  const imageUpload = () => {
    imageRef.current.click(); // Programmatically click the hidden file input
  };

  const [isLoop, setIsLoop] = useState(true);
  const [isBeat, setIsBeat] = useState(false);
  const [imageURL, setImageURL] = useState('');
  const [tagOptions, setTagOptions] = useState([]);

  useEffect(async () => {
    console.log("Confrim Tags in masseditmodal-- ",);

    const response = await getTags();
    const transformedTags = response?.data?.tagValues.map((tag) => ({
      value: tag,
      label: tag
    }));

    setTagOptions(transformedTags);
    console.log("Confrim Tages in masseditmodal-- ", transformedTags);
  }, [open]);

  
  useEffect(() => {
    const url = editData?.basicInfo?.imageURL ?
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${editData?.basicInfo?.imageURL}` : '/images/no_image.png'
    setImageURL(url);
  }, [editData])

  const handleUpdate = async (values) => {
    console.log('values', values);
    const editedData = {
      imageURL: values?.basicInfo?.imageURL,
      fileName: values?.basicInfo?.fileName,
      fileNote: values?.basicInfo?.fileNote,
      tags: values?.basicInfo?.tags,
      bpm: values?.basicInfo?.bpm,
      fileKey: values?.basicInfo?.fileKey,
      scale: values?.basicInfo?.scale,
      isLoop: isLoop,
      exclusivePrice: values?.beatInfo?.exclusivePrice,
      nonExlusivePrice: values?.beatInfo?.nonExclusivePrice,
      isComplete: false
    };
    
    editedData.isComplete = (
      editedData.fileName != "" &&
      editedData.fileNote != "" &&
      editedData.imageURL != "" &&
      editedData.scale != "" &&
      editedData.fileKey != "" &&
      editedData.tags.length > 0 &&
      editedData.bpm != ""
    );

    const response = await updateAudioFile(
      values.basicInfo.file,
      editedData,
      editData.basicInfo.userID,
      editData._id,
    );
    onUpdateSelectedList(response);
    editModalClose();
  };

  const handleLoopCheckChanged = () => {
    setIsLoop(!isLoop);
  };

  const handleBeatCheckChanged = () => {
    setIsBeat(!isBeat);
  };

  const SearchTagInEdit = useCallback(({ field, options, setFieldValue }) => {
    const handleChange = (selectedOptions) => {
      setFieldValue(field.name, selectedOptions);
    };
    return (
      <CreatableSelect
        options={options}
        isMulti
        onChange={handleChange}
        onBlur={field.onBlur}
        classNamePrefix="select_tags"
        components={{ DropdownIndicator: null }}
        value={field.value}
      />
    );
  })

  return (
    <>
      <Modal
        open={open}
        onClose={editModalClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="MainInboxCreate"
          sx={{
            ...Modelstyle,
            width: "600px",
            height: "auto",
            display: "flex",
            padding: "24px",
            flexDirection: "column",
            borderRadius: "0.625rem",
            background: "#141515",
          }}
        >
          <Box
            sx={{ height: "40px", display: "flex", justifyContent: "right" }}
          >
            <IconButton
              onClick={editModalClose}
              aria-label="settings"
              sx={{
                color: "#fff",
                backgroundColor: "none",
                fontSize: "2rem",
                padding: "0 !important",
                margin: "0 !important",
                marginTop: "-7px !important",
              }}
            >
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>
          <Formik
            initialValues={editData}
            enableReinitialize={true}
            onSubmit={handleUpdate}
          // validationSchema={BeatSchema}
          >
            {({ setFieldValue }) => (
              <Form>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: "16px" }}
                  >
                    {/* <Field name="basicInfo.imageURL">
                      {({ field }) => {

                        return (
                          <div className={styles.library_item_big_image_wrap}>
                            <img src={field.value} alt="library_item" width={136} height={136} onClick={imageUpload} />
                          </div>
                        )
                      }}
                    </Field> */}
                    <div className={styles.library_item_big_image_wrap}>
                      <img src={imageURL} alt="library_item" width={136} height={136} onClick={imageUpload} />
                    </div>
                    <input
                      type="file"
                      id="basicInfo.file"
                      style={{ display: "none" }}
                      ref={imageRef}
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        setFieldValue("basicInfo.file", file);
                        setImageURL(URL.createObjectURL(file));
                        setFieldValue(
                          "basicInfo.imageURL",
                          URL.createObjectURL(file),
                        );
                      }}
                    />

                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: "16px",
                    }}
                  >
                    <div
                      style={{
                        color: "#FFFFFF",
                        fontSize: "24px",
                      }}
                    >
                      <Field name="basicInfo.fileName">
                        {({ field }) => (
                          <EditableFormikText
                            field={field}
                            text={field.value}
                            style={modalStyles.edited_title}
                            setFieldValue={setFieldValue}
                            name="basicInfo.fileName"
                          />
                        )}
                      </Field>
                    </div>
                    <div
                      style={{
                        color: "#FFFFFF",
                        fontSize: "16px",
                      }}
                    >
                      <Field name="basicInfo.fileNote">
                        {({ field }) => (
                          <EditableFormikText
                            field={field}
                            text={field.value}
                            style={modalStyles.edited_desc}
                            setFieldValue={setFieldValue}
                            name="basicInfo.fileNote"
                          />
                        )}
                      </Field>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>Tags</label>
                      <Field name="basicInfo.tags">
                        {({ field }) => (
                          <SearchTagInEdit field={field} options={tagOptions} setFieldValue={setFieldValue} />
                        )}
                      </Field>
                    </div>
                    <Field name="basicInfo.bpm">
                      {({ field }) => (
                        <div>
                          <label>BPM</label>
                          <Slider
                            value={field.value}
                            valueLabelDisplay="auto"
                            min={0}
                            max={200}
                            step={1}
                            onChange={(event) => {
                              setFieldValue(field.name, event.target.value);
                            }}
                            sx={{
                              color: "#2EBB55", // Color of the slider track and thumb
                              // To change the color of the labels that appear next to the marks
                              "& .MuiSlider-markLabel": {
                                color: "#afafaf", // Replace 'blue' with any desired color
                              },
                              // To change the color of the popup value label
                              "& .MuiSlider-valueLabel": {
                                color: "white", // Set the text color to white for the popup label
                                // If you wish to change the background color of the popup label:
                                backgroundColor: "green", // Replace 'green' with any desired color
                              },
                            }}
                          />
                          {/* <BPMComponent field={field} setFieldValue={setFieldValue} /> */}
                        </div>
                      )}
                    </Field>
                    <Field name="basicInfo.fileKey">
                      {({ field }) => (
                        <div>
                          <label>Key</label>
                          <div style={{ borderBottom: "1px solid #AFAFAF" }}>
                            <ToggleExpand2 primary={field.value} menu={KEY} field={field} setFieldValue={setFieldValue} />
                          </div>
                        </div>
                      )}
                    </Field>
                    <ErrorMessage
                      name="basicInfo.fileKey"
                      className={modalStyles.error_msg}
                      component="div"
                    />
                    <Field name="basicInfo.scale">
                      {({ field }) => (
                        <div>
                          <label>Scale</label>
                          <div style={{ borderBottom: "1px solid #AFAFAF" }}>
                            <ToggleExpand2 primary={field.value} menu={SCALE} field={field} setFieldValue={setFieldValue} />
                          </div>
                          <p style={{ textAlign: "right", color: "#2EBB55" }}>
                            (Optional)
                          </p>
                        </div>
                      )}
                    </Field>
                    <ErrorMessage
                      name="basicInfo.scale"
                      className={modalStyles.error_msg}
                      component="div"
                    />

                    <div>
                      <label>Tell us what you are uploading?</label>
                      <div style={{ display: "flex", gap: "20px", marginTop: "8px" }}>
                        <div style={{ display: "flex", gap: "8px", cursor: "pointer" }} onClick={() => setIsLoop(!isLoop)}>
                          <img src={isLoop ? CheckIcon : UncheckIcon} alt="uncheck" />
                          <div style={{ fontSize: "16px", color: "#afafaf" }}>
                            Loop
                          </div>
                        </div>
                        <div style={{ display: "flex", gap: "8px", cursor: "pointer" }} onClick={() => setIsLoop(!isLoop)}>
                          <img src={!isLoop ? CheckIcon : UncheckIcon} alt="uncheck" />
                          <div style={{ fontSize: "16px", color: "#afafaf" }}>
                            Beat
                          </div>
                        </div>
                      </div>
                    </div>
                    {!isLoop && <Form>
                      <div className={modalStyles.label}>
                        Beat Exclusive Price
                      </div>
                      <Field
                        name="beatInfo.exclusivePrice"
                        className={modalStyles.div_item}
                      />
                      <ErrorMessage
                        name="beatInfo.exclusivePrice"
                        className={modalStyles.error_msg}
                        component="div"
                      />
                      <div className={modalStyles.label}>
                        Beat Non-Exclusive Price
                      </div>
                      <Field
                        name="beatInfo.nonExclusivePrice"
                        className={modalStyles.div_item}
                      />
                      <ErrorMessage
                        name="beatInfo.nonExclusivePrice"
                        className={modalStyles.error_msg}
                        component="div"
                      />
                    </Form>}
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <BlueButtonComponents text="Finishing Editing" fontsize="16px" type="submit" />
                    </div>
                  </div>
                </div>
              </Form>
            )}

          </Formik>

        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(EditPackModal);
