import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import TrashPageComponent from "../../components/MyContractsComponents/Trash/TrashPageComponent.js";
const TrashPage = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<TrashPageComponent />}
        activeText="My Contracts"
      />
    </Grid>
  );
};

export default TrashPage;
