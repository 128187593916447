import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Typography, useTheme } from "@mui/material";

const RightCheckBox = ({ title = "false", icons = "false" }) => {
  const [state, setState] = React.useState({ [title]: false });
  const theme = useTheme();
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      <FormControl component="" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id="demo-simple-select-label"
                checked={state[title]}
                onChange={handleChange}
                name={title}
                sx={{
                  width: 24,
                  height: 24,
                  color: "#AFAFAF",
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  marginRight: "12px",
                  textTransform: "capitalize",
                  borderRadius: "100px",
                  background: "none",
                  "& .MuiSvgIcon-root": {
                    borderRadius: "50%",
                    fill: "#AFAFAF",
                  },
                  "&.Mui-checked": {
                    backgroundColor: "transparent",
                    border: "none",
                    "& .MuiSvgIcon-root": {
                      color: "#2AC171",
                      borderRadius: "100px",
                      "& path": {
                        fill: "#2AC171",
                      },
                    },
                  },
                  "&.Mui-checked:hover": {
                    background: "transparent",
                  },
                }}
              />
            }
            label={
              <Typography
                variant="body1"
                style={{
                  color: "#AFAFAF",
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  marginLeft: "10px",
                  userSelect: "none",
                }}
              >
                {title}
                <img src={icons} alt="" />
              </Typography>
            }
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export default RightCheckBox;
