import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ModelContentBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "& .header-icon-box": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
    "& .back-icon": {
      width: "24px",
      height: "24px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
    "& .close-icon": {
      width: "30px",
      height: "30px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
  "& h5": {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: "5px",
  },
  "& p": {
    textAlign: "center",
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "35px",
    padding: "0 40px",
  },
  "& .input-box-main": {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",

    "& div:first-child": {
      gap: "10px",
      "& div:nth-child(3)": {
        marginLeft: "30px",
      },
    },
    input: {
      width: "60px !important",
      height: "60px",
      // marginRight: index % 3 === 2 ? "30px" : "12px",
      borderRadius: "5px",
      border: "1px solid #414241",
      background: "#1C1B1B",
      textAlign: "center",
      color: "#fff",
    },

    [theme.breakpoints.down("md")]: {
      input: {
        width: "42px !important",
        height: "42px",
      },
      "& div:first-child": {
        gap: "5px",
        "& div:nth-child(4)": {
          marginLeft: "10px",
        },
      },
    },
  },
  "& .btn-box": {
    textAlign: "right",
    marginTop: "40px",
    marginBottom: "40px",
    "& .btn": {
      border: "1px solid #AFAFAF",
      padding: "10px",
      ccolor: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
      borderRadius: "5px",
      background: "#2EBB55",
    },
  },
  "& span": {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    cursor: "pointer",
  },
  "& .underline": {
    textAlign: "center",
    background: "#fff",
    width: "190px",
    marginLeft: "33.2%",
    marginTop: "0",
  },
}));
export { ModelContentBox };
