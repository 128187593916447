import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Box, Button, Heading, HStack, Input } from "native-base";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Loader } from "../../Loader";
import styles from "../styles.module.css";
import { AddPhoto } from "../Svgs/AddPhoto";
import ProgressTags from "./ProgressTags";
import allImages from "../../../assets";
import { FormControl, Select } from "@mui/material";
import { MenuItem } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import "./style.css";

const UploadSoundKitsBasic = (props) => {
  const { setStep, uploadData, setUploadData, edit, editData } = props;

  const navigate = useNavigate();
  const types = ["image/jpeg", "image/png"];
  const [isLoadingCover, setIsLoadingCover] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isRoyaltyFreeChecked, setIsRoyaltyFreeChecked] = useState(false);
  const [soundKit, setSoundKit] = useState(null);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  console.log(isRoyaltyFreeChecked, "isRoyaltyFreeChecked");
  const dataKit = [
    "Drum Kit",
    "One Shot Kit",
    "Loop kit",
    "Preset Bank",
    "Midi kit",
  ];
  const schema = yup.object().shape({
    imgUrl: yup.string().required("Profile image is required").default(""),
    title: yup
      .string()
      .required("Title is a required field")
      .max(50, "Title should not be more than 50 characters")
      .default(""),
    kit: yup.string().required("Kit is a required field").default(""),
    soundBpm: yup.string().default(""),
    soundKey: yup.string().default(""),
    soundPrice: yup
      .number()
      .typeError("Price is required")
      .required("Price is required")
      .default(""),
    isRoyaltyFree: yup
      .boolean()
      .typeError("Please select an option")
      .required()
      .default(false),
    description: yup
      .string()
      .required("Description is a required field")
      .default(""),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = (data) => {
    console.log("sdasdsaddataBasic:", data);

    setUploadData({
      ...uploadData,
      soundPackCover: data?.imgUrl,
      soundPackTitle: data?.title,
      soundPackKit: data?.kit,
      soundPackKey: data?.soundKey,
      soundPackBPM: data?.soundBpm,
      soundPackPrice: data?.soundPrice,
      soundPackIsRoyaltyFree: data?.isRoyaltyFree,
      soundPackDescription: data?.description,
    });
    setStep(2);
  };
  const verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      if (!types.includes(currentFileType)) {
        console.log("not accepted");
        toast.error(".png, .jpg,.jpeg file format are accepted");
        return false;
      }
      return true;
    }
  };

  const handleOnDropLogoImage1 = async (files, rejectedFiles) => {
    const profileData = new FormData();
    const fileSizeLimitInMB = 15;
    const minImageWidth = 600;
    const minImageHeight = 600;
    const maxImageWidth = 6000;
    const maxImageHeight = 6000;
    const allowedFileSize = fileSizeLimitInMB * 1024 * 1024;
    const marginOfError = 1024 * 1024;
    if (rejectedFiles && rejectedFiles.length > 0) {
      const rejectedFile = rejectedFiles[0];
      if (rejectedFile.size > allowedFileSize + marginOfError) {
        toast.error(`File size should not exceed ${fileSizeLimitInMB} MB`);
        return;
      }
      verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > allowedFileSize + marginOfError) {
        toast.error(`File size should not exceed ${fileSizeLimitInMB} MB`);
        return;
      }
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = function () {
        if (img.width < minImageWidth || img.height < minImageHeight) {
          toast.error(
            `Image dimensions should be at least ${minImageWidth}x${minImageHeight}`
          );
          return;
        }
        if (img.width > maxImageWidth || img.height > maxImageHeight) {
          toast.error(
            `Image dimensions should not exceed ${maxImageWidth}x${maxImageHeight}`
          );
          return;
        }
        if (verifyImageFile(files)) {
          profileData.append("file", files[0]);
          profileData.append("type", "Public");
          setIsLoadingCover(true);
          var config = {
            method: "post",
            url: `${process.env.REACT_APP_FILE_URL}/file/uploadprofilepicture`,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: profileData,
            onUploadProgress: (progressEvent) => {
              // let { progress } = this.state;
              const progress =
                (progressEvent.loaded / progressEvent.total) * 100;
              setLoadingProgress(progress);
            },
          };
          axios(config)
            .then(function (response) {
              setValue("imgUrl", response?.data?.file?.url);
              setIsLoadingCover(false);
              setLoadingProgress(0);
              trigger("imgUrl");
            })
            .catch(function (error) {
              console.log(error);
              setIsLoadingCover(false);
              setLoadingProgress(0);
            });
          return;
        } else {
          setIsLoadingCover(false);
          setLoadingProgress(0);
          return;
        }
      };
    }
  };
  useEffect(() => {
    if (edit) {
      reset({
        imgUrl: edit ? editData?.soundPackCover : "",
        title: edit ? editData?.soundPackTitle : "",
        kit: edit ? editData?.soundPackKitType : "",
        soundBpm: edit ? editData?.soundPackBPM : "",
        soundKey: edit ? editData?.soundPackKey : "",
        soundPrice: edit ? editData?.soundPackPrice : "",
        isRoyaltyFree: edit ? editData?.soundPackIsRoyaltyFree : false,
        description: edit ? editData?.soundPackDescription : "",
      });
      editData && setIsRoyaltyFreeChecked(editData.soundPackIsRoyaltyFree);
      editData && setSoundKit(editData.soundPackKitType);
    } else {
      reset({
        imgUrl: "",
        title: "",
        kit: "",
        soundBpm: "",
        soundKey: "",
        soundPrice: "",
        isRoyaltyFree: false,
        description: "",
      });
    }
  }, [editData]);

  return (
    <Box w={"100%"}>
      <Box w={"100%"} alignSelf={"center"} px={"3%"} mt={4}>
        <ProgressTags index={1} />

        <Box
          w={{ lg: "80%", base: "90%" }}
          alignSelf={"center"}
          my={{ lg: 10, base: 5 }}
        >
          <form>
            {isLoadingCover ? (
              <div className={styles.loaderProfile}>
                <Box position={"relative"} display={"inline-flex"}>
                  <CircularProgress
                    variant="determinate"
                    value={loadingProgress}
                    size={80}
                    sx={{
                      color: "#2ebb55",
                    }}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position={"absolute"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Heading
                      fontFamily={"Poppins"}
                      fontWeight={400}
                      fontSize={"15px"}
                      textAlign={"center"}
                      color={"#afafaf"}
                    >
                      {Math.round(loadingProgress) + "%"}
                    </Heading>
                  </Box>
                </Box>
              </div>
            ) : (
              <Dropzone
                name="dropzone1"
                accept="image/*"
                onDrop={handleOnDropLogoImage1}
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <Button
                    variant={"ghost"}
                    alignSelf={"center"}
                    w={{ lg: "30%", base: "80%" }}
                    px={8}
                    py={2}
                    my={{ lg: 10, base: 5 }}
                    borderWidth={getValues("imgUrl") ? 0 : 1}
                    borderColor={"#afafaf"}
                    borderStyle={"dashed"}
                    rounded={"md"}
                    _hover={{ bg: "transparent" }}
                    _pressed={{ bg: "transparent" }}
                  >
                    <div {...getRootProps()} className={styles.presentation}>
                      {getValues("imgUrl") ? (
                        <img
                          src={getValues("imgUrl")}
                          alt="cover"
                          className={styles.coverPic}
                        />
                      ) : (
                        <>
                          <Box alignSelf={"center"}>
                            <AddPhoto />
                          </Box>
                          <Heading
                            fontFamily={"Poppins"}
                            fontWeight={700}
                            fontSize={"20px"}
                            textAlign={"center"}
                            color={"#afafaf"}
                            py={3}
                            alignSelf={"center"}
                            w={"85%"}
                          >
                            Add A Kit Cover Photo
                          </Heading>
                          <Heading
                            fontFamily={"Poppins"}
                            fontWeight={700}
                            fontSize={"12px"}
                            textAlign={"center"}
                            color={"#afafaf"}
                            alignSelf={"center"}
                            w={"85%"}
                          >
                            600 x 600 - 6,000 x 6,000
                          </Heading>
                          <Heading
                            fontFamily={"Poppins"}
                            fontWeight={700}
                            fontSize={"12px"}
                            textAlign={"center"}
                            color={"#afafaf"}
                            alignSelf={"center"}
                            w={"85%"}
                          >
                            MAX
                          </Heading>
                          <Heading
                            fontFamily={"Poppins"}
                            fontWeight={700}
                            fontSize={"12px"}
                            textAlign={"center"}
                            color={"#afafaf"}
                            alignSelf={"center"}
                            w={"85%"}
                          >
                            JPEG PNG
                          </Heading>
                        </>
                      )}
                      <input {...getInputProps()} />
                    </div>
                  </Button>
                )}
              </Dropzone>
            )}
            {errors.imgUrl && (
              <p className={styles.error} style={{ alignSelf: "center" }}>
                {errors.imgUrl?.message}
              </p>
            )}

            <Box width={"100%"}>
              <Heading
                my={5}
                textTransform={"capitalize"}
                fontSize={{ xl: "24px", lg: "24px", md: "20px", base: "18px" }}
                fontWeight={500}
                fontFamily={"Poppins"}
                textAlign={"left"}
                color={"#afafaf"}
              >
                Sound Kit Title
              </Heading>
              <Input
                _text={{ fontSize: "1rem", fontFamily: "poppins" }}
                _hover={{ borderColor: "#AFAFAF" }}
                _focus={{ bg: "transparent" }}
                focusOutlineColor="#2EBB55"
                fontFamily="poppins"
                fontSize={{ lg: "18px", md: "16px", base: "14px" }}
                color={"#AFAFAF"}
                borderColor="#AFAFAF"
                height={{
                  xl: "60px",
                  base: "45px",
                }}
                {...register("title")}
                onChangeText={(text) => {
                  setValue("title", text);
                  trigger("title");
                }}
              />
              {errors.title && (
                <p className={styles.error}>{errors.title?.message}</p>
              )}
            </Box>
            <HStack
              width={"100%"}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              flexGrow={1}
            >
              <Box width={{ lg: "48%", base: "100%" }}>
                <Heading
                  my={5}
                  textTransform={"capitalize"}
                  fontSize={{
                    xl: "24px",
                    lg: "24px",
                    md: "20px",
                    base: "18px",
                  }}
                  fontWeight={500}
                  fontFamily={"Poppins"}
                  textAlign={"left"}
                  color={"#afafaf"}
                >
                  Sound Kit Type
                </Heading>
                <ThemeProvider theme={darkTheme}>
                  <CssBaseline />
                  <div className={styles.test001}>
                    <FormControl fullWidth>
                      <Select
                        id="demo-simple-select"
                        style={{
                          border: "1px solid #AFAFAF",
                          color: "#AFAFAF",
                          fontSize: "18px",
                        }}
                        value={soundKit}
                        onChange={(event) => {
                          setSoundKit(event.target.value);
                          setValue("kit", event.target.value);
                          trigger("kit");
                        }}
                      >
                        {dataKit.map((item, index) => (
                          <MenuItem
                            style={{
                              backgroundColor: "#1C1B1B",
                              color: "#AFAFAF",
                              borderBottom: "1px solid #AFAFAF",
                              fontSize: "18px",
                            }}
                            key={index}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </ThemeProvider>

                {errors.kit && (
                  <p className={styles.error}>{errors.kit?.message}</p>
                )}
              </Box>
              <Box width={{ lg: "48%", base: "100%" }}>
                <HStack w={"100%"} justifyContent={"space-between"} my={5}>
                  <Heading
                    textTransform={"capitalize"}
                    fontSize={{
                      xl: "24px",
                      lg: "24px",
                      md: "20px",
                      base: "18px",
                    }}
                    fontWeight={500}
                    fontFamily={"Poppins"}
                    textAlign={"left"}
                    color={"#afafaf"}
                  >
                    BPM
                  </Heading>
                  <Heading
                    fontSize={{
                      xl: "16px",
                      lg: "16px",
                      md: "14px",
                      base: "10px",
                    }}
                    fontWeight={400}
                    fontFamily={"Poppins"}
                    textAlign={"left"}
                    color={"#afafaf"}
                    alignSelf={"center"}
                  >
                    {"(Optional)"}
                  </Heading>
                </HStack>
                <Input
                  _text={{ fontSize: "1rem", fontFamily: "poppins" }}
                  _hover={{ borderColor: "#AFAFAF" }}
                  _focus={{ bg: "transparent" }}
                  focusOutlineColor="#2EBB55"
                  fontFamily="poppins"
                  fontSize={{ lg: "18px", md: "16px", base: "14px" }}
                  color={"#AFAFAF"}
                  borderColor="#AFAFAF"
                  height={{
                    xl: "60px",
                    base: "45px",
                  }}
                  onKeyPress={(event) => {
                    const allowedKeys = [
                      "0",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "-",
                      "Backspace",
                      "Enter",
                    ];
                    if (!allowedKeys.includes(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  {...register("soundBpm")}
                  // value={getValues('soundBpm')}
                  onChangeText={(text) => {
                    setValue("soundBpm", text);
                    trigger("soundBpm");
                  }}
                />
                {errors.soundBpm && (
                  <p className={styles.error}>{errors.soundBpm?.message}</p>
                )}
              </Box>
            </HStack>
            <HStack
              width={"100%"}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              flexGrow={1}
            >
              <Box width={{ lg: "48%", base: "100%" }}>
                <HStack w={"100%"} justifyContent={"space-between"} my={5}>
                  <Heading
                    textTransform={"capitalize"}
                    fontSize={{
                      xl: "24px",
                      lg: "24px",
                      md: "20px",
                      base: "18px",
                    }}
                    fontWeight={500}
                    fontFamily={"Poppins"}
                    textAlign={"left"}
                    color={"#afafaf"}
                  >
                    Key
                  </Heading>
                  <Heading
                    fontSize={{
                      xl: "16px",
                      lg: "16px",
                      md: "14px",
                      base: "10px",
                    }}
                    fontWeight={400}
                    fontFamily={"Poppins"}
                    textAlign={"left"}
                    color={"#afafaf"}
                    alignSelf={"center"}
                  >
                    {"(Optional)"}
                  </Heading>
                </HStack>
                <Input
                  _text={{ fontSize: "1rem", fontFamily: "poppins" }}
                  _hover={{ borderColor: "#AFAFAF" }}
                  _focus={{ bg: "transparent" }}
                  focusOutlineColor="#2EBB55"
                  fontFamily="poppins"
                  fontSize={{ lg: "18px", md: "16px", base: "14px" }}
                  color={"#AFAFAF"}
                  borderColor="#AFAFAF"
                  height={{
                    xl: "60px",
                    base: "45px",
                  }}
                  {...register("soundKey")}
                  // value={getValues('soundKey')}
                  onChangeText={(text) => {
                    setValue("soundKey", text);
                    trigger("soundKey");
                  }}
                />
                {errors.soundKey && (
                  <p className={styles.error}>{errors.soundKey?.message}</p>
                )}
              </Box>

              <Box width={{ lg: "48%", base: "100%" }}>
                <HStack w={"100%"} justifyContent={"space-between"} my={5}>
                  <Heading
                    textTransform={"capitalize"}
                    fontSize={{
                      xl: "24px",
                      lg: "24px",
                      md: "20px",
                      base: "18px",
                    }}
                    fontWeight={500}
                    fontFamily={"Poppins"}
                    textAlign={"left"}
                    color={"#afafaf"}
                  >
                    Price ($)
                  </Heading>
                </HStack>
                <Input
                  _text={{ fontSize: "1rem", fontFamily: "poppins" }}
                  _hover={{ borderColor: "#AFAFAF" }}
                  _focus={{ bg: "transparent" }}
                  focusOutlineColor="#2EBB55"
                  fontFamily="poppins"
                  fontSize={{ lg: "18px", md: "16px", base: "14px" }}
                  color={"#AFAFAF"}
                  borderColor="#AFAFAF"
                  height={{
                    xl: "60px",
                    base: "45px",
                  }}
                  onKeyPress={(event) => {
                    const allowedKeys = [
                      "0",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      ".",
                      "Backspace",
                      "Enter",
                    ];
                    if (!allowedKeys.includes(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  {...register("soundPrice")}
                  // value={getValues('soundPrice')}
                  onChangeText={(text) => {
                    setValue("soundPrice", text);
                    trigger("soundPrice");
                  }}
                />
                {errors.soundPrice && (
                  <p className={styles.error}>{errors.soundPrice?.message}</p>
                )}
              </Box>
            </HStack>

            <Box width={{ lg: "48%", base: "100%" }}>
              <HStack w={"100%"} justifyContent={"space-between"} my={5}>
                <Heading
                  textTransform={"capitalize"}
                  fontSize={{
                    xl: "24px",
                    lg: "24px",
                    md: "20px",
                    base: "18px",
                  }}
                  fontWeight={500}
                  fontFamily={"Poppins"}
                  textAlign={"left"}
                  color={"#afafaf"}
                >
                  Is this Royalty FREE?
                </Heading>
              </HStack>
              <div className={styles.checkboxes_wrapper}>
                <label className={styles.radio_container}>
                  Yes
                  <input
                    type="radio"
                    name="isRoyaltyFree"
                    value={true}
                    checked={isRoyaltyFreeChecked === true}
                    onChange={() => {
                      setIsRoyaltyFreeChecked(true);
                      setValue("isRoyaltyFree", true);
                      trigger("isRoyaltyFree");
                    }}
                  />
                  <span className={styles.checkmark}></span>
                </label>
                <label className={styles.radio_container}>
                  No
                  <input
                    type="radio"
                    name="isRoyaltyFree"
                    value={false}
                    checked={isRoyaltyFreeChecked === false}
                    onChange={() => {
                      setIsRoyaltyFreeChecked(false);
                      setValue("isRoyaltyFree", false);
                      trigger("isRoyaltyFree");
                    }}
                  />
                  <span className={styles.checkmark}></span>
                </label>
              </div>
              {errors.isRoyaltyFree && (
                <p className={styles.error}>{errors.isRoyaltyFree?.message}</p>
              )}
            </Box>

            <Box width={"100%"}>
              <Heading
                my={5}
                textTransform={"capitalize"}
                fontSize={{ xl: "24px", lg: "24px", md: "20px", base: "18px" }}
                fontWeight={500}
                fontFamily={"Poppins"}
                textAlign={"left"}
                color={"#afafaf"}
              >
                Description
              </Heading>
              <Input
                _text={{ fontSize: "1rem", fontFamily: "poppins" }}
                _hover={{ borderColor: "#AFAFAF" }}
                _focus={{ bg: "transparent" }}
                focusOutlineColor="#2EBB55"
                multiline={"true"}
                numberOfLines={6}
                fontFamily="poppins"
                fontSize={{ lg: "18px", md: "16px", base: "14px" }}
                color={"#AFAFAF"}
                borderColor="#AFAFAF"
                {...register("description")}
                // value={getValues('description')}
                onChangeText={(text) => {
                  setValue("description", text);
                  trigger("description");
                }}
              />
              {errors.description && (
                <p className={styles.error}>{errors.description?.message}</p>
              )}
            </Box>

            <HStack
              alignSelf={"center"}
              w={{ lg: "60%", base: "100%" }}
              my={{ lg: 16, base: 5 }}
              justifyContent={"space-between"}
            >
              <Button
                w={"45%"}
                variant={"ghost"}
                _hover={{
                  backgroundColor: "#2EBA55",
                  _text: {
                    color: "#fff",
                  },
                }}
                _pressed={{
                  bg: "#2EBB55",
                  _text: {
                    color: "#fff",
                  },
                }}
                _focus={{ bg: "transparent" }}
                p={0}
                py={2.5}
                borderColor="#2EBA55"
                borderWidth={1}
                alignSelf={"center"}
                _text={{
                  color: "#2ebb55",
                  fontWeight: "medium",
                  fontSize: {
                    xl: "18px",
                    lg: "18px",
                    md: "16px",
                    base: "14px",
                  },
                  fontFamily: "Poppins",
                }}
                onPress={() => navigate("/soundPack")}
              >
                CANCEL
              </Button>
              <Button
                w={"45%"}
                borderColor="#2EBA55"
                borderWidth={1}
                _pressed={{
                  bg: "#2EBB55",
                  _text: {
                    color: "#fff",
                  },
                }}
                backgroundColor="#2EBA55"
                py={2.5}
                _text={{
                  color: "#fff",
                  fontSize: {
                    xl: "18px",
                    lg: "18px",
                    md: "16px",
                    base: "14px",
                  },
                  fontWeight: "medium",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                }}
                _hover={{
                  backgroundColor: "transparent",
                  _text: {
                    color: "#2EBA55",
                  },
                }}
                alignSelf={"center"}
                onPress={handleSubmit(onSubmitHandler)}
              >
                NEXT
              </Button>
            </HStack>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadSoundKitsBasic;
