import React, { useState, memo } from "react";
import { Box, Button, Modal } from "@mui/material";
import { ModelContentBox } from "./styles/PaymentDetailModelStyle";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "501px",
  bgcolor: "#141617",
  pt: 2,
  px: 4,
  pb: 3,
};

const PaymentDetailModel = ({
  open = false,
  setOpen = () => {},
  setCongradulationOpen = () => {},
  paymentDetais = "",
  selectedPlans = "",
}) => {
  const PaymentDetailModelClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(!open);
  };
  const PaymentDetailModelOpen = () => {
    setOpen(false);
    setCongradulationOpen(true);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={PaymentDetailModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="main"
          sx={{
            ...style,
            width: { xs: "100%", sm: "580px" },
            padding: "40px",
            borderRadius: "10px",
            background: "#141617",
          }}
        >
          {/* <Box className="header-icon-box">
            <CloseIcon
              className="close-icon"
              onClick={PaymentDetailModelClose}
            />
          </Box> */}
          <h4>Payment Details</h4>
          <p>
            View or update your payment information to ensure a smooth and
            secure transaction experience.
          </p>
          <Box className="package-datail-box">
            <Box className="plan-box">
              <h5>{selectedPlans || ""}</h5>
              <h6>HitMaker Membership</h6>
            </Box>
            <Box className="transaction-date-box">
              <Box className="date">
                <h6>transaction date:</h6>
                <h6>
                  {moment(parseInt(paymentDetais?.date)).format("MM/DD/YY")}
                </h6>
              </Box>
              {/* <Box className="hours">
                <h6>hours:</h6>
                <h6>10:30 AM</h6>
              </Box> */}
              <Box className="sub-total">
                <h6>subtotal:</h6>
                <h6>${paymentDetais?.amountPaid || "0.00"} USD</h6>
              </Box>
              <Box className="discount">
                <h6>discount:</h6>
                <h6>
                  $
                  {paymentDetais?.discount === null
                    ? 0
                    : paymentDetais?.discount}{" "}
                  USD
                </h6>
              </Box>
              <Box className="total">
                <h6>total:</h6>
                <h4>${paymentDetais?.amountPaid || "0.00"} USD</h4>
              </Box>
              <Button
                variant="contained"
                fullWidth
                className="pay-btn"
                onClick={PaymentDetailModelOpen}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(PaymentDetailModel);
