import React, { useState, memo, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { MainBeatsBox, HeaderInfoBox, MainDescriptionBox } from "./style";
import {
  open_eye,
  beatsdelete,
  BeatsShare,
  image2,
  new_share,
} from "../../../../../assets/smartlink";
import MainDeletePopUp from "../../../common/deletepopup/MainDeletePopUp";
import ShareInboxModel from "./ShareBeatPopup";
import AddCollaboratorPopup from "./AddCollaboratorPopup";
import { useNavigate } from "react-router-dom";
import {
  DotMenu,
  ShareIcon,
  UserIcon,
  LockIcon,
  messageEdit,
  TrashIcon,
  EyeIcon,
} from "../../../../../assets/smartlink";

const BeatsBox = ({
  data = [],
  index,
  isOpen,
  toggleDropdown,
  setEditData,
  OpenInboxCreate,
}) => {
  const dropdownRef = useRef(null);
  const [SharedData, SetSharedData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownClick = (e) => {
    e.preventDefault();
    toggleDropdown(index);
  };

  const EditData = (EditInbox) => {
    OpenInboxCreate(true);
    setEditData(EditInbox);
  };

  // /inbox-beats/:name/unitedmarket/:id
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const DeleteModelOpen = () => {
    setOpen(!open);
  };
  const [shareopen, setShareOpen] = useState(false);
  const [collaboratorOpen, setCollaboratorOpen] = useState(false);

  const handleAddColaboratore = (value) => {
    setCollaboratorOpen(!collaboratorOpen);
    SetSharedData(value);
  };
  const haldelShaerOpen = (value) => {
    SetSharedData(value);
    setShareOpen(!shareopen);
  };

  // Auro Close Drop Down When Click anywhere in Body

  const closeDropdowns = (e) => {
    if (
      isOpen &&
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target)
    ) {
      toggleDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdowns);
    return () => {
      document.removeEventListener("click", closeDropdowns);
    };
  }, [isOpen]);

  return (
    <>
      <MainBeatsBox>
        <HeaderInfoBox className="header_main">
          <Box className="left">
            <img
              src={data?.image || image2}
              alt={data?.title}
              className="main_image"
            />
            <Box className="beat-info">
              <Typography variant="h4">{data?.title || "N/A"}</Typography>
              <Box className="desc">
                <Typography variant="body1">Submitted Beats :</Typography>
                <span>{data?.totalBeats || "0"} </span>
              </Box>
            </Box>
          </Box>
          <Box className="right">
            <Box className="icon-box">
              <div
                ref={dropdownRef}
                style={{
                  background: "#141515",
                  borderRadius: "3px",
                  height: "30px",
                  width: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleDropdownClick}
              >
                <img
                  src={DotMenu}
                  style={{ height: "18px", width: "18px" }}
                  alt="DotMenu.svg"
                  className="icon"
                />
              </div>
              {isOpen && (
                <Box className="BeatBoxDropdown">
                  <Box
                    onClick={() => {
                      navigate(
                        `/inbox-beats/${data?.title.toLowerCase()}/unitedmarket/${
                          data?._id
                        }`
                      );
                    }}
                    className="BeatboxList"
                  >
                    <img
                      src={EyeIcon}
                      style={{ width: "12px", height: "12px" }}
                      alt="ShareIcon.svg"
                    />
                    <h1>View</h1>
                  </Box>

                  {/* ......Add Collaborator Popup Open......... */}

                  <Box
                    className="BeatboxList"
                    onClick={() => handleAddColaboratore(data)}
                  >
                    <img
                      src={UserIcon}
                      style={{ width: "12px", height: "12px" }}
                      alt="ShareIcon.svg"
                    />
                    <h1>Add Collaborator</h1>
                  </Box>

                  <Box
                    onClick={() => haldelShaerOpen(data)}
                    className="BeatboxList"
                  >
                    <img
                      src={ShareIcon}
                      style={{ width: "12px", height: "12px" }}
                      alt="ShareIcon.svg"
                    />
                    <h1>Share</h1>
                  </Box>
                  {/* <Box className="BeatboxList">
                    <img
                      src={
                        UserIcon ||
                        "https://united-market.s3.us-east-2.amazonaws.com/fileupload/1702298814414/MicrosoftTeams-image.png"
                      }
                      style={{ width: "12px", height: "12px" }}
                      alt="ShareIcon.svg"
                    />
                    <h1>Add Collaborator</h1>
                  </Box> */}
                  <Box onClick={() => EditData(data)} className="BeatboxList">
                    <img
                      src={messageEdit}
                      style={{ width: "12px", height: "12px" }}
                      alt="ShareIcon.svg"
                    />
                    <h1>Edit Inbox</h1>
                  </Box>
                  <Box onClick={DeleteModelOpen} className="BeatboxList">
                    <img
                      src={TrashIcon}
                      style={{ width: "12px", height: "12px" }}
                      alt="ShareIcon.svg"
                    />
                    <h1>Delete</h1>
                  </Box>
                </Box>
              )}

              {/* <img
              src={open_eye}
              alt="view icon"
                className="icon"
                onClick={() => {
                  navigate(
                    `/inbox-beats/${data?.title.toLowerCase()}/unitedmarket/${
                      data?._id
                    }`
                  );
                }}
              />
              <img
                src={beatsdelete}
                alt="beats delete icon"
                className="icon"
                onClick={DeleteModelOpen}
              />
              <img
                src={new_share}
                alt="Beats Share icon"
                className="icon"
                onClick={() => haldelShaerOpen(data)}
              /> */}
            </Box>
          </Box>
        </HeaderInfoBox>
        <Box className="desc-box">
          <p>{data?.description}</p>
        </Box>
        <MainDeletePopUp open={open} setOpen={setOpen} ID={data?._id} />
        <ShareInboxModel
          open={shareopen}
          setOpen={haldelShaerOpen}
          SharedData={SharedData}
        />
        <AddCollaboratorPopup
          collaboratorOpen={collaboratorOpen}
          setCollaboratorOpen={setCollaboratorOpen}
          SharedData={SharedData}
        />
      </MainBeatsBox>
    </>
  );
};

export default memo(BeatsBox);


