import React, { useEffect } from "react";
import { Grid } from "@mui/material";

// import styles from "./styles.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
// import CreateOpportunity from "../../components/OpportunitiesComponents/PageComponents/createOpportunity";
import CreateOpportunity from "../../components/OpportunitiesComponents/Opportunities/CreateOpportunity";
import { useNavigate } from "react-router-dom";
import useAuth from "../../context/AuthContext";
import { get } from "lodash";
import { ROLES } from "../../shared/constants";

const CreateOpportunityPage = ({ isEdit }) => {
  console.log("CHECKPOINT - CreateOpportunitiesPage");

  const navigate = useNavigate();
  const { user, userPrivileges } = useAuth();

  let canCreate;

  if (user.roleId.roleName === "Admin") {
    canCreate = true;
  } else if (user.roleId.roleName === "Music Producer") {
    canCreate = userPrivileges?.privileges[0]?.isCreate || false;
  } else {
    canCreate = true;
  }

  useEffect(() => {
    if (!canCreate) {
      navigate("/opportunities");
    }
  }, [user]);

  return (
    <Grid>
      <Sidebar
        childComponent={<CreateOpportunity isEdit={isEdit} />}
        activeText="Opportunities"
      />
    </Grid>
  );
};

export default CreateOpportunityPage;
