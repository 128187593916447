export const formValidation = (data) => {
  const errors = {};
  if (!data.coustom_info?.trim()) {
    errors.coustom_info = "Custom information is required";
  }
  if (!data.focus_area?.trim()) {
    errors.focus_area = "Focus area is required";
  }
  if (!data.goals?.trim()) {
    errors.goals = "Goals and objectives is required";
  }
  // if (!data.knowledge_idea?.trim()) {
  //   errors.knowledge_idea = "Knowledge ideas is required";
  // }
  // if (!data.regions?.trim()) {
  //   errors.regions = "Demographic regions is required";
  // }
  if (!data.specific_ideas) {
    errors.specific_ideas = "Specific idea outcome is required";
  }
  return errors;
};

export const tagsValidation = (data) => {
  const errors = {};
  if (data && data.length === 0) {
    errors.target = "Target audience is required";
  }
  return errors;
};

export const audience_sug = [
  "Music Producers",
  "Artist",
  "Existing Fan base",
  "New Fan Base",
  "Corporate Partners"
];

export const GenerateFormData = [
  {
    Title: "Goals and Objectives",
    name: "goals",
    Placeholder: "Enter your Goals and Objectives",
    subTitle: "What are your marketing Goals and Objectives?",
    TooltipTitle:
      "The user will specify the goals and objectives entering information in a text field box",
  },
  {
    Title: "Focus Areas",
    name: "focus_area",
    Placeholder: "Enter your Focus Areas",
    subTitle: "What are your Focus Areas?",
    TooltipTitle:
      "User can also add additional information to give the prompt a better understanding of the requirement to generate reports",
  },
  {
    Title: "Additional Information",
    name: "coustom_info",
    Placeholder: "Input any additional info you want considered",
    subTitle: "Input any additional information",
    TooltipTitle: "Custom Information",
  },
  // {
  //   Title: "Knowledge Ideas",
  //   name:"knowledge_idea",
  //   Placeholder: "Knowledge Ideas",
  //   TooltipTitle: "Custom Information",
  // },
];

export const graphic_options = [
  { value: "North America", label: "North America" },
  { value: "South America", label: "South America" },
  { value: "Europe", label: "Europe" },
  { value: "Asia", label: "Asia" },
  { value: "Africa", label: "Africa" },
];

export const specific_options = [
  { value: "Increase merchandise sales", 
  label: "Increase merchandise sales" },
  {
    value: "Grow on social media",
    label: "Grow on social media",
  },
  {
    value: "Increase ticket sales",
    label: "Increase ticket sales",
  },
  {
    value: "Bring more fans to your concert",
    label: "Bring more fans to your concert",
  },
  {
    value: "Built out an authentic fanbase",
    label: "Built out an authentic fanbase",
  },
  {
    value: "Launch subscription plan for fans",
    label: "Launch subscription plan for fans",
  },
  {
    value: "Grow streams",
    label: "Grow streams",
  },
  {
    value: "Sell merchandise",
    label: "Sell merchandise",
  },
];
