// import * as React from "react";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { SelectMain } from "./style";
// import { useState } from "react";

// const MenuList = [
//   {
//     name: "Creation Date",
//   },
//   {
//     name: "Title",
//   },
//   {
//     name: "Last Updated",
//   },
//   {
//     name: "Least Recent",
//   },
//   {
//     name: "Live",
//   },
//   {
//     name: "Live",
//   },
//   {
//     name: "Drafts",
//   },
// ];

// const SelectDropdown = ({ height = "" }) => {
//   const [selectedValue, setSelectedValue] = useState("");

//   const handleChange = (event) => {
//     setSelectedValue(event.target.value);
//   };

//   return (
//     <SelectMain height={height} className="SelectMain">
//       <FormControl fullWidth>
//         <Select
//           className="select-box"
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           value={selectedValue}
//           label="Age"
//           onChange={handleChange}
//         >
//           {MenuList.map((items, id) => (
//             <MenuItem value={items.value} key={id}>
//               {items.name}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </SelectMain>
//   );
// };
// export default SelectDropdown;
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectMain, StyledMenuItem } from "./style";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const MenuList = [
  {
    name: "Creation Date",
  },
  {
    name: "Title",
  },
  {
    name: "Last Updated",
  },
  {
    name: "Least Recent",
  },
  {
    name: "Live",
  },
  {
    name: "Drafts",
  },
];
const menuPaperStyle = {
  marginTop: "5px",
  marginLeft: "-40px",
  padding: "20px",
  background: "#141515",
  borderRadius: "10px",
  color: "#FFFFFF",
};
const SelectDropdown = ({ height = "auto", width = "auto", label = "" }) => {
  const [age, setAge] = React.useState({ label });
  const [isSelectOpen, setIsSelectOpen] = React.useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleOpen = () => {
    setIsSelectOpen(true);
  };

  const handleClose = () => {
    setIsSelectOpen(false);
  };

  return (
    <SelectMain height={height} width={width}>
      <FormControl fullWidth>
        <Select
          className={`select-box ${isSelectOpen ? "open" : ""}`}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label={label}
          onChange={handleChange}
          IconComponent={ArrowForwardIosIcon}
          onClose={handleClose}
          onOpen={handleOpen}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "right",
              horizontal: "center",
            },
            PaperProps: {
              style: menuPaperStyle,
            },
          }}
        >
          {MenuList.map((item, id) => (
            <StyledMenuItem value={item.name} key={id}>
              {item.name}
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
    </SelectMain>
  );
};
export default SelectDropdown;
