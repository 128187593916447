import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    h2: "1.875rem",
    h3: "1.5rem",
    h4: "1.1rem",
    h5: "1rem",
    h6: "0.75rem",
  },
  palette: {
    primary: {
      main: "#141515",
      light: "#0B0E0B",
      dark: "#000",
      darker: "#0A0B0A",
    },
    secondary: {
      main: "#2EBB55",
      light: "#fff",
      dark: "#AFAFAF",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
