import React from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  MainTitle,
  PageTitle,
  Report,
} from "../../../components/smartlink";

const ReportPage = () => {
  return (
    <>
      <SmartLinkLayout>
        <MainTitle title="Smart Link" hasbutton={false} />
        <HorizontalMenu />
        <PageTitle title="Report" hasNavigate={false} />
        <Report />
      </SmartLinkLayout>
    </>
  );
};

export default ReportPage;
