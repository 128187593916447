import React, { useState } from "react";
import { InputsTagsMain, MainPreRleaseBox, RleaseContentBox } from "./style";
import { Box, Typography } from "@mui/material";
import { Field, Form, Formik, useField } from "formik";
// import TagsInput from "react-tagsinput";
// import "react-tagsinput/react-tagsinput.css";
// import { singer2 } from "../../../assets/smartlink";
import InputField from "../common/inputfield/inputField";
import ShareButton from "../common/ShareButton/ShareButton";
import ImageUploader from "../common/Imageuploader/ImageUploader";
import { TagsInputField } from "../release/pages/evaluateLink/evaluationForm/Index";
import * as Yup from "yup";
import { generateRandomString } from "../helpers/UniqueCode";
import { Notification } from "../../NotifyMsg/Notification";
import { useNavigate } from "react-router-dom";
import PreReleaseServices from "./prereleaseservices/PreReleaseServices";

export const validationSchema = Yup.object().shape({
  artist: Yup.string().required("Artist is required"),
  code: Yup.string()
    .min(3, "Shortcode must be at least 3 characters")
    .matches(
      /^[a-zA-Z0-9-_]+$/,
      "Invalid characters in shortcode. Supported characters are a-z, A-Z, 0-9, - and _"
    )
    .required("Shortcode is required"),
  title: Yup.string().required("Title is required"),
  tag: Yup.array().min(1, "Tag is required").of(Yup.string().required()),
  link: Yup.string()
    .matches(
      /^[a-zA-Z]+$/,
      "Invalid characters in link. Only alphabets are allowed."
    )
    .required("Link is required"),
});

const PreRelease = () => {
  const navigate = useNavigate();
  const [uploadimageurl, setuploadimageurl] = useState(
    "https://du6zmi1l7ys6.cloudfront.net/fileupload/1701685632245/Default.png"
  );
  const [initialCode] = useState(generateRandomString(6));
  const [tags, setTags] = useState([]);

  /// Services

  const [isChecked, setIsChecked] = useState(false);
  const [PreObj, setPreobject] = useState({});

  const onSubmit = async (values, actions) => {
    console.log("Runing....");
    console.log("🚀 ~ file: PreRelease.js:54 ~ onSubmit ~ values:", values);
    const mutationVariables = {
      artist: values.artist || "",
      mediaType: "PreRelease",
      subMediaType: "Track",
      title: values.title || "",
      code: values.code,
      status: "Published",
      locales: [],
      url: values.link,
      tags: values.tag.join(","),
      image:
        uploadimageurl ||
        "https://du6zmi1l7ys6.cloudfront.net/fileupload/1701685632245/Default.png",
    };
    setPreobject(mutationVariables);

    setIsChecked(true);
  };

  console.log(PreObj, "Pre-Object");

  return (
    <MainPreRleaseBox className="RleaseContentBox">
      {!isChecked ? (
        <RleaseContentBox>
          <Box className="heading-desc">
            <Typography variant="h4">Setup Your Pre-Release Link</Typography>
            <Typography variant="body1" sx={{ color: "#AFAFAF" }}>
              This is where you can personalize your short link by including a
              subdomain and a short code. You can also attach tags, artist tags,
              and a title to assist in organizing the link within the board.
            </Typography>
          </Box>
          <Box className="table-box">
            <Box className="img-box">
              <ImageUploader
                setuploadimageurl={setuploadimageurl}
                img={uploadimageurl}
              />
            </Box>

            <Box
              className="form"
              sx={{
                "& .error": {
                  color: "#ec3330",
                  fontSize: "13px",
                  fontWeight: 500,
                  marginTop: "2px",
                  fontFamily: "Poppins",
                },
                "& .error_main": {
                  input: {
                    border: "1px solid #ec3330",
                  },
                  "& .react-tagsinput": {
                    border: "1px solid #ec3330",
                  },
                },
                "& .tag_error_main": {
                  "& .react-tagsinput": {
                    border: "1px solid #ec3330",
                    opacity: 1,
                  },
                },
              }}
            >
              <Formik
                initialValues={{
                  title: "",
                  artist: "",
                  code: initialCode,
                  tag: [],
                  link: "",
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {({ errors, values }) => (
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "10px",
                    }}
                  >
                    <Box className={`${errors.title ? "error_main" : ""}`}>
                      <Field
                        label="Title"
                        type="text"
                        name="title"
                        placeholder="Enter Title"
                        component={InputField}
                      />
                      {errors.title && (
                        <div className="error">{errors.title}</div>
                      )}
                    </Box>
                    <Box className={`${errors.artist ? "error_main" : ""}`}>
                      <Field
                        label="Artists"
                        type="text"
                        name="artist"
                        placeholder="Enter Artists"
                        component={InputField}
                      />
                      {errors.artist && (
                        <div className="error">{errors.artist}</div>
                      )}
                    </Box>
                    <InputsTagsMain>
                      <TagsInputField label="Tags" name="tag" />
                    </InputsTagsMain>

                    <Box className="link-field-box">
                      <Box
                        sx={{ width: "100%" }}
                        className={`${errors.link ? "error_main" : ""}`}
                      >
                        <Field
                          label="Links"
                          type="text"
                          name="link"
                          placeholder="Please Musician Name"
                          component={InputField}
                        />
                        {errors.link && (
                          <div className="error">{errors.link}</div>
                        )}
                      </Box>

                      <Box
                        className={`${errors.code ? "error_main " : ""}`}
                        sx={{ width: "100%" }}
                      >
                        <Field
                          label="code"
                          type="text"
                          name="code"
                          placeholder="code"
                          component={InputField}
                        />
                        {errors.code && (
                          <div className="error">{errors.code}</div>
                        )}
                      </Box>
                    </Box>
                    <Typography variant="h5">
                      {`${
                        values.link === "" ? "musician name" : values.link
                      }/unitedmarket.com/${values.code}`}
                      <span> is available</span>
                    </Typography>
                    <Box className="btn-box">
                      <ShareButton
                        className="prerelease-btn"
                        name="Create"
                        type="submit"
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </RleaseContentBox>
      ) : (
        <PreReleaseServices Preobj={PreObj} />
      )}
    </MainPreRleaseBox>
  );
};

export default PreRelease;
