import React, { Fragment, useEffect, useState, useMemo } from "react";
import { size } from "lodash";
import { Box } from "@material-ui/core";
import PackCard from "./PackCard.js";
import useAuth from "../../../context/AuthContext.js";
import CircularLoaderPage from "../../UniversalComponents/CircularLoader/CircularLoaderPage.js";
import { PaginationMain } from "../../smartlink/index.js";
import SelectFileModal from "./Modals/SelectFileModal.js";
import NoItemComponents from "../AdditionalComponents/utils/NoItemComponent.js";
import { feedBeatPacksByQuery } from "../../../context/apis/audio-library.js";
import useLibraryStore from "../../../store/library.js";

const PAGE_LIMIT = 10;

const PackContentArea = ({ tags, fileKey, minBpm, maxBpm, scale, mode, packName, sortBy }) => {

  const { user, userPrivileges } = useAuth();
  const [currentPage, setcurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [feedsLoading, setFeedsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [packData, setPackData] = useState([]);
  const { newAdded } = useLibraryStore();
  const [selectFileModalsOpen, setSelectFileModalsOpen] = useState(false);

  const handleToggleSelectFileModals = () => {
    setSelectFileModalsOpen(!selectFileModalsOpen);
  };


  // let canCreate;

  // if (user.roleId.roleName === "Admin") {
  //   canCreate = true;
  // } else if (user.roleId.roleName === "Music Producer") {
  //   canCreate = userPrivileges?.privileges[0]?.isCreate || false;
  // } else {
  //   canCreate = true;
  // }

  useEffect(() => {

    setFeedsLoading(true);
    const queryString = new URLSearchParams({
      userId: user._id,
      page: currentPage,
      limit: PAGE_LIMIT,
      tags: JSON.stringify(tags),
      minBpm: minBpm,
      maxBpm: maxBpm,
      fileKey: fileKey,
      scale: scale,
      mode: mode,
      packName: packName,
      sortOrder: sortBy.title,
    }).toString();

    feedBeatPacksByQuery(queryString)
      .then((response) => {
        if (response?.data) {
          const newRows = response.data || [];
          setPackData(newRows);
          setIsEmpty(newRows.length == 0);
          const totalPages = Math.max(response.totalPages || 1, 1);
          setTotalPages(totalPages);
        }
      })
      .finally(() => {
        setFeedsLoading(false);
      });
  }, [currentPage, sortBy, newAdded]);


  useEffect(() => {
    setcurrentPage(1);
  }, [filter, sortBy]);

  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };

  const deletePack = (index) => {
    let tempPackData = [...packData];
    tempPackData.splice(index, 1);
    setPackData(tempPackData);
  };


  return (
    <>
      {isEmpty ? (
        <>
          <NoItemComponents
            src="../v2Assets/NoFilesFound.png"
            text="No Files Found"
            isButton
            isPack
            handler={handleToggleSelectFileModals}
          />
          <SelectFileModal
            open={selectFileModalsOpen}
            setOpen={setSelectFileModalsOpen}
          />
        </>
      ) : (
        <>
          <div>
            {!feedsLoading ? (
              size(packData) > 0 &&
              packData.map((item, index) => {
                return (
                  <Fragment key={`pack-card-${index}`}>
                    <PackCard 
                    packData={item}
                    index={index}
                    onDeleteComplete={deletePack}
                     />

                  </Fragment>
                );
              })
            ) : (
              <div>
                <CircularLoaderPage />
              </div>
            )}
            {!feedsLoading && totalPages > 1 && (
              <>
                <Box sx={{ marginBottom: "1rem" }}>
                  <PaginationMain
                    pageCount={totalPages}
                    page={currentPage}
                    handelChange={handlePageChange}
                  />
                </Box>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PackContentArea;
