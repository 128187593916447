import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Main } from "../../common/tables/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { InsightsTableMain } from "./InsightsTableStyle";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#414241",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#2EBB55",
  },
}));

const InsightsTables = ({
  TableHeadData = [],
  BodyData = [],
  ProgressBar = false,
  roundedimage = false,
  NoDataText = "",
}) => {
  return (
    <>
      <InsightsTableMain>
        <Main>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {TableHeadData?.map((value) => {
                    return (
                      <>
                        <TableCell key={value.title}> {value.title}</TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {BodyData?.map((row, index) => {
                  const { Link, ClickThroughs, Visits, CTR } = row || {};

                  return (
                    <>
                      <TableRow key={index}>
                        {ClickThroughs && (
                          <>
                            <TableCell>{ClickThroughs}</TableCell>
                          </>
                        )}
                        {Link && (
                          <>
                            <TableCell>
                              <div
                                className={`link_main ${
                                  roundedimage ? "roundedimage" : ""
                                }`}
                              >
                                <img src={Link?.image} alt={Link.title} />
                                <div className="link_title">
                                  <strong>{Link?.title || "N/A"}</strong>
                                  {Link?.desc && <p>{Link?.desc || "N/A"}</p>}
                                </div>
                              </div>
                            </TableCell>
                          </>
                        )}
                        {Visits && (
                          <TableCell className="visits_count">
                            {Visits}
                          </TableCell>
                        )}
                        {ProgressBar && CTR && (
                          <>
                            <TableCell className="ProgressBar_count">
                              <strong>{CTR}</strong>
                            </TableCell>
                            <TableCell className="ProgressBar_count_bar">
                              <BorderLinearProgress
                                variant="determinate"
                                value={parseFloat(CTR.replace("%", ""))}
                              />
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </>
                  );
                })}

                {BodyData?.length === 0 && (
                  <>
                    <TableRow>
                      <TableCell
                        className="no_data"
                        colSpan={TableHeadData?.length}
                      >
                        {NoDataText
                          ? NoDataText
                          : "No data available, please try again later..."}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Main>
      </InsightsTableMain>
    </>
  );
};

export default InsightsTables;
