import React from "react";
import styles from "./dashboard.module.css";

function DashboardHeader() {
  return (
    <img
      className={styles.dashboard_header_image}
      alt="header"
      src="/v2Assets/dashboard_assets/Dashboard_Header.png"
    ></img>
  );
}

export default DashboardHeader;
