import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import SyncForm from "../../components/OpportunitiesComponents/Sync/SubmitSyncForm";
import ApplyOpportunity from "../../components/OpportunitiesComponents/Opportunities/SubmitOpportunity";

const SubmitOpportunityPage = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<ApplyOpportunity />}
        activeText="Opportunities"
      />
    </Grid>
  );
};

export default SubmitOpportunityPage;
