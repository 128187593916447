import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "18px",
  paddingBottom: "20px",
  background: "#0D120F",
  //   background: "#fff",

  "& .recent_update_main .recent_update_list:last-child": {
    borderBottom: "none",
    paddingBottom: "0px",
    marginBottom: "35px",
  },
  "& h6": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "30px",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: "20px",
    marginTop: "0px",
    color: "#FFFFFF",
  },
  "& .recent_update_list": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #414241",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
  "& .recent_update_list:last-child": {
    borderBottom: "none",
    paddingBottom: "0px",
  },
  "& .recent_update_images": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    img: {
      width: "50px",
      height: "50px",
      borderRadius: "5px",
      objectFit: "cover",
    },
  },
  "& span": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#AFAFAF",
  },
  "& p": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#AFAFAF",
    margin: "0px 0px",
  },
  "& strong": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#E3E3E3",
  },

  "& .goto_link a": {
    marginTop: "20px",
    border: "1px solid #AFAFAF",
    width: "100%",
    display: "flex",
    textAlign: "center",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: theme.typography.fontFamily,
    textDecoration: "none",
    color: "#AFAFAF",
    borderRadius: "5px",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: "#2EBB55",
      color: "#fff",
      border: "1px solid #2EBB55",
    },
  },
}));

export { Main };
