import React from "react";
import styles from "./visual.module.css";

export default function BulletChart(props) {
  const data = props.analyticsData?.locationData;

  return (
    <div className={styles.bullet_chart}>
      {data?.map((interest, index) => (
        <div key={`bulletchartZ-${index}`} className={styles.interestItem}>
          <div className={styles.interestItemText}>
            <div className={styles.interestName}>
              {interest.city ?? interest.code}
            </div>
            <div className={styles.interestValue}>
              {interest.percent.toFixed(2)}%
            </div>
          </div>
          <div className={styles.horizontalBox}>
            <div
              className={styles.filler}
              style={{ width: `${interest.percent}%` }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
