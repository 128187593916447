import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const Mainswitchs = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 70,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "& .MuiSwitch-thumb": {
      color: "#fff",
    },
    "&.Mui-checked": {
      transform: "translateX(45px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2EBB55" : "#2EBB55",
        opacity: 1,
        border: 0,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
    "&:not(.Mui-checked)": {
      transform: "translateX(25px)",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "light" ? "#AFAFAF" : "#AFAFAF",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function CustomizedSwitches({ checked, onChange, title }) {
  return (
    <FormGroup>
      <FormControlLabel
        sx={{ margin: "0" }}
        control={
          <Mainswitchs
            sx={{ m: 1 }}
            checked={checked}
            val={title}
            onChange={onChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
      />
    </FormGroup>
  );
}
