import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

const MainLinkBox = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  paddingBottom: "10px",

  "& .link-heading": {
    display: "flex",

    alignItems: "center",

    width: "100%",

    "& h4": {
      fontStyle: "normal",

      fontWeight: 500,

      lineHeight: "normal",

      fontFamily: theme.typography.h4,

      color: theme.palette.secondary.light,

      marginBottom: "20px",
    },
  },

  "& .link-box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "6.25rem",
    marginTop: "10px",
    gap: "2rem",
    borderRadius: "0.3125rem",
    padding: "1rem",
    background: theme.palette.primary.darker,
    transition: "all 0.4s ease-in-out",
    "&:hover": { background: "rgba(46, 187, 85, 0.10)" },
    "& .right-content": {
      display: "flex",

      alignItems: "center",

      width: "30rem",

      height: " 4rem",

      gap: "1rem",

      "& .singer-info": {
        display: "flex",

        gap: "0.3rem",

        flexDirection: "column",

        "& h5": {
          fontFamily: theme.typography.fontFamily,

          color: theme.palette.secondary.light,

          fontSize: theme.typography.h5,

          fontStyle: "normal",

          fontWeight: 500,

          lineHeight: "normal",
        },

        "& h6": {
          fontFamily: theme.typography.fontFamily,

          color: theme.palette.secondary.dark,

          fontSize: theme.typography.h6,

          fontStyle: "normal",

          fontWeight: 400,

          lineHeight: "normal",
        },
      },
    },

    "& .left_content": {
      ul: {
        display: "none",
      },
    },
    "&:hover .MoreHorizIcon": {
      display: "none",
    },
    "&:hover ul": {
      display: "flex",
    },
  },
  a: {
    color: "#2EBB55",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textDecoration: "none",
  },
  ul: {
    display: "flex",
    padding: "0px",
    margin: "0px",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    "& li:first-child": {
      listStyle: "none",
    },
    //   "& li:nth-child(3)": {
    //     listStyle: "none",
    //     a: { color: "#AFAFAF" },
    //   },
  },
  li: {
    color: "#2EBB55",
  },
}));

export { MainLinkBox };
