import React from "react";
import { Main } from "./styles";
import { Box, Grid } from "@mui/material";
import SelectDropDown from "./SelectDropDown";
import { AnalyticsCard, AnalyticsChart } from "../..";

const DashBoardAnalytics = () => {
  return (
    <Main>
      <Box className="header">
        <h6>Dashboard</h6>
        <SelectDropDown />
      </Box>
      <Box className="box_AnalyticsCard">
        <Grid container spacing={2}>
          <Grid item lg={4} sm={12} sx={{ width: "100%" }}>
            <AnalyticsCard title="vistis" value="0" />
          </Grid>
          <Grid item lg={4} sm={12} sx={{ width: "100%" }}>
            <AnalyticsCard title="click-throughs" value="0" />
          </Grid>
          <Grid item lg={4} sm={12} sx={{ width: "100%" }}>
            <AnalyticsCard title="ctr" value="0" />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <AnalyticsChart />
      </Box>
    </Main>
  );
};

export default DashBoardAnalytics;
