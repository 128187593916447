import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
export const RleaseContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "& .heading-desc": {
    display: "flex",
    flexDirection: "column",
    gap: "0.725rem",
    width: "100%",
    "& h4": {
      color: "#E3E3E3",
      fontFamily: theme.typography.fontFamily,
      fontSize: "24px",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
    "& body1": {
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
  "& .table-box": {
    display: "flex",
    gap: "2rem",
    width: "100%",
    marginTop: "2rem",
    "& .img-box": {
      textAlign: "center",
    },
    "& .form": {
      width: "100%",
      "& .link-field-box": {
        display: "flex",
        gap: "1.5rem",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          gap: "10px",
        },
      },
      "& h5": {
        fontFamily: theme.typography.fontFamily,
        fontSize: "12px",
        color: "#AFAFAF",
        fontStyle: "italic",
        fontWeight: 400,
        lineHeight: "1.875rem",
        marginTop: "0.5rem",
        "& span": {
          color: theme.palette.secondary.main,
        },
      },
      "& .btn-box": {
        width: "100%",
        marginTop: "40px",
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down("sm")]: {
          "& .prerelease-btn": {
            width: "100%",
          },
          marginTop: "20px",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
}));

export const InputsTagsMain = styled(Box)(({ theme }) => ({
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    color: "#AFAFAF",
    marginBottom: "5px",
    display: "block",
  },
  "& .react-tagsinput": {
    display: "flex",
    alignItems: "center",
    input: {
      marginBottom: "0px",
      color: theme.palette.secondary.light,
      "::placeholder": {
        color: theme.palette.secondary.light,
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      },
      "::-webkit-input-placeholder": {
        color: theme.palette.secondary.light,
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      },
      "&:focus": {
        border: "none",
        outline: "none",
      },
    },
    "& .react-tagsinput-tag": {
      marginBottom: "0px",
      borderRadius: "2px",
      border: "1px solid #AFAFAF !important",
      color: "#AFAFAF",
      fontFamily: theme.typography.fontFamily,
      background: "transparent",
      backgroundColor: "transparent",
      padding: "3px 8px",
    },
    "& .react-tagsinput-tag a::before": {
      color: "red",
    },
    width: "100%",
    height: "2.5rem",
    padding: "0rem 0.9rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    background: "#1C1B1B !important",
    border: "1px solid #414241",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.light,
    opacity: "0.56",
  },
}));
