import React from "react";

const ThumbIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M6.9917 15.6452V7.17856C6.99201 6.84631 7.09059 6.52158 7.27503 6.24523L9.55003 2.86189C9.77482 2.55737 10.0899 2.33144 10.4504 2.21625C10.811 2.10105 11.1987 2.10244 11.5584 2.22023C11.96 2.36278 12.2987 2.64212 12.5151 3.00932C12.7314 3.37652 12.8116 3.80814 12.7417 4.22856L12.3084 6.95773C12.2907 7.07228 12.2972 7.18926 12.3273 7.30118C12.3574 7.41311 12.4106 7.51751 12.4834 7.60773C12.5558 7.68867 12.6444 7.75349 12.7434 7.798C12.8425 7.8425 12.9498 7.86569 13.0584 7.86606H16.4834C16.7829 7.85411 17.0809 7.91452 17.3522 8.04217C17.6235 8.16982 17.86 8.36096 18.0417 8.59939C18.1991 8.83647 18.2965 9.10825 18.3255 9.39133C18.3545 9.67441 18.3143 9.9603 18.2084 10.2244L16.1584 16.4619C15.9904 16.9968 15.6588 17.4655 15.2103 17.802C14.7617 18.1384 14.2189 18.3255 13.6584 18.3369H10.4084C9.7959 18.3505 9.19681 18.1567 8.70837 17.7869L7.6417 16.9619C7.4393 16.8073 7.27534 16.608 7.1626 16.3796C7.04985 16.1513 6.99137 15.8999 6.9917 15.6452Z"
        fill="#AFAFAF"
      />
      <path
        d="M4.3415 5.55273H3.48317C2.1915 5.55273 1.6665 6.05273 1.6665 7.28607V15.6694C1.6665 16.9027 2.1915 17.4027 3.48317 17.4027H4.3415C5.63317 17.4027 6.15817 16.9027 6.15817 15.6694V7.28607C6.15817 6.05273 5.63317 5.55273 4.3415 5.55273Z"
        fill="#AFAFAF"
      />
    </svg>
  );
};

export default ThumbIcon;
