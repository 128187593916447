import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import SyncForm from "../../components/OpportunitiesComponents/Sync/SubmitSyncForm";

const SubmitSyncPage = () => {
  return (
    <Grid>
      <Sidebar childComponent={<SyncForm />} activeText="Sync Placements" />
    </Grid>
  );
};

export default SubmitSyncPage;
