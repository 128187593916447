import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import { useDebounce } from "../../../../hooks/useDebounce";
import { Loader } from "../../../UniversalComponents/Loader";
import PageSearchHeader from "../../../UniversalComponents/page_search_header";
import { AllowUsersAccessRequest } from "../../../../AdminGraphqlRequest/User/UserRequests";
import styles from "../../admin.module.css";
import allImages from "../../../../assets";
import { useLazyQuery } from "@apollo/client";
import { getUsers } from "../../../../backend/admin_queries/AdminQueries";
import EditUser from "../edit_user";
import Pagination from "../../../../components-old/Pagination/Pagination";
import { ROLES } from "../../../../shared/constants";
// import style from "./under_review.module.css";

function AdminUnderReview() {
  const [checkedItems, setCheckedItems] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [query, setSearchQuery] = useState("");
  const [isView, setIsView] = useState(false);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [user, setUser] = useState();
  const [filterAnchor, setFilterAnchor] = useState(null);
  const [filterBy, setFilter] = useState(null);

  const searchQuery = useDebounce(query, 500);

  const [loadUsers] = useLazyQuery(getUsers, {
    context: { clientName: "user" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const {
        searchUserGlobaly: { users, totalCount },
      } = data;
      setUsersData(users);
      setTotalCount(Math.ceil(totalCount / numPerPage));
      setIsLoading(false);
    },
    onError(err) {
      console.log("error on getting user info", err);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    loadUsers({
      variables: {
        pageNo: currentPage,
        perPage: numPerPage,
        query: searchQuery,
        filter: { isAdminReviewed: false },
      },
    });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    loadUsers({
      variables: {
        pageNo: 1,
        perPage: numPerPage,
        query: searchQuery,
        filter: { isAdminReviewed: false },
      },
    });
  }, [searchQuery]);

  useEffect(() => {
    if (filterBy) {
      loadUsers({
        variables: {
          pageNo: currentPage,
          perPage: numPerPage,
          query: searchQuery,
          filter: { role: filterBy },
        },
      });
    }
  }, [filterBy]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   loadUsers({
  //     variables: {
  //       pageNo: currentPage,
  //       perPage: numPerPage,
  //       query: "",
  //     },
  //   });
  // }, [currentPage]);

  const approveUsers = async () => {
    try {
      const usersToApprove = Array.from(new Set(checkedItems));
      console.info(`Users to approve: ${usersToApprove}`);
      if (usersToApprove && usersToApprove.length > 0) {
        console.info("In UPDATE...");
        await AllowUsersAccessRequest(usersToApprove, true, true);
        loadUsers({
          variables: {
            pageNo: currentPage,
            perPage: numPerPage,
            query: "",
            filter: { isAdminReviewed: false },
          },
        });
        setCheckedItems(new Set());
        toast.success("Users have been approved successfully!");
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const rejectUsers = async () => {
    try {
      const usersToReject = Array.from(new Set(checkedItems));
      console.info(`Users to reject: ${usersToReject}`);
      if (usersToReject && usersToReject.length > 0) {
        console.info("In UPDATE...");
        await AllowUsersAccessRequest(usersToReject, false, true);
        loadUsers({
          variables: {
            pageNo: currentPage,
            perPage: numPerPage,
            query: "",
            filter: { isAdminReviewed: false },
          },
        });
        setCheckedItems(new Set());
        setIsView(false);
        toast.success("Users have been rejected successfully!");
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const approveSingleUser = async (_id) => {
    try {
      const approveUser = await AllowUsersAccessRequest([_id], true, true);
      setIsView(false);
      toast.success("User approve successfully!");
      loadUsers({
        variables: {
          pageNo: currentPage,
          perPage: numPerPage,
          query: "",
          filter: { isAdminReviewed: false },
        },
      });
    } catch (error) {
      setIsView(false);
      console.log("Error while approving user =", error);
    }
  };

  const rejectSingleUser = async (_id) => {
    try {
      const rejectUser = await AllowUsersAccessRequest([_id], false, true);
      console.info("rejectUser =", rejectUser);
      toast.success("User request rejected successfully!");
      loadUsers({
        variables: {
          pageNo: currentPage,
          perPage: numPerPage,
          query: "",
          filter: { isAdminReviewed: false },
        },
      });
    } catch (error) {
      console.info("Error while rejecting user request =", error);
    }
  };

  const toggleCheck = (id) => {
    setCheckedItems((prevChecked) => {
      const newChecked = new Set(prevChecked);
      if (newChecked.has(id)) {
        newChecked.delete(id);
      } else {
        newChecked.add(id);
      }
      return newChecked;
    });
  };

  const applyFilter = (value) => {
    console.info("applyFilter value =", value);
    if (value === filterBy) {
      setFilter("");
      setFilterAnchor(null);
    } else {
      setFilter(value);
      setFilterAnchor(null);
    }
  };

  const handleFilterClick = (event) => {
    setFilterAnchor(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchor(null);
  };

  const handleUser = (user) => {
    setUser(user);
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchQuery = (value) => {
    setSearchQuery(value);
  };

  return isLoading ? (
    <Box className={styles.selected_beat_main_layout}>
      <Loader />
    </Box>
  ) : (
    <div className={styles.sub_page_layout}>
      <div className={styles.inner_page_layout}>
        <PageSearchHeader
          title="Under Review"
          searchBar="Search Open Applications"
          showFilter={false}
          applyFilter={applyFilter}
          filterData={Object.values(ROLES)}
          showApprove={checkedItems.size > 0}
          showApproveTitle="Accept All"
          showReject={checkedItems.size > 0}
          approveUsers={approveUsers}
          setSearchQuery={handleSearchQuery}
          rejectUsers={rejectUsers}
          filterAnchor={filterAnchor}
          setFilterAnchor={setFilterAnchor}
          handleFilterClick={handleFilterClick}
          handleFilterClose={handleFilterClose}
        />
        {/* 
        // no need of the column      
      <div className={styles.table_sections}>
      </div> */}
        <div className={styles.table_content}>
          {usersData.length > 0 ? (
            usersData.map((data, index) => (
              <React.Fragment key={`userreviewZ-${index}`}>
                {data.isAdminReviewed === false && (
                  <div className={styles.user_content} key={data._id}>
                    <div className={styles.checkboxPlusImageBox}>
                      <div className={styles.checkbox_column}>
                        {checkedItems.has(data._id) ? (
                          <div
                            className={styles.unchecked}
                            onClick={() => toggleCheck(data._id)}
                          >
                            <img
                              className={styles.unchecked_image}
                              src="/check.png"
                              alt="check"
                            ></img>
                          </div>
                        ) : (
                          <div
                            className={styles.checkbox}
                            onClick={() => toggleCheck(data._id)}
                          ></div>
                        )}
                      </div>
                      <div
                        className={`${styles.user_info} ${styles.profile_column}`}
                      >
                        <img
                          src={data.profilePic}
                          className={styles.user_image}
                          alt="user"
                        ></img>
                        <div
                          className={`${styles.user_info_text} ${styles.user_info_admin_text}`}
                        >
                          <p
                            className={`${styles.user_title} ${styles.user_title_under_review}`}
                          >
                            {data.firstname} {data.lastname}
                          </p>
                          <p
                            className={`${styles.user_username} ${styles.user_data_under_review}`}
                          >
                            @{data.instaUrl}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className={styles.labelName}>
                      <p className={styles.label_btn}>
                        {data?.roleId?.roleName}
                      </p>
                    </div>
                    <div className={styles.viewButton}>
                      <p
                        className={styles.view_info}
                        onClick={() => {
                          handleUser(data);
                          setIsView(true);
                        }}
                      >
                        View
                      </p>
                    </div>

                    <div className={styles.acceptDecline}>
                      <div
                        className={styles.closeIcon}
                        onClick={() => rejectSingleUser(data._id)}
                      >
                        <img
                          className={styles.crossIcon}
                          src={allImages.crossWhiteIcon}
                          alt="cancelIcon"
                        />
                      </div>
                      <div
                        className={styles.correctIcon}
                        onClick={() => approveSingleUser(data._id)}
                      >
                        <img
                          className={styles.correctImg}
                          src={allImages.correctIcon}
                          alt="correctIcon"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#fff",
                alignItems: "center",
                marginTop: 80,
              }}
            >
              <Typography>No Data Found</Typography>
            </Box>
          )}
        </div>
        {/* <div className={styles.table_pagination}></div> */}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalCount}
        onPageChange={handlePageChange}
      />
      <EditUser
        editForm={isView}
        EditFormClose={() => {
          setIsView(false);
        }}
        isReadOnly={true}
        user={user}
        isView={isView}
        fetchUsers={() =>
          loadUsers({
            variables: {
              pageNo: currentPage,
              perPage: numPerPage,
              query: "",
            },
          })
        }
        handleApproveUser={approveSingleUser}
        handleReject={rejectSingleUser}
        setUsersData={setUsersData}
        buttonText={["REJECT", "ACCEPT"]}
      />
    </div>
  );
}

export default AdminUnderReview;
