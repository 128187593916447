import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { RadioMainBox } from "./style/RadioButtonsStyle";

const RadioButtons = ({
  title = "",
  label1 = "",
  label2,
  onChange,
  defaultValue,
}) => {
  return (
    <RadioMainBox>
      <h5>{title}</h5>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(e) => onChange(e.target.value)}
        value={defaultValue}
      >
        <FormControlLabel
          value={label1}
          control={<Radio className="radio-style" />}
          label={label1}
          sx={{ color: "#fff" }}
        />
        <FormControlLabel
          value={label2}
          control={<Radio className="radio-style" />}
          label={label2}
          sx={{ color: "#fff" }}
        />
      </RadioGroup>
    </RadioMainBox>
  );
};

export default RadioButtons;
