import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const DashboardMain = styled(Box)(({ theme }) => ({
  //   display: "flex",
  width: "100%",
  //   alignItems: "flex-start",
  marginTop: "30px",
  marginBottom: "20px",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   "& h6": {
  //     fontFamily: theme.typography.fontFamily,
  //     fontSize: "24px",
  //     fontWeight: 500,
  //     lineHeight: "36px",
  //     letterSpacing: "0em",
  //     textAlign: "left",
  //     margin: "0px 0px",
  //   },
  //   button: {
  //     borderRadius: "5px",
  //     background: "#2EBB55",
  //     color: "#FFF",
  //     fontFamily: theme.typography.fontFamily,
  //     fontSize: "16px",
  //     fontStyle: "normal",
  //     fontWeight: 500,
  //     lineHeight: "normal",
  //     textTransform: "capitalize",
  //     padding: "8px 20px",
  //     "&:hover": { background: "#2EBB55" },
  //   },
}));

export { DashboardMain };
