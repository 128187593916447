import React, { useEffect, useRef, useState } from "react";
import NotifyIcon from "./NotifyIcon";
import { NotificationsMainBox } from "./style/NotificationStyle";
import { Box, Button } from "@mui/material";
import axios from "axios";
import useAuth from "../../../../context/AuthContext";
import Timeago from "../Timeago/Timeago";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Notification } from "../../../NotifyMsg/Notification";
import { Notificationsocket } from "../../../../context/web-socket/socket";
import useInfiniteScroll from "react-infinite-scroll-hook";

const NotificationsMain = () => {
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const { user } = useAuth();

  const [pageNumber, setPageNumber] = useState(1);
  const [lastPageReached, setLastPageReached] = useState(false);
  const [callRefreshAPiStatus, setCallRefreshAPiStatus] = useState(false);
  const refreshApiRef = useRef(null);

  const UserID = user._id;
  const navigate = useNavigate();
  const { pathname } = location;

  // ********** Marks notifications as read **********
  const markAsRead = async (ids) => {
    if (!ids) return;

    const readNotificationURL = `${process.env.REACT_APP_NOTIFICATIONS_URL}/notification/read-notifications/${UserID}`;

    const payload = {
      notificationIds: ids,
    };

    try {
      await axios.post(readNotificationURL, payload);

      return true;
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // ********** Fetches notifications **********
  const fetchNotifications = async (pageNumberToFetch) => {
    let perpage = 10;
    if (lastPageReached === true) {
      setLoadMore(false);

      return;
    }
    pageNumberToFetch === 1 ? setIsLoading(true) : setLoadMore(true);

    try {
      if (pageNumberToFetch === 1) {
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFICATIONS_URL}/notification/get-notifications/${UserID}?page=${pageNumberToFetch}&pageSize=${perpage}`
        );

        const newNotifications = response?.data?.items;

        setNotifications(newNotifications);

        const notificationIds = newNotifications.map((item) => item._id);

        if (notificationIds.length > 0) {
          markAsRead(notificationIds);
        }

        setIsLoading(false);
      } else {
        if (!notifications.length) {
          setIsLoading(false);
          setLoadMore(false);
          return;
        }
        let userSeekId = notifications.slice(-1)[0]._id;
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFICATIONS_URL}/notification/get-notifications/${UserID}?pageSize=${perpage}&seekId=${userSeekId}`
        );

        const newNotifications = response?.data?.items;
        if (!newNotifications.length) {
          setLastPageReached(true);
          setLoadMore(false);
          return;
        }
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...newNotifications,
        ]);

        const notificationIds = newNotifications.map((item) => item._id);

        if (notificationIds.length > 0) {
          markAsRead(notificationIds);
        }

        setLoadMore(false);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setIsLoading(false);
      setLoadMore(false);
    }
  };

  // useEffect(() => {

  //   Notificationsocket.on("send-notification-user", async (value) => {
  //     if (value && pathname === "/notification") {
  //       await fetchNotifications();
  //     }
  //   });
  //   return () => {
  //     // Cleanup the socket event listener when the component unmounts
  //     Notificationsocket.off("send-notification-user");
  //   };
  // }, [Notificationsocket]);

  // ********** Infinite Scroll Hook **********
  const [infiniteLoaderRef] = useInfiniteScroll({
    loading: isLoading || loadMore,
    hasNextPage: !lastPageReached,
    onLoadMore: () => {
      setPageNumber((prev) => prev + 1);
    },
  });

  // ********** Call Notification Api Based on PageNumber **********
  useEffect(() => {
    fetchNotifications(pageNumber);
  }, [pageNumber]);

  // ********** Function for Refreshing Notifications **********
  const refreshFetchNotifications = async (refreshNumber) => {
    let perpage = 10;

    try {
      if (!callRefreshAPiStatus) {
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_NOTIFICATIONS_URL}/notification/get-notifications/${UserID}?page=${refreshNumber}&pageSize=${perpage}`
      );

      const newNotifications = response?.data?.items;

      const filteredNotifications = newNotifications?.filter((newNotif) => {
        return !notifications.some((notif) => notif._id === newNotif._id);
      });

      // Update state only if there are new notifications
      if (filteredNotifications.length > 0) {
        filteredNotifications.length < 9
          ? setNotifications((prevNotifications) => [
              ...filteredNotifications,
              ...prevNotifications,
            ])
          : setNotifications(newNotifications);

        const notificationIds = filteredNotifications?.map((item) => item._id);

        if (notificationIds.length > 0) {
          markAsRead(notificationIds);
        }
        setIsLoading(false);
      } else {
        return;
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // ********** Interval for Automatic Refresh of Notifications **********
  useEffect(() => {
    const fetchInterval = setInterval(() => {
      refreshFetchNotifications(1);
    }, 10000);

    return () => {
      clearInterval(fetchInterval);
    };
  }, [callRefreshAPiStatus]);

  // ********** Delete Notification Handler **********
  const HandelDelete = async (id) => {
    if (!id) {
      Notification("error", "Id is missing.");
    }
    const response = await axios.delete(
      `${process.env.REACT_APP_NOTIFICATIONS_URL}/notification/delete-notification/${id}`
    );
    if (response.status === 200 || response.status === 201) {
      Notification("success", "Notification deleted successfully.");
      const updatedArray = notifications.filter((item) => item._id !== id);
      setNotifications(updatedArray);
    }
  };

  // ********** Intersection Observer Callback Function **********
  const entryCallBackFunc = (entries) => {
    const [entry] = entries;
    setCallRefreshAPiStatus(entry.isIntersecting);
  };

  // ********** Refresh Options **********
  const refreshOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  };

  // ********** Observing Intersection with Refresh API Ref **********
  useEffect(() => {
    const observer = new IntersectionObserver(
      entryCallBackFunc,
      refreshOptions
    );

    if (refreshApiRef.current) {
      observer.observe(refreshApiRef.current);
    }

    return () => {
      if (refreshApiRef.current) {
        observer.disconnect(refreshApiRef.current);
      }
    };
  }, [refreshOptions, refreshApiRef]);

  // ********** Renders Buttons and Redirects based on Notification Type **********
  const ButtonsAndRedirects = ({ link, buttonType, notificationId }) => {
    let buttonName = "";

    switch (buttonType) {
      case "beat-favorite":

      case "beat-select":

      case "opportunity-download":

      case "opportunity-expire-one-day":

      case "opportunity-expire-one-week":

      case "opportunity-favorited":

      case "opportunity-selected":
        buttonName = "View My Submission";

        break;

      case "beat-download":
        buttonName = "View Details";

        break;

      case "beat-submission":
        buttonName = "View Opportunity";

        break;

      case "beat-expire-one-day":

      case "beat-expire-two-day":
        buttonName = "Extend";

        break;

      case "new-opportunity":
        buttonName = "View Opportunities";

        break;

      case "opportunity-submission":
        buttonName = "Review Beats";

      default:
        buttonName = "View Details";
    }
    console.log("sachin", isLoading);

    return (
      <Box className="btn-box">
        {link && (
          <Button
            variant="contained"
            onClick={() => {
              navigate(link);
            }}
          >
            {buttonName}
          </Button>
        )}

        <Button
          variant="outlined"
          className="btn_cancel"
          onClick={() => HandelDelete(notificationId)}
        >
          <CloseIcon />
        </Button>
      </Box>
    );
  };

  return (
    <>
      {isLoading ? (
        <p style={{ color: "white", paddingTop: "10px", textAlign: "center" }}>
          Loading...
        </p>
      ) : notifications?.length === 0 ? (
        <div
          style={{
            height: "100px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          No Notification Found
        </div>
      ) : (
        <>
          <div ref={refreshApiRef}></div>
          {notifications?.map((Notif) => {
            const notificationTypeLabels = {
              "beat-download": "View Details",
              "beat-selected": "beatheart_selected",
              "beat-favorite": "View my Submissions",
              "opportunity-expire-one-day": "View Opportunities",
              "opportunity-expire-one-day": "View Opportunities",
            };

            const NotificationTypeButton =
              notificationTypeLabels[Notif?.notification_type] ||
              "View Details";

            return (
              <NotificationsMainBox key={Notif?._id}>
                <NotifyIcon
                  isRead={Notif.is_read}
                  type={Notif?.notification_type}
                />
                <Box className="right-main">
                  <h5 className="notify_title">{Notif.title}</h5>
                  <h6 className="notify_desc">{Notif.description}</h6>
                  <Box className="btn-main">
                    <span>
                      <Timeago date={Notif.created_at} />
                    </span>
                    {/* beat-inbox.*/}

                    <ButtonsAndRedirects
                      link={Notif?.link}
                      buttonType={Notif?.notification_type}
                      notificationId={Notif?._id}
                    />
                  </Box>
                </Box>
              </NotificationsMainBox>
            );
          })}
        </>
      )}
      {/* ########## Load More Notification ##########  */}

      <div ref={infiniteLoaderRef}></div>
      {loadMore && (
        <p style={{ color: "white", paddingTop: "10px", textAlign: "center" }}>
          Loading more...
        </p>
      )}
    </>
  );
};

export default NotificationsMain;
