import React from "react";
import ProducersLayouts from "./ProducersLayouts";
import AuthWelcomePopup from "../../../../components/smartlink/producers/auths/AuthWelcomePopup";

const ProducersWelcomePage = () => {
  return (
    <ProducersLayouts>
      <AuthWelcomePopup />
    </ProducersLayouts>
  );
};

export default ProducersWelcomePage;
