import { Box, Divider, HStack, Image } from "native-base";
import React from "react";
import { useRef } from "react";
import Footer from "./Footer/Footer";
import GetNotified from "./Getnotified/GetNotified";
import HeaderComp from "./Header/HeaderComp";
import NewLandingPageBanner from "./NewLandingPageBanner";
import Services from "./Services";

function LandingPage() {
  const homeOnClickRef = useRef(null);
  const scrollEffect = (targetRef) => {
    targetRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <div>
      <HeaderComp scrollFtn={scrollEffect} homeOnClickRef={homeOnClickRef} />
      <NewLandingPageBanner />
      <Services homeOnClickRef={homeOnClickRef} />
      {/* <GetNotified /> */}
      <Footer />
    </div>
  );
}

export default LandingPage;
