import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { RadioMainBox } from "./styles/RadioButtonsStyle";

const RadioButtons = ({
  title = "",
  label1 = "",
  label2 = "",
  onChange = () => {},
  defaultValue = "",
}) => {
  return (
    <RadioMainBox>
      <h5>{title}</h5>
      <RadioGroup
        sx={{
          gap: `${title == "Inbox Type" ? "30px" : "40px"}`,
        }}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(e) => onChange(e.target.value)}
        value={defaultValue}
        defaultValue={` ${defaultValue == "Public" ? label1 : label2} `}
      >
        <FormControlLabel
          value={label1}
          control={<Radio className="radio-style" />}
          label={label1}
          sx={{ color: "#fff", marginLeft: "0", gap: "10px" }}
        />
        <FormControlLabel
          value={label2}
          control={<Radio className="radio-style" />}
          label={label2}
          sx={{ color: "#fff", gap: "10px" }}
        />
      </RadioGroup>
    </RadioMainBox>
  );
};

export default RadioButtons;
