import React from "react";
import beatInfoStyle from "../../components/SubmissionsComponents/ViewSubmissionInformation/beatinfo.module.css";
import crossIcon from "../../assets/Icons/crossIcon.png";

function BeatAddModal(props) {
  return (
    <div className={beatInfoStyle.popup_outer_wrapper}>
      <div className={beatInfoStyle.popup_wrapper}>
        <div className={beatInfoStyle.header}>
          <span className={beatInfoStyle.title}>{props.title}</span>
          <img
            src={crossIcon}
            alt="crossIcon"
            className={beatInfoStyle.cross_icon}
            onClick={props.handleClose}
          />
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default BeatAddModal;
