import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const RecentIntegrationBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "auto",
  padding: "40px 0px",
  gap: "50px",
}));
const MainCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  borderRadius: "0.625rem",
  background: "#0A0B0A",
  color: "#fff",
  // gap: "3rem",
  "& .beat_link_main": {
    display: "flex",
    alignItems: "center",
    background: "#141515",
    paddingLeft: "5px",
    "& .beat_icon": {
      marginRight: "5px",
    },
    h6: {
      marginRight: "5px",
    },
  },

  "& .artistName": {
    "& span": {
      color: "#2EBB55",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
  "& .main_heading": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .main_heading_status": {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    h5: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      fontFamily: theme.typography.fontFamily,
      textTransform: "capitalize",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& span.Draft": {
      color: "#D9C400",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "& span.Live": {
      color: "#2EBB55",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
  "& .image_container": {
    height: "28vh",
    marginBottom: "12px",
    position: "relative",
    transition: "all 0.4s ease-in-out",
    "& .box_image": {
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    "&:hover::after": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      background: "rgba(0, 0, 0, 0.70)",
      zIndex: 1,
    },
    "&:hover .image_container_hover": {
      opacity: 1,
    },
  },
  "& .image_container_hover": {
    opacity: 0,
    zIndex: 2,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: " translate(-50%, -50%)",
    width: "80%",
    ul: {
      display: "flex",
      padding: "0px",
      margin: "0px",
      alignItems: "center",
      justifyContent: "center",
      gap: "30px",
      "& li:first-child": {
        listStyle: "none",
      },
      //   "& li:nth-child(3)": {
      //     listStyle: "none",
      //     a: { color: "#AFAFAF" },
      //   },
    },
    li: {
      color: "#2EBB55",
    },
    a: {
      color: "#2EBB55",
      fontFamily: theme.typography.fontFamily,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textDecoration: "none",
    },
  },

  "& .content": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "& h6": {
      color: theme.palette.secondary.dark,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.h6,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },

  "& .link_titile": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& h6": {
      color: theme.palette.secondary.dark,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.h6,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    img: {
      cursor: "pointer",
    },
  },
  "& h5": {
    color: theme.palette.secondary.light,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  "& .title-box": {
    display: "flex",
    justifyContent: "space-between",
    "& h6": {
      color: theme.palette.secondary.dark,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.h6,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
}));
export { RecentIntegrationBox, MainCard };
