import React from 'react';
import styles from './../styles.module.css';
export const EditIcon = () => {
  return (
    <svg
      className={styles.editIcon}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13'
        stroke='#2EBB55'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M16.0398 3.02001L8.15984 10.9C7.82205 11.2705 7.59359 11.7275 7.49984 12.22L7.06984 15.23C7.01761 15.464 7.02532 15.7075 7.09225 15.9377C7.15918 16.168 7.28317 16.3776 7.45272 16.5471C7.62228 16.7167 7.8319 16.8407 8.06214 16.9076C8.29239 16.9745 8.53582 16.9822 8.76984 16.93L11.7798 16.5C12.2709 16.402 12.7268 16.1741 13.0998 15.84L20.9798 7.96001C22.3398 6.60001 22.9798 5.02001 20.9798 3.02001C18.9798 1.02001 17.3998 1.66001 16.0398 3.02001Z'
        stroke='#2EBB55'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.9102 4.1499C15.2417 5.32737 15.8701 6.39997 16.7351 7.26495C17.6001 8.12993 18.6727 8.75831 19.8502 9.0899'
        stroke='#2EBB55'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
