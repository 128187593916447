import React, { useState, useContext, useEffect, useRef } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Modal, makeStyles, Checkbox } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import styles from "../mycontracts.module.css";
import { Notification } from "../../NotifyMsg/Notification";
import TrashIcon from "../../../assets/trash.png";
import EditIcon from "../../../assets/edit-2.png";
import LogoIcon from "../../../assets/Logo/pay-logo.png";
import CloseIcon from "../../../assets/close.png";
import MicoLogo from "../../../assets/mic-logo.png";
import TwoCircle from "../../../assets/two-circle.png";
import FailedIcon from "../../../assets/FailedIcon.png";
import SuccessIcon from "../../../assets/trash-success.png";
import { useStyles, CARD_OPTIONS } from "../../../shared/constants";

function SendTemplate() {
  const classes = useStyles();

  const [roleFirstNames, setRoleFirstNames] = useState([""]);
  const [rolelastNames, setRoleLastNames] = useState([""]);
  const [roleEmails, setRoleEmails] = useState([""]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [isFreemium, setIsFreemium] = useState(true);
  const [isPaid, setIsPaid] = useState(false);
  const [showPayDialog, setShowPayDialog] = useState(false);
  const [isCardShow, setIsCardShow] = useState(false);

  const [cardName, setCardName] = useState("*******");
  const [cardError, setCardError] = useState("");

  const [documentTitle, setDocumentTitle] = useState("");
  const [documentMessage, setDocumentMessage] = useState("");

  const [titleRemain, setTitleRemain] = useState(52);
  const [messageRemain, setMessageRemain] = useState(10000);

  const [showFailedModal, setShowFailedModal] = useState(false);
  const [showContractSentModal, setShowContractSentModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const router = useNavigate();
  const { id } = useParams();


  useEffect(async () => {
    const token = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_CONTRACT_URL}/templates/${id}`,
      headers: {
        accesstoken: token,
      },
    };
    const response = await axios(config);
    console.log(response.data);
    setSelectedTemplate(response.data);
    setIsFreemium(response.data.isFreemium);
  }, []);

  const isValid = async () => {
    if (documentTitle === "") {
      Notification("error", "Enter the Document Title");
      return;
    }
    for (let i = 0; i < roleFirstNames.length; i++) {
      if (roleFirstNames[i] === "") {
        Notification("error", "Enter the usernames");
        return;
      }
      if (roleEmails[i] === "") {
        Notification("error", "Enter the emails");
        return;
      }
    }
    let isPay = true;
    if (!isFreemium) {
      try {
        const config = {
          method: "get",
          url: `${process.env.REACT_APP_CONTRACT_URL}/templates/get-user-contract-counts`,
          headers: {
            accesstoken: localStorage.getItem("token"),
          },
        };
        const response = await axios(config);
        isPay = response.data >= 3;
      } catch (err) {
        Notification("error", err.message);
        return;
      }
    }
    if (isPay) {
      setShowPayDialog(true);
    } else {
      sendTemplate();
    }
  };
  const addCardSubmit = async (e) => {
    e.preventDefault();

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardNumberElement,
    // });
    // console.log("PaymentMethod", paymentMethod);

    const { token, error } = await stripe.createToken(cardNumberElement);

    if (!error) {
      try {
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_CONTRACT_URL}/create-payment-intent`,
          data: {
            amount: isFreemium ? 575 : 275,
            token: token,
          },
          headers: {
            accesstoken: localStorage.getItem("token"),
          },
        };
        const response = await axios(config);
        const confirmPayment = await stripe.confirmCardPayment(
          response.data.clientSecret,
          { payment_method: { card: cardNumberElement } }
        );

        if (confirmPayment.paymentIntent) {
          setIsCardShow(false);
          sendTemplate();
        } else if (confirmPayment.error) {
          // Payment failed, handle the error
          // Notification("error", `${confirmPayment.error.message}`);
          setShowFailedModal(true);
        }
      } catch (error) {
        Notification("error", `${error.message}`);
        return;
      } finally {
      }
    } else {
      setCardError(error.code);
      return;
    }
  };
  const sendTemplate = async () => {
    var data = new FormData();
    data.append("roleFirstNames", roleFirstNames);
    data.append("roleLastNames", rolelastNames);
    data.append("roleEmails", roleEmails);
    data.append("roleTypes", selectedTemplate.roleTypes);
    data.append("owner", "Ilarion");
    data.append("title", documentTitle)
    data.append('message', documentMessage)

    for (let i = 0; i < roleFirstNames.length; i++) {
      if (
        roleFirstNames[i] === "" ||
        rolelastNames[i] === "" ||
        roleEmails[i] === ""
      )
        return;
    }
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_CONTRACT_URL}/templates/send/${id}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        accesstoken: `${localStorage.getItem("token")}`,
      },
    };
    try {
      await axios(config);
      setShowContractSentModal(true);
    } catch (err) {
      Notification("error", err.message);
    }
  };

  const handleRoleFirstName = (e, id) => {
    const name = e.target.value;
    let roleFirstNameStream = [...roleFirstNames];
    roleFirstNameStream[id] = name;
    setRoleFirstNames(roleFirstNameStream);
  };
  const handleRoleLastName = (e, id) => {
    const name = e.target.value;
    let roleLastNameStream = [...rolelastNames];
    roleLastNameStream[id] = name;
    setRoleLastNames(roleLastNameStream);
  };

  const handleRoleEmail = (e, id) => {
    const email = e.target.value;
    let roleEmailStream = [...roleEmails];
    roleEmailStream[id] = email;
    setRoleEmails(roleEmailStream);
  };

  return (
    <div>
      <div className={styles.template_container}>
        <div className={styles.middle_title_layout}>
          <div className={styles.middle_title_font}>
            <p className={styles.middle_title_recip}>
              {selectedTemplate.title}
            </p>
          </div>
        </div>

        <div className={styles.middle_title_layout}>
          {selectedTemplate.roleTypes?.map((item, index) => (
            <div key={index} className={styles.recipient_group}>
              <div className={styles.recipient_rectangle} />
              <div className={styles.recipient_form_group}>
                <div className={styles.user_name_group}>
                  <label className={styles.user_name_label}>{item}</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 24,
                      width: "50%",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        First Name
                      </label>
                      <input
                        type="text"
                        className={styles.recipient_user_name}
                        value={roleFirstNames[index]}
                        onChange={(e) => handleRoleFirstName(e, index)}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        Last Name
                      </label>
                      <input
                        type="text"
                        className={styles.recipient_user_name}
                        value={rolelastNames[index]}
                        onChange={(e) => handleRoleLastName(e, index)}
                      />
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.recipient_group_user_label}>
                        Email
                      </label>
                      <input
                        type="email"
                        className={styles.recipient_user_name}
                        value={roleEmails[index]}
                        onChange={(e) => handleRoleEmail(e, index)}
                      />
                    </div>
                  </div>
                  <img src={EditIcon} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.middle_title_layout}>
          <div className={styles.middle_title_font}>
            <p style={{ fontSize: 30, fontWeight: 500, margin: 0 }}>Document</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 24,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 24,
                width: "100%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={styles.recipient_group_user_label}>
                  Title
                </label>
                <input
                  type="text"
                  className={styles.recipient_user_name}
                  value={documentTitle}
                  onChange={(e) => {
                    const lenth = e.target.value.length;
                    if (lenth > 52) return;
                    setTitleRemain(52 - lenth);
                    setDocumentTitle(e.target.value);
                  }}
                />
                <label
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    fontWeight: 400,
                    height: 18,
                    color: "#E3E3E3",
                  }}
                >
                  Character remaining:{titleRemain}
                </label>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={styles.recipient_group_user_label}>
                  Message
                </label>
                <textarea
                  type="text"
                  className={styles.email_message}
                  rows={5}
                  value={documentMessage}
                  onChange={(e) => {
                    const lenth = e.target.value.length;
                    if (lenth > 10000) return;
                    setMessageRemain(10000 - lenth);
                    setDocumentMessage(e.target.value);
                  }}
                />
                <label
                  style={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    fontWeight: 400,
                    height: 18,
                    color: "#E3E3E3",
                  }}
                >
                  Character remaining:{messageRemain}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sidebar_bottom_button_container}>
        <button
          className={styles.sidebar_bottom_button}
          onClick={() => router("/my-contracts/templates")}
        >
          Prev
        </button>
        <button
          className={styles.sidebar_bottom_button}
          onClick={async () => {
            isValid();
          }}
        >
          Send
        </button>
      </div>
      <Modal
        open={showPayDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%" }}
          className={classes.modalpaper}
        >
          <div className={classes.modalimgcontainer}>
            <img src={LogoIcon} style={{ width: 100, height: 100 }} />
            <div>
              <div>
                <p className={classes.modaltext2}>
                  You need to pay{" "}
                  <span className={classes.priceText}>
                    {isFreemium ? "$5.75" : "$2.75"}
                  </span>{" "}
                  to send a contract.
                </p>
              </div>
              <div className={classes.modalbuttonContainer}>
                <button
                  className={classes.modalCancelbutton}
                  onClick={() => setShowPayDialog(false)}
                >
                  Cancel
                </button>
                <button
                  className={classes.modalDeletebutton}
                  onClick={() => {
                    setIsCardShow(true);
                    setShowPayDialog(false);
                  }}
                >
                  Pay
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={showFailedModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%" }}
          className={classes.modalpaper}
        >
          <img
            className={classes.modalcloseimg}
            src={CloseIcon}
            onClick={() => setShowFailedModal(false)}
          />
          <div className={classes.modalimgcontainer}>
            <img src={FailedIcon} style={{ width: 100, height: 100 }} />
            <p className={classes.modaltest1}>Payment Failed</p>

            <div>
              <div>
                <p className={classes.modaltext2}>
                  Something went wrong we couldn’t process your payment.
                </p>
              </div>
              <div className={classes.modalonebuttonContainer}>
                <button
                  className={classes.modalDeletebutton}
                  onClick={() => {
                    setIsCardShow(true);
                    setShowPayDialog(false);
                  }}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={showContractSentModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%" }}
          className={classes.modalpaper}
        >
          <img
            className={classes.modalcloseimg}
            src={CloseIcon}
            onClick={() => {
              setShowContractSentModal(false);
              router("/my-contracts/sent");
            }}
            alt=""
          />
          <div className={classes.modalimgcontainer}>
            <img src={SuccessIcon} style={{ width: 100, height: 100 }} alt="" />
            <p className={classes.modaltest1}>Contract Sent</p>

            <div>
              <div>
                <p className={classes.modaltext2}>
                  Congratulation your contract has been successfully sent.
                </p>
              </div>
              <div className={classes.modalonebuttonContainer}>
                <button
                  className={classes.modalDeletebutton}
                  onClick={() => {
                    setShowContractSentModal(false);
                    router("/my-contracts/sent");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={isCardShow}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%" }}
          className={classes.addcardmodalpaper}
        >
          <img
            className={classes.modalcloseimg}
            src={CloseIcon}
            onClick={() => setIsCardShow(false)}
          />
          <div className={classes.addcardmodalimgcontainer}>
            <p className={classes.modaltitle}>Pay Now</p>
            <div className={classes.addedcardContainer}>
              <img src={MicoLogo} className={classes.micImg} />
              <p className={classes.cardnumber}>{"**** **** **** ****"}</p>
              <p className={classes.cardname}>{cardName}</p>
              <div className={classes.overlayCard}>
                <p className={classes.expdate}>
                  Exp{" "}
                  <span style={{ fontSize: 12, fontWeight: 600 }}>
                    {"**/**"}
                  </span>
                </p>
                <img src={TwoCircle} className={classes.circleImage} />
              </div>
            </div>
          </div>

          <form onSubmit={addCardSubmit}>
            <div className={classes.carddetailcontainer}>
              <div className={classes.carddetail}>
                <p className={classes.carddetailtext}>Card Detail</p>
              </div>
              <div className={classes.carditemcontainer}>
                <div className={classes.cardinputgroup}>
                  <p className={classes.inputtag}>Name on Card</p>
                  <input
                    className={classes.inputelement}
                    name="card-name"
                    onChange={(e) => setCardName(e.target.value)}
                  />
                </div>
                <div className={classes.cardinputgroup}>
                  <p className={classes.inputtag}>Card Number</p>
                  <CardNumberElement
                    options={CARD_OPTIONS}
                    className={classes.inputelement}
                    onChange={() => setCardError('')}
                  />
                  {cardError.indexOf("number") !== -1 && (
                    <p style={{ margin: 0, color: "red" }}>
                      Invalid Card Number
                    </p>
                  )}
                </div>
                <div className={classes.cardinputgroup}>
                  <p className={classes.inputtag}>Expiration Date</p>

                  <CardExpiryElement
                    options={CARD_OPTIONS}
                    className={classes.inputelement}
                    onChange={() => setCardError('')}
                  />
                  {cardError.indexOf("expiry") !== -1 && (
                    <p style={{ margin: 0, color: "red" }}>
                      Invalid Expiration Date
                    </p>
                  )}
                </div>
                <div className={classes.cardinputgroup}>
                  <p className={classes.inputtag}>CVV</p>
                  <CardCvcElement
                    options={CARD_OPTIONS}
                    className={classes.inputelement}
                    onClick={() => setCardError('')}
                  />
                  {cardError.indexOf("cvc") !== -1 && (
                    <p style={{ margin: 0, color: "red" }}>Invalid CVC</p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className={classes.futureContainer}>
                <Checkbox style={{ color: "#AFAFAF", padding: 0 }} />
                <p style={{ color: "#AFAFAF", fontSize: 16, margin: 0 }}>
                  Use this card for future purchases
                </p>
              </div>
              <button className={classes.continueButton}>Continue</button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
export default SendTemplate;
