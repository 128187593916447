import React, { memo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { ModelContentBox } from "./style";
import theme from "../../theme";
import { delete_icon } from "../../../../assets/smartlink";
import { useMutation } from "@apollo/client";
import {
  DELETE_BEAT_Box_MUTATION,
  GetAllActiveBeatBoxes,
} from "../../../../Graphql_services/beatinbox/Beats";
import { Notification } from "../../../NotifyMsg/Notification";
import { findNodeHandle } from "react-native-web";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "background.paper",
  //   boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const MainDeletePopUp = ({ open = false, setOpen = () => {}, ID = "" }) => {
  const [Isloading, setisloading] = useState(false);

  const [deleteBeat] = useMutation(DELETE_BEAT_Box_MUTATION, {
    refetchQueries: [GetAllActiveBeatBoxes, "getActiveBeatBoxes"],
    context: { clientName: "beats" },
  });
  const DeleteModelClose = () => {
    setOpen(!open);
  };
  const handleDelete = async () => {
    if (!ID) {
      Notification("error", "Id is required");
    }
    try {
      setisloading(true);
      const response = await deleteBeat({
        variables: { id: ID },
      });

      if (response?.data?.deleteBeatBox?.success) {
        Notification("success", "Inbox Deleted Successfully.");
        setOpen(false);
      } else {
        Notification("error", response?.data?.deleteBeatBox?.message);
      }
    } catch (error) {
      Notification("error", error.message);
      console.error("An error occurred while deleting the item:", error);
    } finally {
      setisloading(false);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={DeleteModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...style,
            width: { xs: "100%", sm: "500px" },
            display: "flex",
            alignItems: "center",
            padding: "3rem",
            borderRadius: "0.625rem",
            background: theme.palette.primary.main,
            backgroundColor: "none",
          }}
        >
          <Box className="dell-icon">
            <img src={delete_icon} alt="beats-delete-icon" />
            <Typography variant="h3" sx={{ color: "#E3E3E3;" }}>
              Delete Inbox
            </Typography>
          </Box>
          <p>Do you really want to delete this inbox?</p>
          <Box className="btn-box">
            <Button
              variant="outline"
              className="cancel-btn"
              onClick={DeleteModelClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="create-btn"
              onClick={handleDelete}
              disabled={Isloading}
              sx={{
                "&:disabled": {
                  background: "gray !important",
                  color: "#fff !important",
                },
              }}
            >
              {Isloading ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "#fff",
                  }}
                />
              ) : (
                "Delete"
              )}
            </Button>
          </Box>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(MainDeletePopUp);
