import React, { useContext, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { isEmailexist } from "./Mutation";
import { Notification } from "../../../NotifyMsg/Notification";
import { AuthContext } from "../../../Authentication/Layout";

function useCheckMutation() {
  const { setActiveStep, accountType, setProgressbarValue, setIsDisableBtn } =
    useContext(AuthContext);
  const [isEmailExists] = useMutation(isEmailexist, {
    onCompleted(data) {
      if (!data?.isEmailExists?.success) {
        console.log(data.isEmailExists, "asdasd");
        if (
          accountType === "Music Producer" &&
          data.isEmailExists.user.roleId.roleName === "Music Producer"
        ) {
          if (data.isEmailExists.isLeftUser === false) {
            Notification("error", data.isEmailExists.message);
            setTimeout(() => {
              setIsDisableBtn(false);
            }, 1400);
          } else if (data.isEmailExists.isLeftUser === null) {
            setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setIsDisableBtn(false);
          } else {
            setTimeout(() => {
              localStorage.setItem("email", data.isEmailExists.user.email);
              localStorage.setItem(
                "accountType",
                data.isEmailExists.user.roleId.roleName
              );
              localStorage.setItem("userId", data?.isEmailExists?.user?._id);

              setIsDisableBtn(false);
              window.location.href = "/approved/signup/1?step=8";
            }, 1400);
          }
        } else {
          console.log("data", data.isEmailExists);
          Notification("error", data.isEmailExists.message);
          setTimeout(() => {
            setIsDisableBtn(false);
          }, 1400);
        }
      } else {
        // Notification("error", data.isEmailExists.message);
        setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setIsDisableBtn(false);
      }
    },
  });

  return { isEmailExists };
}

export default useCheckMutation;
