import React from "react";
import { FormWrapper } from "./style";
import RightArrow from "../../../../../../assets/smartlink/icons/RightArrow";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@mui/material";
import { Notification } from "../../../../../NotifyMsg/Notification";
import CircularProgress from "@mui/material/CircularProgress";
import { EXTRACT_DATA_FROM_URLS } from "../../../../../../Graphql_services/smartlink/SmartLink";
import { useLazyQuery } from "@apollo/client";

const urlValidationSchema = Yup.string()
  .url("Please enter a valid URL.")
  .required("This field can't be blank.");

const upcValidationSchema = Yup.string().matches(
  /^[0-9]{12}$/,
  "Please enter a valid UPC (12 digits)."
);

const isrcValidationSchema = Yup.string().matches(
  /^[A-Z]{2}[A-Z0-9]{3}[0-9]{7}$/,
  "Please enter a valid ISRC (2 uppercase letters, 3 alphanumeric characters, 7 digits)."
);
const UrlForm = ({ setdata = () => {} }) => {
  const [getData, { loading, error, data }] = useLazyQuery(
    EXTRACT_DATA_FROM_URLS
  );
  const formik = useFormik({
    initialValues: {
      value: "",
    },
    validationSchema: Yup.object().shape({
      value: Yup.string().test(
        "isValidFormat",
        "Please enter a valid URL, UPC, or ISRC",
        function (value) {
          if (!value) {
            return false;
          }
          if (/^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
            return true;
          }
          if (/^[0-9]{12}$/.test(value)) {
            return true;
          }
          if (/^[A-Z]{2}[A-Z0-9]{3}[0-9]{7}$/.test(value)) {
            return true;
          }
          return false;
        }
      ),
    }),
    onSubmit: async (values) => {
      const { value } = values;
      let Data;

      try {
        const result = await getData({
          variables: { url: value },
          context: { clientName: "smartlink" },
        });

        Data = result.data.dataExtractionFromUrl;
      } catch (error) {
        console.error("Error extracting data:", error);
      }

      if (/^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
        console.log("It's a URL");
        // Additional logic for URL
        setdata({
          type: "url",
          Data,
        });
      }

      // Check if it's a UPC
      if (/^[0-9]{12}$/.test(value)) {
        setdata({
          type: "UPC",
          Data,
        });
      }

      // // Check if it's an ISRC
      if (/^[A-Z]{2}[A-Z0-9]{3}[0-9]{7}$/.test(value)) {
        setdata({
          type: "ISRC",
          Data,
        });
      }
    },
  });
  return (
    <FormWrapper>
      <div className="inputmain">
        <form onSubmit={formik.handleSubmit}>
          <input
            type="text"
            placeholder="Insert URL, UPC, or ISRC"
            id="value"
            name="value"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.value}
            className={
              formik.touched.value && formik.errors.value ? "error" : null
            }
          />
          <Button type="submit" disabled={loading}>
            {!loading ? (
              <RightArrow />
            ) : (
              <CircularProgress
                color="inherit"
                size={20}
                sx={{ color: "#000" }}
              />
            )}
          </Button>
        </form>
      </div>
      {formik.touched.value && formik.errors.value ? (
        <span className="error_span">{formik.errors.value}</span>
      ) : null}
    </FormWrapper>
  );
};

export default UrlForm;
