import { Box } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";

export const SideBarBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "300px",
  height: "100vh",
  background: "black",
  zIndex: 9999,
  display: "flex",
  flexDirection: "column",
  "& .header": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
  },
  "& .menuList": {
    div: {
      listStyle: "none",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      padding: "0px 30px",
      "& .links": {
        color: "white",
        padding: "10px 0",
        textDecoration: "none",
        borderBottom: "0.5px solid gray",
      },
    },
  },
}));
