/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import {
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Input,
  Link,
} from "native-base";
import styles from "./styles.module.css";
import React from "react";

import banner from "../../assets/Distribution/banner.svg";
import UNITEDMARKET from "../../assets/Distribution/UNITEDMARKET.svg";

import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";

function DistributionBanner() {
  const openNewWindow = () => {
    const url = "https://form.typeform.com/to/zafXwjGZ";
    window.open(url, "_blank");
  };
  return (
    <div className={styles.BannerMain}>
      <div className={styles.BannerTextMain}>
        <img
          src={UNITEDMARKET}
          alt="UNITEDMARKET"
          className={styles.BannerText}
        />
        <div className={styles.ApplyNowPublishing} onClick={openNewWindow}>
          Take Control your Career
        </div>
      </div>
    </div>
  );
}

export default DistributionBanner;
