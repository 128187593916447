import React, { useState } from "react";
import { InsightMain } from "./style";
import HeaderWidgets from "../widgets/headertabs/HeaderWidgets";
import ResultCount from "../widgets/resultcount/ResultCount";
import InsightsTables from "../widgets/InsightsTables";

const InsightSources = () => {
  const [activeButton, setActiveButton] = useState("Channels");
  const buttons = ["Channels", "Referrers", "Activity IDS"];

  const TableHeaddata = [
    { title: "#", val: "id" },
    { title: "Channels", val: "channels" },
    { title: "Visits", val: "Visits" },
  ];

  const BodyData = [
    {
      id: "80",
      title: "Channels",
      visits: "147",
    },
  ];

  return (
    <InsightMain className="InsightMain">
      <HeaderWidgets
        title="devices"
        buttons={buttons}
        activeButton={activeButton}
        setActiveButton={setActiveButton}
      />

      <ResultCount count={0} title="Channels" />
      <InsightsTables
        // BodyData={BodyData}
        ProgressBar={false}
        TableHeadData={TableHeaddata}
        NoDataText="No Channels"
      />
    </InsightMain>
  );
};

export default InsightSources;
