import cx from "clsx";
import { useEffect, useCallback } from "react";
import "./style.css";
import Slider from "@mui/material/Slider";

import {
  forward_left,
  forward_right,
  pausecircle_green,
  playcircle_green,
  VolumeIcon,
  Shuffle,
  flipIcon,
  Dummyimg,
  VolumeCross,
} from "../../../../assets/smartlink";

const Audioplayers = ({
  songs,
  playerState,
  setPlayerState,
  player,
  play,
  pause,
  pausePlay,
  isImg,
  handlePlaylistPlay,
}) => {
  const increaseSpeed = () => {
    const newSpeed = player.current.playbackRate + 0.1;
    setPlayerState({ speed: newSpeed });
    player.current.playbackRate = newSpeed;
  };

  const decreaseSpeed = () => {
    const newSpeed = player.current.playbackRate - 0.1;
    setPlayerState({ speed: newSpeed });
    player.current.playbackRate = newSpeed;
  };

  const playNext = useCallback(() => {
    const next =
      playerState.current < playerState.songs.length
        ? playerState.current + 1
        : 0;

    setPlayerState({ current: next });
    player.current.addEventListener("canplay", () => play(), { once: true });
  }, [playerState]);

  const playPrevious = () => {
    const prev =
      playerState.current > 0
        ? playerState.current - 1
        : playerState.songs.length - 1;

    setPlayerState({ current: prev });
    player.current.addEventListener("canplay", () => play(), { once: true });
  };

  const handleSeek = (event) => {
    const { value } = event.target;
    setPlayerState({ progress: value });
    player.current.currentTime = (value / 100) * player.current.duration;
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    setPlayerState({ volume: newVolume });
    player.current.volume = newVolume / 100;
  };

  const toggleMute = () => {
    if (playerState.volume === 0) {
      setPlayerState({ volume: playerState.prevVolume, isMuted: false });
      player.current.volume = playerState.prevVolume / 100;
    } else {
      setPlayerState({
        volume: 0,
        prevVolume: playerState.volume,
        isMuted: true,
      });
      player.current.volume = 0;
    }
  };

  useEffect(() => {
    const updateProgress = (event) => {
      if (player.current) {
        const progress = Math.floor(
          (event.target.currentTime / event.target.duration) * 100
        );

        if (progress === 100) {
          playNext();
        } else {
          setPlayerState({ progress });
        }
      }
    };

    if (player.current) {
      player.current.addEventListener("timeupdate", updateProgress);

      return () => {
        if (player.current) {
          player.current.removeEventListener("timeupdate", updateProgress);
        }
      };
    }
  }, [player.current, playNext, setPlayerState]);

  useEffect(() => {
    if (songs.length > 0) {
      setPlayerState({ ...playerState, songs: songs });
    }
  }, [songs]);

  return (
    <div className="player">
      <div className="ControlsMain">
        <button className="prev" onClick={playPrevious}>
          <img src={forward_left} alt="forward_left.svg" title="Previous" />
        </button>

        <button className="play" onClick={pausePlay}>
          {playerState.isPlaying ? (
            <img src={pausecircle_green} alt="PauseCircle.svg" title="Pause" />
          ) : (
            <img src={playcircle_green} alt="PlayCircle.svg" title="Play" />
          )}
        </button>

        <button className="next" onClick={playNext}>
          <img src={forward_right} alt="forward_right.svg" title="Next" />
        </button>
      </div>
      <audio
        ref={player}
        src={playerState.songs.at(playerState.current)?.url}
      />

      {playerState.songs.at(playerState.current) && (
        <div style={{ display: `${isImg ? "flex" : "none"}` }} className="song">
          <div>
            <img
              style={{ height: "60px", width: "48px" }}
              src={Dummyimg}
              alt="Dummyimg.svg"
            />
          </div>
          <div>
            <h1>{playerState.songs.at(playerState.current).fileName}</h1>
            <span>Abc No Artist</span>
          </div>
        </div>
      )}

      <Slider
        size="small"
        min={0}
        max={100}
        onChange={handleSeek}
        value={playerState.progress}
        aria-label="Small"
        color="success"
      />

      <div className="volume">
        <button className="volumeButton" onClick={toggleMute}>
          {playerState.isMuted ? (
            <img
              style={{ height: "12px", width: "12px" }}
              src={VolumeCross}
              alt="MuteIcon.svg"
              title="Mute"
            />
          ) : (
            <img
              style={{ height: "12px", width: "12px" }}
              src={VolumeIcon}
              alt="Unmuteicon.svg"
              title="Unmute"
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default Audioplayers;
