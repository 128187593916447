import axios from "../../config/axiosInterceptor";
import { HTTP_STATUS } from "../../shared/constants";

const useGeneral = () => {
  const getUserPrivileges = async (userID) => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/user/fetch-user-privileges`;
      const params = { userID: userID };

      const { data, status } = await axios.get(url, { params });

      if (status === HTTP_STATUS.OK) {
        return data;
      } else {
        throw new Error("Error while fetching payment information");
      }
    } catch (error) {
      throw error;
    }
  };

  const getPaymentInfo = async (userID) => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/payment/fetch-payment-information`;
      const params = { userID: userID };

      const { data, status } = await axios.get(url, { params });

      if (status === HTTP_STATUS.OK) {
        return data;
      } else {
        throw new Error("Error while fetching payment information");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchPaymentPortalLink = async (userID, userStripeID, returnURL) => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/payment/create-payment-portal`;
      const params = {
        userID: userID,
        userStripeID: userStripeID,
        returnURL: returnURL,
      };

      const { data, status } = await axios.get(url, { params });
      const portalURL = data.portalURL;
      if (status === HTTP_STATUS.OK) {
        return portalURL;
      } else {
        throw new Error("Error while fetching payment link");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchRevenueData = async () => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/analytics/fetch-revenue-data`;
      const { data, status } = await axios.get(url);

      if (status === HTTP_STATUS.OK) {
        return data;
      } else {
        throw new Error("Error while fetching revenue data");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchUserData = async () => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/analytics/fetch-user-data`;
      const { data, status } = await axios.get(url);

      if (status === HTTP_STATUS.OK) {
        return data;
      } else {
        throw new Error("Error while fetching user data");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchSubscriptionTypeBreakdown = async () => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/analytics/fetch-subscription-type-breakdown`;
      const { data, status } = await axios.get(url);

      if (status === HTTP_STATUS.OK) {
        return data;
      } else {
        throw new Error("Error while fetching subscription type breakdown");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchSessionData = async () => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/analytics/fetch-session-data`;
      const { data, status } = await axios.get(url);

      if (status === HTTP_STATUS.OK) {
        return data;
      } else {
        throw new Error("Error while fetching subscription type breakdown");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchPageClickData = async () => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/analytics/fetch-page-click-data`;
      const { data, status } = await axios.get(url);

      if (status === HTTP_STATUS.OK) {
        return data;
      } else {
        throw new Error("Error while fetching subscription type breakdown");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchRecentSignUps = async () => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/analytics/fetch-recent-sign-ups`;
      const { data, status } = await axios.get(url);

      if (status === HTTP_STATUS.OK) {
        return data;
      } else {
        throw new Error("Error while fetching subscription type breakdown");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchAnalyticsFromDatabase = async () => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/analytics/fetch-admin-analytics`;
      const { data, status } = await axios.get(url);

      if (status === HTTP_STATUS.OK) {
        return data;
      } else {
        throw new Error("Error while fetching analytics");
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchSubmissionCounts = async () => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/analytics//fetch-submission-counts`;
      const { data, status } = await axios.get(url);

      if (status === HTTP_STATUS.OK) {
        return data;
      } else {
        throw new Error("Error while fetching analytics");
      }
    } catch (error) {
      console.error("Error in refreshAllData:", error);
      throw error;
    }
  };

  const refreshAllData = async () => {
    try {
      const url = `${process.env.REACT_APP_GENERAL_URL}/general/analytics/refresh-all-data`;
      const { status } = await axios.get(url);

      if (status !== HTTP_STATUS.OK) {
        throw new Error("Error while fetching analytics");
      }
      return true;
    } catch (error) {
      console.error("Error in refreshAllData:", error);
      throw error;
    }
  };

  return {
    getUserPrivileges,
    getPaymentInfo,
    fetchPaymentPortalLink,
    fetchRevenueData,
    fetchUserData,
    fetchSubscriptionTypeBreakdown,
    fetchRecentSignUps,
    fetchSessionData,
    fetchAnalyticsFromDatabase,
    fetchPageClickData,
    fetchSubmissionCounts,
    refreshAllData,
  };
};
export default useGeneral;
