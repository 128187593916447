import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ModelContentBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "& .header-icon-box": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
    "& .back-icon": {
      width: "24px",
      height: "24px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
    "& .close-icon": {
      width: "30px",
      height: "30px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
  "& h5": {
    color: "#FFFFFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
  },
  "& .input-main": {
    marginTop: "40px",
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
    "& .input-field-box": {
      textAlign: "start",
      width: "100%",
    },
    input: {
      borderRadius: "5px",
      border: "1px solid #414241",
      background: "#1C1B1B",
      fontFamily: theme.typography.fontFamily,
      marginTop: "8px",
      color: "#AFAFAF",
      "&::placeholder": {
        color: "#AFAFAF",
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        "& .input-field-box": {
          width: "100%",
        },
      },
    },
  },

  "& .third-field-box": {
    width: "48%",
    textAlign: "start",
    marginBottom: "10px",
    input: {
      marginTop: "8px",
      "&::placeholder": {
        color: "#AFAFAF",
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  "& .btn-main-box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "40px",
    "& span, & .green-btn": {
      color: "#2EBB55",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textDecorationLine: "underline",
      textTransform: "capitalize",
      cursor: "pointer",
    },
    "& .green-btn": {
      borderRadius: "5px",
      background: "#2EBB55",
      padding: "10.5px 30px",
      color: "#fff",
      textDecorationLine: "none",
    },
  },
  "& .error": {
    color: "red",
    paddingTop: "10px",
    fontSize: "12px",
    fontFamily: theme.typography.fontFamily,
  },

  "& .content": {
    display: "flex",
    position: "relative",
    alignItems: "center",
    "& .icon-main": {
      marginTop: "5px",
      marginLeft: "7px",
      "& .escalimation": {
        color: "#AFAFAF",
        cursor: "pointer",
        fontSize: "20px",
        "&:hover": {
          borderRadius: "100px",
          filter:
            "invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)",
        },
      },
      "& .icon-content": {
        position: "absolute",
        top: "100%",
        left: "0px",
        zIndex: "9999",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "5px",
        background: "#141515",
        padding: "18px",
        color: "#AFAFAF",
        fontFamily: theme.typography.fontFamily,
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        width: "230px",
        [theme.breakpoints.down("sm")]: {
          left: "-100px",
        },
      },
    },
    h5: {
      color: "#FFFFFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  },
  [theme.breakpoints.between("xs", "sm")]: {
    padding: "20px",
    h5: {
      marginBottom: "21px",
      fontSize: "18px",
    },
    "& .input-main": {
      flexDirection: "column",
    },
    "& .third-field-box": {
      width: "100%",
    },
    "& .tool-tip": {
      marginTop: "0px",
      width: "20px",
      height: "20px",
    },
    "& .content ": {
      alignItems: "center",
    },
  },
}));
export { ModelContentBox };
