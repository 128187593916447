const opportunities_data = [
  {
    id: "1",
    titel: "John Wick",
    subtitle: "Pop",
    name: "John",
    monthly_listeners: "Pop",
    verified: true,
    posted_by: "Po",
    time_remaining: "Po",
    creator_image: "/v2Assets/profile_picture_assets/imageOne.png",
    posted_by_image: "/v2Assets/profile_picture_assets/imageOne.png",
  },
  {
    id: "1",
    titel: "John Wick",
    subtitle: "Pop",
    name: "John",
    monthly_listeners: "Pop",
    verified: true,
    posted_by: "Po",
    time_remaining: "Po",
    creator_image: "/v2Assets/profile_picture_assets/imageOne.png",
    posted_by_image: "/v2Assets/profile_picture_assets/imageOne.png",
  },
  {
    id: "1",
    titel: "John Wick",
    subtitle: "Pop",
    name: "John",
    monthly_listeners: "Pop",
    verified: true,
    posted_by: "Po",
    time_remaining: "Po",
    creator_image: "/v2Assets/profile_picture_assets/imageOne.png",
    posted_by_image: "/v2Assets/profile_picture_assets/imageOne.png",
  },
  {
    id: "1",
    titel: "John Wick",
    subtitle: "Pop",
    name: "John",
    monthly_listeners: "Pop",
    verified: true,
    posted_by: "Po",
    time_remaining: "Po",
    creator_image: "/v2Assets/profile_picture_assets/imageOne.png",
    posted_by_image: "/v2Assets/profile_picture_assets/imageOne.png",
  },
];

export default opportunities_data;
