import Select, { components } from "react-select";
import React, { useRef, useState, memo, useEffect } from "react";
import { MODE_TONE, TYPE_TONE } from "../../../shared/constants";
import "../css/select.css";
import { Search } from "@material-ui/icons";
import universalStyles from "../../UniversalComponents/universal.module.css";
import styles from "../../LibrariesComponents/Libraries/Libraries.module.css";
import LibLineDivider from "../Libraries/LibLineDivider";
import { set } from "lodash";
import useLibraryStore from "../../../store/library";
import { getTags } from "../../../context/apis/audio-library";
import list from "postcss/lib/list";
const Control = ({ children, ...props }) => (
    <components.Control {...props}>
        <img
            className={universalStyles.search_icon}
            src="/v2Assets/connect_assets/Search.png"
            alt="search"
        ></img>
        {children}
    </components.Control>
);

export const SearchTagsHorizontal = ({ foundText, onTagStatusChange, tags, is_tag = true }) => {

    const [list_data, setListData] = useState([]);
    const [filteredTags, setFilteredTags] = useState([]);
    const [search, setSearch] = useState(false);

    const handleSearch = (searchText) => {
        if (searchText != "") {
            setSearch(true)
        } else {
            setSearch(false);
        }


        if (searchText.trim() === "") {

        } else {
            const lowercasedValue = searchText.toLowerCase();
            const filtered = list_data.filter((tag) =>
                tag.value.toLowerCase().includes(lowercasedValue),
            );
            setFilteredTags(filtered);
        }
    };

    const handleSelect = (index, tag) => {
        console.log('asdfasdf', list_data, tag)
        const updatedFilteredTags = list_data.filter((objA) => ![...tags, tag].some((objB) => objA.value === objB.value));
        const newtags = [...tags, tag];
        onTagStatusChange(newtags);
        setFilteredTags(updatedFilteredTags);

        setFilteredTags(prevFilteredTags => {
            // Calculate the new selected tags again, since we cannot access the result of the above state updater function here.
            const newtagsCalculation = [...tags, tag];
            return list_data.filter(tagOption => !newtagsCalculation.some(selectedTag => selectedTag.value === tagOption.value));
        });

    }

    const handleChange = (remainingList) => {
        onTagStatusChange(remainingList);
        const untags = list_data.filter((objA) => !remainingList.some((objB) => objA.value === objB.value));
        setFilteredTags(untags);
        onTagStatusChange(remainingList);
    }

    const handleBlur = (event) => {
        event.preventDefault();
    }

    useEffect(() => {
        onTagStatusChange(tags);
    }, [tags])

    useEffect(async () => {
        const response = await getTags();
        const transformedTags = response?.data?.tagValues.map((tag) => ({
            value: tag,
            label: tag
        }));
        setListData(is_tag ? transformedTags : TYPE_TONE)
        setFilteredTags(is_tag ? transformedTags : TYPE_TONE);
    }, []);

    return (
        <>
            <Select
                isMulti
                onChange={handleChange}
                // onBlur={handleBlur}
                classNamePrefix="search_select_tags"
                placeholder="Search Tags here"
                closeMenuOnSelect={false}
                components={{ Control, DropdownIndicator: null }}
                menuIsOpen={false}
                value={tags}
                onInputChange={(newValue) => { handleSearch(newValue) }}
                isSearchable
                options={list_data}
            />
            {foundText && search && <div className={styles.found_text}>{filteredTags.length} tag found</div>}
            <div className={styles.search_tags_container}>
                {filteredTags.map((tag, index) => (
                    <div key={`searchTagsZ-${index}`} className={styles.search_tag_item} onClick={() => handleSelect(index, tag)}>{tag.label}</div>
                ))}
            </div>
        </>
    )
}






