import { gql } from "@apollo/client";

export const SignUpMutation = gql`
  mutation (
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
    $countrycode: Float
    $phoneno: Float
    $labelName: String
    $roleId: String!
    $instaUrl: String
    $profilePic: String!
    $username: String!
    $genre: String
    $isPreSignUp: Boolean
    $noOfMonthlyListeners: Float
    $spotifyUrl: String
    $howDidYouKnow: String
    $howDidYouKnowText: String
    $membershipType: String
    $isFreemium: Boolean
    $isVerified: Boolean
    $isAdminReviewed: Boolean
  ) {
    signUp(
      firstname: $firstname
      lastname: $lastname
      email: $email
      password: $password
      countrycode: $countrycode
      phoneno: $phoneno
      labelName: $labelName
      roleId: $roleId
      instaUrl: $instaUrl
      profilePic: $profilePic
      userName: $username
      genre: $genre
      isPreSignUp: $isPreSignUp
      noOfMonthlyListeners: $noOfMonthlyListeners
      spotifyUrl: $spotifyUrl
      howDidYouKnow: $howDidYouKnow
      howDidYouKnowText: $howDidYouKnowText
      isSoundPackAllowed: false
      membershipType: $membershipType
      isFreemium: $isFreemium
      isVerified: $isVerified
      isAdminReviewed: $isAdminReviewed
    ) {
      success
      message
      user {
        firstname
        userName
        profilePic
        email
        countrycode
        phoneno
        accountType
        labelName
        _id
        roleId {
          roleName
        }
      }
      accessToken
      refreshToken
      reactionAccessToken
      reactionRefreshToken
      marketplaceAccessToken
      marketplaceRefreshToken
      sessionId
    }
  }
`;

export const sendOTP = gql`
  mutation ($email: String!, $countrycode: Float, $phoneno: Float) {
    sendOtp(email: $email, countrycode: $countrycode, phoneno: $phoneno) {
      success
      message
    }
  }
`;

export const verifyOTP = gql`
  mutation ($otp: String!, $email: String!) {
    verifyOtp(otp: $otp, email: $email) {
      success
      message
    }
  }
`;

export const SignInWithFacebook = gql`
  mutation ($userID: String!, $accessToken: String!) {
    facebooklogin(userID: $userID, accessToken: $accessToken) {
      success
      user {
        firstname
      }
      message
    }
  }
`;

export const SetRole = gql`
  mutation (
    $userId: String!
    $roleId: String!
    $labelName: String!
    $howDidYouKnow: String!
    $instaUrl: String!
  ) {
    setRole(
      userId: $userId
      roleId: $roleId
      labelName: $labelName
      howDidYouKnow: $howDidYouKnow
      instaUrl: $instaUrl
    ) {
      success
      message
    }
  }
`;

export const getNewRoles = gql`
  query {
    getHowDoYouKnows {
      _id
      name
    }
  }
`;

export const GetRoles = gql`
  query {
    getRoles {
      roleName
      _id
    }
  }
`;

export const SignInWithGoogle = gql`
  mutation ($idToken: String!) {
    googlelogin(idToken: $idToken) {
      success
      message
      isLogin
      user {
        _id
        firstname
        lastname
        email
      }
      accessToken
      refreshToken
    }
  }
`;

// copy code from the HOOks / Authentication / CheckE / Mutation.js file

export const isEmailexist = gql`
  mutation ($email: String!) {
    isEmailExists(email: $email) {
      success
      message
      isLeftUser
      user {
        _id
        email
        roleId {
          _id
          roleName
        }
      }
    }
  }
`;

export const ADDSUBSCRIPTIONPLAN = gql`
  mutation (
    $userToken: String!
    $priceId: String
    $howDidYouKnowText: String
    $howDidYouKnow: String
  ) {
    attachPlan(
      userToken: $userToken
      priceId: $priceId
      howDidYouKnow: $howDidYouKnow
      howDidYouKnowText: $howDidYouKnowText
    ) {
      success
      message
      accessToken
      refreshToken
      reactionAccessToken
      reactionRefreshToken
      marketplaceAccessToken
      marketplaceRefreshToken
      sessionId
      user {
        email
        profilePic
        userName
        roleId {
          _id
          roleName
        }
        accountType
        _id
      }
    }
  }
`;

export const Login = gql`
  mutation (
    $email: String!
    $password: String!
    $countrycode: Float
    $phoneno: Float
  ) {
    login(
      email: $email
      password: $password
      countrycode: $countrycode
      phoneno: $phoneno
    ) {
      success
      message
      user {
        _id
        firstname
        userName
        coverPic
        lastname
        email
        profilePic
        roleId {
          roleName
        }
      }
      accessToken
      refreshToken
      reactionAccessToken
      reactionRefreshToken
      sessionId
      isPaid
      isFreemium
      isPreSignUpUser
      shouldStartSubscription
      shouldRenewRetrySubscription
    }
  }
`;

export const ForgetPass = gql`
  mutation ($email: String!, $countrycode: Float, $phoneno: Float) {
    forgetPassword(
      email: $email
      countrycode: $countrycode
      phoneno: $phoneno
    ) {
      success
      message
    }
  }
`;

export const VerifySignInOtp = gql`
  mutation ($otp: String!) {
    checkResetOtp(otp: $otp) {
      success
      message
    }
  }
`;

export const ResetPasswordSignIn = gql`
  mutation ($password: String!, $confirmPassword: String!, $otp: String!) {
    resetPassword(
      password: $password
      confirmPassword: $confirmPassword
      otp: $otp
    ) {
      success
      message
    }
  }
`;
