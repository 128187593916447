import { Box } from '@mui/material';

import { styled } from '@mui/material/styles';

const SelectMain = styled(Box)(({ theme }) => ({
  minWidth: 130,
  '& .select-box': {
    height: '50px',
    border: 'none !important',
    color: '#FFF',
    background: 'rgba(0, 0, 0, 0.5)',
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontWeight: 500,
    '& svg': {
      fill: '#FFF',
    },
    '& fieldset': {
      display: 'none !important',
    },
  },
}));

export { SelectMain };
