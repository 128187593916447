import React from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  MainTitle,
  PageTitle,
  InboxSetting,
} from "../../../components/smartlink";

const InboxSettingPage = () => {
  return (
    <>
      <SmartLinkLayout>
        <MainTitle title="Smart Link" hasbutton={false} islinkpage={true} />
        <HorizontalMenu />
        <PageTitle
          title="Inbox Setting"
          hasNavigate={true}
          NavigateTitle="Inbox Setting"
        />
        <InboxSetting />
      </SmartLinkLayout>
    </>
  );
};

export default InboxSettingPage;
