import React, { useEffect, useState } from 'react';
import {
	Grid,
	Button,
	Chip,
	Tooltip,
	tooltipClasses,
	styled,
} from '@mui/material';
import styles from './styles.module.css';
import { getAllTutorial } from '../../Graphql_services/TutorialMutation';
import ReactPlayer from 'react-player';
import { useLazyQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';
export default function Tutorial() {
	const BootstrapTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: '#1c1b1b',
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#1c1b1b',
			color: theme.palette.common.white,
			maxWidth: 600,
		},
	}));
	const [tutorialData, setTutorialData] = useState([]);
	const [getTutorialData] = useLazyQuery(getAllTutorial, {
		context: { clientName: 'admin' },
		fetchPolicy: 'no-cache',
		onCompleted(data) {
			if (data?.getAllTutorial?.success) {
				console.log('getAllTutorial', data);
				setTutorialData(data?.getAllTutorial?.tutorials);
			} else {
				localStorage.clear();
				Navigate('/signin');
			}
		},
		onError(err) {
			console.log('error on getting All Tutorial ', err);
		},
	});
	useEffect(() => {
		getTutorialData();
	}, []);
	return (
		<div>
			<Grid className={styles.content}>
				<p className={styles.heading}>Media</p>
			</Grid>
			<Grid container direction='row'>
				{tutorialData.length === 0 ? (
					<p className={styles.available}>No media is available</p>
				) : (
					tutorialData.map((item, index) => (
						<Grid
							item
							md={6}
							lg={6}
							sm={12}
							xs={12}
							className={styles.tutorialSection}
							key={index}
						>
							{/* <video className={styles.videoPlayer} controls>
                <source src={item.videoUrl} type='video/mp4' />
              </video> */}
							<ReactPlayer
								url={item.videoUrl}
								controls={true}
								className={styles.videoPlayer}
							/>
							<BootstrapTooltip
								title={item.title}
								className={styles.customTooltip}
								placement='bottom-start'
							>
								<p className={styles.title}>{item.title}</p>
							</BootstrapTooltip>
							<BootstrapTooltip
								title={item.description}
								className={styles.customTooltip}
								placement='bottom-start'
							>
								<p className={styles.description}>{item.description}</p>
							</BootstrapTooltip>
						</Grid>
					))
				)}
			</Grid>
		</div>
	);
}
