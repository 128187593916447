import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const MainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "80vh",
  justifyContent: "center",
  alignItems: "center",
}));

const InboxContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "40%",
  "& h4": {
    color: theme.palette.secondary.dark,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    margin: "0px",
  },
  "& h5": {
    fontFamily: theme.typography.fontFamily,
    margin: "0px",
    color: "rgba(175, 175, 175, 0.34)",
    fontSize: theme.typography.h5,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginTop: "0.5rem",
  },

  "& .btn": {
    display: "flex",

    alignItems: "center",

    width: "9.5625rem",

    height: "3rem",

    gap: "0.5rem",

    background: "#2EBB55",

    color: "#fff",

    borderRadius: "0.3125rem",

    fontFamily: theme.typography.fontFamily,

    fontSize: theme.typography.h5,

    textTransform: "capitalize",

    fontStyle: "normal",

    fontWeight: 500,

    lineHeight: "normal",
    marginTop: "2rem",
    "&:hover": { background: "#2EBB55" },
  },
}));

export { MainBox, InboxContentBox };
