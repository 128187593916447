import { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import styles from "./sync_submission_card.module.css";

import { Avatar, Divider, Grid, IconButton } from "@mui/material";
import allImages from "../../../assets";
import { toast } from "react-toastify";
import useAppState from "../../../context/useAppState";
import { getTimeRemainingText, handleDownload } from "../../../shared/funs";
import { get, last, size } from "lodash";
import { useNavigate } from "react-router-dom";
import WavePlayer from "../../../components-old/WavePlayer";
import ViewCollaboratorsPopup from "../../Popups/ViewCollaboratorsPopup";
import DarkTooltip from "../../OpportunitiesComponents/AdditionalComponents/Tooltip/DarkTooltip";

const SyncSubmissionCard = ({
  isShowOpportunityName = false,
  isShowMusicProducerName = false,
  isShowView = false,
  isShowFavorite = false,
  isShowFlag = false,
  isShowStatus = true,
  isShowBeatDetail,
  showDownloadbtn = false,
  data,
  beatInfoBtn = null,
  selectBeatInfo = null,
  isShowCollaborators = true,
  sender,
  reciever,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { updateFavorite, updateFlag } = useAppState("sync");

  const [favorite, setFavorite] = useState(false);
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();
  const {
    collaborators,
    isMasterSample,
    _id,
    file,
    exclusivePrice,
    isFavorite,
    isFlag,
    opportunity,
  } = data;

  const { title, coverPhoto, bpm, key, scale, expireDate, userInfo } =
    opportunity || {};

  const allAccepted =
    collaborators &&
    collaborators.every((element) => element.status === "Accepted");

  useEffect(() => {
    setFavorite(data.isFavorite);
    setFlag(data.isFlag);
  }, [data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFavorite = () => {
    const payload = { isFavorite: !favorite };

    try {
      updateFavorite(_id, payload);
      setFavorite((prev) => !prev);
    } catch (error) {
      toast.error("Record not updated, please try again.");
    }
  };

  const onFlag = () => {
    const payload = { isFlag: !flag };

    try {
      updateFlag(_id, payload);
      setFlag((prev) => !prev);
    } catch (error) {
      toast.error("Record not updated, please try again.");
    }
  };

  const handleView = () => {
    navigate(`/opportunities/viewsubmissiondetails/${data._id}`);
  };

  const [collaboratorPopup, setCollaboratorPopup] = useState(false);

  const handleViewCollaboratorsPopup = () => {
    setCollaboratorPopup(true);
  };

  const handleClosePopup = () => {
    setCollaboratorPopup(false);
  };

  const cancelHandler = () => {
    handleClosePopup();
  };

  const open = Boolean(anchorEl);

  const fileName = decodeURIComponent(last(get(file, "url", "").split("/")));

  console.log(data);

  const spotifyLinkHandler = () => {
    window.open(data.spotifySongLink, "_blank");
  };

  return (
    <div className={styles.submissions_card}>
      <div className={styles.submissions_card_left}>
        <div className={styles.submission_info}>
          <div className={styles.text}>
            <div className={styles.title_image}>
              {data.songName}
              {data.spotifySongLink && (
                <img
                  src="/SubmissionIcons/SpotifyIcon.png"
                  alt="check"
                  className={styles.spotify_icon}
                  onClick={spotifyLinkHandler}
                />
              )}
            </div>
            <div className={styles.title_small}>{data.musicianName}</div>
          </div>

          <div className={styles.text}>
            {data.isrcCode && (
              <div className={styles.subtitle}>ISRC Code: {data.isrcCode}</div>
            )}
            {data.canClear !== "Not Without Permission" && (
              <div className={styles.subtitle_image}>
                Can Clear Record
                <img
                  src="/SubmissionIcons/GreenCheckIcon.png"
                  alt="check"
                  className={styles.green_check}
                />
              </div>
            )}
          </div>
          <div className={styles.bottom_left_info}>
            {data.labelName && (
              <div className={styles.text}>
                <div className={styles.title_small}>Signed by:</div>
                <div className={styles.subtitle}>{data.labelName}</div>
              </div>
            )}
            {data.masterOwnerName && (
              <div className={styles.text}>
                <div className={styles.title_small_image}>Master Owner:</div>
                <div className={styles.subtitle_image}>
                  {data.masterOwnerName}
                  {data.masterOwnerEmail && (
                    <DarkTooltip title={data.masterOwnerEmail}>
                      <img
                        src="/SubmissionIcons/EmailIcon.png"
                        alt="contact"
                        className={styles.contact_icon}
                      />
                    </DarkTooltip>
                  )}
                  {data.masterOwnerPhone && (
                    <DarkTooltip title={data.masterOwnerPhone}>
                      <img
                        src="/SubmissionIcons/PhoneIcon.png"
                        alt="contact"
                        className={styles.contact_icon_small}
                      />
                    </DarkTooltip>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.submissions_card_right}>
        {isShowFavorite && (
          <div onClick={() => onFavorite()}>
            <img
              src={favorite ? allImages.filledYellowStar : allImages.star}
              alt="star"
              className={styles.star_icon}
            />
          </div>
        )}

        <div className={styles.text_right}>
          <div className={styles.subtitle}>
            Publisher Name: {data.publisherName}
          </div>
          <div className={styles.subtitle}>
            Publisher PRO #: {data.publisherPRO}
          </div>
        </div>

        {isShowCollaborators && size(collaborators) > 0 && (
          <div
            className={styles.view_collab_button}
            onClick={handleViewCollaboratorsPopup}
          >
            View Collaborators
          </div>
        )}

        <ViewCollaboratorsPopup
          collaborators={collaborators}
          isOpen={collaboratorPopup}
          onClose={handleClosePopup}
        />

        <div className={styles.bottom_right_info}>
          {file?.url && (
            <div className={styles.waveform}>
              <WavePlayer
                key={file.url}
                url={file.url}
                file={file}
                height={40}
              />
            </div>
          )}
          {true && file?.url && (
            <div>
              <div
                className={styles.download_button}
                onClick={() => handleDownload(file.url, file.name)}
              >
                Download
              </div>
            </div>
          )}
          {/* {isShowView && (
            <div className={styles.info_button} onClick={handleView}>
              View
            </div>
          )} */}

          {/* <div className={styles.view_button} onClick={handleView}>
            View
          </div> */}

          {/* {isShowBeatDetail && selectBeatInfo} */}
        </div>
      </div>
    </div>
  );

  function ShowCollaborators() {
    return (
      <div>
        <IconButton variant="contained" onClick={handleClick} size="small">
          <img src={allImages.info} className={styles.time_icon} alt="info" />
        </IconButton>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{ paper: styles.collaboratorsInfo }}
        >
          <Grid container rowGap={1}>
            <Grid item xs={12}>
              <Typography className={styles.heading}>
                Collaborators & Splits{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container rowGap={1}>
                {collaborators.map(
                  ({ name, lastname, publishingPercentage }, index) => (
                    <Grid xs={12} key={index}>
                      <Grid container justifyContent={"space-between"}>
                        <Grid item>
                          <Grid container alignItems={"center"} columnGap={1}>
                            <Avatar className={styles.avatar} />
                            <Typography className={styles.name}>
                              {name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography className={styles.name}>
                            {" "}
                            {publishingPercentage}%
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} className={styles.beatPriceWrapper}>
              <Grid
                container
                rowGap={1}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography className={styles.beatPriceLabel}>
                    Beat Price{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={styles.price}>
                    ${exclusivePrice}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider className={styles.divider} />
            </Grid>
            {isMasterSample && (
              <Grid item xs={12}>
                <Typography className={styles.isMasterText}>
                  It’s contain <b>Master</b> Sample
                </Typography>
              </Grid>
            )}
          </Grid>
        </Popover>
      </div>
    );
  }
};

export default SyncSubmissionCard;
