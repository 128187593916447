import React, { useState, memo } from "react";
import { ModelContentBox, Modelstyle } from "../socialintegrate/style";
import { Box, Button, IconButton, Modal } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { close_icon } from "../../../assets/smartlink";
import { SelectedBoxMain } from "./style";

const SelectedBoxPopup = ({
  open = false,
  setOpen = () => {},
  SelectedValue = {},
}) => {
  const CreateModelClose = () => {
    setOpen(!open);
  };
  const HandelOnClick = (value) => {};

  return (
    <>
      <Modal
        open={open}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...Modelstyle,
            width: { xs: "100%", sm: "500px" },
            padding: "2rem",
            borderRadius: "0.625rem",
            background: "#141515",
          }}
        >
          <Box className="popup_header">
            <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "2rem",
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#AFAFAF",
                backgroundColor: "none",
                fontSize: "2rem",
              }}
            >
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>
          <SelectedBoxMain>
            <h6>You've selected {SelectedValue?.name}</h6>
            <Box className="selected_main">
              <Button disabled onClick={HandelOnClick}>
                Update Selection
              </Button>
              <Button onClick={CreateModelClose}>Confirm</Button>
            </Box>
          </SelectedBoxMain>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(SelectedBoxPopup);
