import React from "react";
import { DetailsWrapper, DetailsWrapperFlex } from "./style";
import CopyDetails from "./Details/CopyDetails";
import ProfileDetails from "./Details/ProfileDetails";
import ShareDetails from "./Details/ShareDetails";

const ReleaseDetails = ({CreatedLinkData}) => {
  return (
    <DetailsWrapperFlex className="scroll">
      <CopyDetails CreatedLinkData={CreatedLinkData} />
      <ProfileDetails CreatedLinkData={CreatedLinkData} />
      <ShareDetails CreatedLinkData={CreatedLinkData} />
    </DetailsWrapperFlex>
  );
};

export default ReleaseDetails;
