import React, { useState } from "react";
import { Main } from "./styles";
import { Box, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { refresh_double_icon, crown } from "../../../../assets/smartlink";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import VideoPopup from "../../beatinbox/Videopopup/VideoPopup";
import RemainingLimits from "./RemainingLimits";
import MultiModal from "../MultiModal/MultiModal";
import useAuth from "../../../../context/AuthContext";
import { useQuery } from "@apollo/client";
import { GetAllActiveBeatBoxes } from "../../../../Graphql_services/beatinbox/Beats";

const Index = ({
  title = "",
  hasbutton = true,
  isBeatinbox = false,
  openCreateBeatPopup = () => {},
  islinkpage = false,
  isloading = false,
  refetch = () => {},
}) => {
  const [currentPage, setcurrentPage] = useState(1);
  const perPage = 9;

  const { loading, error, data } = useQuery(GetAllActiveBeatBoxes, {
    variables: { page: currentPage, perPage },
    fetchPolicy: "no-cache",
    context: { clientName: "beats" },
  });

  const RemainingLinks = data?.getActiveBeatBoxes?.remainingBeatInbox;
  const beatBoxes = data?.getActiveBeatBoxes?.beatBoxes;

  const { user } = useAuth();
  const PlanName = user?.planId?.planName;
  const BeatLength = beatBoxes?.length;
  const roleName = user.roleId?.roleName;
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setopen] = useState(false);
  const [PaymentOpen, setPaymentOpen] = useState(false);
  const HandelClick = () => {
    if (isBeatinbox) {
      if (
        (BeatLength === 3 || BeatLength > 3) && roleName=== "Music Producer" &&
        (PlanName === "Free" || PlanName === "Freemium")
      ) {
        setPaymentOpen(true);
        return;
      }
      openCreateBeatPopup();
    }
    if (islinkpage) {
      navigate("/smart-link/createlink");
    }
  };
  const handleRefreshBeatInbox = () => {
    refetch();
  };

  const PathName =
    location.pathname === "/beat-inbox" ||
    location.pathname.startsWith("/inbox-beats");

  const ShowRemainingLimits =
    location.pathname === "/beat-inbox" ||
    location.pathname.startsWith("/inbox-beats") ||
    location.pathname === "/beat-inbox/starred-beats" ||
    location.pathname === "/beat-inbox/deleted" ||
    location.pathname === "/beat-inbox/more-storage";

  return (
    <Main>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <h6>{title}</h6>
        {title == "Beat Inbox" && (
          <PlayCircleIcon
            onClick={() => setopen(!open)}
            sx={{
              color: "#2EBB55",
              cursor: "pointer",
            }}
          />
        )}
      </div>
      {hasbutton && (
        <>
          <Box className="header-createinbox-box">
            {!loading && ShowRemainingLimits && roleName=== "Music Producer" && (
              <RemainingLimits
                RemainingLinks={RemainingLinks}
                beatBoxes={beatBoxes}
              />
            )}
            {PathName && (
              <Button className="refresh-btn" onClick={handleRefreshBeatInbox}>
                <img
                  className={`${isloading ? "IsRotate" : ""}`}
                  src={refresh_double_icon}
                  alt="refresh-icon"
                />
              </Button>
            )}
            <Button onClick={HandelClick}>
              {islinkpage ? "Create Link" : "Create Inbox"}
            </Button>
          </Box>
        </>
      )}

      <VideoPopup open={open} />
      <MultiModal
        open={PaymentOpen}
        heading="Upgrade Your Plan"
        text="You have exceeded your inbox creation limit. Please upgrade your membership to create unlimited inboxes."
        icon={crown}
        type="link"
        title="Upgrade"
        RedirectTo="/beat-inbox/more-storage"
        setPaymentOpen={setPaymentOpen}
      />
    </Main>
  );
};

export default Index;
