import { Stack } from "@mui/material";
import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import styles from "../../Libraries/Libraries.module.css";
import OutlineButton from "../../AdditionalComponents/OutlineButton.js";
// import { PaginatorProps } from "types";
import BlueButtonComponents from "./BlueButtonComponent.js";

const NoItemComponents = ({
  src,
  text = "",
  isButton = false,
  isPack,
  handler,
  
}) => {
  return (
    <>
      <div className={styles.no_file_item}>
        <img alt="no file" src={src} />
        <p className={styles.no_files_found_text}>{text}</p>
        {isButton && (
          // <BlueButtonComponents text="Create +" onClickFunction={handler} />
          <BlueButtonComponents text={isPack ? "Create Pack": "Create + "} onClickFunction={handler} />
        )}
      </div>
    </>
  );
};

export default NoItemComponents;
