import React from "react";
import styles from "./styles.module.css";
import { Tooltip } from "@mui/material";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function CheckboxWithLabel(props) {
  const { title, value, info, action, handler } = props;

  const handleChange = (event) => {
    handler({ type: action, payload: event.target.checked });
  };

  return (
    <label className={styles.container}>
      <input type="checkbox" checked={value} onChange={handleChange} />
      <div className={styles.checkmark}></div>
      <span className={styles.checkbox_text}>
        {capitalizeFirstLetter(title)}
      </span>
      {info && (
        <Tooltip title={info} arrow>
          <img
            src="/v2Assets/submission_form_assets/moreInfoIcon.png"
            alt="info"
            className={styles.infoImage}
          />
        </Tooltip>
      )}
    </label>
  );
}

export default CheckboxWithLabel;
