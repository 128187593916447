import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, IconButton, Modal, linkClasses } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import { ModelContentBox, Modelstyle } from "./style";
import CloseIcon from "@mui/icons-material/Close";

import modalStyles from "../Libraries.module.css";

import styles from "../../../../components/OpportunitiesComponents/Opportunities/submit_opportunity.module.css"
import { useDropzone } from "react-dropzone";
import useAppState from "../../../../context/useAppState";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LibrariesSchema from "../../YupSchema/LibrariesSchema";
import { toast } from "react-toastify";

import BlueButtonComponents from "../../AdditionalComponents/utils/BlueButtonComponent";
import UploadField from "../../AdditionalComponents/utils/UploadFiled";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import {
  SCALE,
  KEY,
  TAGS,
  MODE_TONE,
  DEFAULT_VALUE,
} from "../../../../shared/constants";
import universalStyles from "../../../UniversalComponents/universal.module.css";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import Toggle from "react-toggle";
import "../../css/select.css";
import "../../css/toggle.css";
import "react-toggle/style.css";
import uploadSmallIcon from "../../../../assets/Icons/download.png";
import EditableFormikText from "../EditableFormikText";
import useAuth from "../../../../context/AuthContext";

import { submitAudioFiles, downloadResource, fetchFileList, fetchFileBlob, deleteDownloadedFiles, getTags, updateAudioFileMore } from "../../../../context/apis/audio-library";
import useLibraryStore from "../../../../store/library";
import { Notification } from "../../../NotifyMsg/Notification";
import { white_upload } from "../../../../assets/smartlink";
import CircularLoaderPage from "../../../UniversalComponents/CircularLoader/CircularLoaderPage";
import CircularProgress from '@mui/material/CircularProgress';


const MassEditModal = ({
  massEditModalOpen = false,
  setMassEditModal = () => { },
  audioFiles,
  setAudioFiles,
  data,
  setDataList,
  docID,
  editMore,
  setCardEditModal = () => { },
}) => {

  console.log("Data in MassEditModal", data);

  const [isLoadingAudio, setIsLoadingAudio] = useState(false);

  const fileInputRefs = useRef([]);
  const audioFirstRefs = useRef([]);
  const audioSecondRefs = useRef([]);
  const audioThirdRefs = useRef([]);

  const { user } = useAuth();
  const { uploadFile, uploadAudio } = useAppState("file");
  const [audioList, setAudioList] = useState([]);
  const { setNewAdded } = useLibraryStore();
  const [isLoading, setIsLoading] = useState(false);

  const [dropbox_link, setDropbox_link] = useState("");

  const [tagOptions, setTagOptions] = useState([]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? 'blue' : 'gray', // Change border color when input is focused
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#afafaf', // Change text color of the input field
    }),
  };

  const handleLinkInputChange = (e) => {
    setDropbox_link(e.target.value);
  };


  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    // Initialize FormData
    setSubmitting(true);
    setIsLoading(true);
    console.log("********************")
    console.log(values);

    for (let index = 0; index < values.forms?.length; index++) {
      let basicInfoData = values.forms[index].basicInfo;
      if (
        basicInfoData.fileName != "" &&
        basicInfoData.fileNote != "" &&
        basicInfoData.imageURL != "" &&
        basicInfoData.scale != "" &&
        basicInfoData.fileKey != "" &&
        basicInfoData.tags.length > 0 &&
        basicInfoData.bpm != ""
      ) {
        values.forms[index].isComplete = true;
      }
      else {
        values.forms[index].isComplete = false;
      }

    }

    const formData = new FormData();
    for (let index = 0; index < values.forms?.length; index++) {
      formData.append('imageFiles', values.forms[index].basicInfo.file);
    }

    // Append audio files to FormData
    for (let index = 0; index < audioFiles?.length; index++) {
      formData.append('audioFiles', audioFiles[index]);
    }

    // Append wav files to FormData
    for (let index = 0; index < values.forms?.length; index++) {
      formData.append('wavFiles', values.forms[index].pitchInfo?.wavFileID);
    }

    // Append instrumental files to FormData
    for (let index = 0; index < values.forms?.length; index++) {
      formData.append('instrumentalFiles', values.forms[index].pitchInfo?.instrumentalFileID);
    }

    // Append cleanVersion files to FormData
    for (let index = 0; index < values.forms?.length; index++) {
      formData.append('cleanVersionFiles', values.forms[index].pitchInfo?.cleanVersionFileID);
    }

    // Append other data to FormData
    formData.append("data", JSON.stringify(values));
    if (!editMore) {
      formData.append("user_id", user._id);
      await submitAudioFiles(formData).then((response) => {
        if (response) {
          setNewAdded();
          setSubmitting(false);
          setIsLoading(false);
          Notification("success", "Created an Audio Successfully");
          setMassEditModal(false);
        } else {
          Notification("error", "Error");
          setSubmitting(false);
          setIsLoading(false);
        }
      }).catch((error) => {
        console.log("catch error", error)
        Notification("error", `Server Error: ${error.message}`);
        Notification("error", `File Type is inValid`);
        setSubmitting(false);
        setIsLoading(false);
      });
    } else {
      console.log("data------------", data)
      formData.append("docID", docID);
      await updateAudioFileMore(formData).then((response) => {
        if (response) {
          setNewAdded();
          setSubmitting(false);
          setIsLoading(false);
          Notification("success", "Updated an Audio Successfully");
        } else {
          Notification("error", "Error");
          setSubmitting(false);
          setIsLoading(false);
        }
      }).catch((error) => {
        Notification("error", `Server Error: ${error.message}`);
        setSubmitting(false);
        setIsLoading(false);
      });
      console.log("Edit More Info", formData);
      setMassEditModal(false);
      setCardEditModal(false);
    }
  };

  useEffect(() => {
    setAudioList(audioFiles);
  });

  const handleModalClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setMassEditModal(false);
  };

  const handleCheckBoxChange = ({ field, form, event }) => {
    form.setFieldValue(field.name, event.target.checked);
  };

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <img
        className={universalStyles.search_icon}
        src="/v2Assets/connect_assets/Search.png"
        alt="search"
      ></img>
      {children}
    </components.Control>
  );

  const MultiSelectField = ({ field, form, options, ...props }) => {
    const handleChange = (selectedOptions) => {
      form.setFieldValue(field.name, selectedOptions);
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default behavior of submitting the form

        const inputValue = event.target.value.trim();
        if (inputValue) {
          const newOption = { value: inputValue, label: inputValue };
          handleChange([...field.value, newOption]);
          event.target.value = ''; // Clear the input field
        }
      }
    };

    return (
      <CreatableSelect
        {...field}
        {...props}
        options={options}
        isMulti
        onChange={handleChange}
        onBlur={field.onBlur}
        classNamePrefix="select_tags"
        components={{ Control, DropdownIndicator: null }}
        styles={customStyles}

      // onKeyDown={handleKeyDown}
      />
    );
  };

  const ToggleField = ({ field, form, options, ...props }) => {
    const handleToggleChange = (event) => {
      form.setFieldValue(field.name, event.target.checked);
    };

    return (
      <div
        className={modalStyles.cover_container}
        style={{ paddingRight: "5px" }}
      >
        <div className={modalStyles.input_box_text}>{props.text}</div>
        <Toggle
          {...field}
          checked={field.value}
          icons={{
            checked: <p className={modalStyles.toggle_yes}>YES</p>,
            unchecked: <p className={modalStyles.toggle_no}>No</p>,
          }}
          onChange={handleToggleChange}
          style={{ width: "60px" }}
        />
      </div>
    );
  };

  const onDropForFile = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        // setErrorMessage('Please upload only audio files.');
      } else {
        // setErrorMessage('');
        setAudioFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);

        if (acceptedFiles.length > 0) {
          const updatedData = acceptedFiles.map(() => ({ ...DEFAULT_VALUE }));
          setDataList((prevFormData) => ({
            ...prevFormData,
            forms: [...prevFormData.forms, ...updatedData],
          }));
        }
      }
    },
    [setAudioFiles, setDataList],
  );

  const extractDropBoxLink = async () => {
    setIsLoading(true);
    if (dropbox_link == "") {
      Notification("error", "Please input Dropbox Link");
      setIsLoading(false);
      return;
    }
    try {
      const convertedLink = dropbox_link.replace(/dl=0$/, "dl=1");
      console.log("convertedLink==== ", convertedLink);

      const result = await downloadResource(convertedLink);
      console.log(result.message);
      if (result.status === 200) {
        const fileNames = await fetchFileList();
        const downloadPromises = fileNames.map(fetchFileBlob);
        // Wait for all files to be fetched
        const newFiles = await Promise.all(downloadPromises);
        // Update the state with the new files
        setIsLoading(false);
        setAudioFiles((prevFiles) => [...prevFiles, ...newFiles]);
        if (newFiles.length > 0) {
          const updatedData = newFiles.map(() => ({ ...DEFAULT_VALUE }));
          setDataList((prevFormData) => ({
            ...prevFormData,
            forms: [...prevFormData.forms, ...updatedData],
          }));
        }
        await deleteDownloadedFiles();
      }
      else {
        setIsLoading(false);
        Notification("error", "The link looks invalid");
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching audio files:', error);
      Notification("error", "The link looks invalid");
    }
  };

  const onDropAudio = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0] || null;

    const payload = new FormData();

    if (file) {
      setIsLoadingAudio(true);
      payload.append("file", file);
      payload.append("type", "Public");

      try {
        const { fileLocation, audioWaveFileLocation } =
          await uploadAudio(payload);

      } catch (error) {
        console.info("error while saving file => ");
        toast.error("Something went wrong with file upload, please try again");
      } finally {
        setIsLoadingAudio(false);
      }
    }
  }, []);

  const TrackDropzone = useDropzone({
    onDrop: onDropAudio,
  });

  const handleImageClick = (index) => {
    if (
      fileInputRefs != undefined &&
      fileInputRefs.current[index] != undefined
    ) {
      fileInputRefs.current[index].click();
    }
  };

  const handleAduio1Click = (index) => {
    if (
      audioFirstRefs != undefined &&
      audioFirstRefs.current[index] != undefined
    ) {
      audioFirstRefs.current[index].click();
    }
  };

  const handleAudio2Click = (index) => {
    if (
      audioSecondRefs != undefined &&
      audioSecondRefs.current[index] != undefined
    ) {
      audioSecondRefs.current[index].click();
    }
  };

  const handleAudio3Click = (index) => {
    if (
      audioThirdRefs != undefined &&
      audioThirdRefs.current[index] != undefined
    ) {
      audioThirdRefs.current[index].click();
    }
  };

  useEffect(async () => {
    const response = await getTags();
    const transformedTags = response?.data?.tagValues.map((tag) => ({
      value: tag,
      label: tag
    }));

    setTagOptions(transformedTags);
    console.log("Confrim Tages in masseditmodal-- ", transformedTags);
  }, [massEditModalOpen]);

  return (
    <>
      <Modal
        open={massEditModalOpen}
        onClose={handleModalClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...Modelstyle,
            width: { xs: "100%", sm: "60%" },
            height: { xs: "90%", sm: "90%" },
            padding: "20px",
            paddingTop: "40px",
            borderRadius: "0.625rem",
            background: "#141515",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            position: "relative",
          }}
        >
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
               <CircularProgress color="inherit" />
            </Backdrop>
            <Box
              sx={{
                textAlign: "right",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                position: "absolute",
                top: "15px",
                right: "5%",
                width: "90%",
              }}
            >

              <Box className={modalStyles.modal_primary_font}>
                {!editMore ? (<div>Selected Files: {"  "}
                  <span className={modalStyles.modal_secondary_font}>
                    {audioFiles?.length}
                  </span></div>) : <div>Edit More Info</div>}

              </Box>
              <IconButton
                onClick={handleModalClose}
                aria-label="settings"
                sx={{
                  color: "#fff",
                  backgroundColor: "none",
                  fontSize: "2rem",
                  padding: "0 !important",
                  margin: "0 !important",
                  marginTop: "7px !important",
                  svg: {
                    color: "#fff",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                height: "100%"
              }}
            >

              <div
                className={!editMore ? modalStyles.mass_upload_container : modalStyles.mass_upload_container_for_more}
                style={{ border: "none" }}
              >
                {!editMore &&
                  <div className={`${modalStyles.d_flex} ${modalStyles.w_100}`}>
                    <div className={`${modalStyles.d_flex} ${modalStyles.w_100}`}>
                      <div className={modalStyles.upload_item}>
                        <UploadField
                          onDrop={onDropForFile}
                          title="Upload More Files"
                          desc="or Paste the Dropbox link for Mass Upload"
                        />
                        <div
                          className={modalStyles.dropbox_link}
                          style={{ marginTop: "-20px" }}
                        >
                          <input
                            className={modalStyles.search_box_input}
                            type="text"
                            placeholder={"Please here the Link"}
                            onChange={handleLinkInputChange}
                          />
                          <div
                            className={modalStyles.link_button}
                            onClick={extractDropBoxLink}
                          >
                            Next
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>


              <div className={modalStyles.modal_scroll}>
                <Formik
                  initialValues={data}
                  enableReinitialize={true}
                  validationSchema={LibrariesSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue, isSubmitting }) => (
                    <Form>
                      <FieldArray name="forms">
                        {({ push: pushForm }) => (
                          <div style={{marginTop: "15px"}}>
                            {values.forms.map((form, index, field) => {
                              return (
                                <div key={`fieldZ-${index}`}>
                                  {/* Basic Info Start*/}
                                  <Box
                                    className={modalStyles.mass_basic_container}
                                  >
                                    <Box
                                      className={modalStyles.modal_primary_font}
                                      style={{ marginBottom: "10px" }}
                                    >
                                      Basic File Info
                                    </Box>
                                    <Field
                                      name={`forms.${index}.basicInfo.userID`}
                                      className={modalStyles.div_item}
                                      type="hidden"
                                      value={user._id}
                                    />

                                    {/* Upload File */}
                                    <Box
                                      display="flex"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      marginTop="10px"
                                      style={{ padding: "0px 15px" }}
                                    >
                                      <div
                                        style={{
                                          width: "80px",
                                          background: "transparent",
                                        }}
                                      >
                                        <input
                                          type="file"
                                          id={`forms.${index}.basicInfo.file`}
                                          style={{ display: "none" }}
                                          ref={(el) =>
                                            (fileInputRefs.current[index] = el)
                                          }
                                          onChange={(event) => {
                                            const file =
                                              event.currentTarget.files[0];
                                            setFieldValue(
                                              `forms.${index}.basicInfo.file`,
                                              file,
                                            );
                                            setFieldValue(
                                              `forms.${index}.basicInfo.userID`,
                                              user._id,
                                            );
                                            setFieldValue(
                                              `forms.${index}.basicInfo.imageURL`,
                                              URL.createObjectURL(file),
                                            );
                                          }}
                                        />
                                        <img
                                          src={`${form.basicInfo.imageURL != "" ? !editMore ? form.basicInfo.imageURL : (`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${form.basicInfo.imageURL}`) : "/images/no_image.png"}`}
                                          alt="Uploaded"
                                          style={{ maxWidth: "100%" }}
                                          onClick={() => handleImageClick(index)}
                                          id={`image-${index}`}
                                        />
                                        <ErrorMessage
                                          name={`forms.${index}.basicInfo.file`}
                                          className={modalStyles.error_msg}
                                          component="div"
                                          style={{ width: "130px" }}
                                        />
                                      </div>
                                      <div style={{ marginLeft: "10px" }}>
                                        <div
                                          style={{
                                            color: "#FFFFFF",
                                            fontSize: "20px",
                                          }}
                                        >
                                          <EditableFormikText
                                            text={
                                              form.basicInfo.fileName ||
                                              "Untitled"
                                            }
                                            style={modalStyles.edited_title}
                                            name={`forms.${index}.basicInfo.fileName`}
                                            setFieldValue={setFieldValue}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            color: "#AFAFAF",
                                          }}
                                        >
                                          <EditableFormikText
                                            text={
                                              form.basicInfo.fileNote ||
                                              "Add a note"
                                            }
                                            style={modalStyles.edited_desc}
                                            name={`forms.${index}.basicInfo.fileNote`}
                                            setFieldValue={setFieldValue}
                                          />
                                        </div>
                                      </div>
                                      <img
                                        src={`/images/tick-${form.basicInfo.imageURL != null &&
                                          form.basicInfo.fileName != "" &&
                                          form.basicInfo.scale != "" &&
                                          form.basicInfo.fileKey != "" &&
                                          form.basicInfo.tags.length > 0 &&
                                          form.basicInfo.bpm != ""
                                          ? "complete.png" : "incomplete.png"}`}
                                        style={{ marginLeft: "10px" }}
                                      />

                                    </Box>

                                    <Box display="flex">
                                      <Box width="50%" padding="0px 10px">
                                        <Form>
                                          <div className={modalStyles.label}>
                                            Title
                                          </div>
                                          <Field
                                            name={`forms.${index}.basicInfo.title`}
                                            className={modalStyles.div_item}
                                            placeholder="Enter Title"
                                          />
                                          <ErrorMessage
                                            name={`forms.${index}.basicInfo.title`}
                                            className={modalStyles.error_msg}
                                            component="div"
                                          />
                                        </Form>

                                        <Form>
                                          <div className={modalStyles.label}>
                                            Scale
                                          </div>
                                          <Field
                                            name={`forms.${index}.basicInfo.scale`}
                                            as="select"
                                            className={modalStyles.div_item}
                                          >
                                            <option value="" disabled>
                                              Select Scale
                                            </option>
                                            {SCALE.map((scale, index) => {
                                              return (
                                                <option key={`scaleZ-${index}`} value={scale}>
                                                  {scale}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                          <ErrorMessage
                                            name={`forms.${index}.basicInfo.scale`}
                                            className={modalStyles.error_msg}
                                            component="div"
                                          />
                                        </Form>
                                        <Form>
                                          <div className={modalStyles.label}>
                                            Key
                                          </div>
                                          <Field
                                            name={`forms.${index}.basicInfo.fileKey`}
                                            as="select"
                                            className={modalStyles.div_item}
                                          >
                                            <option value="" disabled>
                                              Select Key
                                            </option>
                                            {KEY.map((fileKeyItem, index) => {
                                              return (
                                                <option key={`kkey-${index}`} value={fileKeyItem}>
                                                  {fileKeyItem}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                          <ErrorMessage
                                            name={`forms.${index}.basicInfo.fileKey`}
                                            className={modalStyles.error_msg}
                                            component="div"
                                          />
                                        </Form>
                                      </Box>
                                      <Box width="50%" padding="0px 10px">
                                        <Form>
                                          <div className={modalStyles.label}>
                                            Tags
                                          </div>
                                          <Field
                                            name={`forms.${index}.basicInfo.tags`}
                                            className={`${modalStyles.div_item} ${modalStyles.p_0}`}
                                            placeholder="Search Tags"
                                            component={MultiSelectField}
                                            options={tagOptions}
                                          />
                                          <ErrorMessage
                                            name={`forms.${index}.basicInfo.tags`}
                                            className={modalStyles.error_msg}
                                            component="div"
                                          />
                                        </Form>

                                        <Form>
                                          <div className={modalStyles.label}>
                                            BPM
                                          </div>
                                          <Field
                                            name={`forms.${index}.basicInfo.bpm`}
                                          >
                                            {(props) => (
                                              <div
                                                className={`${modalStyles.div_item} ${modalStyles.bpm_suffix}`}
                                              >
                                                <input
                                                  type="text"
                                                  {...props.field}
                                                  onChange={props.field.onChange}
                                                  onBlur={props.field.onBlur}
                                                  className={
                                                    modalStyles.input_field_2
                                                  }
                                                />
                                                <span
                                                  className={modalStyles.suffix}
                                                >
                                                  limit 200
                                                </span>
                                              </div>
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name={`forms.${index}.basicInfo.bpm`}
                                            className={modalStyles.error_msg}
                                            component="div"
                                          />
                                        </Form>
                                        {/* <Form>
                                        <div className={modalStyles.label}>
                                          Email
                                        </div>
                                        <Field
                                          name={`forms.${index}.basicInfo.email`}
                                          className={modalStyles.div_item}
                                        />
                                        <ErrorMessage
                                          name={`forms.${index}.basicInfo.email`}
                                          className={modalStyles.error_msg}
                                          component="div"
                                        />
                                      </Form> */}
                                      </Box>
                                    </Box>
                                  </Box>
                                  {/* Basic Info End*/}
                                  {/* Beat Info Start*/}
                                  <Box
                                    className={modalStyles.mass_basic_container}
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Box
                                      className={modalStyles.modal_primary_font}
                                      style={{ marginBottom: "10px" }}
                                    >
                                      Beat Info:{" "}
                                    </Box>
                                    <Box display="flex">
                                      <Box width="50%" padding="0px 10px">
                                        <Form>
                                          <div className={modalStyles.label}>
                                            Beat Exclusive Price
                                          </div>
                                          <Field
                                            name={`forms.${index}.beatInfo.exclusivePrice`}
                                            className={modalStyles.div_item}
                                          />
                                          <ErrorMessage
                                            name={`forms.${index}.beatInfo.exclusivePrice`}
                                            className={modalStyles.error_msg}
                                            component="div"
                                          />
                                        </Form>
                                        <Form>
                                          <div className={modalStyles.label}>
                                            Publisher Name
                                          </div>
                                          <Field
                                            name={`forms.${index}.beatInfo.publisherName`}
                                            className={modalStyles.div_item}
                                          />
                                          <ErrorMessage
                                            name={`forms.${index}.beatInfo.publisherName`}
                                            className={modalStyles.error_msg}
                                            component="div"
                                          />
                                        </Form>
                                        <Form>
                                          <div className={modalStyles.label}>
                                            Split %
                                          </div>
                                          <Field
                                            name={`forms.${index}.beatInfo.splitRatio`}
                                          >
                                            {(props) => (
                                              <div
                                                className={`${modalStyles.div_item} ${modalStyles.split_prefix}`}
                                              >
                                                <span
                                                  className={modalStyles.suffix}
                                                >
                                                  %
                                                </span>
                                                <input
                                                  type="text"
                                                  {...props.field}
                                                  onChange={props.field.onChange}
                                                  onBlur={props.field.onBlur}
                                                  className={
                                                    modalStyles.input_field_2
                                                  }
                                                />
                                              </div>
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name={`forms.${index}.beatInfo.splitRatio`}
                                            className={modalStyles.error_msg}
                                            component="div"
                                          />
                                        </Form>
                                      </Box>
                                      <Box width="50%" padding="0px 10px">
                                        <Form>
                                          <div className={modalStyles.label}>
                                            Beat Non-Exclusive Price
                                          </div>
                                          <Field
                                            name={`forms.${index}.beatInfo.nonExclusivePrice`}
                                            className={modalStyles.div_item}
                                          />
                                          <ErrorMessage
                                            name={`forms.${index}.beatInfo.nonExclusivePrice `}
                                            className={modalStyles.error_msg}
                                            component="div"
                                          />
                                        </Form>
                                        <Form>
                                          <div className={modalStyles.label}>
                                            Publisher Pro #
                                          </div>
                                          <Field
                                            name={`forms.${index}.beatInfo.publisherPro`}
                                            className={modalStyles.div_item}
                                          />
                                          <ErrorMessage
                                            name={`forms.${index}.beatInfo.publisherPro `}
                                            className={modalStyles.error_msg}
                                            component="div"
                                          />
                                        </Form>

                                        <Form>
                                          <div className={modalStyles.label}>
                                            Mood/Tone
                                          </div>
                                          <Field
                                            name={`forms.${index}.beatInfo.modeTone`}
                                            as="select"
                                            className={modalStyles.div_item}
                                          >
                                            <option value="" disabled>
                                              Select Moode/Tone
                                            </option>
                                            {MODE_TONE.map((mode_tone, index) => {
                                              return (
                                                <option
                                                  key={`mode_tone-${index}`}
                                                  value={mode_tone}
                                                >
                                                  {mode_tone}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                          <ErrorMessage
                                            name={`forms.${index}.beatInfo.modeTone`}
                                            className={modalStyles.error_msg}
                                            component="div"
                                          />
                                        </Form>
                                      </Box>
                                    </Box>
                                    {/* <Box padding="0px 10px" marginTop="20px">
                                    <Form>
                                      <Field
                                        name={`forms.${index}.beatInfo.isCollaborate`}
                                        className={modalStyles.div_item}
                                        component={({
                                          field,
                                          form,
                                          ...props
                                        }) => (
                                          <ToggleField
                                            text="Are there any collaborators on this audio file?"
                                            field={field}
                                            form={form}
                                            {...props}
                                          />
                                        )}
                                      />
                                  
                                    </Form>
                                  </Box> */}
                                    <Box
                                      padding="0px 10px"
                                      marginTop="20px"
                                    >
                                      <Form>
                                        <Field
                                          name={`forms.${index}.beatInfo.isCollaborate`}
                                          className={modalStyles.div_item}
                                        >
                                          {({ field, form, ...props }) => (
                                            <div
                                              className={
                                                modalStyles.owner_container
                                              }
                                            >
                                              <div
                                                className={
                                                  modalStyles.owner_toggle
                                                }
                                              >
                                                <div
                                                  className={
                                                    modalStyles.input_box_text
                                                  }
                                                  style={{
                                                    marginLeft: "0px",
                                                  }}
                                                >
                                                  Are there any collaborators on this audio file?
                                                </div>
                                                <Toggle
                                                  checked={field.value}
                                                  icons={{
                                                    checked: (<p className={modalStyles.toggle_yes}>YES</p>),
                                                    unchecked: (<p className={modalStyles.toggle_no}>No</p>
                                                    ),
                                                  }}
                                                  field={field}
                                                  form={form}
                                                  onChange={(event) => handleCheckBoxChange({ field, form, event })}
                                                  style={{ width: "60px" }}
                                                  {...props}
                                                />
                                              </div>
                                              {field.value && (
                                                <>
                                                  <div>
                                                    <button
                                                      className={styles.addCollaboratorsBtn}
                                                      style={{ width: "250px", height: "37px" }}
                                                      type="button"
                                                      onClick={() => {
                                                        const currentCollaborators = form.values.forms[index].beatInfo.collaborationList || []; // Get current collaborators or initialize an empty array
                                                        const updatedCollaborators = [
                                                          ...currentCollaborators,
                                                          { musicianName: '', email: '', splitPercentage: '' }
                                                        ];
                                                        setFieldValue(`forms.${index}.beatInfo.collaborationList`, updatedCollaborators);
                                                      }}

                                                    >
                                                      <div className={modalStyles.collaborator_button_container}>
                                                        <div className={modalStyles.collaborator_button_text}>Add Collabrator</div>
                                                        <div className={modalStyles.collaborator_button_text}>+</div>
                                                      </div>
                                                    </button>
                                                  </div>


                                                  <FieldArray name={`forms.${index}.beatInfo.collaborationList`}>
                                                    {({ push, remove }) => {
                                                      return (
                                                        <div>
                                                          {form.values.forms[index]?.beatInfo?.collaborationList?.map((collaborator, collaboratorIndex) => (
                                                            <div key={collaboratorIndex} className={modalStyles.collabrator_card}>
                                                              <div onClick={() => remove(collaboratorIndex)} className={modalStyles.collabrator_card_close_icon}><CloseIcon style={{ color: "#AFAFAF" }} /></div>
                                                              <Box width="100%" display="flex">
                                                                <Box width="50%" padding="0px 10px">
                                                                  <div className={modalStyles.label}>Musician Name</div>
                                                                  <Field name={`forms.${index}.beatInfo.collaborationList[${collaboratorIndex}].musicianName`} className={modalStyles.div_item} />

                                                                  <div className={modalStyles.label}>Spilt %</div>
                                                                  <Field name={`forms.${index}.beatInfo.collaborationList[${collaboratorIndex}].splitPercentage`} className={modalStyles.div_item} />

                                                                </Box>
                                                                <Box width="50%" padding="0px 10px">
                                                                  <div className={modalStyles.label}>Email</div>
                                                                  <Field name={`forms.${index}.beatInfo.collaborationList[${collaboratorIndex}].email`} className={modalStyles.div_item} />
                                                                </Box>
                                                              </Box>
                                                            </div>
                                                          ))}
                                                        </div>
                                                      )
                                                    }}
                                                  </FieldArray>
                                                </>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                      </Form>
                                    </Box>
                                  </Box>
                                  {/* Beat Info End*/}
                                  {/* Does int container Master sample */}
                                  <Box
                                    className={modalStyles.mass_basic_container}
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      gap="20px"
                                    >
                                      <Box padding="0px 10px">
                                        <Form>
                                          <Field
                                            name={`forms.${index}.beatInfo.containMasterSample`}
                                            className={modalStyles.div_item}
                                            component={({
                                              field,
                                              form,
                                              ...props
                                            }) => (
                                              <ToggleField
                                                text="Does its contain master sample?"
                                                field={field}
                                                form={form}
                                                {...props}
                                              />
                                            )}
                                          />
                                        </Form>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {/* Pitch for Sync Start */}
                                  <Box
                                    className={modalStyles.mass_basic_container}
                                    style={{ marginTop: "20px" }}
                                  >
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      gap="20px"
                                    >
                                      <Box padding="0px" display="flex">
                                        <Field
                                          name={`forms.${index}.pitchInfo.enablePitch`}
                                          className={modalStyles.checkbox}
                                          type="checkbox"
                                        />
                                        <Box
                                          className={
                                            modalStyles.modal_primary_font
                                          }
                                          style={{ marginLeft: "10px" }}
                                        >
                                          Pitch for Sync
                                        </Box>
                                      </Box>
                                      {form.pitchInfo.enablePitch && (
                                        <Box padding="0px 10px">
                                          <div
                                            style={{
                                              padding: "0px 10px",
                                              display: "flex",
                                              gap: "10px",
                                            }}
                                          >
                                            <div className={modalStyles.w_50}>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  justifyContent: "center",
                                                  gap: "10px",
                                                }}
                                              >
                                                <Form>
                                                  <div
                                                    className={modalStyles.label}
                                                  >
                                                    Upload Wav Files
                                                  </div>
                                                  <Field
                                                    name={`forms.${index}.pitchInfo.wavFileID`}
                                                    component={({
                                                      field,
                                                      form,
                                                    }) => (
                                                      <div
                                                        className={modalStyles.div_item}
                                                        style={{
                                                          display: "flex",
                                                          justifyContent: "space-between",
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                          <img
                                                            src={uploadSmallIcon}
                                                            className={modalStyles.subupload_small_icon}
                                                            alt="upload"
                                                          />
                                                          <div className={modalStyles.subupload_text}>{field.value?.name ? field.value.name : "Upload or Drag Wav Files "}</div>
                                                        </div>
                                                        <div className={modalStyles.subupload_container} onClick={() => handleAduio1Click(index)}>
                                                          <img
                                                            src={white_upload}
                                                            className={modalStyles.subupload_small_icon}
                                                            alt="upload"
                                                          />
                                                          <input
                                                            type="file"
                                                            id={`forms.${index}.pitchInfo.wavFileID`}
                                                            style={{ display: "none" }}
                                                            ref={(el) =>
                                                              (audioFirstRefs.current[index] = el)
                                                            }
                                                            accept=".wav"
                                                            onChange={(event) => {
                                                              const file = event.currentTarget.files[0];
                                                              console.log("File=", file)
                                                              if (file && file.name.endsWith('.wav')) {
                                                                setFieldValue(`forms.${index}.pitchInfo.wavFileID`, file);
                                                              } else {
                                                                toast.warning('Invalid file type. Please select a .wav file.')
                                                                event.currentTarget.value = '';
                                                              }
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    )}
                                                  />
                                                </Form>
                                                <Form>
                                                  <div
                                                    className={modalStyles.label}
                                                  >
                                                    Upload Clean Version
                                                    <span
                                                      style={{ color: "#2EBB55" }}
                                                    >
                                                      (optional)
                                                    </span>
                                                  </div>
                                                  <Field
                                                    name={`forms.${index}.pitchInfo.cleanVersionFileID`}
                                                    component={({
                                                      field,
                                                      form,
                                                      ...props
                                                    }) => (
                                                      <div
                                                        className={modalStyles.div_item}
                                                        style={{
                                                          display: "flex",
                                                          justifyContent: "space-between",
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                          <img
                                                            src={uploadSmallIcon}
                                                            className={modalStyles.subupload_small_icon}
                                                            alt="upload"
                                                          />
                                                          <div className={modalStyles.subupload_text}>{field.value?.name ? field.value.name : "Upload or Drag Clean Version"}</div>
                                                        </div>
                                                        <div className={modalStyles.subupload_container} onClick={() => handleAudio2Click(index)}>
                                                          <img
                                                            src={white_upload}
                                                            className={modalStyles.subupload_small_icon}
                                                            alt="upload"
                                                          />
                                                        </div>
                                                        <input
                                                          type="file"
                                                          id={`forms.${index}.pitchInfo.cleanVersionFileID`}
                                                          style={{ display: "none" }}
                                                          ref={(el) =>
                                                            (audioSecondRefs.current[index] = el)
                                                          }
                                                          // accept=".wav"
                                                          onChange={(event) => {
                                                            const file =
                                                              event.currentTarget.files[0];
                                                            setFieldValue(`forms.${index}.pitchInfo.cleanVersionFileID`, file);
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                  />
                                                </Form>
                                              </div>
                                            </div>
                                            <div className={modalStyles.w_50}>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  justifyContent: "center",
                                                  gap: "10px",
                                                }}
                                              >
                                                <Form>
                                                  <div
                                                    className={modalStyles.label}
                                                  >
                                                    Upload Instrumental(s)

                                                  </div>
                                                  <Field
                                                    name={`forms.${index}.pitchInfo.instrumentalFileID`}
                                                    component={({
                                                      field,
                                                      form,
                                                      ...props
                                                    }) => (
                                                      <div
                                                        className={modalStyles.div_item}
                                                        style={{
                                                          display: "flex",
                                                          justifyContent: "space-between",
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                          <img
                                                            src={uploadSmallIcon}
                                                            className={modalStyles.subupload_small_icon}
                                                            alt="upload"
                                                          />
                                                          <div className={modalStyles.subupload_text}>{field.value?.name ? field.value.name : "Upload or Drag Clean Version"}</div>
                                                        </div>
                                                        <div className={modalStyles.subupload_container} onClick={() => handleAudio3Click(index)}>
                                                          <img
                                                            src={white_upload}
                                                            className={modalStyles.subupload_small_icon}
                                                            alt="upload"
                                                          />
                                                        </div>
                                                        <input
                                                          type="file"
                                                          id={`forms.${index}.pitchInfo.instrumentalFileID`}
                                                          style={{ display: "none" }}
                                                          ref={(el) =>
                                                            (audioThirdRefs.current[index] = el)
                                                          }
                                                          onChange={(event) => {
                                                            const file =
                                                              event.currentTarget.files[0];
                                                            setFieldValue(`forms.${index}.pitchInfo.instrumentalFileID`, file);
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                  />
                                                </Form>
                                              </div>
                                            </div>
                                          </div>
                                          <Box
                                            padding="0px 10px"
                                            marginTop="20px"
                                          >
                                            <Form>
                                              <Field
                                                name={`forms.${index}.pitchInfo.trackInfo.enableTrack`}
                                                className={modalStyles.div_item}
                                                component={({
                                                  field,
                                                  form,
                                                  ...props
                                                }) => (
                                                  <ToggleField
                                                    text="Is the track released or Unreleased ?"
                                                    field={field}
                                                    form={form}
                                                    {...props}
                                                  />
                                                )}
                                              />
                                            </Form>
                                          </Box>
                                          <Box
                                            padding="0px 10px"
                                            marginTop="20px"
                                          >
                                            <Form>
                                              <Field
                                                name={`forms.${index}.pitchInfo.ownerInfo.enableOwner`}
                                                className={modalStyles.div_item}
                                              >
                                                {({ field, form, ...props }) => (
                                                  <div className={modalStyles.owner_container}>
                                                    <div className={modalStyles.owner_toggle} >
                                                      <div className={modalStyles.input_box_text} style={{ marginLeft: "0px", }}>
                                                        Are you the master owner?
                                                      </div>
                                                      <Toggle
                                                        checked={field.value}
                                                        icons={{
                                                          checked: (
                                                            <p className={modalStyles.toggle_yes}>Yes</p>
                                                          ),
                                                          unchecked: (
                                                            <p className={modalStyles.toggle_no}>No</p>
                                                          ),
                                                        }}
                                                        field={field}
                                                        form={form}
                                                        onChange={(event) => handleCheckBoxChange({ field, form, event, })}
                                                        style={{ width: "60px" }}
                                                        {...props}
                                                      />
                                                    </div>
                                                    {field.value && (
                                                      <Box
                                                        width="100%"
                                                        display="flex"
                                                      >
                                                        <Box
                                                          width="50%"
                                                          padding="0px 10px"
                                                        >
                                                          <Form>
                                                            <div
                                                              className={
                                                                modalStyles.label
                                                              }
                                                            >
                                                              Full Name
                                                            </div>
                                                            <Field
                                                              // name={`forms.${index}.pitchInfo.ownerInfo.fullName`}
                                                              className={
                                                                modalStyles.div_item
                                                              }
                                                              value={`${user.firstname} ${user.lastname}`}
                                                            />

                                                          </Form>
                                                          <Form>
                                                            <div
                                                              className={
                                                                modalStyles.label
                                                              }
                                                            >
                                                              Phone number
                                                            </div>
                                                            <Field
                                                              // name={`forms.${index}.pitchInfo.ownerInfo.phone`}
                                                              className={
                                                                modalStyles.div_item
                                                              }
                                                              value={`${user.phoneno}`}
                                                            />

                                                          </Form>
                                                        </Box>
                                                        <Box
                                                          width="50%"
                                                          padding="0px 10px"
                                                        >
                                                          <Form>
                                                            <div
                                                              className={
                                                                modalStyles.label
                                                              }
                                                            >
                                                              Email Address
                                                            </div>
                                                            <Field
                                                              // name={`forms.${index}.pitchInfo.ownerInfo.email`}
                                                              className={
                                                                modalStyles.div_item
                                                              }
                                                              value={`${user.email}`}
                                                            />

                                                          </Form>
                                                        </Box>
                                                      </Box>
                                                    )}
                                                  </div>
                                                )}
                                              </Field>
                                            </Form>
                                          </Box>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                  {/* Pitch for Sync End */}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </FieldArray>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          width: "100%",
                        }}
                      >
                        <BlueButtonComponents
                          text={!isSubmitting ? !editMore ? 'Next' : 'Finish Editing' : 'Submitting...'}
                          fontsize="16px"
                          background="#414241"
                          color="#AFAFAF"
                          padding="8px 20px"
                          type="submit"
                          marginRight="10px"
                          disabled={isSubmitting ? true : false}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </>

        </ModelContentBox>
      </Modal>
    </>
  );
};

export default MassEditModal;
