import React from "react";
import { OpportunityMain } from "./styles/OpportunityStyle";
import { Box, Button, Grid } from "@mui/material";
import {
  opportunity_image1,
  opportunity_image2,
  opportunity_image3,
} from "../../assets/smartlink";

const opportunityData = [
  {
    image: opportunity_image1,
    singer_Name: "Talha Anjum",
    listner: "30.3 M Monthly Listner",
  },
  {
    image: opportunity_image2,
    singer_Name: "Talha Anjum",
    listner: "30.3 M Monthly Listner",
  },
  {
    image: opportunity_image3,
    singer_Name: "Dua Lipa",
    listner: "30.3 M Monthly Listner",
  },
];
const Opportunity = () => {
  return (
    <>
      <OpportunityMain>
        <Grid container spacing={3}>
          {opportunityData.map((items) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              key={items.singer_Name}
              sx={{ width: "100%" }}
            >
              <Box className="item_main">
                <img
                  src={items.image}
                  alt={items.singer_Name}
                  className="item_image"
                />
                <Box className="desc_box">
                  <h5>{items.singer_Name}</h5>
                  <h6>{items.listner}</h6>
                </Box>
                <Box className="btn_box">
                  <Button variant="contained" className="btn">
                    Submit Beats
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </OpportunityMain>
    </>
  );
};

export default Opportunity;
