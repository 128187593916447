import React, { useState, useEffect } from "react";

import styles from "./Libraries.module.css";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Box } from "@material-ui/core";


import SendFileModal from "./Modals/SendFileModal";
import { FileIcon, InfoCirclegray, TrashIcon, ViewIcon } from "../../../assets/smartlink";
import LibLineDivider from "./LibLineDivider";
import FavIcon from "../../../assets/smartlink/icons/FavIcon";
import EyeIcon from "../../../assets/smartlink/icons/EyeIcon";
import ShareIcon from "../../../assets/smartlink/icons/ShareIcon";
import ShareSVG from "../../../assets/smartlink/icons/ShareSvg";
import DownloadSvg from "../../../assets/smartlink/icons/DownloadSvg";
import ShareSvg from "../../../assets/smartlink/icons/ShareSvg";
import PackActionSvg from "../../../assets/smartlink/icons/PackActionSvg";
import DarkTooltip from "../../OpportunitiesComponents/AdditionalComponents/Tooltip/DarkTooltip";
import { Pack_Detail_Tooltop, UNCOMPLETE_LIBRARY_TOOLTIP } from "../../../shared/constants";
import { getUserInfo } from "../../../context/apis/audio-library";

function formatMongoDate(mongoDate) {
  console.log("✔ --- Origin Date ---", mongoDate);

  // Convert to Date object if mongoDate is a string
  const dateObject = (typeof mongoDate === "string") ? new Date(mongoDate) : mongoDate;

  // Ensure dateObject is a valid Date object
  if (!(dateObject instanceof Date) || isNaN(dateObject)) {
    throw new Error('Invalid date object');
  }

  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const date = String(dateObject.getDate()).padStart(2, '0');
  const year = String(dateObject.getFullYear()).slice(-2); // Get last 2 digits of the year

  return `${month}/${date}/${year}`;
}

function formatDuration(duration) {
  // Ensure duration is a number and not negative
  if (typeof duration !== "number" || duration < 0) {
    throw new Error('Invalid duration. Must be a non-negative number.');
  }

  const minutes = Math.floor(duration / 60); // Get whole minutes
  const seconds = Math.round(duration % 60); // Get remaining seconds

  // Pad with zero if necessary to always have two digits
  const minutesStr = String(minutes).padStart(2, '0');
  const secondsStr = String(seconds).padStart(2, '0');

  return `${minutesStr}:${secondsStr}`;
}

function TimeAgo(createdAT) {
  const currentTime = new Date();

  console.log(" ---Current Time--- ", currentTime);


  const pastTime = new Date(createdAT);
  const diffInSeconds = Math.floor((currentTime - pastTime) / 1000);

  let timeAgo = "";
  if (diffInSeconds < 60) {
    timeAgo = `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    // less than 1 hour
    const minutes = Math.floor(diffInSeconds / 60);
    timeAgo = `${minutes} minutes ago`;
  } else if (diffInSeconds < 86400) {
    // less than 1 day
    const hours = Math.floor(diffInSeconds / 3600);
    timeAgo = `${hours} hours ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    timeAgo = `${days} days ago`;
  }

  return timeAgo;
}

function PackDetailCard({ packData, audioFileData }) {
  const isDesktop = useMediaQuery("(min-width: 1200px)");



  console.log(' ---Viewer--- ', audioFileData?.data?.basicInfo?.viewers);
  console.log(' ---Downloader--- ', audioFileData?.data?.basicInfo?.downloaders);
  const cover_image =
    audioFileData?.data?.basicInfo?.imageURL ?
      (`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${audioFileData?.data?.basicInfo?.imageURL}`.replace(/\\/g, '/').replace(/�/g, '')) :
      "/images/no_image.png";
  console.log("cover_image", cover_image);
  const user_avatar = "/images/no_image.png";

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sendFileModalOpen, setSendFileModalOpen] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const navigate = useNavigate();

  const goPackMoreDetail = (id1, id2, packname, audioname) => {
    console.log("id is", id2);
    navigate(`/myaudiolibrary/beat_pack/${id1}/${id2}?packname=${packname}&audioname=${audioname}`)
  }

  const downloadAudioFile = async (filePath) => {
    const baseUrl = `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/`;
    const absoluteUrl = new URL(filePath, baseUrl);
    try {

      const response = await fetch(absoluteUrl.href);
      // Convert response to blob
      const blob = await response.blob();
      const filename = filePath.split('\\').pop().split('/').pop();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement('a');
      link.href = url;
      // link.download = 'audio-file.mp3'; // Set the desired file name
      link.download = filename; // Set the desired file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading audio file:', error);
    }
  };

  function getActiveAndNonActiveUsers(viewers, downloaders) {
    // Find active users (present in both viewers and downloaders)
    const activeUsers = viewers.filter((viewer) =>
      downloaders.some((downloader) => downloader.personId === viewer.personId)
    );

    // Find non-active viewers (not present in downloaders)
    const nonActiveViewers = viewers.filter(
      (viewer) => !downloaders.some((downloader) => downloader.personId === viewer.personId)
    );

    // Find non-active downloaders (not present in viewers)
    const nonActiveDownloaders = downloaders.filter(
      (downloader) => !viewers.some((viewer) => viewer.personId === downloader.personId)
    );

    return {
      activeUsers,
      nonActiveViewers,
      nonActiveDownloaders,
    };
  }

  const { activeUsers, nonActiveViewers, nonActiveDownloaders } =
    getActiveAndNonActiveUsers(audioFileData?.data?.basicInfo?.viewers, audioFileData?.data?.basicInfo?.downloaders);


  console.log("activeuser", activeUsers);
  console.log("nonviewers", nonActiveViewers);
  console.log("nondownloaders", nonActiveDownloaders);


  const TooltipContent = () => {
    return (
      <Box className={styles.tooltip_layout}>
        <div className={styles.tooltip_content}>
          <div className={styles.tooltip_key}>BPM</div>
          <div className={styles.tooltip_value}>{audioFileData?.data?.basicInfo?.bpm}</div>
        </div>
        <div className={styles.tooltip_content}>
          <div className={styles.tooltip_key}>Scale</div>
          <div className={styles.tooltip_value}>{audioFileData?.data?.basicInfo?.scale}</div>
        </div>
        <div className={styles.tooltip_content}>
          <div className={styles.tooltip_key}>Key</div>
          <div className={styles.tooltip_value}>{audioFileData?.data?.basicInfo?.fileKey}</div>
        </div>
      </Box>
    )
  }

  return (
    <Box>
      <div className={styles.pack_detail_card_layout}>
        <div className={styles.opportunity_card_content} >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "30%",
                cursor: "pointer"
              }}
              onClick={() => setIsExpand(!isExpand)}
            >
              <div className={styles.opportunity_card_image_container}>
                <div
                  className={styles.opportunity_card_image}
                  style={{
                    background: `url(${cover_image})center center / cover no-repeat`,
                  }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "12px", flexDirection: "column" }}>
                <div className={styles.pack_detail_card_title}>{audioFileData?.data?.basicInfo?.fileName}</div>
                <div className={styles.pack_detail_card_subtitle}>{audioFileData?.data?.basicInfo?.fileNote}</div>
              </div>

            </div>

            <div className={`${styles.w_20}`} >
              <DarkTooltip title={<TooltipContent />} placement="bottom">
                <img src={InfoCirclegray} alt="info" width={20} height={20} />
              </DarkTooltip>
            </div>
            <div className={`${styles.w_10} ${styles.text_center} ${styles.pack_detail_content_title}`}>{formatDuration(audioFileData?.data?.basicInfo?.fileDuration)}</div>
            <div className={`${styles.w_10} ${styles.text_center} ${styles.pack_detail_content_title}`}>{audioFileData?.data?.basicInfo?.viewers?.length}</div>
            <div className={`${styles.w_10} ${styles.text_center} ${styles.pack_detail_content_title}`}>{formatMongoDate(audioFileData?.data?.createdAt)}</div>
            {/* <div className={`${styles.w_10} ${styles.text_center} ${styles.pack_detail_content_title}`}>02/03/22</div> */}
            <div className={`${styles.w_20} ${styles.pack_action_container}`}>
              <div className={styles.iconText} onClick={() => { setSendFileModalOpen(!sendFileModalOpen) }}><ShareSvg color="#AFAFAF" /></div>
              <div className={styles.iconText} onClick={() => downloadAudioFile(audioFileData?.data?.basicInfo?.fileURL)}><DownloadSvg color="#AFAFAF" /></div>
              <div className={styles.iconText} onClick={() => { goPackMoreDetail(packData._id, audioFileData?.data?._id, packData?.basicInfo.packName, audioFileData?.data?.basicInfo?.fileName) }}>
                <PackActionSvg color="#AFAFAF" />
              </div>
            </div>

          </div>
        </div>
        {isExpand && <DetailComponent />}
      </div>

      <SendFileModal
        open={sendFileModalOpen}
        setOpen={setSendFileModalOpen}
        type="file"
        id={audioFileData?.data?._id}
        imageUrl={audioFileData?.data?.basicInfo?.imageURL}
        title={audioFileData?.data?.basicInfo?.fileName}
      />
    </Box>
  );

  function DetailComponent() {
    const [activeUsersAvatar, setActiveUsersAvatar] = useState([]);
    const [nonActiveViewersAvatar, setNonActiveViewersAvatar] = useState([]);
    const [nonActiveDownloadersAvatar, setNonActiveDownloadersAvatar] = useState([]);

    useEffect(() => {
      // Create a function to fetch data as useEffect should not return anything
      const fetchActiveUsersAvatar = async () => {
        try {
          // Use Promise.all to wait for all getUserInfo calls to finish
          const avatarPromises = activeUsers.map(user =>
            getUserInfo(user.personId)
          );

          // Await all promises and then extract profilePic from each resolved promise
          const avatars = (await Promise.all(avatarPromises)).map(
            response => response?.data?.profilePic
          );

          // Set the state with the fetched avatars
          setActiveUsersAvatar(avatars);
        } catch (error) {
          console.error("Failed to fetch activer avatars", error);
        }
      };

      // Create a function to fetch data as useEffect should not return anything
      const fetchNonActiveViewersAvatar = async () => {
        try {
          // Use Promise.all to wait for all getUserInfo calls to finish
          const avatarPromises = nonActiveViewers.map(user =>
            getUserInfo(user.personId)
          );

          // Await all promises and then extract profilePic from each resolved promise
          const avatars = (await Promise.all(avatarPromises)).map(
            response => response?.data?.profilePic
          );

          // Set the state with the fetched avatars
          setNonActiveViewersAvatar(avatars);
        } catch (error) {
          console.error("Failed to fetch nonActiveViewer avatars", error);
        }
      };

      // Create a function to fetch data as useEffect should not return anything
      const fetchNonActiveDownloadersAvatar = async () => {
        try {
          // Use Promise.all to wait for all getUserInfo calls to finish
          const avatarPromises = nonActiveDownloaders.map(user =>
            getUserInfo(user.personId)
          );

          // Await all promises and then extract profilePic from each resolved promise
          const avatars = (await Promise.all(avatarPromises)).map(
            response => response?.data?.profilePic
          );

          // Set the state with the fetched avatars
          setNonActiveDownloadersAvatar(avatars);
        } catch (error) {
          console.error("Failed to fetch nonActiveDownloader avatars", error);
        }
      };


      // Call the function if there are any active users
      if (activeUsers.length > 0) {
        fetchActiveUsersAvatar();
      }

      if (nonActiveViewers.length > 0) {
        fetchNonActiveViewersAvatar();
      }

      if (nonActiveDownloaders.length > 0) {
        fetchNonActiveDownloadersAvatar();
      }

    }, []);
    return (
      <div style={{ marginLeft: "3px" }}>
        {activeUsers && activeUsers.length > 0 && (
          activeUsers.map((activer, index) => (

            <div className={styles.pack_detail_card_layout} style={{ border: "none", borderTop: "1px solid #323333" }}>
              <div className={styles.opportunity_card_content}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className={styles.opportunity_card_image_container}>
                      <div
                        className={styles.opportunity_card_image}
                        style={{
                          background: `url(${activeUsersAvatar[index]})center center / cover no-repeat`,
                          borderRadius: "100px"
                        }}
                      />
                    </div>
                    <div>
                      <div className={styles.pack_detail_card_title}>{activer.name}</div>
                      <div className={styles.pack_detail_content_title}>{TimeAgo(activer.time)}</div>
                      {/* <div className={styles.pack_detail_card_title}>Downloader One</div> */}
                      {/* <div className={styles.pack_detail_content_title}>11 Hours Ago</div> */}
                    </div>
                  </div>
                  <div className={styles.opportunity_data_information}>
                    <div className={styles.opportunity_tags_container}>
                      <FavIcon color="#2EBB55" />
                      <EyeIcon color="#2EBB55" />
                    </div>
                  </div>
                </div>
              </div>
            </div >
          ))
        )}

        {nonActiveViewers && nonActiveViewers.length > 0 && (
          nonActiveViewers.map((viewer, index) => (

            <div className={styles.pack_detail_card_layout}
              style={{ border: "none", borderTop: "1px solid #323333" }}
            >
              <div className={styles.opportunity_card_content}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className={styles.opportunity_card_image_container}>
                      <div
                        className={styles.opportunity_card_image}
                        style={{
                          background: `url(${nonActiveViewersAvatar[index]})center center / cover no-repeat`,
                          borderRadius: "100px"
                        }}
                      />
                    </div>
                    <div>
                      <div className={styles.pack_detail_card_title}>{viewer.name}</div>
                      <div className={styles.pack_detail_content_title}>{TimeAgo(viewer.time)}</div>
                      {/* <div className={styles.pack_detail_card_title}>Viewer One</div>
                      <div className={styles.pack_detail_content_title}>23 Hours Ago</div> */}
                    </div>
                  </div>
                  <div className={styles.opportunity_data_information}>
                    <div className={styles.opportunity_tags_container}>
                      <FavIcon color="#AFAFAF" />
                      <EyeIcon color="#2EBB55" />
                    </div>
                  </div>
                </div>
              </div>
            </div >
          ))
        )}
        {nonActiveDownloaders && nonActiveDownloaders.length > 0 && (
          nonActiveDownloaders.map((downloader, index) => (

            <div className={styles.pack_detail_card_layout} style={{ border: "none", borderTop: "1px solid #323333" }}>
              <div className={styles.opportunity_card_content}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className={styles.opportunity_card_image_container}>
                      <div
                        className={styles.opportunity_card_image}
                        style={{
                          background: `url(${nonActiveDownloadersAvatar[index]})center center / cover no-repeat`,
                          borderRadius: "100px"
                        }}
                      />
                    </div>
                    <div>
                      <div className={styles.pack_detail_card_title}>{downloader.name}</div>
                      <div className={styles.pack_detail_content_title}>{TimeAgo(downloader.time)}</div>
                      {/* <div className={styles.pack_detail_card_title}>Downloader One</div> */}
                      {/* <div className={styles.pack_detail_content_title}>11 Hours Ago</div> */}
                    </div>
                  </div>
                  <div className={styles.opportunity_data_information}>
                    <div className={styles.opportunity_tags_container}>
                      <FavIcon color="#2EBB55" />
                      <EyeIcon color="#AFAFAF" />
                    </div>
                  </div>
                </div>
              </div>
            </div >
          ))
        )}
      </div>
    )
  }

}
export default PackDetailCard;
