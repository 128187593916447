import React from "react";
import { Grid } from "@mui/material";

// import styles from "./styles.module.css";
import { Sidebar } from "../../layout/Sidebar/Sidebar";

import InboxComponent from "../../components/InboxComponents/inboxComponent";

const Chat = () => {
  return (
    <Grid>
      <Sidebar activeText="Chat" childComponent={<InboxComponent />} />
    </Grid>
  );
};

export default Chat;
