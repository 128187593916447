import React, { memo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Main } from "./styles";
import { SubMenu, BeatInboxMenu } from "./menu";
import useAuth from "../../../../context/AuthContext";

const HorizontalMenu = ({ isBeatInbox = false }) => {
  const location = useLocation();
  const { user } = useAuth();
  // const navigate = useNavigate();
  // const [activeItemIndex, setActiveItemIndex] = useState(null);

  // const handleItemClick = (index, url) => {
  //   setActiveItemIndex(index);
  //   navigate(url);
  // };

  const ShowMenuList = isBeatInbox ? BeatInboxMenu : SubMenu;

  const LinkUrls = [
    "/smart-link/pre-release-create",
    "/smart-link/createlink",
    "/smart-link/created-link",
    "/smart-link/insights",
    "/smart-link/insights-sources",
    "/smart-link/insights-commission",
    "/smart-link/insights-sales",
    "/smart-link/insights-devices",
    "/smart-link/insights-location",
    "/smart-link/release",
  ];

  const IsAdmin = user?.roleId?.roleName;

  return (
    <Main>
      <ul>
        {ShowMenuList?.map((value) => {
          const isActiveLink =
            location.pathname === value.url ||
            (LinkUrls.includes(location.pathname) && value.name === "Link");
          const isAdminAndNotStarredBeats =
            (IsAdmin === "Admin" ||
              IsAdmin === "A & R" ||
              IsAdmin === "Label" ||
              IsAdmin === "Manager") &&
            value.name == "Get More Storage";

          return (
            <>
              {isAdminAndNotStarredBeats ? null : (
                <li key={value.name}>
                  <Link
                    // className={
                    //   activeItemIndex === index
                    //     ? "active"
                    //     : "" || location.pathname === value?.url
                    //     ? "active"
                    //     : ""
                    // }
                    className={isActiveLink ? "active" : ""}
                    to={value?.url}
                  >
                    <img src={value.icon} alt={value.name} />
                    {value.name}
                  </Link>
                </li>
              )}
            </>
          );
        })}
      </ul>
    </Main>
  );
};

export default memo(HorizontalMenu);
