import React from "react";
import { Main, LinksMain } from "./styles";
import { Grid } from "@mui/material";
import { InsightPageLink } from "./Links";
import { Link, useLocation } from "react-router-dom";

const LinkInsightsLayout = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <Main>
        <Grid container spacing={2}>
          <Grid item xl={2} lg={2} md={4} sm={12}>
            <LinksMain>
              <ul>
                {InsightPageLink.map((item) => {
                  return (
                    <>
                      <li
                        key={item?.url}
                        className={
                          item?.url === location.pathname ? "active" : ""
                        }
                      >
                        <Link to={item?.url}>
                          <img src={item?.icon} alt={item?.name} />
                          {item?.name}
                        </Link>
                      </li>
                    </>
                  );
                })}
              </ul>
            </LinksMain>
          </Grid>
          <Grid item xl={10} lg={10} md={8} sm={12}>
            {children}
          </Grid>
        </Grid>
      </Main>
    </>
  );
};

export default LinkInsightsLayout;
