import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

const MainBeatsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: "#0A0B0A",
  padding: "0.75rem",
  borderRadius: "5px",
  height: "140px",
  // overflow: "hidden",
  width: "100%",
  "&:hover": {
    background: "rgba(46, 187, 85, 0.10)",
  },
  "& .desc-box": {
    marginTop: "0.75rem",
    "& p": {
      color: "#AFAFAF",
      fontSize: theme.typography.h6,
      fontFamily: theme.typography.fontFamily,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      wordWrap: "break-word",
      margin: "0",
    },
  },
  [theme.breakpoints.down("lg")]: {
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    height: "100%",
    "& .desc-box": {
      "& p": {
        wordBreak: "break-all",
      },
    },
  },
}));

const HeaderInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  position: "relative",

  "& .left": {
    "& .main_image": {
      width: "48px !important",
      height: "48px !important",
      borderRadius: "3px",
      objectFit: "cover",
    },
    display: "flex",

    width: "50%",

    gap: "0.56rem",

    "& .beat-info": {
      display: "flex",
      flexDirection: "column",
      gap: "0.12rem",

      "& h4": {
        color: "#fff",
        fontFamily: theme.typography.fontFamily,
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginTop: "0",
        width: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },

      "& .desc": {
        display: "flex",
        gap: "2px",
        alignItems: "center",
        "& p": {
          fontFamily: theme.typography.fontFamily,
          color: "#AFAFAF",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        },
        "& span": {
          fontFamily: theme.typography.fontFamily,
          color: "#AFAFAF",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        },
      },
    },
  },
  "& .right": {
    // display: "flex",
    // alignItems: "center",
    "& .icon-box": {
      // display: "flex",
      // alignItems: "flex-start",
      // gap: "0.75rem",

      "& .icon": {
        cursor: "pointer",
      },
      "& .BeatBoxDropdown": {
        padding: "12px",
        background: "#141515",
        borderRadius: "5px",
        right: "0",
        top: "45px",
        position: "absolute",
        zIndex: "1",
        gap: "8px",

        "& .BeatboxList": {
          display: "flex",
          width: "150px",
          padding: " 6.5px 0px",
          alignItems: "center",
          cursor: "pointer",
          gap: "5px",
          borderBottom: "0.8px solid #AFAFAF",

          "&:last-of-type": {
            borderBottom: "none",
          },
        },
        "& h1": {
          color: "#AFAFAF",
          fontFamily: theme.typography.fontFamily,
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        },
      },
    },
  },

  // [theme.breakpoints.down("lg")]: {
  //   width: "100%",
  //   // flexDirection: "column",
  //   gap: "15px",
  //   "& .right": {
  //     width: "100%",
  //     "& .icon-box": {
  //       width: "100%",
  //       justifyContent: "space-around",
  //     },
  //   },
  // },
  [theme.breakpoints.down("sm")]: {
    "& h4": {
      fontSize: "12px !important",
    },
    "& p": {
      fontSize: "9px !important",
    },
    //   width: "100%",
    //   // flexDirection: "column",
    //   gap: "15px",
    //   "& .right": {
    //     width: "100%",
    //     "& .icon-box": {
    //       width: "100%",
    //       justifyContent: "flex-start",
    //     },
    //   },
  },
}));

export { MainBeatsBox, HeaderInfoBox };
