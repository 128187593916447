import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import SyncSubmissionsComponent from "../../components/SubmissionsComponents/SyncSubmissions/syncSubmissionComponent";

const SyncSubmissionsPage = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<SyncSubmissionsComponent />}
        activeText="My Submissions"
      />
    </Grid>
  );
};

export default SyncSubmissionsPage;
