import React, { useState } from "react";
import Select from "react-select";
import { SelectMain } from "./style";
import { Box } from "@mui/material";

const Option = ({ innerProps, label }) => (
  <Box
    sx={{
      padding: "10px 20px",
      borderBottom: "0.8px solid #AFAFAF",
      "&:hover": {
        borderRadius: "3px",
        background: "#414241",
        border: "none",
      },
    }}
  >
    <div {...innerProps}>{label}</div>
  </Box>
);

const SplitSelect = ({
  label = "",
  options,
  placeholder,
  onChange = () => {},
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "48px",
      borderRadius: "5px",
      border: state.isFocused ? "1px solid #414241" : "1px solid #414241",
      background: "#1C1B1B",
      color: "#fff",
      boxShadow: "none",
      "&:hover": { borderColor: "#414241" },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menuPortalTarget: document.body,
    menu: (provided) => ({
      ...provided,
      width: "120px",
      left: "auto",
      right: "0",
      background: "#141515",
      borderRadius: "5px",
      color: "#AFAFAF",
      fontFamily: "Poppins",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      padding: "15px",
      textAlign: "left",
      marginTop: "0px",
      cursor: "pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#AFAFAF",
    }),
  };

  const handleMenuOpen = (state) => {
    setIsMenuOpen(state.menuIsOpen);
  };

  const HandlerSelect = (value) => {
    onChange(value.value);
    setSelectedOption(value.value);
  };

  return (
    <SelectMain>
      <h5>{label}</h5>
      <Select
        className="select-box"
        defaultValue={selectedOption}
        onChange={HandlerSelect}
        onMenuOpen={() => handleMenuOpen({ menuIsOpen: true })}
        onMenuClose={() => handleMenuOpen({ menuIsOpen: false })}
        options={options}
        styles={customStyles}
        components={{
          Option,
          IndicatorSeparator: () => null,
        }}
        menuIsOpen={isMenuOpen}
        placeholder={placeholder}
      />
    </SelectMain>
  );
};

export default SplitSelect;
