import React, { useState } from "react";
import styles from "./distribution.module.css";
import PageSidebar from "../UniversalComponents/page_sidebar";
import DistributionPerks from "./distribution_pages/DistributionPerks";
import DistributionApply from "./distribution_pages/DistributionApply";
import DistributionCalculate from "./distribution_pages/DistributionCalculate";

export default function DistributionPages() {
  const [activeTab, setActiveTab] = useState("Perks");
  const tabs = ["Perks", "Apply", "Calculate"];

  const switchToApplyTab = () => {
    setActiveTab("Apply");
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.header_menu}>
        {tabs.map((tab) => (
          <p
            key={tab}
            className={`
        ${styles.menu_item} 
        ${activeTab === tab ? styles.menu_item_selected : ""}
      `}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </p>
        ))}
      </div>
      <div className={styles.page_layout}>
        {activeTab === "Perks" && (
          <DistributionPerks switchToApplyTab={switchToApplyTab} />
        )}
        {activeTab === "Apply" && <DistributionApply />}
        {activeTab === "Calculate" && <DistributionCalculate />}
      </div>
      {/* <PageSidebar
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      /> */}
    </div>
  );
}
