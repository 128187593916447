import { useReducer } from "react";

const initialArgs = {
  currentUrl: ""
}

function reducer(state, action) {
  return { ...state, ...action };
}

const useCommon = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const updateURL = async (url = "") => {
    try {
      setState({ ...state, currentUrl: url })
    } catch (error) {

    }
  }

  return {
    ...state,
    setState,
    updateURL,
  };
};

export default useCommon;
