import { gql } from '@apollo/client';
export const verify_PromoCode = gql`
	mutation checkPromocode($promoCode: String, $email: String) {
		checkPromocode(promoCode: $promoCode, email: $email) {
			success
			message
		}
	}
`;
export const verify_Freemium_PromoCode = gql`
	mutation attachFreemiumPlan($promoCode: String, $email: String) {
		attachFreemiumPlan(promoCode: $promoCode, email: $email) {
			success
			message
			user {
				_id
				profilePic
				userName
				roleId {
					roleName
				}
			}
			accessToken
			refreshToken
			reactionAccessToken
			reactionRefreshToken
			sessionId
		}
	}
`;
