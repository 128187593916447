import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Musician = styled(Box)(({ theme }) => ({
  background: "#141515",
  width: "100%",
  display: "flex",
  padding: "50px 0 50px 0",
  justifyContent: "center",
  "& .MainMusician": {
    width: "85%",
    "& .MusicHead": {
      margin: 0,
      marginBottom: 25,
      span: {
        color: "#FFF",
        fontFamily: '"Poppins", sans-serif',
        fontSize: "56px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "70px",
        position: "relative",
        "&:after": {
          content: "''",
          width: "4px",
          position: "absolute",
          left: "-30px",
          top: 0,
          background: "#2EBB55",
          height: "100%",
          borderRadius: "50px",
        },
      },
    },
    "& .MainGrid2": {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",

      "& .MusicContentBox": {
        width: "80%",
        // border: "1px Solid Red",
      },
      "& .MusicListBar": {
        display: "flex",
        alignItems: "flex-start",
        padding: 0,
        gap: "48px",
        "& .ListItem": {
          color: "#AFAFAF",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          textTransform: "capitalize",
          listStyle: "none",
          cursor: "pointer",
          position: "relative",
          "& .UnderLine": {
            width: "100%",
            height: "2px",
            background: "#2EBB55;",
            position: "absolute",
            strokeWidth: "3px",
            stroke: "#2EBB55",
            borderRadius: "50px",
            bottom: "-2px",
          },
        },
      },
      "& .MusicContent": {
        color: "#AFAFAF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },
    },
  },
  [theme.breakpoints.down("md")]: {
    "& .MainMusician": {
      width: "100%",
      "& .MusicHead": {
        marginTop: "20px !important",
        textAlign: "center",
        span: {
          fontSize: "32px !important",
        },
      },
      "& .breakLine": {
        display: "none !important",
      },
    },

    "& .MusicContentBox": {
      width: "100% !important",
    },
    "& .MusicContent": {
      textAlign: "center",
    },
  },
}));

export { Musician };
