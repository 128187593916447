import React, { useEffect, useRef, useState } from "react";
import {
  forward_left,
  forward_right,
  pausecircle_green,
  playcircle_green,
  VolumeIcon,
  Shuffle,
  flipIcon,
  Dummyimg,
  VolumeCross,
} from "../../../../assets/smartlink";
import Slider from "@mui/material/Slider";

import "./style.css";

const MultiAudioPlayer = ({ src, isPlaying, onToggle, key }) => {
  const audioRef = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    const audioElement = audioRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(audioElement.currentTime);
      setDuration(audioElement.duration);
    };

    audioElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audioElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (isMuted) {
      audioElement.muted = true;
    } else {
      audioElement.muted = false;
    }
  }, [isMuted]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.playbackRate = playbackSpeed;
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, playbackSpeed]);

  const handleToggle = () => {
    onToggle(src);
  };

  const handleSeek = (e) => {
    const seekTime = (e.target.value * duration) / 100;
    setCurrentTime(seekTime);
    audioRef.current.currentTime = seekTime;
  };

  const handleForward = () => {
    audioRef.current.currentTime += 10;
  };

  const handleBackward = () => {
    audioRef.current.currentTime -= 10;
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div style={{ gap: "10px" }} className="player">
      <div className="ControlsMain">
        <button className="prev" onClick={handleBackward}>
          <img src={forward_left} alt="forward_left.svg" title="Backword" />
        </button>

        <button className="next" onClick={handleToggle}>
          {isPlaying ? (
            <img src={pausecircle_green} alt="PauseCircle.svg" title="Pause" />
          ) : (
            <img src={playcircle_green} alt="PlayCircle.svg" title="Play" />
          )}
        </button>

        <button className="next" onClick={handleForward}>
          <img src={forward_right} alt="forward_right.svg" title="Forword" />
        </button>
      </div>
      <audio ref={audioRef} src={src} />

      <Slider
        size="small"
        min={0}
        max={100}
        onChange={handleSeek}
        disabled={!duration}
        value={(currentTime / duration) * 100 || 0}
        aria-label="Small"
        color="success"
      />

      <div className="volume">
        <button className="volumeButton" onClick={handleMute}>
          {isMuted ? (
            <img
              style={{ height: "12px", width: "12px" }}
              src={VolumeCross}
              alt="MuteIcon.svg"
              title="Mute"
            />
          ) : (
            <img
              style={{ height: "12px", width: "12px" }}
              src={VolumeIcon}
              alt="Unmuteicon.svg"
              title="Unmute"
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default MultiAudioPlayer;
