import axios from "../../config/axiosInterceptor";
import { HTTP_STATUS } from "../../shared/constants";

export const beatInboxTest = async (userId) => {
  try {
    const { data, status } = await axios.get(
      `https://api-united-new.demotrt.com/rest/getBeatInboxSubmissions`
    );

    if (status === HTTP_STATUS.OK || status === 201) {
      return data;
    } else {
      throw new Error("Error while testing ");
    }
  } catch (error) {
    console.log("Error while testing BeatInboxTest:", error);
    throw new Error(error);
  }
};
//   `${process.env.REACT_APP_BEAT_URL}/getBeatInboxSubmissions`
