import React from 'react';
import styles from './../styles.module.css';
export const MusicFile = () => {
  return (
    <svg
      className={styles.addPhoto}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M57.2592 16.5564V19.6408C57.3105 20.8102 57.0783 21.9746 56.5826 23.0349C56.0869 24.0953 55.3422 25.0201 54.4121 25.7306C53.2536 26.5623 51.8572 26.9969 50.4313 26.9696C49.5075 26.9732 48.5896 26.8217 47.716 26.5215L33.533 21.8026V47.4533C33.5481 50.3524 32.5621 53.1679 30.7415 55.4241C28.921 57.6803 26.3774 59.2389 23.5406 59.8367C20.7037 60.4345 17.7475 60.0347 15.1713 58.705C12.5951 57.3752 10.5568 55.197 9.40087 52.5383C8.24491 49.8796 8.04209 46.9034 8.82668 44.1124C9.61127 41.3214 11.3352 38.8869 13.7072 37.2199C16.0792 35.553 18.9538 34.7559 21.8455 34.9633C24.7373 35.1707 27.4688 36.3698 29.5786 38.3582V10.5458C29.5379 9.37522 29.7774 8.21188 30.2772 7.15262C30.777 6.09336 31.5227 5.16884 32.4521 4.45604C33.4233 3.80888 34.5334 3.39997 35.6923 3.26255C36.8512 3.12513 38.0262 3.26307 39.1218 3.66516L50.774 7.56681C52.614 8.26942 54.2083 9.49482 55.3605 11.0921C56.5128 12.6894 57.1728 14.5888 57.2592 16.5564Z'
        fill='#AFAFAF'
      />
    </svg>
  );
};
