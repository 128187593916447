import axios from "axios";

const PAYMENT_INSTANCE = axios.create({
  baseURL: `${process.env.REACT_APP_PAYMENTS_URL}/payments`,
});

PAYMENT_INSTANCE.interceptors.request.use((config) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { token } = userData ? userData : "";
  config.headers.token = token ? token : "";
  return config;
});

const stripeDirectCheckout = async ({
  userId,
  paymentType,
  amount,
  currency,
  source,
  description,
}) => {
  const payload = {
    userId,
    paymentType,
    amount,
    currency,
    source,
    description,
  };

  try {
    let data = await PAYMENT_INSTANCE.post("/custom-payment", payload);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { stripeDirectCheckout };
