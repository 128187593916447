import React, { useState, memo, useEffect } from "react";
import { Box, Drawer, Grid } from "@mui/material";
import { Header, MainTitle } from "../";
import { DeskTopDrawer, MainComponent } from "./styles";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Theme from "../theme";
import { Sidebar } from "../../../layout/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";

const SmartLinkLayout = ({ children, title = "" }) => {
  const location = useLocation();
  const fullUrl = location.pathname + location.search;
  const params = new URLSearchParams(fullUrl?.split("?")[1]);
  const type = params.get("type");

  const [isWelcomeHome, setIsWelcomeHome] = useState(false);

  useEffect(() => {
    setIsWelcomeHome(location.pathname === "/welcome-home");
  }, [location]);

  const paddingValue = isWelcomeHome ? 0 : 50;

  const ActiveText = isWelcomeHome
    ? "Home"
    : type === "opportunities"
    ? "My Submissions"
    : title || "Beat Inbox";

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Grid>
        <Sidebar
          activeText={ActiveText}
          childComponent={
            <div style={{ padding: paddingValue }}>{children}</div>
          }
        />
      </Grid>
    </ThemeProvider>
  );
};

export default memo(SmartLinkLayout);
