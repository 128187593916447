import React, { useState, useEffect } from "react";
import { Main } from "./style";
import { Box, Button } from "@mui/material";
import SwitchButton from "../common/switchbutton/SwitchButton";
import SelectInboxPopup from "./SelectInboxPopup";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_ACTIVE_INBOX } from "../../../Graphql_services/smartlink/SmartLink";
import SelectedBoxPopup from "./SelectedBoxPopup";

const MainArr = [
  {
    title: "Beat Inbox",
    desc: "Turn on to connect to an inbox and receive beats from music producers.",
  },
  {
    title: "Show Thumbnail",
    desc: "Display sender photo in inbox listing",
  },
  {
    title: "Smart Group Ream Inbox",
    desc: "Groups read and unread inbox in a conversation",
  },
  {
    title: "Auto-Fit Messages",
    desc: "Optimize message to fit to screen",
  },
];
const InboxSetting = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [GetActiveinbox, { loading, data, error }] =
    useLazyQuery(GET_ALL_ACTIVE_INBOX);

  useEffect(() => {
    if (open) {
      GetActiveinbox({
        variables: { page: 1, perPage: 9 },
        context: { clientName: "beats" },
      });
    }
  }, [open, GetActiveinbox]);

  const CreateModel = () => {
    setOpen(true);
  };

  const handleCheckedtype = (e) => {
    setOpen(e.target.checked);
  };

  console.log(data?.getActiveBeatBoxes?.beatBoxes, "Data");

  return (
    <Main>
      <Box>
        {MainArr.map((item, id) => (
          <Box className="inbox_setting_main">
            <Box className="inbox_setting_text" key={id}>
              <h6>{item.title}</h6>
              <p>{item.desc}</p>
            </Box>
            <Box className="inbox_setting_switch">
              <SwitchButton onChange={handleCheckedtype} title={item.title} />
            </Box>
          </Box>
        ))}
      </Box>
      <Box className="inbox_setting_button">
        <Button onClick={CreateModel}>Save</Button>
      </Box>
      <SelectInboxPopup
        ActiveData={data?.getActiveBeatBoxes?.beatBoxes}
        open={open}
        setOpen2={setOpen2}
        setOpen={setOpen}
      />
      <SelectedBoxPopup open={open2} setOpen={setOpen2} />
    </Main>
  );
};

export default InboxSetting;
