import { Box, HStack } from "native-base";
import React from "react";
import TutorialComp from "../../components-old/Tutorial/Tutorial";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
const Tutorial = () => {
  return (
    <Box w={"100%"} bg={"#000201"}>
      <HStack w={"100%"}>
        <Sidebar childComponent={<TutorialComp />} activeText="Media" />
      </HStack>
    </Box>
  );
};

export default Tutorial;
