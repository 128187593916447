import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const LandingContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    padding: "0 20px",
    background: "#141515",
  },
}));

const HeroSection = styled(Box)(({ theme }) => ({
  background: "#141515",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  "& .InnerSec": {
    marginTop: "160px",
    width: "85%",
    "& .HeroContent": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    "& .HeroHead": {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "56px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "70px",
      margin: 0,
    },
    "& .HeroPara ": {
      color: "#AFAFAF",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    "& .ScreenSec": {
      position: "relative",
      height: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",

      "& .ScreenMiain": {
        marginTop: "70px",
        background: "#141515",
        "& .GlobeImg": {
          position: "absolute",
          top: "30px",
          left: "12%",
        },
        "& .WireImg": {
          position: "absolute",
          bottom: "20px",
          left: "15.5%",
        },
        "& .ScreenStand": {
          position: "absolute",
          bottom: "0",
          left: "30%",
          width: "716px",
          height: "42px",
          flexShrink: 0,
          borderRadius: "716px",
          background: "rgba(8, 8, 8, 0.25)",
          filter: "blur(62px)",
        },
      },
    },
  },
  [theme.breakpoints.down("xl")]: {
    "& .WireImg": {
      bottom: "0 !important",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& .HeroHead": {
      fontSize: "34px !important",
      lineHeight: "50px !important",
      padding: "20px 0",
    },
    "& .HeroPara": {
      fontSize: "16px !important",
      paddingBottom: "18px !important",
    },
    "& .ScreenSec": {
      height: "100% !important",
    },
    "& .GlobeImg": {
      top: "46px !important",
      left: "-8% !important",
      width: "60px !important",
    },
    "& .WireImg": {
      bottom: "-33px !important",
      left: "1.5% !important",
      width: "60px !important",
    },
  },
}));

const LadingButton = styled(Button)(({ theme }) => ({
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  borderRadius: "5px",
  background: "#2EBB55",
  padding: "8px 24px",
  gap: " 10px",
  textTransform: "capitalize",
  "&:hover": {
    background: "#2EBB55",
  },
  "& .learn_btn": {
    marginTop: "60px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export { HeroSection, LadingButton, LandingContainer };
