const analytics_data = {
  header_stats: [
    {
      id: "1",
      number: 5536,
      title: "Revenue",
      change: +3.43,
      icon: "/AnalyticsIcons/coins.png",
      time_frame: "Since last month",
    },
    {
      id: "2",
      number: 439,
      title: "Users",
      change: -2.43,
      icon: "/AnalyticsIcons/user.png",
      time_frame: "Since last month",
    },
    {
      id: "3",
      number: 39,
      title: "Churn",
      change: -10.42,
      icon: "/AnalyticsIcons/losses.png",
      time_frame: "Since last month",
    },
    {
      id: "4",
      number: 2059,
      title: "Sessions",
      change: +6.93,
      icon: "/AnalyticsIcons/sessionsIcon.png",
      time_frame: "Since last week",
    },
  ],
  time_spent_per_page: [
    {
      page_title: "Opportunities",
      page_percent: 63,
    },
    {
      page_title: "Distribution",
      page_percent: 36,
    },
    {
      page_title: "Settings",
      page_percent: 23,
    },
    {
      page_title: "Dashboard",
      page_percent: 14,
    },
    {
      page_title: "My Submissions",
      page_percent: 9,
    },
  ],
  drop_off_per_page: [
    {
      page_title: "Settings",
      page_percent: 50,
    },
    {
      page_title: "Dashboard",
      page_percent: 41,
    },
    {
      page_title: "Opportunities",
      page_percent: 36,
    },
    {
      page_title: "Distribution",
      page_percent: 21,
    },
    {
      page_title: "My Submissions",
      page_percent: 11,
    },
  ],
  recent_activity: [
    {
      id: "1",
      title: "Invite sent to",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "2",
      title: "Invite withdrawn from",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "3",
      title: "Follow up sent to",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "4",
      title: "Response from",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "5",
      title: "Reply sent to",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "6",
      title: "Meeting scheduled with",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "7",
      title: "Scheduling Link sent to",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
  ],
};

export default analytics_data;
