import React from "react";
import classes from "./Sidebar.module.css";

function SidebarIconNew(props) {
  return (
    <div className={classes.sidebar_icon}>
      <img
        className={classes.sidebar_icon_image}
        src={props.sidebar_icon}
        alt="icon"
      ></img>
    </div>
  );
}

export default SidebarIconNew;
