import React, { useState } from "react";
import { InsightMain } from "../insightsources/style";
import HeaderWidgets from "../widgets/headertabs/HeaderWidgets";
import InsightsTables from "../widgets/InsightsTables";
import ResultCount from "../widgets/resultcount/ResultCount";
import { image2 } from "../../../../assets/smartlink";

const InsightsSales = () => {
  const [activeButton, setActiveButton] = useState("Products");
  const buttons = ["Products", "Stores"];

  const TableHeaddata = [
    { title: "#", val: "id" },
    { title: "Products", val: "products" },
    { title: "Item Sold", val: "item-sold" },
    { title: "Earning", val: "earning" },
  ];

  const BodyData = [
    {
      id: "1",
      Link: {
        image: image2,
        title: "Tu Hai Kaha",
        desc: "Uran Official",
      },
      itemsold: 1,
      earning: "49.65",
    },
    {
      id: "2",
      Link: {
        image: image2,
        title: "Tu Hai Kaha",
        desc: "Uran Official",
      },
      itemsold: 1,
      earning: "49.65",
    },
  ];

  return (
    <>
      <InsightMain className="InsightMain">
        <HeaderWidgets
          title="Sales"
          buttons={buttons}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
        />

        <ResultCount
          count={0}
          title="Products"
          ShowPriceAndnumber={true}
          price="$49.65"
        />
        <InsightsTables
          BodyData={BodyData}
          ProgressBar={false}
          TableHeadData={TableHeaddata}
          NoDataText="No Products"
        />
      </InsightMain>
    </>
  );
};

export default InsightsSales;
