import React from "react";
import { ShareBtn } from "./style";
import CircularProgress from "@mui/material/CircularProgress";

const ShareButton = ({ name = "", type = "button", isloading = false }) => {
  return (
    <>
      <ShareBtn variant="contained" type={type} disabled={isloading}>
        {isloading ? (
          <CircularProgress size={20} sx={{ color: "#000" }} />
        ) : (
          name
        )}
      </ShareBtn>
    </>
  );
};

export default ShareButton;
