import React, { memo, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ModelContentBox, HeaderInfoBox, SocialMain } from "./sharestyle";
import {
  close_icon,
  image2,
  gray_smstracking,
  gray_copy,
  search_icon,
  singer2,
  edit_gray,
  twiter_x_icon,
  facebook_icon,
  instagram_icon,
  mail_icon,
} from "../../../../../assets/smartlink";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DoneIcon from "@mui/icons-material/Done";
import { Notification } from "../../../../NotifyMsg/Notification";
import {
  GetAllActiveBeatBoxes,
  UPDATE_BEATBOX_SHARE_LINK,
} from "../../../../../Graphql_services/beatinbox/Beats";
import { useMutation } from "@apollo/client";
import LoadingSpinner from "../../../common/loader/LoadingSpinner";
import CircularProgress from "@mui/material/CircularProgress";
// import theme from "../../../../theme/theme";
// import CancelIcon from "../../../../assets/images/cancel.svg";
// import BeatsImg from "../../../../assets/images/beat-img.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "background.paper",
  border: "none",
  pt: 2,
  px: 4,
  pb: 3,
};

const SocialPlatforms = [
  {
    icon: facebook_icon,
    title: "facebook",
  },
  {
    icon: twiter_x_icon,
    title: "x",
  },
  {
    icon: instagram_icon,
    title: "instagram",
  },
  {
    icon: mail_icon,
    title: "mail",
  },
];

const ShareInboxModel = ({
  open = false,
  setOpen = () => {},
  SharedData = [],
}) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [loading, setloading] = useState(false);

  const inputRef = useRef(null);
  const [updateBeatBoxShareLink] = useMutation(UPDATE_BEATBOX_SHARE_LINK, {
    refetchQueries: [GetAllActiveBeatBoxes, "getActiveBeatBoxes"],

    context: { clientName: "beats" },
  });

  useEffect(() => {
    if (SharedData?.beatboxShareLink) {
      const Link = SharedData?.beatboxShareLink.toString().replace(/\s+/g, "");
      setInputValue(Link);
    } else {
      const Tit = SharedData?.title?.toString().replace(/\s+/g, "");
      setInputValue(`${Tit}/unitedmarket`);
    }
  }, [SharedData]);

  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditable]);

  const ShareModelClose = () => {
    setOpen(!open);
    setIsEditable(false);
  };

  const copyToClipboard = async (textToCopy) => {
    const Url = window.location.href.split("/");
    const BaseUrl = Url[0] + "//" + Url[2];

    try {
      await navigator.clipboard.writeText(`${BaseUrl}${textToCopy}`);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
      Notification("success", `${SharedData.title} link copied`);
    } catch (err) {
      console.error("Copy failed: ", err);
    }
  };

  const handleEditClick = () => {
    setIsEditable(true);
    const newValue = inputValue.replace("/unitedmarket", "");
    setInputValue(newValue);
  };

  const handleBlur = () => {
    setIsEditable(false);
  };

  const validateInput = () => {
    // const lettersOnlyRegex = /^[A-Za-z]+$/;
    const lettersOnlyRegex = /^[A-Za-z0-9.]+$/;
    const Value = inputValue.trim();
    if (!Value) {
      Notification("error", "Please enter a value.");
      return false;
    }

    if (!lettersOnlyRegex.test(Value)) {
      Notification(
        "error",
        "Please enter only letters, numbers, or decimal points without special characters."
      );
      return false;
    }

    return true;
  };

  const handleUpdateBeatLink = async () => {
    if (!validateInput()) {
      return;
    }
    const ID = SharedData?._id;
    const CreatedLink = inputValue.replace("/unitedmarket", "");
    setloading(true);
    try {
      const { data } = await updateBeatBoxShareLink({
        variables: { inboxId: ID, beatboxShareLink: CreatedLink },
      });
      console.log("Mutation result:", data);
      if (data?.updateBeatBoxShareLink?.success) {
        Notification("success", data?.updateBeatBoxShareLink?.message);
        setInputValue(
          `${data?.updateBeatBoxShareLink?.beatboxShareLink}/unitedmarket`
        );
        setIsEditable(false);
      } else {
        setIsEditable(true);
        Notification("error", data?.updateBeatBoxShareLink?.message);
      }
    } catch (error) {
      console.error("Mutation error:", error);
      Notification("error", error.message || "");
      setIsEditable(false);
    } finally {
      setloading(false);
      // setIsEditable(false);
    }
  };

  const handleCopy = (e) => {
    // Prevent the default behavior of copying
    e.preventDefault();
    // Optionally, you can provide some feedback to the user
    console.log("Copying is not allowed.");
  };

  return (
    <>
      <Modal
        open={open}
        onClose={ShareModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...style,
            width: { xs: "100%", sm: "500px" },
            display: "flex",
            padding: "40px 40px 60px 40px",
            borderRadius: "10px",
            background: "#141515",
            border: "none",
            position: "relative",
          }}
        >
          <Box className="CreateModelMead">
            <Box className="heading-box">
              <Typography variant="h3">Send Inbox</Typography>
            </Box>
            <IconButton
              onClick={ShareModelClose}
              aria-label="settings"
              sx={{
                // position: "absolute",
                // top: "30px",
                // right: "25px",
                //   color: theme.palette.primary.dark,
                fontSize: "2rem",
                padding: "0 !important",
                margin: "0 !important",
              }}
            >
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>
          <Box className="send-main-box">
            <p variant="body1">Send File</p>
            <HeaderInfoBox>
              <Box className="left">
                <img
                  src={SharedData?.image || image2}
                  alt={SharedData?.title}
                />
                <Box className="beat-info">
                  <Typography variant="h4">{SharedData?.title}</Typography>
                  <Box className="desc">
                    <Typography variant="body1">Submitted Beats :</Typography>
                    <span>
                      {SharedData?.totalBeats ? SharedData?.totalBeats : 0}
                    </span>
                  </Box>
                </Box>
              </Box>
              {/* <Box className="right">
                <Box className="icon-box">
                  <IconButton color="primary" aria-label="Delete">
                    <MoreHorizIcon />
                  </IconButton>
                </Box>
              </Box> */}
            </HeaderInfoBox>
          </Box>
          <Box className="search_main">
            <Box className="search_box">
              {!isEditable && <img src={search_icon} alt="gray_copy" />}
              {isEditable ? (
                <input
                  ref={inputRef}
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value.trim())}
                  // onBlur={handleBlur}
                  autoFocus
                  className="editable_input"
                />
              ) : (
                // <input
                //   // type="text"
                //   value={inputValue}
                //   readOnly
                //   style={{ userSelect: "none" }}
                // >
                <span
                  onCopy={handleCopy}
                  style={{
                    userSelect: "none",
                    color: "#FFF",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  {inputValue}
                </span>
              )}
            </Box>

            {!isEditable && (
              <>
                <IconButton
                  color="primary"
                  aria-label="gray_copy"
                  onClick={handleEditClick}
                >
                  <img src={edit_gray} alt="gray_copy" />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="gray_copy"
                  onClick={() =>
                    copyToClipboard(`/${inputValue}/${SharedData?._id}`)
                  }
                  className={`${copySuccess ? "copySuccess" : ""}`}
                >
                  {!copySuccess ? (
                    <img src={gray_copy} alt="gray_copy" />
                  ) : (
                    <DoneIcon sx={{ color: "#fff" }} />
                  )}
                </IconButton>
              </>
            )}
            {isEditable && (
              <IconButton
                color="primary"
                aria-label="gray_copy"
                onClick={handleUpdateBeatLink}
                className="copySuccess"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress style={{ color: "#fff" }} size={20} />
                ) : (
                  <DoneIcon sx={{ color: "#fff" }} />
                )}
              </IconButton>
            )}

            {/* <IconButton
              color="primary"
              aria-label="Delete"
              className="mail_icon"
            >
              <img src={gray_smstracking} alt="gray_smstracking" />
            </IconButton> */}
          </Box>
          <Box className="user_list_main">
            {/* <p
              style={{
                color: "#AFAFAF",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textAlign: "center",
              }}
            >
              No Data Found
            </p> */}
            {/* <Box className="user_list">
              <Box className="user_info">
                <img src={singer2} alt="user" />
                <Box className="user_name">
                  <h6>Harris Abbasi</h6>
                  <p>harrisabbasi.88@gmail.com</p>
                </Box>
              </Box>
              <Box className="user_icon">
                <IconButton color="primary" aria-label="Delete">
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
            <Box className="user_list">
              <Box className="user_info">
                <img src={singer2} alt="user" />
                <Box className="user_name">
                  <h6>Harris Abbasi</h6>
                  <p>harrisabbasi.88@gmail.com</p>
                </Box>
              </Box>
              <Box className="user_icon">
                <IconButton color="primary" aria-label="Delete">
                  <AddIcon />
                </IconButton>
              </Box>
            </Box> */}
          </Box>
          {/* <Box className="sendinbox_main">
            <Button disabled>Send Inbox</Button>
          </Box> */}

          {/* ..........SHARE BEAT-INBOX LINK TO SOCIAL MEDIA............ */}
          {/* <SocialMain>
            <h3>Share With Your Friends!</h3>
            <Grid container spacing={2}>
              {SocialPlatforms.map((items) => (
                <Grid
                  item
                  lg={3}
                  xs={6}
                  md={6}
                  sm={6}
                  sx={{ textAlign: "center" }}
                >
                  <img src={items.icon} alt="social-icons" />
                  <h5>{items.title}</h5>
                </Grid>
              ))}
            </Grid>
          </SocialMain> */}
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(ShareInboxModel);
