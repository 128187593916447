import React, { useState } from "react";
import styles from "../../auth_pages.module.css";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { createSubscription } from "../../../../Graphql_services/PaymentsMutation";
import { useMutation } from "@apollo/client";
import { Notification } from "../../../NotifyMsg/Notification";
import CircularProgress from "@mui/material/CircularProgress";
import { verify_PromoCode } from "../../../../Graphql_services/PromoCodeMutation";
import { useNavigate } from "react-router-dom";

const Payment = ({ onBack, onNext, user, completeAccount }) => {
  console.log("🚀 ~ Payment ~ user:", user);

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [Subscription] = useMutation(createSubscription, {
    fetchPolicy: "no-cache",
    context: { clientName: "auth" },
    onCompleted(data) {
      if (data?.attachSubscriptionForUser?.success === true) {
        localStorage.setItem(
          "profilePic",
          data?.attachSubscriptionForUser?.user?.profilePic
        );
        localStorage.setItem(
          "userName",
          data?.attachSubscriptionForUser?.user?.userName
        );
        localStorage.setItem(
          "accountType",
          data?.attachSubscriptionForUser?.user?.roleId?.roleName
        );
        localStorage.setItem(
          "token",
          data?.attachSubscriptionForUser?.accessToken
        );
        localStorage.setItem(
          "userId",
          data?.attachSubscriptionForUser?.user?._id
        );
        localStorage.setItem(
          "opportunityToken",
          data?.attachSubscriptionForUser?.reactionAccessToken
        );
        document.cookie = `sessionId=${data?.attachSubscriptionForUser?.sessionId};path=/;domain=app.unitedmarket.com`;
        document.cookie = `reactionAccessToken=${data?.attachSubscriptionForUser?.reactionAccessToken};path=/;domain=app.unitedmarket.com`;
        window.location.href = "/home";
        // setUser(data?.attachSubscriptionForUser?.user);
        // setIsAuthenticated(true);
        // setProgressbarValue((prevActiveStep) => prevActiveStep + 1);
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if (data?.attachSubscriptionForUser?.success === false) {
        Notification("error", data?.attachSubscriptionForUser?.message);
      }
    },
    onError(error) {
      console.log("error.....", error);
      Notification("error", error);
    },
  });
  // const [verifyPromoCode] = useMutation(verify_PromoCode, {
  //   fetchPolicy: "no-cache",
  //   context: { clientName: "auth" },
  //   onCompleted(data) {
  //     console.log(data, "in verify promo");
  //     if (data?.checkPromocode?.success) {
  //       Notification("success", data?.checkPromocode?.message);
  //     }
  //     if (!data.checkPromocode.success) {
  //       Notification("error", data?.checkPromocode?.message);
  //     }
  //   },
  //   onError(error) {
  //     console.log("error.....", error);
  //     Notification("error", error);
  //   },
  // });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error) {
        console.log("[error]", error);
        Notification("error", error);
      } else {
        // Try to subscribe to the plan
        try {
          await Subscription({
            variables: {
              priceId: user.profile.planID,
              email: user.profile.emial || user.email,
              paymentMethodId: paymentMethod.id,
              // promoCode: user.profile.promoCode,
            },
          });
          // TODO: pass paymentMethod.id to your server and save it in your database
        } catch (subscriptionError) {
          console.error("Subscription Error:", subscriptionError);
          Notification("error", subscriptionError.message);
        }
      }
    } catch (createPaymentMethodError) {
      console.error("[error]", createPaymentMethodError);
      Notification("error", createPaymentMethodError.message);
    } finally {
      setLoading(false);
    }
  };

  // const verifyPromo = async () => {
  //   console.log("verifyPromo");
  //   await verifyPromoCode({
  //     variables: {
  //       promoCode: promo,
  //       email: localStorage.getItem("email"),
  //     },
  //   });
  // };

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
        >
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}>
              <p className={styles.auth_title}>Card Info</p>
              <p className={styles.auth_subtitle}>
                Your card will only be charged if you are accepted
              </p>
            </div>
            <div className={`${styles.input_list} ${styles.CardElementMain}`}>
              <form onSubmit={handleSubmit}>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#fff",
                        border: "1px solid #afafaf",
                        borderRadius: "4px",
                        padding: "8px",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                    },
                  }}
                />
              </form>
            </div>
          </div>

          <div className={styles.buttonControls}>
            <div
              onClick={(e) => {
                handleSubmit(e);
              }}
              className={styles.signin_box}
            >
              <p className={styles.connect_text}>
                {loading ? (
                  <CircularProgress size={25} sx={{ color: "white" }} />
                ) : (
                  "Continue"
                )}
              </p>
            </div>

            <p className={styles.previous_text} onClick={onBack}>
              Previous Step
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
