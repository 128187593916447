import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { profile_background } from "../../../assets/smartlink";

const MainProfileBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "300px",
  "& .header-main": {
    width: "100%",
    height: "180px",
    position: "relative",
    "& .header-img": {
      width: "100%",
      height: "180px",
      borderRadius: "5px",
      backgroundImage: `url("${profile_background}")`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      objectFit: "cover",
      "& .upload-btn": {
        width: "136px",
        height: "40px",
        flexShrink: 0,
        borderRadius: "5px",
        border: "1px solid #AFAFAF",
        color: "#AFAFAF",
        textTransform: "capitalize",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        opacity: 0,
        transition: "opacity 0.3s ease-in-out",
      },
      "&:hover .upload-btn": {
        opacity: 1,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "140px",
      },
    },
    "& .profile-img-main": {
      position: "absolute",
      left: "0px",
      bottom: "-70px",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      // border: "1px solid yellow",
      "& .profileInfo_main": {
        display: "flex",
        gap: "18px",
        // border: "1px solid red",
        alignItems: "center",
        marginLeft: "25px",
        "& .profile-pic": {
          width: "100px",
          height: "100px",
        },
        "& .profile-info": {
          marginTop: "20px",
          // border: "1px solid red",
          "& .profile-name": {
            color: "#FFFFFF",
            fontFamily: theme.typography.fontFamily,
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textTransform: "capitalize",
          },
          "& .profile-desc": {
            display: "flex",
            gap: "6px",
            alignItems: "center",
            img: {
              height: "24px",
              width: "24px",
            },
            "& p": {
              color: "#AFAFAF",
              fontFamily: theme.typography.fontFamily,
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              margin: "0",
            },
            [theme.breakpoints.down("sm")]: {
              img: {
                height: "14px",
                width: "14px",
              },
              p: {
                fontSize: "9px",
              },
            },
          },
          [theme.breakpoints.down("sm")]: {
            marginTop: "35px",
            "& .profile-name": {
              fontSize: "14px",
            },
          },
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "10px",
          gap: "12px",
        },
      },
      "& .profileInfo_syncBtn": {
        marginTop: "20px",
        "& .SyncBtn": {
          display: "flex",
          gap: "8px",
          alignItems: "center",
          borderRadius: "3px",
          background: "#2EBB55",
          color: "#FFFFFF",
          fontFamily: theme.typography.fontFamily,
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          textTransform: "capitalize",
          padding: "8px 7px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        bottom: "-65px",
        "& .profile-pic": {
          height: "80px !important",
          width: "80px !important",
        },
        "& .profileInfo_syncBtn": {
          width: "100%",
          "& .SyncBtn": {
            width: "100%",
          },
        },
      },
    },
  },
  "& .edit-box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "70px",
    left: "100px",
    width: "22px",
    height: "22px",
    flexShrink: 0,
    borderRadius: "100px",
    background: "#FFF",
    filter: "drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25))",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      top: "60px",
      left: "67px",
      width: "18px",
      height: "18px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    height: "270px",
  },
}));
const PersonalInfoMain = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "#0D120F",
  borderRadius: "5px",
  padding: "30px",
  marginBottom: "30px",
  "& .info-heading": {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize",
    margin: "0",
    marginBottom: "10px",
  },
  "& .form-main": {
    width: "70%",
    marginBottom: "15px",
    "& .field": {
      height: "48px",
    },
    "& .input-main": {
      display: "flex",
      gap: "50px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      "& .input-main": {
        flexDirection: "column",
        gap: "12px",
      },
    },
  },
  "& .form-main-last": {
    width: "33.3%",
    "& .field": {
      height: "48px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
const ButtonBox = styled(Box)(({ theme }) => ({
  textAlign: "right",
  "& .btn": {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    padding: "10.5px 38px",
    borderRadius: "5px",
    background: "#2EBB55",
    marginRight: "90px",
    textTransform: "capitalize",
    marginBottom: "20px",
    "&:hover": {
      background: "#2EBB55",
    },
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    "& .btn": {
      width: "100%",
    },
  },
}));

export { MainProfileBox, PersonalInfoMain, ButtonBox };
