import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import { SelectMain, MatrixBox, ButtonBox } from "./RightSelecterDropdownStyle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button } from "@mui/material";
import RightCheckBox from "./RightCheckBox";
import { useState } from "react";

const menuPaperStyle = {
  marginTop: "5px",
  marginLeft: "-80px",
  padding: "20px 10px 20px 30px",
  background: "#141515",
  borderRadius: "10px",
  width: "auto",
};

const RightSelecterDropdown = ({
  height = "auto",
  width = "auto",
  label = "",
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [arrowDirectionDown, setArrowDirectionDown] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setArrowDirectionDown(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setArrowDirectionDown(false);
  };

  const handleChange = (value) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelection = prevSelectedItems.includes(value)
        ? prevSelectedItems.filter((item) => item !== value)
        : [...prevSelectedItems, value];

      return updatedSelection;
    });
  };

  const handleApplyButtonClick = () => {
    handleClose();
  };

  return (
    <SelectMain height={height} width={width}>
      <FormControl fullWidth>
        <MatrixBox onClick={handleOpen}>
          {label}
          <ArrowForwardIosIcon
            style={{
              transform: arrowDirectionDown ? "rotate(90deg)" : "none",
              fontSize: "12px",
            }}
          />
        </MatrixBox>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: menuPaperStyle,
          }}
          classes={{ paper: "custom-menu-paper" }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <RightCheckBox
              title="Visits"
              value="Visits"
              onChange={() => handleChange("Visits")}
            />
            <RightCheckBox
              title="Click-throughs"
              value="Click-throughs"
              onChange={() => handleChange("Click-throughs")}
            />
            <RightCheckBox
              title="CTR"
              value="CTR"
              onChange={() => handleChange("CTR")}
            />
            <RightCheckBox
              title="Engagements"
              value="Engagements"
              onChange={() => handleChange("Engagements")}
            />
            <RightCheckBox
              title="Bounce Rate"
              value="Bounce Rate"
              onChange={() => handleChange("Bounce Rate")}
            />
            <RightCheckBox
              title="Item Sold"
              value="Item Sold"
              onChange={() => handleChange("Item Sold")}
            />
            <RightCheckBox
              title="Items Sold Per Click-throughs"
              value="Items Sold Per Click-throughs"
              onChange={() => handleChange("Items Sold Per Click-throughs")}
            />
          </Box>
          <ButtonBox>
            <Button
              variant="contained"
              className="btn"
              onClick={handleApplyButtonClick}
            >
              Apply
            </Button>
          </ButtonBox>
        </Menu>
      </FormControl>
    </SelectMain>
  );
};
export default RightSelecterDropdown;
