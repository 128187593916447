import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";

export const ReleaseBoxWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "5px",
  background: "#0D120F",
  padding: 40,
  display: "block",
  h5: {
    margin: 0,
    fontWeight: 400,
    fontSize: 24,
    color: "#E3E3E3",
    textTransform: "capitalize",
  },
  p: {
    margin: "12px 0",
    fontSize: 12,
    color: "#AFAFAF",
    span: {
      color: "#2EBB55",
    },
  },
}));
