import React from "react";
import { SideBarBox } from "./styles/SidebarStyle";
import { mainLogo } from "../../assets/smartlink";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const NavSidebar = ({ show, setShow }) => {
  const NavList = [
    {
      list: "Home",
      path: "/",
    },
    {
      list: "Features",
      path: "#",
    },
    {
      list: "Distribution",
      path: "/distribution",
    },
    {
      list: "Blog",
      path: "https://blog.unitedmarket.com/",
    },
    {
      list: "Pricing",
      path: "#",
    },
  ];
  return (
    <SideBarBox>
      <Box className="header">
        <img src={mainLogo} alt="Logo.svg" className="logoImg" />
        <CloseIcon
          onClick={() => setShow(false)}
          sx={{ color: "white", cursor: "pointer" }}
        />
      </Box>
      <Box className="menuList">
        <div>
          {NavList.map((x, i) => {
            return (
              <Link
                to={x.path}
                className="links"
                key={i}
                onClick={() => setShow(false)}
              >
                {x.list}
              </Link>
            );
          })}
        </div>
      </Box>
    </SideBarBox>
  );
};

export default NavSidebar;
