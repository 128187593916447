import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const InsightMain = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "20px",
  marginBottom: "40px",
  background: "#0D120F",
  "& .header_button button": {
    width: "100px !important",
  },
}));

export { InsightMain };
