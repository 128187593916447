import React from "react";
import { CollaborationsPopover } from "./CollaborationsStyle";
import { Box, Typography } from "@mui/material";

const Collaborations = ({
  list = [],
  tone = "",
  containsMasterSample,
  userName = "",
  publishingSplit = "",
}) => {
  return (
    <CollaborationsPopover>
      <Box className="collaborator">
        <Typography variant="h5">Collaborator (s)</Typography>

        {list && list?.length > 0 ? (
          list.map((val) => {
            return (
              <>
                <Typography variant="h6" key={val._id}>
                  {val?.name}: {val?.splitPrice ? val?.splitPrice + "%" : null}
                </Typography>
              </>
            );
          })
        ) : (
          // <Typography variant="h6">No Data Found</Typography>
          <Typography variant="h6">
            {userName.substr(-15) + ": " + publishingSplit + "%"}
          </Typography>
        )}
      </Box>
      <Box className="tone-box">
        <Typography variant="h5">Tone/Vibe:</Typography>
        <Typography variant="h6">{tone}</Typography>
      </Box>
      <Box className="master-box">
        <Typography variant="h5">Master Sample</Typography>
        <Typography variant="h6">
          {containsMasterSample === "Yes" ? "MASTER SAMPLE USED" : "No"}
        </Typography>
      </Box>
    </CollaborationsPopover>
  );
};

export default Collaborations;
