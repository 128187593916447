import React from 'react'
import BackIcon from "../../../../../assets/smartlink/icons/BackIcon";
import CloseIcon from "../../../../../assets/smartlink/icons/CloseIcon";
import { ModalHeaderWrapper } from './style';

const ModalHeader = ({ handleModalOpen }) => {
  return (
    <ModalHeaderWrapper>
      <div onClick={() => handleModalOpen(false)}><BackIcon /></div>
      <div onClick={() => handleModalOpen(false)}> <CloseIcon /></div>
    </ModalHeaderWrapper>
  )
}

export default ModalHeader