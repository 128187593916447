import { Button } from "@mui/material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
const ShareBtn = styled(Button)(({ theme }) => ({
  padding: "10.5px 30px !important",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px !important",
  fontFamily: theme.typography.fontFamily,
  fontSize: "18px !important",
  color: "#FFFFFF !important",
  background: "#2EBB55 !important",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  cursor: "pointer",
  textTransform: "capitalize",
  "&:hover": {
    background: "#2EBB55",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CardBtn = styled(Box)(({ theme }) => ({
  display: "flex",

  padding: "0.75rem 5.9375rem",

  justifyContent: "center",

  alignItems: "center",

  fontStyle: "normal",

  borderRadius: "0.3125rem",

  cursor: "pointer",

  fontFamily: theme.typography.fontFamily,

  fontSize: theme.typography.h5,

  background: theme.palette.secondary.main,

  marginTop: "20px",
}));

export { ShareBtn, CardBtn };
