import React, { memo, useRef, useState } from "react";
import { Avatar, Box, Button, Divider, Modal } from "@mui/material";
import { ModelContentBox } from "./CustomizationProfileStyle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "background.paper",
  pt: 2,
  px: 4,
  pb: 3,
  border: "none",
};

const colors = [
  {
    value: "#FECCD8",
  },
  {
    value: "#FEBCB7",
  },
  {
    value: "#C0F1CA",
  },
  {
    value: "#FFDDAE",
  },
  {
    value: "#C7ECFC",
  },
  {
    value: "#DDD4FB",
  },
  {
    value: "#FA7F9C",
  },
  {
    value: "#FD7A70",
  },
  {
    value: "#5DE97A",
  },
  {
    value: "#FDC270",
  },
  {
    value: "#59CAFB",
  },
  {
    value: "#9074EE",
  },
];

const ProfilePictureModel = ({ open, setOpen, handleSave, Data = {} }) => {
  const [activeColor, setActiveColor] = useState(null);
  const [image, setImage] = useState(Data?.profilePic || null);
  const fileInputRef = useRef(null);

  const handleColorClick = (color) => {
    setActiveColor(color);
    setImage(null);
  };

  const ProfilePictureClose = () => {
    setOpen(!open);
  };

  const handleImageUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      document.querySelector(
        ".profile-pic"
      ).style.backgroundImage = `url(${URL.createObjectURL(selectedFile)})`;
      setImage(URL.createObjectURL(selectedFile));

      // Upload image

      const profileData = new FormData();

      try {
        profileData.append("file", selectedFile);
        profileData.append("type", "Public");

        var config = {
          method: "post",
          url: `${process.env.REACT_APP_FILE_URL}/file/uploadprofilepicture`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: profileData,
        };
        const response = await axios(config);
        if (response.status === 200) {
          setImage(response?.data?.fileLocation);
        }
      } catch (error) {
        console.log(
          "🚀 ~ file: CreateBeatInboxPopup.js:65 ~ UploadImagehandler ~ error:",
          error
        );
        // Notification("error", error.message);
      } finally {
        // setIsLoadingProfile(false);
      }

      setActiveColor(null);
    }
  };
  const handleSaveClick = () => {
    handleSave({ image, activeColor });
    setOpen(false);
  };
  return (
    <div>
      <Modal
        open={open}
        // onClose={ProfilePictureClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          sx={{
            ...style,
            width: { xs: "100%", sm: "500px" },
            alignItems: "center",
            padding: "40px 60px 60px 60px",
            borderRadius: "10px",
            background: "#141515",
            border: "none",
          }}
        >
          <Box className="header-box">
            <ArrowBackIcon className="back-arrow" />
            <CloseIcon className="close-icon" onClick={ProfilePictureClose} />
          </Box>
          <Box className="profile-info-box">
            <Avatar
              src={image}
              className="profile-pic"
              style={{
                background: activeColor || "",
                cursor: "pointer",
                Repeat: "no-repeat",
              }}
              onClick={handleImageUpload}
            ></Avatar>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <h5 className="profile-name">
              {`${Data?.firstname + " " + Data?.lastname}`}
            </h5>
            {/* <Button
              variant="contained"
              className="upload-btn"
              onClick={handleImageUpload}
            >
              upload image
            </Button> */}
            <Box className="divider-box">
              <Divider
                sx={{ height: "1px", background: "#AFAFAF", width: "140px" }}
              />
              <span>or</span>
              <Divider
                sx={{ height: "1px", background: "#AFAFAF", width: "140px" }}
              />
            </Box>
          </Box>
          <Box className="color-main">
            <h4>Chose Background Color</h4>
            <Box className="color-box-main">
              {colors.map((items, index) => {
                return (
                  <span
                    key={index}
                    className={`box ${
                      activeColor === items.value ? "active" : ""
                    }`}
                    onClick={() => handleColorClick(items.value)}
                    style={{
                      background: items.value,
                      border:
                        activeColor === items.value
                          ? "2px solid transparent"
                          : "",
                      outline:
                        activeColor === items.value
                          ? `1px solid ${activeColor}`
                          : "",
                      outlineOffset: "3px",
                    }}
                  ></span>
                );
              })}
            </Box>
            <Box className="save-btn-box">
              <Button variant="contained" onClick={handleSaveClick}>
                Save
              </Button>
            </Box>
          </Box>
        </ModelContentBox>
      </Modal>
    </div>
  );
};
export default memo(ProfilePictureModel);
