import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import TemplatepageComponent from "../../components/MyContractsComponents/MyContracts/TemplatePageComponent";

const TemplatePage = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<TemplatepageComponent />}
        activeText="My Contracts"
      />
    </Grid>
  );
};

export default TemplatePage;
