import React from "react";
import { ResultCountMain } from "./styles";
import { Box } from "@mui/material";

const ResultCount = ({
  count = 0,
  title = "",
  ShowPriceAndnumber = false,
  price = "",
}) => {
  return (
    <>
      <ResultCountMain>
        <h6>
          {count} {title}
        </h6>
        {ShowPriceAndnumber && (
          <>
            <Box className="showPrice_number_main">
              <h6>{count}</h6>
              <h6>{price}</h6>
            </Box>
          </>
        )}
      </ResultCountMain>
    </>
  );
};

export default ResultCount;
