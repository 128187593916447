import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const CardMainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  height: "12rem",
  background: "#1B1C1B",
  flexShrink: 0,
  padding: "2rem",
  borderRadius: "0.3125rem",
  gap: "30px",
  "& h5": {
    color: theme.palette.secondary.dark,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5,
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  "& span": {
    color: theme.palette.secondary.light,
    fontFamily: theme.typography.fontFamily,
    fontSize: "3rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
}));

export { CardMainBox };
