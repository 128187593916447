import React, { useState } from "react";
import Navbar from "./Navbar";
import {
  HeroSection,
  LadingButton,
  LandingContainer,
} from "./styles/HeroStyle";
import { Box } from "@mui/material";
import { GlobeImg, Wire, ScreenImg, Companies } from "../../assets/smartlink";
import { Musician } from "./styles/MusicianStyle";
import Grid from "@mui/material/Grid";
import { Musician1, Musician2, Musician3 } from "../../assets/smartlink";
import Features from "./Features";
import Members from "./Members";
import { Seeonsection } from "./styles/SeeonStyle";
import TitleDetaisWidgets from "../Distribution/widgets/TitleDetaisWidgets";
import Footer from "../Footer/Footer";
import ScrollToTop from "./scrollTop";
import BannerSlider from "./BannerSlider";
import CosignSlider from "./CosignSlider";
import { OpportunitySectionMain } from "./styles/OpportunityStyle";
// import { CosignSectionMain } from "./styles/CosignsStyle";
import Opportunity from "./Opportunity";
import { FrequentlySectionMain } from "./styles/FrequentlyStyle";
import FrequentlySection from "./FrequentlySection";
import { CosignSectionMain } from "./styles/CosignsStyle";
import { useNavigate } from "react-router-dom";
import NavSidebar from "./NavSidebar";

let MusicNavbar = ["Artists", "Music Producers", "A&R"];

let MusicianArr = [
  {
    title: "Artists",
    img: Musician1,
    desp: "Spend more time creating new music and getting it heard from  fans worldwide. Create new hit songs with tailored beats and submit your music to sync opportunities from major brands. ",
  },
  {
    title: "Music Producers",
    img: Musician2,
    desp: "Access exclusive collaboration opportunities with artists and  producers worldwide. Send your beats for sync placement in TV shows, commercials, and more. Protect your work with WEB3 contracts",
  },
  {
    title: "A&R",
    img: Musician3,
    desp: "Let us help you focus exclusively on your music. We'll set you up with the producers  and beat makers of your dreams, quickly signing contracts so no detail gets skipped over - it's a streamlined process that takes one worry off your mind!",
  },
];

const Landingpage = () => {
  const navigate = useNavigate();

  const [selectedMusician, setSelectedMusician] = useState(0);

  const handleMusicianClick = (index) => {
    setSelectedMusician(index);
  };

  return (
    <LandingContainer>
      <Navbar />
      {/* <ScrollToTop /> */}

      {/* // Hero Section */}

      <HeroSection>
        <BannerSlider />
        <Box className="InnerSec">
          <Box className="HeroContent">
            <h1 className="HeroHead">
              Technology For The Next <br /> Generation Of Musicans
            </h1>
            <p className="HeroPara">
              We make it easy for you to focus on your music career and
              collaborations while we take care of <br /> the business side by
              protecting your compositions.
            </p>
            <LadingButton onClick={() => navigate("/signin")}>
              Get Started
            </LadingButton>
          </Box>
          <Box className="ScreenSec">
            <div className="ScreenMiain">
              <img
                style={{
                  width: "auto",
                  height: "auto",
                }}
                className="GlobeImg"
                src={GlobeImg}
                alt="GlobImg.svg"
              />
              <img
                style={{
                  // width: "auto",
                  width: "100%",
                  // height: "89%",
                  position: "relative",
                  zIndex: 10,
                }}
                src={ScreenImg}
                alt="ScreenImg.svg"
              />
              <img className="WireImg" src={Wire} alt="Wire.svg" />
              {/* <Box className="ScreenStand"></Box> */}
            </div>
          </Box>
        </Box>
      </HeroSection>

      {/* Mussician Section  */}

      <Musician>
        <Box className="MainMusician">
          <Grid container>
            <Grid item xs={12} md={6}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={MusicianArr[selectedMusician]?.img}
                alt="Musician1.svg"
              />
            </Grid>
            <Grid item xs={12} md={6} className="MainGrid2">
              <Box className="MusicContentBox">
                <h6 className="MusicHead">
                  <span>
                    Built For <br className="breakLine" /> You
                  </span>
                </h6>
                <ul className="MusicListBar">
                  {MusicNavbar.map((item, index) => (
                    <li
                      className="ListItem"
                      style={{
                        color: `${
                          index == selectedMusician ? "#FFF" : "#AFAFAF"
                        }`,
                      }}
                      key={index}
                      onClick={() => handleMusicianClick(index)}
                    >
                      {item}
                      <div
                        className={`${
                          index == selectedMusician ? "UnderLine" : ""
                        }`}
                      ></div>
                    </li>
                  ))}
                </ul>
                <p className="MusicContent">
                  {MusicianArr[selectedMusician]?.desp}
                </p>
                <LadingButton>Join Now</LadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Musician>

      {/* Feactures Section  */}
      <div
        style={{
          width: "100%",
          background: "#141515",
          padding: "50px 0 50px 0",
        }}
      >
        <Features />
      </div>

      {/* Cosigns Section  */}
      <CosignSectionMain>
        <TitleDetaisWidgets
          title="Cosigns"
          details="We make it easy for you to focus on your music career and collaborations while we take care of the business side by protecting your compositions."
        />
        <CosignSlider />
      </CosignSectionMain>

      {/* Members Section  */}
      <div
        style={{
          width: "100%",
          background: "#141515",
          padding: "50px 0 50px 0",
        }}
      >
        <Members />
      </div>

      {/* New Opportunities  Section  */}
      <OpportunitySectionMain>
        <TitleDetaisWidgets
          title="New Opportunities"
          details="We make it easy for you to focus on your music career and collaborations while we take care of the business side by protecting your compositions."
        />
        <Opportunity />
        <div style={{ marginTop: "60px" }}>
          <LadingButton>Learn More</LadingButton>
        </div>
      </OpportunitySectionMain>

      {/* SeeOn Section  */}
      <Seeonsection>
        <TitleDetaisWidgets title="As Seen On" />
        <Box className="MainBox">
          <img
            style={{ width: "80%", height: "auto" }}
            src={Companies}
            alt="Companies.svg"
          />
        </Box>
        <div style={{ marginTop: "64px", textAlign: "center" }}>
          <LadingButton>Join Now</LadingButton>
        </div>
      </Seeonsection>

      {/* Frequently Asked Questions Section  */}
      <FrequentlySectionMain>
        <TitleDetaisWidgets
          title="Frequently Asked Questions"
          details="United Market is the only music platform that lets you run your business on one platform, seamlessly across all digital channels."
        />
        <FrequentlySection />
      </FrequentlySectionMain>

      {/* Footer Section  */}

      <Footer />
    </LandingContainer>
  );
};

export default Landingpage;
