/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import { toast } from "react-toastify";

import Tooltip from "@material-ui/core/Tooltip";
import { get } from "lodash";
import styles from "./Opportunities.module.css";
import {
  formatCount,
  getInitialsFromName,
  remainngTimeCount,
} from "../../../../shared/funs";
import useAppState from "../../../../context/useAppState";
import SpotifyIcon from "../../../../assets/Icons/platform/Spotify.png";
import Waveform from "../../../../components-old/Submission/Waveform";
import moment from "moment";
import DeletePopup from "../../../Popups/DeletePopup";
import allImages from "../../../../assets";
import { updateOpportunityById } from "../../../../context/apis/admin/opportunity";

function OpportunityCard({
  opportunityData,
  onApprove,
  removeOpportunity,
  isAdmin,
  showApproveBtns = false,
  showExpCountDown = true,
}) {
  const navigate = useNavigate();
  const { deleteOpportunityById, toggleOpportunityFeaturedStatus } =
    useAppState("opportunity");

  const cover_image =
    opportunityData?.coverPhoto?.url || "/images/Image_not_available.png";
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState();

  const [isFeatured, setIsFeatured] = React.useState(opportunityData?.featured);

  function handleFeatureStatus() {
    setIsFeatured(!isFeatured);
    toggleOpportunityFeaturedStatus(opportunityData?._id);
  }

  React.useEffect(() => {
    if (opportunityData?.userId) {
      setUserInfo(opportunityData?.user);
    }
  }, [opportunityData]);

  const handleDeletePopup = () => {
    setDeletePopup(true);
  };

  const handleClosePopup = () => {
    setDeletePopup(false);
  };

  const cancelHandler = (isDelete, opportunityId) => {
    if (isDelete === "yes") {
      deleteHandler(opportunityId);
      handleClosePopup(false);
    } else {
      handleClosePopup();
    }
  };

  const deleteHandler = async () => {
    try {
      const response = await deleteOpportunityById(opportunityData?._id);
      toast.success("Opportunity deleted successfully.", { toastId: 1 });
      removeOpportunity(opportunityData?._id);
      console.info("response => ", response);
    } catch (err) {
      console.log("error on delete oportunity id..", err);
    }
  };

  const updateOpportunityStatusHandler = async (status) => {
    try {
      const response = await updateOpportunityById(opportunityData?._id, {
        status,
      });
      toast.success("Opportunity updated successfully.", { toastId: 1 });
      removeOpportunity(opportunityData?._id);
    } catch (err) {
      console.log("error on update opportunity id..", err);
    }
  };

  const linkHandler = (link) => {
    window.open(link, "_blank");
  };

  const remainingMinutes = Math.floor(
    moment
      .duration(moment(opportunityData.expireDate).diff(moment()))
      .asMinutes(),
  );

  return (
    <Box
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: "10px",
      }}
      px={{ xs: "15px" }}
      my={4}
    >
      <Grid
        container
        spacing={4}
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        {/* Hide this on mobile */}
        <Hidden xsDown>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            xl={3}
            style={{
              maxWidth: "300px",
              height: "300px",
              padding: "15px 0px 15px 15px",
            }}
          >
            <div
              style={{
                background: `url(${cover_image})center center / cover no-repeat`,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "7px",
                // backgroundPosition: 'center',
                // backgroundRepeat: 'no-repeat',
                // backgroundSize: 'cover',
                // width: "290px",
                // height: "290px",
              }}
            />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          xl={9}
          style={{
            minWidth: "calc(100% - 328px)",
            flexBasis: "80%",
          }}
        >
          <Box className={styles.card_content_wrapper}>
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 0 }}>
              <Grid container spacing={1}>
                <Hidden smUp>
                  <Grid item xs={2}>
                    <div
                      style={{
                        height: "100%",
                        minHeight: "3rem",
                        maxHeight: "3.7rem",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                    >
                      <img
                        src={cover_image}
                        alt="card"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 7,
                        }}
                      />
                    </div>
                  </Grid>
                </Hidden>
                <Grid item xs={6} sm={8} md={8} xl={9}>
                  <Hidden smUp>
                    <Tooltip
                      arrow
                      title={opportunityData?.title}
                      placement="top"
                    >
                      <Typography
                        className={styles.opportunity_title}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {opportunityData?.title}
                      </Typography>
                    </Tooltip>
                  </Hidden>
                  <Hidden xsDown>
                    {/* <Tooltip arrow title={capitalizeFirstLetter(opportunityData.title)} placement="top"> */}
                    <Typography className={styles.opportunity_title}>
                      {opportunityData?.title}
                    </Typography>
                    <Tooltip
                      placement="bottom-start"
                      title={opportunityData?.description}
                    >
                      <Typography
                        sx
                        className={`${styles.opportunity_description} ${styles.responsive_description}`}
                      >
                        {opportunityData?.description}
                      </Typography>
                    </Tooltip>
                  </Hidden>
                </Grid>
                <Hidden smUp>
                  <Grid item xs={4}>
                    <div className={styles.uploaded_by_wrapper}>
                      <Box className={styles.posted_by}>
                        <Box>
                          <Typography className={styles.opportunity_posted_by}>
                            Posted By
                          </Typography>
                          <Typography className={styles.opportunity_owner}>
                            {userInfo?.firstname || "demo"}
                          </Typography>
                        </Box>
                        <Avatar
                          src={userInfo?.profilePic}
                          className={styles.avatar}
                        />
                      </Box>
                    </div>
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid item xs={12} sm={4} md={4} xl={3}>
                    <div className={styles.uploaded_by_wrapper}>
                      {!isAdmin && (
                        <Box className={styles.posted_by}>
                          <Box>
                            <Typography
                              className={styles.opportunity_posted_by}
                            >
                              Posted By
                            </Typography>
                            <Typography className={styles.opportunity_owner}>
                              {userInfo?.userName || "User"}
                            </Typography>
                          </Box>
                          <Avatar
                            src={userInfo?.profilePic}
                            className={styles.avatar}
                          />
                        </Box>
                      )}
                      {isAdmin && (
                        <Typography className={styles.subCtn}>
                          {opportunityData.submissionCount} Submissions
                        </Typography>
                      )}
                      {isAdmin && (
                        <>
                          {isFeatured ? (
                            <div
                              className={styles.unfeature_button}
                              onClick={handleFeatureStatus}
                            >
                              Featured
                            </div>
                          ) : (
                            <div
                              className={styles.feature_button}
                              onClick={handleFeatureStatus}
                            >
                              Feature
                            </div>
                          )}
                        </>
                      )}
                      {/* <Typography className={styles.due_date_wrapper}>
                        Expiration Date: {moment(opportunityData.dueDate).format("MMM/DD/YY")}
                      </Typography> */}
                    </div>
                  </Grid>
                </Hidden>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    justifyItems={"flex-end"}
                    flexDirection={"row-reverse"}
                    alignItems={"flex-end"}
                  >
                    {/* <Typography style={{ color: "#afafaf" }}>
                      {opportunityData?.monthlyListerners && (
                        <>
                          Monthly Listeners:{" "}
                          <span style={{ color: "#fff" }}>
                            {parseInt(
                              opportunityData?.monthlyListerners
                            )?.toLocaleString()}
                          </span>
                        </>
                      )}
                    </Typography> */}

                    {/* <Box style={{ display: "flex", gap: 2 }}>
                        <IconButton
                          className={styles.post_image_wrapper}
                          style={{ alignSelf: "flex-end" }}
                        >
                          <img
                            src={clockGreenImg}
                            alt="danger"
                            height={18}
                            width={18}
                          />
                        </IconButton>
                        <Typography className={styles.due_date_wrapper}>
                          {moment(opportunityData?.dueDate).diff(
                            moment(),
                            "days"
                          ) >= 0
                            ? moment(opportunityData?.dueDate).diff(
                                moment(),
                                "days"
                              ) + " days remaining"
                            : "Expired"}
                        </Typography>
                      </Box> */}
                    {/* <IconWithText
                      iconSource={fireImg}
                      // handler={handleUpvoteCount}
                      loading={loading}
                      tooltipTitle="Upvotes"
                    /> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {get(opportunityData, "artist.name", "") && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      alt={opportunityData?.artist?.name}
                      style={{ height: 28, width: 28, fontSize: 12 }}
                    >
                      {getInitialsFromName(opportunityData?.artist?.name)}
                    </Avatar>
                    <Typography
                      style={{
                        color: "#2EBB55",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                      }}
                    >
                      {opportunityData?.artist?.name || "Guest"}
                    </Typography>
                  </Box>
                </Box>
              )}
              <span className={styles.opportunity_tags}>
                Genre: {opportunityData?.genre}
              </span>
              {opportunityData?.bpm && (
                <span className={styles.opportunity_tags}>
                  BPM: {opportunityData?.bpm}
                </span>
              )}

              {opportunityData?.monthlyListeners && (
                <Tooltip
                  placement="top-end"
                  title={opportunityData.monthlyListeners}
                >
                  <span
                    className={styles.opportunity_tags}
                    style={{ display: "inline-flex", cursor: "pointer" }}
                  >
                    Spotify Monthly Listeners:{" "}
                    {formatCount(opportunityData.monthlyListeners, true)}
                  </span>
                </Tooltip>
              )}
              {/* {opportunityData?.key && (
                <span className={styles.opportunity_tags}>
                  KEY: {opportunityData?.key}
                </span>
              )}
              {opportunityData?.scale && (
                <span className={styles.opportunity_tags}>
                  Scale: {opportunityData?.scale}
                </span>
              )} */}
              <span className={styles.opportunity_tags}>
                File Format: {opportunityData?.fileFormat}
              </span>
            </div>
            {opportunityData?.referenceTrack && (
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography style={{ color: "#afafaf", flexShrink: 0 }}>
                    Reference Track:
                  </Typography>
                </Grid>
                <Grid item>
                  {get(opportunityData, "referenceTrack.url") && (
                    <Waveform
                      key={opportunityData?.referenceTrack?.url}
                      url={opportunityData?.referenceTrack?.url}
                      file={opportunityData?.referenceTrack}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            <Box
              flexGrow={1}
              display={"flex"}
              style={{ paddingTop: 5, paddingBottom: 5 }}
              flexDirection={"column"}
            >
              {opportunityData?.terms === "true" ||
                (opportunityData?.terms === true && (
                  <Box
                    display={"flex"}
                    style={{ paddingTop: 5, paddingBottom: 6 }}
                  >
                    <Box display={"flex"} style={{ gap: "5px" }}>
                      <img
                        src="/images/info-circle.png"
                        alt=""
                        width={"15px"}
                        height={"15px"}
                      />
                      <Typography style={{ color: "#afafaf", fontSize: 12 }}>
                        Terms are pre-set for this opportunity
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
            <div className={styles.socialIconWrapper}>
              {opportunityData?.spotifyLink && (
                <Box>
                  <IconButton
                    style={{ padding: "5px 5px 10px" }}
                    onClick={() => linkHandler(opportunityData.spotifyLink)}
                  >
                    <img
                      src={SpotifyIcon}
                      alt="spotify link"
                      style={{ height: "25px", width: "25px" }}
                    />
                  </IconButton>
                </Box>
              )}
            </div>

            {showApproveBtns ? (
              <Fragment>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Grid>
                    <button
                      onClick={() =>
                        navigate(
                          `/opportunities/admin/edit/${opportunityData?._id}`,
                        )
                      }
                      className={styles.viewOppBtn}
                    >
                      Edit
                    </button>
                  </Grid>
                  <Grid>
                    <div className={styles.actionWrapper}>
                      <button
                        onClick={() =>
                          updateOpportunityStatusHandler("Rejected")
                        }
                        className={`${styles.actionBtn} ${styles.rejectBtn}`}
                      >
                        <img src={allImages.close} alt="" />
                      </button>
                      <button
                        onClick={() =>
                          updateOpportunityStatusHandler("Approved")
                        }
                        className={`${styles.actionBtn} ${styles.approveBtn}`}
                      >
                        <img src={allImages.tick} alt="" />
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </Fragment>
            ) : (
              <Box
                flexGrow={0}
                className={styles.card_footer}
                alignItems={"end"}
              >
                <DeletePopup
                  open={deletePopup}
                  title="Delete Opportunity"
                  content="Do you really want to remove this opportunity?"
                  label1="Delete"
                  label2="Cancel"
                  handler={cancelHandler}
                  id={opportunityData?.id}
                />
                <div
                  style={{
                    display: "flex",
                    gap: "18px",
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  {isAdmin && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "12px",
                      }}
                    >
                      <button
                        type="button"
                        className={styles.btnEdit}
                        onClick={() =>
                          navigate(
                            `/opportunities/admin/edit/${opportunityData?._id}`,
                          )
                        }
                      >
                        Edit
                      </button>
                      <button
                        className={styles.btnEdit}
                        onClick={(e) => handleDeletePopup()}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "48px",
                      backgroundColor: "#d9d9d90f",
                      borderRadius: "5px",
                      paddingLeft: "35px",
                      paddingRight: "21px",
                    }}
                  >
                    {showExpCountDown && (
                      <Fragment>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "4px 3px",
                          }}
                        >
                          <span
                            style={
                              remainingMinutes > 0
                                ? { color: "#7E7E7E", fontSize: "16px" }
                                : { color: "red" }
                            }
                          >
                            {remainngTimeCount(opportunityData.expireDate)
                              .days > 0
                              ? remainngTimeCount(opportunityData.expireDate)
                                  .days
                              : 0}
                          </span>
                          <span
                            style={
                              remainingMinutes > 0
                                ? { color: "#7E7E7E", fontSize: "16px" }
                                : { color: "red" }
                            }
                          >
                            {remainngTimeCount(opportunityData.expireDate)
                              .days <= 1
                              ? "day"
                              : "days"}
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "4px 3px",
                          }}
                        >
                          <span
                            style={
                              remainingMinutes > 0
                                ? { color: "#7E7E7E", fontSize: "16px" }
                                : { color: "red" }
                            }
                          >
                            {remainngTimeCount(opportunityData.expireDate)
                              .hours > 0
                              ? remainngTimeCount(opportunityData.expireDate)
                                  .hours
                              : 0}
                          </span>
                          <span
                            style={
                              remainingMinutes > 0
                                ? { color: "#7E7E7E", fontSize: "16px" }
                                : { color: "red" }
                            }
                          >
                            hours
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "4px 3px",
                          }}
                        >
                          <span
                            style={
                              remainingMinutes > 0
                                ? { color: "#7E7E7E", fontSize: "16px" }
                                : { color: "red" }
                            }
                          >
                            {remainngTimeCount(opportunityData.expireDate)
                              .minutes > 0
                              ? remainngTimeCount(opportunityData.expireDate)
                                  .minutes
                              : 0}
                          </span>
                          <span
                            style={
                              remainingMinutes > 0
                                ? { color: "#2EBB55", fontSize: "16px" }
                                : { color: "red" }
                            }
                          >
                            minutes
                          </span>
                        </div>
                      </Fragment>
                    )}
                  </Box>
                </div>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default OpportunityCard;
