import React, { useRef, useState, memo, useEffect } from "react";
import { Formik, Form } from "formik";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { ModelContentBox, Modelstyle } from "../styles/style";
import { close_icon } from "../../../../assets/smartlink";
// import DescriptionArea from "../../common/inputfield/TextAreaField";
import { Notification } from "../../../NotifyMsg/Notification";
import BPMSlider from "../../AdditionalComponents/utils/BPMSlider";
import ToggleExpand from "../ToggleExpand";
import OutlineButton from "../../AdditionalComponents/OutlineButton";
import universalStyles from "../../../UniversalComponents/universal.module.css";
import styles from "../Libraries.module.css";
import { SearchTagsHorizontal } from "../../AdditionalComponents/SearchTags";
import useLibraryStore from "../../../../store/library.js";
import { getTags } from "../../../../context/apis/audio-library.js";
import { Label } from "recharts";
import { set } from "lodash";

const TagsModal = ({
  open = false,
  setOpen = () => { },
  tags,
  onTagStatusChange
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTags, setFilteredTags] = useState(tags);
  const [tagOptions, setTagOptions] = useState([]);


  const CreateModelClose = (event) => {
    setOpen(!open);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.trim() === "") {
      setFilteredTags(tags);
    } else {
      const lowercasedValue = value.toLowerCase();
      const filtered = tags.filter((tag) =>
        tag.toLowerCase().includes(lowercasedValue),
      );
      setFilteredTags(filtered);
    }
  };

  const handleTagStatusChange = (tags) => {
    onTagStatusChange(tags);
  };

  useEffect(async () => {
    console.log("Confrim Tages in tagsmodal-- ",);

    const response = await getTags();
    const transformedTags = response?.data?.tagValues.map((tag) => ({
      value: tag,
      label: tag
    }));

    setTagOptions(transformedTags);
    console.log("Confrim Tages in tagsmodal-- ", transformedTags);
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="MainInboxCreate"
          sx={{
            ...Modelstyle,
            width: "600px",
            display: "flex",
            padding: "24px",
            maxHeight: "300px",
            overflowY: "auto",
            flexDirection: "column",
            borderRadius: "0.625rem",
            background: "#141515",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  fontSize: "24px",
                  color: "#AFAFAF",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Tags
              </div>
              <Box
                sx={{
                  height: "40px",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <IconButton
                  onClick={CreateModelClose}
                  aria-label="settings"
                  sx={{
                    color: "#fff",
                    //   color: theme.palette.primary.dark,
                    backgroundColor: "none",
                    fontSize: "2rem",
                    padding: "0 !important",
                    margin: "0 !important",
                    marginTop: "-7px !important",
                  }}
                >
                  <img src={close_icon} alt="cancel-icon" />
                </IconButton>
              </Box>
            </div>
            <SearchTagsHorizontal
              foundText={true}
              onTagStatusChange={onTagStatusChange}
              tags={tags}
              options={tagOptions}
            />
          </div>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(TagsModal);
