import React, { useState } from 'react';
import { Popover, IconButton, MenuItem, Stack, Grid, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import BookIcon from '@mui/icons-material/Book';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import styles from "./sync_submission_card.module.css";



const MENU_OPTIONS = [
  {
    label: "Sync",
    icon: "",
    linkTo: "/beat-inbox/my-submissions/sync",
  },
  {
    label: "Artist Collaborations",
    icon: "",
    linkTo: "/beat-inbox/my-submissions",
  },
];

const PopoverButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (link) => () => {
    setAnchorEl(null);
    navigate(link);
  };

  const openPopOver = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        variant="contained"
        onClick={handleClick}
        size="small"
        aria-controls={openPopOver ? 'menu-popover' : undefined}
        aria-haspopup="true"
      >
        <MenuOpenIcon className={styles.custom_icon}/>
      </IconButton >
      <Popover
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          '.MuiPaper-root':{
            background: "rgba(0, 0, 0, 0.87) !important"
          }
        }}
      >
        {/* <Grid container rowGap={1}>
          <Grid item xs={12}> */}
            <Stack sx={{ p: 0.5,background: "rgba(0, 0, 0, 0.87)" }}>
              {MENU_OPTIONS.map((option,index) => (
                <>
                 <MenuItem
                  key={option.label}
                  onClick={handleClose(option.linkTo)}
                  sx={{ color:"white !important"  }}
                >
                  {option.label}
                </MenuItem>
                {index < MENU_OPTIONS.length - 1 && (
                 <Divider sx={{ borderStyle: "dashed", background: "white !important", margin: 0}} />
                 )}
                </>
              ))}
            </Stack>
          {/* </Grid>
        </Grid> */}
      </Popover>

    </div>
  );
};

export default PopoverButton;
