import React from "react";

const DownloadSvg2 = ({}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_916_12048)">
        <path d="M10.0001 9.16669C10.2211 9.16669 10.4331 9.25448 10.5894 9.41076C10.7457 9.56705 10.8335 9.77901 10.8335 10V15.4867L11.911 14.41C12.0674 14.2538 12.2796 14.1661 12.5007 14.1662C12.7219 14.1664 12.9339 14.2544 13.0901 14.4109C13.2464 14.5673 13.3341 14.7795 13.3339 15.0006C13.3338 15.2217 13.2458 15.4338 13.0893 15.59L10.736 17.9392C10.5251 18.1492 10.3193 18.3334 10.0001 18.3334C9.72013 18.3334 9.52846 18.1925 9.34346 18.0167L6.91096 15.59C6.75449 15.4338 6.66649 15.2217 6.66633 15.0006C6.66618 14.7795 6.75387 14.5673 6.91013 14.4109C7.06639 14.2544 7.2784 14.1664 7.49954 14.1662C7.72068 14.1661 7.93282 14.2538 8.0893 14.41L9.1668 15.4867V10C9.1668 9.77901 9.25459 9.56705 9.41087 9.41076C9.56715 9.25448 9.77911 9.16669 10.0001 9.16669ZM9.58346 1.66669C11.9035 1.66669 13.8835 3.12502 14.6551 5.17669C15.6831 5.45933 16.5939 6.06301 17.2547 6.89968C17.9155 7.73635 18.2917 8.76225 18.3284 9.82776C18.3652 10.8933 18.0606 11.9426 17.459 12.8229C16.8574 13.7031 15.9904 14.3681 14.9843 14.7209C14.9221 14.1581 14.6699 13.6334 14.2693 13.2334C13.8434 12.8055 13.2766 12.547 12.6743 12.5059L12.5001 12.5V10C12.5008 9.34927 12.2476 8.72391 11.7945 8.25684C11.3414 7.78977 10.724 7.51778 10.0735 7.49869C9.42302 7.47959 8.79073 7.71488 8.31099 8.15457C7.83124 8.59426 7.54185 9.20368 7.5043 9.85335L7.50013 10V12.5C7.17144 12.4995 6.84591 12.5641 6.54228 12.6899C6.23864 12.8158 5.96291 13.0005 5.73096 13.2334C5.28268 13.6813 5.02193 14.2833 5.0018 14.9167C4.13423 14.7394 3.34567 14.2905 2.7504 13.6349C2.15512 12.9794 1.78408 12.1513 1.69104 11.2707C1.59801 10.3901 1.78782 9.50277 2.23296 8.7373C2.67809 7.97184 3.35543 7.36801 4.1668 7.01335C4.18521 5.58893 4.76401 4.2291 5.77784 3.22836C6.79167 2.22763 8.15892 1.66657 9.58346 1.66669Z" fill="#AFAFAF" />
      </g>
      <defs>
        <clipPath id="clip0_916_12048">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownloadSvg2;