import React from "react";

const FavIcon = ({color="#2EBB55"}) => {
  return (
    <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" height="24" 
    viewBox="0 0 24 24" 
    fill="none">
      <path d="M16.8203 1.91016H7.18031C6.15779 1.91411 5.17828 2.32205 4.45524 3.04509C3.73221 3.76812 3.32426 4.74764 3.32031 5.77016V19.8602C3.32031 21.6602 4.61031 22.4202 6.19031 21.5502L11.0703 18.8402C11.3612 18.697 11.6811 18.6226 12.0053 18.6226C12.3295 18.6226 12.6494 18.697 12.9403 18.8402L17.8203 21.5502C19.4003 22.4302 20.6903 21.6702 20.6903 19.8602V5.77016C20.684 4.74664 20.2739 3.76699 19.5492 3.04418C18.8245 2.32137 17.8438 1.91383 16.8203 1.91016V1.91016ZM15.6203 9.03016L11.6203 13.0302C11.5508 13.0999 11.4682 13.1553 11.3773 13.1931C11.2863 13.2309 11.1888 13.2503 11.0903 13.2503C10.9918 13.2503 10.8943 13.2309 10.8034 13.1931C10.7124 13.1553 10.6298 13.0999 10.5603 13.0302L9.06031 11.5302C8.92783 11.388 8.85571 11.1999 8.85914 11.0056C8.86257 10.8113 8.94128 10.6259 9.07869 10.4885C9.2161 10.3511 9.40149 10.2724 9.59579 10.269C9.79009 10.2656 9.97814 10.3377 10.1203 10.4702L11.0903 11.4402L14.5603 7.97016C14.7025 7.83768 14.8905 7.76555 15.0848 7.76898C15.2791 7.77241 15.4645 7.85112 15.6019 7.98853C15.7393 8.12595 15.8181 8.31133 15.8215 8.50563C15.8249 8.69993 15.7528 8.88798 15.6203 9.03016Z" fill={color} />
    </svg>
  );
};

export default FavIcon;
