import React from "react";
import {
  CoverImage,
  DetailsWrapper,
  ProfileHeader,
  ProfileLink,
  ReactIconBox,
  ReactionIcons,
} from "../style";
import ThreeDots from "../../../../../../../assets/smartlink/icons/ThreeDots";
import Circle from "../../../../../../../assets/smartlink/icons/Circle";
import ThumbIcon from "../../../../../../../assets/smartlink/icons/ThumbIcon";
import CommentIcon from "../../../../../../../assets/smartlink/icons/CommentIcon";
import ShareIcon from "../../../../../../../assets/smartlink/icons/ShareIcon";

const user = require("./../../../../../../../assets/Images/user.png");
const songCover = require("./../../../../../../../assets/Images/songCover.png");

const ProfileDetails = ({ CreatedLinkData }) => {
  let Data = CreatedLinkData;
  console.log(Data, "Recived From Copy");

  const { images, title } = CreatedLinkData;

  return (
    <DetailsWrapper>
      <ProfileHeader>
        <div className="user">
          <img src={user} />
          <div>
            <h6>Kibriya Zameer</h6>
            <p>
              Just Now . <Circle />
            </p>
          </div>
        </div>
        <ThreeDots />
      </ProfileHeader>

      <ProfileLink>
        <h5>{title}</h5>
        <p>united.market/secret645asdf</p>
      </ProfileLink>

      <CoverImage>
        {images && images.length > 0 && <img src={images[3]?.url || ""} />}{" "}
      </CoverImage>

      <ReactionIcons>
        <ReactIconBox>
          <ThumbIcon /> Like
        </ReactIconBox>

        <ReactIconBox>
          <CommentIcon /> Comment
        </ReactIconBox>

        <ReactIconBox>
          <ShareIcon /> Share
        </ReactIconBox>
      </ReactionIcons>
    </DetailsWrapper>
  );
};

export default ProfileDetails;
