import PackDetail from "../../components/LibrariesComponents/Libraries/PackDetail";
import axios from "../../config/axiosInterceptor";
import { HTTP_STATUS } from "../../shared/constants";


export const submitAudioFiles = async (payload) => {
  console.log("payload in submitAudioFiles: ", payload);
  const response = await axios.post(
    `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/submitaudiofiles`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response;
};

export const updateAudioFile = async (imagefile, details, userId, docId) => {

  const formData = new FormData();
  formData.append("data", JSON.stringify(details));
  formData.append("imageFile", imagefile);
  const response = await axios.post(
    `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/update?userId=${userId}&docId=${docId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response.data; // Return only the data part of the response
};


export const updateAudioFileMore = async (payload) => {
  console.log("payload in updateAudioFileMore: ", payload);
  const response = await axios.post(
    `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/updatemore`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response;
};


export const feedAudioFilesByQuery = async (queryString) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/feed?${queryString}`,
    );
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the audio files:", error);
    throw error;
  }
};

export const deleteAudioById = async (docId) => {
  try {
    if (!docId) {
      throw new Error("DocId are required.");
    }
    const queryParams = new URLSearchParams({ docId });
    const response = await axios.delete(`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/delete?${queryParams.toString()}`);
    const data = response.data;
    if (!response.status === 200) {
      throw new Error(
        data.message || "An error occurred while deleting the audio file.",
      );
    }
    return data;
  } catch (error) {
    console.error("Error deleting audio file:", error);
    throw error;
  }
};


export const trashAudioFile = async (userId, docId) => {
  try {
    if (!userId || !docId) {
      throw new Error("Both userId and docId are required.");
    }
    const queryParams = new URLSearchParams({ userId, docId });

    const response = await fetch(
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/trash?${queryParams.toString()}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(
        data.message || "An error occurred while trashing the audio file.",
      );
    }
    return data;
  } catch (error) {
    console.error("Error trashing audio file:", error);
    throw error;
  }
};

export const starAudioFile = async (userId, docId, isStared) => {
  try {
    if (!userId || !docId) {
      throw new Error("Both userId and docId are required.");
    }
    const queryParams = new URLSearchParams({ userId, docId, isStared });

    const response = await fetch(
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/star?${queryParams}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(
        data.message || "An error occurred while staring the audio file.",
      );
    }
    return data;
  } catch (error) {
    console.error("Error staring audio file:", error);
    throw error;
  }
};

export const getAudioLibraryById = async (docID) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/find?docId=${docID}`);
    return response.data; // The document data is assumed to be here
  } catch (error) {
    if (error.response) {
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Error request:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
    // Handle the error appropriately in your UI
    throw error;
  }
};

export const addDownloaderToFile = async (userId, docId, name, personId, downloadTime, isFavorited) => {
  try {
    if (!userId || !docId || !name || !personId || !downloadTime) {
      throw new Error('All parameters are required');
    }

    console.log('Adding downloader to file:', userId, docId, name, personId, downloadTime, isFavorited);
    const response = await axios.post(`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/adddownloader`,
      { downloadTime }, // This is the body of the POST request
      {
        params: {
          userId,
          docId,
          name,
          personId,
          isFavorited
        }
      }
    );
    return response;
  } catch (error) {
    console.error('Error adding downloader to audio file:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export const addViewerToFile = async (userId, docId, name, personId, viewTime, isFavorited) => {
  try {
    if (!userId || !docId || !name || !personId || !viewTime) {
      throw new Error('All parameters are required');
    }

    const response = await axios.post(`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/addviewer`,
      { viewTime }, // This is the body of the POST request
      {
        params: {
          userId,
          docId,
          name,
          personId,
          isFavorited
        }
      }
    );
    return response;
  } catch (error) {
    console.error('Error adding viewer to audio file:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const downloadResource = async (linkToDownload) => {
  try {
    console.log("❤")
    const response = await axios.get(`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/download`, {
      params: { linkToDownload },
    });

    return response;
  } catch (error) {
    console.error('Error downloading the resource:', error);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const fetchFileList = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/sharedlist`,
    );
    console.log("response of fetchFileList---------------", response);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the audio files:", error);
    throw error;
  }
};

export const fetchFileBlob = async (fileName) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/storage/dropbox/${fileName}`, {
      responseType: 'blob',
    });
    return new File([response.data], fileName, {
      type: 'audio/mpeg', // adjust the MIME type if necessary
    });
  } catch (error) {
    console.error(`Error fetching file: ${fileName}`, error);
    throw error; // Re-throw the error to be handled by the caller
  }
};


export const getTotalFileSize = async (userId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/total-file-size`, {
      params: {
        userId: userId
      }
    });
    console.log("response of getTotalFileSize---------------", response);
    return response;
  } catch (error) {
    console.error("There was an error getting total size", error);
    throw error;
  }
};

export const getUserInfo = async (userId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/get-user-info`, {
      params: {
        userId: userId
      }
    });
    console.log("response of getUserInfo---------------", response);
    return response;
  } catch (error) {
    console.error("There was an error getting user Info", error);
    throw error;
  }
};

export const getTags = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/get-tags`);
    console.log("response of getUserInfo---------------", response);
    return response;
  } catch (error) {
    console.error("There was an error getting tag Info", error);
    throw error;
  }
};






export const deleteDownloadedFiles = async () => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/file/deletedownloaded`, {
    });
    console.log('Success:', response.data);
  } catch (error) {
    console.error('Error:', error);
  }
};



export const submitBeatPack = async (payload) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/pack/submitbeatpack`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response;
};

export const feedBeatPacksByQuery = async (queryString) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/pack/feed?${queryString}`,
    );
    console.log("response---------------", response);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the audio files:", error);
    throw error;
  }
};

export const getBeatPackById = async (docID) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/pack/find?docId=${docID}`);
    return response.data; // The document data is assumed to be here
  } catch (error) {
    if (error.response) {
    } else if (error.request) {
    } else {
    }
    // Handle the error appropriately in your UI
    throw error;
  }
};

export const deleteBeatPackbydocId = async (docId) => {
  try {
    if (!docId) {
      throw new Error("DocId are required.");
    }
    const queryParams = new URLSearchParams({ docId });
    const response = await axios.delete(`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/pack/delete?${queryParams.toString()}`);
    const data = response.data;
    if (!response.status === 200) {
      throw new Error(
        data.message || "An error occurred while trashing the audio file.",
      );
    }
    return data;
  } catch (error) {
    console.error("Error trashing audio file:", error);
    throw error;
  }
};

export const sendFileByShareLink = async (payload) => {
  try {

    console.log(" -- Before calling backend API, confrimed Payload in audio-library.js", payload);
    const { data, status } = await axios.post(
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/sharefile`,
      payload,
    );

    if (status === 200 || status === 201) {
      return data;
    } else {
      throw new Error("Error while saving submission");
    }
  } catch (error) {
    return error;
  }
};


export const generateToken = async (payload) => {
  try {
    const { data, status } = await axios.post(
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/generatetoken`,
      payload,
    );

    if (status === 200 || status === 201) {
      return data;
    } else {
      throw new Error("Error while saving submission");
    }
  } catch (error) {
    return error;
  }
};


export const decodeToken = async (token) => {
  try {
    // Check if token is provided
    if (!token) {
      throw new Error("Token is required for decoding");
    }

    const { data, status } = await axios.get(
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/decodetoken`,
      { params: { token } }
    );

    if (status === 200) {
      return data.decoded; // Assuming the backend returns the decoded object under a 'decoded' key
    } else {
      throw new Error("Error while decoding token");
    }
  } catch (error) {
    // Handle errors appropriately in your application context
    console.error(error.message || "An unknown error occurred");
    throw error;
  }
};


export const addDownloaderToPack = async (userId, docId, name, personId, downloadTime) => {
  console.log('Adding downloader to file:', userId, docId, name, personId, downloadTime);
  try {
    if (!userId || !docId || !name || !personId || !downloadTime) {
      throw new Error('All parameters are required');
    }
    const response = await axios.post(`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/pack/adddownloader`,
      { downloadTime }, // This is the body of the POST request
      {
        params: {
          userId,
          docId,
          name,
          personId
        }
      }
    );
    return response;
  } catch (error) {
    console.error('Error adding downloader to audio file:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const addViewerToPack = async (userId, docId, name, personId, viewTime) => {
  try {
    if (!userId || !docId || !name || !personId || !viewTime) {
      throw new Error('All parameters are required');
    }

    console.log('Adding viewer to file:', userId, docId, name, personId, viewTime);
    const response = await axios.post(`${process.env.REACT_APP_AUDIO_LIBRARY_URL}/audiolibrary/pack/addviewer`,
      { viewTime }, // This is the body of the POST request
      {
        params: {
          userId,
          docId,
          name,
          personId
        }
      }
    );
    console.log('Downloader added successfully:', response.data);
    return response;
  } catch (error) {
    console.error('Error adding downloader to audio file:', error.response ? error.response.data : error.message);
    throw error;
  }
};





