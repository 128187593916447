import React from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  MainTitle,
  NotificationsMain,
  PageTitle,
} from "../../../components/smartlink";

const NotificationPage = () => {
  return (
    <>
      <SmartLinkLayout title="Settings">
        <MainTitle title="Notifications" hasbutton={false} />
        {/* <HorizontalMenu /> */}
        {/* <PageTitle title="Notifications" hasNavigate={false} /> */}
        <NotificationsMain />
      </SmartLinkLayout>
      ;
    </>
  );
};

export default NotificationPage;
