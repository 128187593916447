import React from 'react'
import "./style.css"
import { LoadingContentWrapper, LoadingDetailsWrapper } from './style'
import ProgressLoader from '../../CircularProgressWithLabel.js'
import CircularWithValueLabel from '../../CircularProgressWithLabel.js'

const LoadingContent = () => {
    return (
        // <LoadingContentWrapper>
        // <LoadingDetailsWrapper>
        <>
            <div className='loading-content-wrapper'>
                <div className='loading-details'>
                    <div className='loading'>
                        <CircularWithValueLabel />
                    </div>
                    <div className='cover-container'>
                        <img src={require("../../../../../../assets/Images/cover.png")} />
                    </div>
                </div>
            </div>
                <div style={{color :'#AFAFAF'}}>Creating Link...</div>
        </>
        // </LoadingDetailsWrapper>
        // </LoadingContentWrapper>
    )
}

export default LoadingContent