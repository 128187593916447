import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { FailedPaymentIcon } from "../../assets/smartlink";

export function BeatsCheckoutFailedModal({
  openModal,
  closeModal,
  handleTryAgain,
}) {
  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "#141515",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
        }}
      >
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            top: 1,
            right: 40,
            color: "white",
            marginTop: "25px",
          }}
          onClick={closeModal}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <img
            src={FailedPaymentIcon}
            alt="failed_payment_icon"
            style={{ width: "60px", height: "60px" }}
          />
        </div>
        <Typography
          id="modal-title"
          variant="h5"
          component="p"
          sx={{
            color: "white",
            textAlign: "center",
          }}
          style={{ marginTop: "10px" }}
        >
          Payment Failed
        </Typography>
        <Typography
          id="modal-description"
          variant="h8"
          component="p"
          sx={{
            color: "#AFAFAF",
            textAlign: "center",
          }}
          style={{ marginTop: "10px" }}
        >
          Something went wrong we couldn’t process your payment. Contact us if
          you lost your money.{" "}
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",

            marginTop: "12px",
          }}
        >
          <Button
            onClick={closeModal}
            sx={{
              flex: 1,
              backgroundColor: "transparent ",
              border: "1px solid #2EBB55 !important",
              color: "white !important",
              borderRadius: "5px !important",
              height: { lg: "45px", md: "35px", base: "48px" },
              marginRight: "10px !important",
              fontSize: "16px !important",
            }}
          >
            Cancel
          </Button>

          <Button
            variant=""
            onClick={handleTryAgain}
            sx={{
              flex: 1,
              backgroundColor: "#2EBB55 !important",

              color: "white !important",
              borderRadius: "5px !important",
              height: { lg: "45px", md: "35px", base: "48px" },
              marginRight: "10px !important",
              fontSize: "16px !important",
            }}
          >
            Try Again
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
