import React from "react";
import { SmartLinkBoxType } from "../";
import { prerelease, Release, bio, Content } from "../../../assets/smartlink";
import { Grid } from "@mui/material";

const CreateLink = () => {
  return (
    <>
      {/* <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={12}>
          <SmartLinkBoxType
            title="Pre- Release"
            icon={prerelease}
            navigate="/smart-link/pre-release-create"
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12}>
          <SmartLinkBoxType title="Release" icon={Release} />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12}>
          <SmartLinkBoxType title="Bio" icon={bio} />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12}>
          <SmartLinkBoxType title="Content" icon={Content} />
        </Grid>
      </Grid> */}

      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={12}>
          <SmartLinkBoxType
            title="Pre-Release"
            icon={prerelease}
            navigate="/smart-link/pre-release-create"
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12}>
          <SmartLinkBoxType
            title="Release"
            icon={Release}
            navigate="/smart-link/release"
          />
        </Grid>
        {/* <Grid item xl={4} lg={4} md={4} sm={12}>
          <SmartLinkBoxType
            title="Bio"
            icon={bio}
            navigate="/smart-link/bio-create"
          />
        </Grid> */}
        <Grid item xl={4} lg={4} md={4} sm={12}>
          <SmartLinkBoxType
            title="Content"
            icon={Content}
            navigate="/smart-link/release"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CreateLink;
