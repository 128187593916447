import React from 'react';

import { Box, Button } from 'native-base';
import { useNavigate } from 'react-router';
import styles from '../../auth_pages.module.css';
import { useMutation } from '@apollo/client';
import { SignUpMutation } from '../../../../Graphql_services/SignUpMutation';
import { Notification } from '../../../NotifyMsg/Notification';

function Finished({ onBack, user, onNext, completeAccount }) {
  // console.log("🚀 ~ Finished ~ user:", user);
  const navigate = useNavigate();
  const [signUpMutation, { loading, error, data }] =
    useMutation(SignUpMutation);

  const handleSignUp = async () => {
    const { profile = {} } = user;

    const Variables = {
      firstname: profile.name.firstName,
      lastname: profile.name.firstName,
      email: profile.emial,
      phoneno: parseFloat(profile.phoneNo),
      password: profile.password,
      roleId: profile.roleID,
      profilePic: profile.profilePic,
      username: profile.userName,
      isFreemium: true,
      isVerified: true,
      isAdminReviewed: true,
    };

    if (profile.label) {
      Variables.labelName = profile.label;
    }
    if (profile.spotifyId) {
      Variables.spotifyUrl = profile.spotifyId;
    }
    if (profile.monthlyListeners) {
      Variables.noOfMonthlyListeners = Number(profile.monthlyListeners);
    }
    if (profile.genre) {
      Variables.genre = profile.genre;
    }

    try {
      const result = await signUpMutation({
        variables: Variables,
      });
      if (result?.data?.signUp?.success) {
        const UserResponseData = result?.data?.signUp;

        localStorage.setItem('profilePic', UserResponseData?.user?.profilePic);
        localStorage.setItem('userName', UserResponseData?.user?.userName);
        localStorage.setItem(
          'accountType',
          UserResponseData?.user?.roleId?.roleName
        );
        localStorage.setItem('token', UserResponseData?.accessToken);
        localStorage.setItem('userId', UserResponseData?.user?._id);
        localStorage.setItem(
          'opportunityToken',
          UserResponseData?.reactionAccessToken
        );
        document.cookie = `sessionId=${data?.UserResponseData?.sessionId};path=/;domain=unitedmarket.com`;
        document.cookie = `reactionAccessToken=${data?.UserResponseData?.reactionAccessToken};path=/;domain=unitedmarket.com`;
        window.location.href = '/home';
        Notification('success', result?.data?.signUp?.message);
      } else {
        Notification('error', result?.data?.signUp?.message);
      }
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const errorMessage = error.graphQLErrors[0]?.message;
        Notification('error', `SignUp Error: ${errorMessage}`);
      } else {
        Notification(
          'error',
          `An unexpected error occurred. Please try again.`
        );
      }
    }
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
          style={{ justifyContent: 'end' }}
        >
          <Box
            w={{ lg: '90%', md: '90%', sm: '85%', base: '94%' }}
            fontFamily='poppins'
            mx={'auto'}
            mb={10}
          >
            <Button
              _text={{
                color: '#E3E3E3',
                fontWeight: 'normal',
                fontSize: { xl: '1.2rem', md: '1rem', base: '1rem' },
                fontFamily: 'poppins',
                textTransform: 'capitalize',
              }}
              height={{ lg: '45px', sm: '35px', base: '48px' }}
              backgroundColor={'#2EBB55'}
              _hover={{
                backgroundColor: '#000',
                borderColor: '#2EBB55',
                borderWidth: 1,
                color: '#2EBB55',
                _text: { color: '#2EBB55' },
              }}
              isDisabled={loading}
              onPress={handleSignUp}
            >
              {loading ? 'Please wait' : 'finish'}
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Finished;
