import { useReducer } from "react";
import {
  addOpportunity,
  getFeedOpportunityApi,
  getMyOpportunityApi,
  getMyOpportunityByQuery,
  getOpportunity,
  getSelectedBeatsByQuery,
  pauseOpportunityApi,
  removeOpportunity,
  updateOpportunityById,
  toggleFeaturedStatus,
  addCollaboratorApi,
  addSubmission,
  getMySubmissions,
  extendPitchingPeriod,
  getSubmission,
  getSubmissionDetailsbyId,
  updateSubmissionById,
  updateCollaboratorApi,
  getSubmissionDownloadFileList,
  getCheckoutSubmissionCard,
} from "../apis/sync";
import { toast } from "react-toastify";

const initialArgs = {
  data: {
    title: "",
    description: "",
    lookingFor: "",
    artist: {
      name: "",
      status: "",
    },
    monthlyListeners: "",
    expireDate: "",
    genre: "",
    bpm: "",
    key: "",
    scale: "",
    fileFormat: "",
    hasTerms: false,
    terms: {
      advance: "",
      publishing: "",
      points: "",
    },
    hasSpotifyLink: false,
    spotifyLink: null,
    coverPhoto: null,
    hasReferenceTrack: false,
    referenceTrack: null,
    userId: "",
  },
};

function reducer(state, action) {
  return { ...state, ...action };
}

const useSync = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const resetOpportunity = async () => {
    setState({
      ...state,
      data: initialArgs.data,
    });
  };

  const saveOpportunity = async (payload) => {
    try {
      const { data } = await addOpportunity(payload);
      resetOpportunity();
      toast.success("Success");
    } catch (err) {
      toast.error("Error");
    }
  };

  const updateOpportunity = async (id, payload) => {
    try {
      const data = await updateOpportunityById(id, payload);
      resetOpportunity();
      return data.msg;
    } catch (err) {
      toast.error("Error");
    }
  };

  const fetchOpportunityById = async (id) => {
    try {
      const { data } = await getOpportunity(id);
      console.info("fetchOpportunityById data => ", data);

      setState({ ...state, data });
      return data;
    } catch (err) {
      toast.error("error while fetching opportunity");
      return err;
    }
  };

  const deleteOpportunityById = async (id) => {
    try {
      const { data } = await removeOpportunity(id);
      console.info("deleteOpportunityById data => ", data);

      setState({ ...state, data });
      return data;
    } catch (err) {
      toast.error("error while deleting opportunity");
      return err;
    }
  };

  const updateOpportunityState = async (key, value) => {
    try {
      console.info("key, value => ", key, value);

      const _state = { ...state };
      _state.data[key] = value;
      setState(_state);
    } catch (error) {
      console.info(
        "@updateOpportunityState:  Error while updating state => ",
        error
      );
    }
  };

  const pauseOpportunity = async (id) => {
    try {
      const data = await pauseOpportunityApi(id);
      if (data) {
        toast.success("Opportunity pause successfully.");
      }
    } catch (error) {
      console.log("error while pause the opportunity = ", error);
    }
  };

  const fetchFeedOpportunity = async (queryString) => {
    try {
      const data = await getFeedOpportunityApi(queryString);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while pause the opportunity = ", error);
    }
  };

  const fetchMyOpportunity = async (id) => {
    try {
      const data = await getMyOpportunityApi(id);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while pause the opportunity = ", error);
    }
  };

  const fetchMyOpportunityByQuery = async (queryString) => {
    try {
      const data = await getMyOpportunityByQuery(queryString);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while fetching my opportunity = ", error);
    }
  };

  const fetchSelectedBeatByQuery = async (queryString) => {
    try {
      const data = await getSelectedBeatsByQuery(queryString);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while fetching selected beats = ", error);
    }
  };

  const toggleOpportunityFeaturedStatus = async (id) => {
    try {
      const data = await toggleFeaturedStatus(id);
      if (data) {
        return data;
      }
    } catch (error) {
      console.log("error while toggling featured status = ", error);
    }
  };

  const saveSubmission = async (payload) => {
    try {
      setState({ ...state, isLoading: true });
      const res = await addSubmission(payload);
      return res;
    } catch (err) {
      toast.error("Error Submitting Sync");
    } finally {
      setState({ ...state, isLoading: false });
    }
  };
  const updateSubmission = async (id, payload) => {
    try {
      await updateSubmissionById(id, payload);
    } catch (err) {
      console.info("updateSubmission err => ", err);
    }
  };

  const fetchSubmissionById = async (id, queryString) => {
    try {
      const { data } = await getSubmission(id, queryString);

      setState({ ...state, data });
      return data;
    } catch (err) {
      toast.error("error while fetching submission");
      return err;
    }
  };

  const fetchMySubmission = async (queryString) => {
    try {
      const data = await getMySubmissions(queryString);

      setState({ ...state, data });
      return data;
    } catch (err) {
      toast.error("error while fetching submission");
      return err;
    }
  };

  const fetchSubmissionDownloadFileList = async (id) => {
    try {
      const { data } = await getSubmissionDownloadFileList(id);

      setState({ ...state, data });
      return data;
    } catch (err) {
      toast.error("error while fetching submission");
      return err;
    }
  };

  const fetchSubmissionDetailsById = async (id) => {
    try {
      const { data, opportunity } = await getSubmissionDetailsbyId(id);
      Object.assign(data, {
        opportunity: opportunity,
      });

      console.info("fetchSubmissionDetailsById data => ", data);

      setState({ ...state, data });
      return data;
    } catch (err) {
      toast.error("error while fetching Submission Details");
      return err;
    }
  };
  const updateSubmissionState = async (key, value) => {
    try {
      console.info("key, value => ", key, value);

      const _state = { ...state };
      _state.data[key] = value;
      setState(_state);
    } catch (error) {
      console.info(
        "@updateSubmissionState:  Error while updating state => ",
        error
      );
    }
  };

  const updateFavorite = async (id, payload) => {
    try {
      const updateFavorite = await updateSubmissionById(id, payload);
      if (updateFavorite.message === "Data updated") {
        toast.success(
          `Marked as ${
            payload?.isFavorite ? "favorite" : "unfavorite"
          } successfully.`
          // { toastId: payload?.isFavorite ? 1 : 2 }
        );
      }
      return updateFavorite.message;
    } catch (error) {
      console.info(
        "@UpdateFavorite status: Error while updating the favorite status =>",
        error
      );
    }
  };

  const updateFlag = async (id, payload) => {
    try {
      const updateFlag = await updateSubmissionById(id, payload);
      if (updateFlag.message === "Data updated") {
        toast.success(
          `Beat ${payload?.isFlag ? "reported" : "unreported"} successfully.`
          // { toastId: 1 }
        );
      }
      return updateFlag.message;
    } catch (error) {
      console.info(
        "@UpdateFlag status: Error while updating the flag status =>",
        error
      );
    }
  };

  const updateBeatPrice = async (id, payload) => {
    try {
      const response = await updateSubmissionById(id, payload);
      console.log("response =", response);
      if (response.status === 1) {
        toast.success("Beat price updated successfully!");
      }
      return response.data;
    } catch (error) {
      console.info("Error while updating pitching period.", error);
    }
  };

  const extendPitching = async (id, payload) => {
    try {
      const data = await updateSubmissionById(id, payload);
      if (data.message === "Data updated") {
        toast.success("Pitching Period Extended!");
      }
      return data.message;
    } catch (error) {
      console.log("error while extend the pitching period.");
    }
  };

  const addCollaboratorData = async (id, payload) => {
    try {
      const data = await addCollaboratorApi(id, payload);
      if (data.message === "Data updated") {
        toast.success("Collaborator Added successfully!");
      }
      return { data, message: data.message };
    } catch (error) {
      console.log("Error while adding collaborator =", error);
    }
  };

  const updateCollaborator = async (id, payload) => {
    try {
      const response = await updateCollaboratorApi(id, payload);
      console.info("response =", response);
      if (response) {
        toast.success("Collaborator Updated successfully!");
      }
      return response;
    } catch (error) {
      console.log("Error while updating the collaborator =", error);
    }
  };

  const updateBeatStatus = async (id, payload) => {
    try {
      const data = await updateSubmissionById(id, payload);
      if (data.message === "Data updated") {
        toast.success("Beat selected successfully");
      }
      return data.message;
    } catch (error) {
      console.log("error while selecting beat.");
    }
  };

  const fetchCheckoutSubmissionCardDetails = async (id) => {
    try {
      const data = await getCheckoutSubmissionCard(id);
      return data;
    } catch (error) {
      console.log("error while selecting beat.");
    }
  };

  return {
    ...state,
    setState,
    saveOpportunity,
    updateOpportunity,
    fetchOpportunityById,
    deleteOpportunityById,
    updateOpportunityState,
    resetOpportunity,
    pauseOpportunity,
    fetchFeedOpportunity,
    fetchMyOpportunity,
    fetchMyOpportunityByQuery,
    fetchSelectedBeatByQuery,
    toggleOpportunityFeaturedStatus,
    saveSubmission,
    updateSubmission,
    fetchSubmissionById,
    fetchSubmissionDetailsById,
    updateSubmissionState,
    updateFavorite,
    updateFlag,
    fetchMySubmission,
    updateBeatPrice,
    extendPitching,
    addCollaboratorData,
    updateCollaborator,
    updateBeatStatus,
    fetchSubmissionDownloadFileList,
    fetchCheckoutSubmissionCardDetails,
  };
};

export default useSync;
