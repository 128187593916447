import React from "react";
import { Grid } from "@mui/material";

import { Sidebar } from "../../../layout/Sidebar/Sidebar";
import Form from "./Form";

const ConnectMeSubmissionPage = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<Form />}
        activeText="ConnectMe Submission Create"
      />
    </Grid>
  );
};

export default ConnectMeSubmissionPage;
