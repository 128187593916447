import React, { useEffect, useState } from "react";
import {
  MainPreReleaseServices,
  PreReleaseServicesBox,
  ActiveServices,
  MainServicesBox,
  RightBox,
  LeftBox,
  CheckBoxMain,
} from "./style";
import { Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  music_icon,
  amazon_icon,
  spotify_icon,
  deezer_icon,
  youtube_icon,
  messenger_icon,
} from "../../../../assets/smartlink";
import SwitchButton from "../../common/switchbutton/SwitchButton";
import CheckBox from "./CheckBox";
import DatePickers from "./DatePicker";
import SubmittButton from "../../common/submitbutton/submittbutton";
import {
  CREATE_PRE_SMART_LINK,
  UPDATE_PRERELEASE_SMART_LINK,
} from "../../../../Graphql_services/smartlink/SmartLink";
import { useMutation } from "@apollo/client";
import { Notification } from "../../../NotifyMsg/Notification";
import { useNavigate } from "react-router-dom";

const ActiveService = [
  {
    title: "Apple Music Pre - Add",
    icon: music_icon,
    value: "AppleMusicPreRelease",
  },
  {
    title: "Youtube Music Pre - Save",
    icon: youtube_icon,
    value: "YoutubeMusicPreRelease",
  },
  // {
  //   title: "Notify on Messenger",
  //   icon: messenger_icon,
  //   value:""
  // },
  {
    title: "Spotify Pre - Save",
    icon: spotify_icon,
    value: "SpotifyPreRelease",
  },
  {
    title: "Amazon Music Pre - Save",
    icon: amazon_icon,
    value: "AmazonMusicPreRelease",
  },
  {
    title: "Deezer Pre - Save",
    icon: deezer_icon,
    value: "DeezerPreRelease",
  },
];
const PreReleaseServices = ({ Preobj, EditData }) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [Linkurl, setLinkurl] = useState(null);
  const [EditMediaServices, setEditMediaServices] = useState([]);

  const handleServiceToggle = (value) => {
    const index = selectedServices.indexOf(value);
    if (index === -1) {
      setSelectedServices([...selectedServices, value]);
    } else {
      const updatedServices = [...selectedServices];
      updatedServices.splice(index, 1);
      setSelectedServices(updatedServices);
    }
  };

  const [createPreSmartLink, { data, loading }] = useMutation(
    CREATE_PRE_SMART_LINK
  );

  const [UpdatePreSmartLink, { data: UpdateData, loading: UpdateLoafing }] =
    useMutation(UPDATE_PRERELEASE_SMART_LINK);

  const handlePublish = async () => {
    Preobj.releaseDate = selectedDate;
    Preobj.baseUrl = Linkurl;
    if (EditData) {
      console.clear();
      const convertedArray = selectedServices.map((service) => {
        return { preReleaseServiceType: service };
      });
      Preobj.subMediaType = EditData?.smartlink?.subMediaType;
      Preobj.preReleaseServices = selectedServices;
      Preobj.locales = [
        {
          preReleaseServiceSettings: convertedArray,
          mediaServices: EditMediaServices,
          isoCode: EditData?.smartlink?.locales[0]?.isoCode,
        },
      ];

      if (Linkurl == null) {
        Notification("error", "Enter release source ");
        return;
      }
      if (selectedDate == null) {
        Notification("error", "Select Date ");
        return;
      }
      try {
        const result = await UpdatePreSmartLink({
          variables: Preobj,
          context: { clientName: "smartlink" },
        });
        if (result?.data?.updatePreSmartLink?.success) {
          Notification("success", result?.data?.updatePreSmartLink?.message);
          navigate("/smart-link/created-link");
        } else {
          Notification("error", result?.data?.updatePreSmartLink?.message);
        }
      } catch (error) {
        Notification(
          "error",
          error.message || "something went wrong please try again"
        );
      }
    } else {
      Preobj.preReleaseServices = selectedServices;

      if (Linkurl == null) {
        Notification("error", "Enter release source ");
        return;
      }
      if (selectedDate == null) {
        Notification("error", "Select Date ");
        return;
      }

      try {
        const result = await createPreSmartLink({
          variables: Preobj,
          context: { clientName: "smartlink" },
        });
        if (result?.data?.createPreSmartLink?.success) {
          Notification("success", result?.data?.createPreSmartLink?.message);
          navigate("/smart-link/created-link");
        } else {
          Notification("error", result?.data?.createPreSmartLink?.message);
        }
      } catch (error) {
        Notification(
          "error",
          error.message || "something went wrong please try again"
        );
      }
    }
  };

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    let UpdatedObj;
    if (EditData) {
      UpdatedObj = EditData?.smartlink;
    }
    if (UpdatedObj !== undefined) {
      setLinkurl(UpdatedObj?.baseUrl);
      // Services
      let services = UpdatedObj?.locales[0]?.preReleaseServiceSettings;
      let newserv = services.map((item) => {
        return item.preReleaseServiceType;
      });
      setSelectedServices(newserv);

      // Medaia Serices IDS

      let medaiid = UpdatedObj?.locales[0]?.mediaServices;
      let mediaIDS = medaiid.map((item) => {
        return { mediaServiceId: item.mediaServiceId, status: "NoDestination" };
      });
      setEditMediaServices(mediaIDS);
    }
  }, [EditData]);

  return (
    <>
      <MainPreReleaseServices>
        <PreReleaseServicesBox>
          <h3>Pre - Release</h3>
          <h4 className="description">
            Choose the services you'd like to feature on your pre-release
            landing page. We automatically add your release to fans' libraries
            as soon as it becomes available
          </h4>
          <ActiveServices>
            <h5>{selectedServices.length} active services</h5>
            {ActiveService.map((item, id) => (
              <MainServicesBox key={id}>
                <RightBox>
                  <Box className="img-box">
                    <img src={item.icon} alt="icon" />
                  </Box>
                  <h4 className="title">{item.title}</h4>
                </RightBox>
                <LeftBox>
                  <SwitchButton
                    checked={selectedServices.includes(item.value)}
                    onChange={(isChecked) =>
                      handleServiceToggle(isChecked ? item.value : null)
                    }
                  />
                  <ArrowForwardIosIcon className="farwordArrow" />
                </LeftBox>
              </MainServicesBox>
            ))}
          </ActiveServices>
        </PreReleaseServicesBox>
        <PreReleaseServicesBox className="sourcebox-main">
          <h3>Release Date And Source</h3>
          <h4 className="description">
            On the date you specify below, we will scan the release source and
            automatically transform your pre-release link into a standard link.
          </h4>
          <h3 className="releaseDate">Release Date</h3>
          <Box className="release-date">
            <DatePickers
              setSelectedDate={setSelectedDate}
              title="Select date"
              className="date-picker"
            />
            <span className="time-box">12:00 am</span>
            <span>Edt</span>
          </Box>
          <h3 className="releaseDate">Release Source</h3>
          <Box className="release-source">
            <span>Note</span>
            <input
              type="text"
              className=""
              value={Linkurl}
              placeholder="Enter a Spotify Link, Apple Music Link, ISRC or UPC code"
              onChange={(e) => setLinkurl(e.target.value)}
            />
          </Box>
          <Box className="parts">
            <h3>
              Which parts of your link do you want to update on the release day?
            </h3>
            <span>
              Existing sections will be replaced if new content is detected.
            </span>
          </Box>
          <CheckBoxMain>
            <Box className="checkbox-column">
              <CheckBox
                checked={true}
                onChange={handleChange}
                title="Media samples"
              />
              <CheckBox
                checked={true}
                onChange={handleChange}
                title="Meta data"
              />
              <CheckBox
                checked={true}
                onChange={handleChange}
                title="Social card image"
              />
            </Box>
            <Box className="checkbox-column">
              <CheckBox
                checked={true}
                onChange={handleChange}
                title="Service destinations"
              />
              <CheckBox
                checked={true}
                onChange={handleChange}
                title="Landing page image"
              />
              <CheckBox
                checked={true}
                onChange={handleChange}
                title="Update manual destinations"
              />
            </Box>
          </CheckBoxMain>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Box className="btn-box">
              <SubmittButton
                name="Publish"
                type="submit"
                isloading={loading}
                submitt={handlePublish}
              />
            </Box>
          </div>
        </PreReleaseServicesBox>
      </MainPreReleaseServices>
    </>
  );
};

export default PreReleaseServices;
