import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";

export const dayCount = (dateToCheckAgainst, pitchingPeriod = null) => {
  var eventdate = moment(dateToCheckAgainst);
  var todaysdate = moment();
  if (pitchingPeriod) {
    const createdAtData = moment(dateToCheckAgainst).add(
      pitchingPeriod,
      "days"
    );
    return createdAtData.diff(todaysdate, "days");
  } else {
    return eventdate.diff(todaysdate, "days");
  }
};

export const remainngTimeCount = (dateToCheckAgainst) => {
  const now = moment();
  const expiration = moment(dateToCheckAgainst);
  // get the difference between the moments
  const diff = expiration.diff(now);
  //express as a duration
  const diffDuration = moment.duration(diff);
  return {
    minutes: diffDuration.minutes(),
    hours: diffDuration.hours(),
    days: Math.floor(diffDuration.asDays()),
  };
};

export const handleDownload = async (
  url,
  fileName,
  commingFrom = "",
  ID = ""
) => {
  try {
    if (commingFrom === "my_submissions" && ID) {
      const { data, status } = await axios.get(
        `${
          process.env.REACT_APP_OPPORTUNITY_URL
        }/opportunities/submission/getDownloadBeat/${ID}?userId=${localStorage.getItem(
          "userId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    }

    const response = await fetch(url);
    const blob = await response.blob();

    const fileUrl = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileName;
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(fileUrl);
    document.body.removeChild(a);

    toast.success("File downloaded successfully!");
  } catch (error) {
    toast.error("Error while downloading file.");
    console.log("error while fetching audio file", error);
  }
};

export function calculateOpportunityStatus(data) {
  const opportunity = data?.opportunity;
  const pitchingPeriod = opportunity?.recordList?.pitchingPeriod?.split(" ")[0];
  const createdAt = opportunity?.createdAt;
  if (pitchingPeriod) {
    const daysRemaining = dayCount(createdAt, pitchingPeriod);
    if (daysRemaining > 0) {
      return `${daysRemaining} ${
        daysRemaining === 1 ? "day" : "days"
      } remaining`;
    } else {
      return "Expired";
    }
  } else {
    return "";
  }
}

export const getTimeRemainingText = (createdAt, pitchingPeriod) => {
  const daysToPitch = dayCount(createdAt, pitchingPeriod.split(" ")[0]);

  if (!pitchingPeriod) {
    return "";
  }

  if (daysToPitch > 0) {
    return `${daysToPitch} ${daysToPitch === 1 ? "day" : "days"} remaining`;
  }

  return "Expired";
};

export const getInitialsFromName = (name) => {
  if (!name) return;

  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
};

export const formatCount = (number, withAbbr = false, decimals = 2) => {
  const COUNT_ABBRS = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"];

  if (isNaN(number)) return 0;

  const i =
    0 === number ? number : Math.floor(Math.log(number) / Math.log(1000));
  let result = parseFloat((number / Math.pow(1000, i)).toFixed(decimals));
  if (withAbbr) {
    result += `${COUNT_ABBRS[i]}`;
  }
  return result;
};
