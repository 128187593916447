import React, { useState, useEffect } from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  CreateBeatInboxPopup,
  HorizontalMenu,
  MainTitle,
  StarredBeats,
} from "../../../components/smartlink";
import { image_placeholder_icon } from "../../../assets/smartlink";
import { useQuery } from "@apollo/client";
import { GetAllFavouriteBeats } from "../../../Graphql_services/beatinbox/Beats";
import BugsPopup from "../../../components/smartlink/producers/producerssubmit/popups/BugsPopup";

const StarredBeatsPage = () => {
  const [Bugopen, setBugopen] = useState(false);
  const [open, setOpen] = useState(false);

  const { loading, error, data } = useQuery(GetAllFavouriteBeats, {
    context: { clientName: "beats" },
    fetchPolicy: "network-only",
  });

  const createModelOpen = () => {
    setOpen(!open);
  };
  const beatBoxes = data?.getFavouriteBeats?.beats;
  useEffect(() => {
    if (error) {
      setBugopen(true);
    }
  }, [error]);

  return (
    <>
      <SmartLinkLayout>
        <MainTitle
          title="Beat Inbox"
          hasbutton={true}
          isBeatinbox={true}
          openCreateBeatPopup={createModelOpen}
        />
        <HorizontalMenu isBeatInbox={true} />
        <StarredBeats data={beatBoxes} loading={loading} />

        <CreateBeatInboxPopup
          open={open}
          setOpen={setOpen}
          image={image_placeholder_icon}
        />
        <BugsPopup open={Bugopen} setOpen={setBugopen} />
      </SmartLinkLayout>
    </>
  );
};

export default StarredBeatsPage;
