import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const RadioMainBox = styled(Box)(({ theme }) => ({
  "& h5": {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "16px",
  },

  "& label": {
    color: "#AFAFAF",
    textTransform: "capitalize",
  },
  "& .radio-style": {
    color: "#AFAFAF",
    fontSize: "22px",
    "&.Mui-checked": { color: "#2EBB55" },
  },
}));
export { RadioMainBox };
