// mixpanel.js
import mixpanel from "mixpanel-browser";

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_KEY;

mixpanel.init(MIXPANEL_TOKEN, {
  debug: true,
  track_pageview: true,
  ignore_dnt: true,
  persistence: "localStorage",
});

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    console.log("Tracking event: ", name);
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
