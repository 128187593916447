import React from "react";
import { Grid } from "@mui/material";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
// import MyContractsComponent from "../../components/MyContractsComponents/MyContracts/MyContractsComponent.js";
import CreateTemplateComponent from "../../components/MyContractsComponents/MyContracts/CreateTemplateComponent";

const CreateTemplates = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<CreateTemplateComponent />}
        activeText="My Contracts"
      />
    </Grid>
  );
};

export default CreateTemplates;
