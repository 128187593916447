import React, { memo, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modelstyle, ModelContentBox } from "../../socialintegrate/style";
import { Box, Button, IconButton, Modal } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { close_icon } from "../../../../assets/smartlink";
import { WelcomeMain, FormMain } from "./styles/MainStyles";
import { useNavigate } from "react-router-dom";
import { ForgetPass } from "../../../Hooks/Authentication/SignIn/ForgetPasword/Mutation";
import { useMutation } from "@apollo/client";
import { Notification } from "../../../NotifyMsg/Notification";
// import ForgetPasswordPopup from "./ForgetPasswordPopup";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ForgetPasswordPopup = ({
  open = false,
  setOpen = () => {},
  previusOpen = () => {},
}) => {
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const [forgetPasswordMutation, { data, error }] = useMutation(ForgetPass);
  const [forgotPasswordOpen, setforgotPasswordOpen] = useState(false);
  const CreateModelClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(!open);
  };

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const res = await forgetPasswordMutation({
        variables: {
          email: values.email,
          countrycode: null,
          phoneno: null,
        },
      });

      if (res?.data?.forgetPassword?.success) {
        setOpen(false);
        Notification("success", res?.data?.forgetPassword?.message);
        navigate(`/producer/auth/otp/${values.email}`);
      } else {
        Notification("error", res?.data?.forgetPassword?.message);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* <Modal
        open={true}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0, 0, 0, 0.50)",
          },
        }}
      > */}
      <ModelContentBox
        sx={{
          ...Modelstyle,
          width: { xs: "100%", sm: "500px" },
          padding: "2rem",
          borderRadius: "20px",
          background:
            "linear-gradient(239deg, rgba(255, 255, 255, 0.14) -14.64%, rgba(255, 255, 255, 0.02) 133.34%, rgba(255, 255, 255, 0.06) 133.34%)",
          backdropFilter: "blur(200px)",
        }}
        className="ModelContentBox"
      >
        <Box className="popup_header">
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            aria-label="settings"
            sx={{
              color: "#AFAFAF",
              backgroundColor: "none",
              fontSize: "2rem",
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <IconButton
            onClick={CreateModelClose}
            aria-label="settings"
            sx={{
              color: "#AFAFAF",
              backgroundColor: "none",
              fontSize: "2rem",
            }}
          >
            <img src={close_icon} alt="cancel-icon" />
          </IconButton>
        </Box>

        <WelcomeMain>
          <h6>Forget Password</h6>
          <p>Please enter your registered email address below</p>
        </WelcomeMain>
        <FormMain className="forget_password">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors }) => (
              <Form>
                <div className={`form__field ${errors.email ? "error" : ""}`}>
                  <label htmlFor="email">Email Address:</label>
                  <Field type="text" id="email" name="email" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>

                <Button type="submit" disabled={isloading}>
                  {isloading ? "Loading..." : "Submit"}
                </Button>

                <Box className="othre__acount">
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </FormMain>
      </ModelContentBox>
      {/* </Modal> */}
    </>
  );
};

export default memo(ForgetPasswordPopup);
