import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import styles from "./submissions_card.module.css";

import { Avatar, Divider, Grid, IconButton } from "@mui/material";
import { get, last, size } from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import allImages from "../../../assets";
import { beatinbox_message } from "../../../assets/smartlink";
import WavePlayer from "../../../components-old/WavePlayer";
import { sendConnectionRequest } from "../../../context/apis/connect-me";
import useAppState from "../../../context/useAppState";
import { getTimeRemainingText, handleDownload } from "../../../shared/funs";
import BeatCardCheckoutModal from "../../BeatsCheckout/BeatCardCheckoutModal";
import { BeatsCheckoutFailedModal } from "../../BeatsCheckout/BeatsCheckoutFailedModal";
import { BeatsCheckoutSuccessModal } from "../../BeatsCheckout/BeatsCheckoutSuccessModal";
import { BeatsLicenseModal } from "../../BeatsCheckout/BeatsLiscenseModal";

const MODALS = {
  LICENSE_MODAL: "LICENSE_MODAL",
  CHECKOUT_MODAL: "CHECKOUT_MODAL",
  SUCCESS_MODAL: "SUCCESS_MODAL",
  ERROR_MODAL: "ERROR_MODEL",
};

const ConnectSubmissionCard = ({
  isShowOpportunityName = false,
  isShowMusicProducerName = false,
  isShowView = false,
  isShowFavorite = false,
  isShowFlag = false,
  isShowStatus = true,
  isShowBeatDetail,
  showDownloadbtn = false,
  data,
  beatInfoBtn = null,
  selectBeatInfo = null,
  isShowCollaborators = true,
  sender,
  reciever,
  commingFrom = "",
  canSelect,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { updateFavorite, updateFlag } = useAppState("submission");

  const [favorite, setFavorite] = useState(false);
  const [flag, setFlag] = useState(false);
  const [isNotHideChat, setIsNotHideChat] = useState(true);
  const navigate = useNavigate();
  const {
    collaborators,
    isMasterSample,
    _id,
    file,
    exclusivePrice,
    isFavorite,
    isFlag,
    opportunity,
  } = data;
  console.log("🚀 ~ data:", data);

  const { title, coverPhoto, bpm, key, scale, expireDate, userInfo } =
    opportunity || {};

  const allAccepted =
    collaborators &&
    collaborators.every((element) => element.status === "Accepted");

  useEffect(() => {
    const url = window.location.pathname; // Use window.location.pathname to get the current URL
    const params = url.split("/").pop();
    console.log("params", params);
    if (params === "my-submissions") {
      console.log("here is true");
      setIsNotHideChat(false);
    }
  }, []);

  useEffect(() => {
    setFavorite(data.isFavorite);
    setFlag(data.isFlag);
  }, [data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFavorite = () => {
    const payload = { isFavorite: !favorite };

    try {
      updateFavorite(_id, payload);
      setFavorite((prev) => !prev);
    } catch (error) {
      toast.error("Record not updated, please try again.");
    }
  };

  const onFlag = () => {
    const payload = { isFlag: !flag };

    try {
      updateFlag(_id, payload);
      setFlag((prev) => !prev);
    } catch (error) {
      toast.error("Record not updated, please try again.");
    }
  };

  const handleView = () => {
    navigate(
      `/opportunities/viewsubmissiondetails/${data._id}?type=my_submissions`
    );
  };

  const open = Boolean(anchorEl);

  const fileName = decodeURIComponent(last(get(file, "url", "").split("/")));

  const [price, setPrice] = useState(0);
  const [modalName, setModalName] = useState(null);

  const closeModal = () => {
    setModalName(null);
  };

  const handleModal = (name) => {
    setModalName(null);

    setTimeout(() => {
      setModalName(name);
    }, 500);
  };

  const handleBeatDownload = async () => {
    if (!file?.url) {
      new Notification("error", {
        body: "No audio file found!",
      });
      return;
    }
    const response = await fetch(file.url);
    const audioBlob = await response.blob();
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(audioBlob);
    downloadLink.download = file.fileName || "audio_file";
    downloadLink.onclick = () => {
      new Notification("success", {
        body: "Download complete!",
      });
    };
    downloadLink.click();
  };

  const handleSuccess = () => {
    handleModal(MODALS.SUCCESS_MODAL);
    handleBeatDownload();
  };

  let ifNotSame =
    data.opportunity.userId ===
    (data.createdBy || data.opportunity.userInfo._id)
      ? false
      : true;
  console.log("ifnot same", isNotHideChat);
  console.log("ifnot same", ifNotSame, isNotHideChat);
  const createOneTwoOneChat = async () => {
    console.log("data=======", data);
    let oppertunityBy = data.opportunity.userId;
    let submittedBy = data.createdBy || data.opportunity.userInfo._id;
    console.log("submitter id", submittedBy);
    console.log("oppertunier id", oppertunityBy);
    if (submittedBy === oppertunityBy) {
      toast.error("You can not start a conversation with yourself ");
    } else {
      let conenction = await sendConnectionRequest(submittedBy, oppertunityBy);
      console.log("connection", conenction);
      if (conenction.chatId) {
        navigate(`/chat/${conenction.chatId}`);
      } else toast.error("Unable to start Chat please try again in a while!");
    }
  };

  return (
    <div className={styles.submissions_card}>
      <div className={styles.submissions_card_left}>
        <div className={styles.submission_info}>
          <img
            className={styles.submission_image}
            src={coverPhoto?.url}
            alt="submission"
          />
          <div className={styles.text}>
            {/* <div className={styles.title}>lookingFor:{lookingFor}</div> */}
            {isShowOpportunityName && (
              <div className={styles.title}>{title}</div>
            )}
            <div
              className={isShowOpportunityName ? styles.subtitle : styles.title}
            >
              {fileName}
            </div>
            {true && (
              <div className={styles.subtitle}>{userInfo?.userName}</div>
            )}
          </div>
          <div className={styles.topAction}>
            {ifNotSame && isNotHideChat && (
              <img
                src={beatinbox_message}
                onClick={createOneTwoOneChat}
                alt="messsag-icon"
                style={{ width: "2vw" }}
              />
            )}
            {isShowFavorite && (
              <div onClick={() => onFavorite()}>
                <img
                  src={favorite ? allImages.filledYellowStar : allImages.star}
                  alt="star"
                />
              </div>
            )}
            {isShowFlag && (
              <div onClick={() => onFlag()}>
                <img
                  src={flag ? allImages.filledRedFlag : allImages.flag}
                  alt="flag"
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.bottom_info}>
          {file?.url && (
            <div className={styles.waveform}>
              <WavePlayer key={file.url} url={file.url} file={file} />
            </div>
          )}
          {true && file?.url && (
            <div>
              <div
                className={styles.download_button}
                onClick={() =>
                  handleDownload(file.url, file.name, commingFrom, _id)
                }
              >
                Download
              </div>
            </div>
          )}
          <div className={styles.bottom_text}>
            {bpm && <p className={styles.bottom_info_text}>BPM: {bpm}</p>}
            {key && <p className={styles.bottom_info_text}>KEY: {key}</p>}
            {scale && <p className={styles.bottom_info_text}>SCALE: {scale}</p>}

            {/* for showing the tone and mode  */}
            {/* <div className={styles.ErrorOutlineIcon}>
              <ErrorOutlineIcon />
              <div className={styles.toneToltipMain}>sad :asda</div>
            </div> */}
            {isShowCollaborators && size(collaborators) > 0 && (
              <div>
                <IconButton
                  variant="contained"
                  onClick={handleClick}
                  size="small"
                >
                  <img src={allImages.info} className={styles.time_icon} />
                </IconButton>
                {/* Collaborators & Splits  popver */}
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  classes={{ paper: styles.collaboratorsInfo }}
                >
                  <Grid container rowGap={1}>
                    <Grid item xs={12}>
                      <Typography className={styles.heading}>
                        Collaborators & Splits{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container rowGap={1}>
                        {collaborators.map(
                          ({ name, lastname, publishingPercentage }, index) => (
                            <Grid xs={12} key={index}>
                              <Grid container justifyContent={"space-between"}>
                                <Grid item>
                                  <Grid
                                    container
                                    alignItems={"center"}
                                    columnGap={1}
                                  >
                                    <Avatar className={styles.avatar} />
                                    <Typography className={styles.name}>
                                      {name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Typography className={styles.name}>
                                    {" "}
                                    {publishingPercentage}%
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={styles.beatPriceWrapper}>
                      <Grid
                        container
                        rowGap={1}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <Typography className={styles.beatPriceLabel}>
                            Beat Price{" "}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={styles.price}>
                            ${exclusivePrice}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={styles.divider} />
                    </Grid>
                    {isMasterSample && (
                      <Grid item xs={12}>
                        <Typography className={styles.isMasterText}>
                          It’s contain <b>Master</b> Sample
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Popover>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.submissions_card_right}>
        <div className={styles.time_remaining}>
          <img
            src={allImages.clock}
            alt="clock"
            className={styles.time_icon}
          ></img>
          <p className={styles.time_remaining_text}>
            {getTimeRemainingText(data?.createdAt, data?.pitchingPeriod)}
          </p>
          {size(collaborators) > 0 && isShowStatus && (
            <>
              {allAccepted ? (
                <img
                  src={allImages.greenTick}
                  alt="greentick"
                  className={styles.time_icon}
                />
              ) : (
                <img
                  src={allImages.warning}
                  alt="warning"
                  className={styles.time_icon}
                />
              )}
            </>
          )}
        </div>
        <div className={styles.bottom_right_info}>
          {/* {data.status && (
            <div className={styles.status_button}>{data.status}</div>
          )} */}
          {isShowView && (
            <div className={styles.info_button} onClick={handleView}>
              View
            </div>
          )}
          {/* {isShowBeatDetail && selectBeatInfo} */}
          {/* {beatInfoBtn && beatInfoBtn} */}
          {canSelect && (
            <div
              className={styles.info_button}
              onClick={() => handleModal(MODALS.LICENSE_MODAL)}
            >
              Buy
            </div>
          )}
          {modalName === MODALS.LICENSE_MODAL && (
            <BeatsLicenseModal
              openModal={true}
              closeModal={closeModal}
              data={data}
              handleCheckout={() => handleModal(MODALS.CHECKOUT_MODAL)}
              setPrice={setPrice}
            />
          )}

          {modalName === MODALS.CHECKOUT_MODAL && (
            <BeatCardCheckoutModal
              isOpen={true}
              handleClose={closeModal}
              handleSuccess={handleSuccess}
              price={price}
              handleError={() => {
                console.log("--- handle error reached ----");
                handleModal(MODALS.ERROR_MODAL);
              }}
            />
          )}

          {modalName === MODALS.SUCCESS_MODAL && (
            <BeatsCheckoutSuccessModal
              openModal={true}
              closeModal={closeModal}
            />
          )}

          {modalName === MODALS.ERROR_MODAL && (
            <BeatsCheckoutFailedModal
              openModal={true}
              closeModal={closeModal}
              handleTryAgain={() => handleModal(MODALS.CHECKOUT_MODAL)}
            />
          )}

          {/* {isShowBeatDetail && beatInfoBtn} */}
        </div>
      </div>
    </div>
  );
};

export default ConnectSubmissionCard;
