import React, { useContext, useState } from "react";
import { Controller } from "react-hook-form";

import CountryCodes from "../Authentication/CountryCode/CountryCodes";
import { FormControl, Image, Input, Pressable } from "native-base";
import { AuthContext } from "../Authentication/Layout";

const Textfield = (props) => {
  const [show, setShow] = useState(true);
  const {
    label,
    control,
    type,
    color,
    btnText,
    mt,
    placeholder,
    onChangeEmail,
    onChangeOtp,
    emailValue,
    otp,
    customType,
  } = props;

  const {
    activeStep,
    Email,
    otpValue,
    Password,
    registredEmail,
    registredphone,
    setRegisteredphone,
    genre,
    username,
    genreField,
    setgenreField,
    socialField,
    setSocialField,
    instaMail,
    otpvalue,
    firstName,
    lastName,
    noOfMonthlyListeners,
    spotifyUrl,
    labelName,
  } = useContext(AuthContext);

  // const isEmail = type === "email"
  const isregistrationEmail = type === "email";
  const isRegistredphone = type === "phoneNumber";
  // const isOthers = type === "others";
  const isPassword = type === "password" || type === "confirmPassword";
  const isUsername = type === "username";
  const isOthers = type === "others";
  const isOTP = type === "otp";
  const isFirstName = type === "firstName";
  const isLastName = type === "lastName";
  const isInstaGram = type === "email_username";
  const isNoOfMonthlyListner = type === "monthlyListerner";
  const isSpotifyLink = type === "spotifyLink";
  const isLabelName = type === "labelName";
  return (
    <FormControl mt={mt}>
      <FormControl.Label
        fontFamily="poppins"
        // mb={2}

        _text={{
          fontSize: { lg: "xl", md: "1rem", base: "1rem" },
          color: color,
          fontFamily: "poppins",
          fontWeight: "medium",
        }}
        textTransform={"capitalize"}
      >
        {label}
      </FormControl.Label>
      {props?.type === "phone" || props?.type === "phoneNumber" ? (
        <CountryCodes type={props?.type} />
      ) : (
        <Controller
          control={control}
          defaultValue={
            isLabelName
              ? labelName
              : isNoOfMonthlyListner
              ? noOfMonthlyListeners
              : isSpotifyLink
              ? spotifyUrl
              : isLastName
              ? lastName
              : isFirstName
              ? firstName
              : isOTP
              ? otpvalue
              : isInstaGram
              ? instaMail
              : isPassword
              ? Password
              : isregistrationEmail
              ? registredEmail
              : isRegistredphone
              ? registredphone
              : isUsername
              ? username
              : isOthers && activeStep === 6
              ? genreField
              : socialField
          }
          render={({ field: { onChange, value, onBlur } }) => (
            <Input
              _hover={{ borderColor: "rgba(255, 255, 255, 0.5)" }}
              // onFocus={borderColor:"green"}
              focusOutlineColor="rgba(46, 187, 85, 0.6)"
              _focus={{ borderColor: "rgba(46, 187, 85, 0.6)" }}
              //  _focus={{}}
              // type={props.type === "password" ? "password" : ""}
              onChangeText={
                customType === "email"
                  ? onChangeEmail
                  : customType === "otp"
                  ? onChangeOtp
                  : onChange
              }
              _text={{ fontSize: "1rem", fontFamily: "poppins", color: color }}
              fontFamily="poppins"
              fontSize={{ lg: "18px", md: "16px", base: "14px" }}
              color="rgba(255, 255, 255, 0.65)"
              onBlur={onBlur}
              borderColor="rgba(255, 255, 255, 0.3)"
              height={{
                xl: "60px",
                base: "45px",
              }}
              secureTextEntry={
                show &&
                (props?.type === "password" ||
                  props?.type === "confirmPassword")
                  ? true
                  : false
              }
              type={props?.type === "otp" ? "number" : "text"}
              placeholder={props.placeholder || ""}
              //  InputLeftElement={props.type==="phone" && <CountryCodes/>}
              InputRightElement={
                props?.type === "password" ||
                props?.type === "confirmPassword" ? (
                  <Pressable mr={5} onPress={() => setShow(!show)}>
                    {!show && (
                      <Image
                        height={{ md: "25px", base: "18px" }}
                        width={{ md: "28px", base: "20px" }}
                        resizeMode=""
                        source={require("..//..//assets//LoginScreens/Show.png")}
                      />
                    )}
                    {show && (
                      <Image
                        height={{ md: "25px", base: "18px" }}
                        width={{ md: "28px", base: "20px" }}
                        resizeMode=""
                        source={require("..//..//assets//LoginScreens/eye-slash.png")}
                      />
                    )}
                  </Pressable>
                ) : (
                  ""
                )
              }
              value={
                customType === "email"
                  ? emailValue
                  : customType === "otp"
                  ? otp
                  : value
              }
            />
          )}
          name={type}
        />
      )}
    </FormControl>
  );
};

export default Textfield;
