import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "flex-start",
  paddingTop: "0px",
  borderBottom: "1px solid #414241",
  paddingBottom: "20px",
  justifyContent: "space-between",
  alignItems: "center",

  "& h6": {
    fontFamily: theme.typography.fontFamily,
    color: "#FFF",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    margin: "0px 0px",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  },
  "& .header-createinbox-box": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& .refresh-btn": {
      background: "#414241",
      padding: "8px",
      borderRadius: "5px",
      cursor: "pointer",
      svg: {
        color: "#FFF",
      },
      "&:hover": {
        background: "rgba(46, 187, 85, 0.20)",
      },
    },
    button: {
      borderRadius: "5px",
      background: "#2EBB55",
      color: "#FFF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "capitalize",
      padding: "8px 20px",
      "&:hover": { background: "#2EBB55" },
    },
  },

  [theme.breakpoints.down("md")]: {
    "& h6": {
      fontSize: "20px",
    },
    button: {
      fontSize: "15px",
    },
  },
}));

const RemainingMain = styled(Box)(({ theme }) => ({
  color: "#F00",
  fontFamily: theme.typography.fontFamily,
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginRight: 10,
}));

export { Main, RemainingMain };
