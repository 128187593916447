import React, { memo } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { ModelContentBox, Modelstyle } from "../styles/style";
import { close_icon } from "../../../../assets/smartlink";

import BlueButtonComponents from "../../AdditionalComponents/utils/BlueButtonComponent";

const WelcomePack = ({
  open = false,
  setOpen = () => {},
  EditData = {},
  setEditData = () => {},
  friends = [],
}) => {
  const CreateModelClose = (event) => {
    setOpen(!open);
    setEditData({});
  };

  return (
    <>
      <Modal
        open={open}
        onClose={CreateModelClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="MainInboxCreate"
          sx={{
            ...Modelstyle,
            width: "720px",
            display: "flex",
            padding: "18px",
            height: "auto",
            flexDirection: "column",
            borderRadius: "0.625rem",
            background: "#141515",
          }}
        >
          <Box
            sx={{ height: "40px", display: "flex", justifyContent: "right" }}
          >
            <IconButton
              onClick={CreateModelClose}
              aria-label="settings"
              sx={{
                color: "#fff",
                //   color: theme.palette.primary.dark,
                backgroundColor: "none",
                fontSize: "2rem",
                padding: "0 !important",
                margin: "0 !important",
                marginTop: "-7px !important",
              }}
            >
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "12px",
              padding: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "12px",
              }}
            >
              <div
                style={{
                  fontSize: "24px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Welcome To Your Pack
              </div>
              <div
                style={{
                  fontSize: "18px",
                  color: "#AFAFAF",
                  textAlign: "center",
                }}
              >
                Packs are collections of files that you can share with
                collaboratiors.
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <img src="v2Assets/ViewActivity.png" alt="view" />
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <div style={{ fontSize: "24px", color: "white" }}>
                  View Activity
                </div>
                <div style={{ fontSize: "18px", color: "#AFAFAF" }}>
                  See which packs are getting opened and who’s using the files.
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <img src="v2Assets/UpdateContent.png" alt="update" />
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <div style={{ fontSize: "24px", color: "white" }}>
                  Update Content
                </div>
                <div style={{ fontSize: "18px", color: "#AFAFAF" }}>
                  Add or remove a file from any pack to keep content up to date.
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <img src="v2Assets/ControlAccess.png" alt="control" />
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <div style={{ fontSize: "24px", color: "white" }}>
                  Control Access
                </div>
                <div style={{ fontSize: "18px", color: "#AFAFAF" }}>
                  Delete packs or update permissions at any time.
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "20px 0px",
            }}
          >
            <BlueButtonComponents
              text="Got it"
              onClickFunction={CreateModelClose}
            />
          </div>
        </ModelContentBox>
      </Modal>
    </>
  );
};

export default memo(WelcomePack);
