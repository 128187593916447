import LicenseComponent from "../components/MyContractsComponents/License/LicenseComponent";
import DraftComponent from "../components/MyContractsComponents/Drafts/DraftComponent";
import SentComponent from "../components/MyContractsComponents/Sent/SentComponent";
import TrashComponent from "../components/MyContractsComponents/Trash/TrashComponent";
import ContractDasboardComponent from "../components/MyContractsComponents/Dashboard/DashboardComponent";
import TemplatesManage from "../components/MyContractsComponents/MyContracts/TemplatesManage";

import ReceiveContractIcon from "../assets/receive-contract.png";
import SentContractIcon from "../assets/sent-contract.png";
import DraftContractIcon from "../assets/draft-contract.png";
import CompleteContractIcon from "../assets/complete-contract.png";

import { makeStyles } from "@material-ui/core";
import { format } from "date-fns";


export const HTTP_STATUS = {
  OK: 200,
};

export const LOOKING_FOR = ["Beats", "Loops"];

export const LOOKING_FOR_SYNC = [
  "Instrumentals",
  "Complete Track",
  "Custom Track",
];
export const LOOKING_FOR_CONTRACTS = [
  "Dashboard",
  "Templates",
  "Drafts",
  "Sent",
  "Trash",
];

export const CONTRACT_COMPONENTS = {
  Templates: <TemplatesManage />,
  License: <LicenseComponent />,
  Drafts: <DraftComponent />,
  Sent: <SentComponent />,
  Trash: <TrashComponent />,
  Dashboard: <ContractDasboardComponent />,
};

export const CONTRACT_DASHBOARD_ICON = {
  receive: ReceiveContractIcon,
  sent: SentContractIcon,
  completed: CompleteContractIcon,
  draft: DraftContractIcon,
};

export const SYNC_MEDIA_TYPE = [
  "Advertisement",
  "Movie",
  "TV Show",
  "Documentary",
  "Influencer Content",
  "Sport(s) Game Day experience",
  "Other",
];

export const SOUNDPACKS_TABS = ["SOUND PACKS", "PURCHASES"];

export const GENRE = [
  "Rap/Hip-Hop",
  "R&B",
  "Alternative",
  "Indie",
  "Country",
  "Pop",
  "Other",
  "EDM",
  "Trap",
];

export const KEY = [
  "C",
  "C#/Db",
  "D",
  "D#/Eb",
  "E",
  "F",
  "F#/Gb",
  "G",
  "G#/Ab",
  "A",
  "A#/Bb",
  "B",
];

export const ARTIST_STATUS = [
  "Independent Artist",
  "Signed to Major Record Label",
];

export const SCALE = [
  "Major",
  "Minor",
  "Major Pentatonic",
  "Minor Pentatonic",
  "Whole Tone",
  "Chromatic",
  "Blues",
];

export const MODE_TONE = [
  "Dark",
  "Happy",
  "Energy/ Uptempo",
  "Virtual",
  "Atmospheric",
  "Pain",
  "Smooth",
  "Emotional",
  "Other",
];

export const TAGS = [
  "#lofi music",
  "#bollywood",
  "#Music",
  "#artist",
  "#hollywood",
  "#tollywood",
  "#talhaanjum",
  "#baap song",
  "Music",
  "ca/ b mior",
  "beat/loop",
  "#Rap",
];

export const FILE_FORMAT = ["MP3", "AV", "AIFF", "PCM", "STEMS"];

export const ROLES = {
  LABEL: "Label",
  MUSIC_PRODUCER: "Music Producer",
  A_AND_R: "A & R",
  MANAGER: "Manager",
  ARTIST: "Artist",
};

export const TUNEVIBES_DATA = [
  "Dark",
  "Happy",
  "Energy/ Uptempo",
  "Virtual",
  "Atmospheric",
  "Pain",
  "Emotional",
  "Smooth",
  "Others",
];

export const PITCHING_PERIOD = [
  {
    value: "30 days",
    label: "30 days",
  },
  {
    value: "60 days",
    label: "60 days",
  },
  {
    value: "90 days",
    label: "90 days",
  },
];

export const PUBLISHING_FEE_TOOLTIP =
  "We take a 5% publishing fee for facilitating the collaboration.";

export const UNCOMPLETE_LIBRARY_TOOLTIP =
  "You must fill out all information before submitting this audio file.";

export const Pack_Detail_Tooltop =
  "This is a multiline \n ;Tooltip example&#10;with three lines";

export const SUBSCRIPTION_PLANS = [
  {
    planId: process.env.REACT_APP_MONTHLY_SUBSCRIPTION,
    planName: "45",
  },
  {
    planId: process.env.REACT_APP_YEARLY_SUBSCRIPTION,
    planName: "300",
  },
];

export const PUBLISH_PERCENT_AMOUNT = 45;

export const ACCOUNT_STATUS = {
  accessGranted: "Access Granted",
  byPassSubscription: "By Pass Subscription",
  normalFlow: "Normal Flow",
  paymentPending: "Payment Pending",
  byPassPayment: "By Pass Payment",
};

export const COUNTRY = [
  "United States",
  "Spain",
  "United Kingdom",
  "Canada",
  "Brazil",
  "China",
  "German",
  "France",
];

export const YEAR = ["2018", "2019", "2020", "2021", "2022", "2023", "2024"];

export const SEARCHTAGLIST = [
  ["ca/ b mior", "ca/ b mior", "Music"],
  ["artist", "ca/ b mior", "beat/loop"],
];

export const TAG_OPTIONS = [
  { value: "#lofi music", label: "#lofi music" },
  { value: "#bollywood", label: "#bollywood" },
  { value: "#Music", label: "#Music" },
  { value: "#artist", label: "#artist" },
  { value: "#hollywood", label: "#hollywood" },
  { value: "#tollywood", label: "#tollywood" },
  { value: "#talhaanjum", label: "#talhaanjum" },
  { value: "#baap song", label: "#baap song" },
  { value: "#Music", label: "#Music" },
  { value: "#ca/ b mior", label: "#ca/ b mior" },
  { value: "#beat/loop", label: "#beat/loop" },
  { value: "#Rap", label: "#Rap" },
];

export const TYPE_TONE = [
  { value: "Dark", label: "Dark" },
  { value: "Happy", label: "Happy" },
  { value: "Energy/ Uptempo", label: "Energy/ Uptempo" },
  { value: "Virtual", label: "Virtual" },
  { value: "Atmospheric", label: "Atmospheric" },
  { value: "Pain", label: "Pain" },
  { value: "Smooth", label: "Smooth" },
  { value: "Emotional", label: "Emotional" },
  { value: "Other", label: "Other" },
];

export const DEFAULT_VALUE = {
  basicInfo: {
    userID: "",
    file: null,
    fileURL: "",
    fileName: "Untitled",
    fileNote: "Add a Note",
    fileSize: 0,
    fileDuration: 0,
    imageURL: "",
    title: "",
    tags: [],
    scale: "",
    bpm: 30,
    fileKey: "",
    email: "",
    sharedLink: "",
    isStared: false,
    isTrashed: false,
  },
  beatInfo: {
    exclusivePrice: 0,
    nonExclusivePrice: 0,
    publisherName: "",
    publisherPro: "",
    splitRatio: "",
    modeTone: "",
    isCollaborate: false,
    containMasterSample: false,
  },
  pitchInfo: {
    enablePitch: false,
    wavFileID: null,
    instrumentalFileID: null,
    cleanVersionFileID: null,
    trackInfo: {
      enableTrack: false,
      country: "",
      registrationCode: "",
      year: 1990,
      designationCode: "",
    },
    ownerInfo: {
      enableOwner: false,
      fullName: "",
      email: "",
      phone: "",
    },
  },
};

export const useStyles = makeStyles((theme) => ({
  modaltitle: {
    fontSize: 24,
    color: "#E3E3E3",
    fontWeight: 400,
    margin: 0,
  },
  futureContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
    paddingBottom: 15,
  },
  continueButton: {
    width: "100%",
    height: 56,
    fontSize: 20,
    fontWeight: 500,
    borderRadius: 5,
    backgroundColor: "#2EBB55",
    color: "white",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
    "&:active": {
      opacity: 1,
    },
  },
  cardContainer: {
    width: 310,
    height: 200,
    backgroundColor: "#030303",
    border: "1px dashed #424142",
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addedcardContainer: {
    width: 310,
    height: 200,
    backgroundColor: "#2EBB55",
    border: "none",
    borderRadius: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  overlayCard: {
    backgroundColor: "#EBF4B3",
    height: 64,
    width: "100%",
    borderRadius: "0 0 18px 18px",
    position: "absolute",
    bottom: 0,
  },
  addcardtext: {
    fontSize: 20,
    fontWeight: 400,
    color: "#AFAFAF",
    margin: 0,
  },
  carddetailcontainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingTop: 30,
    paddingBottom: 30,
  },
  addcardmodalimgcontainer: {
    gap: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  micImg: {
    position: "absolute",
    top: 24,
    left: 24,
  },
  carddetail: {
    display: "flex",
    justifyContent: "space-between",
  },
  carddetailtext: {
    fontSize: 24,
    color: "#E3E3E3",
    margin: 0,
  },
  addcardbutton: {
    color: "#2EBB55",
    fontSize: 16,
    fontWeight: 500,
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  carditemcontainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: 20,
  },
  cardinputgroup: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  inputtag: { fontSize: 16, color: "#AFAFAF", margin: 0, textAlign: "left" },
  inputelement: {
    backgroundColor: "#211D1E",
    color: "white",
    padding: "15px 16px",
    height: 48,
    border: "none",
    "&:focus": {
      outline: "none",
    },
  },
  cardnumber: {
    margin: 0,
    fontSize: 19,
    fontWeight: 700,
    color: "white",
    position: "absolute",
    top: 73,
  },
  circleImage: {
    position: "absolute",
    left: 233,
    top: 10,
  },
  expdate: {
    position: "absolute",
    left: 24,
    margin: 0,
    fontSize: 12,
    top: 20,
  },
  cardname: {
    margin: 0,
    fontSize: 12,
    fontWeight: 400,
    color: "white",
    position: "absolute",
    top: 105,
    left: 24,
  },
  modalpaper: {
    position: "absolute",
    width: 544,
    backgroundColor: "#141515",
    boxShadow: theme.shadows[5],
    padding: "40px 80px  80px 80px",
    borderRadius: 10,
    textAlign: "right",
  },
  addcardmodalpaper: {
    position: "absolute",
    width: 544,
    backgroundColor: "#141515",
    boxShadow: theme.shadows[5],
    padding: "20px 40px 60px 40px",
    borderRadius: 10,
    textAlign: "right",
  },
  modaltext: {
    color: "white",
    fontSize: 18,
    fontWeight: 400,
    margin: 0,
    textAlign: "center",
  },
  modaltest1: {
    color: "white",
    fontSize: 24,
    fontWeight: 400,
    margin: 0,
    textAlign: "center",
    fontFamily: "Poppins",
  },
  modaltext2: {
    fontSize: 18,
    fontWeight: 400,
    color: "#AFAFAF",
    textAlign: "center",
    margin: 0,
    fontFamily: "Poppins",
    paddingTop: 10,
  },
  modalimgcontainer: {
    gap: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  modalcloseimg: {
    cursor: "pointer",
  },
  modalbuttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 40,
  },
  modalonebuttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 40,
  },
  modalCancelbutton: {
    background: "none",
    border: "1px solid #FFFFFF",
    borderRadius: 5,
    fontSize: 18,
    fontWeight: 500,
    color: "white",
    padding: "10.5px 30px 10.5px 30px",
    cursor: "pointer",
    width: 180,
    height: 56,
  },
  modalDeletebutton: {
    background: "#2EBB55",
    color: "white",
    border: "none",
    borderRadius: 5,
    fontSize: 18,
    fontWeight: 500,
    padding: "14.5px 40px 14.5px 40px",
    cursor: "pointer",
    width: 180,
    height: 56,
  },
  priceText: {
    color: "#2EBB55",
    fontSize: 22,
  },
}));

export const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      fontWeight: 500,
      fontSize: "12px",
      fontSmoothing: "antialiased",
      color: "#fff",
      ":-webkit-autofill": { color: "red" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};

export const useTableStyles = makeStyles((theme) => ({
  root: {
    margin: 50,
    gap: 30,
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
    backgroundColor: "#141515",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
    backgroundColor: "white",
  },
  tableCell: {
    color: "#E3E3E3",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    borderBottom: "1px solid #424142",
  },
  tableContainer: {
    border: "none",
  },
  tablesortLabelIcon: {
    color: "white !important",
  },
  tablesortlabelactive: {
    color: "white",
  },
  useButton: {
    backgroundColor: "#2EBB55",
    fontSize: 16,
    lineHeight: 1.5,
    border: "none",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  modalpaper: {
    position: "absolute",
    width: 544,
    backgroundColor: "#141515",
    boxShadow: theme.shadows[5],
    padding: "40px 80px  80px 80px",
    borderRadius: 10,
    textAlign: "right",
  },
  modaltext: {
    color: "white",
    fontSize: 18,
    fontWeight: 400,
    margin: 0,
    textAlign: "center",
  },
  modaltest1: {
    color: "white",
    fontSize: 24,
    fontWeight: 400,
    margin: 0,
    textAlign: "center",
    fontFamily: "Poppins",
  },
  modaltext2: {
    fontSize: 18,
    fontWeight: 400,
    color: "#AFAFAF",
    textAlign: "center",
    margin: 0,
    fontFamily: "Poppins",
    paddingTop: 10,
  },
  modalimgcontainer: {
    gap: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "0 50px",
  },
  modalcloseimg: {
    cursor: "pointer",
  },
  modalbuttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 40,
  },
  modalCancelbutton: {
    background: "none",
    border: "1px solid #FFFFFF",
    borderRadius: 5,
    fontSize: 18,
    fontWeight: 500,
    color: "white",
    padding: "10.5px 30px 10.5px 30px",
    cursor: "pointer",
  },
  modalDeletebutton: {
    background: "#2EBB55",
    color: "white",
    border: "none",
    borderRadius: 5,
    fontSize: 18,
    fontWeight: 500,
    padding: "10.5px 30px 10.5px 30px",
    cursor: "pointer",
  },
}));

export const getTimeFromUTC = (str) => {
  const date = new Date(str + " UTC");
  const localTime = format(date, "MMM/dd/yyyy-h:mm a");
  return {
    date: localTime.split("-")[0],
    time: localTime.split("-")[1].toLocaleLowerCase(),
  };
};
