import { Box, Button } from "@mui/material";

import { styled } from "@mui/material/styles";

export const SolidButtonWrapper = styled(Button)(({ theme }) => ({
  borderRadius: "5px !important",
  fontSize: "18px",
  background: "#2EBB55 !important",
  padding: "10.5px 20px !important",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  textTransform: "capitalize",
  fontFamily: theme.typography.fontFamily,
  color: "#FFFFFF !important",

  "&:hover": {
    background: "#2EBB55 !important",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const GhostButtonWrapper = styled(Button)(({ theme }) => ({
  borderRadius: "5px !important",
  fontSize: "18px !important",
  background: "transparent",
  color: "#AFAFAF !important",
  padding: "10.5px 20px !important",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  border: "1px solid #AFAFAF !important",
  textTransform: "capitalize",
  fontFamily: theme.typography.fontFamily,

  "&:hover": {
    background: "transparent",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const InputWrapper = styled(Box)(({ theme }) => ({
  input: {
    width: "732px",
    height: "48px",
    margin: "18px 0 40px 0",
    background: "none",
    outline: "none",
    border: "1px solid #414241",
    borderRadius: "5px",
    padding: "9px 25px",
    color: "#AFAFAF",
  },
  [theme.breakpoints.down("md")]: {
    input: {
      width: "100%",
      padding: "5px 10px",
    },
  },
}));
