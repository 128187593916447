import cards_on_file from "../../../backend/dummy_data/credit_card_data";

import styles from "./settings_tabs.module.css";
import { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getMyBillingInfo } from "../../../Graphql_services/BillingMutation";
import {
  deletePaymentMethod,
  getMyPaymentMethod,
  defaultPaymentMethod,
} from "../../../Graphql_services/PaymentsMutation";
import { get, size } from "lodash";
import moment from "moment/moment";
import useAuth from "../../../context/AuthContext";
import { Loader } from "../../../components-old/Loader";
import AddCard from "./AddCard";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import trashIcon from "../../../assets/Icons/trash.png";
import DeletePopup from "../../Popups/DeletePopup";
import { Radio } from "@mui/material";
import { pink } from "@mui/material/colors";
const stripe = loadStripe(process.env.REACT_APP_PUBLISHERS_KEY);
//#001B8E
const CreditCard = ({
  card,
  setPaymentMethodId,
  handleDeletePopup,
  deletePopup,
  cancelHandler,
  defaultPaymentCardHandler,
  dafaultPopup,
  handleDefaultPopup,
}) => {
  const {
    user: { firstname, lastname },
  } = useAuth();

  const handleDeletecard = () => {
    if (card?.id) {
      setPaymentMethodId(card?.id);
      handleDeletePopup();
    }
  };
  const handleDefaultPaymentCard = () => {
    if (card?.id) {
      setPaymentMethodId(card?.id);
      handleDefaultPopup();
    }
  };
  return (
    <div className={styles.creditCardContainer}>
      <div className={styles.overlayShape}></div>
      <div className={styles.overlayShapeBefore} />
      <div className={styles.cardDetailsLeft}>
        <div className={styles.image_box}>
          <img
            className={styles.cardLogo}
            alt="card type"
            src="/v2Assets/credit_card_logos/visa_logo.png"
          />
        </div>
        <p className={styles.cardNumber}>•••• •••• •••• {card.card?.last4}</p>
        <div className={styles.cardInfo}>
          <p className={styles.infoTitle}>Name</p>
          <p className={styles.infoValue}>{`${firstname} ${lastname}`}</p>
        </div>
      </div>
      <div className={styles.cardDetailsRight}>
        <div className={styles.more_actions_wrapper}>
          <div className={styles.trash_icon_wrapper}>
            <img
              className={styles.trash_icon}
              alt="delete"
              src={trashIcon}
              onClick={handleDeletecard}
            />
            <DeletePopup
              open={deletePopup}
              title="Delete Card"
              content="Do you really want to delete this card?"
              label1="Yes"
              label2="No"
              handler={cancelHandler}
            />
          </div>
          <div>
            <Radio
              checked={card.isDefaultPaymentMethod ? true : false}
              onClick={handleDefaultPaymentCard}
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
            />
            <DeletePopup
              open={dafaultPopup}
              title="Set Default Payment Card"
              content="Do you want to set this card as default payment card?"
              label1="Yes"
              label2="No"
              handler={defaultPaymentCardHandler}
            />
          </div>
        </div>
        <div className={styles.cardInfoRight}>
          <p className={styles.infoTitle}>Expiry Date</p>
          <p className={styles.infoValue}>
            {card?.card?.exp_month < 10 ? 0 : ""}
            {card?.card?.exp_month}/{card?.card?.exp_year}
          </p>
        </div>
      </div>
    </div>
  );
};

const AddPayment = () => {
  return (
    <div className={styles.addPayment}>
      <div className={styles.addPaymentIcon}>
        {/* <PlusIcon className={styles.icon} /> */}
      </div>
      <p className={styles.addPaymentText}>Add payment method</p>
    </div>
  );
};

const Table = ({ paymentHistory = [] }) => {
  const data = useMemo(() => paymentHistory, [paymentHistory]);

  const [pageIndex, setPageIndex] = useState(0);

  const itemsPerPage = 5;
  const startIndex = pageIndex * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex < pageCount - 1;

  const nextPage = () => {
    if (canNextPage) setPageIndex((old) => old + 1);
  };

  const previousPage = () => {
    if (canPreviousPage) setPageIndex((old) => old - 1);
  };

  const pagedData = data.slice(startIndex, endIndex);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        Cell: (props) => {
          console.info("serial number =>", props); // the value is 'this is a test'
          return <span>{props.cell.row.index + 1}</span>;
        },
      },
      {
        Header: "Transaction ID",
        accessor: "id",
      },
      {
        Header: "Date",
        accessor: "created",
        Cell: (props) => {
          console.info("props.cell.value => ", props.cell.value);

          console.info("props =>"); // the value is 'this is a test'
          return (
            <span>
              {moment(new Date(props.cell.value * 1000)).format("lll")}
            </span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Amount",
        accessor: "amount_due",
        Cell: (props) => {
          console.info("props =>"); // the value is 'this is a test'
          return <span>${props.cell.value / 100}</span>;
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data: pagedData,
    });

  return (
    <div>
      <div className={styles.tableContainer}>
        <table {...getTableProps()} className={styles.table}>
          <thead className={styles.tableHeader}>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, colIndex) => (
                  <th
                    {...column.getHeaderProps()}
                    className={styles.tableHeading}
                    key={colIndex}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className={styles.tableBody}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell, cellIndex) => {
                    console.info("cell => ", cell);

                    return (
                      <td
                        {...cell.getCellProps()}
                        className={styles.tableData}
                        key={cellIndex}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={styles.pagination}>
        {canPreviousPage && (
          <button
            onClick={previousPage}
            disabled={!canPreviousPage}
            className={`${styles.btn} ${styles.prevBtn}`}
          >
            Prev
          </button>
        )}
        {canNextPage && (
          <button
            className={`${styles.btn} ${styles.nextBtn}`}
            onClick={nextPage}
            disabled={!canNextPage}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default function PaymentSettings() {
  const [cards, setCards] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isLoadingPaymentHistory, setIsLoadingPaymentHistory] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);
  const [dafaultPopup, setDefaultPopup] = useState(false);

  const handleDeletePopup = () => {
    setDeletePopup(true);
  };

  const handleDeleteClosePopup = () => {
    setDeletePopup(false);
  };

  const handleDefaultPopup = () => {
    setDefaultPopup(true);
  };

  const handleDefaultClosePopup = () => {
    setDefaultPopup(false);
  };

  const cancelHandler = (value) => {
    if (value === "yes") {
      setIsLoadingCard(true);
      paymentMethodId && deleteCard();
      handleDeleteClosePopup();
    } else {
      handleDeleteClosePopup();
    }
  };
  const defaultPaymentCardHandler = (value) => {
    if (value === "yes") {
      setIsLoadingCard(true);
      paymentMethodId && AddDefaultPaymentMethod();
      handleDefaultClosePopup();
    } else {
      handleDefaultClosePopup();
    }
  };

  const [getBilling] = useLazyQuery(getMyBillingInfo, {
    context: { clientName: "user" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      console.info("getMyBillingInfo", data);
      const { getMyBillingInformations } = data;
      if (get(getMyBillingInformations, "invoices", [])) {
        setPaymentHistory(get(getMyBillingInformations, "invoices", []));
      } else {
        setPaymentHistory([]);
      }
      setIsLoadingCard(false);
    },
    onError(err) {
      console.info("error on getting getMyBillingInfo", err);
      setPaymentHistory([]);
      setIsLoadingCard(false);
    },
  });

  const [getMyPayment] = useLazyQuery(getMyPaymentMethod, {
    context: { clientName: "user" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      console.info("datasetCards => ", data);
      const { getMyPaymentMethods } = data;

      if (get(getMyPaymentMethods, "paymentMethods", []).length) {
        setCards(
          getMyPaymentMethods.paymentMethods.filter(
            ({ type }) => type === "card"
          )
        );
        setIsLoadingCard(false);
      } else {
        setCards([]);
      }
      setIsLoadingPaymentHistory(false);
    },
    onError(err) {
      console.log("error on getting getMyPaymentMethod", err);
      setIsLoadingPaymentHistory(false);
    },
  });

  const [deleteCard] = useMutation(deletePaymentMethod, {
    context: { clientName: "user" },
    variables: {
      paymentMethodId: paymentMethodId,
    },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data?.deletePaymentMethod?.success == true) {
        toast.success("Card deleted successfully");
        getMyPayment();
      }
      if (data?.deletePaymentMethod?.success == false) {
        toast.error(data?.deletePaymentMethod?.message);
        setIsLoadingCard(false);
      }
    },
    onError(err) {
      console.log("error on  DeletePaymentMethod", err);
    },
  });

  const [AddDefaultPaymentMethod] = useMutation(defaultPaymentMethod, {
    context: { clientName: "user" },
    variables: {
      paymentMethodId: paymentMethodId,
    },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data?.setDefaultPaymentMethod?.success == true) {
        toast.success("Card set as default successfully");
        getMyPayment();
      }
      if (data?.setDefaultPaymentMethod?.success == false) {
        toast.error(data?.setDefaultPaymentMethod?.message);
      }
    },
    onError(err) {
      console.log("error on  AddDefaultPaymentMethod", err);
    },
  });

  useEffect(() => {
    setIsLoadingCard(true);
    setIsLoadingPaymentHistory(true);
    getBilling();
    getMyPayment();
  }, []);

  console.info("paymentHistory => ", paymentHistory);

  console.info("cards => ", cards);
  return (
    <>
      {isLoadingCard || isLoadingPaymentHistory ? (
        <div className={styles.centerDiv}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.main_layout}>
            <div className={styles.credit_cards_container}>
              {size(cards) > 0 ? (
                cards.map((card, index) => (
                  <CreditCard
                    key={index}
                    card={card}
                    setPaymentMethodId={setPaymentMethodId}
                    handleDeletePopup={handleDeletePopup}
                    deletePopup={deletePopup}
                    dafaultPopup={dafaultPopup}
                    handleDefaultPopup={handleDefaultPopup}
                    cancelHandler={cancelHandler}
                    defaultPaymentCardHandler={defaultPaymentCardHandler}
                  />
                ))
              ) : (
                <div className={styles.centerDiv}>No Cards</div>
              )}
            </div>
            <Elements stripe={stripe}>
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <AddCard
                    stripe={stripe}
                    elements={elements}
                    getMyPayment={getMyPayment}
                  />
                )}
              </ElementsConsumer>
            </Elements>
          </div>
          <div className={styles.container}>
            <h1 className={styles.title}>Billing History</h1>
            {size(paymentHistory) > 0 ? (
              <Table paymentHistory={paymentHistory} />
            ) : (
              <div className={styles.centerDiv}>No Payment History</div>
            )}
          </div>
        </>
      )}
    </>
  );
}
