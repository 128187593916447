import React, { useState, useCallback, useEffect } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { get } from "lodash";

import PageNavigationControls from "../../UniversalComponents/page_navigation_controls";
import useAuth from "../../../context/AuthContext";
import { ConfirmDeletePopup } from "../../ScreenPopups/ConfirmDeletePopup";
import AudioPlayer from "../../UniversalComponents/audio_player";
import useAppState from "../../../context/useAppState";

import CheckboxWithLabel from "../AdditionalComponents/Checkbox/CheckboxWithLabel";
import SyncSchema from "../YupSchema/SyncSchema";
import CircularLoader from "../../UniversalComponents/CircularLoader/CircularLoader";

import styles from "./submit_content.module.css";
import {
  GENRE,
  KEY,
  LOOKING_FOR_SYNC,
  SYNC_MEDIA_TYPE,
  SCALE,
} from "../../../shared/constants";
import moment from "moment";
import { Tooltip } from "@mui/material";

function CreateSync({ isEdit }) {
  const {
    user: { _id },
  } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();

  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const {
    data,
    saveOpportunity,
    resetOpportunity,
    updateOpportunity,
    updateOpportunityState,
    fetchOpportunityById,
  } = useAppState("sync");
  const { uploadFile, uploadAudio, uploadDocument } = useAppState("file");

  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      title: "",
      mediaType: "",
      companyName: "",
      projectName: "",
      description: "",
      lookingFor: "",
      expireDate: "",
      genre: "",
      bpm: "",
      key: "",
      scale: "",
      coverPhoto: null,
      hasReferenceTrack: false,
      referenceTrack: null,
      referanceSpotifyLink: "",
      manual: null,
      hasManual: false,
      userId: "",
      postedBySelf: true,
      postedByName: "",
      postedByImage: null,
      isGuidelines: false,
      guidelines: "",
    },
    resolver: yupResolver(SyncSchema),
    reValidateMode: "onSubmit",
    mode: "onChange",
  });

  useEffect(() => {
    if (isEdit && id) {
      fetchOpportunityById(id);
    }
  }, [isEdit, id]);

  useEffect(() => {
    const fields = [
      "title",
      "mediaType",
      "companyName",
      "projectName",
      "description",
      "lookingFor",
      "expireDate",
      "genre",
      "bpm",
      "key",
      "scale",
      "coverPhoto",
      "hasReferenceTrack",
      "referenceTrack",
      "referanceSpotifyLink",
      "manual",
      "hasManual",
      "postedBySelf",
      "postedByName",
      "postedByImage",
      "userId",
      "isGuidelines",
      "guidelines",
    ];

    // set data to hook form

    fields.forEach((key) => {
      setValue(key, data[key]);
    });
  }, [data]);

  useEffect(() => {
    return () => {
      resetOpportunity();
    };
  }, []);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0] || null;
    const profileData = new FormData();

    if (file) {
      setIsLoadingImage(true);
      profileData.append("file", file);
      profileData.append("type", "Public");

      try {
        const { fileLocation } = await uploadFile(profileData);
        setValue("coverPhoto", {
          name: file.name,
          url: fileLocation,
        });
      } catch (error) {
        toast.error("Something went wrong with file upload, please try again");
      } finally {
        setIsLoadingImage(false);
      }
    }
  };

  const ImageDropzone = useDropzone({
    multiple: false,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    onDrop,
  });

  const onDropPostedByPic = async (acceptedFiles) => {
    const file = acceptedFiles[0] || null;
    const profileData = new FormData();

    if (file) {
      setIsLoadingImage(true);
      profileData.append("file", file);
      profileData.append("type", "Public");

      try {
        const { fileLocation } = await uploadFile(profileData);
        setValue("postedByImage", {
          name: file.name,
          url: fileLocation,
        });
      } catch (error) {
        toast.error("Something went wrong with file upload, please try again");
      } finally {
        setIsLoadingImage(false);
      }
    }
  };

  const PostedByPicDropzone = useDropzone({
    multiple: false,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    onDrop: (acceptedFiles) => onDropPostedByPic(acceptedFiles),
  });

  const onDropDocument = async (acceptedFiles) => {
    const file = acceptedFiles[0] || null;
    const documentData = new FormData();

    if (file) {
      setIsLoadingDocument(true);
      documentData.append("file", file);
      documentData.append("type", "Public");

      try {
        const { fileLocation } = await uploadDocument(documentData);
        setValue("manual", {
          name: file.name,
          url: fileLocation,
        });
      } catch (error) {
        toast.error(
          "Something went wrong with the document upload, please try again"
        );
      } finally {
        setIsLoadingDocument(false);
      }
    }
  };

  const DocumentDropzone = useDropzone({
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    onDrop: (acceptedFiles) => onDropDocument(acceptedFiles),
  });

  console.info("errors => ", errors);

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      userId: _id,
    };

    try {
      if (isEdit) {
        try {
          delete payload.userId;
          const result = await updateOpportunity(id, payload);

          if (result) {
            toast.success("Opportunity Updated Successfully!");

            setTimeout(() => {
              console.info("pathname => ", pathname);

              if (pathname.includes("/admin")) {
                navigate("/adminpanel/opportunities");
              } else {
                navigate("/sync");
              }
            }, 0);
          }
        } catch (error) {
          console.info("error while updating opp => ", error);
        }
      } else {
        try {
          const result = await saveOpportunity(payload);
          navigate("/sync");
        } catch (error) {
          console.info("error while saving opp => ", error);
        }
      }
    } catch (error) {
      console.info("@onSubmit error while saving opportunity => ", error);
    }
  };

  const onDropAudio = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0] || null;

    const payload = new FormData();

    if (file) {
      setIsLoadingAudio(true);
      payload.append("file", file);
      payload.append("type", "Public");

      try {
        const { fileLocation, audioWaveFileLocation } =
          await uploadAudio(payload);
        setValue("referenceTrack", {
          name: file.name,
          url: fileLocation,
          waveUrl: audioWaveFileLocation,
        });
      } catch (error) {
        console.info("error while saving file => ");
        toast.error("Something went wrong with file upload, please try again");
      } finally {
        setIsLoadingAudio(false);
      }
    }
  }, []);

  const TrackDropzone = useDropzone({
    onDrop: onDropAudio,
  });

  const {
    hasReferenceTrack,
    hasManual,
    manual,
    isGuidelines,
    referenceTrack,
    coverPhoto,
    postedByImage,
    postedBySelf = true,
  } = watch();

  return (
    <div className={styles.main_layout}>
      <div className={styles.top_info}>
        <PageNavigationControls
          onBack={() => {
            if (pathname.includes("/admin")) {
              navigate("/adminpanel/opportunities");
            } else {
              navigate("/opportunities");
            }
          }}
        />
        <p className={styles.page_title}>
          {isEdit ? "Edit" : "Create"} A Sync Placement
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.submit_page_header}>
          {isLoadingImage ? (
            <div className={styles.image_loading}>
              <CircularLoader />
            </div>
          ) : (
            <div className={styles.image_layout}>
              {!coverPhoto ? (
                <div className={styles.drag_image}>
                  <>
                    <div
                      {...ImageDropzone.getRootProps()}
                      className={styles.audioDropzone}
                    >
                      <img
                        src="/fileUpload.png"
                        alt="upload"
                        className={styles.drag_track_image}
                      ></img>
                      <p className={styles.drag_track_text}>
                        Drag or Upload Image
                      </p>
                      <p className={styles.drag_track_text_browse}>
                        Browse Files
                      </p>

                      <input
                        {...ImageDropzone.getInputProps()}
                        id="uploadImage"
                        accept="image/*"
                      />
                      {get(errors, `coverPhoto.message`, "") && (
                        <p className={styles.error_text}>
                          {get(errors, `coverPhoto.message`, "")}
                        </p>
                      )}
                    </div>
                  </>
                </div>
              ) : (
                <div className={styles.chosen_file}>
                  <DisplayCoverImage
                    fileName={coverPhoto.name}
                    file={coverPhoto.url}
                    type="image"
                    setDeletePopup={(data) => setDeletePopup(data)}
                    setDeleteName={() => setDeleteType("image")}
                    desc={"Are you sure you want to delete?"}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <LineDivider />

        <div className={styles.account_info_container}>
          {/* title */}
          <Controller
            control={control}
            name="title"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Title"
                  content={value}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/*Media Type*/}
          <Controller
            control={control}
            name="mediaType"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Media Type"
                  content={value}
                  dropdown={true}
                  dropDownData={SYNC_MEDIA_TYPE}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Are you looking for? */}
          <Controller
            control={control}
            name="lookingFor"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Are you looking for?"
                  content={value}
                  dropdown={true}
                  dropDownData={LOOKING_FOR_SYNC}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />
          {/* Company Name */}
          <Controller
            control={control}
            name="companyName"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Company Name"
                  content={value}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Project Name */}
          <Controller
            control={control}
            name="projectName"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Project Name (optional)"
                  content={value}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />
        </div>

        <LineDivider />
        <div className={styles.account_info_container}>
          {/* Genre */}
          <Controller
            control={control}
            name="genre"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Genre"
                  content={value}
                  dropdown={true}
                  dropDownData={GENRE}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Key */}
          <Controller
            control={control}
            name="key"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Key (optional)"
                  content={value}
                  dropdown={true}
                  dropDownData={KEY}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Scale */}
          <Controller
            control={control}
            name="scale"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Scale (optional)"
                  content={value}
                  dropdown={true}
                  dropDownData={SCALE}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* BPM */}
          <Controller
            control={control}
            name="bpm"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="BPM (optional)"
                  content={value}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />
        </div>

        <LineDivider />
        <div className={styles.account_info_container}>
          {/* Expiration Date (DD/MM/YYYY) */}
          <Controller
            control={control}
            name="expireDate"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Expiration Date (DD/MM/YYYY)"
                  content={moment(value).format("YYYY-MM-DD")}
                  type="date"
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />
        </div>

        <LineDivider />

        {/* Description */}
        <Controller
          control={control}
          name="description"
          render={({ field: { name, value }, formState }) => {
            return (
              <FormField
                error={get(formState, `errors[${name}].message`, "")}
                title="Description"
                textArea={true}
                content={value}
                onValueChange={(value) =>
                  setValue(name, value, { shouldValidate: true })
                }
              />
            );
          }}
        />

        <LineDivider />
        <Controller
          control={control}
          name="postedBySelf"
          render={({ field: { name, value } }) => {
            return (
              <CheckboxWithLabel
                title="Post from current account"
                value={value}
                handler={({ payload }) =>
                  setValue(name, payload, { shouldValidate: true })
                }
                action="postedBySelf"
              />
            );
          }}
        />

        <div>
          {!postedBySelf && (
            <div className={styles.posted_by_info}>
              {isLoadingImage ? (
                <div className={styles.a}>
                  <CircularLoader />
                </div>
              ) : (
                <div className={styles.a}>
                  {!postedByImage ? (
                    <div className={styles.posted_by}>
                      <>
                        <div
                          {...PostedByPicDropzone.getRootProps()}
                          className={styles.audioDropzone}
                        >
                          <div className={styles.posted_by}>
                            <p className={styles.posted_by_title}>
                              Posted By Image
                            </p>
                            <div className={styles.posted_by_image_section}>
                              <div className={styles.posted_by_image}>
                                <img
                                  src="/fileUpload.png"
                                  alt="upload"
                                  className={styles.add_image}
                                ></img>
                              </div>
                              <div className={styles.add_image_button}>
                                Browse Files
                              </div>
                            </div>
                          </div>

                          <input
                            {...PostedByPicDropzone.getInputProps()}
                            id="uploadImage"
                            accept="image/*"
                          />
                          {get(errors, `coverPhoto.message`, "") && (
                            <p className={styles.error_text}>
                              {get(errors, `coverPhoto.message`, "")}
                            </p>
                          )}
                        </div>
                      </>
                    </div>
                  ) : (
                    <div className={styles.posted_by}>
                      <>
                        <div
                          {...PostedByPicDropzone.getRootProps()}
                          className={styles.audioDropzone}
                        >
                          <div className={styles.posted_by}>
                            <p className={styles.posted_by_title}>
                              Posted By Image
                            </p>
                            <div className={styles.posted_by_image_section}>
                              <div className={styles.posted_by_image}>
                                <div className={styles.display_image_wrapper}>
                                  <img
                                    src={postedByImage.url}
                                    alt="file"
                                    className={styles.posted_by_image_cover}
                                  ></img>
                                </div>
                              </div>
                              <div className={styles.add_image_button}>
                                Browse Files
                              </div>
                            </div>
                          </div>

                          <input
                            {...PostedByPicDropzone.getInputProps()}
                            id="uploadImage"
                            accept="image/*"
                          />
                          {get(errors, `postedByImage.message`, "") && (
                            <p className={styles.error_text}>
                              {get(errors, `postedByImage.message`, "")}
                            </p>
                          )}
                        </div>
                      </>
                    </div>
                  )}
                </div>
              )}
              <div className={styles.posted_by_textbox}>
                <Controller
                  control={control}
                  name="postedByName"
                  render={({ field: { name, value }, formState }) => {
                    return (
                      <FormField
                        error={get(formState, `errors[${name}].message`, "")}
                        title="Posted By"
                        content={value}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <LineDivider />

        <Controller
          control={control}
          name="isGuidelines"
          render={({ field: { name, value } }) => {
            return (
              <CheckboxWithLabel
                title="Guidelines"
                value={value}
                handler={({ payload }) =>
                  setValue(name, payload, { shouldValidate: true })
                }
                action="isGuidelines"
              />
            );
          }}
        />

        <div>
          {isGuidelines && (
            <div className={styles.account_info_container_single_margin}>
              <Controller
                control={control}
                name="guidelines"
                render={({
                  field: { name, onBlur, onChange, value },
                  formState,
                }) => {
                  return (
                    <FormField
                      error={get(formState, `errors[${name}].message`, "")}
                      title="Guidelines"
                      textArea={true}
                      content={value}
                      onValueChange={(value) =>
                        setValue(name, value, { shouldValidate: true })
                      }
                    />
                  );
                }}
              />
            </div>
          )}
        </div>

        <LineDivider />

        {/* Reference Track */}
        <Controller
          control={control}
          name="hasReferenceTrack"
          render={({ field: { name, value } }) => {
            return (
              <CheckboxWithLabel
                title="Reference Track"
                value={value}
                handler={({ payload }) =>
                  setValue(name, payload, { shouldValidate: true })
                }
                action="hasReferenceTrack"
              />
            );
          }}
        />

        <div>
          {hasReferenceTrack && (
            <>
              {isLoadingAudio ? (
                <div className={styles.track_layout}>
                  <div className={styles.drag_image}>
                    <CircularLoader />
                  </div>
                </div>
              ) : (
                <div className={styles.track_layout}>
                  {!referenceTrack ? (
                    <div className={styles.drag_image}>
                      <>
                        <div
                          {...TrackDropzone.getRootProps()}
                          className={styles.audioDropzone}
                        >
                          <img
                            src="/fileUpload.png"
                            alt="upload"
                            className={styles.drag_track_image}
                          ></img>
                          <p className={styles.drag_track_text}>
                            Upload Reference Track
                          </p>
                          <p className={styles.drag_track_text_browse}>
                            Browse Files
                          </p>

                          <input
                            {...TrackDropzone.getInputProps()}
                            id="uploadAudio"
                          />
                          {get(errors, `coverPhoto.message`, "") && (
                            <p className={styles.error_text}>
                              {get(errors, `coverPhoto.message`, "")}
                            </p>
                          )}
                        </div>
                      </>
                    </div>
                  ) : (
                    <div className={styles.chosen_file}>
                      <DisplayReferanceTrack
                        fileName={referenceTrack?.name}
                        file={referenceTrack?.url}
                        setDeletePopup={(data) => setDeletePopup(data)}
                        setDeleteName={() => setDeleteType("audio")}
                        desc={"Are you sure you want to delete?"}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className={styles.spacer}></div>
              <Controller
                control={control}
                name="referanceSpotifyLink"
                render={({ field: { name, value }, formState }) => {
                  return (
                    <FormField
                      error={get(formState, `errors[${name}].message`, "")}
                      title="Referance Spotify Link"
                      content={value}
                      onValueChange={(value) =>
                        setValue(name, value, { shouldValidate: true })
                      }
                    />
                  );
                }}
              />
            </>
          )}
        </div>

        <LineDivider />

        {/* Manual */}
        <Controller
          control={control}
          name="hasManual"
          render={({ field: { name, value } }) => {
            return (
              <CheckboxWithLabel
                title="Sync Brief"
                value={value}
                handler={({ payload }) =>
                  setValue(name, payload, { shouldValidate: true })
                }
                action="hasManual"
              />
            );
          }}
        />

        <div>
          {hasManual && (
            <div>
              {isLoadingDocument ? (
                <div className={styles.document_layout}>
                  <CircularLoader />
                </div>
              ) : (
                <div className={styles.document_layout}>
                  {!manual ? (
                    <div className={styles.drag_image}>
                      <>
                        <div
                          {...DocumentDropzone.getRootProps()}
                          className={styles.audioDropzone}
                        >
                          <img
                            src="/fileUpload.png"
                            alt="upload"
                            className={styles.drag_track_image}
                          ></img>
                          <p className={styles.drag_track_text}>
                            Drag or Upload Document
                          </p>
                          <p className={styles.drag_track_text_browse}>
                            Browse Files
                          </p>

                          <input
                            {...DocumentDropzone.getInputProps()}
                            id="uploadAudio"
                          />
                          {get(errors, `manual.message`, "") && (
                            <p className={styles.error_text}>
                              {get(errors, `manual.message`, "")}
                            </p>
                          )}
                        </div>
                      </>
                    </div>
                  ) : (
                    <div className={styles.chosen_document}>
                      <DisplayDocument
                        fileName={manual?.name}
                        file={manual?.url}
                        setDeletePopup={(data) => setDeletePopup(data)}
                        setDeleteName={() => setDeleteType("document")}
                        desc={"Are you sure you want to delete?"}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <LineDivider />
        <div className={styles.form_buttons}>
          <div
            className={styles.cancel_button}
            onClick={() => navigate("/sync")}
          >
            <p className={styles.button_text}>Cancel</p>
          </div>
          <div className={styles.submit_button}>
            <button className={styles.button_text} disabled={isSubmitting}>
              {isEdit ? "Update" : "Publish"}
            </button>
          </div>
        </div>
      </form>
      <div className={styles.line_divider_invisible}></div>
      <ConfirmDeletePopup
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        deleteFtn={() => {
          if (deleteType === "audio") {
            setValue("referenceTrack", null);
          } else if (deleteType === "image") {
            setValue("coverPhoto", null);
          } else {
            setValue("manual", null);
          }
        }}
        desc={"Are you sure you want to delete?"}
      />
    </div>
  );
}

const FormField = ({
  title,
  type = "text",
  error = "",
  textArea = false,
  name,
  info,
  content,
  dropdown = false,
  dropDownData,
  onValueChange,
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleOptionClick = (option) => {
    onValueChange(option);
    toggleDropdown();
  };

  const handleInputChange = (event) => {
    onValueChange(event.target.value);
  };

  const inputBoxStyle = textArea
    ? styles.input_box_text_area
    : styles.input_box;
  const inputStyle = textArea ? styles.input_text_area : styles.input_text;

  return (
    <div className={styles.user_info_layout}>
      <div className={styles.field_info}>
        <p
          className={
            title === "Posted By"
              ? styles.posted_by_title
              : styles.user_info_title
          }
        >
          {title}
        </p>

        {info && (
          <Tooltip title={info} arrow>
            <img
              src="/v2Assets/submission_form_assets/moreInfoIcon.png"
              alt="info"
              className={styles.infoImage}
            />
          </Tooltip>
        )}
      </div>
      <div className={inputBoxStyle}>
        {textArea ? (
          <textarea
            name={name}
            className={styles.input_text_area}
            value={content}
            onClick={() => {
              dropdown && toggleDropdown();
            }}
            onChange={(e) => !dropdown && handleInputChange(e)}
            type={type}
          />
        ) : (
          <input
            name={name}
            className={styles.input_text}
            value={content}
            onClick={() => {
              dropdown && toggleDropdown();
            }}
            onChange={(e) => !dropdown && handleInputChange(e)}
            type={type}
          />
        )}
        {dropdown && (
          <div
            className={styles.input_box_dropdown_button}
            onClick={toggleDropdown}
          >
            <div className={styles.dropdown_green_bg}>
              <img
                src="/v2Assets/submission_form_assets/DropDownArrow.png"
                alt="options"
                className={styles.drop_down_image}
              />
              {isDropdownVisible && (
                <div className={styles.dropdown_menu}>
                  {dropDownData?.map((option, i) => (
                    <div
                      key={i}
                      className={styles.dropdown_menu_option_box}
                      onClick={() => handleOptionClick(option)}
                    >
                      <p className={styles.dropdown_menu_option}>{option}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {error && <p className={styles.error_text}>{error}</p>}
    </div>
  );
};

const LineDivider = () => {
  return <div className={styles.line_divider}></div>;
};

function DisplayCoverImage(props) {
  const { fileName, setDeletePopup, setDeleteName, type, file } = props;
  return (
    <div className={styles.display_file_wrapper}>
      <img
        src="/deleteOptionEx.png"
        alt="Check"
        className={styles.delete_icon_corner}
        onClick={() => {
          setDeletePopup(true);
          setDeleteName(fileName);
        }}
      />

      <img src={file} alt="file" className={styles.cover_file_image}></img>
    </div>
  );
}

function DisplayReferanceTrack(props) {
  const [isReferanceHovered, setIsReferanceHovered] = useState(false);
  const { fileName, setDeletePopup, setDeleteName, type, file } = props;
  return (
    <div
      className={
        isReferanceHovered
          ? `${styles.file_display} ${styles.hovered}`
          : styles.file_display
      }
    >
      <img
        src="/deleteOptionEx.png"
        alt="Check"
        className={styles.delete_icon_corner}
        onMouseEnter={() => setIsReferanceHovered(true)}
        onMouseLeave={() => setIsReferanceHovered(false)}
        onClick={() => {
          setDeletePopup(true);
          setDeleteName(fileName);
        }}
      />
      <p className={styles.file_title}>{fileName}</p>

      <AudioPlayer src={file} />
    </div>
  );
}

function DisplayDocument(props) {
  const [isReferanceHovered, setIsReferanceHovered] = useState(false);
  const { fileName, setDeletePopup, setDeleteName, type, file } = props;

  function openDocument() {
    window.open(file, "_blank").focus();
  }

  return (
    <div
      className={
        isReferanceHovered
          ? `${styles.document_display} ${styles.hovered}`
          : styles.document_display
      }
    >
      <img
        src="/deleteOptionEx.png"
        alt="Check"
        className={styles.delete_icon_corner}
        onMouseEnter={() => setIsReferanceHovered(true)}
        onMouseLeave={() => setIsReferanceHovered(false)}
        onClick={() => {
          setDeletePopup(true);
          setDeleteName(fileName);
        }}
      />
      <p className={styles.document_file_title} onClick={openDocument}>
        {fileName}
      </p>
    </div>
  );
}

export default CreateSync;
