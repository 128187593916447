import React from "react";
import styles from "../../auth_pages.module.css";
import { useState } from "react";
import { FormField } from "../../signup_flow/FormInput";
import CircularProgress from "@mui/material/CircularProgress";
import { ResetPasswordSignIn } from "../../../../Graphql_services/SignUpMutation";
import { useMutation } from "@apollo/client";
import { Notification } from "../../../NotifyMsg/Notification";
import { useNavigate } from "react-router-dom";

const SetNewPasswordForm = ({ onBack, onNext, user, completeAccount }) => {
  const navigate = useNavigate();
  const [resetPasswordSignInMutation, { loading, error }] =
    useMutation(ResetPasswordSignIn);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const [validationChecker, setValidationChecker] = useState({
    passwordValidationMessage: "",
    rePasswordValidationMessage: "",
  });

  const handlePassword = (value) => {
    if (value.length < 8) {
      setValidationChecker({
        passwordValidationMessage: "Password Must Be 8 Character Long",
      });
    } else {
      setValidationChecker({ passwordValidationMessage: "" });
      setPassword(value);
    }
  };

  const handleRePassword = (value) => {
    if (value !== password) {
      setValidationChecker({
        rePasswordValidationMessage: "Password do not match",
      });
    } else {
      setValidationChecker({ rePasswordValidationMessage: "" });
      setRePassword(value);
    }
  };

  const canContinue =
    password !== "" && rePassword !== "" && password === rePassword;

  const HandelEmailChecker = async () => {
    const otp = user?.profile?.otp;
    const variables = {
      password,
      confirmPassword: rePassword,
      otp,
    };
    try {
      const response = await resetPasswordSignInMutation({
        variables: variables,
      });
      if (!response?.data?.resetPassword.success) {
        Notification("error", response?.data?.resetPassword.message);
        return;
      } else {
        Notification("success", response?.data?.resetPassword.message);
        window.location.href = "/signin";
      }
    } catch (error) {
      Notification(
        "error",
        error.message || "something went wrong please try again"
      );
    }
  };

  return (
    <div className={styles.main_layout}>
      <div className={styles.shadow_overlay}>
        <div
          className={
            completeAccount ? styles.complete_account_box : styles.auth_box
          }
        >
          <div className={styles.auth_box_content}>
            <div className={styles.auth_box_header}>
              <p className={styles.auth_title}>
                Get Registered On United Market
              </p>
              <p className={styles.auth_subtitle}> Set New Password </p>
            </div>
            <div className={styles.input_list}>
              <FormField
                title="Password"
                onValueChange={(value) => handlePassword(value)}
                required={validationChecker.passwordValidationMessage}
                type="password"
              />
              <FormField
                title="Confirm Password"
                onValueChange={(value) => handleRePassword(value)}
                required={validationChecker.rePasswordValidationMessage}
                type="password"
              />
            </div>
          </div>

          <div className={styles.buttonControls}>
            {canContinue ? (
              <div className={styles.signin_box} onClick={HandelEmailChecker}>
                <p className={styles.connect_text}>
                  {loading ? (
                    <CircularProgress size={25} sx={{ color: "white" }} />
                  ) : (
                    "Continue"
                  )}
                </p>
              </div>
            ) : (
              <div className={styles.signin_box_inactive}>
                <p className={styles.connect_text}>Continue</p>
              </div>
            )}
            <p className={styles.previous_text} onClick={onBack}>
              Previous Step
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNewPasswordForm;
