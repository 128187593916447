import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SelectMain } from './SelectStyles';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { Controller } from 'react-hook-form';

// Define your GraphQL query
const GET_ROLES = gql`
  query {
    getRoles {
      _id
      roleName
    }
  }
`;

const SelectDropDown = ({
  //   name,
  //   control,
  //   label,
  //   placeholder,
  //   errors,
  setValue,
  role,
  setRole,
  //   isFetching,
  getValues,
}) => {
  const [options, setOptions] = React.useState([]);
  //   const [role, setRole] = React.useState({});
  const accountType = localStorage.getItem('accountType');
  const [getRoles, { loading, error, data }] = useLazyQuery(GET_ROLES, {
    onCompleted: (data) => {
      console.log('Query completed', data);
      if (data?.getRoles) {
        // const filteredRolesArray = data.getRoles.map((role) => role.roleName);
        if (accountType == 'Admin') {
          const filteredOption = data?.getRoles.filter(
            (role) => role.roleName != 'N/A'
          );
          setOptions(filteredOption);
        } else {
          const filteredOption = data?.getRoles.filter(
            (role) =>
              role.roleName.toLowerCase() !== 'admin' && role.roleName != 'N/A'
          );
          setOptions(filteredOption);
        }
      }
    },
    onError: (error) => {
      console.error('Query error', error);
      // You can handle errors here
    },
  });

  React.useEffect(() => {
    getRoles();
  }, []);

  const handleChange = (event) => {
    setValue('roleId', event.target.value); // Update the role value using setValue
    let roleobj =
      options.find((role) => role.roleName === event.target.value) || null;
    setRole(roleobj);
  };

  return (
    // <Controller
    //   control={control}
    //   name={name}
    //   render={({ field }) => (
    <SelectMain>
      <FormControl fullWidth>
        <Select
          //   {...field}
          //   name={name}
          MenuProps={{
            MenuListProps: {
              sx: {
                'li.MuiButtonBase-root': {
                  display: 'flex',
                  flexDirection: 'column',
                },
              },
            },
          }}
          className='select-box'
          id='demo-simple-select'
          value={getValues('roleId').roleName || getValues('roleId')}
          onChange={handleChange}
        >
          {options.map((option, id) => (
            <MenuItem value={option.roleName} key={option?._id}>
              {option?.roleName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SelectMain>
  );
  //       )}
  //     />
  //   );
};
export default SelectDropDown;
