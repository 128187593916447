import { gql } from '@apollo/client';

export const getAllTutorial = gql`
  query {
    getAllTutorial {
      message
      success
      tutorials {
        title
        description
        videoUrl
        createdAt
        updatedAt
      }
    }
  }
`;
