import React from "react";
import { CONTRACT_DASHBOARD_ICON } from "../../../shared/constants";
import styles from "../mycontracts.module.css";

export default function ContractItem({
  iconName,
  title,
  isActive,
  count,
  onClick,
}) {
  return (
    <div
      className={styles.contract_dashboard_item}
      onClick={onClick}
      style={{ backgroundColor: isActive ? "#2EBB5533" : "#1C1B1B" }}
    >
      <div className={styles.contract_dashboard_img_container}>
        <img src={CONTRACT_DASHBOARD_ICON[iconName]} onClick={onClick} />
        <p className={styles.contract_dashboard_subtitle}>{title}</p>
      </div>
      <p className={styles.contract_dashboard_count}>{count}</p>
    </div>
  );
}
