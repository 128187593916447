import React, { useState, useCallback, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDropzone } from "react-dropzone";
import { Box, Heading } from "native-base";
import { toast } from "react-toastify";
import { forEach, get, omit } from "lodash";

import PageNavigationControls from "../../UniversalComponents/page_navigation_controls";
import useAuth from "../../../context/AuthContext";
// import { DeleteSoundPack } from "../../soundpack_page/Modals/DeleteSoundPack";
import { ConfirmDeletePopup } from "../../ScreenPopups/ConfirmDeletePopup";
import AudioPlayer from "../../UniversalComponents/audio_player";
import useAppState from "../../../context/useAppState";
import musicNote from "../../../assets/headers/musicnote.png";
import allImages from "../../../assets";
import CheckboxWithLabel from "../AdditionalComponents/Checkbox/CheckboxWithLabel";
import OpportunitiesSchema from "../../OpportunitiesComponents/YupSchema/OpportunitiesSchema";

import CircularLoader from "../../UniversalComponents/CircularLoader/CircularLoader";

import styles from "../Sync/submit_content.module.css";
import {
  ARTIST_STATUS,
  FILE_FORMAT,
  GENRE,
  KEY,
  LOOKING_FOR,
  PUBLISHING_FEE_TOOLTIP,
  SCALE,
} from "../../../shared/constants";
import moment from "moment";
import { IconButton, Tooltip } from "@mui/material";
import DarkTooltip from "../AdditionalComponents/Tooltip/DarkTooltip";

function CreateOpportunity({ isEdit }) {
  const {
    user: { _id },
  } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();

  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const {
    data,
    saveOpportunity,
    resetOpportunity,
    updateOpportunity,
    updateOpportunityState,
    fetchOpportunityById,
  } = useAppState("opportunity");
  const { uploadFile, uploadAudio } = useAppState("file");

  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      lookingFor: "",
      artist: {
        name: "",
        status: "",
      },
      monthlyListeners: "",
      expireDate: "",
      genre: "",
      bpm: "",
      key: "",
      scale: "",
      fileFormat: "",
      hasTerms: false,
      terms: {
        advance: "",
        publishing: "",
        points: "",
      },
      hasSpotifyLink: false,
      spotifyLink: null,
      coverPhoto: null,
      hasReferenceTrack: false,
      referenceTrack: null,
      referanceSpotifyLink: null,
      userId: "",
    },
    resolver: yupResolver(OpportunitiesSchema),
    reValidateMode: "onSubmit",
    mode: "onChange",
  });

  console.info("errors => ", errors);

  useEffect(() => {
    if (isEdit && id) {
      fetchOpportunityById(id);
    }
  }, [isEdit, id]);

  useEffect(() => {
    const fields = [
      "title",
      "description",
      "lookingFor",
      "artist",
      "monthlyListeners",
      "expireDate",
      "genre",
      "bpm",
      "key",
      "scale",
      "fileFormat",
      "hasTerms",
      "terms",
      "hasSpotifyLink",
      "spotifyLink",
      "coverPhoto",
      "hasReferenceTrack",
      "referenceTrack",
      "referanceSpotifyLink",
      "userId",
    ];

    // set data to hook form

    fields.forEach((key) => {
      setValue(key, data[key]);
    });
  }, [data]);

  useEffect(() => {
    return () => {
      resetOpportunity();
    };
  }, []);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0] || null;
    const profileData = new FormData();

    if (file) {
      setIsLoadingImage(true);
      profileData.append("file", file);
      profileData.append("type", "Public");

      try {
        const { fileLocation } = await uploadFile(profileData);
        setValue("coverPhoto", {
          name: file.name,
          url: fileLocation,
        });
      } catch (error) {
        toast.error("Something went wrong with file upload, please try again");
      } finally {
        setIsLoadingImage(false);
      }
    }
  };

  const ImageDropzone = useDropzone({
    multiple: false,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    onDrop,
  });

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      userId: _id,
    };

    try {
      if (isEdit) {
        try {
          delete payload.userId;
          const result = await updateOpportunity(id, payload);

          if (result) {
            toast.success("Opportunity Updated Successfully!");

            setTimeout(() => {
              console.info("pathname => ", pathname);

              if (pathname.includes("/admin")) {
                navigate("/adminpanel/opportunities");
              } else {
                navigate("/opportunities");
              }
            }, 500);
          }
        } catch (error) {
          console.info("error while updating opp => ", error);
        }
      } else {
        try {
          const result = await saveOpportunity(payload);

          navigate("/opportunities");
        } catch (error) {
          console.info("error while saving opp => ", error);
        }
      }
    } catch (error) {
      console.info("@onSubmit error while saving opportunity => ", error);
    }
  };

  const onDropAudio = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0] || null;

    const payload = new FormData();

    if (file) {
      setIsLoadingAudio(true);
      payload.append("file", file);
      payload.append("type", "Public");

      try {
        const { fileLocation, audioWaveFileLocation } =
          await uploadAudio(payload);
        setValue("referenceTrack", {
          name: file.name,
          url: fileLocation,
          waveUrl: audioWaveFileLocation,
        });
      } catch (error) {
        console.info("error while saving file => ");
        toast.error("Something went wrong with file upload, please try again");
      } finally {
        setIsLoadingAudio(false);
      }
    }
  }, []);

  const TrackDropzone = useDropzone({
    onDrop: onDropAudio,
  });

  const {
    hasReferenceTrack,
    referenceTrack,
    coverPhoto,
    hasSpotifyLink,
    hasTerms,
  } = watch();

  return (
    <div className={styles.main_layout}>
      <div className={styles.top_info}>
        <PageNavigationControls
          onBack={() => {
            if (pathname.includes("/admin")) {
              navigate("/adminpanel/opportunities");
            } else {
              navigate("/opportunities");
            }
          }}
        />
        <p className={styles.page_title}>
          {isEdit ? "Edit" : "Create"} An Opportunity
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.submit_page_header}>
          {isLoadingImage ? (
            <div className={styles.image_loading}>
              <CircularLoader />
            </div>
          ) : (
            <div className={styles.image_layout}>
              {!coverPhoto ? (
                <div className={styles.drag_image}>
                  <>
                    <div
                      {...ImageDropzone.getRootProps()}
                      className={styles.audioDropzone}
                    >
                      <img
                        src="/fileUpload.png"
                        alt="upload"
                        className={styles.drag_track_image}
                      ></img>
                      <p className={styles.drag_track_text}>
                        Drag or Upload Image
                      </p>
                      <p className={styles.drag_track_text_browse}>
                        Browse Files
                      </p>

                      <input
                        {...ImageDropzone.getInputProps()}
                        id="uploadImage"
                        accept="image/*"
                      />
                      {get(errors, `coverPhoto.message`, "") && (
                        <p className={styles.error_text}>
                          {get(errors, `coverPhoto.message`, "")}
                        </p>
                      )}
                    </div>
                  </>
                </div>
              ) : (
                <div className={styles.chosen_file}>
                  <DisplayCoverImage
                    fileName={coverPhoto.name}
                    file={coverPhoto.url}
                    type="image"
                    setDeletePopup={(data) => setDeletePopup(data)}
                    setDeleteName={() => setDeleteType("image")}
                    desc={"Are you sure you want to delete?"}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <LineDivider />
        <div className={styles.account_info_container}>
          {/* title */}
          <Controller
            control={control}
            name="title"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Title"
                  content={value}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Are you looking for? */}
          <Controller
            control={control}
            name="lookingFor"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Are you looking for?"
                  content={value}
                  dropdown={true}
                  dropDownData={LOOKING_FOR}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Genre */}
          <Controller
            control={control}
            name="genre"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Genre"
                  content={value}
                  dropdown={true}
                  dropDownData={GENRE}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Key */}
          <Controller
            control={control}
            name="key"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Key (optional)"
                  content={value}
                  dropdown={true}
                  dropDownData={KEY}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Artist Name */}
          <Controller
            control={control}
            name="artist.name"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Artist Name"
                  content={value}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Artist status */}
          <Controller
            control={control}
            name="artist.status"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Artist Status"
                  content={value}
                  dropdown={true}
                  dropDownData={ARTIST_STATUS}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Artist Name */}
          <Controller
            control={control}
            name="bpm"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="BPM (optional)"
                  content={value}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Key */}
          <Controller
            control={control}
            name="scale"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Scale (optional)"
                  content={value}
                  dropdown={true}
                  dropDownData={SCALE}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Spotify Monthly Listeners */}
          <Controller
            control={control}
            name="monthlyListeners"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Spotify Monthly Listeners"
                  content={value}
                  type="number"
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* File Format */}
          <Controller
            control={control}
            name="fileFormat"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="File Format"
                  content={value}
                  dropdown={true}
                  dropDownData={FILE_FORMAT}
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />

          {/* Expiration Date (DD/MM/YYYY) */}
          <Controller
            control={control}
            name="expireDate"
            render={({ field: { name, value }, formState }) => {
              return (
                <FormField
                  error={get(formState, `errors[${name}].message`, "")}
                  title="Expiration Date (DD/MM/YYYY)"
                  content={moment(value).format("YYYY-MM-DD")}
                  type="date"
                  onValueChange={(value) =>
                    setValue(name, value, { shouldValidate: true })
                  }
                />
              );
            }}
          />
        </div>

        <LineDivider />

        {/* Description */}
        <Controller
          control={control}
          name="description"
          render={({ field: { name, value }, formState }) => {
            return (
              <FormField
                error={get(formState, `errors[${name}].message`, "")}
                title="Description"
                content={value}
                onValueChange={(value) =>
                  setValue(name, value, { shouldValidate: true })
                }
              />
            );
          }}
        />

        <LineDivider />

        <div>
          <div className={styles.united_market_cut}>
            <p className={styles.united_market_cut_text}>
              United Market 5%{" "}
              <DarkTooltip title={PUBLISHING_FEE_TOOLTIP} placement="top">
                <IconButton variant="contained" size="small">
                  <img src={allImages.info} />
                </IconButton>
              </DarkTooltip>
            </p>
          </div>
        </div>

        <LineDivider />

        {/* Spotify Profile Link */}
        <Controller
          control={control}
          name="hasSpotifyLink"
          render={({ field: { name, value } }) => {
            return (
              <CheckboxWithLabel
                title="Spotify Profile Link"
                value={value}
                handler={({ payload }) =>
                  setValue(name, payload, { shouldValidate: true })
                }
                action="hasSpotifyLink"
              />
            );
          }}
        />

        <div>
          {/* Spotify Link */}

          {hasSpotifyLink && (
            <>
              <div className={styles.spacer_form_field}></div>
              <Controller
                control={control}
                name="spotifyLink"
                render={({ field: { name, value } }) => {
                  return (
                    <FormField
                      title="Spotify Link"
                      content={value}
                      onValueChange={(value) =>
                        setValue(name, value, { shouldValidate: true })
                      }
                    />
                  );
                }}
              />
            </>
          )}
        </div>
        <LineDivider />

        {/* Reference Track */}
        <Controller
          control={control}
          name="hasReferenceTrack"
          render={({ field: { name, value } }) => {
            return (
              <CheckboxWithLabel
                title="Reference Track"
                value={value}
                handler={({ payload }) =>
                  setValue(name, payload, { shouldValidate: true })
                }
                action="hasReferenceTrack"
              />
            );
          }}
        />

        <div>
          {hasReferenceTrack && (
            <>
              {isLoadingAudio ? (
                <div className={styles.track_layout}>
                  <div className={styles.drag_image}>
                    <CircularLoader />
                  </div>
                </div>
              ) : (
                <div className={styles.track_layout}>
                  {!referenceTrack ? (
                    <div className={styles.drag_image}>
                      <>
                        <div
                          {...TrackDropzone.getRootProps()}
                          className={styles.audioDropzone}
                        >
                          <img
                            src="/fileUpload.png"
                            alt="upload"
                            className={styles.drag_track_image}
                          ></img>
                          <p className={styles.drag_track_text}>
                            Upload Reference Track
                          </p>
                          <p className={styles.drag_track_text_browse}>
                            Browse Files
                          </p>

                          <input
                            {...TrackDropzone.getInputProps()}
                            id="uploadAudio"
                          />
                          {get(errors, `coverPhoto.message`, "") && (
                            <p className={styles.error_text}>
                              {get(errors, `coverPhoto.message`, "")}
                            </p>
                          )}
                        </div>
                      </>
                    </div>
                  ) : (
                    <div className={styles.chosen_file}>
                      <DisplayReferanceTrack
                        fileName={referenceTrack?.name}
                        file={referenceTrack?.url}
                        setDeletePopup={(data) => setDeletePopup(data)}
                        setDeleteName={() => setDeleteType("audio")}
                        desc={"Are you sure you want to delete?"}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className={styles.spacer_medium}></div>
              <Controller
                control={control}
                name="referanceSpotifyLink"
                render={({ field: { name, value }, formState }) => {
                  return (
                    <FormField
                      error={get(formState, `errors[${name}].message`, "")}
                      title="Referance Spotify Link"
                      content={value}
                      onValueChange={(value) =>
                        setValue(name, value, { shouldValidate: true })
                      }
                    />
                  );
                }}
              />
            </>
          )}
        </div>
        <LineDivider />
        {/* Add Terms */}
        <Controller
          control={control}
          name="hasTerms"
          render={({ field: { name, value } }) => {
            return (
              <CheckboxWithLabel
                title="Add Terms"
                value={value}
                handler={({ payload }) =>
                  setValue(name, payload, { shouldValidate: true })
                }
                action="hasTerms"
              />
            );
          }}
        />

        <div>
          {hasTerms && (
            <>
              <div className={styles.spacer_form_field}></div>
              <div className={styles.account_info_container}>
                <Controller
                  control={control}
                  name="terms.advance"
                  render={({ field: { name, value } }) => {
                    return (
                      <FormField
                        title="Advance ($)"
                        type="number"
                        content={value}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="terms.points"
                  render={({ field: { name, value } }) => {
                    return (
                      <FormField
                        title="Points"
                        type="number"
                        content={value}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="terms.publishing"
                  render={({ field: { name, value } }) => {
                    return (
                      <FormField
                        title="Publishing"
                        type="number"
                        content={value}
                        onValueChange={(value) =>
                          setValue(name, value, { shouldValidate: true })
                        }
                      />
                    );
                  }}
                />
              </div>
            </>
          )}
        </div>
        <LineDivider />
        {/* <div className={styles.line_divider_invisible}></div> */}
        <div className={styles.form_buttons}>
          <div
            className={styles.cancel_button}
            onClick={() => navigate("/opportunities")}
          >
            <p className={styles.button_text}>Cancel</p>
          </div>
          <div className={styles.submit_button}>
            <button className={styles.button_text} disabled={isSubmitting}>
              {isEdit ? "Update" : "Publish"}
            </button>
          </div>
        </div>
      </form>
      <div className={styles.line_divider_invisible}></div>
      <ConfirmDeletePopup
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        deleteFtn={() => {
          if (deleteType === "audio") {
            setValue("referenceTrack", null);
          } else {
            setValue("coverPhoto", null);
          }
        }}
        desc={"Are you sure you want to delete?"}
      />
    </div>
  );
}

const FormField = ({
  title,
  type = "text",
  error = "",
  name,
  info,
  content,
  dropdown = false,
  dropDownData,
  onValueChange,
}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleOptionClick = (option) => {
    onValueChange(option);
    toggleDropdown();
  };

  const handleInputChange = (event) => {
    onValueChange(event.target.value);
  };

  return (
    <div className={styles.user_info_layout}>
      <div className={styles.field_info}>
        <p className={styles.user_info_title}>{title}</p>
        {info && (
          <Tooltip title={info} arrow>
            <img
              src="/v2Assets/submission_form_assets/moreInfoIcon.png"
              alt="info"
              className={styles.infoImage}
            />
          </Tooltip>
        )}
      </div>
      <div className={styles.input_box}>
        <input
          name={name}
          className={styles.input_box_text}
          value={content}
          onClick={() => {
            dropdown && toggleDropdown();
          }}
          onChange={(e) => !dropdown && handleInputChange(e)}
          type={type}
        />
        {dropdown && (
          <div
            className={styles.input_box_dropdown_button}
            onClick={toggleDropdown}
          >
            <div className={styles.dropdown_green_bg}>
              <img
                src="/v2Assets/submission_form_assets/DropDownArrow.png"
                alt="options"
                className={styles.drop_down_image}
              />
              {isDropdownVisible && (
                <div className={styles.dropdown_menu}>
                  {dropDownData?.map((option, index) => (
                    <div
                    key={`menulistZ-${index}`}
                      className={styles.dropdown_menu_option_box}
                      onClick={() => handleOptionClick(option)}
                    >
                      <p className={styles.dropdown_menu_option}>{option}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {error && <p className={styles.error_text}>{error}</p>}
    </div>
  );
};

const LineDivider = () => {
  return <div className={styles.line_divider}></div>;
};

function DisplayCoverImage(props) {
  const { fileName, setDeletePopup, setDeleteName, type, file } = props;
  return (
    <div className={styles.display_file_wrapper}>
      <img
        src="/deleteOptionEx.png"
        alt="Check"
        className={styles.delete_icon_corner}
        onClick={() => {
          setDeletePopup(true);
          setDeleteName(fileName);
        }}
      />

      <img src={file} alt="file" className={styles.cover_file_image}></img>
    </div>
  );
}

function DisplayReferanceTrack(props) {
  const [isReferanceHovered, setIsReferanceHovered] = useState(false);
  const { fileName, setDeletePopup, setDeleteName, type, file } = props;
  return (
    <div
      className={
        isReferanceHovered
          ? `${styles.file_display} ${styles.hovered}`
          : styles.file_display
      }
    >
      <img
        src="/deleteOptionEx.png"
        alt="Check"
        className={styles.delete_icon_corner}
        onMouseEnter={() => setIsReferanceHovered(true)}
        onMouseLeave={() => setIsReferanceHovered(false)}
        onClick={() => {
          setDeletePopup(true);
          setDeleteName(fileName);
        }}
      />
      <p className={styles.file_title}>{fileName}</p>

      <AudioPlayer src={file} />
    </div>
  );
}

function Displayfile(props) {
  const { fileName, setDeletePopup, setDeleteName, type, file } = props;
  return type === "image" ? (
    <Box w={"100%"}>
      {/* <NeedToMakeThisPretty /> */}
      <Box
        alignSelf={"center"}
        alignItems={"center"}
        style={{ gap: "10px", flexDirection: "row" }}
        className={styles.imagePreviewWrapper}
      >
        <img src={file} alt="file" className={styles.cover_file_image}></img>
        <div
          className={styles.delete_button}
          onClick={() => {
            setDeletePopup(true);
            setDeleteName(fileName);
          }}
        >
          Delete
        </div>
      </Box>
    </Box>
  ) : (
    <div className={styles.file_display}>
      <div className={styles.file_display_top}>
        <div className={styles.file_info}>
          <img
            src="/v2Assets/submission_form_assets/music_file.png"
            alt="file"
            className={styles.song_file_image}
          ></img>
          <div>
            <p className={styles.file_title}>{fileName}</p>
          </div>
        </div>
      </div>
      <div className={styles.file_display_bottom}>
        <div
          className={styles.delete_button}
          onClick={() => {
            setDeletePopup(true);
            setDeleteName(fileName);
          }}
        >
          Delete
        </div>
        <AudioPlayer src={file} />
      </div>
    </div>
  );
}

export default CreateOpportunity;
