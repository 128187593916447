import axios from "axios";
import adminAxiosInstance from "./Axios";
// import { loggedOutAction } from 'store/actions/authaction';

// let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InRva2VuIjoiOTg3ZTJhNDQ2YjViMTNlNzc0ZGEzNzBlMmQ3ZGUwMTAwZTA3YmZhYjFiYjAzY2I5ZTMyZjI0OWViZGEyYWRkYzVkYTZlYzYyOWUxZjBkZjI2Y2VlOTAiLCJpc0ltcGVyc29uYXRlZCI6ZmFsc2UsInVzZXJJZCI6IjYzN2M0YTNiY2ZlODI2M2ZmOGZhYjM2YSJ9LCJpYXQiOjE2NzA0MjQxNjcsImV4cCI6MTY3MDQyOTU2N30.3rOU_Evly6LAlvq4fiyPNU2Mw1vYmHFH3-U9492tdog"
export const LoginUser = async (adminEmail, adminPassword) => {
  // console.log(adminEmail, adminPassword);
  try {
    var data = JSON.stringify({
      query: `mutation{
                    login(email:"${adminEmail}",password:"${adminPassword}"){
                    success
                    message
                    admin{
                        firstname
                        lastname
                        email
                        _id
                    }
                    opportunityAccessToken
                    opportunityRefreshToken
                    accessToken
                    refreshToken
                    }
                }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_ADMIN_URL}/adminapis`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    let response = await axios(config);

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default {
  catalogItems: async function () {
    // get catalogItems (in our case opportunities)
    try {
      let token = JSON.parse(localStorage.getItem("userData"));
      let shopId = "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ==";
      // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InRva2VuIjoiODE0ODZlMmY3ZTIwMDUxZjZlMmVjMWYyYzllNDIwNTQ4M2Y1ZjI5MWM5NjE3MmEyMjVjZDRiMjRmYTYzYWEyYzc5YjE1ZTI5ZTEyNzQzOTAzY2E2MmEiLCJpc0ltcGVyc29uYXRlZCI6ZmFsc2UsInVzZXJJZCI6IjYzN2M0YTNiY2ZlODI2M2ZmOGZhYjM2YSJ9LCJpYXQiOjE2NzA0MTQwODQsImV4cCI6MTY3MDQxOTQ4NH0.gvN59Ir8IgYCFccgjtxxTh5flTyWg8L4ey2BVSv8b6I"
      var data = JSON.stringify({
        query: `query {
                products(shopIds: ["cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="] , first:1000){
                    totalCount
                    pageInfo{
                        hasNextPage
                        startCursor
                        hasPreviousPage
                        endCursor
                    }
                    nodes{
                        _id
                        title
                        publishedAt
                        description
                        uploadedBy{
                            userId
                        }
                        metafields{
                            key
                            value
                            valueType
                            description
                        }
                        isVisible
                        variants{
                            _id
                            attributeLabel
                            isVisible
                        }
                    }
                }
            }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_OPPORTUNITY_BACKEND_URL}/graphql`,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        data: data,
      };
      let products = await axios(config);
      return products;
    } catch (err) {
      console.log("error", err);
      // err.response.request.status == (401 || 402 || 403) ? loggedOutAction() : null;
    }
  },

  publishOpportunities: async function (product) {
    try {
      let token = JSON.parse(localStorage.getItem("userData"));
      let shopId = "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ==";

      var data = JSON.stringify({
        query: `mutation {
                publishProductsToCatalog(productIds: ["${product._id}"]){
                 _id
                  product{
                    title
                    description
                    variants{
                      _id
                    }
                  }
                }
              }`,
        variables: {},
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_OPPORTUNITY_BACKEND_URL}/graphql`,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        data: data,
      };
      let products = await axios(config);
      // todo send email
      const payload = {
        userId: product?.uploadedBy?.userId,
        success: true,
        description: "",
      };
      this.sendEmailNotification(payload).then(() => {
        // product.metafields.find((e) => e.key === 'action').value = 'published';
        // console.log('Reject metafields', product?.metafields);
        this.updateOpportunities(product);
        // this.getSubmissions(product._id, product.variants[0]._id);
      });

      return products;
    } catch (err) {
      console.log("error", err);
    }
  },
  rejectOpportunity: async function (product, description) {
    // get catalogItems (in our case opportunities)
    try {
      let token = JSON.parse(localStorage.getItem("userData"));
      let shopId = "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ==";
      // console.log('Product To Be Publish', product, description);
      let data = {
        userId: product?.uploadedBy?.userId,
        success: false,
        description: description,
      };
      this.sendEmailNotification(data).then(() => {
        // product.metafields.find((e) => e.key === 'action').value = 'rejected';
        // console.log('Reject metafields', product?.metafields);
        this.updateOpportunities(product);
      });
    } catch (err) {
      console.log("error", err);
    }
  },

  sendEmailNotification: async function (payload) {
    let token = JSON.parse(localStorage.getItem("AccessToken"));

    try {
      // console.log('payload', payload);
      var data = JSON.stringify({
        query: `mutation{
                onRejectSendEmail(opportunityId:"${payload.userId}",success: ${payload.success}, description: "${payload.description}"){
                  success
                  message
                }
              }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_ADMIN_URL}/adminapis`,
        headers: {
          "Content-Type": "application/json",
          authorization: `${token}`,
        },
        data: data,
      };
      return await axios(config);
    } catch (err) {
      console.log("error", err);
    }
  },

  getSubmissions: async function (prod_id, var_id) {
    try {
      let token = JSON.parse(localStorage.getItem("userData"));

      let shopId = "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ==";
      // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InRva2VuIjoiODE0ODZlMmY3ZTIwMDUxZjZlMmVjMWYyYzllNDIwNTQ4M2Y1ZjI5MWM5NjE3MmEyMjVjZDRiMjRmYTYzYWEyYzc5YjE1ZTI5ZTEyNzQzOTAzY2E2MmEiLCJpc0ltcGVyc29uYXRlZCI6ZmFsc2UsInVzZXJJZCI6IjYzN2M0YTNiY2ZlODI2M2ZmOGZhYjM2YSJ9LCJpYXQiOjE2NzA0MTQwODQsImV4cCI6MTY3MDQxOTQ4NH0.gvN59Ir8IgYCFccgjtxxTh5flTyWg8L4ey2BVSv8b6I"
      var data = JSON.stringify({
        query: `query {
                    getProductBids(input: {
                      productId: "${prod_id}"
                      variantId: "${var_id}"
                    }){
                        bids {
                        _id
                         createdByinfo {
                          name
                        }
                        createdBy
                        fileUrl
                      }
                      count
                    }
                  }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_OPPORTUNITY_BACKEND_URL}/graphql`,
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        data: data,
      };
      let subm = await axios(config);

      return subm;
    } catch (err) {
      console.log("error", err);
    }
  },
  updateOpportunities: async function (parmas) {
    try {
      let token = JSON.parse(localStorage.getItem("userData"));
      let shopId = "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ==";
      let updatedMetafields = parmas.metafields;
      var data = JSON.stringify({
        query: `mutation {
            updateProduct(input:{
                shopId: "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ=="
                productId: "${parmas._id}"
                product: {
                    metafields: [
                        {
                            key: action
                            value: ${updatedMetafields?.find(
                              (e) => e.key === "action"
                            )}
                        }
                    ]
                }
            }){
                product{
                _id
                title
                isVisible
                    metafields{
                        value
                        key
                    }
                }
            }
            }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_OPPORTUNITY_BACKEND_URL}/graphql`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      let updatedOpportunities = await axios(config);
      return updatedOpportunities;
      // .then(function (response) {
      // console.log(JSON.stringify(response.data));
      // })
      // .catch(function (error) {
      // console.log(error);
      // });
    } catch (err) {
      console.log("error from updated", err);
      return;
    }
  },

  deleteOpportunity: async function (product) {
    try {
      let token = JSON.parse(localStorage.getItem("userData"));
      let shopId = "cmVhY3Rpb24vc2hvcDp0OGJnSnRxSEF2RkRqRU1OdQ==";
      // let updatedMetafields = parmas.metafields;
      var data = JSON.stringify({
        query: `mutation{
                    removeProduct(productId: "${product._id}") {
                      success
                      status
                      message
                    }
                  }
                `,
        variables: {},
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_OPPORTUNITY_BACKEND_URL}/graphql`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      let response = await axios(config);
      console.log("response", response);
      return response;
    } catch (err) {
      console.log("error from updated", err);
      return;
    }
  },

  createUsers: async (
    firstname,
    lastname,
    email,
    password,
    userName,
    phone,
    role,
    isVerified,
    allowCreate,
    profilePic,
    membershipType,
    instaUrl,
    isFreemium = true,
  ) => {
    try {
      let token = localStorage.getItem("token");
      var data = JSON.stringify({
        query: `mutation{
                    signUp
                    (
                      firstname:"${firstname}",
                      lastname:"${lastname}",
                      email:"${email}",
                      phoneno: ${phone},
                      password:"${password}",
                      profilePic:"${profilePic}",
                      userName: "${userName}",
                      roleId: "${role}",
                      isVerified: ${isVerified},
                      allowCreate: ${allowCreate},
                      isPreSignUp: false,
                      membershipType: "${membershipType}",
                      isAdminReviewed: true,
                      isFreemium:${isFreemium},
                      instaUrl:"${instaUrl}"
                    )

                    {
                      success
                      message
                      user{
                        _id
                           email
                        profilePic
                       
                      }
                    }
                  }
                  `,
        variables: {},
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_AUTH_URL}/auth`,
        headers: {
          "Content-Type": "application/json",
          authorization: `${token}`,
        },
        data: data,
      };
      let response = await axios(config);

      return response;
      // getAllUsers();
    } catch (err) {
      console.log("error", err);
    }
  },

  deleteUsers: async (user_id) => {
    try {
      let token = JSON.parse(localStorage.getItem("AccessToken"));
      var data = JSON.stringify({
        query: `query{
                    deleteUser(userId:"${user_id}"){
                      success
                      message
                    }
                  }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_ADMIN_URL}/adminapis`,
        headers: {
          "Content-Type": "application/json",
          authorization: `${token}`,
        },
        data: data,
      };
      let response = await axios(config);

      return response;
    } catch (err) {
      console.log("error", err);
    }
  },

  suspendUsers: async (user_id) => {
    try {
      let token = JSON.parse(localStorage.getItem("AccessToken"));
      var data = JSON.stringify({
        query: `query{
                    suspendUser(userId:"${user_id}"){
                    success
                    message
                  }
                }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_ADMIN_URL}/adminapis`,
        headers: {
          "Content-Type": "application/json",
          authorization: `${token}`,
        },
        data: data,
      };
      let response = await axios(config);
      return response;
    } catch (err) {
      console.log("error", err);
    }
  },

  editUsers: async (
    id,
    profile,
    userName,
    password,
    genre,
    role,
    insta,
    email,
  ) => {
    console.log(id, profile, userName, password, genre, role, insta, email);
    try {
      let token = JSON.parse(localStorage.getItem("AccessToken"));
      var data = JSON.stringify({
        query: `mutation{
                    updateUserAsAdmin(Updateprofilebody:{
                      userName:"${userName}"
                      genre:"${genre}"
                      password:"${password}"
                      roleId: "${role}"
                      instaUrl: "${insta}"
                      email:"${email}"


                      experienceinput:{
                        title:null
                        description:null
                        profileHeadline:null
                        companyName:null
                        startDate:null
                        endDate:null
                      }
                      profilePic:"${profile}"
                      coverPic:null
                    },userId:"${id}"){
                        success
                        message
                        isTokenExpired
                        updatedUser{
                          email
                          userName
                          genre   
                          profilePic
                          coverPic
                          instaUrl
                        }
                    }
                  }
                  
                  `,
        variables: {},
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_ADMIN_URL}/user`,
        headers: {
          "Content-Type": "application/json",
          authorization: `${token}`,
        },
        data: data,
      };
      let response = await axios(config);

      return response;
      // getAllUsers();
    } catch (err) {
      console.log("error", err);
    }
  },

  giveAccessToUser: async (id, access) => {
    try {
      let token = JSON.parse(localStorage.getItem("AccessToken"));
      var data = JSON.stringify({
        query: `mutation{
                    onGrantOpportunityPermission(userId:"${id}",privilege:{
                      value:"opportunity"
                      isRead:true
                      isUpdate:false
                      isDelete:true
                      isCreate:${access}
                    }){
                      success
                      message
                    }
                  }
                  `,
        variables: {},
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_USER_URL}/user`,
        headers: {
          "Content-Type": "application/json",
          authorization: `${token}`,
        },
        data: data,
      };
      let response = await axios(config);
      // console.log('..', response);
      return response;
    } catch (err) {
      console.log("error", err);
    }
  },
};
 
 
export const getAllUsers = async () => {
  try {
    let token = localStorage.getItem("token");
    const data = JSON.stringify({
      query: `query{
                getAllUserswoPagination{
                  success
                  message
                  users{
                    _id
                    firstname
                    lastname
                    email
                    spotifyUrl
                    noOfMonthlyListeners
                    howDidYouKnow{
                        name
                    }
                    phoneno
                    isSuspended
                    isFreemium
                    profilePic
                    userName
                    opportunityId
                    isSoundPackAllowed
                    genre
                    isPaid
                    planId{
                        planName
                        priceId
                    }
                    subscriptionStatus
                    accountStatus
                    roleId{
                        roleName
                        _id
                    }
                    instaUrl                        
                    rolePrivilege{
                        privileges{
                          isRead
                          isDelete
                          isUpdate
                          isCreate
                        }
                      }
                  }
                }
              }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_USER_URL}/user`,
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
      data: data,
    };
    let users = await axios(config);
    return users;
  } catch (err) {
    console.log("error", err);
    // err.response.request.status == (401 || 402 || 403) ? loggedOutAction() : null;
  }
};

export const forgotPasswordAdmin = async (adminEmail) => {
  try {
    let data = await adminAxiosInstance.post("/admin/forgot-password", {
      email: adminEmail,
    });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const verifyotpAdmin = async (verifyotp) => {
  try {
    let data = await adminAxiosInstance.post("/admin/checkotp", {
      verificationToken: verifyotp,
    });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const resetPasswordAdmin = async (oldpassword, newpassword, otp) => {
  try {
    let res = await adminAxiosInstance.post("/admin/reset-password", {
      password: oldpassword,
      confirmPassword: newpassword,
      verificationToken: otp,
    });
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const viewAllUsers = async () => {
  try {
    let res = await adminAxiosInstance.get("/admin/viewallusers");

    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const byPassPayment =  async ({id, isPaid}) => {

  let token = localStorage.getItem("token");
  var config = {
    method: "patch",
    url: `${process.env.REACT_APP_OPPORTUNITY_URL}/opportunities/admin/bypasspayment/${id}`,
    headers: { 
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
   },
    data: {
      isPaid: isPaid
    },
  };
  axios(config)
    .then(function (response) {
    })
    .catch(function (error) {
      throw error;
    });

};


export const generateMarketingSummary = async ({ data, setSummary, setLoading }) => {
  try {
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const { token } = userData;

    const baseUrl = process.env.REACT_APP_AI_URL;
    // const baseUrl = "http://localhost:5555";

    const response = await fetch(`${baseUrl}/marketing/generate_summary`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ input: data }),
    });

    if (!response.ok) {
      throw new Error("Failed to generate marketing summary.");
    }

    return await readAndUpdateStream({ response, setSummary, setLoading });
  } catch (error) {
    console.error("Generate Marketing Summary Error:", error);
    throw error; 
  }
};

const readAndUpdateStream = async ({ response, setSummary, setLoading }) => {
  const reader = response.body.getReader();
  const textDecoder = new TextDecoder("utf-8");

  let streamContent = "";
  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      streamContent += textDecoder.decode(value);
    }
    setSummary(streamContent);
  } catch (error) {
    console.error("Error reading stream:", error);
    throw error;
  } finally {
    setLoading(false);
  }

  return streamContent;
};

