import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Box } from "@material-ui/core";

import styles from "./Libraries.module.css";

import OutlineButton from "../AdditionalComponents/OutlineButton.js";
import TagsModal from "./Modals/TagsModal.js";
import KeysModal from "./Modals/KeysModal.js";

import { KEY, TAGS } from "../../../shared/constants";
import BlueButtonComponents from "../AdditionalComponents/utils/BlueButtonComponent.js";
import SelectFileModal from "./Modals/SelectFileModal.js";
import useLibraryStore from "../../../store/library.js";



const SubTitleBar = ({
  hasSubOption,
  onTagStatusChange,
  tags,
  createButton = false,

}) => {

  const { allLibraryData, setAllLibraryData } = useLibraryStore();

  const [tagsModalOpen, setTagsModalOpen] = useState(false);
  const [keysModalOpen, setKeysModalOpen] = useState(false);

  const [selectFileModalsOpen, setSelectFileModalsOpen] = useState(false);

  const { selectedTags, setSelectedTags } = useLibraryStore();
  const [link, setLink] = useState([]);
  const [packName, setPackName] = useState("");
  const [audioName, setAudioName] = useState("");

  const location = useLocation();

  const handleTagStatusChange = (tags) => {
    setSelectedTags(tags);
    onTagStatusChange(tags);
  };

  const handleToggleSelectFileModals = () => {
    setSelectFileModalsOpen(!selectFileModalsOpen);
  };

  useEffect(() => {
    getUrl();

  }, [window.location.pathname])



  const getTitle = (title) => {
    var modifiedString = "";
    if (title != undefined) {
      modifiedString = title.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase());
    }
    return modifiedString;
  }

  const getUrl = () => {
    const currentRouteName = window.location.pathname.replace(/\/$/, "").split('/');
    const searchParams = new URLSearchParams(location.search);
    const packname = searchParams.get('packname');
    const audioname = searchParams.get('audioname');
    
    setLink(currentRouteName);
    setPackName(packname);
    setAudioName(audioname);
  }


  return (
    <>
      <Box
        className={styles.parent_wrapper}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "30px",
          marginBottom: "20px",
        }}
      >
        <Box
          className={styles.titleWrapper}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            cursor: "pointer"
          }}
        >
          {link && link.length == 2 && <Link className={styles.page_sub_link}>All Files</Link>}
          {link && link.length == 3 && <Link className={styles.page_sub_link} to={`/myaudiolibrary/${link[2]}`}>{getTitle(link[2])}</Link>}
          {link && link.length == 4 &&
            <>
              <Link className={styles.page_sub_link} to={`/myaudiolibrary/beat_pack`}>{`${getTitle(link[2])}  >  `}</Link>
              <Link className={styles.page_sub_link} to={`/myaudiolibrary/beat_pack/${link[3]}?packname=${packName}`}>{packName}</Link>
            </>
          }
          {link && link.length == 5 &&
            <>
              <Link
                className={styles.page_sub_link}
                to={`/myaudiolibrary/beat_pack`}
              >
                {`${getTitle(link[2])}  >  `}
              </Link>

              <Link
                className={styles.page_sub_link}
                to={`/myaudiolibrary/beat_pack/${link[3]}?packname=${packName}`}
              >
                {packName} 
              </Link>

              <Link
                className={styles.page_sub_link}
                to={`/myaudiolibrary/beat_pack/${link[3]}/${link[4]}`}
              >
                {` > ${audioName}`}
              </Link>
            </>
          }


        </Box>
        {hasSubOption && (
          <div className={`${styles.buttonGroup} ${styles.buttonsWrapper}`}>
            <div
              className={styles.opportunity_large_tags}
              onClick={() => setTagsModalOpen(true)}
            >
              <img
                alt="tag"
                src="/v2Assets/TagIcons/Tags.png"
                className={styles.opportunity_large_tags_icon}
              />
              <label>Tags</label>
            </div>
            <div
              className={styles.opportunity_large_tags}
              onClick={() => setKeysModalOpen(true)}
            >
              <img
                alt="key"
                src="/v2Assets/TagIcons/Key.png"
                className={styles.opportunity_large_tags_icon}
              />
              Key & BPM
            </div>
            {createButton && <BlueButtonComponents text="Create + " onClickFunction={handleToggleSelectFileModals} />}
          </div>
        )}
      </Box>
      <TagsModal open={tagsModalOpen} setOpen={setTagsModalOpen} Tags={TAGS} tags={selectedTags} onTagStatusChange={handleTagStatusChange} />
      <KeysModal
        open={keysModalOpen}
        setOpen={setKeysModalOpen}
      />
      <SelectFileModal
        open={selectFileModalsOpen}
        setOpen={setSelectFileModalsOpen}

      />
    </>
  );
};

export default SubTitleBar;
