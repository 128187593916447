import React from "react";
import { Grid } from "@mui/material";

import { Sidebar } from "../../layout/Sidebar/Sidebar";

import AdminPanel from "../../components/AdminConsole/admin_panel";

const AdminPage = () => {
  return (
    <Grid>
      <Sidebar activeText="Admin" childComponent={<AdminPanel />} />
    </Grid>
  );
};

export default AdminPage;
