import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Main = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "20px",
  paddingBottom: "20px",
  marginBottom: "40px",
  background: "#0D120F",
  //   background: "#fff",
  "& h6": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "30px",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: "20px",
    marginTop: "0px",
    color: "#FFFFFF",
  },
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  "& .header_button": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  "& .header_button button": {
    border: "1px solid #1C1B1B",
    color: "#AFAFAF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    background: "#1C1B1B",
    width: "70px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "3px",
    "&.active": {
      background: "#2EBB55",
      color: "#fff",
    },
  },
}));

export { Main };
