import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../mycontracts.module.css";
import {
  LOOKING_FOR_CONTRACTS,
} from "../../../shared/constants";
import TemplatesComponent from "./TemplatesComponent";
import { Loader } from "../../OpportunitiesComponents/AdditionalComponents/Loader";
import EnhancedTable from "./TemplatesManage";

function CreateTemplateComponent() {
  const tabsRef = useRef(null);
  const underlineRef = useRef(null);
  const [activeTab, setActiveTab] = useState(LOOKING_FOR_CONTRACTS[1]);
  const [type, setType] = useState(LOOKING_FOR_CONTRACTS[1]);
  const [page, setPage] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);
  const router = useNavigate()

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setPage(0);
    setcurrentPage(1);
  }, [type]);
  const ShowBlurClass = !loading;

  useEffect(() => {
    const tabEl = [...tabsRef.current.children].find(
      (child) => child.textContent === activeTab
    );
    const { offsetLeft, offsetWidth } = tabEl;
    underlineRef.current.style.left = `${offsetLeft}px`;
    underlineRef.current.style.width = `${offsetWidth}px`;
  }, [activeTab]);
  useEffect(() => {});

  return (
    <div className={styles.main_layout}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "50px",
          marginRight: "50px",
        }}
      >
        <p className={styles.page_title}>Contracts</p>
      </div>
      <div className={styles.tabs} ref={tabsRef}>
        {LOOKING_FOR_CONTRACTS.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab && styles.active}`}
            onClick={() => {
              if(tab === 'Dashboard') router('/my-contracts') 
              else router(`/my-contracts/${tab.toLowerCase()}`)
            }}
          >
            {tab}
          </div>
        ))}
        <div className={styles.underline} ref={underlineRef} />
      </div>
      <div>{!loading ? <TemplatesComponent /> : <Loader />}</div>
    </div>
  );
}

export default CreateTemplateComponent;
