import React from "react";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Loader } from "../../UniversalComponents/Loader";

import styles from "../admin.module.css";
import PageSearchHeader from "../../UniversalComponents/page_search_header";

import { getUsers } from "../../../backend/admin_queries/AdminQueries";
import { DeleteUserRequest } from "../../../AdminGraphqlRequest/User/UserRequests";
import { IconButton, Tooltip } from "@mui/material";
import { Box } from "native-base";
import EditUser from "./edit_user";
import { VerifyUsersRequest } from "../../../AdminGraphqlRequest/User/UserRequests";
import { toast } from "react-toastify";
import { getAllUsers } from "../../../AdminAxiosInstance/index";

import { useDebounce } from "../../../hooks/useDebounce";
// import Pagination from "../../../components-old/Pagination/Pagination";
import allImages from "../../../assets";
import { ACCOUNT_STATUS } from "../../../shared/constants";
import { get, set } from "lodash";
import CustomPagination from "../../CustomPagination/CustomPagination";
import DeleteUserModal from "./deleteUserModal";

const AdminUsers = () => {
  const theme = useTheme();
  const [checkedItems, setCheckedItems] = useState(new Set());

  const [isLoading, setIsLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [numPerPage, setNumPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [user, setUser] = useState();

  const [isEdit, setIsEdit] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [memebershipType, setMembershipType] = useState("Free");

  const [isDelete, setIsDelete] = useState(false);

  const handleIsDeleteOpen = () => {
    setIsDelete(true);
  };

  const handleIsDeleteClose = () => {
    setIsDelete(false);
  };

  const [query, setSearchQuery] = useState("");

  const [sortAnchor, setSortAnchor] = useState(null);
  const [sortBy, setSortBy] = useState("createdAt");
  const [filterAnchor, setFilterAnchor] = useState(null);

  const [filterBy, setFilterBy] = useState({});
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);

  const searchQuery = useDebounce(query, 500);

  const sortData = [
    {
      id: 1,
      title: "Last Seen",
      key: "lastSeen",
    },
    {
      id: 2,
      title: "Submissions",
      key: "submissionCount",
    },
    {
      id: 3,
      title: "Time Spent",
      key: "timeSpent",
    },
  ];

  const [loadUsers] = useLazyQuery(getUsers, {
    context: { clientName: "user" },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const {
        searchUserGlobaly: { users, totalCount },
      } = data;

      setUsersData(users);
      setTotalCount(Math.ceil(totalCount / numPerPage));
      setIsLoading(false);
    },
    onError(err) {
      console.log("error on getting user info", err);
      setIsLoading(false);
    },
  });

  // const [deleteUser] = useLazyQuery(deleteUserMutation, {
  //   context: { clientName: "adminapis" },
  //   fetchPolicy: "no-cache",
  //   onCompleted: (data) => {
  //     console.log("Mutation completed", data);
  //     // Handle the response data here
  //   },
  //   onError: (error) => {
  //     console.error("Mutation error", error);
  //     // You can handle errors here
  //   },
  // });

  useEffect(() => {
    setIsLoading(true);
    loadUsers({
      variables: {
        pageNo: currentPage,
        perPage: numPerPage,
        query: searchQuery,
        sortBy: sortBy,
        order: "desc",
        filter: filterBy,
      },
    });
  }, [currentPage, sortBy, filterBy]);

  useEffect(() => {
    setCurrentPage(1);
    setIsLoading(true);
    loadUsers({
      variables: {
        pageNo: 1,
        perPage: numPerPage,
        query: searchQuery,
        sortBy: sortBy,
        order: "desc",
        filter: filterBy,
      },
    });
  }, [searchQuery]);

  const toggleCheck = (id) => {
    setCheckedItems((prevChecked) => {
      const newChecked = new Set(prevChecked);
      if (newChecked.has(id)) {
        newChecked.delete(id);
      } else {
        newChecked.add(id);
      }
      return newChecked;
    });
  };

  const verifyUsers = async () => {
    try {
      const usersToVerify = Array.from(new Set(checkedItems));
      if (usersToVerify && usersToVerify.length > 0) {
        await VerifyUsersRequest(usersToVerify, true);
        setCheckedItems(new Set());
        toast.success("Users have been verified successfully!");
        setCurrentPage(1);
        setSearchQuery("");
        loadUsers({
          variables: {
            pageNo: currentPage,
            perPage: numPerPage,
            query: "",
          },
        });
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleDeleteUser = async (user) => {
    try {
      const response = await DeleteUserRequest(user._id);
      console.log("response", response);
      if (response.deleteUser.success) {
        toast.success("User has been deleted successfully!");
        setIsDelete(false);
        // setCurrentPage(1);
        // setSearchQuery("");
        setIsLoading(true);
        loadUsers({
          variables: {
            pageNo: currentPage,
            perPage: numPerPage,
            query: searchQuery,
            sortBy: sortBy,
            order: "desc",
            filter: filterBy,
          },
        });
      }
    } catch (err) {
      console.log("error", err);
      toast.error("Error deleting user");
    }
  };

  const handleUser = (user) => {
    setUser(user);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleAddUser = () => {
    setIsEdit(false);
    setEditForm(true);
  };

  const handleSearchQuery = (value) => {
    setSearchQuery(value);
  };

  // Function to convert data to CSV format
  const convertToCSV = async () => {
    // Define the fields to include in the CSV
    const fieldsToInclude = [
      "firstname",
      "lastname",
      "email",
      "phoneno",
      "roleId",
      "planId",
      "instaUrl",
    ];

    // Filter the user data to include only the specified fields
    let filteredUsers;
    if (searchQuery !== "") {
      filteredUsers = usersData.map((user) => {
        const filteredUser = {};
        fieldsToInclude.forEach((field) => {
          if (field === "planId") {
            filteredUser[field] = getMembershipType(user);
          } else if (field === "roleId") {
            filteredUser[field] = get(user, `[${field}].roleName`, "");
          } else {
            filteredUser[field] = user[field];
          }
        });
        return filteredUser;
      });
    } else {
      const { data } = await getAllUsers();
      filteredUsers = data.data.getAllUserswoPagination.users.map((user) => {
        const filteredUser = {};
        fieldsToInclude.forEach((field) => {
          if (field === "planId") {
            filteredUser[field] = getMembershipType(user);
          } else if (field === "roleId") {
            filteredUser[field] = get(user, `[${field}].roleName`, "");
          } else {
            filteredUser[field] = user[field];
          }
        });
        return filteredUser;
      });
    }

    const header = fieldsToInclude.join(",");
    const csvData = filteredUsers.map((user) => Object.values(user).join(","));
    return `${header}\n${csvData.join("\n")}`;
  };

  // Function to trigger CSV download
  const handleExport = async () => {
    setIsLoading(true);
    const csvContent = await convertToCSV();
    setIsLoading(false);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "users.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const applySorting = (value) => {
    if (value === sortBy) {
      setSortBy("");
      setSortAnchor(null);
    } else {
      setSortBy(value);
      setSortAnchor(null);
    }
  };

  const applyFiltering = (event, value) => {
    console.info("applySorting value", value);
    if (value === filterBy) {
      setFilterBy("");
      setSubmenuAnchorEl(event.currentTarget);
    } else {
      setFilterBy(value);
      setSubmenuAnchorEl(null);
    }
  };

  const handleSortClick = (event) => {
    setSortAnchor(event.currentTarget);
  };

  const handleFilterClick = (event) => {
    setFilterAnchor(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchor(null);
  };

  const handleFilterClose = () => {
    setFilterAnchor(null);
  };

  const handleSubMenuLeave = () => {
    setSubmenuAnchorEl(null);
  };

  const handleSubMenuOpen = (event) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const getMembershipType = (data) => {
    const planName = data?.planId?.planName;
    const roleName = data.roleId?.roleName;
    let memebershipType = "Free";

    if (planName) {
      if (
        planName.toLowerCase().includes("free") &&
        roleName !== "Music Producer"
      ) {
        memebershipType = "Free";
      } else if (
        roleName === "Music Producer" &&
        data.accountStatus === "byPassPayment"
      ) {
        memebershipType =
        ACCOUNT_STATUS[data.accountStatus] || data.accountStatus;
      }
      else if (
        planName.toLowerCase().includes("month") && 
        data.accountStatus != "byPassPayment"
      ) {
        memebershipType = "Monthly";
      } else if (
        planName.toLowerCase().includes("year") && 
        data.accountStatus != "byPassPayment"
      ) {
        memebershipType = "Yearly";
      } else {
        if (roleName === "Music Producer") {
          memebershipType =
            ACCOUNT_STATUS[data.accountStatus] || data.accountStatus;
        }
      }
    } else {
      if (roleName === "Music Producer") {
        memebershipType =
          ACCOUNT_STATUS[data.accountStatus] || data.accountStatus;
      }
    }
    return memebershipType;
  };

  return (
    <div
      className={`${styles.sub_page_layout_no_padding} ${styles.admin_user_main}`}
    >
      <div className={styles.control_box}>
        <PageSearchHeader
          title="Users"
          searchBar="Search United Market Users"
          showFilter={false}
          showSort={true}
          showAddUser={true}
          showVerification={checkedItems.size > 0}
          showVerificationTitle="Verify Users"
          setSearchQuery={handleSearchQuery}
          handleAddUser={handleAddUser}
          verifyUsers={verifyUsers}
          handleExport={handleExport}
          showExport={true}
          showSortBy={true}
          sortData={sortData}
          sortAnchor={sortAnchor}
          filterAnchor={filterAnchor}
          handleSortClick={handleSortClick}
          handleFilterClick={handleFilterClick}
          applySorting={applySorting}
          applyFiltering={applyFiltering}
          handleSortClose={handleSortClose}
          handleFilterClose={handleFilterClose}
          handleSubMenuLeave={handleSubMenuLeave}
          submenuAnchorEl={submenuAnchorEl}
          handleSubMenuOpen={handleSubMenuOpen}
          setFilterBy={setFilterBy}
          adminUsers={true}
          showSearchBar={true}
        />
      </div>
      <div className={styles.table_sections}>
        <div className={styles.checkbox_column_admin}></div>

        <div className={styles.admin_profile_column_header}>
          <p className={styles.row_titles}>User</p>
        </div>
        <div className={styles.role_column}>
          <p className={styles.row_titles}>Role</p>
        </div>
        {/* <div className={styles.last_seen_column}>
          <p className={styles.row_titles}>Last Seen</p>
        </div> */}
        <div className={styles.membership_column}>
          <p className={styles.row_titles}>Membership Type</p>
        </div>
        <div className={styles.submissions_column}>
          <p className={styles.row_titles}>Submissions</p>
        </div>
        <div className={styles.edit_column}></div>
      </div>
      {isLoading ? (
        <Box className={styles.selected_beat_main_layout}>
          <Loader />
        </Box>
      ) : (
        <div className={styles.user_section_layout}>
          <div className={styles.admin_users_table_content}>
            {usersData.map((data, index) => (
              <div key={`userDataZ-${index}`} className={styles.user_content}>
                <div className={styles.admin_users_checkbox_column}>
                  {checkedItems.has(data._id) ? (
                    <div
                      className={styles.unchecked}
                      onClick={() => toggleCheck(data._id)}
                    >
                      <img
                        className={styles.unchecked_image}
                        src="/check.png"
                        alt="check"
                      ></img>
                    </div>
                  ) : (
                    <div
                      className={styles.checkbox}
                      onClick={() => toggleCheck(data._id)}
                    ></div>
                  )}
                </div>
                <div
                  className={`${styles.user_info} ${styles.admin_profile_column}`}
                >
                  <img
                    src={data.profilePic}
                    className={styles.user_image}
                    alt="user"
                  ></img>
                  <div className={styles.admin_user_info_text}>
                    <div>
                      <p className={styles.user_title}>
                        {data.firstname} {data.lastname}
                      </p>
                      <p className={styles.user_subtitle}>
                        <Tooltip placement="top" arrow title={data.email}>
                          <span className={styles.truncate}>{data.email}</span>
                        </Tooltip>
                      </p>
                    </div>
                  </div>
                  {data.isVerified && (
                    <div className={styles.verified}>
                      <img
                        src={allImages.verifiedTick}
                        alt={"profile"}
                        className={styles.verified_icon}
                      />
                    </div>
                  )}
                </div>

                <div
                  className={`${styles.role_type_box} ${styles.role_column}`}
                >
                  <p className={styles.info_title}>{data?.roleId?.roleName}</p>
                </div>
                {/* <div style={{ margin: "10px" }}></div>
                <div className={styles.last_seen_column}>
                  <p className={styles.info_title}>-</p>
                </div> */}
                <div className={styles.membership_column}>
                  <p className={styles.info_title}>{getMembershipType(data)}</p>
                </div>

                <div className={styles.submissions_column}>
                  <p className={styles.info_title_value}>
                    {data?.submissionCount || 0}
                  </p>
                </div>
                {/* <div
                  className={styles.edit_column}
                  onClick={() => {
                    handleUser(data);
                    setIsEdit(true);
                    setEditForm(true);
                  }}
                > */}

                {/* </div> */}
                <div className={styles.edit_column}>
                  <div
                    className={styles.edit_button}
                    style={{
                      marginBottom: "5px",
                    }}
                    onClick={() => {
                      handleUser(data);
                      // handleDeleteUser(data);
                      // setIsDelete(true);
                      handleIsDeleteOpen();
                    }}
                  >
                    <p className={styles.info_title}>Delete</p>
                  </div>
                  <div
                    className={styles.edit_button}
                    onClick={() => {
                      handleUser(data);
                      setIsEdit(true);
                      setEditForm(true);
                    }}
                  >
                    <p className={styles.info_title}>Edit</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* <div>xyzzzzzzzz</div> */}

      {/* <Pagination
        currentPage={currentPage}
        totalPages={totalCount}
        onPageChange={handlePageChange}
      /> */}

      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: "10px",
          marginBottom: "10px",
          justifyContent: "center",
        }}
      >
        <CustomPagination
          count={totalCount}
          boundaryCount={1}
          siblingCount={1}
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>

      <EditUser
        editForm={editForm}
        EditFormClose={() => {
          setEditForm(false);
          setIsEdit(false);
        }}
        user={user}
        isEdit={isEdit}
        fetchUsers={() =>
          loadUsers({
            variables: {
              pageNo: currentPage,
              perPage: numPerPage,
              query: "",
            },
          })
        }
        setUsersData={setUsersData}
        buttonText={["CANCEL", "SAVE"]}
      />

      <DeleteUserModal
        open={isDelete}
        onClose={handleIsDeleteClose}
        onDelete={handleDeleteUser}
        user={user}
      />
    </div>
  );
};

export default AdminUsers;
