import React from "react";
import { Grid } from "@mui/material";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import CreateSong from "../../components/AiSongwriterComponents/create_song";

const SongwriterPage = () => {
  return (
    <Grid>
      <Sidebar activeText="AI Songwriter" childComponent={<CreateSong />} />
    </Grid>
  );
};

export default SongwriterPage;
