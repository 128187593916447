import React from "react";
import { Grid } from "@mui/material";

import { Sidebar } from "../../layout/Sidebar/Sidebar";

import TempDashboard from "./TempDashboard";

const HomePage = () => {
  return (
    <Grid>
      <Sidebar childComponent={<TempDashboard />} activeText="Home" />
    </Grid>
  );
};

export default HomePage;
