import React from "react";
import SmartLinkLayout from "../../../components/smartlink/generalLayout/SmartLinkLayout";
import {
  HorizontalMenu,
  MainTitle,
  PageTitle,
} from "../../../components/smartlink";

const BoardInsight = () => {
  return (
    <>
      <SmartLinkLayout>
        <MainTitle title="Smart Link" hasbutton={false} />
        <HorizontalMenu />
        <PageTitle title="Board insight" hasNavigate={false} />
      </SmartLinkLayout>
    </>
  );
};

export default BoardInsight;
