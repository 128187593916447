/**\n * Ihtesham Nazir \n * Codistan pvt lt.\n */
import { Button, Image, Text, VStack, Box } from "native-base";
import React from "react";
import Modal from "@material-ui/core/Modal";
import { ThemeProvider, createTheme } from "@material-ui/core";
import styles from "./popup.module.css";
export function ConfirmDeletePopup(props) {
  const { openModal, closeModal, deleteFtn, desc } = props;

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const modalStyle = getModalStyle();

  return (
    <Modal open={openModal} onClose={closeModal}>
      <div style={modalStyle} className={styles.modalPaper}>
        <div className={styles.modal_header}>
          <div className={styles.counterweight}></div>
          <img
            className={styles.close_icon}
            src="/whiteClose.png"
            alt="trash"
            onClick={() => {
              closeModal();
            }}
          ></img>
        </div>
        <div className={styles.modal_body}>
          <img
            className={styles.delete_icon}
            src="/delete.png"
            alt="trash"
          ></img>
          <p className={styles.popup_text}> {desc}</p>

          <div className={styles.popup_buttons}>
            <div
              className={styles.cancel_button}
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </div>
            <div
              className={styles.delete_button}
              onClick={() => {
                deleteFtn();
                closeModal();
              }}
            >
              Delete
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
