import React from "react";
import { Grid } from "@mui/material";

import { Sidebar } from "../../layout/Sidebar/Sidebar";

import DirectChatComponent from "../../components/InboxComponents/directChatComponent";

const DirectChat = () => {
  return (
    <Grid>
      <Sidebar
        activeText="Single Chat"
        childComponent={<DirectChatComponent />}
      />
    </Grid>
  );
};

export default DirectChat;
