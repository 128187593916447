import React, { useEffect, createContext, useContext } from "react";

import { Box, Button, HStack } from "native-base";
import { useParams } from "react-router-dom";
import Calendly from "../../Calendly/Calendly";
import Referal from "./Referal";
import Sidebar from "./Sidebar";
import LoginForm from "./PreSignUpForm";
import { AuthContext } from "../Layout";
import "../../Authentication/Form.css";
import ReviewApplication from "./ReviewApplication";

const SignUp = ({ flag }) => {
  const {
    activeStep,
    setshowCalender,
    isEmail,
    showCalender,
    othersSocial,
    othersGenre,
  } = useContext(AuthContext);
  return (
    <Box minH={"100vh"}>
      {showCalender && (
        <Button
          opacity={0.6}
          position={"absolute"}
          backgroundColor="#000"
          zIndex={222}
          top={"0"}
          left="0"
          right="0"
          bottom="0"
          onPress={() => setshowCalender(false)}
        ></Button>
      )}
      {showCalender && <Calendly />}
      <HStack minH={"100vh"}>
        <div className="sidebarSignup">
          <Sidebar />
        </div>
        <div style={{ width: "55%" }} className="containerSignup">
          {activeStep === 0 && (
            <LoginForm
              title="choose your account type!"
              subtitle="I’m a/an"
              label=""
              btnText="Next"
              type="account type"
            />
          )}

          {activeStep === 1 && (
            <LoginForm
              title="Get Registered On United Market"
              subtitle="Enter Your Email Address "
              label="Email Address:"
              btnText="next"
              type={"email"}
              //  type2={"phoneNumber"}
            />
          )}
          {activeStep === 2 && (
            <LoginForm
              title="Get Registered On United Market"
              subtitle="Enter Your Phone Number"
              label="Phone Number:"
              // label2="phone number"
              btnText="next"
              type={"phoneNumber"}
              // type2={"phoneNumber"}
            />
          )}
          {activeStep === 3 && (
            <LoginForm
              smallText={`Please provide the OTP code sent to your ${
                isEmail ? "email" : "phone number"
              }`}
              title="Please Verify Your Account"
              subtitle="Enter Your Verification Code"
              label="OTP:"
              btnText="Submit Code"
              type="otp"
            />
          )}
          {activeStep === 4 && (
            <LoginForm
              title="Create Your Password"
              subtitle="Enter Your Password"
              label="Password:"
              label2="Confirm Password:"
              btnText="next"
              type={"password"}
              type2={"confirmPassword"}
            />
          )}
          {activeStep === 5 && (
            <LoginForm
              title="Add Your Instagram"
              subtitle="Enter your Instagram Link"
              label="Instagram:"
              btnText="next"
              type="email_username"
            />
          )}

          {activeStep === 6 && (
            <LoginForm
              title="What Is Your Musical Genre?"
              subtitle=""
              btnText="submit"
            />
          )}
          {othersGenre && activeStep === 7 && (
            <LoginForm
              title="What Is Your Musical Genre?"
              subtitle=""
              btnText="finish"
              label="others"
              type="others"
            />
          )}
          {((!othersGenre && activeStep === 7) ||
            (othersGenre && activeStep === 8)) && (
            <LoginForm
              title="add your profile info"
              subtitle=""
              label="Preferred Username:"
              type="username"
              label1="First Name:"
              type1={"firstName"}
              label2="Last Name:"
              type2={"lastName"}
              btnText="Next"
            />
          )}
          {((!othersGenre && activeStep === 8) ||
            (othersGenre && activeStep === 9)) && (
            <LoginForm
              // title='Choose Your Membership'
              // subtitle='You will not be charged unless you’re accepted.'
              //subtitle={`Subscribe to Early Member Pricing (${accountType})`}
              btnText="Add Card Info"
              type=""
            />
          )}
          {((!othersGenre && activeStep === 9) ||
            (othersGenre && activeStep === 10)) && (
            <LoginForm
              title="Would You Be Interested In Publishing Through Us?"
              subtitle=""
              btnText="submit"
            />
          )}
          {((!othersGenre && othersSocial && activeStep === 10) ||
            (othersGenre && othersSocial && activeStep === 11)) && (
            <LoginForm
              title="Would You Be Interested In Publishing Through Us?"
              subtitle=""
              btnText="finish"
              label="others"
              type="others"
            />
          )}
          {((!othersGenre && !othersSocial && activeStep === 10) ||
            (othersSocial && !othersGenre && activeStep === 11) ||
            (!othersSocial && othersGenre && activeStep === 11) ||
            (othersSocial && othersGenre && activeStep === 12)) && (
            <ReviewApplication />
          )}
        </div>
      </HStack>
    </Box>

    // </AuthContext.Provider>
  );
};

export default SignUp;
