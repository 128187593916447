import gql from "graphql-tag";

export const getUsers = gql`
  query searchUserGlobaly(
    $query: String
    $pageNo: Float
    $perPage: Float
    $sortBy: String
    $order: String
    $filter: UserFilterInput
  ) {
    searchUserGlobaly(
      userSearchRequest: {
        query: $query
        pageNo: $pageNo
        perPage: $perPage
        sortBy: $sortBy
        order: $order
        filter: $filter
      }
    ) {
      success
      message
      users {
        _id
        firstname
        lastname
        email
        spotifyUrl
        noOfMonthlyListeners
        howDidYouKnow {
          name
        }
        phoneno
        isSuspended
        isFreemium
        profilePic
        userName
        opportunityId
        isSoundPackAllowed
        genre
        isPaid
        isAdminReviewed
        planId {
          planName
          priceId
        }

        subscriptionStatus
        accountStatus
        roleId {
          roleName
          _id
        }
        instaUrl
        rolePrivilege {
          privileges {
            isRead
            isDelete
            isUpdate
            isCreate
          }
        }
        submissionCount
        isVerified
      }
      isLastPage
      totalCount
    }
  }
`;
