import React from "react";
import { Main } from "./styles/style";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import theme from "../../../../components/smartlink/theme";
import { ToastContainer } from "react-toastify";

const ProducersLayouts = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Main>{children}</Main>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
};

export default ProducersLayouts;
