import React from "react";

import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';

import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';

import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined';

import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined';
const AudioControls = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick
}) => (
  <div className="audio-controls">
    {/* <SkipPreviousOutlinedIcon sx={{ fontSize: "36px", color: "white" }} onClick={onPrevClick} /> */}
    {isPlaying ? (
      <PauseCircleFilledOutlinedIcon sx={{ fontSize: "36px", color: "white" }} onClick={() => onPlayPauseClick(false)} />
    ) : (
      <PlayCircleFilledOutlinedIcon sx={{ fontSize: "36px", color: "white" }} onClick={() => onPlayPauseClick(true)} />
    )}
    {/* <SkipNextOutlinedIcon sx={{ fontSize: "36px", color: "white" }} onClick={onNextClick} /> */}
  </div>
);

export default AudioControls;
