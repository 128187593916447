import { beatInboxTest } from "../apis/beat-inbox";

const useBeatInbox = () => {
  const testBeatInboxSubmissionsEndpoint = async (userId) => {
    try {
      const chats = await beatInboxTest(userId);
      return chats;
    } catch (error) {
      console.log("Error while testing: ", error);
      throw error;
    }
  };

  return {
    testBeatInboxSubmissionsEndpoint,
  };
};

export default useBeatInbox;
