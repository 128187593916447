import React, { useEffect, useState } from "react";
import styles from "../SubmissionsComponents/submissions.module.css";
import searchIcon from "../../assets/Icons/searchIcon.png";
import useAppState from "../../context/useAppState";
import SyncCard from "../OpportunitiesComponents/Sync/SyncCard";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import SyncSubmissionCard from "../SubmissionsComponents/SyncSubmissions/syncSubmissionCard";
import SelectBeatInfoData from "./SelectBeatInfoData";
import { useDebounce } from "../../hooks/useDebounce";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { getSubmission } from "../../context/apis/sync";
import { last, size } from "lodash";
import { toast } from "react-toastify";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const PAGE_LIMIT = 10;

function SyncSubmission() {
  const { id } = useParams();
  const { data, fetchOpportunityById, resetOpportunity } = useAppState("sync");
  const { data: submissionListData, fetchSubmissionById } = useAppState("sync");
  const {
    data: submissionDetailsData,
    fetchSubmissionDetailsById,
    fetchSubmissionDownloadFileList,
  } = useAppState("sync");
  const { downloadAllBeat } = useAppState("file");
  const [listData, setListData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [isSelected, setIsSelected] = useState(false);

  const searchQuery = useDebounce(searchText, 500);

  const [loading, setLoading] = useState(false);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [error, setError] = useState(false);
  const [sortBy, setSortBy] = useState("createdAt");
  const [page, setPage] = useState(0);

  useEffect(() => {
    setLoading(true);
    try {
      const queryString = new URLSearchParams({
        page: 1,
        limit: PAGE_LIMIT,
        search: searchQuery,
        sort: sortBy,
      }).toString();
      getSubmission(id, queryString)
        .then((response) => {
          const newRows = [...response.data];

          setHasNextPage(newRows.length < response.totalCount);
          setListData(newRows);
          setPage((page) => page + 1);
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setError(true);
    }
  }, [searchQuery, sortBy]);

  const getMoreData = async () => {
    setLoading(true);
    try {
      const newPage = page + 1;

      const queryString = new URLSearchParams({
        page: newPage,
        limit: PAGE_LIMIT,
        search: searchQuery,
        sort: sortBy,
      }).toString();
      getSubmission(id, queryString)
        .then((response) => {
          const newRows = [...listData, ...response.data];

          setHasNextPage(newRows.length < response.totalCount);
          setListData(newRows);
          setPage((page) => page + 1);
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setError(true);
    }
  };

  const fetchMore = () => {
    console.log("fetch more...");
    if (loading) return;
    getMoreData();
  };

  useEffect(() => {
    setPage(0);
  }, [sortBy]);

  useEffect(() => {
    if (id) {
      fetchOpportunityById(id);
    }

    return () => {
      resetOpportunity();
    };
  }, [id]);

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  const downloadFiles = async () => {
    setIsSelected(true);
    const data = await fetchSubmissionDownloadFileList(id);

    if (data.length > 0) {
      const fileData =
        size(data) > 0 &&
        data.map((element) => {
          const file = element.file;
          const url = file && file.url;
          const filename = url && decodeURIComponent(last(url.split("/")));
          return { path: url, filename: filename };
        });

      const fileList = fileData;

      const zip = new JSZip();
      const folder = zip.folder("files"); // folder name where all files will be placed in
      fileList.forEach((url) => {
        const blobPromise = fetch(url.path).then((r) => {
          if (r.status === 200) return r.blob();
          return Promise.reject(new Error(r.statusText));
        });
        const name = url.filename;
        folder.file(name, blobPromise);
      });
      zip.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, "AllBeat");
        setIsSelected(false);
        toast.success("Successfully downloaded");
      });
    } else {
      toast.error("Please try again  after some time");
    }
  };

  return (
    <>
      <div className={styles.main_layout}>
        <SyncCard
          displayOnly={true}
          opportunityData={data}
          shouldRenderEditPauseButtons={true}
        />
        <div className={styles.spacer}></div>
        <div className={styles.top_controls}>
          <div className={styles.top_controls_top}>
            <p
              className={styles.page_title}
              style={{
                color: "#fff",
                fontFamily: "Poppins",
                fontSize: "40px",
                margin: "0",
              }}
            >
              Submissions
            </p>
            {/* <div className={styles.filter_box}>
              <img
                className={styles.filter_icon}
                src={filter}
                alt="filter"
                onClick={toggleSortOrder}
              ></img>
            </div> */}
            {listData.length > 0 && (
              <div>
                <div
                  className={
                    isSelected
                      ? styles.disabled_download_button
                      : styles.download_button
                  }
                  onClick={downloadFiles}
                  disabled={isSelected}
                >
                  Download All
                </div>
              </div>
            )}
          </div>
          <div className={styles.search_box}>
            <img
              className={styles.search_icon}
              src={searchIcon}
              alt="search"
            ></img>
            <input
              className={styles.search_box_input}
              type="text"
              placeholder="Search my submissions"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>

        <Box sx={{ marginTop: "21px" }}>
          {listData.length > 0 ? (
            listData.map((list, index) => (
              <SyncSubmissionCard
                key={index}
                isShowMusicProducerName={true}
                opportunity={data}
                onView={() => {}}
                isShowBeatDetail={true}
                isShowFlag={true}
                isShowFavorite={true}
                showDownloadbtn={true}
                isShowView={true}
                data={list}
                selectBeatInfo={<SelectBeatInfoData data={list} />}
              />
            ))
          ) : (
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Typography style={{ color: "#fff" }}>No Data Found</Typography>
            </Box>
          )}

          {(loading || hasNextPage) && (
            <Grid container justifyContent={"center"} ref={sentryRef}>
              <Grid item>
                <Typography style={{ color: "#2ebb55" }}>Loading..</Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </div>
    </>
  );
}

export default SyncSubmission;
