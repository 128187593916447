import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ReactPhoneInput from "react-phone-input-2";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import { InputLabel, TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import styles from "./styles.module.css";
import apis from "../../../../AdminAxiosInstance/index";
import { makeStyles } from "@material-ui/core/styles";
import {
  attchFreemiumPlan,
  updateUserRequest,
  updateUserAccountStatus,
} from "../../../../AdminGraphqlRequest/User/UserRequests";
import allImages from "../../../../assets";
import { ForceLogout } from "../../../../helpers/forceLogout";
import { byPassPayment } from "../../../../AdminAxiosInstance/index";

const MenuProps = {
  PaperProps: {
    style: {
      width: 240,
      backgroundColor: "#1C1B1B",
      color: "#afafaf",
    },
  },
};
const getMembershipType = (data) => {
  const planName = data?.planId?.planName;
  let memebershipType = "Free";
  if (
    data?.role?.roleName === "Music Producer" &&
    data.accountStatus === "byPassPayment"
  ) {
    memebershipType = data.accountStatus;
  } else {
    if (planName) {
      if (planName.toLowerCase().includes("free")) {
        memebershipType = "Free";
      } else if (planName.toLowerCase().includes("month")) {
        memebershipType = "Monthly";
      } else if (planName.toLowerCase().includes("year")) {
        memebershipType = "Yearly";
      }
    }
  }
  return memebershipType;
};

const UserForm = (props) => {
  const classes = useStyles();
  const {
    editForm,
    EditFormClose,
    user,
    isEdit,
    isView,
    isReadOnly,
    setUsersData,
  } = props;
  const schema = yup.object().shape({
    firstname: yup.string().required(),
    username: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().when("isEdit", {
      is: true,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required(),
    }),
    phoneno: yup
      .number()
      .required()
      .typeError("Please enter a valid phone number"),
    lastname: yup.string().required(),
    role: yup
      .object()
      .shape({
        roleName: yup.string(),
        _id: yup.string(),
      })
      .required()
      .typeError("role is required"),
    isAllowOpportunities: yup.string(),
    isVerified: yup.string(),
    membershipType: yup.string().required("Membership is required"),
    profilePic: yup.string().required("profile image is required"),
    instaUrl: yup.string().required("Instagram  is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      isEdit: true,
    },
    resolver: yupResolver(schema),
  });
  const [roles, getRoles] = useState([]);
  const [isClose, setIsClose] = useState(false);

  const { role } = watch();

  const getDefaultValues = () => {
    let membershipType = isEdit && !isClose ? getMembershipType(user) : "Free";

    const planName = user?.planId?.planName;

    const roleName = user?.roleId?.roleName;

    if (
      roleName === "Music Producer" &&
      planName &&
      planName.toLowerCase().includes("free")
    ) {
      membershipType = user.accountStatus;
      setValue("membershipType", user.accountStatus, { shouldTouch: true });
    }

    reset({
      email: isEdit && !isClose ? user?.email : "",
      username: isEdit && !isClose ? user?.userName : "",
      firstname: isEdit && !isClose ? user?.firstname : "",
      password: "",
      phoneno: isEdit && !isClose ? "+" + user?.phoneno : null,
      profilePic: isEdit && !isClose ? user?.profilePic : "",
      lastname: isEdit && !isClose ? user?.lastname : "",
      role: isEdit && !isClose ? user?.roleId : null,
      membershipType,
      isVerified: isEdit && !isClose ? user?.isVerified : false,
      isAllowOpportunities:
        isEdit && !isClose
          ? user?.rolePrivilege?.privileges[0]?.isCreate
          : false,
      instaUrl: isEdit && !isClose ? user?.instaUrl : "",
      isEdit: true,
    });
  };
  useEffect(() => {
    fetchRoles();
    getDefaultValues();
  }, []);
  useEffect(() => {
    if (isClose) {
      getDefaultValues();
      setIsClose(false);
    }
  }, [isClose]);
  useEffect(() => {
    const planName = user?.planId?.planName;

    const roleName = user?.roleId?.roleName;
    let memebershipType = isEdit && !isClose ? getMembershipType(user) : "Free";

    if (roleName === "Music Producer" && user.accountStatus === "byPassPayment") {
      memebershipType = user.accountStatus;
      setValue("membershipType", user.accountStatus, { shouldTouch: true })
    }

    if (
      roleName === "Music Producer" &&
      planName &&
      planName.toLowerCase().includes("free")
    ) {
      memebershipType = user.accountStatus;
      setValue("membershipType", user.accountStatus, { shouldTouch: true });
    }

    reset({
      email: isEdit && !isClose ? user?.email : "",
      username: isEdit && !isClose ? user?.userName : "",
      phoneno: isEdit && !isClose ? "+" + user?.phoneno : null,
      password: "",
      profilePic: isEdit && !isClose ? user?.profilePic : "",
      firstname: isEdit && !isClose ? user?.firstname : "",
      lastname: isEdit && !isClose ? user?.lastname : "",
      role: isEdit && !isClose ? user?.roleId : null,
      membershipType: memebershipType,
      isVerified: isEdit && !isClose ? user.isVerified : "",
      isAllowOpportunities:
        isEdit && !isClose ? user?.rolePrivilege?.privileges[0]?.isCreate : "",
      instaUrl: isEdit && !isClose ? user?.instaUrl : "",
      isEdit: true,
    });
    setValue("isEdit", isEdit);
  }, [user, isEdit]);

  useEffect(() => {
    if (isView) {
      const planName = user?.planId?.planName;

      const roleName = user.roleId?.roleName;
      let memebershipType = user.membershipType;

      if (
        roleName === "Music Producer" &&
        planName &&
        planName.toLowerCase().includes("free")
      ) {
        memebershipType = user.accountStatus;
        setValue("membershipType", user.accountStatus, { shouldTouch: true });
      }

      const payload = {
        email: user?.email,
        username: user?.userName,
        phoneno: user?.phoneno,
        password: "",
        profilePic: user?.profilePic,
        firstname: user?.firstname,
        lastname: user?.lastname,
        role: user?.roleId,
        membershipType: memebershipType,
        isVerified: user.isVerified,
        isAllowOpportunities: user?.rolePrivilege?.privileges[0]?.isCreate,
        instaUrl: user?.instaUrl,
      };
      Object.entries(payload).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [user, isView]);

  const fetchRoles = () => {
    let token = localStorage.getItem("token");
    var data = JSON.stringify({
      query: `query{
                getRoles{
                  roleName
                  _id
                }
              }`,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_AUTH_URL}/auth`,
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const rolesArray = response.data.data.getRoles;
        getRoles(rolesArray.filter((role) => role.roleName !== "Admin"));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const uploadProfile = (pic) => {
    let token = localStorage.getItem("token");
    var data = new FormData();
    data.append("file", pic);
    data.append("type", "Public");
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_FILE_URL}/file/uploadprofilepicture`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setValue("profilePic", response.data.file.url, {
          shouldValidate: true,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleCover = (val) => {
    uploadProfile(val.target.files[0]);
  };
  const freemiumPlan = (id) => {
    attchFreemiumPlan(id)
      .then((res) => {
        const { attachFreemiumPlanAsAdmin } = res;
        if (attachFreemiumPlanAsAdmin) {
          if (attachFreemiumPlanAsAdmin.success === true) {
            toast.success("User created Successfully");
            setIsClose(true);
            EditFormClose();
            props.fetchUsers();
          }
          if (attachFreemiumPlanAsAdmin.success === false) {
            if (attachFreemiumPlanAsAdmin.isTokenExpired) {
              ForceLogout();
            } else {
              apis
                .deleteUsers(user)
                .then((res) => {
                  const { deleteUser } = res;
                  if (deleteUser.success === true) {
                  }
                  if (deleteUser.success === false) {
                    toast.error(deleteUser.message);
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(`Couldn't create user, ${error}`);
      });
  };
  const onSubmitHandler = async (data) => {
    if (isEdit) {
      let {
        email,
        profilePic,
        firstname,
        lastname,
        username,
        role,
        isAllowOpportunities,
        isVerified,
        instaUrl,
      } = data;
      const allowCreate = isAllowOpportunities === "true" ? true : false;
      const verified = isVerified === "true" ? true : false;

      let membershipType = data.membershipType;
      let isFreemium = true;

      if (data?.role?.roleName === "Music Producer") {
        if (membershipType === "byPassPayment") {
          membershipType = "Free";
        } else {
          isFreemium = false;
        }
      }

      const response = await updateUserRequest(
        email,
        profilePic,
        username,
        firstname,
        lastname,
        role._id,
        user._id,
        user?.phoneno,
        allowCreate,
        verified,
        membershipType,
        instaUrl,
        "",
        isFreemium,
      );
      if (response?.updateUserAsAdmin?.success) {
        if (data?.role?.roleName === "Music Producer") {
          if (data.membershipType === "byPassPayment") {
            await byPassPayment({id: user._id, isPaid: true});
            await updateUserAccountStatus("byPassPayment", user._id);
          } else {
            await updateUserAccountStatus("paymentPending", user._id);
          }
        }
        toast.success("User updated successfully");
        setIsClose(true);
        EditFormClose();
        await props.fetchUsers();
      } else {
        if (response.updateUserAsAdmin.isTokenExpired) {
          // ForceLogout();
        } else {
          toast.error(response.updateUserAsAdmin.message);
        }
      }
    } else if (isView) {
      // this is for accept user
      props.handleApproveUser(user._id);
    } else {
      const {
        firstname,
        lastname,
        email,
        password,
        username,
        phoneno,
        role,
        isVerified,
        isAllowOpportunities,
        profilePic,
        instaUrl,
      } = data;
      let membershipType = data.membershipType;
      let isFreemium = true;

      const allowCreate = isAllowOpportunities === "yes" ? true : false;
      const verified = isVerified === "true" ? true : false;

      if (data?.role?.roleName === "Music Producer") {
        if (membershipType === "byPassPayment") {
          membershipType = "Free";
        } else {
          isFreemium = false;
        }
      }

      apis
        .createUsers(
          firstname,
          lastname,
          email,
          password,
          username,
          phoneno,
          role._id,
          verified,
          allowCreate,
          profilePic,
          membershipType,
          instaUrl,
          isFreemium,
        )
        .then(async (res) => {
          if (res.status === 200) {
            if (res?.data?.data?.signUp.success === true) {
              if (
                data?.role?.roleName === "Music Producer" &&
                data.membershipType === "byPassPayment"
              ) {
                await updateUserAccountStatus(
                  "byPassPayment",
                  res.data.data.signUp.user._id,
                );
                await byPassPayment({
                  id:  res.data.data.signUp.user._id,
                  isPaid: true
                });
              } else {
                await updateUserAccountStatus(
                  "paymentPending",
                  res.data.data.signUp.user._id,
                );
              }
              toast.success("User created Successfully");
              setIsClose(true);
              EditFormClose();
              props.fetchUsers();
            } else {
              toast.error(res?.data?.data?.signUp.message);
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          toast.error(`Couldn't create user, ${error}`);
        });
    }
  };

  useEffect(() => {
    if (role && role.roleName && role.roleName !== "Music Producer") {
      setValue("membershipType", "Free", { shouldTouch: true });
    } else {
      if (!isEdit) {
        setValue("membershipType", "", { shouldTouch: true });
      }
    }
  }, [role]);

  return (
    <div>
      <Dialog open={editForm} scroll={"body"}>
        <DialogTitle className={styles.modalHeader}>
          {isView && "View User"}
          {isEdit && "Edit User"}
          {!isView && !isEdit && "Create User"}
          <IconButton
            className={styles.modalCloseBut}
            aria-label="close"
            onClick={() => {
              setIsClose(true);
              EditFormClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <DialogContent sx={{ background: "#1C1B1B", color: "#ffffff" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ alignSelf: "center", px: 5, mx: 2 }}>
                <Box className={styles.editUserWrap}>
                  {getValues("profilePic") ? (
                    <Box>
                      <img
                        className={styles.editUserImg}
                        src={getValues("profilePic")}
                        alt={"profile"}
                      />
                    </Box>
                  ) : (
                    <Box>
                      <img
                        className={styles.editUserImg}
                        src={allImages.defaultProfile}
                        alt={"profile"}
                      />
                    </Box>
                  )}
                  <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={handleCover}
                  />
                  <label htmlFor="select-image">
                    {!isReadOnly && (
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={styles.userEditBtn}
                      >
                        Change profile picture
                      </Button>
                    )}
                  </label>
                  <p className={styles.errorMsg}>
                    {errors.profilePic?.message}
                  </p>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <FormGroup className={styles.editUserFormField}>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={styles.label}>First name</InputLabel>
                    <TextField
                      {...register("firstname")}
                      name="firstname"
                      placeholder="First name"
                      variant="outlined"
                      disabled={isReadOnly}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      className={styles.input_field}
                    />
                    <p className={styles.errorMsg}>
                      {errors.firstname?.message}
                    </p>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={styles.label}>Username</InputLabel>
                    <TextField
                      {...register("username")}
                      name="username"
                      placeholder="Username"
                      variant="outlined"
                      disabled={isReadOnly}
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      className={styles.input_field}
                    />
                    <p className={styles.errorMsg}>
                      {errors.username?.message}
                    </p>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <>
                      <InputLabel className={styles.label}>Email</InputLabel>
                      <TextField
                        {...register("email")}
                        name="email"
                        placeholder="Email"
                        variant="outlined"
                        disabled={isEdit || isReadOnly}
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        className={styles.input_field}
                        inputProps={{ maxLength: 50 }}
                      />
                      <p className={styles.errorMsg}>{errors.email?.message}</p>
                    </>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <>
                      <InputLabel className={styles.label}>
                        Instagram
                      </InputLabel>
                      <TextField
                        {...register("instaUrl")}
                        name="instaUrl"
                        placeholder="@username"
                        variant="outlined"
                        disabled={isReadOnly}
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        className={styles.input_field}
                        inputProps={{ maxLength: 50 }}
                      />
                      <p className={styles.errorMsg}>
                        {errors.instaUrl?.message}
                      </p>
                    </>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="roleLabel" className={styles.label}>
                      Role
                    </InputLabel>
                    <Controller
                      control={control}
                      name="role"
                      render={({ field }) => (
                        <Select
                          id="role"
                          {...field}
                          label="Role"
                          labelId="roleLabel"
                          className={classes.selectOptions}
                          style={{
                            height: "48px",
                            paddingTop: "16px",
                            backgroundColor: "#000",
                            border: "1px solid #000",
                          }}
                          variant="filled"
                          MenuProps={MenuProps}
                          renderValue={(selected) => {
                            if (selected?.length === 0) {
                              return <em>Choose Role</em>;
                            }
                            return selected?.roleName;
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                          readOnly={isReadOnly}
                        >
                          {roles.map((role, index) => (
                            <MenuItem
                              style={{ paddingLeft: "15px", display: "block" }}
                              key={`mainZ-${index}`}                              value={role}
                            >
                              {role.roleName}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <p className={styles.errorMsg}>{errors.role?.message}</p>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="membershipType" className={styles.label}>
                      Membership Type
                    </InputLabel>
                    <Controller
                      control={control}
                      name="membershipType"
                      render={({ field }) => (
                        <Select
                          id="membershipType"
                          {...field}
                          label="Membership Type"
                          labelId="membershipType"
                          className={classes.selectOptions}
                          style={{
                            height: "48px",
                            paddingTop: "16px",
                            backgroundColor: "#000",
                            border: "1px solid #000",
                          }}
                          variant="filled"
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          readOnly={
                            isReadOnly || role?.roleName !== "Music Producer"
                          }
                        >
                          {role?.roleName !== "Music Producer" && (
                            <MenuItem
                              style={{ paddingLeft: "15px", display: "block" }}
                              value={"Free"}
                            >
                              Free
                            </MenuItem>
                          )}
                          {role?.roleName === "Music Producer" && (
                            <MenuItem
                              style={{ paddingLeft: "15px", display: "block" }}
                              value={"Monthly"}
                            >
                              Monthly
                            </MenuItem>
                          )}
                          {role?.roleName === "Music Producer" && (
                            <MenuItem
                              style={{ paddingLeft: "15px", display: "block" }}
                              value={"Yearly"}
                            >
                              Yearly
                            </MenuItem>
                          )}
                          {role?.roleName === "Music Producer" && (
                            <MenuItem
                              style={{ paddingLeft: "15px", display: "block" }}
                              value={"byPassPayment"}
                            >
                              By Pass Payment
                            </MenuItem>
                          )}
                        </Select>
                      )}
                    />
                    <p className={styles.errorMsg}>
                      {errors.membershipType?.message}
                    </p>
                  </FormControl>
                </FormGroup>
              </Grid>

              <Grid item xs={6}>
                <FormGroup className={styles.editUserFormField}>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={styles.label}>Last name</InputLabel>
                    <TextField
                      {...register("lastname")}
                      name="lastname"
                      placeholder="Last name"
                      variant="outlined"
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      disabled={isReadOnly}
                      className={styles.input_field}
                    />
                    <p className={styles.errorMsg}>
                      {errors.lastname?.message}
                    </p>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={styles.label}>Password</InputLabel>
                    <TextField
                      {...register("password")}
                      name="password"
                      placeholder="Password"
                      variant="outlined"
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      className={styles.input_field}
                      inputProps={{ maxLength: 50 }}
                      disabled={isReadOnly}
                    />
                    <p className={styles.errorMsg}>
                      {errors.password?.message}
                    </p>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel className={styles.label}>
                      Phone Number
                    </InputLabel>
                    <TextField
                      {...register("phoneno")}
                      name="phoneno"
                      placeholder="Phone Number"
                      variant="outlined"
                      InputLabelProps={{
                        style: { color: "white" },
                      }}
                      className={styles.input_field}
                      inputProps={{ maxLength: 10 }}
                      disabled={isReadOnly}
                    />
                    <p className={styles.errorMsg}>{errors.phoneno?.message}</p>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      id="isAllowOpportunities"
                      className={styles.label}
                    >
                      Can post opportunities
                    </InputLabel>
                    <Controller
                      control={control}
                      name="isAllowOpportunities"
                      render={({ field }) => (
                        <Select
                          id="isAllowOpportunities"
                          {...field}
                          label="Can Post Opportunities"
                          labelId="isAllowOpportunities"
                          className={classes.selectOptions}
                          style={{
                            height: "48px",
                            paddingTop: "16px",
                            backgroundColor: "#000",
                            border: "1px solid #000",
                          }}
                          variant="filled"
                          MenuProps={MenuProps}
                          renderValue={(selected) => {
                            if (selected) {
                              return "Yes";
                            } else {
                              return "No";
                            }
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                          readOnly={isReadOnly}
                        >
                          <MenuItem
                            style={{ paddingLeft: "15px", display: "block" }}
                            value={true}
                          >
                            Yes
                          </MenuItem>
                          <MenuItem
                            style={{ paddingLeft: "15px", display: "block" }}
                            value={false}
                          >
                            No
                          </MenuItem>
                        </Select>
                      )}
                    />
                    <p className={styles.errorMsg}>{errors.role?.message}</p>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="isVerified" className={styles.label}>
                      Verified
                    </InputLabel>
                    <Controller
                      control={control}
                      name="isVerified"
                      render={({ field }) => (
                        <Select
                          id="isVerified"
                          {...field}
                          label="Role"
                          labelId="isVerified"
                          className={classes.selectOptions}
                          style={{
                            height: "48px",
                            paddingTop: "16px",
                            backgroundColor: "#000",
                            border: "1px solid #000",
                          }}
                          variant="filled"
                          MenuProps={MenuProps}
                          renderValue={(selected) => {
                            if (selected) {
                              return "Yes";
                            } else {
                              return "No";
                            }
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                          readOnly={isReadOnly}
                        >
                          <MenuItem
                            style={{ paddingLeft: "15px", display: "block" }}
                            value={true}
                          >
                            Yes
                          </MenuItem>
                          <MenuItem
                            style={{ paddingLeft: "15px", display: "block" }}
                            value={false}
                          >
                            No
                          </MenuItem>
                        </Select>
                      )}
                    />
                    <p className={styles.errorMsg}>{errors.role?.message}</p>
                  </FormControl>
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            className={styles.editBtnWrap}
            m={5}
            sx={{ background: "#1C1B1B" }}
          >
            <Button
              sx={{
                backgroundColor: "tranparent",
                color: "gray",
                border: "1px solid #ffffff00",
                width: "50%",
                "&:hover": {
                  backgroundColor: "transparent",
                  border: "1px solid gray",
                  color: "gray",
                },
              }}
              onClick={() => {
                EditFormClose();
                isView && props.handleReject(user._id);
              }}
            >
              {props.buttonText[0]}
            </Button>
            <Button
              sx={{
                backgroundColor: "#2EBA55",
                color: "white",
                border: "1px solid #2EBA55",
                width: "50%",
                "&:hover": { backgroundColor: "transparent", color: "#2EBA55" },
              }}
              type="submit"
            >
              {props.buttonText[1]}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default UserForm;

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "20px",
    minWidth: 230,
  },
  selectOptions: {
    "& .MuiListItem-root": {
      borderRadius: 20,
    },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "red",
      color: "red",
    },
    border: "1px solid #afafaf",
    borderRadius: "5px",
    color: "#afafaf",
    padding: "10px",
    height: "3.1876em",
    icon: {
      fill: "white",
    },
    root: {
      color: "white",
    },
  },
}));
