import React from "react";
import { Submitbtn } from "./style";
import CircularProgress from "@mui/material/CircularProgress";

const SubmittButton = ({
  name = "",
  type = "button",
  isloading = false,
  submitt,
}) => {
  const handleClick = () => {
    if (submitt && typeof submitt === "function") {
      submitt();
    }
  };

  return (
    <Submitbtn
      variant="contained"
      type={type}
      disabled={isloading}
      onClick={handleClick}
    >
      {isloading ? <CircularProgress size={20} sx={{ color: "#000" }} /> : name}
    </Submitbtn>
  );
};

export default SubmittButton;
