import React, { memo, useState, useRef, useEffect } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { toast } from 'react-toastify';
import { ModelContentBox, Modelstyle } from "../styles/style";
import { close_icon } from "../../../../assets/smartlink";
import BPMSlider from "../../AdditionalComponents/utils/BPMSlider";
import ToggleExpand from "../ToggleExpand";
import OutlineButton from "../../AdditionalComponents/OutlineButton";
import LibLineDivider from "../LibLineDivider";
import Select from "react-select";
import styles from "../Libraries.module.css";
import CreatableSelect from 'react-select/creatable';

import { KEY, SCALE, TAG_OPTIONS } from "../../../../shared/constants";
import BlueButtonComponents from "../../AdditionalComponents/utils/BlueButtonComponent";
import EditableText from "../EditableText";

import { updateAudioFile, getTags } from "../../../../context/apis/audio-library";
import { Notification } from "../../../NotifyMsg/Notification";
import MassEditModal from "./MassEditModal";

const EditCardModal = ({
  open = false,
  setOpen = () => { },
  editData = {},
  completeEdit,
}) => {


  const createModalClose = (event) => {
    setOpen(!open);
  };

  const handleCompleteEdit = (updatedData) => {
    setOpen(!open);
    completeEdit(updatedData);
  };
  const [file, setFile] = useState(editData.basicInfo.file);
  const [imageURL, setImageURL] = useState(
    editData?.basicInfo?.imageURL ?
      `${process.env.REACT_APP_AUDIO_LIBRARY_URL}/${editData?.basicInfo?.imageURL}` : '/images/no_image.png'
  );
  const [fileName, setFileName] = useState(editData.basicInfo.fileName);
  const [fileNote, setFileNote] = useState(editData.basicInfo.fileNote);
  const [tags, setTags] = useState(editData.basicInfo.tags);
  const [bpm, setBpm] = useState(editData.basicInfo.bpm);
  const [fileKey, setFileKey] = useState(editData.basicInfo.fileKey);
  const [scale, setScale] = useState(editData.basicInfo.scale);
  const inputRef = useRef(null);
  const [tagOptions, setTagOptions] = useState([]);


  const [massEditModalOpen, setMassEditModalOpen] = useState(false);
  const [audioFiles, setAudioFiles] = useState([]);

  const initialValues = {
    forms: [

    ],
  };

  const [dataList, setDataList] = useState(initialValues);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? 'blue' : 'gray', // Change border color when input is focused
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#afafaf', // Change text color of the input field
    }),
  };


  useEffect(async () => {
    console.log("Confrim Tags in masseditmodal-- ",);
    console.log("Edit Data=", editData);
    setDataList({
      forms: [editData],
    })
    const response = await getTags();
    const transformedTags = response?.data?.tagValues.map((tag) => ({
      value: tag,
      label: tag
    }));

    setTagOptions(transformedTags);
    console.log("Confrim Tages in masseditmodal-- ", transformedTags);
  }, [open]);

  const handleChange = (selectedOption) => {
    setTags(selectedOption);
  };

  const handleImageUpload = (e) => {
    const uploaded_file = e.target.files[0];
    const image_url = URL.createObjectURL(uploaded_file);
    setFile(uploaded_file);
    setImageURL(image_url);
  };

  const handleUpdate = async () => {
    const editedData = {
      // file: file, // Make sure to uncomment this line if the file needs to be part of the edited data
      imageURL: imageURL,
      fileName: fileName,
      fileNote: fileNote,
      tags: tags,
      bpm: bpm,
      fileKey: fileKey,
      scale: scale,
      isComplete: false
    };

    editedData.isComplete = (
      editedData.fileName != "" &&
      editedData.fileNote != "" &&
      editedData.imageURL != "" &&
      editedData.scale != "" &&
      editedData.fileKey != "" &&
      editedData.tags.length > 0 &&
      editedData.bpm != ""
    );
    await updateAudioFile(
      file,
      editedData,
      editData.basicInfo.userID,
      editData._id,
    ).then((response) => {
      if (response) {
        handleCompleteEdit(response);
        Notification("success", "Successfully Edited");
      } else {
        Notification("error", "Failed");
      }
    })
  };

  const openMassEditModal = () => {
    setMassEditModalOpen(true);
  }

  return (
    <>
      <Modal
        open={open}
        onClose={createModalClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <ModelContentBox
          className="MainInboxCreate"
          sx={{
            ...Modelstyle,
            width: "600px",
            height: "auto",
            display: "flex",
            padding: "24px",
            flexDirection: "column",
            borderRadius: "0.625rem",
            background: "#141515",
            overflowY: 'hidden'
          }}
        >
          <Box
            sx={{ height: "40px", display: "flex", justifyContent: "right" }}
          >
            <IconButton
              onClick={createModalClose}
              aria-label="settings"
              sx={{
                color: "#fff",
                //   color: theme.palette.primary.dark,
                backgroundColor: "none",
                fontSize: "2rem",
                padding: "0 !important",
                margin: "0 !important",
                marginTop: "-7px !important",
              }}
            >
              <img src={close_icon} alt="cancel-icon" />
            </IconButton>
          </Box>
          <div style={{ display: "flex", gap: "20px" }}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <img
                src={imageURL}
                style={{ borderRadius: "10px" }}
                width={136}
                height={136}
                onClick={() => inputRef.current.click()}
              />
            </div>
            <input
              type="file"
              ref={inputRef}
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "16px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <div>
                  <EditableText
                    text={fileName || "Untitled"}
                    onEdit={setFileName}
                    style={styles.edited_title}
                  />
                  <EditableText
                    text={fileNote || "Add a note"}
                    onEdit={setFileNote}
                    style={styles.edited_desc}
                  />
                </div>
                <img
                  src={`/images/tick-${imageURL != null &&
                    fileName != "" &&
                    scale != "" &&
                    fileKey != "" &&
                    tags.length > 0 &&
                    bpm != ""
                    ? "complete.png" : "incomplete.png"}`}
                  style={{ marginLeft: "10px", width: "20px", height: "20px" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>Tags</label>
                <div style={{ borderBottom: "1px solid white" }}>
                  <CreatableSelect
                    options={tagOptions}
                    isMulti
                    onChange={handleChange}
                    classNamePrefix="select_tags"
                    value={tags}
                    components={{ DropdownIndicator: null }}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div>
                <label>BPM</label>
                <BPMSlider value={bpm} setValue={setBpm} />
              </div>
              <LibLineDivider />

              <div>
                <label>Key</label>
                <div style={{ borderBottom: "1px solid #AFAFAF" }}>
                  <ToggleExpand primary={fileKey} menu={KEY} setValue={setFileKey} />
                </div>
              </div>
              <div>
                <label>Scale</label>
                <div style={{ borderBottom: "1px solid #AFAFAF" }}>
                  <ToggleExpand
                    primary={scale}
                    menu={SCALE}
                    setValue={setScale}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <BlueButtonComponents
                  text="Edit More Info"
                  background="transparent"
                  bordercolor="#fff"
                  onClickFunction={openMassEditModal}
                />
                <BlueButtonComponents
                  text="Finish Editing"
                  onClickFunction={handleUpdate}
                />
              </div>
            </div>
          </div>
        </ModelContentBox>
      </Modal>

      <MassEditModal
        massEditModalOpen={massEditModalOpen}
        setMassEditModal={setMassEditModalOpen}
        audioFiles={audioFiles}
        setAudioFiles={setAudioFiles}
        data={dataList}
        setDataList={setDataList}
        docID = {editData?._id}
        editMore={true}
        setCardEditModal = {setOpen}
      />
    </>
  );
};

export default memo(EditCardModal);
