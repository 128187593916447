import React, { useState } from "react";
import style from "./membership.module.css";
import { Grid, IconButton, Modal, Typography } from "@mui/material";
import check from "../../../assets/Images/tick-circle.svg";
import close from "../../../assets/Images/add-circle.svg";
import marker from "../../../assets/Images/mark.svg";
import greenCheck from "../../../assets/Images/Vector.svg";
import { Box } from "@mui/system";
import { close_icon } from "../../../assets/smartlink";
import congratulation from "../../../assets/Images/congratulation.svg";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import Slider from "react-slick";
import Card from "../../../assets/Images/card2.svg";

const ManageMembership = () => {
  const [open, setOpen] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [congrates, setCongrates] = useState(false);
  const [isActive, setIsActive] = useState("1");

  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "cvc":
        setCvc(value);
        break;
      case "expiry":
        setExpiry(value);
        break;
      case "name":
        setName(value);
        break;
      case "number":
        setNumber(value);
        break;
      default:
        break;
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className={style.container}>
        <h1 className={style.section_title}>Choose Your Plan</h1>
        <p className={style.section_tagline}>
          Select the membership that fits your needs
        </p>
        <div className={style.container2}>
          <div
            className={
              isActive === "1"
                ? style.membership_button_active
                : style.membership_button
            }
            onClick={() => setIsActive("1")}
            role="button"
          >
            Monthly
          </div>
          <div
            className={
              isActive === "2"
                ? style.membership_button_active
                : style.membership_button
            }
            onClick={() => setIsActive("2")}
            role="button"
          >
            Yearly
          </div>
        </div>
        <p className={style.section_paragraph}>
          We highly recommend our yearly package. If you choose yearly <br />
          Package so you can save your
          <span className={style.text_badge}>17%</span>
          amount
        </p>

        <Grid container spacing={2} justifyContent="center">
          <Grid item lg={4}>
            <div className={style.card}>
              <h1 className={style.card_title}>Free</h1>
              <p className={style.card_para}>Send files once in a while</p>
              <p className={style.card_price}>$0</p>
              <div className={style.card_button}>Subscribed</div>
              <div className={style.card_list}>
                <img src={check} alt="check" height={16} />
                <p className={style.card_list_para}>15 GB storage space</p>
              </div>
              <div className={style.card_list}>
                <img src={check} alt="check" height={16} />
                <p className={style.card_list_para}>
                  3 Opportunity Submissions per month
                </p>
              </div>
              <div className={style.card_list}>
                <img src={check} alt="check" height={16} />
                <p className={style.card_list_para}>$5.75 per contract sent</p>
              </div>
              <div className={style.card_list}>
                <img src={check} alt="check" height={16} />
                <p className={style.card_list_para}>$5.75 per contract sent</p>
              </div>
              <div className={style.card_list}>
                <img src={check} alt="check" height={16} />
                <p className={style.card_list_para}>Create up to 5 Inboxs</p>
              </div>
              <div className={style.card_list}>
                <img src={close} alt="check" height={20} />
                <p className={style.card_list_para}>
                  Can not track audio files status or analytics
                </p>
              </div>
              <div className={style.card_list}>
                <img src={close} alt="check" height={20} />
                <p className={style.card_list_para}>
                  Audio Files are not protected with our blockchain technology
                </p>
              </div>
            </div>
          </Grid>
          <Grid item lg={4}>
            <div className={style.card}>
              <h1 className={style.card_title}>Hitmaker</h1>
              <p className={style.card_para}>Send files once in a while</p>
              <p className={style.card_price}>
                $99/{isActive === "1" ? "mo" : "year"}
              </p>
              <div
                role="button"
                onClick={() => setOpen(true)}
                className={style.card_button_active}
              >
                Get Started
              </div>
              <div className={style.card_list}>
                <img src={check} alt="check" height={16} />
                <p className={style.card_list_para}>50 GB storage space</p>
              </div>
              <div className={style.card_list}>
                <img src={check} alt="check" height={16} />
                <p className={style.card_list_para}>
                  Unlimited Opportunity Submissions
                </p>
              </div>
              <div className={style.card_list}>
                <img src={check} alt="check" height={16} />
                <p className={style.card_list_para}>
                  Unlimited Beat Inbox Creations
                </p>
              </div>
              <div className={style.card_list}>
                <img src={check} alt="check" height={16} />
                <p className={style.card_list_para}>
                  Can track audio files & will be notified of any interaction
                  with their audio file
                </p>
              </div>
              <div className={style.card_list}>
                <img src={check} alt="check" height={16} />
                <p className={style.card_list_para}>$2.75 per contract</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {open && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={style.modal}>
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  right: "-20px",
                  top: "-45px",
                }}
              >
                <IconButton
                  onClick={() => setOpen(false)}
                  aria-label="settings"
                  sx={{
                    color: "#fff",
                    backgroundColor: "none",
                    fontSize: "2rem",
                    padding: "0 !important",
                    margin: "0 !important",
                  }}
                >
                  <img src={close_icon} alt="cancel-icon" />
                </IconButton>
              </Box>
              <h1 className={style.modal_title}>Buy a Membership</h1>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img src={marker} alt="markers" height={40} />
                <h1 className={style.modal_price}>
                  $99
                  <span className={style.modal_price_year}>{isActive === "1" ? '/Monthly' : '/Yearly'}</span>
                </h1>
              </Box>
              <hr className={style.h_r} />
              <h1 className={style.modal_title2}>Features</h1>
              <Grid container spacing={2}>
                <Grid item lg={6}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <img src={greenCheck} alt="markers" height={14} />
                    <h1 className={style.modal_price_year}>
                      Unlimited Storage
                    </h1>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <img src={greenCheck} alt="markers" height={14} />
                    <h1 className={style.modal_price_year}>
                      Unlimited Submissions
                    </h1>
                  </Box>
                </Grid>
                <Grid item lg={12}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <img src={greenCheck} alt="markers" height={14} />
                    <h1 className={style.modal_price_year}>
                      Sell & Upload Unlimited Soundpacks
                    </h1>
                  </Box>
                </Grid>
              </Grid>
              <hr className={style.h_r} />

              <p className={style.modal_para}>
                By clicking "Get Elite!" below, you agree to our Terms of Use
                and Privacy Policy. You also understand that you will be billed
                $9.99, which will automatically renew every 1 months. You can
                cancel at any time.
              </p>

              <div
                role="button"
                className={style.modal_button}
                onClick={() => {
                  setOpen(false);
                  setPaymentModal(true);
                }}
              >
                Get Hitmaker
              </div>
            </Box>
          </Box>
        </Modal>
      )}

      {paymentModal && (
        <Modal
          open={paymentModal}
          onClose={() => setPaymentModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={style.modal}>
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  right: "-20px",
                  top: "-20px",
                }}
              >
                <IconButton
                  onClick={() => setPaymentModal(false)}
                  aria-label="settings"
                  sx={{
                    color: "#fff",
                    backgroundColor: "none",
                    fontSize: "2rem",
                    padding: "0 !important",
                    margin: "0 !important",
                  }}
                >
                  <img src={close_icon} alt="cancel-icon" />
                </IconButton>
              </Box>
              {/* <Cards
                cvc={cvc}
                expiry={expiry}
                focused={focus}
                name={name}
                number={number}
              /> */}

              <h1 className={style.modal_title3}>Pay Now</h1>

              {true ? (
                <div className="slider-container">
                  <Slider {...settings}>
                    <div>
                      <h3
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img src={Card} alt="card" height={180} />
                      </h3>
                    </div>
                    <div>
                      <h3
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img src={Card} alt="card" height={180} />
                      </h3>
                    </div>
                    <div>
                      <h3
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img src={Card} alt="card" height={180} />
                      </h3>
                    </div>
                  </Slider>
                </div>
              ) : (
                <Box
                  sx={{
                    height: "240px",
                    width: "260px",
                    backgroundColor: "#000000",
                    border: "1px dashed #424142",
                    borderRadius: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginX: "auto",
                  }}
                >
                  <Box sx={{ fontSize: "18px", fontWeight: "400" }}>
                    Add a card
                  </Box>
                </Box>
              )}

              <h1 className={style.modal_title2}>Order Summary</h1>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1 className={style.modal_label}>Plan Selected:</h1>
                <h1 className={style.modal_value}>Elite</h1>
              </Box>
              <hr className={style.h_r2} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1 className={style.modal_label}>Total Amount:</h1>
                <h1 className={style.modal_value_green}>$99/{isActive === "1" ? 'mo' : 'year'}</h1>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1 className={style.modal_title2}>Card Detail</h1>
                <h1 className={style.modal_value_green}>Add Card</h1>
              </Box>

              <Grid container spacing={2}>
                <Grid item lg={6}>
                  <h1 className={style.modal_price_year}>Name on Card</h1>
                  <input
                    type="text"
                    name="name"
                    className={style.inputBoxText}
                    placeholder="Zuraid Zameer"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </Grid>
                <Grid item lg={6}>
                  <h1 className={style.modal_price_year}>Card Number</h1>
                  <input
                    type="text"
                    name="number"
                    className={style.inputBoxText}
                    placeholder="1253 5432 3521 3090"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </Grid>
                <Grid item lg={6}>
                  <h1 className={style.modal_price_year}>Expiration Date</h1>
                  <input
                    type="text"
                    name="expiry"
                    className={style.inputBoxText}
                    placeholder="09/27"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </Grid>
                <Grid item lg={6}>
                  <h1 className={style.modal_price_year}>CVV</h1>
                  <input
                    type="text"
                    name="cvc"
                    className={style.inputBoxText}
                    placeholder="341"
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </Grid>
              </Grid>
              <br />
              <br />

              <div
                role="button"
                className={style.modal_button}
                onClick={() => {
                  setPaymentModal(false);
                  setCongrates(true);
                }}
              >
                Continue
              </div>
            </Box>
          </Box>
        </Modal>
      )}

      {congrates && (
        <Modal
          open={congrates}
          onClose={() => setCongrates(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={style.modal}>
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  right: "-20px",
                  top: "-20px",
                }}
              >
                <IconButton
                  onClick={() => setCongrates(false)}
                  aria-label="settings"
                  sx={{
                    color: "#fff",
                    backgroundColor: "none",
                    fontSize: "2rem",
                    padding: "0 !important",
                    margin: "0 !important",
                  }}
                >
                  <img src={close_icon} alt="cancel-icon" />
                </IconButton>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <img src={congratulation} alt="congrates" height={100} />
              </Box>
              <h1 className={style.modal_title}>Congratulations</h1>

              <p className={style.modal_para2}>
                Your Plan is successfully activated. You will received an email
                for the complete details on your plan or you can check your
                United Market account.
              </p>

              <div
                role="button"
                className={style.modal_button}
                onClick={() => {
                  setOpen(false);
                  setPaymentModal(true);
                }}
              >
                Get Hitmaker
              </div>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ManageMembership;
