import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "24px",
  marginTop: "40px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));
