import React from "react";
import { Main } from "./styles";
import { Link } from "react-router-dom";
import { whitearrowleft } from "../../../../assets/smartlink";

const PageTitle = ({
  title = "",
  hasNavigate = true,
  hasUrl = "",
  NavigateTitle = "",
}) => {
  const Url = hasUrl ? hasUrl : -1;
  return (
    <Main>
      {!hasNavigate && <h6>{title}</h6>}
      {hasNavigate && (
        <Link to={Url}>
          <img src={whitearrowleft} alt="whitearrowleft" /> {NavigateTitle}
        </Link>
      )}
    </Main>
  );
};

export default PageTitle;
