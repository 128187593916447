import React, { useEffect } from "react";
import { Backdrop, Box, Button, Modal, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const DeleteUserModal = ({ open, onClose, onDelete, user }) => {
  console.log("user", user);

  useEffect(() => {
    console.log("user", user);
  }, [user, open]);
  return (
    <Modal
      open={open}
      disableAutoFocus={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        disableAutoFocus
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "600px",
          borderRadius: "30px",
          outline: "none !important",
          backgroundColor: "rgba(20, 20, 20, 1)",
          border: "none",
          borderColor: "rgba(20, 20, 20, 1)",
          //   boxShadow: 24,
          p: 4,
          "@media (max-width: 600px)": {
            width: "90vw",
          },
        }}
      >
        <AccountCircleIcon
          style={{
            fontSize: "60px",
            color: "#2ebb55",
            margin: "auto",
            display: "block",
          }}
        />
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            mt: "20px !important",
            fontFamily: "Poppins !important",
            fontStyle: "normal !important",
            fontWeight: "400 !important",
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "30px",
            color: "#FFFFFF",
          }}
        >
          Are you sure you want to delete this user?
        </Typography>
        {/* <Typography
          id="modal-modal-description"
          sx={{
            mt: "10px !important",
            mb: "20px !important",
            fontFamily: "Poppins !important",
            fontStyle: "normal !important",
            fontWeight: "400 !important",
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "30px",
            color: "grey",
          }}
        >
          We want to ensure we have the most up to date information
        </Typography> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            // marginTop: "20px !important",
          }}
        >
          <Button
            variant="contained"
            size="medium"
            onClick={onClose}
            sx={{
              color: "#FFF !important",
              fontFamily: "Poppins !important",
              fontSize: "16px !important",
              fontStyle: "normal !important",
              fontWeight: "500 !important",
              lineHeight: "normal !important",
              borderRadius: "5px !important",
              marginY: "20px !important",
              background: "#transparent !important",
              padding: "8px 24px !important",
              textTransform: "capitalize !important",

              "&:hover": {
                backgroundColor: "rgba(255, 0, 0, 0.5) !important",
              },

              "@media (max-width: 600px)": {
                fontSize: "14px !important",
                padding: "8px 16px !important",
              },
            }}
          >
            CANCEL
          </Button>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            // marginTop: "20px !important",
          }}
        >
          <Button
            variant="contained"
            size="medium"
            onClick={() => onDelete(user)}
            sx={{
              color: "#FFF !important",
              fontFamily: "Poppins !important",
              fontSize: "16px !important",
              fontStyle: "normal !important",
              fontWeight: "500 !important",
              lineHeight: "normal !important",
              borderRadius: "5px !important",
              background: "#2EBB55 !important",
              padding: "8px 24px !important",
              textTransform: "capitalize !important",
              "@media (max-width: 600px)": {
                fontSize: "14px !important",
                padding: "8px 16px !important",
              },
            }}
          >
            CONFIRM
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteUserModal;
