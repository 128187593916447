import React, { useEffect } from "react";
import { Grid } from "@mui/material";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import ViewSubmission from "../../components/ViewSubmissionComponents/viewSubmission";
import SyncSubmission from "../../components/ViewSubmissionComponents/SyncSubmission";
import { useNavigate } from "react-router-dom";
import useAuth from "../../context/AuthContext";
import { get } from "lodash";
import { ROLES } from "../../shared/constants";

const ViewSyncSubmissions = () => {
  return (
    <Grid>
      <Sidebar
        childComponent={<SyncSubmission />}
        activeText="Sync Placements"
      />
    </Grid>
  );
};

export default ViewSyncSubmissions;
