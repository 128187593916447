import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ResultCountMain = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  h6: {
    color: "#FFF",
    fontFamily: theme.typography.fontFamily,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    margin: "0",
  },
  "& .showPrice_number_main": {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
}));

export { ResultCountMain };
