import { Box, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

// const SelectMain = styled(Box)(({ theme, height }) => ({
//   minWidth: 123,
//   "& .select-box": {
//     height: height,
//     border: "1px solid #AFAFAF",
//     color: "#AFAFAF",
//     "& svg": {
//       fill: theme.palette.secondary.dark,
//     },
//     "& .MuiMenu-list": {
//       flexDirection: "column",
//       background: "red",

//       "& .MuiMenuItem-root": {
//         color: "green",
//       },
//     },
//   },
// }));

// export { SelectMain };

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "400px",
  lineHeight: "normal",
  color: "#FFFFFF",
  display: "inherit !important",
  padding: "20px 0px !important",
  width: "290px !important",
  background: "none !important",
  borderTop: "1px solid #414241 !important",
  "&:first-child": {
    borderTop: "none !important",
    paddingTop: "0px  !important",
  },
  "&:last-child": {
    paddingBottom: "0px  !important",
  },
  "&:hover": {
    color: "#AFAFAF !important",
  },
}));

const SelectMain = styled(Box)(({ theme, height }) => ({
  maxWidth: 123,

  "& .select-box": {
    minWidth: 123,
    height: height,
    border: "1px solid #AFAFAF !important",
    padding: "6px 10px",
    color: theme.palette.secondary.dark,
    background: "transparent",
    opacity: "999",
    "& svg": {
      fill: "#AFAFAF !important",
      fontSize: "12px",
    },

    "&.select-box.open svg": {
      transform: "rotate(90deg)",
      fontSize: "12px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px!important",
    },
  },
}));

export { SelectMain, StyledMenuItem };
