import { useEffect } from "react";
import { Field, Form, Formik, useField } from "formik";
import React, { useState } from "react";
import { InputsTagsMain, RleaseContentBox } from "./style";
import { Box, Typography } from "@mui/material";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { singer2 } from "../../../../../../assets/smartlink";
import InputField from "../../../../common/inputfield/inputField";
import ShareButton from "../../../../common/ShareButton/ShareButton";
import { ReleaseBoxWrapper } from "../../../style";
import ImageUploader from "../../../../common/Imageuploader/ImageUploader";
import {
  CREATE_SMART_LINK,
  UPDATE_SMART_LINK,
  CREATE_CONTENT_SMART_LINK,
  UPDATE_PRERELEASE_SMART_LINK,
} from "../../../../../../Graphql_services/smartlink/SmartLink";
import { useMutation } from "@apollo/client";
import { generateRandomString } from "../../../../helpers/UniqueCode";
import * as Yup from "yup";
import { Notification } from "../../../../../NotifyMsg/Notification";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../../common/pagetitle/PageTitle";
import PreReleaseServices from "../../../../prerelease/prereleaseservices/PreReleaseServices";

const validationSchema = Yup.object().shape({
  artist: Yup.string().required("Artist is required"),
  code: Yup.string()
    .min(3, "Shortcode must be at least 3 characters")
    .matches(
      /^[a-zA-Z0-9-_]+$/,
      "Invalid characters in shortcode. Supported characters are a-z, A-Z, 0-9, - and _"
    ),
  title: Yup.string().required("Title is required"),
  tag: Yup.array()
    .of(Yup.string().required())
    .test("Tag is Required", "At least one tag is required", function (value) {
      return Array.isArray(value) && value.length > 0;
    }),
  link: Yup.string()
    .matches(
      /^[a-zA-Z]+$/,
      "Invalid characters in link. Only alphabets are allowed."
    )
    .required("Link is required"),
});

export const TagsInputField = ({ label, defaultTags = [], ...props }) => {
  const [field, meta, helpers] = useField(props.name);

  useEffect(() => {
    if (defaultTags.length > 0) {
      helpers.setValue(defaultTags);
    }
  }, [defaultTags, helpers]);

  const handleTagsChange = (tags) => {
    helpers.setValue(tags);
    helpers.setTouched(true);
  };

  return (
    <div>
      <label>{label}</label>
      <TagsInput value={field.value} onChange={handleTagsChange} />
      {meta.error && field.value.length === 0 && (
        <div className="error">{meta.error}</div>
      )}
    </div>
  );
};

const EvaluationForm = ({
  handleModalOpen = () => {},
  shareModelOpen = () => {},
  setCreatedLinkData = () => {},
  EditData = {},
  BaseData = null,
  heading = "",
  Subheading = "",
}) => {
  const navigate = useNavigate();
  // ============= Extract Data From URL =============
  let ExtractData = {};
  if (BaseData) {
    ExtractData = BaseData.Data.data.attributes;
  }
  const { code, mediaType, title, images } = ExtractData;

  // =============== Edit && Update Data =================

  let UpdateData = {};

  if (EditData) {
    UpdateData = EditData.smartlink;
  }

  console.log(EditData, "Edit Data");

  // ======================= States =====================

  const [uploadimageurl, setuploadimageurl] = useState(
    images && images.length > 0
      ? images[0]
      : "" || (UpdateData?.images.length > 0 && UpdateData?.images[3].url)
  );
  const [tags, setTags] = useState([]);
  const [initialCode] = useState(generateRandomString(6));
  const [RoleType, setRoletype] = useState("");
  const [Preobj, setPreobj] = useState(false);
  const [IsPrerelease, setIsPrerelease] = useState(false);

  // ======================= Mutations =====================

  // Create Smart Link
  const [createSmartLink, { data, loading }] = useMutation(CREATE_SMART_LINK);

  // Create Content Link
  const [createContentSmartLink, { data: ContentData, loading: ContentLoad }] =
    useMutation(CREATE_CONTENT_SMART_LINK);

  // Update Smart Link
  const [updateSmartLink, { data: updatedata, loading: updateload }] =
    useMutation(UPDATE_SMART_LINK);

  // ======================= Submitt Apis =======================

  const onSubmit = async (values) => {
    try {
      let result;

      // Edit && Update Smart Link

      if (EditData && Object.keys(EditData).length > 0) {
        let UpdatedObject = {
          artist: values.artist || "",
          title: values.title || "",
          code: values.code,
          status: "Published",
          mediaType: UpdateData?.mediaType,
          tags: values.tag.join(","),
          linkId: UpdateData?.id,
          image:
            uploadimageurl ||
            "https://images.unsplash.com/photo-1470225620780-dba8ba36b745?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        };
        if (UpdateData?.mediaType == "Content") {
          handleModalOpen(true);
          // Edit && Update Content Smart Link

          UpdatedObject.fallbackUrl = UpdateData?.fallbackUrl;
          result = await updateSmartLink({
            variables: UpdatedObject,
            context: { clientName: "smartlink" },
          });
          handleModalOpen(false);
          Notification("success", "Link updated Successfully");
          navigate("/smart-link/created-link");
        }
        if (UpdateData?.mediaType == "PreRelease") {
          // Edit Pre-Release Smart Link

          setPreobj(UpdatedObject);
          setIsPrerelease(true);
        } else {
          // Edit && Update Release Smart Link
          handleModalOpen(true);
          UpdatedObject.baseUrl = "https://www.youtube.com";
          result = await updateSmartLink({
            variables: UpdatedObject,
            context: { clientName: "smartlink" },
          });
          handleModalOpen(false);
          Notification("success", "Link updated Successfully");
          navigate("/smart-link/created-link");
        }
      } else {
        const mutationVariables = {
          artist: values.artist || "",
          mediaType: mediaType,
          title: values.title || "",
          // fallbackUrl: "https://google.com",
          // url: "htttps://unitedmarket.com",
          code: values.code,
          tags: values.tag.join(","),
          image:
            uploadimageurl ||
            "https://images.unsplash.com/photo-1470225620780-dba8ba36b745?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        };
        if (BaseData.type == "UPC") {
          mutationVariables.upc = ExtractData?.upc;
        }
        if (BaseData.type == "ISRC") {
          mutationVariables.isrc = ExtractData?.isrc;
        }
        if (BaseData.type == "url") {
          mutationVariables.baseUrl = ExtractData?.url;
        }

        if (RoleType == "Content") {
          handleModalOpen(true);
          // Create Content Smart Link
          mutationVariables.mediaType = "Content";
          result = await createContentSmartLink({
            variables: mutationVariables,
            context: { clientName: "smartlink" },
          });
          handleModalOpen(false);
          setCreatedLinkData(result.data.createContentSmartLink.smartlink);
          Notification("success", "Content Link Created Succesfully");
          navigate("/smart-link/created-link");
        } else {
          handleModalOpen(true);
          // Create Release Smart Link
          mutationVariables.mediaType = mediaType;

          result = await createSmartLink({
            variables: mutationVariables,
            context: { clientName: "smartlink" },
          });
          handleModalOpen(false);
          Notification("success", "Release Link Created Succesfully");
          setCreatedLinkData(result.data.createSmartLink.smartlink);
          navigate("/smart-link/created-link");
        }
      }

      shareModelOpen(true);

      values.title = "";
      values.artist = "";
      values.code = "";
      values.tag = [];
    } catch (error) {
      handleModalOpen(false);
      Notification("error", error.message);
      console.log(error.message, "Errror");
    }
  };

  // Default Image set When Add or Update Smart Link

  useEffect(() => {
    if (images && images.length > 0) {
      setuploadimageurl(images[0]);
    } else if (
      UpdateData &&
      UpdateData.images &&
      UpdateData.images.length > 0
    ) {
      setuploadimageurl(UpdateData.images[3].url);
    }
  }, [images, UpdateData]);

  useEffect(() => {
    console.log("Updated uploadimageurl:", uploadimageurl);
  }, [uploadimageurl]);

  /// Assign Role to Create Link  =>>>>> Release && Content

  useEffect(() => {
    let role = localStorage.getItem("RouteType");
    if (role !== "") {
      setRoletype(role);
    }
  }, []);

  return (
    <>
      <ReleaseBoxWrapper>
        {!IsPrerelease ? (
          <RleaseContentBox>
            <Box className="heading-desc">
              <Typography variant="h4">
                {RoleType == "Content"
                  ? "Set Up Your Content Link"
                  : heading !== ""
                  ? heading
                  : "Elevate Your Music Release Link"}
              </Typography>
              <Typography variant="body1">
                {RoleType == "Content"
                  ? "Provide us with a few more details. You can modify these later."
                  : Subheading !== ""
                  ? Subheading
                  : "Unlock the Stage for Your Music! Add the magic with tags, and let your artists shine. Our platform transforms your tunes into a mesmerizing experience, captivating audiences worldwide."}
              </Typography>
            </Box>
            <Box className="table-box">
              <Box className="img-box">
                <ImageUploader
                  setuploadimageurl={setuploadimageurl}
                  img={uploadimageurl}
                />
                {/* <img src={singer2} alt="user image" style={{ width: "100%" }} /> */}
              </Box>
              <Box
                className="form"
                sx={{
                  "& .error": {
                    color: "#ec3330",
                    fontSize: "13px",
                    fontWeight: 500,
                    marginTop: "2px",
                    fontStyle: "italic",
                  },
                  "& .error_main": {
                    input: {
                      border: "1px solid #ec3330",
                    },
                    "& .react-tagsinput": {
                      border: "1px solid #ec3330",
                    },
                  },
                  "& .tag_error_main": {
                    "& .react-tagsinput": {
                      border: "1px solid #ec3330",
                      opacity: 1,
                    },
                  },
                }}
              >
                <Formik
                  initialValues={{
                    title: title || (UpdateData && UpdateData.title),
                    artist:
                      UpdateData &&
                      UpdateData.artists &&
                      UpdateData.artists.length > 0
                        ? UpdateData.artists[0].name
                        : "",
                    code: code || (UpdateData && UpdateData.code),
                    tag: [] || (UpdateData && UpdateData.tags),
                    link: "",
                  }}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ errors, values }) => (
                    <Form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                      }}
                    >
                      <Box className={`${errors.title ? "error_main" : ""}`}>
                        <Field
                          label="Title"
                          type="text"
                          name="title"
                          placeholder="Enter Title"
                          component={InputField}
                        />
                        {errors.title && (
                          <div className="error">{errors.title}</div>
                        )}
                      </Box>
                      <Box className={`${errors.artist ? "error_main" : ""}`}>
                        <Field
                          label="Artists"
                          type="text"
                          name="artist"
                          placeholder="Enter Artists"
                          component={InputField}
                        />
                        {errors.artist && (
                          <div className="error">{errors.artist}</div>
                        )}
                      </Box>
                      <InputsTagsMain
                        className={`${errors.tag ? "tag_error_main" : ""}`}
                      >
                        <TagsInputField
                          label="Tags"
                          name="tag"
                          defaultTags={UpdateData?.tags}
                        />
                      </InputsTagsMain>
                      <Box className="link-field-box">
                        <Box
                          sx={{ width: "100%" }}
                          className={`${errors.link ? "error_main" : ""}`}
                        >
                          <Field
                            label="Links"
                            type="text"
                            name="link"
                            placeholder="Please Musician Name"
                            component={InputField}
                          />
                          {errors.link && (
                            <div className="error">{errors.link}</div>
                          )}
                        </Box>

                        <Box
                          className={`${errors.code ? "error_main " : ""}`}
                          sx={{ width: "100%" }}
                        >
                          <Field
                            label="code"
                            type="text"
                            name="code"
                            placeholder="code"
                            component={InputField}
                          />
                          {errors.code && (
                            <div className="error">{errors.code}</div>
                          )}
                        </Box>
                      </Box>
                      <Typography variant="h5">
                        {`${
                          values.link === "" ? "musician name" : values.link
                        }/unitedmarket.com/${values.code}`}
                        <span> is available</span>
                      </Typography>
                      <Box
                        className="btn-box"
                        // onClick={() => handleModalOpen(true)}
                      >
                        <ShareButton
                          name={heading == "Organize" ? "Update" : "Create"}
                          type="submit"
                          isloading={loading || updateload || ContentLoad}
                        />
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </RleaseContentBox>
        ) : (
          <PreReleaseServices Preobj={Preobj} EditData={EditData} />
        )}
      </ReleaseBoxWrapper>
    </>
  );
};

export default EvaluationForm;
