import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useReducer,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { Main, TableMain } from "../beatdelete/style";
import ErrorIcon from "@mui/icons-material/Error";
import {
  fill_star,
  image2,
  pausecircle,
  playcircle,
  InfoCircle,
  InfoCirclegray,
} from "../../../../assets/smartlink";

// import AudioPlayer from "../../common/musicplayer/AudioPlayer";
import audioTracks from "../../common/musicplayer/AudioTracks";
import { AudioPlayerWrapper } from "./AudioPlayerWrapper";
import { Notification } from "../../../NotifyMsg/Notification";
import {
  ADDToFavouriteBeats,
  GetAllFavouriteBeats,
} from "../../../../Graphql_services/beatinbox/Beats";
import { useMutation } from "@apollo/client";
import BeatEmpty from "../emptybeat/BeatEmpty";
import LoadingSpinner from "../../common/loader/LoadingSpinner";

// {
//   title: "TUMHE APNA BANANE KA",
//   artist: "Artist 2",
//   color: "#ff0000",
//   image: "path/to/image2.jpg",
//   audioSrc: "Audio2",
// }
import Audioplayers from "../../common/Audioplayer/Audioplayer";
import Collaborations from "../inboxbeat/Collaborations";

const StarredBeats = ({ data = [], loading = false }) => {
  const [addToFavouriteBeats] = useMutation(ADDToFavouriteBeats, {
    refetchQueries: [GetAllFavouriteBeats, "getFavouriteBeats"],
    context: { clientName: "beats" },
  });
  const [PlayData, setPlayData] = useState([]);

  const [voiceIndex, setVoiceIndex] = useState(null);
  const [isplay, setisplay] = useState(null);
  const [rowLoading, setRowLoading] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showDropdownIndex, setShowDropdownIndex] = useState(null);

  const TableHeaddata = [
    { title: "" },
    { title: "File Name" },
    { title: "Inbox Name" },
    { title: "Remaining Days" },
    { title: "Price" },
    { title: "Play" },
  ];

  const RemoveStarredhandler = async (id) => {
    try {
      // setIsLoading(true);
      if (!id) {
        Notification("error", "id is required!");
        return;
      }
      setRowLoading(id);
      const res = await addToFavouriteBeats({
        variables: { id },
        context: { clientName: "beats" },
      });
      if (res?.data?.addFavouriteBeats?.success) {
        Notification("success", res?.data?.addFavouriteBeats?.message);
      } else {
        Notification("error", res?.data?.addFavouriteBeats?.message);
      }
    } catch (error) {
      Notification("error", error.message);
    } finally {
      // setIsLoading(false);
      setRowLoading(null);
    }
  };

  /// Audio Functionlity

  const player = useRef(null);
  const [SongsData, setSongsData] = useState([]);

  const [playerState, setPlayerState] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    {
      songs: SongsData,
      isPlaying: false,
      current: 0,
      progress: 0,
      volume: 100,
      prevVolume: 100,
    }
  );

  const pause = () => {
    player.current.pause();
    setPlayerState({ isPlaying: false });
  };

  const play = () => {
    player.current.play();
    setPlayerState({ isPlaying: true });
  };

  const pausePlay = () => {
    if (playerState.isPlaying) {
      pause();
    } else {
      play();
    }
  };

  const handlePlaylistPlay = (index) => {
    setisplay(!isplay);
    if (playerState.current === index) {
      pausePlay();
    } else {
      setPlayerState({ current: index });
      player.current.addEventListener("canplay", () => play(), { once: true });
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const audioFileIds = data.map((beat) => beat.audioFileId);
      setSongsData(audioFileIds);
    }
  }, [data]);

  return (
    <Main>
      <h6>Starred Beats</h6>
      {!loading && data?.length === 0 && (
        <BeatEmpty
          text="Your starred inbox is empty"
          desc="Create your first inbox to begin starring beats"
        />
      )}
      <TableMain>
        <TableContainer
          sx={{
            overflowX: "unset",
          }}
          component={Paper}
        >
          <Table className="starred_beats_table">
            {data?.length > 0 && (
              <TableHead>
                <TableRow>
                  {TableHeaddata?.map((value) => {
                    return (
                      <>
                        <TableCell key={value.title}> {value.title}</TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={TableHeaddata.length}
                    sx={{
                      textAlign: "center !important",
                      fontSize: "14px !important",
                      color: "#AFAFAF !important",
                    }}
                  >
                    loading...
                  </TableCell>
                </TableRow>
              )}
              {/* {!loading && data?.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={TableHeaddata.length}
                    sx={{
                      textAlign: "center !important",
                      fontSize: "14px !important",
                      color: "#AFAFAF !important",
                    }}
                  >
                    You have no starred beats
                  </TableCell>
                </TableRow>
              )} */}
              {data &&
                data?.length > 0 &&
                data?.map((row, index) => {
                  const {
                    filename,
                    audioFileId,
                    remainingDays,
                    beatExclusivePrice,
                    inboxId,
                  } = row || {};
                  const words = audioFileId?.fileName.substr(-22);

                  return (
                    <>
                      <TableRow key={row?._id}>
                        {/* <TableCell>
                          <div className="starred_beats_image">
                            <img
                              src={fill_star}
                              alt={filename}
                              onClick={() => RemoveStarredhandler(row?._id)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </TableCell> */}
                        <TableCell>
                          <div style={{ width: "30px" }}>
                            {rowLoading === row._id ? (
                              <LoadingSpinner size={25} />
                            ) : (
                              <div>
                                <img
                                  src={fill_star}
                                  alt={filename}
                                  onClick={() => RemoveStarredhandler(row?._id)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          {words || "N/A"}{" "}
                          <span
                            onMouseEnter={() => {
                              setHoveredIndex(index);
                              setShowDropdownIndex(index);
                            }}
                            onMouseLeave={() => {
                              setHoveredIndex(null);
                              setShowDropdownIndex(null);
                            }}
                            style={{ position: "relative" }}
                          >
                            <ErrorIcon
                              sx={{
                                height: "18px !important",
                                width: "18px !important",
                                color: `${
                                  hoveredIndex === index ? "#2EBB55" : "#AFAFAF"
                                }`,
                              }}
                              alt="Info_Circle.svg"
                            />
                            {showDropdownIndex === index && (
                              <Collaborations
                                list={row?.collaboratorsDetails}
                                className="popover-box"
                              />
                            )}
                          </span>{" "}
                        </TableCell>
                        <TableCell className="inboxname">
                          <span className="name_value">
                            {inboxId?.title || "N/A"}
                          </span>
                        </TableCell>
                        <TableCell
                          className="remainingdays"
                          sx={{ color: "#F00" }}
                        >
                          <span
                            className="date_value"
                            style={{ color: "#F00" }}
                          >
                            {remainingDays + " days" || "N/A"}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span className="date_value">
                            ${beatExclusivePrice?.toFixed(2) || "N/A"}
                          </span>
                        </TableCell>
                        <TableCell>
                          <div
                            onClick={() => handlePlaylistPlay(index)}
                            className="payicon_icon"
                          >
                            {playerState.current === index &&
                            playerState.isPlaying ? (
                              <img
                                src={pausecircle}
                                alt="Pauseicon.svg"
                                title="Pause"
                              />
                            ) : (
                              <img
                                src={playcircle}
                                alt="Playicon.svg"
                                title="Play"
                              />
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </TableMain>

      {SongsData.length > 0 && (
        <AudioPlayerWrapper>
          <Audioplayers
            songs={SongsData}
            playerState={playerState}
            setPlayerState={setPlayerState}
            player={player}
            pause={pause}
            play={play}
            pausePlay={pausePlay}
            isImg={true}
          />
        </AudioPlayerWrapper>
      )}
    </Main>
  );
};

export default StarredBeats;
